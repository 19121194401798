<div class="card" style="border: 0px solid red; padding: 11px;" [ngStyle]="{'height': height}">
    <h3>{{title}}</h3>
    <ejs-grid
        #grid
        [dataSource]='dataSource'
        [height]="gridHeight"
        [allowResizing]='true'
        width='100%'>
        <e-columns>
            <e-column *ngFor="let column of columns"
                [field]="column.field"
                [headerText]="column.headerText"
                [textAlign]="column.textAlign"
                [width]="column.width"
                [template]="column.template">
            </e-column>
        </e-columns>
    </ejs-grid>
</div>