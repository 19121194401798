import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { Event } from '../../../models/Event';
import { CustomerService } from '../../../services/customer/customer.service';
import { Customer } from '../../../models/Customer';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { Incident } from '../../../models/Incident';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin, Subscription, of, throwError, concat, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IncidentService } from '../../../services/incident/incident.service';
import { UserService } from '../../../services/users/user.service';
import { DiffService } from '../../../services/workers/diff-service.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { TextMarkerOptions } from 'codemirror';
import { AnalysisService } from '../../../services/analysis/analysis.service';
import { bufferCount, catchError, concatAll, concatMap, delay, last, mergeAll, mergeMap, retryWhen, scan, take, tap} from 'rxjs/operators';
import * as Diff2Html from 'diff2html';
import { ContextMenuItemModel, ContextMenuService, DataSourceChangedEventArgs, DataStateChangeEventArgs, GridComponent, PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Ej2EventsService } from '../../../services/ej2Proxies/ej2-events.service';
import { TimezoneService } from '../../../services/timezone.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
declare var $:any;
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
  providers: [ContextMenuService]
})
export class EventsComponent implements OnInit, AfterViewInit, OnDestroy {
  
  public customers:Customer[];
  public codeFile:any;
  public codeFileContent;
  public codeMirrorOptions:any;

  public codeFileTabActive:boolean;
  public domainTabActive:boolean;
  public regexTabActive:boolean;
  public analysisTabActive:boolean;
  
  @ViewChild('analysisViewer') 
  public analysisViewer:CodemirrorComponent;

  @ViewChild('newCodeFileViewer') 
  public newCodeFileViewer:CodemirrorComponent;

  public selectedCustomerIds:Number[];
  public eventTableWidget:any;
  public events:Event[];
  public vendors:any[];
  public vendorName:string;
  public rules:any[];
  public selectedEvent:Event;
  public messages:any[];

  public historyIndex:number;
  public analysts:any[];

  public incidentForm:FormGroup;
  public eventsLoading:boolean;

  public regexForm:FormGroup;

  public outputDiffHtml:string;

  public latestFileContent:string;
  
  diffSub:Subscription;

  public analysisInfo:any;
  public analysisPosition:number;
  public curItem:any;
  public gutterId:any;

  public analysisFeatures:string;
  public linesMarked:boolean;
  public selectedCount:number;

  public severities = [
    {
      value: 0,
      label: "Unknown"
    },
    {
      value: 1,
      label: "Green"
    },
    {
      value: 2,
      label: "Yellow"
    },
    {
      value: 3,
      label: "Red"
    }
  ];

  public statuses = [
    {
      value: 1,
      label: "In Progress"
    },
    {
      value: 2,
      label: "Resolved"
    }
  ];

  currentUserVaultId:number;
  hash1: any;
  hash2: any;

  public pageSettings: PageSettingsModel;
  public selectionSettings:SelectionSettingsModel;  
  public filterSettings: Object;
  public sortSettings:Object;
  @ViewChild('eventsGrid') public eventsGrid: GridComponent;
  public dateFormat:any;
  public responseSizeFormat:any;
  public data:Observable<any>;
  public toolbar:any;

  public totalEvents:string;
  public searchSettings:any;
  public aggregatesSettings:any;

  public contextMenuItems: ContextMenuItemModel[];


  constructor(public el: ElementRef, 
              public route:ActivatedRoute, 
              public sitemapService:SitemapService, 
              public customerService:CustomerService,
              public vendorService:VendorsService,
              public incidentService:IncidentService,
              public spinnerService:SpinnerService,               
              public notificationService:VaultNotificationService,
              public modalService: NgbModal,
              public authHttp: HttpClient,
              public _fb:FormBuilder,
              public userService:UserService,
              public diffService:DiffService,
              public dataExportService:DataExportService,
              public zone:NgZone,
              public analysisService:AnalysisService,
              public ej2ProxyService:Ej2EventsService,
              public timezoneService:TimezoneService,
              public clipboardApi: ClipboardService
              ) {
    
    this.sitemapService.update(this.route);
    this.toolbar = ['Search'];
    // this.data = ej2ProxyService.pipe(tap(()=>{this.spinnerService.toggle(false);}));
    this.eventsLoading = true;
    this.selectedCustomerIds = [];
    this.selectedEvent = null;
    this.codeFileTabActive = true;
    this.codeFile = {name: "", history:[], hash: ""};
    this.linesMarked = false;
    this.totalEvents = "";
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      tabSize: 2,
      readOnly: true,
      gutters: ["CodeMirror-linenumbers", "analysis"]      
    };
  
    this.historyIndex = 0;

    this.regexForm = this._fb.group({      
      contentHash: [''],
      vendor: -1,
      rule: -1
    });

    this.filterSettings = { type: 'FilterBar'};
    this.pageSettings = { pageSize: 1000, pageCount: 10, currentPage: 1, pageSizes: ["1000", "2000"]};
    let state = { skip: 0, take: this.pageSettings.pageSize };
    this.selectionSettings = {
      type: 'Multiple',
      mode: 'Row',
      checkboxMode: 'ResetOnRowClick'
    }
    this.dateFormat = {type: 'dateTime', format:'M/d/y h:mm a z'};
    this.responseSizeFormat = {type:'number', format: 'N2'};
    
    this.sortSettings = {
      columns: [
        { field: 'detectedDateTime', direction: 'Descending'}
      ]
    };

    this.searchSettings = {
      fields: ['codeFileUrl'], 
      operator: 'contains', 
      ignoreCase: true
    };

    this.aggregatesSettings = {

    }
    
    this.ej2ProxyService.subscribe((results)=>{
      console.log(results);
      this.spinnerService.toggle(false);
      this.eventsGrid.dataSource = results.result;
      this.totalEvents = `Event total: ${results.count}`;
    });
    this.ej2ProxyService.execute(state)

    
  }

  ngOnDestroy(): void {
    if ( this.diffSub){
      this.diffSub.unsubscribe();
    }
  }

  next(direction){
    if (this.codeFile && this.codeFile.history && this.codeFile.history.length > 0){
      if ( this.historyIndex + direction >= 0 && this.historyIndex + direction < this.codeFile.history.length){
        this.historyIndex += direction;

        const hash = this.codeFile.history[this.historyIndex].hash;
        this.outputDiffHtml = 'Loading...';
        this.loadCodeFile(hash).subscribe((content)=>{

          this.hash1 = this.codeFile.hash;
          this.hash2 = hash;

          this.updatePrettyDiff([this.latestFileContent, content.toString()]);

        },
        (error)=>{
           this.outputDiffHtml = '// Unable to load file...';
        });
      }      
    }    
  }

  loadCodeFiles(file1, file2):Observable<any> {
    return forkJoin(
      this.loadCodeFile(file1),
      this.loadCodeFile(file2)
    );
  };

  loadCodeFile(hash, flag:string=".beautified"):Observable<any>{
    if ( !hash ){
      return of("");
    }
    return this.dataExportService.getAnalysisFile(hash + flag);
  }

  contextMenuOpen(event){
    console.log('Before Open'); 
    let elementId = event.element.id;    
    if (elementId &&  elementId.startsWith("grid") ){
      let fileId = this.selectedEvent.data?.codeFileId;
      console.log("fileId", fileId);
      this.eventsGrid.contextMenuModule.contextMenu.enableItems(["Code Details"], fileId != undefined);
    } else {
      let subMenu = event.element as HTMLElement
      if ( subMenu.className == 'e-menu-parent e-ul ' && event.parentItem.properties.text == 'Create Green Incident') {
        console.log('Fixing height...');
        subMenu.style.height = '250px';
        subMenu.style.overflow = 'auto';
      }
    }
    
  }

  ngAfterViewInit(): void {  
    
    this.userService.getUsers(true).subscribe((users)=>{    
      this.analysts = users.filter((a)=>{
        if ( a.email == JSON.parse(localStorage.profile).email){
          return true;
        }
        return false;
      });
    })
  }

  ngOnInit() {

    this.contextMenuItems = [
      { text: 'Copy', id: 'copy', items:[
        {text: "File Name", id: "copyFileName"},
        {text: "File Hash", id: "copyFileHash"},
        {text: "Vendor Name", id: "copyVendorName"}
      ] },      
      {separator: true},
      { text: 'Search', id: 'search', items:[
          {text: "File", id: 'searchFile' },
          {text: "Vendor", id: 'searchVendor' }
        ]
      },      
      {separator: true},
      { text: 'Code Details', id: 'codeDetails' },
      { text: 'Vendor Page', id: 'vendorPage' }
    ];

    const userId = JSON.parse(localStorage.getItem("profile")).sub.toString();
    this.userService.getUser(userId).subscribe((userInfo)=>{      
      this.currentUserVaultId = userInfo[0].vaultId;      
    },(error)=>{
      console.log(error);
    });

    this.customerService.getAllCustomers().subscribe((customers)=>{
      this.onCustomersLoaded(customers);
    });

    this.vendorService.getVendors("").subscribe((vendors)=>{
      this.vendors = vendors;
    });

    this.regexForm.get('vendor').valueChanges.subscribe(val => {
      if ( val != "") {
        this.vendorName = val;
        this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
          this.rules = rules;
          this.regexForm.patchValue({rule:-1});
        });
      }
    });

    this.buildIncidentForm();
  }

  toggleTabs(codeFileActive:boolean=false, domainActive:boolean=false, regexActive:boolean=false, analysisActive:boolean=false){

    this.codeFileTabActive = codeFileActive;
    this.domainTabActive = domainActive;
    this.regexTabActive = regexActive;
    this.analysisTabActive = analysisActive;

  }

  buildIncidentForm() {
    this.incidentForm = this._fb.group({
      name: "",
      severity: 1,
      status: 1,
      analyst: 0,
      linkedEvents: "",
      message: ""
    });

    $('a[data-toggle="tab"]a:contains("Code File")').on('shown.bs.tab', (e) => {
      this.toggleTabs(true);
      this.zone.run(()=>{
        console.log('Zone refresh...');
      });
    });

    $('a[data-toggle="tab"]a:contains("Domain")').on('shown.bs.tab', (e) => {
      
      this.toggleTabs(false, true);
      this.zone.run(()=>{
        console.log('Zone refresh...');
      });
    });

    $('a[data-toggle="tab"]a:contains("Regex")').on('shown.bs.tab', (e) => {
      this.toggleTabs(false, false, true);
      this.zone.run(()=>{
        console.log('Zone refresh...');
      });
    });

    $('a[data-toggle="tab"]a:contains("Analysis")').on('shown.bs.tab', (e) => {
      console.log('Updating tabs...');
      this.toggleTabs(false, false, false, true);
            
      console.log('Zone...');
      this.zone.run(()=>{
        console.log('Zone refresh...');        
      });
      console.log('Complete...');

      this.analysisViewer.codeMirror.setSize("100%", "475px");
      console.log('Refresh code view...');
      this.analysisViewer.codeMirror.refresh();

    });

    this.refreshNames();
  }

  onCustomersLoaded(customers) {    
    this.customers = customers.sort( (a:Customer, b:Customer) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
    $(".customers").change();
  }

  createIncident(model) {
    const form = model.value;
    console.log(form);

    const selectedEvents = this.eventsGrid.getSelectedRecords() as Event[];
    var incidents = [];
    const linkedEventIds = form.linkedEvents.split(",");
    for (let index = 0; index < selectedEvents.length; index++) {
      const event = selectedEvents[index];      
      // console.log("EVENT");
      // console.log(event);
      var theIncident = new Incident(null, form.name, form.message, form.severity, form.status, this.currentUserVaultId ? this.currentUserVaultId : 805, event.customerId, event.id, null, linkedEventIds);      
      theIncident.vendor = this.getVendorName(event.vendorId);
      theIncident.eventType = event.name;      
      incidents.push(theIncident);       
    }
    
    console.log("Incident Count: " + incidents.length);
  
    this.spinnerService.toggle(true);

    let batchSize = window['batchSizeOverride'] ? window['batchSizeOverride'] : 20;

    let counter = 0;
    from(incidents).pipe(
      bufferCount(batchSize),
      concatMap((chunk)=>{ return this.incidentService.createIncident(chunk).pipe(catchError(err => of(err)))}),
      tap((x)=>{ console.log("Tap x", JSON.stringify(x)); this.notificationService.info("Progress", `Batch ${++counter} of ${ Math.ceil(incidents.length / batchSize) } has completed`) }),
      scan((acc, res) => [...acc, res], []),
      last()
    ).subscribe((x)=>{
      console.log("Counter: " + counter++);
      console.log(x);

      this.notificationService.success('Success!', 'The incident(s) were created!', 3000);
      let state = { skip: 0, take: this.pageSettings.pageSize };
      this.ej2ProxyService.execute(state);

      this.incidentForm = this._fb.group({
        name: "",
        severity: 0,
        status: 1,
        analyst: 0,
        linkedEvents: "",
        message: ""
      });
      
      this.selectedCount = 0;
    },
    (error)=>{
      console.log("Create Incident Error", error);
      this.notificationService.error('Error!', 'Incident(s) were NOT created!', 3000);
      this.spinnerService.toggle(false);
    },
    ()=>{
      console.log("Create Incident Complete");
      this.outputDiffHtml = 'Select files to compare...';
      this.codeFile.name = "";
    });
  }

  getFileNameFromEvent(event){
    var data = event;
    if ( data.typeId == 0 && data.data && data.data.codeFileUrl ){
      if ( data.data.codeFileUrl.length <= 100 ){
        return data.data.codeFileUrl;
      } else {
        // return `<div title=${data.data.codeFileUrl}>${data.data.codeFileUrl.substr(0, 100)}...</div><span class="icon zmdi zmdi-copy" onClick="copyToClipboard(event, '${data.data.codeFileUrl}');"></span>`;
        return data.data.codeFileUrl.substr(0, 100);
      }
    }
    return "???";
  }

  missingDataRenderer(data, type, row, meta){
    if ( (type == 'display' || type == 'sort') && (data == null || data == undefined) ){
      return "???";
    }
    return data;
  }

  updatePrettyDiff(files){
    this.outputDiffHtml = "Loading...";
    this.diffSub = this.diffService.startDiff(files[1].toString(), files[0].toString()).subscribe((result)=>{      
      if ( !result.data || result.data == ""){
        console.log("EMPTY/NULL from worker");
      } else {                
        let outputHtml = Diff2Html.html(result.data, {matching: 'none', outputFormat:'side-by-side'} );
        this.outputDiffHtml = outputHtml;
      }
    },(err)=>{
      this.outputDiffHtml = "An error has occurred...";
    });
  }

  onNameChange(){
    var name = this.incidentForm.value.name;
    this.incidentForm.patchValue({message:name})
  }

  testRegex(){
    
    const ruleId = this.regexForm.value.rule;
    if ( ruleId != -1 ){
      this.vendorService.testRuleForFile(this.vendorName, ruleId, this.hash1, this.hash2).subscribe((results)=>{
        if (results.error){
          alert("Error: " + results.error);
        } else {
          var msg = 'Rule matched!'
          if ( results.result == false ){
            if ( results.change ){
              msg = "Unexpected change: " + results.change;
            } else {
              msg = "Rule does not match";
            }            
          } 
          alert(msg);
        }
      });
    } else if ( this.hash1 == '' ) {
      alert('Specify a file hash!');
    } else {
      alert('Select a rule!');
    }
  }

  refreshRules(){
    this.vendorService.getClassificationRules(this.vendorName, true).subscribe((rules)=>{
      this.rules = rules;
      this.regexForm.patchValue({rule:-1});
    });
  }

  contextMenuClick(event) {
  
    let menuItemProps = event.item.properties;
    let menuItemText = menuItemProps.text;
    let menuItemId = menuItemProps.id;
    let hasChildren = menuItemProps.items?.length

    if (!hasChildren || hasChildren == 0){
      console.log(menuItemText);
      if ( menuItemId.startsWith("greenIncident") ){
        let fakeForm = {value: {
          name: menuItemText,
          severity: 1,
          status: 2,
          analyst: 0,
          linkedEvents: "",
          message: menuItemText
        }};
        this.createIncident(fakeForm);
      } else if ( menuItemId == 'copyFileName'){
        this.clipboardApi.copyFromContent(this.codeFile.name);
        this.notificationService.info('', 'File name copied to clipboard');
      } else if ( menuItemId == 'copyFileHash'){
        this.clipboardApi.copyFromContent(this.codeFile.hash);
        this.notificationService.info('', 'File hash copied to clipboard');
      } else if ( menuItemId == 'copyVendorName'){
        this.clipboardApi.copyFromContent(this.codeFile.vendorName);
        this.notificationService.info('', 'Vendor name copied to clipboard');
      } else if ( menuItemId == 'codeDetails'){
        window.open(this.codeFile.link, "_blank");      
      } else if ( menuItemId == 'vendorPage'){
        window.open(this.codeFile.vendorLink, "_blank");
      } else if ( menuItemId == 'searchFile' ){
        window.open("/codeFiles?f=" + this.codeFile.name);
      } else if ( menuItemId == 'searchVendor'){
        window.open("/codeFiles?v=" + this.codeFile.vendorName);
      }
    }
  }

  refreshNames(){
    this.customerService.getEventMessage().subscribe((messages)=>{
      this.messages = messages;    
      let messageItems = this.messages.map((message, i)=>{ return { text: message.message, id: 'greenIncident' + i}});
      let foundIndex = this.eventsGrid.contextMenuModule.contextMenu.items.filter((item)=>{return item.text == 'Create Green Incident'});
      if ( foundIndex && foundIndex.length == 1 ){
        foundIndex[0].items = messageItems;
      } else {        
        this.eventsGrid.contextMenuModule.contextMenu.insertAfter([{separator: true},  {text: 'Create Green Incident', items: messageItems} ], "Vendor Page");
        this.eventsGrid.contextMenuModule.contextMenu.insertBefore([{separator: true}], "Create Green Incident");
      }
    });
  }

  nextAnalysis(direction){
    
    if ( this.linesMarked != true && this.analysisInfo != null && this.analysisInfo.length > 0 ) {      
      console.log('Marking text...' + this.analysisInfo.length + ' rows...');
      var options:TextMarkerOptions = {css:"color : white; background-color: #ff0000"};
      const codeViewerDoc =  this.analysisViewer.codeMirror.getDoc();
      for (let index = 0; index < this.analysisInfo.length; index++) {
        console.log("Getting row...");
        var item = this.analysisInfo[index];
        console.log("Got row...");
        const start:CodeMirror.Position = {line:(item.location.start.line-1), ch:item.location.start.column};
        console.log("Start created...");
        const end:CodeMirror.Position = {line:(item.location.end.line-1), ch:item.location.end.column};
        console.log("End created...");
        codeViewerDoc.markText(start, end, options);
        console.log("Marked text...");
      };
      this.linesMarked = true;
    }

    if ( !this.analysisInfo || (this.analysisPosition <= 0 && direction == -1) || (this.analysisPosition >= this.analysisInfo.length-1 && direction == 1)){
      return;
    }
    
    var scrollViewPosition:CodeMirror.Position;
    if ( direction == 'start' ){
      scrollViewPosition = {line:(0), ch:0};
      this.analysisPosition = -1;
    } else if ( direction == 'end') {
      
      const numLines = this.codeFileContent.split(/\r\n|\r|\n/).length;
      scrollViewPosition = {line:(numLines - 1), ch:0};      
      this.analysisPosition = this.analysisInfo.length;
    } else {
      
      this.analysisPosition += direction
      const item = this.analysisInfo[this.analysisPosition];
      if ( !item ){
        return;
      }
      scrollViewPosition = {line:(item.location.start.line-1), ch:item.location.start.column};
      
      if ( this.curItem ){
        this.gutterId = this.analysisViewer.codeMirror.clearGutter("analysis");
      }

      this.gutterId = this.analysisViewer.codeMirror.setGutterMarker(item.location.start.line-1, "analysis", this.makeMarker());

      this.curItem = item;
    }
    this.analysisViewer.codeMirror.scrollIntoView(scrollViewPosition);
  }

  makeMarker() {
    var marker = document.createElement("div");
    marker.style.color = "#f00";
    marker.innerHTML = "●";
    return marker;
  }

  onCopied(val){
    this.notificationService.success('Copied', val + ' was copied to clipboard.', 3000);
  }

  isNewCodeFileEvent(event){
    if (event && event.name == "New Code File" ){
      return true;
    }
    return false;
  }

  severityToDisplayString(severity){
    return `badge badge-pill badge-${IncidentService.severityToStyles[severity]}`;
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('Data State Change');
    console.log(state);
    this.eventsGrid.hideSpinner();
    this.ej2ProxyService.execute(state);
  }

  dataSourceChange(source:DataSourceChangedEventArgs){
    console.log("dataSourceChange");
    console.log(source);
  }

  onRowDeselected(event){
    console.log("onEventDeselected");
    console.log(event);
    if (event.isInteracted == true ){
      this.selectedCount = this.eventsGrid.getSelectedRecords().length;
    }
    
  }

  onEventSelected(event){
    console.log("onEventSelected");
    console.log(event.data);

    this.codeFile.name = event.data.codeFileUrl;
    this.codeFile.vendorName = event.data.vendorName;
    this.codeFile.link = `/vendors/${event.data.vendorName}/code/${event.data?.data?.codeFileId}`;
    this.codeFile.vendorLink = `/vendors/${event.data.vendorName}`;

    this.selectedCount = this.eventsGrid.getSelectedRecords().length;

    this.historyIndex = 0;
    const selectedItems = this.eventsGrid.getSelectedRecords();
    this.selectedEvent = (selectedItems.length == 2 ? selectedItems[1] : selectedItems[0]) as Event;

    this.analysisPosition = -1;
    this.toggleTabs(true);
    this.zone.run(()=>{
      console.log('Zone refresh...');        
    });

    this.analysisViewer.codeMirror.scrollIntoView({line:(0), ch:0});

    this.incidentForm = this._fb.group({
      name: "",
      severity: 1,
      status: 2,
      analyst: 0,
      linkedEvents: "",
      message: ""
    });

    var fileHash = this.selectedEvent.data.fileContentsHash;
    // this.codeFile.name = "Loading...";
    this.codeFile.hash = fileHash;
    this.regexForm.patchValue({contentHash: this.codeFile.hash});

    if (window["disableAutoLoadHistory"] == true ){
      return;
    }

    this.outputDiffHtml = 'Loading...';

    if ( !this.isNewCodeFileEvent(this.selectedEvent) ) {
      this.vendorService.getCodeFileHistory(this.selectedEvent.vendorName, this.selectedEvent.data.codeFileId, false, false, false, 100).subscribe((result)=>{
        console.log(result);
        if ( result && result.dataId ) {
          this.vendorService.getCodeFileHistoryData(this.selectedEvent.vendorName, this.selectedEvent.data.codeFileId, result.dataId)
            .pipe(          
              retryWhen(result =>
                
                result.pipe(
                  scan((acc, error) => ({ count: acc.count + 1, error }), {
                    count: 0,
                    error: undefined as any,
                  }),
                  concatMap(result => {
                    // here we can check the error.
                    // We can specify the retry only if we are getting 5xx errors for instance.
                    if (result.error.status === 503) {
                      return of(result);
                    }
                    // in other cases we throw an error down the pipe
                    return throwError(result);
                  }),
                  mergeMap( payload =>{
                    return of(payload).pipe(delay(1000 * payload.count))
                  }),
                  // we can keep calling forever but usually we want to avoid this.
                  // So, we set the number of attempts including the initial one.
                  take(4),
                  o => concat(o, throwError({message:`Unable to load data. Please try again or contact support`, code:666}))
                )
              ),
            ).subscribe((fileHistory)=>{
              console.log("FileHistory!");
              console.log(fileHistory);
              if (fileHistory){

                this.codeFile.history = fileHistory;          
                // this.codeFile.name = this.codeFile.history.length > 0 ? this.codeFile.history[0].name : "";
      
                var tempHistoryIndex = 0;
                this.codeFile.history.filter((h, i)=>{ if ( h.id == this.selectedEvent.data.codeFileHistoryId) { tempHistoryIndex = i; return h;} });
      
                // Since the history call returns ALL versions now, and not the history starting from X, slice it so we don't have to bother with offsets          
                this.codeFile.history = this.codeFile.history.slice(tempHistoryIndex + 1);
                
                var nextVersion = this.codeFile && this.codeFile.history[0] ? this.codeFile.history[0].currenthash : null;
    
                this.hash1 = fileHash;
                this.hash2 = nextVersion;
      
                forkJoin(
                  this.loadCodeFiles(fileHash, nextVersion),
                  this.loadCodeFile(fileHash),
                  this.dataExportService.getAnalysisFile(fileHash + ".simpleAnalysis").pipe(catchError(error=>of(error)))
                ).subscribe( results => {
      
                  this.latestFileContent = results[0].toString();
                  this.updatePrettyDiff(results[0]);
      
                  this.codeFileContent = results[1].toString();
                  
                  var analysis = results[2] ? JSON.parse(results[2].toString()).basicAnalysisResults : [];
                  
                  this.analysisInfo = analysis;
                  this.analysisFeatures = this.analysisService.processAnalysis(analysis);            
                  this.linesMarked = false;
                },
                (error)=>{
                  console.log(error);
                  this.outputDiffHtml = '// Unable to load file...';
                });
              }
            },
            (error)=>{
              console.log("Get history DATA error handler: ")
              console.log(error);
              if (error.code && error.code == 666 ){
                // this.historyDataError = error.message;
              }
          });
        } else {
          console.log("Data ID was not returned...")
        }
      });
    }
    
    this.loadCodeFile(fileHash, this.isNewCodeFileEvent(this.selectedEvent) ? ".raw" : ".beautified").subscribe(
      (fileContent)=>{
        console.log("Setting codeFileContent...");
        this.codeFileContent = fileContent.toString();
        if ( this.isNewCodeFileEvent(this.selectedEvent) ) {
          // this.codeFile.name = this.getFileNameFromEvent(this.selectedEvent);
          this.newCodeFileViewer.codeMirror.setSize("100%", "475px");
          console.log('Refresh new code view...');
          this.newCodeFileViewer.codeMirror.refresh();
          this.analysisFeatures = "Unavailable";
        }
    },
      (error)=>{
        console.log("Error:", error);
        this.codeFileContent = "// Unable to load file...";
    });
  }

  getVendorName(event) {  
    if ( event && event.data && event.data.vendorId ) {
      const vendor = this.vendors.filter((v)=> { return v.id == event.data.vendorId } );
      if ( vendor && vendor[0] && vendor[0].name ){
        event.vendorId = event.data.vendorId;
        event.vendorName = vendor[0].name;
      } else {
        event.vendorId = event.data.vendorId;
        event.vendorName = `Unknown Vendor (${event.vendorId})`;
      }
    }

    return event.vendorName;
  }

  actionHandler(args:any){
    console.log("actionHandler", args);
    if ( args.type == 'actionBegin' && args.columnName == 'vendorId' ){
      // args.cancel = true;
      // this.eventsGrid.refresh();
    } else if ((args.requestType === 'filterchoicerequest') ){      
      args.filterChoiceCount = 20;
      args.filterModel.dialogObj.contentEle.classList.add('e-hide'); 
      args.filterModel.dialogObj.element.querySelector('.e-footer-content').classList.add('e-hide'); 
    }

    // However, we can increase the excel/checkbox filter count by modifying the filterChoiceCount argument value(as per our need) in the 
    // actionBegin event when the requestType is filterchoicerequest or filtersearchbegin. This is demonstrated in the below code snippet,
  }

  actionBegin(event){
    console.log("Action Begin");
    console.log(event);    
  }

  dataBound(event){
    Object.assign((this.eventsGrid.filterModule as any).filterOperators, { startsWith: 'contains' });
  }
}