<div class="row">
    <div class="col-sm-12">
      <div class="card card-table card-border">
        <div class="card-header">
            <div class="tools">              
              <button *ngIf="authService.userHasScopes(['write:admin'])" type="button" class="btn btn-rounded btn-space btn-primary" data-toggle="modal" data-target="#modal-create-edit"><i style="color: white;" class="icon icon-left zmdi zmdi-plus"></i> New</button>
            </div>
          <span class="title">Event Outputs</span>
          <span class="card-subtitle">Create and manage your event outputs below</span>
        </div>
        <div class="card-body">
          <ejs-grid #grid
          [dataSource]='items'
          locale='en-US' 
          [allowPaging]="true" 
          [pageSettings]="pageSettings" 
          [allowSorting]="true" 
          [sortSettings]="sortSettings"
          [allowSelection]="false"
          [enableHover]="false"
          [toolbar]="toolbar"
          [allowResizing]="true"
          (dataBound)="onDataBound()"
          (created)="onGridCreate()"
          (dataStateChange)= 'dataStateChange($event)'
          >
          <e-columns>            
            <e-column [visible]="authService.userHasScopes(['write:admin'])" width="8%" headerText="Actions">
                <ng-template #template let-data>
                  <button class="" (click)="showDeletePrompt(data)"><i class="icon icon-left zmdi zmdi-delete"></i></button>&nbsp;
                  <button class="" (click)="onEditClick(data)"><i class="icon icon-left zmdi zmdi-edit"></i></button>&nbsp;
                  <button class="" (click)="onDuplicateClick(data)"><i class="icon icon-left zmdi zmdi-copy"></i></button>
                </ng-template>
            </e-column>          
            <e-column field='name' headerText='Name' width="40%"></e-column>
            <e-column field='destinationTypeId' headerText='Destination Type' width="52%">
                <ng-template #template let-data>
                    {{destinationIdToName(data.destinationTypeId)}}
                </ng-template>
            </e-column>            
          </e-columns>
        </ejs-grid>
        </div>      
      </div>
    </div>
  </div>
  
  <div class="item-modal modal fade" id="modal-create-edit" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>Output Details</p>
            <form [formGroup]="newItemForm">
              <div class="form-group row pt-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Name:</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input class="form-control" type="text" formControlName="name">
                </div>
              </div>
              <div class="form-group row pt-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Event Type:</label>
                <div class="row col-8">
                    <div class="col-12">
                        <select class="form-control eventType" formControlName="destinationTypeId" (change)="onTypeChange($event)">
                            <option *ngFor="let destination of destinations;" [ngValue]="destination.id">{{destination.name}}</option>
                        </select>
                    </div>     
                </div>
              </div>
              <div *ngIf="selectedType != 1 && selectedType != 3">
                {{todoMessage}}
              </div>
              <div *ngIf="selectedType == 1">
              <div formArrayName="recipients" *ngFor="let recipient of emailRecipients.controls; let i=index">
                <div [formGroupName]="i">
                  <div class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right">Name:</label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input class="form-control" type="text" formControlName="name">
                    </div>
                  </div>
                  <div class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right">Email Address:</label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input class="form-control" type="text" formControlName="email">
                    </div>
                  </div>
                  <div class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right">Time to Send:</label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input class="form-control" type="text" formControlName="timeToSend">
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="form-group row pt-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <a style="cursor: pointer;" (click)="addEmailFormControl()">+ Add Recipient</a>
                </div>
              </div>
              </div>

              <div formArrayName="webhook" *ngIf="selectedType == 3">
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Url:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="url">
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Data:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="data">
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">HTTP Method:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="method">
                  </div>
                </div>                                  
              </div>
            </form>
            <div class="mt-8">
              <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
              <button class="btn btn-rounded btn-space btn-success" type="button" (click)="create();" [disabled]="newItemForm.invalid">Save</button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="mod-confirm-delete" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
            <h3>Warning!</h3>
            <p>Are you sure you want to delete "{{selectedItem?.name}}"? You cannot undo this action!</p>
            <div class="mt-8">
              <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
              <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="onDeleteClick();">Proceed</button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
  