<div *ngIf="isFetched; else fetcherror">
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-lg-6"></div>
    <div class="col-lg-2">
      <simple-dropdown [datasource]="groupMapping" (sendEvent)="updateReportGroupId($event)"
        [backtrackValue]="backtrackGroupId"> </simple-dropdown>
    </div>
    <div class="col-lg-2">
      <div class='card'
        style="padding: auto;margin:0; height: 35px; color: lightgrey; vertical-align: middle; border: 0.5px grey solid;"
        [hidden]="reportType!=='Global'"> </div>
      <simple-dropdown id="siteDropdown" [hidden]="reportType==='Global'" [datasource]="siteMapping"
        (sendEvent)="updateReportSiteId($event)" [backtrackValue]="backtrackSiteId"> </simple-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-lg-2" style="display: flex; flex-direction: row; justify-content: center;  max-height: 150px;">
      <div style="object-fit: scale-down; width: 80%; height: 100%;">
        <img *ngIf="logo" [src]="logo" class="report-logo" alt="Customer Logo"/>
      </div>
    </div>
    <div class="col-lg-4">
      <h1>{{reportType}}: <span style="color: grey;">{{labels[reportTitleDetails] || reportTitleDetails}}</span></h1>
      <h5 *ngIf="showMeta">Report Generated On {{configData.reportGenerated}}
        <br />Generated using data from {{configData.reportingStart}} to {{configData.reportingEnd}}
      </h5>
    </div>
    <div class="col-lg-4">
      <highlight-value-history title="Key Pages with Violations Risk" [value]="keyPageViolations?.value || 0"
        [change]="keyPageViolations?.change || 0"></highlight-value-history>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-lg-5">
      <condensed-value-display title="Total Pages Monitored" [max]="totalPages.max" [label]="'pages'"
      *ngIf="!DisabledInConfig('page-monitor') else hiddenPanel"
        [monitored]="totalPages.monitored"></condensed-value-display>
    </div>
    <div class="col-lg-5">
      <scan-recency-buckets [datasource]="scanRecency"
      *ngIf="!DisabledInConfig('recency-bucket') else hiddenPanel"></scan-recency-buckets>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-1"></div>

    <div class="col-lg-5" [hidden]="reportType==='Site'">
      <condensed-value-display title="Total Sites Monitored" [max]="totalSites.max" [label]="'sites'"
      *ngIf="!DisabledInConfig('site-monitor') else hiddenPanel"
        [monitored]="totalSites.monitored"></condensed-value-display>
    </div>
    <div class="col-lg-5" [hidden]="reportType==='Site'">
      <vendor-map-panel [data]="violationSummaryByVendor"
      *ngIf="!DisabledInConfig('vendor-map') else hiddenPanel"></vendor-map-panel>
    </div>
    <div class="col-lg-10" [hidden]="reportType!=='Site'">
      <vendor-map-panel [data]="violationSummaryByVendor" [length]="10"
      *ngIf="!DisabledInConfig('vendor-map') else hiddenPanel"></vendor-map-panel>
    </div>

  </div>

  <div class="row" *ngIf="isLoaded; else loading">
    <div class="col-sm-1"></div>

    <div class="col-lg-10">
      <basic-violation-risk-summary-panel [data]="violationSummaryByRisk"
        *ngIf="!DisabledInConfig('violation-summary') else hiddenPanel"></basic-violation-risk-summary-panel>
    </div>
  </div>

  <div class="row" *ngIf="isLoaded; else loading">
    <div class="col-sm-1"></div>
    <div class="col-lg-10" *ngIf="reportType==='Global'">
      <geolocation-map-panel [datasource]="violationSummaryByRegion"
        *ngIf="!DisabledInConfig('geolocation-map') else hiddenPanel"> </geolocation-map-panel>
    </div>
  </div>

  <div class="row" *ngIf="isLoaded; else loading">
    <div class="col-sm-1"></div>
    <div class="col-lg-10" *ngIf="reportType==='Global'">
      <advanced-violation-risk-summary-panel [datasource]="violationDetailedSummaryByRisk" [labelmapping]="labels"
        *ngIf="!DisabledInConfig('advanced-summary') else hiddenPanel"></advanced-violation-risk-summary-panel>
    </div>
  </div>
  <ng-template #loading>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-lg-10" *ngIf="reportType==='Global'">
        <p>LOADING....</p>
      </div>
    </div>
  </ng-template>

  <div class="row" *ngIf="isLoaded; else loading">
    <div class="col-sm-1"></div>

    <div class="col-lg-10" *ngIf="reportType==='Global'">
      <vendor-summary-panel [data]="violationSummaryByVendor"
        *ngIf="!DisabledInConfig('vendor-summary') else hiddenPanel"></vendor-summary-panel>
    </div>
  </div>

  <div class="row" *ngIf="isLoaded; else loading">
    <div class="col-sm-1"></div>

    <div class="col-lg-10" *ngIf="reportType==='Group'">
      <group-summary-panel [data]="violationSummaryBySite"> </group-summary-panel>
    </div>
  </div>

  <div class="row" *ngIf="isLoaded; else loading">
    <div class="col-sm-1"></div>

    <div class="col-lg-10" *ngIf="reportType==='Site'">
      <site-summary-panel [datasource]="pagesData"></site-summary-panel>
    </div>
  </div>
</div>
<ng-template #fetcherror>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-lg-10" *ngIf="reportType==='Global'">
      <h1> DATA UNAVAILABLE</h1>
    </div>
  </div>
</ng-template>

<ng-template #hiddenPanel>
  <div class="card"
    style="padding:10px; margin-left: auto; margin-right: auto; height: 195px; background-color: lightgrey;">
    <div class="card-body"
      style="text-align: center; font-size: large; color: grey; height: 100%; display: flex; flex-direction: row; justify-content: center; text-shadow: 0px 0px 3px 5px black; align-items: center; gap:10px">
      <span class="zmdi zmdi-block"> </span> PANEL DISABLED
    </div>
  </div>
</ng-template>