<div class="control-section">
    <ejs-grid #grid 
        id='vendorsGrid' 
        (dataBound)="dataBound()"        
        [dataSource]='data'                 
        [enableHover]='false' 
        [allowSelection]='false'
        rowHeight='120'
    >
        <ng-template #detailTemplate let-data>            
        
            <ejs-chart style='display:block;' 
                [chartArea]='chartArea' 
                [width]='width'
                id=chart{{data.id}} 
                align='center'                 
                [primaryXAxis]='primaryXAxis' 
                [primaryYAxis]='primaryYAxis'
                [title]='title' 
                [tooltip]='tooltip' 
                (pointRender)='pointRender($event)' 
                [legendSettings]='legend' 
                (load)='load($event)'>
                <e-series-collection>
                    <e-series                         
                        [minRadius]='minRadius' 
                        [maxRadius]='maxRadius' 
                        type='Bubble' 
                        xName='x' 
                        yName='y' 
                        size='size' 
                        name='Events' 
                        [marker]='marker'> 
                    </e-series>
                </e-series-collection>
            </ejs-chart>


            <h4>Validations</h4>        
            <table class="detailtable" width="100%">
                <tbody>                    
                    <tr *ngFor="let valDetail of data.detail.validations">
                        <td><a href="#">{{valDetail.name}}</a></td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
        <e-columns>
            <e-column field='vendorName' headerText='Vendor Name'>
                <ng-template #template let-data>
                    <img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.vendorIcon }}.ico"/>{{data.vendorName}}
                </ng-template>
            </e-column>
            <e-column field='lastConfigUpdate' headerText='Latest Config Change' type='datetime'></e-column>
            <e-column headerText='Presence'>
                <ng-template #template let-data>
                    <table style="width: 100%;">
                        <tr>
                            <td>{{computeVendorPresence(data)}}</td>
                            <!-- <td style="width: 99%;"><div id="spklinePresence{{data.id}}"></div></td> -->
                            <td>
                                <ejs-sparkline 
                                id="presenceSPL{{data.id}}"
                                height="50px"
                                width="150px"
                                lineWidth=2
                                valueType="Category"
                                fill="#3C78EF"
                                negativePointColor="red"
                                format='n'
                                (load)="sparklineLoad($event)"
                                [tooltipSettings]="tooltipSettings"
                                xName="timestamp" 
                                yName="value" 
                                >
                            </ejs-sparkline>            
                            </td>
                        </tr>
                    </table>
                  </ng-template>
            </e-column>
            <e-column headerText='Validation Status'>
                <ng-template #template let-data>
                    <table style="width: 100%;">
                        <tr>
                            <td>{{computeValidationStatus(data)}}</td>
                            <!-- <td style="width: 99%;"><div id="spklineStatus{{data.id}}"></div></td> -->
                            <td>
                                <ejs-sparkline 
                                    id="statusSPL{{data.id}}"
                                    height="50px"
                                    width="150px"
                                    lineWidth=2
                                    valueType="Category"
                                    fill="#3C78EF"
                                    negativePointColor="red"
                                    (load)="sparklineLoad($event)"
                                    [tooltipSettings]="tooltipSettings2"
                                    xName="timestamp" 
                                    yName="testsPassing" 
                                    >
                                </ejs-sparkline>            
                            </td>
                        </tr>
                    </table>            
                  </ng-template>
            </e-column>            
        </e-columns>
    </ejs-grid>
</div>