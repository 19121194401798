import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { VaultBaseService } from '../VaultBaseService';
import { AuthService } from '../auth/auth.service';
import { TimezoneService } from '../timezone.service';
import { map } from 'rxjs/operators';

const SDK_LIB = require('../../../assets/mobile/trackers.json');

@Injectable({
  providedIn: 'root'
})
export class MobileService extends VaultBaseService {

  constructor(authHttp: HttpClient,
    public timezoneService: TimezoneService,
    public authService: AuthService
  ) {
    super(authHttp);
  }

  getAllSDKs(): Observable<any> {
    console.log(SDK_LIB);
    return of(SDK_LIB);
  }

  getAppRuns(name, version): Observable<any> {
    return of([
      {
        "lastCheckedFormatted": this.timezoneService.format(1728349089665, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726558703991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726472303991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726385903991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726299503991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726213103991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726126703991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
      {
        "lastCheckedFormatted": this.timezoneService.format(1726040303991, false, true),
        "regionLabel": "US East (N. Virginia)",
        "appRunnerType": "Android 15 (Device - Rooted)"
      },
    ].map((item, index)=>{
      return {...item, lastCheckedFormatted: this.timezoneService.format(Date.now()-(1000 * 60 * 60 * 24 * index), false, true), ts: 1728337421997}
    }));
  }

  getAndroidApps(customerId, appName: string = null): Observable<any> {
    if (customerId == 109) {
      return this.getFidelityApps(appName);
    } else if (customerId == 11) {
      return this.getDeltaApps(appName);
    }
    return this.getAndroidAppsDemo();
  }

  getDeltaApps(appName): Observable<any> {
    let apps = [
      {
        name: "Fly Delta",
        package: "com.delta.mobile.android",
        pii: [
          "Email Address",
          "First Name",
          "Last Name"
        ],
        domains: [

        ],
        versions: [
          "6.2.2",
          "6.2.1",
          "6.2.0",
          "6.1.9"
        ],
        sdks: [
          {
            "name": "Adobe Experience Cloud",
            "categories": "",
            "url": "https://business.adobe.com",
            "icon": "adobe.com",
            "version": "1.0.0"
          },
          {
            "name": "Dynatrace",
            "categories": "Analytics",
            "url": "https://www.dynatrace.com",
            "icon": "www.dynatrace.com",
            "version": "1.0.0"
          },
          {
            "name": "Google CrashLytics",
            "categories": "Crash reporting",
            "url": "https://firebase.google.com/products/crashlytics/",
            "icon": "crashlytics.com",
            "version": "1.0.0"
          },
          {
            "name": "Google Firebase Analytics",
            "categories": "Analytics",
            "url": "https://firebase.google.com",
            "icon": "firebase.google.com",
            "version": "1.0.0"
          },
          {
            "name": "Qualtrics",
            "categories": "",
            "url": "https://www.qualtrics.com",
            "icon": "qualtrics.com",
            "version": "1.0.0"
          },
          {
            "name": "Atrius (LocusLabs)",
            "categories": "",
            "url": "https://atrius.com/welcome-locuslabs/",
            "icon": "atrius.com",
            "version": "1.0.0"
          },
          {
            "name": "flybuy (Radius Networks)",
            "categories": "Analytics",
            "url": "https://www.flybuy.com",
            "icon": "flybuy.com",
            "version": "1.0.0"
          }
        ]
      },
    ].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
    if (appName) {
      apps = apps.filter((app) => {
        return app.name == appName
      })
    }
    return of(apps);
  }

  getFidelityApps(appName): Observable<any> {
    let apps = [
      {
        name: "Fidelity Investments",
        package: "com.fidelity.android",
        pii: [
          "Email Address",
          "First Name",
          "Last Name"
        ],
        domains: [

        ],
        versions: [
          "3.99",
          "3.98",
          "3.97"
        ],
        sdks: [
          {
            "name": "Adobe Experience Cloud",
            "categories": "",
            "url": "https://business.adobe.com",
            "icon": "adobe.com",
            "version": "1.0.0"
          },
          {
            "name": "Branch.io",
            "categories": "Analytics",
            "url": "https://www.branch.io",
            "icon": "branch.io",
            "version": "1.0.0"
          },
          {
            "name": "Google CrashLytics",
            "categories": "Crash reporting",
            "url": "https://firebase.google.com/products/crashlytics/",
            "icon": "crashlytics.com",
            "version": "1.0.0"
          },
          {
            "name": "Google Firebase Analytics",
            "categories": "Analytics",
            "url": "https://firebase.google.com",
            "icon": "firebase.google.com",
            "version": "1.0.0"
          },
          {
            "name": "Qualtrics",
            "categories": "",
            "url": "https://www.qualtrics.com",
            "icon": "qualtrics.com",
            "version": "1.0.0"
          },
          {
            "name": "LaunchDarkly",
            "categories": "",
            "url": "https://www.launchdarkly.com",
            "icon": "launchdarkly.com",
            "version": "1.0.0"
          }
        ]
      },
      {
        name: "NetBenefits - Fidelity at Work",
        package: "com.fidelity.wi.activity",
        pii: [
          "Email Address",
          "First Name",
          "Last Name"
        ],
        domains: [

        ],
        versions: [
          "4.15.2"
        ],
        sdks: [

        ]
      },
      {
        name: "Fidelity Health®",
        package: "com.fidelity.hcg.health",
        pii: [
          "Email Address",
          "First Name",
          "Last Name"
        ],
        domains: [

        ],
        versions: [
          "2.11.29"
        ],
        sdks: [
          {
            "name": "Adobe Experience Cloud",
            "categories": "",
            "url": "https://business.adobe.com",
            "icon": "adobe.com",
            "version": "1.0.0"
          },
          {
            "name": "Google CrashLytics",
            "categories": "Crash reporting",
            "url": "https://firebase.google.com/products/crashlytics/",
            "icon": "crashlytics.com",
            "version": "1.0.0"
          },
          {
            "name": "Google Firebase Analytics",
            "categories": "Analytics",
            "url": "https://firebase.google.com",
            "icon": "firebase.google.com",
            "version": "1.0.0"
          },
        ]
      },
      {
        name: "Fidelity Youth®",
        package: "com.fidelity.teen",
        pii: [
          "Email Address",
          "First Name",
          "Last Name"
        ],
        domains: [

        ],
        versions: [
          "1.24"
        ],
        sdks: [
          {
            "name": "Apteligent by VMWare (formerly Crittercism)",
            "categories": "",
            "url": "https://www.omnissa.com/workspace-one-intelligence/",
            "icon": "omnissa.com",
            "version": "1.0.0"
          },
          {
            "name": "Adobe Experience Cloud",
            "categories": "",
            "url": "https://business.adobe.com",
            "icon": "adobe.com",
            "version": "1.0.0"
          },
          {
            "name": "Branch.io",
            "categories": "Analytics",
            "url": "https://www.branch.io",
            "icon": "branch.io",
            "version": "1.0.0"
          },
          {
            "name": "Google CrashLytics",
            "categories": "Crash reporting",
            "url": "https://firebase.google.com/products/crashlytics/",
            "icon": "crashlytics.com",
            "version": "1.0.0"
          },
          {
            "name": "Google Firebase Analytics",
            "categories": "Analytics",
            "url": "https://firebase.google.com",
            "icon": "firebase.google.com",
            "version": "1.0.0"
          },
          {
            "name": "Qualtrics",
            "categories": "",
            "url": "https://www.qualtrics.com",
            "icon": "qualtrics.com",
            "version": "1.0.0"
          }
        ]
      },
      {
        name: "Fidelity Charitable",
        package: "com.fidelity.charitable",
        pii: [
          "Email Address",
          "First Name",
          "Last Name"
        ],
        domains: [

        ],
        versions: [
          "5.3"
        ],
        sdks: [

        ]
      }
    ].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
    if (appName) {
      apps = apps.filter((app) => {
        return app.name == appName
      })
    }
    return of(apps);
  }

  getAndroidAppsDemo(): Observable<any> {
    return of([
      {
        name: "NBC",
        package: "com.nbcuni.nbc",
        versions: [
          "9.12.1",
          "9.12.0",
          "9.11.0",
          "8.1.3",
        ]
      },
      {
        name: "Telemundo",
        package: "com.telemundo.awe",
        versions: [
          "9.12.2",
          "9.12.1"
        ]
      },
      {
        name: "Universal FL",
        package: "com.universalstudios.orlandoresort",
        versions: [
          "6.5.0",
          "6.4.0",
          "6.3.9"
        ]
      },
      {
        name: "Bravo",
        package: "com.nbcu.tve.bravo",
        versions: [
          "9.12.2",
          "9.12.1"
        ]
      },
      {
        name: "E",
        package: "com.nbcu.tve.e",
        versions: [
          "9.12.2"
        ]
      },
      {
        name: "Dinotracker AR",
        package: "com.nbcuni.universal.jurassicworld",
        versions: [
          "1.1",
          "1.0"
        ]
      },
      {
        name: "Universal Kids",
        package: "com.sproutonline.now",
        versions: [
          "3.4.3.143",
          "3.4.3.142"
        ]
      },
      // {
      //   name: "NBC Chicago",
      //   package: "com.nbcuni.nbcots.nbcchicago.android",
      //   versions:[
      //     "7.13",
      //     "7.12"
      //   ]
      // },
    ].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    }))
  }

  getTimeline(ts=1728337421997) {
    return this.authHttp.get(`/assets/mobile/runs/${ts}/timeline.json`);
  }

  getAppRunRequest(reqId, ts = "1728337421997"): Observable<any> {
    return this.authHttp.get(`/assets/mobile/runs/${ts}/request/${reqId}.json`).pipe(map((obj: any) => {

      let parsedBody;
      if (obj.method == 'POST') {
        parsedBody = decodeURI(decodeURI(obj.body));
      }

      return {
        ...obj,
        headers: Object.entries(obj.headers).map(([key, value]) => ({ key, value })),
        body: parsedBody
      }
    }))
  }

  getAppRunScreenRecording(ts): Observable<any> {
    return this.authHttp.get(`/assets/mobile/runs/${ts}/screenrecording/screen.mp4`);
  }

  getAppRunsS3(packageName, version): Observable<any> {
    // var that = this;
    // var retPromise = new Promise(function (resolve) {
    //   that.authService.getAwsCreds().subscribe((creds) => {
    //     AWS.config.region = env.aws.region;
    //     AWS.config.credentials = new AWS.Credentials(creds);

    //     var params = {
    //       Bucket: env.aws.pageRunnerBucket,
    //       Prefix: `mobile/${packageName}/${version}/`
    //     };
    //     const s3 = new S3();
    //     from(s3.listObjectsV2(params).promise())
    //       .pipe(catchError((error) => of(error)))
    //       .subscribe((response) => {
    //         resolve(
    //           response.Contents
    //         );
    //       });
    //   });
    // });
    // return from(retPromise);
    return null;
  }

}

// const s3 = new S3();
//         from(s3.getObject(params).promise()).pipe(catchError(error => of(error))).subscribe((response) => {
//           // console.log("XXXX", response);
//           if (response && response.Body) {
//             resolve(response.Body);
//           } else {
//             reject(response);
//           }
//         });
//       });

// var params = {
//   Bucket: env.exportAWS.bucket,
//   Key: key,
//   ResponseContentDisposition: `attachment; filename=${filename}`,
// };

// const s3 = new AWS.S3();

// // Check if the file exists in the bucket using headObject
// s3.headObject({ Bucket: env.exportAWS.bucket, Key: key }, function (err, data) {