import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-group-panel',
  templateUrl: './site-group-panel.component.html',
  styleUrls: ['./site-group-panel.component.css']
})
export class SiteGroupPanelComponent implements OnInit, OnChanges {

  @Input('dataSource') dataSource: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.dataSource !== undefined) {
      this.updateData();
    }
  }

  updateData(): void {
  }

}
