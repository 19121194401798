import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridModel } from '@syncfusion/ej2-angular-grids';
import { VarPagesGridData } from '../../dashboardDS';

@Component({
  selector: 'app-adobe-var-pages-panel',
  templateUrl: './adobe-var-pages-panel.component.html',
  styleUrls: ['./adobe-var-pages-panel.component.css']
})
export class AdobeVarPagesPanelComponent implements OnInit, OnChanges {
  @Input('data') gridData: VarPagesGridData;

  public nestedGrid: GridModel = {
    queryString: 'page',
    columns: [
      { field: 'type', headerText: 'Type' },
      { field: 'name', headerText: 'Name' },
      { field: 'value', headerText: 'Value' },
      { field: 'passed', headerText: 'Passed', template: '<div>${passed}</div>' },
      { field: 'rulesFailed', headerText: 'Rules Failed' },
    ],
    allowResizing: true,
  };
  public childGrid: GridModel = {
    queryString: 'page',
    childGrid: this.nestedGrid,
    columns: [
      // { field: 'page', headerText: 'Page', visible: false },
      // { headerText: 'Variables', textAlign: 'Left', width: 100 },
      { field: 'group', headerText: 'Group', textAlign: 'Left', width: 75 },
      { field: 'eVarsFound', headerText: 'eVar Found', },
      { field: 'eVarErrs', headerText: 'eVar Errors', },
      { field: 'propsFound', headerText: 'Prop Found', },
      { field: 'propErrs', headerText: 'Prop Errors', },
      { field: 'adobeLaunch', headerText: 'Adobe Launch', },
      { field: 'lastScanned', headerText: 'Last Scanned', },
      { field: 'scenario', headerText: 'Scenario', },

    ],
  };
  @ViewChild('grid')
  public grid?: GridComponent;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.updateData();
  }

  expand(): void {
    (this.grid as GridComponent).detailRowModule.expandAll();
  }

  collapse(): void {
    (this.grid as GridComponent).detailRowModule.collapseAll();
  }

  updateData(): void {
    if (!this.gridData)
      return;
    this.childGrid.dataSource = this.gridData.pageRowData;
    this.nestedGrid.dataSource = this.gridData.varData;
    if (this.grid)
      (this.grid as GridComponent).refresh();
  }

}
