import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UsersComponent } from './users.component';
import { UserDetailsComponent } from './details/user-details/user-details.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule
  ],
  declarations: [
    UsersComponent,
    UserDetailsComponent
  ],
  exports: [
    UsersComponent,
    UserDetailsComponent
  ],
  providers:[
    PageService,
    SortService,
    FilterService,
    GroupService
  ]
})
export class UserComponentsModule { }
