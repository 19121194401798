<div class="report-container">
  <div class="row" style="margin-bottom: 25px;">
    <!-- <div class="col-lg-2" style="display: flex; justify-content: center; align-items: center;">
      <img src="assets/svg/tracker.svg" alt="Tracker Report Logo" height="125px">
    </div> -->
    <div class="col-lg-2" style="display: flex; flex-direction: row; justify-content: center;">
      <img src="assets/svg/violation.svg" alt="Tracker Report Logo" height="125px">
    </div>
    <div class="col-lg-7">
      <div class="report-title">CMP & 3<sup>rd</sup> Party Technology Analysis</div>
      <div class="report-config-name">
        {{selectedConfig.name}}
      </div>
      <div class="report-meta">
        <div>{{selectedConfig.region}}</div>
        <div>{{selectedConfig.consent
          }}</div>

      </div>
    </div>
    <div class="col-lg-3">
      <ejs-dropdownlist width="100%" [dataSource]="configSelectionList" placeholder="Select a config"
        (select)="onConfigSelect($event)" [fields]="configSelectionListFields"></ejs-dropdownlist>
    </div>
    <!-- <div class="col-lg-3">
      <ejs-dropdownlist width="100%" [dataSource]="[]" placeholder="Select a page"></ejs-dropdownlist>
    </div> -->
  </div>
  <div class="row">

    <div class="col-lg-4">
      <app-title-card [isLoading]="isLoading" height="250px" [tooltipContent]="descriptions.cmpDetection">
        <ng-container header>
          <span class=" title-icon"><img src="assets/svg/privacy.svg" height="20px"></span>
          CMP Tool Detection
        </ng-container>
        <div class="flex-row" style="font-size:large;" *ngIf="cmpDetectionData?.length > 0 else noInfo">
          <multi-pie-chart [datasource]="cmpDetectionData" id="cmpDetection"></multi-pie-chart>
        </div>
      </app-title-card>
    </div>
    <div class="col-lg-4">
      <app-title-card [isLoading]="isLoading" height="250px" [tooltipContent]="descriptions.thirdPartyTech">
        <ng-container header>
          <span class="zmdi zmdi-dns title-icon"></span>
          3rd Party Technology
        </ng-container>
        <div class="flex-column" style="align-items:flex-start;" *ngIf="cmpDetectionData?.length > 0 else noInfo">

          <span class="label">Average 3rd Party Cookies Per Page</span>
          <span class="number-display">{{avgCookies}} <span class="label">cookies</span></span>



          <span class="label">Average 3rd Party Requests Per Page</span>
          <span class="number-display">{{avgRequests}} <span class="label">requests</span></span>


        </div>
      </app-title-card>
    </div>
    <div class="col-lg-4">
      <app-title-card [isLoading]="isLoading" height="250px" [tooltipContent]="descriptions.scanRecency">
        <ng-container header>
          <span class="zmdi zmdi-chart-donut title-icon"></span>
          Scan recency
        </ng-container>
        <div class="flex-row" style="font-size:large;" *ngIf="cmpDetectionData?.length > 0 else noInfo">
          <multi-pie-chart [datasource]="recencydata" id="recencyChart"></multi-pie-chart>
        </div>
      </app-title-card>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12" style="border: 0px solid #fc7d73;">
      <app-title-card [isLoading]="isLoading" height="500px" [tooltipContent]="descriptions.scannedPages">
        <ng-container header>
          <span class="zmdi zmdi-collection-item title-icon"></span>
          Scanned Pages
        </ng-container>
        <ejs-grid height="100%" width="100%" [dataSource]="pageList" (rowSelected)="onPageSelect($event)" [allowPaging]="true"
          [allowResizing]="true" [allowFiltering]="true" [allowSorting]="true" [filterSettings]="{type:'Excel'}">
          <e-columns>
            <e-column field="url" headerText="URL" textAlign="Left"></e-column>
            <e-column field="cmpDetected" headerText="CMP Detected" width="150" textAlign="Center">
              <ng-template #template let-data>
                <span *ngIf="data.cmpDetected else noDetected" class="zmdi zmdi-check-circle"
                  style="color: green; font-size: large;"></span>
              </ng-template>
              <ng-template #noDetected>
                <span class="zmdi zmdi-close-circle" style="color: red; font-size: large;"></span>
              </ng-template>
            </e-column>
            <e-column field="requestViolationCount" headerText="Request Count" width="150" textAlign="Center"></e-column>
            <e-column field="cookieViolationCount" headerText="Cookie Count" width="150" textAlign="Center"></e-column>
          </e-columns>
        </ejs-grid>


      </app-title-card>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6" style="border: 0px solid #fc7d73;">
      <app-title-card [isLoading]="isLoading" height="400px" [tooltipContent]="descriptions.thirdPartyRequests">
        <ng-container header>
          <span class="title-icon"><img src="assets/svg/code-tracker.svg" height="20px"></span>
          3rd Party Request
        </ng-container>
        <ejs-grid height="100%" [dataSource]="gridRequests" [allowSorting]="true" [sortSettings]="{columns:[{
          field: 'requestCount', direction: 'Descending'
        }]}">
          <e-columns>
            <e-column field="hostname" headerText="Hostname" textAlign="Left" [autoFit]="true" maxWidth="250px"
              clipMode="EllipsisWithTooltip">

            </e-column>
            <e-column field="requestCount" headerText="Request" textAlign="Left">
              <ng-template #template let-data>
                <div style="display: flex; gap: 10px; align-items: center;">
                  <span style="min-width: 50px;; text-align: right">{{data.requestCount}}</span>
                  <div
                    style="height: 10px; flex-grow:1; background-color: lightgrey; position: relative; overflow: hidden; border-radius: .5rem;">
                    <div style="position: absolute; height: 100%; background-color: #6390ee;"
                      [style.width]="data.percent"></div>
                  </div>
                  <span style="min-width: 75px;">{{data.percent}}</span>
                </div>

              </ng-template>
            </e-column>

          </e-columns>
          <!-- <ng-template #groupSettingsCaptionTemplate let-data>
            <span style="font-weight: 600; font-size: medium;"> {{ data.key || 'No Hostname' }} </span>
            <span style="font-weight: 400; font-size: small; color:grey"> - {{ data.count }} Request{{data.count !== 1
              ?'s' :''}} </span>
          </ng-template> -->
        </ejs-grid>
      </app-title-card>
    </div>
    <div class="col-lg-6">
      <app-title-card [isLoading]="isLoading" height="400px" [tooltipContent]="descriptions.thirdPartyCookies">
        <ng-container header>
          <span class="title-icon"> <img src="assets/svg/cookie-tracker.svg" height="20px"></span>
          3rd Party Cookies
        </ng-container>
        <ejs-grid height="100%" [dataSource]="gridCookies" [allowResizing]="true" width="100%" [allowSorting]="true" [sortSettings]="{columns:[{
          field: 'cookieCount', direction: 'Descending'
        }]}">
          <e-columns>
            <e-column field="hostname" headerText="Hostname" textAlign="Left" maxWidth="250px"
              clipMode="EllipsisWithTooltip"></e-column>
            <e-column field="cookieCount" headerText="Name" maxWidth="250px" textAlign="Left">
              <ng-template #template let-data>
                <div style="display: flex; gap: 10px; align-items: center;">
                  <span style="min-width: 50px; text-align: right">{{data.cookieCount}}</span>
                  <div
                    style="height: 10px; flex-grow:1; background-color: lightgrey; position: relative; overflow: hidden; border-radius: .5rem;">
                    <div style="position: absolute; height: 100%; background-color: #6390ee;"
                      [style.width]="data.percent"></div>
                  </div>
                  <span style="min-width: 75px;">{{data.percent}}</span>
                </div>

              </ng-template>
            </e-column>
          </e-columns>
          <!-- <ng-template #groupSettingsCaptionTemplate let-data>
            <span style="font-weight: 600; font-size: medium;"> {{ data.key }} </span>
            <span style="font-weight: 400; font-size: small; color:grey"> - {{ data.count }} Cookie{{data.count !== 1 ?'s'
              :''}} </span>
          </ng-template> -->
        </ejs-grid>
      </app-title-card>
    </div>

  </div>

  <ng-template #noInfo>
    <div class="no-info">Select Configuration to Load Data</div>
  </ng-template>
</div>
