import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { environment as env } from "../../../../../environments/environment";
import { VendorSummaryData } from '../../dashboardDS';

@Component({
  selector: 'vendor-summary-panel',
  templateUrl: './vendor-summary-panel.component.html',
  styleUrls: ['./vendor-summary-panel.component.css'],
})
export class VendorSummaryPanelComponent implements OnInit,OnChanges {
  public cdnUrl: string;
  @Input ('data') inputData: VendorSummaryData[] = [];
  // public inputData: any = [
  //   {
  //     "vendorName": "Pubmatic",
  //     "vendorId": 0,
  //     "vendorDomain": "pubmatic.com",
  //     "violationCount": 4603,
  //     "violationShare": 16.241487597473625,
  //     "pageCount": 118,
  //     "pageShare": 28.780487804878046,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Id5-sync",
  //     "vendorId": 0,
  //     "vendorDomain": "id5-sync.com",
  //     "violationCount": 1004,
  //     "violationShare": 3.5425708337743904,
  //     "pageCount": 77,
  //     "pageShare": 18.78048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Doubleclick",
  //     "vendorId": 0,
  //     "vendorDomain": "doubleclick.net",
  //     "violationCount": 924,
  //     "violationShare": 3.26029427331428,
  //     "pageCount": 276,
  //     "pageShare": 67.31707317073172,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Tapad",
  //     "vendorId": 0,
  //     "vendorDomain": "tapad.com",
  //     "violationCount": 848,
  //     "violationShare": 2.9921315408771743,
  //     "pageCount": 116,
  //     "pageShare": 28.292682926829265,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Smartadserver",
  //     "vendorId": 0,
  //     "vendorDomain": "smartadserver.com",
  //     "violationCount": 658,
  //     "violationShare": 2.321724709784411,
  //     "pageCount": 119,
  //     "pageShare": 29.024390243902438,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Rfihub",
  //     "vendorId": 0,
  //     "vendorDomain": "rfihub.com",
  //     "violationCount": 649,
  //     "violationShare": 2.289968596732649,
  //     "pageCount": 74,
  //     "pageShare": 18.048780487804876,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Casalemedia",
  //     "vendorId": 0,
  //     "vendorDomain": "casalemedia.com",
  //     "violationCount": 579,
  //     "violationShare": 2.042976606330052,
  //     "pageCount": 118,
  //     "pageShare": 28.780487804878046,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Audrte",
  //     "vendorId": 0,
  //     "vendorDomain": "audrte.com",
  //     "violationCount": 549,
  //     "violationShare": 1.9371228961575102,
  //     "pageCount": 81,
  //     "pageShare": 19.75609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Contextweb",
  //     "vendorId": 0,
  //     "vendorDomain": "contextweb.com",
  //     "violationCount": 543,
  //     "violationShare": 1.9159521541230022,
  //     "pageCount": 71,
  //     "pageShare": 17.317073170731707,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adsrvr",
  //     "vendorId": 0,
  //     "vendorDomain": "adsrvr.org",
  //     "violationCount": 532,
  //     "violationShare": 1.8771391270597368,
  //     "pageCount": 122,
  //     "pageShare": 29.756097560975608,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Yahoo",
  //     "vendorId": 0,
  //     "vendorDomain": "yahoo.com",
  //     "violationCount": 530,
  //     "violationShare": 1.870082213048234,
  //     "pageCount": 123,
  //     "pageShare": 30,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Nr-data",
  //     "vendorId": 0,
  //     "vendorDomain": "nr-data.net",
  //     "violationCount": 521,
  //     "violationShare": 1.8383260999964717,
  //     "pageCount": 176,
  //     "pageShare": 42.926829268292686,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bidswitch",
  //     "vendorId": 0,
  //     "vendorDomain": "bidswitch.net",
  //     "violationCount": 484,
  //     "violationShare": 1.7077731907836704,
  //     "pageCount": 90,
  //     "pageShare": 21.951219512195124,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Technoratimedia",
  //     "vendorId": 0,
  //     "vendorDomain": "technoratimedia.com",
  //     "violationCount": 483,
  //     "violationShare": 1.704244733777919,
  //     "pageCount": 66,
  //     "pageShare": 16.097560975609756,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Linkedin",
  //     "vendorId": 0,
  //     "vendorDomain": "linkedin.com",
  //     "violationCount": 441,
  //     "violationShare": 1.5560495395363607,
  //     "pageCount": 91,
  //     "pageShare": 22.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Amazon-adsystem",
  //     "vendorId": 0,
  //     "vendorDomain": "amazon-adsystem.com",
  //     "violationCount": 428,
  //     "violationShare": 1.5101795984615929,
  //     "pageCount": 73,
  //     "pageShare": 17.804878048780488,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bidr",
  //     "vendorId": 0,
  //     "vendorDomain": "bidr.io",
  //     "violationCount": 422,
  //     "violationShare": 1.4890088564270845,
  //     "pageCount": 71,
  //     "pageShare": 17.317073170731707,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Rubiconproject",
  //     "vendorId": 0,
  //     "vendorDomain": "rubiconproject.com",
  //     "violationCount": 409,
  //     "violationShare": 1.4431389153523164,
  //     "pageCount": 115,
  //     "pageShare": 28.04878048780488,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adform",
  //     "vendorId": 0,
  //     "vendorDomain": "adform.net",
  //     "violationCount": 409,
  //     "violationShare": 1.4431389153523164,
  //     "pageCount": 84,
  //     "pageShare": 20.48780487804878,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adnxs",
  //     "vendorId": 0,
  //     "vendorDomain": "adnxs.com",
  //     "violationCount": 400,
  //     "violationShare": 1.4113828023005541,
  //     "pageCount": 124,
  //     "pageShare": 30.24390243902439,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Mfadsrvr",
  //     "vendorId": 0,
  //     "vendorDomain": "mfadsrvr.com",
  //     "violationCount": 349,
  //     "violationShare": 1.2314314950072334,
  //     "pageCount": 34,
  //     "pageShare": 8.292682926829269,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "W55c",
  //     "vendorId": 0,
  //     "vendorDomain": "w55c.net",
  //     "violationCount": 349,
  //     "violationShare": 1.2314314950072334,
  //     "pageCount": 91,
  //     "pageShare": 22.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Srv",
  //     "vendorId": 0,
  //     "vendorDomain": "sync.srv.stackadapt.com",
  //     "violationCount": 348,
  //     "violationShare": 1.227903038001482,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Stackadapt",
  //     "vendorId": 0,
  //     "vendorDomain": "srv.stackadapt.com",
  //     "violationCount": 348,
  //     "violationShare": 1.227903038001482,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "360yield",
  //     "vendorId": 0,
  //     "vendorDomain": "360yield.com",
  //     "violationCount": 348,
  //     "violationShare": 1.227903038001482,
  //     "pageCount": 72,
  //     "pageShare": 17.560975609756095,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adscale",
  //     "vendorId": 0,
  //     "vendorDomain": "adscale.de",
  //     "violationCount": 345,
  //     "violationShare": 1.2173176669842278,
  //     "pageCount": 80,
  //     "pageShare": 19.51219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Owneriq",
  //     "vendorId": 0,
  //     "vendorDomain": "owneriq.net",
  //     "violationCount": 318,
  //     "violationShare": 1.1220493278289403,
  //     "pageCount": 36,
  //     "pageShare": 8.780487804878048,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Scorecardresearch",
  //     "vendorId": 0,
  //     "vendorDomain": "scorecardresearch.com",
  //     "violationCount": 309,
  //     "violationShare": 1.090293214777178,
  //     "pageCount": 156,
  //     "pageShare": 38.048780487804876,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "3lift",
  //     "vendorId": 0,
  //     "vendorDomain": "3lift.com",
  //     "violationCount": 263,
  //     "violationShare": 0.9279841925126143,
  //     "pageCount": 116,
  //     "pageShare": 28.292682926829265,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Espn",
  //     "vendorId": 0,
  //     "vendorDomain": "dcfdata.espn.com",
  //     "violationCount": 262,
  //     "violationShare": 0.9244557355068628,
  //     "pageCount": 83,
  //     "pageShare": 20.243902439024392,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Lijit",
  //     "vendorId": 0,
  //     "vendorDomain": "lijit.com",
  //     "violationCount": 258,
  //     "violationShare": 0.9103419074838572,
  //     "pageCount": 33,
  //     "pageShare": 8.048780487804878,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Everesttech",
  //     "vendorId": 0,
  //     "vendorDomain": "everesttech.net",
  //     "violationCount": 256,
  //     "violationShare": 0.9032849934723546,
  //     "pageCount": 86,
  //     "pageShare": 20.975609756097562,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Fonts",
  //     "vendorId": 0,
  //     "vendorDomain": "fonts.net",
  //     "violationCount": 251,
  //     "violationShare": 0.8856427084435976,
  //     "pageCount": 65,
  //     "pageShare": 15.853658536585366,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bfmio",
  //     "vendorId": 0,
  //     "vendorDomain": "bfmio.com",
  //     "violationCount": 247,
  //     "violationShare": 0.8715288804205921,
  //     "pageCount": 40,
  //     "pageShare": 9.75609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adgrx",
  //     "vendorId": 0,
  //     "vendorDomain": "adgrx.com",
  //     "violationCount": 241,
  //     "violationShare": 0.8503581383860837,
  //     "pageCount": 39,
  //     "pageShare": 9.512195121951219,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Demdex",
  //     "vendorId": 0,
  //     "vendorDomain": "demdex.net",
  //     "violationCount": 241,
  //     "violationShare": 0.8503581383860837,
  //     "pageCount": 86,
  //     "pageShare": 20.975609756097562,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sitescout",
  //     "vendorId": 0,
  //     "vendorDomain": "sitescout.com",
  //     "violationCount": 233,
  //     "violationShare": 0.8221304823400727,
  //     "pageCount": 43,
  //     "pageShare": 10.487804878048781,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorId": 0,
  //     "violationCount": 225,
  //     "violationShare": 0.7939028262940616,
  //     "pageCount": 45,
  //     "pageShare": 10.975609756097562,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Crwdcntrl",
  //     "vendorId": 0,
  //     "vendorDomain": "crwdcntrl.net",
  //     "violationCount": 223,
  //     "violationShare": 0.7868459122825587,
  //     "pageCount": 94,
  //     "pageShare": 22.926829268292686,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Criteo",
  //     "vendorId": 0,
  //     "vendorDomain": "criteo.com",
  //     "violationCount": 222,
  //     "violationShare": 0.7833174552768074,
  //     "pageCount": 80,
  //     "pageShare": 19.51219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Acuityplatform",
  //     "vendorId": 0,
  //     "vendorDomain": "acuityplatform.com",
  //     "violationCount": 220,
  //     "violationShare": 0.7762605412653046,
  //     "pageCount": 41,
  //     "pageShare": 10,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Gammaplatform",
  //     "vendorId": 0,
  //     "vendorDomain": "gammaplatform.com",
  //     "violationCount": 219,
  //     "violationShare": 0.7727320842595533,
  //     "pageCount": 35,
  //     "pageShare": 8.536585365853659,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Turn",
  //     "vendorId": 0,
  //     "vendorDomain": "turn.com",
  //     "violationCount": 215,
  //     "violationShare": 0.7586182562365478,
  //     "pageCount": 74,
  //     "pageShare": 18.048780487804876,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Liadm",
  //     "vendorId": 0,
  //     "vendorDomain": "i.liadm.com",
  //     "violationCount": 212,
  //     "violationShare": 0.7480328852192936,
  //     "pageCount": 40,
  //     "pageShare": 9.75609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Simpli",
  //     "vendorId": 0,
  //     "vendorDomain": "simpli.fi",
  //     "violationCount": 211,
  //     "violationShare": 0.7445044282135422,
  //     "pageCount": 70,
  //     "pageShare": 17.073170731707318,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Thrtle",
  //     "vendorId": 0,
  //     "vendorDomain": "thrtle.com",
  //     "violationCount": 209,
  //     "violationShare": 0.7374475142020395,
  //     "pageCount": 69,
  //     "pageShare": 16.82926829268293,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Lynx",
  //     "vendorId": 0,
  //     "vendorDomain": "beacon.lynx.cognitivlabs.com",
  //     "violationCount": 208,
  //     "violationShare": 0.733919057196288,
  //     "pageCount": 36,
  //     "pageShare": 8.780487804878048,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Agkn",
  //     "vendorId": 0,
  //     "vendorDomain": "agkn.com",
  //     "violationCount": 201,
  //     "violationShare": 0.7092198581560284,
  //     "pageCount": 90,
  //     "pageShare": 21.951219512195124,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Pippio",
  //     "vendorId": 0,
  //     "vendorDomain": "pippio.com",
  //     "violationCount": 200,
  //     "violationShare": 0.7056914011502771,
  //     "pageCount": 29,
  //     "pageShare": 7.073170731707316,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Taboola",
  //     "vendorId": 0,
  //     "vendorDomain": "taboola.com",
  //     "violationCount": 198,
  //     "violationShare": 0.6986344871387742,
  //     "pageCount": 35,
  //     "pageShare": 8.536585365853659,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Tremorhub",
  //     "vendorId": 0,
  //     "vendorDomain": "tremorhub.com",
  //     "violationCount": 181,
  //     "violationShare": 0.6386507180410007,
  //     "pageCount": 33,
  //     "pageShare": 8.048780487804878,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Imrworldwide",
  //     "vendorId": 0,
  //     "vendorDomain": "imrworldwide.com",
  //     "violationCount": 179,
  //     "violationShare": 0.6315938040294979,
  //     "pageCount": 71,
  //     "pageShare": 17.317073170731707,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Openx",
  //     "vendorId": 0,
  //     "vendorDomain": "openx.net",
  //     "violationCount": 170,
  //     "violationShare": 0.5998376909777354,
  //     "pageCount": 90,
  //     "pageShare": 21.951219512195124,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Quantserve",
  //     "vendorId": 0,
  //     "vendorDomain": "quantserve.com",
  //     "violationCount": 158,
  //     "violationShare": 0.5574962069087188,
  //     "pageCount": 41,
  //     "pageShare": 10,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Smaato",
  //     "vendorId": 0,
  //     "vendorDomain": "smaato.net",
  //     "violationCount": 158,
  //     "violationShare": 0.5574962069087188,
  //     "pageCount": 47,
  //     "pageShare": 11.463414634146343,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Deepintent",
  //     "vendorId": 0,
  //     "vendorDomain": "deepintent.com",
  //     "violationCount": 137,
  //     "violationShare": 0.48339860978793975,
  //     "pageCount": 40,
  //     "pageShare": 9.75609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bluekai",
  //     "vendorId": 0,
  //     "vendorDomain": "bluekai.com",
  //     "violationCount": 135,
  //     "violationShare": 0.476341695776437,
  //     "pageCount": 59,
  //     "pageShare": 14.390243902439023,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "1rx",
  //     "vendorId": 0,
  //     "vendorDomain": "1rx.io",
  //     "violationCount": 116,
  //     "violationShare": 0.4093010126671607,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Kargo",
  //     "vendorId": 0,
  //     "vendorDomain": "kargo.com",
  //     "violationCount": 115,
  //     "violationShare": 0.40577255566140924,
  //     "pageCount": 40,
  //     "pageShare": 9.75609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Unrulymedia",
  //     "vendorId": 0,
  //     "vendorDomain": "targeting.unrulymedia.com",
  //     "violationCount": 115,
  //     "violationShare": 0.40577255566140924,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Mxptint",
  //     "vendorId": 0,
  //     "vendorDomain": "mxptint.net",
  //     "violationCount": 114,
  //     "violationShare": 0.4022440986556579,
  //     "pageCount": 40,
  //     "pageShare": 9.75609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Ipredictive",
  //     "vendorId": 0,
  //     "vendorDomain": "ipredictive.com",
  //     "violationCount": 113,
  //     "violationShare": 0.39871564164990647,
  //     "pageCount": 39,
  //     "pageShare": 9.512195121951219,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Tribalfusion",
  //     "vendorId": 0,
  //     "vendorDomain": "tribalfusion.com",
  //     "violationCount": 111,
  //     "violationShare": 0.3916587276384037,
  //     "pageCount": 41,
  //     "pageShare": 10,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Rlcdn",
  //     "vendorId": 0,
  //     "vendorDomain": "rlcdn.com",
  //     "violationCount": 109,
  //     "violationShare": 0.38460181362690093,
  //     "pageCount": 37,
  //     "pageShare": 9.024390243902438,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adentifi",
  //     "vendorId": 0,
  //     "vendorDomain": "adentifi.com",
  //     "violationCount": 107,
  //     "violationShare": 0.3775448996153982,
  //     "pageCount": 37,
  //     "pageShare": 9.024390243902438,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Inmobi",
  //     "vendorId": 0,
  //     "vendorDomain": "inmobi.com",
  //     "violationCount": 106,
  //     "violationShare": 0.3740164426096468,
  //     "pageCount": 37,
  //     "pageShare": 9.024390243902438,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Appier",
  //     "vendorId": 0,
  //     "vendorDomain": "c.appier.net",
  //     "violationCount": 105,
  //     "violationShare": 0.3704879856038954,
  //     "pageCount": 35,
  //     "pageShare": 8.536585365853659,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Playground",
  //     "vendorId": 0,
  //     "vendorDomain": "ads.playground.xyz",
  //     "violationCount": 104,
  //     "violationShare": 0.366959528598144,
  //     "pageCount": 35,
  //     "pageShare": 8.536585365853659,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Narrative",
  //     "vendorId": 0,
  //     "vendorDomain": "io.narrative.io",
  //     "violationCount": 101,
  //     "violationShare": 0.35637415758088986,
  //     "pageCount": 35,
  //     "pageShare": 8.536585365853659,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Semasio",
  //     "vendorId": 0,
  //     "vendorDomain": "semasio.net",
  //     "violationCount": 95,
  //     "violationShare": 0.33520341554638156,
  //     "pageCount": 73,
  //     "pageShare": 17.804878048780488,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Mathtag",
  //     "vendorId": 0,
  //     "vendorDomain": "mathtag.com",
  //     "violationCount": 91,
  //     "violationShare": 0.321089587523376,
  //     "pageCount": 71,
  //     "pageShare": 17.317073170731707,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Opera",
  //     "vendorId": 0,
  //     "vendorDomain": "adx.opera.com",
  //     "violationCount": 88,
  //     "violationShare": 0.31050421650612187,
  //     "pageCount": 35,
  //     "pageShare": 8.536585365853659,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Loopme",
  //     "vendorId": 0,
  //     "vendorDomain": "csync.loopme.me",
  //     "violationCount": 83,
  //     "violationShare": 0.29286193147736495,
  //     "pageCount": 37,
  //     "pageShare": 9.024390243902438,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Twitter",
  //     "vendorId": 0,
  //     "vendorDomain": "twitter.com",
  //     "violationCount": 82,
  //     "violationShare": 0.28933347447161356,
  //     "pageCount": 70,
  //     "pageShare": 17.073170731707318,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Eyeota",
  //     "vendorId": 0,
  //     "vendorDomain": "eyeota.net",
  //     "violationCount": 78,
  //     "violationShare": 0.275219646448608,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Nationalgeographic",
  //     "vendorId": 0,
  //     "vendorDomain": "www.nationalgeographic.org",
  //     "violationCount": 76,
  //     "violationShare": 0.26816273243710526,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Mookie1",
  //     "vendorId": 0,
  //     "vendorDomain": "mookie1.com",
  //     "violationCount": 75,
  //     "violationShare": 0.26463427543135387,
  //     "pageCount": 44,
  //     "pageShare": 10.731707317073171,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sportradarserving",
  //     "vendorId": 0,
  //     "vendorDomain": "sportradarserving.com",
  //     "violationCount": 72,
  //     "violationShare": 0.2540489044140997,
  //     "pageCount": 13,
  //     "pageShare": 3.1707317073170733,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "T",
  //     "vendorId": 0,
  //     "vendorDomain": "t.co",
  //     "violationCount": 70,
  //     "violationShare": 0.24699199040259695,
  //     "pageCount": 70,
  //     "pageShare": 17.073170731707318,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Youtube",
  //     "vendorId": 0,
  //     "vendorDomain": "youtube.com",
  //     "violationCount": 70,
  //     "violationShare": 0.24699199040259695,
  //     "pageCount": 14,
  //     "pageShare": 3.414634146341464,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Innovid",
  //     "vendorId": 0,
  //     "vendorDomain": "innovid.com",
  //     "violationCount": 64,
  //     "violationShare": 0.22582124836808865,
  //     "pageCount": 27,
  //     "pageShare": 6.585365853658537,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Teads",
  //     "vendorId": 0,
  //     "vendorDomain": "teads.tv",
  //     "violationCount": 61,
  //     "violationShare": 0.21523587735083446,
  //     "pageCount": 51,
  //     "pageShare": 12.439024390243903,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Intentiq",
  //     "vendorId": 0,
  //     "vendorDomain": "intentiq.com",
  //     "violationCount": 60,
  //     "violationShare": 0.21170742034508308,
  //     "pageCount": 9,
  //     "pageShare": 2.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Us-7",
  //     "vendorId": 0,
  //     "vendorDomain": "waltdisney.us-7.evergage.com",
  //     "violationCount": 60,
  //     "violationShare": 0.21170742034508308,
  //     "pageCount": 8,
  //     "pageShare": 1.951219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Com",
  //     "vendorId": 0,
  //     "vendorDomain": "disney.com.br",
  //     "violationCount": 60,
  //     "violationShare": 0.21170742034508308,
  //     "pageCount": 9,
  //     "pageShare": 2.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Creative-serving",
  //     "vendorId": 0,
  //     "vendorDomain": "creative-serving.com",
  //     "violationCount": 57,
  //     "violationShare": 0.20112204932782896,
  //     "pageCount": 15,
  //     "pageShare": 3.6585365853658534,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Aniview",
  //     "vendorId": 0,
  //     "vendorDomain": "aniview.com",
  //     "violationCount": 56,
  //     "violationShare": 0.19759359232207757,
  //     "pageCount": 27,
  //     "pageShare": 6.585365853658537,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bing",
  //     "vendorId": 0,
  //     "vendorDomain": "bing.com",
  //     "violationCount": 56,
  //     "violationShare": 0.19759359232207757,
  //     "pageCount": 27,
  //     "pageShare": 6.585365853658537,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Krxd",
  //     "vendorId": 0,
  //     "vendorDomain": "krxd.net",
  //     "violationCount": 51,
  //     "violationShare": 0.17995130729332062,
  //     "pageCount": 48,
  //     "pageShare": 11.707317073170733,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adition",
  //     "vendorId": 0,
  //     "vendorDomain": "adfarm1.adition.com",
  //     "violationCount": 50,
  //     "violationShare": 0.17642285028756927,
  //     "pageCount": 47,
  //     "pageShare": 11.463414634146343,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sc-static",
  //     "vendorId": 0,
  //     "vendorDomain": "sc-static.net",
  //     "violationCount": 50,
  //     "violationShare": 0.17642285028756927,
  //     "pageCount": 50,
  //     "pageShare": 12.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Snapchat",
  //     "vendorId": 0,
  //     "vendorDomain": "snapchat.com",
  //     "violationCount": 50,
  //     "violationShare": 0.17642285028756927,
  //     "pageCount": 50,
  //     "pageShare": 12.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Disney",
  //     "vendorId": 0,
  //     "vendorDomain": "www.disney.com.br",
  //     "violationCount": 50,
  //     "violationShare": 0.17642285028756927,
  //     "pageCount": 9,
  //     "pageShare": 2.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Seadform",
  //     "vendorId": 0,
  //     "vendorDomain": "seadform.net",
  //     "violationCount": 49,
  //     "violationShare": 0.17289439328181785,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Tiktok",
  //     "vendorId": 0,
  //     "vendorDomain": "tiktok.com",
  //     "violationCount": 49,
  //     "violationShare": 0.17289439328181785,
  //     "pageCount": 49,
  //     "pageShare": 11.951219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Weborama",
  //     "vendorId": 0,
  //     "vendorDomain": "weborama.fr",
  //     "violationCount": 49,
  //     "violationShare": 0.17289439328181785,
  //     "pageCount": 46,
  //     "pageShare": 11.219512195121952,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "E-volution",
  //     "vendorId": 0,
  //     "vendorDomain": "e-volution.ai",
  //     "violationCount": 48,
  //     "violationShare": 0.16936593627606647,
  //     "pageCount": 45,
  //     "pageShare": 10.975609756097562,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sonobi",
  //     "vendorId": 0,
  //     "vendorDomain": "go.sonobi.com",
  //     "violationCount": 47,
  //     "violationShare": 0.16583747927031509,
  //     "pageCount": 27,
  //     "pageShare": 6.585365853658537,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Gumgum",
  //     "vendorId": 0,
  //     "vendorDomain": "gumgum.com",
  //     "violationCount": 45,
  //     "violationShare": 0.15878056525881232,
  //     "pageCount": 25,
  //     "pageShare": 6.097560975609756,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bttrack",
  //     "vendorId": 0,
  //     "vendorDomain": "bttrack.com",
  //     "violationCount": 39,
  //     "violationShare": 0.137609823224304,
  //     "pageCount": 26,
  //     "pageShare": 6.341463414634147,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Company-target",
  //     "vendorId": 0,
  //     "vendorDomain": "company-target.com",
  //     "violationCount": 38,
  //     "violationShare": 0.13408136621855263,
  //     "pageCount": 16,
  //     "pageShare": 3.902439024390244,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Ib-ibi",
  //     "vendorId": 0,
  //     "vendorDomain": "global.ib-ibi.com",
  //     "violationCount": 37,
  //     "violationShare": 0.13055290921280124,
  //     "pageCount": 34,
  //     "pageShare": 8.292682926829269,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Pswec",
  //     "vendorId": 0,
  //     "vendorDomain": "pswec.com",
  //     "violationCount": 33,
  //     "violationShare": 0.1164390811897957,
  //     "pageCount": 9,
  //     "pageShare": 2.195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Springserve",
  //     "vendorId": 0,
  //     "vendorDomain": "springserve.com",
  //     "violationCount": 32,
  //     "violationShare": 0.11291062418404432,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Creativecdn",
  //     "vendorId": 0,
  //     "vendorDomain": "creativecdn.com",
  //     "violationCount": 32,
  //     "violationShare": 0.11291062418404432,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Cquotient",
  //     "vendorId": 0,
  //     "vendorDomain": "cquotient.com",
  //     "violationCount": 30,
  //     "violationShare": 0.10585371017254154,
  //     "pageCount": 8,
  //     "pageShare": 1.951219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Disneyadvertising",
  //     "vendorId": 0,
  //     "vendorDomain": "www.disneyadvertising.com",
  //     "violationCount": 30,
  //     "violationShare": 0.10585371017254154,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Marketiq",
  //     "vendorId": 0,
  //     "vendorDomain": "marketiq.com",
  //     "violationCount": 28,
  //     "violationShare": 0.09879679616103879,
  //     "pageCount": 13,
  //     "pageShare": 3.1707317073170733,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Media",
  //     "vendorId": 0,
  //     "vendorDomain": "media.net",
  //     "violationCount": 28,
  //     "violationShare": 0.09879679616103879,
  //     "pageCount": 10,
  //     "pageShare": 2.4390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Connatix",
  //     "vendorId": 0,
  //     "vendorDomain": "connatix.com",
  //     "violationCount": 28,
  //     "violationShare": 0.09879679616103879,
  //     "pageCount": 7,
  //     "pageShare": 1.707317073170732,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Serving-sys",
  //     "vendorId": 0,
  //     "vendorDomain": "serving-sys.com",
  //     "violationCount": 25,
  //     "violationShare": 0.08821142514378463,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Zendesk",
  //     "vendorId": 0,
  //     "vendorDomain": "midtowncomicshelp.zendesk.com",
  //     "violationCount": 25,
  //     "violationShare": 0.08821142514378463,
  //     "pageCount": 5,
  //     "pageShare": 1.2195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Ladsp",
  //     "vendorId": 0,
  //     "vendorDomain": "ladsp.com",
  //     "violationCount": 24,
  //     "violationShare": 0.08468296813803323,
  //     "pageCount": 7,
  //     "pageShare": 1.707317073170732,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Google",
  //     "vendorId": 0,
  //     "vendorDomain": "google.com",
  //     "violationCount": 23,
  //     "violationShare": 0.08115451113228186,
  //     "pageCount": 13,
  //     "pageShare": 3.1707317073170733,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Attentivemobile",
  //     "vendorId": 0,
  //     "vendorDomain": "attentivemobile.com",
  //     "violationCount": 22,
  //     "violationShare": 0.07762605412653047,
  //     "pageCount": 8,
  //     "pageShare": 1.951219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Samplicio",
  //     "vendorId": 0,
  //     "vendorDomain": "samplicio.us",
  //     "violationCount": 21,
  //     "violationShare": 0.07409759712077908,
  //     "pageCount": 11,
  //     "pageShare": 2.682926829268293,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Lkqd",
  //     "vendorId": 0,
  //     "vendorDomain": "lkqd.net",
  //     "violationCount": 21,
  //     "violationShare": 0.07409759712077908,
  //     "pageCount": 7,
  //     "pageShare": 1.707317073170732,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Ispot",
  //     "vendorId": 0,
  //     "vendorDomain": "ispot.tv",
  //     "violationCount": 18,
  //     "violationShare": 0.06351222610352493,
  //     "pageCount": 12,
  //     "pageShare": 2.9268292682926833,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Yieldmo",
  //     "vendorId": 0,
  //     "vendorDomain": "yieldmo.com",
  //     "violationCount": 17,
  //     "violationShare": 0.05998376909777354,
  //     "pageCount": 5,
  //     "pageShare": 1.2195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Advertising",
  //     "vendorId": 0,
  //     "vendorDomain": "advertising.com",
  //     "violationCount": 16,
  //     "violationShare": 0.05645531209202216,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Cnn",
  //     "vendorId": 0,
  //     "vendorDomain": "cnn.com",
  //     "violationCount": 16,
  //     "violationShare": 0.05645531209202216,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Smartclip",
  //     "vendorId": 0,
  //     "vendorDomain": "sxp.smartclip.net",
  //     "violationCount": 16,
  //     "violationShare": 0.05645531209202216,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Outbrain",
  //     "vendorId": 0,
  //     "vendorDomain": "outbrain.com",
  //     "violationCount": 16,
  //     "violationShare": 0.05645531209202216,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Avct",
  //     "vendorId": 0,
  //     "vendorDomain": "ads.avct.cloud",
  //     "violationCount": 15,
  //     "violationShare": 0.05292685508627077,
  //     "pageCount": 12,
  //     "pageShare": 2.9268292682926833,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Cdn",
  //     "vendorId": 0,
  //     "vendorDomain": "turnip.cdn.turner.com",
  //     "violationCount": 15,
  //     "violationShare": 0.05292685508627077,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adkernel",
  //     "vendorId": 0,
  //     "vendorDomain": "adkernel.com",
  //     "violationCount": 14,
  //     "violationShare": 0.04939839808051939,
  //     "pageCount": 13,
  //     "pageShare": 3.1707317073170733,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sharethrough",
  //     "vendorId": 0,
  //     "vendorDomain": "sharethrough.com",
  //     "violationCount": 14,
  //     "violationShare": 0.04939839808051939,
  //     "pageCount": 13,
  //     "pageShare": 3.1707317073170733,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Clickcertain",
  //     "vendorId": 0,
  //     "vendorDomain": "a.clickcertain.com",
  //     "violationCount": 12,
  //     "violationShare": 0.04234148406901662,
  //     "pageCount": 10,
  //     "pageShare": 2.4390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Usbrowserspeed",
  //     "vendorId": 0,
  //     "vendorDomain": "a.usbrowserspeed.com",
  //     "violationCount": 12,
  //     "violationShare": 0.04234148406901662,
  //     "pageCount": 10,
  //     "pageShare": 2.4390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Brand-display",
  //     "vendorId": 0,
  //     "vendorDomain": "brand-display.com",
  //     "violationCount": 12,
  //     "violationShare": 0.04234148406901662,
  //     "pageCount": 12,
  //     "pageShare": 2.9268292682926833,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Nrich",
  //     "vendorId": 0,
  //     "vendorDomain": "nrich.ai",
  //     "violationCount": 10,
  //     "violationShare": 0.03528457005751385,
  //     "pageCount": 8,
  //     "pageShare": 1.951219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Zopim",
  //     "vendorId": 0,
  //     "vendorDomain": "widget-mediator.zopim.com",
  //     "violationCount": 9,
  //     "violationShare": 0.031756113051762465,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Eqads",
  //     "vendorId": 0,
  //     "vendorDomain": "eqads.com",
  //     "violationCount": 8,
  //     "violationShare": 0.02822765604601108,
  //     "pageCount": 8,
  //     "pageShare": 1.951219512195122,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Dotomi",
  //     "vendorId": 0,
  //     "vendorDomain": "dotomi.com",
  //     "violationCount": 8,
  //     "violationShare": 0.02822765604601108,
  //     "pageCount": 5,
  //     "pageShare": 1.2195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Stickyadstv",
  //     "vendorId": 0,
  //     "vendorDomain": "ads.stickyadstv.com",
  //     "violationCount": 7,
  //     "violationShare": 0.024699199040259696,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Socdm",
  //     "vendorId": 0,
  //     "vendorDomain": "socdm.com",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 6,
  //     "pageShare": 1.4634146341463417,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Uplynk",
  //     "vendorId": 0,
  //     "vendorDomain": "uplynk.com",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Mtrcs",
  //     "vendorId": 0,
  //     "vendorDomain": "pixel.mtrcs.samba.tv",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 5,
  //     "pageShare": 1.2195121951219512,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Rezync",
  //     "vendorId": 0,
  //     "vendorDomain": "rezync.com",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 3,
  //     "pageShare": 0.7317073170731708,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Spotify",
  //     "vendorId": 0,
  //     "vendorDomain": "spotify.com",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 3,
  //     "pageShare": 0.7317073170731708,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Videohub",
  //     "vendorId": 0,
  //     "vendorDomain": "videohub.tv",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sundaysky",
  //     "vendorId": 0,
  //     "vendorDomain": "sundaysky.com",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Yandex",
  //     "vendorId": 0,
  //     "vendorDomain": "yandex.ru",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 3,
  //     "pageShare": 0.7317073170731708,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Cintnetworks",
  //     "vendorId": 0,
  //     "vendorDomain": "c.cintnetworks.com",
  //     "violationCount": 6,
  //     "violationShare": 0.02117074203450831,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Trustedsite",
  //     "vendorId": 0,
  //     "vendorDomain": "www.trustedsite.com",
  //     "violationCount": 5,
  //     "violationShare": 0.017642285028756925,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Zucks",
  //     "vendorId": 0,
  //     "vendorDomain": "zucks.net",
  //     "violationCount": 4,
  //     "violationShare": 0.01411382802300554,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Omnitagjs",
  //     "vendorId": 0,
  //     "vendorDomain": "omnitagjs.com",
  //     "violationCount": 4,
  //     "violationShare": 0.01411382802300554,
  //     "pageCount": 4,
  //     "pageShare": 0.975609756097561,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Ctnsnet",
  //     "vendorId": 0,
  //     "vendorDomain": "ctnsnet.com",
  //     "violationCount": 4,
  //     "violationShare": 0.01411382802300554,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Sojern",
  //     "vendorId": 0,
  //     "vendorDomain": "sojern.com",
  //     "violationCount": 4,
  //     "violationShare": 0.01411382802300554,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Extremereach",
  //     "vendorId": 0,
  //     "vendorDomain": "extremereach.io",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Fwmrm",
  //     "vendorId": 0,
  //     "vendorDomain": "fwmrm.net",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 3,
  //     "pageShare": 0.7317073170731708,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Axonix",
  //     "vendorId": 0,
  //     "vendorDomain": "openrtb-us-east-1.axonix.com",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 3,
  //     "pageShare": 0.7317073170731708,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adotmob",
  //     "vendorId": 0,
  //     "vendorDomain": "adotmob.com",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Fksnk",
  //     "vendorId": 0,
  //     "vendorDomain": "fksnk.com",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Blismedia",
  //     "vendorId": 0,
  //     "vendorDomain": "blismedia.com",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 3,
  //     "pageShare": 0.7317073170731708,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Addthis",
  //     "vendorId": 0,
  //     "vendorDomain": "addthis.com",
  //     "violationCount": 3,
  //     "violationShare": 0.010585371017254154,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Optimizely",
  //     "vendorId": 0,
  //     "vendorDomain": "rum.optimizely.com",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Adhaven",
  //     "vendorId": 0,
  //     "vendorDomain": "adhaven.com",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Zemanta",
  //     "vendorId": 0,
  //     "vendorDomain": "zemanta.com",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Smadex",
  //     "vendorId": 0,
  //     "vendorDomain": "smadex.com",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Reson8",
  //     "vendorId": 0,
  //     "vendorDomain": "reson8.com",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 2,
  //     "pageShare": 0.4878048780487805,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Ap",
  //     "vendorId": 0,
  //     "vendorDomain": "interactives.ap.org",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Postrelease",
  //     "vendorId": 0,
  //     "vendorDomain": "postrelease.com",
  //     "violationCount": 2,
  //     "violationShare": 0.00705691401150277,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Travelaudience",
  //     "vendorId": 0,
  //     "vendorDomain": "travelaudience.com",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Mediago",
  //     "vendorId": 0,
  //     "vendorDomain": "mediago.io",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Resetdigital",
  //     "vendorId": 0,
  //     "vendorDomain": "resetdigital.co",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Admixer",
  //     "vendorId": 0,
  //     "vendorDomain": "admixer.net",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Zeotap",
  //     "vendorId": 0,
  //     "vendorDomain": "zeotap.com",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Cpmstar",
  //     "vendorId": 0,
  //     "vendorDomain": "server.cpmstar.com",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Lemmatechnologies",
  //     "vendorId": 0,
  //     "vendorDomain": "lemmatechnologies.com",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Placed",
  //     "vendorId": 0,
  //     "vendorDomain": "placed.com",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   },
  //   {
  //     "vendorName": "Bidtheatre",
  //     "vendorId": 0,
  //     "vendorDomain": "adsby.bidtheatre.com",
  //     "violationCount": 1,
  //     "violationShare": 0.003528457005751385,
  //     "pageCount": 1,
  //     "pageShare": 0.24390243902439024,
  //     "otherMetrics": []
  //   }
  // ]

  public otherMetricsList: any = [];
  public percentRegex = /\.?0+$/;
  public vendorList = [];
  public isLoaded:boolean;

  public data: any = [];
  constructor(
    private cd: ChangeDetectorRef
  ) {
    // this.data = this.inputData || [];
    this.isLoaded = false;
    
  }

  colorDefine(value:number){
    if(value < 10){
      return 'green'
    }else if(value < 50){
      return 'orange'
    }else{
      return 'red'
    }
  }

  ngOnInit(): void {
    this.cdnUrl = env.cdn.url;
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.data = this.inputData;
    this.isLoaded = true;
  }
}
