<div style="width: 100%; height:100%; background-color: #eeeeee" class="be-loading" [class.be-loading-active]="imagesLoading">
  <div >
    <div *ngIf="img2str; else singleImage;">
      <div class="container" style="float: left;">
        <h4 style="text-align: center">{{dateA}}</h4>
        <canvas #img1></canvas>
      </div>
      <div class="container" style="float: right;">
        <h4 style="text-align: center">{{dateB}}</h4>
        <canvas #img2></canvas>
      </div>
      <div class="container" style="float: center;">
        <h4 style="text-align: center">Image Diff</h4>
        <canvas #diff></canvas>
      </div>
    </div>
    <ng-template #singleImage>
      <div class="container">
        <img src="{{img1str}}" >
      </div>
    </ng-template>
  </div>
  <div class="be-spinner">
    <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
    </svg>
  </div>
</div>