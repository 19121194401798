import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'simple-progress-bar',
  templateUrl: './simple-progress-bar.component.html',
  styleUrls: ['./simple-progress-bar.component.css']
})
export class SimpleProgressBarComponent implements OnInit {

  @Input('value') value: string;
  @Input('max') max: string;
  @Input('label') label: string;

  public getPercent(){
    return ((parseInt(this.value) / parseInt(this.max)) * 100).toFixed(0);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
