import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-monitoring',
  templateUrl: './monitoring-wrapper.component.html',
  styleUrls: ['./monitoring-wrapper.component.css']
})
export class MonitoringWrapperComponent extends FieldWrapper {

}
