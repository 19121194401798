export class ReportLibraryConfig {


    constructor(
        public title: string,
        public description: string,
        public reportCode: string,
        public beautifiedPath: string,
        public hasRaw: boolean,
        public hidden?: boolean,
        public icon?: string,
        public category?: string,
        public isCustom?: boolean,
    ) {

    }
}
