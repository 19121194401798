<div class="row" style="margin-bottom: 25px;">
    <div class="col-lg-2">
        <img src="https://1000logos.net/wp-content/uploads/2021/10/logo-Meta.png" alt="Facebook Logo" height="100px">
    </div>
    <div class="col-lg-7">
        <div class="report-title">Meta Vendor Analysis</div>
        <div class="report-meta">Report Generated on 27th May 2024</div>
    </div>
</div>
<div class="row" style="margin-bottom:25px;">
    <div class="col-lg-8">
        <ejs-dropdownlist width="100%" [dataSource]="pageList" placeholder="Select a page"
            [fields]="pageListFields" (select)="onDropdownValueChanged($event)" [value]="selectedPageUrl"
            ></ejs-dropdownlist>
    </div>
</div>
<div class="row">
    <div class="col-lg-4" style="border: 0px solid #fc7d73;">
        <app-title-card>
            <ng-container header>
                <span class="zmdi zmdi-lock-outline title-icon"></span>
                Privacy Features
            </ng-container>
            <div class="flex-row">
                <div class="flex-column" style="padding:5px;">
                    <div style="font-size:xx-large;"><span class="zmdi" [class]="PFData.dpo === 'LDU' ?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="PFData.dpo === 'LDU' ? 'green' : '#fc7d73'"></span></div>
                    <div style="font-size: large; width: 100px;text-align: center;">Limited Data Use</div>
                </div>
                <div class="grid-col-2" style="margin:5px">
                    <div class="code-text">fbp</div>
                    <ejs-tooltip class="overflow-text" style=" width: fit-content;max-width: 150px ;" [content]="PFData.fbp || 'No Value'">
                        <div>{{PFData.fbp || 'Not Found'}}</div>
                    </ejs-tooltip>

                    <div class="code-text">fbc</div>
                    <ejs-tooltip class="overflow-text" style=" width: fit-content;max-width: 150px ;" [content]="PFData.fbc || 'No Value'">
                        <div>{{PFData.fbc || 'Not Found'}}</div>
                    </ejs-tooltip>

                </div>
            </div>
        </app-title-card>
    </div>
    <div class="col-lg-5" style="border: 0px solid #fc7d73;">
        <app-title-card>
            <ng-container header>
                <span class="zmdi zmdi-dns title-icon"></span>
                Deployed Technology
            </ng-container>
            <div class="flex-row" style="font-size:large;">
                <div class="flex-column">
                    <div><span class="zmdi" [class]="FBTechData.SDK ?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="FBTechData.SDK ? 'green' : '#fc7d73'" style="font-size:xx-large;"></span>
                    </div>
                    <div>SDK</div>
                </div>
                <div class="flex-column">
                    <div><span class="zmdi" [class]="FBTechData.FBTrack?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="FBTechData.FBTrack ? 'green' : '#fc7d73'" style="font-size:xx-large;"></span>
                    </div>
                    <div>FBTrack</div>
                </div>
                <div class="flex-column">
                    <div><span class="zmdi" [class]="FBTechData['Privacy Sandbox'] ?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="FBTechData['Privacy Sandbox'] ? 'green' : '#fc7d73'"
                            style="font-size:xx-large;"></span></div>
                    <div>Privacy Sandbox</div>
                </div>
            </div>
        </app-title-card>
    </div>
    <div class="col-lg-3" style="border: 0px solid #fc7d73;">
        <app-title-card>
            <ng-container header>
                <span class="zmdi zmdi-settings title-icon"></span>
                Conversion API
            </ng-container>
            <div class="flex-row" style="font-size: large;">
                <div class="flex-column">
                    <div style="font-size:xx-large;">{{CAPIData.POST}}</div>
                    <div>POST</div>
                </div>
                <div class="flex-column">
                    <div style="font-size:xx-large;">{{CAPIData.GET}}</div>
                    <div>GET</div>
                </div>
            </div>

        </app-title-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-6" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-image-o title-icon"></span>
                Tracking Pixels IDs
            </ng-container>
            <div class="flex-row" style="height: 100%;">
                <div class="flex-column" style="padding:5px; min-width: 200px;">
                    <div style="font-size:xx-large">{{PIDData?.length || 'No Pixels'}}</div>
                    <div>Detected</div>
                </div>
                <div style="flex-grow: 1; height:100%">
                    <ejs-grid height="100%" [dataSource]="PIDData" width="100%">
                    </ejs-grid>
                </div>
            </div>
        </app-title-card>
    </div>

    <div class="col-lg-6" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-videocam title-icon"></span>
                Video on Page
            </ng-container>
            <div style="height:100%">
                <ejs-grid height="100%"
                    [dataSource]="VOPData">
                <e-columns>
                    <e-column field="vendor" headerText="Vendor" textAlign="left" autoFit="true">
                    </e-column>
                    <e-column field="value" headerText="Request" textAlign="left">
                    </e-column>
                </e-columns>
                </ejs-grid>
            </div>

        </app-title-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-12" style="border: 0px solid #fc7d73;">
        <app-title-card height="300px">
            <ng-container header>
                <span class="zmdi zmdi-file-text title-icon"></span>
                Config File Parameters
            </ng-container>
            <div class="flex-row" style="height: 100%;">
                <div class="flex-column" style="padding:5px; min-width: 400px;">
                    <div style="font-size:x-large">Config File {{CFSData.length === 0 ? 'Not' : ''}} Detected
                    </div>
                    <div *ngIf="CFSData.length !== 0 ">with {{CFSData.length || 0}} Parameter(s)</div>
                </div>
                <div style="height:100%; flex-grow: 1;">
                    <ejs-grid height="100%" [dataSource]="CFSData" width="100%">
                        <e-columns>
                            <e-column field="configOpt" headerText="Config Option" textAlign="left">
                                <ng-template #template let-data>
                                    <ejs-tooltip
                                        [content]="FBDetailsService.getDescription('configOptions.'+data.configOpt)">
                                        <div style="width: fit-content;">{{data.configOpt}}</div>
                                    </ejs-tooltip>
                                </ng-template>
                            </e-column>
                            <e-column field="value" headerText="Value" textAlign="left">
                                <ng-template #template let-data>
                                    <div>
                                        {{data.value}}
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </app-title-card>
    </div>
</div>
<!-- <div class="row">
            <div class="col-lg-12" style="border: 0px solid #fc7d73;">
                <app-fb-details-nested-grid title="Faceboook Data" height="350" [columns]="FBDColumns"
                    [childGrid]="FBDChildGrid" [dataSource]="FBDData ? FBDData : null"
                    tooltip="&lt;b&gt;Faceboook Data:&lt;/b&gt; This table is a compilation of facebook query parameters and values. Included is a description of each parameter, which is grouped into acommpanying categories.">
                </app-fb-details-nested-grid>
            </div>
        </div> -->
<div class="row">
    <div class="col-lg-12">
        <app-title-card height="600px">
            <ng-container header>
                <span class="zmdi zmdi-info-outline title-icon"></span>
                All Parameters
                <span>: {{FBDData?.length || 0}} Params</span>
            </ng-container>
            <ng-container headerToolbar>
                <ejs-dropdownlist #pixelDropDown [dataSource]="pixelDropDownList" [fields]="{text: 'id', value: 'id'}" placeholder="Filter by Pixel..." (select)="onPixelSelect($event)"></ejs-dropdownlist>
                <span style="font-size: medium;"> Advanced View</span>
                <ejs-switch [(ngModel)]="showAdvanced" (change)="onAdvancedToggle()"></ejs-switch>
            </ng-container>
            <div class="flex-row" style="height:100%">
                <ejs-grid height="100%" [dataSource]="FBDData" #FBDataGrid
                    [groupSettings]="{ columns: ['category'],showDropArea: false }" [allowGrouping]="true" [allowFiltering]="true">
                    <e-columns>
                        <e-column field="category" [allowFiltering]="false" ></e-column>
                        <e-column field="param" headerText="Parameter" textAlign="left" [allowGrouping]='false' isPrimaryKey='true' [allowFiltering]="false" >
                            <ng-template #template let-data>
                                <ejs-tooltip
                                    [content]="data.name+ ': ' + (data.explanation || 'No Description Available')">
                                    <div style='font-family:monospace; font-size: medium;'>{{data.param}}</div>
                                </ejs-tooltip>

                            </ng-template>
                        </e-column>
                        <e-column field="value" headerText="Value" textAlign="left" [allowGrouping]='false' [allowFiltering]="false">
                            <ng-template #template let-data>
                                <div>
                                    {{data.value}}
                                </div>
                            </ng-template>
                        </e-column>
                        <!-- <e-column field="apiName"></e-column> -->
                        <e-column field="name" [visible]="showAdvanced" [allowFiltering]="false"></e-column>
                        <e-column field="explanation"  [visible]="showAdvanced" [allowFiltering]="false"></e-column>
                        <e-column field="associatedPixel"  [allowFiltering]="true"></e-column>

                    </e-columns>
                    <ng-template #groupSettingsCaptionTemplate let-data>
                        <span style="font-weight: bold;">{{ data.key }} - {{data.count}}</span>
                    </ng-template>
                </ejs-grid>
            </div>
        </app-title-card>
    </div>
</div>