<div *ngIf="isLoaded | async">
    <div >
        <div class="row">
            <div class="col-lg-6" style="border: 0px solid red;">
                <app-fb-details-dropdown
                    title="Page"
                    [data]="pageData"
                    fields="{ text: 'url', value: 'id' }"
                    placeholder="Choose a Page"
                    (dropdownValueChange)="onDropdownValueChanged($event)">
                </app-fb-details-dropdown>
            </div>
            <div class="col-lg-6" style="border: 0px solid red;">
                <app-fb-details-dropdown title="Domain"></app-fb-details-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4" style="border: 0px solid red;">
                <app-fb-details-cardlist
                    title="Privacy Features"
                    height="175"
                    [columns]="PFColumns"
                    [dataSource]="PFData">
                </app-fb-details-cardlist>
            </div>
            <div class="col-lg-4" style="border: 0px solid red;">
                <app-fb-details-cardlist
                    title="FB Tech"
                    height="175"
                    [columns]="FBTechColumns"
                    [dataSource]="FBTechData">
                </app-fb-details-cardlist>
            </div>
            <div class="col-lg-4" style="border: 0px solid red;">
                <app-fb-details-cardlist
                    title="Video on Page"
                    height="175"
                    [columns]="VOPColumns"
                    [dataSource]="VOPData">
                </app-fb-details-cardlist>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5" style="border: 0px solid red;">
                <app-fb-details-cardlist
                    title="Conversion API"
                    height="175"
                    [columns]="CAPIColumns"
                    [dataSource]="CAPIData">
                </app-fb-details-cardlist>
            </div>
            <div class="col-lg-2" style="border: 0px solid red;">
                <app-fb-details-cardlist
                    title="Pixels"
                    height="175"
                    [columns]="PIDColumns"
                    [dataSource]="PIDData">
                </app-fb-details-cardlist>
            </div>
            <div class="col-lg-5" style="border: 0px solid red;">
                <app-fb-details-cardlist
                    title="Config File Status"
                    height="175"
                    [columns]="CFSColumns"
                    [dataSource]="CFSData">
                </app-fb-details-cardlist>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" style="border: 0px solid red;">
                <app-fb-details-nested-grid
                    title="Faceboook Data"
                    height="350"
                    [columns]="FBDColumns"
                    [childGrid]="FBDChildGrid"
                    [dataSource]="FBDData ? FBDData : null">
                </app-fb-details-nested-grid>
            </div>
        </div>
    </div>
</div>