import { Component, OnInit, ElementRef, AfterViewInit, Input, OnChanges, SimpleChange, ViewChild, SimpleChanges, OnDestroy, NgZone } from '@angular/core';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { DataExportService } from '../../../../services/data-export/data-export.service';
import { GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../../services/timezone.service';
declare var $:any;

@Component({
  selector: 'code-info',
  templateUrl: './code-info.component.html',
  styleUrls: ['./code-info.component.css']
})
export class CodeInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  
  @Input() public vendor:any;
  
  files:any[];
  filesSub:Subscription;
  notificationOptions:any;
  exportNotificationOptions:any;
  exportId:string;
  pollingTimer:any;
  pollingTimerCounter:number;
  showExportButton:boolean;
  exportButtonDisabled:boolean;
  
  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];  
  public dateFormat:any;
  @ViewChild('codeFilesGrid') public grid: GridComponent;

  constructor(private vendorService:VendorsService, 
              private zone:NgZone, 
              private router:Router, 
              private route:ActivatedRoute,
              private notificationService:NotificationsService,
              private dataExportService:DataExportService,
              private spinnerService:SpinnerService,
              private timezoneService:TimezoneService
  ) {

    this.notificationOptions = {
      timeOut: 2000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.exportNotificationOptions = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.showExportButton = false;
    this.exportButtonDisabled = false;

    this.dateFormat = {type: 'dateTime', skeleton:'short'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'name', direction: 'Ascending'}  
      ]
    }
  }

  ngAfterViewInit() { 
    console.log('Code Info ngAfterViewInit');
    let vendorName = this.router.url.replace("/vendors/", "").replace(/#.*/,"");
    this.loadData(vendorName);
  }

  ngOnInit(){
    console.log('Code Info ngOnInit')
    $('a[data-toggle="tab"]a:contains("Code Files")').on('shown.bs.tab', (e) => {
      this.router.navigate([], {fragment:"code"});      
    });
  }

  loadData(vendorName) {
    this.spinnerService.toggle(true);
    this.filesSub = this.vendorService.getCodeFiles(vendorName).subscribe((codeFiles) => {
      console.log("wtf");
      console.log(codeFiles);
      if (!codeFiles){
        this.files = [];
        return;
      }
      this.files = codeFiles;
      // this.files = codeFiles.map((item)=>{
      //   var displayName:string = item.fileName;
      //   if ( displayName.length > 100 ){
      //     const lastSlashIndex = displayName.lastIndexOf("/");
      //     displayName = displayName.substr(lastSlashIndex);
          
      //     displayName = item.fileName.substr(0, 100-displayName.length) + ".." + displayName;
      //   }
      //   return {...item, displayName: displayName }
      // });
      this.grid.dataSource = this.files;
      this.spinnerService.toggle(false);
    },
    (error)=>{
      this.spinnerService.toggle(false);
    });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('Code Info ngOnChanges')
  //   if ( changes && changes["vendor"] && changes["vendor"].currentValue){
  //     // if ( !this.route.snapshot.fragment || (this.route.snapshot.fragment && this.route.snapshot.fragment == "code")){      
  //       this.loadData(this.vendor.name);
  //     // }            
  //   }
  // }

  ngOnDestroy(){
    if ( this.filesSub){
      this.filesSub.unsubscribe();
    }
    if ( this.pollingTimer ){
      clearTimeout(this.pollingTimer);
    }    
  }

  onCopied(event){
    this.notificationService.success('Copied', 'Full URL was copied to clipboard.', this.notificationOptions);
  }

  exportData() {
    this.exportButtonDisabled = true;
    this.dataExportService.startDataExport(DataExportService.CODE_FILES_REPORT, this.vendor.id).subscribe((result)=> {
      this.notificationService.success('Success!', 'Your export was requested sucessfully. The file will download automatically once it has been generated. Please remain on this page until then.', this.exportNotificationOptions);
      console.log(result);
      this.exportId = result.exportId;
      this.pollingTimerCounter = 0;
      this.startPolling();
    },
    (error)=>{
      this.exportButtonDisabled = false;
      console.log(error);
      this.notificationService.success('Oops!', 'Your export request was not sucessful. Please contact support.', this.exportNotificationOptions);
    });
  }

  startPolling() {
    this.pollingTimer = setTimeout(()=>{
      console.log(new Date() + " Checking for file...");

      const filePath = `${localStorage.cid}/${this.exportId}/${DataExportService.CODE_FILES_REPORT}.${DataExportService.CSV_EXTENSION}`;      
      this.dataExportService.getDataExportFile(filePath).subscribe((data)=>{
        
        clearTimeout(this.pollingTimer);

        this.exportButtonDisabled = false;
        
        let fileName = "Vault JS " + this.vendor.name + " Code Files "  + new Date().toLocaleString() + ".csv";  
        this.dataExportService.saveFile(fileName, data.toString());
        
        clearTimeout(this.pollingTimer);
      },
      (error)=>{
        console.log("S3 Error")
        console.log(error);
        if ( this.pollingTimerCounter < 10 ){
          this.startPolling();
          this.pollingTimerCounter++;
        } else {
          clearTimeout(this.pollingTimer);
          this.notificationService.success('Hmmm...', 'Your export seems to be taking longer than expected. Please contact support.', this.notificationOptions);
        }
      });
    }, 1000);
  }
}