<div class="row">
    <div class="col-sm-12">
        <div class="card card-table card-border be-loading">
            <div class="card-header">
                <div *ngIf="!hideList" class="form-group row pt-1" style="margin:0; display: flex; flex-direction: row; align-items: center; flex-wrap:wrap">
                    <span>Available Reports:</span>
                    <div style="flex-grow:1; padding-left:35px; flex-shrink: 1">
                        <ejs-dropdownlist width="250px" [dataSource]="reports" [fields]="{text:'label',value:'label'}" [itemTemplate]="dropDownTemplate" (change)="onReportChange($event)" placeholder="Select a report">
                        </ejs-dropdownlist>
                    </div>
                    <div class="buttonGroup">
                        <div *ngIf="authService.isVaultUser()" class="parent-div">
                            <button class="btn btn-rounded btn-space btn-success">Upload Report(s)</button>
                            <input type="file" multiple name="upfile" (change)="upload($event.target.files);" />
                        </div>
                        <div *ngIf="authService.isVaultUser()" class="parent-div">
                            <button class="btn btn-rounded btn-space btn-danger" (click)="deleteReport()">Delete
                                Report</button>
                        </div>
                        <div *ngIf="authService.isVaultUser()" class="parent-div">
                            <button class="btn btn-rounded btn-space btn-primary" (click)="addToMenu()">Add to
                                Menu</button>
                        </div>
                        <div *ngIf="authService.isVaultUser()" class="parent-div">
                            <button class="btn btn-rounded btn-space btn-warning" (click)="resetMenu()">Reset
                                Menu</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div *ngIf="manifestFile" class="form-group row pt-1">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Records Cursor:</label>
                            <div class="col-12 col-sm-8 col-lg-6">
                                <select class="form-control queries" [(ngModel)]="fileCounter"
                                    (ngModelChange)="onFileChange();">
                                    <option *ngFor="let label of cursorLabels" [ngValue]="label.value">{{ label.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="manifestFile || exportUrl" class="form-group row pt-1">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">All Records:</label>
                            <div class="col-12 col-sm-8 col-lg-6" style="margin-top: 10px;">
                                <a href="{{exportUrl}}">Export</a>
                            </div>
                        </div>
                    </div>
                </div>

                <ejs-grid #grid [hidden]="isJSON" [dataSource]="data" locale='en-US' [allowPaging]="true" [allowExcelExport]="true" 
                    [toolbar]="['Export CSV']" (toolbarClick)="toolbarClick($event)"
                    [pageSettings]="pageSettings" [allowSorting]="true" [allowSelection]="false" [enableHover]="false"
                    allowFiltering="true" [filterSettings]="filterSettings" [allowResizing]="true"
                    [allowTextWrap]='true' height="800px" [allowGrouping]="true" [groupSettings]="groupOptions"
                    (dataBound)="onDataBound($event)" [allowReordering]="true" [enableStickyHeader]="true">
                    <ng-template #groupSettingsCaptionTemplate let-data>
                        <div
                            style="margin-left: 10px; padding: 5px; background-color: grey; color: white; border-radius: 0.25rem; padding-left: 10px; padding-right:10px; width: fit-content;">
                            {{data.key}}</div>
                    </ng-template>
                </ejs-grid>



                <div [hidden]="!isJSON" style="height: 500px; display: block; padding:20px">
                    <ngx-codemirror #advConfigEditor [(ngModel)]="codeMirrorJSON"
                        [options]="codeMirrorOptions"></ngx-codemirror>
                </div>


            </div>
        </div>
    </div>
</div>
<simple-notifications></simple-notifications>