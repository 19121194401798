import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable, forkJoin, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { VendorsService } from "../services/vendors/vendors.service";
import { VendorLibraryService } from "../services/vendorLibrary/vendor-library.service";


@Injectable()
export class VendorResolver implements Resolve<any> {
    
    constructor(private vendorService:VendorsService, private vendorLibService:VendorLibraryService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any> {
        console.log("aaaaa Vendor Resolver", route);
        const vendorId = route.params["vendor"];
        const requestId = route.params["requestId"];

        console.log("aaaaa Vendor Resolver", vendorId, requestId);

        this.spinnerService.toggleRoot(true);

        return forkJoin([
            this.vendorService.getVendors(),
            (requestId ? this.vendorLibService.getResourceMatcher(requestId) : of(null))
        ]).pipe(map((d:any)=>{
            console.log("aaaaa Vendor Resolver ");
            console.log("aaaaa", d);        
            let vendor = d[0].find((v)=>{return v.id == vendorId})
            let ret = {vendorName: vendor.name, requestName: ''}
            if ( requestId ){                
                ret.requestName = d[1].name
            }
            console.log("aaaaa", ret);
            return ret;            
        })).pipe(tap((x)=>{
            this.spinnerService.toggleRoot(false);
        }))
    }
}