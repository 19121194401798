import { Injectable } from '@angular/core';
import { VaultBaseService } from '../VaultBaseService';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import * as AWS from 'aws-sdk';
import { environment as env } from '../../../environments/environment';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { S3 } from 'aws-sdk';
import { switchMap, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class GlobalResourcesService extends VaultBaseService {
  private _bucket: string;
  constructor(authHttp: HttpClient, private authService: AuthService) {
    super(authHttp);
    this._bucket = env.aws.globalResourcesBucket;
  }

  /**
   * Retrieves the URL for a PDF file.
   *
   * @param pdfName - The name of the PDF file.
   * @returns An Observable that emits the URL as a string.
   */
  public getPDFLinkUrl(pdfName: string): Observable<string> {
    return this._getSignedUrl(this._bucket, pdfName);
  }

  private _getSignedUrl(bucket: string, key: string): Observable<string> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;

        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: bucket,
          Key: key,
        };

        const s3 = new S3();
        const signedUrl = s3.getSignedUrl('getObject', params);
        resolve(signedUrl);
      });
    });
    return from(retPromise) as Observable<string>;
  }
  /**
   * Retrieves a list of global resources from AWS S3 bucket.
   *
   * @returns {Observable<any>} An observable that emits the response containing the list of resources.
   */
  public getListOfResources(prefix?: string): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        var params = {
          Bucket: `${env.aws.globalResourcesBucket}`,
        };
        if (prefix) {
          params['Prefix'] = prefix;
        }
        const s3 = new AWS.S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            resolve(response);
          });
      });
    });
    return from(retPromise);
  }
  public getFileFromS3(key: string): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        var params = {
          Bucket: that._bucket,
          Key: key,
        };
        const s3 = new AWS.S3();
        from(s3.getObject(params).promise()).subscribe(
          (response) => {
            resolve(response.Body.toString());
          },
          (error) => {
            resolve('');
          }
        );
      });
    });
    return from(retPromise);
  }

  public putFileToS3(key: string, data: any): Observable<any> {
    let fileContents = JSON.stringify(data, null, 2);
    console.log('Processing file: ' + key);
    const s3PutParams = {
      Body: fileContents,
      Bucket: this._bucket,
      Key: key,
      ContentType: 'application/json',
    };
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const s3 = new S3();
        return from(s3.putObject(s3PutParams).promise());
      }),
      map((response) => {
        console.log('putResponse', response);
        return response;
      }),
      catchError((error) => {
        console.error('Error uploading file', error);
        return of({ error: error });
      })
    );
  }
}
