// BUG: Spinner is stuck sometimes randomly? maybe when selecting the same view thats already selected? YUP
// QOL: Table Content Border looks odd
// QOL: Move all styles to CSS
// BUG: Report Name change between RDS might be causing issues ? When in view card and changing report name using queryparam RRV?
// FIXME: RDS Mapping Settings panel not working?
// BUG: If PV card queries timeoutm, spinner is stuck
// BUG: Accidental double click on export causes double export job
// BUG: Query Log stuck at spinner when using view cards in s3 mode
// TODO: Clear Filtering currently not working
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Dialog,
  DialogComponent,
  TooltipComponent,
  TooltipEventArgs,
} from '@syncfusion/ej2-angular-popups';
import {
  GridComponent,
  ColumnModel,
  GroupSettingsModel,
  PageSettingsModel,
  ColumnMenuItem,
  DataStateChangeEventArgs,
  Column,
  ToolbarService,
  FilterService,
  PageService,
  SortService,
  FilterSettings,
  ExcelExportProperties,
  Data,
  FilterSearchBeginEventArgs,
} from '@syncfusion/ej2-angular-grids';
import {
  createElement,
  enableVersionBasedPersistence,
  getComponent,
} from '@syncfusion/ej2-base';
import {
  DataManager,
  Query,
  Predicate,
  ReturnOption,
} from '@syncfusion/ej2-data';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import {
  ColumnsModel,
  QueryBuilderComponent,
  RuleModel,
  TemplateColumn,
} from '@syncfusion/ej2-angular-querybuilder';
import { MenuComponent, MenuItem } from '@syncfusion/ej2-angular-navigations';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { PredicateModel } from '@syncfusion/ej2-angular-grids';
import { ReportDetails } from '../../../models/ReportDetails';
import { ReportingS3Service } from '../../../services/reporting-s3/reporting-s3.service';
import {
  ReportingViewsService,
  ViewObject,
} from '../../../services/reporting-views/reporting-views.service';
import { ReportingLibraryService } from '../../../services/reporting-library/reporting-library.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { CustomReportService } from '../../../services/customReportService/custom-report-service.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ProgressBarService } from '../../../services/progressBarService/progress-bar.service';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  finalize,
  mapTo,
  tap,
  map,
  switchMap,
  filter,
} from 'rxjs/operators';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { ReportViewerService } from '../../../services/report-viewer/report-viewer.service';
import {
  ExportObject,
  ReportExportService,
} from '../../../services/report-export/report-export.service';
import { DictionaryObject } from '../../../services/reporting-dict/reporting-dict.service';
import { DateTimePicker } from '@syncfusion/ej2-calendars';

enableVersionBasedPersistence(true);

declare var $: any;


@Component({
  selector: 'app-raw-report-viewer',
  templateUrl: './raw-report-viewer.component.html',
  styleUrls: ['./raw-report-viewer.component.css'],
  providers: [ToolbarService, FilterService, PageService, SortService],
})
export class RawReportViewerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('viewMenu') public viewMenu: MenuComponent;
  @ViewChild('grid') public grid: GridComponent;
  @ViewChild('detailGrid') public detailGrid: GridComponent;
  @ViewChild('filterQueryBuilder') public queryBuilder: QueryBuilderComponent;
  @ViewChild('newStateName') public newStateName: any;
  @ViewChild('newLocalStateName') public newLocalStateName: any;

  private reportCode: string;
  public isCustomReport: boolean = false;
  public loadingLargeData: boolean = false;
  public progressValue: number = 0;

  public reportTitle: string;
  public viewName: string;

  public s3ViewList: any = [];
  public localViewList: any = [];
  public reportIcon: string = 'report';

  public allowScrolling = true;
  public scrollSettings = { width: 'auto', height: 'auto' };
  private maxColumnWidth = 600;
  public maxColumnHeight = 50;

  public filterParamsList: any = [];

  public listAllReports: ReportDetails[] = [];
  public fieldAllReports: FieldSettingsModel = { value: 'name' };
  public selectedReport: ReportDetails = {} as ReportDetails;
  public reportDataManager: DataManager = null; // initialize in constructor
  public reportQuery: Query = new Query();
  public queryLog: any = [];
  public rdsMappingList: any = [];
  public qbOperators: any = [
    { key: 'equal', value: 'Equal' },
    { key: 'notequal', value: 'Not Equal' },
  ];
  public dictionary: any = [] as DictionaryObject[];
  public columnMenuItems: ColumnMenuItem[] = [];
  public defaultColumnMenuItems: any[] = [
    'AutoFitAll',
    'AutoFit',
    'SortAscending',
    'SortDescending',
    {
      text: 'Complex Querying',
      iconCss: 'e-icons e-filter',
      id: 'ComplexQuery',
    },
  ];
  // || Constructor
  constructor(
    public spinnerService: SpinnerService,
    public customReportService: CustomReportService,
    private notificationService: VaultNotificationService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private progressBarService: ProgressBarService,
    private reportingS3: ReportingS3Service,
    private reportingViews: ReportingViewsService,
    private reportingLibrary: ReportingLibraryService,
    private reportViewerService: ReportViewerService,
    private router: Router
  ) {
    this.reportQuery = new Query().page(
      this.pageSettings.currentPage,
      this.pageSettings.pageSize
    );
    this.reportDataManager = new DataManager(
      {
        json: [],
      },
      this.reportQuery
    );
    this.showLogger = true;
    this.columnMenuItems = [
      'AutoFitAll',
      'AutoFit',
      'SortAscending',
      'SortDescending',
      'Filter',
    ];
  }
  // || ngOnInit
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.spinnerService.toggle(true);
        console.log('Query Params:', params);
        // Handle the query params change here
        // if (params['RRV']) {
        this._loadInitialReport();
        // }
      },
      (error) => {},
      () => {
        this.spinnerService.toggle(false);
      }
    );
    this.spinnerService.toggle(true, 'Loading Report');
  }

  ngOnDestroy(): void {
    this.grid.destroy();
    console.log('Grid Destroyed');
  }
  //|| ngAfterViewInit
  ngAfterViewInit(): void {
    this.progressBarService.setStopHandler(
      this.reportViewerService.stopDataDownload
    );
    this._loadInitialReport();
  }
  public customReportDescription: string = '';
  private _loadInitialReport() {
    if (this.grid) {
      this.grid.pageSettings = this.pageSettings;
      this.grid.filterSettings = this.filterSettings;
      this.grid.groupSettings = this.groupSettings;
    }
    if (this.queryBuilder) {
      this.queryBuilder.setRules([] as RuleModel);
    }
    this.reportViewerService.snapshot = this.activatedRoute.snapshot;
    this.reportViewerService.initialize().subscribe((data) => {
      this.reportTitle = this.reportViewerService.reportType;
      this.viewName = this.reportViewerService.selectedView?.name || '';
      this.dictionary = this.reportViewerService.dictionary;
      this.customReportDescription =
        this.reportViewerService.customReportDescription;

      if (this.reportViewerService.isCustom) {
        this.isCustomReport = true;
      }
      console.log('Report Viewer Service Initialized: ');
      console.log('Data: ', data);
      this.listAllReports = this.reportViewerService.availableReports;
      this.selectedReport = this.reportViewerService.selectedReport;
      this.s3ViewList = this.reportViewerService.s3ViewList;
      this.localViewList = this.reportViewerService.localViewList;
      const _selectedView = this.reportViewerService.selectedView;
      if (_selectedView && _selectedView.columns) {
        this.visibleColumns = this.getVisibleColumnsFromView(_selectedView);
      }
      console.log('Selected Report:', this.reportViewerService.selectedReport);
      console.log('Retrieving Data Manager');
      if (this.reportViewerService.useRDS) {
        this.columnMenuItems = this.defaultColumnMenuItems;
        this.spinnerService.toggle(true, 'Executing Query');
        this.reportViewerService
          .getInitialDataManager()
          .subscribe((data: any) => {
            // Load from RDS
            console.log('Data from DataManager: ', data);
            this.reportDataManager = data;
            this.grid.dataSource = this.reportDataManager;
            this.gridColumnUpdate(Object.keys(data.result[0]));

            this.queryBuilder.dataSource = data.result || [];
            let sqlWhere = this.queryBuilder.getSqlFromRules(
              this.queryBuilder.rule ||
                ([
                  {
                    condition: 'and',
                    rules: [],
                  },
                ] as RuleModel)
            );
            let predicate = this.queryBuilder.getPredicate(
              this.queryBuilder.rule ||
                ([
                  {
                    condition: 'and',
                    rules: [],
                  },
                ] as RuleModel)
            );
            if (this.reportViewerService.viewParamFlag) {
              const rule = this.reportViewerService.selectedView.filterRules;
              this.queryBuilder.setRules(rule);
              this.updateFilterCards();
              sqlWhere = this.queryBuilder.getSqlFromRules(rule);
              predicate = this.queryBuilder.getPredicate(rule);
              console.log('SQL WHERE USING VIEW: ', sqlWhere);
            }else if (this.reportViewerService.filterParamFlag) {
              console.log('Filter Params: ', this.reportViewerService.filterInParams);
              const tempFilters: RuleModel = {
                condition: 'and',
              };
              tempFilters.rules =
                this.reportViewerService.filterInParams.map(
                  (item) => {
                    return {
                      field: item.field,
                      operator: 'equal',
                      value: item.value,
                    } as RuleModel;
                  }
                );
              this.queryBuilder.setRules(tempFilters);
              sqlWhere = this.queryBuilder.getSqlFromRules(tempFilters);
              predicate = this.queryBuilder.getPredicate(tempFilters);
              this.updateFilterCards();
              console.log('SQL WHERE USING FILTER PARAMS: ', sqlWhere);
            }
            let query = this.reportQuery.take(100);
            if (predicate) {
              query.where(predicate);
            }

            this.reportViewerService
              .getDataManager(query, sqlWhere, true)
              .subscribe(
                (data: any) => {
                  console.log('Data from FULL DataManager: ', data);
                  this.reportDataManager = data;
                  this.grid.dataSource = this.reportDataManager;
                  if(data.count > 0){
                    this.gridColumnUpdate(Object.keys(data.result[0]));
                  }
                  // this.gridColumnUpdate(Object.keys(data.result[0]));
                },
                (error) => {},
                () => {
                  setTimeout(() => {
                    this.spinnerService.toggle(false);
                    this.grid.autoFitColumns();
                  }, 1000);
                }
              ),
              (error) => {
                console.log('Something Went Wrong: ', error);
              },
              () => {};
          });
      } else {
        // Load from S3
        this.columnMenuItems = [...this.defaultColumnMenuItems, 'Filter'];
        this.reportViewerService
          .getInitialDataManager()
          .pipe(
            tap((data: any) => {
              console.log('INCOMING DATA: ', data);
              this.queryBuilder.dataSource = [];
            })
          )
          .subscribe(
            (data: any) => {
              if (data.count > this.reportingS3.initialChunkLimit) {
                this.reportViewerService
                  .getInitialDataManager(true)
                  .pipe(
                    tap((data: any) => {
                      console.log('INCOMING DATA2: ', data);
                    })
                  )
                  .subscribe(
                    (_data: any) => {
                      console.log('Data from DataManager: ', _data);
                      this.reportDataManager = new DataManager(_data.result);
                      this.grid.dataSource = this.reportDataManager;
                      this.grid.refresh();
                      this.grid.autoFitColumns();
                      // this.queryBuilder.dataSource = [this.fulfillArrayWithEmptyValues(_data.result[0])];
                      if (this.reportViewerService.selectedView) {
                        this.RunComplexQuery();
                      } else if (this.reportViewerService.filterParamFlag) {
                        console.log('Filter Params: ', this.reportViewerService.filterInParams);
                        const tempFilters: RuleModel = {
                          condition: 'and',
                        };
                        tempFilters.rules =
                          this.reportViewerService.filterInParams.map(
                            (item) => {
                              return {
                                field: item.field,
                                operator: 'equal',
                                value: item.value,
                              } as RuleModel;
                            }
                          );
                        this.queryBuilder.setRules(tempFilters);
                        this.updateFilterCards();
                        this.RunComplexQuery();
                      }
                    },
                    (error) => {
                      console.log('Something Went Wrong: ', error);
                    }
                  );
              }

              //|| NEED TO LOOK HERE IF REGULAR FILTERS ARE NOT WORKING
              this.reportDataManager = new DataManager(data.result);
              this.grid.dataSource = this.reportDataManager;
              this.gridColumnUpdate(Object.keys(data.result[0]));
              this.grid.refresh();
              this.grid.autoFitColumns();
              this.queryBuilder.dataSource = data.result.map((item) => {
                // set all empty values to ''
                return this.fulfillArrayWithEmptyValues(item);
              });
              if (this.reportViewerService.selectedView) {
                this.queryBuilder.setRules(
                  this.reportViewerService.selectedView.filterRules
                );
                this.updateFilterCards();
                this.RunComplexQuery();
              }
            },
            (error) => {
              console.log('Something Went Wrong: ', error);
            },
            () => {
              this.grid.autoFitColumns();
              this.spinnerService.toggle(false);
            }
          );
      }
    });
  }

  public localStateList: any = [];
  public queryDataBound($event) {
    console.log('Query Data Bound: ', $event);
    console.log('Data Bound: ', this.queryBuilder);
  }

  public visibleColumns: string[] = [];
  public currentQueryLogId: number = 0;
  public runQuerySpinner: boolean = false;
  public RunComplexQuery(rules?: RuleModel) {
    this.reportViewerService.pageSize = this.pageSettings.pageSize;
    if (this.reportViewerService.useRDS) {
      this.addQueryToLog();
      this.runQuerySpinner = true;
      const sqlWhere = this.getSQLWhere(rules ? rules : null);
      console.log('SQL WHERE: ', sqlWhere);

      this.reportViewerService
        .runComplexQueryRDS(
          this.queryBuilder.getPredicate(this.queryBuilder.getRules()),
          sqlWhere
        )
        .subscribe(
          (data) => {
            this.updateFilterCards();
            console.log('Data from Complex Query: ', data);
            this.reportDataManager = data;
            if (data.count > 0) {
              this.gridColumnUpdate(Object.keys(data.result[0]));
              this.grid.refresh();
              this.grid.autoFitColumns();
            }
            this.updateQueryLog({
              id: this.currentQueryLogId,
              status: 'success',
              count: data.count,
              query: sqlWhere || 'No Query',
            });
          },
          (error) => {
            console.error('Error Running Complex Query: ', error);
            this.updateQueryLog({
              id: this.currentQueryLogId,
              status: 'failed',
              count: -1,
              query: sqlWhere || 'No Query',
            });
            this.runQuerySpinner = false;
          },
          () => {
            console.log('Completed Running Complex Query');
            this.runQuerySpinner = false;
          }
        );
    } else {
      console.log('Running Local Query');
      this.addQueryToLog();
      const query = new Query();
      const sqlWhere = this.getSQLWhere();
      const predicate: Predicate = this.queryBuilder.getPredicate(
        this.queryBuilder.getRules()
      );
      if (predicate) {
        query.where(predicate);
      }
      from(this.reportDataManager.executeQuery(query)).subscribe(
        (data: any) => {
          const filtersFromGrid = this.grid.filterSettings.columns.map((p$) => {
            return {
              field: p$.field,
              operator: p$.operator,
              value: p$.value,
            } as RuleModel;
          });
          console.log('Filters from Grid: ', filtersFromGrid);
          this.updateFilterCards();
          console.log('Local Query Result: ', data);
          this.grid.dataSource = new DataManager(data.result, data.query);
          this.gridColumnUpdate(Object.keys(data.result[0]));
          this.grid.refresh();
          this.grid.autoFitColumns();
          this.updateQueryLog({
            id: this.currentQueryLogId,
            status: 'success',
            count: data.result.length,
            query: sqlWhere,
          });
        },
        (error) => {
          console.error('Error Running Local Query: ', error);
          this.updateQueryLog({
            id: this.currentQueryLogId,
            status: 'failed',
            count: null,
            query: 'Local Query',
          });
        },
        () => {
          console.log('Completed Running Local Query');
          this.runQuerySpinner = false;
        }
      );
    }
  }
  public state: DataStateChangeEventArgs;
  public dataStateChange(state: DataStateChangeEventArgs) {
    // console.log('Data State Change: ', state);
    if (state.action.requestType === 'refresh') {
      return;
    }
    const sqlWhere = this.getSQLWhere();
    // console.log('SQL WHERE: ', sqlWhere);
    if (this.reportViewerService.useRDS) {
      this.reportViewerService
        .dataStateChangeRDS(
          this.queryBuilder.getPredicate(this.queryBuilder.getRules()),
          state,
          sqlWhere
        )
        .subscribe(
          (data: any) => {
            if (data == null) {
              return;
            }
            console.log('Data State Change Success: ', data);
            this.reportDataManager = data;
            this.grid.refresh();
          },
          (error) => {
            console.error('Data State Change Error: ', error);
          },
          () => {
            console.log('Data State Change Complete');
          }
        );
    }
  }

  getVisibleColumns(): string[] {
    return this.grid
      .getColumns()
      .filter((col: ColumnModel) => col.visible)
      .map((col: Column) => col.field);
  }

  getVisibleColumnsFromView(view: any): string[] {
    console.log('Getting columns from State: ', view);
    return view.columns.filter((col) => col.visible).map((col) => col.field);
    return [];
  }
  // ===========================================================================
  // Type Check and Convert Column Type
  // ===========================================================================

  public selectedRow: any;
  public selectedData: any;
  private initialState: any;
  private currentState: any;
  onRowSelected($event) {
    const fields = Object.keys($event.data);
    const data = fields
      .map((field) => {
        let isJSON = false;
        let parsedData = null;
        try {
          parsedData = JSON.parse($event.data[field]);
          isJSON = true;
        } catch (e) {
          isJSON = false;
        }

        return {
          field: field,
          value: isJSON ? parsedData : $event.data[field],
          isJSON: isJSON,
        };
      })
      .filter((column) => {
        if (this.currentState?.columns.length > 0) {
          return this.currentState.columns.some((stateColumn) => {
            return stateColumn.field === column.field;
          });
        }
        return true;
      });
    //console.log('ROW INFO BASED ON STATE: ', data);
    this.selectedData = data;
  }

  public stateList = [];

  public onReportDropdownSelect($event) {
    console.log('Selected Report: ', $event);
    this.router.navigate([], {
      queryParams: { RRV: $event.itemData.name },
    });
  }

  public onSelectView(view: any) {
    console.log('Selected View: ', view);
    this.viewName = view.name;
    // if (this.reportViewerService.useRDS) {
    if (this.validateRules(view.filterRules)) {
      this.queryBuilder.setRules(view.filterRules);
    } else {
      this.queryBuilder.reset();
    }
    this.visibleColumns = this.getVisibleColumnsFromView(view);
    this.grid.refreshColumns();
    this.notificationService.info('Loading View', 'Loading View');
    this.PredefinedViewSelectDialog.hide();
    this.RunComplexQuery();
    // }
  }

  public onDeleteS3View($event) {
    console.log('Delete S3 View: ', $event);
    this.reportingViews
      .deleteViewFromS3(this.reportViewerService.reportCode, $event.name)
      .subscribe(
        (data) => {
          console.log('Deleted S3 View: ', data);
        },
        (error) => {
          console.error('Error Deleting S3 View: ', error);
        },
        () => {
          this.loadAllStates();
        }
      );
  }
  // ===========================================================================
  // Local View Actions
  // ===========================================================================

  public selectedViewName: string;

  public onClearFiltering() {
    this.grid.clearSorting();
    this.grid.clearFiltering();
    this.grid.clearGrouping();
    this.queryBuilder.reset();
    this.updateFilterCards();
    this.RunComplexQuery();
  }
  public ParseFromSQL() {
    const sqlString = $('#sqlQuery').val();
    console.log('SQL String: ', sqlString);
    this.queryBuilder.setRules(this.queryBuilder.getRulesFromSql(sqlString));
  }
  public sqlQuery: string = '';
  public GetSQLFromBuilder() {
    if (this.queryBuilder.getRules().rules.length === 0) {
      this.notificationService.error('Error Getting SQL', 'No Rules Defined');
      return;
    }
    const sqlString = this.queryBuilder.getSqlFromRules(this.queryBuilder.rule);

    $('#sqlQuery').val(sqlString);
  }
  // ===========================================================================
  // Predefined View Actions
  // ===========================================================================
  @ViewChild('createPredefinedStateName')
  createPredefinedStateName: TextBoxComponent;
  public cpsnSpinner: boolean = false;
  public cspnIsEmpty: boolean = false;
  public onAddS3State() {
    // opens modal for name and captures state
    this.cpsnSpinner = true;
    const value = this.createPredefinedStateName.value as string;
    console.log('State Name: ', value);
    if (value === '' || value === null) {
      this.notificationService.error(
        'Error Saving State',
        'State name cannot be empty'
      );
      this.cpsnSpinner = false;
      this.cspnIsEmpty = true;
      return;
    } else {
      this.cspnIsEmpty = false;
    }
    this.reportingViews
      .saveViewListToS3(
        this.reportViewerService.reportCode,
        value,
        this.grid.getPersistData(),
        this.queryBuilder.getRules()
      )
      .subscribe(
        (data) => {
          console.log('Saved State: ', data);
        },
        (error) => {
          console.error('Error Saving State: ', error);
        },
        () => {
          this.cpsnSpinner = false;
          this.loadAllStates();
        }
      );

    //   this.reportingViews
    //     .saveStateListToS3(this.reportCode, {
    //       name: name,
    //       state: JSON.parse(this.grid.getPersistData()),
    //       isSimpleFilter: this.isSimpleFilter,
    //       complexFilterRules: this.isSimpleFilter
    //         ? this.queryBuilder.getRules()
    //         : null,
    //     })
    //     .then(() => {
    //       this.notificationService.success(
    //         `State Saved as ${name}`,
    //         'State has been saved successfully'
    //       );
    //     })
    //     .catch(() => {
    //       this.notificationService.error(
    //         'Error Saving State',
    //         'Error Saving State'
    //       );
    //     })
    //     .finally(() => {
    //       this.cpsnSpinner = false;
    //       this.spinnerService.toggle(false);
    //       this.loadAllStates();
    //     });
  }
  // ===========================================================================
  // Loads all the states from S3 and Local Storage for Use
  // ===========================================================================
  private initialLoad: boolean = true;
  public loadAllStates() {
    this.reportViewerService.localViewListFromLocalStorage.subscribe(
      (data) => {
        this.localViewList = data;
      },
      () => {
        console.log('Error Loading Local Views');
        this.spinnerService.toggle(false);
      },
      () => {
        this.spinnerService.toggle(false);
      }
    );
    this.reportViewerService.s3ViewListFromS3.subscribe(
      (data) => {
        this.s3ViewList = data;
      },
      () => {
        console.log('Error Loading S3 Views');
        this.spinnerService.toggle(false);
      },
      () => {
        this.spinnerService.toggle(false);
      }
    );
    this.spinnerService.toggle(true, 'Loading All States');
  }

  public addViewToLibrary(data: any) {
    //this.spinnerService.toggle(true);
    let reportLibraryConfig: any[] = [];
    this.reportingLibrary
      .getReportLibraryConfig()
      .pipe(
        map((config: any) => {
          reportLibraryConfig = config;
          console.log('Report Library Config: ', reportLibraryConfig);
          if (
            reportLibraryConfig &&
            reportLibraryConfig.some(
              (config) =>
                config.reportCode === this.reportCode &&
                config.isView &&
                config.title === data.name
            )
          ) {
            this.notificationService.error(
              'Error Adding View',
              'View Already Exists'
            );
            throw new Error('View Already Exists');
          }
          return reportLibraryConfig;
        }),
        switchMap((config: any) => {
          reportLibraryConfig.push({
            reportCode: this.reportViewerService.reportCode,
            hidden: false,
            isView: true,
            isCustom: false,
            title: data.name,
            description: this.reportTitle,
            icon: 'cookie-tracker',
          });
          return this.reportingLibrary.saveReportLibraryConfigToS3(
            reportLibraryConfig
          );
        })
      )
      .subscribe(
        () => {
          this.notificationService.success(
            'Success',
            'View Added Successfully'
          );
          this.spinnerService.toggle(false);
        },
        (error) => {
          console.error('Error adding view to library:', error);
          this.spinnerService.toggle(false);
        }
      );
  }
  // ===========================================================================
  // On Grid Action Complete
  // ===========================================================================
  public onActionComplete($event) {
    // console.log('Action Complete: ', $event);
  }

  public onActionBegin($event) {
    console.log('Action Begin: ', $event);
    if (!this.reportViewerService.useRDS) {
      if ($event.requestType === 'filtering') {
        this.updateFilterCards();
      }
    }
  }

  public onColumnMenuClick($event) {
    console.log('Column Menu Click: ', $event);
    if ($event.item.id === 'ComplexQuery') {
      this.ComplexQueryDialog.show();
    }
  }

  public updateFilterCards() {
    const filtersFromGrid =
      this.grid.filterSettings.columns.map((p$) => {
        return {
          field: p$.field,
          operator: p$.operator,
          value: p$.value,
        } as RuleModel;
      }) || [];
    const filtersFromQB =
      this.rulesToListOfFields(this.queryBuilder.getRules()) || [];
    console.log('Filters from Grid: ', filtersFromGrid);
    this.filterParamsList = [...filtersFromQB, ...filtersFromGrid];
  }

  public onActionFailure($event) {
    // console.log('Action Failure: ', $event);
  }

  onQueryChange($event: any): void {
    const predicate: Predicate = this.queryBuilder.getPredicate(
      this.queryBuilder.rule
    );
    console.log('Predicate: ', predicate);
  }

  // ===========================================================================
  // View UI Components and Methods
  // ===========================================================================

  onBeforeOpen(args: any): void {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    args.element.style.top = `${
      args.element.getBoundingClientRect().top + scrollTop
    }px`;
    args.element.style.left = `${
      args.element.getBoundingClientRect().left + scrollLeft
    }px`;
  }

  public exportProperties: ExcelExportProperties = {
    exportType: 'AllPages',
  };
  // ===========================================================================
  // On Toolbar Click
  // ===========================================================================
  // ===========================================================================
  // For Rendering Tooltip upon header hover for column description
  // ===========================================================================
  @ViewChild('tooltip') toolTip?: TooltipComponent;
  public beforeRender(args: TooltipEventArgs): void {
    if (!(args.target && args.target.innerText)) {
      return;
    }
    let description = '';
    if (this.dictionary && this.dictionary.length > 0) {
      const findDictEntry = this.dictionary.find((entry) => {
        return (
          entry.field.toLowerCase() === args.target.innerText.toLowerCase()
        );
      });
      if (findDictEntry) {
        description = findDictEntry.description;
        if (
          findDictEntry.overrideHeader &&
          findDictEntry.overrideHeader !== '' &&
          findDictEntry.overrideHeader !== null
        ) {
          description = findDictEntry.overrideHeader;
        }
        (this.toolTip as TooltipComponent).content = description;
      } else {
        description = 'No Description Available';
        (this.toolTip as TooltipComponent).content = description;
      }
    } else {
      description = 'No Description Available';
      (this.toolTip as TooltipComponent).content = description;
    }
  }

  // ===========================================================================
  // Filter Button Actions
  // ===========================================================================
  public filterColumnList: any = [];
  removeFilterByField(field: string) {
    this.grid.removeFilteredColsByField(field);
    this.filterColumnList = this.grid.filterSettings.columns.map(
      (filter) => filter.field
    );
  }

  // ===========================================================================
  // Grid Settings and Methods
  // ===========================================================================

  public filterSettings: FilterSettings = {
    type: 'Excel',
    mode: 'OnEnter',
  } as FilterSettings;
  public groupSettings: GroupSettingsModel = { showDropArea: false };
  private defaultColumnSettings: Partial<ColumnModel> = {
    maxWidth: this.maxColumnWidth,
    minWidth: 100,
    width: 250,
    clipMode: 'EllipsisWithTooltip',
  };
  public pageSettings: PageSettingsModel = {
    pageSize: 100,
    pageSizes: ['100', '500', '1000', '5000'],
    currentPage: 1,
  };

  private _columns: ColumnsModel[] = [];
  public qbColumns: ColumnsModel[] = [];

  public get columns(): ColumnsModel[] {
    return this._columns;
  }
  public set columns(value: ColumnsModel[]) {
    this._columns = value;
  }
  public gridColumnUpdate(columns: string[]): void {
    console.log('Updating Grid Columns with fields: ', columns);
    if (columns === null || columns.length === 0) return;
    this.columns = columns.map((col: string) => {
      let isVisible = true;
      if (this.visibleColumns.length > 0) {
        isVisible = !!this.visibleColumns.find((column) => column === col);
      }
      if (this.dictionary && this.dictionary.length > 0) {
        const findIndex = this.dictionary.find((entry) => {
          if (!entry.field) {
            return false;
          }
          return entry.field?.toLowerCase() === col?.toLowerCase();
        });
        if (findIndex) {
          return {
            headerText: findIndex.overrideHeader || col,
            field: col,
            label: findIndex.overrideHeader || col,
            type: findIndex.type || 'string',
            visible: isVisible,
            ...this.defaultColumnSettings,
          } as ColumnsModel;
        }
      }
      return {
        headerText: col,
        field: col,
        label: col,
        type: 'string',
        visible: isVisible,
        ...this.defaultColumnSettings,
      } as ColumnsModel;
    });
    const updatedColumns = this.columns.map((col) => {
      if (col.type === 'datetime' || col.type === 'date') {
        const temp = { ...col } as ColumnsModel;
        temp.template = this.dateTemplate;
        temp.type = 'date';
        temp.format = 'MM/dd/yyyy hh:mm:ss:fff a';
        return temp;
      }
      return { ...col } as ColumnsModel;
    });

    Object.assign(this.qbColumns, updatedColumns);

    console.log('XXXX Columns: ', this.columns);
  }
  // ===========================================================================
  // Local View Actions
  // ===========================================================================
  public dialogObj: Dialog;

  public onCreateLocalView(): void {
    this.LocalViewSelectDialog?.hide();
    this.dialogObj = DialogUtility.confirm({
      title: 'New View',
      content:
        '<p>Do you want to add a new view to Local Storage?</p><input style="padding: 5px 15px; border-radius: .25rem; border: 1px solid lightgrey" id="addNewLocalView" placeholder="Add View Name" />',
      okButton: {
        text: 'Save',
        click: this.newLocalView.bind(this),
      },
      cancelButton: {
        text: 'Cancel',
      },
    });
  }
  public onDeleteLocalView(viewName: string): void {
    this.dialogObj = DialogUtility.confirm({
      title: 'New View',
      content: 'Do you want to delete the selected view from Local Storage?',
      okButton: {
        text: 'Yes, Delete',
        click: this.deleteLocalView.bind(this, viewName),
      },
      cancelButton: {
        text: 'Cancel',
      },
    });
  }
  public onSelectLocalView(view: any): void {
    // Only Local View Related Settings
    console.log('Selected View: ', view);
    this.viewName = view.name;
    if (this.reportViewerService.useRDS) {
      if (this.validateRules(view.filterRules)) {
        this.queryBuilder.setRules(view.filterRules);
      } else {
        this.queryBuilder.reset();
      }
      this.visibleColumns = this.getVisibleColumnsFromView(view);
      this.notificationService.info('Loading View', 'Loading View');
      this.LocalViewSelectDialog.hide();
      this.RunComplexQuery();
    } else {
      this.queryBuilder.setRules(view.filterRules);
      this.visibleColumns = this.getVisibleColumnsFromView(view);
      this.updateFilterCards();
      this.RunComplexQuery();
    }
  }
  public newLocalView(): void {
    // ADD NEW LOCAL VIEW
    const el = document.getElementById('addNewLocalView') as HTMLInputElement;
    if (el) {
      if (el.value === '') {
        el.style.border = '1px solid red';
      } else {
        el.style.border = '1px solid lightgrey';
        const value = el.value;
        this.spinnerService.toggle(true);
        this.dialogObj.hide();
        this.reportingViews
          .saveViewListToLocalStorage(
            this.reportViewerService.reportCode,
            value,
            this.grid.getPersistData(),
            this.queryBuilder.getRules()
          )
          .subscribe(
            () => {
              this.loadAllStates();
              this.notificationService.success(
                'State Saved',
                'State has been saved successfully'
              );
            },
            (error) => {
              this.stopOnError('Error Saving State', 'Something Went Wrong');
            },
            () => {
              this.spinnerService.toggle(false);
            }
          );
      }
    } else {
      this.stopOnError('Error Saving State', 'Something Went Wrong');
    }
    return;
  }
  public deleteLocalView(viewName: string): void {
    // DELETE LOCAL VIEW
    this.reportingViews
      .deleteViewListFromLocalStorage(this.reportCode, viewName)
      .subscribe(
        () => {
          this.dialogObj.hide();
          this.loadAllStates();
          this.notificationService.success(
            'State Deleted',
            'State has been deleted successfully'
          );
        },
        (error) => {
          this.notificationService.error(
            'Error Deleting State',
            'Error Deleting State'
          );
        }
      );
  }

  // =============================================================================
  // Export FROM RDS
  // =============================================================================
  @ViewChild('exportCustomName') exportCustomName: TextBoxComponent;
  public exportQuery: string = '';
  public exportColumns: any = [];
  public onExportReport() {
    this.exportColumns = this.getVisibleColumns().map((col) => {
      return {
        columnName: col,
      };
    });
    this.exportQuery = this.getSQLWhere();
    this.ExportDialog.show();
  }

  public exportReport(): void {
    this.ExportDialog.hide();
    const name = this.exportCustomName.value || '';
    if (this.reportViewerService.useRDS) {
      this.spinnerService.toggle(true, 'Queuing Export Job');
      const columns = this.getVisibleColumns();
      const sqlWhere = this.exportQuery;
      this.reportViewerService.exportData(columns, sqlWhere, name).subscribe(
        (data: any) => {
          this.spinnerService.toggle(false);
          console.log('Export Started: ', data);
          this.dialogObj = DialogUtility.confirm({
            title: 'Export Started',
            content: '<div><p>Export has been started</p></div>',
            okButton: {
              text: 'Go to Export Center',
              click: () => {
                this.dialogObj.hide();
                this.router.navigate(['/export-center']);
              },
            },
            cancelButton: {
              text: 'Return to Report',
              click: () => {
                this.dialogObj.hide();
              },
            },
          });
        },
        (error: any) => {},
        () => {
          this.spinnerService.toggle(false);
        }
      );
    } else {
      if (!name || name === '') {
        this.exportProperties.fileName = `VJS-${
          this.reportViewerService.reportCode
        }-Export-${new Date().toISOString()}.csv`;
      } else {
        this.exportProperties.fileName = name + '.csv';
      }
      this.spinnerService.toggle(true, 'Exporting Data');
      from(this.grid.csvExport(this.exportProperties)).subscribe(
        (data) => {
          console.log('Export Data: ', data);
        },
        (error) => {
          console.error('Error Exporting Data: ', error);
        },
        () => {
          this.spinnerService.toggle(false);
          console.log('Export Complete');
        }
      );
    }
  }

  // ===========================================================================
  // Dialogs and Toolbar Button Actions
  // ===========================================================================
  @ViewChild('ComplexQueryDialog') ComplexQueryDialog?: DialogComponent;
  @ViewChild('ReportViewConfigDialog') ReportViewConfigDialog?: DialogComponent;
  @ViewChild('LocalViewSelectDialog') LocalViewSelectDialog?: DialogComponent;
  @ViewChild('PredefinedViewSelectDialog')
  PredefinedViewSelectDialog?: DialogComponent;
  @ViewChild('ColumnSelectorDialog') ColumnSelectorDialog?: DialogComponent;
  @ViewChild('ExportDialog') ExportDialog?: DialogComponent;

  public openComplexQueryDialog() {
    this.ComplexQueryDialog?.show();
  }
  public openReportViewConfigDialog() {
    this.ReportViewConfigDialog?.show();
  }
  public openLocalViewSelectDialog() {
    this.LocalViewSelectDialog?.show();
  }
  public openPredefinedViewSelectDialog() {
    this.PredefinedViewSelectDialog?.show();
  }
  public openColumnSelectorDialog() {
    // this.ColumnSelectorDialog?.show();
    this.grid?.columnChooserModule.openColumnChooser();
    // Override width and maxWidth
    const el = document.querySelector(
      'div[aria-label="Column chooser dialog"]'
    ) as HTMLElement;
    el.style.width = 'fit-content';
    el.style.maxWidth = '500px';
  }
  public openExportDialog() {
    this.ExportDialog?.show();
  }
  public doAutoFit() {
    this.grid.autoFitColumns();
  }
  // ===========================================================================
  // RDS Mapping Settings
  // ==========================================================================
  private setRDSMappingObj(data: any) {
    const temp = {};
    data.forEach((item) => {
      // key already exists the throw error
      if (temp[item.reportName]) {
        this.notificationService.error(
          'Duplicate RDS Mapping',
          'Duplicate RDS Mapping'
        );
        return;
      } else {
        temp[item.reportName] = item.tableName;
      }
    });
    return temp;
  }
  public rdsToolbarClick(args: any) {
    console.log('RDS Toolbar Click: ', args);
  }
  public async onRDSActionBegin(args: any) {
    console.log('RDS Action Begin: ', args);
  }
  public async onRDSActionComplete(args: any) {
    console.log('RDS Action Complete: ', args);
    if (args.requestType === 'save') {
      console.log('RDS Mapping List: ', this.rdsMappingList);
      console.log(
        'RDS Mapping Obj: ',
        this.setRDSMappingObj(this.rdsMappingList)
      );
      this.spinnerService.toggle(true, 'Saving RDS Mapping');
      await this.reportingS3.setRDSMappingToS3(
        this.setRDSMappingObj(this.rdsMappingList)
      );
      this.spinnerService.toggle(false);
    }
  }

  // ===========================================================================
  // Query Logger
  // ===========================================================================
  public rightPaneAnimationSettings: Object = {
    effect: 'SlideRight',
    duration: 150,
  };
  public dialogAnimationSettings: Object = {
    effect: 'Fade',
    duration: 150,
  };
  @ViewChild('QueryLogGrid') queryLogGrid?: GridComponent;
  addQueryToLog() {
    const lastId =
      this.queryLog.length > 0 ? this.queryLog[this.queryLog.length - 1].id : 0;
    const timestamp = new Date();
    const status = 'running';
    this.currentQueryLogId = lastId + 1;
    const newQuery = {
      id: this.currentQueryLogId,
      query: null,
      timestamp: timestamp,
      status: status,
      count: null,
    };
    this.queryLog.push(newQuery);
    this.queryLogGrid?.refresh();
  }

  updateQueryLog(_log: any) {
    this.queryLog = this.queryLog.map((log) => {
      if (log.id === _log.id) {
        log.status = _log.status;
        log.count = _log.count;
        log.query = _log.query;
      }
      return log;
    });
    this.queryLogGrid?.refresh();
  }

  public showLogger: boolean;
  onLoggerToggle() {
    console.log('Logger Toggled: ', this.showLogger);
    this.showLogger = !this.showLogger;
  }

  // ===========================================================================
  // Simple Helpers
  // ===========================================================================

  public splitOthersToTags(value: string): string[] {
    if (!value) return [];
    return value.split('_').map((tag) => tag.trim());
  }
  private stopOnError(title, message) {
    this.notificationService.error(title, message);
    this.spinnerService.toggle(false);
  }

  public visibleColumnsToString(cols: any): number {
    if (!cols) return 0;
    return cols.filter((col) => col.visible).length;
  }

  public fulfillArrayWithEmptyValues(obj) {
    const out = { ...obj };
    const keys = Object.keys(out);
    keys.forEach((key) => {
      if (!out[key]) {
        out[key] = '';
      }
    });
    return out;
  }

  public getSQLWhere(rules?: RuleModel): string {
    if (!rules) {
      rules = this.queryBuilder.getRules();
    }
    const validRule = this.validateRules(rules);
    console.log('Valid Rule: ', validRule, 'Rules: ', rules);
    const sqlWhere = validRule ? this.queryBuilder.getSqlFromRules(rules) : '';
    return sqlWhere;
  }
  private validateRules(rules: RuleModel): boolean {
    return rules.rules.length > 0 && rules.rules[0].operator !== '';
  }

  public rulesToListOfFields(
    rules: RuleModel
  ): { field: string; value: string; operator: string }[] {
    //should work for both simple and complex rules - needs to be recursive
    console.log('Rules to List of Fields: ', rules);
    const fields: { field: string; value: string; operator: string }[] = [];
    const getFields = (rule: RuleModel) => {
      if (rule.rules) {
        rule.rules.forEach((r) => {
          getFields(r);
        });
      } else {
        if (rule.field !== null && rule.field !== '') {
          fields.push({
            field: rule.field,
            value: rule?.value?.toString(),
            operator: rule.operator,
          });
        }
      }
    };
    getFields(rules);
    console.log('Fields: ', fields);
    return fields;
  }

  // ===========================================================================
  // Query builder templates
  dateTemplate: TemplateColumn = {
    create: () => {
      console.log('Creating DateTimePicker');
      return createElement('input', { attrs: { type: 'Date' } });
    },
    destroy: (args: { elementId: string }) => {
      let datetime: DateTimePicker = getComponent(
        document.getElementById(args.elementId),
        'datetimepicker'
      ) as DateTimePicker;
      if (datetime) {
        console.log('Destroying DateTimePicker');
        datetime.destroy();
      }
    },
    write: (args: { elements: Element; values: Date }) => {
      let today: Date = new Date();
      let currentYear: number = today.getFullYear();
      let currentMonth: number = today.getMonth();
      let currentDay: number = today.getDate();
      let dateTimeInstance: DateTimePicker = new DateTimePicker({
        value: new Date(currentYear, currentMonth, currentDay, 0, 0,0,0),
        format: 'MM/dd/yyyy hh:mm:ss:fff a',
        change: (args: any) => {
          console.log('Change Event: ', args);
          this.queryBuilder.notifyChange(args.value, args.element);
        }
      });

      dateTimeInstance.appendTo('#' + args.elements.id);
    },
  };// ===========================================================================

}
