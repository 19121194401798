import { Component, OnInit, ElementRef, SecurityContext, ViewChild } from '@angular/core';
import { User } from '../../models/User';
import { Subscription } from '../../../../node_modules/rxjs';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { SitemapService } from '../../services/sitemap/sitemap.service';
import { UserService } from '../../services/users/user.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { AuthService } from '../../services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TimezoneService } from '../../services/timezone.service';
declare var $:any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users:any[];
  usersLoading:boolean;
  usersSub:Subscription;

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('usersGrid') public grid: GridComponent;
  //return `<span style="cursor: pointer;">${ this.sanitizer.sanitize(SecurityContext.HTML, data)}</span>`;
  constructor(public authService:AuthService, 
              private el: ElementRef, 
              private route:ActivatedRoute, 
              private router:Router, 
              private sitemapService:SitemapService, 
              private usersService:UserService, 
              private spinnerService:SpinnerService,
              private sanitizer: DomSanitizer,
              public timezoneService:TimezoneService) { 
    this.sitemapService.update(route);
    this.usersLoading = true;
    this.spinnerService.toggle(true);
    this.dateFormat = {type: 'dateTime', skeleton:'short'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'user_metadata.lastName', direction: 'Ascending'}
      ]
    }
  }

  ngOnInit() {
    
    var includeVaultUsers = false;
    const currentUserEmail:string = JSON.parse(localStorage.profile).email;
    if ( currentUserEmail.endsWith("@vaultjs.com")){
      includeVaultUsers = true;
    }

    this.usersSub = this.usersService.getUsers(includeVaultUsers).subscribe( (users) => {

      this.usersLoading = false;
      this.spinnerService.toggle(false);
      this.users = users.filter((u)=>{
        if ( u.blocked == null || u.blocked == false) {
          return true;
        }
        return false;
      }).map((u)=>{
        return {...u, "logins_count": (u["logins_count"] >= 0  ? u["logins_count"] : 0) };
      })
    });
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }
  
  ngOnDestroy(){
    this.usersSub.unsubscribe();
  }

  createUser(){
    console.log('New user click');
    this.router.navigate(["/admin/users/new"]);
  }

  addUser(){
    console.log('Existing user click');
    this.router.navigate(["/admin/users/existing"]);
  }

  rolesArrayToString(roles:any[]){
    return roles.map((r)=>{ return r.name}).join(", ");
  }

}
