import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { SitemapService } from '../../services/sitemap/sitemap.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../../services/alerts/alerts.service';
import { Observable, Subject, Subscription } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-alerting',
  templateUrl: './alerting.component.html',
  styleUrls: ['./alerting.component.css']
})
export class AlertingComponent implements OnInit, AfterViewInit, OnDestroy {

  alerts:any[];
  alertsLoading:boolean;
  alertsSub:Subscription;
  tooltipContent:string;

  private alertsTable: any;
  private tableWidget: any;

  constructor(private el: ElementRef, private route:ActivatedRoute, private router:Router, private sitemapService:SitemapService, private alertsService:AlertsService) { 
    this.sitemapService.update(route);
    this.alertsLoading = true;
    this.tooltipContent = "The events you subscribe to will show up here.";
  }

  ngOnInit() {
    this.alertsSub = this.alertsService.getAlerts().subscribe( (alerts)=>{
      this.alertsLoading = false;
      this.alerts = alerts;
      if ( this.tableWidget ){
        this.tableWidget.destroy();
      } else {
        let tableOptions: any = {
          dom:
            "<'row be-datatable-header'<'col-sm-4'><'col-sm-8'f>>" +
            "<'row be-datatable-body'<'col-sm-12'tr>>" +
            "<'row be-datatable-footer'<'col-sm-4'l><'col-sm-8'p>>",
          data: this.alerts,          
          select: {
            style: 'os',
            items: 'cell',
            info: false
          },
          columns: [
              { 
                title: 'Title', 
                data: 'title',
                render: function(data, type, row, meta){
                  if (type == 'display') {
                    return `<span style="cursor: pointer; font-weight: bold;">${data}</span><br/>${row.subTitle}`;
                  }
                  return data;                  
                }
              },
              {
                title: 'Date',
                data: 'date',
                render:{
                  display: function(data, type, row, meta){
                    return new Date(data).toLocaleString();
                  }
                }
              }            
          ],
          "lengthMenu": [15, 25, 50, 75, 100],
          "language": {
            "loadingRecords": "Please wait - loading...",
            "zeroRecords": "You do not have any alerts..."
          },
          order:[
            [1, 'desc']
          ]
      };
      this.alertsTable = $(this.el.nativeElement.querySelector('table'));
      this.tableWidget = this.alertsTable.DataTable(tableOptions);

      $('#alertsTable').on('select.dt', (e, dt, type, indexes) => {        
        if ( indexes.length == 1 && indexes[0].column == 0) {
          const row = indexes[0].row;
          const alert = this.alerts[row];
          console.log(row, alert);
          this.router.navigate(["/alerts/details/", alert.id]);
        }
      });
      }
    });
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }
  
  ngOnDestroy(){
    this.alertsSub.unsubscribe();
  }

}
