import { Component, OnInit, OnDestroy } from '@angular/core';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { environment as env } from '../../../../environments/environment';
import { Router } from '@angular/router';
// import { DataExportService } from '../../../services/data-export/data-export.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ReportingS3Service } from '../../../services/reporting-s3/reporting-s3.service';
import { Papa } from 'ngx-papaparse';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';
import { forkJoin } from 'rxjs';
import { TrackerDashboardService } from '../../../services/tracker-dashboard/tracker-dashboard.service';
interface TrackerSummary {
  pageDomain: string;
  calculatedVendorName: string;
  domainTotalVendors: number;
  vendorTotalTrackers: number;
  summaryTotalTrackers: number;
  summaryTotalCodeTrackers: number;
  summaryTotalDataTrackers: number;
  summaryTotalCookieTrackers: number;
  vendorTrackerCategories: string;
  totalPages: number;
}

@Component({
  selector: 'app-tracker-summary-dashboard',
  templateUrl: './tracker-summary-dashboard.component.html',
  styleUrls: ['./tracker-summary-dashboard.component.css'],
})
export class TrackerSummaryDashboardComponent implements OnInit, OnDestroy {
  public cdnUrl: string;
  private cid: number;
  public isLoading: boolean = true;
  private currentFile: string = '';
  constructor(
    private vendorService: VendorsService,
    private router: Router,
    private authService: AuthService,
    private papa: Papa,
    private spinnerService: SpinnerService,
    private trackerDashboard: TrackerDashboardService
  ) {
    this.spinnerService.toggle(true);
    this.cid = parseInt(this.authService.customerId());
  }
  public trackerColumns = [
    {
      field: 'totalCodeTrackers',
      headerText: 'Code',
      textAlign: 'center',
      allowResizing: false,
    },
    {
      field: 'totalDataTrackers',
      headerText: 'Data',
      textAlign: 'center',
      allowResizing: false,
    },
    {
      field: 'totalCookieTrackers',
      headerText: 'Cookie',
      textAlign: 'center',
      allowResizing: false,
    },
  ];
  ngOnInit(): void {
    this.cdnUrl = env.cdn.url;
    this.loadData();
  }
  ngOnDestroy(): void {}

  public uniqueVendors: number = 0;
  public uniqueDomains: number = 0;
  public uniqueTrackers: number = 0;
  public uniqueCodeTrackers: number = 0;
  public uniqueDataTrackers: number = 0;
  public uniqueCookieTrackers: number = 0;

  public data: TrackerSummary[] = [];
  public domainGroupedData: any = [];
  private vendorList: any = [];
  private loadData(): void {
    this.isLoading = true;
    forkJoin([
      this.vendorService.getAllVendors(),
      this.trackerDashboard.getTrackerSummary(),
      this.trackerDashboard.getTrackerSummaryDomain(),
      this.trackerDashboard.getTrackerSummaryDomainVendor(),
    ]).subscribe((values) => {
      console.log('Values: ', values);
      this.vendorList = values[0];
      const trackersummary = values[1];
      const trackersummarydomain = values[2];
      const trackersummarydomainvendor = values[3];
      this.uniqueVendors = trackersummary.uniquevendors;
      this.uniqueDomains = trackersummary.uniquedomains;
      this.uniqueTrackers = trackersummary.summarytotaltrackers;
      this.uniqueCodeTrackers = trackersummary.summarytotalcodetrackers;
      this.uniqueDataTrackers = trackersummary.summarytotaldatatrackers;
      this.uniqueCookieTrackers = trackersummary.summarytotalcookietrackers;
      trackersummarydomain.sort((a, b) => b.totalpages - a.totalpages);
      this.domainGroupedData = trackersummarydomain;
      this._allHostnameVendorsData = trackersummarydomainvendor.map((row) => {
        const findVendorLogo = this.vendorList.find(
          (vendor) => vendor.name === row.calculatedvendorname
        );
        if (findVendorLogo) {
          row.website = findVendorLogo.website;
        }
        if (
          row.trackercategories &&
          typeof row.trackercategories !== 'boolean'
        ) {
          row.trackercategories = row.trackercategories.split(', ');
        } else {
          row.trackercategories = [];
        }
        return row;
      });
      this.isLoading = false;
      this.spinnerService.toggle(false);
    });
  }
  public hasPages: boolean = false;
  public trackersByDomain: any[] = [];

  private trackerCats = ['Code Tracker', 'Data Tracker', 'Cookie Tracker'];
  mapCategories(categories: string) {
    const temp = categories.split(', ');
    return this.trackerCats.map((cat) => {
      if (temp.includes(cat)) {
        return cat;
      } else {
        return 'none';
      }
    });
  }
  goToVendorDetails(vendorName: string) {
    this.router.navigate(['/reports/tracker-vendor-dashboard'], {
      queryParams: { v: vendorName },
    });
  }
  goToDetailCodeReport(domain, vendor) {
    this.router.navigate(['/reports/tracker-report-viewer/TS'], {
      queryParams: {
        pageHostname: domain,
        calculatedVendorName: vendor,
      },
    });
  }
  goToDetailCookieReport(domain, vendor) {
    this.router.navigate(['/reports/tracker-report-viewer/CTD'], {
      queryParams: {
        pageHostname: domain,
        calculatedVendorName: vendor,
      },
    });
  }
  goToDetailDataReport(domain, vendor) {
    this.router.navigate(['/reports/tracker-report-viewer/DTD'], {
      queryParams: {
        pageHostname: domain,
        calculatedVendorName: vendor,
      },
    });
  }
  goToCategoryDetails(cat, domain, vendor) {
    switch (cat) {
      case 'Data Tracker':
        this.goToDetailDataReport(domain, vendor);
        break;
      case 'Cookie Tracker':
        this.goToDetailCookieReport(domain, vendor);
        break;
      case 'Code Tracker':
        this.goToDetailCodeReport(domain, vendor);
        break;
    }
  }
  private interpolateColor(color1, color2, factor) {
    let result = '#';
    for (let i = 0; i < 3; i++) {
      let value1 = parseInt(color1.substring(1 + i * 2, 3 + i * 2), 16);
      let value2 = parseInt(color2.substring(1 + i * 2, 3 + i * 2), 16);
      let value = Math.round(value1 + (value2 - value1) * factor).toString(16);
      result += ('0' + value).slice(-2); // Pad with zero if necessary
    }
    return result;
  }
  public baseColors = ['#00dbff', '#6390ee', '#0e1227', '#e5ebe8'];

  private generateMultiGradientColors(numItems, baseColors) {
    let colors = [];
    const numSections = baseColors.length - 1;
    if (numItems > 1) {
      for (let i = 0; i < numItems; i++) {
        const sectionLength = 1 / numSections;
        const position = (i / (numItems - 1)) * numSections; // Position in the gradient
        const index = Math.floor(position); // Determine which color to start from
        const factor = position - index; // Factor of interpolation within the section

        let color = this.interpolateColor(
          baseColors[index],
          baseColors[Math.min(index + 1, baseColors.length - 1)],
          factor
        );
        colors.push(color);
      }
    } else if (numItems === 1) {
      // Only one item, use the first color
      colors.push(baseColors[0]);
    }
    return colors;
  }
  private _allHostnameVendorsData: any = [];
  public selectedHostnameDetails: any = {
    pagehostname: '',
    vendorDetails: [],
  };
  onHostnameSelected($event: any) {
    const pieColors = this.generateMultiGradientColors(10, this.baseColors);
    console.log($event);
    const hostname = $event.data.pagehostname;
    this.selectedHostnameDetails.pagehostname = hostname;

    const _temp  =this._allHostnameVendorsData.filter(
        (row) => row.pagehostname === hostname
      );
    _temp.sort((a, b) => b.totaltrackers - a.totaltrackers);
    this.selectedHostnameDetails.vendorDetails = _temp;
    this.selectedHostnameDetails.pieData =  this.selectedHostnameDetails.vendorDetails.map((child, index) => {
        return {
          label: child.calculatedvendorname,
          value: child.totaltrackers || 1,
          color: pieColors[index] || '#000',
          children: [],
        };
      });
    console.log(this.selectedHostnameDetails);
  }
}
