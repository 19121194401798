import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../../../../services/customer/customer.service';
import { EditService, ToolbarService, PageService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../../services/spinner/spinner.service';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [ToolbarService, EditService, PageService]
})
export class ReportsComponent implements OnInit {

  reports:any;
  public editSettings: Object;
  toolbar:any;
  
  @ViewChild('grid') 
  public grid: GridComponent;

  constructor(protected customerService:CustomerService, public spinnerService:SpinnerService) { 
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true , newRowPosition: 'Bottom', mode:'Batch' };
    this.toolbar = ['Add', 'Delete', 'Update', 'Cancel'];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.spinnerService.toggle(true);
    this.customerService.getScheduledReports().subscribe((reports)=>{
      reports = reports.filter((item)=>{
        return item.name.startsWith("menu:") == false;
      })
      console.log(reports);
      this.reports = reports;
      this.spinnerService.toggle();
    });
  }

  beforeBatchSave(args){
    console.log(args);

    this.spinnerService.toggle(true);

    this.customerService.updateScheduledReports(args.batchChanges).subscribe((results)=>{
      this.spinnerService.toggle();
      this.loadData();
    });
  }

}
