// TODO: SWAP COL ORDER TO BE BETTER

export const CUSTOM_COLUMNS = {
  161: ['ishtmlcommenttag', 'isdonottracklayout'],
  201: ['ishtmlcommenttag', 'isdonottracklayout'],
}
 const TS_COLUMNS = [
  'calculatedvendorname',
  'configid',
  'configname',
  'ddgresourceapis',
  'ddgvendorcategory',
  'ddgvendordisplayname',
  'doesaccessminimalfingerprintingsignals',
  'doesreadhttpcookie',
  'doesreadinputs',
  'doesreadjavascriptcookie',
  'doesreadlocalstorage',
  'doesreadsessionstorage',
  'doeswritehttpcookie',
  'doeswritejavascriptcookie',
  'doeswritelocalstorage',
  'doeswritesessionstorage',
  'initiatorchainhostnames',
  'isinthirdpartyiframe',
  'isthirdparty',
  'isthirdpartytoframe',
  'istrackercode',
  'istrackercookie',
  'istrackerdata',
  'pagedomain',
  'pagehostname',
  'pageurl',
  'parentiframedomain',
  'region',
  'regionname',
  'requestdocumenthostname',
  'requesthostname',
  'requestpath',
  'requestuuid',
  'resourcematcherid',
  'totalfingerprintsignals',
  'totalinputreads',
];

export const CTD_COLUMNS = [
  'calculatedvendorname',
  'configid',
  'configname',
  'cookieaction',
  'cookiecount',
  'cookiedomain',
  'cookiename',
  'cookiesource',
  'cookievalue',
  'ddgresourceapis',
  'ddgvendorcategory',
  'ddgvendordisplayname',
  'doesaccessminimalfingerprintingsignals',
  'doesreadhttpcookie',
  'doesreadinputs',
  'doesreadjavascriptcookie',
  'doesreadlocalstorage',
  'doesreadsessionstorage',
  'doeswritehttpcookie',
  'doeswritejavascriptcookie',
  'doeswritelocalstorage',
  'doeswritesessionstorage',
  'initiatorchainhostnames',
  'isinthirdpartyiframe',
  'isthirdparty',
  'isthirdpartytoframe',
  'istrackercode',
  'istrackercookie',
  'istrackerdata',
  'jobtimestamp',
  'jobuuid',
  'pagehostname',
  'pageurl',
  'parentiframedomain',
  'region',
  'regionname',
  'requestdocumenthostname',
  'requesthostname',
  'requestpath',
  'requestrecorduuid',
  'requesttimestamp',
  'requestuuid',
  'resourcematcherid',
  'totalfingerprintsignals',
  'totalinputreads',
];

 const DTD_COLUMNS = [
  'calculatedvendorname',
  'configid',
  'configname',
  'datakey',
  'datavalue',
  'ddgresourceapis',
  'ddgvendorcategory',
  'ddgvendordisplayname',
  'doesaccessminimalfingerprintingsignals',
  'doesreadhttpcookie',
  'doesreadinputs',
  'doesreadjavascriptcookie',
  'doesreadlocalstorage',
  'doesreadsessionstorage',
  'doeswritehttpcookie',
  'doeswritejavascriptcookie',
  'doeswritelocalstorage',
  'doeswritesessionstorage',
  'initiatorchainhostnames',
  'ishttpcookie',
  'isinthirdpartyiframe',
  'ispost',
  'isqueryparam',
  'isthirdparty',
  'isthirdpartytoframe',
  'istrackercode',
  'istrackercookie',
  'istrackerdata',
  'jobtimestamp',
  'jobuuid',
  'pagehostname',
  'pageurl',
  'parentiframedomain',
  'postdatatransformation',
  'posteddataformat',
  'region',
  'regionname',
  'requestdocumenthostname',
  'requesthostname',
  'requestpath',
  'requestrecorduuid',
  'requesttimestamp',
  'requestuuid',
  'resourcematcherid',
  'totalfingerprintsignals',
  'totalinputreads',
];
const getColumnsByType = (type: string) => {
  switch (type) {
    case 'TS':
      return TS_COLUMNS;
    case 'CTD':
      return CTD_COLUMNS;
    case 'DTD':
      return DTD_COLUMNS;
    default:
      return [];
  }
}
export const getColumns = (type: string,clientid?:number) => {
  if (clientid && CUSTOM_COLUMNS[clientid]) {
    const out = getColumnsByType(type)
    return [...out, ...CUSTOM_COLUMNS[clientid]];

  }
  return getColumnsByType(type);
}
