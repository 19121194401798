import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { DomainDefinition } from "../models/DomainDefinition";
import { VendorLibraryService } from "../../../services/vendorLibrary/vendor-library.service";
import { SpinnerService } from "../../../services/spinner/spinner.service";
import { VendorLibraryStateService } from "../../../services/vendorLibrary/vendor-library-state.service";
import { tap } from "rxjs/operators";


@Injectable()
export class VendorLibraryResolver implements Resolve<DomainDefinition> {
    
    constructor( 
        private vendorLibraryService:VendorLibraryService, 
        private spinnerService:SpinnerService,
        private vendorLibState:VendorLibraryStateService
        ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<DomainDefinition> | DomainDefinition | Observable<any> {
        console.log("VendorLibrary Resolver", route);
        const domainDefId = route.params["domainDefId"];
        const resourceMatcherId = route.params["resourceMatcherId"];
        const extractorId = route.params["extractorId"];


        console.log("Vendor Lib Resolver", domainDefId, resourceMatcherId, extractorId);

        let domainDef = this.vendorLibState.selectedDomainDefinition;
        if ( domainDef.value ) {            
            return domainDef.value
        } else {
            this.spinnerService.toggle(true);

            return forkJoin([
                this.vendorLibraryService.getAllFlags(),
                this.vendorLibraryService.getAllMatcherAttributeKeys(),
                this.vendorLibraryService.getDomainDefinition(domainDefId)
            ]).pipe(tap((results)=>{
                let domainDefObj = results[2][0];
                this.vendorLibState.selectedDomainDefinition.next(domainDefObj);

                if ( resourceMatcherId ){
                    let resourceMatcher = domainDefObj.resourceMatchers.filter( item => item.id == resourceMatcherId)[0];
                    this.vendorLibState.selectedResourceMatcher.next(resourceMatcher);

                    if (extractorId){
                        let extractor = resourceMatcher.extractors.filter( item => item.id == extractorId)[0];
                        this.vendorLibState.selectedExtractor.next(extractor);
                    }

                }

                this.spinnerService.toggle();
            }));

            // return this.vendorLibraryService.getDomainDefinition(domainDefId).pipe(tap((domainDef)=>{
            //     domainDef = domainDef[0];
            //     this.vendorLibState.selectedDomainDefinition.next(domainDef);

            //     if ( resourceMatcherId ){
            //         let resourceMatcher = domainDef.resourceMatchers.filter( item => item.id == resourceMatcherId)[0];
            //         this.vendorLibState.selectedResourceMatcher.next(resourceMatcher);

            //         if (extractorId){
            //             let extractor = resourceMatcher.extractors.filter( item => item.id == extractorId)[0];
            //             this.vendorLibState.selectedExtractor.next(extractor);
            //         }

            //     }

            //     this.spinnerService.toggle();
            // }));
        }
    }
}