import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, concat, from, zip } from 'rxjs';
import { concatMap, map, reduce } from 'rxjs/operators';

import { Predicate, Query, ReturnOption } from '@syncfusion/ej2-data';

import {
  ReportingS3Service,
  RDSMapping,
} from '../reporting-s3/reporting-s3.service';
import { ReportingRdsService } from '../reporting-rds/reporting-rds.service';
import {
  ReportingViewsService,
  ViewObject,
} from '../reporting-views/reporting-views.service';
import { ReportingLibraryService } from '../reporting-library/reporting-library.service';

import { ReportDetails } from '../../models/ReportDetails';
import {
  ReportExportService,
  ExportObject,
} from '../report-export/report-export.service';
import { AuthService } from '../auth/auth.service';
import {
  ReportingDictService,
  DictionaryObject,
} from '../reporting-dict/reporting-dict.service';

@Injectable({
  providedIn: 'root',
})
export class ReportViewerService {
  private _snapshot: any;
  private _viewParamFlag: boolean;
  private _filterParamFlag: boolean;
  private _refFileParamFlag: boolean;
  private _customFileParamFlag: boolean;
  private _useRDS: boolean;
  private _reportCode: string;
  private _isCustom: boolean;

  private _rdsMapping: RDSMapping;
  private _s3ViewList: ViewObject[];
  private _localViewList: ViewObject[];
  private _allViewsList: ViewObject[];
  private _availableReports: ReportDetails[];
  private _dictionary: DictionaryObject[];

  private _selectedReport: ReportDetails;
  private _selectedView: ViewObject;
  private _reportType: string;
  private _customReportDescription: string;

  private _pageSize: number; // TODO: Initialize this value

  constructor(
    private reportingS3Service: ReportingS3Service,
    private reportingRdsService: ReportingRdsService,
    private reportingViewsService: ReportingViewsService,
    private reportingLibraryService: ReportingLibraryService,
    private reportinDictService: ReportingDictService,
    private reportExportService: ReportExportService,
    private auth: AuthService
  ) {
    this._viewParamFlag = false;
    this._filterParamFlag = false;
    this._refFileParamFlag = false;
    this._customFileParamFlag = false;
    this._useRDS = false;
    this._isCustom = false;
    this._reportType = '';
  }

  public showViewerOptions(): Observable<any> {
    console.log('setViewerOptions');
    console.log('ReportCode: ', this._reportCode);
    console.log('ViewParamFlag: ', this._viewParamFlag);
    console.log('FilterParamFlag: ', this._filterParamFlag);
    console.log('RefFileParamFlag: ', this._refFileParamFlag);
    console.log('CustomFileParamFlag: ', this._customFileParamFlag);
    console.log('UseRDS: ', this._useRDS);
    return of(null);
  }

  public initialize(): Observable<any> {
    console.log('SNAPSHOT: ', this._snapshot);

    if (this._reportCode === 'CSTM') {
      console.log('Custom Report Code Detected');
      this._isCustom = true;
      const filename = this._snapshot.queryParams['CRKV'].split('.csv')[0];
      console.log('Filename: ', filename);
      return this.reportingLibraryService.getReportLibraryConfig().pipe(
        map((config) => {
          console.log('Config: ', config);
          const reportDetails = config.find(
            (report) => report.reportCode === filename
          );
          console.log('Report Details: ', reportDetails);
          if (!reportDetails) {
            console.log('Report not found');
            return false;
          }
          this._reportType = reportDetails.title;
          this._customReportDescription = reportDetails.description;
          this._selectedReport = reportDetails;
          this._selectedReport.name = filename + '.csv';
          return filename;
        })
      );
    }

    const _rdsMapping: Observable<RDSMapping> =
      of(null)
    const _s3ViewList: Observable<ViewObject[]> =
      this.reportingViewsService.getViewListFromS3(this._reportCode);
    const _localViewList: Observable<ViewObject[]> =
      this.reportingViewsService.getViewListFromLocalStorage(this._reportCode);
    const _availableReports: Observable<ReportDetails[]> =
      this.reportingLibraryService.getAvailableReportsByCode(this._reportCode);
    const _dictionary = this._isCustom
      ? of(null)
      : this.reportinDictService.getDictByCode(this._reportCode);

    return forkJoin([
      _rdsMapping,
      _s3ViewList,
      _localViewList,
      _availableReports,
      _dictionary,
    ]).pipe(
      map((results) => {
        console.log('Results: ', results);
        this._rdsMapping = results[0];
        this._s3ViewList = results[1] || ([] as ViewObject[]);
        this._localViewList = results[2] || ([] as ViewObject[]);
        this._allViewsList = [...this._s3ViewList, ...this._localViewList];
        this._availableReports = results[3];
        this._dictionary = results[4] || [];
        console.log('Available Reports: ', this._availableReports);

        this._reportType = this.reportingLibraryService.getReportTitle(
          this._reportCode
        );
        return true;
      }),
      concatMap(() =>
        concat(
          this._updateReportUsingRRV(),
          this._updateViewUsingPV(),
          this._isRDSReport(),
          this._hasFilterParams()
        )
      ),
      reduce((acc, curr) => acc + ' - ' + curr, '')
    );
  }

  private _updateReportUsingRRV(): Observable<string> {
    // Logic to update report using RRV
    console.log('Available Reports in RRV Function: ', this.availableReports);
    if (!this.availableReports) {
      return of('No available reports found');
    }
    console.log(
      'Updating report using RRV: ',
      this._snapshot.queryParams['RRV']
    );
    if (this._refFileParamFlag) {
      // Perform actions related to ref file
      const refFileName = this._snapshot.queryParams['RRV'];
      const findReport = this._availableReports.find(
        (report) => report.name === refFileName
      );
      if (findReport) {
        this._selectedReport = findReport;
      } else {
        this._selectedReport = this._availableReports[0]; // Fallback to first available report
      }
    } else {
      this._selectedReport = this._availableReports[0]; // Fallback to first available report
    }
    return of('Report updated using RRV');
  }

  private _updateViewUsingPV(): Observable<string> {
    if (!this._allViewsList) {
      console.log('No views found');
      this._selectedView = null;
      this.viewParamFlag = false;
      return of('No views found');
    }
    console.log('Updating view using PV: ', this._snapshot.queryParams['PV']);
    const findView = this._allViewsList.find(
      (view) => view.name === this._snapshot.queryParams['PV']
    );
    if (findView) {
      // Perform actions related to view
      console.log('Found view: ', findView);
      this._selectedView = findView;
    } else {
      this._selectedView = null;
      this.viewParamFlag = false;
      console.log('No view found');
    }

    return of('View updated using PV');
  }

  private _hasFilterParams(): Observable<string> {
    if (this.viewParamFlag) {
      this._filterParamFlag = false;
      return of('Overriding filter params');
    }
    if (!this._snapshot.queryParams) {
      this._filterParamFlag = false;
      return of('No query params found');
    }
    const _filterParams = Object.keys(this._snapshot.queryParams).map((key) => {
      return {
        field: key,
        value: this._snapshot.queryParams[key],
      };
    });
    const filterParams = _filterParams.filter(
      (param) =>
        !['RRV', 'CRKV', 'PV'].some((p) => p === param.field.toString())
    );
    console.log('Filter Params: ', filterParams);
    if (filterParams.length > 0) {
      this.filterParamFlag = true;
      this._filterInParams = filterParams;
      return of('Filter params found');
    } else {
      this.filterParamFlag = false;
      return of('No filter params found');
    }
  }
  private _filterInParams: { field: string; value: string }[] = [];

  private _isRDSReport(): Observable<string> {
    // Logic to determine if the report is an RDS report
    this._useRDS = !!this._tableMapping[this._reportCode];
    return of(this._useRDS ? 'Using RDS' : 'Not using RDS');
  }

  private _tableMapping: any = {
    CTD: 'cookietrackerdetails',
    DTD: 'datatrackerdetails',
    TS: 'datatrackersummary',
  };

  public getInitialDataManager(bigData: boolean = false): Observable<any> {
    // Logic to initialize data manager
    if (this._useRDS) {
      // const rdsTables = this._tableMapping[this._reportCode];
      const table = this._tableMapping[this._reportCode];
      return this.reportingRdsService.getInitialData(table);
    } else {
      if (bigData) {
        if (this._isCustom) {
          return this.reportingS3Service.getDataManagerS3Big(
            this._selectedReport
          );
        }
        return this.reportingS3Service.getDataManagerS3Big(
          this._selectedReport
        );
      } else {
        if (this._isCustom) {
          return this.reportingS3Service.getDataManagerS3(this._selectedReport);
        }
        return this.reportingS3Service.getDataManagerS3(this._selectedReport);
      }
    }
  }

  public getDataManager(
    reportQuery: Query,
    sqlWhere: string,
    init: boolean = false
  ): Observable<ReturnOption> {
    return this.reportingRdsService.executeQuery(
      reportQuery,
      this._tableMapping[this._reportCode],
      sqlWhere,
      init
    );
  }

  public dataStateChangeRDS(
    predicate: Predicate,
    state: any,
    sqlWhere?: string
  ): Observable<any> {
    console.log('Table state changed: ', state);
    switch (state.action.requestType) {
      case 'paging':
        const pagingQuery = new Query().skip(state.skip).take(state.take);
        if (predicate) {
          pagingQuery.where(predicate);
        }
        return this.getDataManager(pagingQuery, sqlWhere);
      case 'sorting':
        const sortQuery = new Query()
          .sortBy(state.sorted[0].name, state.sorted[0].direction)
          .skip(state.skip)
          .take(state.take);
        if (predicate) {
          sortQuery.where(predicate);
        }
        return this.getDataManager(sortQuery, sqlWhere);
      default:
        console.log('Unhandled request type: ', state.action.requestType);
        return of(null);
    }
  }

  public runComplexQueryRDS(
    predicate: Predicate,
    sqlWhere: string
  ): Observable<any> {
    // Logic to run complex query
    const query = new Query().skip(0).take(this._pageSize);
    if (predicate) {
      query.where(predicate);
    }
    //simulate timeout error for testing
    // return new Observable((observer) => {
    //   setTimeout(() => {
    //     observer.error('Timeout Error');
    //   }, 1000);
    // });
    return this.getDataManager(query, sqlWhere);
  }

  public getCustomReport(reportName: string): Observable<ReturnOption> {
    return this.reportingS3Service.getCustomReport(reportName + '.csv'); // || ADDING THE .CSV EXTENSION
  }

  public exportData(
    columns: string[],
    sqlWhere: string,
    customName?: string
  ): Observable<any> {
    const exportObject: ExportObject = {
      reportCode: this._reportCode,
      columns: columns,
      sqlWhere: sqlWhere,
      tableName: this._tableMapping[this._reportCode],
      customerId: this.auth.customerId(),
      customName: customName,
      initiatorName: this.auth.getUserProfile()?.nickname || 'Unknown',
    };
    return this.reportExportService.generateExportQuery(exportObject);
  }

  public set snapshot(snapshot: any) {
    // Logic to handle snapshot
    this._snapshot = snapshot;
    this.viewParamFlag = !!snapshot.queryParams['PV'];
    this.filterParamFlag = !!(
      Object.keys(snapshot.queryParams).filter(
        (key) => !['RRV', 'CRKV', 'PV'].some((p) => p === key.toString())
      ).length > 0
    );
    this.refFileParamFlag = !!snapshot.queryParams['RRV'];
    this.customFileParamFlag = !!snapshot.queryParams['CRKV'];
    this.reportCode = snapshot.params['reportCode'];
  }

  public get viewParamFlag(): boolean {
    return this._viewParamFlag;
  }

  public set viewParamFlag(value: boolean) {
    this._viewParamFlag = value;
  }

  public get filterParamFlag(): boolean {
    return this._filterParamFlag;
  }

  public set filterParamFlag(value: boolean) {
    this._filterParamFlag = value;
  }

  public get refFileParamFlag(): boolean {
    return this._refFileParamFlag;
  }

  public set refFileParamFlag(value: boolean) {
    this._refFileParamFlag = value;
  }

  public get customFileParamFlag(): boolean {
    return this._customFileParamFlag;
  }

  public set customFileParamFlag(value: boolean) {
    this._customFileParamFlag = value;
  }

  public get useRDS(): boolean {
    return this._useRDS;
  }

  public set useRDS(value: boolean) {
    this._useRDS = value;
  }

  public get reportCode(): string {
    return this._reportCode;
  }

  public set reportCode(value: string) {
    this._reportCode = value;
  }

  public get rdsMapping(): RDSMapping {
    return this._rdsMapping;
  }

  public get allViewList(): ViewObject[] {
    return this._allViewsList;
  }

  public get s3ViewList(): ViewObject[] {
    return this._s3ViewList;
  }

  public get localViewList(): ViewObject[] {
    return this._localViewList;
  }

  public get availableReports(): ReportDetails[] {
    return this._availableReports;
  }

  public get dictionary(): any {
    return this._dictionary;
  }

  public get selectedReport(): ReportDetails {
    return this._selectedReport;
  }
  public get selectedView(): ViewObject {
    return this._selectedView;
  }

  public get isCustom(): boolean {
    return this._isCustom;
  }

  public get pageSize(): number {
    return this._pageSize;
  }

  public set pageSize(value: number) {
    this._pageSize = value;
  }

  public get stopDataDownload(): any {
    return this.reportingS3Service.stopBigDataRequest;
  }

  public get localViewListFromLocalStorage(): Observable<ViewObject[]> {
    return this.reportingViewsService.getViewListFromLocalStorage(
      this._reportCode
    );
  }

  public get s3ViewListFromS3(): Observable<ViewObject[]> {
    return this.reportingViewsService.getViewListFromS3(this._reportCode);
  }
  public get reportType(): string {
    return this._reportType;
  }
  public get customReportDescription(): string {
    return this._customReportDescription;
  }

  public get filterInParams(): { field: string; value: string }[] {
    return this._filterInParams || [];
  }
}

// if (this.hasViewsInURL && this.initialLoad) {
//   this.spinnerService.toggle(
//     true,
//     'Loading Report from RDS with View Params'
//   );
//   initialView = this.s3States.find(
//     (state) => state.name === this.viewParams
//   );
//   if (initialView) {
//     this.visibleColumns = this.getVisibleColumnsFromState(initialView);

//     const rules: any = !!initialView
//       ? initialView.complexFilterRules
//       : null;

//     this.queryBuilder.setRules(rules || []);
//   }
//   // Get 1 row for Query Builder and Grid Columns then get Eveyrthing else
// }
