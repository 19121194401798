import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridModel } from '@syncfusion/ej2-angular-grids';
import { AdobeVarGridData } from '../../dashboardDS';

@Component({
  selector: 'app-adobe-var-grid-panel',
  templateUrl: './adobe-var-grid-panel.component.html',
  styleUrls: ['./adobe-var-grid-panel.component.css']
})
export class AdobeVarGridPanelComponent implements OnInit {
  @Input('data') gridData: AdobeVarGridData;

  public childGrid: GridModel = {
    queryString: 'variable',
    columns: [
      { field: 'passed', headerText: 'Passed', template: '<div>${passed}</div>' },
      { field: 'value', headerText: 'Value' },
      { field: 'page', headerText: 'Page', },
    ],
  };
  @ViewChild('grid')
  public grid?: GridComponent;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.updateData();
  }

  expand(): void {
    (this.grid as GridComponent).detailRowModule.expandAll();
  }

  collapse(): void {
    (this.grid as GridComponent).detailRowModule.collapseAll();
  }

  updateData(): void {
    if (!this.gridData)
      return;
    this.childGrid.dataSource = this.gridData.variableRowData;
    if (this.grid)
      (this.grid as GridComponent).refresh();
  }
}
