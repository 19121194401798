import { Component, OnInit } from '@angular/core';
import * as d3 from "d3";
import { HttpClient } from '@angular/common/http';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { forkJoin, of } from 'rxjs';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { NodesService } from '../../../services/nodes/nodes.service';
import { catchError } from 'rxjs/operators';
declare var $:any;

@Component({
  selector: 'nodes',
  templateUrl: './nodes.component.html',
  styleUrls: ['./nodes.component.css']
})
export class NodesComponent implements OnInit {

  d3Viz:any;
  nodes:any;
  force:any;
  svg:any;
  domainGroups:any;

  constructor(
    protected httpClient:HttpClient,
    protected dataExportService:DataExportService,
    protected notificationService:VaultNotificationService,
    protected spinnerService:SpinnerService,
    protected nodesService:NodesService
  ) { 
    
  }

  ngOnInit(): void {   
    let dataLocation = localStorage.getItem('nodesDataLocation');
    if ( dataLocation ){
      forkJoin([
        this.dataExportService.getPageRunnerOutputFile(`${dataLocation}sortedDomainGroups.json`).pipe(catchError(error=>of(error))),
        this.dataExportService.getPageRunnerOutputFile(`${dataLocation}nodes.json`).pipe(catchError(error=>of(error)))
      ]).subscribe((results)=> {

        let sortedDomainGroupsRaw = results[0].toString();
        let nodesRaw = results[1].toString();

        if ( sortedDomainGroupsRaw == 'AccessDenied: Access Denied' || nodesRaw == 'AccessDenied: Access Denied') {
          this.notificationService.error('Oops!', 'The files required were not found.');
          return;
        } 


        this.domainGroups = JSON.parse(JSON.parse(results[0].toString()));
        this.nodes = JSON.parse(JSON.parse(results[1].toString()));

        this.renderGraph(this.nodes);
      },
      (err)=>{
        this.notificationService.error("Oops!","Unable to load data.");
      })
    } else {
      this.notificationService.error('Oops', 'Unable to load files.');
    }
  }

  renderGraph(data){
    console.log("RENDER");
    // debugger
    $("#d3Viz").empty();

    let angularThis = this;

    var w = $("#d3Viz").width(), 
        h = $("#d3Viz").height();

    var edgesLength = data.links ? data.links.length : 0;
    var chargeMultiplier = 0;

    if (data && data.nodes && data.nodes.length > 0) {
      if (data.nodes.length < 15) {
          chargeMultiplier = 70;
      } else if (data.nodes.length < 20) {
          chargeMultiplier = 35;
      } else if (data.nodes.length < 40) {
          chargeMultiplier = 25;
      } else {
          chargeMultiplier = 15;
      }        
    }

    var focus_node = null, highlight_node = null;
    
    var text_center = false;
    var outline = false;
    
    var min_score = 0;
    var max_score = 4;
    
    var color = d3.scale.linear()
      .domain([min_score, (min_score+max_score)/2, max_score])
      .range(["lime", "yellow", "red"]);
    
    var highlight_color = "blue";
    var highlight_trans = 0.1;
      
    var size = d3.scale.pow().exponent(1)
      .domain([1,100])
      .range([8,24]);
      
      var force =  d3.layout.force()
      .nodes(data.nodes)
      .links(data.links)    
      .charge(function(d) {
        return -100 * chargeMultiplier - edgesLength;
      })
      .linkDistance(500)
      .linkStrength(.1)
      .friction(0.8)
      .size([w, h])
      .gravity(0.2 + (edgesLength / 700))
      .start();

    window["force"] = force;
      
    var margin = {top: -5, right: -5, bottom: -5, left: -5};
    var default_node_color = "#ccc";
    //var default_node_color = "rgb(3,190,100)";
    var default_link_color = "#888";
    var nominal_base_node_size = 8;
    var nominal_text_size = 10;
    var max_text_size = 24;
    var nominal_stroke = 1.5;
    var max_stroke = 4.5;
    var max_base_node_size = 36;
    var min_zoom = 0.1;
    var max_zoom = 7;
    var svg = d3.select("#d3Viz").append("svg")
      .attr("width", w + margin.left + margin.right)
      .attr("height", h + margin.top + margin.bottom);
    var zoom = d3.behavior.zoom().scaleExtent([min_zoom,max_zoom])
    var g = svg.append("g");
    svg.style("cursor","move");
    var THAT = this;

    var Tooltip = d3.select("#d3Viz")
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "2px")
    .style("border-radius", "5px")
    .style("padding", "5px")
    ;
    
    var linkedByIndex = {};
    data.links.forEach(function(d) {
        linkedByIndex[d.source + "," + d.target] = true;
    });

    function isConnected(a, b) {
      return true; //linkedByIndex[a.index + "," + b.index] || linkedByIndex[b.index + "," + a.index] || a.index == b.index;
    }

    var superThis = this

    var link = g.selectAll(".link")
      .data(data.links)
      .enter().append("line")      
      .attr("class", "link")
      .style("stroke-width", nominal_stroke)
      .style("stroke", function(d) {
          // console.log(arguments);
          if (isNumber(d.value) && d.value >= 0) return color(d.value);
          else return default_link_color;
      })

    var node = g.selectAll(".node")
        .data(data.nodes)
        .enter().append("g")
        .attr("class", function(d){
          if ( d.fixed == true ){
            return "node rootNode";
          } else {
            return "node";
          }
        })       
        .call(force.drag);
        
    var tocolor = "fill";
    var towhite = "stroke";
    if (outline) {
        tocolor = "stroke"
        towhite = "fill"
    }

    var randomColors = {};

  function getRandomColorCode() {
    var makeColorCode = '0123456789ABCDEF';
    var code = '#';
    for (var count = 0; count < 6; count++) {
       code =code+ makeColorCode[Math.floor(Math.random() * 16)];
    }
    return code;
 }

    node.append("rect")
    .attr("x", "-8px")
    .attr("y", "-8px")
    .attr('width', function(d){
      if ( d.fixed == true ){
        return "32px"
      }
      return "16px";
    })
    .attr('height', function(d){
      if ( d.fixed == true ){
        return "32px"
      }
      return "16px";
    })
    .attr('fill', (d)=>{
      var groupColor = randomColors[d.group];
      if ( !groupColor){
        randomColors[d.group] = getRandomColorCode();
        groupColor = randomColors[d.group];
      }
      return groupColor;
    })
    .attr('stroke-width', function(d){
      // if ( d.selected == true ){
      //   return 5;
      // }
      // return 0;
    })
    .attr('stroke', (d)=>{
      // console.log(d);      
      // if ( d.selected == true ){
      //   return 'blue';
      // }
    })
    ;

    var text = g.selectAll(".text")
        .data(data.nodes)
        .enter().append("text")
        .attr("dy", ".35em")
        .style("font-size", nominal_text_size + "px")

    if (text_center)
        text.text(function(d) {
            return d.displayName;
        })
        .style("text-anchor", "middle");
    else
        text.attr("dx", function(d) {
            return (size(d.size) || nominal_base_node_size);
        })
        .text(function(d) {
            return '\u2002' + d.id;
        });

    node.on("mouseover", function(d) {
            // set_highlight(d);
            Tooltip
            .style("opacity", "1").style("visibility", "visible");
        })
        .on("mousedown", function(d) {          
          d.fixed = true;
          d3.event.stopPropagation();
          focus_node = d;
        // if (highlight_node === null) set_highlight(d)

        }).on("mouseout", function(d) {
            // exit_highlight();
            Tooltip
            .style("opacity", "0").style("visibility", "hidden");
         
         

        }).on("mousemove", function(d){

          var ttX = d3.mouse(document.getElementById('d3Viz'))[0] + 16,
              ttY = d3.mouse(document.getElementById('d3Viz'))[1] + 16;
          
          
          let domainGroupInfo = angularThis.domainGroups[d.group];
          var text = d && d.group ?  "<b>Tokens:</b><br>": "N/A";
          if (domainGroupInfo){            
            text += domainGroupInfo.tokens.join("<br>");
          }else {
            text += 'None'
          }
          
          console.log("TOOLTIP", text);
          Tooltip
          .html(`${text}`)
          .style("left", (ttX ) + "px")
          .style("top", (ttY) + "px")

        }).on("click", function(d){
          
          
          
          
        
        }).on("dblclick", function(d){
          d3.event.stopPropagation();

          
          
          
          // d3.select(this).select("rect").transition()
          //   .duration(750)
          //   .attr("width", 16)
          //   .attr("height", 16)
          //   .style("fill", "lightsteelblue");
        })
        ;

    d3.select(window).on("mouseup",
        function() {
            if (focus_node !== null) {
                focus_node = null;                
            }

            // if (highlight_node === null) exit_highlight();
        });

    function exit_highlight() {
        highlight_node = null;
        if (focus_node === null) {
            svg.style("cursor", "move");
            if (highlight_color != "white") {
                // circle.style(towhite, "white");
                text.style("font-weight", "normal");
                link.style("stroke", function(o) {
                    return (isNumber(o.score) && o.score >= 0) ? color(o.score) : default_link_color
                });
            }

        }
    }

    function set_highlight(d) {
        // console.log(d);
        svg.style("cursor", "pointer");
        if (focus_node !== null) d = focus_node;
        highlight_node = d;
        // console.log(d);

        var target = d.index;
        var counter = 0;
        var nodeChain = [d.index];
        while (target != 0) {        
          var node = data.links.filter((l)=>{return l.target.index == target})[0];
          // console.log(node.source.index);
          target = node.source.index;
          nodeChain.push(target);
          if ( counter++ > 100){
            console.log("Busting out of loop....");
            break;
          }
        }

        if (highlight_color != "white") {
            // circle.style(towhite, function(o) {
            //     return isConnected(d, o) ? highlight_color : "white";
            // });
            text.style("font-weight", function(o) {
                return isConnected(d, o) ? "bold" : "normal";
            });
            link.style("stroke", function(o) {
              // console.log(o);
                return nodeChain.indexOf(o.target.index) >= 0 ? highlight_color : 
                  ((isNumber(o.score) && o.score >= 0) 
                    ? 
                      color(o.score) 
                        : 
                      default_link_color);
            });
        }
    }


    zoom.on("zoom", function() {

        var stroke = nominal_stroke;
        if (nominal_stroke * zoom.scale() > max_stroke) stroke = max_stroke / zoom.scale();
        link.style("stroke-width", stroke);
        // circle.style("stroke-width", stroke);

        var base_radius = nominal_base_node_size;
        if (nominal_base_node_size * zoom.scale() > max_base_node_size) base_radius = max_base_node_size / zoom.scale();
        // circle.attr("d", d3.svg.symbol()
        //     .size(function(d) {
        //         return Math.PI * Math.pow(size(d.size) * base_radius / nominal_base_node_size || base_radius, 2);
        //     })
        //     .type(function(d) {
        //         return d.type;
        //     }))

        //circle.attr("r", function(d) { return (size(d.size)*base_radius/nominal_base_node_size||base_radius); })
        if (!text_center) text.attr("dx", function(d) {
            return (size(d.size) * base_radius / nominal_base_node_size || base_radius);
        });

        var text_size = nominal_text_size;
        if (nominal_text_size * zoom.scale() > max_text_size) text_size = max_text_size / zoom.scale();
        text.style("font-size", text_size + "px");

        g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
    });

    svg.call(zoom);

    resize();
    //window.focus();
    
    force.on("tick", function() {

        node.attr("transform", function(d) {
            return "translate(" + d.x + "," + d.y + ")";
        });
        text.attr("transform", function(d) {
            return "translate(" + d.x + "," + d.y + ")";
        });

        link.attr("x1", function(d) {
                return d.source.x;
            })
            .attr("y1", function(d) {
                return d.source.y;
            })
            .attr("x2", function(d) {
                return d.target.x;
            })
            .attr("y2", function(d) {
                return d.target.y;
            });

        node.attr("cx", function(d) {
                return d.x;
            })
            .attr("cy", function(d) {
                return d.y;
            });
    });

    function resize() {
        var width = window.innerWidth,
            height = window.innerHeight;
        svg.attr("width", width).attr("height", height);

        force.size([force.size()[0] + (width - w) / zoom.scale(), force.size()[1] + (height - h) / zoom.scale()]).resume();
        w = width;
        h = height;
    }

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
  }
  
}
