import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { Papa } from 'ngx-papaparse';
import { AuthService } from '../../../services/auth/auth.service';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';

interface TrackerVendorSummary {
  countUniquePageDomains: number;
  countPages: number;
  countChdPages: number;
  countTrackers: number;
  countCookieTrackers: number;
  countDataTrackers: number;
  countCodeTrackers: number;
  calculatedVendorName: string;
  pageDomain: string;
  pageCount: number;
  domainTrackerCount: number;
  domainTrackerCategories: string;
}

@Component({
  selector: 'app-tracker-vendor-dashboard',
  templateUrl: './tracker-vendor-dashboard.component.html',
  styleUrls: ['./tracker-vendor-dashboard.component.css'],
})
export class TrackerVendorDashboardComponent implements OnInit, OnDestroy {
  public cdnUrl: string;
  private cid: number;
  private vendorName: string;
  private currentFile: string = '';

  constructor(
    private vendorService: VendorsService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private notificationService: VaultNotificationService,
    private dataExportService: DataExportService,
    private papa: Papa,
    private authService: AuthService,
    private router: Router,
    private dashboardFileSelectorService: DashboardFileSelectorService
  ) {
    this.cdnUrl = env.cdn.url;
    this.dashboardFileSelectorService.toggle(true);
    this.dashboardFileSelectorService.setReportCode('TVD');
    this.cid = parseInt(this.authService.customerId());
    this.vendorName =
      this.route.snapshot.queryParams.v || this.availableVendors[0];
  }
  private vendorList = [];
  public availableVendors = [];
  public uniquePages: number = 0;
  public uniqueDomains: number = 0;
  public uniqueTrackers: number = 0;
  public uniqueCodeTrackers: number = 0;
  public uniqueDataTrackers: number = 0;
  public uniqueCookieTrackers: number = 0;
  public CHDPages: number = 0;
  public data: TrackerVendorSummary[] = [];
  public selectedVendorName = '';
  public selectedVendorInfo: any = {};
  public vendorFields = { text: 'name', value: 'name' };
  public vendorGroupedData: any = [];
  public vendorPieData: any = [];
  public pagePieData: any = [];
  private loadVendorData(vendorName: string) {
    const temp = this.vendorList.find((vendor) => vendor.name === vendorName);
    console.log(temp);
    this.selectedVendorInfo = temp || {};
    this.selectedVendorName = vendorName;
    this.dataGrouping(
      this.data.filter(
        (row) => row.calculatedVendorName === this.selectedVendorName
      )
    );
  }
  onVendorSelected($event) {
    const itemData = $event.itemData;
    console.log(itemData);
    this.loadVendorData(itemData.value);
  }
  private async loadData(filename?: string) {
    this.vendorList = await new Promise((resolve, reject) => {
      this.vendorService.getAllVendors().subscribe(
        (vendors: any) => {
          resolve(vendors);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
    this.data = await new Promise((resolve, reject) => {
      this.dataExportService.getTrackerVendors(this.cid,filename).subscribe(
        (data: any) => {
          this.papa.parse(data.toString(), {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
              console.log(result.meta.fields);
              resolve(result.data as TrackerVendorSummary[]);
            },
          });
        },
        (error: any) => {
          reject(error);
        }
      );
    });
    const allVendors = new Set();
    this.data.forEach((row) => {
      allVendors.add(row.calculatedVendorName);
    });
    this.availableVendors = Array.from(allVendors).sort(
      (a:any, b:any) => a.localeCompare(b)
    );
    this.loadVendorData(this.vendorName || this.availableVendors[0]);
    this.spinnerService.toggle(false);
  }
  public dataByDomain = [];
  private dataGrouping(data: TrackerVendorSummary[]) {
    const topLevelData = data[0];
    this.uniqueCodeTrackers = topLevelData.countCodeTrackers;
    this.uniqueDataTrackers = topLevelData.countDataTrackers;
    this.uniqueCookieTrackers = topLevelData.countCookieTrackers;
    this.uniqueTrackers = topLevelData.countTrackers;
    this.CHDPages = topLevelData.countChdPages;
    this.uniquePages = topLevelData.countPages;

    const allDomains = new Set();
    data.forEach((row) => {
      allDomains.add(row.pageDomain);
    });
    this.uniqueDomains = allDomains.size;
    this.vendorGroupedData = data.map((vendor) => {
      return {
        name: vendor.pageDomain,
        pageCount: vendor.pageCount,
        domainTrackerCount: vendor.domainTrackerCount,
        categories: vendor.domainTrackerCategories.split(', ').map((cat) => {
          return cat.trimEnd().trimStart();
        })
      };
    });
    let colors = this.generateMultiGradientColors(
      this.vendorGroupedData.length,
      this.baseColors
    );
    this.pagePieData = this.vendorGroupedData.map((child, index) => {
      return {
        label: child.name,
        value: child.pageCount || 1,
        color:  colors[index],
        children: [],
      };
    });
    this.vendorPieData = this.vendorGroupedData.map((child, index) => {
      return {
        label: child.name,
        value: child.domainTrackerCount || 1,
        color: colors[index],
        children: [],
      };
    });
    this.isLoaded = true;
    this.spinnerService.toggle(false);
  }
  ngOnInit(): void {
    this.cdnUrl = env.cdn.url;
    this.spinnerService.toggle(true);
    this.dashboardFileSelectorService.setSelectionHandler(this.onReportSelect.bind(this));
    this.currentFile = this.dashboardFileSelectorService.defaultFile || null;
    this.loadData(this.currentFile);
  }
  ngOnDestroy(): void {
    this.dashboardFileSelectorService.toggle(false);
  }
  public isLoaded: boolean = true;

  onReportSelect(filename: any) {
    console.log('New File Selected: ', filename);
    this.isLoaded = false;
    this.spinnerService.toggle(true);
    this.currentFile = filename;
    this.loadData(filename);
  }
 goToDetailCodeReport(domain, vendor) {
    this.router.navigate(['/reports/raw-report-viewer/TS'], {
      queryParams: { pageHostname: domain, calculatedVendorName: vendor, "RRV": this.currentFile.replace('TVD','TS') },
    });
  }
  goToDetailCookieReport(domain, vendor) {
    this.router.navigate(['/reports/raw-report-viewer/CTD'], {
      queryParams: { pageHostname: domain, calculatedVendorName: vendor, "RRV": this.currentFile.replace('TVD','CTD')  },
    });
  }
  goToDetailDataReport(domain, vendor) {
    this.router.navigate(['/reports/raw-report-viewer/DTD'], {
      queryParams: { pageHostname: domain, calculatedVendorName: vendor , "RRV": this.currentFile.replace('TVD','DTD') },
    });
  }
  goToCategoryDetails(cat, domain, vendor) {
    switch (cat) {
      case 'Data Tracker':
        this.goToDetailDataReport(domain, vendor);
        break;
      case 'Cookie Tracker':
        this.goToDetailCookieReport(domain, vendor);
        break;
      case 'Code Tracker':
        this.goToDetailCodeReport(domain, vendor);
        break;
    }
  }
  private interpolateColor(color1, color2, factor) {
    let result = '#';
    for (let i = 0; i < 3; i++) {
      let value1 = parseInt(color1.substring(1 + i * 2, 3 + i * 2), 16);
      let value2 = parseInt(color2.substring(1 + i * 2, 3 + i * 2), 16);
      let value = Math.round(value1 + (value2 - value1) * factor).toString(16);
      result += ('0' + value).slice(-2); // Pad with zero if necessary
    }
    return result;
  }
  public baseColors = ['#00dbff', '#6390ee', '#0e1227', '#e5ebe8',];

  private generateMultiGradientColors(numItems, baseColors) {
    let colors = [];
    const numSections = baseColors.length - 1;
    if (numItems > 1) {
      for (let i = 0; i < numItems; i++) {
        const sectionLength = 1 / numSections;
        const position = (i / (numItems - 1)) * numSections; // Position in the gradient
        const index = Math.floor(position); // Determine which color to start from
        const factor = position - index; // Factor of interpolation within the section

        let color = this.interpolateColor(
          baseColors[index],
          baseColors[Math.min(index + 1, baseColors.length - 1)],
          factor
        );
        colors.push(color);
      }
    } else if (numItems === 1) {
      // Only one item, use the first color
      colors.push(baseColors[0]);
    }
    return colors;
  }
}
