import { Component, OnInit, ViewChild } from '@angular/core';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { ChangeEventArgs, FieldMode, QueryBuilderComponent, QueryBuilderModule, TemplateColumn } from '@syncfusion/ej2-angular-querybuilder';
import { ActivatedRoute } from '@angular/router';
import { createElement, getComponent } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { GridComponent, ResizeService, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { TimezoneService } from '../../../services/timezone.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import * as Diff2Html from 'diff2html';
import { forkJoin, timer } from 'rxjs';
import { DiffService } from '../../../services/workers/diff-service.service';
import { delayWhen } from 'rxjs/operators';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { TextMarkerOptions } from 'codemirror';
import { ClipboardService } from 'ngx-clipboard';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment as env } from '../../../../environments/environment';
import { HashDataAdaptor } from './HashDataAdaptor';
import { RequestsDataAdaptor } from './RequestsDataAdaptor';
declare var $: any;

export enum ANALYSIS_TYPE {
  BEAUTIFIED = "beaut",
  RAW = "raw",
  DEOBFUSCATED = "deobf",
  ANALYSIS = "analysis",
  AST = "ast",
  RETIREJS = "retire"
}

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.css'],
  providers: [ResizeService]
})
export class RequestDetailComponent implements OnInit {

  hashDataManager: any;
  requestsDataManager: DataManager;
  extractedDataManager: any;

  selectedHash: string;

  resetRequestsTimeoutId: any;

  extractedDsContextMenuClick(event: any) {
    console.log(event);
    let menuItemProps = event.item.properties;
    let menuItemId = menuItemProps.id;

    let rowItem: any = this.extractedGrid.getSelectedRecords()[0];
    if (menuItemId == 'copyKey') {
      this.clipboardApi.copyFromContent(rowItem.key);
      this.notificationService.info('', 'Key copied to clipboard');
    } else if (menuItemId == 'copyValue') {
      this.clipboardApi.copyFromContent(rowItem.value);
      this.notificationService.info('', 'Value copied to clipboard');
    }
  }

  extractedDsContextMenuItems = [
    { text: "Copy Key", id: "copyKey" },
    { text: "Copy Value", id: "copyValue" }
  ]

  pagesContextMenuClick(event: any) {
    console.log(event);
    let menuItemProps = event.item.properties;
    let menuItemId = menuItemProps.id;

    let rowItem: any = this.pagesGrid.getSelectedRecords()[0];
    if (menuItemId == 'copyPage') {
      this.clipboardApi.copyFromContent(rowItem.starturl);
      this.notificationService.info('', 'Page URL copied to clipboard');
    } else if (menuItemId == 'copyRequest') {
      this.clipboardApi.copyFromContent(rowItem.requesturl);
      this.notificationService.info('', 'Request URL copied to clipboard');
    }
  }

  hashContextMenuOpen($event: any) {

  }

  hashContextMenuClick(event: any) {
    console.log(event);
    let menuItemProps = event.item.properties;
    let menuItemId = menuItemProps.id;

    if (menuItemId == 'copyFileHash') {
      let rowItems: any[] = this.hashGrid.getSelectedRecords();
      this.clipboardApi.copyFromContent(rowItems[0].responsehash);
      this.notificationService.info('', 'Hash copied to clipboard');
    }
  }

  onPagesButtonGroupClick(buttonName: string) {
    // bail if active button was clicked
    if (buttonName == 'Pages' && this.isPagesButtonActive == true) return;
    if (buttonName == 'Data' && this.isPagesButtonActive == false) return;

    this.isPagesButtonActive = !this.isPagesButtonActive;
  }

  sideBySideFileDiff = `<div id="{{fileHtmlId}}" class="d2h-file-wrapper" data-lang="{{file.language}}">
  <div class="d2h-file-header">
    {{{filePath}}}
  </div>
  <div class="d2h-files-diff">
      <div class="d2h-file-side-diff">
          <div class="d2h-code-wrapper">
              <table class="d2h-diff-table">
                  <thead>@LEFT@</thead>
                  <tbody class="d2h-diff-tbody">
                  {{{diffs.left}}}
                  </tbody>
              </table>
          </div>
      </div>
      <div class="d2h-file-side-diff">
          <div class="d2h-code-wrapper">
              <table class="d2h-diff-table">
                  <thead>@RIGHT@</thead>
                  <tbody class="d2h-diff-tbody">
                  {{{diffs.right}}}
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>`;

  // @ViewChild('queryBuilder')
  // queryBuilder: QueryBuilderComponent;

  @ViewChild('pagesGrid')
  pagesGrid: GridComponent

  @ViewChild('extractedGrid')
  extractedGrid: GridComponent

  @ViewChild('hashGrid')
  hashGrid: GridComponent

  @ViewChild('codeViewer')
  codeViewer: CodemirrorComponent;

  keys: any[];
  keyValues: any[];

  vendorId = null;
  resourceMatcherId = null;

  requests;

  extractedGridGroupSettings: any;

  isCollapsed = false;

  separator: string = '.';
  fieldMode: FieldMode = 'DropdownTree';

  loadRequestsDisabled: boolean = true;

  extractedSortSettings = {
    columns: [
      { field: 'key', direction: 'Ascending' }
    ]
  }

  pagesSortSettings = {
    columns: [
      { field: 'formattedTimestamp', direction: 'Descending' }
    ]
  }

  pageFilterSettings = {
    type: 'Excel'
  }

  pagesSelectionSettings = {
    type: 'Multiple'
  }

  pagingSettingsHashGrid = {
    pageCount: 1,
    pageSize: 50
  }

  pagingSettingsPagesGrid = {
    pageCount: 1,
    pageSize: 50
  }

  curSelectedRowIndices: any[];

  codeMirrorOptions: any;

  codeFileContent;
  outputHtml;

  tooltipContent;

  isRawTabActive;
  isAnalysisTabActive;
  isDiffTabActive;
  isBeautificationTabActive
  isAstTabActive;
  isDeobftabActive;
  isRetireActive;
  isDataActive;

  analysisInfo: any;
  analysisPosition: number;
  curItem: any;

  extractedDS:any = [];

  hashes = [];

  isPagesButtonActive: boolean;

  hashContextMenuItem = [
    { text: "Copy Hash", id: "copyFileHash" }
  ];

  pagesContextMenuItems = [
    { text: "Copy Page", id: "copyPage" },
    { text: "Copy Request", id: "copyRequest" }
  ];

  public ANALYSIS_TYPES = ANALYSIS_TYPE;

  onTabClick(event) {
    console.log("qqq onTabClick", event);
    console.log(event);

    var tabId = event.target.id;
    console.log("Tab ID", tabId);

    if (event.target.nodeName == "SPAN") {
      tabId = event.target.parentNode.id;
    }

    this.resetAllTabs();

    let funcToUse = this.dataExportService.getCodeFileBeautified;
    let isJSON = false;
    if (tabId == ANALYSIS_TYPE.BEAUTIFIED) {
      this.isBeautificationTabActive = true;
      funcToUse = this.dataExportService.getCodeFileBeautified;
    } else if (tabId == ANALYSIS_TYPE.RAW) {
      this.isRawTabActive = true;
      funcToUse = this.dataExportService.getCodeFileRaw;
    } else if (tabId == ANALYSIS_TYPE.DEOBFUSCATED) {
      this.isDeobftabActive = true;
      funcToUse = this.dataExportService.getCodeFileDeobfuscated;
    } else if (tabId == ANALYSIS_TYPE.ANALYSIS) {
      this.isAnalysisTabActive = true;
      funcToUse = this.dataExportService.getCodeFileSimpleAnalysis;
      isJSON = true;
    } else if (tabId == ANALYSIS_TYPE.AST) {
      this.isAstTabActive = true;
      funcToUse = this.dataExportService.getCodeFileAst;
      isJSON = true;
    } else if (tabId == ANALYSIS_TYPE.RETIREJS) {
      this.isRetireActive = true;
      funcToUse = this.dataExportService.getCodeFileRetireJs;
      isJSON = true;
    }

    let hash = this.curSelectedRowIndices[0].responsehash;
    this.spinnerService.toggle();
    funcToUse.apply(this.dataExportService, [hash]).subscribe((response) => {

      if (this.isAnalysisTabActive == true) {

        this.dataExportService.getCodeFileBeautified(hash).subscribe((gcfbResponse) => {

          let fileContents = gcfbResponse.toString();
          this.codeFileContent = fileContents;
          this.codeViewer.codeMirror.getDoc().setValue(this.codeFileContent);
          this.codeViewer.codeMirror.setSize("100%", "475px");

          this.analysisPosition = -1;
          var analysis = response ? JSON.parse(response.toString()).basicAnalysisResults : [];
          this.analysisInfo = analysis;

          for (let index = 0; index < analysis.length; index++) {
            var item = analysis[index];
            const start: CodeMirror.Position = { line: (item.location.start.line - 1), ch: item.location.start.column };
            const end: CodeMirror.Position = { line: (item.location.end.line - 1), ch: item.location.end.column };
            var options: TextMarkerOptions = { css: "color : white; background-color: #ff0000" };
            console.log(start, end, options);
            this.codeViewer.codeMirror.getDoc().markText(start, end, options);
          };
        })
      } else {
        let fileContents = response.toString();
        this.codeFileContent = (isJSON ? JSON.stringify(JSON.parse(fileContents), null, 2) : fileContents);
        this.codeViewer.codeMirror.setSize("100%", "475px");
        this.spinnerService.toggle(false);
      }

    },
      (err) => {
        console.log("Caught Error");
        const fileContents = '// Unable to load data. Please contact support.'
        this.codeFileContent = fileContents;
        this.spinnerService.toggle(false);
      })

  }

  effectiveDelay = (delay) => {
    let effectiveTimer = (now => delay => timer(delay + now - Date.now()))(Date.now())
    return delayWhen(() => effectiveTimer(delay));
  }

  constructor(
    private route: ActivatedRoute,
    private vendorsService: VendorsService,
    private tzService: TimezoneService,
    private spinnerService: SpinnerService,
    private dataExportService: DataExportService,
    private notificationService: VaultNotificationService,
    private diffService: DiffService,
    private sitemapService: SitemapService,
    public clipboardApi: ClipboardService
  ) {
    this.sitemapService.update(this.route);

    this.extractedGridGroupSettings = {
      showGroupedColumn: false,
      columns: ['key']
    }
    this.curSelectedRowIndices = [];
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      tabSize: 2,
      readOnly: true,
      theme: 'material',
      gutters: ["CodeMirror-linenumbers", "analysis"]
    };

    this.tooltipContent = ``;
    this.isPagesButtonActive = true;
  }

  ngAfterViewInit() {
    $('[data-toggle="popover"]').popover();
  }

  ngOnInit(): void {

    this.vendorId = this.route.snapshot.params.vendor;
    this.resourceMatcherId = this.route.snapshot.params.requestId;
    let queryParams = this.route.snapshot.queryParams;
    let hash = queryParams["vjsHash"];
    console.log('qp', hash);
    let kvp: any[] = Object.keys(queryParams).map((qpKey) => {
      return { key: qpKey, value: queryParams[qpKey], operator: 'equal' }
    });
    if (hash) {
      kvp = kvp.filter((i) => { return i.key !== 'vjsHash' });
    }

    console.log('qp', kvp);

    // this.spinnerService.toggle(true);

    this.hashDataManager = new DataManager({
      url: `${env.api.url}vendors/${this.vendorId}/requestV2/${this.resourceMatcherId}`,
      adaptor: new HashDataAdaptor(
        this.tzService,
      ),
    });

    this.requestsDataManager = new DataManager({
      url: `${env.api.url}vendors/${this.vendorId}/requestV2/${this.resourceMatcherId}`,
      adaptor: new RequestsDataAdaptor(
        this.tzService,
        this
      ),
    });
  }

  dataBound(event) {
    // this.extractedGrid.groupModule.collapseAll();
  }

  toggleCollapse() {
    if (this.isCollapsed) {
      this.extractedGrid.groupModule.expandAll();
      this.isCollapsed = false;
    }
    else {
      this.extractedGrid.groupModule.collapseAll();
      this.isCollapsed = true;
    }
  }

  pagesRowSelecting(event: RowSelectEventArgs) {
    console.log("pagesRowSelecting", event);
    let count = event.rowIndexes?.length;
    console.log(count);
    if (count && count > 2) {
      event.cancel = true;
    }
  }

  pagesRowSelected(event) {
    console.log("pagesRowSelected", Date.now(), event);

    clearTimeout(this.resetRequestsTimeoutId);

    // return;
    this.spinnerService.toggle(true);
    
    let selectedRows = this.hashGrid.getSelectedRecords();
    
    if (event.rowIndexes && selectedRows && selectedRows.length == 2) {
      this.curSelectedRowIndices = event.rowIndexes;
      console.log("actual selected rows");
      console.log(selectedRows);

      let leftFile: any = selectedRows[0];// this.hashGrid.dataSource[this.curSelectedRowIndices[0]];
      let rightFile: any = selectedRows[1];//this.hashGrid.dataSource[this.curSelectedRowIndices[1]];

      console.log("left", leftFile);
      console.log("right", rightFile);

      forkJoin([
        this.dataExportService.getCodeFileBeautified(leftFile.responsehash),
        this.dataExportService.getCodeFileBeautified(rightFile.responsehash)
      ]).subscribe((files) => {
        this.diffService.startDiff(files[0].toString(), files[1].toString()).subscribe((result) => {
          if (!result.data || result.data == "") {
            console.log("EMPTY/NULL from worker");
          } else {
            let outputHtml = Diff2Html.html(result.data, {
              matching: 'none',
              outputFormat: 'side-by-side',
              rawTemplates: {
                "file-summary-wrapper": ``,
                "side-by-side-file-diff": this.sideBySideFileDiff.replace('@LEFT@', leftFile.responsehash).replace('@RIGHT@', rightFile.responsehash)
              }
            });
            this.outputHtml = outputHtml;
          }
          this.spinnerService.toggle();
        },
          (error) => {
            this.spinnerService.toggle();
          })
      },
        (error) => {
          this.spinnerService.toggle();
        })
    } else {
      this.curSelectedRowIndices = this.hashGrid.getSelectedRecords()
      this.selectedHash = this.curSelectedRowIndices[0].responsehash;
      let hash = this.selectedHash;
      console.log("Hash", hash);

      // this.requestsDataManager = new DataManager({
      //   url: `${env.api.url}vendors/${this.vendorId}/request/${this.resourceMatcherId}`,
      //   adaptor: new RequestsDataAdaptor(
      //     this.tzService,
      //     this
      //   ),
      // });

      this.pagesGrid.allowFiltering = true;
      this.pagesGrid.filterByColumn("responsehash", "equal", hash);
      this.pagesGrid.allowFiltering = false;

      this.extractedGrid.showSpinner()
      this.vendorsService.getExtractedValuesForHash(this.vendorId, this.resourceMatcherId, hash).subscribe((results: any) => {
        this.extractedDS = results.result;
        this.extractedGrid.hideSpinner();
      });

      // this.dataExportService.getAnalysisFile(`${event.data.responsehash}.js`, event.data.bucket).subscribe((response)=>{
      this.dataExportService.getCodeFileBeautified(hash).subscribe((response) => {
        this.resetAllTabs();
        this.isBeautificationTabActive = true;

        const fileContents = response.toString();
        this.codeFileContent = fileContents;
        if (this.codeViewer && this.codeViewer.codeMirror) {
          this.codeViewer?.codeMirror?.setSize("100%", "475px");
        } else {
          setTimeout(() => { this.codeViewer?.codeMirror?.setSize("100%", "475px"); }, 50)
        }
        this.spinnerService.toggle(false);
      },
        (error) => {
          console.log(error);
          this.notificationService.error("Uh oh...", "Unable to load the requested file. Please contact support.")
          this.codeFileContent = `// Unable to load file...`;
          this.codeViewer.codeMirror.setSize("100%", "475px");
          this.spinnerService.toggle(false);
        },
        () => {
          console.log("File DL complete...");
        })
    }
  }

  pagesRowDeselecting(event) {
    console.log("pagesRowDeselecting", event);
  }

  pagesRowDeselected(event) {
    console.log("pagesRowDeselected", Date.now(), event);
    console.log("this.hashGrid.getSelectedRowIndexes();", this.hashGrid.getSelectedRecords());
    let selRowIxs = this.hashGrid.getSelectedRowIndexes();
    console.log("selected Count", selRowIxs);
    if (selRowIxs && selRowIxs.length == 0) {
      this.codeFileContent = '//Select a file..';
      this.codeViewer.codeMirror.refresh();
    }

    this.resetRequestsTimeoutId = setTimeout(() => {
      this.extractedDS = []
      this.selectedHash = null;
      this.pagesGrid.allowFiltering = true;
      this.pagesGrid.clearFiltering();
      this.pagesGrid.allowFiltering = false;
    }, 100);
  }

  qbBeforeChange(event: ChangeEventArgs) {
    console.log(event);
    if (event.selectedField == 'Required' || event.selectedField == 'Optional') {
      // console.log('Canceling...');
      // event.cancel = true;
    }
  }

  qbActionBegin(event) {
    console.log(event);
  }

  qbChange(event) {
    console.log(event);
  }

  qbCreated(event) {
    console.log(event);
  }

  qbDataBound(event) {
    console.log(event);
  }

  qbRuleChange(event) {
    console.log(event);
  }

  makeMarker() {
    var marker = document.createElement("div");
    marker.style.color = "#f00";
    marker.innerHTML = "●";
    return marker;
  }

  next(direction) {

    if (!this.analysisInfo || (this.analysisPosition <= 0 && direction == -1) || (this.analysisPosition >= this.analysisInfo.length - 1 && direction == 1)) {
      return;
    }

    //console.log(direction);

    var scrollViewPosition: CodeMirror.Position;
    if (direction == 'start') {
      scrollViewPosition = { line: (0), ch: 0 };
      this.analysisPosition = -1;
    } else if (direction == 'end') {

      const numLines = this.codeFileContent.split(/\r\n|\r|\n/).length;
      scrollViewPosition = { line: (numLines - 1), ch: 0 };
      this.analysisPosition = this.analysisInfo.length;
    } else {
      this.analysisPosition += direction
      const item = this.analysisInfo[this.analysisPosition];
      console.log("ITEM");
      console.log(item);
      if (!item) {
        return;
      }
      scrollViewPosition = { line: (item.location.start.line - 1), ch: item.location.start.column };

      if (this.curItem) {
        window["gutterId"] = this.codeViewer.codeMirror.clearGutter("analysis");
      }

      window["gutterId"] = this.codeViewer.codeMirror.setGutterMarker(item.location.start.line - 1, "analysis", this.makeMarker());

      this.curItem = item;
    }
    this.codeViewer.codeMirror.scrollIntoView(scrollViewPosition);
  }

  resetAllTabs() {
    this.isBeautificationTabActive =
      this.isRawTabActive =
      this.isAnalysisTabActive =
      this.isDiffTabActive =
      this.isDeobftabActive =
      this.isAstTabActive =
      this.isRetireActive =
      this.isDataActive =
      false;
  }

  downloadFile(event, hash, type) {
    console.log(hash, type);

    if (!hash) return;

    this.dataExportService.getCodeFileBeautified(hash).subscribe((response) => {
      this.dataExportService.saveFile(hash, response.toString(), DataExportService.JS_EXTENSION);
    });

    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.cancel = true;

  }

}