import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportingV2Service } from '../../../services/reporting-v2/reporting-v2.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
@Component({
  selector: 'app-report-selection-tile',
  templateUrl: './report-selection-tile.component.html',
  styleUrls: ['./report-selection-tile.component.css'],
})
export class ReportSelectionTileComponent implements OnChanges {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() beautifiedPath: string;
  @Input() reportCode: string;
  @Input() hasRaw: boolean;
  @Input() isEmpty: boolean;
  @Input() isCustom: boolean;

  public availableReports: any;
  public url: string;
  constructor(
    private router: Router,
    private reportingV2Service: ReportingV2Service,
    private notificationService: VaultNotificationService
  ) {}

  ngOnChanges(): void {
    this.initializeInputs();
  }

  initializeInputs() {
    this.title = this.title ? this.title : 'Title';
    this.description = this.description ? this.description : 'Description';
    this.icon = this.icon ? this.icon : 'report';
    this.beautifiedPath = this.beautifiedPath ? this.beautifiedPath : null;
    if(this.beautifiedPath){
      this.url = this.beautifiedPath
    }else{
      this.url = `reports/raw-report-viewer/${this.reportCode}`;
    }
    this.hasRaw = this.hasRaw ? this.hasRaw : false;
    this.isEmpty = this.isEmpty ? this.isEmpty : false;
  }
  openRawReportViewer() {
    if(this.isEmpty){
      this.notificationService.info('No Reports Available',`No reports available for ${this.title}`);
      return;
    }
    this.url = `reports/raw-report-viewer/${this.reportCode}`;
    if(this.isCustom){
      this.url = `reports/raw-report-viewer/CSTM?CRKV=${this.reportCode}`;
    }
    this.router.navigateByUrl(this.url);
  }
  navigateToReport() {
    if(this.beautifiedPath){
      this.router.navigateByUrl(this.url);
    }else{
      this.openRawReportViewer();
    }
  }
}
