import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sparkline-panel',
  templateUrl: './sparkline-panel.component.html',
  styleUrls: ['./sparkline-panel.component.css']
})

export class SparklinePanelComponent implements OnInit {
  @Input('occurences') occurences: string;
  @Input('title') title: number;
  @Input('chartHeight') chartHeight: number;
  @Input('dataSeries') dataSeries: any;
  @Input('lineWidth') lineWidth: string;
  @Input('tooltip') tooltip: any;
  @Input('XAxis') XAxis: any;
  @Input('YAxis') YAxis: any;

  public _primaryXAxis: any;
  public _primaryYAxis: any;
  public _chartArea: any;
  public _lineWidth;
  public _tooltip: any;

  constructor() {
    this._primaryXAxis = {
      visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      }
    };
    this._primaryYAxis = {
      visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      }
    };
    this._chartArea = {
      border: { width: 0 },
    };
    this._lineWidth = 2;
    this._tooltip = { enable: false };
  }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    console.log("parent changes")
    if (this.dataSeries && this.dataSeries.length) {
      if(this.dataSeries[0].length)
        this.dataSeries = this.dataSeries.map(d => d);
    }
    if (this.tooltip) {
      this._tooltip = {...this._tooltip, ...this.tooltip};
    }
    if (this.lineWidth) {
      this._lineWidth = this.lineWidth;
    }
    if (this.XAxis) {
      this._primaryXAxis = {...this._primaryXAxis, ...this.XAxis};
    }
    if (this.YAxis) {
      this._primaryYAxis = {...this._primaryYAxis, ...this.YAxis};
    }
  }

}
