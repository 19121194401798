import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Vendor } from '../../../../../models/Vendor';
import { SpinnerService } from '../../../../../services/spinner/spinner.service';
import { VendorsService } from '../../../../../services/vendors/vendors.service';

@Component({
  selector: 'app-new-vendor-list',
  templateUrl: './new-vendor-list.component.html',
  styleUrls: ['./new-vendor-list.component.css'],
  providers:[VendorsService]
})
export class NewVendorListComponent implements OnInit{
  vendor: Vendor;
  listings: Vendor[];
  
  constructor(
    private vendorservice: VendorsService, private activatedRoute: ActivatedRoute, 
    private router:Router,
    private spinnerService:SpinnerService
  ) { 

  }

  ngOnInit() {
  }

  addVendor(form:NgForm){
    this.spinnerService.toggle(true);
    this.vendorservice.createNewVendor(form.value).subscribe((result)=>{  
      this.router.navigate(['/vendorInfo']); 
      this.spinnerService.toggle(false);
    },
    (err)=>{
      this.spinnerService.toggle(false);
    });
  }
}