import { Injectable } from '@angular/core';
import { DataSourceChangedEventArgs, DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { concatMap, delay, map, mergeMap, retryWhen, scan, take } from 'rxjs/operators';
import { DataValidationService } from '../data-validation/data-validation.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class Ej2RuleHistoryService extends Subject<any> {

  constructor(private dataValidationService:DataValidationService, private spinnerService:SpinnerService) { 
    super();
  }

  public async execute(state:any, id:string) {
    this.spinnerService.toggle(true);
    var sortOrderDict = {"ascending" : "asc", "descending": "desc"};

    let ruleHistoryJobStartResponse:any = await this.dataValidationService.getValidationRuleHistory(
      id, 
      (state.skip ? state.skip : 0),
      (state.take ? state.take : 20),
      (state.sorted ? state.sorted[0].name : "epoch"),
      (state.sorted ? sortOrderDict[state.sorted[0].direction.toLowerCase()] : "desc"),
      "",
      1
    ).toPromise();


    if ( ruleHistoryJobStartResponse && ruleHistoryJobStartResponse.dataId && ruleHistoryJobStartResponse.totalId) {              
      this.dataValidationService.getValidationRuleHistoryData(
          this.dataValidationService.selectedValidationRule.value.uuid, 
          ruleHistoryJobStartResponse.dataId, 
          ruleHistoryJobStartResponse.totalId, 
          1
      ).pipe(          
        retryWhen(result =>            
          result.pipe(
            scan((acc, error) => ({ count: acc.count + 1, error }), {
              count: 0,
              error: undefined as any,
            }),
            concatMap(result => {
              // here we can check the error.
              // We can specify the retry only if we are getting 5xx errors for instance.
              if (result.error.status === 503) {
                return of(result);
              }
              // in other cases we throw an error down the pipe
              return throwError(result);
            }),
            mergeMap( payload =>{
              return of(payload).pipe(delay(1000 * payload.count))
            }),
            // we can keep calling forever but usually we want to avoid this.
            // So, we set the number of attempts including the initial one.
            take(10),
            o => concat(o, throwError({message:`Unable to load data. Please try again or contact support`, code:666}))
          )
        ),
      ).subscribe((historyData)=>{
        this.spinnerService.toggle(false);
        super.next({
          count: historyData.recordsTotal,
          result: historyData.data
        });
      });
    }           
  }
}
