<div class="be-splash-screen" *ngIf="customerService.customers.length > 1">
  <div class="be-wrapper be-login">
   <div class="be-content">
     <div class="main-content container-fluid">
       <div class="splash-container">
         <div class="card be-loading">
           <div class="card-header"><img src="assets/img/logo-xx.png" alt="logo" width="52" height="52" class="logo-img"><br/>Vault JS<span class="splash-description">Please select the account for this session:</span></div>
           <div class="card-body" style="height: 500px;">
              <ng-container *ngFor="let customer of customerService.customers">
                  <table class="table">
                    <tr><td><a style="cursor: pointer;" (click)="selectAccount(customer.id);">{{customer.name}}</a></td></tr>
                  </table>
                </ng-container>
           </div>           
         </div>          
       </div>
     </div>
   </div>
 </div>
</div>