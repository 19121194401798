<div class="card card-default" style="padding: 10px; height: 195px">
    <div class="card-header" style="padding: 10px; margin: 0px;">Scan Recency
    </div>
    <div class="card-body" *ngIf="isLoaded; else loading" style="margin-bottom: 0; padding: 10px;">
        <div style="border: 0px solid black; width: 100%; margin: auto;
             display: flex; padding: 0; flex-direction: column; color:black; justify-content: flex-start; gap:2px; ">
            <div *ngFor="let bucket of data; let i = index"
                style="display: flex; flex-direction: row; align-content: center; gap: 10px; align-items: center;">
                <div class="overflow" [style.width]="((bucket/2)+1)+'%'" [style.background-color]="theme[i]"
                    style="text-overflow: clip; overflow: hidden; overflow-wrap: normal; margin:0; border-radius: 0.25rem;">
                </div>
                <div style="width: fit-content; height: fit-content;"><span
                        style="font-size: large;">{{bucket.toFixed(5).replace(percentRegex,"")}}%</span>
                    <span style="color: grey; margin-left: 5px;">{{'('+datasource[i]+' pages)'}}</span>
                </div>
            </div>
        </div>
        <div style="width: 100%; margin: auto; display: flex; padding: 0; flex-direction: row;">
            <div *ngFor="let bucket of data; let i = index" [style.width]=" ((1/data.length)*100) + '%'"
                style="display: flex; flex-direction: row; gap:5px; align-items: center; margin-top: 10px; flex-wrap: wrap;">
                <div style="width: 10px;height: 10px; border-radius: 0.25rem;" [style.background-color]="theme[i]">
                </div>
                <div style="font-weight: bold;">{{recencyLabels[i]}}</div>
            </div>
        </div>
    </div>
    <ng-template #loading>
        <div class="card-body">
            LOADING...
        </div>
    </ng-template>

</div>