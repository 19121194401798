import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigComponent } from './components/alerting/config/config.component';
import { AuthCallbackIdPComponent } from './components/auth/auth-callback-idp/auth-callback-idp.component';
import { AuthCallbackComponent } from './components/auth/auth-callback/auth-callback.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';
import { CodeFilesComponent } from './components/code-files/code-files.component';
import { ConfigManagementComponent } from './components/configManagement/config-management/config-management.component';
import { ConfigPageListComponent } from './components/configManagement/config-page-list/config-page-list.component';
import { DataVizComponent } from './components/data-viz/data-viz.component';
import { DataViz2Component } from './components/data-viz2/data-viz2.component';
import { DataViz3Component } from './components/data-viz3/data-viz3.component';
import { DataViz4Component } from './components/data-viz4/data-viz4.component';
import { RealtimeEventViewComponent } from './components/developer/tools/realtime-event-view/realtime-event-view.component';
import { RealtimeRegionalMapComponent } from './components/developer/tools/realtime-regional-map/realtime-regional-map.component';
import { ToolsComponent } from './components/developer/tools/tools.component';
import { DomaineditComponent } from './components/developer/tools/vendor-domains-list/domainedit/domainedit.component';
import { NewDomainComponent } from './components/developer/tools/vendor-domains-list/new-domain/new-domain.component';
import { VendorDomainsListComponent } from './components/developer/tools/vendor-domains-list/vendor-domains-list.component';
import { NewVendorListComponent } from './components/developer/tools/vendor-domains/new-vendor-list/new-vendor-list.component';
import { VendorDomainsComponent } from './components/developer/tools/vendor-domains/vendor-domains.component';
import { VendorEditComponent } from './components/developer/tools/vendor-domains/vendor-edit/vendor-edit.component';
import { AvancedEventRuleComponent } from './components/events/avanced-event-rule/avanced-event-rule.component';
import { CodeDiffComponent } from './components/events/code-diff/code-diff.component';
import { EventRuleComponent } from './components/events/event-rule/event-rule.component';
import { IncidentDetailsComponent } from './components/incidents/incident-details/incident-details.component';
import { IncidentsComponent } from './components/incidents/incidents/incidents.component';
import { InputSpyComponent } from './components/input-spy/input-spy.component';
import { LinkDiscoveryConfigComponent } from './components/link-discovery/link-discovery-config/link-discovery-config.component';
import { ListComponent } from './components/list/list.component';
import { AnalysisDetailsComponent } from './components/page/analysis-details/analysis-details.component';
import { PageDetailsComponent } from './components/page/page-details/page-details.component';
import { PageHistoryDetailsComponent } from './components/page/page-history-details/page-history-details.component';
import { PageRunnerComponent } from './components/page/page-runner/page-runner.component';
import { PageComponent } from './components/page/page/page.component';
import { ScriptDetailsComponent } from './components/page/scripted-page-runner/script-details/script-details.component';
import { ScriptHistoryComponent } from './components/page/scripted-page-runner/script-history/script-history.component';
import { ScriptedPageRunnerComponent } from './components/page/scripted-page-runner/scripted-page-runner.component';
import { QueryDetailsComponent } from './components/queries/admin/query-details/query-details.component';
import { QueriesComponent } from './components/queries/queries.component';
import { CustomReportViewerComponent } from './components/reports/custom-report-viewer/custom-report-viewer.component';
import { DashboardGlobalComponent } from './components/reports/dashboard/global/global.component';
import { DataCollectionSummaryReportComponent } from './components/reports/data-collection-summary-report/data-collection-summary-report.component';
import { GlobalComponent } from './components/reports/disney/global/global.component';
import { GroupComponent } from './components/reports/disney/group/group.component';
import { SiteComponent } from './components/reports/disney/site/site.component';
import { AdobeReportComponent } from './components/reports/cibc/adobe-report/adobe-report.component';
import { ReportOverviewComponent } from './components/reports/report-overview/report-overview.component';
import { SecretsComponent } from './components/secrets/secrets/secrets.component';
import { AccountSwitchComponent } from './components/shared-ui/account-switch/account-switch.component';
import { ProfileComponent } from './components/shared-ui/profile/profile.component';
import { RootComponent } from './components/shared-ui/root/root.component';
import { HarViewerComponent } from './components/standalone/har-viewer/har-viewer.component';
import { NodesComponent } from './components/standalone/nodes/nodes.component';
import { Nodes2Component } from './components/standalone/nodes2/nodes2.component';
import { ScreenshotComponent } from './components/standalone/screenshot/screenshot.component';
import { UserDetailsComponent } from './components/users/details/user-details/user-details.component';
import { UsersComponent } from './components/users/users.component';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { ConditionDetailsComponent } from './components/validation/condition/condition-details/condition-details.component';
import { ConditionComponent } from './components/validation/condition/condition.component';
import { RuleDetailsComponent } from './components/validation/rule/rule-details/rule-details.component';
import { TestSuiteDetailsComponent } from './components/validation/test-suite/test-suite-details/test-suite-details.component';
import { TestSuiteComponent } from './components/validation/test-suite/test-suite.component';
import { ValidationOverviewComponent } from './components/validation/validation-overview/validation-overview.component';
import { VendorIDValidationList } from './components/vendor-id-validation-list/vendor-id-validation-list.component';
import { DomainDefinitionDetailComponent } from './components/vendorLibrary/domain-definition-detail/domain-definition-detail.component';
import { DomainDefinitionComponent } from './components/vendorLibrary/domain-definition/domain-definition.component';
import { ExtractorDetailComponent } from './components/vendorLibrary/extractor-detail/extractor-detail.component';
import { VendorLibraryResolver } from './components/vendorLibrary/resolvers/vendorLibrary.resolver';
import { ResourceMatcherDetailComponent } from './components/vendorLibrary/resource-matcher-detail/resource-matcher-detail.component';
import { UnclassifiedRequestsComponent } from './components/vendorLibrary/unclassified-requests/unclassified-requests.component';
import { CodeFileDetailComponent } from './components/vendors/code-file-detail/code-file-detail.component';
import { RequestDetailComponent } from './components/vendors/request-detail/request-detail.component';
import { VendorDetailsComponent } from './components/vendors/vendor-details/vendor-details.component';
import { VendorsComponent } from './components/vendors/vendors.component';
import { Vendors2Component } from './components/vendors2/vendors2.component';
import { Vendors3Component } from './components/vendors3/vendors3.component';
import { DataResolver } from './resolvers/data.resolver';
import { PageResolver } from './resolvers/page.resolver';
import { PageHistoryResolver } from './resolvers/pageHistory.resolver';
import { PageRunnerScriptResolver } from './resolvers/pageRunnerScript.resolver';
import { QueryResolver } from './resolvers/query.resolver';
import { RootResolver } from './resolvers/root.resolver';
import { TestSuiteResolver } from './resolvers/testSuite.resolver';
import { UserResolver } from './resolvers/user.resolver';
import { ValidationRuleResolver } from './resolvers/validationRule.resolver';
import { VendorResolver } from './resolvers/vendor.resolver';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { CodeReviewComponent } from './components/events/code-review/code-review.component';
import { VppaReportComponent } from './components/reports/vppa-report/vppa-report.component';
import { VendorReviewComponent } from './components/events/vendor-review/vendor-review.component';
import { DomainReviewComponent } from './components/events/domain-review/domain-review.component';
import { CodeReviewComponentV2 } from './components/events/code-review-v2/code-review-v2.component';
import { FingerprintDashboardComponent } from './components/reports/fingerprint-dashboard/fingerprint-dashboard.component';
import { TrackerSummaryDashboardComponent } from './components/reports/tracker-summary-dashboard/tracker-summary-dashboard.component';
import { TrackerVendorDashboardComponent } from './components/reports/tracker-vendor-dashboard/tracker-vendor-dashboard.component';
import { ReportLibraryComponent } from './components/report-library/report-library.component';
import { RawReportViewerComponent } from './components/report-library/raw-report-viewer/raw-report-viewer.component';
import { ReportDataGridComponent } from './components/report-data-grid/report-data-grid.component';
import { FbDetailsComponent } from './components/reports/fb-details/fb-details.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'request-password',
    component: PasswordResetComponent
  },
  {
    path: 'authCallback',
    component: AuthCallbackComponent
  },
  {
    path: 'authCallbackIdP',
    component: AuthCallbackIdPComponent
  },
  {
    path: 'accountSelect',
    component: AccountSwitchComponent
  },
  {
    path: 'codeDiff',
    component: CodeDiffComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedRole: ['read:analyst']
    }
  },
  {
    path: 'har',
    component: HarViewerComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedRole: ['read:ui']
    }
  },
  {
    path: 'screenshot',
    component: ScreenshotComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedRole: ['read:ui']
    }
  },
  {
    path: 'nodes',
    component: NodesComponent,
    canActivate:[AuthGuardService]
  },
  {
    path: '',
    component: RootComponent,
    canActivate: [AuthGuardService],
    resolve: {
      rootData: RootResolver
    },
    children:[
      {
        path: '',
        component: VendorsComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reportData',
        component: ReportDataGridComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'dataViz',
        component: DataVizComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'dataViz2',
        component: DataViz2Component,
        canActivate:[AuthGuardService]
      },
      {
        path: 'dataViz3',
        component: DataViz3Component,
        canActivate:[AuthGuardService]
      },
      {
        path: 'dataViz4',
        component: DataViz4Component,
        canActivate:[AuthGuardService]
      },
      {
        path: 'inputSpy',
        component: InputSpyComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors',
        component: VendorsComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors2',
        component: Vendors2Component,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors3',
        component: Vendors3Component,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendorInfo',
        component: VendorDomainsComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['write:admin']
        }
      },
      {
        path: 'vendorDomains/:vendorId',
        component: VendorDomainsListComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['write:admin']
        }
      },
      {
        path: 'newVendor',
        component: NewVendorListComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['write:admin']
        }
      },
      {
        path:'newdomain/:vendorId',
        component: NewDomainComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['write:admin']
        }
      },
      {
          path:'vendorEdit/:name',
          component: VendorEditComponent,
          canActivate: [AuthGuardService],
          data: {
            expectedRole: ['write:admin']
          }
      },
      {
        path:'domainEdit/:vendorId',
        component: DomaineditComponent,
        canActivate: [AuthGuardService],
          data: {
            expectedRole: ['write:admin']
          }
      },
      {
        path: 'codeFiles',
        component: CodeFilesComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors/library',
        component: VendorsComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors/:vendor',
        component: VendorDetailsComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors/:vendor/code/:fileId',
        component: CodeFileDetailComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'vendors/:vendor/request/:requestId',
        component: RequestDetailComponent,
        canActivate:[AuthGuardService],
        resolve: {
          vendorState: VendorResolver
        }
      },
      {
        path: 'eventPipeline',
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'rules',
            component: EventRuleComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'advancedRules',
            component: AvancedEventRuleComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:analyst', 'write:analyst']
            }
          }
        ]
      },
      {
        path: 'events',
        canActivate: [AuthGuardService],
        children:[
          {
            path: 'list',
            component: UnclassifiedRequestsComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:analyst', 'write:analyst']
            }
          },

        ]
      },
      {
        path: 'validation',
        canActivate: [AuthGuardService],
        children:[
          {
            path: 'overview',
            component: ValidationOverviewComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:ui']
            }
          },
          {
            path: 'testSuites',
            component: TestSuiteComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:admin']
            }
          },
          {
            path: 'testSuites/:testSuiteId',
            component: TestSuiteDetailsComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:admin']
            },
            resolve: {
              testSuite: TestSuiteResolver
            }
          },

          {
            path: 'testSuites/:testSuiteId/rules/:validationRuleId',
            component: RuleDetailsComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:admin']
            },
            resolve: {
              testSuite: TestSuiteResolver,
              validationRule: ValidationRuleResolver
            }
          },
          {
            path: 'conditions',
            component: ConditionComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:admin']
            }
          },
          {
            path: 'conditions/:conditionId',
            component: ConditionDetailsComponent,
            canActivate: [AuthGuardService],
            data: {
              expectedRole: ['read:admin']
            }
          },
        ]
      },
      {
        path: 'queries',
        component: QueriesComponent,
        canActivate:[AuthGuardService],
        data: {
          expectedRole: ['read:admin']
        }
      },
      {
        path: 'pages',
        component: PageComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'pages/:pageId',
        component: PageDetailsComponent,
        canActivate: [AuthGuardService],
        resolve: {
          data: PageResolver
        }
      },
      {
        path: 'pages/:pageId/history/:pageHistoryId',
        component: PageHistoryDetailsComponent,
        canActivate: [AuthGuardService],
        resolve: {
          page: PageResolver,
          pageHistory: PageHistoryResolver
        }
      },
      {
        path: 'pages/:pageId/history/:pageHistoryId/nodes',
        component: Nodes2Component,
        canActivate:[AuthGuardService],
        resolve: {
          data: PageResolver,
          pageHistory: PageHistoryResolver
        }
      },
      {
        path: 'pages/:pageId/history/:pageHistoryId/analysis',
        component: AnalysisDetailsComponent,
        canActivate: [AuthGuardService],
        resolve: {
          data: PageResolver,
          pageHistory: PageHistoryResolver
        }
      },
      {
        path: 'automatedUsers',
        component: ScriptedPageRunnerComponent,
        data: {
          expectedRole: ['read:admin', 'write:admin']
        }
      },
      {
        path: 'incidents',
        component: IncidentsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'incidents/details/:incidentId', // revert this after fixing the issue with the incident details page
        component: IncidentDetailsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'reports',
        component: ReportOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'report-library',
        component: ReportLibraryComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['read:ui']
        }
      },
      {
        path: 'reports/raw-report-viewer/:reportCode',
        component: RawReportViewerComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['read:ui']
        }
      },
      {
        path: 'reports/dataCollectionSummary',
        component: DataCollectionSummaryReportComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/consentSummary',
        component: ValidationSummaryComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['read:ui']
        }
      },
      {
        path: 'reports/custom/:reportName',
        component: CustomReportViewerComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ['read:ui']
        },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'reports/disney/global',
        component: GlobalComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/disney/group',
        component: GroupComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/disney/site',
        component: SiteComponent,
      canActivate:[AuthGuardService]
      },
      {
        path: 'reports/adobe-analytics',
        component: AdobeReportComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/dashboard/global',
        component: DashboardGlobalComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/vppa-report',
        component: VppaReportComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/fingerprint-dashboard',
        component: FingerprintDashboardComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/tracker-summary-dashboard',
        component: TrackerSummaryDashboardComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/tracker-vendor-dashboard',
        component: TrackerVendorDashboardComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'reports/fb-details-dashboard',
        component: FbDetailsComponent,
        canActivate:[AuthGuardService]
      },
      {
        path: 'admin',
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'codeReview',
            component: CodeReviewComponent,
            data: {
              expectedRole: ['read:analyst', 'write:analyst']
            }
          },
          {
            path: 'codeReviewV2',
            component: CodeReviewComponentV2,
            data: {
              expectedRole: ['read:analyst', 'write:analyst']
            }
          },
          {
            path: 'domainReview',
            component: DomainReviewComponent,
            data: {
              expectedRole: ['read:analyst', 'write:analyst']
            }
          },
          {
            path: 'vendorReview',
            component: VendorReviewComponent,
            data: {
              expectedRole: ['read:analyst', 'write:analyst']
            }
          },
          {
            path: 'alerts/config',
            component: ConfigComponent,
            data: {
              expectedRole: ['read:user', 'write:user']
            }
          },
          {
            path: 'pagerunner/config',
            component: PageRunnerComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'automatedUsers/:scriptId',
            component: ScriptDetailsComponent,
            resolve: {
              script: PageRunnerScriptResolver,
            },
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'automatedUsers/:scriptId/history',
            component: ScriptHistoryComponent,
            resolve: {
              script: PageRunnerScriptResolver,
            },
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'linkDiscovery/config',
            component: LinkDiscoveryConfigComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'lists',
            component: ListComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'vendor-id-validation-list',
            component: VendorIDValidationList,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'secrets',
            component: SecretsComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'users',
            component: UsersComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'users/:userId',
            component: UserDetailsComponent,
            resolve: {
                user: UserResolver
            },
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'queries/:queryId',
            component: QueryDetailsComponent,
            resolve: {
                query: QueryResolver
            },
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'vendorLibrary',
            component: DomainDefinitionComponent,
            data: {
              expectedRole: ['read:developer', 'write:developer']
            }
          },
          {
            path: 'configmanagement',
            component: ConfigManagementComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'configmanagement/configPageLinks/:configId',
            component: ConfigPageListComponent,
            data: {
              expectedRole: ['read:admin', 'write:admin']
            }
          },
          {
            path: 'vendorLibrary/domainDefinition/:domainDefId',
            component: DomainDefinitionDetailComponent,
            data: {
              expectedRole: ['read:developer', 'write:developer']
            },
            resolve: {
              domainDefinition: VendorLibraryResolver
            }
          },
          {
            path: 'vendorLibrary/domainDefinition/:domainDefId/resourceMatcher/:resourceMatcherId',
            component: ResourceMatcherDetailComponent,
            data: {
              expectedRole: ['read:developer', 'write:developer']
            },
            resolve: {
              domainDefinition: VendorLibraryResolver
            }
          },
          {
            path: 'vendorLibrary/domainDefinition/:domainDefId/resourceMatcher/:resourceMatcherId/extractor/:extractorId',
            component: ExtractorDetailComponent,
            data: {
              expectedRole: ['read:developer', 'write:developer']
            },
            resolve: {
              domainDefinition: VendorLibraryResolver
            }
          }
        ]
      },
      {
        path: 'developer/tools',
        component: ToolsComponent,
        data: {
          expectedRole: ['read:developer', 'write:developer']
        }
      },
      {
        path: 'developer/eventViewer',
        component: RealtimeEventViewComponent,
        data: {
          expectedRole: ['read:analyst', 'write:analyst']
        }
      },
      {
        path: 'developer/geoViewer',
        component: RealtimeRegionalMapComponent,
        data: {
          expectedRole: ['read:analyst', 'write:analyst']
        }
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers:[
    RootResolver,
    UserResolver,
    DataResolver,
    PageResolver,
    PageHistoryResolver,
    PageRunnerScriptResolver,
    TestSuiteResolver,
    ValidationRuleResolver,
    QueryResolver,
    VendorLibraryResolver,
    VendorResolver
  ]
})
export class AppRoutingModule { }
