import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../../services/auth/auth.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
declare var $:any;
import { DataValidationService } from '../../../services/data-validation/data-validation.service';
import { Condition } from '../../../models/Condition';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { QueryBuilderComponent } from '@syncfusion/ej2-angular-querybuilder';
import { Browser } from '@syncfusion/ej2-base';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { Vendor } from '../../../models/Vendor';
import { DataStateChangeEventArgs, Grid, GridComponent, PageSettingsModel, Resize, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TimezoneService } from '../../../services/timezone.service';
import { Ej2ConditionProxyService } from '../../../services/ej2Proxies/ej2-condition-proxy.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from '../../../services/spinner/spinner.service';


@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.css']
})
export class ConditionComponent implements OnInit {

  CONDITION_JS_TEMPLATE: string = `(data) => {\n\treturn {\n\t\tpageMatch: true,\n\t\trequestsMatched: data.requests\n\t};\n};`;

  tooltipContent:string;

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('conditionsGrid') public grid: GridComponent;

  exportId:string;
  notificationOptions:any;
  exportButtonDisabled:boolean;

  selectedCondition:Condition;
  newConditionForm:FormGroup;

  codeMirrorOptions:any;
  @ViewChild('codeEditor') codeEditor:CodemirrorComponent;
  @ViewChild('querybuilder') qryBldrObj: QueryBuilderComponent;
  typeValues:string[] = ["Document", "Image", "Script", "XHR"];
  typeTemplate:any;
  vendorTemplate:any;
  typeOperators: any[] = [ 
    { value: 'equal', key: 'Is' },
    { value: 'notequal', key: 'Is Not' },
  ];
  vendors:Vendor[];

  conditions$:Observable<any>;

  constructor(
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private notificationService: NotificationsService,
    private zone:NgZone,
    public authService:AuthService,
    private dataValidationService:DataValidationService,
    public _fb:FormBuilder,
    public vendorService:VendorsService,
    public timezoneService:TimezoneService,
    public ej2ProxyService:Ej2ConditionProxyService,
    public spinnerService:SpinnerService
    ) {       
      this.conditions$ = this.ej2ProxyService.pipe(tap(()=>{this.spinnerService.toggle(false);}));
      let state = { skip: 0, take: 20 };
      this.ej2ProxyService.execute(state);

      this.sitemapService.update(this.route);
      this.tooltipContent = "Browse or search for conditions";

      this.notificationOptions = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };

      this.dateFormat = {type: 'dateTime', skeleton:'short'}
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 20, 
        pageCount: 4, 
        pageSizes: [20, 25, 50]
      };
      this.toolbar = ['Search'];
      this.sortSettings = {
        columns: [
          { field: 'name', direction: 'Ascending'}
        ]
      };

      Grid.Inject(Resize);
      
      this.exportButtonDisabled = false;

      this.newConditionForm = this._fb.group({
        id: -1,
        name: '',
        description: '',
        filterJavaScript: this.CONDITION_JS_TEMPLATE
      });

      this.codeMirrorOptions = {
        lineNumbers: true,
        mode: 'javascript',
      };
  }

  createdControl(): void {
    if (Browser.isDevice) {
        this.qryBldrObj.summaryView = true;
      }
  }

  createCondition(){
    $('#modal-create-edit-condition').modal('hide');
    
    console.log("createCondition");
    console.log(this.newConditionForm.value);
    this.spinnerService.toggle(true);
    if ( this.newConditionForm.value.id == -1 ){
      const newCondition = new Condition(-1, this.newConditionForm.value.name, this.newConditionForm.value.description, "", new Date(), this.codeEditor.codeMirror.getValue());
      this.dataValidationService.createCondition(newCondition).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The condition has been saved.', this.notificationOptions);
        this.grid.refresh();
      });
    } else {
      this.selectedCondition.name = this.newConditionForm.value.name;
      this.selectedCondition.description = this.newConditionForm.value.description;
      this.selectedCondition.filterJavaScript = this.codeEditor.codeMirror.getValue();
      this.dataValidationService.updateCondition(this.selectedCondition).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The test suite has been updated.', this.notificationOptions);
        this.grid.refresh();
      });
    }
  };

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();            
  }

  ngOnInit() {

    this.vendorService.getVendors().subscribe((vendors)=>{
      this.vendors = vendors;
    });
    
    $('#modal-create-edit-condition').on('shown.bs.modal', (e) => {
      this.codeEditor.codeMirror.setSize("99%", "99%");
      this.codeEditor.codeMirror.refresh();
      console.log('Set editor size...');
    });

    $('#modal-create-edit-condition').on('show.bs.modal', (e) => {     
      if (e.relatedTarget){
        console.log('New !');
        this.newConditionForm = this._fb.group({
          id: -1,
          name: '',
          description: '',
          filterJavaScript: this.CONDITION_JS_TEMPLATE
        });
        this.codeEditor.codeMirror.setValue(this.CONDITION_JS_TEMPLATE);
        this.zone.run(()=>{
          console.log("Refreshing condition edit modal zone...");          
        });
      }
    });
  }

  onEditConditionClick(condition){
    this.selectedCondition = condition;
    console.log("Edit", this.selectedCondition);
    this.newConditionForm = this._fb.group({
      id: this.selectedCondition.id,
      name: this.selectedCondition.name,
      description: this.selectedCondition.description,
      filterJavaScript: this.selectedCondition.filterJavaScript
    });
    this.codeEditor.codeMirror.setValue(this.selectedCondition.filterJavaScript);
    $("#modal-create-edit-condition").modal('toggle');
  }

  showDeletePrompt(condition){
    this.selectedCondition = condition;
    console.log("Delete", this.selectedCondition);
    $("#mod-confirm-delete").modal('toggle');    
  }

  onDeleteClick(){
    $("#mod-confirm-delete").modal('toggle');
    if ( this.selectedCondition){
      this.spinnerService.toggle(true);
      this.dataValidationService.deleteCondition(this.selectedCondition).subscribe((response)=>{
        console.log(response);
        this.notificationService.success('Success!', 'The condition has been deleted.', this.notificationOptions);
        this.grid.refresh();
      })
    }
  }

  onDataBound(){    
    // this.grid.autoFitColumns();
  }

  onGridCreate(){
    this.grid.showSpinner = () => true;
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);    
    this.spinnerService.toggle(true);
    this.ej2ProxyService.execute(state);
  }

}

