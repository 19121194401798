import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './services/auth/auth.service';
import { AuthGuardService } from './services/auth/auth-guard.service';

import { VendorsService } from './services/vendors/vendors.service';
import { SitemapService } from './services/sitemap/sitemap.service';
import { AlertsService } from './services/alerts/alerts.service';
import { TopicService } from './services/topic/topic.service';
import { TokenInterceptor } from './services/auth/token.interceptor';

import { AuthComponentsModule } from './components/auth/auth-components.module';
import { SharedUiComponentsModule } from './components/shared-ui/shared-ui-components.module';
import { VendorComponentsModule } from './components/vendors/vendor-components.module';
import { PageComponentsModule } from './components/page/page-components.module';

import { CodeFilesComponent } from './components/code-files/code-files.component';
import { QueryComponentsModule } from './components/queries/query-components.module';
import { EventComponentsModule } from './components/events/event-components.module';
import { StandaloneComponentsModule } from './components/standalone/standalone-components.module';
import { ValidationComponentsModule } from './components/validation/validation-components.module';
import { ReportComponentsModule } from './components/reports/report-components.module';
import { IncidentComponentsModule } from './components/incidents/incident-components.module';
import { DeveloperComponentsModule } from './components/developer/developer-components.module';
import { UserComponentsModule } from './components/users/user-components.module';
import { AlertingComponentsModule } from './components/alerting/alerting-components.module';
import { LinkDiscoveryComponentsModule } from './components/link-discovery/link-discovery-components.module';

import { VendorLibraryComponentsModule } from './components/vendorLibrary/vendor-library.module';

import { DetailRowService, GridAllModule, ToolbarService } from '@syncfusion/ej2-angular-grids';

import { Ng9OdometerModule } from 'ng9-odometer';

import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorModule, SparklineAllModule, BubbleSeriesService, TooltipService } from '@syncfusion/ej2-angular-charts';

import { SkeletonModule } from 'primeng/skeleton';
import { Vendors2Component } from './components/vendors2/vendors2.component';
import { Vendors3Component } from './components/vendors3/vendors3.component';
import { AutoComplete, AutoCompleteModule, ComboBoxModule, DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DataVizComponent } from './components/data-viz/data-viz.component';
import { PivotViewModule } from '@syncfusion/ej2-angular-pivotview';
import { SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';
import { DataViz2Component } from './components/data-viz2/data-viz2.component';
import { DataViz3Component } from './components/data-viz3/data-viz3.component';
import { DataViz4Component } from './components/data-viz4/data-viz4.component';
import { InputSpyComponent } from './components/input-spy/input-spy.component';
import { SecretsComponent } from './components/secrets/secrets/secrets.component';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { SparklineModule } from '@syncfusion/ej2-angular-charts';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs'
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { AccordionAllModule, TabItem } from '@syncfusion/ej2-angular-navigations';
import { TooltipAllModule,TooltipModel,Tooltip } from '@syncfusion/ej2-angular-popups';

import { ListComponent } from './components/list/list.component';
import { DataVizNurtecComponent } from './components/data-viz-nurtec/data-viz-nurtec.component';
import { DataVizNurtec2Component } from './components/data-viz-nurtec2/data-viz-nurtec2.component';
import { CustomReportService } from './services/customReportService/custom-report-service.service';
import { ConfigManagementComponent } from './components/configManagement/config-management/config-management.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ConfigPageListComponent } from './components/configManagement/config-page-list/config-page-list.component';
import { VendorIDValidationList } from './components/vendor-id-validation-list/vendor-id-validation-list.component';
import { TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ReportLibraryComponent } from './components/report-library/report-library.component';
import { ReportSelectionTileComponent } from './components/report-library/report-selection-tile/report-selection-tile.component';
import { RawReportViewerComponent } from './components/report-library/raw-report-viewer/raw-report-viewer.component';
import { RegionPipe } from './pipes/RegionPipe/region.pipe';
import { ReportDataGridComponent } from './components/report-data-grid/report-data-grid.component';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import { MenuAllModule } from '@syncfusion/ej2-angular-navigations';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
@NgModule({
  declarations: [
    AppComponent,
    CodeFilesComponent,
    Vendors2Component,
    Vendors3Component,
    DataVizComponent,
    DataViz2Component,
    DataViz3Component,
    DataViz4Component,
    InputSpyComponent,
    SecretsComponent,
    ValidationSummaryComponent,
    ListComponent,
    DataVizNurtecComponent,
    DataVizNurtec2Component,
    ConfigManagementComponent,
    ConfigPageListComponent,
    VendorIDValidationList,
    ReportLibraryComponent,
    ReportSelectionTileComponent,
    RawReportViewerComponent,
    // RegionPipe,
    ReportDataGridComponent,

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    AuthComponentsModule,
    SharedUiComponentsModule,
    VendorComponentsModule,
    PageComponentsModule,
    QueryComponentsModule,
    EventComponentsModule,
    StandaloneComponentsModule,
    ValidationComponentsModule,
    ReportComponentsModule,
    IncidentComponentsModule,
    DeveloperComponentsModule,
    UserComponentsModule,
    AlertingComponentsModule,
    LinkDiscoveryComponentsModule,
    GridAllModule,
    SkeletonModule,
    SparklineAllModule,
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorModule,
    DropDownListAllModule,
    PivotViewModule,
    SpreadsheetAllModule,
    VendorLibraryComponentsModule,
    SparklineModule,
    ComboBoxModule,
    Ng9OdometerModule.forRoot(),
    TextBoxModule,
    ListViewModule,
    DialogModule,
    AutoCompleteModule,
    TabAllModule,
    CodemirrorModule,
    AccordionAllModule,
    TooltipAllModule,
    QueryBuilderModule,
    MenuAllModule,
    SwitchModule
  ],
  providers: [
    Title,
    AuthService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    VendorsService,
    SitemapService,
    AlertsService,
    TopicService,
    ToolbarService,
    DetailRowService,
    BubbleSeriesService,
    TooltipService,
    CustomReportService
  ],
  // exports: [RegionPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
