import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { EditService, ToolbarService, PageService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-secrets',
  templateUrl: './secrets.component.html',
  styleUrls: ['./secrets.component.css'],
  providers: [ToolbarService, EditService, PageService]
})
export class SecretsComponent implements OnInit {

  keys:string[];
  secrets:any;
  toolbar:any;
  creds:any;
  SECRET_ID:string;
  @ViewChild('grid') public grid: GridComponent;

  public editSettings: Object;

  constructor(
    public authService:AuthService, 
    public spinnerService:SpinnerService, 
    public customerService:CustomerService,
    public notificationsService:NotificationsService
  ) { 
    this.keys = [];
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true , newRowPosition: 'Bottom', mode:'Batch' };
    this.toolbar = ['Add', 'Delete', 'Update', 'Cancel'];
    this.SECRET_ID = `secret`;
  }

  ngOnInit(): void {
    this.loadSecrets();
  }

  loadSecrets(){
    this.spinnerService.toggle(true);
    this.customerService.getLists(this.SECRET_ID).subscribe((response)=>{
      let secretsObj = JSON.parse(response);
      if ( !response.error ) {
        this.secrets = Object.keys(secretsObj).map((k, i)=>{ return {index: i, key: k, value: secretsObj[k]} });
      }
      this.spinnerService.toggle();  
    })
  }

  beforeBatchSave(args){
    console.log(args);

    let updatedSecrets = {};
    for (var a of args.batchChanges.addedRecords){
      updatedSecrets[a.key] = a.value;
    }
    for (var s of this.secrets){
      let changed = args.batchChanges.changedRecords.filter((c)=>{return c.index == s.index});
      let deleted = args.batchChanges.deletedRecords.filter((c)=>{return c.index == s.index});
      if ( changed.length == 1 ) {
        let updated = changed[0];
        updatedSecrets[updated.key] = updated.value;        
      } else if (deleted.length == 1){
        // don't copy it to updated array
      } else {
        updatedSecrets[s.key] = s.value;        
      }
    }

    console.log(updatedSecrets);

    this.spinnerService.toggle(true);

    this.customerService.updateList(this.SECRET_ID, updatedSecrets).subscribe((updateResponse)=>{
      console.log(updateResponse);
      if ( !updateResponse.error ) {
        this.spinnerService.toggle();
        this.loadSecrets();
      }
    },
    (err)=>{
      console.log(err);
      this.notificationsService.error("Uh oh!", "An error occurred");
    });    
  }
}
