import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { DataStateChangeEventArgs, GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Condition } from '../../../../models/Condition';
import { ValidationRule } from '../../../../models/ValidationRule';
import { AuthService } from '../../../../services/auth/auth.service';
import { DataValidationService } from '../../../../services/data-validation/data-validation.service';
import { Ej2ValidationRuleProxyService } from '../../../../services/ej2Proxies/ej2-validation-rule-proxy.service';
import { SitemapService } from '../../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
declare var $:any;

@Component({
  selector: 'app-test-suite-details',
  templateUrl: './test-suite-details.component.html',
  styleUrls: ['./test-suite-details.component.css']
})
export class TestSuiteDetailsComponent implements OnInit {

  RULE_JS_TEMPLATE:string = `
  const URL = data.libs.url.URL;
  const idList = [""];
  
  // Go through requests and filter down to the ones we care about
  data.requests.filter((item) => {
      try {
          const parsedUrl = new URL(item.request.url);
          return true;
      } catch (e) {}
      return false;
  }).forEach((item) => {
      // do something with the important requests
  });
  
  return {
      success: true,
      message: 'validation message',
      data: {}
  };
  `;

  testSuiteId:number;
  rules:ValidationRule[];
  conditions:Condition[];
  tooltipContent:string;

  notificationOptions:any;

  selectedRule:ValidationRule;
  newRuleForm:FormGroup;
  codeMirrorOptions:any;
  @ViewChild('codeEditor') codeEditor:CodemirrorComponent;

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('rulesGrid') public grid: GridComponent;

  rules$:Observable<any>;

  constructor(
    private el: ElementRef,
    private dataValidationService:DataValidationService,
    private zone:NgZone,
    public authService:AuthService,
    private _fb:FormBuilder,
    private notificationService: NotificationsService,
    private router: Router,
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private spinnerService:SpinnerService,
    private ej2ProxyService:Ej2ValidationRuleProxyService
    ) { 

    this.rules$ = this.ej2ProxyService.pipe(tap(()=>{this.spinnerService.toggle(false);}));
    let state = { skip: 0, take: 20 };
    

    this.sitemapService.update(this.route);
    this.testSuiteId = dataValidationService.selectedTestSuite.value.id;
    console.log("Test Suite Id", this.testSuiteId);

    this.ej2ProxyService.execute(state, this.testSuiteId);

    this.conditions = [];
    this.notificationOptions = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    
    this.newRuleForm = this._fb.group({
      id: -1,
      name: '',
      description: '',
      enabled: false,
      validationJavaScript: this.RULE_JS_TEMPLATE,
      conditions: this.conditions
    });

    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
    };      

    this.dateFormat = {type: 'dateTime', skeleton:'short'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'name', direction: 'Ascending'}          
      ]
    };
    
  }

  onEditConditionClick(rule) {
    this.selectedRule = rule;
    this.newRuleForm = this._fb.group({
      id: this.selectedRule.id,
      name: this.selectedRule.name,
      description: this.selectedRule.description,
      enabled: this.selectedRule.enabled,
      validationJavaScript: this.selectedRule.validationJavaScript
    });
    this.codeEditor.codeMirror.setValue(this.selectedRule.validationJavaScript);
    this.codeEditor.codeMirror.setSize("99%", "99%");
    this.codeEditor.codeMirror.refresh();
    $("#modal-create-edit-rule").modal('toggle');
  }
  
  createRule(){
    $('#modal-create-edit-rule').modal('hide');
    console.log("createValidationRule");
    console.log(this.newRuleForm.value);
    var updatedConditions = $(".conditions").select2('data').map((c)=>{
      console.log(c);
      return Number(c.id.split(":")[1].trim());
    })
    
    console.log("updatedConditions");
    console.log(updatedConditions);
    
    this.spinnerService.toggle(true);
    if ( this.newRuleForm.value.id == -1 ){
      const newRule = new ValidationRule(-1, "", this.newRuleForm.value.name, this.newRuleForm.value.description, this.testSuiteId, "", "", new Date(), this.newRuleForm.value.enabled, 0, this.codeEditor.codeMirror.getValue());
      newRule.conditions = updatedConditions;
      this.dataValidationService.createValidationRule(newRule).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The rule has been saved.', this.notificationOptions);
        this.grid.refresh();
      },
      (err)=>{
        this.spinnerService.toggle(false);
      });
    } else {
      
      this.selectedRule.name = this.newRuleForm.value.name;
      this.selectedRule.description = this.newRuleForm.value.description;
      this.selectedRule.enabled = this.newRuleForm.value.enabled;
      this.selectedRule.validationJavaScript = this.codeEditor.codeMirror.getValue();
      this.selectedRule.conditions = updatedConditions;
      this.dataValidationService.updateValidationRule(this.selectedRule).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The rule has been updated.', this.notificationOptions);
        this.grid.refresh();  
      },
      (err)=>{
        this.spinnerService.toggle(false);
      });
    }
  };

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
    $(".conditions").select2({width: '100%', placeholder:'Select a condition(s)...'});
  }

  ngOnInit() {


    $('#modal-create-edit-rule').on('shown.bs.modal', (e) => {      
      this.codeEditor.codeMirror.setSize("99%", "99%");
      this.codeEditor.codeMirror.refresh();
      console.log('Set editor size...');
    });

    $('#modal-create-edit-rule').on('show.bs.modal', (e) => {      
      if (e.relatedTarget){
        this.newRuleForm = this._fb.group({
          id: -1,
          name: '',
          enabled: false,
          description: '',
          validationJavaScript: this.RULE_JS_TEMPLATE,
          conditions: this.conditions
        });
        this.codeEditor.codeMirror.setValue(this.RULE_JS_TEMPLATE);
      } else {
        var s2Val = [];
        
        this.selectedRule.conditions.forEach((c)=>{
          var foundCondition = this.conditions.filter((condition)=>{ return condition.id == c["id"] });
          var index = foundCondition ? this.conditions.indexOf(foundCondition[0]) : -1;
          if ( index >= 0) {
            s2Val.push(`${index}: ${c["id"]}`);
          }
        });

        console.log("s2Val")
        console.log(s2Val)
        $(".conditions").val(s2Val);  
        $(".conditions").trigger('change');
                  
        this.codeEditor.codeMirror.setValue(this.selectedRule.validationJavaScript);          
      }      
    });
    
    this.spinnerService.toggle(true);
    this.dataValidationService.getConditions(null, null, null, null, null, null, true).subscribe((results)=>{
      this.conditions = (results as any) as Condition[];
    },
    (err)=>{
      console.log(err);
    });    
  }

  showDeletePrompt(rule){
    this.selectedRule = rule;
    $("#mod-confirm-delete").modal('toggle');
  }

  onDeleteClick(){
    $("#mod-confirm-delete").modal('toggle');
    if ( this.selectedRule){
      this.spinnerService.toggle(true);
      this.dataValidationService.deleteValidationRule(this.selectedRule).subscribe((response)=>{
        console.log(response);
        this.notificationService.success('Success!', 'The validation rule has been deleted.', this.notificationOptions);
        this.grid.refresh();
      },
      (error)=>{
        this.spinnerService.toggle(false);
      })
    }
  }

  onGridCreate(){
    this.grid.showSpinner = () => true;
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);    
    this.spinnerService.toggle(true);
    this.ej2ProxyService.execute(state, this.testSuiteId);
  }

}
