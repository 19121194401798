<div class="row be-loading" [class.be-loading-active]="vizLoading">
  <div class="col-lg-12 graphDiv">
    <div class="card graphCard" style="height: 500px;">
      <div class="card-header card-header-divider">
        <div class="tools">
          <span class="icon zmdi zmdi-fullscreen" (click)="fullScreen();"></span>
        </div>
        Nodes<span class="card-subtitle"></span>
      </div>
      <div class="card-body">          
        <div id="d3Viz" style="width: 100%; height: 375px" ></div>
      </div>
    </div>
  </div>
</div>
<br/>
<div class="row">
  <div class="col-lg-12 requestsDiv">
    <div class="card card-table card-border be-loading" [class.be-loading-active]="dataLoading">
      <div class="card-body">        
          <ejs-grid #grid [dataSource]='gridData' allowPaging='true' allowSorting="true" [allowGrouping]="true" [groupSettings]="groupOptions" [pageSettings]='pageSettings' (dataBound)='dataBound()' (created)='created()' (load)='load()' [sortSettings]='sortSettings' [toolbar]="toolbar">
              <e-columns>            
                  <e-column field='domain' headerText="Domain"></e-column>
                  <e-column field='token' headerText='Token'></e-column>
              </e-columns>
          </ejs-grid>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div>
    </div>
  </div>
</div>