const json_data: string =
`
{
    "enabled": true,
    "timeout": 30000,
    "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36",
    "headers": [
      {
        "header": ""
      }
    ],
    "ensightenOptOut": true,
    "customerName": "",
    "consentReport": true
  }
`;

export { json_data };