export class TestSuite {

    public lastRun?:Date;
    public status?:string;
    public totalRules?:number;
    public passingRules?:number;
    public failingRules?:number;
    
    constructor(
        public id:number,
        public uuid:string,
        public name:string,    
        public customerId:number,        
        public description:string,
        public createdBy:number,
        public dateCreated:Date,
        public isPublic:boolean        
    ) {
        // this.lastRun = new Date();
        // this.status = "XXX";

    }
}
