import { Injectable } from '@angular/core';
import { from, of, Observable, concat, forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { S3 } from 'aws-sdk';
import * as AWS from 'aws-sdk';
import { environment as env } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ReportDetails } from '../../models/ReportDetails';
import { AllDefinedReports } from './AllDefinedReport';
import { ReportLibraryConfig } from '../../models/ReportLibraryConfig';
import { VaultNotificationService } from '../notifications/vault-notification.service';

// interface ReportLibraryConfig {
//   title?: string;
//   description?: string;
//   reportCode: string;
//   beautifiedPath?: string;
//   hasRaw?: boolean;
//   hidden: boolean;
//   icon?: string;
//   category?: string;
//   isCustom?: boolean;
//   isView?: boolean;
// }

@Injectable({
  providedIn: 'root',
})
export class ReportingLibraryService {
  private defaultConfig: ReportLibraryConfig[] = AllDefinedReports;
  private customerId: string;
  private bucket: string;
  constructor(
    private authService: AuthService,
    private notificationService: VaultNotificationService
  ) {
    this.customerId = this.authService.customerId();
    this.bucket = `${env.aws.customReportBucket}-${this.customerId}`;
  }

  /**
   * Retrieves the configuration for the report library.
   * @returns An observable that emits the report library configuration.
   */
  public getReportLibraryConfig(): Observable<any> {
    return this._getFile('report-library-config.json').pipe(
      map((config) => {
        try {
          return JSON.parse(config);
        } catch (e) {
          return [];
        }
      })
    );
  }
  /**
   * Retrieves the default configuration.
   *
   * @returns The default configuration.
   */
  public getDefaultConfig() {
    return this.defaultConfig;
  }
  /**
   * Overrides the default configuration with the provided configuration.
   * Appends new items to the default configuration and overrides certain settings of existing items if they exist in the new configuration.
   *
   * @param config - The configuration to override the default configuration with.
   * @returns The updated configuration after the override.
   */
  public overrideDefaultConfig(
    config: ReportLibraryConfig[]
  ): ReportLibraryConfig[] {
    // append new items to the default config
    // override only certain setting of the existing items if they exist in the new config
    if (!config) {
      return this.defaultConfig;
    }
    console.log('Overriding default config with: ', config);
    const outConfig = [...this.defaultConfig];
    config.forEach((newConfig) => {
      const existingConfigs = outConfig.filter(
        (c) => c.reportCode === newConfig.reportCode && !newConfig.isView
      );
      if (existingConfigs.length > 0) {
        existingConfigs.forEach((existingConfig) => {
          const index = outConfig.indexOf(existingConfig);
          outConfig[index] = {
            title: newConfig.title || existingConfig.title,
            description: newConfig.description || existingConfig.description,
            reportCode: existingConfig.reportCode,
            beautifiedPath: existingConfig.beautifiedPath,
            hasRaw: existingConfig.hasRaw,
            hidden:
              newConfig.hidden !== undefined
                ? newConfig.hidden
                : existingConfig.hidden,
            icon: newConfig.icon || existingConfig.icon,
            category: existingConfig.category,
            isCustom: existingConfig.isView ? false : existingConfig.isCustom,
            isView: existingConfig.isView,
          } as ReportLibraryConfig;
        });
      } else {
        outConfig.push({ ...newConfig, isCustom: true, hasRaw: true });
      }
    });
    return outConfig;
  }
  /**
   * Saves the report library configuration to S3.
   *
   * @param config - The configuration to be saved.
   * @returns An observable that resolves when the configuration is successfully saved.
   */
  public saveReportLibraryConfigToS3(config: any[]): Observable<any> {
    return this._uploadJSON(config, 'report-library-config.json');
  }
  /**
   * Extracts report details from a given report name.
   *
   * @param reportName - The name of the report.
   * @param size - The size of the report.
   * @returns The extracted report details.
   */
  public extractReportDetails(
    reportName: string = '',
    size?: number
  ): ReportDetails {
    if (!reportName) {
      return null;
    }
    const r = reportName.split('-');
    const out: ReportDetails = {
      name: reportName,
      clientCode: r[0],
      reportCode: r[1],
      config: this.extractConfig(r[2]),
      timestamp: this.extractDate(r[3]),
      semVer: this.extractSemVer(r[4]),
      other: r.slice(5).join('-').split('.')[0],
      size: size || 0,
    } as ReportDetails;
    if (
      out.clientCode &&
      out.reportCode &&
      out.config &&
      out.timestamp &&
      out.semVer
    ) {
      // console.log(`Valid report name: ${reportName}`);
      return out;
    } else {
      // console.log(`Invalid report name: ${reportName}`);
      if (reportName.endsWith('.csv')) {
        return {
          name: reportName,
          clientCode: '',
          reportCode: 'CSTM',
          config: {},
          timestamp: '',
          semVer: '',
          other: '',
          size: 0,
        };
      } else {
        return null;
      }
    }
  }
  /**
   * Retrieves the latest report name from the given array of reports.
   *
   * @param reports - The array of reports.
   * @returns The latest report name.
   */
  public getLatestReportName(reports: any): string {
    return reports.reduce((acc, curr) => {
      if (acc.timestamp < curr.timestamp) {
        return curr;
      } else {
        return acc;
      }
    });
  }
  /**
   * Retrieves the available reports based on the provided code.
   * If no code is provided, all available reports will be returned.
   *
   * @param code - The code used to filter the reports.
   * @returns An Observable that emits an array of ReportDetails objects.
   */
  public getAvailableReportsByCode(
    code: string = ''
  ): Observable<ReportDetails[]> {
    var that = this;
    var retPromise: Promise<ReportDetails[]> = new Promise(function (resolve) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${
            env.name
          }-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            // console.log(`Filtering reports for ${code}`);
            resolve(
              response.Contents.map((report) =>
                that.extractReportDetails(report.Key, report.Size)
              )
                .filter((report) => {
                  return report?.reportCode === code;
                })
                .sort((a, b) => {
                  return (
                    new Date(b.timestamp).getTime() -
                    new Date(a.timestamp).getTime()
                  );
                })
            );
          });
      });
    });
    return from(retPromise);
  }

  /**
   * Retrieves the available reports.
   * @returns An Observable of ReportDetails array.
   */
  public getAvailableReports(): Observable<ReportDetails[]> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${
            env.name
          }-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            resolve(
              response.Contents.map((report) =>
                that.extractReportDetails(report.Key, report.Size)
              ) as ReportDetails[]
            );
          });
      });
    });
    return from(retPromise) as Observable<ReportDetails[]>;
  }

  public getAllReportNamesEndingWith(suffix: string): Observable<ReportDetails[]> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${
            env.name
          }-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            resolve(
              response.Contents.map((report) => report.Key.endsWith(suffix))
            );
          });
      });
    });
    return from(retPromise) as Observable<ReportDetails[]>;
  }

  /**
   * Retrieves the last modified date of a report.
   *
   * @param reportName - The name of the report.
   * @returns An Observable that emits the last modified date of the report.
   */
  public getLastModifiedDate(reportName: string): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${
            env.name
          }-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            const report = response.Contents.filter(
              (report) => report.Key === reportName
            );
            // console.log('Report: ', report);
            resolve(report[0]?.LastModified.toString());
          });
      });
    });
    return from(retPromise);
  }

  /**
   * Extracts the region and ID from the given config string.
   *
   * @param config - The config string to extract from.
   * @returns An object containing the extracted region and ID.
   */
  private extractConfig(config: string): { region: string; id: string } {
    try {
      const configParts = config.split('_');
      return {
        region: configParts[0] || 'No region specified',
        id: configParts[1] || 'No associated config ID',
      };
    } catch (e) {
      return {
        region: 'No region specified',
        id: 'No associated config ID',
      };
    }
  }
  /**
   * Extracts a date from a string and returns it as a formatted string.
   *
   * @param date - The date string to extract the date from.
   * @returns The extracted date as a formatted string, or null if an error occurs.
   */
  private extractDate(date: string): string {
    try {
      var month = parseInt(date.substring(0, 2)) - 1;
      var day = parseInt(date.substring(2, 4));
      var year = parseInt('20' + date.substring(4, 6));
      return new Date(year, month, day).toDateString();
    } catch (e) {
      return null;
    }
  }
  /**
   * Extracts the semantic version from a given string.
   *
   * @param semVer - The string containing the semantic version.
   * @returns The extracted semantic version in the format "major.minor.patch", or null if an error occurs.
   */
  private extractSemVer(semVer: string): string {
    try {
      var major = semVer.substring(1, 2);
      var minor = semVer.substring(3, 5);
      var patch = semVer.substring(5, 7);
      return `${major}.${minor}.${patch}`;
    } catch (e) {
      return null;
    }
  }
  /**
   * Retrieves the icon associated with the given code.
   *
   * @param code - The code of the report.
   * @returns The icon associated with the code, or 'report' if no icon is found.
   */
  public getIcon(code: string): string {
    const report = this.defaultConfig.find((c) => c.reportCode === code);
    // console.log('Icon for ', code, ' is ', report?.icon);
    return report ? report.icon : 'report';
  }

  /**
   * Sets the last modified date in the local storage for a specific report and customer.
   * @param reportName - The name of the report.
   * @param customerId - The ID of the customer.
   * @param lastModifiedDate - The last modified date to be stored.
   */
  public setLastModifiedDateInLocalStorage(
    reportName: string,
    customerId: string,
    lastModifiedDate: string
  ): void {
    localStorage.setItem(`${reportName}_${customerId}`, lastModifiedDate);
  }
  /**
   * Retrieves the last modified date from the local storage for a specific report and customer.
   * @param reportName - The name of the report.
   * @param customerId - The ID of the customer.
   * @returns An observable that emits the last modified date as a string.
   */
  public getLastModifiedDateFromLocalStorage(
    reportName: string,
    customerId: string
  ): Observable<string> {
    return of(localStorage.getItem(`${reportName}_${customerId}`));
  }
  /**
   * Retrieves a file from AWS S3 bucket.
   *
   * @param key - The key of the file to retrieve.
   * @returns An Observable that emits the file content as a string, or null if the file does not exist.
   */
  private _getFile(key): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
        };
        const s3 = new S3();
        from(s3.getObject(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            console.log('listObjectsV2', response);
            if (response && response.Body) {
              console.log(response);
              resolve(response.Body.toString());
            } else {
              resolve(null);
            }
          });
      });
    });
    return from(retPromise);
  }
  /**
   * Uploads JSON data to an S3 bucket.
   *
   * @param data - The JSON data to upload.
   * @param filename - The name of the file to be uploaded.
   * @returns A promise that resolves to the response from the S3 service.
   */
  private _uploadJSON(data: File[], filename: string): Observable<any> {
    let fileContents = JSON.stringify(data, null, 2);
    console.log('Processing file: ' + filename);
    const s3PutParams = {
      Body: fileContents,
      Bucket: `${env.aws.customReportBucket}-${this.customerId}`,
      Key: filename,
      ContentType: 'application/json',
    };
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const s3 = new S3();
        return from(s3.putObject(s3PutParams).promise());
      }),
      map((response) => {
        console.log('putResponse', response);
        return response;
      }),
      catchError((error) => {
        console.error('Error uploading file', error);
        return of({ error: error });
      })
    );
  }

  /**
   * Retrieves the title of a report based on the provided report code.
   * @param {string} reportCode - The code of the report.
   * @returns {string | null} - The title of the report, or null if the report is not found.
   */
  public getReportTitle(reportCode: string): string | null {
    const report = this.defaultConfig.find((c) => c.reportCode === reportCode);
    return report ? report.title : null;
  }

  public loadReportLibrary(): Observable<any> {
    return forkJoin([
      this.getReportLibraryConfig(),
      this.getAvailableReports(),
    ]).pipe(
      map(([config, reports]) => {
        const defaultConfig = this.overrideDefaultConfig(config);
        const categories = this.groupReportByCode(reports);
        return this.overrideDefaultConfig(config).map((config) => {
          let cat = this.listCategories.includes(config.category)
            ? config.category
            : 'Custom Reports';
          if (config.isView) {
            cat = 'Custom Views';
          }
          return {
            title: config.title,
            description: config.description,
            icon: config.icon,
            reportCode: config.reportCode,
            beautifiedPath: config.beautifiedPath,
            hasRaw: config.hasRaw,
            isEmpty: config.isView
              ? false
              : config.isCustom
              ? !reports.some(
                  (report) => report?.name === config.reportCode + '.csv'
                )
              : !categories.some((report) => report === config.reportCode),
            hidden: config.hidden,
            cat: cat,
            isCustom: config.isView ? false : config.isCustom,
            isView: config.isView,
            defaultValues:
              defaultConfig.find(
                (defaultConfig) =>
                  defaultConfig.reportCode === config.reportCode
              ) || {},
          };
        });
      })
    );
  }

  public updateReportLibraryEntry(obj: ReportLibraryConfig): Observable<any> {
    return this.getReportLibraryConfig().pipe(
      map((config) => {
        if (!config) {
          config = [];
        }
        const newConfig = config.map((c) => {
          if (c.reportCode === obj.reportCode && !c.isView && !c.isCustom) {
            c.hidden = obj.hidden;
          }
          return c;
        });
        if (
          !newConfig.some(
            (c) => c.reportCode === obj.reportCode && !c.isView && !c.isCustom
          )
        ) {
          console.log('Adding new report to config: ', obj);
          const newEntry = {
            reportCode: obj.reportCode,
            hidden: obj.hidden,
          };
          newConfig.push(newEntry);
        }
        return newConfig;
      }),
      switchMap((newConfig) => {
        return this.saveReportLibraryConfigToS3(newConfig);
      })
    );
  }
  public addCustomReportToLibrary(obj: ReportLibraryConfig): Observable<any> {
    // get report library config and check if the report already exists
    // if it does, return an error
    // if it doesn't, add the report to the library and save the new config
    return this.getReportLibraryConfig().pipe(
      map((config) => {
        obj.hidden = false;
        obj.isCustom = true;
        if (!config) {
          config = [];
          console.log('Config is empty');
        }
        if (
          config.some(
            (c) => c.reportCode === obj.reportCode && !c.isView && c.isCustom
          )
        ) {
          console.log('Report already exists in the library');
          this.notificationService.warn(
            'Report already exists in the library',
            ''
          );
          return config;
        }
        console.log('Adding new report to config: ', config);

        config.push(obj);
        return config;
      }),
      switchMap((config) => {
        return this.saveReportLibraryConfigToS3(config);
      })
    );
  }
  public deleteCustomReportFromLibrary(
    obj: ReportLibraryConfig
  ): Observable<any> {
    return this.getReportLibraryConfig().pipe(
      map((config) => {
        if (!config) {
          config = [];
        }
        const findIndex = config.findIndex(
          (c) => c.reportCode === obj.reportCode && !c.isView && c.isCustom
        );
        if (findIndex > -1) {
          console.log('Deleting report from config: ', config[findIndex]);
          config.splice(findIndex, 1);
        } else {
          console.log('Report not found in the library', obj);
          this.notificationService.warn('Report not found in the library', '');
        }
        return config;
      }),
      switchMap((newConfig) => {
        return this.saveReportLibraryConfigToS3(newConfig);
      })
    );
  }
  public updateCustomReportInLibrary(
    prevObj: ReportLibraryConfig,
    obj: ReportLibraryConfig
  ): Observable<any> {
    return this.getReportLibraryConfig().pipe(
      map((config) => {
        obj.hidden = false;
        obj.isCustom = true;
        const findIndex = config.findIndex(
          (c) => c.reportCode === prevObj.reportCode && !c.isView && c.isCustom
        );
        if (findIndex > -1) {
          console.log('Updating report from config: ', config[findIndex]);
          config[findIndex] = obj;
        } else {
          console.log('Report not found in the library', prevObj);
          this.notificationService.warn('Report not found in the library', '');
        }
        return config;
      }),
      switchMap((config) => {
        return this.saveReportLibraryConfigToS3(config);
      })
    );
  }

  public deleteViewCardFromLibrary(obj: ReportLibraryConfig): Observable<any> {
    return this.getReportLibraryConfig().pipe(
      map((config) => {
        if (!config) {
          config = [];
        }
        const findIndex = config.findIndex(
          (c) =>
            c.reportCode === obj.reportCode && c.isView && obj.title === c.title
        );
        if (findIndex > -1) {
          console.log('Deleting report from config: ', config[findIndex]);
          config.splice(findIndex, 1);
        } else {
          console.log('Report not found in the library', obj);
          this.notificationService.warn('Report not found in the library', '');
        }
        return config;
      }),
      switchMap((newConfig) => {
        return this.saveReportLibraryConfigToS3(newConfig);
      })
    );
  }

  public saveConfig(config: any): Observable<any> {
    // compare the new stuff with the update stuff and do some validation and save the new stuff if eveything is correct;
    return this.saveReportLibraryConfigToS3(config);
  }
  private _listCategories: any = [
    'Dashboard',
    'Cookie Reports',
    'Online Tracking Technology',
    'Other Privacy Risks',
    'Vendor Analytics',
    'Extras',
    'Custom Reports',
    'Custom Views',
  ];
  public get listCategories(): any {
    return this._listCategories;
  }

  private groupReportByCode(reports: any[] = []) {
    const categories = new Set();
    reports.forEach((report) => {
      if (report && report.reportCode) categories.add(report.reportCode);
    });
    return Array.from(categories);
  }
}
