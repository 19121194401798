<div id="menu-panel" class="row" *ngIf="hasTestSuites">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header" style="flex-direction: column;">
                <div
                    style="flex-grow: 1; display: flex; flex-direction: row; gap:10px; justify-content: flex-start; width: 100%;">
                    <ejs-dropdownlist [dataSource]="rulesList" [itemTemplate]="dropDownTemplate" width="350px"
                        [enabled]="rulesList.length > 1" placeholder="Select a rule" (select)="OnRuleSelect($event)"
                        [fields]="{text:'rule.name',value:'rule.uuid'}"></ejs-dropdownlist>
                    <div [hidden]="rulesList.length > 1">
                        <span class="zmdi zmdi-hourglass-outline new-spinner"></span>
                        Loading Rules....
                    </div>

                    <div
                        style="display: flex; flex-direction: row; justify-content: flex-end; gap: 10px; align-items: center; flex-grow:1">
                        <span>Recent</span>
                        <ejs-dropdownlist [dataSource]="takeValueList" width="100px" (change)="updateTakeValue($event)"
                            [value]="100">
                        </ejs-dropdownlist>
                        <span>Validations</span>
                    </div>
                    <button class="btn" (click)="reloadRuleData()" [disabled]="!selectedRuleObj">
                        Reload
                        <span class="zmdi zmdi-refresh"></span>
                    </button>

                </div>
                <div style="border-top: 0.5px solid lightgrey; padding:10px; width: 100%;"
                    *ngIf="selectedData.rule !==null">
                    <span style="padding-right: 10px;  ">
                        {{selectedData.timeFrameString}}</span>
                    <span style="border-left: 0.5px solid lightgrey;padding-left:10px;">Found
                        <span style="font-weight: bold;">{{selectedData?.totalResults}}</span> Validation Results</span>
                </div>

            </div>
        </div>
    </div>
</div>
<div id="info-panel" class="row">
    <div class="col-lg-12">
        <div class="card" *ngIf="selectedRule">
            <div class="card-header">
                <div style="flex-grow: 1;">Validation Rule Details</div>
                <span style="font-size: small; color:grey; font-weight:normal; padding-left: 10px">Selected Date:
                    {{
                    selectedData.lastChecked}}</span>
                <button class="btn" style="background-color: rgb(21, 151, 203);" (click)="onLatestResults()">
                    Show Latest Results
                    <span class="zmdi zmdi-calendar"></span>
                </button>
            </div>
            <div class="card-body">
                <div id="top-info-container">
                    <div class="stat-container">
                        <div class="current-stat"><span class="zmdi zmdi-check-circle big-symbols"
                                [style.color]="'seagreen'"></span> <span class="current-stat-label">PASSED:</span>
                            {{selectedData.currentPassed}}</div>
                        <div class="other-stat">
                            <div>MIN: {{selectedData.minPassed}}</div>
                            <div>MAX: {{selectedData.maxPassed}}</div>
                        </div>
                    </div>
                    <div class="stat-container">
                        <div class="current-stat"><span class="zmdi zmdi-close-circle big-symbols"
                                [style.color]="'salmon'"></span><span class="current-stat-label">FAILED:</span>
                            {{selectedData.currentFailed}}</div>
                        <div class="other-stat">
                            <div>MIN: {{selectedData.minFailed}}</div>
                            <div>MAX: {{selectedData.maxFailed}}</div>
                        </div>
                    </div>
                    <div id="basic-info">
                        <div><span class="basic-info-label">Name: </span>{{selectedData.rule.name}}</div>
                        <div><span class="basic-info-label">UUID: </span>{{selectedData.rule.uuid}} <span
                                (click)="copyToClipboard(selectedData.rule.uuid)"
                                class="zmdi zmdi-copy copy-icon"></span></div>
                        <div><span class="basic-info-label">Test Suite: </span>{{selectedData.testSuite.name}} <span
                                (click)="goToTestSuite(selectedData.testSuite.id)"
                                class="zmdi zmdi-link copy-icon"></span></div>
                        <div><span class="basic-info-label">TS UUID : </span>{{selectedData.testSuite.uuid}} <span
                                (click)="copyToClipboard(selectedData.testSuite.uuid)"
                                class="zmdi zmdi-copy copy-icon"></span></div>
                        <div><span class="basic-info-label">Author: </span>{{selectedRule.author}}</div>
                    </div>
                </div>
                <div id="bottom-info-container">
                    <div *ngIf="selectedData.hasHistory else nohistory">
                        <ejs-chart [primaryXAxis]='primaryXAxis' [primaryYAxis]="primaryYAxis"
                            [legendSettings]='legendSettings' [palettes]="palette" [chartArea]="chartArea" width="100%"
                            height="300px" [tooltip]="tooltip" (tooltipRender)="onTooltipRender($event)"
                            (pointClick)="onHistorySelect($event)">
                            <e-series-collection>
                                <e-series [dataSource]="selectedData.history" type="StackingColumn" xName="date"
                                    yName="failed" name="Failed"></e-series>
                                <e-series [dataSource]="selectedData.history" type="StackingColumn" xName="date"
                                    yName="passed" name="Passed"></e-series>
                            </e-series-collection>
                        </ejs-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="id-panel" class="row" *ngIf="selectedRule && selectedData.type?.name === 'ID'">
    <div class="col-lg-12">
        <div class="card">

            <div class="card-header">
                <div style="flex-grow: 1;">ID List</div>
                <span style="font-size: small; color:grey; font-weight:normal; padding-left: 10px">Selected Date: {{
                    selectedData.lastChecked}}</span>

            </div>
            <div class="card-body">
                <div class="id-list-container" *ngIf="selectedData.hasHistory else nohistory">
                    <div class="id-list">
                        <div class="id-list-header" style="background-color: seagreen;">
                            <span class="zmdi zmdi-check-circle"></span> Allowed IDs
                            <ejs-tooltip content='Copy ID List' position="TopCenter" [showTipPointer]="false">
                                <span class="zmdi zmdi-copy copy-icon" style="font-size: x-large;"
                                    *ngIf="selectedData.type.idList.allowedIds?.length > 0"
                                    (click)="copyToClipboard(idListJoin(selectedData.type.idList.allowedIds))">
                                </span>
                            </ejs-tooltip>
                        </div>
                        <div class="id-list-value-container"
                            *ngIf="selectedData.type.idList.allowedIds?.length > 0 else noIds">
                            <ng-container *ngFor="let id of selectedData.type.idList.allowedIds">
                                <ejs-tooltip content='Copy ID' position="TopCenter" [showTipPointer]="false">
                                    <div class="id-list-value" (click)="copyToClipboard(id)">{{id}}
                                    </div>
                                </ejs-tooltip>
                            </ng-container>
                        </div>
                    </div>
                    <div class="id-list">
                        <div class="id-list-header" style="background-color: salmon;"><span
                                class="zmdi zmdi-alert-circle"></span> Unknown IDs
                            <ejs-tooltip content='Copy ID List' position="TopCenter" [showTipPointer]="false">
                                <span class="zmdi zmdi-copy copy-icon" style="font-size: x-large;"
                                    *ngIf="selectedData.type.idList.unknownIds?.length > 0"
                                    (click)="copyToClipboard(idListJoin(selectedData.type.idList.unknownIds))">
                                </span>
                            </ejs-tooltip>
                        </div>

                        <div class="id-list-value-container"
                            *ngIf="selectedData.type.idList.unknownIds?.length > 0 else noIds">
                            <ng-container *ngFor="let id of selectedData.type.idList.unknownIds">
                                <ejs-tooltip content='Copy ID' position="TopCenter" [showTipPointer]="false">
                                    <div class="id-list-value" (click)="copyToClipboard(id)">{{id}}
                                    </div>
                                </ejs-tooltip>
                            </ng-container>
                        </div>
                    </div>
                    <div class="id-list">
                        <div class="id-list-header" style="background-color: orange;"><span
                                class="zmdi zmdi-help"></span> Pending IDs <ejs-tooltip content='Copy ID List'
                                position="TopCenter" [showTipPointer]="false">
                                <span class="zmdi zmdi-copy copy-icon" style="font-size: x-large;"
                                    *ngIf="selectedData.type.idList.pendingIds?.length > 0"
                                    (click)="copyToClipboard(idListJoin(selectedData.type.idList.pendingIds))">
                                </span>
                            </ejs-tooltip></div>
                        <div class="id-list-value-container"
                            *ngIf="selectedData.type.idList.pendingIds?.length > 0 else noIds">
                            <ng-container *ngFor="let id of selectedData.type.idList.pendingIds">
                                <ejs-tooltip content='Copy ID' position="TopCenter" [showTipPointer]="false">
                                    <div class="id-list-value" (click)="copyToClipboard(id)">{{id}}
                                    </div>
                                </ejs-tooltip>
                            </ng-container>


                        </div>
                    </div>
                </div>
                <ng-template #noIds>
                    <div class="no-ids">No IDs Found</div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<div id="content-panel" class="row" *ngIf="selectedData.hasHistory">
    <div class="col-lg-12">
        <div class="card" *ngIf="selectedRule">

            <div class="card-header">
                <div style="flex-grow: 1;">Validation Messages</div>
                
                <span style="font-size: small; color:grey; font-weight:normal; padding-left: 10px">Selected Date: {{
                    selectedData.lastChecked}}</span>
            </div>
            <div class="card-body">
                <ejs-grid id="results-grid"
                    [dataSource]="selectedData.showPassed ? selectedData.passedContent : selectedData.failedContent "
                    [allowResizing]="true" [allowPaging]="true" height="600px">
                    <e-columns>
                        <e-column field="chrome_linkurl" width="350px">
                            <ng-template #headerTemplate>
                                <div id="grid-header">
                                    <div>
                                        {{ selectedData.showPassed ? 'Passed' : 'Failed'}} Validation Results
                                    </div>
                                    <!-- <ejs-switch [checked]="rule.showFailed"
                                            (change)="rule.showFailed = !rule.showFailed"></ejs-switch> -->
                                </div>
                            </ng-template>
                            <ng-template #template let-data>
                                <div>
                                    <div id="result-url"
                                        [style.background-color]="data.validation_message.indexOf('#ERROR') > -1 ?'salmon' : 'lightgrey'">
                                        {{data.chrome_linkurl}}
                                        <ejs-tooltip content="Copy URL" position="TopCenter" [showTipPointer]="false">
                                            <span (click)="copyToClipboard(data.chrome_linkurl)"
                                                class="zmdi zmdi-copy copy-icon"></span>
                                        </ejs-tooltip>
                                        <ejs-tooltip content="Copy Message" position="TopCenter"
                                            [showTipPointer]="false">
                                            <span (click)="copyToClipboard(data.validation_message)"
                                                class="zmdi zmdi-format-subject copy-icon"></span>
                                        </ejs-tooltip>
                                    </div>
                                    <div id="result-message" *ngIf="data.validation_message !== '' else noMessage"
                                        [class.error-validation]="data.validation_message.indexOf('#ERROR') > -1">
                                        {{selectedData.type?.name === 'ID' ? IDMessageFormatter(data.validation_message)
                                        || data.validation_message : data.validation_message }}
                                    </div>
                                    <ng-template #noMessage>
                                        <div id="result-message" style="color: lightgrey;"> No validation message
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</div>

<ng-template #newSpinner>
    <span>
        <span class="zmdi zmdi-hourglass-outline new-spinner" style="padding: 5px;"></span>
    </span>
</ng-template>
<ng-template #nohistory>
    <div class="no-history">NOT ENOUGH INFORMATION AVAILABLE</div>
</ng-template>

<div class="row" *ngIf="!hasTestSuites">
    <div class="col-sm-1"></div>
    <div class="card col-lg-10" style="height: 200px;">
        <div class="no-history">No Test Suites Found</div>
    </div>
</div>
<div class="row" *ngIf="!hasValidationRules">
    <div class="col-sm-1"></div>
    <div class="card col-lg-10" style="height: 200px;">
        <div class="no-history">No Validation Rules Found</div>
    </div>
</div>