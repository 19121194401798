import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgStyle } from '@angular/common';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-fb-details-cardlist',
  templateUrl: './fb-details-cardlist.component.html',
  styleUrls: ['./fb-details-cardlist.component.css']
})
export class FbDetailsCardlistComponent implements OnInit {
  @Input('title') title: string;
  @Input('height') height: any;
  @Input('columns') columns: any;
  @Input('dataSource') dataSource: any;

  @ViewChild('grid', { static: true }) grid: GridComponent;

  gridHeight: number;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.gridHeight = this.height - 54;
    this.grid.refresh();
  }

}
