import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  private static PROPERTIES = { 
    dataTheft: "Data Theft", 
    resourceTheft: "Resource Theft", 
    dynamicContent: "Dynamic Content", 
    infection: "Infection", 
    eval: "Eval"
  };

  constructor() { }

  processAnalysis(data):string{
    
    var propCount = {};
    for(var row of data){
      if ( row.properties){
        for (var prop of Object.keys(row.properties)) {
          
          if ( row.properties[prop] == true ){
            propCount[prop] = propCount[prop] == null ? 1 : propCount[prop] + 1;
          }	
        }	
      }	
    }
    
    var returnString = Object.keys(propCount).map((p)=>{return `${AnalysisService.PROPERTIES[p]} (${propCount[p]})`;  }).join(", ");
    return returnString;
  }
}
