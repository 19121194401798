<div class="row">
    <div class="col-lg-12 dataDiv">
        <div class="card card-table card-border">
            <div class="card-body">        
                <ejs-grid #grid 
                height="700px"
                    [dataSource]='data' 
                    allowPaging='true' 
                    allowSorting="true" 
                    [allowGrouping]="true" 
                    [groupSettings]="groupOptions" 
                    [pageSettings]='pageSettings' 
                    [sortSettings]='sortSettings'
                    [filterSettings]='filterSettings'
                    [allowFiltering]='true'
                    (dataBound)="onDataBound($event)"
                >
                    <e-columns>            
                        <e-column field='vendorName' headerText="Vendor">
                            <ng-template #template let-data>
                                <img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.vendorWebsite ? data.vendorWebsite.toLowerCase() : 'unknown' }}.ico"/><a [routerLink] = "['/vendors',data.vendorName]">{{data.vendorName}}</a><span class="icon zmdi zmdi-sign-in" style="margin-left: 5px; font-size: 15px !important"></span>
                            </ng-template>
                        </e-column>
                        <e-column field='domain' headerText="Domain"></e-column>
                        <e-column field='allKeys' headerText='Key'></e-column>
                        <e-column field='allValues' headerText='Value'></e-column>            
                    </e-columns>
                </ejs-grid>
            </div>    
        </div>
    </div>
</div>