import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { Page } from "../models/Page";
import { PageService } from "../services/page/page.service";
import { tap } from "rxjs/operators";

@Injectable()
export class PageHistoryResolver implements Resolve<Page> {
    
    constructor(private pageService:PageService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<Page> {
        console.log("Page History Resolver", route);
        const pageId = route.params["pageId"];
        const pageHistoryId = route.params["pageHistoryId"];

        if ( this.pageService.selectedPageHistory && this.pageService.selectedPageHistory.value && this.pageService.selectedPageHistory.value.id == pageHistoryId) {            
            return;
        } else {
            this.spinnerService.toggleRoot(true);
            return this.pageService.getPageHistory(pageId, 0, 1, 'lastChecked', "desc", pageHistoryId, 1).pipe(tap((data)=>{                
                this.pageService.selectedPageHistory.next(data.data[0]);
                this.spinnerService.toggleRoot(false);
            }));
        }        
    }
}