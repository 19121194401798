import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { VendorsService } from '../../services/vendors/vendors.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../services/sitemap/sitemap.service';
import { Vendor } from '../../models/Vendor';
import { environment as env } from "../../../environments/environment";
import { DataExportService } from '../../services/data-export/data-export.service';
import { NotificationsService } from 'angular2-notifications';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { GridComponent, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { TimezoneService } from '../../services/timezone.service';
declare var $:any;

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit, AfterViewInit, OnDestroy {
  private vendors:any[];  
  vendorTitle:string;
  tooltipContent:string;
  exportId:string;
  pollingTimer:any;
  pollingTimerCounter:number;
  notificationOptions:any;
  exportButtonDisabled:boolean;
  
  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public cdnUrl:string;
  public dateFormat:any;
  @ViewChild('grid') public grid: GridComponent;

  constructor(private el: ElementRef, 
              private vendorService:VendorsService, 
              private router: Router, 
              private route:ActivatedRoute, 
              private sitemapService:SitemapService,
              private dataExportService:DataExportService,
              private notificationService: NotificationsService,
              private spinnerService:SpinnerService,
              public tzService:TimezoneService
  ) {     
    this.sitemapService.update(this.route);
    this.spinnerService.toggle(true);
    this.vendorTitle = "Vendors";
    this.tooltipContent = "Vendors that were identified on your site during a Vault JS scan of your site(s)";
    this.notificationOptions = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.exportButtonDisabled = false;
    this.cdnUrl = env.cdn.url;
    this.dateFormat = {type: 'dateTime', format: 'M/d/y, h:m:s a z'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'name', direction: 'Ascending'}  
      ]
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit(){
    
    $('[data-toggle="popover"]').popover();
    var isLib = this.route.snapshot.url.join('').indexOf("library") > -1;
    this.vendorTitle = isLib ? "Vendor Library" : this.vendorTitle;
    this.tooltipContent = isLib ? "Vendor Library is the complete list of the vendors that Vault JS monitors on an ongoing basis, scanning 1000's of vendor code files and top websites around the world. These vendors are not all on your website(s)." : this.tooltipContent;
    this.vendorService.getVendors("", isLib).subscribe( (vendors:Vendor[]) => {
      this.spinnerService.toggle(false);
      console.log(vendors);
      this.vendors = vendors;
      // this.vendors = vendors.map((vendor)=>{
      //   return {...vendor, mostRecentUpdate: this.tzService.format(vendor.mostRecentUpdate)}
      // })
      console.log(this.vendors);
      this.grid.dataSource = vendors;

    });
  }
  
  exporData() {
    this.exportButtonDisabled = true;
    this.dataExportService.startDataExport(DataExportService.VENDORS).subscribe((result)=> {
      this.notificationService.success('Success!', 'Your export was requested sucessfully. The file will download automatically once it has been generated. Please remain on this page until then.', this.notificationOptions);
      console.log(result);
      this.exportId = result.exportId;
      this.pollingTimerCounter = 0;
      this.startPolling();
    },
    (error)=>{
      this.exportButtonDisabled = false;
      console.log(error);
      this.notificationService.success('Oops!', 'Your export request was not sucessful. Please contact support.', this.notificationOptions);
    });
  }

  startPolling() {
    this.pollingTimer = setTimeout(()=>{
      console.log(new Date() + " Checking for file...");
      
      const filePath = `${localStorage.cid}/${this.exportId}/${DataExportService.VENDORS}.${DataExportService.CSV_EXTENSION}`;      
      this.dataExportService.getDataExportFile(filePath).subscribe((data)=>{
        clearTimeout(this.pollingTimer);

        this.exportButtonDisabled = false;
        
        let fileName = "Vault JS Vendors " + new Date().toLocaleString() + ".csv";
        this.dataExportService.saveFile(fileName, data.toString());

        clearTimeout(this.pollingTimer);
      },
      (error)=>{
        console.log("S3 Error")
        console.log(error);
        if ( this.pollingTimerCounter < 10 ){
          this.startPolling();
          this.pollingTimerCounter++;
        } else {
          clearTimeout(this.pollingTimer);
          this.notificationService.success('Hmmm...', 'Your export seems to be taking longer than expected. Please contact support.', this.notificationOptions);
        }
      });
    }, 1000);
  }

  ngOnDestroy(){
    if ( this.pollingTimer ){
      clearTimeout(this.pollingTimer);
    }
  }
}