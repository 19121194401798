<div class="be-splash-screen">
   <div class="be-wrapper be-login">
    <div class="be-content">
      <div class="main-content container-fluid">
        <div class="splash-container">
          <div class="card be-loading" [class.be-loading-active]="submitted">
            <div class="card-header">
              <img *ngIf="ssoId" src="assets/img/{{ssoId}}-logo.png" class="customer-logo" alt="Customer Logo"/>
              <img src="assets/img/logo-xx.png" alt="logo" width="52" height="52" class="logo-img">
              <br/>Vault JS
              <span class="splash-description">{{ ssoId ? ' Please enter your SSO user ID: ': 'Please enter your user information:' }}</span>
            </div>
            <div *ngIf="errors.length > 0" role="alert" class="alert alert-danger alert-dismissible">
              <button type="button" data-dismiss="alert" aria-label="Close" class="close"><span aria-hidden="true" class="mdi mdi-close"></span></button>
              <div class="icon"> <span class="zmdi zmdi-close-circle-o"></span></div>
              <div class="message">{{errors}}</div>
            </div>
            <div class="card-body">
              <form (ngSubmit)="login()">
                <div class="form-group">
                  <input  name="email" [(ngModel)]="user.email" id="input-email" type="text" placeholder="{{ ssoId ? 'User ID' : 'Username' }}" autocomplete="off" class="form-control">
                </div>
                <div class="form-group" *ngIf="!ssoId">
                  <input name="password" [(ngModel)]="user.password" type="password" id="input-password" placeholder="Password" class="form-control">
                </div>
                <div class="form-group row login-tools" *ngIf="!ssoId">
                  <div class="col-6 login-remember">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input"><span class="custom-control-label">Remember Me</span>
                    </label>
                  </div>
                  <div class="col-6 login-forgot-password"><a href="/request-password">Forgot Password?</a></div>
                </div>
                <div class="form-group login-submit">
                  <button class="btn btn-primary btn-xl" [disabled]="submitted" >Sign in</button>
                </div>
              </form>
            </div>
            <div class="be-spinner">
              <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
              </svg>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>