import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  public stopHandler: any;
  public value: number = 0;
  public show: boolean = false;
  public unit: string = '';
  public maxValue: number = 1;
  public percentage: number = 0;
  public indeterminateEvent: EventEmitter<boolean> = new EventEmitter();
  public resetEvent: EventEmitter<void> = new EventEmitter();
  public completeEvent: EventEmitter<void> = new EventEmitter();
  constructor() { }

  toggle(show: boolean = true) {
    this.show = show;
  }
  setMaxValue(max: number) {
    this.maxValue = max;
  }
  setValue(value: number, max: number, unit: string = '') {
    this.value = value;
    this.maxValue = max;
    this.unit = unit;
  }
  resetValue() {
    this.value = 0;
  }
  setStopHandler(handler: any) {
    this.stopHandler = handler;
  }
  setIndeterminate(indeterminate: boolean) {
    this.indeterminateEvent.emit(indeterminate);
    console.log('indeterminate event emitted');
  }

  resetComponent() {
    this.resetEvent.emit();
  }
  complete() {
    this.completeEvent.emit();
  }


}
