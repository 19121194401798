<div class="row top-segment">
  <div class="col-lg-2">
    <div class="card">
      <div class="card-header">Open Incidents</div>
      <div class="card-body open-incidents-container">
        <div class="stat-card">
          <div>Red</div>
          <div style="color: red;">{{openIncidentsAggregate.redCount}}</div>
        </div>
        <div class="stat-card">
          <div>Yellow</div>
          <div style="color: orange;">{{openIncidentsAggregate.yellowCount}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-5">
    <div class="card">
      <div class="card-header">Open Incident History</div>
      <div class="card-body" style="padding: 5px;">
        <ejs-chart id='chart' #chart [dataSource]="chartList" height="200px" [primaryXAxis]="primaryXAxis"
          [palettes]="paletteColors">
          <e-series-collection>
            <e-series [dataSource]="chartList" xName="date" yName="redCount" type="Line" name="Red Incidents"
              [marker]="marker" width="2"> </e-series>
            <e-series [dataSource]="chartList" xName="date" yName="yellowCount" type="Line" name="Yellow Incidents"
              [marker]="marker" width="2"></e-series>
          </e-series-collection>
        </ejs-chart>
      </div>
    </div>
  </div>
  <div class="col-lg-5">
    <div class="card">
      <div class="card-header">Open Incident Age</div>
      <div class="card-body">
        <multi-pie-chart [datasource]="pieChartList"></multi-pie-chart>
        <!-- <ejs-accumulationchart #pie class="acc" height="200px" selectionMode="Point" background="transparent">
          <e-accumulation-series-collection>
            <e-accumulation-series [name]="'toolTipLabel'" [dataSource]="pieChartList" xName="type" yName="count"
              radius="r" enableAnimation="true" class="series">
            </e-accumulation-series>
          </e-accumulation-series-collection>
        </ejs-accumulationchart> -->
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">Incident History

      </div>
      <div class="card-body">
        <ejs-grid [allowPaging]="true" [dataSource]="incidentList" [allowResizing]="true" [allowSorting]="true"
          [sortSettings]="sortOptions" [allowFiltering]="true" [filterSettings]="filterSettings">
          <e-columns>
            <e-column field="statusFilter" width="30px" [allowResizing]="false" headerText="" [allowSorting]="false">
              <ng-template #template let-data>
                <span style='font-size: large; font-weight: bold;' class="zmdi"
                  [style.color]="data.severity === 2 ? 'red':'orange'" [class.zmdi-circle-o]="data.status == 1"
                  [class.zmdi-circle]="data.status == 2">
                </span>
              </ng-template>
            </e-column>
            <e-column field="reviewRequested" headerText="" [visible]="false"></e-column>
            <e-column field="severity" headerText="" [visible]="false"></e-column>
            <e-column field="status" headerText="" [visible]="false"></e-column>

            <e-column field="name" headerText=" Name" textAlign="Left">
              <ng-template #template let-data>
                <div style="position: relative;;">
                  <div class="row" style="margin: 0px 4px; position: relative;">
                    <div class="col"
                      style="font-size: medium; font-weight: bold; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                      <a [routerLink]="['/incidents', 'details', data.id]" style="color:black">
                        {{data.name}}
                      </a>
                      <span style='font-size: large; font-weight: bold; margin-left: 5px'
                        class="zmdi zmdi-collection-{{data.mergedCount <= 9 ? 'item-' + data.mergedCount : 'plus'}}"></span>
                      <span style="font-weight: bold; color:grey; " *ngIf="data.isMerged === 1"> [MERGED]</span>
                      <a [routerLink]="['/incidents', 'details', data.id]"><span class="zmdi zmdi-arrow-right"
                          style="margin-left: 10px;"></span></a>
                    </div>
                  </div>
                  <div class="row" style="padding:0px; margin: 0px 4px; color:grey; font-style: italic; overflow:clip; text-overflow: ellipsis;">
                    <div class="col" style="font-size: small; padding:2px; margin:0;">
                      {{data.description}}
                    </div>
                  </div>
                  <div *ngIf="data.reviewRequested" class="review-tag">
                    <span class="zmdi zmdi-alert-triangle"></span>
                  </div>
                </div>
              </ng-template>
            </e-column>

            <!-- <e-column field="type" headerText="Incident Type" textAlign="Left"></e-column> -->

            <e-column field="analysts" headerText="Analysts">
              <ng-template #template let-data>
                <div class="analyst-list">
                  <div *ngFor="let analyst of data.analysts">
                    {{analyst}}
                  </div>
                </div>
              </ng-template>
            </e-column>
            <e-column field="formattedTimestamp" headerText="Creation Date" textAlign="Left" width="200"></e-column>
            <e-column field="formattedLastUpdate" headerText="Last Update" textAlign="Left" width="200"></e-column>
            <!-- <e-column field="lastEditedOn" headerText="Last Edited Date" textAlign="Left" width="100"></e-column> -->
          </e-columns>
        </ejs-grid>
        <!-- <div class="toolbar" style="margin-right:5px" *ngIf="authService.isVaultUser()"><button (click)="showNewIncidentModal()"><span class="zmdi zmdi-file-add"></span> New Incident</button></div>
      </div> -->

      </div>
    </div>
  </div>

  <!-- <div class="new-incident-modal modal fade" id="new-incident-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <p>New Incident</p>
          <form [formGroup]="newIncidentForm">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="name">
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Description</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <textarea cols="100" rows="4" class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>
          </form>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="createIncident();">Save</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div> -->