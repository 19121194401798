import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

interface DropdownData {
  id: string;
  name: string;
}
@Component({
  selector: 'simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.css'],
})
export class SimpleDropdownComponent implements OnInit {
  @Output('sendEvent') selectionEvent = new EventEmitter<string>();
  @Input('backtrackValue') backtrackValue: string;
  @Input('datasource') inputData: DropdownData[];

  public data: DropdownData[];
  public placeholder: string = 'Select a Site';
  public fields: Object = { text: 'name', value: 'id' };
  public selectedId: string = '0';

  constructor() {}
  ngOnInit(): void {
    this.data = this.inputData;
  }
  ngOnChanges(): void {
    this.data = this.inputData;
  }
  onCreated(event: any) {
    this.placeholder = this.data[0].name;
    this.selectionEvent.emit(this.data[0].id);
  }

  onChange(event: any) {
    this.selectedId = event.itemData.id.toString();
    this.selectionEvent.emit(this.selectedId);
  }
}
