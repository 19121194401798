<div class="row">
    <div class="col-sm-12">
      <div class="card card-table card-border">
        <div class="card-header">
          <span class="title">Scheduled Reports</span>
          <span class="card-subtitle">Create and manage your scheduled reports below. Double click to edit an existing name or value.</span>
        </div>
        <div class="card-body">
          <ejs-grid #grid
          [dataSource]='reports'
          height="500"
          locale='en-US' 
          [allowPaging]="true"
          [editSettings]="editSettings"
          [toolbar]="toolbar"
          (beforeBatchSave)="beforeBatchSave($event)"
          >
          <e-columns>                        
            <e-column field='name' headerText='Name'></e-column>
            <e-column field='utcOffset' headerText='UTC Offset'></e-column>
            <e-column field='templateName' headerText='Template Name'></e-column>
          </e-columns>
        </ejs-grid>
        </div>      
      </div>
    </div>
</div>
