import { Component, OnInit, Input, OnChanges } from '@angular/core';

declare var $: any;

@Component({
  selector: 'group-summary-panel',
  templateUrl: './group-summary-panel.component.html',
  styleUrls: ['./group-summary-panel.component.css'],
})
export class GroupSummaryPanelComponent implements OnInit, OnChanges {
  @Input('data') inputData: any[];
  public data: any = [];
  public riskList: string[] = [];
  public sortSettings: { columns: { field: string; direction: string }[] };
  public pageOptions: {
    pageSize: number;
    pageCount: number;
    pageSizes: number[];
  };
  public filterSettings: Object;
  public editSettings: { allowEditing: boolean; mode: string };

  refreshGraphs(event) {
    setTimeout(() => {
      $('.sparklinebasic').sparkline('html', {
        highlightSpotColor: 'black',
        highlightLineColor: 'black',
        fillColor: false,
        spotColor: false,
        minSpotColor: false,
        maxSpotColor: false,
        lineWidth: 1,
        enableTagOptions: true,
      });
      $('.sparkpie').sparkline('html', { type: 'pie', height: '2.0em' });
    }, 0);
  }
  constructor() {
    this.sortSettings = {
      columns: [],
    };

    this.filterSettings = {
      type: 'Excel',
    };
    this.pageOptions = {
      pageSize: 25,
      pageCount: 5,
      pageSizes: [25, 50, 75, 100, 200],
    };
    this.editSettings = { allowEditing: false, mode: 'Normal' };
  }

  createSparkLineData(value: number, max: number) {
    // createa an array with values growing from 0 to
  }
  public colorCutoff = (value: any,cutoffA: any,cutoffB:any) => {
    if (value < cutoffA) {
      return 'green';
    } else if (value < cutoffB) {
      return 'orange';
    } else {
      return 'red';
    }
  };
  updateData() {
    const riskSet: Set<string> = new Set();
    this.data = this.inputData.map((site) => {
      const row: any = {};
      // find index with Uppercase followed by a lowercase in the string and replace with space for site name. ignore first letter
      row['site'] = site.siteName.replace(/(?<!^)(?=[A-Z][a-z])/g, ' ');
      row['keyPageViolations'] = site.keyPageViolations;
      row['scanRecency'] = site.scanRecency;
      site.violationsByRisk.forEach((risk: any) => {
        riskSet.add(risk.type);
        row[risk.type] = risk;
        row[risk.type].color = this.colorCutoff(risk.percentPages, 10, 50);
        row[risk.type].percent = risk.percentPages
          ?.toFixed(2)
          .replace(/\.?0+$/, '');
      });
      return row;
    });
    this.riskList = Array.from(riskSet);
  }
  ngOnInit(): void {
    this.updateData();
  }
  ngOnChanges() {
    this.updateData();
  }
}
