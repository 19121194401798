<div class="floating-panel">
    <ejs-dropdownlist [dataSource]="reportList" [fields]="reportListFields"
        [value]="this.selectedReport?.name"
        (select)="onReportSelect($event)" width="300px"
        >
        <ng-template #itemTemplate let-data>
            <!--set the value to itemTemplate property-->
            <div style="display: flex; flex-direction: column; gap:2px;padding:10px; width: 100%;">
                <span>{{data?.other}}</span>
                <span style="font-size: small">{{data?.timestamp}} | {{data?.config?.region | region}}</span>

            </div>
        </ng-template>
        <ng-template #valueTemplate let-data>
            <!--set the value to valueTemplate property-->
            <div style="display: flex; flex-direction: column; gap:5px;padding:10px; width: 100%">
                <div>{{data?.other}}</div>
                <div style="font-size: small;color:grey">{{data?.timestamp}} | {{data?.config?.region | region}}</div>

            </div>

        </ng-template>
    </ejs-dropdownlist>
</div>