<div class="card" style="height: 195px; padding: 10px;">
    <div class="card-header" style="padding: 10px; margin: 0px;"> Top Vendors by Violation Count</div>
    <div class="card-body"
        style="display: flex; flex-direction: row; flex-wrap: wrap; margin: auto; justify-content: center; padding: 5px;">
        <div *ngFor="let vendor of data" popHack="popoverTarget" data-toggle="popover" data-trigger="hover" data-placement="bottom" data-html="true" data-container="body" [attr.data-content]="vendor.vendorName"
            style="width: fit-content; margin-right: 10px; border: 0.5px solid lightgrey; padding:10px; border-radius: 0.2rem;">
            <!-- <div style="display: inline-block; width: fit-content; padding: 5px">{{vendor.name}}</div> -->
            <img style="width: 35px; height:35px; margin: auto; display: block;"
                src="https://{{cdnUrl}}/vendors/icon/{{ vendor.vendorDomain ? vendor.vendorDomain.toLowerCase() : 'unknown' }}.ico"
                alt="" />
            <div style="
                display: block; width: fit-content; padding: 5px; margin-left: 2px; margin-top: 10px; font-size: medium;
                 ">
                {{vendor.violationCount}}</div>

        </div>
    </div>
</div>