import { Component, OnInit, OnDestroy } from '@angular/core';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { environment as env } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Papa } from 'ngx-papaparse';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';

interface TrackerSummary {
  pageDomain: string;
  calculatedVendorName: string;
  domainTotalVendors: number;
  vendorTotalTrackers: number;
  summaryTotalTrackers: number;
  summaryTotalCodeTrackers: number;
  summaryTotalDataTrackers: number;
  summaryTotalCookieTrackers: number;
  vendorTrackerCategories: string;
}

@Component({
  selector: 'app-tracker-summary-dashboard',
  templateUrl: './tracker-summary-dashboard.component.html',
  styleUrls: ['./tracker-summary-dashboard.component.css'],
})
export class TrackerSummaryDashboardComponent implements OnInit, OnDestroy {
  public cdnUrl: string;
  private cid: number;
  public isLoaded: boolean = true;
  private currentFile: string = '';
  constructor(
    private vendorService: VendorsService,
    private router: Router,
    private dataExportService: DataExportService,
    private authService: AuthService,
    private papa: Papa,
    private spinnerService: SpinnerService,
    private dashboardFileSelectorService: DashboardFileSelectorService
  ) {
    this.dashboardFileSelectorService.toggle(true);
    this.dashboardFileSelectorService.setReportCode('TSD');
    this.cid = parseInt(this.authService.customerId());
  }
  public trackerColumns = [
    {
      field: 'totalCodeTrackers',
      headerText: 'Code',
      textAlign: 'center',
      allowResizing: false,
    },
    {
      field: 'totalDataTrackers',
      headerText: 'Data',
      textAlign: 'center',
      allowResizing: false,
    },
    {
      field: 'totalCookieTrackers',
      headerText: 'Cookie',
      textAlign: 'center',
      allowResizing: false,
    },
  ];
  ngOnInit(): void {
    this.cdnUrl = env.cdn.url;
    this.spinnerService.toggle(true);
    this.dashboardFileSelectorService.setSelectionHandler(this.onReportSelect.bind(this));
    this.currentFile = this.dashboardFileSelectorService.defaultFile || null;
    this.loadData(this.currentFile);
  }
  ngOnDestroy(): void {
    this.dashboardFileSelectorService.toggle(false);
  }

  onReportSelect(filename: any) {
    console.log('New File Selected: ', filename);
    this.isLoaded = false;
    this.spinnerService.toggle(true);
    this.currentFile = filename;
    this.loadData(filename);
  }

  public uniqueVendors: number = 0;
  public uniqueDomains: number = 0;
  public uniqueTrackers: number = 0;
  public uniqueCodeTrackers: number = 0;
  public uniqueDataTrackers: number = 0;
  public uniqueCookieTrackers: number = 0;

  public data: TrackerSummary[] = [];
  public domainGroupedData: any = [];
  private vendorList: any = [];
  private async loadData(filename?: string) {
    this.vendorList = await new Promise((resolve, reject) => {
      this.vendorService.getAllVendors().subscribe(
        (vendors: any) => {
          resolve(vendors);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
    this.data = await new Promise((resolve, reject) => {
      this.dataExportService.getTrackerSummary(this.cid,filename).subscribe(
        (data: any) => {
          this.papa.parse(data.toString(), {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
              console.log(result.meta.fields);
              resolve(result.data as TrackerSummary[]);
            },
          });
        },
        (error: any) => {
          reject(error);
        }
      );
    });
    this.dataGrouping(this.data);
  }
  private dataGrouping(data: TrackerSummary[]) {
    const topLevelData = data[0];
    this.uniqueCodeTrackers = topLevelData.summaryTotalCodeTrackers;
    this.uniqueDataTrackers = topLevelData.summaryTotalDataTrackers;
    this.uniqueCookieTrackers = topLevelData.summaryTotalCookieTrackers;
    this.uniqueTrackers = topLevelData.summaryTotalTrackers;

    const allDomains = new Set();
    const allVendors = new Set();
    data.forEach((row) => {
      allDomains.add(row.pageDomain);
      allVendors.add(row.calculatedVendorName);
    });
    this.uniqueVendors = allVendors.size;
    this.uniqueDomains = allDomains.size;
    this.domainGroupedData = Array.from(allDomains).map((domain) => {
      const domainData = data.filter((row) => row.pageDomain === domain);
      const totalVendors =
        domainData.find((row) => row.calculatedVendorName === 'ALL')
          ?.domainTotalVendors || -1;
      const children = domainData
        .filter((row) => row.calculatedVendorName !== 'ALL')
        .map((row) => {
          return {
            name: row.calculatedVendorName,
            website:
              this.vendorList.find(
                (vendor) => vendor.name === row.calculatedVendorName
              )?.website || '',
            totalTrackers: row.vendorTotalTrackers,
            categories: row.vendorTrackerCategories ? row.vendorTrackerCategories.split(', ') : [],
          };
        })
        .sort((a, b) => b.totalTrackers - a.totalTrackers);
      const pieColors = this.generateMultiGradientColors(
        10,
        this.baseColors
      );
      const pieData = children.map((child, index) => {
        return {
          label: child.name,
          value: child.totalTrackers || 1,
          color: pieColors[index] || '#000',
          children: [],
        };
      });
      console.log(pieData);
      const out = {
        name: domain,
        vendorCount: totalVendors,
        vendorDetails: children,
        pieData: pieData,
      };
      return out;
    });
    this.isLoaded = true;
    this.spinnerService.toggle(false);
  }
  goToVendorDetails(vendorName: string) {
    this.router.navigate(['/reports/tracker-vendor-dashboard'], {
      queryParams: { v: vendorName },
    });
  }
  goToDetailCodeReport(domain, vendor) {
    this.router.navigate(['/reports/raw-report-viewer/TS'], {
      queryParams: { pageHostname: domain, calculatedVendorName: vendor, "RRV": this.currentFile.replace('TSD','TS') },
    });
  }
  goToDetailCookieReport(domain, vendor) {
    this.router.navigate(['/reports/raw-report-viewer/CTD'], {
      queryParams: { pageHostname: domain, calculatedVendorName: vendor, "RRV": this.currentFile.replace('TSD','CTD')  },
    });
  }
  goToDetailDataReport(domain, vendor) {
    this.router.navigate(['/reports/raw-report-viewer/DTD'], {
      queryParams: { pageHostname: domain, calculatedVendorName: vendor , "RRV": this.currentFile.replace('TSD','DTD') },
    });
  }
  goToCategoryDetails(cat, domain, vendor) {
    switch (cat) {
      case 'Data Tracker':
        this.goToDetailDataReport(domain, vendor);
        break;
      case 'Cookie Tracker':
        this.goToDetailCookieReport(domain, vendor);
        break;
      case 'Code Tracker':
        this.goToDetailCodeReport(domain, vendor);
        break;
    }
  }
  private interpolateColor(color1, color2, factor) {
    let result = '#';
    for (let i = 0; i < 3; i++) {
      let value1 = parseInt(color1.substring(1 + i * 2, 3 + i * 2), 16);
      let value2 = parseInt(color2.substring(1 + i * 2, 3 + i * 2), 16);
      let value = Math.round(value1 + (value2 - value1) * factor).toString(16);
      result += ('0' + value).slice(-2); // Pad with zero if necessary
    }
    return result;
  }
  public baseColors = ['#00dbff', '#6390ee', '#0e1227', '#e5ebe8',];

  private generateMultiGradientColors(numItems, baseColors) {
    let colors = [];
    const numSections = baseColors.length - 1;
    if (numItems > 1) {
      for (let i = 0; i < numItems; i++) {
        const sectionLength = 1 / numSections;
        const position = (i / (numItems - 1)) * numSections; // Position in the gradient
        const index = Math.floor(position); // Determine which color to start from
        const factor = position - index; // Factor of interpolation within the section

        let color = this.interpolateColor(
          baseColors[index],
          baseColors[Math.min(index + 1, baseColors.length - 1)],
          factor
        );
        colors.push(color);
      }
    } else if (numItems === 1) {
      // Only one item, use the first color
      colors.push(baseColors[0]);
    }
    return colors;
  }
}
