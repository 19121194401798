import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { EventRuleService } from '../../../../services/eventRule/event-rule.service';
import { InstrumentationService } from '../../../../services/instrumentation/instrumentation.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

@Component({
  selector: 'event-trigger',
  templateUrl: './event-trigger.component.html',
  styleUrls: ['./event-trigger.component.css']
})
export class EventTriggerComponent implements OnInit {
  @ViewChild('codeEditor') codeEditor: CodemirrorComponent;
  eventTemplate:string;
  notificationOptions:any;
  codeMirrorOptions:any;
  viewed:boolean;

  constructor(
    private instrumentationService:InstrumentationService, 
    private spinnerService:SpinnerService,
    private notificationService:NotificationsService
  ) { 
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
  
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
    };
    this.eventTemplate = "{}";
    this.viewed = false;
  }

  ngOnInit(): void {
  }

  onFocus(): void {
    // this hack is needed to populate code editor with text
    // without having to click on editor the first time
    if (!this.viewed) {
      this.viewed = true;
      setTimeout(() => {
        this.codeEditor.codeMirror.refresh();
      }, 1000);
    }
  }

  loadTemplate(type){
    let template = EventRuleService.EVENT_TEMPLATES[type];
    if ( !template ){
      template = EventRuleService.EVENT_TEMPLATES["UI Event"]; // i dont see this key in EVENT_TEMPLATES
    }
    this.eventTemplate = JSON.stringify(template, null, 2);
  }

  sendEvent(){
    let eventJson = this.eventTemplate.trim();//.replace(/\s/g, "");
    try{
      let eventObj = JSON.parse(eventJson);
      this.instrumentationService.sendRawEvent(eventObj);
      this.notificationService.success("Sucess", "Event was sent successfully", this.notificationOptions);
    } catch(e) {
      this.notificationService.error("Error", "Invalid JSON object.", this.notificationOptions);
    }
  }
}
