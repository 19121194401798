import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fb-details-dropdown',
  templateUrl: './fb-details-dropdown.component.html',
  styleUrls: ['./fb-details-dropdown.component.css']
})
export class FbDetailsDropdownComponent implements OnInit {
  @Input('title') title: string;
  @Input('data') data: any;
  @Input('placeholder') placeholder: string;
  @Input('fields') fields: any;

  @Output() dropdownValueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onDropdownChange(event: any): void {
    this.dropdownValueChange.emit(event.value);
  }

}
