import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatcherAttributeKey } from '../../components/vendorLibrary/models/MatcherAttributeKey';
import { MatcherFlag } from '../../components/vendorLibrary/models/MatcherFlag';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from "../../../environments/environment";
import { DomainDefinition } from '../../components/vendorLibrary/models/DomainDefinition';
import { DomainMatcher } from '../../components/vendorLibrary/models/DomainMatcher';
import { CreateObjectResponse } from '../../components/vendorLibrary/models/CreateObjectResponse';
import { MatcherAttribute } from '../../components/vendorLibrary/models/MatcherAttribute';
import { ResourceMatcher } from '../../components/vendorLibrary/models/ResourceMatcher';
import { tap } from 'rxjs/operators';
import { Extractor } from '../../components/vendorLibrary/models/Extractor';

@Injectable({
  providedIn: 'root'
})
export class VendorLibraryService extends VaultBaseService {

  cache: { [type: string]: Observable<any>; };

  private readonly DAYS: number = 2;
  private readonly END_POINT: string = `${env.api.url}vendorLibrary/`

  public matcherAttributeKeysById: Map<number, MatcherAttributeKey>;
  public matcherAttributeKeys: MatcherAttributeKey[];

  constructor(authHttp: HttpClient) {
    super(authHttp);
    this.cache = {};
    this.matcherAttributeKeysById = new Map<number, MatcherAttributeKey>();
  }

  getAllDomainDefinitions(useCache: boolean = true): Observable<any> {
    const cacheKey = `domainDefinitions`;
    const url = `${this.END_POINT}domainDefinition`;
    if (!this.cache[cacheKey] || useCache == false) {
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  getDomainDefinition(domainDefId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefId}`;
    return this.authHttp.get(url);
  }

  getResourceMatcher(resourceMatcherId: number): Observable<any> {
    const url = `${this.END_POINT}resourceMatcher/${resourceMatcherId}`;
    return this.authHttp.get(url);
  }

  getAllFlags(): Observable<MatcherFlag[]> {
    const cacheKey = `flags`;
    const url = `${this.END_POINT}matcherFlags`;
    if (!this.cache[cacheKey]) {
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  getAllMatcherAttributeKeys(): Observable<MatcherAttributeKey[]> {
    const cacheKey = `attributeKeys`;
    const url = `${this.END_POINT}matcherAttributeKeys`;
    if (!this.cache[cacheKey]) {
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey].pipe(tap((results) => {
      this.matcherAttributeKeys = results;
      this.matcherAttributeKeys.forEach((key) => {
        this.matcherAttributeKeysById[key.id] = key;
      });
    }));
  }

  createDomainDefinition(domainDefinition: DomainDefinition): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(domainDefinition)
    );
  }

  deleteDomainDefinition(domainDefinitionId: number, recursive = false): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}`;
    const params = new HttpParams().set("recursive", recursive.toString());
    return this.authHttp.delete(url, { params });
  }

  updateDomainDefinition(domainDefinition): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinition.id}`;
    return this.authHttp.put(url, JSON.stringify(domainDefinition));
  }

  createDomainMatcher(domainDefinitionId: number, domainMatcher: DomainMatcher): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/domainMatcher`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(domainMatcher)
    );
  }

  deleteDomainMatcher(domainDefinitionId: number, domainMatcherId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/domainMatcher/${domainMatcherId}`;
    return this.authHttp.delete(url);
  }

  createDefaultAttribute(domainDefinitionId: number, matcherAttribute: MatcherAttribute): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/attribute`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(matcherAttribute)
    );
  }

  deleteDefaultAttribute(domainDefinitionId: number, matcherAttributeId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/attribute/${matcherAttributeId}`;
    return this.authHttp.delete(url);
  }

  createResourceMatcher(domainDefinitionId: number, resourceMatcher: ResourceMatcher): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(resourceMatcher)
    );
  }

  updateResourceMatcher(domainDefinitionId: number, resourceMatcher): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcher.id}`;
    return this.authHttp.put(url, JSON.stringify(resourceMatcher));
  }

  deleteResourceMatcher(domainDefinitionId: number, resourceMatcherId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}`;
    return this.authHttp.delete(url);
  }

  createResourceMatcherAttribute(domainDefinitionId: number, resourceMatcherId: number, matcherAttribute: MatcherAttribute): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/attribute`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(matcherAttribute)
    );
  }

  deleteResourceMatcherAttribute(domainDefinitionId: number, resourceMatcherId: number, matcherAttributeId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/attribute/${matcherAttributeId}`;
    return this.authHttp.delete(url);
  }

  createResourceMatcherExtractor(domainDefinitionId: number, resourceMatcherId: number, extractor: Extractor): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(extractor)
    );
  }

  updateResourceMatcherExtractor(domainDefinitionId: number, resourceMatcherId: number, extractor): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor/${extractor.id}`;
    return this.authHttp.put(url, JSON.stringify(extractor));
  }

  deleteResourceMatcherExtractor(domainDefinitionId: number, resourceMatcherId: number, extractorId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor/${extractorId}`;
    return this.authHttp.delete(url);
  }

  createResourceMatcherExtractorAttribute(domainDefinitionId: number, resourceMatcherId: number, extractorId: number, matcherAttribute: MatcherAttribute): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor/${extractorId}/attribute`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(matcherAttribute)
    );
  }

  deleteResourceMatcherExtractorAttribute(domainDefinitionId: number, resourceMatcherId: number, extractorId: number, matcherAttributeId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor/${extractorId}/attribute/${matcherAttributeId}`;
    return this.authHttp.delete(url);
  }

  createResourceMatcherExtractorFlag(domainDefinitionId: number, resourceMatcherId: number, extractorId: number, flagId: number): Observable<CreateObjectResponse> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor/${extractorId}/flag/${flagId}`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify({})
    );
  }

  deleteResourceMatcherExtractorFlag(domainDefinitionId: number, resourceMatcherId: number, extractorId: number, flagId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/${domainDefinitionId}/resourceMatcher/${resourceMatcherId}/extractor/${extractorId}/flag/${flagId}`;
    return this.authHttp.delete(url);
  }

  testDomainDefinition(domainDefId: number): Observable<any> {
    const url = `${this.END_POINT}domainDefinition/test/${domainDefId}`;
    return this.authHttp.get(url);
  }

  refreshData(): Observable<any> {
    const url = `${env.api.url}refreshData`;
    return this.authHttp.get(url);
  }

  refreshVendorLib(): Observable<any> {
    const url = `${env.api.url}vendorLibrary/saveCache`;
    return this.authHttp.get(url);
  }

  getPageRunAnalysisDetails(pageId: string, jobUuid: string): Observable<any> {
    const url = `${env.api.url}pageRunAnalysisV2/${pageId}/${jobUuid}`;
    return this.authHttp.get(url);
  }

  getUnclassifiedRequestDomains(days: number = this.DAYS): Observable<any> {
    const url = `${env.api.url}requests/unclassified`;
    const params = new HttpParams().set("days", days);
    return this.authHttp.get(url, { params });
  }

  getUnclassifiedRequests(domain: string, days: number = this.DAYS, limit: number = 200, offset: number = 0): Observable<any> {
    const url = `${env.api.url}requests/unclassified`;
    const params = new HttpParams().set("domain", domain).set("days", days).set("limit", limit).set("offset", offset);
    
    return this.authHttp.get(url, {params});
  }

  private createPageRunnerJobEvent(data: any): Observable<any> {
    const url = `${env.api.url}createPageRunnerJobEvent`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  createManualCodeFileReviewEvent(events): Observable<any> {

    let payload = {
      type: {
        event: 'manualNewCodeFileReview',
        jobStep: 'manualAnalysis',
        jobStepType: 'manualNewCodeFileReview',
        schema: 'manualNewCodeFileReview'
      },
      events: events.map( (e) => { return {customerId: e.customerid, jobuuid: e.jobuuid, requestuuid: e.requestrecorduuid, responsehash: e.responsehash} })
    };

    return this.createPageRunnerJobEvent(payload);
  }

  createManualDomainReviewEvent(events): Observable<any> {

    let payload = {
      type: {
        event: 'manualNewDomainReview',
        jobStep: 'manualAnalysis',
        jobStepType: 'manualNewDomainReview',
        schema: 'manualNewDomainReview'
      },
      events: events.map( (e) => { return {jobUuid: e.jobuuid, requestrecorduuid: e.requestrecorduuid, domain: e.domain} })
    };

    return this.createPageRunnerJobEvent(payload);
  }

  createManualNewVendorReviewEvent(events): Observable<any> {
    console.log("createManualNewVendorReviewEvent");
    console.log(events);
    let payload = {
      type: {
        event: 'manualNewVendorReview',
        jobStep: 'manualAnalysis',
        jobStepType: 'manualNewVendorReview',
        schema: 'manualNewVendorReview'
      },
      events: events.map( (e) => { return {jobUuid: e.jobuuid, requestrecorduuid: e.requestrecorduuid, customerId: e.customerid, vendorid: e.calculatedvendorid} })
    };

    return this.createPageRunnerJobEvent(payload);

  }

  testRequests(domainId: number, rmId: number, requests: any[]): Observable<any> {
    const url = `${env.api.url}classifyRequests`;
    return this.authHttp.post(
      url,
      JSON.stringify({
        domainDefinitionId: domainId,
        resourceMatcherId: rmId,
        requests: requests
      })
    );
  }

  getChangedProperties(currentObject, changedObject) {
    let returnObj = {};
    let props = Object.keys(currentObject);
    for (var prop of props) {
      if (currentObject[prop] != changedObject[prop] && Array.isArray(currentObject[prop]) == false) {
        returnObj[prop] = changedObject[prop];
      }
    }
    if (Object.keys(returnObj).length > 0) {
      returnObj["id"] = currentObject.id;
      return returnObj;
    }
    return null;
  }
}
