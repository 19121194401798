<div class="row">
  <div class="col-lg-8">
    <div class="card" id="incident-info-card">
      <div class="card-header" style="font-size: x-large; display: flex; flex-direction: row; padding-bottom: 0px;">
        <div style="flex-grow: 1;">
          {{incidentDetails.name}} | <span style="font-size: small; color: grey;">ID {{incidentDetails.id}}</span><br />
          <div class='incident-description'>{{incidentDetails.description}}</div>
        </div>
        <div *ngIf="incidentDetails.isMerged === 0 && authService.isVaultUser()"
          style="width: fit-content; flex-grow: 0" class="edit-btn" (click)="onDialogOpen('#edit-dialog')">
          <span class="zmdi zmdi-edit"></span>
        </div>
      </div>
      <div class="card-body" style="display: flex; flex-direction: column; ">
        <div class="incident-info-container">
          <div>Status:{{"\t"}} <span
              [style.color]="incidentDetails.status === 2 ? 'green' : '#6390ee'">{{incidentDetails.status === 2 ?
              "Resolved"
              : "In Progress"}} <span class="zmdi"
                [class]="incidentDetails.status === 2? 'zmdi-check-all' : 'zmdi-lock-open'"></span></span></div>
          <div>Severity: <span
              [style.color]="getSeverityColor(incidentDetails.severity)">{{severityTypes[incidentDetails.severity]}}</span>
          </div>
        </div>
        <ng-container *ngIf="incidentDetails.isMerged === 0 else mergedToolbar">
          <div class="toolbar" *ngIf="authService.isVaultUser()">
            <!-- <button (click)="resetAll()"><span class="zmdi zmdi-refresh-sync"></span>
              Reset</button> -->
            <button (click)="onDialogOpen('#severity-dialog')"><span class="zmdi zmdi-alert-octagon"></span>Change
              Severity</button>

            <button (click)="onDialogOpen('#status-dialog')" *ngIf="incidentDetails.status === 2"><span
                class="zmdi zmdi-lock-open"></span>Reopen</button>
            <button (click)="onDialogOpen('#status-dialog')" *ngIf="incidentDetails.status === 1"><span
                class="zmdi zmdi-check-all"></span>Resolve</button>
            <!-- <button><span class="zmdi zmdi-plus-circle-o-duplicate"></span>Update</button> -->
          </div>
        </ng-container>
        <ng-template #mergedToolbar>
          <div class="toolbar">
            <button style="background-color: darkgray; color: white;pointer-events: none; cursor: default;"><span
                class="zmdi zmdi-alert-circle-o"></span>Incident has been merged to another incident</button>
          </div>
        </ng-template>

        <div class="toolbar" *ngIf="!authService.isVaultUser()">
          <button (click)="onDialogOpen('#acknowledge-dialog')" *ngIf="incidentDetails.status === 2"><span
              class="zmdi zmdi-lock-open"></span>Acknowledge to Reopen</button>
          <button (click)="onDialogOpen('#acknowledge-dialog')" *ngIf="incidentDetails.status === 1"><span
              class="zmdi zmdi-check-all"></span>Acknowledge to Resolve</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="card" id="analyst-review-card">
      <div class="card-header">Analyst Review</div>
      <div class="card-body" style="display: flex; flex-direction: column; ">
        <div class="analyst-review-container">
          <div *ngFor="let analyst of incidentDetails.allAnalysts; index as i">
            <span class="zmdi zmdi-account" style="margin-right: 10px;"></span>{{analyst}} {{i === 0 ? '(Initiator)' :
            ''}}
          </div>
        </div>
        <div class="toolbar" *ngIf="incidentDetails.isMerged === 0">
          <button *ngIf="incidentDetails.isReviewRequested" (click)="onDialogOpen('#review-dialog')"><span
              class="zmdi zmdi-upload"></span>Submit Review</button>
          <button *ngIf="!incidentDetails.isReviewRequested" (click)="onDialogOpen('#review-dialog')"><span
              class="zmdi zmdi-alert-triangle"></span>Request Review</button>
          <button *ngIf="incidentDetails.isReviewRequested"
            style="background-color: red; color: white;pointer-events: none; cursor: default;"><span
              class="zmdi zmdi-alert-circle-o"></span>Review Requested</button>

        </div>
      </div>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-lg-6">
    <div class="card-header" style="margin-left: 150px">Associated Code Files ({{codeFilesList?.length +
      mergedCodeFilesList?.length || 0}} Files)</div>
    <div class="test list-container">
      <div class="test list-selection-container">

        <div class="test list-selection-item"
          *ngFor="let file of sortCodeFiles(codeFilesList.concat(mergedCodeFilesList))" (click)="onFileSelect(file)"
          [class.list-item-active]="file?.requestrecorduuid === selectedFile?.requestrecorduuid">
          <div class="selection-indicator" *ngIf="file.incidentIndex > 0"
            [style.background-color]="getPalleteColor(file.incidentIndex)"></div>{{file.nameString}}
        </div>
        <!-- <div class="list-selection-item list-action-btn" (click)="onAddSelect()"
          *ngIf="incidentDetails.isMerged === 0 && authService.isVaultUser()">
          <span class="zmdi zmdi-plus-circle" style="margin-right: 10px;"></span>Add File
        </div> -->
      </div>
      <ng-container *ngIf="! addingFile else addingFileContainer">
        <div class="test list-content-container"
          *ngIf="codeFilesList?.length > 0 || mergedCodeFilesList?.length > 0 else noFilesFound">
          <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
            <div
              style=" font-size: large;font-weight: bold; text-align: right; border-right: 1px lightgrey solid; margin-right:10px; padding-right: 10px; "
              [style.border-right]="selectedFile.isUnclassified ? 'none': '1px lightgrey solid'">
              {{selectedFile.isUnclassified ? "Unclassified Code File" :selectedFile?.vendorName}}</div>
            <img *ngIf="!selectedFile.isUnclassified" style="width: 35px; height:35px; margin: 5px; display: block;"
              src="https://{{cdnUrl}}/vendors/icon/{{ selectedFile.vendorDomain ? selectedFile.vendorDomain.toLowerCase() : 'unknown' }}.ico"
              alt="" />
          </div>
          <div class="content">
            <div *ngIf="!selectedFile.isUnclassified"><span class="label">Domain Matcher: </span>

              <span class="value" (click)="linkToVendor($event,selectedFile)">{{selectedFile?.domainName || "No Domain Matcher"}}</span>
            </div>

            <div *ngIf="!selectedFile.isUnclassified"><span class="label">Resource Matcher: </span> <span class="value"
                (click)="linkToRequestDetails($event, selectedFile)">{{selectedFile?.resourceName || "No Resource Matcher"}}</span></div>

            <div><span class="label">Found on: </span> <span *ngIf="selectedFile?.starturl"
                (click)="linkToPageHistory($event, selectedFile?.jobuuid)"
                class="value">{{selectedFile?.starturl}}</span>
              <span *ngIf="!selectedFile?.starturl" style="font-weight: normal; font-style: italic;"> Page not specified</span>
            </div>

            <div><span class="label">Scanned on: </span> <span>{{selectedFile?.dateString || "Invalid Date"}}</span>
            </div>

            <div style="text-overflow: clip; width: 100%; text-wrap: wrap; word-break: break-all;"><span
                class="label">Request URL:
              </span> <span>{{selectedFile?.requesturl || selectedFile?.url || "Request URL Not Found"}}</span></div>

          </div>

        </div>
        <ng-template #noFilesFound>
          <div class="list-content-container">
            NO FILES FOUND
          </div>
        </ng-template>
      </ng-container>
      <ng-template #addingFileContainer>
        <div class="test list-content-container">
          <input />
          <button>Add by Resource ID</button>
        </div>
      </ng-template>

    </div>
  </div>
  <div class="col-lg-6">
    <div class="card-header" style="margin-left: 150px">Associated Incidents</div>
    <div class="test list-container">
      <div class="test list-selection-container">
        <div class="test list-selection-item" *ngFor="let mergeFile of mergedIncidentsList"
          (click)="onMergeSelect(mergeFile);onMergeAddViewClose()"
          [class.list-item-active]="mergeFile.id === selectedMergeIncident.id">
          <div class="selection-indicator" *ngIf="mergeFile.incidentIndex > 0"
            [style.background-color]="getPalleteColor(mergeFile.incidentIndex)"></div>Incident {{mergeFile.id}} <br />
          {{mergeFile.incident}}
        </div>
        <div class="list-selection-item list-action-btn" (click)="onMergeAddViewOpen()"
          *ngIf="incidentDetails.isMerged === 0 && authService.isVaultUser()">
          <span class="zmdi zmdi-plus-circle" style="margin-right: 10px;"></span>Add Incident
        </div>
      </div>
      <div class="test list-content-container " *ngIf="!showMergeAddView else mergeAddView">
        <ng-container *ngIf="mergedIncidentsList?.length > 0 else noMergeIncidents">


          <div class="content">
            <div><span class="label">Name: </span> {{selectedMergeIncident.name}}</div>
            <div><span class="label">Description: </span> {{selectedMergeIncident.description}}</div>
            <div><span class="label">Incident Created On: </span> {{selectedMergeIncident.dateString}}</div>
          </div>

        </ng-container>
        <ng-template #noMergeIncidents>
          <div
            style="width: 100%; height: 100%; font-size: large; color: lightgrey; text-align: center; flex-grow: 1; align-items: center; vertical-align: center;">
            NO INCIDENTS FOUND
          </div>
        </ng-template>
      </div>
      <ng-template #mergeAddView>
        <form [formGroup]="mergeForm" class="list-content-container">
          <div>
            <ejs-dropdownlist [dataSource]="listOfPossibleMerges" [fields]="{text:'id',value:'id'}"
              [placeholder]="'Select an Incident to Merge'" formControlName="mergeId">
              <ng-template #itemTemplate let-data>
                <!--set the value to itemTemplate property-->
                <div style="display: flex; flex-direction: column; padding-top: 5px; width: 100%; ">
                  <div>{{data.name}}</div>
                  <div style="font-size: small; text-overflow: ellipsis; width: 100%; word-wrap: break-word;">
                    ID {{data.id}}</div>
                </div>
              </ng-template>
              <ng-template #valueTemplate let-data>
                <!--set the value to itemTemplate property-->
                <div style="display: flex; flex-direction: column; padding-top: 5px; width: 100%; ">
                  <span style="margin: 0px 10px;">{{data.name}}</span>
                </div>
              </ng-template>
            </ejs-dropdownlist>
          </div>
          <div class="toolbar"><button [disabled]="mergeForm.value.mergeId === null"
              [class.static-button]="mergeForm.value.mergeId === null" (click)="onMergeSubmit()"><span
                class="zmdi zmdi-file-add"></span>Merge Incident</button></div>

        </form>
      </ng-template>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">Incident Timeline</div>
      <div class="card-body">
        <div id="log-view">
          <div class="toolbar" style="margin-top:0">
            <button (click)="onDialogOpen('#new-comment-dialog')"><span class="zmdi zmdi-comment"
                *ngIf="incidentDetails.isMerged === 0"></span>New
              Comment</button>
            <button (click)="onViewLatest()"><span class="zmdi zmdi-calendar"></span>View Latest</button>
            <button (click)="refreshTimeline()"><span class="zmdi zmdi-refresh-sync"></span> Refresh Timeline
              View</button>
          </div>
          <div id="log-container" style="position: relative;">
            <div id="log-timeline">
              <div style="display:block;position: relative; height: 100%">
                <div *ngIf="timeline !== {}" id="line-container">
                  <svg id="incident-svg">
                   <!-- <line *ngFor="let x of tempLogData.splice(1)" class='incident-line' x1="0" x2="0" y1="0" y2="0" stroke="black" stroke-width="2"></line> -->
                  </svg>
                </div>
              </div>
              <div *ngFor="let logs of tempLogData" class="date-container">
                <div class="date-header">
                  {{logs.day}}
                </div>
                <div class="marker-container">
                  <div *ngFor="let log of logs.logs" class="all-markers">
                    <div class="incident-marker" *ngIf="!log.isFiller else filler">

                      <button class="incident-marker-btn classic" [attr.show]="true" [value]="log.type"
                        (mousedown)="showDetailsCard(log)" [style.background-color]="getLogTypes(log.type).color">
                        <span [class]="getLogTypes(log.type).icon"></span>
                        <div *ngIf="!log.isVaultUser" class="user-badge">
                          <span class="zmdi zmdi-account-circle"></span>
                        </div>
                        <div *ngIf="log.isHidden && authService.isVaultUser()" class="user-badge-2">
                          <span class="zmdi zmdi-eye-off"></span>
                        </div>
                      </button>
                      <div class="incident-text">{{getLogTypes(log.type).label}}<br />{{log.timeString}}</div>
                    </div>
                  </div>

                </div>
                <div class="merge-container">
                  <div *ngFor="let log of logs.mergedLogs" class="all-markers">
                    <div class="incident-marker" *ngIf="!log.isFiller else mergedFiller">
                      <button class="incident-marker-btn merged" [attr.show]="true" [value]="log.type"
                        (mousedown)="showDetailsCard(log)" [style.background-color]="getLogTypes(log.type).color"><span
                          [class]="getLogTypes(log.type).icon"></span></button>

                      <div class="incident-text">{{getLogTypes(log.type).label}}<br />{{log.timeString}}</div>
                    </div>
                  </div>
                </div>
                <ng-template #filler>
                  <div class="incident-marker" [style.opacity]='0'>
                    <button class="incident-marker-btn classic empty-marker" [attr.show]="false" [value]="0"></button>
                    <div class="incident-text">NULL NULL NULL NULL NULL</div>
                  </div>
                </ng-template>
                <ng-template #mergedFiller>
                  <div class="incident-marker" [style.opacity]='0'>
                    <button class="incident-marker-btn merged empty-marker" [attr.show]="false" [value]="0"></button>
                    <div class="incident-text"></div>
                  </div>
                </ng-template>

              </div>

            </div>
            <div id="log-details-card" [attr.show]="false">
              <div *ngIf="isCardVisible else closedCard ">

                <div id="details-header">
                  <div id="title" [style.color]="getLogTypes(cardDetails.type).color"><span
                      [class]="getLogTypes(cardDetails.type).icon"></span> {{cardDetails.title}}
                    {{cardDetails.isHidden?"\(Hidden\)":""}}</div>
                  <div id="btns">
                    <button class="close-btn"><span class="zmdi zmdi-close" (click)="hideDetailsCard()"></span></button>
                  </div>
                </div>
                <div id="details-body">
                  <div><span>Date: </span> {{cardDetails.date}}</div>
                  <div style="text-transform: capitalize;"><span>User: </span> {{cardDetails.user}}</div>
                  <div style="white-space: pre; text-wrap: wrap;"><span>Comment: </span><br
                      *ngIf="cardDetails.comment?.indexOf('\n') > -1" />{{cardDetails.comment}}</div>

                </div>
              </div>
              <ng-template #closedCard>
                <div style="width: 100%; height: 100%; text-align: center; color:lightgrey; font-size: x-large;">
                  Select incident marker from the timeline to view more details</div>
              </ng-template>

            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="new-comment-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <span class="zmdi zmdi-comment" style="margin-right: 10px"></span> Add New Comment
        </div>
        <div class="close-btn" (click)="onDialogClose('#new-comment-dialog')"><span class="zmdi zmdi-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="commentForm">
          <div class="form-content">
            <textarea formControlName="comment" class="form-textarea" placeholder="Enter Comment Here.."></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="toolbar" style="margin: 0">
          <div style="font-size: x-small;" [hidden]="!authService.isVaultUser()"> Comment is <b>{{isCommentHidden?
              'Hidden' : 'Visible'}}</b> to Non-VaultJS Users</div>
          <button [value]="isCommentHidden" (click)="toggleComment()" [hidden]="!authService.isVaultUser()"><span
              class="zmdi {{isCommentHidden ?  'zmdi-eye-off' : 'zmdi-eye'}}"
              [style.color]="isCommentHidden ? 'lightgrey' : 'skyblue' "></span>Visibility: {{isCommentHidden ? 'Hidden'
            : 'Visible'}}</button>
          <button (click)="onCommentSubmit();onDialogClose('#new-comment-dialog');"><span
              class="zmdi zmdi-check"></span>Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="status-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <span class="zmdi zmdi-comment" style="margin-right: 10px"></span> Are you sure you want to
          {{incidentDetails.status === 2 ? 'Reopen' : 'Resolve'}} this incident?
        </div>
        <div class="close-btn" (click)="onDialogClose('#status-dialog')"><span class="zmdi zmdi-close"></span></div>
      </div>
      <div class="modal-footer">
        <div class="toolbar" style="margin: 0">
          <button *ngIf="incidentDetails.status === 2" (click)="onReopenSubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Re-open</button>
          <button *ngIf="incidentDetails.status === 1" (click)="onResolveSubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Resolve</button>

        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="severity-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <span class="zmdi zmdi-comment" style="margin-right: 10px"></span> Confirm Severity Change from
          <span [style.color]="incidentDetails.severity === 0 ? 'orange' : 'red'">{{incidentDetails.severity === 0 ?
            "Yellow" : "Red"}}</span>
          <span class="zmdi zmdi-arrow-right" style="margin: 0px 10px"></span>
          <span [style.color]="incidentDetails.severity === 0 ? 'red' : 'orange'">{{incidentDetails.severity === 0 ?
            "Red" : "Yellow"}}</span>
        </div>
        <div class="close-btn" (click)="onDialogClose('#severity-dialog')"><span class="zmdi zmdi-close"></span></div>
      </div>
      <div class="modal-footer">
        <div class="toolbar" style="margin: 0">
          <button *ngIf="incidentDetails.severity === 2" (click)="onOrangeSeveritySubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Change to Yellow</button>
          <button *ngIf="incidentDetails.severity === 1" (click)="onRedSeveritySubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Change to Red</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="review-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <span class="zmdi zmdi-comment" style="margin-right: 10px"></span>
          <span *ngIf="incidentDetails.isReviewRequested">Are you sure you want to submit the review?</span>
          <span *ngIf="!incidentDetails.isReviewRequested">Are you sure you want to request a review?</span>
        </div>
        <div class="close-btn" (click)="onDialogClose('#review-dialog')"><span class="zmdi zmdi-close"></span></div>
      </div>
      <div class="modal-footer">
        <div class="toolbar" style="margin: 0">
          <button *ngIf="incidentDetails.isReviewRequested" (click)="onReviewCompleteSubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Submit</button>
          <button *ngIf="!incidentDetails.isReviewRequested" (click)="onReviewRequestSubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Request</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="acknowledge-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <span class="zmdi zmdi-comment" style="margin-right: 10px"></span> Are you sure you want to acknowledge
          {{incidentDetails.status === 2 ? 'Reopening' : 'Resolving'}} this incident?
        </div>
        <div class="close-btn" (click)="onDialogClose('#acknowledge-dialog')"><span class="zmdi zmdi-close"></span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="toolbar" style="margin: 0">
          <button *ngIf="incidentDetails.status === 2" (click)="onAckReopenSubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Acknowledge for Reopening</button>
          <button *ngIf="incidentDetails.status === 1" (click)="onAckResolveSubmit()"><span
              class="zmdi zmdi-check"></span>Yes, Acknowledge for Resolving</button>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="edit-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <span class="zmdi zmdi-comment" style="margin-right: 10px"></span> Edit Incident Details
        </div>
        <div class="close-btn" (click)="onDialogClose('#edit-dialog')"><span class="zmdi zmdi-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="editForm">
          <div class="form-content">
            <input type="text" formControlName="name" class="form-textinput" [value]="incidentDetails.name" />
            <textarea formControlName="description" class="form-textarea" style="height: 100px;"
              [value]="incidentDetails.description"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="toolbar" style="margin: 0">
          <button (click)="onEditSubmit();onDialogClose('#edit-dialog');"><span
              class="zmdi zmdi-check"></span>Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>