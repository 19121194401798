<div class="row">
    <div class="col-lg-12">
        <div class="card" style="display: flex; flex-direction: row; padding: 10px; align-items: center;">
            <div class="card-header"
                style="font-size: xx-large; font-weight: 500; flex-grow: 1; padding:10px;margin:0px;">Report Library
            </div>
            <div class="toolbar">
                <button type="button" (click)="configModalAction()" *ngIf="authService.isVaultUser()"><span class="zmdi zmdi-settings"></span> Library
                    Configuration</button>
                <!-- <button (click)="testDownload()">Test Download</button> -->
            </div>
        </div>
    </div>
</div>
<ng-container *ngFor="let cat of listCategories">
    <div class="row" *ngIf="splitCheckHidden(cat)">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header" style="font-size: large;">{{cat}}</div>
                <div style="display: grid; grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: minmax(1fr, max-content); gap: 20px; padding: 20px; width:100%; position: relative">
                    <ng-container *ngFor="let report of splitByCategory(cat)" #reportTiles>
                        <app-report-selection-tile *ngIf="!report.hidden" [title]="report.title"
                            [description]="report.description" [icon]="report.icon" [reportCode]="report.reportCode"
                            [beautifiedPath]="report.beautifiedPath" [hasRaw]='report.hasRaw' [isEmpty]="report.isEmpty"
                            [isCustom]="report.isCustom"
                            style="position: relative;"></app-report-selection-tile>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="modal fade" id="configModal" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 800px;">
            <div class="modal-header">
                Report Library Configuration

            </div>
            <div class="modal-body">
                <div class="card-header" style="padding:0px; margin:10px 0 5px 0; display:flex; flex-direction: row; border-bottom: 1px solid black;">
                    <div style="flex-grow:1">Custom Reports</div>
                    <div class="toolbar" style="font-size:small">
                        <button (click)="addNewCustomConfig()"> Add Custom Report</button>
                    </div>
                </div>
                <div style="display: flex;flex-direction:column; gap:10px;">
                    <div *ngFor="let report of tempCustomConfigState;index as i" class="form-box">
                        <div style="display: flex; flex-direction:row; gap:5px">
                            <input type="text" [(ngModel)]="report.title" placeholder="Title">

                            <input type="text" [(ngModel)]="report.icon" placeholder="Icon">
                            <input type="text" [(ngModel)]="report.reportCode" placeholder="Report Code">
                        </div>
                        <div style="display: flex; flex-direction:row; gap:5px">
                            <input type="text" [(ngModel)]="report.description" placeholder="Description" style="flex-grow: 1;">
                            <button (click)="removeCustomConfig(i)" style="width: fit-content;"><span class="zmdi zmdi-delete"></span> Remove</button>
                        </div>

                    </div>
                </div>
                <div class="card-header" style="padding:0px; margin: 10px 0px 5px 0px; border-bottom: 1px black solid;">Predefined Reports </div>
                <div>
                    <ng-container *ngFor="let report of tempPredefinedConfigState">
                        <div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
                            <input type="checkbox" [(ngModel)]="report.hidden">
                            <span>{{report.title}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer">
                <div class="toolbar" style="margin: 0">
                    <button type="button" (click)="onConfigConfirm()"><span class="zmdi zmdi-settings"></span> Confirm
                        Changes</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add the following code inside the placeholder -->