import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountSwitchService } from '../../../services/accountSwitch/account-switch.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { environment as env } from "../../../../environments/environment";

@Component({
  selector: 'app-account-switch',
  templateUrl: './account-switch.component.html',
  styleUrls: ['./account-switch.component.css']
})
export class AccountSwitchComponent implements OnInit {
  
  cdnUrl:string;

  customers;

  constructor(public customerService:CustomerService,
              private router:Router,               
              private accountSwitchService:AccountSwitchService) { 
    this.cdnUrl = env.cdn.url;

    this.customers = this.customerService.customers.map((c)=>{
      let products = c["products"].map( p => p.name);
      c.name += ` (${ products.length > 0 ? products.join(",") : "N/A" })` 
      return c;
    });
  }

  ngOnInit() {
    if ( !this.customerService.customers || (this.customerService.customers && this.customerService.customers.length == 0)){
      this.router.navigate(["/logout"]);
    }
  }

  selectAccount(accountId:number){
   this.accountSwitchService.switchAccount(accountId);    
  }

}
