import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { ReportingS3Service } from '../../../services/reporting-s3/reporting-s3.service';
import { Papa } from 'ngx-papaparse';
import { AuthService } from '../../../services/auth/auth.service';
import { TrackerDashboardService } from '../../../services/tracker-dashboard/tracker-dashboard.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-tracker-vendor-dashboard',
  templateUrl: './tracker-vendor-dashboard.component.html',
  styleUrls: ['./tracker-vendor-dashboard.component.css'],
})
export class TrackerVendorDashboardComponent implements OnInit, OnDestroy {
  public cdnUrl: string;
  private cid: number;
  private vendorName: string;
  private currentFile: string = '';

  constructor(
    private vendorService: VendorsService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private router: Router,
    private trackerService: TrackerDashboardService
  ) {
    this.cdnUrl = env.cdn.url;
    this.cid = parseInt(this.authService.customerId());
    this.vendorName =
      this.route.snapshot.queryParams.v || this.availableVendors[0];
  }
  private vendorList = [];
  public availableVendors = [];
  public uniquePages: number = 0;
  public uniqueDomains: number = 0;
  public uniqueTrackers: number = 0;
  public uniqueCodeTrackers: number = 0;
  public uniqueDataTrackers: number = 0;
  public uniqueCookieTrackers: number = 0;
  public CHDPages: number = 0;
  public selectedVendorName = '';
  public selectedVendorInfo: any = {};
  public vendorFields = { text: 'name', value: 'name' };
  public vendorGroupedData: any = [];
  public vendorPieData: any = [];
  public pagePieData: any = [];

  public emptyPieData = [
    {
      label: 'No Data',
      value: 1,
      color: 'lightgrey',
      children: [],
    },
  ];
  private loadVendorData(vendorName: string) {
    const temp = this.vendorList.find((vendor) => vendor.name === vendorName);
    this.selectedVendorInfo = temp || {};
    this.selectedVendorName = vendorName;
    const selectedVendorData =
      this._allTrackerData.find(
        (row) => row.calculatedvendorname === this.selectedVendorName
      ) || {};

    this.uniqueCodeTrackers = selectedVendorData.summarytotalcodetrackers;
    this.uniqueDataTrackers = selectedVendorData.summarytotaldatatrackers;
    this.uniqueCookieTrackers = selectedVendorData.summarytotalcookietrackers;
    this.vendorGroupedData = this._allTrackerDataDomain
      .filter((row) => row.calculatedvendorname === this.selectedVendorName)
      .map((row) => {
        if (
          row.trackercategories &&
          typeof row.trackercategories !== 'boolean'
        ) {
          row.trackercategories =
            this.mapCategories(row.trackercategories || ' ') || [];
        } else {
          row.trackercategories = [];
        }
        return row;
      });
    this.uniquePages = this.vendorGroupedData.reduce(
      (acc, cur) => (cur.totalpages || 0) + acc,
      0
    );
    this.uniqueDomains = this.vendorGroupedData.length;

    this.uniqueTrackers = selectedVendorData.summarytotaltrackers;
    let colors = this.generateMultiGradientColors(
      this.vendorGroupedData.length,
      this.baseColors
    );
    if (this.vendorGroupedData.length === 0) {
      this.pagePieData = this.emptyPieData;
      this.vendorPieData = this.emptyPieData;
    } else {
      this.pagePieData = this.vendorGroupedData.map((child, index) => {
        return {
          label: child.pagehostname,
          value: child.totalpages || 1,
          color: colors[index],
          children: [],
        };
      });
      this.vendorPieData = this.vendorGroupedData.map((child, index) => {
        return {
          label: child.pagehostname,
          value: child.totaltrackers || 1,
          color: colors[index],
          children: [],
        };
      });
    }
  }
  onVendorSelected($event) {
    const itemData = $event.itemData;
    this.loadVendorData(itemData.value);
  }
  private _allTrackerData: any = [];
  private _allTrackerDataDomain: any = [];
  private loadData() {
    forkJoin([
      this.vendorService.getAllVendors(),
      this.trackerService.getTrackerSummaryVendor(),
      this.trackerService.getTrackerSummaryDomainVendor(),
    ]).subscribe(
      (values) => {
        console.log(values);
        this.vendorList = values[0];
        const trackersummaryvendor = values[1];
        const trackersummarydomainvendor = values[2];
        this._allTrackerData = trackersummaryvendor;
        this._allTrackerDataDomain = trackersummarydomainvendor;
        const allVendors = new Set();
        this._allTrackerData.forEach((row) => {
          allVendors.add(row.calculatedvendorname);
        });
        this.availableVendors = Array.from(allVendors).sort((a: any, b: any) =>
          a.localeCompare(b)
        );
        this.loadVendorData(this.vendorName || this.availableVendors[0]);
      },
      (error) => {},
      () => {
        this.spinnerService.toggle(false);
      }
    );
  }
  public dataByDomain = [];
  private trackerCats = ['Code Tracker', 'Data Tracker', 'Cookie Tracker'];
  mapCategories(categories: string) {
    if (!categories) {
      return [];
    }
    const temp = categories.split(', ');
    console.log(temp);
    return this.trackerCats.map((cat) => {
      if (temp.includes(cat)) {
        return cat;
      } else {
        return 'none';
      }
    });
  }
  ngOnInit(): void {
    this.cdnUrl = env.cdn.url;
    this.spinnerService.toggle(true);
    this.loadData();
  }
  ngOnDestroy(): void {}
  public isLoaded: boolean = true;

  goToDetailCodeReport(domain, vendor) {
    this.router.navigate(['/reports/tracker-report-viewer/TS'], {
      queryParams: {
        pageHostname: domain,
        calculatedVendorName: vendor,
      },
    });
  }
  goToDetailCookieReport(domain, vendor) {
    this.router.navigate(['/reports/tracker-report-viewer/CTD'], {
      queryParams: {
        pageHostname: domain,
        calculatedVendorName: vendor,
      },
    });
  }
  goToDetailDataReport(domain, vendor) {
    this.router.navigate(['/reports/tracker-report-viewer/DTD'], {
      queryParams: {
        pageHostname: domain,
        calculatedVendorName: vendor,
      },
    });
  }
  goToCategoryDetails(cat, domain, vendor) {
    switch (cat) {
      case 'Data Tracker':
        this.goToDetailDataReport(domain, vendor);
        break;
      case 'Cookie Tracker':
        this.goToDetailCookieReport(domain, vendor);
        break;
      case 'Code Tracker':
        this.goToDetailCodeReport(domain, vendor);
        break;
    }
  }
  private interpolateColor(color1, color2, factor) {
    let result = '#';
    for (let i = 0; i < 3; i++) {
      let value1 = parseInt(color1.substring(1 + i * 2, 3 + i * 2), 16);
      let value2 = parseInt(color2.substring(1 + i * 2, 3 + i * 2), 16);
      let value = Math.round(value1 + (value2 - value1) * factor).toString(16);
      result += ('0' + value).slice(-2); // Pad with zero if necessary
    }
    return result;
  }
  public baseColors = ['#00dbff', '#6390ee', '#0e1227', '#e5ebe8'];

  private generateMultiGradientColors(numItems, baseColors) {
    let colors = [];
    const numSections = baseColors.length - 1;
    if (numItems > 1) {
      for (let i = 0; i < numItems; i++) {
        const sectionLength = 1 / numSections;
        const position = (i / (numItems - 1)) * numSections; // Position in the gradient
        const index = Math.floor(position); // Determine which color to start from
        const factor = position - index; // Factor of interpolation within the section

        let color = this.interpolateColor(
          baseColors[index],
          baseColors[Math.min(index + 1, baseColors.length - 1)],
          factor
        );
        colors.push(color);
      }
    } else if (numItems === 1) {
      // Only one item, use the first color
      colors.push(baseColors[0]);
    }
    return colors;
  }
}
