import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';
import { ReportingS3Service } from '../../../services/reporting-s3/reporting-s3.service';
import { Router } from '@angular/router';
import { Grid, GridComponent } from '@syncfusion/ej2-angular-grids';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
interface ViolationRow {
  cookie: string;
  vendor: string;
  count: number;
  data: any;
}

@Component({
  selector: 'app-report-alerts',
  templateUrl: './report-alerts.component.html',
  styleUrls: ['./report-alerts.component.css'],
})
export class ReportAlertsComponent implements OnInit {
  @ViewChild('grid') grid: GridComponent;

  public isLoading = false;
  constructor(
    private dashboardFileSelectorService: DashboardFileSelectorService,
    private reporting: ReportingS3Service,
    private router: Router,
    private sitemap: SitemapService,
    private spinnerService: SpinnerService
  ) {
    this.dashboardFileSelectorService.toggle(true);
    this.dashboardFileSelectorService.setReportCode('CRS');
    this.sitemap.hideHeader = true;
    this.isLoading = true;

    this.processorList = [
      { id: 'nonEssentialResponseCookies', name: 'Non-Essential Cookies' },
      { id: 'WithGTMTMS', name: 'Non-Essential Cookies w/ GTM' },
      { id: 'WithoutGTMTMS', name: 'Non-Essential Cookies w/o GTM' },
      { id: 'WithADBTMS', name: 'Non-Essential Cookies w/ Adobe DTM' },
      { id: 'WithoutADBTMS', name: 'Non-Essential Cookies w/o Adobe DTM' },
    ];
  }
  public rawData: any = [];
  public selectedFile: any = '';
  public violationData: ViolationRow[] = [];
  public onNonEssential = true;
  public processorList: any = [];
  public processorListFields: any = {
    text: 'name',
    value: 'id',
  };

  ngOnInit(): void {
    this.dashboardFileSelectorService.setSelectionHandler(
      this.onReportSelected.bind(this)
    );
  }
  ngOnDestroy(): void {
    this.dashboardFileSelectorService.toggle(false);
  }
  public selectedProcessor: any = 'nonEssentialResponseCookies';
  public processorTitle = '';
  public uniqueCookies = 0;
  public totalPages = 0;
  public totalCookies = 0;
  public topNChains = 0;
  compileReportData = () => {
    this.spinnerService.toggle(true,'Compiling Report Data');
    let temp = [];
    this.processorTitle = this.processorList.find(
      (p) => p.id === this.selectedProcessor
    ).name;
    switch (this.selectedProcessor) {
      case 'nonEssentialResponseCookies':
        temp = this.rawData.filter((row) => {
          return row['Is Essential'] === false && row['Source'] === 'response';
        });
        this.violationData = this.topNChainsByCookie(temp);
        this.uniqueCookies = this.getUniqueCookiesCount(temp);
        // this.totalPages = this.getUniquePagesCount(temp);
        this.totalCookies = this.totalCookieCount(temp);
        break;
      case 'WithGTMTMS':
        temp = this.rawData.filter((row) => {
          return (
            row['Is Essential'] === false &&
            row['Source'] === 'response' &&
            row['Request Chain'].indexOf('googletagmanager.com') > -1
          );
        });
        this.violationData = this.topNChainsByCookie(temp);
        this.uniqueCookies = this.getUniqueCookiesCount(temp);
        // this.totalPages = this.getUniquePagesCount(temp);
        this.totalCookies = this.totalCookieCount(temp);
        break;
      case 'WithoutGTMTMS':
        temp = this.rawData.filter((row) => {
          return (
            row['Is Essential'] === false &&
            row['Source'] === 'response' &&
            row['Request Chain'].indexOf('googletagmanager.com') === -1
          );
        });
        this.violationData = this.topNChainsByCookie(temp);
        this.uniqueCookies = this.getUniqueCookiesCount(temp);
        // this.totalPages = this.getUniquePagesCount(temp);
        this.totalCookies = this.totalCookieCount(temp);
        break;
      case 'WithADBTMS':
        temp = this.rawData.filter((row) => {
          return (
            row['Is Essential'] === false &&
            row['Source'] === 'response' &&
            row['Request Chain'].indexOf('adobedtm.com') > -1
          );
        });
        this.violationData = this.topNChainsByCookie(temp);
        this.uniqueCookies = this.getUniqueCookiesCount(temp);
        // this.totalPages = this.getUniquePagesCount(temp);
        this.totalCookies = this.totalCookieCount(temp);
        break;
      case 'WithoutADBTMS':
        temp = this.rawData.filter((row) => {
          return (
            row['Is Essential'] === false &&
            row['Source'] === 'response' &&
            row['Request Chain'].indexOf('adobedtm.com') === -1
          );
        });
        this.violationData = this.topNChainsByCookie(temp);
        this.uniqueCookies = this.getUniqueCookiesCount(temp);
        // this.totalPages = this.getUniquePagesCount(temp);
        this.totalCookies = this.totalCookieCount(temp);
        break;
    }
    this.spinnerService.toggle(false);
  };
  onProcessorChange($event: any) {
    console.log('processor change', $event);
    this.selectedProcessor = $event.value;
    this.compileReportData();
  }
  onReportSelected($event: any) {
    this.isLoading = true;
    console.log('selected report', $event);
    this.selectedFile = $event;
    this.spinnerService.toggle(true,'Loading Report Data');
    this.reporting.getReportData($event).subscribe((data) => {
      console.log('data', data);
      if (this.grid) this.grid.showSpinner();
      this.rawData = data.result;
      this.totalPages = this.getUniquePagesCount(this.rawData);
      this.compileReportData();
    }, (error) => {}, () => {
      if (this.grid) this.grid.hideSpinner();
      this.isLoading = false;
      this.spinnerService.toggle(false);
    });
  }
  private getUniqueCookiesCount = (data): any => {
    const cookies = new Set();
    data.forEach((row) => {
      cookies.add(this.createKey(row['Cookie Name'], row['Vendor Name']));
    });
    return Array.from(cookies).length;
  };

  private getUniquePagesCount = (data): any => {
    const pages = new Set();
    data.forEach((row) => {
      pages.add(row['Page URL']);
    });
    return Array.from(pages).length;
  };

  private totalCookieCount = (data): any => {
    return data.length;
  };


  private createKey = (cookieName, vendor) => {
    return `${cookieName} - ${vendor}`;
  };
  top5Chains = (data) => {
    const chains = {};
    data.forEach((row) => {
      if (chains[row['Request Chain']]) {
        chains[row['Request Chain']].push(row);
      } else {
        chains[row['Request Chain']] = [row];
      }
    });
    // sort by length
    const sortedChains = Object.keys(chains)
      .sort((a, b) => chains[b].length - chains[a].length)
      .slice(0, 5)
      .map((chain) => {
        return {
          chain: this.chainTransform(chain),
          count: chains[chain].length,
          // data: chains[chain],
        };
      });
    // console.log(sortedChains);
    return sortedChains;
  };
  chainTransform = (chain: any) => {
    const chainArr = chain.split(' -> ');
    return chainArr.map((c) => {
      let isIFrame = false;
      if (c.startsWith('(') && c.endsWith(')')) {
        isIFrame = true;
        c = c.substring(1, c.length - 1);
      }
      return {
        node: c,
        isIFrame: isIFrame,
      };
    });
  };
  goToReportWithCookie(cookie: string) {
    this.router.navigate(['/reports/raw-report-viewer/CRS'], {
      queryParams: {
        'Cookie Name': cookie,
        Source: 'response',
        RRV: this.selectedFile.replace('TSD', 'TS'),
      },
    });
  }
  // ===========================================================================
  // processors
  // ===========================================================================
  public topNChainsByCookie = (data, N: number = 20): ViolationRow[] => {
    this.topNChains = N;
    const allCookies = new Set();
    data.forEach((row) => {
      allCookies.add(this.createKey(row['Cookie Name'], row['Vendor Name']));
    });
    const cookies = {};
    Array.from(allCookies).forEach((cookie: any) => {
      cookies[cookie] = [];
      data.forEach((row) => {
        // console.log(row["Cookie Name"], row["Vendor Name"], createKey(row["Cookie Name"], row["Vendor Name"]))
        if (this.createKey(row['Cookie Name'], row['Vendor Name']) === cookie) {
          cookies[cookie].push(row);
        }
      });
    });
    // sort by length
    const sortedCookies = Object.keys(cookies)
      .sort((a, b) => cookies[b].length - cookies[a].length)
      .slice(0, N)
      .map((cookie) => {
        const cookieData = data.find(
          (row) =>
            this.createKey(row['Cookie Name'], row['Vendor Name']) === cookie
        );
        return {
          cookie: cookieData['Cookie Name'],
          vendor: cookieData['Vendor Name'],
          count: cookies[cookie].length,
          data: this.top5Chains(cookies[cookie]),
        };
      });
    console.log(sortedCookies);
    return sortedCookies;
  };
}
