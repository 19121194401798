import { Injectable } from '@angular/core';
import { openDB } from 'idb';

@Injectable({
  providedIn: 'root'
})
export class ReportingIdbService {
  private dbPromise = openDB('VJSReportDataDB', 1, {
    upgrade(db) {
      // Create an object store with a key path that combines reportName and accountId
      const store = db.createObjectStore('VJSReportData', { keyPath: 'compositeKey' });
      store.createIndex('reportNameIndex', 'reportName');
      store.createIndex('accountIdIndex', 'accountId');
    },
  });

  constructor() { }

  private createCompositeKey(reportName: string, accountId: string): string {
    return `${reportName}_${accountId}`;
  }

  async getCachedData(reportName: string, accountId: string): Promise<any> {
    const db = await this.dbPromise;
    const compositeKey = this.createCompositeKey(reportName, accountId);
    return db.transaction('VJSReportData').objectStore('VJSReportData').get(compositeKey);
  }

  async cacheData(reportName: string, accountId: string, data: any): Promise<void> {
    const db = await this.dbPromise;
    const compositeKey = this.createCompositeKey(reportName, accountId);
    const tx = db.transaction('VJSReportData', 'readwrite');
    tx.objectStore('VJSReportData').put({ compositeKey, reportName, accountId, data });
    await tx.done;
  }
  async removeCachedData(reportName: string, accountId: string): Promise<void> {
    const db = await this.dbPromise;
    const compositeKey = this.createCompositeKey(reportName, accountId);
    const tx = db.transaction('VJSReportData', 'readwrite');
    tx.objectStore('VJSReportData').delete(compositeKey);
    await tx.done;
  }
}
