import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment as env } from "../../../../../environments/environment";

declare var $: any;
@Component({
  selector: 'vendor-map-panel',
  templateUrl: './vendor-map-panel.component.html',
  styleUrls: ['./vendor-map-panel.component.css'],
})
export class VendorMapPanelComponent implements OnChanges {
  @Input('length') length: number = 5;
  @Input('data') inputData: Object[] = [];
  public cdnUrl = env.cdn.url;
  public unknownImgSrc = `https://${this.cdnUrl}/vendors/icon/unknown.ico`;
  public data: Object[] = [];

  constructor() {
    this.data = this.inputData.sort((a:any, b:any) => b.count - a.count).slice(0, this.length);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.data = this.inputData.sort((a:any, b:any) => b.count - a.count).slice(0, this.length);
    setTimeout(() => {
      $('div[popHack]').popover('enable');
    }, 100);
  }
}
