<div *ngIf="isLoaded | async">
    <div class="container h-100 d-flex flex-column">
        <div class="row">
            <div class="col-lg-4">
                <div class="col-lg-12 h-25">
                    <extended-accum-chart title="Pages With Correct Configuration" [max]="totalPages.max" [label]="'Pages'"
                        [monitored]="totalPages.monitored"></extended-accum-chart>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-lg-12">
                        <app-trend-line-display title="Key Pages" [max]="keyTrendLen"
                            [series]="keyTrendErrs.series"></app-trend-line-display>
                    </div>
                    <div class="col-lg-12">
                        <app-trend-line-display title="Total Pages" [max]="totalTrendLen"
                            [series]="totalTrendErrs.series"></app-trend-line-display>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-lg-12">
                        <condensed-value-display title="Total Pages Have Adobe Launch" [max]="adobeLaunch.max"
                            [label]="'Pages'" [monitored]="adobeLaunch.monitored"></condensed-value-display>
                    </div>
                    <div class="col-lg-12">
                        <condensed-value-display title="Variable Validations Pass Tests" [max]="varInst.max"
                            [label]="" [monitored]="varInst.monitored"></condensed-value-display>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding-left: 15px; border: 0px solid red;">
            <div class="col-lg-8" style="border: 0px solid red;">
                <div class="card">
                    <app-bar-chart-panel title="Variables" yLabel="Validations" [data]="topVars" width="721">
                    </app-bar-chart-panel>
                </div>
            </div>
            <!-- <div class="col-lg-3" style="border: 0px solid red; height: 450px;">
                <div class="card" style="display: table; width: 100%; height: 100%;">
                    <div class="card-header">Top Tests Failed</div>
                    <div class="card-body" style="padding-top: 11px;">
                        <ul>
                            <li *ngFor="let error of topErrs" style="padding-bottom: 11px;">
                                <span><b>{{error.testName}}</b></span>
                                <br />
                                <span>{{error.totalErrors}} failures</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4" style="border: 0px solid red; height: 450px;">
                <div class="card" style="display: table; width: 100%; height: 100%;">
                    <app-site-group-panel [dataSource]="groupData">
                    </app-site-group-panel>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" style="border: 0px solid red; padding-left: 30px;">
                <div class="card card-default" style="border: 0px solid red;">
                    <div class="card-header">Variables</div>
                    <div class="card-body">
                        <app-adobe-var-grid-panel [data]="variableGridData"></app-adobe-var-grid-panel>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" style="border: 0px solid red; padding-left: 30px;">
                <div class="card card-default" style="border: 0px solid red;">
                    <div class="card-header">Pages</div>
                    <div class="card-body">
                        <app-adobe-var-pages-panel [data]="pageGridData"></app-adobe-var-pages-panel>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" style="border: 0px solid red; padding-left: 30px;">
                <div class="card card-default" style="border: 0px solid red;">
                    <div class="card-header">Rules</div>
                    <div class="card-body">
                        <app-rule-grid-panel [data]="ruleGridData"></app-rule-grid-panel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
