import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'scan-recency-buckets',
  templateUrl: './scan-recency-buckets.component.html',
  styleUrls: ['./scan-recency-buckets.component.css'],
})
export class ScanRecencyBucketsComponent implements OnChanges {
  public isLoaded: boolean = true;
  @Input('datasource') datasource = [];
  public inputData: number[] = [1002983, 1, 0,];
  public opacity: number[] = this.inputData.map((item,index) => {
    return  0.2+(index/(this.inputData.length) * 0.8);
  });
  public percentRegex = /\.?0+$/;
  public recencyLabels: string[] = ['< 7 Day', '7-30 Days', '> 30 Days'];
  public theme: string[] = [ '#00dbff', '#6390ee','#0e1227'];
  public sum:any;
  public data:any;

  constructor() {
  }

  updateData(){
    this.sum = this.datasource.reduce((a, b) => a + b, 0);
    this.data = this.datasource.map((item) => {
      return item / this.sum * 100;
    }
    );
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }
}
