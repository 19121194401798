<div class="card card-default">
    <div class="card-header">Pages
    </div>
    <div class="card-body" *ngIf="isLoaded; else loading">
        <ejs-grid #gridsite id="gridsite" [dataSource]='data' [allowPaging]="true" height="350px" [allowSorting]="true"
            [sortSettings]="sortSettings" allowFiltering="true" [pageSettings]="pageOptions" [allowSelection]="false"
            [enableHover]="false" [filterSettings]="filterSettings" locale='en-US' allowResizing='true' autoFit="true"
            [allowTextWrap]='true' (actionComplete)="refreshGraphs2($event)" (dataSourceChanged)="updateData()" [textWrapSettings]="{wrapMode: 'Header'}"
            (dataBound)="refreshGraphs2($event)" >
            <e-columns>
                <e-column field="isKeyPage" headerText='KPV' [autoFit]="true" headerTextAlign="Center"
                    textAlign="center" [showColumnMenu]="false" [allowFiltering]="false">

                    <ng-template #template let-data>
                        <div class="icon"><span class="zmdi" [class.zmdi-key]="data.isKeyPage"
                                [class.key]="data.isKeyPage"></span></div>
                    </ng-template>
                </e-column>
                <e-column field='page' headerText='Page' [autoFit]="true" maxWidth="300px" textAlign="left" headerTextAlign="Center"  clipMode='EllipsisWithTooltip'>
                    <ng-template #template let-data>
                        <span><a href="http://ui.vaultjs.com/pages/{{data.id}}">{{data.page}}</a></span>
                    </ng-template>
                </e-column>

                <e-column *ngFor="let risk of gRiskList; index as i" [headerText]="risk.label" [field]="risk.key"
                    [allowSorting]="false" [allowFiltering]="false" [allowReordering]="false" [showColumnMenu]="false"
                    [autoFit]="false" minWidth="100px">
                    {{risk.label}}
                    <ng-template #template let-data>
                        <div style="text-align: center;">
                            <ng-container *ngIf="data.violations[i].checked">
                                <span *ngIf="data.violations[i].count > 0" class="zmdi zmdi-close redX icon"
                                    popHack="popoverTarget" data-container="body" data-toggle="popover"
                                    data-trigger="hover" data-placement="top" data-html="true"
                                    [attr.data-content]=" 'Violation Count: '+ data.violations[i].count +  '<br/> Vendors: ' + data.violations[i].vendors">
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!data.violations[i].checked">
                                <span class="zmdi zmdi-close-circle-o greyX icon"></span>
                            </ng-container>
                        </div>
                    </ng-template>
                </e-column>
                <e-column field="lastChecked" headerText='Last Checked' headerTextAlign="Center" minWidth="150px"
                    textAlign="center" [autoFit]="true">
                    <ng-template #template let-data>
                        <span>{{dateFormatting(data.lastChecked)}}</span>
                    </ng-template>
                </e-column>

            </e-columns>
        </ejs-grid>
    </div>
    <ng-template #loading>
        <div class="card-body">
            LOADING...
        </div>
    </ng-template>

</div>