<div class="main-content container-fluid be-loading">  
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card be-loading" [class.be-loading-active]="isLoading">
          <div class="card-header">
            <span class="title">Overview</span>
            <span class="card-subtitle"></span>
          </div>
          <div class="card-body" style="height: 120px">
            <p>Pages Monitored: {{data?.PagesMonitored | number }}</p>
            <p>New Code Files (last 30 days): {{data?.NewCodeFiles | number}}</p>
            <p>Code Changes (last 30 days): {{data?.CodeChanges | number }}</p>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>        
    <div class="col-sm-6">
      <div class="card">
        <div class="card be-loading" [class.be-loading-active]="isLoading">
          <div class="card-header">
            <span class="title">Top Reports</span>
            <span class="card-subtitle">Download and view reports</span>
          </div>
          <div class="card-body">
            <!-- <p><span class="vaultLink" (click)="getReport('Pages', 'pages');">Pages Report</span></p> -->
            <p *ngIf="customerId == 36"><span class="vaultLink" (click)="getReport('TagReport', 'tagReport', 'zip', 'application/zip');">Tag Report (4/27/20)</span></p>
            <p><span class="vaultLink" (click)="getReport('Vendors', 'vendors');">3rd Party Vendors</span></p>
            <p *ngIf="customerId != 36">&nbsp;</p>            
            <!-- <p><span class="vaultLink" (click)="getReport('CodeFiles', 'codeFiles');">3rd Party Code Files</span></p> -->            
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">    
    <div class="col-sm-12">
      <reportIncidents></reportIncidents>
    </div>
  </div>
  <div class="be-spinner">
    <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
    </svg>
  </div>
</div>