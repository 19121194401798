import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { User } from "../models/User";
import { Injectable } from "@angular/core";
import { UserService } from "../services/users/user.service";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";


@Injectable()
export class UserResolver implements Resolve<User[]> {
    
    constructor(private userService:UserService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<User[]> | User[] {
        console.log("User Resolver", route);
        const userId = route.params["userId"];
        
        if (userId == "new" || userId == "existing" ) {
            return [new User(userId, "", "", "", null, null, [])];
        } else {
            this.spinnerService.toggleRoot(true);
            return this.userService.getUser(userId).pipe(tap(()=>{
                this.spinnerService.toggleRoot(false);
            }));
        }
    }
}