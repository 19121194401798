import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataStateChangeEventArgs, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-grids';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { EventType } from '../../../models/EventType';
import { EventCondition } from '../../../models/EventCondition';
import { EventAction } from '../../../models/EventAction';
import { EventOutput } from '../../../models/EventOutput';
import { AuthService } from '../../../services/auth/auth.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { EventRuleService } from '../../../services/eventRule/event-rule.service';
import { Ej2EventRulesProxyService } from '../../../services/ej2Proxies/ej2-event-rules-proxy.service';
import { tap } from 'rxjs/operators';
import { Ej2AdvancedEventRulesProxyService } from '../../../services/ej2Proxies/ej2-advanced-event-rules-proxy.service';
declare var $: any;

@Component({
  selector: 'avanced-event-rule',
  templateUrl: './avanced-event-rule.component.html',
  styleUrls: ['./avanced-event-rule.component.css']
})
export class AvancedEventRuleComponent implements OnInit {

  notificationOptions:any;
  selectedRule:any;
  codeMirrorOptions:any;
  rules:Observable<any>;
  pageSettings:PageSettingsModel;
  sortSettings:SortSettingsModel;
  toolbar: string[];
  dateFormat:any;
  
  @ViewChild('rulesGrid') grid: GridComponent;
  eventTypes:EventType[];
  eventConditions:EventCondition[];
  eventActions:EventAction[];
  eventOutputs:EventOutput[];
  eventDestinationTypes:any[];
  isEditingRule:boolean;
  isNewRule:boolean;
  rules$:Observable<any>;

  constructor(
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private notificationService: NotificationsService,
    public authService:AuthService,
    public _fb:FormBuilder,
    public timezoneService:TimezoneService,    
    public spinnerService:SpinnerService,
    private eventRuleService:EventRuleService,
    private ej2ProxyService:Ej2AdvancedEventRulesProxyService
  ) { 

      this.isEditingRule = false;
      this.isNewRule = false;
      this.eventTypes = []      
      this.eventOutputs = [];

      this.sitemapService.update(this.route);

      this.notificationOptions = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };

      this.dateFormat = {type: 'dateTime', skeleton:'short'}
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 20, 
        pageCount: 4, 
        pageSizes: [20, 25, 50]
      };
      this.toolbar = ['Search'];
      this.sortSettings = {
        columns: [
          { field: 'enabled', direction: 'Descending'},
          { field: 'name', direction: 'Ascending'}
        ]
      };
  }

  ngOnInit(): void {
    this.loadRuleData();
  }

  loadRuleData(){
    this.rules$ = this.ej2ProxyService.pipe(tap((result)=>{
      console.log("result");
      console.log(result);
      this.eventDestinationTypes = result.allDestinations;
      this.eventOutputs = result.allOutputs;
      this.eventTypes = this.authService.isVaultUser() ? result.allEvents : result.allEvents.filter((event)=>{ return event.isInternal == false });
      this.spinnerService.toggle(false);
    }));
    let state = { skip: 0, take: 20 };
    this.ej2ProxyService.execute(state);
  }

  onNewClick(){
    this.isNewRule = true;
    this.isEditingRule = true;
    this.selectedRule = null;
  }

  onDeleteClick() {
    $("#mod-confirm-rule-delete").modal('toggle');
    if ( this.selectedRule){
      this.spinnerService.toggle(true);
      this.eventRuleService.deleteEventRule([this.selectedRule.id]).subscribe((response)=>{
        console.log(response);
        this.notificationService.success('Success!', 'The rule has been deleted.', this.notificationOptions);
        this.loadRuleData();
      })
    }
  }

  onEditRuleClick(rule) {
    console.log(rule);
    this.selectedRule = rule;    
    this.isEditingRule = true;
    this.isNewRule = false;
  }

  onDuplicateClick(rule){
    console.log(rule);
    rule.id = null;
    rule.name += ' Copy';
    rule.isDuplicate = true;
    
    this.onEditRuleClick(rule);
  }

  showDeletePrompt(rule){
    this.selectedRule = rule;
    $("#mod-confirm-rule-delete").modal('toggle');    
  }

  onDataBound() {    
   
  }

  onGridCreate() {
    this.grid.showSpinner = () => false;
    this.loadRuleData();
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);    
    this.spinnerService.toggle(true);
    this.ej2ProxyService.execute(state);
  }

  getOutputNames(rule){
    return rule.outputs.map((o)=>{return o.destinationName}).join(", ");
  }

  onNewCancel(event){
    console.log(event);
    this.isEditingRule = false;
  }

  onNewSave(event){
    console.log(event);
    this.isEditingRule = false;
    if ( event.state == 'success') {
      this.notificationService.success('Success!', `The rule has been saved successfully.`, this.notificationOptions);      
    }    
  }
  

}
