<div class="report-container">
    <div class="row">
        <div class="col-lg-12">
            <app-title-card height="1000px" [hideInfoIcon]="true">
                <ng-container header>
                    <span class="zmdi zmdi-book title-icon"></span>
                    User Manual
                     <span style="font-size: small; color: grey; margin-left: 10px;" *ngIf="lastModified">| Updated on {{lastModified}}</span>
                </ng-container>
                <ng-container headerToolbar>
                    <ejs-dropdownlist [dataSource]="userManualVersionList" [fields]="userManualListFields" [value]="selectedManualVersion"></ejs-dropdownlist>
                    <!-- <button (click)="onNewTabClick()">Open link in New Tab</button> -->
                </ng-container>
                <iframe *ngIf="pdfLink" [src]="pdfLink" width="100%" height="100%" frameborder="0" scrolling="no"
                    style="border-radius: .2rem; " allowfullscreen title="VJS Manual v1.1.2"></iframe>
            </app-title-card>
        </div>
    </div>
</div>
