<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border">
      <div class="card-header">
          <div class="tools">
            <button type="button" class="btn btn-rounded btn-space btn-success" (click)="createScript();"><i style="color: white;" class="icon icon-left zmdi zmdi-edit"></i> Create New Script</button>
            <!-- <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exporData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button> -->
          </div>
        <span class="title">Automated Users</span>&nbsp;<span class="icon zmdi zmdi-info" title="Automated Users" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
        <span class="card-subtitle">Manage your automated users below</span>
      </div>
      <div class="card-body">
        <ejs-grid #grid 
        [dataSource]='ej2ScriptedPageRunData | async'
        locale='en-US' 
        [allowPaging]="true" 
        [pageSettings]="pageSettings" 
        [allowSorting]="true" 
        [sortSettings]="sortSettings"
        [allowSelection]="false"
        [enableHover]="false"
        [toolbar]="toolbar"
        (dataStateChange)= 'dataStateChange($event)'>
        <e-columns>
          <e-column [visible]="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" width="55">
            <ng-template #template let-data>
              <button class="btn btn-xs btn-rounded btn-space btn-primary" (click)="onEditClick(data)">Edit</button>
            </ng-template>
          </e-column>
          <e-column field='scriptFilePath' headerText='Name'>
            <ng-template #template let-data>
              <a [routerLink] = "['/admin/automatedUsers/',data.id, 'history']">{{data.scriptFilePath}}</a>
            </ng-template>
          </e-column>
          <e-column field='enabled' headerText='Enabled'></e-column>
          <e-column field='cronSchedule' headerText='Schedule'></e-column>
        </e-columns>
      </ejs-grid>
      </div>
    </div>
  </div>
</div>