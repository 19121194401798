import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DataValidationService } from "../services/data-validation/data-validation.service";
import { ValidationRule } from "../models/ValidationRule";


@Injectable()
export class ValidationRuleResolver implements Resolve<ValidationRule> {
    
    constructor(private dataValidationService:DataValidationService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any> {
        console.log("Validation Rule Resolver", route);
        const testSuiteId = route.params["testSuiteId"];
        const validationRuleId = route.params["validationRuleId"];

        if ( this.dataValidationService.selectedValidationRule.value && this.dataValidationService.selectedValidationRule.value.id == validationRuleId) {
            return;
        } else {

            this.spinnerService.toggleRoot(true);
            return this.dataValidationService.getRulesForTestSuite(testSuiteId, 0, 1000, "name", "desc", "", 0)
                .pipe(
                    tap((response)=>{
                        var rules = response.data.filter( (r) => { return r.id == validationRuleId });
                        this.dataValidationService.selectedValidationRule.next( rules.length == 1 ? rules[0] : null )
                        this.spinnerService.toggleRoot(false);
                    })
                );
        }        
    }
}