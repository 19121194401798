import { Pipe, PipeTransform } from '@angular/core';
import { domain } from 'process';
import { VendorDomain } from '../models/VendorDomain';

@Pipe({
  name: 'searchfilterdomains'
})
export class SearchfilterdomainsPipe implements PipeTransform {

  transform(listings:VendorDomain[], searchval:string ): VendorDomain[] {
    if (!listings || !searchval){
      return listings;
    }
    return listings.filter(VendorDomain => VendorDomain.name.toLocaleLowerCase().includes(searchval.toLocaleLowerCase()));
  }
}
