import { Injectable } from '@angular/core';
import { from, of, Observable, concat, forkJoin } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import * as AWS from 'aws-sdk';
import { environment as env } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { v4 as uuidv4 } from 'uuid';
import { map } from 'rxjs/operators';
import { ReportingLibraryService } from '../reporting-library/reporting-library.service';
import { DataExportService } from '../data-export/data-export.service';
export interface ExportObject {
  reportCode: string;
  columns: string[];
  sqlWhere: string;
  tableName: string;
  customerId: string;
  initiatorName?: string;
  customName?: string;
}

interface ExportReportLogs {
  uuid: string;
  timestamp: number;
  output: boolean;
  complete: boolean;
  running: boolean;
  initiate: boolean;
  columns?: any;
  sqlWhere?: any;
  outputSize?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReportExportService {
  private _env;
  private _bucket: string;
  private _customerId: string;
  constructor(
    private authService: AuthService,
    private reportLib: ReportingLibraryService,
    private dataExport: DataExportService
  ) {
    this._env = env;
    this._customerId = this.authService.customerId();
    this._bucket = `${this._env.exportAWS.bucket}`;
  }

  public generateExportQuery(exportObject: ExportObject): Observable<any> {
    if (!this.validateColumns(exportObject.columns)) {
      return of({ error: 'Invalid columns' });
    }
    const columns = exportObject.columns.join(',');
    const headers = exportObject.columns
      .map((col) => "'" + col + "'")
      .join(',');
    const sqlWhere = exportObject.sqlWhere;
    const tableName = exportObject.tableName;
    // const customerId = exportObject.customerId;
    const template = `SELECT ${columns} FROM pagerunner.${tableName} ${
      sqlWhere ? 'WHERE ' + sqlWhere : ''
    }`;
    const out = {
      query: template,
      sourceTable: tableName,
      columns: columns,
      sqlWhere: sqlWhere,
      reportCode: exportObject.reportCode,
      initiatorName: exportObject.initiatorName,
      customName: exportObject.customName,
    };
    console.log('Generated: ', out);
    // return of(out);
    return this.uploadInitiatorFile(out, this.createInitiatorFileName());
  }

  private createInitiatorFileName() {
    const uuid = uuidv4();
    return `initiate_${uuid}_${new Date().getTime()}`;
  }

  private columnsToString(columns: string[]) {
    // if columns contains any keywords that manipulate the query, return *
    const blockedWords = [
      'DELETE',
      'UPDATE',
      'INSERT',
      'DROP',
      'CREATE',
      'ALTER',
      'TRUNCATE',
      'RENAME',
      'REPLACE',
      'SET',
      'GRANT',
      'REVOKE',
      'SHOW',
      'DESCRIBE',
      'USE',
      'FLUSH',
      'KILL',
      'ANALYZE',
      'CHECK',
      'OPTIMIZE',
      'REPAIR',
      'RESTORE',
      'BACKUP',
      'PURGE',
      'RESET',
      'START',
      'STOP',
      'RELOAD',
      'SHUTDOWN',
      'EXPLAIN',
      'HANDLER',
      'LOAD',
      'LOCK',
      'UNLOCK',
      'ROLLBACK',
      'SAVEPOINT',
    ];
    if (columns.some((column) => blockedWords.includes(column.toUpperCase()))) {
      console.error('Blocked word detected in columns');
      return '*';
    }
    if (!columns || columns.length === 0) {
      return '*';
    } else {
      return columns.join(',');
    }
  }

  private columnHeadersToString(columns: string[]) {}

  private validateColumns(columns: string[]) {
    // check if columns are valid
    if (!columns || columns.length === 0) {
      return false;
    }
    if (columns.some((column) => column.includes(' '))) {
      return false;
    }
    const blockedWords = [
      'DELETE',
      'UPDATE',
      'INSERT',
      'DROP',
      'CREATE',
      'ALTER',
      'TRUNCATE',
      'RENAME',
      'REPLACE',
      'SET',
      'GRANT',
      'REVOKE',
      'SHOW',
      'DESCRIBE',
      'USE',
      'FLUSH',
      'KILL',
      'ANALYZE',
      'CHECK',
      'OPTIMIZE',
      'REPAIR',
      'RESTORE',
      'BACKUP',
      'PURGE',
      'RESET',
      'START',
      'STOP',
      'RELOAD',
      'SHUTDOWN',
      'EXPLAIN',
      'HANDLER',
      'LOAD',
      'LOCK',
      'UNLOCK',
      'ROLLBACK',
      'SAVEPOINT',
    ];
    if (columns.some((column) => blockedWords.includes(column.toUpperCase()))) {
      console.error('Blocked word detected in columns');
      return false;
    }
    return true;
  }

  private uploadInitiatorFile(data: any, filename: string): Observable<any> {
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = this._env.exportAWS.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const fileContents = JSON.stringify(data, null, 2);
        const s3PutParams = {
          Body: fileContents,
          Bucket: this._bucket,
          Key: `${this._customerId}/${filename}`,
          ContentType: 'text/plain',
        };
        return from(new AWS.S3().putObject(s3PutParams).promise());
      }),
      map((response) => {
        console.log('putResponse', response);
        return response;
      }),
      catchError((error) => {
        console.error('Error uploading file', error);
        return of({ error: error });
      })
    );
  }

  public getAllExports(): Observable<any> {
    return this.authService.getAwsCreds().pipe(
      tap((creds) => {
        AWS.config.update({
          region: this._env.exportAWS.region,
          credentials: new AWS.Credentials(creds),
        });
        console.log('Got creds', creds);
      }),
      switchMap(() => {
        const s3 = new AWS.S3();
        return from(
          s3
            .listObjectsV2({
              Bucket: this._bucket,
              Prefix: this._customerId + '/',
            })
            .promise()
        );
      }),
      switchMap((response) => {
        console.log('listObjectsV2', response);
        return forkJoin(
          this.formatFiles(response.Contents).map((item) => {
            return this.getLogInfo(item);
          })
        );
      }),
      map((data) => {
        console.log('All exports', data);
        return data;
      }),
      catchError((error) => {
        console.error('Error listing objects', error);
        return of({ error: error });
      })
    );
  }

  public formatFiles(array: any): ExportReportLogs[] {
    const out: ExportReportLogs[] = [];
    const filtered = array
      .map((item) => {
        console.log('Item: ', item);
        return { name: item.Key.split('/')[1], size: item.Size };
      })
      .filter(
        (item) =>
          item.name.startsWith('initiate_') ||
          item.name.startsWith('running_') ||
          item.name.startsWith('complete_') ||
          item.name.startsWith('output_') ||
          item.name.startsWith('fail_')
      )
      .forEach((item) => {
        console.log('Item: ', item);
        const split = item.name.split('_');
        const status = split[0];
        const size = status === 'output' ? item.size : null;
        const uuid = split[1];
        const timestamp = split[2].split('.')[0];
        const output = status === 'output';
        const complete = status === 'complete';
        const running = complete || status === 'running';
        const initiate = running || status === 'initiate';

        const findIndex = out.findIndex((item) => item.uuid === uuid);
        if (findIndex > -1) {
          out[findIndex] = {
            uuid: uuid,
            timestamp: parseInt(timestamp),
            output: output || out[findIndex].output,
            complete: complete || out[findIndex].complete,
            running: running || out[findIndex].running,
            initiate: initiate || out[findIndex].initiate,
            outputSize: size || out[findIndex].outputSize,
          };
        } else {
          out.push({
            uuid: uuid,
            timestamp: parseInt(timestamp),
            output: output,
            complete: complete,
            running: running,
            initiate: initiate,
            outputSize: size || null,
          });
        }
      });
    console.log('Out: ', out);
    return out;
  }

  public getLogInfo(Log: ExportReportLogs): Observable<any> {
    let filePrefix = '';
    if (Log.initiate) filePrefix = 'initiate_';
    if (Log.running) filePrefix = 'running_';
    if (Log.complete) filePrefix = 'complete_';
    if (filePrefix === '') return of();
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = this._env.exportAWS.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const s3 = new AWS.S3();
        return from(
          s3
            .getObject({
              Bucket: this._bucket,
              Key: `${this._customerId}/${filePrefix}${Log.uuid}_${Log.timestamp}`,
            })
            .promise()
        );
      }),
      map((response) => {
        // console.log('getLogInfo', response.Body.toString());
        let meta = null;
        let columns = null;
        try {
          meta = JSON.parse(response.Body.toString());
          if (filePrefix === 'initiate_') {
            meta = {
              data: meta,
            };
          }
          columns = meta?.data?.columns.split(',')?.length || null;
          console.log(`Parsed JSON: ${Log.uuid} : `, meta?.data);
        } catch (e) {
          console.error('Error parsing JSON', e);
          meta = { error: 'Error parsing JSON' };
        }

        return {
          uuid: Log.uuid,
          timestamp: Log.timestamp,
          output: Log.output,
          complete: Log.complete,
          running: Log.running,
          initiate: Log.initiate,
          status: this.setStatus(Log),
          columns: columns,
          sqlWhere: meta?.data?.sqlWhere || null,
          reportTitle:
            this.reportLib.getReportTitle(meta?.data?.reportCode) || null,
          initiatorName: meta?.data?.initiatorName || null,
          customName: meta?.data?.customName || null,
          outputSize: Log.outputSize || null,
        };
      }),
      catchError((error) => {
        console.error('Error getting log info', error);
        return of({ error: error });
      })
    );
  }
  public setStatus(data: any): number {
    if (!data.complete && data.running && data.output) return 6; // Took too long but complete
    if (this.possiblyFailed(data)) return 5; // failed
    if (this.moreThan20mins(data)) return 4; // timeout
    if (!data.output && data.complete) return 3; // missing
    if (data.output && data.complete) return 2; // complete
    if (data.running) return 1; // running
    if (data.initiate) return 0; // running
    return -1;
  }

  public downloadFile(uuid: string, timestamp: number): Observable<any> {
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = this._env.exportAWS.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        console.log('Got creds', creds);
        const s3 = new AWS.S3();
        return from(
          s3
            .getObject({
              Bucket: this._bucket,
              Key: `${this._customerId}/output_${uuid}_${timestamp}000`,
              ResponseContentDisposition: 'attachment; filename="output.csv"',
            })
            .promise()
        );
      }),
      map((response) => {
        console.log('downloadFile', response);
        return response.Body.toString();
      }),
      // try with different key
      catchError((error) => {
        console.error('Error downloading file', error);
        return this.authService.getAwsCreds().pipe(
          switchMap((creds) => {
            AWS.config.region = this._env.exportAWS.region;
            AWS.config.credentials = new AWS.Credentials(creds);
            console.log('Got creds', creds);
            const s3 = new AWS.S3();
            return from(
              s3
                .getObject({
                  Bucket: this._bucket,
                  Key: `${this._customerId}/output_${uuid}_${timestamp}.part_00000`,
                  ResponseContentDisposition:
                    'attachment; filename="output.csv"',
                })
                .promise()
            );
          }),
          map((response) => {
            console.log('downloadFile', response);
            return response.Body.toString();
          }),
          catchError((error) => {
            console.error('Error downloading file', error);
            return of({ error: error });
          })
        );
      })
    );
  }

  public exportLink(
    uuid: string,
    timestamp: number,
    reportname?: string
  ): Observable<any> {
    return this.dataExport
      .getSignedReportUrl(
        `${this._customerId}/output_${uuid}_${timestamp}000`,
        reportname
      )
      .pipe(
        catchError((error) => {
          return this.dataExport
            .getSignedReportUrl(
              `${this._customerId}/output_${uuid}_${timestamp}.part_00000`,
              reportname
            )
            .pipe(
              catchError((error) => {
                return of({ error: error });
              })
            );
        })
      );
  }
  private moreThan20mins(log: any): boolean {
    // timestamp is greater than 20 mins and not complete
    if (log.complete) return false;
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - log.timestamp;
    if (timeDiff > 1200000) return true;
    return false;
  }
  private possiblyFailed(log: any): boolean {
    // timestamp is greater than 2 hours and not complete
    if (log.complete) return false;
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - log.timestamp;
    if (timeDiff > 7200000) return true;
    return false;
  }
}
