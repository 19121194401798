import { Injectable } from '@angular/core';
import { DataManager, Query, ReturnOption } from '@syncfusion/ej2-data';
import { ReportDataAdaptor } from './ReportDataAdaptor';
import { environment as env } from '../../../environments/environment';
import { TimezoneService } from '../timezone.service';
import { concat, from, Observable, of } from 'rxjs';
import { shareReplay, map, tap, concatMap } from 'rxjs/operators';
/**
 * Service for handling reporting data from RDS.
 */
/**
 * Service for handling reporting RDS data.
 */
@Injectable({
  providedIn: 'root',
})
export class ReportingRdsService {
  constructor(private timezoneService: TimezoneService) {}
  private dataCache: { [key: string]: Observable<any> } = {};

  /**
   * Creates a DataManager instance for the specified table.
   *
   * @param tableName - The name of the table.
   * @returns A DataManager instance.
   */
  private createDataManager(tableName: string): DataManager {
    return new DataManager({
      url: `${env.api.url}/data/report/v2/${tableName}`,
      adaptor: new ReportDataAdaptor(this.timezoneService),
    });
  }

  /**
   * Fetches data from the data manager using the provided query.
   *
   * @param dataManager - The data manager instance.
   * @param query - The query to execute.
   * @returns An observable that emits the fetched data.
   */
  private fetchData(
    dataManager: DataManager,
    query: Query
  ): Observable<ReturnOption> {
    return from(dataManager.executeQuery(query)).pipe(
      map((data: any) => {
        data.result.result =  data.result.result.map((item: any) => {
          // if has field customerid, then remove it
          if (item.customerid) {
            delete item.customerid;
          }
          return item;
        });
        return data.result;
      }),
      shareReplay(1)
    );
  }

  /**
   * Retrieves a DataManager for the specified table.
   *
   * @param tableName - The name of the table.
   * @returns An Observable that emits the DataManager for the specified table.
   */
  getDataManager(tableName: string): Observable<DataManager> {
    return of(this.createDataManager(tableName));
  }

  /**
   * Executes a query on the specified table and returns the result as an Observable.
   * @param query - The query object containing the queries to execute.
   * @param tableName - The name of the table to execute the query on.
   * @param sqlWhere - Optional SQL WHERE clause to filter the results.
   * @param init - Optional boolean flag indicating whether to initialize the data manager.
   * @returns An Observable that emits the result of the query.
   */
  executeQuery(
    query: Query,
    tableName: string,
    sqlWhere?: string,
    init: boolean = false
  ): Observable<any> {
    const cacheKey = `${tableName}-${JSON.stringify(query.queries)}-${
      sqlWhere || ''
    }`;
    let _datamanager = null;
    const queryWithParams = sqlWhere
      ? query.addParams('sqlWhere', sqlWhere)
      : query;

    if (!this.dataCache[cacheKey] || init) {
      return this.getDataManager(tableName).pipe(
        tap((dataManager) => {
          _datamanager = dataManager;
        }),
        concatMap(() =>
          this.fetchData(_datamanager, queryWithParams).pipe(
            tap((data) => {
              this.dataCache[cacheKey] = of(data);
            })
          )
        )
      );
    } else {
      return this.dataCache[cacheKey];
    }
  }
  /**
   * Retrieves the initial data for a given table.
   *
   * @param tableName - The name of the table.
   * @returns An Observable of type ReturnOption.
   */
  getInitialData(tableName: string): Observable<ReturnOption> {
    const dataManager = this.createDataManager(tableName);
    const query = new Query().take(1).skip(0);
    return this.fetchData(dataManager, query);
  }
}
