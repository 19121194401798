import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Query } from '@syncfusion/ej2-data';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VendorLibraryStateService } from '../../../services/vendorLibrary/vendor-library-state.service';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';

@Component({
  selector: 'app-extractor-detail',
  templateUrl: './extractor-detail.component.html',
  styleUrls: ['./extractor-detail.component.css']
})
export class ExtractorDetailComponent implements OnInit {

  attributePageSettings;
  attributeEditSettings;
  attributeToolbar;
  matcherAttributeKeyEditParams;
  matcherAttributeKeysById;

  flags;
  flagsById;
  flagsEditParams;
  flagsPageSettings;
  flagsEditSettings;
  flagsToolbar;

  domainDefinitionId:number;
  resourceMatcherId:number;

  form = new FormGroup({});
  model = null;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Name',
        placeholder: 'i.e. Screen Resolution',
        required: true,
      }
    },
    {
      key: 'key',
      type: 'input',
      templateOptions: {
        label: 'JSON Key',
        placeholder: 'i.e. screenResolution',
        required: true,
      }
    },
    {
      key: 'value',
      type: 'input',
      templateOptions: {
        label: 'Value',
        placeholder: 'Regex i.e. [&?]sr=(?<res>.+?)[&$]',
        required: true
      }
    },
    {
      key: 'description',
      type: 'textarea',
      templateOptions: {
        label: 'Description',
        placeholder: 'i.e. The screen resolution for the monitor that is rendering the page.',
        required: true,
        rows: 5
      }
    },
    {
      key: 'groupName',
      type: 'input',
      templateOptions: {
        label: 'Group Name',
        placeholder: 'i.e. res',
        required: true,
      }
    },
    {
      key: 'isRequired',
      type: 'checkbox',
      templateOptions: {
        type: 'checkbox',
        label: 'Required',
      },
    },
    {
      key: 'flags',
      type: 'select',
      templateOptions: {
        label: 'Flag(s)',
        multiple: true,
        options: this.vendorLibService.getAllFlags(),
        valueProp: 'id',
        labelProp: 'name',
      }      
    },
    {
      key: 'attributes',
      type: 'repeat',
      templateOptions: {
        addText: 'Add Attribute',
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'matcherAttributeKeyId',
            type: 'select',
            templateOptions: {
              label: 'Key',
              options: this.vendorLibService.getAllMatcherAttributeKeys(),
              valueProp: 'id',
              labelProp: 'name',
            },
          },
          {
            type: 'input',
            key: 'value',
            className: 'col-sm-4',
            templateOptions: {
              label: 'Value',
            },
          },
        ],
      },
    },
  ];

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private siteMapService:SitemapService,
    private spinnerService:SpinnerService,
    private vendorLibService:VendorLibraryService,
    private vendorLibState:VendorLibraryStateService
  ) { 
      this.siteMapService.update(this.route);

      this.attributeEditSettings = {
        showDeleteConfirmDialog: true, 
        allowEditing: false, 
        allowAdding: true, 
        allowDeleting: true, 
        newRowPosition: 'Top'
      };

      this.attributeToolbar = ['Add', 'Delete', 'Cancel'];
      this.attributePageSettings = { 
        currentPage: 1, 
        pageSize: 50, 
        pageCount: 4, 
        pageSizes: [50, 75, 100]
      };

      this.flagsEditSettings = {
        showDeleteConfirmDialog: true, 
        allowEditing: false, 
        allowAdding: true, 
        allowDeleting: true, 
        newRowPosition: 'Top'
      };

      this.flagsToolbar = ['Add', 'Delete', 'Cancel'];
      this.flagsPageSettings = { 
        currentPage: 1, 
        pageSize: 50, 
        pageCount: 4, 
        pageSizes: [50, 75, 100]
      };
      
      this.matcherAttributeKeyEditParams = {
        params:   {
          allowFiltering: true,        
          fields: { text:'name', value:'id' },
          query: new Query(),
          actionComplete: () => false,
          dataSource: this.vendorLibService.matcherAttributeKeys
        }
      };

      this.flagsEditParams = {
        params:   {
          allowFiltering: true,        
          fields: { text:'name', value:'id' },
          query: new Query(),
          actionComplete: () => false          
        }
      };

      this.flagsById = {};
  }

  ngOnInit(): void {
    
    this.domainDefinitionId = this.route.snapshot.params["domainDefId"];
    this.resourceMatcherId = this.route.snapshot.params["resourceMatcherId"];
    this.matcherAttributeKeysById = this.vendorLibService.matcherAttributeKeysById;

    this.vendorLibState.selectedExtractor.subscribe((data)=>{
      this.model = data;
      // Need to do this?
      //this.options.updateInitialValue();
    });

    this.vendorLibService.getAllFlags().subscribe((flags)=>{
      this.flags = flags;
      this.flagsEditParams.params.dataSource = this.flags;
      this.flags.forEach((key)=>{
        this.flagsById[key.id] = key;
      });
      console.log(this.flags);
      console.log(this.flagsById);
      console.log('wtasdfasdfasdf');
    });
  }

  onSubmit(model) {
    console.log(model);
  }

  attributeActionBegin(event) {
    
    if ( event.action == 'add' ) {
      this.spinnerService.toggle(true);
      this.vendorLibService.createResourceMatcherExtractorAttribute(this.domainDefinitionId, this.resourceMatcherId, this.model.id, event.data).subscribe((response)=>{
        console.log(response);
        this.spinnerService.toggle();
      });
    } else if ( event.requestType == 'delete' ){
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteResourceMatcherExtractorAttribute(this.domainDefinitionId, this.resourceMatcherId, this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
      });
    }
  }

  flagsActionBegin(event){
    console.log("flagsActionBegin", event);
    if ( event.action == 'add' ) {
      this.spinnerService.toggle(true);
      this.vendorLibService.createResourceMatcherExtractorFlag(this.domainDefinitionId, this.resourceMatcherId, this.model.id, event.data.id).subscribe((response)=>{
        console.log(response);
        this.spinnerService.toggle();
      });
    } else if ( event.requestType == 'delete' ){
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteResourceMatcherExtractorFlag(this.domainDefinitionId, this.resourceMatcherId, this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
      });
    }
  }

}


//   commandClick(event) {
//     console.log(event);
//     if ( event.commandColumn.type == 'ExtractorDetails'){
//       this.router.navigate(["extractor", event.rowData.id], {relativeTo: this.route});
//     }    
//   }

//   actionBegin(args){
//     console.log(args);
//     if ( args.requestType === 'add') {
//       args.cancel = true;
//       this.router.navigate(["extractor", "new"], {relativeTo: this.route});
//     }
//   }

//   actionComplete(args) {
//     console.log(args);    
//   }

// }
