import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../../../../services/customer/customer.service';
import { EditService, ToolbarService, PageService, GridComponent, BatchChanges } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../../services/spinner/spinner.service';

@Component({
  selector: 'persistentCustomReports',
  templateUrl: './persistent-report-menu-item-editor.component.html',
  styleUrls: ['./persistent-report-menu-item-editor.component.css'],
  providers: [ToolbarService, EditService, PageService]
})
export class PersistentCustomReportMenuItemEditorComponent implements OnInit {

  reports:any;
  public editSettings: Object;
  toolbar:any;
  
  @ViewChild('grid') 
  public grid: GridComponent;

  constructor(protected customerService:CustomerService, public spinnerService:SpinnerService) { 
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true , newRowPosition: 'Bottom', mode:'Batch' };
    this.toolbar = ['Add', 'Delete', 'Update', 'Cancel'];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.spinnerService.toggle(true);
    this.customerService.getScheduledReports().subscribe((reports)=>{
      reports = reports.filter((item)=>{
        return item.name.startsWith("menu:");
      }).map((item)=>{

        let menuText = item.name.replace("menu:", "");
        let templateName = item.templateName;
        let scopes = null;
        if ( item.templateName.indexOf("|") > -1 ){
          let chunks = templateName.split("|");
          templateName = chunks[0];
          scopes = chunks[1];
        }        
             
        let ret = {name: menuText, fileName: templateName, id: item.id, utcOffset: null };
        if ( scopes ){
          ret["roles"] = scopes;
        }
        return ret;
      })
      console.log(reports);
      this.reports = reports;
      this.spinnerService.toggle();
    });
  }

  beforeBatchSave(args){
    console.log(args);

    let added = (args.batchChanges as BatchChanges).addedRecords;
    let changed = (args.batchChanges as BatchChanges).changedRecords;
    let deleted = (args.batchChanges as BatchChanges).deletedRecords;

    let txAdded = added.map((item:any)=>{
      console.log("added", item);
      item.name = item.name.startsWith("menu:") ? item.name : `menu:${item.name}`;
      let templateName = item.fileName;
      if ( item.roles ){
        console.log("roles", item.roles);
        templateName = `${templateName}|${item.roles}`;
      }
      return {id:item.id, name: `${item.name}`, utcOffset: null, templateName: templateName };
    })

    let txChanged = changed.map((item:any)=>{
      console.log("changed", item);
      item.name = item.name.startsWith("menu:") ? item.name : `menu:${item.name}`;
      let templateName = item.fileName;
      if ( item.roles ){
        console.log("roles", item.roles);
        templateName = `${templateName}|${item.roles}`;
      }
      return {id:item.id, name: `${item.name}`, utcOffset: null, templateName: templateName };
    })

    console.log("txAdded", txAdded);
    console.log("txChanged", txChanged);

    this.spinnerService.toggle(true);

    
    this.customerService.updateScheduledReports({addedRecords: txAdded, changedRecords: txChanged, deletedRecords: deleted}).subscribe((results)=>{
      this.spinnerService.toggle();
      this.loadData();
    });
  }

}


// "id": 10,
// "name": "monthlyIncidentSummaryx",
// "utcOffset": "-5:00",
// "templateName": ""
// }