<form [formGroup]="outputFormGroup">
    <div class="form-group row pt-1">
        <div class="col-12 col-sm-8 col-lg-3">
            <ejs-combobox id='vendor' #vendor
            [dataSource]='vendors' 
            [fields]='vendorFields' 
            formControlName="vendor"
            placeholder='Select a vendor'
            allowFiltering='true'
            allowCustom='false'
            filterType='contains'>
            </ejs-combobox>
        </div>                                            
    </div>
    <div *ngIf="showHttpMethod" class="form-group row pt-1">
        <div class="col-12 col-sm-8 col-lg-3">
            <ejs-combobox id='webhookHttpMethod' #webhookHttpMethod
            [dataSource]='webhookHttpMethods' 
            [fields]='fields' 
            formControlName="method"
            placeholder='Select an HTTP method'
            allowFiltering='true'
            allowCustom='false'
            filterType='contains'>
            </ejs-combobox>
        </div>                                            
    </div>
    <div class="form-group row pt-1">
        <div class="col-12 col-sm-8 col-lg-8">
            <input class="form-control form-control-sm"
                id="txtUrl"
                type="text" 
                formControlName="url"
                placeholder="Webhook URL">
        </div>
    </div>
    <div class="form-group row pt-1">
        <div class="col-12 col-sm-8" style="height: 300px;">
            <ejs-richtexteditor #webhookTributeRTE 
                height="275px" 
                [enableResize]="true" 
                editorMode="Markdown"
                id='webhookTributeRTE' 
                (created)='onCreate()' 
                (actionBegin)='actionBeginEvent($event)' 
                [toolbarSettings]="toolbarSettings"
                formControlName="rawData"
                placeholder='Press # to insert and filter properties of the currently selected event.'>
            </ejs-richtexteditor>
        </div>
    </div>                    
</form>