import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  regionsArray = [
    {
      "name": "af-south-1",
      "description": "Africa (Cape Town)",
      "continent": "Africa",
      "city": "Cape Town",
      "latitude": -33.924870,
      "longitude": 18.424055
    },
    {
      "name": "ap-east-1",
      "description": "Asia Pacific (Hong Kong)",
      "continent": "Asia Pacific",
      "city": "Hong Kong",
      "latitude": -33.924870,
      "longitude": 18.424055
    },
    {
      "name": "ap-south-2",
      "description": "Asia Pacific (Hyderabad)",
      "continent": "Asia Pacific",
      "city": "Hyderabad",
      "latitude": 17.385044,
      "longitude": 78.486671
    },
    {
      "name": "ap-southeast-3",
      "description": "Asia Pacific (Jakarta)",
      "continent": "Asia Pacific",
      "city": "Jakarta",
      "latitude": -6.175110,
      "longitude": 106.865036
    },
    {
      "name": "ap-southeast-4",
      "description": "Asia Pacific (Melbourne)",
      "continent": "Asia Pacific",
      "city": "Melbourne",
      "latitude": -37.813629,
      "longitude": 144.963058
    },
    {
      "name": "eu-central-2",
      "description": "Europe (Zurich)",
      "continent": "Europe",
      "city": "Zurich",
      "latitude": 47.376888,
      "longitude": 8.541694
    },
    {
      "name": "eu-south-1",
      "description": "Europe (Milan)",
      "continent": "Europe",
      "city": "Milan",
      "latitude": 45.4641943,
      "longitude": 9.1896346
    },
    {
      "name": "ap-northeast-1",
      "description": "Asia Pacific (Tokyo)",
      "continent": "Asia Pacific",
      "city": "Tokyo",
      "latitude": 35.6840574,
      "longitude": 139.7744912
    },
    {
      "name": "ap-northeast-2",
      "description": "Asia Pacific (Seoul)",
      "continent": "Asia Pacific",
      "city": "Seoul",
      "latitude": 37.5666791,
      "longitude": 126.9782914
    },
    {
      "name": "ap-south-1",
      "description": "Asia Pacific (Mumbai)",
      "continent": "Asia Pacific",
      "city": "Mumbai",
      "latitude": 19.0815772,
      "longitude": 72.8866275
    },
    {
      "name": "ap-southeast-1",
      "description": "Asia Pacific (Singapore)",
      "continent": "Asia Pacific",
      "city": "Singapore",
      "latitude": 1.357107,
      "longitude": 103.8194992
    },
    {
      "name": "ap-southeast-2",
      "description": "Asia Pacific (Sydney)",
      "continent": "Asia Pacific",
      "city": "Sydney",
      "latitude": -33.8698439,
      "longitude": 151.2082848
    },
    {
      "name": "ca-central-1",
      "description": "Canada (Central)",
      "continent": "Canada",
      "city": "Central",
      "latitude": 53.515228116139546,
      "longitude": -113.49187224655762
    },
    {
      "name": "ca-west-1",
      "description": "Canada (Calgary)",
      "continent": "Canada",
      "city": "Calgary",
      "latitude": 51.049999,
      "longitude": -114.066666
    },
    {
      "name": "eu-central-1",
      "description": "Europe (Frankfurt)",
      "continent": "Europe",
      "city": "Frankfurt",
      "latitude": 50.1106444,
      "longitude": 8.6820917
    },
    {
      "name": "eu-north-1",
      "description": "Europe (Stockholm)",
      "continent": "Europe",
      "city": "Stockholm",
      "latitude": 59.3251172,
      "longitude": 18.0710935
    },
    {
      "name": "eu-west-1",
      "description": "Europe (Ireland)",
      "continent": "Europe",
      "city": "Ireland",
      "latitude": 52.865196,
      "longitude": -7.9794599
    },
    {
      "name": "eu-west-2",
      "description": "Europe (London)",
      "continent": "Europe",
      "city": "London",
      "latitude": 51.5073359,
      "longitude": -0.12765
    },
    {
      "name": "eu-west-3",
      "description": "Europe (Paris)",
      "continent": "Europe",
      "city": "Paris",
      "latitude": 48.8588897,
      "longitude": 2.320041
    },
    {
      "name": "me-south-1",
      "description": "Middle East (Bahrain)",
      "continent": "Middle East",
      "city": "Bahrain",
      "latitude": 26.1551249,
      "longitude": 50.5344606
    },
    {
      "name": "sa-east-1",
      "description": "South America (São Paulo)",
      "continent": "South America",
      "city": "São Paulo",
      "latitude": -23.5506507,
      "longitude": -46.6333824
    },
    {
      "name": "us-east-1",
      "description": "US East (N. Virginia)",
      "continent": "US East",
      "city": "N. Virginia",
      "latitude": 38.01741,
      "longitude": -77.02663
    },
    {
      "name": "us-east-2",
      "description": "US East (Ohio)",
      "continent": "US East",
      "city": "Ohio",
      "latitude": 40.2253569,
      "longitude": -82.6881395
    },
    {
      "name": "us-west-1",
      "description": "US West (N. California)",
      "continent": "US West",
      "city": "N. California",
      "latitude": 36.7014631,
      "longitude": -118.755997
    },
    {
      "name": "us-west-2",
      "description": "US West (Oregon)",
      "continent": "US West",
      "city": "Oregon",
      "latitude": 43.9792797,
      "longitude": -120.737257
    },    
    {
      "name": "eu-south-2",
      "description": "Europe (Spain)",
      "continent": "Europe",
      "city": "Spain",
      "latitude": 39.3260685,
      "longitude": -4.8379791
    },
    {
      "name": "me-central-1",
      "description": "Middle East (UAE)",
      "continent": "Middle East",
      "city": "UAE",
      "latitude": 24.0002488,
      "longitude": 53.9994829
    },    
    {
      "name": "ap-northeast-3",
      "description": "Asia Pacific (Osaka)",
      "continent": "Asia Pacific",
      "city": "Osaka",
      "latitude": 34.661629,
      "longitude": 135.4999268
    },
    {
      "name": "il-central-1",
      "description": "Israel (Tel Aviv)",
      "continent": "Israel",
      "city": "Tel Aviv",
      "latitude": 32.0852997,
      "longitude": 34.7818064
    }
  ];

  regionsDict = {};


  constructor() { 
    console.log("Regions");
    this.regionsArray.forEach((r)=>{
      // console.log(r.name);
      this.regionsDict[r.name] = r.description;
    });
    console.log(this.regionsDict);
  }

  getDescription(name:string) {
    // console.log(name);
    let ret = this.regionsDict[name];
    // console.log(ret);
    if ( !ret ) {
      ret = this.regionsDict['us-east-1'];
    }
    return ret;
  }
}