import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageRunnerService } from '../../../../services/data-export/page-runner.service';
import { PageRunnerScript } from '../../../../models/PageRunnerScript';
import { VaultNotificationService } from '../../../../services/notifications/vault-notification.service';
declare var $:any;

@Component({
  selector: 'app-script-details',
  templateUrl: './script-details.component.html',
  styleUrls: ['./script-details.component.css']
})
export class ScriptDetailsComponent implements OnInit {

  pageRunnerScriptForm:FormGroup;
  btnText:string;
  closeResult: string;
  isExisting:boolean;
  script:any;
  scriptId:string;
  scriptButtonText:string;
  selectedFile:any;
  btnEnabled:boolean;
  model:PageRunnerScript;
  headerText:string;
  headerSubText:string;
  codeMirrorOptions:any;

  @ViewChild('scriptFile') 
  scriptFile:ElementRef;

  constructor(private route: ActivatedRoute, 
    private fb:FormBuilder,
    private spinnerService:SpinnerService,
    private pageRunnerService:PageRunnerService,
    private router:Router,
    private notificationService:VaultNotificationService,
    private modalService: NgbModal) {

      this.isExisting = false;
      this.spinnerService.toggle(false);
      this.scriptButtonText = 'Browse files...';
      this.btnText = "Update";

      this.codeMirrorOptions = {
        lineNumbers: true,
        mode: 'javascript',
        tabSize: 2,
        readOnly: false,
        gutters: ["CodeMirror-linenumbers", "analysis"]      
      };
  }

  ngOnInit() {
    
    this.scriptId = this.route.snapshot.params["scriptId"];
    this.model = this.pageRunnerService.selectedScript.value;
    this.selectedFile = null;

    console.log("MODEL");
    console.log(this.model);
    
    if (this.scriptId == "new" ) {
      this.isExisting = false;
      console.log("New script...");
      var newScript = new PageRunnerScript(-1, "Enter a name...", "", "", false);
      this.pageRunnerService.selectedScript.next(newScript);
      this.model = this.pageRunnerService.selectedScript.value;
      this.btnText = "Create";
      this.btnEnabled = true;
      this.headerText = "New Automated User";
      this.headerSubText = "Specify the properties of the new automated user";
    } else {
      this.isExisting = true;
      this.btnText = "Update";
      this.btnEnabled = false;
      this.headerText = "Update Automated User";
      this.headerSubText = "Update the properties for the automated user " + this.model.scriptFilePath;
    }

    this.pageRunnerScriptForm = this.fb.group({
      name: this.model.scriptFilePath,
      enabled: this.model.enabled,
      cronSchedule: this.model.cronSchedule,
      fileContent: this.model.fileContent
    });

    // this.pageRunnerScriptForm.valueChanges.subscribe(data => {
    //   console.log('Form changes', data);
    // })
  }

  onFileChange(event){
    // console.log(event);
    this.selectedFile = this.scriptFile.nativeElement.files[0];
    console.log(this.selectedFile);
    this.scriptButtonText = this.selectedFile.name;
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  onSubmit(){
    
    let objToUpdate = this.pageRunnerScriptForm.value;
    objToUpdate.id = this.scriptId;
    
    console.log(objToUpdate);
    

    if (objToUpdate.id == "new"){
      
      if (objToUpdate.cronSchedule == "" ){
        this.notificationService.error('Uh oh!', "A schedule must be specified");
        return;
      }

      // if ( this.selectedFile == null ){
      //   this.notificationService.error('Uh oh!', "Please select a script file.");
      //   return;
      // }
      
      this.spinnerService.toggle(true);

      this.pageRunnerService.createScript(objToUpdate, this.selectedFile)
        .subscribe(
          this.handleCreateUpdateSuccess("Automated user was created successfully!"), 
          this.handleCreateUpdateError          
        );
    } else {

      this.spinnerService.toggle(true);

      this.pageRunnerService.updateScript(objToUpdate, this.selectedFile)
        .subscribe(
          this.handleCreateUpdateSuccess("Automated user was successfully updated!"), 
          this.handleCreateUpdateError
        );
    }
  }

  handleCreateUpdateSuccess(message:string):(response:any)=>void {
    return (response:any)=>{
      console.log("handleCreateUpdateSuccess");
      var newScript = new PageRunnerScript(-1, "Enter a name...", "", "", false);
      this.pageRunnerService.selectedScript.next(newScript);

      this.pageRunnerScriptForm = this.fb.group({
        name: this.pageRunnerService.selectedScript.value.scriptFilePath,
        enabled: this.pageRunnerService.selectedScript.value.enabled,
        cronSchedule: this.pageRunnerService.selectedScript.value.cronSchedule,
        fileContent: ""
      });
      
      this.scriptFile.nativeElement.value = "";
      this.scriptButtonText = "Browse files...";

      this.spinnerService.toggle(false);

      if ( response.error ){
        this.notificationService.error('Uh oh!', response.error);
      } else {
        this.notificationService.success('Success!', message);
      }
    }
  }

  handleCreateUpdateError(message):(error:any)=>void{
    return (error)=>{
      console.log("handleCreateUpdateError");
      console.log(error);
      this.notificationService.error('Uh oh!', error);
      this.spinnerService.toggle(false);
    }
  }

  onDeleteClick(){
    $('#mod-confirm-delete').modal('hide');
    this.spinnerService.toggle(true);
    this.pageRunnerService.deleteScript(this.scriptId).subscribe((resposne)=>{
      this.spinnerService.toggle(false);
      this.notificationService.success('Success!', "Automated user was deleted successfully.");
    },
    (error)=>{
      this.notificationService.error('Uh oh!', error);
      this.spinnerService.toggle(false);
    })
  }

  cancelEdit(){
    this.router.navigate(["/automatedUsers"]);
  }
}