<!-- <div class="main-content container-fluid"> -->
  <div class="row">    
    <div class="col-sm-12">
      <div class="card card-table">        
        <p>{{ totalEvents }}</p>
        <div class="card-body">
          <div class="row">            
            <div class="col-sm-12">              
              <ejs-grid #eventsGrid 
                        height='500'
                        [allowPaging]="true" 
                        [allowSorting]="true" 
                        [sortSettings]="sortSettings"
                        allowFiltering="true" 
                        [pageSettings]="pageSettings"
                        [selectionSettings]="selectionSettings" 
                        [allowSelection]="true" 
                        [enableHover]="false"                         
                        [filterSettings]="filterSettings"
                        allowResizing='true'
                        (rowSelected)="onEventSelected($event)"
                        (rowDeselected)="onRowDeselected($event)"
                        [searchSettings]="searchSettings"
                        (actionBegin)="actionBegin($event)"
                        (dataBound)="dataBound($event)"
                        [contextMenuItems]="contextMenuItems"
                        (contextMenuClick)="contextMenuClick($event)"
                        (contextMenuOpen)="contextMenuOpen($event)"
                        >
                <e-columns>
                    <e-column field='severityId' headerText='' width='45'>
                      <ng-template #template let-data>
                        <span class="{{severityToDisplayString(data.severityId)}}">&nbsp;</span>
                      </ng-template>
                    </e-column>
                    <e-column field='name' headerText='Name' width='150'></e-column>
                    <e-column field='vendorName' headerText='Vendor' width='150'></e-column>
                    <e-column field='typeName' headerText='Type' width='150'></e-column>
                    <e-column field='codeFileUrl' headerText='Type Properties' width="750"></e-column>
                    <e-column field='data.responseSize' headerText='Response Size (kb)' format="N" width="175"></e-column>
                    <e-column field='detectedDateTime' headerText='Date Discovered' type='datetime' width='200' [format]="dateFormat"></e-column>
                    <e-column field='data.fileContentsHash' headerText='File Content Hash' width='600'></e-column>
                </e-columns>                
              </ejs-grid>
              <h4> Selected: {{selectedCount ? selectedCount : 0}}</h4>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3">
      <div class="page-head-title">
        <h2>Incident Properties</h2>
      </div>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="incidentForm" (ngSubmit)="createIncident(incidentForm)">
            <div class="form-group row pt-1">
              <label class="col-12">Name</label>
              <div class="row col-12">
                <div class="col-10">
                  <select class="form-control" formControlName="name" (change)="onNameChange();">
                    <option *ngFor="let msg of messages;" [ngValue]="msg.message">{{msg.message}}</option>
                  </select>
                </div>
                <div class="col-2">
                  <button type="button" class="btn btn-rounded btn-space btn-primary" (click)="refreshNames();"><i style="color: white;" class="icon icon-left zmdi zmdi-refresh"></i></button>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 ">Severity</label>
              <div class="col-12">
                <select class="form-control" formControlName="severity">
                  <option *ngFor="let item of severities" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 ">Status</label>
              <div class="col-12">
                <select class="form-control" formControlName="status">
                  <option *ngFor="let item of statuses" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 ">Linked Events</label>
              <div class="col-12">
                <input class="form-control" type="text" placeholder="Comma separated IDs..." formControlName="linkedEvents">
              </div>
            </div>
            <div></div>
            <div class="form-group row pt-1">
              <label class="col-12 ">Initial Message</label>
              <div class="col-12">
                <textarea class="form-control" formControlName="message"></textarea>
              </div>
            </div>                            
            <div style="text-align: center">
              <button type="submit" class="btn btn-rounded btn-space btn-success">Create Incident</button>
            </div>
          </form>
        </div>
      </div>
    </div>        
    <div class="col-sm-9">
      <div class="page-head-title">
        <h2>Event Object(s)</h2>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-container">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item"><a class="nav-link" [class.active]="codeFileTabActive" href="#codeFile" data-toggle="tab" role="tab">Code File</a></li>              
              <li class="nav-item"><a class="nav-link" [class.active]="regexTabActive" href="#regex" data-toggle="tab" role="tab">Regex</a></li>
              <li class="nav-item"><a class="nav-link" [class.active]="analysisTabActive" href="#analysis" data-toggle="tab" role="tab">Analysis</a></li>
              <li *ngIf="analysisTabActive == true" class="nav-item"><a class="nav-link" (click)="nextAnalysis('start');"><span class="icon zmdi zmdi-fast-rewind"></span></a></li>
              <li *ngIf="analysisTabActive == true" class="nav-item"><a class="nav-link" (click)="nextAnalysis(-1);"><span class="icon zmdi zmdi-arrow-left"></span></a></li>
              <li *ngIf="analysisTabActive == true" class="nav-item" style="margin-top: 10px;"><span>{{analysisPosition >= 0 && analysisPosition < this.analysisInfo.length ? (analysisPosition + 1) + "/" + this.analysisInfo.length : "" }}</span></li>
              <li *ngIf="analysisTabActive == true" class="nav-item"><a class="nav-link" (click)="nextAnalysis(1);"><span class="icon zmdi zmdi-arrow-right"></span></a></li>
              <li *ngIf="analysisTabActive == true" class="nav-item"><a class="nav-link" (click)="nextAnalysis('end');"><span class="icon zmdi zmdi-fast-forward"></span></a></li>
            </ul>
          </div>
          <div class="tab-content">
            <div class="tab-pane" [class.active]="codeFileTabActive" id="codeFile" role="tabpanel">
              <h4>Event ID: {{selectedEvent ? selectedEvent.id : ""}}&nbsp;<span ngxClipboard (cbOnSuccess)="onCopied('Event ID')" [cbContent]="selectedEvent?.id" class="icon zmdi zmdi-copy" style="cursor: pointer;"></span></h4>
              <h4>File: <a href="{{codeFile.link}}" target="_blank">{{codeFile.name}}</a>&nbsp;<span ngxClipboard (cbOnSuccess)="onCopied('File name')" [cbContent]="codeFile?.name" class="icon zmdi zmdi-copy" style="cursor: pointer;"></span></h4>
              <h4>File Hash: {{codeFile.hash}}&nbsp;<span ngxClipboard (cbOnSuccess)="onCopied('File hash')" [cbContent]="codeFile?.hash" class="icon zmdi zmdi-copy" style="cursor: pointer;"></span></h4>
              <h4>Vendor: <a href="{{codeFile.vendorLink}}" target="_blank">{{codeFile.vendorName}}</a></h4>
              <h4>Analysis Features: {{analysisFeatures}}</h4>
              <div *ngIf="!isNewCodeFileEvent(selectedEvent)" id="mergeViewContainer">
                <div>
                  <table style="width: 100%">
                    <thead>
                      <tr> 
                          <th><a class="nav-link" target="_" [routerLink]="['/codeDiff']" [queryParams]="{h1:hash1, h2:hash2}"><span class="icon zmdi zmdi-fullscreen"></span></a></th>
                          <th style="width:50%; padding-left: 3%;">Selected~{{historyIndex+1}}&nbsp;&nbsp;<a style="cursor: pointer;" (click)="next(-1);"><span class="icon zmdi zmdi-arrow-left"></span></a>&nbsp;&nbsp;&nbsp;&nbsp;<a style="cursor: pointer;" (click)="next(1);"><span class="icon zmdi zmdi-arrow-right"></span></a></th>
                          <th style="width:50%; padding-left: 3%;">Selected</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <!-- <div id="mergeViewDiv" style="height: 500px;"></div> -->
                <div id="prettyDiffDiv" [innerHtml]="outputDiffHtml"></div>
              </div>
              <div *ngIf="isNewCodeFileEvent(selectedEvent)" style="width: 100%; height: 475px;" >
                <ngx-codemirror #newCodeFileViewer [(ngModel)]="codeFileContent" [options]="codeMirrorOptions"></ngx-codemirror>
              </div>
            </div>
            <div class="tab-pane" [class.active]="domainTabActive" id="domain" role="tabpanel">
              <table id="domainTable" class="table table-striped table-bordered"></table>
            </div>
            <div class="tab-pane" [class.active]="regexTabActive" id="regex" role="tabpanel">
              <form [formGroup]="regexForm" (ngSubmit)="testRegex()">                
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Vendor:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <select class="form-control vendors" formControlName="vendor">
                      <option value=""></option>
                      <option *ngFor="let vendor of vendors;" [ngValue]="vendor.name">{{vendor.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Rule:</label>
                  <div class="row col-8">
                    <div class="col-6">
                      <select class="form-control rules" formControlName="rule">
                        <option value=""></option>
                        <option *ngFor="let rule of rules;" [ngValue]="rule.id">{{rule.name}} ({{rule.id}})</option>
                      </select>
                    </div>
                    <div class="col-2">
                      <button type="button" class="btn btn-rounded btn-space btn-primary" (click)="refreshRules();">Refresh</button>
                    </div>
                  </div>
                </div>
                <div style="text-align: center">
                  <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!regexForm.valid">Test</button>
                </div>
              </form>  
            </div>
            <div class="tab-pane" [class.active]="analysisTabActive" id="analysis" role="tabpanel">
              <div></div>
              <ngx-codemirror #analysisViewer [(ngModel)]="codeFileContent" [options]="codeMirrorOptions"></ngx-codemirror>
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->