

import { Component, OnInit } from '@angular/core';
import { ReportExportService } from '../../services/report-export/report-export.service';
import { Observable } from 'rxjs';
import { SpinnerService } from '../../services/spinner/spinner.service';
@Component({
  selector: 'app-export-center',
  templateUrl: './export-center.component.html',
  styleUrls: ['./export-center.component.css'],
})
export class ExportCenterComponent implements OnInit {

  public dataBeforeRefresh: any[] = [];
  public statusTypes =
  [
    {color: 'blue', text: 'Queued', icon: 'zmdi-caret-right'},
    {color: 'orange', text: 'Processing...', icon: 'zmdi-spinner'}, // processing
    {color: 'green', text: 'Completed', icon: 'zmdi-check-all'}, // completed within time
    {color: 'red', text: 'Missing', icon: 'zmdi-alert-circle-o'}, // missing
    {color: 'red', text: 'Taking Longer than expected', icon: 'zmdi-timer'}, // taking longer than expected
    {color: 'red', text: 'Failed', icon: 'zmdi-close-circle-o'}, // failed super late
    {color: 'green', text: 'Completed', icon: 'zmdi-check-all'}, // completed super late
  ]
  constructor(private reportExportService: ReportExportService,
    private spinnerService: SpinnerService
  ) {}
  public allReport: Observable<any>;
  ngOnInit(): void {
    this.loadData();
  }
  public onRefreshClick(): void {
    this.loadData();
  }

  private loadData(): void {
    this.allReport = this.reportExportService.getAllExports()
    this.allReport.subscribe(
      (data) => {
        this.dataBeforeRefresh = data;
      },
      () => {
        console.log('Error');
      },
      () => {}
    );
  }

  public onDownloadClick(_data: any): void {
    this.spinnerService.toggle(true);
    const filename = `${_data.customName}_` + new Date().toISOString() + '.csv' || 'report.csv';
    this.reportExportService.exportLink(_data.uuid, _data.timestamp,filename).subscribe(
      (data) => {
        const a = document.createElement('a');
        a.href = data;
        a.download = `${_data.customName}_` + new Date().toISOString() + '.csv' || 'report.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(data);
        this.spinnerService.toggle(false);
      },
      () => {
        console.log('Error');
        this.spinnerService.toggle(false);
      },
      () => {
        this.spinnerService.toggle(false);
      }
    );
    // this.reportExportService.downloadFile(_data.uuid, _data.timestamp).subscribe(
    //   (data) => {
    //     // Convert data to string if it's an object
    //     const csvData = typeof data === 'string' ? data : JSON.stringify(data);

    //     // Create a Blob with the correct MIME type
    //     const blob = new Blob([csvData], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `${_data.customName}_` + new Date().toISOString() + '.csv' || 'report.csv';
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //   },
    //   () => {
    //     console.log('Error');
    //   },
    //   () => {}
    // );
  }

  public sortSettings: any = { columns: [{ field: 'timestamp', direction: 'Descending' }] };


}
