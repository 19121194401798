<div class="row">
  <div class="col-sm-12">
    <div class="card card-table">
      <div class="card-header">
        <div class="tools">
          <button type="button" class="btn btn-rounded btn-space btn-primary" (click)="createUser();"><i style="color: white;" class="icon icon-left zmdi zmdi-accounts-add"></i> Add New User</button>          
        </div>
        <span class="title">Users</span>&nbsp;
        <span class="card-subtitle">View and edit the users in your account.</span>
      </div>
      <div class="card-body">
        <ejs-grid #usersGrid
          [dataSource]="users"
          locale='en-US' 
          [allowPaging]="true" 
          [pageSettings]="pageSettings" 
          [allowSorting]="true" 
          [sortSettings]="sortSettings"
          [allowSelection]="false"
          [enableHover]="false"
          [toolbar]="toolbar"
          [allowResizing]="true">
        <e-columns>          
          <e-column field='user_metadata.lastName' headerText="Name">
            <ng-template #template let-data>
              <a [routerLink] = "['/admin/users/', data.user_id.replace('auth0|','')]">{{data.user_metadata.lastName}}, {{data.user_metadata.firstName}}</a>
            </ng-template>
          </e-column>
          <e-column field='email' headerText='Email/User ID'></e-column>
          <e-column field='created_at' headerText='Date Created' type='datetime'>
            <ng-template #template let-data>{{ timezoneService.format(data.created_at, true) }}</ng-template>
          </e-column>
          <e-column field='logins_count' headerText='Login Count'></e-column>
          <e-column field='last_login' headerText='Latest Login' type='datetime'>
            <ng-template #template let-data>{{ timezoneService.format(data.last_login, true) }}</ng-template>
          </e-column>
          <e-column field='roles' headerText='Role(s)'>
            <ng-template #template let-data>{{ rolesArrayToString(data.roles) }}</ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
      </div>
    </div>
  </div>
</div>