import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { Papa } from 'ngx-papaparse';
import { CustomReportService } from '../../../services/customReportService/custom-report-service.service';
import {
  GridComponent,
  ColumnModel,
  FilterSettingsModel,
  GroupSettingsModel,
  PageSettingsModel,
  ResizeSettingsModel,
  PredicateModel,
} from '@syncfusion/ej2-angular-grids';
import { enableVersionBasedPersistence } from '@syncfusion/ej2-base';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { ActivatedRoute } from '@angular/router';
import { ReportingV2Service } from '../../../services/reporting-v2/reporting-v2.service';
import { AuthService } from '../../../services/auth/auth.service';
import {
  TooltipComponent,
  TooltipEventArgs,
} from '@syncfusion/ej2-angular-popups';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { ReportDataAdaptor } from './ReportDataAdaptor';
import { environment as env } from '../../../../environments/environment';
import { TimezoneService } from '../../../services/timezone.service';
import { ProgressBarService } from '../../../services/progressBarService/progress-bar.service';
import { QueryBuilderComponent } from '@syncfusion/ej2-angular-querybuilder';
import {
  MenuComponent,
  MenuItem,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
enableVersionBasedPersistence(true);

declare var $: any;

@Component({
  selector: 'app-raw-report-viewer',
  templateUrl: './raw-report-viewer.component.html',
  styleUrls: ['./raw-report-viewer.component.css'],
})
export class RawReportViewerComponent implements OnInit, AfterViewInit {
  @ViewChild('viewMenu') public viewMenu: MenuComponent;
  @ViewChild('grid') public grid: GridComponent;
  @ViewChild('detailGrid') public detailGrid: GridComponent;
  @ViewChild('filterQueryBuilder') public queryBuilder: QueryBuilderComponent;
  @ViewChild('newStateName') public newStateName: any;
  @ViewChild('newLocalStateName') public newLocalStateName: any;

  private reportCode: string;
  public isCustomReport: boolean = false;
  public gridDataManager: DataManager;
  private gridData: any;
  public loadingLargeData: boolean = false;
  public progressValue: number = 0;

  public allReportsList: any;
  public allReportsDropdownFields: any = { text: 'timestamp', value: 'name' };
  public selectedReport: any = {};

  public reportTitle: string;

  private filterParams: any;

  public s3States: any = [];
  public s3StatesDropdownFields: any = { text: 'name', value: 'name' };
  public selectedState: any = {};
  public reportIcon: string = 'report';
  public dictionary: any = {};
  private useRDS: boolean = true;
  private rdsReportMapping: any = {};
  public formattedData = [];
  public columns: ColumnModel[];
  public allowScrolling = true;
  public scrollSettings = { width: 'auto', height: 'auto' };
  private maxColumnWidth = 600;
  public maxColumnHeight = 50;
  public toolbarOptions;
  public defaultColumnSettings: Partial<ColumnModel> = {
    maxWidth: this.maxColumnWidth,
    minWidth: 100,
    width: 250,
    clipMode: 'EllipsisWithTooltip',
  };
  constructor(
    public spinnerService: SpinnerService,
    public customReportService: CustomReportService,
    public papa: Papa,
    private notificationService: VaultNotificationService,
    private activatedRoute: ActivatedRoute,
    private reportingV2Service: ReportingV2Service,
    public authService: AuthService,
    private timezoneService: TimezoneService,
    private progressBarService: ProgressBarService
  ) {
    // const customerId = this.authService.customerId();
    this.progressBarService.setStopHandler(this.stopDownload.bind(this));
    this.reportCode = this.getCodeFromRoute();
    this.reportAdaptor = new ReportDataAdaptor(this.timezoneService);
    this.reportAdaptorQuery = new Query();
    if (this.authService.isVaultUser()) {
      this.viewMenuItems = [...this.viewMenuItems, ...this.vaultOnlyMenuItems];
    }
    if (this.isCustomReport) {
      // Check Query Params for value of CRKV
      const reportKey = this.getQueryParamsFromURL('CRKV');
      this.reportingV2Service
        .getReportLibraryConfig()
        .subscribe(async (_data: any) => {
          const data = JSON.parse(_data);
          const customReportDetails = data.find((report) => {
            // Check for Key In Config
            return report.reportCode === reportKey;
          });
          if (customReportDetails) {
            // If Code Found, Update Icon and Title
            this.reportIcon = customReportDetails.icon;
            this.reportTitle = customReportDetails.title;
          }
          await this.reportingV2Service
            .getAvailableReports()
            .toPromise()
            .then((data: any) => {
              this.allReportsList = data;
              //console.log('All Reports: ', this.allReportsList);
              this.selectedReport = this.allReportsList.find((report) => {
                // Check for Key In S3 and Update Selected Report
                return report.name === reportKey + '.csv';
              });
              //console.log('Selected: ', this.selectedReport);
              this.checkRDSandLoadReport(
                // Check RDS Option and Load Report
                this.selectedReport.name,
                this.selectedReport.size
              );
            })
            .catch((error) => {
              this.stopOnError(
                'Error Loading Report',
                'Something Went Wrong in Loading Report'
              );
              //console.log('Error Loading Report: ', error);
            });
        });
    } else {
      const title = this.reportingV2Service
        .getDefaultConfig()
        .find((config) => {
          return config.reportCode === this.reportCode;
        }).title;
      this.reportingV2Service
        .getAvailableReportsByCode(this.reportCode)
        .subscribe(
          // Get Reports By Code
          (reports: any) => {
            this.allReportsList = reports;
            const referencedReport = this.getQueryParamsFromURL('RRV');
            //console.log('Referenced Report: ', referencedReport);
            if (referencedReport) {
              const filteredListOfRefReports = this.allReportsList.filter(
                (report) => {
                  //console.log('REPORT NAMES IN RRV LIST: ', report);
                  return (
                    report.name === referencedReport ||
                    report.name === referencedReport + '.gz'
                  );
                }
              );
              //console.log('Filtered List: ', filteredListOfRefReports);
              if (filteredListOfRefReports.length > 0) {
                this.selectedReport = filteredListOfRefReports[0];
                //console.log('RRV Report: ', this.selectedReport);
              } else {
                this.selectedReport = this.allReportsList[0];
              }
            } else {
              this.selectedReport = this.allReportsList[0];
            }
            //console.log('Selected: ', this.selectedReport);
            this.checkRDSandLoadReport(
              this.selectedReport.name,
              this.selectedReport.size
            );
          },
          (error) => {
            this.stopOnError(
              'Error Loading Report',
              'Something Went Wrong in Loading Report'
            );
            //console.log('Error Loading Report: ', error);
          }
        );
    }
  }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    this.initGridSettings();
  }
  ngAfterViewInit(): void {
    if (this.grid) this.updateGrid();
  }
  public reportAdaptor: ReportDataAdaptor;
  public reportAdaptorQuery: any;
  updateDataManagerUrl(tableName: string) {
    if (!this.reportDataManager) {
      this.reportDataManager = new DataManager(
        {
          url: `${env.api.url}/data/report/${tableName}`,
          adaptor: this.reportAdaptor,
        },
        this.reportAdaptorQuery
      );
    } else {
      this.reportDataManager.dataSource.url = `${env.api.url}/data/report/${tableName}`;
    }
  }
  // ===========================================================================
  // Filtering Switch
  // ===========================================================================
  public isComplexFilter: boolean = false;

  // ===========================================================================
  // Query Builder
  // ===========================================================================
  onQueryChange($event): void {
    console.log('Query Change Event: ', $event);
    const predicate: Predicate = this.queryBuilder.getPredicate(
      this.queryBuilder.rule
    );
    if (predicate) {
      this.reportDataManager
        .executeQuery(new Query().where(predicate))
        .then((e: any) => {
          this.grid.dataSource = e.result;
          this.grid.refresh();
        });
    }
  }
  public queryReload() {
    this.queryBuilder.trigger('change');
  }
  // ===========================================================================
  // Type Check and Convert Column Type
  // ===========================================================================
  private createColumns(_data) {
    this.columns = Object.keys(_data[0]).map((key) => {
      let dynamicType = 'string'
      if(this.dictionary){
        const fieldEntry = this.dictionary.find((entry) => {
          return entry.name === key;
        });
        if (fieldEntry && fieldEntry.type && fieldEntry.type !== '') {
          dynamicType = fieldEntry.type;
        }
      }
      return {
        headerText: key,
        field: key,
        type: dynamicType,
        maxWidth: this.maxColumnWidth,
        ...this.defaultColumnSettings,
      } as ColumnModel;
    });
    if(this.queryBuilder) this.queryBuilder.columns = this.columns.map((column) => {
      return {
        field: column.field,
        label: column.headerText,
        type: column.type,
      };
    })
    //console.log(this.columns);
    return this.columns;
  }
  public selectedRow: any;
  public selectedData: any;
  private initialState: any;
  private currentState: any;
  onRowSelected($event) {
    const fields = Object.keys($event.data);
    const data = fields
      .map((field) => {
        let isJSON = false;
        let parsedData = null;
        try {
          parsedData = JSON.parse($event.data[field]);
          isJSON = true;
        } catch (e) {
          isJSON = false;
        }

        return {
          field: field,
          value: isJSON ? parsedData : $event.data[field],
          isJSON: isJSON,
        };
      })
      .filter((column) => {
        if (this.currentState?.columns.length > 0) {
          return this.currentState.columns.some((stateColumn) => {
            return stateColumn.field === column.field;
          });
        }
        return true;
      });
    //console.log('ROW INFO BASED ON STATE: ', data);
    this.selectedData = data;
  }

  public stateList = [];
  public stateListDropdownFields = {
    text: 'name',
    value: 'name',
    groupBy: 'cat',
  };
  private defaultColumns: any;
  private resetToDefault() {
    if (this.initialState) {
      this.grid.setProperties(this.initialState);
      //console.log('INITIAL STATE WHILE RESET: ', this.initialState);
      const tempState = JSON.parse(this.initialState);
      if (tempState.columns.length > 0) {
        tempState.columns.map((column) => this.grid.showColumns(column.field));
      }
      this.grid.refreshColumns();
    } else {
      this.notificationService.error(
        'Error Loading Initial State',
        'Initial State Not Found'
      );
    }
  }
  public initDefaultColumns() {
    setTimeout(() => {
      //console.log('INITIAL STATE: ', this.grid.getPersistData());
      this.initialState = this.grid.getPersistData();
      if (this.initialState?.columns.length > 0) {
        // this.notificationService.success(
        //   'Setting Initial State',
        //   'Initial State Set'
        // );
      } else {
        this.initDefaultColumns();
      }
    }, 500);
  }
  private stopOnError(title, message) {
    this.notificationService.error(title, message);
    this.spinnerService.toggle(false);
  }

  private getFilterQueryParams() {
    const allParams = {};
    Object.keys(this.getQueryParamsFromURL()).forEach((key) => {
      if (key === 'RRV' || key === 'CRKV') {
        return;
      }
      allParams[key] = this.getQueryParamsFromURL(key);
    });
    //console.log('All Params: ', allParams);
    this.filterParams = allParams;
  }

  private getQueryParamsFromURL(key?: string) {
    if (key) {
      return this.activatedRoute.snapshot.queryParams[key];
    }
    return this.activatedRoute.snapshot.queryParams;
  }
  private getCodeFromRoute() {
    const code = this.activatedRoute.snapshot.params['reportCode'];
    if (code && code !== 'CSTM' && this.validateCode(code)) {
      this.isCustomReport = false;
      return code;
    } else if (code && code === 'CSTM') {
      this.isCustomReport = true;
      return code;
    }
    this.stopOnError(
      'Error Loading Report',
      'Something Went Wrong While Getting Report Code'
    );
    return null;
  }

  private validateCode(code: string) {
    return this.reportingV2Service.getDefaultConfig().some((config) => {
      return config.reportCode === code;
    });
  }

  private initialChunkLimit = 1000;
  public reportDataManager: DataManager;
  // ===========================================================================
  // Check RDS and Load Report From RDS or S3
  // ===========================================================================
  private async checkRDSandLoadReport(reportName: string, reportSize?: number) {
    this.loadAllStates();
    if (!this.isCustomReport) {
      this.reportTitle = this.reportingV2Service.getReportNameFromCode(
        this.reportCode
      );
    }
    await this.reportingV2Service
      .getRDSMappingFromS3()
      .toPromise()
      .then((data: any) => {
        this.rdsReportMapping = JSON.parse(data) || {};
        //console.log('RDS Mapping: ', this.rdsReportMapping);
        //console.log('Report Name: ', reportName);
        this.useRDS = this.rdsReportMapping[reportName] ? true : false;
      })
      .catch((error) => {
        this.notificationService.error(
          'Error Loading RDS Mapping',
          'Error Loading RDS Mapping'
        );
        this.useRDS = false;
        this.rdsReportMapping = {};
      });
    if (this.useRDS) {
      this.updateDataManagerUrl(this.rdsReportMapping[reportName]);
      // this.notificationService.info('Report in RDS', 'Loading Data from RDS');
      this.loadReportToFromRDS(reportName, reportSize);
      this.spinnerService.toggle(false);
      return;
    }
    this.enableVirtualization = false;
    this.allowPaging = true;
    this.gridData = [];
    this.grid.columns = [];
    this.loadReportToGrid(reportName, reportSize);
  }
  // ===========================================================================
  // Load Report information from RDS
  // ===========================================================================
  private loadReportToFromRDS(reportName: string, reportSize?: number) {
    this.spinnerService.toggle(true, 'Fetching Report From DB...');
    this.reportIcon = this.isCustomReport
      ? 'report'
      : this.reportingV2Service.getIcon(this.reportCode);
    this.gridData = this.reportDataManager;
    this.queryBuilder.dataSource = this.gridData;
    this.updateGrid();
    this.reportingV2Service
        .getDictionaryFromS3(this.reportCode)
        .toPromise()
        .then((data: any) => {
          this.dictionary = JSON.parse(data);
          console.log('Dictionary: ', this.dictionary);
          console.log('Dictionary Type: ', typeof this.dictionary);
        })
        .catch((error) => {
          //console.log('Error Loading Dictionary: ', error);
          this.dictionary = [];
        });
    this.grid.columns = this.grid.columns.map((column) => {
      return {
        ...column,
        maxWidth: this.maxColumnWidth,
        minWidth: 100,
        width: 250,
        clipMode: 'EllipsisWithTooltip',
      };
    });
    setTimeout(() => {
      // this.notificationService.success(
      //   'DOING SET TIMEOUT',
      //   'DOING SET TIMEOUT'
      // );
      this.grid.autoFitColumns();
      this.getFilterQueryParams();
      this.FilterFromQueryParams();
      this.updateGrid();
      this.loadAllStates();
      this.initDefaultColumns();

      this.spinnerService.toggle(false);
    }, 500);
  }
  // ===========================================================================
  // Load Report from S3
  // ===========================================================================
  private loadReportToGrid(reportName: string, reportSize?: number) {
    this.spinnerService.toggle(true, 'Fetching Report...');
    this.reportIcon = this.isCustomReport
      ? 'report'
      : this.reportingV2Service.getIcon(this.reportCode);
    if (!this.isCustomReport) {
      this.reportingV2Service
        .getDictionaryFromS3(this.reportCode)
        .toPromise()
        .then((data: any) => {
          this.dictionary = JSON.parse(data);
          console.log('Dictionary: ', this.dictionary);
          console.log('Dictionary Type: ', typeof this.dictionary);
        })
        .catch((error) => {
          //console.log('Error Loading Dictionary: ', error);
          this.dictionary = [];
        });
    }
    this.getFilterQueryParams();
    const isFilteredSelection = Object.keys(this.filterParams).length > 0; // Check if Filter Params Exist
    let query = `SELECT * FROM s3object s LIMIT ${this.initialChunkLimit + 5};`; // Create Custom Initial Query
    if (isFilteredSelection) {
      // Query Based on Filter Params
      query = `SELECT * FROM s3object s WHERE ${Object.keys(this.filterParams)
        .map((key) => {
          return `${key} LIKE '%${this.filterParams[key]}%'`;
        })
        .join(' AND ')} LIMIT ${this.initialChunkLimit + 5};`;
    }
    //console.log('Query: ', query);
    this.reportingV2Service
      .getReportData(reportName, query, reportSize, false)
      .toPromise()
      .then((data: any) => {
        this.spinnerService.toggle(true);
        this.grid.columns = this.createColumns(data);
        // this.queryBuilder.columns = this.grid.columns as any;
        if (data.length > this.initialChunkLimit || isFilteredSelection) {
          this.notificationService.info(
            'Large Data Set',
            'This report has a large data set. Only the first 1000 records are loaded. Please wait...'
          );
          this.reportDataManager = new DataManager(data);
          this.gridData = this.reportDataManager;
          this.queryBuilder.dataSource = this.gridData;
          this.loadingLargeData = true;
          this.reportingV2Service
            .getReportData(
              reportName,
              `SELECT * FROM S3Object s`,
              reportSize,
              true
            )
            .subscribe((data) => {
              this.gridData = null;
              this.reportDataManager = new DataManager(data);
              this.gridData = this.reportDataManager;
              this.queryBuilder.dataSource = this.gridData;

              this.updateGrid();
              // this.notificationService.success(
              //   'All Data Loaded',
              //   'All data has been loaded successfully'
              // );
              this.loadingLargeData = false;
            });
        } else {
          this.gridData = new DataManager(data);
        }

        setTimeout(() => {
          this.FilterFromQueryParams();
          this.updateGrid();
          this.loadAllStates();
          this.grid.autoFitColumns();
          this.spinnerService.toggle(false);
          this.initDefaultColumns();
        }, 200);
      })
      .catch((error) => {
        this.spinnerService.toggle(false);
        this.notificationService.error(
          'Report Load Error',
          'Report has not been loaded successfully'
        );
      });
  }

  public stopDownload() {
    this.reportingV2Service.stopBigDataRequest();
    this.loadingLargeData = false;
  }
  private FilterFromQueryParams() {
    if (!this.filterParams) return;
    this.filterSettings.columns = Object.keys(this.filterParams)
      .filter((key) => {
        return this.filterParams[key] !== '';
      })
      .map((key) => {
        return {
          field: key,
          operator: 'contains',
          value: this.filterParams[key],
          predicate: 'and',
          matchCase: false,
        } as PredicateModel;
      });
  }

  public onReportDropdownSelect($event) {
    //console.log('Selected Report: ', $event);
    this.selectedReport = $event.itemData;
    this.progressBarService.stopHandler();
    this.progressBarService.resetComponent();
    this.checkRDSandLoadReport(
      this.selectedReport.name,
      this.selectedReport.size
    );
  }
  public onSelectView($event) {
    // applies state to grid
    try {
      //console.log('Selected View: ', $event.itemData.state);
      this.selectedViewName = $event.itemData.name;
      if (this.selectedViewName === 'Default') {
        this.onClearView();
        return;
      }
      this.grid.clearGrouping();
      this.grid.clearSorting();
      this.grid.clearFiltering();

      this.isComplexFilter = $event.itemData.isComplexFilter;
      if (
        $event.itemData.isComplexFilter &&
        $event.itemData.complexFilterRules
      ) {
        this.grid.setProperties($event.itemData.state);

        this.queryBuilder.setRules($event.itemData.complexFilterRules);
        console.log(
          'Complex Filter Rules: ',
          $event.itemData.complexFilterRules
        );
        this.queryBuilder.trigger('change');
      } else {
        console.log('Resetting Query Builder');
        this.queryBuilder.reset();
        try{
        this.grid.setProperties($event.itemData.state);
        }catch(e){
          console.log('Error Setting State: ', e);
        }
      }
      //console.log('Applying View: ', $event.itemData.state);

      setTimeout(() => {
        this.grid.refreshColumns();
        this.grid.autoFitColumns();
      }, 200);
    } catch (e) {
      console.log('Error Applying View: ', e);
    }
  }
  // ===========================================================================
  // Local View Actions
  // ===========================================================================
  public onNewView() {
    // opens modal for name and captures state
    if (this.newLocalStateName.nativeElement.value === '') {
      this.notificationService.error(
        'Error Saving State',
        'State name cannot be empty'
      );
      return;
    }
    //console.log('New State: ', JSON.parse(this.grid.getPersistData()));
    //console.log('New State Name: ', this.newLocalStateName.value);
    this.reportingV2Service.saveStateListToLocalStorage(this.reportCode, {
      name: this.newLocalStateName.nativeElement.value,
      state: JSON.parse(this.grid.getPersistData()),
      isComplexFilter: this.isComplexFilter,
      complexFilterRules: this.isComplexFilter
        ? this.queryBuilder.getRules()
        : null,
    });
    this.loadAllStates();
    this.closeModal('#addLocalState');
    this.notificationService.success(
      'State Saved',
      'State has been saved successfully'
    );
  }
  public selectedViewName: string;
  public onDeleteView() {
    // opens modal for confirmation
    this.reportingV2Service.deleteStateListFromLocalStorage(
      this.reportCode,
      this.selectedViewName
    );
    this.loadAllStates();
    this.notificationService.success(
      'State Deleted',
      'State has been deleted successfully'
    );
    this.closeModal('#deleteLocalState');
  }
  public onClearView() {
    this.selectedState = null;
    this.resetToDefault();
    this.grid.clearSorting();
    this.grid.clearFiltering();
    this.grid.clearGrouping();
    this.closeModal('#clearLocalState');
    this.notificationService.success(
      'State Cleared',
      'State has been cleared successfully'
    );
  }
  // ===========================================================================
  // Predefined View Actions
  // ===========================================================================
  public async onAddS3State() {
    // opens modal for name and captures state
    const name = this.newStateName.nativeElement.value;

    if (name === '') {
      this.closeModal('#predefinedLibraryModal');
      this.notificationService.error(
        'Error Saving State',
        'State name cannot be empty'
      );
      return;
    }

    //console.log('New State: ', JSON.parse(this.grid.getPersistData()));
    this.spinnerService.toggle(true);
    await this.reportingV2Service.saveStateListToS3(this.reportCode, {
      name: name,
      state: JSON.parse(this.grid.getPersistData()),
      isComplexFilter: this.isComplexFilter,
      complexFilterRules: this.isComplexFilter
        ? this.queryBuilder.getRules()
        : null,
    });
    this.spinnerService.toggle(false);
    this.notificationService.success(
      `State Saved as ${name}`,
      'State has been saved successfully'
    );
    this.closeModal('#predefinedLibraryModal');
    this.loadAllStates();
  }
  // ===========================================================================
  // Loads all the states from S3 and Local Storage for Use
  // ===========================================================================
  public loadAllStates() {
    // loads all states from service
    this.reportingV2Service
      .getStateListFromS3(this.reportCode)
      .toPromise()
      .then((stateObjs: any) => {
        this.s3States = JSON.parse(stateObjs).map((state: any) => {
          return {
            ...state,
            cat: 'Predefined View',
          };
        });
        //console.log('S3 Views: ', this.s3States);
      })
      .catch((error: any) => {
        //console.log('Error Loading Views: ', error);
        this.s3States = [];
        // this.notificationService.error(
        //   'Error Loading States',
        //   'Error loading states from S3'
        // );
      })
      .finally(() => {
        const localStates = this.reportingV2Service
          .getStateListFromLocalStorage(this.reportCode)
          .map((state: any) => {
            return {
              ...state,
              cat: 'Local View',
            };
          });
        this.stateList = [
          {
            name: 'Default',
            cat: 'none',
          },
          ...this.s3States,
          ...localStates,
        ];
        try {
          const menuItemIndex: any = this.viewMenuItems.findIndex((item) => {
            return item.name === 'Views';
          });
          console.log('Menu Item Index: ', this.viewMenuItems[menuItemIndex]);

          this.viewMenuItems[menuItemIndex].children[1].children =
            this.stateList.filter((state) => {
              return state.cat === 'Predefined View';
            }) as MenuItem[];
          this.viewMenuItems[menuItemIndex].children[2].children =
            this.stateList.filter((state) => {
              return state.cat === 'Local View';
            });
          this.viewMenu.items = this.viewMenuItems;
          this.viewMenu.dataBind();
        } catch (e) {
          console.log('Error Updating Menu: ', e);
        }

        //console.log('View List: ', this.stateList);
      });
  }
  // ===========================================================================
  // On Grid Action Complete
  // ===========================================================================
  private firstRefresh: boolean = true;
  public onActionComplete($event) {
    console.log('Action Complete: ', $event);
    //console.log('Action: ', $event);
    if (
      this.useRDS &&
      this.firstRefresh &&
      $event.requestType === 'refresh' &&
      $event.name === 'actionComplete'
    ) {
      //console.log('Auto Fitting Columns');
      this.firstRefresh = false;
      this.grid.columns = this.grid.columns.map((column) => {
        return {
          ...column,
          maxWidth: this.maxColumnWidth,
          minWidth: 100,
          width: 250,
          clipMode: 'EllipsisWithTooltip',
        };
      });
      this.updateGrid();
      this.grid.autoFitColumns();
    }

    if ($event.type === 'actionComplete' && $event.action === 'filter') {
      const columns = new Set();
      $event.columns.forEach((column: any) => {
        columns.add(column.field);
      });
      this.filterColumnList = Array.from(columns);
    }
  }

  public onActionBegin($event) {
    console.log('Action Begin: ', $event);
    if ($event.requestType === 'filterchoicerequest') {
      if (this.reportAdaptor) {
        console.log('Setting Distinct to True');
        this.reportAdaptor.isDistinct = true;
        this.reportAdaptor.distinctColumns = $event.query.distincts;
      }
    }
    if (
      $event.requestType === 'filterchoicerequest' ||
      $event.requestType === 'filtersearchbegin'
    ) {
      $event.filterChoiceCount = 3000;
    }
    if($event.requestType === 'sorting'){
      this.grid.sortColumn($event.columnName, $event.direction, true);
    }
  }
  public onActionFailure($event) {
    console.log('Action Failure: ', $event);
  }

  // ===========================================================================
  // View UI Components and Methods
  // ===========================================================================
  public viewMenuItems: any = [
    {
      name: 'Views',
      children: [
        {
          name: 'Default',
          children: [] as any,
        },
        {
          name: 'Predefined Views',
          children: [] as any,
        },
        {
          name: 'Local Views',
          children: [] as any,
        },
      ] as any[],
    },
    {
      name: 'View Actions',
      children: [
        {
          name: 'New View',
        },
        {
          name: 'Delete View',
        },
        {
          name: 'Clear View',
        },
      ],
    },
  ];
  private vaultOnlyMenuItems: any = [
    {
      name: 'Predefined View Settings',
      children: [],
    },
  ];
  public localViewMenuFields = {
    text: 'name',
    children: 'children',
  };

  public onViewMenuClick($event) {
    console.log('View Menu Click: ', $event);
    const actionName = $event?.item?.name || 'noname';
    switch (actionName) {
      case 'New View':
        this.openModal('#addLocalState');
        break;
      case 'Delete View':
        this.openModal('#deleteLocalState');
        break;
      case 'Clear View':
        this.openModal('#clearLocalState');
        break;
      case 'Predefined View Settings':
        this.openModal('#predefinedLibraryModal');
        break;
      case 'Default':
        this.onClearView();
        break;
    }
    if ($event.item.cat && $event.item.cat === 'Predefined View') {
      this.onSelectView({ itemData: $event.item });
    } else if ($event.item.cat && $event.item.cat === 'Local View') {
      this.onSelectView({ itemData: $event.item });
    }
  }

  onBeforeOpen(args: any): void {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    args.element.style.top = `${
      args.element.getBoundingClientRect().top + scrollTop
    }px`;
    args.element.style.left = `${
      args.element.getBoundingClientRect().left + scrollLeft
    }px`;
  }
  // ===========================================================================
  // Modal Methods
  // ===========================================================================
  public openModal(id) {
    $(id).modal('show');
  }
  public closeModal(id) {
    $(id).modal('hide');
  }
  public exportProperties: any = {
    exportType: 'AllPages',
  };
  // ===========================================================================
  // On Toolbar Click
  // ===========================================================================
  public onToolbarClick(args: any): void {
    // //console.log(args.item);
    if (args.item.id === 'clearFilter') {
      this.grid.clearFiltering();
      this.filterColumnList = [];
    }
    if (args.item.id === 'autoFitAll') {
      this.grid.autoFitColumns();
    }
    if (args.item.id.indexOf('csvexport') > -1) {
      this.exportProperties.fileName = `VJS-Report-${
        this.reportCode
      }-${Date.now()}.csv`;

      this.grid!.csvExport(this.exportProperties);
    }
  }
  // ===========================================================================
  // For Rendering Tooltip upon header hover for column description
  // ===========================================================================
  @ViewChild('tooltip') toolTip?: TooltipComponent;
  public beforeRender(args: TooltipEventArgs): void {
    let description = '';
    if (this.dictionary) {
      const findDictEntry = this.dictionary.find((entry) => {
        return entry.name === args.target.innerText;
      });
      if (findDictEntry) {
        description = findDictEntry.description;
        if(findDictEntry.override && findDictEntry.override !== '' && findDictEntry.override !== null) {
          description = findDictEntry.override;
        }
        (this.toolTip as TooltipComponent).content = description;
      } else {
        description = 'No Description Available';
        (this.toolTip as TooltipComponent).content = description;
      }
    } else {
      description = 'No Description Available';
      (this.toolTip as TooltipComponent).content = description;
    }
  }

  // ===========================================================================
  // Filter Button Actions
  // ===========================================================================
  public filterColumnList: any = [];
  removeFilterByField(field: string) {
    // this.grid.filterSettings.columns = this.grid.filterSettings.columns.filter(
    //   (filter) => {
    //     return filter.field !== field;
    //   }
    // );
    this.grid.removeFilteredColsByField(field);
    this.filterColumnList = this.grid.filterSettings.columns.map(
      (filter) => filter.field
    );
  }

  // ===========================================================================
  // Grid Settings and Methods
  // ===========================================================================
  private allowExcelExport: boolean;
  private allowFiltering: boolean;
  private allowGrouping: boolean;
  private allowKeyboard: boolean;
  private allowMultiSorting: boolean;
  private allowPaging: boolean;
  private allowPdfExport: boolean;
  private allowReordering: boolean;
  private allowResizing: boolean;
  private allowRowDragAndDrop: boolean;
  private allowSelection: boolean;
  private allowSorting: boolean;
  private allowTextWrap: boolean;

  private showColumnChooser: boolean;
  private showColumnMenu: boolean;
  private showHider: boolean;
  private columnMenuItems: any;

  private filterSettings: FilterSettingsModel;
  private groupSettings: GroupSettingsModel;
  private pageSettings: PageSettingsModel;
  private resizeSettings: ResizeSettingsModel;

  private enableAdaptiveUI: boolean;
  private enableAltRow: boolean;
  private enableAutoFill: boolean;
  private enableColumnVirtualization: boolean;
  private enableHeaderFocus: boolean;
  private enableHover: boolean;
  private enableHtmlSanitizer: boolean;
  private enableImmutableMode: boolean;
  private enableInfiniteScrolling: boolean;
  private enablePersistence: boolean;
  private enableRtl: boolean;
  private enableStickyHeader: boolean;
  private enableVirtualMaskRow: boolean;
  private enableVirtualization: boolean;

  private initGridSettings() {
    this.gridData = [];
    this.allowExcelExport = true;
    this.allowFiltering = true;
    this.allowGrouping = true;
    this.allowKeyboard = true;
    this.allowMultiSorting = true;
    this.allowPaging = true;
    this.allowPdfExport = false;
    this.allowReordering = true;
    this.allowResizing = true;
    this.allowRowDragAndDrop = false;
    this.allowSelection = true;
    this.allowSorting = true;
    this.allowTextWrap = false;

    this.showColumnChooser = true;
    this.showColumnMenu = true;
    this.showHider = true;
    this.columnMenuItems = [
      'SortAscending',
      'SortDescending',
      'Group',
      'Ungroup',
      'Filter',
      'ClearFilter',
    ];
    this.toolbarOptions = [
      'ColumnChooser',
      // 'Search',
      'CsvExport',
      {
        text: 'Clear Filter',
        tooltipText: 'Clear All Filters',
        prefixIcon: 'e-filter-clear',
        id: 'clearFilter',
      },
      {
        text: 'Autofit',
        tooltipText: 'Autofit All Columns',
        prefixIcon: 'e-auto-fit-content',
        id: 'autoFitAll',
      },
    ];

    this.filterSettings = {
      type: 'Excel',
      mode: 'OnEnter',

    };
    this.groupSettings = { showDropArea: false };
    this.pageSettings = {
      pageSize: 100,
      pageSizes: ['100', '500', '1000', '5000'],
    };
    // this.resizeSettings = { allowResizing: true };

    this.enableAdaptiveUI = false;
    this.enableAltRow = false;
    this.enableAutoFill = false;
    this.enableColumnVirtualization = false;
    this.enableHeaderFocus = true;
    this.enableHover = true;
    this.enableHtmlSanitizer = false;
    this.enableImmutableMode = false;
    this.enableInfiniteScrolling = false;
    this.enablePersistence = false;
    this.enableRtl = false;
    this.enableStickyHeader = true;
    this.enableVirtualMaskRow = true;
    this.enableVirtualization = false;
  }

  private updateGrid() {
    this.grid.dataSource = this.gridData;
    this.grid.allowExcelExport !== this.allowExcelExport
      ? (this.grid.allowExcelExport = this.allowExcelExport)
      : null;
    this.grid.allowFiltering !== this.allowFiltering
      ? (this.grid.allowFiltering = this.allowFiltering)
      : null;
    this.grid.allowGrouping !== this.allowGrouping
      ? (this.grid.allowGrouping = this.allowGrouping)
      : null;
    this.grid.allowKeyboard !== this.allowKeyboard
      ? (this.grid.allowKeyboard = this.allowKeyboard)
      : null;
    this.grid.allowMultiSorting !== this.allowMultiSorting
      ? (this.grid.allowMultiSorting = this.allowMultiSorting)
      : null;
    this.grid.allowPaging !== this.allowPaging
      ? (this.grid.allowPaging = this.allowPaging)
      : null;
    this.grid.allowPdfExport !== this.allowPdfExport
      ? (this.grid.allowPdfExport = this.allowPdfExport)
      : null;
    this.grid.allowReordering !== this.allowReordering
      ? (this.grid.allowReordering = this.allowReordering)
      : null;
    this.grid.allowResizing !== this.allowResizing
      ? (this.grid.allowResizing = this.allowResizing)
      : null;
    this.grid.allowRowDragAndDrop !== this.allowRowDragAndDrop
      ? (this.grid.allowRowDragAndDrop = this.allowRowDragAndDrop)
      : null;
    this.grid.allowSelection !== this.allowSelection
      ? (this.grid.allowSelection = this.allowSelection)
      : null;
    this.grid.allowSorting !== this.allowSorting
      ? (this.grid.allowSorting = this.allowSorting)
      : null;
    this.grid.allowTextWrap !== this.allowTextWrap
      ? (this.grid.allowTextWrap = this.allowTextWrap)
      : null;

    this.grid.showColumnChooser !== this.showColumnChooser
      ? (this.grid.showColumnChooser = this.showColumnChooser)
      : null;
    this.grid.showColumnMenu !== this.showColumnMenu
      ? (this.grid.showColumnMenu = this.showColumnMenu)
      : null;
    this.grid.columnMenuItems !== this.columnMenuItems
      ? (this.grid.columnMenuItems = this.columnMenuItems)
      : null;
    this.grid.toolbar !== this.toolbarOptions
      ? (this.grid.toolbar = this.toolbarOptions)
      : null;
    // this.grid.showHider = this.showHider;
    // this.filterSettings = this.filterSettings;
    this.grid.filterSettings !== this.filterSettings
      ? (this.grid.filterSettings = this.filterSettings)
      : null;

    this.grid.groupSettings !== this.groupSettings
      ? (this.grid.groupSettings = this.groupSettings)
      : null;
    this.grid.pageSettings !== this.pageSettings
      ? (this.grid.pageSettings = this.pageSettings)
      : null;
    this.grid.resizeSettings !== this.resizeSettings
      ? (this.grid.resizeSettings = this.resizeSettings)
      : null;

    this.grid.enableAdaptiveUI !== this.enableAdaptiveUI
      ? (this.grid.enableAdaptiveUI = this.enableAdaptiveUI)
      : null;
    this.grid.enableAltRow !== this.enableAltRow
      ? (this.grid.enableAltRow = this.enableAltRow)
      : null;
    this.grid.enableAutoFill !== this.enableAutoFill
      ? (this.grid.enableAutoFill = this.enableAutoFill)
      : null;
    this.grid.enableColumnVirtualization !== this.enableColumnVirtualization
      ? (this.grid.enableColumnVirtualization = this.enableColumnVirtualization)
      : null;
    this.grid.enableHeaderFocus !== this.enableHeaderFocus
      ? (this.grid.enableHeaderFocus = this.enableHeaderFocus)
      : null;
    this.grid.enableHover !== this.enableHover
      ? (this.grid.enableHover = this.enableHover)
      : null;
    // this.grid.enableHtmlSanitizer = this.enableHtmlSanitizer;
    this.grid.enableImmutableMode !== this.enableImmutableMode
      ? (this.grid.enableImmutableMode = this.enableImmutableMode)
      : null;
    this.grid.enableInfiniteScrolling !== this.enableInfiniteScrolling
      ? (this.grid.enableInfiniteScrolling = this.enableInfiniteScrolling)
      : null;
    this.grid.enablePersistence !== this.enablePersistence
      ? (this.grid.enablePersistence = this.enablePersistence)
      : null;
    this.grid.enableRtl !== this.enableRtl
      ? (this.grid.enableRtl = this.enableRtl)
      : null;
    this.grid.enableStickyHeader !== this.enableStickyHeader
      ? (this.grid.enableStickyHeader = this.enableStickyHeader)
      : null;
    // this.grid.enableVirtualMaskRow = this.enableVirtualMaskRow;
    this.grid.enableVirtualization !== this.enableVirtualization
      ? (this.grid.enableVirtualization = this.enableVirtualization)
      : null;
    this.grid.height = '700px';
  }
}
