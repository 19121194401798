<div class="col-lg-9">
  <div class="card card-table" style="height: 620px">
    <div class="card-header">
      <div class="controlContainer">
        <div class="row">
          <div class="controlButtons">
            <div class="btn-group controlButton">
              <button title="Pause/Resume" class="btn btn-rounded btn-primary" [class.active]="isPaused" type="button"
                (click)="onPauseClick();"><i class="icon icon-left zmdi zmdi-pause"></i></button>
            </div>
            <div class="btn-group controlButton">
              <button title="Clear Log" class="btn btn-rounded btn-danger" type="button" (click)="logHistory = []"><i
                  class="icon icon-left zmdi zmdi-delete"></i></button>
            </div>
          </div>
          <div class="">
            <input #searchTextBox class="form-control form-control-sm" style="width: 300px;" type="text"
              placeholder="Search..." (keyup)="onSearchTermChanged($event.target.value)">
          </div>
        </div>
        <div class="row">
          <div class="controlButtons">
            <div style="padding-left: 30px;">
              <span class="speedClass">Delay (seconds): </span><span
                style="font-size: 1.1rem; padding-right: 15px;">0</span><mv-slider [(value)]="delay" [min]="0"
                [max]="10" [enabled]="true" (slideStop)="onSlideStop($event)"></mv-slider><span
                style="font-size: 1.1rem; padding-left: 5px;">10</span>
            </div>
          </div>
        </div>
        <!-- <button class="btn btn-rounded btn-success" ><i class="icon icon-left zmdi zmdi-play"></i></button>&nbsp;
                        <button class="btn btn-rounded btn-success" ><i class="icon icon-left zmdi zmdi-pause"></i></button>&nbsp; -->
        <!-- <button type="button" class="btn btn-rounded btn-space btn-success" data-toggle="modal" data-target="#modal-bulk-update" ><i style="color: white;" class="icon icon-left zmdi zmdi-collection-plus"></i>Pause</button> -->
        <!-- <button [disabled]="false" type="button" class="btn btn-rounded btn-space btn-primary" ><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button> -->
      </div>
      <span class="title ">Real Time Event Log</span>
      <span class="card-subtitle">View events in real time as they come in!</span>
    </div>
    <div class="logContainer">
      <log-monitor #logMonitor [logStream]="logStream$ | async" theme="dark" [animated]="true" [icons]="false"
        [history]="logHistory"></log-monitor>
    </div>
  </div>
</div>
<div class="col-lg-3">
  <div class="card legendContainer">
    <div class="card-header">Legend</div>
    <div class="card-body">
      <table class="table">
        <thead>
          <th>Event Type</th>
          <th>Enabled</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <svg class="square" width="20" height="20">
                <rect width="20" height="20" style="fill:transparent; stroke-width:2; stroke:black;" />
              </svg>
              ALL Events
            </td>
            <td><input type="checkbox" id="showAll" [ngModel]="showAllEvents"
                (change)="showAllEvents = !showAllEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-file-monitor-code-file-update square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Code File Change
            </td>
            <td><input type="checkbox" id="showCodeFileChangeEvents" [ngModel]="showCodeFileChangeEvents"
                (change)="showCodeFileChangeEvents = !showCodeFileChangeEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-code-file-analysis-complete-success square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Code Analysis Complete
            </td>
            <td><input type="checkbox" id="showCodeAnalysisCompleteEvents" [ngModel]="showCodeAnalysisCompleteEvents"
                (change)="showCodeAnalysisCompleteEvents= !showCodeAnalysisCompleteEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-vault-ui-user-navigation square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Instrumentation
            </td>
            <td><input type="checkbox" id="showInstrumentationEvents" [ngModel]="showInstrumentationEvents"
                (change)="showInstrumentationEvents = !showInstrumentationEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-page-runner-new-code-file square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              New Code File
            </td>
            <td><input type="checkbox" id="showNewCodeFileEvents" [ngModel]="showNewCodeFileEvents"
                (change)="showNewCodeFileEvents = !showNewCodeFileEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-new-incident square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              New Incident
            </td>
            <td><input type="checkbox" id="showNewIncidentEvents" [ngModel]="showNewIncidentEvents"
                (change)="showNewIncidentEvents = !showNewIncidentEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-page-runner-page-run-complete square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Page Run Complete
            </td>
            <td><input type="checkbox" id="showPageRunCompletEvents" [ngModel]="showPageRunCompletEvents"
                (change)="showPageRunCompletEvents = !showPageRunCompletEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-page-runner-page-run-start square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Page Run Start
            </td>
            <td><input type="checkbox" id="showPageRunCompletEvents" [ngModel]="showPageRunStartEvents"
                (change)="showPageRunStartEvents = !showPageRunStartEvents;"></td>
          </tr>
          <tr>
            <td>
              <svg class="msg-scheduled-report-complete-success square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Report Complete
            </td>
            <td><input type="checkbox" id="showReportCompleteEvents" [ngModel]="showReportCompleteEvents"
                (change)="showReportCompleteEvents = !showReportCompleteEvents;"></td>
          </tr>
          <!-- <tr>
                        <td>
                          <svg class="msg-timer square" width="20" height="20">
                            <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
                          </svg>
                          Timer Complete
                        </td>
                        <td><input type="checkbox" id="showTimerCompleteEvents" [ngModel]="showTimerCompleteEvents"
                            (change)="showTimerCompleteEvents = !showTimerCompleteEvents;"></td>
                      </tr> -->
          <tr>
            <td>
              <svg class="msg-validation-validation-result square" width="20" height="20">
                <rect width="20" height="20" style="fill:currentColor; stroke-width:2; stroke:black;" />
              </svg>
              Validation Result
            </td>
            <td><input type="checkbox" id="showValidationResultEvents" [ngModel]="showValidationResultEvents"
                (change)="showValidationResultEvents = !showValidationResultEvents;"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>