import { Component, OnInit } from '@angular/core';
import { QueriedReportingService } from '../../services/queried-reporting/queried-reporting.service';
import { GlobalResourcesService } from '../../services/global-resources/global-resources.service';
import { ConfigGroupsService } from '../../services/config-groups/config-groups.service';
import { DataExportService } from '../../services/data-export/data-export.service';
import { environment as env } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { TrackerDashboardService } from '../../services/tracker-dashboard/tracker-dashboard.service';
@Component({
  selector: 'app-service-testing-view',
  templateUrl: './service-testing-view.component.html',
  styleUrls: ['./service-testing-view.component.css'],
})
export class ServiceTestingViewComponent implements OnInit {
  constructor(
    private queriedReportingService: QueriedReportingService,
    private globalResourcesService: GlobalResourcesService,
    private configGroupsService: ConfigGroupsService,
    private dataExportService: DataExportService,
    private sanitizer: DomSanitizer,
    private trackerDashboardService: TrackerDashboardService
  ) {}
  public pdfLink: any = '';
  public bucket: string;
  ngOnInit(): void {
    this.dataExportService
      .getSignedGlobalResourceUrl('VJS Manual v1.1.2.pdf')
      .subscribe((data) => {
        this.pdfLink = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      });
  }

  public run_getDataByPageUrl(): void {
    this.queriedReportingService
      .getDataByPageUrl('queryTest.csv', 'page1')
      .subscribe((data) => {
        console.log('Data:', data);
      });
  }
  public run_uniqueValues(): void {
    this.queriedReportingService
      .getUniqueValues('queryTest.csv', 'pageUrl')
      .subscribe((data) => {
        console.log('Data:', data);
      });
  }

  public run_globalResourcesAllFiles(): void {
    this.globalResourcesService.getListOfResources().subscribe((data) => {
      console.log('Data:', data);
    });
  }

  public run_configGroupsAllFiles(): void {
    this.configGroupsService.getConfigGroups().subscribe((data) => {
      console.log('Data:', JSON.parse(data));
    });
  }
  public trackerData: string;
  public run_trackerDashboard(): void {
    this.trackerDashboardService.getTrackerSummaryDomainVendor().subscribe((data) => {
      console.log('Data:', data);
      this.trackerData = JSON.stringify(data,null,2);
    });
  }
}
