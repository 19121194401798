import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
import { ToolbarService, LinkService, ImageService, QuickToolbarService, ResizeService, MarkdownEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { HtmlEditorService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import Tribute  from 'tributejs';
import { IEventOutput } from '../../../../models/IEventOutput';
import { EventRuleService } from '../../../../services/eventRule/event-rule.service';

@Component({
  selector: 'sms-output',
  templateUrl: './sms-output.component.html',
  styleUrls: ['./sms-output.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, ResizeService, MarkdownEditorService]
})
export class SmsOutputComponent implements OnInit, IEventOutput, OnChanges {

  @ViewChild('txtPhoneNumber')
  txtPhoneNumber : TextBoxComponent;

  @ViewChild('tributeRTE') 
  rteObj: RichTextEditorComponent;
  
  @ViewChild('recipientsList')
  listviewInstance: ListViewComponent;

  @Input() data: any;
  @Input() eventType:string;
  @Input() isNewRule:boolean;

  tributeObj:any;
  toolbarSettings: any = {
    enable: false
  };
  
  public outputFormGroup:FormGroup;

  public recipientsLVFields : Object = {text: "text", iconCss: "e-icons" };
  public recipients: any[] =  [];

  constructor(public formBuilder:FormBuilder) { 
    this.outputFormGroup = this.formBuilder.group({
      body: ''
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes')
    console.log(changes);
    console.log(this.isNewRule);
    
    if ( changes.eventType && this.tributeObj) {
      this.tributeObj.append(0, EventRuleService.getTributeJSOptions(this.eventType), true);

      if (!this.isNewRule) return;

      if ( this.outputFormGroup.get('body').value && this.outputFormGroup.get('body').value.indexOf("{{") != -1){
        this.outputFormGroup.patchValue({body: ''});
      }      
    }
  }

  getValue(): Object {
    return {...this.outputFormGroup.value, recipients: this.recipients.map((r)=>{return {phoneNumber: r.text, name: ""}})};
  }
  

  ngOnInit(): void {
    
    console.log('sms init');
    console.log(this.data);
    
    this.outputFormGroup = this.formBuilder.group({
      body: this.data ? this.data.body : ''
    });

    if ( this.data && this.data.recipients) {
      this.recipients = this.data.recipients.map((r, i)=>{return {text: r.phoneNumber, id: i, icon: "delete-icon" }});
    }

    console.log("this.recipients");
    console.log(this.recipients);
      
  }

  public onCreate(): void {

    this.tributeObj = new Tribute({
      trigger: "#",
      values: EventRuleService.getTributeJSOptions(this.eventType),
      selectTemplate: function(item) {
        let value = item.original.value;
        if ( item.original.key == 'Vendor') {
          value = 'event.vendorName';
        }
        return `{{${value}}}`;
      },
      menuItemTemplate: function(item) {
        return item.string;
      }
    });     
    
    if ( this.rteObj && this.rteObj.inputElement ){
      this.tributeObj.attach(this.rteObj.inputElement);
    }
  }

  deleteItem(args) {
    args.stopPropagation();
    let liItem = args.target.parentElement.parentElement;
    this.listviewInstance.removeItem(liItem);
    this.onLVComplete();
  }
  
  onLVComplete(){
    let iconEle = document.getElementsByClassName("delete-icon");
    //Event handler to bind the click event for delete icon
    Array.from(iconEle).forEach((element) => {
      element.addEventListener("click", this.deleteItem.bind(this));
    });
  }

  ontxtChange(event){
    console.log('txtchange');
    if (event.value == "" ) return;
    console.log(event);
    this.listviewInstance.addItem([{ text: event.value, id: this.recipients.length, icon: "delete-icon" }]) 
    this.txtPhoneNumber.value = "";
    this.onLVComplete();    
  }

  public actionBeginEvent(args: any): void {
    if (args.requestType === 'EnterAction') {
      args.cancel = true;
    }
  }

}
