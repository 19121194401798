export class PageRunnerScript {

    fileContent?:string;

    constructor(
        public id:number, 
        public scriptFilePath:string,
        public lastRun:string,
        public cronSchedule:string,
        public enabled:boolean        
    ) {
                
    }
}