export class ValidationRuleHistory {
    
    constructor(
        public id:number,
        public page:string,
        public lastRun:Date,
        public status:string,
        public error?:string
    ){

    }
}
