import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-har-viewer',
  templateUrl: './har-viewer.component.html',
  styleUrls: ['./har-viewer.component.css']
})
export class HarViewerComponent implements OnInit {

  constructor(private route:ActivatedRoute) { 
    console.log("Path", this.route.snapshot.queryParams["path"]);
  }

  ngOnInit() {
  }

}
