import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  find,
  retry,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import {
  LogMessage as NgxLogMessage,
  LogMonitorComponent,
} from 'ngx-log-monitor';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment as env } from '../../../../../environments/environment';
let worldMap: object[] = require('./worldMap.json');
let population: object[] = require('./population.json');
import {
  MapsTheme,
  Maps,
  Marker,
  MapsTooltip,
  ILoadEventArgs,
  MapsTooltipService,
  MarkerService,
  DataLabelService,
  MapsComponent,
  ILoadedEventArgs,
} from '@syncfusion/ej2-angular-maps';
import { RegionService } from '../../../../services/region/region.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
// import { eventTypes } from './event-types';
import { VendorsService } from '../../../../services/vendors/vendors.service';
@Component({
  selector: 'app-realtime-event-view',
  templateUrl: './realtime-event-view.component.html',
  styleUrls: ['./realtime-event-view.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    MapsTooltipService,
    MarkerService,
    MapsComponent,
    DataLabelService,
  ],
})
export class RealtimeEventViewComponent implements OnInit {
  @ViewChild('logGrid') public logGrid: GridComponent;
  @ViewChild('map') public map: MapsComponent;
  @ViewChild('logMonitor') public logMonitor: LogMonitorComponent;
  @ViewChild('searchTextBox') public searchTextBox;
  endpoint = '';
  messages = [];
  websocket = undefined;
  subject;
  logStream$;
  logHistory;
  isPaused: boolean;
  subscription;
  eventLogInternal;
  searchTerm$ = new Subject<string>();
  searchText;
  filteredHistory;
  delay = 0;
  ticksLables = ['0', '10'];

  showCodeFileChangeEvents: boolean = false;
  showCodeAnalysisCompleteEvents: boolean = false;
  showInstrumentationEvents: boolean = false;
  showNewCodeFileEvents: boolean = false;
  showNewIncidentEvents: boolean = false;
  showPageRunCompletEvents: boolean = false;
  showPageRunStartEvents: boolean = false;
  showReportCompleteEvents: boolean = false;
  showTimerCompleteEvents: boolean = false;
  showValidationResultEvents: boolean = false;
  showAllEvents: boolean = true;
  public cdnUrl: string;
  public titleSettings: object = {
    text: 'Data Centers',
    textStyle: {
      size: '16px',
    },
  };

  regionTimers: any;

  public layers: object[];

  constructor(
    private regionService: RegionService,
    private vendorsService: VendorsService
  ) {
    this.cdnUrl = env.cdn.url;
    this.logHistory = [];
    this.isPaused = false;
    this.eventLogInternal = [];
    this.regionTimers = {};
    this.layers = [
      {
        shapeData: worldMap,
        type: 'Sublayer',
        dataSource: this.regionService.regionsArray,
        shapeSettings: { fill: '#6390ee' },
        markerSettings: [
          {
            dataSource: Array.from(this.regionService.regionsArray),
            visible: true,
            shape: 'Circle',
            fill: 'white',
            width: 5,
            border: { width: 2, color: '#285255' },
            tooltipSettings: {
              visible: true,
              valuePath: 'name',
            },
          },
        ],
      },
      {
        shapeData: worldMap,
        shapeSettings: { fill: '#6390ee' },
        markerSettings: [
          {
            dataSource: Array.from(this.regionService.regionsArray),
            visible: true,
            template:
              '<div id="template ${name}"><div class="pulse-css"><br /></div></div>',
            tooltipSettings: {
              template:
                '<div><div class="toolback"><div class="listing2"><center> ${description} </center></div></div>',
              visible: true,
              valuePath: 'name',
            },
          },
        ],
      },
    ];
  }
  private vendorList = [];
  ngOnInit(): void {
    // this.endpoint = 'wss://17vpxar0ih.execute-api.us-east-1.amazonaws.com/dev';
    this.vendorsService.getVendors().subscribe((vendors) => {
      this.vendorList = vendors;
    });
    this.endpoint = env.api.wsurl;
    this.logStream$ = new Subject<NgxLogMessage>();
    this.connectToWebSocket();
    // this.testData = this._testData.map((item) => {
    //   return this.eventFormatter(item);
    // })
  }

  connectToWebSocket() {
    this.subject = webSocket({
      url: `${this.endpoint}?t=${localStorage.getItem(
        'access_token'
      )}&cid=${localStorage.getItem('cid')}`,
      openObserver: {
        next: () => {
          console.log('WS Open');
        },
      },
      closingObserver: {
        next: () => {
          console.log('WS Closing...');
        },
      },
      closeObserver: {
        next: () => {
          console.log('WS Close...');
        },
      },
    });
    this.start();
  }

  start() {
    console.log('start', this.delay);
    let delayMs = this.delay * 1000;
    console.log(delayMs);
    this.subscription = this.subject.pipe(retry(5), delay(delayMs)).subscribe(
      (event) => {
        console.log(event);
        this._logEvents.next(this.eventLogInternal);
        this.logGrid.refresh();
        if (
          this.showAllEvents == true ||
          (event.eventTypeName == 'Code File Change' &&
            this.showCodeFileChangeEvents == true) ||
          (event.eventTypeName == 'Code Analysis Complete' &&
            this.showCodeAnalysisCompleteEvents == true) ||
          (event.eventTypeName == 'Instrumentation' &&
            this.showInstrumentationEvents == true) ||
          (event.eventTypeName == 'New Code File' &&
            this.showNewCodeFileEvents == true) ||
          (event.eventTypeName == 'New Incident' &&
            this.showNewIncidentEvents == true) ||
          (event.eventTypeName == 'Page Run Start' &&
            this.showPageRunStartEvents == true) ||
          (event.eventTypeName == 'Page Run Complete' &&
            this.showPageRunCompletEvents == true) ||
          (event.eventTypeName == 'Report Complete' &&
            this.showReportCompleteEvents == true) ||
          (event.eventTypeName == 'Timer Complete' &&
            this.showTimerCompleteEvents == true) ||
          (event.eventTypeName == 'Validation Result' &&
            this.showValidationResultEvents == true)
        ) {
          this.logStream$.next({
            type: event.source + '-' + event.event,
            message: event.eventTypeName + ' - ' + this.eventToString(event),
          });
          if (event.eventTypeName == 'Page Run Start') {
            console.log(event.region);
            if (event.region) {
              let element = document.getElementById(`template ${event.region}`);
              if (element) {
                element.style.visibility = 'visible';
                let alreadyStarted = this.regionTimers[event.region];
                if (alreadyStarted) {
                  clearInterval(alreadyStarted);
                }
                this.regionTimers[event.region] = setInterval(() => {
                  let element = document.getElementById(
                    `template ${event.region}`
                  );
                  if (element) {
                    element.style.visibility = 'hidden';
                  }
                }, 1000 * 20);
              }
            }
          }
        } else {
          console.log(`Filtered ${event.eventTypeName}...`);
        }
      },
      (err) => {
        console.log('Websocket error', err);
      },
      () => {
        console.log('Websocket stream complete');
        this.subject.unsubscribe();
        this.subscription.unsubscribe();
        this.connectToWebSocket();
      }
    );
  }
  public testData = [];
  public _testData = [
    {
      eventTypeName: 'Job Scheduled (priority)',
      event: 'scheduler-job-scheduled',
      source: 'page-runner',
      customerId: null,
      customerIds: [39],
      url: 'http://test.vaultjs.com/google.html',
      jobId: 'c0f845c6-fa38-42fa-8700-dd3bb9168449',
      region: 'us-east-1',
      timestamp: '2024-09-03T05:25:08.725Z',
      color: 'white',
    },
    {
      eventTypeName: 'Page Run Start',
      event: 'page-run-start',
      source: 'page-runner',
      customerId: null,
      customerIds: [39],
      url: 'http://test.vaultjs.com/google.html',
      jobId: 'c0f845c6-fa38-42fa-8700-dd3bb9168449',
      region: 'us-east-1',
      timestamp: '2024-09-03T05:25:15.223Z',
      color: 'white',
    },

    {
      eventTypeName: 'Page Run Complete',
      event: 'page-run-complete',
      source: 'page-runner',
      customerId: null,
      customerIds: [39],
      url: 'http://test.vaultjs.com/google.html',
      jobId: 'c0f845c6-fa38-42fa-8700-dd3bb9168449',
      region: 'us-east-1',
      timestamp: '2024-09-03T05:25:19.503Z',
      color: 'white',
    },
    {
      eventTypeName: 'Classify With Vendor Library',
      event: 'analysis-classifyWithVendorLibrary',
      customerId: 39,
      url: 'https://tms.delta.com/delta/dl_bastian/serverComponent.php?namespace=TMS&staticJsPath=tms.delta.com/delta/dl_bastian/code/&publishedOn=Thu%20Jul%2020%2013:23:45%20GMT%202023&ClientID=912&PageID=https%3A%2F%2Fwww.delta.com%2F%3FcountryLang%3Den-us%26page_title%3DDelta%20Air%20Lines%20%7C%20Flights%20%26%20Plane%20Tickets%20%2B%20Hotels%20%26%20Rental%20Cars%26page_path%3D%2F%26device%3Ddesktop%26isHomePage%3Dtrue%26ZT%7CZH%7CCN%3Dfalse%26ensPrivacy%3Dfalse%26metaVendorID%3D%26KayakLinkID_Booking%3D%26region%3Dus%26accountType%3Dpersonal%26404_page%3Dfalse',
      calculatedVendorId: 7467,
      timestamp: '2024-09-03T05:25:29.019Z',
      color: 'white',
    },

    {
      eventTypeName: 'Save New JavaScript',
      event: 'analysis-saveNewScriptResponseHashes',
      customerId: 39,
      timestamp: '2024-09-03T05:25:29.896Z',
      color: 'white',
    },

    {
      eventTypeName: 'Generate Request Tree',
      event: 'analysis-generateRequestTree',
      customerId: 39,
      timestamp: '2024-09-03T05:25:34.076Z',
      color: 'white',
    },

    {
      eventTypeName: 'Obfuscation Detection',
      event: 'analysis-obfuscationDetection',
      customerId: 39,
      pageRunnerJobUuid: 'c0f845c6-fa38-42fa-8700-dd3bb9168449',
      timestamp: '2024-09-03T05:25:34.837Z',
      color: 'white',
    },

    {
      eventTypeName: 'Validation Result',
      event: 'validation-result',
      source: 'validation',
      customerId: 39,
      validationResultUuid: 'a0c6ec95-0217-419f-8ee3-ec0d048de7ae',
      testSuiteUuid: '9e71dab9-e60c-4244-8331-3c8f85a62684',
      validationRuleUuid: 'e152be9e-2a45-4299-9f7d-be4365b413ee',
      conditionUuid: 'dd411db9-02c2-4566-b48e-0e5a595fc07e',
      linkId: '2380586930',
      pageRunnerHistoryId: -1,
      documentUrl: 'http://test.vaultjs.com/google.html',
      jobUuid: '5a48ab3d-c0b9-4ecc-aad5-36de2f880026',
      bucket: 'vjs-dev-page-runner-output',
      key: '39/2024/9/3/5/test.vaultjs.com/google.html/public/5a48ab3d-c0b9-4ecc-aad5-36de2f880026/',
      keyIsPath: true,
      validationSuccess: true,
      timestamp: 1725341334389,
      resultMessage: 'validation message',
      resultData: '{ "somedata":"moredata" }',
    },
  ];
  @ViewChild('jobGrid') public jobGrid: GridComponent;
  private _jobList = [
    {
      jobId: 'c0f845c6-fa38-42fa-8700-dd3bb9168449',
      url: 'http://test.vaultjs.com/google.html',
      region: 'us-east-1',
      scheduled: true,
      pageRunStarted: true,
      pageRunCompleted: true,
      analysisEvents: 24,
      validationEvents: 23,
      analysisStatus: 'ongoing',
    },
  ];
  eventToString(event) {
    switch (event.eventTypeName) {
      case 'Instrumentation':
        return `${event.type} - ${event.page} - ${event.user}`;
      case 'Code File Change':
        return `${event.url} (Id: ${event.codeFileId}, History Id: ${event.codeFileHistoryId}, UUID: ${event.uuid}, Hash: ${event.hash}, Size: ${event.responseSize}, Valid JS: ${event.isValidJS})`;
      case 'Page Run Complete':
        return `${event.url} (Job ID: ${event.jobId} Region: ${event.region})`;
      case 'Validation Result':
        return `${event.documentUrl} Result Message: ${event.resultMessage} Result Data: ${event.resultData} (Key: ${event.key}, Test Suite UUID: ${event.testSuiteUuid})`;
      default:
        return JSON.stringify(event);
    }
  }
  onJobRowSelected($event) {
    console.log($event);
    const jobId = $event.data.jobId
    this.filterByJobId(jobId);
  }
  filterByJobId(jobId) {
    this.logGrid.filterByColumn('jobId', 'equal', jobId);
  }

  onPauseClick() {
    this.isPaused = !this.isPaused;
    if (this.isPaused) {
      this.subscription.unsubscribe();
    } else {
      this.start();
      if (this.searchTextBox.nativeElement.value != '') {
        this.searchTextBox.nativeElement.value = '';
      }
    }
  }

  onSearchTermChanged(filterText: string) {
    if (this.searchTerm$.observers.length === 0) {
      this.searchTerm$
        .pipe(debounceTime(750), distinctUntilChanged())
        .subscribe((filterQuery) => {
          console.log(filterQuery);
          this.searchText = filterQuery;
          if (filterQuery == '') {
            if (this.isPaused) {
              this.onPauseClick();
            }
            this.logHistory = this.filteredHistory;
          } else {
            this.filteredHistory = this.logMonitor.history;

            this.onPauseClick();
            this.logHistory = this.logMonitor.history.filter((item) => {
              return item.message.match(new RegExp(filterQuery, 'g'));
            });
          }
        });
    }
    this.searchTerm$.next(filterText);
  }

  onSlideStop(event) {
    console.log('Slider Stop');
    console.log(event);
    this.subscription.unsubscribe();
    setTimeout(() => {
      this.start();
    }, 100);
  }

  public loaded = (args: ILoadedEventArgs) => {
    let lines: Object[] = args.maps.layers[0].markerSettings[0]
      .dataSource as Object[];
    for (let i: number = 0; i < lines.length; i++) {
      let region: string = lines[i]['name'];
      let marker: HTMLElement = document.getElementById(`template ${region}`);
      if (marker) {
        marker.style.visibility = 'hidden';
      }
    }
  };

  private _logEvents: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public get logEvents() {
    return this._logEvents.asObservable();
  }
}
