import { environment as env } from '../../../environments/environment';

interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  callbackURLIdP:string;
  apiUrl: string;
}
  
export const AUTH_CONFIG: AuthConfig = {
  clientID: env.auth0.clientID,
  domain: env.auth0.domain,
  callbackURL: env.auth0.callbackURL,
  callbackURLIdP: env.auth0.callbackURLIdP,
  apiUrl: env.auth0.apiUrl
};