<div style="display: flex; flex-direction: row; gap:5px; justify-content: space-around;">
        <div id="{{id}}_container"></div>

    <div id="legend-container"
        style="display: flex; flex-direction: column;  gap: 5px; justify-content: center; width: fit-content;">
        <div *ngFor="let legend of flattenedData" >
            <ejs-tooltip content="Count: {{legend.value}}" position="TopCenter" [hidden]="legend.value===0">
            <div
                (mouseenter)="highlightArc(legend.id)" (mouseleave)="unhighlightArc()" class="legend" id="{{id}}-{{legend.id}}-legend">
                <div style="padding-right: 5px; width: 50px; text-align: right;">{{legend.percent.toFixed(1)}}%</div>
                <div style="width: 14px; height: 14px; border-radius: .5rem;" [style.background-color]="legend.color">
                </div>
                <div style="border: 0px;  padding-left: 5px;" [style.border-color]="legend.color">{{legend.label}}</div>
            </div>
        </ejs-tooltip>
        </div>
    </div>
</div>