import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VaultBaseService {

  constructor(protected authHttp: HttpClient) { 
    
  }
  protected makeHttpRequest(url:string, params:HttpParams=null, share:boolean=true):Observable<any> {
    if ( share ){
      return this.authHttp.get(url, {params}).pipe(shareReplay());
    }
    return this.authHttp.get(url, {params});
  }
}
