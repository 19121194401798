import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QueryBuilderComponent, FieldMode, ColumnsModel, TemplateColumn } from '@syncfusion/ej2-angular-querybuilder';
import { EventOutput } from '../../../../models/EventOutput';
import { EventType } from '../../../../models/EventType';
import { AuthService } from '../../../../services/auth/auth.service';
import { EventRuleService } from '../../../../services/eventRule/event-rule.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { IEventOutput } from '../../../../models/IEventOutput';
import { EmailOutputComponent } from '../../event-output/email-output/email-output.component';
import { WebhookOutputComponent } from '../../event-output/webhook-output/webhook-output.component';
import { SmsOutputComponent } from '../../event-output/sms-output/sms-output.component';
import { createElement, getComponent } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'new-event-rule',
  templateUrl: './new-event-rule.component.html',
  styleUrls: ['./new-event-rule.component.css']
})
export class NewEventRuleComponent implements OnInit {

  @ViewChild('filterQueryBuilder') 
  filterQueryBuilder: QueryBuilderComponent;

  @ViewChild('webhook')
  webhook:WebhookOutputComponent;

  @ViewChild("email")
  email:EmailOutputComponent;

  @ViewChild("sms")
  sms:SmsOutputComponent;

  @Output() cancelEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();

  @Input() public rule:any;
  @Input() public isNewRule:boolean;
  @Input() public eventTypes:EventType[];
  @Input() public eventOutputs:EventOutput[];
  @Input() public eventDestinationTypes:any[];

  outputData:any;

  ruleForm:FormGroup;
  filterConfig:any;
  selectedEventTypeName:string;
  fields: Object = { value: 'id', text: 'name' };
  eventFields: Object = { value: 'id', text: 'name' };
  selectedDestination:any;
  typeOperators: any[] = [ 
    { value: 'equal', key: 'Is' },
    { value: 'notequal', key: 'Is Not' },
  ];

  todoMessage:string;
  separator: string = '.';
  fieldMode: FieldMode = 'DropdownTree';
  rules:any;
  queryCols: ColumnsModel[] = [];

  eventTypeEnabled:boolean;

  vendorTemplate: TemplateColumn;

  severityTemplate: TemplateColumn;
  statusTemplate: TemplateColumn;
  
  constructor(
    public spinnerService:SpinnerService,
    public eventRuleService:EventRuleService,
    public authService:AuthService,
    public formBuilder:FormBuilder,
    public vendorService:VendorsService
  ) { 
    console.log('ner constructor');
    this.todoMessage = "Not yet supported...";

    this.ruleForm = this.formBuilder.group({
      id: -1,
      name: '',        
      enabled: false,
      eventTypeId: null,
      destinationId: null
    });
    this.filterConfig = {};
    this.eventTypeEnabled = true;
  }

  ngOnInit(): void {
    console.log('ner ngOnInit');

    console.log("Add/Edit Form onInit");
    console.log(this.rule);

    console.log('wtf')
    console.log(this.eventTypes);
    console.log(this.eventOutputs);
    console.log(this.eventDestinationTypes);
    console.log('ftw')

    if ( !this.rule){
      this.rule = {id: null, name: '', enabled: false, typeId: null}
    }

    this.ruleForm.patchValue({
      id: this.rule.id,
      name: this.rule.name, 
      enabled: this.rule.enabled,
      eventTypeId: this.rule.typeId
    });

    if ( this.rule.id != null || this.rule.isDuplicate == true ){
      this.eventTypeEnabled = false;
      this.outputData = {};

      this.selectedDestination = this.rule.outputs[0].destinationId;      
      this.ruleForm.patchValue({destinationId: this.rule.outputs[0].destinationId});

      if ( this.rule.outputs[0].outputConfig && this.rule.outputs[0].outputConfig != "") {
        try{ 
          this.outputData = JSON.parse(this.rule.outputs[0].outputConfig);
        } catch(parseErr) {        
          console.log(parseErr);          
        }
      }
    }

    this.buildSeverityColTemplate();
    this.buildStatusColTemplate();
    // this.eventTypes = []      
    this.loadRuleData();
  }

  buildSeverityColTemplate(){
    this.severityTemplate = {
      create: () => {
        return createElement('input', { attrs: { 'type': 'text' } });
      },
      destroy: (args: { elementId: string }) => {
          let dropdown: DropDownList = (getComponent(document.getElementById(args.elementId), 'dropdownlist') as DropDownList);
          if (dropdown) {
              dropdown.destroy();
          }
      },
      write: (args: { elements: Element, values: string[] | string, operator: string }) => {
        console.log("severity col template write fn args: ");
        console.log(args);
        let dropDownObj: DropDownList = new DropDownList({
          dataSource: [{name: "Red", id: "Red"}, {name: "Yellow", id: "Yellow"}, {name: "Green", id: "Green"}, {name: "Unknown", id: "Unknown"}],
          value: args.values as string,
          change: (e: any) => {
            this.filterQueryBuilder.notifyChange(e.itemData.id, e.element);
          },
          fields: { text: 'name', value: 'id' },
          placeholder: 'Select a severity'
      });
      dropDownObj.appendTo('#' + args.elements.id);
      }
    };
  }

  buildStatusColTemplate(){
    this.statusTemplate = {
      create: () => {
        return createElement('input', { attrs: { 'type': 'text' } });
      },
      destroy: (args: { elementId: string }) => {
          let dropdown: DropDownList = (getComponent(document.getElementById(args.elementId), 'dropdownlist') as DropDownList);
          if (dropdown) {
              dropdown.destroy();
          }
      },
      write: (args: { elements: Element, values: string[] | string, operator: string }) => {
        console.log("status col template write fn args: ");
        console.log(args);        
        let dropDownObj: DropDownList = new DropDownList({
          dataSource: [{name: "In Progress", id: "In Progress"}, {name: "Resolved", id: "Resolved"}],
            value: args.values as string,
            change: (e: any) => {
              this.filterQueryBuilder.notifyChange(e.itemData.id, e.element);
            },
            fields: { text: 'name', value: 'id' },
            placeholder: 'Select a vendor'
        });
        dropDownObj.appendTo('#' + args.elements.id);
      }
    };
  }

  loadRuleData(){
    console.log('ner loadRuleData');
    this.spinnerService.toggle(true);

    forkJoin([
      this.vendorService.getAllVendors()
    ]).subscribe((results)=>{
      
      let vendors = results[0];
      // let eventRules = results[1];

      this.vendorTemplate = {
        create: () => {
          return createElement('input', { attrs: { 'type': 'text' } });
        },
        destroy: (args: { elementId: string }) => {
            let dropdown: DropDownList = (getComponent(document.getElementById(args.elementId), 'dropdownlist') as DropDownList);
            if (dropdown) {
                dropdown.destroy();
            }
        },
        write: (args: { elements: Element, values: string[] | string, operator: string }) => {
          console.log("args");
          console.log(args);
          let ds = vendors.map((v)=>{return {name: v.name, id: v.id + ""}});
          console.log('args.values');
          console.log(args.values);
          let dropDownObj: DropDownList = new DropDownList({
              dataSource: ds,
              value: args.values as string,
              change: (e: any) => {
                this.filterQueryBuilder.notifyChange(e.itemData.id, e.element);
              },
              fields: { text: 'name', value: 'id' },
              placeholder: 'Select a vendor'
          });
          dropDownObj.appendTo('#' + args.elements.id);
        }
      };
      
      this.spinnerService.toggle();

    },
    (err)=>{
      console.log(err);
    });
  }

  cancel(){
    this.cancelEvent.emit('cancel!');
  }

  save() {
    
    let queryObj = this.filterQueryBuilder.getValidRules(this.filterQueryBuilder.rule);
    console.log(queryObj);
    let query = JSON.stringify(queryObj);
    // if (queryObj.rules.length == 1 ){
    //   let props = Object.keys(queryObj.rules[0]);
    //   if ( props.length > 0 && queryObj.rules[0].condition && queryObj.rules[0].rules ){
    //     query = JSON.stringify(queryObj);
    //   }
    // }
  
    this.spinnerService.toggle(true);
    
    let ruleData = {
      eventTypeId: this.ruleForm.value.eventTypeId,
      enabled: this.ruleForm.value.enabled == true ? true : false,
      name: this.ruleForm.value.name, 
      filterConfig: query, 
      conditionCode: '',
      uiJSON: '',
      associationJSON: '',
      customRule: false,
      actionCode: '',
      actionConfig: '',
      destinationTypeId: this.selectedDestination,
      destinationConfig: JSON.stringify(this.getOutputControl().getValue())
    };

    console.log(ruleData);
    
    if ( this.rule.id == null ) {

      this.eventRuleService.createEventRule([ruleData]).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
        this.saveEvent.emit({type: 'new', state: 'success'});
      },
      (err)=>{
        console.log(err);
        this.spinnerService.toggle();
      });
    } else {
      let updatedRuleData = {
        id: this.rule.id,
        name: this.ruleForm.value.name,
        enabled: this.ruleForm.value.enabled == true ? true : false,
        filterConfig: query,
        customRule: false,
        outputId: this.rule.outputs[0].outputId,
        destinationConfig: JSON.stringify(this.getOutputControl().getValue())
      }

      this.eventRuleService.updateEventRule([updatedRuleData]).subscribe((updateResponse)=>{
        console.log(updateResponse);
        this.spinnerService.toggle();
        this.saveEvent.emit({type: 'update', state: 'success'});
      },
      (err)=>{
        console.log(err);
        this.spinnerService.toggle();
      });
    }
  }

  eventChange(selectedId){
    console.log('ner eventChange');
    console.log('eventChange', selectedId);
    if ( !selectedId || !this.eventTypes || this.eventTypes.length == 0 ) return;  
    this.selectedEventTypeName = this.eventTypes.filter((item)=>{ return item.id == selectedId;})[0].name;
    
    this.filterQueryBuilder.columns = EventRuleService.getEventTemplatePropsForEventType(this.selectedEventTypeName);

    console.log(this.filterQueryBuilder.columns);

    let vendorCol = this.filterQueryBuilder.columns.filter((c) => {
      return c.label == 'Vendor'
    });
    if ( vendorCol && vendorCol.length == 1 ) {
      vendorCol[0].template = this.vendorTemplate;
    }

    let severityCol = this.filterQueryBuilder.columns.filter((c) => {
      return c.label == 'Severity'
    });
    if ( severityCol && severityCol.length == 1 ){
      let col = severityCol[0]
      col.template = this.severityTemplate;
      col.operators = [{key: "Is", value: "equal"},{key: "Is Not", value: "notequal"}]
    }

    let statusCol = this.filterQueryBuilder.columns.filter((c) => {
      return c.label == 'Status'
    });
    if ( statusCol && statusCol.length == 1 ){
      let col = statusCol[0]
      col.template = this.statusTemplate;
      col.operators = [{key: "Is", value: "equal"},{key: "Is Not", value: "notequal"}]
    }

    console.log('template set');
    
    if ( !this.rule.id && this.rule.isDuplicate != true ){
      this.filterQueryBuilder.reset();
      this.filterQueryBuilder.refresh();
    } else {
      try{
        this.filterConfig = JSON.parse(this.rule.filterConfig);
        setTimeout(() => {
          this.filterQueryBuilder.setRules(this.filterConfig);  
        }, 200);
        
      }catch(err){
        console.log('errror !!!!!')
        console.log(err);
      }
    }    
  }

  getOutputControl():IEventOutput {
    if ( this.selectedDestination == 3 ) {
      return this.webhook as IEventOutput;
    } else if ( this.selectedDestination == 1 ) {
      return this.email as IEventOutput;
    } else if ( this.selectedDestination == 4 ) {
      return this.sms as IEventOutput
    }
    
    return null;
  }
  
}