import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { Data } from "../models/Data";
import { VendorsService } from "../services/vendors/vendors.service";
import { tap } from "rxjs/operators";

@Injectable()
export class DataResolver implements Resolve<Data[]> {
    
    constructor(private vendorService:VendorsService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<Data[]> | Data[] {
        
        this.spinnerService.toggleRoot(true);
        
        console.log("Data Resolver", route);
        
        const vendor = route.params['vendor'];
        const domainId = route.params["domainId"];
        
        return this.vendorService.getDataForDomain(vendor, domainId).pipe(tap(()=>{
            this.spinnerService.toggleRoot(false);
        }));
    }
}