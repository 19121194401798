import { Component, OnInit, ViewChild } from '@angular/core';
import { DomainDefinition } from '../models/DomainDefinition';
import { CustomerService } from '../../../services/customer/customer.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { VendorLibraryStateService } from '../../../services/vendorLibrary/vendor-library-state.service';
import { Query } from '@syncfusion/ej2-data';
import { environment as env } from "../../../../environments/environment";
import { QueryService } from '../../../services/query/query.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { Papa } from 'ngx-papaparse';
import { concatMap, delay, mergeMap, retryWhen, scan, take } from 'rxjs/operators';
import { concat, forkJoin, of, throwError } from 'rxjs';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
declare var $:any;

@Component({
  selector: 'app-domain-definition-detail',
  templateUrl: './domain-definition-detail.component.html',
  styleUrls: ['./domain-definition-detail.component.css']
})
export class DomainDefinitionDetailComponent implements OnInit {

  @ViewChild('resultsGrid') public resultsGrid: GridComponent;
  model:DomainDefinition;

  pageSettings;
  editSettings;
  resourceMatcherEditSettings;
  resourceMatcherToolbar;
  toolbar;
  selectOptions;
  commands;
  matcherAttributeKeyEditParams;
  matcherAttributeKeys;
  matcherAttributeKeysById;
  runResult:any;
  manifestFile:any;
  exportUrl:string;

  public resultsGridPageSettings:PageSettingsModel;
  public resultsGridFilterSettings: Object;

  constructor(
    public vendorLibService:VendorLibraryService,
    public vendorLibState:VendorLibraryStateService,
    public vendorService:VendorsService,
    public customerService:CustomerService,
    public spinnerService:SpinnerService,
    public notificationService:VaultNotificationService,
    private route:ActivatedRoute,
    private router:Router,
    private siteMapService:SitemapService,
    private queryService:QueryService,
    public dataExportService:DataExportService,
    public papa:Papa,

  ) { 
    this.resultsGridFilterSettings = { type: 'Excel' };
    this.siteMapService.update(this.route);
    this.editSettings = { 
      showDeleteConfirmDialog: true, 
      allowEditing: false, 
      allowAdding: true, 
      allowDeleting: true, 
      newRowPosition: 'Top'
    };
    this.toolbar = ['Add', 'Delete', 'Cancel'];

    this.resourceMatcherEditSettings = { 
      showDeleteConfirmDialog: true, 
      allowEditing: true, 
      allowAdding: true, 
      allowDeleting: true, 
      mode: 'Normal', 
      allowEditOnDblClick: true,
      newRowPosition: 'Top'
    };
    this.resourceMatcherToolbar = ['Add', 'Cancel'];

    this.pageSettings = {
      currentPage: 1, 
      pageSize: 50, 
      pageCount: 4, 
      pageSizes: [50, 75, 100]
    };
    this.selectOptions = { type: 'Single' };

    this.commands = [
      { type: 'ResourceMatcherDetails', buttonOption: { iconCss: ' icon zmdi zmdi-info', cssClass: 'e-flat', disabled: false }},
      { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
      { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
      { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }
    ];

    this.matcherAttributeKeyEditParams = {
      params:   {
        allowFiltering: true,        
        fields: { text:'name', value:'id' },
        query: new Query(),
        actionComplete: () => false
      }
    };
    
    // So UI errors don't show up in the console
    this.model = new DomainDefinition(0, "", 0, false);
  }

  ngOnInit(): void {  
    this.loadData();
  }

  loadData(){
    this.spinnerService.toggle(true);
    forkJoin([
      this.vendorLibService.getAllMatcherAttributeKeys()
    ]).subscribe((results)=>{
      this.vendorLibService.getDomainDefinition(this.vendorLibState.selectedDomainDefinition.value.id).subscribe(item => {
        this.model = item[0];
        this.spinnerService.toggle();
       });
      this.matcherAttributeKeysById = this.vendorLibService.matcherAttributeKeysById;
      this.matcherAttributeKeyEditParams.params.dataSource = this.vendorLibService.matcherAttributeKeys;
    });
  };

  commandClick(event) {
    console.log("commandClick", event);
    if ( event.commandColumn.type == 'ResourceMatcherDetails'){
      this.vendorLibState.selectedResourceMatcher.next(event.rowData);
      this.router.navigate(["resourceMatcher", event.rowData.id], {relativeTo: this.route});
    }    
  }

  domainMatcherActionBegin(event){
    if ( event.action == 'add' ) {
      this.spinnerService.toggle(true);
      this.vendorLibService.createDomainMatcher(this.model.id, event.data).subscribe((response)=>{
        console.log(response);
        this.spinnerService.toggle();
      })    
    } else if ( event.requestType == 'delete' ){
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteDomainMatcher(this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
      });
    }
  }

  defaultAttributeActionBegin(event){
    if ( event.action == 'add' ) {
      this.spinnerService.toggle(true);
      this.vendorLibService.createDefaultAttribute(this.model.id, event.data).subscribe((response)=>{
        console.log(response);
        this.spinnerService.toggle();
      })    
    } else if ( event.requestType == 'delete' ){
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteDefaultAttribute(this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
      });
    }
  }

  resourceMatcherActionBegin(event){
    console.log(event.type, event.requestType);
    console.log("actionBegin", event);
    if ( event.action == 'add' ) {
      this.spinnerService.toggle(true);
      this.vendorLibService.createResourceMatcher(this.model.id, event.data).subscribe((response)=>{
        console.log(response);
        this.loadData();
      })
    } else if ( event.action == 'edit' ){
      console.log('EDIT');
      let changes = this.vendorLibService.getChangedProperties(event.rowData, event.data);
      if ( changes ){
        this.spinnerService.toggle(true);
        this.vendorLibService.updateResourceMatcher(this.model.id, changes).subscribe((response)=>{
          console.log(response);
          this.loadData();
        });
      } else {
        this.notificationService.info("Info", "No changes were detected to save.")
      }
    } else if ( event.requestType == 'delete' ){
      console.log('DELETE');
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteResourceMatcher(this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.loadData();
      });
    } else if ( event.type == 'actionComplete' && event.requestType == 'add'){
      this.commands[0].buttonOption.disabled = true;
    } else if (event.type == 'actionBegin' && event.requestType == 'cancel') { 
      this.commands[0].buttonOption.disabled = false;
    }
  }

  onTestClick(){
    console.log("onTestClick", this.model.id);
    this.spinnerService.toggle(true);
    this.vendorLibService.testDomainDefinition(this.model.id).subscribe((result)=>{
      console.log(result);
      if ( !result.error ) {
        this.notificationService.info("Info", "Query was started successfully. Once results are avaiable you will be notified.")
        this.runResult = result;
        this.checkStatus();
      } else {
        this.notificationService.error("Uh oh...", "An unexpected error ocurred running the query.");
      }      
      this.spinnerService.toggle(false);
    });

  }

  checkStatus(){
    // this.spinnerService.toggle(true);
    this.queryService.runQuery(this.model.id, this.runResult.dataId, this.runResult.uuid, "athena")
    .pipe(          
      retryWhen(result =>        
        result.pipe(
          scan((acc, error) => ({ count: acc.count + 1, error }), {
            count: 0,
            error: undefined as any,
          }),
          concatMap(result => {
            // here we can check the error.
            // We can specify the retry only if we are getting 5xx errors for instance.
            if (result.error.status === 503) {
              return of(result);
            }
            // in other cases we throw an error down the pipe
            return throwError(result);
          }),
          mergeMap( payload =>{
            return of(payload).pipe(delay(1000 * payload.count))
          }),
          // we can keep calling forever but usually we want to avoid this.
          // So, we set the number of attempts including the initial one.
          take(15),
          o => concat(o, throwError({error:`Unable to load data. Please try again or contact support`}))
        )
      ),
    ).subscribe((runResult)=>{
      console.log(runResult);
      if (runResult.message && runResult.message == 'Complete' ) {                  
        this.dataExportService.getSignedUrl(`${env.aws.exportBucket}/${localStorage.cid}/query/${this.runResult.dataId}.csv`).subscribe((url)=>{this.exportUrl = url;});
        this.loadCSV(`${localStorage.cid}/query/${this.runResult.dataId}.csv`);       
      } else if ( runResult.error ){
        this.notificationService.error("Uh oh...", runResult.error);
        this.spinnerService.toggle(false);
      }
    });
  }

  loadCSV(url) {  
    url = url.replace("s3://", "").replace(env.aws.exportBucket + "/", "");
    console.log("url");
    console.log(url);
    this.dataExportService.getQueryResultFile(url).subscribe((queryResults)=>{
      let csv  = queryResults.toString();
      this.papa.parse(csv, {
        header: true,
        skipEmptyLines: "greedy",
        complete: (results, file)=>{
          
          console.log("Papa results:");
          console.log(results);

          // this.data = results.data;

          this.spinnerService.toggle(false);

          // this.columnDefs = this.manifestFile.schema.elements.map((field)=>{
          //   return {field: field.name, sortable: true, filter: true, resizable: true}
          // });
          
          // this.rowData = this.data;
          
          this.resultsGrid.dataSource = [];
          this.resultsGrid.columns = [];

          this.resultsGrid.dataSource = results.data;

          setTimeout(() => {
            // this.grid.refresh();
            this.resultsGrid.autoFitColumns();
          }, 100);
          // $('#modal-query-results').modal('show');

          let toast = this.notificationService.success("Query Complete", "The query has completed. Click here to view data.", 0);
          toast.click.subscribe((event)=>{
            $('#modal-query-results').modal('show');
          });

        },
        error: (error, file)=>{
          console.log("Papa error:")
          console.log(error);

          this.spinnerService.toggle(false);
        }
      });
    });
  }

}
