<div class="container">
    <div class="form-group row">
        <label for="search" class="col-sm-2 col-form-label" style="text-align: right;">Search</label>
        <div class="col-sm-6">
            <input type="text" [(ngModel)]="searchValue" class="form-control" id="search" />
        </div>
        <div class="border">
            <a routerLink='/newVendor'><button class="btn btn-md btn-outline-primary">New Vendor</button></a>
        </div>        
        <button class="btn btn-rounded btn-space btn-success" style="margin-left: 30px;" (click)="reclassifyClick();">Reclassify Code Files</button>
    </div>
</div>

<table id="vendors">
    <thead>
        <tr>
            <th>ID</th>
            <th>Vendor Name</th>
            <th>Icon</th>
            <th>Website</th>
            <th>Actions</th>
        </tr>  
    </thead>
    <tbody>
    <tr *ngFor= 'let listing of listings | searchfilterdomains: searchValue'>
        <td>{{listing.id}}</td>
        <td><a [routerLink]="['/vendorDomains', listing.id ]" (click)="onVendorClick(listing);">{{listing.name}}</a></td>
        <td style="text-align: center;"><img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/vendors/icon/{{ listing.website ? listing.website.toLowerCase() : 'unknown' }}.ico"/></td>
        <td>{{listing.website}}</td>
        <td>
            <a [routerLink] = "['/vendorEdit',listing.name]"><button class="btn btn-primary"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button></a>
            <button (click)="onDeleteClicked(listing.id)" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
        </td>
        </tr>
    </tbody>
</table>