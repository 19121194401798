import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IPointRenderEventArgs, ITooltipRenderEventArgs, ChartShape } from '@syncfusion/ej2-angular-charts';
import { environment as env } from "../../../../../../environments/environment";

@Component({
  selector: 'app-col-bar-chart-panel',
  templateUrl: './col-bar-chart-panel.component.html',
  styleUrls: ['./col-bar-chart-panel.component.css']
})
export class ColBarChartPanelComponent implements OnInit, OnChanges {
  @Input('title') title: string;
  @Input('yLabel') yLabel: string;
  @Input('data') chartData: any;
  @Input('name') name: string;

  public primaryXAxis?: any;
  public primaryYAxis: any;
  public titleStyle: any;
  public legendSettings: any;
  public chartArea: any;
  public tooltip: any;
  public margin: any;
  public series: any;
  private cdnUrl: string;
  public marker = {
    visible: true,
    width: 25,
    height: 25,
    dataLabel: {
      visible: true,
      font: {
        fontFamily: 'Sans-Serif',
      }
    }
  };

  constructor() {
    this.title = 'No Data Found';
    this.primaryXAxis = {
      // visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      },
      valueType: 'Category',
      labelIntersectAction: 'Rotate90',
      titleStyle: {
        fontFamily: 'Sans-Serif',
      },
      labelStyle: {
        fontFamily: 'Sans-Serif',
      }
    };
    this.primaryYAxis = {
      // visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      },
      labelStyle: {
        fontFamily: 'Sans-Serif',
      },
      titleStyle: {
        fontFamily: 'Sans-Serif',
      }
    };
    this.titleStyle = {
      fontFamily: 'Sans-Serif',
    };
    this.legendSettings = { textStyle: { fontFamily: 'Sans-Serif' } };
    this.chartArea = {
      border: { width: 0 },
      title: this.title
    };
    this.margin = {
      top: 25,
      right: 25,
      bottom: 25,
      left: 25
    };
    this.cdnUrl = env.cdn.url;
  }

  ngOnInit(): void {
    this.chartArea.title = this.title;
    this.primaryYAxis.title = this.yLabel;

    this.tooltip = {
      enable: true,
      shared: true,
    };
  }

  ngOnChanges(): void {
    this.updateData();
  }

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    const point = args.series.dataSource[args.data.pointIndex];
    args.text = "Attributes: " + "<b>" + point.y + "</b>";
    args.textStyle.fontFamily = 'Sans-Serif';
    // console.log(args.text)
  }

  updateData(): void {
    if (this.chartData.length) {
      if (this.chartData.length > 30) {
        this.marker = {
          visible: true,
          width: 15,
          height: 15,
          dataLabel: {
            visible: true,
            font: {
              fontFamily: 'Sans-Serif',
            },
          }
        };
      }
      this.series = this.chartData.map((data) => {
        return {
          x: data.vendor,
          y: data.totUniqInst
        }
      });
    }
  }

  public pointRender(args: IPointRenderEventArgs): void {
    const data = this.chartData[args.point.index];
    const imgUrl = `https://${this.cdnUrl}/vendors/icon/${data.website ? data.website.toLowerCase() : 'unknown'}.ico`;
    args.point.marker.shape = <ChartShape>'Image';
    args.point.marker.imageUrl = imgUrl;
  }

}
