<ejs-chart id="chart-container" 
    [primaryXAxis]='primaryXAxis'
    [primaryYAxis]='primaryYAxis' 
    [title]='title' 
    [tooltip]='tooltip' 
    (pointRender)="pointRender($event);"
    (selectionComplete)="selectionComplete($event);"
    selectionMode="Point"
    >
    <e-series-collection>
        <e-series [dataSource]='series1' type='Scatter' xName='x' yName='fileCount' opacity=0.7 [marker]='marker'></e-series>
    </e-series-collection>
</ejs-chart>
<h4>Details</h4>
<ejs-grid #grid 
    id='detailsGrid' 
    [dataSource]='selectedVendor'                 
    [enableHover]='false' 
    [allowSelection]='false'
>
    <e-columns>
        <e-column field='name' headerText='Validation'></e-column>
        <e-column field='description' headerText='Description'></e-column>
        <e-column field='passing' headerText='Status'></e-column>
        <e-column field='results.passing' headerText='Tests Passing'></e-column>
        <e-column field='results.failing' headerText='Tests Failing'></e-column>
        <e-column headerText='Actions' width='200' textAlign='Center'>
            <ng-template #template let-data>
                <ejs-dropdownlist id='actions' #actions [dataSource]='actionList' [value]='value' [fields]='fields' [placeholder]='waterMark' [popupHeight]='height'></ejs-dropdownlist>
            </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>