import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'highlighted-value-display',
  templateUrl: './highlighted-value-display.component.html',
  styleUrls: ['./highlighted-value-display.component.css']
})
export class HighlightedValueDisplayComponent implements OnInit {
  @Input('value') value: string;
  @Input('label') label: string;
  constructor() { }

  ngOnInit(): void {
  }

}
