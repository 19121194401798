<div class="card" style="height: 160px;">
    <div class="card-header" style=" font-size: large; width: fit-content;  margin:auto">{{title}}</div>
    <div class="card-body" style="display: flex; flex-direction: row; width: fit-content;  margin:auto ">
        <div class="card-lhs" style="padding: 0; margin: 0; padding-left: 10px;">
            <div
                style="padding: 0; margin:auto; border: 0px solid red; width: fit-content; margin:auto">
                <div style="font-size: 4rem" [class.increase]="value>0" [class.decrease]="value===0">{{value}}</div>
            </div>

        </div>
        <div class="card-rhs col-lg" style="border: 0px solid red; position: relative;margin: auto;">
            <span style="font-size: large;" [class.increase]="change>0" [class.nochange]="change===0"
                [class.decrease]="change<0">{{changeText}}</span>
            <span [hidden]="change === 0" style="font-size: large; margin-left: 3px;" class="zmdi"
                [class.increase]="change>0" [class.decrease]="change<0" [class.zmdi-trending-down]="change<0"
                [class.zmdi-trending-up]="change>0"></span>
            <br />
            <span style="color:grey">since last report</span>
        </div>
    </div>

</div>