import { Component, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import {
  ITooltipRenderEventArgs,
  Series,
} from '@syncfusion/ej2-angular-charts';
import { DataValidationService } from '../../../services/data-validation/data-validation.service';
import { Ej2TestSuiteProxyService } from '../../../services/ej2Proxies/ej2-test-suite-proxy.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { Ej2RuleHistoryService } from '../../../services/ej2Proxies/ej2-rule-history.service';
import { concat, Observable, of, throwError } from 'rxjs';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-validation-overview',
  templateUrl: './validation-overview.component.html',
  styleUrls: ['./validation-overview.component.css'],
})
export class ValidationOverviewComponent implements OnInit {
  public primaryXAxis?: Object;
  public title?: string;
  public primaryYAxis?: Object;
  public legendSettings = { visible: true, position: 'Top' };
  public palette = ['#fc7d73', '#40ebc2'];
  public border = { width: 2, color: '#FF0000' };
  public chartArea: any;
  public tooltip: any;

  public test: any;
  public hasTestSuites: boolean;
  public hasValidationRules: boolean;

  constructor(
    private dataValidationService: DataValidationService,
    private ej2TestSuiteProxyService: Ej2TestSuiteProxyService,
    private spinnerService: SpinnerService,
    private ej2RuleHistoryService: Ej2RuleHistoryService,
    private router: Router
  ) {
    this.tooltip = {
      enable: true,
      fill: '#ffffffff',
      opacity: '#ffffff',
      textStyle: {
        color: 'black',
        fontFamily: 'sans-serif',
      },
    };
    this.primaryXAxis = {
      valueType: 'DateTimeCategory',
      labelFormat: 'dd MMM `yy',
      labelIntersectAction: 'Hide',
      edgeLabelPlacement: 'Hide',
      majorGridLines: {
        width: 0,
      },
      minorGridLines: {
        width: 0,
      },
      labels: {
        visible: false,
      },
    };
    this.primaryYAxis = {
      majorGridLines: {
        color: 'red',
        width: 0,
      },
      minorGridLines: {
        color: 'red',
        width: 0,
      },
    };
    this.border = { width: 2, color: '#FF0000' };
    this.chartArea = {
      border: {
        width: 0,
      },
    };
    this.hasTestSuites = true;
    this.hasValidationRules = true;
  }
  public testSuites$;
  public numRules = 0;

  public rulesList: any[] = [];
  public takeValueList = [100, 500, 1000, 5000];
  public selectedTakeValue = 100;
  public selectedRule = null;
  public selectedData:any = {
    rule: null,
    history: [],
    hasHistory: false,
    lastChecked: null,
    progress: 0,
    passedContent: [],
    failedContent: [],
    currentPassed: null,
    currentFailed: null,
    minPassed: null,
    maxPassed: null,
    minFailed: null,
    maxFailed: null,
    timeFrameString: null,
    showPassed: true,
    type: null,
    totalResults: null,
  };
  public selectedHistory = null;

  public selectedRuleObj = null;
  public hasHistory = false;

  public selectedDateIndex = null;

  public dropDownTemplate =
    '<div style="display: flex; flex-direction: column; padding:3px; gap: 2px"> <div> ${rule.name} </div><div style=" font-size: x-small;"> ${testSuite.name} </div> </div>';

  private groupHistoryByData(data: any[]) {
    let output = [];
    // group by month-year key

    data.forEach((item) => {
      const date = new Date(item.epoch);
      const dateIndex = output.findIndex((dateItem) => {
        return (
          // dateItem.date.getMonth() === date.getMonth() &&
          // dateItem.date.getFullYear() === date.getFullYear()
          dateItem.date.toDateString() === date.toDateString()
        );
      });
      if (dateIndex < 0) {
        output.push({
          date: date,
          passed: item.validation_success === true ? 1 : 0,
          failed: item.validation_success === true ? 0 : 1,
          passedContent: item.validation_success === true ? [item] : [],
          failedContent: item.validation_success === true ? [] : [item],
        });
      } else {
        output[dateIndex].passed += item.validation_success === true ? 1 : 0;
        output[dateIndex].failed += item.validation_success === true ? 0 : 1;
        output[dateIndex].passedContent =
          item.validation_success === true
            ? [...output[dateIndex].passedContent, item]
            : output[dateIndex].passedContent;
        output[dateIndex].failedContent =
          item.validation_success === true
            ? output[dateIndex].failedContent
            : [...output[dateIndex].failedContent, item];
      }
    });
    let hasHistory = true;
    if (output.length === 0) {
      output.push({
        date: new Date(),
        passed: 0,
        failed: 0,
        passedContent: [],
        failedContent: [],
      });
      hasHistory = false;
    }

    output.sort((a, b) => {
      return a.date - b.date;
    });

    // if (output.length > 30) {
    //   output.splice(30, output.length - 30);
    // }

    return { hasHistory, output };
  }

  public onTooltipRender(args: ITooltipRenderEventArgs) {
    args.headerText = '';
    const series = args.data.seriesName;
    const date = new Date(Date.parse(args.data.pointX.toString()));
    args.text = `${date.toDateString()}<br/>${series}: ${args.data.pointY}`;
  }

  private isIDValidationRule(tsName) {
    let isRule = false;
    if (tsName === 'ID Validations') {
      isRule = true;
    }
    return {
      name: isRule ? 'ID' : null,
      idList: {},
    };
  }

  private updateIDList(typeObj, content) {
    const obj = {
      allowedIds: [],
      unknownIds: [],
      pendingIds: [],
    };
    const idListObj = {
      allowedIds: [],
      unknownIds: [],
      pendingIds: [],
    };
    content.forEach((item) => {
      const idObj = this.extractIDFromMessage(item.validation_message);
      if (idObj) {
        obj.allowedIds.push(...idObj.allowedIds);
        obj.unknownIds.push(...idObj.unknownIds);
        obj.pendingIds.push(...idObj.pendingIds);
      }
    });
    obj.unknownIds.forEach((item) => {
      if(idListObj.unknownIds.includes(item)) {
        return;
      }
      idListObj.unknownIds.push(item);
    });
    obj.pendingIds.forEach((item) => {
      if(idListObj.pendingIds.includes(item)) {
        return;
      }
      idListObj.pendingIds.push(item);
    });
    obj.allowedIds.forEach((item) => {
      if(idListObj.allowedIds.includes(item)) {
        return;
      }
      idListObj.allowedIds.push(item);
    });
    typeObj.idList = idListObj;
    console.log('TYPE OBJ: ', typeObj);
    return typeObj;
  }

  private extractIDFromMessage(message) {
    try {
      const idObj = JSON.parse(message);
      console.log('ID OBJ FOUND: ', idObj);
      return idObj;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public IDMessageFormatter(message) {
    const idObj = this.extractIDFromMessage(message);
    if (idObj) {
      const messageArray = [];
      if (idObj.allowedIds.length > 0) {
        messageArray.push(
          `Allowed IDs: ${idObj.allowedIds.map((item) => item).join(', ')}`
        );
      } else {
        messageArray.push(`Allowed IDs: No Ids Found`);
      }
      if (idObj.unknownIds.length > 0) {
        messageArray.push(
          `Unknown IDs: ${idObj.unknownIds.map((item) => item).join(', ')}`
        );
      } else {
        messageArray.push(`Unknown IDs: No Ids Found`);
      }
      if (idObj.pendingIds.length > 0) {
        messageArray.push(
          `Pending IDs: ${idObj.pendingIds.map((item) => item).join(', ')}`
        );
      } else {
        messageArray.push(`Pending IDs: No Ids Found`);
      }
      return messageArray.join('\n');
    }

    return null;
  }

  public copyToClipboard(value) {
    navigator.clipboard.writeText(value);
  }

  public idListJoin(idList) {
    return idList.join(',');
  }

  private async GetRulesList() {
    console.log('GETTING RULES LIST');
    this.spinnerService.toggle(true);
    this.testSuites$ = this.ej2TestSuiteProxyService.pipe();
    this.ej2TestSuiteProxyService.execute({});
    this.testSuites$.subscribe(async (testSuiteData) => {
      const testSuiteIds = testSuiteData.result.map((item) => {
        return item.id;
      });
      const rules = [] as Promise<any>[];
      if (testSuiteIds.length === 0) {
        this.hasTestSuites = false;
        this.spinnerService.toggle(false);
        return;
      }
      testSuiteIds.forEach((id, index) => {
        console.log('GETTING RULES FOR TEST SUITE: ', id);
        rules[index] = this.dataValidationService
          .getRulesForTestSuite(id, 0, 100, 'name', 'asc', '', 1)
          .pipe()
          .toPromise();
      });

      await Promise.all(rules)
        .then((ruleData) => {
          console.log('ALL PROMISES RESOLVED');
          ruleData.forEach((item, index) => {
            item.data.forEach((rule) => {
              const ruleObj = {
                rule: rule,
                testSuite: testSuiteData.result[index],
              };

              this.rulesList.push(ruleObj);
            });
          });
        })
        .catch((err) => {
          console.log('ERROR: ', err);
        });
      this.spinnerService.toggle(false);
    });
  }

  private async LoadRuleData(ruleObj) {
    const rule = ruleObj.rule;
    const testSuite = ruleObj.testSuite;
    this.dataValidationService.selectedValidationRule.next(rule);
    const ruleHistoryService = new Ej2RuleHistoryService(
      this.dataValidationService,
      new SpinnerService()
    );
    const historydata = ruleHistoryService.pipe();
    ruleHistoryService.execute(
      {
        skip: 0,
        take: this.selectedTakeValue || 100,
      },
      rule.uuid
    );
    return new Promise<any>((resolve, reject) => {
      historydata.subscribe(
        (history) => {
          const historyparsed = this.groupHistoryByData(history.result);
          const historyDataGrouped = historyparsed.output;
          const hasHistory = historyparsed.hasHistory;
          const output: any = {};
          output['type'] = this.isIDValidationRule(testSuite.name);
          output['rule'] = rule;
          output['testSuite'] = testSuite;
          output['history'] = historyDataGrouped;
          output['totalResults'] = history.result.length;
          output['hasHistory'] = hasHistory;
          output['timeFrameString'] = this.ParseTimeFrame(historyDataGrouped);
          output['lastChecked'] = historyDataGrouped[historyDataGrouped.length-1]?.date;
          output['currentPassed'] = historyDataGrouped[historyDataGrouped.length-1]?.passedContent.length;
          output['currentFailed'] = historyDataGrouped[historyDataGrouped.length-1]?.failedContent.length;
          if (output['currentPassed'] === 0) {
            output['showPassed'] = false;
          } else {
            output['showPassed'] = true;
          }
          output['minPassed'] = Math.min(
            ...historyDataGrouped.map((item) => {
              return item.passedContent.length;
            })
          );
          output['maxPassed'] = Math.max(
            ...historyDataGrouped.map((item) => {
              return item.passedContent.length;
            })
          );

          output['minFailed'] = Math.min(
            ...historyDataGrouped.map((item) => {
              return item.failedContent.length;
            })
          );

          output['maxFailed'] = Math.max(
            ...historyDataGrouped.map((item) => {
              return item.failedContent.length;
            })
          );

          output['progress'] = 100;
          output.passedContent =
            historyDataGrouped[historyDataGrouped.length - 1]?.passedContent;
          output.failedContent =
            historyDataGrouped[historyDataGrouped.length - 1]?.failedContent;
          this.selectedDateIndex = historyDataGrouped.length - 1;
          if (output.type.name === 'ID') {
            console.log('UPDATING ID LIST');
            output.type = this.updateIDList(output.type, [
              ...output.passedContent,
              ...output.failedContent,
            ]);
          }
          resolve(output);
        },
        (err) => {
          console.log(err);
          reject();
        }
      );
    });
  }

  public updateTakeValue($event) {
    this.selectedTakeValue = $event.itemData.value;
    console.log('TAKE VALUE: ', this.selectedTakeValue);
  }

  public reloadRuleData() {
    this.spinnerService.toggle(true);
    this.LoadRuleData(this.selectedRuleObj)
      .then((data) => {
        this.selectedData = data;
        this.spinnerService.toggle(false);
      })
      .catch((err) => {
        this.spinnerService.toggle(false);
        console.log(err);
      });
  }

  private ParseTimeFrame(histData) {
    const first = histData[0].date;
    const last = histData[histData.length - 1].date;
    return `Showing Results from ${first.toDateString()} - ${last.toDateString()}`;
  }

  public onHistorySelect($event) {
    console.log('SELECTED HISTORY: ', $event);
    this.selectedHistory = $event.itemData;
    const clickedDate = $event.point.x;
    const historyValues = this.selectedData.history.find((item) => {
      return Date.parse(item.date) === Date.parse(clickedDate);
    });
    const seriesIndex = $event.seriesIndex;
    if (seriesIndex === 0) {
      this.selectedData.showPassed = false;
    } else {
      this.selectedData.showPassed = true;
    }
    this.selectedDateIndex = $event.point.index;
    this.selectedData.passedContent = historyValues.passedContent;
    this.selectedData.failedContent = historyValues.failedContent;
    this.selectedData.currentPassed = historyValues.passedContent.length;
    this.selectedData.currentFailed = historyValues.failedContent.length;
    this.selectedData.lastChecked = historyValues.date;

    if (this.selectedData.type.name === 'ID') {
      this.selectedData.type = this.updateIDList(this.selectedData.type, [
        ...this.selectedData.passedContent,
        ...this.selectedData.failedContent,
      ]);
    }
  }

  public onLatestResults() {
    const index = this.selectedData.history.length - 1;
    this.selectedDateIndex = index;
    this.selectedData.passedContent =
      this.selectedData.history[index].passedContent;
    this.selectedData.failedContent =
      this.selectedData.history[index].failedContent;
    this.selectedData.currentPassed =
      this.selectedData.history[index].passedContent.length;
    this.selectedData.currentFailed =
      this.selectedData.history[index].failedContent.length;
    this.selectedData.lastChecked = this.selectedData.history[index].date;

    if (this.selectedData.currentFailed === 0) {
      this.selectedData.showPassed = true;
    } else {
      this.selectedData.showPassed = false;
    }
    if (this.selectedData.type.name === 'ID') {
      this.selectedData.type = this.updateIDList(this.selectedData.type, [
        ...this.selectedData.passedContent,
        ...this.selectedData.failedContent,
      ]);
    }
  }

  public OnRuleSelect($event) {
    this.spinnerService.toggle(true);
    if ($event.itemData === null) {
      console.log('NULL SELECTED');
    }
    this.selectedRule = $event.itemData.rule;
    this.selectedRuleObj = $event.itemData;
    console.log('SELECTED EVENT: ', $event);
    this.LoadRuleData($event.itemData)
      .then((data) => {
        this.selectedData = data;
        this.spinnerService.toggle(false);
      })
      .catch((err) => {
        this.spinnerService.toggle(false);
        console.log(err);
      });
  }

  public goToTestSuite() {
    this.router.navigate(['validation','testSuites', this.selectedData.testSuite.id]);
  }
  ngOnInit(): void {
    this.GetRulesList();
  }

}
