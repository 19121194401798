<div class="container">
    <div class="text-center" >
        <div class="form-group row">
            <input type="text" [(ngModel)]="hash1" class="form-control" id="hash1" placeholder="Hash 1"/>
        </div>
        <div class="form-group row">
            <input type="text" [(ngModel)]="hash2" class="form-control" id="hash2" placeholder="Hash 2"/>
        </div>
        <button class="btn btn-rounded btn-space btn-primary" style="margin-left: 30px;" (click)="doDiff();">Submit</button>
    </div>
</div>
<div id="diffDiv" [innerHtml]="outputDiffHtml"></div>