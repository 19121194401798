import { Component, Input, OnChanges, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { setTime } from '@syncfusion/ej2-angular-schedule';

declare var $: any;
@Component({
  selector: 'site-summary-panel',
  templateUrl: './site-summary-panel.component.html',
  styleUrls: ['./site-summary-panel.component.css'],
})
export class SiteSummaryPanelComponent implements OnChanges {
  @ViewChild('gridsite')
  public gridsite?: GridComponent;
  @Input('datasource') datasource: any[];
  public data: any = [];
  public isExpanded: boolean = false;
  public isLoaded: boolean;

  //   {
  //     page: 'page 1',
  //     id: '2385688852',
  //     isKeyPage: true,
  //     scanFrequency: 'Daily',
  //     lastChecked: 'Today',
  //     general_violations: [
  //       {
  //         type: 'GDPR',
  //         checked: true,
  //         count: 0,
  //       },
  //       {
  //         type: 'CCPA',
  //         checked: false,
  //         count: 1,
  //       },
  //       {
  //         type: 'LGPD',
  //         checked: true,
  //         count: 1,
  //       },
  //       {
  //         type: 'DCF',
  //         checked: true,
  //         count: 1,
  //       },
  //       {
  //         type: 'SOME_OTHER_METRIC',
  //         checked: true,
  //         count: 1,
  //       },
  //     ],
  //   },
  // ];
  public gRiskList: Object[] = [];
  public sortSettings: { columns: { field: string; direction: string }[] };
  public pageOptions: {
    pageSize: number;
    pageCount: number;
    pageSizes: number[];
  };
  public filterSettings: Object;
  refreshGraphs(event) {
    setTimeout(() => {
      $('.sparklinebasic').sparkline('html', {
        highlightSpotColor: 'black',
        highlightLineColor: 'black',
        fillColor: false,
        spotColor: false,
        minSpotColor: false,
        maxSpotColor: false,
        lineWidth: 1,
        enableTagOptions: true,
      });
      $('span[popHack]').popover('enable');
    }, 0);
  }

  refreshGraphs2(event) {
    setTimeout(() => {
      $('span[popHack]').popover('enable');
    }, 0);
  }

  constructor(private cd: ChangeDetectorRef) {
    // this.isLoaded = false;
    this.sortSettings = {
      columns: [],
    };
    this.filterSettings = {
      type: 'Excel',
    };
    this.pageOptions = {
      pageSize: 25,
      pageCount: 5,
      pageSizes: [25, 50, 75, 100, 200],
    };
    this.updateData();
  }

  updateData() {
    this.data = this.datasource || [];
    if (this.datasource?.length > 0) {
      this.gRiskList = this.datasource[0].violations.map((key) => {
        return {
          key: key.type,
          label: key.type
            .replace(/(?<!^)(?=[A-Z][a-z])/g, ' ')
            .replace('_', ' '),
        };
      });
      setTimeout(() => {
        this.isLoaded = true;
        console.log('site-summary-panel.component.ts updateData');
        this.cd.detectChanges();
      }, 100);
    } 
  }
  ngOnChanges(changes: any): void {
    this.isLoaded = false;
    this.updateData();
    console.log('site-summary-panel.component.ts ngOnChanges');
  }

  dateFormatting(string){
    const epoch = Date.parse(string);
    const date = new Date(epoch);
    return date.toUTCString();
  }
}
