import { CrudOptions, DataManager, DataOptions, DataResult, Query } from '@syncfusion/ej2-data';
import { VaultBaseUrlAdaptor } from '../../../services/VaultBaseUrlAdaptor';
import { TimezoneService } from '../../../services/timezone.service';

export class HashDataAdaptor extends VaultBaseUrlAdaptor {


    constructor(
        private timezoneService: TimezoneService,
    ) {
        super();
    }
    
    override processQuery(dm: DataManager, query: Query, hierarchyFilters?: Object[]): Object {
        let q = super.processQuery(dm, query, hierarchyFilters) as any;
        let queryObj = JSON.parse(q.data);
        queryObj["requestedDataType"] = "hashes"
        q.data = JSON.stringify(queryObj);

        return q;
    }

    override processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
        console.log(data, ds, query, xhr, request, changes);
        
        data.result = (data.result as any[]).map((item) => {
            return { ...item, formattedTimestamp: this.timezoneService.format(item.timestamp*1000, null, true, null, 'M/d/yy h:mm a ZZZZ') }
        });

        console.log(data);

        return data;
    }
}