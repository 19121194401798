import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ProgressBar } from '@syncfusion/ej2-progressbar';
import { EventEmitter } from '@angular/core';
import { ProgressBarService } from '../../../services/progressBarService/progress-bar.service';

@Component({
  selector: 'app-progress-display',
  templateUrl: './progress-display.component.html',
  styleUrls: ['./progress-display.component.css'],
})
export class ProgressDisplayComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() value: number = 0;
  @Input() maxValue: number = 1;
  @Input() unit: string = '';
  @Output() stopEvent: EventEmitter<any> = new EventEmitter();
  public isComplete: boolean = false;
  public displayValue: string = '';
  public indeterminate: boolean = false;

  constructor(private progressBarService: ProgressBarService) {
    this.progress = new ProgressBar({
      type: 'Circular',
      height: '60',
      width: '60',
      segmentCount: 10,
      trackThickness: 3,
      value: 0,
      animation: {
        enable: true,
        duration: 2000,
        delay: 0,
      },
      progressColor: '#4d545c',
      trackColor: '#e5ebe8',
    });


  }
  public progress: ProgressBar;
  ngOnInit(): void {}
  ngAfterViewInit() {
    this.progress.appendTo('#progress-container');
    this.progress.trackColor = '#e5ebe8';
    this.progress.progressColor = '#4d545c';
    this.progressBarService.resetEvent.subscribe(() => {
      console.log('reset event found');
      this.doReset();
    })
    this.progressBarService.completeEvent.subscribe(() => {
      console.log('complete event found');
      this.isComplete = true;
      this.value = 100;
      this.progress.trackColor = 'green';
      this.progress.progressColor = 'green';
      this.displayValue = 'Complete';

    })
    this.progressBarService.indeterminateEvent.subscribe((indeterminate: boolean) => {
      console.log('indeterminate event found');
      this.indeterminate = indeterminate;
    });
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.value) {
      this.progress.value = (this.value * 100) / this.maxValue;
      this.displayValue = `${this.value.toFixed(1)} ${this.unit}`;
    }
  }
  doReset() {
    console.log('reset');
    this.progress.value = 0;
    this.progress.trackColor = '#e5ebe8';
    this.progress.progressColor = '#4d545c';
    this.isComplete = false;
    this.displayValue = '';
  }

  clickHandler() {
    if (this.isComplete) {
      this.progressBarService.toggle(false);
      this.doReset();
    } else {
      this.stopEvent.emit(null);
    }
  }
}
