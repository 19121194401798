import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { environment as env } from "../../../../environments/environment";

import * as d3 from "d3";
declare var $:any;
import { PageService } from '../../../services/page/page.service';
import { forkJoin, of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ClipboardService } from 'ngx-clipboard';
import { catchError } from 'rxjs/operators';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { GridComponent, PageSettingsModel, SelectionSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';


@Component({
  selector: 'app-page-history-details',
  templateUrl: './page-history-details.component.html',
  styleUrls: ['./page-history-details.component.css']
})
export class PageHistoryDetailsComponent implements OnInit {

  vizLoading:boolean;
  dataLoading:boolean;
  selectedRows:any[];
  d3Viz:any;
  pageHistory:any;
  zoomFactor:number;    
  tableData:any[];
  vendorsData:any[];
  notificationOptions:any;
  nodes:any;
  vendors:any[];
  force:any;
  svg:any;
  allVendors = [];

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public sortSettingsVendors:SortSettingsModel;
  public selectionSettingsVendors:SelectionSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('fileGrid') public grid: GridComponent;
  @ViewChild('vendorsGrid') public vendorsGrid: GridComponent;
  public cdnUrl:string;

  constructor(   
    private route:ActivatedRoute, 
    private sitemapService:SitemapService,
    private vendorService:VendorsService,
    private zone:NgZone,
    private pageService:PageService,
    private el:ElementRef,
    private notificationService:NotificationsService,
    private clipboardService: ClipboardService,
    private dataExportService:DataExportService) {
      this.sitemapService.update(route);      
      // this.sitemapService.
      this.vizLoading = true;
      this.dataLoading = true;
      this.selectedRows = [];
      this.zoomFactor = 1;
      this.tableData = [];
      this.notificationOptions = {
        timeOut: 2000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };
      
      this.cdnUrl = env.cdn.url;
      this.dateFormat = {type: 'dateTime', skeleton:'short'}
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 20, 
        pageCount: 4, 
        pageSizes: [20, 25, 50]
      };
      this.toolbar = ['Search'];
      this.sortSettings = {
        columns: [
          { field: 'vendorName', direction: 'Ascending'},
          { field: 'file', direction: 'Ascending'}          
        ]
      };
      this.sortSettingsVendors = {
        columns: [
          { field: 'vendorName', direction: 'Ascending'}
        ]
      };
      this.selectionSettingsVendors = {
        type: 'Multiple', 
        mode: 'Both'
      };
  }

  async ngOnInit() {
    // this.buildTable();
    this.pageHistory = this.pageService.selectedPageHistory; //this.route.snapshot.data ? this.route.snapshot.data.history : this.pageService.selectedPageHistory;
    console.log("this.pageService.selectedPageHistory");
    console.log(this.pageService.selectedPageHistory);
    if ( this.pageHistory ){
      const dataLocation = this.pageHistory.value.dataLocation;
      const isNewBucket = this.pageHistory.value.scriptedPageRunnerHistoryId != null;
      console.log("isNewBucket", isNewBucket);
      const treeFile = `${dataLocation}${isNewBucket ? this.pageHistory.value.uuid +"/" : ""}treeViz.json`;
      const vendorsFile = `${dataLocation}${isNewBucket ? this.pageHistory.value.uuid +"/" : ""}vendors.json`;
      forkJoin([
        this.dataExportService.getPageRunnerOutputFile(treeFile, isNewBucket)
          .pipe(
            catchError( err => this.dataExportService.getPageRunnerOutputFile(treeFile, isNewBucket, true).pipe(catchError(error=>of(error))))
          ),
        this.dataExportService.getPageRunnerOutputFile(vendorsFile, isNewBucket)
          .pipe(
            catchError( err => this.dataExportService.getPageRunnerOutputFile(vendorsFile, isNewBucket, true).pipe(catchError(error=>of(error))))
          ),
        this.vendorService.getAllVendors()
      ]).subscribe((files)=>{

        if ( (files[0] instanceof Error) ){
          this.notificationService.error('Uh oh!', 'The data requested is not available. Please contact support.', {...this.notificationOptions, timeOut: 10000});          
        } else {

          this.allVendors = files[2];
          
          this.nodes = JSON.parse(files[0].toString());
          try {
            
            var counter = 0;
            while(this.nodes && this.nodes.nodes == null){
              this.nodes = JSON.parse(this.nodes);              
              console.log("node counter", counter);
              if ( counter++ == 20 ){
                console.log("Node counter is 20....breaking...");
                break;
              }
            }
            
            let missingVendors = [];
            this.nodes.nodes = this.nodes.nodes.map((node)=>{
              let found = this.allVendors.find((vendor)=>{return vendor.id == node.vendorId});
              if ( !found ) missingVendors.push(node.vendorId);
              return {
                ...node, 
                vendorId: found ? node.vendorId : 7210, 
                vendorName: found ? found.name : "Unknown", 
                vendorWebsite: found ? found.website : null
              }
            })

            let uniqueVendorIds = new Set();
            this.nodes.nodes.forEach(item => {
              let id = item.vendorId;
              if ( id ) {
                uniqueVendorIds.add(id);
              }
            });
          
            console.log(this.nodes);
            if ( files[1] instanceof Error ) {
              this.vendors = files[2].filter((vendor)=>{
                return uniqueVendorIds.has(vendor.id);
              })
              .map((vendor)=>{
                return { vendorName: vendor.name, vendorId: vendor.id, website: vendor.website };
              })
            } else {
              this.vendors = JSON.parse(files[1].toString());
              counter = 0;
              while((this.vendors instanceof Array) == false){
                this.vendors = JSON.parse((String)(this.vendors));
                console.log("vendor counter", counter);
                if ( counter++ == 20 ){
                  console.log("Vendor counter is 20....breaking...");
                  break;
                }
              }
              
              
              this.vendors = this.vendors
              .map((vendorId)=>{
                let found = this.allVendors.find((v)=>{return v.id == vendorId});
                
                return {
                  vendorId: vendorId,
                  vendorName: found ? found.name : `Unknown`, 
                  website: found ? found.website : null
                }
              })
              .filter((item, index, self)=>{
                console.log(missingVendors);
                if ( item.vendorId != 7210 && item.vendorName == "Unknown" && missingVendors.indexOf(item.vendorId) >= 0){
                  return false;
                }
                return true;               
              })
            }

            if ( this.nodes && this.nodes.nodes ){
              this.nodes.nodes = this.nodes.nodes.map((n)=>{
                return {...n, displayName:""};
              });
              
              this.vendors = this.vendors.map((v)=>{
                return {...v, checked: false};
              })
      
              this.renderGraph(this.nodes);
      
              this.tableData = this.nodes.nodes;
              this.grid.dataSource = this.tableData;
            }            
          } catch(pe) {
            console.log(pe);
          }
        }
        
        this.vizLoading = false;        
        this.dataLoading = false;
      });
    }    
  }

  renderGraph(data){
    $("#d3Viz").empty();

    var w = $("#d3Viz").width(), h = $("#d3Viz").height();

    // var margin = {top: -5, right: -5, bottom: -5, left: -5};
    // w = w - margin.left - margin.right,
    // h = h - margin.top - margin.bottom;



    // this.svg = d3.select("#d3Viz").append("svg:svg")
    //     .attr("width", w + margin.left + margin.right)
    //     .attr("height", h + margin.top + margin.bottom)
    //   .append("g")
    //     .attr("transform", "translate(" + margin.left + "," + margin.right + ")")
    //     ;

    var edgesLength = data.links ? data.links.length : 0;
    var chargeMultiplier = 0;

    if (data && data.nodes && data.nodes.length > 0) {
      if (data.nodes.length < 15) {
          chargeMultiplier = 70;
      } else if (data.nodes.length < 20) {
          chargeMultiplier = 35;
      } else if (data.nodes.length < 40) {
          chargeMultiplier = 25;
      } else {
          chargeMultiplier = 15;
      }        
    }

    var keyc = true, keys = true, keyt = true, keyr = true, keyx = true, keyd = true, keyl = true, keym = true, keyh = true, key1 = true, key2 = true, key3 = true, key0 = true

    var focus_node = null, highlight_node = null;
    
    var text_center = false;
    var outline = false;
    
    var min_score = 0;
    var max_score = 1;
    
    var color = d3.scale.linear()
      .domain([min_score, (min_score+max_score)/2, max_score])
      .range(["lime", "yellow", "red"]);
    
    var highlight_color = "blue";
    var highlight_trans = 0.1;
      
    var size = d3.scale.pow().exponent(1)
      .domain([1,100])
      .range([8,24]);
      
      var force =  d3.layout.force()
      .nodes(data.nodes)
      .links(data.links)    
      .charge(function(d) {
        return -100 * chargeMultiplier - edgesLength;
      })
      .linkDistance(5)
      .linkStrength(1)
      .friction(0.8)
      .size([w, h])
      .gravity(0.2 + (edgesLength / 700))
      .start();

    window["force"] = force;
      
    var margin = {top: -5, right: -5, bottom: -5, left: -5};
    var default_node_color = "#ccc";
    //var default_node_color = "rgb(3,190,100)";
    var default_link_color = "#888";
    var nominal_base_node_size = 8;
    var nominal_text_size = 10;
    var max_text_size = 24;
    var nominal_stroke = 1.5;
    var max_stroke = 4.5;
    var max_base_node_size = 36;
    var min_zoom = 0.1;
    var max_zoom = 7;
    var svg = d3.select("#d3Viz").append("svg")
      .attr("width", w + margin.left + margin.right)
      .attr("height", h + margin.top + margin.bottom);
    var zoom = d3.behavior.zoom().scaleExtent([min_zoom,max_zoom])
    var g = svg.append("g");
    svg.style("cursor","move");
    var THAT = this;

    var Tooltip = d3.select("#d3Viz")
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "2px")
    .style("border-radius", "5px")
    .style("padding", "5px")
    ;
    
    var linkedByIndex = {};
    data.links.forEach(function(d) {
        linkedByIndex[d.source + "," + d.target] = true;
    });

    function isConnected(a, b) {
      return true; //linkedByIndex[a.index + "," + b.index] || linkedByIndex[b.index + "," + a.index] || a.index == b.index;
    }

    var superThis = this

    var link = g.selectAll(".link")
      .data(data.links)
      .enter().append("line")      
      .attr("class", "link")
      .style("stroke-width", nominal_stroke)
      .style("stroke", function(d) {
          if (isNumber(d.score) && d.score >= 0) return color(d.score);
          else return default_link_color;
      })

    var node = g.selectAll(".node")
        .data(data.nodes)
        .enter().append("g")
        .attr("class", function(d){
          if ( d.fixed == true ){
            return "node rootNode";
          } else {
            return "node";
          }
        })       
        .call(force.drag);


    node.on("dblclick.zoom", function(d) {
        d3.event.stopPropagation();
        var dcx = (window.innerWidth / 2 - d.x * zoom.scale());
        var dcy = (window.innerHeight / 2 - d.y * zoom.scale());
        zoom.translate([dcx, dcy]);
        g.attr("transform", "translate(" + dcx + "," + dcy + ")scale(" + zoom.scale() + ")");
    });


    var tocolor = "fill";
    var towhite = "stroke";
    if (outline) {
        tocolor = "stroke"
        towhite = "fill"
    }

    node.append("rect")
    .attr("x", "-8px")
    .attr("y", "-8px")
    .attr('width', function(d){
      if ( d.fixed == true ){
        return "32px"
      }
      return "16px";
    })
    .attr('height', function(d){
      if ( d.fixed == true ){
        return "32px"
      }
      return "16px";
    })
    .attr('fill', 'none')
    .attr('stroke-width', function(d){
      if ( d.fixed ){
        return 5;
      }
      return 0;
    })
    .attr('stroke', 'blue')
    ;

    var circle = node.append("svg:image")
      .attr("xlink:href", function(n){
        if ( n && n.vendorWebsite ){
          return `https://${env.cdn.url}/vendors/icon/${n.vendorWebsite.toLowerCase()}.ico`
        } else {
          return `https://${env.cdn.url}/vendors/icon/unknown.ico`;
        }            
      })
      .attr("x", "-8px")
      .attr("y", "-8px")
      .attr("width", function(d){
        if ( d.fixed == true ){
          return "32px"
        }
        return "16px";
      })
      .attr("height", function(d){
        if ( d.fixed == true ){
          return "32px"
        }
        return "16px";
      })
      ;     
    
    var text = g.selectAll(".text")
        .data(data.nodes)
        .enter().append("text")
        .attr("dy", ".35em")
        .style("font-size", nominal_text_size + "px")

    if (text_center)
        text.text(function(d) {
            return d.displayName;
        })
        .style("text-anchor", "middle");
    else
        text.attr("dx", function(d) {
            return (size(d.size) || nominal_base_node_size);
        })
        .text(function(d) {
            return '\u2002' + d.displayName;
        });

    node.on("mouseover", function(d) {
            set_highlight(d);
            Tooltip
            .style("opacity", "1").style("visibility", "visible");
        })
        .on("mousedown", function(d) {
          d.fixed = true;
            d3.event.stopPropagation();
            focus_node = d;
            if (highlight_node === null) set_highlight(d)

        }).on("mouseout", function(d) {
            exit_highlight();
            Tooltip
            .style("opacity", "0").style("visibility", "hidden");
         
         

        }).on("mousemove", function(d){

          var ttX = d3.mouse(document.getElementById('d3Viz'))[0] + 32,
              ttY = d3.mouse(document.getElementById('d3Viz'))[1] + 32;
          
          var text = d && d.file ?  "<b>URL:</b><br>&nbsp;&nbsp;" + superThis.truncateFileName(d.file) : "N/A";
          if ( d && d.responseHeaders ){
            text += `<br><br><b>Response Headers:</b><br>`
            for (var key in d.responseHeaders){
              text += `&nbsp;&nbsp;${key}: ${d.responseHeaders[key]}<br>`
            }
          }
          
          Tooltip
          .html(`${text}`)
          .style("left", (ttX + 20) + "px")
          .style("top", (ttY + 80) + "px")
        })
        ;

    d3.select(window).on("mouseup",
        function() {
            if (focus_node !== null) {
                focus_node = null;                
            }

            if (highlight_node === null) exit_highlight();
        });

    function exit_highlight() {
        highlight_node = null;
        if (focus_node === null) {
            svg.style("cursor", "move");
            if (highlight_color != "white") {
                circle.style(towhite, "white");
                text.style("font-weight", "normal");
                link.style("stroke", function(o) {
                    return (isNumber(o.score) && o.score >= 0) ? color(o.score) : default_link_color
                });
            }

        }
    }

    function set_highlight(d) {
        // console.log(d);
        svg.style("cursor", "pointer");
        if (focus_node !== null) d = focus_node;
        highlight_node = d;
        // console.log(d);

        var target = d.index;
        var counter = 0;
        var nodeChain = [d.index];
        while (target != 0) {        
          var node = data.links.filter((l)=>{return l.target.index == target})[0];
          // console.log(node.source.index);
          target = node.source.index;
          nodeChain.push(target);
          if ( counter++ > 100){
            console.log("Busting out of loop....");
            break;
          }
        }

        if (highlight_color != "white") {
            circle.style(towhite, function(o) {
                return isConnected(d, o) ? highlight_color : "white";
            });
            text.style("font-weight", function(o) {
                return isConnected(d, o) ? "bold" : "normal";
            });
            link.style("stroke", function(o) {
              // console.log(o);
                return nodeChain.indexOf(o.target.index) >= 0 ? highlight_color : 
                  ((isNumber(o.score) && o.score >= 0) 
                    ? 
                      color(o.score) 
                        : 
                      default_link_color);
            });
        }
    }


    zoom.on("zoom", function() {

        var stroke = nominal_stroke;
        if (nominal_stroke * zoom.scale() > max_stroke) stroke = max_stroke / zoom.scale();
        link.style("stroke-width", stroke);
        circle.style("stroke-width", stroke);

        var base_radius = nominal_base_node_size;
        if (nominal_base_node_size * zoom.scale() > max_base_node_size) base_radius = max_base_node_size / zoom.scale();
        circle.attr("d", d3.svg.symbol()
            .size(function(d) {
                return Math.PI * Math.pow(size(d.size) * base_radius / nominal_base_node_size || base_radius, 2);
            })
            .type(function(d) {
                return d.type;
            }))

        //circle.attr("r", function(d) { return (size(d.size)*base_radius/nominal_base_node_size||base_radius); })
        if (!text_center) text.attr("dx", function(d) {
            return (size(d.size) * base_radius / nominal_base_node_size || base_radius);
        });

        var text_size = nominal_text_size;
        if (nominal_text_size * zoom.scale() > max_text_size) text_size = max_text_size / zoom.scale();
        text.style("font-size", text_size + "px");

        g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
    });

    svg.call(zoom);

    resize();
    //window.focus();
    
    force.on("tick", function() {

        node.attr("transform", function(d) {
            return "translate(" + d.x + "," + d.y + ")";
        });
        text.attr("transform", function(d) {
            return "translate(" + d.x + "," + d.y + ")";
        });

        link.attr("x1", function(d) {
                return d.source.x;
            })
            .attr("y1", function(d) {
                return d.source.y;
            })
            .attr("x2", function(d) {
                return d.target.x;
            })
            .attr("y2", function(d) {
                return d.target.y;
            });

        node.attr("cx", function(d) {
                return d.x;
            })
            .attr("cy", function(d) {
                return d.y;
            });
    });

    function resize() {
        var width = w, //window.innerWidth,
            height = h; //window.innerHeight;
        svg.attr("width", width).attr("height", height);

        force.size([force.size()[0] + (width - w) / zoom.scale(), force.size()[1] + (height - h) / zoom.scale()]).resume();
        w = width;
        h = height;
    }

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
  }

  truncateFileName(fileName){
    const LEN = 150;
    var displayName:string = fileName;
    if ( displayName.length > LEN ){
      const lastSlashIndex = displayName.lastIndexOf("/");
      displayName = displayName.substr(lastSlashIndex);
      
      displayName = fileName.substr(0, LEN-displayName.length) + "..." + displayName;

      if ( displayName.length > LEN ){
        displayName = displayName.substr(0, LEN) + "...";
      }

    }
    return displayName;
  }

  getResource(fullUrl){
    var displayName:string = fullUrl;
    
    const lastSlashIndex = displayName.lastIndexOf("/");    
    displayName = displayName.substr(lastSlashIndex);
    
    return displayName.substr(0, 25);
  }

  onVendorCheck(){
    // console.log("Vendor " + vendorId);
    
    var selectedVendors = this.vendorsGrid.getSelectedRecords();

    console.log(selectedVendors);

    this.nodes.nodes.forEach((n)=>{
      if ( selectedVendors.filter((v)=>{return v['vendorId'] == n.vendorId}).length == 1 ){
        const lastSlashIndex = n.file.lastIndexOf("/");      
        n.displayName = n.file.substr(lastSlashIndex).substr(0, 25);
      }else{
        n.displayName = '';
      }      
    });

    this.tableData = this.nodes.nodes.filter((n)=>{
      if ( selectedVendors.length == 0 ){ return true;}
      if ( selectedVendors.filter((v)=>{return v['vendorId'] == n.vendorId}).length == 1 ){
        return true;
      }
      return false;
    });

    this.renderGraph(this.nodes);
    this.grid.dataSource = this.tableData;
  }

  addToClipboard(value){
    this.clipboardService.copyFromContent(value);
    this.notificationService.success('Copied', 'Full URL was copied to clipboard.', this.notificationOptions);    
  }

  fullScreen(){
    var h = $(".graphDiv").height();
    console.log(h);
    h = h < 1000 ? 1000 : 500;
    var vizH = h < 1000 ? 375 : 900;
    
    $(".graphDiv").height(h);
    $(".graphCard").height(h);
    $("#d3Viz").height(vizH);

    this.renderGraph(this.nodes);
  }

  onGridCreate(){
    this.grid.showSpinner = () => true;
  }

  onVendorSelected(event){    
    this.onVendorCheck();
  }

  onVendorDeselected(event){
    this.onVendorCheck();
  }
}
