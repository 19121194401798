<div class="row">
  <div class="col-md-12">
      <div class="button-toolbar d-none d-md-block" style="float: right; padding-bottom: 5px;">
        <div class="btn-group">
          <!-- <button type="button" class="btn btn-secondary" [class.active]="selectedDateRange == 'day'" (click)="changeDateRange('day');">Today</button> -->
          <button type="button" class="btn btn-secondary" [class.active]="selectedDateRange == 'week'" (click)="changeDateRange('week');">Week</button>
          <button type="button" class="btn btn-secondary" [class.active]="selectedDateRange == 'month'" (click)="changeDateRange('month');">Month</button>
          <!-- <button type="button" class="btn btn-secondary" [class.active]="selectedDateRange == 'year'" (click)="changeDateRange('year');">Year</button> -->
          <!-- <button type="button" class="btn btn-secondary" [class.active]="selectedDateRange == 'custom'" (click)="changeDateRange('custom');">Custom</button> -->
        </div>
          <div #dtPickers *ngIf="showCustom" class="row custom-date-fields">
            <div class="col-6">
              <input class="form-control form-control-sm datetimepicker" placeholder="Start Date" id="customStart" data-min-view="2" data-date-format="yyyy-mm-dd">            
            </div>
            <div class="col-6">
              <input class="form-control form-control-sm datetimepicker" placeholder="End Date"  id="customEnd" data-min-view="2" data-date-format="yyyy-mm-dd">
            </div>
            <div class="col-12" style="text-align: center; padding-top: 5px;">
              <button type="submit" class="btn btn-rounded btn-space btn-success" (click)="loadCustomRange();">Load Data</button>
            </div>
          </div>
      </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card be-loading" [class.be-loading-active]="loadingData">
      <div class="card-header card-header-divider">
        <div class="tools"><span class="icon mdi mdi-chevron-down"></span><span class="icon mdi mdi-refresh-sync"></span><span class="icon mdi mdi-close"></span></div><span class="title">Incident Details</span><span class="card-subtitle">View types of incidents over a given timespan</span>
      </div>
      <div class="card-body">
        <canvas id="line-chart" height="100"></canvas>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://-www.w3.org/2000/svg">
          <circle class="circle" fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-6">
    <div class="card be-loading">
      <div class="card-header card-header-divider">New Incidents<span class="card-subtitle">The number of incidents created in the given time span</span></div>
      <div class="card-body">
        <div class="row user-progress">
          <div class="col-10"><span class="title">Green</span>
            <div *ngIf="!loadingData; else incidentsLoadingSkeleton" class="progress">
              <div class="progress-bar bg-success" [style.width.%]="(totalsData?.Green?.created/createdTotal)*100"></div>
            </div>           
          </div>
          <div class="col-2 pl-0 pl-sm-3"><span class="value">{{totalsData?.Green?.created}}</span></div>
        </div>        
        <div class="row user-progress">
          <div class="col-10"><span class="title">Yellow</span>
            <div *ngIf="!loadingData; else incidentsLoadingSkeleton" class="progress">
              <div class="progress-bar bg-warning" [style.width.%]="(totalsData?.Yellow?.created/createdTotal)*100"></div>
            </div>
          </div>
          <div class="col-2 pl-0 pl-sm-3"><span class="value">{{totalsData?.Yellow?.created}}</span></div>
        </div>
        <div class="row user-progress">
          <div class="col-10"><span class="title">Red</span>
            <div *ngIf="!loadingData; else incidentsLoadingSkeleton" class="progress">
              <div class="progress-bar bg-danger" [style.width.%]="(totalsData?.Red?.created/createdTotal)*100"></div>
            </div>
          </div>
          <div class="col-2 pl-0 pl-sm-3"><span class="value">{{totalsData?.Red?.created}}</span></div>
        </div>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card be-loading">
      <div class="card-header card-header-divider">Resolved Incidents<span class="card-subtitle">The number of incidents resolved in the given time span</span></div>
      <div class="card-body">
        <div class="row user-progress">
          <div class="col-10"><span class="title">Green</span>
            <div *ngIf="!loadingData; else incidentsLoadingSkeleton" class="progress">
              <div class="progress-bar bg-success" [style.width.%]="(totalsData?.Green?.resolved/resolvedTotal)*100"></div>
            </div>
          </div>
          <div class="col-2 pl-0 pl-sm-3"><span class="value">{{totalsData?.Green?.resolved}}</span></div>
        </div>
        <div class="row user-progress">
          <div class="col-10"><span class="title">Yellow</span>
            <div *ngIf="!loadingData; else incidentsLoadingSkeleton" class="progress">
              <div class="progress-bar bg-warning" [style.width.%]="(totalsData?.Yellow?.resolved/resolvedTotal)*100"></div>
            </div>
          </div>
          <div class="col-2 pl-0 pl-sm-3"><span class="value">{{totalsData?.Yellow?.resolved}}</span></div>
        </div>
        <div class="row user-progress">
          <div class="col-10"><span class="title">Red</span>
            <div *ngIf="!loadingData; else incidentsLoadingSkeleton" class="progress">
              <div class="progress-bar bg-danger" [style.width.%]="(totalsData?.Red?.resolved/resolvedTotal)*100"></div>
            </div>
          </div>
          <div class="col-2 pl-0 pl-sm-3"><span class="value">{{totalsData?.Red?.resolved}}</span></div>
        </div>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div>      
    </div>
  </div>
</div>

<ng-template #incidentsLoadingSkeleton>
  <p-skeleton styleClass="p-mb-2" borderRadius="16px"></p-skeleton>
</ng-template>