import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { environment as env } from "../../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log('TokenInterceptor');
    console.log(request.url);
    
    // Auth header is screwing with s3, so don't add it.
    if ( request.url.indexOf(`s3.amazonaws.com`) != -1 ) {
      return next.handle(request);
    }

    var headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };
    const cid = localStorage.getItem("cid");
    if ( cid ) {
      headers["cid"] = cid;
    }

    request = request.clone({
      setHeaders: headers
    });
    
    return next.handle(request);
  }
}