import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { Subscription } from 'rxjs';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { environment as env } from "../../../../environments/environment";
import { AccountSwitchService } from '../../../services/accountSwitch/account-switch.service';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.css']
})
export class UsermenuComponent implements OnInit, OnDestroy {
  userProfile:any;
  customerName:string;
  customerNameSub:Subscription;
  sessionInterval:any;
  sessionExpiration:string;
  cdnUrl:string;
  currentCustomerId:any;
  currentCustomer:any;
  
  public customers:any;  
  public customerFields: Object = { text: 'name', value: 'id'};
  public height: string = '220px';
  public watermark: string = 'Select a customer';
  public filterPlaceholder: string = 'Search';
  public onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
      let query: Query = new Query();
      //frame the query based on search string with filter type.
      query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
      //pass the filter data source, filter query to updateData method.
      e.updateData(this.customers, query);
  }

  constructor(
    public authService:AuthService, 
    private customerService:CustomerService,
    private accountSwitchService:AccountSwitchService
  ) {
    this.sessionExpiration = "Session expires in: ";
    this.cdnUrl = env.cdn.url;
  }
  

  ngOnInit() {
    console.log("Usermenu OnInit!");
    this.userProfile = this.authService.getUserProfile();
    this.customers = this.customerService.customers.map((c)=>{
      let products = c["products"].map( p => p.name);
      c.name += ` - ${ c.id } ${ products.length > 0 ? `(${products.join(",")})` : "" }` 
      return c;
    });
    this.customerNameSub = this.customerService.customerName.subscribe((name)=>{
      this.customerName = name;
    });
    console.log(this.userProfile);
    
    this.currentCustomer = this.customerService.customer
    this.currentCustomerId = this.customerService.customer.id;
    
    this.sessionExpiration = "Session expires in: " + this.computeSessionExpiration();

    this.sessionInterval = setInterval(() => {
      this.sessionExpiration = "Session expires in: " + this.computeSessionExpiration();
    }, 1000 * 60);
  }

  ngOnDestroy(){
    this.customerNameSub.unsubscribe();
    clearTimeout(this.sessionInterval);
  }

  computeSessionExpiration(){
    var expiresMins = ((localStorage.expires_at - Date.now())/1000)/60;
    if ( expiresMins < 60 ){
      expiresMins = Math.floor(expiresMins);
      return `${expiresMins} ${ expiresMins == 1 ? "min" : "mins" }`;
    } else {
      const hours = Math.floor(expiresMins / 60);
      const remainingMins = Math.floor(expiresMins % 60);

      return `${ hours } ${ hours == 1 ? "hour" : "hours" } ${remainingMins} ${ remainingMins == 1 ? "min" : "mins" }`;
    }
  }

  onCustomerChange(event) {
    this.accountSwitchService.switchAccount(event.itemData.id, true);
  }

}
