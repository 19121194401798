import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TestSuite } from "../models/TestSuite";
import { DataValidationService } from "../services/data-validation/data-validation.service";


@Injectable()
export class TestSuiteResolver implements Resolve<TestSuite> {
    
    constructor(private dataValidationService:DataValidationService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<TestSuite> {
        console.log("Test Suite Resolver", route);
        const testSuiteId = route.params["testSuiteId"];

        if ( this.dataValidationService.selectedTestSuite.value && this.dataValidationService.selectedTestSuite.value.id == testSuiteId) {
            return;
        } else {
            this.spinnerService.toggleRoot(true);
            return this.dataValidationService.getTestSuite(testSuiteId).pipe(tap((data)=>{
                this.dataValidationService.selectedTestSuite.next(data);
                this.spinnerService.toggleRoot(false);
            }));
        }        
    }
}