  <div class="row" style="height: 200px;">
    <div class="col-xl-4">
      <div class="be-booking-promo statPanel" [class.be-booking-promo-success]="totalDetected == totalScanned" [class.be-booking-promo-danger]="totalDetected == 0" >
        <div class="be-booking-desc">
          <h4 class="be-booking-desc-title">Consent Tool Detected</h4><span class="be-booking-desc-details">A consent tool was detected on {{totalDetected}} out of {{totalScanned}} pages scanned.</span>
        </div>
        <div class="be-booking-promo-price">
          <div class="be-booking-promo-amount">
            <span class="price">
              <ng9-odometer [number]="totalDetectedPercent" format="(,ddd).d"></ng9-odometer>%
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="be-booking-promo statPanel" [class.be-booking-promo-success]="totalViolations == 0" [class.be-booking-promo-danger]="totalViolations > 0">
        <div class="be-booking-desc">
          <h4 class="be-booking-desc-title">Opt Out Violations</h4><span class="be-booking-desc-details">Violations were detected on {{totalViolations}} out of {{totalScanned}} pages.</span>
        </div>
        <div class="be-booking-promo-price">
          <div class="be-booking-promo-amount">
            <span class="price">
              <ng9-odometer [number]="totalViolationsPercent" format="(,ddd).d"></ng9-odometer>%
            </span>
          </div>
        </div>  
      </div>
    </div>
    <div class="col-xl-4">
      <div class="be-booking-promo statPanel">
      <ejs-accumulationchart 
        id="donut-container" #pie 
        style='height: 150px;' 
        [legendSettings]="legendSettings" 
        [tooltip]="pieTooltip" 
        (tooltipRender)="tooltipRender($event);"
        selectionMode='Point'
        (textRender)="onTextRender($event)"
        [center]="pieCenter"
        background="transparent"
        
        >
        <e-accumulation-series-collection>
            <e-accumulation-series 
              name='Pages Scanned' 
              [dataSource]='pieData' 
              xName='x' 
              yName='y' 
              radius="100%"
              [dataLabel]="dataLabel"
              enableAnimation="true"
              > 
          </e-accumulation-series>
        </e-accumulation-series-collection>
    </ejs-accumulationchart>
    </div>
    </div>
  </div>
  <div class="row">
    
    <div class="col-sm-12">
      <h3>Links</h3>
      <div class="card card-table card-border">
        <div class="card-header">
            <div class="tools">
              <!-- <button type="button" class="btn btn-rounded btn-space btn-primary" (click)="exportData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button> -->
            </div>
          <span class="title">&nbsp;</span>
          <span class="card-subtitle">View and export the summary of the consent tool validation data.</span>
        </div>
        <div class="card-body">
          <ejs-grid #grid 
            [allowPaging]="true" 
            [allowSorting]="true"
            [sortSettings]="sortSettings"
            allowFiltering="true" 
            [pageSettings]="pageOptions" 
            [allowSelection]="false" 
            [enableHover]="false"                         
            [filterSettings]="filterSettings"
            locale='en-US'
            allowResizing='true'
            [allowTextWrap]='true'
            height="500px"
            (detailDataBound)="detailDataBound($event)"
          >
          <ng-template #detailTemplate let-data>
            <div style="padding: 10px;">
              <div style="margin-bottom: 10px;"><a href="pages/{{data.id}}" target="_blank">Page Run Details </a></div>
            </div>
            <div class="detailGrid"></div>
          </ng-template>

          <e-columns>
            <e-column field='link' headerText='Link' type="string" [allowEditing]="false" [minWidth]="400"></e-column>
            <e-column field='requestViolationCount' headerText='Request Violations'></e-column>
            <e-column field='cookieViolationCount' headerText='Cookie Violations'></e-column>
          </e-columns>
        </ejs-grid>
        </div>        
      </div>
    </div>
  </div>


  <div class="modal fade" id="modal-select-report" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <h2>Report Selector</h2>
          <p>Please select a report:</p>
          <form [formGroup]="reportSelectionForm">
            <div class="form-group row pt-1">
              <div class="col-12 col-sm-8 col-lg-12">
                <ejs-combobox id='reportCB' #reportCB
                  [dataSource]='availableConfigs' 
                  [fields]="{ value: 'id', text: 'label' }" 
                  formControlName="selectedFormId"
                  placeholder='Select a report'
                  allowFiltering='true'
                  allowCustom='false'
                  filterType='contains'>
                </ejs-combobox>
              </div>
            </div>
          </form>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="onSelectReport();">Save</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>