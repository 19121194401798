import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { debounceTime, delay, distinctUntilChanged, retry } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket'
import { LogMessage as NgxLogMessage, LogMonitorComponent } from 'ngx-log-monitor';
import { Subject } from 'rxjs';
import { environment as env } from "../../../../../environments/environment";
let worldMap: object[] = require('./worldMap.json');
let population: object[] = require('./population.json');
import { MapsTheme, Maps, Marker, MapsTooltip, ILoadEventArgs, MapsTooltipService, MarkerService, DataLabelService, MapsComponent, ILoadedEventArgs } from '@syncfusion/ej2-angular-maps';
import { RegionService } from '../../../../services/region/region.service';

@Component({
  selector: 'app-realtime-event-view',
  templateUrl: './realtime-event-view.component.html',
  styleUrls: ['./realtime-event-view.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MapsTooltipService, MarkerService, MapsComponent, DataLabelService]
})
export class RealtimeEventViewComponent implements OnInit {

  @ViewChild('map') public map: MapsComponent;
  @ViewChild('logMonitor') public logMonitor: LogMonitorComponent;
  @ViewChild('searchTextBox') public searchTextBox;
  endpoint = '';
  messages = [];
  websocket = undefined;
  subject;
  logStream$
  logHistory;
  isPaused: boolean;
  subscription;
  eventLogInternal;
  searchTerm$ = new Subject<string>();
  searchText;
  filteredHistory;
  delay = 0;
  ticksLables = ["0", "10"];

  showCodeFileChangeEvents: boolean = false;
  showCodeAnalysisCompleteEvents: boolean = false;
  showInstrumentationEvents: boolean = false;
  showNewCodeFileEvents: boolean = false;
  showNewIncidentEvents: boolean = false;
  showPageRunCompletEvents: boolean = false;
  showPageRunStartEvents: boolean = false;
  showReportCompleteEvents: boolean = false;
  showTimerCompleteEvents: boolean = false;
  showValidationResultEvents: boolean = false;
  showAllEvents: boolean = true;

  public titleSettings: object = {
    text: 'Data Centers',
    textStyle: {
      size: "16px"
    }
  };

  regionTimers: any;

  public layers: object[];

  constructor(
    private regionService: RegionService
  ) {
    this.logHistory = [];
    this.isPaused = false;
    this.eventLogInternal = [];
    this.regionTimers = {};
    this.layers = [
      {
        shapeData: worldMap,
        type: 'Sublayer',
        dataSource: this.regionService.regionsArray,
        shapeSettings: { fill: '#C3E6ED' },
        markerSettings: [{
          dataSource: Array.from(this.regionService.regionsArray),
          visible: true,
          shape: 'Circle',
          fill: 'white',
          width: 5,
          border: { width: 2, color: '#285255' },
          tooltipSettings: {
            visible: true,
            valuePath: 'name',
          }
        }]
      },
      {
        shapeData: worldMap,
        shapeSettings: { fill: '#C3E6ED' },
        markerSettings: [{
          dataSource: Array.from(this.regionService.regionsArray),
          visible: true,
          template: '<div id="template ${name}"><div class="pulse-css"><br /></div></div>',
          tooltipSettings: {
            template: '<div><div class="toolback"><div class="listing2"><center> ${description} </center></div></div>',
            visible: true,
            valuePath: 'name',
          }
        }]
      }
    ];
  }

  ngOnInit(): void {
    // this.endpoint = 'wss://17vpxar0ih.execute-api.us-east-1.amazonaws.com/dev';
    this.endpoint = env.api.wsurl;
    this.logStream$ = new Subject<NgxLogMessage>();
    this.connectToWebSocket();
  }

  connectToWebSocket() {
    this.subject = webSocket({
      url: `${this.endpoint}?t=${localStorage.getItem('access_token')}&cid=${localStorage.getItem("cid")}`,
      openObserver: {
        next: () => {
          console.log("WS Open");
        }
      },
      closingObserver: {
        next: () => {
          console.log("WS Closing...");
        }
      },
      closeObserver: {
        next: () => {
          console.log("WS Close...");
        }
      }
    });
    this.start();
  }

  start() {
    console.log("start", this.delay);
    let delayMs = this.delay * 1000;
    console.log(delayMs);
    this.subscription = this.subject.pipe(
      retry(5),
      delay(delayMs)
    ).subscribe((event) => {
      this.eventLogInternal.push(event);
      console.log(event);
      if (this.showAllEvents == true ||
        (event.eventTypeName == 'Code File Change' && this.showCodeFileChangeEvents == true) ||
        (event.eventTypeName == 'Code Analysis Complete' && this.showCodeAnalysisCompleteEvents == true) ||
        (event.eventTypeName == 'Instrumentation' && this.showInstrumentationEvents == true) ||
        (event.eventTypeName == 'New Code File' && this.showNewCodeFileEvents == true) ||
        (event.eventTypeName == 'New Incident' && this.showNewIncidentEvents == true) ||
        (event.eventTypeName == 'Page Run Start' && this.showPageRunStartEvents == true) ||
        (event.eventTypeName == 'Page Run Complete' && this.showPageRunCompletEvents == true) ||
        (event.eventTypeName == 'Report Complete' && this.showReportCompleteEvents == true) ||
        (event.eventTypeName == 'Timer Complete' && this.showTimerCompleteEvents == true) ||
        (event.eventTypeName == 'Validation Result' && this.showValidationResultEvents == true)
      ) {
        this.logStream$.next({ type: event.source + '-' + event.event, message: event.eventTypeName + ' - ' + this.eventToString(event) });

        if (event.eventTypeName == 'Page Run Start') {
          console.log(event.region);
          if (event.region) {
            let element = document.getElementById(`template ${event.region}`);
            if (element) {
              element.style.visibility = 'visible';
              let alreadyStarted = this.regionTimers[event.region];
              if (alreadyStarted) {
                clearInterval(alreadyStarted);
              }
              this.regionTimers[event.region] = setInterval(() => {
                let element = document.getElementById(`template ${event.region}`);
                if (element) {
                  element.style.visibility = 'hidden';
                }
              },
                1000 * 20);
            }
          }
        }
      } else {
        console.log(`Filtered ${event.eventTypeName}...`);
      }
    },
      (err) => {
        console.log("Websocket error", err);
      },
      () => {
        console.log("Websocket stream complete");
        this.subject.unsubscribe();
        this.subscription.unsubscribe();
        this.connectToWebSocket();
      });
  }

  eventToString(event) {
    switch (event.eventTypeName) {
      case "Instrumentation":
        return `${event.type} - ${event.page} - ${event.user}`;
      case "Code File Change":
        return `${event.url} (Id: ${event.codeFileId}, History Id: ${event.codeFileHistoryId}, UUID: ${event.uuid}, Hash: ${event.hash}, Size: ${event.responseSize}, Valid JS: ${event.isValidJS})`;
      case "Page Run Complete":
        return `${event.url} (Job ID: ${event.jobId} Region: ${event.region})`;
      case "Validation Result":
        return `${event.documentUrl} Result Message: ${event.resultMessage} Result Data: ${event.resultData} (Key: ${event.key}, Test Suite UUID: ${event.testSuiteUuid})`;
      default:
        return JSON.stringify(event);
    }
  }

  onPauseClick() {
    this.isPaused = !this.isPaused;
    if (this.isPaused) {
      this.subscription.unsubscribe();
    } else {
      this.start();
      if (this.searchTextBox.nativeElement.value != "") {
        this.searchTextBox.nativeElement.value = "";
      }
    }
  }

  onSearchTermChanged(filterText: string) {
    if (this.searchTerm$.observers.length === 0) {
      this.searchTerm$
        .pipe(
          debounceTime(750),
          distinctUntilChanged()
        )
        .subscribe(filterQuery => {
          console.log(filterQuery);
          this.searchText = filterQuery;
          if (filterQuery == "") {
            if (this.isPaused) {
              this.onPauseClick();
            }
            this.logHistory = this.filteredHistory;
          } else {
            this.filteredHistory = this.logMonitor.history;

            this.onPauseClick();
            this.logHistory = this.logMonitor.history.filter((item) => {
              return item.message.match(new RegExp(filterQuery, "g"));
            })
          }

        });
    }
    this.searchTerm$.next(filterText);
  }

  onSlideStop(event) {
    console.log("Slider Stop");
    console.log(event);
    this.subscription.unsubscribe();
    setTimeout(() => {
      this.start();
    }, 100);

  }

  public loaded = (args: ILoadedEventArgs) => {
    let lines: Object[] = args.maps.layers[0].markerSettings[0].dataSource as Object[];
    for (let i: number = 0; i < lines.length; i++) {
      let region: string = lines[i]["name"];
      let marker: HTMLElement = document.getElementById(`template ${region}`);
      if (marker) {
        marker.style.visibility = 'hidden';
      }
    }
  }

}
