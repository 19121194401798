import { Injectable } from '@angular/core';
import {
  CrudOptions,
  DataOptions,
  DataResult,
  Query,
} from '@syncfusion/ej2-data';
import { VaultBaseUrlAdaptor } from '../VaultBaseUrlAdaptor';
import { TimezoneService } from '../timezone.service';
import { DataManager } from '@syncfusion/ej2-data';
@Injectable({
  providedIn: 'root',
})
export class ReportDataAdaptor extends VaultBaseUrlAdaptor {
  constructor(private timezoneService: TimezoneService) {
    super();
  }
// TODO: UPDATE THIS WHEN THE API IS READY
  override processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Object,
    changes?: CrudOptions
  ): DataResult {

    // console.log('Process Response: ',data, ds, query, xhr, request, changes);
    // const count = data.total;
    // const result = data.records;
    // const out = {
    //   result: result,
    //   count: count,
    // }
    // return out;
    return data;
  }
  override processQuery(
    dm: DataManager,
    query: Query,
    hierarchyFilters?: Object[]
  ): Object {
    // console.log('Process Query: ',dm, query, hierarchyFilters);
    let q = super.processQuery(dm, query, hierarchyFilters) as any;
    // console.log('Super Q', q);
    return q;
  }

  override beforeSend(dm: DataManager, request: XMLHttpRequest): void {
    return super.beforeSend(dm, request);
  }


}
