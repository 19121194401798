import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { TestSuiteComponent } from './test-suite/test-suite.component';
import { TestSuiteDetailsComponent } from './test-suite/test-suite-details/test-suite-details.component';
import { RuleComponent } from './rule/rule.component';
import { RuleDetailsComponent } from './rule/rule-details/rule-details.component';
import { ConditionComponent } from './condition/condition.component';
import { ConditionDetailsComponent } from './condition/condition-details/condition-details.component';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import { GridModule, ResizeService } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { ValidationOverviewComponent } from './validation-overview/validation-overview.component';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { TooltipModule} from '@syncfusion/ej2-angular-popups';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    QueryBuilderModule,
    GridModule,
    ChartModule,
    SwitchModule,
    TooltipModule,
    DropDownListModule
  ],
  declarations: [
    TestSuiteComponent,
    TestSuiteDetailsComponent,
    RuleComponent,
    RuleDetailsComponent,
    ConditionComponent,
    ConditionDetailsComponent,
    ValidationOverviewComponent
  ],
  exports: [
    TestSuiteComponent,
    TestSuiteDetailsComponent,
    RuleComponent,
    RuleDetailsComponent,
    ConditionComponent,
    ConditionDetailsComponent
  ],
  providers:[
    PageService,
    SortService,
    FilterService,
    GroupService,
    ResizeService
  ]
})
export class ValidationComponentsModule { }
