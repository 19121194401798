<div class="row">
    <div class="col-lg-1">
    </div>    
    <div class="col-lg-2">
        <div class="card card-flat">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium;">Total Pages<br/>Monitored</span>
                    <div class="be-booking-promo-amount">
                        <span class="singleNumber">
                            <ng9-odometer [number]="totalPages.monitored" format="(,ddd).d"></ng9-odometer>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-default">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium;">{{floorAndFormat(totalPages.pages)}} all pages</span>
                    <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width: 200px">
                                        <div class="progress">
                                            <div class="progress-bar bg-success"
                                                [style.width.%]="getPercent(totalPages.monitored, totalPages.pages)"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <span style="padding-left: 10px; color: black; font-size: smaller; font-style: italic;">{{floorAndFormat(getPercent(totalPages.monitored, totalPages.pages))}}%</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2">
        <div class="card card-flat" style="height: 230px">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium; text-align: center;">Key Page <br />Violations</span>
                    <div class="be-booking-promo-amount">
                        <span class="price">
                            <div style="padding-top: 30px;">
                                <ng9-odometer [number]="keyPageViolations" format="(,ddd).d"
                                    style="color: red; font-size: larger;"></ng9-odometer>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card card-flat" style="height: 230px">
            <div class="card-body">

                <div class="be-booking-promo-price statPanel">
                    <span style="font-size: medium;">Scan Frequency</span>
                    <ejs-accumulationchart id="donut-container" #pie style='height: 150px; width: 600px'
                        [legendSettings]="legendSettings" [tooltip]="pieTooltip"
                        (tooltipRender)="tooltipRender($event);" selectionMode='Point'
                        (textRender)="onTextRender($event)" [center]="pieCenter" background="transparent">
                        <e-accumulation-series-collection>
                            <e-accumulation-series name='Pages Scanned' [dataSource]='data' xName='x' yName='y'
                                radius="100%" [dataLabel]="dataLabel" enableAnimation="true">
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="violationSummary" class="row justify-content-md-center">
    <div class="col-sm-10">
        <div class="card card-default">
            <div class="card-header">Violation Summary</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6 offset-md-3">
                        <ejs-grid #violationSummaryDG id="violationSummaryDG" [dataSource]='violationSummaryData' [allowPaging]="false"
                    [allowSorting]="true" [sortSettings]="sortSettings"
                    [allowSelection]="false" [enableHover]="false"
                    locale='en-US' allowResizing='true'
                    [allowTextWrap]='true' (actionComplete)="refreshGraphs($event)" (dataBound)="refreshGraphs($event)">
                    <e-columns>
                        <e-column field='type' textAlign="right" headerText='' width="10%" headerTextAlign="right">
                            <ng-template #template let-data>
                                <div class="row">
                                    <div class="col" style="font-size: large; font-weight: bold; padding-bottom: 5px;">
                                        {{data.type}}
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field="pages" headerText='% Pages' headerTextAlign="Center" width="12%" textAlign="center">
                            <ng-template #template let-data>
                                <div class="row">
                                    <div class="col" style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                        {{data.pages}}%
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[0]" [attr.values]="data.data"></span>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field="domains" headerText='# Domains' headerTextAlign="Center" width="10%" textAlign="center">
                            <ng-template #template let-data>                                
                                <div class="row">
                                    <div class="col" style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                        {{data.domains.toLocaleString('en-US')}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[1]" [attr.values]="data.data"></span>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field="total" headerText='Total Violations' headerTextAlign="Center" width="10%" textAlign="center">
                            <ng-template #template let-data>                                
                                <div class="row">
                                    <div class="col" style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                        {{data.total.toLocaleString('en-US')}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[2]" [attr.values]="data.data"></span>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row justify-content-md-center">
    <div class="col-10">
        <div class="card card-default">
            <div class="card-header">Pages</div>
            <div class="card-body">       
                <div class="row justify-content-md-center">
                    <div class="col-4">
                        <table border="1" [cellPadding]="5">
                            <tr>
                                <td>
                                   Violation Type: 
                                </td>
                                <td>
                                    <div class="icon"><span class="zmdi zmdi-close blackX" style="vertical-align: middle; margin-top: -5px; padding: 0 10px 0 10px;" ></span>DCF Violation</div>
                                </td>
                                <td>
                                    <div class="icon"><span class="zmdi zmdi-close redX" style="vertical-align: middle; margin-top: -5px; padding: 0 10px 0 10px;"></span>Other Violation</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <br/>
                <ejs-grid #vendorViolationGrid id="vendorViolationGrid" [dataSource]='keyPagesData' [allowPaging]="true" height="350px"
                    [allowSorting]="true" [sortSettings]="sortSettings" allowFiltering="true"
                    [pageSettings]="pageOptions" [allowSelection]="false" [enableHover]="false"
                    [filterSettings]="filterSettings" locale='en-US' allowResizing='true'
                    [allowTextWrap]='true' (actionComplete)="refreshGraphs2($event)" (dataBound)="refreshGraphs2($event)"
                    >
                    <e-columns>
                        <e-column field='page' headerText='Page' width="200px" textAlign="left" headerTextAlign="Center">
                            <ng-template #template let-data>
                                <span class="{{data.color}}Text"><a href="http://{{data.page}}">{{data.page}}</a></span>
                            </ng-template>
                        </e-column>
                        <e-column field="isKeyPage" headerText='Key Page' width="100px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <div class="icon"><span class="zmdi" [class.zmdi-close]="!data.isKeyPage" [class.redX]="!data.isKeyPage" [class.zmdi-check]="data.isKeyPage" [class.greenCheck]="data.isKeyPage"  ></span></div>
                            </ng-template>
                        </e-column>
                        <e-column field="dcfImp" headerText='DCF Implementation' width="160px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>                                
                                <div class="icon"><span class="zmdi" [class.zmdi-close]="!data.dcfImp" [class.redX]="!data.dcfImp" [class.zmdi-check]="data.dcfImp" [class.greenCheck]="data.dcfImp"  ></span></div>
                            </ng-template>
                        </e-column>
                        <e-column headerText='GDPR' width="80px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <div class="icon"><span class="{{data.gdpr}}" popHack="popoverTarget" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true" [attr.data-content]="data.popoverContent"></span></div>
                            </ng-template>
                        </e-column>
                        <e-column headerText='CCPA' width="80px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <div class="icon"><span class="{{data.ccpa}}" popHack="popoverTarget" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true" [attr.data-content]="data.popoverContent"></span></div>
                            </ng-template>
                        </e-column>
                        <e-column headerText='VPPA' width="100px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <div class="row justify-content-md-center" >
                                <ng-container *ngFor="let item of data.vppa">
                                    <div class="col-1 icon"><span class="{{item}}" popHack="popoverTarget" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true" [attr.data-content]="data.popoverContent"></span></div>
                                </ng-container>
                                </div>                                
                            </ng-template>
                        </e-column>
                        <e-column headerText='COPPA' width="100px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <div class="icon"><span class="{{data.coppa}}" popHack="popoverTarget" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true" [attr.data-content]="data.popoverContent"></span></div>
                            </ng-template>
                        </e-column>
                        <e-column headerText='Data Sharing' width="100px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <div class="icon"><span class="{{data.dataSharing}}" popHack="popoverTarget" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true" [attr.data-content]="data.popoverContent"></span></div>
                            </ng-template>
                        </e-column>
                        <e-column field="scanFrequencey" headerText='Scan Frequency' width="125px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <span>{{data.scanFrequencey}} Days</span>
                            </ng-template>
                        </e-column>
                        <e-column headerText='Last Scanned' width="200px" headerTextAlign="Center" textAlign="center">
                            <ng-template #template let-data>
                                <span>{{data.lastScanned}}</span>
                            </ng-template>
                        </e-column>
                        

                    </e-columns>
                </ejs-grid>
            </div>
        </div>

    </div>
</div>