<div class="row be-loading" [class.be-loading-active]="vizLoading">
    <div class="col-lg-10 graphDiv">
        <div class="card graphCard" style="height: 500px;">
            <div class="card-header card-header-divider">
                <div class="tools">
                    <span class="icon zmdi zmdi-fullscreen" (click)="fullScreen();"></span>
                </div>
                Screens<span class="card-subtitle">View the screen in which a particular SDK made a request(s).
                    Click a
                    a screen to view more details.</span>
            </div>
            <div class="card-body">
                <div class="image-container">
                    <a href="#"><img (click)="loadRequests($event,0)" src="assets/mobile/runs/1728337421997/screenshot/0.png" alt="Screenshot 0" [ngClass]="{'selectedScreenshot': curScreenIndex === 0}" ></a>
                    <a href="#"><img (click)="loadRequests($event,1)" src="assets/mobile/runs/1728337421997/screenshot/1.png" alt="Screenshot 1" [ngClass]="{'selectedScreenshot': curScreenIndex === 1}" ></a>
                    <a href="#"><img (click)="loadRequests($event,2)" src="assets/mobile/runs/1728337421997/screenshot/2.png" alt="Screenshot 2" [ngClass]="{'selectedScreenshot': curScreenIndex === 2}"></a>
                    <a href="#"><img (click)="loadRequests($event,3)" src="assets/mobile/runs/1728337421997/screenshot/3.png" alt="Screenshot 3" [ngClass]="{'selectedScreenshot': curScreenIndex === 3}"></a>
                    <a href="#"><img (click)="loadRequests($event,4)" src="assets/mobile/runs/1728337421997/screenshot/4.png" alt="Screenshot 4" [ngClass]="{'selectedScreenshot': curScreenIndex === 4}"></a>
                    <a href="#"><img (click)="loadRequests($event,5)" src="assets/mobile/runs/1728337421997/screenshot/5.png" alt="Screenshot 5" [ngClass]="{'selectedScreenshot': curScreenIndex === 5}"></a>
                    <a href="#"><img (click)="loadRequests($event,6)" src="assets/mobile/runs/1728337421997/screenshot/6.png" alt="Screenshot 6" [ngClass]="{'selectedScreenshot': curScreenIndex === 6}"></a>
                    <a href="#"><img (click)="loadRequests($event,7)" src="assets/mobile/runs/1728337421997/screenshot/7.png" alt="Screenshot 7" [ngClass]="{'selectedScreenshot': curScreenIndex === 7}"></a>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 vendorsDiv">
        <div class="card" style="height: 500px;">
            <div class="card-header card-header-divider">
                Vendors<span class="card-subtitle">Toggle request name per vendor</span>
            </div>
            <div class="card-body">
                <ejs-grid #vendorsGrid height='350' [dataSource]="vendors" locale='en-US' [allowPaging]="false"
                    [pageSettings]="pageSettings" [allowSorting]="true" [sortSettings]="sortSettingsVendors"
                    (created)="onGridCreate()" (rowSelected)="onVendorSelected($event)"
                    (rowDeselected)="onVendorDeselected($event)">
                    <e-columns>
                        <e-column type='checkbox' width='30'></e-column>
                        <e-column field='vendorName' headerText='Vendor'>
                            <ng-template #template let-data>
                                <img style="width: 32px; height:32px;margin-right: 5px;"
                                    src='https://{{cdnUrl}}/mobile/sdks/{{ data.website ? data.website.toLowerCase() : (data.hostname ? data.hostname : "unknown")}}.ico' />{{data.vendorName}}
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
    <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
    </div>
</div>
<br />
<div class="row">
    <div class="col-lg-12 requestsDiv">
        <div class="card card-table card-border be-loading" [class.be-loading-active]="dataLoading">
            <div class="card-body">
                <ejs-grid #fileGrid locale='en-US' [allowPaging]="true" [pageSettings]="pageSettings"
                    [allowSorting]="true" [sortSettings]="sortSettings" [allowSelection]="false" [enableHover]="false"
                    [toolbar]="toolbar" [allowResizing]="true" (created)="onGridCreate()" height="500px">
                    <e-columns>
                        <e-column field='formattedTS' headerText='Timestamp' width="14%"></e-column>
                        <e-column field='method' headerText='HTTP Method' width="8%">
                            <ng-template #detailTemplate let-data>
                                <div *ngIf="data.requestData | async as request" class="card">
                                    <div class="card">
                                        <div class="card-header">HTTP Headers</div>
                                        <div class="card-body">
                                            <div *ngFor="let header of request.headers; let i = index">
                                                {{header.key}}: {{header.value}}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="data.method == 'POST'" class="card">
                                        <div class="card-header">POST Data</div>
                                        <div class="card-body">
                                            {{ request.body }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field='url' headerText='Request URL' width="60%"></e-column>
                        <e-column field='vendorName' headerText='Vendor'>
                            <ng-template #template let-data>
                                <img style="width: 32px; height:32px;margin-right: 5px;"
                                    src='https://{{cdnUrl}}/mobile/sdks/{{ data.vendorWebsite ? data.vendorWebsite.toLowerCase() : "unknown" }}.ico' />{{data.vendorName}}
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div class="be-spinner">
                <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle">
                    </circle>
                </svg>
            </div>
        </div>
    </div>
</div>