import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { LinkDiscoveryConfigComponent } from './link-discovery-config/link-discovery-config.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [    
    LinkDiscoveryConfigComponent
  ],
  exports: [
    LinkDiscoveryConfigComponent
  ]
})
export class LinkDiscoveryComponentsModule { }
