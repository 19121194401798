<div class="row">
  <div class="col-lg-3">
    <app-title-card height="300px">
      <ng-container header>
        <span class="zmdi zmdi-chart title-icon"></span>
        Incidents
      </ng-container>
      <div class="flex-column" style="gap:10px">
        <div class="stat-card">
          <div>Red</div>
          <div style="color: red;">{{openIncidentsAggregate.redCount}}</div>
        </div>
        <div class="stat-card">
          <div>Yellow</div>
          <div style="color: orange;">{{openIncidentsAggregate.yellowCount}}</div>
        </div>
      </div>
    </app-title-card>
  </div>
  <div class="col-lg-5">
    <app-title-card height="300px">
      <ng-container header>
        <span class="zmdi zmdi-chart title-icon"></span>
        Incident History
      </ng-container>
      <div style="width: 100%;">
        <ejs-chart id='chart' #chart [dataSource]="chartList" height="250px" [primaryXAxis]="primaryXAxis" width="100%"
          [palettes]="paletteColors">
          <e-series-collection>
            <e-series [dataSource]="chartList" xName="date" yName="redCount" type="Line" name="Red Incidents"
              [marker]="marker" width="2"> </e-series>
            <e-series [dataSource]="chartList" xName="date" yName="yellowCount" type="Line" name="Yellow Incidents"
              [marker]="marker" width="2"></e-series>
          </e-series-collection>
        </ejs-chart>
      </div>
    </app-title-card>
  </div>
  <div class="col-lg-4">
    <app-title-card height="300px">
      <ng-container header>
        <span class="zmdi zmdi-chart title-icon"></span>
        Incident Recency
      </ng-container>
      <multi-pie-chart [datasource]="pieChartList"></multi-pie-chart>
    </app-title-card>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <app-title-card height="600px">
      <ng-container header>
        <span class="zmdi zmdi-chart title-icon"></span>
        Incident List
      </ng-container>
      <ejs-grid [allowPaging]="true" [dataSource]="incidentList" [allowResizing]="true" [allowSorting]="true" height="100%" width="100%"
        [sortSettings]="sortOptions" [allowFiltering]="true" [filterSettings]="filterSettings">
        <e-columns>
          <e-column field="statusFilter" width="30px" [allowResizing]="false" headerText="" [allowSorting]="false">
            <ng-template #template let-data>
              <span style='font-size: large; font-weight: bold;' class="zmdi"
                [style.color]="data.severity === 2 ? 'red':'orange'" [class.zmdi-circle-o]="data.status == 1"
                [class.zmdi-circle]="data.status == 2">
              </span>
            </ng-template>
          </e-column>
          <e-column field="reviewRequested" headerText="" [visible]="false"></e-column>
          <e-column field="severity" headerText="" [visible]="false"></e-column>
          <e-column field="status" headerText="" [visible]="false"></e-column>

          <e-column field="name" headerText=" Name" textAlign="Left" minWidth="25%">
            <ng-template #template let-data>
              <div style="position: relative; width: 100%;">
                <div class="row" style="margin: 0px 4px; position: relative;">
                  <div class="col"
                    style="font-size: medium; font-weight: bold; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                    <a [routerLink]="['/incidents', 'details', data.id]" style="color:black">
                      {{data.name}}
                    </a>
                    <span style='font-size: large; font-weight: bold; margin-left: 5px'
                      class="zmdi zmdi-collection-{{data.mergedCount <= 9 ? 'item-' + data.mergedCount : 'plus'}}"></span>
                    <span style="font-weight: bold; color:grey; " *ngIf="data.isMerged === 1"> [MERGED]</span>
                    <a [routerLink]="['/incidents', 'details', data.id]"><span class="zmdi zmdi-arrow-right"
                        style="margin-left: 10px;"></span></a>
                  </div>
                </div>
                <div class="row"
                  style="padding:0px; margin: 0px 4px; color:grey; font-style: italic; overflow:clip; text-overflow: ellipsis;">
                  <div class="col" style="font-size: small; padding:2px; margin:0;">
                    {{data.description}}
                  </div>
                </div>
                <div *ngIf="data.reviewRequested" class="review-tag">
                  <span class="zmdi zmdi-alert-triangle"></span>
                </div>
              </div>
            </ng-template>
          </e-column>

          <!-- <e-column field="type" headerText="Incident Type" textAlign="Left"></e-column> -->

          <e-column field="analysts" headerText="Analysts" [autoFit]="true">
            <ng-template #template let-data>
              <div class="analyst-list">
                <div *ngFor="let analyst of data.analysts">
                  {{analyst}}
                </div>
              </div>
            </ng-template>
          </e-column>
          <e-column field="formattedTimestamp" headerText="Creation Date" textAlign="Left" [autoFit]="true"></e-column>
          <e-column field="formattedLastUpdate" headerText="Last Update" textAlign="Left" [autoFit]="true"></e-column>
          <!-- <e-column field="lastEditedOn" headerText="Last Edited Date" textAlign="Left" width="100"></e-column> -->
        </e-columns>
      </ejs-grid>
    </app-title-card>
  </div>
</div>