import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): boolean {
    // debugger;
    console.log('AuthGuard Can Activate Running...');
    const expectedRole = route.data.expectedRole;
    console.log(route);
    console.log(expectedRole);
    // debugger;

    let isAuthenticated = this.auth.isAuthenticated();
    let hasScopes = this.auth.userHasScopes(expectedRole);
    
    console.log("isAuthenticated, hasScopes, expectedRole");
    console.log(isAuthenticated, hasScopes, expectedRole);

    if ( (!isAuthenticated) || (!isAuthenticated && expectedRole && !hasScopes)) {
      if ( state.url.toString() != "/" && state.url.toString().includes( "sso=" ) == false ) {
        console.log('Saving URL: ' + state.url);
        localStorage.setItem('redirectUrl', state.url);
      }

      let ssoId = localStorage.getItem('ssoId');
      if ( ssoId ){
        this.router.navigate(['login'], { queryParams: { sso: ssoId } });
      } else {
        this.router.navigate(['login']);
      }
      return false;
      
    }
    return true;
  }

}
