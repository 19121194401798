import { Condition } from "./Condition";

export class ValidationRule {

    conditions?:number[];
    
    constructor(
        public id:number, 
        public uuid:string,
        public name:string, 
        public description:string="", 
        public testSuiteId:number=0, 
        public testSuiteName:string="", 
        public author:string="",
        public dateCreated:Date=null,
        public enabled:boolean=false,
        public status:number=0,
        public validationJavaScript:string=''){

    }
}
