import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  showSpinner:boolean
  notificationOptions:any;
  emailAddress:string;

  constructor(private authService:AuthService,
              private notificationService:NotificationsService,
              private router:Router ) { 
    this.showSpinner = false;
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
  }

  ngOnInit() {
  }

  resetPassword(){
    this.showSpinner = true;
    this.authService.resetPassword(this.emailAddress, (err, message)=>{
      if ( err ){
        this.showSpinner = false;
        console.log(err);
        this.notificationService.error('Oops!', 'Your password could not be reset. Please contact support.', this.notificationOptions);
      } else {
        this.showSpinner = false;
        console.log(message);
        this.notificationService.success('Success!', message, this.notificationOptions);
        this.emailAddress = '';
        
      }
    });
  }

  onNotificationDestroyed(event){
    console.log(event);
    if ( event.type == 'success'){
      window.location.href = "/";
    }
  }

}