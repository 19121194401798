<h4>Matcher Attributes</h4>
<ejs-grid 
    #resourceMatcherExtractorAttributesGrid 
    id='resourceMatcherExtractorAttributesGrid'
    [dataSource]='model.attributes'
    [allowPaging]='true'
    [pageSettings]='attributePageSettings' 
    [editSettings]='attributeEditSettings'                 
    [allowSelection]='true' 
    [toolbar]='attributeToolbar'
    allowResizing="true"
    (actionBegin)="attributeActionBegin($event)"
>
    <e-columns>                    
        <e-column field='matcherAttributeKeyId' headerText='Name' editType='dropdownedit' [edit]='matcherAttributeKeyEditParams'>
            <ng-template #template let-data>
                {{ matcherAttributeKeysById[data.matcherAttributeKeyId].name }}
            </ng-template>
        </e-column>
        <e-column field='value' headerText='Value'></e-column>
    </e-columns>
</ejs-grid>

<h4>Matcher Flags</h4>
<ejs-grid 
    #resourceMatcherExtractorFlagsGrid 
    id='resourceMatcherExtractorFlagsGrid'
    [dataSource]='model.flags'
    [allowPaging]='true'
    [pageSettings]='flagsPageSettings' 
    [editSettings]='flagsEditSettings'                 
    [allowSelection]='true' 
    allowResizing="true"
    [toolbar]='flagsToolbar'
    (actionBegin)="flagsActionBegin($event)"
>
    <e-columns>                    
        <e-column field='id' headerText='Name' editType='dropdownedit' [edit]='flagsEditParams'>
            <ng-template #template let-data>
                {{ flagsById[data.id].name }}
            </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>


<!-- <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [options]="options" [model]="model"></formly-form>
    <button type="submit" class="btn btn-primary submit-button" [disabled]="!form.valid">Submit</button>
</form> -->