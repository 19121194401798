import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Query } from '@syncfusion/ej2-data';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VendorLibraryStateService } from '../../../services/vendorLibrary/vendor-library-state.service';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';
import { Extractor } from '../models/Extractor';

@Component({
  selector: 'app-resource-matcher-detail',
  templateUrl: './resource-matcher-detail.component.html',
  styleUrls: ['./resource-matcher-detail.component.css']
})
export class ResourceMatcherDetailComponent implements OnInit {

  form = new FormGroup({});
  model = null;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Name',
        placeholder: 'i.e. Google Analytics Library',
        required: true,
      }
    },
    {
      key: 'description',
      type: 'textarea',
      templateOptions: {
        label: 'Description',
        placeholder: 'i.e. A code library for setting up Google Analytics.',
        required: true,
        rows: 5
      }
    },
    {
      key: 'value',
      type: 'input',
      templateOptions: {
        label: 'Value',
        placeholder: 'Regex to apply against URL i.e. ^https?:\/\/www\.google-analytics\.com\/(?<uri>(?<path>(?:plugins\/ua\/|plugins\/ga\/|u\/))?(?<name>analytics|ec|linkid|ecommerce|inpage_linkid|ga|ga_exp|analytics_debug)\.js)$',
        required: true
      }
    },
    {
      key: 'isMonitoringEnabled',
      type: 'checkbox',
      templateOptions: {
        type: 'checkbox',
        label: 'Enabled?',
      },
    },
    {
      key: 'isMonitoringStatic',
      type: 'checkbox',
      templateOptions: {
        type: 'checkbox',
        label: 'Static?',
      },
    },
    {
      key: 'monitoringMask',
      type: 'input',
      templateOptions: {
        type: 'text',
        label: 'Mask',
        placeholder: 'Example: {{protocol}}://www.google-analytics.com/{{type}}/collect?tid={{accountId}}'
      },
    }
    //  {
    //   wrappers: ['monitoring'],
    //   templateOptions: { label: 'Monitoring' },
    //   fieldGroup: [
    //     {
    //       key: 'isMonitoringEnabled',
    //       type: 'checkbox',
    //       templateOptions: {
    //         type: 'checkbox',
    //         label: 'Enabled?',
    //       },
    //     },
    //     {
    //       key: 'isMonitoringStatic',
    //       type: 'checkbox',
    //       templateOptions: {
    //         type: 'checkbox',
    //         label: 'Static?',
    //       },
    //     },
    //     {
    //       key: 'monitoringMask',
    //       type: 'input',
    //       templateOptions: {
    //         type: 'text',
    //         label: 'Mask',
    //         placeholder: 'Example: {{protocol}}://www.google-analytics.com/{{type}}/collect?tid={{accountId}}'
    //       },
    //     },
    //   ],
    // },
  ];

  attributePageSettings;
  attributeEditSettings;
  attributeToolbar;

  pageSettings;
  
  extractorsEditSettings;
  extractorsToolbar;
  extractorsSelectOptions;

  commands;
  matcherAttributeKeyEditParams;
  matcherAttributeKeysById;

  domainDefinitionId:number;
  
  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private siteMapService:SitemapService,
    private vendorLibState:VendorLibraryStateService,
    private vendorLibService:VendorLibraryService,
    private spinnerService:SpinnerService,
    private notificationService:VaultNotificationService
  ) { 
      this.siteMapService.update(this.route);

      this.attributeEditSettings = {
        showDeleteConfirmDialog: true, 
        allowEditing: false, 
        allowAdding: true, 
        allowDeleting: true, 
        newRowPosition: 'Top'
      };

      this.attributeToolbar = ['Add', 'Delete', 'Cancel'];

      this.extractorsEditSettings = { 
        showDeleteConfirmDialog: true, 
        allowEditing: true, 
        allowAdding: true, 
        allowDeleting: true, 
        mode: 'Normal', 
        allowEditOnDblClick: true,
        newRowPosition: 'Top'
      };
      
      this.extractorsToolbar = ['Add', 'Cancel'];
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 50, 
        pageCount: 4, 
        pageSizes: [50, 75, 100]
      };
      this.extractorsSelectOptions = { type: 'Single' };

      this.commands = [
        { type: 'ExtractorDetails', buttonOption: { iconCss: ' icon zmdi zmdi-info', cssClass: 'e-flat' }, disabled: false },
        { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }
      ];

      this.matcherAttributeKeyEditParams = {
        params:   {
          allowFiltering: true,        
          fields: { text:'name', value:'id' },
          query: new Query(),
          actionComplete: () => false,
          dataSource: this.vendorLibService.matcherAttributeKeys
        }
      };
  }

  ngOnInit(): void {

    console.log(this.route.snapshot.params);

    this.domainDefinitionId = this.route.snapshot.params["domainDefId"];

    this.matcherAttributeKeysById = this.vendorLibService.matcherAttributeKeysById;
    
    this.vendorLibState.selectedResourceMatcher.subscribe((data)=>{
      this.model = data;
      // Need to do this?
      //this.options.updateInitialValue();
    });
  }

  onSubmit(model) {
    console.log(model);
  }

  extractorsCommandClick(event) {
    console.log(event);
    if ( event.commandColumn.type == 'ExtractorDetails'){
      this.vendorLibState.selectedExtractor.next(event.rowData);
      this.router.navigate(["extractor", event.rowData.id], {relativeTo: this.route});
    }    
  }

  extractorsActionBegin(event) {
    console.log(event);
    if ( event.action === 'add') {
      this.spinnerService.toggle(true);
      this.vendorLibService.createResourceMatcherExtractor(this.domainDefinitionId, this.model.id, event.data).subscribe((response)=>{
        console.log(response);
        this.spinnerService.toggle();
        this.refreshResourceMatcher();
      })
    } else if ( event.action == 'edit'){
      console.log("EDIT!");
      let changes = this.vendorLibService.getChangedProperties(event.rowData, event.data);
      if ( changes ){
        this.spinnerService.toggle(true);
        this.vendorLibService.updateResourceMatcherExtractor(this.domainDefinitionId, this.model.id, changes).subscribe((response)=>{
          console.log(response);
          this.spinnerService.toggle();
          this.refreshResourceMatcher();
        });
      } else {
        this.notificationService.info("Info", "No changes were detected to save.")
      }
    } else if ( event.requestType == 'delete') {
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteResourceMatcherExtractor(this.domainDefinitionId, this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
        this.refreshResourceMatcher();
      });
    }
  }

  attributeActionBegin(event) {
    
    if ( event.action == 'add' ) {
      this.spinnerService.toggle(true);
      this.vendorLibService.createResourceMatcherAttribute(this.domainDefinitionId, this.model.id, event.data).subscribe((response)=>{
        console.log(response);
        this.spinnerService.toggle();
      });
    } else if ( event.requestType == 'delete' ){
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteResourceMatcherAttribute(this.domainDefinitionId, this.model.id, event.data[0].id).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
      });
    }
  }

  extractorsActionComplete(args) {
    console.log(args);    
  }

  refreshResourceMatcher(): void {
    this.spinnerService.toggle(true);
    this.vendorLibService.getDomainDefinition(this.domainDefinitionId)
    .subscribe(results=>{
      let domainDefObj = results[0];
      let resourceMatcher = domainDefObj.resourceMatchers.filter( item => item.id == this.model.id)[0];
      this.vendorLibState.selectedResourceMatcher.next(resourceMatcher);
      this.spinnerService.toggle();
    });
}

}