<div class="row">
    <div class="col-sm-12">
        <div class="card card-table card-border">
            <div class="card-header" style="display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                    <span class="title">Vendor ID Validation</span>
                    <span class="card-subtitle">Manage list of IDs that can be used
                        throughout the
                        system.</span>
                </div>

                <div>
                    <button type="button" class="control-buttons" style="background-color: darkgray;font-size: medium; margin-right: 10px;"
                        [disabled]="selectedList?.Name == null" (click)="reloadList()">
                        <span class="zmdi zmdi-refresh-sync"></span>
                    </button>
                    <button class="control-buttons" [disabled]="!selectedList || isPristine"
                        style="background-color: seagreen; font-size: medium;" type="button"
                        (click)="saveAllChanges()">Save
                        Changes</button>
                </div>

            </div>
            <div class="card-body" style="padding:25px" *ngIf="isInitialized else notInitialised">
                <div style="margin-bottom: 25px; display: flex; flex-direction: row; gap: 10px">
                    <div style="flex-shrink:1; width:400px;" style="display: none;">
                        <ejs-combobox #dropdown [dataSource]='lists' [fields]='cbFields'
                            placeholder='Select a List or Create a New List' width="100%">
                        </ejs-combobox>
                    </div>

                </div>
                <form [formGroup]="form">
                    <div formArrayName="kvp" *ngFor="let item of keyValuePairs.controls;let i=index"
                        style="margin: 20px 0px; padding-bottom:5px; border-bottom: .5px solid lightgrey;">
                        <div formGroupName="{{i}}">
                            <div
                                style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
                                <div
                                    style="background-color: #ececec; padding:5px; width: fit-content; border-radius: 0.25rem; border-bottom-right-radius: 0; border-bottom-left-radius: 0;">
                                    <ejs-textbox placeholder="Enter Key Name" formControlName="name"
                                        style="display: none;"></ejs-textbox>
                                    <span
                                        style="font-weight: bold; font-size: large; padding: 30px 10px 10px 10px;">{{item.value.name}}</span>
                                </div>
                            </div>
                            <div
                                style="display: flex; flex-direction: column; background-color: #ececec; gap: 5px; border-radius: 0.25rem; border-top-left-radius: 0;">
                                <div
                                    style="display: flex; flex-direction: row; gap:10px; justify-items: center; padding: 10px; align-items: center; height: fit-content; flex-shrink: 0;">
                                    <ejs-textbox style="flex-grow: 1;"
                                        placeholder="Enter an ID or a list of comma-separated IDs" id="name"
                                        width="100%" class="action-buttons" (keyup.enter)="onValueSubmit($event, i)"
                                        #txtNewVal></ejs-textbox>
                                    <button class="control-buttons"
                                        style="font-size: small; color: white; text-align: center; border: none; background-color: #6390ee; border-radius: .5rem; height: 30px; width: 30px; margin:5px;"
                                        (click)="onValueSubmit($event, i)"><span
                                            class="zmdi zmdi-long-arrow-down"></span></button>
                                    <ejs-textbox placeholder="Filter.." width="200px"
                                        (input)="onSearchValueChange($event,i)" class="action-buttons"></ejs-textbox>

                                </div>
                                <div
                                    style="display: flex; flex-direction: row; flex-wrap: wrap; flex-grow: 1; align-items: center; height: fit-content; padding: 10px 10px 10px 10px; background-color: white; margin:0px 10px 10px 10px; border-radius: 0.25rem; border: 1px lightgrey solid; gap: 5px">
                                    <ng-container *ngIf="item.value.value.length > 0; else noVals">
                                        <div *ngFor="let valueItem of item.value.value; let valueIndex=index"
                                            formArrayName="value"
                                            [style.filter]="valueItem.matched ? 'none' : 'opacity(0.2)'"
                                            style="margin: 2px;border-radius: 1rem; padding: 5px 10px 5px 15px ; height:fit-content; color: white; word-break: break-all; text-overflow: ellipsis; background-color: #6390ee; cursor: pointer; box-shadow: 0px 0px 2px 1px lightgrey;" (click)="onValueClick(valueItem)">
                                            {{valueItem.text}} <span class="zmdi zmdi-close-circle-o delete-x"
                                                (click)="onValueDelete(valueItem,i)"></span>
                                        </div>
                                    </ng-container>
                                    <ng-template #noVals>
                                        <div
                                            style="margin: 2px; padding: 5px 10px 5px 15px ; height:fit-content; color: lightgrey; flex-grow: 1; text-align: center;">
                                            List is Empty.
                                        </div>
                                    </ng-template>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <ng-template #notInitialised>
                <div class="card-header" style=" color: salmon">
                    Not Initialized. Contact support for assistance.
                </div>
            </ng-template>
        </div>
    </div>
</div>