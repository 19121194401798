<div class="row">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-2">
        <div class="card card-flat">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium;">Total Sites<br />Monitored</span>
                    <div class="be-booking-promo-amount">
                        <span class="singleNumber">
                            <ng9-odometer [number]="totalSites.monitored" format="(,ddd).d"></ng9-odometer>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-flat">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium;">{{floorAndFormat(totalSites.sites)}} sites total</span>
                    <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width: 200px">
                                        <div class="progress">
                                            <div class="progress-bar bg-success"
                                                [style.width.%]="getPercent(totalSites.monitored, totalSites.sites)">
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span
                                            style="padding-left: 10px; color: black; font-size: smaller; font-style: italic;">{{floorAndFormat(getPercent(totalSites.monitored,
                                            totalSites.sites))}}%</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2">
        <div class="card card-flat">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium;">Total Pages<br />Monitored</span>
                    <div class="be-booking-promo-amount">
                        <span class="singleNumber">
                            <ng9-odometer [number]="totalPages.monitored" format="(,ddd).d"></ng9-odometer>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-default">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium;">{{floorAndFormat(totalPages.pages)}} pages total</span>
                    <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width: 200px">
                                        <div class="progress">
                                            <div class="progress-bar bg-success"
                                                [style.width.%]="getPercent(totalPages.monitored, totalPages.pages)">
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span
                                            style="padding-left: 10px; color: black; font-size: smaller; font-style: italic;">{{floorAndFormat(getPercent(totalPages.monitored,
                                            totalPages.pages))}}%</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2">
        <div class="card card-flat" style="height: 230px">
            <div class="card-body">
                <div class="be-booking-promo-price">
                    <span style="font-size: medium; text-align: center;">Key Page <br />Violations</span>
                    <div class="be-booking-promo-amount">
                        <span class="price">
                            <div style="padding-top: 30px;">
                                <ng9-odometer [number]="keyPageViolations" format="(,ddd).d"
                                    style="color: red; font-size: larger;"></ng9-odometer>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card card-flat" style="height: 230px">
            <div class="card-body">

                <div class="be-booking-promo-price statPanel">
                    <span style="font-size: medium;">Scan Frequency</span>
                    <ejs-accumulationchart id="donut-container" #pie style='height: 150px; width: 600px'
                        [legendSettings]="legendSettings" [tooltip]="pieTooltip"
                        (tooltipRender)="tooltipRender($event);" selectionMode='Point'
                        (textRender)="onTextRender($event)" [center]="pieCenter" background="transparent">
                        <e-accumulation-series-collection>
                            <e-accumulation-series name='Pages Scanned' [dataSource]='data' xName='x' yName='y'
                                radius="100%" [dataLabel]="dataLabel" enableAnimation="true">
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="violationSummary" class="row justify-content-md-center">
    <div class="col-sm-10">
        <div class="card card-default">
            <div class="card-header">Violation Summary</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6 offset-md-3">
                        <ejs-grid #violationSummaryDG id="violationSummaryDG" [dataSource]='violationSummaryData'
                            [allowPaging]="false" [allowSorting]="true" [sortSettings]="sortSettings"
                            [allowSelection]="false" [enableHover]="false" locale='en-US' allowResizing='true'
                            [allowTextWrap]='true' (actionComplete)="refreshGraphs($event)"
                            (dataBound)="refreshGraphs($event)">
                            <e-columns>
                                <e-column field='type' textAlign="right" headerText='' width="10%"
                                    headerTextAlign="right">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col"
                                                style="font-size: large; font-weight: bold; padding-bottom: 5px;">
                                                {{data.type}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field="pages" headerText='% Pages' headerTextAlign="Center" width="12%"
                                    textAlign="center">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col"
                                                style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                {{data.pages}}%
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[0]"
                                                    [attr.values]="data.data"></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field="domains" headerText='# Domains' headerTextAlign="Center" width="10%"
                                    textAlign="center">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col"
                                                style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                {{data.domains}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[1]"
                                                    [attr.values]="data.data"></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field="total" headerText='Total Violations' headerTextAlign="Center"
                                    width="10%" textAlign="center">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col"
                                                style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                {{data.total.toLocaleString('en-US')}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[2]"
                                                    [attr.values]="data.data"></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="mapRow" class="row justify-content-md-center">
    <div class="col-sm-10">
        <div class="card card-default">
            <div class="card-header">Select Geography & Legislation</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-10">
                        <ejs-maps id='maps' #maps style="display:block;" [zoomSettings]='zoomSettings' [layers]='layers'
                            [legendSettings]="mapLegendSettings" (shapeSelected)="shapeSelected($event)"
                            (load)="mapsLoad($event)" (loaded)="loaded($event)"
                            (tooltipRender)="tooltipMapRender($event)" format='n' useGroupingSeparator="true">
                        </ejs-maps>
                    </div>
                    <div class="col" style="margin-left: -65px; padding-top: 175px;">
                        <button class="btn btn-space btn-secondary btn-big"
                            style=" background: linear-gradient(transparent 80%, #03AC13 20%) no-repeat; pointer-events: none;"><i
                                class="icon"><img src="/assets/img/coppa.png" width="29" height="25"></i> COPPA
                        </button>
                        <button class="btn btn-space btn-secondary btn-big"
                            style=" background: linear-gradient(transparent 27%, #C60D25 20%) no-repeat; pointer-events: none;"><i
                                class="icon zmdi zmdi-youtube-play" style="padding: 3px 6px 3px 6px !important"></i>
                            VPPA </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div id="ledGrid" class="row justify-content-md-center">
    <div class="col-sm-10">
        <div class="card card-default">
            <div class="card-header">Violation Summary</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-8 offset-md-2">
                        <ejs-grid #ledGrid id="ledGrid" [dataSource]='ledGridData' [allowPaging]="false"
                            [allowSorting]="true" [sortSettings]="sortSettings" [allowSelection]="false"
                            [enableHover]="false" locale='en-US' allowResizing='true' [allowTextWrap]='true'
                            (actionComplete)="refreshGraphs($event)" (dataBound)="refreshGraphs($event)">
                            <e-columns>
                                <e-column field='type' textAlign="right" headerText='' width="20%"
                                    headerTextAlign="right">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col"
                                                style="font-size: large; font-weight: bold; padding-bottom: 5px;">
                                                {{data.type}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field="total" headerText='Total' headerTextAlign="Center" width="20%"
                                    textAlign="center">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col"
                                                style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                {{data.total}}%
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[0]"
                                                    [attr.values]="data.data"></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field="sports" headerText='Sports' headerTextAlign="Center" width="20%"
                                    textAlign="center">
                                    <ng-template #template let-data>
                                        <div class="row">
                                            <div class="col">
                                                <div class="grid-container-element">
                                                    <div *ngFor="let led of data.leds[0]; let i=index" class="grid-child-element"
                                                        [class.red]="led.violations >= data.ledCutoff[0]"
                                                        [class.green]="led.violations < data.ledCutoff[0]" data-container="body"
                                                        data-toggle="popover" data-trigger="hover" data-placement="top"
                                                        data-html="true"
                                                        [attr.data-content]="'Site Group: ' + led.siteGroup + '<br/>Non-DCF Violations: ' + led.violations + '%'">
                                                        &nbsp;</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">                                        
                                                    <div class="col" style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                        {{data.sports}}%                                                
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[1]" [attr.values]="data.data"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </ng-template>
                                </e-column>
                                <e-column field="news" headerText='News' headerTextAlign="Center" width="20%"
                                    textAlign="center">
                                    <ng-template #template let-data>

                                        <div class="row">
                                            <div class="col">
                                                <div class="grid-container-element">
                                                    <div *ngFor="let led of data.leds[1]" class="grid-child-element"
                                                        [class.red]="led.violations >= data.ledCutoff[1]"
                                                        [class.green]="led.violations < data.ledCutoff[1]" data-container="body"
                                                        data-toggle="popover" data-trigger="hover" data-placement="top"
                                                        data-html="true"
                                                        [attr.data-content]="'Site Group: ' + led.siteGroup + '<br/>Non-DCF Violations: ' + led.violations + '%'">
                                                        &nbsp;</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col"
                                                        style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                        {{data.news.toLocaleString('en-US')}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[2]"
                                                            [attr.values]="data.data"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </ng-template>
                                </e-column>
                                <e-column field="brandedEnt" headerText='Branded Entertainment' headerTextAlign="Center"
                                    width="20%" textAlign="center">
                                    <ng-template #template let-data>


                                        <div class="row">
                                            <div class="col">
                                                <div class="grid-container-element">
                                                    <div *ngFor="let led of data.leds[2]" class="grid-child-element"
                                                        [class.red]="led.violations >= data.ledCutoff[2]"
                                                        [class.green]="led.violations < data.ledCutoff[2]" data-container="body"
                                                        data-toggle="popover" data-trigger="hover" data-placement="top"
                                                        data-html="true"
                                                        [attr.data-content]="'Site Group: ' + led.siteGroup + '<br/>Non-DCF Violations: ' + led.violations + '%'">
                                                        &nbsp;</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">                                
                                                    <div class="col"
                                                        style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                                        {{data.brandedEnt.toLocaleString('en-US')}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[2]"
                                                            [attr.values]="data.data"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row justify-content-md-center">
    <div class="col-10">
        <div class="card card-default">
            <div class="card-header">Violations By Vendor</div>
            <div class="card-body">
                <ejs-grid #vendorViolationGrid id="vendorViolationGrid" [dataSource]='griddata' [allowPaging]="true"
                    [allowSorting]="true" [sortSettings]="sortSettings" allowFiltering="true"
                    [pageSettings]="pageOptions" [allowSelection]="false" [enableHover]="false"
                    [filterSettings]="filterSettings" locale='en-US' allowResizing='true' [editSettings]='editSettings'
                    [allowTextWrap]='false'>
                    <e-columns>
                        <e-column field='vendor' headerText='Vendor' width="15%" textAlign="left"
                            headerTextAlign="Center">
                            <ng-template #template let-data>
                                <img style="width: 32px; height:32px;margin-right: 5px;"
                                    src="https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : 'unknown' }}.ico" /><span>{{data.vendor}}</span>
                            </ng-template>
                        </e-column>
                        <e-column field='violationShare' headerText='Share of Violations' width='30%'
                            textAlign='Center'>
                            <ng-template #template let-data>
                                <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                                    <div class="col-6">
                                        <div class="progress">
                                            <div class="progress-bar bg-success" [style.width.%]="data.violationShare">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        {{data.violationShare}}% ({{data.violationShareTotal}})
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field='pageShare' headerText='Share of Pages' width='30%' textAlign='Center'>
                            <ng-template #template let-data>
                                <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                                    <div class="col-6">
                                        <div class="progress">
                                            <div class="progress-bar bg-success" [style.width.%]="data.pageShare"></div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        {{data.pageShare}}% ({{data.pageShareTotal}})
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field='dcfViolationPercent' headerText='% DCF Violations' width='25%'
                            textAlign='Center'>
                            <ng-template #template let-data>
                                <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                                    <div class="col-10">
                                        <div class="progress">
                                            <div class="progress-bar bg-success"
                                                [style.width.%]="data.dcfViolationPercent"></div>
                                        </div>
                                    </div>
                                    <div class="col-2">{{data.dcfViolationPercent}}%</div>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>

    </div>
</div>