<div class="row">
    <div class="col-sm-12">
        <div class="card card-table card-border">
            <div class="card-body">
                <ejs-grid #reportDataGrid 
                id="data"
                allowFiltering="true" 
                allowResizing='true' 
                locale='en-US'
                [dataSource]='reportDataManager' 
                [allowPaging]="true" 
                [allowSorting]="true"
                [filterSettings]="filterSettings"
                [allowSelection]="false" 
                [enableHover]="false"
                [allowTextWrap]='true'
                (dataBound)="onDataBound()"
                >
                </ejs-grid>
            </div>
        </div>
    </div>
</div>