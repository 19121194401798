import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frequencyInMins'
})
export class FrequencyInMinsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 1440:
        return 'Daily';
      case 10080:
        return 'Weekly';
      case 43200:
        return 'Monthly';
      case 525600:
        return 'Yearly';
      default:
        return value;
    }
  }

}
