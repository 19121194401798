<div class="card" style="display: flex; flex-direction: row; flex-wrap: nowrap;">
    <div class="card-lhs" style="padding: 0; margin: 0;">
        <div class="card-header" style=" font-size: large; width: fit-content; ">{{title}}</div>
        <div style="padding: 0; margin:auto; border: 0px solid red; width: fit-content; padding-bottom: 10px; padding-top:10px;">
            <div style="text-align: left; margin:0px; padding:0px; font-size: x-large;">{{errors}}<span style="font-size: medium; margin-left: 5px">errors</span></div>
            <div
                style=" text-align: left;  margin:auto; padding: auto; font-size: x-large; width: fit-content; color: grey">
                in {{_max}}<span style="font-size: medium; margin-left: 5px">days {{extraLabel}}</span></div>
        </div>

    </div>
    <div class="card-rhs" style="border: 0px solid red; position: relative; width: 300px; margin: auto; flex-shrink: 1;">
        <div style=" position:absolute; padding: 0px; margin:auto; top:0; bottom:0; height: fit-content; width: fit-content; left: 0; right: 0; font-size: x-large; font-weight: bold;">
            {{errors}}%</div>
            <ejs-chart [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
            [chartArea]='chartArea' [margin]='margin' height="200">
            <e-series-collection>
                <e-series [dataSource]='data' type='Scatter' xName='x' yName='y' fill="#00dbff">
                    <e-trendlines>
                        <e-trendline type='Linear' width=5 name='Linear' fill='#C64A75'>
                        </e-trendline>
                    </e-trendlines>
                </e-series>
            </e-series-collection>
            </ejs-chart>
        
    </div>
</div>
