<div class="row" style="margin-bottom: 25px;">
    <div class="col-lg-2" style="display: flex; justify-content: center; align-items: center;">
        <img src="assets/img/logo-tiktok.webp" alt="Facebook Logo" height="125px">
    </div>
    <div class="col-lg-7">
        <div class="report-title">TikTok Vendor Analysis</div>
        <div class="report-meta">Report Generated on 27th May 2024</div>
    </div>
</div>
<div class="row" style="margin-bottom:25px;">
    <div class="col-lg-8">
        <ejs-dropdownlist width="100%" [dataSource]="[]" placeholder="Select a page"
            ></ejs-dropdownlist>
    </div>
    <div class="col-lg-4">
        <div style="display: flex; flex-direction:row; padding: 5px 10px; height: 100%; gap:10px; justify-content: flex-end; border-radius: .25rem; background-color: white; align-items: center;
                    box-shadow: 0px 0px 2px #22222222;">
            <div style="flex-grow:1;">Advanced Details View</div>
            <ejs-switch [checked]="true"></ejs-switch>

        </div>
    </div>
</div>


<div class="row">

    <div class="col-lg-6" style="border: 0px solid #fc7d73;">
        <app-title-card>
            <ng-container header>
                <span class="zmdi zmdi-dns title-icon"></span>
                Deployed Technology
            </ng-container>
            <div class="flex-row" style="font-size:large;">
                <div class="flex-column">
                    <div><span class="zmdi" [class]="true ?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="true ? 'green' : '#fc7d73'" style="font-size:xx-large;"></span>
                    </div>
                    <div>Event JS</div>
                </div>
                <div class="flex-column">
                    <div><span class="zmdi" [class]="true?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="true ? 'green' : '#fc7d73'" style="font-size:xx-large;"></span>
                    </div>
                    <div>Main JS</div>
                </div>
                <div class="flex-column">
                    <div><span class="zmdi" [class]="true ?'zmdi-check-circle' :'zmdi-close-circle'"
                            [style.color]="true ? 'green' : '#fc7d73'" style="font-size:xx-large;"></span></div>
                    <div>Identity JS</div>
                </div>
            </div>
        </app-title-card>

    </div>
    <div class="col-lg-6">
        <app-title-card>
            <ng-container header>
                <span class="zmdi zmdi-dns title-icon"></span>
                Event Tracking
            </ng-container>
            <div class="flex-row" style="font-size:large;">
                NEED TO ADD EVENT TRACKING
            </div>
        </app-title-card>
    </div>

</div>

<div class="row">
    <div class="col-lg-8" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-image-o title-icon"></span>
                Automatic Event Details
            </ng-container>
            <ejs-grid [dataSource]="[]" height="100%">
                <e-columns>
                    <e-column headerText="Name"></e-column>
                    <e-column headerText="Value"></e-column>
                </e-columns>
            </ejs-grid>
        </app-title-card>
    </div>
    <div class="col-lg-4" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-image-o title-icon"></span>
                JSON VIEWER
            </ng-container>
            <div class="flex-row">
            </div>
        </app-title-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-8" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-image-o title-icon"></span>
                Named Event Details
            </ng-container>
            <!-- <div class="flex-row"> -->
            <ejs-grid [dataSource]="[]" height="100%">
                <e-columns>
                    <e-column headerText="Name"></e-column>
                    <e-column headerText="Value"></e-column>
                </e-columns>
            </ejs-grid>

            <!-- </div> -->
        </app-title-card>
    </div>
    <div class="col-lg-4" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-image-o title-icon"></span>
                JSON VIEWER
            </ng-container>
            <div class="flex-row">

            </div>
        </app-title-card>
    </div>
</div>
<!--

<div class="row">
    <div class="col-lg-6" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-image-o title-icon"></span>
                Tracking Pixels IDs
            </ng-container>
            <div class="flex-row">
                <div class="flex-column" style="padding:5px; min-width: 200px;">
                    <div style="font-size:xx-large">{{PIDData?.length || 'No Pixels'}}</div>
                    <div>Detected</div>
                </div>
                <div style="flex-grow: 1;">
                    <ejs-grid height="100%" [dataSource]="PIDData" width="100%">
                    </ejs-grid>
                </div>
            </div>
        </app-title-card>
    </div>

    <div class="col-lg-6" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-videocam title-icon"></span>
                Video on Page
            </ng-container>
            <div style="height:100%">
                <ejs-grid height="100%"
                    [dataSource]="[{vendor:'Youtube',request:'youtube.com/s/player',extractedName:'Some Interesting Video'}]"></ejs-grid>
            </div>

        </app-title-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-12" style="border: 0px solid #fc7d73;">
        <app-title-card height="250px">
            <ng-container header>
                <span class="zmdi zmdi-file-text title-icon"></span>
                Config File Parameters
            </ng-container>
            <div class="flex-row" style="height: 100%;">
                <div class="flex-column" style="padding:5px; min-width: 400px;">
                    <div style="font-size:x-large">Config File {{CFSData.length === 0 ? 'Not' : ''}} Detected
                    </div>
                    <div *ngIf="CFSData.length !== 0 ">with {{CFSData.length || 0}} Parameter(s)</div>
                </div>
                <div style="height:100%; flex-grow: 1;">
                    <ejs-grid height="100%" [dataSource]="CFSData" width="100%">
                        <e-columns>
                            <e-column field="configOpt" headerText="Config Option" textAlign="left">
                                <ng-template #template let-data>
                                    <ejs-tooltip
                                        [content]="FBDetailsService.getDescription('configOptions.'+data.configOpt)">
                                        <div>{{data.configOpt}}</div>
                                    </ejs-tooltip>
                                </ng-template>
                            </e-column>
                            <e-column field="value" headerText="Value" textAlign="left">
                                <ng-template #template let-data>
                                    <div>
                                        {{data.value}}
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </app-title-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <app-title-card height="500px">
            <ng-container header>
                <span class="zmdi zmdi-info-outline title-icon"></span>
                All Parameters
            </ng-container>
            <div class="flex-row" style="height:100%">
                <ejs-grid height="100%" [dataSource]="FBDData"
                    [groupSettings]="{ columns: ['category'],showDropArea: false }" [allowGrouping]="true">
                    <e-columns>
                        <e-column field="category"></e-column>
                        <e-column field="param" headerText="Parameter" textAlign="left" [allowGrouping]='false'>
                            <ng-template #template let-data>
                                <ejs-tooltip
                                    [content]="data.name+ ': ' + (data.explanation || 'No Description Available')">
                                    <div style='font-family:monospace; font-size: medium;'>{{data.param}}</div>
                                </ejs-tooltip>

                            </ng-template>
                        </e-column>
                        <e-column field="value" headerText="Value" textAlign="left" [allowGrouping]='false'>
                            <ng-template #template let-data>
                                <div>
                                    {{data.value}}
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                    <ng-template #groupSettingsCaptionTemplate let-data>
                        <span style="font-weight: bold;">{{ data.key }}</span>
                    </ng-template>
                </ejs-grid>
            </div>
        </app-title-card>
    </div>
</div> -->