import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '../../models/Page';
import { environment as env } from "../../../environments/environment";
import { HttpParams, HttpClient } from '@angular/common/http';
import { VaultBaseService } from '../VaultBaseService';
import { PageHistory } from '../../models/PageHistory';

@Injectable({
  providedIn: 'root'
})
export class PageService extends VaultBaseService {

  cache:{[type:string]:Observable<any>;};
  public selectedPage:BehaviorSubject<Page>;
  public selectedPageHistory:BehaviorSubject<PageHistory>;

  private static GET_PAGES:string = "getPages";
  private static GET_PAGE:string = "getPage";
  private static GET_PAGE_HISTORY:string = "getPageHistory";
  private static GET_PAGE_DATA:string = "getPageData"

  constructor(authHttp: HttpClient) { 
    super(authHttp);
    this.cache = {};
    this.selectedPage = new BehaviorSubject(new Page(0, "", ""));
    this.selectedPageHistory = new BehaviorSubject(new PageHistory("", "", ""));
  }

  public getPages(start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{    
    const cacheKey = `${PageService.GET_PAGES}`;
    const url = `${env.api.url}pages`;
    console.log("search ", search);
    const params = new HttpParams()
                  .set("start", start.toString())
                  .set("length", length.toString())
                  .set("order", order)
                  .set("orderDir", orderDirection)
                  .set("search", search)
                  .set("draw", draw.toString())
                  ;
    this.cache[cacheKey] = this.makeHttpRequest(url, params, false);
    
    return this.cache[cacheKey];
  }

  public getPagesV2(data:any):Observable<any>{
    const url = `${env.api.url}pagesv2`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public getPage(pageId:number):Observable<any>{    
    const cacheKey = `${PageService.GET_PAGE}:${pageId}`;
    
    const url = `${env.api.url}pages/${pageId}`;
    this.cache[cacheKey] = this.makeHttpRequest(url, null, false);
    
    return this.cache[cacheKey];

  }

  public getPageHistory(pageId:number, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{    
    const cacheKey = `${PageService.GET_PAGE_HISTORY}`;
    
    
    const url = `${env.api.url}pages/${pageId}/historyV3`;
    const params = new HttpParams()
                  .set("start", start.toString())
                  .set("length", length.toString())
                  .set("order", order)
                  .set("orderDir", orderDirection)
                  .set("search", search)
                  .set("draw", draw.toString())
                  ;
    this.cache[cacheKey] = this.makeHttpRequest(url, params, false);
    
    return this.cache[cacheKey];

  }

  public getPageData(pageId:number):Observable<any>{    
    const cacheKey = `${PageService.GET_PAGE_DATA}:${pageId}`;
    
    const url = `${env.api.url}pages/${pageId}/data`;
    this.cache[cacheKey] = this.makeHttpRequest(url, null, false);
    
    return this.cache[cacheKey];

  }

  public updatePage(pageInfo:any):Observable<any>{
    const url = `${env.api.url}pages/${pageInfo.pageId}`;
    return this.authHttp.put(url, JSON.stringify(pageInfo));
  }

  public createLink(link:string):Observable<any>{
    const url = `${env.api.url}pages`;
    return this.authHttp.post(url, JSON.stringify({link: link}));
  }

  public getS3File(path):Observable<any>{
    const cacheKey = path;
    if (!this.cache[cacheKey]){
      const url = path;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  };

  public getS3ImageFile(path):Observable<any>{
    const cacheKey = path;
    if (!this.cache[cacheKey]){
      const url = path;
      this.cache[cacheKey] = this.authHttp.request("GET", url, {responseType: "blob"});
    }
    return this.cache[cacheKey];
  };
}


// {
//   "Records": [
//     {
//       "messageId": "19dd0b57-b21e-4ac1-bd88-01bbb068cb78",
//       "receiptHandle": "MessageReceiptHandle",
//       "body": "{\"customerId\": 39, \"linkId\":7608604, \"url\":\"https://www.creditkarma.com/\", \"private\":true}",
//       "attributes": {
//         "ApproximateReceiveCount": "1",
//         "SentTimestamp": "1523232000000",
//         "SenderId": "123456789012",
//         "ApproximateFirstReceiveTimestamp": "1523232000001"
//       },
//       "messageAttributes": {},
//       "md5OfBody": "7b270e59b47ff90a553787216d55d91d",
//       "eventSource": "aws:sqs",
//       "eventSourceARN": "arn:aws:sqs:us-east-1:123456789012:MyQueue",
//       "awsRegion": "us-east-1"
//     }
//   ]
// }