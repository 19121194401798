import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GridModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { AuthService } from '../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
const data = require('./data.json');

@Component({
  selector: 'app-fingerprint-dashboard',
  templateUrl: './fingerprint-dashboard.component.html',
  styleUrls: ['./fingerprint-dashboard.component.css']
})
export class FingerprintDashboardComponent implements OnInit {

  public isLoaded: BehaviorSubject<boolean>;
  public barChartData: any;
  public histData: any;
  public atributeData: any[];
  public vendorGridData: any[];
  public pageGridData: any;

  public vendorGridHeaders: any;
  public vendorChildGrid: GridModel;
  public pageGridHeaders: any;
  public pageChildGrid: GridModel;

  constructor(private spinnerService: SpinnerService,
    private authService: AuthService,
    private notificationService: VaultNotificationService,
    private dataExportService: DataExportService) {

    this.isLoaded = new BehaviorSubject<boolean>(false);
    this.vendorGridHeaders = [
      {
        field: 'vendor',
        headerText: 'Vendor',
        textAlign: 'Left',
      },
      {
        field: 'attributes',
        headerText: 'Unique Attributes',
        textAlign: 'Left',
      },
      {
        field: 'pagesFound',
        headerText: 'Pages Found',
        textAlign: 'Left',
      }
    ];
    this.vendorChildGrid = {
      queryString: 'vendor',
      columns: [
          {
            field: 'domain',
            headerText: 'Domain',
            textAlign: 'Left',
          },
          {
            field: 'attributeCollected',
            headerText: 'Attributes Collected',
            template: '<div>${attributeCollected}</div>'
          },
      ],
    };
    this.pageGridHeaders = [
      {
        field: 'page',
        headerText: 'Page',
        textAlign: 'Left',
      },
      {
        field: 'group',
        headerText: 'Group',
        textAlign: 'Left',
      },
      {
        field: 'uniqAttributes',
        headerText: 'Unique Attributes',
        textAlign: 'Left',
      },
      {
        field: 'totalAttributes',
        headerText: 'Total Attributes',
        textAlign: 'Left',
      }
    ];
    this.pageChildGrid = {
      queryString: 'page',
      columns: [
        { field: 'vendor', headerText: 'Vendor' },
        { field: 'domain', headerText: 'Domain' },
        { field: 'attributes', headerText: 'Attributes', template: '<div>${attributes}</div>' },
      ],
    }
  }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    const CID = parseInt(this.authService.customerId());
    this.dataExportService.getDashboardFile('FPDashboard.json', CID).subscribe({
      next: (data) => {
        try {
          this.loadData(JSON.parse(data.toString()));
        } catch (error) {
          console.log(error);
          this.notificationService.error('Unable to load data.', 'Please try again or contact support');
        }
      },
      error: (err) => {
        console.log(err);
        this.notificationService.error('Unable to load data.', 'Please try again or contact support');
      },

    });
  }

  loadData(FPJSON) {
    console.log(FPJSON);
    this.barChartData = FPJSON['barChartData'];
    this.atributeData = FPJSON['attributesCollected'];
    this.histData = FPJSON['histData'];
    this.vendorGridData = FPJSON['vendorGridData'];
    this.pageGridData = FPJSON['pageGridData'];
    this.spinnerService.toggle(false);
    this.isLoaded.next(true);
  }

}
