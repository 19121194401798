import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, SortService } from '@syncfusion/ej2-angular-grids';
import { NotificationsService } from 'angular2-notifications';
import { Papa } from 'ngx-papaparse';
import { environment as env } from "../../../environments/environment";
import { CustomerService } from '../../services/customer/customer.service';
import { DataExportService } from '../../services/data-export/data-export.service';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Component({
  selector: 'app-data-viz-nurtec2',
  templateUrl: './data-viz-nurtec2.component.html',
  styleUrls: ['./data-viz-nurtec2.component.css']
})
export class DataVizNurtec2Component implements OnInit {

  data:any;
  domains:any;
  public groupOptions: Object;
  public pageSettings: Object;
  public sortSettings: Object;
  public filterSettings: Object;
  @ViewChild('grid')public grid: GridComponent;
  public cdnUrl:string;
  notificationOptions:any;
  public toolbar:any;

  constructor(public papa:Papa, public customerService:CustomerService, public dataExportService:DataExportService, public spinnerService:SpinnerService, public notificationService:NotificationsService) { 
    this.pageSettings = { currentPage: 1, pageSize: 500 };
    this.sortSettings = { 
      columns: [
        { field: 'Present', direction:'Descending'}        
      ] 
    };
    this.filterSettings = { type: 'Excel' };
    this.cdnUrl = env.cdn.url;
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.toolbar = ['Search'];
  }

  ngOnInit(): void {
    this.papa.parse('/assets/data/nurtec/details.tsv', {
      header: true,
      download: true,
      skipEmptyLines: "greedy",
      dynamicTyping: true,
      complete: (results, file)=>{
        console.log(results);
        this.data = results.data;
      },
      error: (error, file)=>{
        console.log(error);
      }
    });
    
  }
}
