import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Condition } from '../../models/Condition';
import { TestSuite } from '../../models/TestSuite';
import { DataTableResponse } from '../../models/DataTableResponse';
import { ValidationRule } from '../../models/ValidationRule';
import { VaultBaseService } from '../VaultBaseService';
import { ValidationRuleHistory } from '../../models/ValidationRuleHistory';
import { environment as env } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataValidationService extends VaultBaseService {
  
  private SERVICE_NAME = 'dataValidation'
  private TEST_SUITE_BASE_URL = `${env.api.url}${this.SERVICE_NAME}/testSuite`;
  private VALIDATION_RULE_BASE_URL = `${env.api.url}${this.SERVICE_NAME}/validationRule`;
  private CONDITION_BASE_URL = `${env.api.url}${this.SERVICE_NAME}/condition`;
  private REPORTING_BASE_URL = `${env.api.url}${this.SERVICE_NAME}/reporting`;

  public selectedTestSuite:BehaviorSubject<TestSuite>;
  public selectedValidationRule:BehaviorSubject<ValidationRule>;

  drawCounters:number[];

  testSuites:TestSuite[];
  conditions:Condition[];
  validationRules:ValidationRule[];
  validationRuleHistory:ValidationRuleHistory[][];

  constructor(authHttp: HttpClient) { 
    super(authHttp); 
    
    this.drawCounters = Array(6).fill(1);

    this.selectedTestSuite = new BehaviorSubject(new TestSuite(-1, "", "", 0, "", 0, null, false));
    this.selectedValidationRule = new BehaviorSubject(null);
    this.validationRuleHistory = [
      [
        new ValidationRuleHistory(0, "https://www.creditkarma.com", new Date(), "Pass", "N/A"),
        new ValidationRuleHistory(1, "https://subdomain.creditkarma.com/", new Date(), "fail", "GA ID was not found on this page"),
        new ValidationRuleHistory(2, "https://www.creditkarma.com/sales", new Date(), "Pass", "N/A")
      ]
    ]
  }

  //#region TEST SUITE CRUD
  public getTestSuites(testSuiteId:number, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<DataTableResponse<TestSuite[]>>{   
    const params = new HttpParams()
                  .set("start", start.toString())
                  .set("length", length.toString())
                  .set("order", order)
                  .set("orderDir", orderDirection)
                  .set("search", search)
                  .set("draw", draw.toString())
                  ;
  
    return this.makeHttpRequest(this.TEST_SUITE_BASE_URL + "s", params, false);
  }

  public getTestSuite(testSuiteId:number):Observable<TestSuite>{
    const url = `${this.TEST_SUITE_BASE_URL}/${testSuiteId}`;
    return this.authHttp.get(url) as Observable<TestSuite>;
    // return null;
  }

  public createTestSuite(newTestSuite:TestSuite):Observable<any>{
    return this.authHttp.post(this.TEST_SUITE_BASE_URL, JSON.stringify(newTestSuite));
  }

  public updateTestSuite(testSuite:TestSuite):Observable<any>{
    const url = `${this.TEST_SUITE_BASE_URL}/${testSuite.id}`;
    return this.authHttp.put(url, JSON.stringify(testSuite));
  }

  public deleteTestSuite(testSuite:TestSuite):Observable<any> {
    const url = `${this.TEST_SUITE_BASE_URL}/${testSuite.id}`;
    return this.authHttp.delete(url);
  }
  //#endregion

  //#region VALIDATION RULE CRUD
  public getRules():Observable<DataTableResponse<ValidationRule[]>>{
    return of(new DataTableResponse(this.drawCounters[2]++, this.validationRules.length, this.validationRules.length, this.validationRules)).pipe(delay(1000));
  }

  public getRulesForTestSuite(testSuiteId:number, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<DataTableResponse<ValidationRule[]>>{
    
    // FYI TEST_SUITE_BASE_URL is the correct base in this function...    
    const url = `${this.TEST_SUITE_BASE_URL}/${testSuiteId}/rules`;
    
    const params = new HttpParams()
                  .set("start", start.toString())
                  .set("length", length.toString())
                  .set("order", order)
                  .set("orderDir", orderDirection)
                  .set("search", search)
                  .set("draw", draw.toString())
                  ;
  
    return this.makeHttpRequest(url, params, false);
  }

  public createValidationRule(newValidationRule:ValidationRule):Observable<any>{
    return this.authHttp.post(this.VALIDATION_RULE_BASE_URL, JSON.stringify(newValidationRule));
  }

  public updateValidationRule(validationRule:ValidationRule):Observable<any>{
    const url = `${this.VALIDATION_RULE_BASE_URL}/${validationRule.id}`;

    return this.authHttp.put(url, JSON.stringify(validationRule));
  }

  public deleteValidationRule(validationRule:ValidationRule):Observable<any> {
    const url = `${this.VALIDATION_RULE_BASE_URL}/${validationRule.id}`;
    return this.authHttp.delete(url);
  }

  public getValidationRuleHistory(ruleUUID:string, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{
    // return of(new DataTableResponse(this.drawCounters[4]++, 0, 0, this.validationRuleHistory[0])).pipe(delay(1000));
    var url = `${this.VALIDATION_RULE_BASE_URL}/${ruleUUID}/history`;
    const params = new HttpParams()
                  .set("offset", start.toString())
                  .set("limit", length.toString())
                  .set("order", order)
                  .set("orderDir", orderDirection)
                  .set("search", search)
                  .set("draw", draw.toString())
                  ;
    return this.makeHttpRequest(url, params, false);
  }

  public getValidationRuleHistoryData(ruleUUID:string, dataId:string, totalId:string, draw:number):Observable<any>{
    var url = `${this.VALIDATION_RULE_BASE_URL}/${ruleUUID}/history`;
      var params = new HttpParams()
        .set('dataId',dataId)
        .set('totalId',totalId)
        .set('draw', draw.toString());
      return this.makeHttpRequest(url, params);
  };

  //#endregion

  //#region CONDITION CRUD
  public getConditions(start:number, length:number, order:string, orderDirection:string, search:string, draw:number, simple:boolean=false):Observable<DataTableResponse<Condition[]>>{   
    const params = new HttpParams()
                  .set("start", start != null ? start.toString() : null)
                  .set("length", length ? length.toString() : null)
                  .set("order", order)
                  .set("orderDir", orderDirection)
                  .set("search", search)
                  .set("draw", draw != null ? draw.toString() : null)
                  .set("simple", simple.toString());
                  ;
  
    return this.makeHttpRequest(this.CONDITION_BASE_URL, params, false);
  }

  public createCondition(newCondition:Condition):Observable<any>{
    return this.authHttp.post(this.CONDITION_BASE_URL, JSON.stringify(newCondition));
  }

  public updateCondition(condition:Condition):Observable<any>{
    const url = `${this.CONDITION_BASE_URL}/${condition.id}`;
    return this.authHttp.put(url, JSON.stringify(condition));
  }

  public deleteCondition(condition:Condition):Observable<any> {
    const url = `${this.CONDITION_BASE_URL}/${condition.id}`;
    return this.authHttp.delete(url);
  }
  //#endregion

  public getSummaryReport(provider):Observable<any>{     
    return this.makeHttpRequest(`${this.REPORTING_BASE_URL}/summary/${provider}`, null, false);
  }

}