import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BarChartSeries } from '../../dashboardDS';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-bar-chart-panel',
  templateUrl: './bar-chart-panel.component.html',
  styleUrls: ['./bar-chart-panel.component.css']
})
export class BarChartPanelComponent implements OnInit, OnChanges {
  @Input('title') title: string;
  @Input('yLabel') yLabel: string;
  @Input('data') chartData: BarChartSeries;
  @Input('width') width: number;

  public primaryXAxis?: any;
  public primaryYAxis: any;
  public chartArea: any;
  public tooltip: any;
  public margin: any;
  public series: any;
  public series1: any;

  constructor() {
    this.title = 'No Data Found';
    this.width = 500;
    this.primaryXAxis = {
      // visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      },
      valueType: 'Category',
      labelIntersectAction: 'Rotate90',
    };
    this.primaryYAxis = {
      // visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      },
    };
    this.chartArea = {
      border: { width: 0 },
      title: this.title
    };
    this.margin = {
      top: 25,
      right: 25,
      bottom: 25,
      left: 25
    };
  }

  ngOnInit(): void {
    this.chartArea.title = this.title;
    this.primaryYAxis.title = this.yLabel;
    this.tooltip = {
      enable: true,
      shared: true,
    };
  }

  ngOnChanges(): void {
    this.updateData();
  }

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    const point = args.series.dataSource[args.data.pointIndex];
    args.text = "<b>" + args.text + "<b> of " + point.totalInstances + " Validations</b>";
    // console.log(args.text)
  }

  updateData(): void {
    if (this.chartData.series.length) {
      this.series = this.chartData.series.map((data) => {
        return {
          x: data.varName,
          y: data.totalErrors,
          totalInstances: data.totalInstances,
        }
      });
      this.series1 = this.chartData.series.map((data) => {
        return {
          x: data.varName,
          y1: data.totalInstances - data.totalErrors,
          totalInstances: data.totalInstances,
        }
      });
    }
  }

}
