<div class="card card-default">
    <div class="card-header">Violations By Vendor</div>
    <div class="card-body" *ngIf="isLoaded; else stillLoading">
        <ejs-grid #vendorViolationGrid id="vendorViolationGrid" [dataSource]='data' [allowPaging]="true"
            [allowTextWrap]='false' [allowSorting]="true">
            <e-columns>

                <e-column field='vendor' headerText='Vendor' width="15%" textAlign="left" headerTextAlign="Center">
                    <ng-template #template let-data>
                        <img style="width: 32px; height:32px;margin-right: 5px;"
                            src="https://{{cdnUrl}}/vendors/icon/{{ data.vendorDomain ? data.vendorDomain.toLowerCase() : 'unknown' }}.ico" /><span>{{data.vendorName}}</span>
                    </ng-template>
                </e-column>

                <e-column field='violationShare' headerText='Share of Violations' width='30%' textAlign='Center'>
                    <ng-template #template let-data>
                        <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                            <div class="col-6">
                                <div class="progress">
                                    <div class="progress-bar" [style.width.%]="data.violationShare"
                                        [style.background-color]="colorDefine(data.violationShare)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                {{data.violationShare.toFixed(3).replace(percentRegex,"")}}% ({{data.violationCount}})
                            </div>
                        </div>
                    </ng-template>
                </e-column>
                <e-column field='pageShare' headerText='Share of Pages' width='30%' textAlign='Center'>
                    <ng-template #template let-data>
                        <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                            <div class="col-6">
                                <div class="progress">
                                    <div class="progress-bar" [style.width.%]="data.pageShare" [style.background-color]="colorDefine(data.pageShare)"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                {{data.pageShare.toFixed(3).replace(percentRegex,"")}}% ({{data.pageCount}})
                            </div>
                        </div>
                    </ng-template>
                </e-column>
                <!-- <e-column *ngFor="let columns of otherMetricsList;index as i" [field]='columns.key'
                    [headerText]='columns.label' width='15%' textAlign='Center'>
                    <ng-template #template let-data>
                        <div class="row user-progress" style="margin-top:5px; margin-bottom: 0px !important;">
                            <div class="col-10">
                                <div class="progress">
                                    <div class="progress-bar " [style.width.%]="data.otherMetrics[i].share"
                                        [style.background-color]="colorDefine(data.otherMetrics[i].share)"></div>
                                </div>
                            </div>
                            <div class="col-2">{{data.otherMetrics[i].count}}%</div>
                        </div>
                    </ng-template>
                </e-column> -->

            </e-columns>
        </ejs-grid>
    </div>
    <ng-template #stillLoading>
        <div class="card-body">Loading...</div>
    </ng-template>
</div>