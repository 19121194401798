import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthCallbackIdPComponent } from './auth-callback-idp/auth-callback-idp.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    AuthCallbackComponent,
    AuthCallbackIdPComponent,
    LoginComponent,
    LogoutComponent,
    PasswordResetComponent
  ],
  exports: [
    AuthCallbackComponent,
    AuthCallbackIdPComponent,
    LoginComponent,
    LogoutComponent,
    PasswordResetComponent
  ]
})
export class AuthComponentsModule { }
