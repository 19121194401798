import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ScreenshotComponent } from './screenshot/screenshot.component';
import { HarViewerComponent } from './har-viewer/har-viewer.component';
import { NodesComponent  } from './nodes/nodes.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { Nodes2Component } from './nodes2/nodes2.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule
  ],
  declarations: [
    ScreenshotComponent,
    HarViewerComponent,
    NodesComponent,
    Nodes2Component
  ],
  exports: [
    ScreenshotComponent,
    HarViewerComponent,
    NodesComponent,
    Nodes2Component
  ]
})
export class StandaloneComponentsModule { }
