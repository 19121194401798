import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ClipboardService } from 'ngx-clipboard';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { PageService } from '../../../services/page/page.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
declare var $:any;
import * as moment from 'moment';
import { DataValidationService } from '../../../services/data-validation/data-validation.service';
import { ValidationRule } from '../../../models/ValidationRule';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.css']
})
export class RuleComponent implements OnInit {

  rules:ValidationRule[];
  rulesLoading:boolean;
  tooltipContent:string;

  private rulesTable:any;
  private tableWidget:any;

  exportId:string;
  pollingTimer:any;
  notificationOptions:any;
  exportButtonDisabled:boolean;
  pollingTimerCounter:number;
  pollingTime:number;

  constructor(private el: ElementRef, 
    private pageService:PageService,
    private router: Router, 
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private customerService:CustomerService,
    private dataExportService:DataExportService,
    private notificationService: NotificationsService,
    private zone:NgZone,
    private authService:AuthService,
    private _clipboardService: ClipboardService,
    private dataValidationService:DataValidationService
    ) { 
      this.pollingTime = 1000;
      this.sitemapService.update(this.route);
      this.rulesLoading = false;
      this.tooltipContent = "Browse or search for rules";
      this.notificationOptions = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };
      this.exportButtonDisabled = false;
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  ngOnInit() {
    if (this.tableWidget) {
      this.tableWidget.destroy(); // essentially refreshes the table
      // you can also remove all rows and add new
      // this.tableWidget.clear().rows.add(this.shipments).draw();
    }

    let tableOptions: any = {
        stateSave: false,
        initComplete: function() {
          var api = this.api();
          var searchWait = 0;
          var searchWaitInterval;
          // Grab the datatables input box and alter how it is bound to events
          $(".dataTables_filter input")
          .unbind() // Unbind previous default bindings
          .bind("input", function(e) { // Bind our desired behavior
              var item = $(this);
              searchWait = 0;
              if(!searchWaitInterval) searchWaitInterval = setInterval(function(){
                  var searchTerm = $(item).val();
                  // if(searchTerm.length >= 3 || e.keyCode == 13) {
                      clearInterval(searchWaitInterval);
                      searchWaitInterval = '';
                      // Call the API search function
                      api.search(searchTerm).draw();
                      searchWait = 0;
                  // }
                  searchWait++;
              },1000);                       
              return;
          });

          $('#rulesTable thead th').each( function () {
            let index = $(this).index();
            var title = $('#rulesTable thead th').eq( index ).text();
            if ( title != 'Last Changed' && title != 'Last Checked' ){
              $(this).append( '<br/><div><input class="form-control form-control-xs" type="text" placeholder="Search '+title+'" /></div>' );
            } else {
              let dateInput = `
                <br/>
                <div class="input-group date datetimepicker" data-min-view="2" data-date-format="yyyy-mm-dd">
                  <input class="form-control form-control-xs" size="10" type="text" placeholder="Start Date"/>
                  <div class="input-group-append">
                    <button class="btn btn-primary"><i class="icon-th zmdi zmdi-calendar"></i></button>
                  </div>
                </div>
              `;
              $(this).append(dateInput);
              if ( index == 4 ){
                $(".datetimepicker").datetimepicker({
                  autoclose: true,
                  componentIcon: '.zmdi.zmdi-calendar',
                  navIcons:{
                    rightIcon: 'zmdi zmdi-chevron-right',
                    leftIcon: 'zmdi zmdi-chevron-left'
                  }
                }).on('changeDate', function(ev){                                               
                  $(ev.target).children("input").trigger({type:"keyup", keyCode:13});
                });
              }
            }            
          });
      
          this.api().columns().every( function () {
            var that = this;
            $( 'input', this.header() ).on( 'click', function (e) {
              e.stopPropagation();
            });
      
            $( 'input', this.header() ).on( 'keyup change clear', function (ev) {
              if (ev.keyCode == 13) {
                if ( that.search() !== this.value ) {                  
                  that.search( this.value ).draw();
                }
              }
            } );
          });
        },
        serverSide: true,
        ajax: (data, callback, settings)=>{
          console.log("Search:", data);

          var columnCriteria = data.columns.flatMap((col)=>{                        
            var searchVal = col.search.value;
            if ( searchVal != "" ) {
              if ( col.data == 'lastChecked' || col.data == 'lastChanged'){
                searchVal = Math.floor(new Date(searchVal).getTime()/1000);
              }
              return [ `${col.data}:${searchVal}`];
            } else {
              return [];
            }           
          });

          console.log("columnCriteria");
          console.log(columnCriteria);

          var searchTerm = columnCriteria.join("$"); // data.search.value is the val from the search text box

          this.rulesLoading = true;
          var orderCol = data.columns[data.order[0].column].data;

          this.dataValidationService.getRules().subscribe((datatableResponse)=>{
            this.rules = datatableResponse.data;
            
            
            this.zone.run(()=>{
              console.log("Refreshing code zone...");
              this.rulesLoading = false;
            });

            callback(datatableResponse);
          },
          (error)=>{
            console.log(error);
            this.zone.run(()=>{
              console.log("Refreshing code zone...");
              this.rulesLoading = false;
            });
          });                   
        },
        dom:
          "<'row be-datatable-header'<'col-sm-4'><'col-sm-8'>>" +
          "<'row be-datatable-body'<'col-sm-12'tr>>" +
          "<'row be-datatable-footer'<'col-sm-4'l<i>><'col-sm-8'p>>",       
        select: {
          style: 'os',
          items: 'cell',
          info: false
        },
        columns: [          
          { 
            title: 'Name', 
            data: 'name'
          },            
          {
            title: 'Description',
            data: 'description'
          },
          {
            title: "Test Suite",
            data: "testSuiteName"
          },
          {
            title: 'Author',
            data: 'author'
          },
          {
            title: 'Date Created',
            data: 'dateCreated'
          },
          {
            title: 'Enabled',
            data: 'enabled'
          },
          {
            title: 'Status',
            data: 'status'
          }
        ],
        "lengthMenu": [15, 25, 50, 75, 100],
        "defaultContent": '',
        "order":[
          [1, 'asc'],[0,'asc']
        ],
        "language": {
          "loadingRecords": "Please wait - loading...",
          "zeroRecords": "No rules were found"
        }
    };
    this.rulesTable = $(this.el.nativeElement.querySelector('table'));
    this.tableWidget = this.rulesTable.DataTable(tableOptions);


    this.tableWidget.on('user-select', (e, dt, type, cell, originalEvent) => {
      const col = cell.index().column, row = cell.index().row;
      // console.log(row, col);

      const shiftKeyPressed = originalEvent.originalEvent.shiftKey;
      // console.log(shiftKeyPressed);
      e.preventDefault();

      const rule = this.rules[row];

      if ( col == 0 ) {
        if ( !shiftKeyPressed ){
          // this.router.navigate(['vendors', codeFile.vendor, 'code', codeFile.id]);
        } else {
          // window.open(`vendors/${codeFile.vendor}/code/${codeFile.id}`);  
        }        
      } else if ( col == 1 ){
        if ( !shiftKeyPressed ){
          // this.router.navigate(['vendors', codeFile.vendor]);
        } else {
          // window.open(`vendors/${codeFile.vendor}`);  
        }
      }
    });
  }

}

