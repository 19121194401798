import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventRuleService } from '../eventRule/event-rule.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class Ej2EventRulesProxyService extends Subject<any> {

  constructor(private eventRuleService:EventRuleService, private spinnerService:SpinnerService) { 
    super();
  }

  public execute(state: any): void {    
    this.spinnerService.toggle(true);
    this.getData(state).pipe(tap(()=>{
      this.spinnerService.toggle(false);
    })).subscribe(x => super.next(x));
  }

  protected getData(state: DataStateChangeEventArgs): Observable<any> {
    var sorted = state.sorted ? state.sorted : [ {
      "name": "name",
      "direction": "ascending"
    }];
    let params = {start: (state.skip ? state.skip : 0), length: (state.take ? state.take : 20), sort: sorted, where: state.where};
    return this.eventRuleService.getEventRulesForUI(params);
  }

}
