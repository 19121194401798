<div class="row">
    <div class="col-lg-3">
        <div>
            Version: <ejs-dropdownlist width="100" [dataSource]="appVersions" [fields]="versionFields" [value]="currentVersion"></ejs-dropdownlist><br/><br/>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-2">
        <div class="card" style="height: 125px">
            <div class="card-header">
                SDKs Detected
            </div>
            <div class="card-body" style="text-align: center;">
                <span style="font-size: medium;">6</span>
            </div>
        </div>

    </div>
    <div class="col-lg-2">
        <div class="card" style="height: 125px">
            <div class="card-header">
                PII Detected
            </div>
            <div class="card-body" style="text-align: center;">
                <span style="font-size: medium;">Email</span><br/>
                <span style="font-size: medium;">Zip code</span>
            </div>
        </div>

    </div>
    <div class="col-lg-2">
        <div class="card" style="height: 125px">
            <div class="card-header">
                Total Requests
            </div>
            <div class="card-body" style="text-align: center;">
                <span style="font-size: medium;">102</span>
            </div>
        </div>

    </div>
    <div class="col-lg-2">
        <div class="card" style="height: 125px">
            <div class="card-header">
                Avg. Requests/Screen
            </div>
            <div class="card-body" style="text-align: center;">
                <span style="font-size: medium;">17</span>
            </div>
        </div>

    </div>
    <div class="col-lg-2">
        <div class="card" style="height: 125px">
            <div class="card-header">
                Screen with Most Requests
            </div>
            <div class="card-body" style="text-align: center;">
                <span style="font-size: medium;">CheckoutActivity</span>
            </div>
        </div>

    </div>
    <div class="col-lg-2">
        <div class="card" style="height: 125px">
            <div class="card-header">
                SDK with Most Requests
            </div>
            <div class="card-body" style="text-align: center;">
                <span style="font-size: medium;">facebook</span>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card card-table card-border be-loading" [class.be-loading-active]="dataLoading">
            <div class="card-header" style="padding:0px; margin:10px 0px">
                <div style="margin-top: 10px; margin-left: 10px;">
                    <button *ngIf="authService.isVaultUser()" type="button"
                        class="btn btn-rounded btn-space btn-success" data-toggle="modal"
                        data-target="#modal-run-now"><i style="color: white;"
                            class="icon icon-left zmdi zmdi-download"></i> Run Now</button>
                    <!-- <button type="button" class="btn btn-rounded btn-space btn-primary"
                        [disabled]="historyGrid.getSelectedRecords().length != 2" (click)="screenshotDiff();">Compare
                        Selected
                        Screenshots</button> -->
                </div>
            </div>
            <div class="card-body" style="padding:0px; margin:10px">

                <ejs-grid #historyGrid height="500px" locale='en-US' [allowPaging]="true" [pageSettings]="pageSettings"
                    [allowSorting]="false" [enableHover]="false" [allowResizing]="true">
                    <e-columns>
                        <e-column type='checkbox' width='30'>
                            <ng-template #headerTemplate let-data></ng-template>
                        </e-column>
                        <e-column field='lastCheckedFormatted' headerText='Last Checked'>
                            <ng-template #template let-data>
                                <a [routerLink]="[data.ts]">{{ data.lastCheckedFormatted }}</a>
                            </ng-template>
                        </e-column>
                        <e-column textAlign="left" headerText='Data' width="150">
                            <ng-template #template let-data>
                                <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                    <ejs-tooltip content="Request Graph">
                                        <ng-container>
                                            <div (click)="onHistoryItemClick($event,data)" class='cat-icons'>
                                                <img width="85%" src="assets/svg/request-graph.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                    <ejs-tooltip content="Screen Recording">
                                        <ng-container>
                                            <div (click)="onScreenshotClick(data)" class='cat-icons'>
                                                <img width="85%" src="assets/svg/sreenshot-desktop-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                    <ejs-tooltip content="HAR">
                                        <ng-container>
                                            <div (click)="onViewHarClick(data)" class='cat-icons'>
                                                <img width="85%" src="assets/svg/list-ul-alt-1-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                </div>
                            </ng-template>
                        </e-column>

                        <e-column textAlign="left" headerText='Analysis' width="110">
                            <ng-template #template let-data>
                                <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                    <ejs-tooltip content="Data Sharing Graph" style="margin-right: 3px;">
                                        <ng-container>
                                            <div (click)="onViewNodeGraphClick(data)" class='cat-icons'>
                                                <img width="100%" src="assets/svg/node-0-connections-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                    <ejs-tooltip content="Analysis">
                                        <ng-container>
                                            <div (click)="onViewAnalysisClick(data)" class='cat-icons'>
                                                <img width="100%" src="assets/svg/flow-tree-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                </div>
                            </ng-template>
                        </e-column>

                        <e-column *ngIf="authService.isVaultUser()" textAlign="left" headerText='Raw Data' width="130">
                            <ng-template #template let-data>
                                <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                    <ejs-tooltip content="Requests" style="margin-right: 3px;">
                                        <ng-container>
                                            <div (click)="saveRawFile('requests', data)" class='cat-icons'>
                                                <img width="100%" src="assets/svg/upload-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                    <ejs-tooltip content="Responses" style="margin-right: 3px;">
                                        <ng-container>
                                            <div (click)="saveRawFile('responses', data)" class='cat-icons'>
                                                <img width="100%" src="assets/svg/download-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                    <ejs-tooltip content="Config">
                                        <ng-container>
                                            <div (click)="saveRawFile('config', data)" class='cat-icons'>
                                                <img width="100%"
                                                    src="assets/svg/website-configuration-setting-svgrepo-com.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                </div>
                            </ng-template>
                        </e-column>


                        <e-column field='config' headerText='Consent Options'>
                            <ng-template #template let-data>
                                <div class="row" style="padding:0px; margin: 0px 4px;">
                                    <div class="col">
                                        <div class="row" style="font-size: small; text-align: left;"> {{
                                            consentString(data.config) }}</div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field='regionLabel' headerText='Region'></e-column>
                        <e-column *ngIf="authService.isVaultUser()" field='appRunnerType'
                            headerText='App Runner Job Type'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div class="be-spinner">
                <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle">
                    </circle>
                </svg>
            </div>
        </div>
    </div>
</div>


<div class="custom-width modal fade" id="modal-run-now" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span
                        class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <h2>Page Runner Region</h2>
                    <p>Select a region in which to run this page</p>
                    <form [formGroup]="runNowRegionPickerForm">
                        <div class="form-group row pt-1">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Region</label>
                            <div class="col-12 col-sm-8 col-lg-6">
                                <ejs-combobox id='eventTypesUI' #eventTypesUI [dataSource]='regions' [fields]='fields'
                                    formControlName="region" placeholder='Select a region' allowFiltering='true'
                                    allowCustom='false' filterType='contains' width="300px">
                                </ejs-combobox>
                            </div>
                        </div>
                        <div class="form-group row pt-1">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Type</label>
                            <div class="col-12 col-sm-8 col-lg-6">
                                <ejs-combobox id='pageRunnerTypesUI ' #pageRunnerTypesUI [dataSource]='pageRunnerTypes'
                                    [fields]="prTypeFields" formControlName="pageRunnerType"
                                    placeholder='Select a page runner type' allowFiltering='true' allowCustom='false'
                                    filterType='contains' width="300px">
                                </ejs-combobox>
                            </div>
                        </div>
                        <!-- <div class="form-group row pt-1">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Config</label>
                            <div class="col-12 col-sm-8 col-lg-6">
                                <ejs-combobox id='configsUI ' #configsUI [dataSource]='configs'
                                    [fields]="configTypeFields" formControlName="configId" placeholder='Select a config'
                                    allowFiltering='true' allowCustom='false' filterType='contains' width="300px">
                                </ejs-combobox>
                            </div>
                        </div> -->
                    </form>
                    <div class="mt-8">
                        <button class="btn btn-rounded btn-space btn-secondary" type="button"
                            data-dismiss="modal">Cancel</button>
                        <button class="btn btn-rounded btn-space btn-success" type="button"
                            (click)="runNow();">Submit</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>

<style>
    #control-container {
        padding: 0px !important;
    }

    #dclData {
        -webkit-animation: opac 1s ease-out infinite;
        animation: opac 1s ease-out infinite;
    }

    #sdData {
        -webkit-animation: opac 1s ease-out infinite;
        animation: opac 1s ease-in-out infinite;
    }

    @keyframes opac {
        0% {
            stroke-opacity: 1;
            stroke-width: 0px;
        }

        100% {
            stroke-opacity: 0;
            stroke-width: 10px;
        }
    }
</style>