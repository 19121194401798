import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { UserService } from '../../../services/users/user.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userTimezone:string;
  vautlId:number;
  userDetailsForm:FormGroup;
  notificationOptions:any;
  customerInfo:any;

  constructor(public authService:AuthService,
              private customerService:CustomerService,
              private fb:FormBuilder, 
              private spinnerService:SpinnerService, 
              private userService:UserService,
              private notificationService:NotificationsService,
              private timezoneService:TimezoneService
  ) {
    this.spinnerService.toggle(false);
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };

    this.userDetailsForm = this.fb.group({
      timezone: [''],
      password: [''],
      retypedPassword: [''],
      isUtcDefault: [''],
      uiDateFormat: ['']
    });
    this.customerInfo = JSON.parse(localStorage.getItem("customer"));
  }

  ngOnInit() {
    this.spinnerService.toggle(true);
    const userId = JSON.parse(localStorage.getItem("profile")).sub.toString();    
    this.userService.getUser(userId).subscribe((userInfo)=>{
      this.spinnerService.toggle(false);
      this.userTimezone = userInfo[0].timezone;
      this.vautlId = userInfo[0].vaultId;
      this.userDetailsForm.patchValue({timezone:this.userTimezone, isUtcDefault: this.customerInfo.isUtcDefaultForUI, uiDateFormat: this.customerInfo.uiDateTimeFormatString})
    },(error)=>{
      this.spinnerService.toggle(false);
      console.log(error);
    })
  }

  onSubmit() {
    console.log(this.userDetailsForm.value);

    if ( (this.userDetailsForm.value.password != '') && this.userDetailsForm.value.password != this.userDetailsForm.value.retypedPassword ){
      this.notificationService.error('Oops!', 'Passwords do not match!', this.notificationOptions);
      return;
    } 

    var updatedUserData:any = {};
      
    var formUserData = this.userDetailsForm.value;

    if ( formUserData.password != '' && formUserData.password == formUserData.retypedPassword ){
      updatedUserData.password = formUserData.password;
    }

    if ( formUserData.timezone != this.userTimezone ){
      updatedUserData.timezone = formUserData.timezone;
      updatedUserData.vaultId = this.vautlId;
    }

    if ( formUserData.isUtcDefault != this.customerInfo.isUtcDefaultForUI ){
      updatedUserData.isUtcDefaultForUI = formUserData.isUtcDefault;
    }

    if ( formUserData.uiDateFormat != this.customerInfo.uiDateTimeFormatString){
      updatedUserData.uiDateTimeFormatString = formUserData.uiDateFormat;
    }
    
    console.log(updatedUserData);

    if ( updatedUserData.password || updatedUserData.timezone || updatedUserData.isUtcDefaultForUI != null || updatedUserData.uiDateTimeFormatString != null ){
      this.spinnerService.toggle(true);
      this.userService.updateUser(updatedUserData).subscribe((updateResponse:any)=>{

        if (updateResponse && updateResponse.error){
          this.notificationService.error('Oops!', updateResponse.error, this.notificationOptions);
          this.spinnerService.toggle(false);
          return;          
        }
  
        this.spinnerService.toggle(false);
        this.userService.clearCache();
  
        this.notificationService.success('Success!', 'Your details were updated', this.notificationOptions);
  
        this.userDetailsForm = this.fb.group({
          password: [''],
          retypedPassword: ['']
        });
        
        var shouldUpdateCustomer = false;
        if ( updatedUserData.isUtcDefaultForUI != null){
          this.customerInfo.isUtcDefaultForUI = updatedUserData.isUtcDefaultForUI;
          this.timezoneService.updateDefaultSetting(this.customerInfo.isUtcDefault);
          shouldUpdateCustomer = true;
        }
        if ( updatedUserData.uiDateTimeFormatString != null ){
          this.customerInfo.uiDateTimeFormatString = updatedUserData.uiDateTimeFormatString;
          this.timezoneService.updateFormatSetting(this.customerInfo.uiDateTimeFormatString);
          shouldUpdateCustomer = true;
        }

        if (shouldUpdateCustomer == true){
          localStorage.setItem('customer', JSON.stringify(this.customerInfo) );
        }
      },
      (error)=>{
        this.spinnerService.toggle(false);
        console.log(error);
  
        this.notificationService.error('Uh oh!', error, this.notificationOptions);
      });
    } else {
      this.notificationService.warn('Hmmm', 'No values were changed. Please try again.', this.notificationOptions);       
    }
  }

}
