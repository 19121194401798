<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border be-loading" [class.be-loading-active]="rulesLoading">
      <div class="card-header">
          <!-- <div class="tools">
            <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exporData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button>
          </div> -->
        <span class="title">Rules</span>&nbsp;<span class="icon zmdi zmdi-info" title="Rules" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
        <span class="card-subtitle">{{tooltipContent}}</span>
      </div>
      <div class="card-body">
        <table id="rulesTable" class="table table-striped table-bordered"></table>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div>
    </div>
  </div>
</div>