<div class="report-container">
    <div class="row" style="margin-bottom: 25px;">
        <div class="col-lg-2" style="display: flex; flex-direction: row; justify-content: center;">
          <img src="assets/svg/vppa.svg" alt="Tracker Report Logo" height="150px">
        </div>
        <div class="col-lg-7">
          <div class="report-title" style="justify-content: center; margin-top: 20px">Video Privacy Protection Act (VPPA) Risk</div>
          <!-- <div class="report-config-name">
            {{selectedConfig.name}}
          </div> -->
        </div>

      </div>
    <div class="row">
        <div class="col-lg-4">
            <app-title-card height="300px">
                <ng-container header>
                    <span class=" zmdi zmdi-chart title-icon"></span>
                    Video Player Distribution
                </ng-container>
                <div class="flex-row">
                    <multi-pie-chart [datasource]="data" id="page-breakdown"
                        *ngIf="totalVideoPlayerPages > 0"></multi-pie-chart>
                </div>
            </app-title-card>
        </div>
        <div class="col-lg-4">
            <app-title-card height="300px">
                <ng-container header>
                    <span class=" zmdi zmdi-chart title-icon"></span>
                    Vendor Distribution
                </ng-container>
                <div id="vendor-buckets">
                    <div *ngFor="let tech of bucketDataByVendor" class="vendor-bucket-item">
                        <div class="bucket-label">{{tech.label}}</div>
                        <div class="bucket-container" [style.width]="100 + '%'">
                            <ejs-tooltip content="{{tech.total}} Pages in Total" mouseTrail="true"
                                [showTipPointer]="false" customClass="custom-tip" position="TopRight">
                                <div class="overlapping-bucket" [style.width]="100 + '%'"
                                    style="background-color: #e5ebe8;"></div>
                            </ejs-tooltip>
                            <ejs-tooltip content="{{tech.trackerCount}} Pages with Tracker" mouseTrail="true"
                                [showTipPointer]="false" customClass="custom-tip" position="TopRight">
                                <div class="overlapping-bucket" [style.width]="(tech.trackerCount/tech.total)*100 + '%'"
                                    style="background-color: #4d545c;"></div>
                            </ejs-tooltip>
                            <ejs-tooltip content="{{tech.videoCount}} Pages with Video Player" mouseTrail="true"
                                [showTipPointer]="false" customClass="custom-tip" position="TopRight">
                                <div class="overlapping-bucket" [style.width]="(tech.videoCount/tech.total)*100 + '%'"
                                    style="background-color: #6390ee;"></div>
                            </ejs-tooltip>
                        </div>
                    </div>
                    <div class="bucket-legends">
                        <div class="legend-marker" style="background-color: #e5ebe8;"></div><span>Total
                            Pages</span>
                        <div class="legend-marker" style="background-color: #4d545c;"></div><span>Pages
                            with
                            Tracker</span>
                        <div class="legend-marker" style="background-color: #6390ee;"></div><span>Pages
                            with Video Player</span>
                    </div>
                </div>
            </app-title-card>
        </div>
        <div class="col-lg-4">
            <app-title-card height="300px">
                <ng-container header>
                    <span class=" zmdi zmdi-chart title-icon"></span>
                    Scan Recency
                </ng-container>
                <div class="flex-column">
                    <multi-pie-chart [datasource]="scanRecency" id="scan-recency"></multi-pie-chart>
                    <div class="panel-header">
                        {{dataByPage.length}} pages scanned in total
                    </div>
                </div>
            </app-title-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <app-title-card height="800px">
                <ng-container header>
                    <span class=" zmdi zmdi-chart title-icon"></span>
                    Scanned Page List
                </ng-container>
                <ejs-grid [dataSource]="dataByPage" (rowSelected)="onSelectedPageChange($event)"
                    [pageSettings]="{ pageSize: 15}" allowResizing="true" [allowPaging]="true" height="100%"
                    allowSorting="true" width="100%"
                    [sortSettings]="{ columns: [{ field: 'playerCount', direction: 'Descending' }]}">
                    <e-columns>
                        <e-column field="page" headerText="Page URL" autoFit="true">
                            <ng-template #template let-data>
                                <div style="display: flex; flex-direction: row; gap: 5px; align-items: center;">
                                    <span class="zmdi zmdi-link goto-link"
                                        [routerLink]="['/pages', data.linkId]"></span>
                                    <span style="text-wrap:pretty;" class='page-link-text'>{{data.page}}</span>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column headerText="Video Players on Page" field="playerCount" width="70px">
                            <ng-template #template let-data>
                                <div style="display: flex; flex-direction: column; gap:2px; width: fit-content;">
                                    <div *ngFor="let player of data.videoPlayers" class="player-tag"
                                        style="background-color: #e5ebe8; color:black">
                                        <span
                                            class="zmdi zmdi-{{player.vendor !== 'Vimeo' && player.vendor !== 'YouTube' ? 'videocam' : player.vendor?.toLowerCase()}}"
                                            style="margin-right: 10px"
                                            [style.color]="player.vendor === 'Vimeo' ? '#1AB7EA' : player.vendor === 'YouTube' ? '#CD201F' : 'darkgrey'"></span>{{player.name}}
                                        <span
                                            style="margin-left: 10px; font-weight: bold;">{{player.urls.length}}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column headerText="Tracker on Page" field="trackerCount" width="120px">
                            <ng-template #template let-data>
                                <div style="display: flex; flex-direction: column; gap:2px; width: fit-content;">
                                    <div *ngFor="let tracker of data.riskyVendors" class="player-tag"
                                        style="background-color: #e5ebe8; color: black;">
                                        <span
                                            class="zmdi zmdi-{{tracker.vendor !== 'Google' && tracker.vendor !== 'Facebook' ? 'tag' :tracker.vendor?.toLowerCase()}}"
                                            [style.color]="tracker.vendor === 'Google' ? '#4285F4' : tracker.vendor === 'Facebook' ? '#1877F2' : 'darkgrey'"
                                            style="margin-right: 10px"></span>{{tracker.name}}
                                        <span
                                            style="margin-left: 10px; font-weight: bold;">{{tracker.urls.length}}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field="lastChecked" headerText="Last Checked" width="120px">
                            <ng-template #template let-data>
                                {{data.lastChecked | date: 'medium'}}
                            </ng-template>
                        </e-column>



                    </e-columns>
                </ejs-grid>
            </app-title-card>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-6">
            <app-title-card height="400px">
                <ng-container header>
                    <span class=" zmdi zmdi-chart title-icon"></span>
                    Tracker Details
                </ng-container>
                <div style="height: 100%; width: 100%; display: flex; flex-direction: column; gap:10px; overflow-y: scroll; scroll-behavior: smooth; scroll-snap-type:mandatory;" *ngIf="selectedPage else noInfo"
                    >
                    <div *ngFor="let url of selectedPage?.trackerData || []"
                        style="box-shadow: 0 0 3px #00000088; border-radius: .25rem; margin: 7px 10px; flex-shrink: 0; scroll-snap-align: start; height:fit-content">
                        <div style="padding: 5px 10px; background-color: #4d545c; font-size: large; color: white;">
                            {{url.urlTrimmed}}</div>
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px; padding: 10px;">
                            <div *ngFor="let param of url.params" class="param-container">
                                <div style="background-color: #4d545c; color:white; padding:5px 10px; ">
                                    {{param.key}}
                                </div>
                                <div style=" color:black; padding:5px 10px; background-color: #e5ebe8;"
                                    [style.color]="param.value !== '' ? 'black':'gray'">
                                    {{param.value !== '' ? param.value : '-'}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </app-title-card>
        </div>
        <div class="col-lg-6">
            <app-title-card height="400px">
                <ng-container header>
                    <span class=" zmdi zmdi-chart title-icon"></span>
                    Video Player Details
                </ng-container>
                <div style="height: 100%; width: 100%; display: flex; flex-direction: column; gap:10px; overflow-y: scroll;" *ngIf="selectedPage else noInfo">
                    <div *ngFor="let videoVendor of selectedPage?.videoPlayers || []"
                        style="box-shadow: 0 0 3px #00000088; border-radius: .25rem; margin: 7px 10px">

                        <div style="padding: 5px 10px; font-size: large;background-color: #4d545c; color: white;">
                            <span
                                class="zmdi zmdi-{{videoVendor.name !== 'Vimeo' && videoVendor.name !== 'YouTube' ? 'videocam' : videoVendor.name?.toLowerCase()}}"
                                style="margin-right: 10px; "></span><span style="color: white;">{{videoVendor.name}}
                                Players</span>
                        </div>
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px; padding: 10px;">
                            <div *ngFor="let url of videoVendor.urls" class="param-container">
                                <div style="background-color: #e5ebe8; color:black; padding:5px 10px;">
                                    {{url}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </app-title-card>
        </div>
    </div>
</div>
<ng-template #noInfo>
    <div class="no-info">Select Page to Load Details</div>
  </ng-template>