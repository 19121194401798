import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { Subscription } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.css']
})
export class AlertDetailsComponent implements OnInit, OnDestroy {
  alertId:any;
  alert:any;
  alertSub:Subscription
  isLoading:boolean;

  constructor(private route:ActivatedRoute, private sitemapService:SitemapService, private alertsService:AlertsService) { 
    sitemapService.update(route);
    this.isLoading = true;
  }

  ngOnInit() {
    this.alertId = this.route.snapshot.params["alertId"];    
    this.alertSub = this.alertsService.getAlertDetails(this.alertId).subscribe(alerts=>{      
      console.log("Alerts", alerts[0]);
      this.alert = alerts[0];

      if ( typeof(this.alert.details) === 'string' ){
        this.alert.details = JSON.parse(this.alert.details);
      }      
      this.isLoading = false;
    });    
  }

  ngOnDestroy(){
    this.alertSub.unsubscribe();
  }

  truncateFileName(fileName:string):string{
    var displayName:string = fileName;
    if ( displayName.length > 40 ){
      const lastSlashIndex = displayName.lastIndexOf("/");
      displayName = displayName.substr(lastSlashIndex);
      
      displayName = fileName.substr(0, 43-displayName.length) + ".." + displayName;            
    }
    if ( displayName.length > 40 ){
      displayName = displayName.substr(0, 40);
    }
    return displayName;
  }

  
}
