import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-group-summary-panel',
  templateUrl: './dynamic-group-summary-panel.component.html',
  styleUrls: ['./dynamic-group-summary-panel.component.css'],
})
export class DynamicGroupSummaryPanelComponent implements OnInit {
  public data: any = [];
  public columnList: string[] = [];
  public riskList: string[] = [];
  public tempData: any = [
    {
      id: '1',
      name: 'Sports',
      violationsByRisk: [
        {
          type: 'GDPR',
          percent: 0.52423232,
          data: 1,
        },
        {
          type: 'CCPA',
          percent: 0.32323235,
          data: 1,
        },
      ],
    },
  ];

  public sortSettings: { columns: { field: string; direction: string }[] };
  public pageOptions: {
    pageSize: number;
    pageCount: number;
    pageSizes: number[];
  };
  public filterSettings: Object;
  public editSettings: { allowEditing: boolean; mode: string };

  constructor() {
    this.sortSettings = {
      columns: [],
    };

    this.filterSettings = {
      type: 'Excel',
    };
    this.pageOptions = {
      pageSize: 25,
      pageCount: 5,
      pageSizes: [25, 50, 75, 100, 200],
    };
    this.editSettings = { allowEditing: false, mode: 'Normal' };
  }

  ngOnInit(): void {
    this.data = this.tempData.map((site) => {
      const row: any = {};
      row['id'] = site.id;
      row['name'] = site.name;
      row['violationsByRisk'] = site.violationsByRisk;
      site.violationsByRisk.forEach((risk) => {
        this.riskList.push(risk.type);
        row[risk.type] = risk.percent;
      });
      return row;
    });
  }
  
}
