<div class="left-sidebar-wrapper">
  <div class="left-sidebar-spacer">
    <div class="left-sidebar-scroll">
      <div class="left-sidebar-content">
        <ul class="sidebar-elements">
          <li class="divider"></li>
          <ng-container *ngFor="let item of menu; first as menuFirst">
            <ng-container *ngIf="!item.hide && item.children.length > 0">
              <li class="parent"><a href="#"><i class="icon zmdi {{item.icon}}"></i><span>{{item.menuText}}</span></a>
                <ul class="sub-menu">
                  
                  <ng-container *ngFor="let child of item.children">
                    <ng-container *ngIf="child.children.length == 0; else childrenTemplate">
                      <ng-container *ngIf="child.isCustomerDisabled; else enabledTemplate">
                        <li><a style="font-style: italic;" href="#" (click)="$event.preventDefault()"><span data-container="body" data-toggle="popover" data-trigger="hover" data-placement="right" data-content="Please contact support to enable this feature.">{{child.menuText}}</span></a></li>
                      </ng-container>
                      <ng-template #enabledTemplate>                        
                        <li *ngIf="!child.hide" [class.active]="sitemapService.activeMenuUrl == child.link"><a [routerLink]="child.link"><span>{{child.menuText}}</span></a></li>
                      </ng-template>
                    </ng-container>
                    
                    <ng-template #childrenTemplate>
                      <li class="parent"><a href="#"><span>{{child.menuText}}</span></a>
                        <ul class="sub-menu">
                          <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: child.children }"></ng-container>
                        </ul>
                      </li>
                    </ng-template>

                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container> 
        </ul>
      </div>
    </div>
  </div>      
</div>

<ng-template #recursiveListTmpl let-list="list">
  <ng-container *ngFor="let child of list">
    <li *ngIf="!child.hide" [class.active]="sitemapService.activeMenuUrl == child.link"><a [routerLink]="child.link"><span>{{child.menuText}}</span></a></li>
  </ng-container>
</ng-template>