import { Component, OnInit } from '@angular/core';
import { ChartTheme, ILoadedEventArgs, IMouseEventArgs, IPointRenderEventArgs, ISelectionCompleteEventArgs } from '@syncfusion/ej2-charts';
import {data} from './data';

@Component({
  selector: 'app-vendors3',
  templateUrl: './vendors3.component.html',
  styleUrls: ['./vendors3.component.css']
})
export class Vendors3Component implements OnInit {
  
  public selectedVendor:any;

  public zoomSettings: Object = {
    mode: 'XY',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableSelectionZooming: true,
    enableScrollbar: true
};

  constructor() {
    var minFc = 0, maxFc = 0;
    for(var i = 0; i < data.length; i++){
      let vendor = data[i];
      minFc = Math.min(minFc, vendor.fileCount);
      maxFc = Math.max(maxFc, vendor.fileCount)
    }
    console.log("minFc, maxFc");
    console.log(minFc, maxFc);
    this.selectedVendor = [];
  }
  

  selectionComplete(arg:ISelectionCompleteEventArgs){
    console.log('selectionComplete');
    console.log(arg);
    if ( arg.selectedDataValues.length == 0 ) {
      this.selectedVendor = [];
      return;
    }
    this.selectedVendor = [
      {
				id: 123123,
				name: "Google Analytics on page",
				description: "This tests if Google is on the page as it should be.",
				results: {
					passing: 999,
					failing: 1
				},
				passing: false,
			},{
				id: 123124,
				name: "Google account in account list",
				description: "This tests if blahblabllaa.",
				results: {
					passing: 999,
					failing: 0
				},
				passing: true				
			}
    ];

  }


  public primaryXAxis: Object;
  public chartData: Object[];
  public title: string;
  public primaryYAxis: Object;
  public series1: Object;
  public series2: Object;
  public marker: Object;
  public tooltip: Object;

  ngOnInit(): void {
      this.primaryXAxis = {
          title: 'Vendor',
          minimum: 0, maximum: 50,
          edgeLabelPlacement: 'Shift',
          labelFormat: '{value}'
      };
      this.primaryYAxis = {
          title: 'File Count',
          minimum: -100, maximum: 1200,
          labelFormat: '{value}',
          rangePadding: 'None'
      };
      this.title = 'Vendors';
      this.marker = {  
        width: 32, 
        height: 32,
        shape: 'Image',
        imageUrl: 'https://cdn.dev.vaultjs.com/vendors/icon/google.com.ico'
      };
      // let data = this.getScatterData();
      this.series1 = data;
      // this.series2 = data.series2;
      this.tooltip = {
        enable: true,
        format: 'Files: <b>${point.y}</b>'
    };
  }

  public pointRender(args: IPointRenderEventArgs): void {
    
    var website = data[args.point.xValue].website;

    if ( website != '' || !website){
      args.series.marker.imageUrl = `https://cdn.dev.vaultjs.com/vendors/icon/${website.toLowerCase()}.ico`;
    } else {
      args.series.marker.imageUrl = 'https://cdn.dev.vaultjs.com/vendors/icon/unknown.ico';
    }       
  }

  

public actionList: Object[] = [
  { Id: 'viewDetails', Name: 'View Details' },
  { Id: 'export', Name: 'Export' }  
];
// maps the appropriate column to fields property
public fields: Object = { text: 'Name', value: 'Id' };
// set the height of the popup element
public height: string = '220px';
// set the placeholder to DropDownList input element
public waterMark: string = 'Select an action';
// public value: string = 'viewDetails';


onChartMouseMove(arg:IMouseEventArgs){
  console.log(arg);
}

}