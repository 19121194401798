import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { ActivatedRoute } from '@angular/router';
import { IncidentService } from '../../../services/incident/incident.service';
import { Papa } from 'ngx-papaparse';

import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { last } from 'rxjs/operators';
declare var $: any;

const logTypes = require('../data/logTypes.json');

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css'],
})
export class IncidentsComponent implements OnInit, AfterViewInit {
  @ViewChild('chart') public chart: any;

  constructor(
    private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private spinnerService: SpinnerService,
    public timezoneService: TimezoneService,
    private papa: Papa,
    private incidentService: IncidentService,
    public authService: AuthService,
    private notificationService: VaultNotificationService,
    private formBuilder: FormBuilder
  ) {
    this.newIncidentForm = this.formBuilder.group({
      name: '',
      description: '',
    });
  }

  public getLogTypes(type) {
    if ([type]) return logTypes[type];
    else return logTypes['default'];
  }

  ngOnInit(): void {
    this.getIncidentList()
      .then(() => {
        this.incidentList.forEach((item) => {
          item.analysts =
            item.allAnalysts.length > 0 ? this.analystFilter(item) : [item.createdBy];

          item.description = item.description || 'No Description Available';
          // const nLog = item.logs.length;
          // item.lastestLog = this.getLogTypes(item.logs[nLog - 1].type);
        });
        this.getOpenHistory(this.incidentList).then((data) => {
          this.chartList = data;
        });
        this.openIncidentsAggregate = this.countOpenIncidents();
        this.pieChartList = this.getOpenRecency(this.incidentList);
      })
      .catch((err) => {
        console.error(err);
      });

    this.sortOptions = {
      columns: [
        { field: 'reviewRequested', direction: 'Descending' },
        { field: 'severity', direction: 'Descending' },
        { field: 'status', direction: 'Ascending' },
      ],
    };
  }
  ngAfterViewInit(): void {}
  public tempData: any = [];
  public incidentList: any = [];
  public chartList: any = [];
  public pieChartList: any = [];

  public markerSettings: any = {
    dataLabel: {
      visible: true,
      position: 'Top',
      font: {
        fontWeight: '600',
      },
    },
  };
  public openIncidentsAggregate: any = {};

  public countOpenIncidents(): any {
    const openIncidents = this.incidentList.filter((x) => x.status === 1);
    const yellowOpen = openIncidents.filter((x) => x.severity === 1).length;
    const redOpen = openIncidents.filter((x) => x.severity === 2).length;
    return { yellowCount: yellowOpen, redCount: redOpen };
  }
  private generateTypeData(incident: any): any {
    switch (incident.status) {
      case 1:
        if (incident.severity === 1) return 'Yellow Open';
        return 'Red Open';
      case 2:
        if (incident.severity === 1) return 'Yellow Closed';
        return 'Red Closed';
      default:
        return 'Unknown';
    }
  }

  public async getOpenHistory2(_data: any): Promise<any> {
    const incidentHistory = [];
    for (let i = 30; i >= 0; i--) {
      incidentHistory.push({
        date: Date.parse(
          new Date(
            new Date().getTime() - i * 24 * 60 * 60 * 1000
          ).toLocaleDateString()
        ),
        yellowCount: 0,
        redCount: 0,
      });
    }
    // const data = [
    //   {
    //     id: 1,
    //     severity: 1,
    //     createdTimestamp: '2023-11-15T00:00:00Z',
    //     logs: [
    //       // { type: 'resolve', epoch: '2023-11-16T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-17T00:00:00Z' },
    //       { type: 'resolve', epoch: '2023-11-18T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-19T00:00:00Z' },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     severity: 2,
    //     createdTimestamp: '2023-11-20T00:00:00Z',
    //     logs: [
    //       // { type: 'resolve', epoch: '2023-11-21T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-22T00:00:00Z' },
    //       { type: 'resolve', epoch: '2023-11-23T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-24T00:00:00Z' },
    //       // { type: 'resolve', epoch: '2023-11-25T00:00:00Z' },
    //       { type: 'reopen', epoch: '2023-11-26T00:00:00Z' },
    //       { type: 'resolve', epoch: '2023-12-16T00:00:00Z' },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     severity: 2,
    //     createdTimestamp: '2023-11-27T00:00:00Z',
    //     logs: [
    //       { type: 'resolve', epoch: '2023-11-28T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-29T00:00:00Z' },
    //       // { type: 'resolve', epoch: '2023-11-30T00:00:00Z' },
    //       { type: 'reopen', epoch: '2023-12-01T00:00:00Z' },
    //       { type: 'resolve', epoch: '2023-12-10T00:00:00Z' },
    //     ],
    //   },
    //   {
    //     id: 4,
    //     severity: 1,
    //     createdTimestamp: '2023-11-15T00:00:00Z',
    //     logs: [
    //       { type: 'resolve', epoch: '2023-11-30T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-17T00:00:00Z' },
    //     ],
    //   },
    //   {
    //     id: 5,
    //     severity: 1,
    //     createdTimestamp: '2023-11-15T00:00:00Z',
    //     logs: [
    //       // { type: 'resolve', epoch: '2023-11-21T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-22T00:00:00Z' },
    //       // { type: 'resolve', epoch: '2023-11-23T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-24T00:00:00Z' },
    //       { type: 'resolve', epoch: '2023-11-25T00:00:00Z' },
    //       // { type: 'reopen', epoch: '2023-11-26T00:00:00Z' },
    //     ],
    //   },
    //   // Add more incidents as needed
    // ];
    let data = [];
    await Promise.all(
      _data.map(async (incident) => {
        await this.incidentService
          .getIncidentV2(incident.id)
          .toPromise()
          .then((res) => {
            incident.logs = res[0].logs;
            data.push(incident);
          });
      })
    );
    //console.log(data);
    const incidentStatus = data.reduce((acc, item) => {
      acc[item.id] = { isOpen: true, severity: item.severity }; // Assume each incident is open initially
      return acc;
    }, {});

    // Iterate over each day in the incident history
    for (let i = 0; i < incidentHistory.length; i++) {
      // Iterate over each incident
      for (const item of data) {
        // Iterate over each log entry for the incident
        for (const log of item.logs) {
          const logDate = new Date(log.epoch).setHours(0, 0, 0, 0);
          // If the log entry is for a date after the current day, break the loop
          if (logDate > incidentHistory[i].date) {
            break;
          }
          // Update the status of the incident based on the log entry
          incidentStatus[item.id].isOpen = log.type === 'reopen';
        }
        // If the incident is open, increment the appropriate count
        if (incidentStatus[item.id].isOpen) {
          if (incidentStatus[item.id].severity === 1) {
            incidentHistory[i].yellowCount++;
          } else if (incidentStatus[item.id].severity === 2) {
            incidentHistory[i].redCount++;
          }
        }
      }
    }
    incidentHistory[incidentHistory.length - 1].yellowCount =
      this.openIncidentsAggregate.yellowCount;
    incidentHistory[incidentHistory.length - 1].redCount =
      this.openIncidentsAggregate.redCount;
    //console.log(incidentHistory);
    return incidentHistory;
  }

  public async getOpenHistory(_data: any): Promise<any> {
    let data = [];
    await Promise.all(
      _data.map(async (incident) => {
        await this.incidentService
          .getIncidentV2(incident.id)
          .toPromise()
          .then((res) => {
            incident.logs = res[0].logs.map((log) => {
              return {
                type: log.type,
                epoch: new Date(log.epoch).getTime(),
              };
            });
            data.push(incident);
          });
      })
    );
    const getMidnight = (date: Date) => {
      date.setHours(0, 0, 0, 0);
      return date.getTime();
    };
    const getLastLogOfDays = (logs) => {
      //
      const output = [];
      let lastLog = null;
      for (let i = logs.length - 1; i >= 0; i--) {
        const log = logs[i];
        const mdn = getMidnight(new Date(log.epoch));
        if (mdn !== lastLog) {
          output.unshift({
            date: mdn,
            type: log.type,
          });
        }
        lastLog = mdn;
      }
      return output;
    };

    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const currentDate = new Date();
    const startDate = new Date(currentDate.getTime() - 30 * MS_PER_DAY);
    const endDate = currentDate;
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / MS_PER_DAY);
    //console.log(diffDays);
    const incidentHistory = Array.from({ length: diffDays }, (_, i) => {
      return {
        date: getMidnight(new Date(startDate.getTime() + i * MS_PER_DAY)),
        yellowCount: 0,
        redCount: 0,
      };
    });

    data.forEach((incident) => {
      const _logs = incident.logs.filter((log) => {
        return log.type === 'reopen' || log.type === 'resolve';
      });
      const logs = getLastLogOfDays([
        {
          epoch: new Date(incident.createdTimestamp).getTime(),
          type: 'create',
        },
        ..._logs,
        {
          epoch: currentDate.getTime(),
          type: 'today',
        },
      ]);
      //console.log(`${incident.id}: `, logs);
      if (logs.length === 2) {
        // for all incidentHistory + 1
        for (let i = 0; i < incidentHistory.length; i++) {
          if (incident.severity === 1) {
            incidentHistory[i].yellowCount++;
          } else if (incident.severity === 2) {
            incidentHistory[i].redCount++;
          }
        }
      } else {
        let startStatus = 'open';
        for (let idx = 0; idx < logs.length - 1; idx++) {
          const log = logs[idx];
          const nextLog = logs[idx + 1];
          if (log.type === 'reopen') {
            startStatus = 'open';
            //console.log(`${incident.id}: ${new Date(log.date).toDateString()} was REOPEN`);
          } else if (log.type === 'resolve') {
            startStatus = 'close';
            //console.log(`${incident.id}: ${new Date(log.date).toDateString()} was RESOLVE`);
          } else if (log.type === 'create') {
            startStatus = 'open';
            //console.log(`${incident.id}: ${new Date(log.date).toDateString()} was CREATE`);
          }
          if (nextLog.date >= startDate.getTime()) {
            //console.log(log.date, startDate.getTime());
            const _thisDate = new Date(log.date);
            const thisDate =
              _thisDate.getTime() < startDate.getTime() ? startDate : _thisDate;
            const nextDate = new Date(nextLog.date);
            const diffDays = Math.ceil(
              Math.abs(nextDate.getTime() - thisDate.getTime()) / MS_PER_DAY
            );
            for (let i = 0; i < diffDays; i++) {
              // if(thisDate.getTime() < startDate.getTime()) continue;
              const thisDay = new Date(thisDate.getTime() + i * MS_PER_DAY);
              const thisDayIdx = incidentHistory.findIndex(
                (x) => x.date === getMidnight(thisDay)
              );
              //console.log(`${incident.id}: ${thisDay.toDateString()} was ${startStatus}`);
              if (startStatus === 'open' && incidentHistory[thisDayIdx]) {
                if (incident.severity === 1) {
                  incidentHistory[thisDayIdx].yellowCount++;
                } else if (incident.severity === 2) {
                  incidentHistory[thisDayIdx].redCount++;
                }
              }
            }
          }
        }
      }
    });
    //replace last day with current open incidents
    incidentHistory[incidentHistory.length - 1].yellowCount =
      this.openIncidentsAggregate.yellowCount;
    incidentHistory[incidentHistory.length - 1].redCount =
      this.openIncidentsAggregate.redCount;
    //console.log(incidentHistory);
    return incidentHistory;
  }

  public getOpenRecency(data: any): any {
    const now = new Date().getTime();
    const time7DaysAgo = now - 7 * 24 * 60 * 60 * 1000;
    const time30DaysAgo = now - 30 * 24 * 60 * 60 * 1000;

    const initialData = {
      last7Days: { total: 0, red: 0, yellow: 0 },
      last30Days: { total: 0, red: 0, yellow: 0 },
      over30Days: { total: 0, red: 0, yellow: 0 },
    };

    const result = data.reduce((acc, curr) => {
      const timeDiff = new Date(curr.createdTimestamp).getTime();
      //console.log(timeDiff, time7DaysAgo, time30DaysAgo);
      if (curr.status === 2) return acc;
      if (timeDiff <= time30DaysAgo) {
        acc.over30Days.total++;
        if (curr.severity === 2) acc.over30Days.red++;
        if (curr.severity === 1) acc.over30Days.yellow++;
      } else if (timeDiff <= time7DaysAgo) {
        acc.last30Days.total++;
        if (curr.severity === 2) acc.last30Days.red++;
        if (curr.severity === 1) acc.last30Days.yellow++;
      } else {
        acc.last7Days.total++;
        if (curr.severity === 2) acc.last7Days.red++;
        if (curr.severity === 1) acc.last7Days.yellow++;
      }

      return acc;
    }, initialData);

    const output = [
      createOutput('Last 7 Days', '#6390ee', result.last7Days),
      createOutput('Last 30 Days', '#00dbff', result.last30Days),
      createOutput('Over 30 Days', '#4d545c', result.over30Days),
    ];

    //console.log(output);
    return output;

    function createOutput(label, color, data) {
      return {
        label,
        value: data.total,
        color,
        children: [],
      };
    }
  }
  public groupByType(data: any): any {
    // group only by type and count
    const grouped = data.reduce((acc, curr) => {
      curr.type = this.generateTypeData(curr);
      if (!acc[curr.type]) {
        acc[curr.type] = { type: curr.type, count: 0 };
      }
      acc[curr.type].count++;
      return acc;
    }, {});
    // convert object to array
    const result = Object.values(grouped);
    //console.log(result);
    return result;
  }

  public groupByDate(data: any): any {
    // filter only in progress
    const inProgress = data;
    // count by date and create list with keys (date, redCount, yellowCount)
    const template = {
      date: '',
      yellowCount: 0,
      redCount: 0,
    };
    const grouped = inProgress.reduce((acc, curr) => {
      const date = curr.formattedTimestamp.split(' ')[0];
      //console.log(date);
      if (!acc[date]) {
        acc[date] = { ...template };
        acc[date].date = date;
      }
      if (curr.severity === 2) {
        acc[date].redCount++;
      } else {
        acc[date].yellowCount++;
      }
      return acc;
    }, {});
    // convert object to array
    const result = Object.values(grouped);
    //console.log(result);
    return result;
  }

  public primaryXAxis: any = {
    valueType: 'DateTimeCategory',
    labelFormat: 'dd MMM',
    labelIntersectAction: 'Hide',
    // edgeLabelPlacement: 'Hide',
    majorGridLines: {
      width: 0,
    },
    minorGridLines: {
      width: 0,
    },
    labels: {
      visible: false,
    },
  };
  public paletteColors: any = ['red', 'orange'];
  public marker: any = {
    visible: false,
    height: 5,
    width: 5,
  };
  public sortOptions = {};

  public filterSettings = { type: 'Excel' };

  public async getIncidentList(): Promise<void> {
    this.spinnerService.toggle(true);
    this.incidentList = await this.incidentService.getIncidentsV2().toPromise();
    this.incidentList = this.incidentList.map((i) => {
      return {
        ...i,
        formattedLastUpdate: this.timezoneService.format(i.lastUpdate),
        formattedTimestamp: this.timezoneService.format(
          i.createdTimestamp,
          false,
          true
        ),
        statusFilter: IncidentService.SEVERITY[i.severity],
      };
    });
    //console.log(this.incidentList);
    this.spinnerService.toggle();
  }
  public newIncidentForm: FormGroup;

  createIncident() {
    $('#new-incident-modal').modal('hide');
    //console.log(this.newIncidentForm.value);
    let newIncident = {
      name: this.newIncidentForm.value.name,
      description: this.newIncidentForm.value.description,
      nickname: this.authService.getUserProfile().nickname,
      status: 1,
      severity: 1,
      requestData: [],
    };

    //console.log(newIncident);

    this.spinnerService.toggle(true);

    this.incidentService.createIncidentV2(newIncident).subscribe((response) => {
      //console.log(response);
      this.notificationService.success(
        'Success',
        'Incident created successfully'
      );
      this.spinnerService.toggle();
    });
  }

  public showNewIncidentModal() {
    $('#new-incident-modal').modal('show');
  }

  public analystFilter(incident) {
    return [
      incident.createdBy,
      ...incident.allAnalysts.filter((el) => {
        return el !== incident.createdBy;
      }),
    ];
  }
}
