import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();  
  window.console.log = () => { }
  /*
    To restore:
    
    delete window.console  // Not sure if this is necessary

    var i = document.createElement('iframe');
    i.style.display = 'none';
    document.body.appendChild(i);
    window.console = i.contentWindow.console;

    https://stackoverflow.com/questions/7089443/restoring-console-log
  */
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  