<div class="card">
    <div>
        <ejs-grid [dataSource]='data' [height]='height' [enableVirtualization]=true [pageSettings]='options'
            [editSettings]='editSettings'>
            <e-columns>
                <e-column [headerText]="title ? title : ''">
                    <ng-template #template let-data>
                        <div>
                            {{data.attribute}}
                        </div>
                    </ng-template>
                </e-column>
                <e-column>
                    <ng-template #template let-data>
                        <div class="container">
                            <div class='bar-container'>
                                <div class="progress-bar"
                                    [ngStyle]="{'width': data.percent + '%', 'background-color': data.color}">
                                    <div *ngIf="data.percent > 25">
                                        <div class="percent" style="padding: 1px; color: white;">{{data.percent}}%</div>
                                    </div>
                                </div>
                                <div *ngIf="data.percent <= 25">
                                    <div style="padding: 1px;">{{data.percent}}%</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>