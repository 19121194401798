import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { environment as env } from "../../../../environments/environment";

declare var $: any;
import { PageService } from '../../../services/page/page.service';
import { forkJoin, of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ClipboardService } from 'ngx-clipboard';
import { catchError } from 'rxjs/operators';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { GridComponent, PageSettingsModel, SelectionSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TimezoneService } from '../../../services/timezone.service';
import { MobileService } from '../../../services/mobile/mobile.service';


@Component({
  selector: 'app-app-history-detail',
  templateUrl: './app-history-detail.component.html',
  styleUrls: ['./app-history-detail.component.css']
})
export class AppHistoryDetailComponent implements OnInit {

  vizLoading: boolean;
  dataLoading: boolean;
  selectedRows: any[];
  pageHistory: any;
  zoomFactor: number;
  tableData: any[];
  vendorsData: any[];
  notificationOptions: any;
  nodes: any;
  vendors: any[];
  force: any;
  svg: any;
  allVendors = [];
  timeline: any;
  screenShotEvents: any;
  requestBlocks: any;
  curScreenIndex;
  selectedImageIndex;

  vendorDict = {
    // 'fidelity.com': {
    //   "id": 7473,
    //   "vendorName": "Fidelity",
    //   "website": "fidelity.com",
    //   "fileCount": 3,
    //   "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
    //   "uniqueResourceIds": 1,
    //   "uniqueDomainIds": 1
    // },
    'qualtrics.com': {
      "id": 7473,
      "vendorName": "Qualtrics",
      "website": "qualtrics.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    'assets.adobedtm.com': {
      "id": 7473,
      "vendorName": "Adobe Experience Cloud",
      "website": "adobe.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    'dpm.demdex.net': {
      "id": 7473,
      "vendorName": "Adobe Demdex",
      "website": "adobe.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    // 'launchdarkly.com': {
    //   "id": 7473,
    //   "vendorName": "Launch Darkly",
    //   "website": "launchdarkly.com",
    //   "fileCount": 3,
    //   "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
    //   "uniqueResourceIds": 1,
    //   "uniqueDomainIds": 1
    // },
    'crashlyticsreports-pa.googleapis.com': {
      "id": 7473,
      "vendorName": "Crashlytics",
      "website": "crashlytics.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    // 'api2.branch.io': {
    //   "id": 7473,
    //   "vendorName": "Branch",
    //   "website": "branch.io",
    //   "fileCount": 3,
    //   "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
    //   "uniqueResourceIds": 1,
    //   "uniqueDomainIds": 1
    // },
    // 'h64.online-metrix.net': {
    //   "id": 7473,
    //   "vendorName": "ThreatMetrix",
    //   "website": "lexisnexis.com",
    //   "fileCount": 3,
    //   "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
    //   "uniqueResourceIds": 1,
    //   "uniqueDomainIds": 1
    // },
    'firebaselogging-pa.googleapis.com': {
      "id": 7473,
      "vendorName": "Firebase",
      "website": "crashlytics.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    'quantummetric.com': {
      "id": 7473,
      "vendorName": "Quantum Metric",
      "website": "quantummetric.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    'delta.com': {
      "id": 7473,
      "vendorName": "Delta",
      "website": "delta.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    },
    'dynatrace.com': {
      "id": 7473,
      "vendorName": "Dynatrace",
      "website": "www.dynatrace.com",
      "fileCount": 3,
      "mostRecentUpdate": "2023-08-29T01:37:44.000Z",
      "uniqueResourceIds": 1,
      "uniqueDomainIds": 1
    }
  }

  public pageSettings: PageSettingsModel;
  public sortSettings: SortSettingsModel;
  public sortSettingsVendors: SortSettingsModel;
  public selectionSettingsVendors: SelectionSettingsModel;
  public toolbar: string[];
  public dateFormat: any;
  @ViewChild('fileGrid') public grid: GridComponent;
  @ViewChild('vendorsGrid') public vendorsGrid: GridComponent;
  public cdnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private vendorService: VendorsService,
    private zone: NgZone,
    private pageService: PageService,
    private el: ElementRef,
    private notificationService: NotificationsService,
    private clipboardService: ClipboardService,
    private dataExportService: DataExportService,
    public mobileService: MobileService,
    private timeZoneService: TimezoneService
  ) {
    this.sitemapService.update(route);
    // this.sitemapService.
    this.vizLoading = true;
    this.dataLoading = true;
    this.selectedRows = [];
    this.zoomFactor = 1;
    this.tableData = [];
    this.notificationOptions = {
      timeOut: 2000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    };

    this.cdnUrl = env.cdn.url;
    this.dateFormat = { type: 'dateTime', skeleton: 'short' }
    this.pageSettings = {
      currentPage: 1,
      pageSize: 20,
      pageCount: 4,
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'vendorName', direction: 'Ascending' },
        { field: 'url', direction: 'Ascending' }
      ]
    };
    this.sortSettingsVendors = {
      columns: [
        { field: 'vendorName', direction: 'Ascending' }
      ]
    };
    this.selectionSettingsVendors = {
      type: 'Multiple',
      mode: 'Both'
    };
  }

  async ngOnInit() {
    this.dataLoading = this.vizLoading = false;
    let ts = this.route.snapshot.params.jobid;
    this.mobileService.getTimeline(ts).subscribe((timeline: any) => {
      console.log(timeline);
      this.timeline = timeline;
      this.timeline.timeline = timeline.timeline.map((r) => {
        let vendorName = "Unknown",
          vendorWebsite = 'unknown',
          formattedTS = this.timeZoneService.format(r.ts, false, true, false, "D h:mm:ss:SSS a ZZZZ");

        if (!r.url) {
          return { ...r, formattedTS }

        }

        let hostname = new URL(r.url).hostname;
        if (hostname.endsWith('fidelity.com')) {
          hostname = 'fidelity.com';
        } else if (hostname.endsWith('launchdarkly')) {
          hostname = 'launchdarkly.com';
        } else if (hostname.endsWith('delta.com')) {
          hostname = 'delta.com'
        } else if (hostname.endsWith('quantummetric.com')) {
          hostname = 'quantummetric.com';
        } else if ( hostname.endsWith('qualtrics.com')){
          hostname = 'qualtrics.com';
        } else if ( hostname.endsWith('dynatrace-managed.com')){
          hostname = 'dynatrace.com';
        }
        let foundVendor = this.vendorDict[hostname];
        if (foundVendor) {
          vendorName = foundVendor.vendorName;
          vendorWebsite = foundVendor.website;
        }

        let returnObj = { ...r, vendorName, vendorWebsite, formattedTS };
        returnObj["requestData"] = this.mobileService.getAppRunRequest(r.requestId);

        return returnObj;
      });

      this.screenShotEvents = timeline.timeline.map((e, index) => {
        return { ...e, index }
      }).filter((e) => { return e.event == 'screenshot' });

      console.log(this.screenShotEvents);

      this.requestBlocks = [
        timeline.timeline.slice(0, this.screenShotEvents[0].index).filter((e) => e.event == 'Mobile Request')
      ];
      console.log(this.requestBlocks);
      for (var i = 0; i < this.screenShotEvents.length - 1; i++) {
        // console.log(i);
        let c = timeline.timeline.slice(this.screenShotEvents[i].index, this.screenShotEvents[i + 1].index);
        console.log(c.length);
        c = c.filter((e) => { return (e.event == 'Mobile Request') })
        this.requestBlocks.push(c);
      }
      console.log(this.requestBlocks);

      this.grid.dataSource = this.requestBlocks.flat();
      console.log(this.grid.dataSource);

      this.vendors = Object.values(this.vendorDict);

    });
  }

  renderGraph(data) {
  }

  truncateFileName(fileName) {
    const LEN = 150;
    var displayName: string = fileName;
    if (displayName.length > LEN) {
      const lastSlashIndex = displayName.lastIndexOf("/");
      displayName = displayName.substr(lastSlashIndex);

      displayName = fileName.substr(0, LEN - displayName.length) + "..." + displayName;

      if (displayName.length > LEN) {
        displayName = displayName.substr(0, LEN) + "...";
      }

    }
    return displayName;
  }

  getResource(fullUrl) {
    var displayName: string = fullUrl;

    const lastSlashIndex = displayName.lastIndexOf("/");
    displayName = displayName.substr(lastSlashIndex);

    return displayName.substr(0, 25);
  }

  onVendorCheck() {
    // console.log("Vendor " + vendorId);

    var selectedVendors = this.vendorsGrid.getSelectedRecords();

    console.log(selectedVendors);

    this.nodes.nodes.forEach((n) => {
      if (selectedVendors.filter((v) => { return v['vendorId'] == n.vendorId }).length == 1) {
        const lastSlashIndex = n.file.lastIndexOf("/");
        n.displayName = n.file.substr(lastSlashIndex).substr(0, 25);
      } else {
        n.displayName = '';
      }
    });

    this.tableData = this.nodes.nodes.filter((n) => {
      if (selectedVendors.length == 0) { return true; }
      if (selectedVendors.filter((v) => { return v['vendorId'] == n.vendorId }).length == 1) {
        return true;
      }
      return false;
    });

    this.renderGraph(this.nodes);
    this.grid.dataSource = this.tableData;
  }

  addToClipboard(value) {
    this.clipboardService.copyFromContent(value);
    this.notificationService.success('Copied', 'Full URL was copied to clipboard.', this.notificationOptions);
  }

  fullScreen() {
    var h = $(".graphDiv").height();
    console.log(h);
    h = h < 1000 ? 1000 : 500;
    var vizH = h < 1000 ? 375 : 900;

    $(".graphDiv").height(h);
    $(".graphCard").height(h);

    this.renderGraph(this.nodes);
  }

  onGridCreate() {
    this.grid.showSpinner = () => true;
  }

  onVendorSelected(event) {
    this.onVendorCheck();
  }

  onVendorDeselected(event) {
    this.onVendorCheck();
  }

  loadRequests(event, index) {
    event.preventDefault();
    console.log("Load requests: " + index);
    if (this.curScreenIndex == index) {
      console.log('reset');
      this.grid.dataSource = this.requestBlocks.flat();
      this.curScreenIndex = null;
    } else {
      console.log('CHanging');
      console.log(this.requestBlocks[index]);
      this.grid.dataSource = this.requestBlocks[index];
      this.curScreenIndex = index;
    }
    this.grid.refresh();
  }

  txHeaders(headers) {
    console.log(headers);
    return Object.keys(headers);
  }

  vendorForRequest(url) {
    let hostname = new URL(url).hostname;
    if (hostname.endsWith('fidelity.com') == true) {
      return this.vendorDict["fidelity.com"]
    }
    return this.vendorDict[hostname];
  }

}
