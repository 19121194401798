import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Role } from '../../../../models/Role';
import { InstrumentationService } from '../../../../services/instrumentation/instrumentation.service';
import { SitemapService } from '../../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { UserService } from '../../../../services/users/user.service';
declare var $:any;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  
  userDetailsForm:FormGroup;
  existingUserForm:FormGroup;
  roles:Role[];
  user:any;
  btnText:string;
  closeResult: string;
  notificationOptions:any;
  isExisting:boolean;

  constructor(private route: ActivatedRoute, 
              private fb:FormBuilder, 
              private spinnerService:SpinnerService, 
              private userService:UserService,
              private router:Router,
              private notificationService:NotificationsService,
              private modalService: NgbModal,
              private sitemapService:SitemapService,
              private instrumentationService:InstrumentationService
   ) {
    this.sitemapService.update(route);
    this.isExisting = false;
    this.spinnerService.toggle(false);
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
  }

  get firstName() { return this.userDetailsForm.get('firstName'); }
  get lastName() { return this.userDetailsForm.get('lastName'); }
  get email() { return this.userDetailsForm.get('email'); }


  ngOnInit() {

    console.log("Resolved user:", this.route.snapshot.data.user[0]);
    this.user = this.route.snapshot.data.user[0];

    if ( this.user.id == "existing" ){
      this.isExisting = true;
      this.existingUserForm = this.fb.group({
        userIds: ['']
      });

      return;
    }


    this.spinnerService.toggle(true);
    this.userService.getRoles().subscribe((roles)=>{
      this.spinnerService.toggle(false);
      this.roles = roles;
      if ( JSON.parse(localStorage.profile).email.indexOf("@vaultjs.com") == -1 ){
        this.roles = roles.filter((role)=>{
          if ( role.name == "Analyst" || role.name == "Developer" ){
            return false;
          }
          return true;
        });
      }      
    });
    
    

    var firstName = this.user.user_metadata ? this.user.user_metadata.firstName : this.user.firstName;
    var lastName = this.user.user_metadata ? this.user.user_metadata.lastName : this.user.lastName;
    
    if ( this.user.id == "new" ){
      this.btnText = "Create";
    } else {
      this.btnText = "Update";
    }

    this.userDetailsForm = this.fb.group({
      firstName: [firstName],
      lastName: [lastName],
      password: [''],
      retypedPassword: [''],
      email: [this.user.email],
      roles: [this.user.roles.map((role)=>{return role.id})]
    });
  }

  resetForm(){
    this.userDetailsForm.markAsPristine();
    this.userDetailsForm.markAsUntouched();
    this.userDetailsForm.reset();
  }

  onSubmit() {
    console.log(this.userDetailsForm.value);
    console.log(this.user);

    if ( (this.user.id == 'new' || this.userDetailsForm.value.password != '') && this.userDetailsForm.value.password != this.userDetailsForm.value.retypedPassword ){
      this.notificationService.error('Oops!', 'Passwords do not match!', this.notificationOptions);
      return;
    } 

    this.spinnerService.toggle(true);

    if ( this.user.id == "new"){
      var newUser = this.userDetailsForm.value;
      newUser.password = '';    
      
      this.instrumentationService.sendEvent({type: 'User Detail', page: `Create New User: ${newUser.email}` });
      
      this.userService.createUser(newUser).subscribe((createResponse:any)=>{

        console.log("createResponse", createResponse);

        if (createResponse && createResponse.error){
          this.notificationService.error('Oops!', createResponse.error, this.notificationOptions);
          this.spinnerService.toggle(false);
          return;          
        }

        this.resetForm();

        this.spinnerService.toggle(false);
        this.userService.clearCache();

        this.notificationService.success('Success!', 'User was created!', this.notificationOptions);
      },
      (error)=>{
        this.spinnerService.toggle(false);
        console.log(error);
      });
    } else {
      // Update existing user...
      
      var updatedUserData:any = {userId: this.user.user_id};
      
      var formUserData = this.userDetailsForm.value;
      
      let updatedProps = [];
      if ( this.user.user_metadata.firstName != formUserData.firstName ) {
        updatedUserData.firstName = formUserData.firstName;
        updatedProps.push('First Name');
      }
      
      if ( this.user.user_metadata.lastName != formUserData.lastName ) {
        updatedUserData.lastName = formUserData.lastName;        
        updatedProps.push('Last Name');
      }
      
      if ( this.user.email != formUserData.email ) {
        updatedUserData.email = formUserData.email;
        updatedProps.push('Email');
      }

      if ( this.user.roles && this.user.roles.length > 0  ){
        updatedUserData.oldRoles = this.user.roles.map((role)=>{
          return role.id;
        });
        updatedProps.push('Current Roles');
      }

      if ( formUserData.roles && formUserData.roles.length > 0 ){ 
        updatedUserData.newRoles = formUserData.roles;
        updatedProps.push('New Roles');
      }

      if ( formUserData.password != '' && formUserData.password == formUserData.retypedPassword ){
        updatedUserData.password = formUserData.password;
        updatedProps.push('Password');
      }
      
      this.instrumentationService.sendEvent({type: 'User Detail', page: `Update User (${this.user.user_id}) Properties: ${updatedProps.join(',')}` });

      this.userService.updateUser(updatedUserData).subscribe((updateResponse:any)=>{

        if (updateResponse && updateResponse.error){
          this.notificationService.error('Oops!', updateResponse.error, this.notificationOptions);
          this.spinnerService.toggle(false);
          return;          
        }

        this.spinnerService.toggle(false);
        this.userService.clearCache();

        this.notificationService.success('Success!', 'User was updated', this.notificationOptions);

      },
      (error)=>{
        this.spinnerService.toggle(false);
        console.log(error);

        this.notificationService.error('Uh oh!', error, this.notificationOptions);

      });
    }
  }

  onDeleteClick(){  
    $('#mod-confirm-delete').modal('hide');
    this.spinnerService.toggle(true);
    
    this.instrumentationService.sendEvent({type: 'User Detail', page: `Delete User: (${this.user.user_id}` });
    this.userService.deleteUser(this.user.user_id.replace("auth0|","")).subscribe((response)=>{
      this.spinnerService.toggle(false);
      this.notificationService.success('Success!', 'User was deleted successfully!', this.notificationOptions);
      this.resetForm();
      this.userService.clearCache();
    },
    (err)=>{
      this.spinnerService.toggle(false);
      this.notificationService.error('Oops!', 'User was not deleted.', this.notificationOptions);
    });
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  addUsers(){
    console.log(this.existingUserForm.value);
    this.userService.createUser({existingIds:this.existingUserForm.value.userIds}).subscribe((result)=>{
      if(result && result["message"]){
        alert(result["message"]);
      }else {
        console.log(result);
        alert("Oops, something bad happened.");
      }
    });
    
  }

}
