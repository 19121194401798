import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import { Data } from '../../models/Data';
import { VaultBaseService } from '../VaultBaseService';
import { bool } from 'aws-sdk/clients/signer';
import { VendorDomain } from '../../models/VendorDomain';

@Injectable({
  providedIn: 'root'
})
export class VendorsService extends VaultBaseService {

  private static VENDORS: string = "vendors/v4";
  private static VENDORSV2: string = "vendorsV2";
  private static VENDOR_INFO: string = "vendorInfo";
  private static VENDOR_ACTIVITY: string = "vendorActivity";
  private static CODEFILES: string = "codefiles";
  private static COOKIES: string = "cookies";
  private static DNS: string = "dns";
  private static CHANGES: string = "changes";
  private static VENDORS_FOR_ALERT_CONFIGS: string = "getVendorsForAlertConfig";
  private static IMAGE_REQUESTS_DOMAINS: string = "imageRequestsDomains";
  private static IMAGE_REQUESTS_DATA: string = "imageRequestsData";
  private static CODE_FILE_RULES = "codeFileRules";

  cache: { [type: string]: Observable<any>; } = {};
  public selectedVendor: any;
  public domaindata = new BehaviorSubject<VendorDomain[]>(null);


  constructor(authHttp: HttpClient) {
    super(authHttp);
    this.cache = {};

  }

  public loadDomain(domain: VendorDomain[]) {
    this.domaindata.next(domain);
  }

  public getDomain(): Observable<VendorDomain[]> {
    return this.domaindata.asObservable();
  }

  public getVendors(limit: string = "", isLibrary: boolean = false): Observable<any> {
    console.log("Limit:", limit, "isLibrary", isLibrary);
    const cacheKey = `${VendorsService.VENDORS}:${limit}:${isLibrary}`;
    if (!this.cache[cacheKey]) {
      const url = env.api.url + VendorsService.VENDORS;
      var params = new HttpParams().set('limit', limit);
      if (isLibrary) {
        params = params.set('lib', '1');
      }
      console.log(params);
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  }

  public getVendorsV2(limit: string = "", isLibrary: boolean = false): Observable<any> {
    console.log("Limit:", limit, "isLibrary", isLibrary);
    const cacheKey = `${VendorsService.VENDORSV2}:${limit}:${isLibrary}`;
    if (!this.cache[cacheKey]) {
      const url = env.api.url + VendorsService.VENDORSV2;
      var params = new HttpParams().set('limit', limit);
      if (isLibrary) {
        params = params.set('lib', '1');
      }
      console.log(params);
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  }

  public getVendorsNoCache(): Observable<any> {
    const url = env.api.url + VendorsService.VENDORS;
    return this.makeHttpRequest(url);
  }

  public getVendorActivity(name: string, isLibrary: boolean = false): Observable<any> {
    const cacheKey = `${VendorsService.VENDOR_ACTIVITY}:${name}:${isLibrary}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${name}/activity`;
      var params = new HttpParams().set('limit', '5');
      if (isLibrary) {
        params = params.set('lib', '1');
      }
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }

    return this.cache[cacheKey];
  }

  public getCodeFiles(name: string, isLibrary: boolean = false): Observable<any> {
    const key = `${VendorsService.CODEFILES}:${name}:${isLibrary}`;
    if (!this.cache[key]) {
      const url = `${env.api.url}vendors/${name}/requestTypes`;
      var params = null;
      if (isLibrary) {
        params = new HttpParams().set('lib', '1');
      }
      this.cache[key] = this.makeHttpRequest(url, params);
    }
    return this.cache[key];
  };

  public getCodeFileHistory(vendorName: string, fileId: number, isLibrary: boolean = false, isHistoryId: boolean = false, analysis: boolean = false, limit: number = 50, offset: number = null): Observable<any> {
    const cacheKey = `${VendorsService.CODEFILES}:${fileId}:${isLibrary}:${analysis}:${isHistoryId}:${limit}:${offset}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${vendorName}/codeFiles/${fileId}/history`;
      var params = new HttpParams()
        .set('lib', isLibrary ? "1" : "")
        .set("historyId", isHistoryId.toString())
        .set("analysis", analysis.toString())
        .set("limit", limit.toString())
        .set("offset", offset ? offset.toString() : "");
      console.log(params);
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  };

  public getCodeFileHashHistory(limit: number = 50, offset: number = null): Observable<any> {
    const url = `${env.api.url}vendors/hashhistory`;
    var params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset ? offset.toString() : "");
    console.log(params);
    return this.makeHttpRequest(url, params);
  };

  public getCodeFileHashHistoryData(dataId: string): Observable<any> {
    const url = `${env.api.url}vendors/hashhistory`;
    var params = new HttpParams().set('dataId', dataId);
    console.log(params);
    return this.makeHttpRequest(url, params);
  };

  public getCodeFileHistoryData(vendorName: string, fileId: number, dataId: string): Observable<any> {
    const cacheKey = `${VendorsService.CODEFILES}:${dataId}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${vendorName}/codeFiles/${fileId}/history`;
      var params = new HttpParams().set('dataId', dataId);
      console.log(params);
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  };

  public getCodeFileChanges(vendorName: string, fileId: number, isLibrary: boolean = false): Observable<any> {
    const cacheKey = `${VendorsService.CHANGES}:${fileId}:${isLibrary}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${vendorName}/codeFiles/${fileId}/changes`;
      var params = null;
      if (isLibrary) {
        params = new HttpParams().set('lib', '1');
      }
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  };

  public getCookies(name: string): Observable<any> {
    const cacheKey = `${VendorsService.COOKIES}:${name}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${name}/cookies`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  };

  public getVendorInfo(name: string): Observable<any> {
    const cacheKey = `${VendorsService.VENDOR_INFO}:${name}`;
    if (!this.cache[cacheKey]) {
      const url = env.api.url + "vendors/" + name;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getVendorsForAlertConfigs(): Observable<any> {
    const cacheKey = `${VendorsService.VENDORS_FOR_ALERT_CONFIGS}`;
    if (!this.cache[cacheKey]) {
      const url = env.api.url + "vendorsForAlertConfig";
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getVendorDnsInfo(vendorId: number): Observable<any> {
    const cacheKey = `${VendorsService.DNS}:${vendorId}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${vendorId}/dns`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getImageRequestInfo(name: string): Observable<any> {
    const cacheKey = `${VendorsService.IMAGE_REQUESTS_DOMAINS}:${name}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${name}/imageRequests`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getDataForDomain(vendor: string, domainId: number): Observable<Data[]> {
    const cacheKey = `${VendorsService.IMAGE_REQUESTS_DATA}:${domainId}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}vendors/${vendor}/imageRequests/${domainId}`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public clearCache() {
    this.cache = {};
  }

  public clearRuleCache() {
    var cacheKeys = Object.keys(this.cache).filter((key) => { return key.startsWith(VendorsService.CODE_FILE_RULES + ":") })
    for (let index = 0; index < cacheKeys.length; index++) {
      const ruleKey = cacheKeys[index];
      console.log("Deleteing cache for: " + ruleKey);
      delete this.cache[ruleKey];
    }
  }

  public getClassificationRules(vendorName: string, clearCache: boolean = false): Observable<any> {
    const cacheKey = `${VendorsService.CODE_FILE_RULES}:${vendorName}`;
    if (!this.cache[cacheKey] || clearCache) {
      const url = `${env.api.url}vendors/${vendorName}/rules`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  };

  public createCodeFileRule(vendorName: string, rule: any): Observable<any> {
    const url = `${env.api.url}vendors/${vendorName}/rules`;
    return this.authHttp.post(url, JSON.stringify(rule));
  };

  public updateCodeFileRule(vendorName: string, rule: any): Observable<any> {
    const url = `${env.api.url}vendors/${vendorName}/rules/${rule.id}`;
    return this.authHttp.put(url, JSON.stringify(rule));
  };

  public deleteCodeFileRule(vendorName: string, ruleIds: number[]): Observable<any> {
    const url = `${env.api.url}vendors/${vendorName}/rules`;
    return this.authHttp.put(url, JSON.stringify(ruleIds));
  };

  public loadPageRunnerFile(url: string): Observable<any> {
    return this.authHttp.get(url, { responseType: 'text' });
  }

  public testRuleForFile(vendorName: string, ruleId: number, hash1: string, hash2: string = null): Observable<any> {
    const url = `${env.api.url}vendors/${vendorName}/rules/${ruleId}/test`;
    var params = new HttpParams().set('hash1', `${hash1}.beautified`);
    if (hash2 && hash2 != "") {
      params = params.append('hash2', `${hash2}.beautified`);
    }
    return this.authHttp.get(url, { params });
  }

  public getDiffPatchForVersions(hash1: string, hash2: string): Observable<any> {

    const cacheKey = `${hash1}${hash2}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}codeFiles/diff`;
      var params = new HttpParams()
        .set('hash1', hash1)
        .set("hash2", hash2);
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  }

  public getVendorForContentUrl(urls: string[]): Observable<any> {
    // const cacheKey = `${ VendorsService.IDENTIFY_URL}:${url.join}`;
    // if (!this.cache[cacheKey]){
    const url = `${env.api.url}vendors/identifyUrl`;
    // this.cache[cacheKey] = this.authHttp.post(url, {urls:urls});
    // }
    // return this.cache[cacheKey];    
    return this.authHttp.post(url, JSON.stringify({ urls: urls }));
  }

  public runFaviconJob(refreshAll: bool = false): Observable<any> {
    const url = `${env.api.url}service/icons`;
    var params = new HttpParams().set('refreshAll', refreshAll + "");
    return this.authHttp.get(url, { params });
  }

  public getDomainsForVendorById(vendorId: number): Observable<VendorDomain[]> {
    const url = `${env.api.url}vendors/${vendorId}/domains`;
    return this.makeHttpRequest(url);
  }

  public createNewVendor(data): Observable<any> {
    const url = `${env.api.url}vendors`;
    return this.authHttp.post(url, data);

  }
  public updateVendor(data): Observable<any> {
    const url = `${env.api.url}vendors`;
    return this.authHttp.put(url, data);
  }

  public getCurrentData(name): Observable<any> {
    const url = `${env.api.url}vendors/${name}`;
    return this.authHttp.get(url, name);
  }

  public getAllVendors(ignoreCache = false): Observable<any> {
    const url = `${env.api.url}vendors/all`;
    const cacheKey = `${VendorsService.VENDORS}:all`;
    if (!this.cache[cacheKey] || ignoreCache == true) {
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }

    return this.cache[cacheKey];
  }

  public deleteVendor(vendorId): Observable<any> {
    const url = `${env.api.url}vendors/${vendorId}`;
    return this.authHttp.delete(url);

  }

  public createDomain(data): Observable<any> {
    const url = `${env.api.url}vendors/domains`;
    return this.authHttp.post(url, data);
  }

  public updateDomain(data): Observable<any> {
    const url = `${env.api.url}vendors/domains`;
    return this.authHttp.put(url, data);
  }

  public getDomains(vendorId): Observable<any> {
    const url = `${env.api.url}vendors/${vendorId}/domains`;
    return this.authHttp.get(url, vendorId);
  }

  public deleteDomain(domainId): Observable<any> {
    const url = `${env.api.url}vendors/domains/${domainId}`;
    return this.authHttp.delete(url);
  }

  public bulkUpdate(data): Observable<any> {
    const url = `${env.api.url}vendors/bulkUpdate`;
    return this.authHttp.put(url, JSON.stringify(data));
  }

  public reclassifyCodeFiles(): Observable<any> {
    const url = `${env.api.url}vendors/reclassify`;
    return this.authHttp.post(url, null);
  }

  public getResourceMatcherKeys(vendorId, resourceMatcherId): Observable<any> {
    const url = `${env.api.url}vendors/${vendorId}/requestV2/${resourceMatcherId}?rdt=keys`;
    return this.makeHttpRequest(url, null);
  }

  public getResourceMatcherKeysAndValues(vendorId, resourceMatcherId): Observable<any> {
    const url = `${env.api.url}vendors/${vendorId}/requestV2/${resourceMatcherId}`;
    return this.authHttp.post(url, JSON.stringify({ "requestedDataType": "allValues" }));
  }

  public getRequestsForMatcher(vendorId, resourceMatcherId, keysAndVals) {
    const url = `${env.api.url}vendors/${vendorId}/requestV2/${resourceMatcherId}`;
    let data = {
      "requestedDataType": "requests",
      "keyValuePairs": keysAndVals
    };
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public getRequestMatcherName(vendorId, resourceMatcherId) {
    const url = `${env.api.url}vendors/${vendorId}/requestV2/${resourceMatcherId}?name=1`;
    let data = {};
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public getHashesForRMForVendor(vendorId, resourceMatcherId) {
    const url = `${env.api.url}vendors/${vendorId}/requestV2/${resourceMatcherId}`;
    let data = {
      "requestedDataType": "hashes"
    };
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public getExtractedValuesForHash(vendorId, resourceMatcherId, hash) {
    const url = `${env.api.url}vendors/${vendorId}/requestV2/${resourceMatcherId}`;
    let data = {
      "requestedDataType": "extracted"
    };
    var params = new HttpParams().set('hash', hash);
    return this.authHttp.post(url, JSON.stringify(data), { params: params });
  }

}