import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from "../../../environments/environment";
import * as AWS from 'aws-sdk';
import { S3 } from 'aws-sdk';
import { forkJoin, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService extends VaultBaseService {

  customerId:any;
  bucket:string;

  constructor(authHttp: HttpClient, private authService:AuthService) { 
    super(authHttp);
    this.customerId = this.authService.customerId()
    this.bucket = `${env.aws.customReportBucket}-${this.customerId}`
  }

  getAvailableReports(){
    var that = this;
    var retPromise = new Promise(function(resolve, reject){
      that.authService.getAwsCreds().subscribe((creds)=>{

        AWS.config.region = env.aws.region;      
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${env.name}-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise()).pipe(catchError(error=>of(error))).subscribe((response)=>{
          resolve(response);          
        });

      })
    });
    return from(retPromise);
    
  }

  getFile(key){
    var that = this;
    var retPromise = new Promise(function(resolve, reject){
      that.authService.getAwsCreds().subscribe((creds)=>{

        AWS.config.region = env.aws.region;      
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key
        };
        const s3 = new S3();
        from(s3.getObject(params).promise()).pipe(catchError(error=>of(error))).subscribe((response)=>{
          console.log("listObjectsV2", response);
          if ( response && response.Body ){
            resolve(response.Body.toString());
          } else {
            resolve(null);
          }          
        });

      })
    });
    return from(retPromise);
  }
  
  getReportData(fileName:string) {
    if ( fileName.indexOf(".") == -1 ){
      fileName += ".csv";
    }
    return forkJoin([
      this.getFile(fileName),
      this.getFile(fileName.split('.')[0] + ".meta"),
    ]);
  }

  async upload(files:File[]){
    for (var file of files){
      let fileContents = await this.getFileContents(file);
      console.log("Processing file: " + file.name);
      const s3PutParams = {
        Body: fileContents,
        Bucket: this.bucket,
        Key: file.name,
        ContentType: "text/plain"
      };
    
      let creds = await this.authService.getAwsCreds().toPromise();
      
      AWS.config.region = env.aws.region;      
      AWS.config.credentials = new AWS.Credentials(creds);
      const s3 = new S3();

      let s3Response = await s3.putObject(s3PutParams).promise();
      console.log("putResponse", s3Response);
    }  
  }

  getFileContents(file){
    var retPromise = new Promise(function(resolve, reject){
      var fileReader = new FileReader();
      
      fileReader.readAsText(file);

      fileReader.onload = function () {
        resolve(fileReader.result);
      }
      fileReader.onabort = function(){
        reject("Abort");
      }
      fileReader.onerror = function(err){
        reject("Error: " + (err ? JSON.stringify(err) : ""));
      }       
    });
    
    return retPromise;
  }

  async deleteReport(key) {
    const s3Params = {
      Bucket: this.bucket,
      Key: key
    };

    let creds = await this.authService.getAwsCreds().toPromise();
  
    AWS.config.region = env.aws.region;      
    AWS.config.credentials = new AWS.Credentials(creds);
    const s3 = new S3();

    let deleteResponse = await s3.deleteObject(s3Params).promise();
    console.log("delete", deleteResponse);    

    return deleteResponse.$response.error;
  }
}