import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GlobalResourcesService } from '../global-resources/global-resources.service';
import { map } from 'rxjs/operators';
import { VaultNotificationService } from '../notifications/vault-notification.service';
import { switchMap } from 'rxjs/operators';
export interface DictionaryObject {
  field: string;
  type: string;
  description: string;
  overrideHeader?: string;
  example?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportingDictService {
  constructor(
    private globalResources: GlobalResourcesService,
    private notifService: VaultNotificationService
  ) {}
  public getDictByCode(reportCode: string): Observable<DictionaryObject[]> {
    // get dictionary from S3 with code and if not found return empty array
    return this.globalResources
      .getFileFromS3(`dictionary${reportCode}.json`)
      .pipe(
        map((data: string) => {
          let output = [] as DictionaryObject[];
          try {
            output = JSON.parse(data) as DictionaryObject[];
          } catch (e) {
            console.error('Error parsing dictionary json:', e);
          }
          return output;
        })
      );
  }
  public updateDictByCode(reportCode:string,data:DictionaryObject[]): Observable<any> {
    return this.globalResources.putFileToS3(`dictionary${reportCode}.json`, data);
  }
  public getDictList(): Observable<string[]> {
    return this.globalResources.getListOfResources('dictionary').pipe(
      map((data: any) => {
        return data.Contents.map((item: any) => {
          console.log(item);
          return item.Key.split('dictionary')[1].replace('.json', '');
        });
      })
    );
  }

  public updateEntryByCode(
    reportCode: string,
    entry: DictionaryObject,
    previousField: string
  ): Observable<any> {
    return this.getDictByCode(reportCode).pipe(
      map((dict) => {
        const index = dict.findIndex((item) => item.field === previousField);
        if (index > -1) {
          this.notifService.info('Updating existing entry','');
          dict[index] = entry;
        }
        return dict;
      }),
      switchMap((dict) => this.updateDictByCode(reportCode, dict))
    );
  }

  public addEntryToDict(
    reportCode: string,
    entry: DictionaryObject
  ): Observable<any> {
    return of(null);
  }

  public deleteEntryFromDict(
    reportCode: string,
    entry: DictionaryObject
  ): Observable<any> {
    return of(null);
  }

  public createDict(reportCode: string): Observable<any> {
    return of(null);
  }
}
