import { Component, OnInit, ViewChild } from '@angular/core';
import { AccumulationChartComponent, AccumulationChart, IAccLoadedEventArgs } from "@syncfusion/ej2-angular-charts";
import { IAccTextRenderEventArgs, ISparklineLoadEventArgs, Sparkline, SparklineTheme } from '@syncfusion/ej2-charts';
import { MapsTheme, Maps, IShapeSelectedEventArgs, ILoadEventArgs, ILoadedEventArgs } from '@syncfusion/ej2-angular-maps';
const worldMap = require('../data/world-map.json');
const datasource = require('../data/default-datasource.json');
const africa = require('../data/africa.json');
const europe = require('../data/europe.json');
const asia = require('../data/asia.json');
const north_america = require('../data/north-america.json');
const south_america = require('../data/south-america.json');
const oceania = require('../data/oceania.json');
const usa = require('../data/usa.json');
export interface ShapeData { continent?: string; }
let touchmove: boolean;
declare var require: any;
import { environment as env } from "../../../../../environments/environment";
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';
declare var $: any;

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
    public coppaActive = false;
    public tooltipContent = "Nibh non faucibus aenean netus ante hendrerit"
    // GLOBAL VARS
    public cdnUrl: string;
    public tooltipLG: Object = {
        enable: true,
        showAtMousePosition: true,
        textStyle: { fontFamily: 'inherit' }
    };
    private heightActually = 20;
    private offset = this.heightActually - 2;
    private startHeightActually = this.offset - 2;
    private endHeightActually = this.offset - 2;
    public borderColor: string = 'yellow';
    public textColor: string = 'blue';
    public axes: Object[] = [{
        minimum: 0,
        maximum: 17,
        line: { width: 0 },
        pointers: [
            {
                markerType: 'Rectangle',
                height: this.heightActually - 4,
                offset: 2,
                value: 1,
                width: 10,
                color: "green",
                position: "Outside"

            }
        ],
        majorTicks: {
            height: 0
        },
        minorTicks: {
            height: 0
        },
        labelStyle: {
            font: { size: '0px' }
        }
    }];

    public container: Object = {
        width: this.heightActually, type: 'Normal', backgroundColor: "white", border: { width: 0 }
    };


    public tooltipSettingsSparkGrid = {
        visible: true,
        format: '${timestamp} : ${value}',
        fill: '#033e96',
        textStyle: {
            color: 'white'
        },
    }
    violationSummaryData: any;

    public floorAndFormat(num){
        let temp = num.toFixed(0);
        return parseInt(temp).toLocaleString('en-US');
    }

    // total sites percentage
    public totalSites = { sites: 121, monitored: 68 }; // HARDCCODED DATA
    // total pages percentage
    public totalPages = { pages: 474508, monitored: 346391  }; // HARDCODED DATA
    sortSettings: { columns: { field: string; direction: string; }[]; };
    filterSettings: Object;
    frequencyEditRules: { number: boolean; };
    editSettings: { allowEditing: boolean; mode: string; };
    pageOptions: { pageSize: number; pageCount: number; pageSizes: number[]; };
    dateFormat: { type: string; skeleton: string; };
    toolbar: string[];
    editparams: { params: { popupHeight: string; }; };
    siteData: any[];
    // percentage helper function
    public getPercent(num, denom): number {
        return (parseInt(num) / parseInt(denom)) * 100;
    }
    // key pages violating
    public keyPageViolations = "5"; // HARDCODED DATA
    //  scan success
    public scanSuccess = "89"; // HARDCODED DATA

    sites;
    sitesFields: Object = { value: 'id', text: 'name' };

    public legendSettings: Object = {
        visible: true,
        toggleVisibility: false,
        position: 'Right',
        height: '75%',
        width: '60%',
        textWrap: 'Wrap',
        maximumLabelWidth: 100,
    };

    public pieTooltip: Object = {
        enable: true
    };

    public pieCenter: Object = {
        x: '30%',
        y: '50%'
    }

    public pieData: Object[] = [
        { 'x': 'Last 24 Hours', daysMin: 0, daysMax: 1, counter: 0, y: 0, text: '' },
        { 'x': 'Last 7 Days', daysMin: 1, daysMax: 7, counter: 0, y: 0, text: '' },
        { 'x': 'Last 30 Days', daysMin: 7, daysMax: 30, counter: 0, y: 0, text: '' },
        { 'x': 'Last 90 Days', daysMin: 30, daysMax: 90, counter: 0, y: 0, text: '' }
    ];

    public tooltipRender(args: ITooltipRenderEventArgs): void {
        console.log(args);
        args.text = `${args.point.x}: ${Number(args.point.y).toFixed(2)}%`;
    }

    public onTextRender(args: IAccTextRenderEventArgs): void {
        // args.series.dataLabel.font.size = this.getFontSize(this.pie.initialClipRect.width);
        // console.log("args.text", args.text);
        if (args.text == '0') {
            args.text = '';
        } else {
            args.text = Number(args.text).toFixed(2) + '%';
        }
    }

    // MAP PROPERTIES

    public tooltipMapRender(args): void {
        if (!args.options.data) {
            args.cancel;
        }
    };

    @ViewChild('maps')
    public maps: Maps;
    public zoomSettings: object = { enable: false };
    public mapLegendSettings: object = { visible: true, mode: 'Interactive', position: 'Bottom', height: '10', width: '350', alignment: 'Center' };
    public layers: any[] = [
        {
            layerType: 'Geometry',
            shapePropertyPath: 'continent',
            shapeDataPath: 'continent',
            dataSource: datasource,
            shapeData: worldMap,
            shapeSettings: {
                colorValuePath: 'vppaViolations', // HARDCODED in default-datasource.json
                colorMapping: [{
                    from: 0, to: 5, color: '#03AC13', label: '< 5% Violating' // green
                },
                {
                    from: 5, to: 50, color: '#FBB917', label: '5-50% Violating' // yellow
                },
                {
                    from: 50, to: 100, color: '#C60D25', label: '> 50% Violating' // red
                },
                ],
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'continent',
                template: '<div id="template"> <div style="border-radius: 4px; border: 1px #abb9c6; opacity: 90%; background: rgba(53, 63, 76, 0.90); box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40); padding-bottom: 5px; padding-top: 10px; padding-left: 10px; padding-right: 10px;"> <div style="font-size: 13px; color: #ffffff; font-weight: 500;"> <center> ${continent} </center> </div> <hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD"> <div> <span style="font-size: 13px; color: #cccccc">VPPA Violations:</span><span style="font-size: 13px; color: #ffffff; font-weight: 500;">${vppaViolations}%</span> </div> </div> </div>'
            },
            markerSettings: [
                {
                    visible: true,
                    template: '<div id="marker1" class="markersTemplate" style="color:white;">Asia' +
                        '</div>',
                    dataSource: [
                        { latitude: 50.32087157990324, longitude: 90.015625 }
                    ],
                    animationDuration: 0
                },
                {
                    visible: true,
                    template: '<div id="marker2" class="markersTemplate" style="color:white;">Australia' +
                        '</div>',
                    dataSource: [
                        { latitude: -28.88583769986199, longitude: 130.296875 }
                    ],
                    animationDuration: 0
                },
                {
                    visible: true,
                    template: '<div id="marker3" class="markersTemplate" style="color:white;">Africa' +
                        '</div>',
                    dataSource: [
                        { latitude: 10.97274101999902, longitude: 16.390625 }
                    ],
                    animationDuration: 0
                },
                {
                    visible: true,
                    template: '<div id="marker4" class="markersTemplate" style="color:white;">Europe' +
                        '</div>',
                    dataSource: [
                        { latitude: 47.95121990866204, longitude: 18.468749999999998 }
                    ],
                    animationDuration: 0,
                },
                {
                    visible: true,
                    template: '<div id="marker5" class="markersTemplate" style="width:50px;color:white;">North America' +
                        '</div>',
                    dataSource: [
                        { latitude: 59.88893689676585, longitude: -109.3359375 }
                    ],
                    animationDuration: 0
                },
                {
                    visible: true,
                    template: '<div id="marker6" class="markersTemplate" style="width:50px;color:white;">South America' +
                        '</div>',
                    dataSource: [
                        { latitude: -6.64607562172573, longitude: -55.54687499999999 }
                    ],
                    animationDuration: 0
                },
            ]
        },
        {
            layerType: 'Geometry',
            shapeData: africa,
            shapeSettings: {
                fill: '#80306A'
            },
            highlightSettings: {
                enable: true,
                fill: '#80306A'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
        {
            layerType: 'Geometry',
            shapeData: europe,
            shapeSettings: {
                fill: '#622D6C'
            },
            highlightSettings: {
                enable: true,
                fill: '#622D6C'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
        {
            layerType: 'Geometry',
            shapeData: asia,
            shapeSettings: {
                fill: '#462A6D'
            },
            highlightSettings: {
                enable: true,
                fill: '#462A6D'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
        {
            layerType: 'Geometry',
            shapeData: north_america,
            shapeSettings: {
                fill: '#C13664'
            },
            highlightSettings: {
                enable: true,
                fill: '#C13664'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
        {
            layerType: 'Geometry',
            shapeData: south_america,
            shapeSettings: {
                fill: '#9C3367'
            },
            highlightSettings: {
                enable: true,
                fill: '#9C3367'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
        {
            layerType: 'Geometry',
            shapeData: oceania,
            shapeSettings: {
                fill: '#2A2870'
            },
            highlightSettings: {
                enable: true,
                fill: '#2A2870'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
        {
            layerType: 'Geometry',
            shapeData: usa,
            shapeSettings: {
                fill: '#80306A'
            },
            highlightSettings: {
                enable: true,
                fill: '#80306A'
            },
            tooltipSettings: {
                visible: true,
                valuePath: 'name'
            }
        },
    ]

    public renderTooltipLG(args: any): void {
        console.log('args', args);
        args.content = 'Mollis venenatis scelerisque erat ut eleifend magna...';
    }

    public getRando(x, y) {
        return Math.floor(Math.random() * (y - x + 1)) + x;
    }

    public getArrayRandos(length, x, y) {
        let ret = [];
        for (var i = 0; i < length; i++){
            ret.push(Math.floor(Math.random() * (y - x + 1)) + x);
        }

        return ret;
    }

    constructor() {
        this.cdnUrl = env.cdn.url;

        // Drop down menu
        this.sites = [{
            id: 1,
            name: "Test"
        }];

        this.sortSettings = {
            columns: [
                
            ]
        };

        this.filterSettings = {
            type: 'Excel'
        };

        this.frequencyEditRules = { number: true };
        this.editSettings = { allowEditing: false, mode: 'Normal' };
        this.editparams = { params: { popupHeight: '300px' } };
        this.toolbar = ['Update', 'Cancel'];
        this.dateFormat = { type: 'dateTime', skeleton: 'short' };

        this.pageOptions = { pageSize: 25, pageCount: 5, pageSizes: [25, 50, 75, 100, 200] };

        this.violationSummaryData = [
            {
                type: "Total",
                pages: 81,
                domains: 72,
                total: 1912121,
                colors: [
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red"
                ],
                data: this.getArrayRandos(15, 0, 100),
            },
            {
                type: "CCPA",
                pages: 85,
                domains: 51,
                total: 242401,
                colors: [
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red"
                ],
                data: this.getArrayRandos(15, 0, 100),

            },
            {
                type: "GDPR",
                pages: 56,
                domains: 43,
                total: 149820,
                colors: [
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red"
                ],
                data: this.getArrayRandos(15, 0, 100),
            },
            {
                type: "VPPA",
                pages: 24,
                domains: 31,
                total: 449031,
                colors: [
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red"
                ],
                data: this.getArrayRandos(15, 0, 100),
            },
            {
                type: "COPPA",
                pages: 12,
                domains: 10,
                total: 62552,
                colors: [
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red"
                ],
                data: this.getArrayRandos(15, 0, 100),
            },
            {
                type: "Data Sharing",
                pages: 59,
                domains: 65,
                total: 912314,
                colors: [
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red",
                    this.getRando(0, 1) == 0 ? "green" : "red"
                ],
                data: this.getArrayRandos(15, 0, 100),
            }
        ];
    }

    initializeRandomArray(length, x, y) {
        var randomArray = [];

        for (var i = 0; i < length; i++) {
            var randomNumber = Math.floor(Math.random() * (y - x + 1)) + x;
            randomArray.push(randomNumber);
        }

        return randomArray;
    }

    initializeRandomArrayWithTrend(length, x, y, z) {
        var randomArray = [];

        var range = y - x;
        var increment = range / (length - 1);

        for (var i = 0; i < length; i++) {
            var randomNumber = Math.random() * increment + (x + i * increment);
            var trendNumber = randomNumber + (z * i);
            randomArray.push(trendNumber);
        }

        return randomArray;
    }

    ngOnInit(): void {
        this.siteData = [
            {
                id: 0,
                domain: "ESPN.com",
                keyPageViolations: 3,
                dcfDeployment: {
                    percent: 45,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                violationDCF: {
                    percent: 45,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                violationOther: {
                    percent: 45,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                dataSharing: {
                    percent: 45,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                percentMonitored: 25,
                scanFrequencey: {
                    pieData: this.initializeRandomArray(3, 1, 10),
                    successPercent: 81
                }
            },
            {
                id: 1,
                domain: "SECSports.com",
                keyPageViolations: 1,
                dcfDeployment: {
                    percent: 29,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                violationDCF: {
                    percent: 61,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                violationOther: {
                    percent: 56,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                dataSharing: {
                    percent: 15,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                percentMonitored: 53,
                scanFrequencey: {
                    pieData: this.initializeRandomArray(3, 1, 10),
                    successPercent: 93
                }
            },
            {
                id: 2,
                domain: "XGames.com",
                keyPageViolations: 1,
                dcfDeployment: {
                    percent: 83,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                violationDCF: {
                    percent: 45,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                violationOther: {
                    percent: 60,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                dataSharing: {
                    percent: 22,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                percentMonitored: 12,
                scanFrequencey: {
                    pieData: this.initializeRandomArray(3, 1, 10),
                    successPercent: 42
                }
            },
            {
                id: 3,
                domain: "Plus.ESPN.com",
                keyPageViolations: null,
                dcfDeployment: {
                    percent: 55,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                violationDCF: {
                    percent: 88,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                violationOther: {
                    percent: 71,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                dataSharing: {
                    percent: 45,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                percentMonitored: 67,
                scanFrequencey: {
                    pieData: this.initializeRandomArray(3, 1, 10),
                    successPercent: 37
                }
            },
            {
                id: 4,
                domain: "ESPNDeportes.ESPN.com",
                keyPageViolations: null,
                dcfDeployment: {
                    percent: 47,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                violationDCF: {
                    percent: 3,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'green'
                },
                violationOther: {
                    percent: 46,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                dataSharing: {
                    percent: 39,
                    data: this.initializeRandomArray(26, 0, 10),
                    color: 'red'
                },
                percentMonitored: 25,
                scanFrequencey: {
                    pieData: this.initializeRandomArray(3, 1, 10),
                    successPercent: 52
                }
            }
        ];

        this.griddata = [
            {
                vendor: 'Microsoft',
                website: 'microsoft.com',
                violationShare: 38,
                violationShareTotal: 107402,
                pageShare: 47,
                pageShareTotal: 5320,
                dcfViolationPercent: 18
            },
            {
                vendor: 'Adobe',
                website: 'adobe.com',
                violationShare: 25,
                violationShareTotal: 89305,
                pageShare: 54,
                pageShareTotal: 3011,
                dcfViolationPercent: 42
            },
            {
                vendor: 'Alphabet',
                website: 'google.com',
                violationShare: 13,
                violationShareTotal: 41072,
                pageShare: 27,
                pageShareTotal: 1355,
                dcfViolationPercent: 68
            }
        ];

    }

    sparkInterval;

    ngAfterViewInit() {
        $('[data-toggle="popover"]').popover();
        this.sparkInterval = setInterval(() => {
            let sp = $('.sparklinebasic');
            console.log(sp);
            if (sp) {
                $('.sparklinebasic-green').sparkline('html', {
                    lineColor: 'green',
                    highlightSpotColor: 'black',
                    highlightLineColor: 'black',
                    fillColor: false,
                    spotColor: false,
                    minSpotColor: false,
                    maxSpotColor: false,
                    lineWidth: 1
                });
                $('.sparklinebasic-red').sparkline('html', {
                    lineColor: 'red',
                    highlightSpotColor: 'black',
                    highlightLineColor: 'black',
                    fillColor: false,
                    spotColor: false,
                    minSpotColor: false,
                    maxSpotColor: false,
                    lineWidth: 1
                });
                $('.sparkpie').sparkline('html', { type: 'pie', height: '2.0em' });
                clearInterval(this.sparkInterval);
            }
        }, 400)


        document.getElementById('category').onclick = () => {
            this.maps.baseLayerIndex = 0;
            this.maps.refresh();
            let button: HTMLElement = document.getElementById('button');
            button.style.display = 'none';
            document.getElementById('content').innerHTML = 'Click on a shape to drill';
            (<HTMLElement>document.getElementById('category')).style.visibility = 'hidden';
            (<HTMLElement>document.getElementById('text')).innerHTML = '';
            (<HTMLElement>document.getElementById('symbol')).style.visibility = 'hidden';
        };
    }

    public lineData: object[] = [
        [0, 6, -4, 1, -3, 2, 5],
        [5, -4, 6, 3, -1, 2, 0],
        [6, 4, 0, 3, -2, 5, 1],
        [4, -6, 3, 0, 1, -2, 5],
        [3, 5, -6, -4, 0, 1, 2],
        [1, -3, 4, -2, 5, 0, 6],
        [2, 4, 0, -3, 5, -6, 1],
        [5, 4, -6, 3, 1, -2, 0],
        [0, -6, 4, 1, -3, 2, 5],
        [6, 4, 0, -3, 2, -5, 1],
        [4, 6, -3, 0, 1, 2, 5],
        [3, -5, -6, 4, 0, 1, 2],
        [1, 3, -4, -2, 5, 0, 6],
        [2, -4, 0, -3, 5, 6, 1],
        [5, 4, -6, 3, 1, -2, 0],
        [0, 6, 4, -1, -3, 2, 5],
        [6, -4, 0, -3, 2, 5, 1],
        [4, 6, -3, 0, -1, 2, 5],
        [6, 4, 0, -3, 2, -5, 1],
        [3, 5, 6, -4, 0, 1, 2],
        [1, 3, -4, 2, -5, 0, 6]
    ];
    public columnData: Object[] = [
        [0, 6, -4, 1, -3, 2, 5],
        [5, -4, 6, 3, -1, 2, 0],
        [6, 4, 0, 3, -2, 5, 1],
        [4, -6, 3, 0, 1, -2, 5],
        [3, 5, -6, -4, 0, 1, 2],
        [1, -3, 4, -2, 5, 0, 6],
        [2, 4, 0, -3, 5, -6, 1],
        [5, 4, -6, 3, 1, -2, 0],
        [0, -6, 4, 1, -3, 2, 5],
        [6, 4, 0, -3, 2, -5, 1],
        [4, 6, -3, 0, 1, 2, 5],
        [3, -5, -6, 4, 0, 1, 2],
        [1, 3, -4, -2, 5, 0, 6],
        [2, -4, 0, -3, 5, 6, 1],
        [5, 4, -6, 3, 1, -2, 0],
        [0, 6, 4, -1, -3, 2, 5],
        [6, -4, 0, -3, 2, 5, 1],
        [4, 6, -3, 0, -1, 2, 5],
        [6, 4, 0, -3, 2, -5, 1],
        [3, 5, 6, -4, 0, 1, 2],
        [1, 3, -4, 2, -5, 0, 6]
    ];
    // tslint:disable
    public griddata: object[] = [];
    public getSparkData(type: string, count: number): number[] {
        if (type === 'line') {
            return this.lineData[count] as number[];
        } else {
            return this.columnData[count] as number[];
        }
    }
    // public renderGridSparkline(): void {
    //     setTimeout(() => {
    //         for (let i: number = 0; i < 1; i++) {
    //             let line: Sparkline = new Sparkline({
    //                 height: '50px',
    //                 width: '50%',
    //                 lineWidth: 2,
    //                 valueType: 'Numeric',
    //                 fill: 'green',
    //                 dataSource: this.getSparkData('line', i),
    //                 tooltipSettings: {
    //                     visible: true
    //                 }
    //             });
    //             line.appendTo('#dcfDeployment' + i);
    //         }
    // }, 100);
    // }

    public load(args: ISparklineLoadEventArgs): void {
        let selectedTheme: string = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.sparkline.theme = <SparklineTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
    };

    // Map functions

    public mapsLoad(args: ILoadEventArgs) {
        // custom code start
        let theme: string = location.hash.split('/')[1];
        theme = theme ? theme : 'Material';
        args.maps.theme = <MapsTheme>(theme.charAt(0).toUpperCase() +
            theme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
        // custom code end
    }

    public loaded = (args: ILoadedEventArgs) => {
        let mapsSVG: HTMLElement = document.getElementById('mapdrilldown_svg') as HTMLElement;
        if (mapsSVG) {
            mapsSVG.addEventListener('touchmove', (e: MouseEvent) => { touchmove = true; }, false);
        }
    };
    public shapeSelected = (args: IShapeSelectedEventArgs): void => {
        let shape: string = (args.shapeData as ShapeData).continent;
        if (this.maps.baseLayerIndex === 0 && !touchmove) {
            if (shape === 'Africa') {
                this.maps.baseLayerIndex = 1;
                this.maps.refresh();
            }
            else if (shape === 'Europe') {
                this.maps.baseLayerIndex = 2;
                this.maps.refresh();
            }
            else if (shape === 'Asia') {
                this.maps.baseLayerIndex = 3;
                this.maps.refresh();
            }
            else if (shape === 'North America') {
                this.maps.baseLayerIndex = 4;
                this.maps.refresh();
            }
            else if (shape === 'South America') {
                this.maps.baseLayerIndex = 5;
                this.maps.refresh();
            }
            else if (shape === 'Australia') {
                this.maps.baseLayerIndex = 6;
                this.maps.refresh();
            }
            let button: HTMLElement = document.getElementById('button'); button.style.display = 'block';
            document.getElementById('content').innerHTML = '';
            (<HTMLElement>document.getElementById('category')).style.visibility = 'visible';
            (<HTMLElement>document.getElementById('text')).innerHTML = shape;
            (<HTMLElement>document.getElementById('symbol')).style.visibility = 'visible';
        };
        touchmove = false;
    }
    // custom code end
    public pausData: object[] = [
        { x: 0, xval: '2005', yval: 20090440 },
        { x: 1, xval: '2006', yval: 20264080 },
        { x: 2, xval: '2007', yval: 20434180 },
        { x: 3, xval: '2008', yval: 21007310 },
        { x: 4, xval: '2009', yval: 21262640 },
        { x: 5, xval: '2010', yval: 21515750 },
        { x: 6, xval: '2011', yval: 21766710 },
        { x: 7, xval: '2012', yval: 22015580 },
        { x: 8, xval: '2013', yval: 22262500 },
        { x: 9, xval: '2014', yval: 22507620 },
    ];
    public pausTooltipSettings: object = {
        visible: true,
        format: '${xval} : ${yval}',
        trackLineSettings: {
            visible: true,
            color: 'red',
            width: 2
        }
    };
    public pcanData: object[] = [
        { x: 0, xval: '2005', yval: 32805040 },
        { x: 1, xval: '2006', yval: 33098930 },
        { x: 2, xval: '2007', yval: 33390140 },
        { x: 3, xval: '2008', yval: 33212700 },
        { x: 4, xval: '2009', yval: 33487210 },
        { x: 5, xval: '2010', yval: 33759740 },
        { x: 6, xval: '2011', yval: 34030590 },
        { x: 7, xval: '2012', yval: 34300080 },
        { x: 8, xval: '2013', yval: 34568210 },
        { x: 9, xval: '2014', yval: 34834840 },
    ];
    public pchinaData: object[] = [
        { x: 0, xval: '2005', yval: 1306314000 },
        { x: 1, xval: '2006', yval: 1313974000 },
        { x: 2, xval: '2007', yval: 1321852000 },
        { x: 3, xval: '2008', yval: 1330045000 },
        { x: 4, xval: '2009', yval: 1338613000 },
        { x: 5, xval: '2010', yval: 1330141000 },
        { x: 6, xval: '2011', yval: 1336718000 },
        { x: 7, xval: '2012', yval: 1343240000 },
        { x: 8, xval: '2013', yval: 1349586000 },
        { x: 9, xval: '2014', yval: 1355693000 },
    ];
    public pfraData: object[] = [
        { x: 0, xval: '2005', yval: 60656180 },
        { x: 1, xval: '2006', yval: 60876140 },
        { x: 2, xval: '2007', yval: 63713930 },
        { x: 3, xval: '2008', yval: 64057790 },
        { x: 4, xval: '2009', yval: 64057790 },
        { x: 5, xval: '2010', yval: 64768390 },
        { x: 6, xval: '2011', yval: 65312250 },
        { x: 7, xval: '2012', yval: 65630690 },
        { x: 8, xval: '2013', yval: 65951610 },
        { x: 9, xval: '2014', yval: 66259010 },
    ];
    public pgerData: object[] = [
        { x: 0, xval: '2005', yval: 82431390 },
        { x: 1, xval: '2006', yval: 82422300 },
        { x: 2, xval: '2007', yval: 82400990 },
        { x: 3, xval: '2008', yval: 82369550 },
        { x: 4, xval: '2009', yval: 82329760 },
        { x: 5, xval: '2010', yval: 82282990 },
        { x: 6, xval: '2011', yval: 81471830 },
        { x: 7, xval: '2012', yval: 81305860 },
        { x: 8, xval: '2013', yval: 81147260 },
        { x: 9, xval: '2014', yval: 80996690 },
    ];
    public pindData: object[] = [
        { x: 0, xval: '2005', yval: 1080264000 },
        { x: 1, xval: '2006', yval: 1095352000 },
        { x: 2, xval: '2007', yval: 1129866000 },
        { x: 3, xval: '2008', yval: 1147996000 },
        { x: 4, xval: '2009', yval: 1166079000 },
        { x: 5, xval: '2010', yval: 1173108000 },
        { x: 6, xval: '2011', yval: 1189173000 },
        { x: 7, xval: '2012', yval: 1205074000 },
        { x: 8, xval: '2013', yval: 1220800000 },
        { x: 9, xval: '2014', yval: 1236345000 },
    ];
    public prusData: object[] = [
        { x: 0, xval: '2005', yval: 143420300 },
        { x: 1, xval: '2006', yval: 142893500 },
        { x: 2, xval: '2007', yval: 141377800 },
        { x: 3, xval: '2008', yval: 140702100 },
        { x: 4, xval: '2009', yval: 140041200 },
        { x: 5, xval: '2010', yval: 139390200 },
        { x: 6, xval: '2011', yval: 138739900 },
        { x: 7, xval: '2012', yval: 142517700 },
        { x: 8, xval: '2013', yval: 142500500 },
        { x: 9, xval: '2014', yval: 142470300 },
    ];
    public psweData: object[] = [
        { x: 0, xval: '2005', yval: 9001774 },
        { x: 1, xval: '2006', yval: 9016596 },
        { x: 2, xval: '2007', yval: 9031088 },
        { x: 3, xval: '2008', yval: 9045389 },
        { x: 4, xval: '2009', yval: 9059651 },
        { x: 5, xval: '2010', yval: 9074055 },
        { x: 6, xval: '2011', yval: 9074055 },
        { x: 7, xval: '2012', yval: 9103788 },
        { x: 8, xval: '2013', yval: 9119423 },
        { x: 9, xval: '2014', yval: 9723809 },
    ];
    public pukData: object[] = [
        { x: 0, xval: '2005', yval: 60441460 },
        { x: 1, xval: '2006', yval: 60609150 },
        { x: 2, xval: '2007', yval: 60776240 },
        { x: 3, xval: '2008', yval: 60943910 },
        { x: 4, xval: '2009', yval: 61113200 },
        { x: 5, xval: '2010', yval: 62348450 },
        { x: 6, xval: '2011', yval: 62698360 },
        { x: 7, xval: '2012', yval: 63047160 },
        { x: 8, xval: '2013', yval: 63395580 },
        { x: 9, xval: '2014', yval: 63742980 },
    ];
    public pusData: object[] = [
        { x: 0, xval: '2005', yval: 295734100 },
        { x: 1, xval: '2006', yval: 298444200 },
        { x: 2, xval: '2007', yval: 301139900 },
        { x: 3, xval: '2008', yval: 303824600 },
        { x: 4, xval: '2009', yval: 307212100 },
        { x: 5, xval: '2010', yval: 310232900 },
        { x: 6, xval: '2011', yval: 313232000 },
        { x: 7, xval: '2012', yval: 313847500 },
        { x: 8, xval: '2013', yval: 316668600 },
        { x: 9, xval: '2014', yval: 318892100 },
    ];
    public border: object = {
        color: 'red',
        width: 2
    };
    public areatooltipSettings: object = {
        visible: true,
        format: '${xval} : ${yval}',
    };
    public aausData: object[] = [
        { x: 0, xval: '2005', yval: 2.61 },
        { x: 1, xval: '2006', yval: 2.64 },
        { x: 2, xval: '2007', yval: 2.66 },
        { x: 3, xval: '2008', yval: 2.73 },
        { x: 4, xval: '2009', yval: 2.75 },
        { x: 5, xval: '2010', yval: 2.78 },
        { x: 6, xval: '2011', yval: 2.81 },
        { x: 7, xval: '2012', yval: 2.84 },
        { x: 8, xval: '2013', yval: 2.88 },
        { x: 9, xval: '2014', yval: 2.91 },
    ];
    public acanData: object[] = [
        { x: 0, xval: '2005', yval: 3.29 },
        { x: 1, xval: '2006', yval: 3.31 },
        { x: 2, xval: '2007', yval: 3.34 },
        { x: 3, xval: '2008', yval: 3.33 },
        { x: 4, xval: '2009', yval: 3.35 },
        { x: 5, xval: '2010', yval: 3.38 },
        { x: 6, xval: '2011', yval: 3.41 },
        { x: 7, xval: '2012', yval: 3.44 },
        { x: 8, xval: '2013', yval: 3.46 },
        { x: 9, xval: '2014', yval: 3.49 },
    ];
    public achinaData: object[] = [
        { x: 0, xval: '2005', yval: 136.12 },
        { x: 1, xval: '2006', yval: 136.92 },
        { x: 2, xval: '2007', yval: 137.74 },
        { x: 3, xval: '2008', yval: 138.59 },
        { x: 4, xval: '2009', yval: 139.48 },
        { x: 5, xval: '2010', yval: 138.6 },
        { x: 6, xval: '2011', yval: 139.29 },
        { x: 7, xval: '2012', yval: 139.97 },
        { x: 8, xval: '2013', yval: 140.63 },
        { x: 9, xval: '2014', yval: 141.26 },
    ];
    public afraData: object[] = [
        { x: 0, xval: '2005', yval: 110.88 },
        { x: 1, xval: '2006', yval: 111.28 },
        { x: 2, xval: '2007', yval: 99.02 },
        { x: 3, xval: '2008', yval: 99.56 },
        { x: 4, xval: '2009', yval: 99.56 },
        { x: 5, xval: '2010', yval: 100.66 },
        { x: 6, xval: '2011', yval: 101.45 },
        { x: 7, xval: '2012', yval: 101.94 },
        { x: 8, xval: '2013', yval: 102.44 },
        { x: 9, xval: '2014', yval: 102.92 },
    ];
    public agerData: object[] = [
        { x: 0, xval: '2005', yval: 230.89 },
        { x: 1, xval: '2006', yval: 230.86 },
        { x: 2, xval: '2007', yval: 230.8 },
        { x: 3, xval: '2008', yval: 230.71 },
        { x: 4, xval: '2009', yval: 230.6 },
        { x: 5, xval: '2010', yval: 230.47 },
        { x: 6, xval: '2011', yval: 228.2 },
        { x: 7, xval: '2012', yval: 227.73 },
        { x: 8, xval: '2013', yval: 227.29 },
        { x: 9, xval: '2014', yval: 226.87 },
    ];
    public aindData: object[] = [
        { x: 0, xval: '2005', yval: 328.59 },
        { x: 1, xval: '2006', yval: 333.18 },
        { x: 2, xval: '2007', yval: 343.68 },
        { x: 3, xval: '2008', yval: 349.19 },
        { x: 4, xval: '2009', yval: 354.73 },
        { x: 5, xval: '2010', yval: 356.86 },
        { x: 6, xval: '2011', yval: 361.75 },
        { x: 7, xval: '2012', yval: 366.59 },
        { x: 8, xval: '2013', yval: 371.37 },
        { x: 9, xval: '2014', yval: 376.1 },
    ];
    public arusData: object[] = [
        { x: 0, xval: '2005', yval: 8.4 },
        { x: 1, xval: '2006', yval: 8.37 },
        { x: 2, xval: '2007', yval: 8.28 },
        { x: 3, xval: '2008', yval: 8.24 },
        { x: 4, xval: '2009', yval: 8.19 },
        { x: 5, xval: '2010', yval: 8.15 },
        { x: 6, xval: '2011', yval: 8.11 },
        { x: 7, xval: '2012', yval: 8.34 },
        { x: 8, xval: '2013', yval: 8.33 },
        { x: 9, xval: '2014', yval: 8.33 },
    ];
    public asweData: object[] = [
        { x: 0, xval: '2005', yval: 20.01 },
        { x: 1, xval: '2006', yval: 20.04 },
        { x: 2, xval: '2007', yval: 20.07 },
        { x: 3, xval: '2008', yval: 20.1 },
        { x: 4, xval: '2009', yval: 20.12 },
        { x: 5, xval: '2010', yval: 20.15 },
        { x: 6, xval: '2011', yval: 20.18 },
        { x: 7, xval: '2012', yval: 20.22 },
        { x: 8, xval: '2013', yval: 20.25 },
        { x: 9, xval: '2014', yval: 21.59 },
    ];
    public aukData: object[] = [
        { x: 0, xval: '2005', yval: 246.88 },
        { x: 1, xval: '2006', yval: 247.57 },
        { x: 2, xval: '2007', yval: 248.25 },
        { x: 3, xval: '2008', yval: 248.93 },
        { x: 4, xval: '2009', yval: 250.86 },
        { x: 5, xval: '2010', yval: 255.94 },
        { x: 6, xval: '2011', yval: 257.37 },
        { x: 7, xval: '2012', yval: 258.8 },
        { x: 8, xval: '2013', yval: 260.23 },
        { x: 9, xval: '2014', yval: 261.66 },
    ];
    public ausData: object[] = [
        { x: 0, xval: '2005', yval: 30.71 },
        { x: 1, xval: '2006', yval: 30.99 },
        { x: 2, xval: '2007', yval: 30.65 },
        { x: 3, xval: '2008', yval: 30.92 },
        { x: 4, xval: '2009', yval: 31.26 },
        { x: 5, xval: '2010', yval: 31.57 },
        { x: 6, xval: '2011', yval: 31.88 },
        { x: 7, xval: '2012', yval: 31.94 },
        { x: 8, xval: '2013', yval: 32.23 },
        { x: 9, xval: '2014', yval: 32.45 },
    ];
    public wausData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: -1 },
        { x: 2, xval: '2007', yval: -1 },
        { x: 3, xval: '2008', yval: 1 },
        { x: 4, xval: '2009', yval: -1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: -1 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: -1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wcanData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: -1 },
        { x: 2, xval: '2007', yval: -1 },
        { x: 3, xval: '2008', yval: -1 },
        { x: 4, xval: '2009', yval: -1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: -1 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: -1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wchinaData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: 1 },
        { x: 2, xval: '2007', yval: 1 },
        { x: 3, xval: '2008', yval: 1 },
        { x: 4, xval: '2009', yval: 1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: 0 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: -1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wfraData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: -1 },
        { x: 2, xval: '2007', yval: 1 },
        { x: 3, xval: '2008', yval: -1 },
        { x: 4, xval: '2009', yval: -1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: -1 },
        { x: 7, xval: '2012', yval: 0 },
        { x: 8, xval: '2013', yval: -1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wgerData: object[] = [
        { x: 0, xval: '2005', yval: 0 },
        { x: 1, xval: '2006', yval: 1 },
        { x: 2, xval: '2007', yval: -1 },
        { x: 3, xval: '2008', yval: -1 },
        { x: 4, xval: '2009', yval: -1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: 1 },
        { x: 7, xval: '2012', yval: 1 },
        { x: 8, xval: '2013', yval: 1 },
        { x: 9, xval: '2014', yval: 1 },
    ];
    public windData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: -1 },
        { x: 2, xval: '2007', yval: 1 },
        { x: 3, xval: '2008', yval: -1 },
        { x: 4, xval: '2009', yval: -1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: -1 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: -1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wrusData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: 0 },
        { x: 2, xval: '2007', yval: -1 },
        { x: 3, xval: '2008', yval: 1 },
        { x: 4, xval: '2009', yval: 0 },
        { x: 5, xval: '2010', yval: 0 },
        { x: 6, xval: '2011', yval: 0 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: 1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wsweData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: -1 },
        { x: 2, xval: '2007', yval: 0 },
        { x: 3, xval: '2008', yval: 0 },
        { x: 4, xval: '2009', yval: 0 },
        { x: 5, xval: '2010', yval: 0 },
        { x: 6, xval: '2011', yval: 0 },
        { x: 7, xval: '2012', yval: 1 },
        { x: 8, xval: '2013', yval: 1 },
        { x: 9, xval: '2014', yval: 1 },
    ];
    public wukData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: 0 },
        { x: 2, xval: '2007', yval: 0 },
        { x: 3, xval: '2008', yval: 0 },
        { x: 4, xval: '2009', yval: 0 },
        { x: 5, xval: '2010', yval: 1 },
        { x: 6, xval: '2011', yval: 0 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: 0 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public wusData: object[] = [
        { x: 0, xval: '2005', yval: 1 },
        { x: 1, xval: '2006', yval: -1 },
        { x: 2, xval: '2007', yval: -1 },
        { x: 3, xval: '2008', yval: -1 },
        { x: 4, xval: '2009', yval: 1 },
        { x: 5, xval: '2010', yval: -1 },
        { x: 6, xval: '2011', yval: -1 },
        { x: 7, xval: '2012', yval: -1 },
        { x: 8, xval: '2013', yval: -1 },
        { x: 9, xval: '2014', yval: -1 },
    ];
    public causData: object[] = [
        { x: 0, xval: '2005', yval: 12.26 },
        { x: 1, xval: '2006', yval: 12.14 },
        { x: 2, xval: '2007', yval: 12.02 },
        { x: 3, xval: '2008', yval: 12.55 },
        { x: 4, xval: '2009', yval: 12.47 },
        { x: 5, xval: '2010', yval: 12.39 },
        { x: 6, xval: '2011', yval: 12.33 },
        { x: 7, xval: '2012', yval: 12.28 },
        { x: 8, xval: '2013', yval: 12.23 },
        { x: 9, xval: '2014', yval: 12.19 },
    ];
    public ccanData: object[] = [
        { x: 0, xval: '2005', yval: 10.84 },
        { x: 1, xval: '2006', yval: 10.78 },
        { x: 2, xval: '2007', yval: 10.75 },
        { x: 3, xval: '2008', yval: 10.29 },
        { x: 4, xval: '2009', yval: 10.28 },
        { x: 5, xval: '2010', yval: 10.28 },
        { x: 6, xval: '2011', yval: 10.28 },
        { x: 7, xval: '2012', yval: 10.28 },
        { x: 8, xval: '2013', yval: 10.28 },
        { x: 9, xval: '2014', yval: 10.29 },
    ];
    public cchinaData: object[] = [
        { x: 0, xval: '2005', yval: 13.14 },
        { x: 1, xval: '2006', yval: 13.25 },
        { x: 2, xval: '2007', yval: 13.45 },
        { x: 3, xval: '2008', yval: 13.71 },
        { x: 4, xval: '2009', yval: 14 },
        { x: 5, xval: '2010', yval: 12.17 },
        { x: 6, xval: '2011', yval: 12.29 },
        { x: 7, xval: '2012', yval: 12.31 },
        { x: 8, xval: '2013', yval: 12.25 },
        { x: 9, xval: '2014', yval: 12.17 },
    ];
    public cfraData: object[] = [
        { x: 0, xval: '2005', yval: 12.15 },
        { x: 1, xval: '2006', yval: 11.99 },
        { x: 2, xval: '2007', yval: 12.91 },
        { x: 3, xval: '2008', yval: 12.73 },
        { x: 4, xval: '2009', yval: 12.57 },
        { x: 5, xval: '2010', yval: 12.43 },
        { x: 6, xval: '2011', yval: 12.29 },
        { x: 7, xval: '2012', yval: 12.72 },
        { x: 8, xval: '2013', yval: 12.6 },
        { x: 9, xval: '2014', yval: 12.6 },
    ];
    public cgerData: object[] = [
        { x: 0, xval: '2005', yval: 8.33 },
        { x: 1, xval: '2006', yval: 8.25 },
        { x: 2, xval: '2007', yval: 8.2 },
        { x: 3, xval: '2008', yval: 8.18 },
        { x: 4, xval: '2009', yval: 8.18 },
        { x: 5, xval: '2010', yval: 8.21 },
        { x: 6, xval: '2011', yval: 8.3 },
        { x: 7, xval: '2012', yval: 8.33 },
        { x: 8, xval: '2013', yval: 8.37 },
        { x: 9, xval: '2014', yval: 8.42 },
    ];
    public cindData: object[] = [
        { x: 0, xval: '2005', yval: 22.32 },
        { x: 1, xval: '2006', yval: 22.01 },
        { x: 2, xval: '2007', yval: 22.69 },
        { x: 3, xval: '2008', yval: 22.22 },
        { x: 4, xval: '2009', yval: 21.76 },
        { x: 5, xval: '2010', yval: 21.34 },
        { x: 6, xval: '2011', yval: 20.97 },
        { x: 7, xval: '2012', yval: 20.6 },
        { x: 8, xval: '2013', yval: 20.24 },
        { x: 9, xval: '2014', yval: 19.89 },
    ];
    public crusData: object[] = [
        { x: 0, xval: '2005', yval: 9.8 },
        { x: 1, xval: '2006', yval: 9.95 },
        { x: 2, xval: '2007', yval: 10.92 },
        { x: 3, xval: '2008', yval: 11.03 },
        { x: 4, xval: '2009', yval: 11.1 },
        { x: 5, xval: '2010', yval: 11.11 },
        { x: 6, xval: '2011', yval: 11.05 },
        { x: 7, xval: '2012', yval: 10.94 },
        { x: 8, xval: '2013', yval: 12.11 },
        { x: 9, xval: '2014', yval: 11.87 },
    ];
    public csweData: object[] = [
        { x: 0, xval: '2005', yval: 10.36 },
        { x: 1, xval: '2006', yval: 10.27 },
        { x: 2, xval: '2007', yval: 10.2 },
        { x: 3, xval: '2008', yval: 10.15 },
        { x: 4, xval: '2009', yval: 10.13 },
        { x: 5, xval: '2010', yval: 10.14 },
        { x: 6, xval: '2011', yval: 10.18 },
        { x: 7, xval: '2012', yval: 10.24 },
        { x: 8, xval: '2013', yval: 10.33 },
        { x: 9, xval: '2014', yval: 11.92 },
    ];
    public cukData: object[] = [
        { x: 0, xval: '2005', yval: 10.78 },
        { x: 1, xval: '2006', yval: 10.71 },
        { x: 2, xval: '2007', yval: 10.67 },
        { x: 3, xval: '2008', yval: 10.65 },
        { x: 4, xval: '2009', yval: 10.65 },
        { x: 5, xval: '2010', yval: 12.34 },
        { x: 6, xval: '2011', yval: 12.29 },
        { x: 7, xval: '2012', yval: 12.27 },
        { x: 8, xval: '2013', yval: 12.26 },
        { x: 9, xval: '2014', yval: 12.22 },
    ];
    public cusData: object[] = [
        { x: 0, xval: '2005', yval: 14.14 },
        { x: 1, xval: '2006', yval: 14.14 },
        { x: 2, xval: '2007', yval: 14.16 },
        { x: 3, xval: '2008', yval: 14.18 },
        { x: 4, xval: '2009', yval: 13.82 },
        { x: 5, xval: '2010', yval: 13.83 },
        { x: 6, xval: '2011', yval: 13.83 },
        { x: 7, xval: '2012', yval: 13.68 },
        { x: 8, xval: '2013', yval: 13.66 },
        { x: 9, xval: '2014', yval: 13.42 },
    ];
    public minAus: object = { minY: 12 };
    public minCan: object = { minY: 10.20 };
    public minChi: object = { minY: 12 };
    public minFra: object = { minY: 11.5 };
    public minGer: object = { minY: 8 };
    public minInd: object = { minY: 19 };
    public minRus: object = { minY: 9.5 };
    public minSwe: object = { minY: 10 };
    public minUk: object = { minY: 10 };
    public minUs: object = { minY: 13 };

    // pie chart code begin

    public data: Object[] = [
        { x: "1-7 Days", y: 37 },
        { x: "8-30 Days", y: 22 },
        { x: "30-90 Days", y: 15 },
        { x: "> 90 Days", y: 41 },
    ];

    @ViewChild("pie")
    public pie: AccumulationChartComponent | AccumulationChart;
    palette = [
        "rgba(253,65,60,0.9)",
        "rgba(253,150,60,0.9)",
        "rgba(253,187,44,0.9)",
        "rgb(255,217,134)",
        "rgb(48,52,57)"
    ];
    public pieangle(e: Event): void {
        let angle: string = (document.getElementById(
            "pieangle"
        ) as HTMLInputElement).value;
        this.pie.series[0].startAngle = parseFloat(angle);
        this.pie.series[0].endAngle = parseFloat(angle);
        this.pie.series[0].animation.enable = false;
        document.getElementById("pieangleText").innerHTML = angle;
        this.pie.removeSvg();
        this.pie.refreshSeries();
        this.pie.refreshChart();
    }
    public pieradius(e: Event): void {
        let radius: string = (document.getElementById(
            "pieradius"
        ) as HTMLInputElement).value;
        this.pie.series[0].radius = radius + "%";
        document.getElementById("pieradiusText").innerHTML = (
            parseInt(radius, 10) / 100
        ).toFixed(2);
        this.pie.series[0].animation.enable = false;
        this.pie.removeSvg();
        this.pie.refreshSeries();
        this.pie.refreshChart();
    }
    public pieexploderadius(e: Event): void {
        let radius: string = (document.getElementById(
            "pieexploderadius"
        ) as HTMLInputElement).value;
        this.pie.visibleSeries[0].explodeOffset = radius + "%";
        document.getElementById("pieexploderadiusText").innerHTML = (
            parseInt(radius, 10) / 100
        ).toFixed(2);
        this.pie.series[0].animation.enable = false;
        this.pie.removeSvg();
        this.pie.refreshSeries();
        this.pie.refreshChart();
    }
    public pieexplodeindex(e: Event): void {
        let index: number = +(document.getElementById(
            "pieexplodeindex"
        ) as HTMLInputElement).value;
        this.pie.visibleSeries[0].explodeIndex = index;
        document.getElementById("pieexplodeindexText").innerHTML = index.toString();
        this.pie.series[0].animation.enable = false;
        this.pie.removeSvg();
        this.pie.refreshSeries();
        this.pie.refreshChart();
    }
    public piecenterx(e: Event): void {
        let x: string = (document.getElementById("x") as HTMLInputElement).value;
        this.pie.center.x = x + "%";
        document.getElementById("xvalue").innerHTML = x + "%";
        this.pie.series[0].animation.enable = false;
        this.pie.removeSvg();
        this.pie.refreshSeries();
        this.pie.refreshChart();
    }
    public piecentery(e: Event): void {
        let y: string = (document.getElementById("y") as HTMLInputElement).value;
        this.pie.center.y = y + "%";
        document.getElementById("yvalue").innerHTML = y + "%";
        this.pie.series[0].animation.enable = false;
        this.pie.removeSvg();
        this.pie.refreshSeries();
        this.pie.refreshChart();
    }
    public animation: Object = {
        enable: false
    };

    //Initializing Datalabel
    public dataLabel: Object = {
        visible: false,
        position: "Inside",
        font: { size: "12px", color: "white" },
        template: "<div>${point.x}: ${point.y}</div>"
    };
    // custom code start
    public loadPieChart(args: IAccLoadedEventArgs): void {
        /*
        let selectedTheme: string = location.hash.split("/")[1];
        selectedTheme = selectedTheme ? selectedTheme : "Material";
        args.accumulation.theme = <AccumulationTheme>(
          (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
            /-dark/i,
            "Dark"
          )
        );
        //dark mode
        this.pie.background = "#212121";
        */
    }
    // custom code end
    public center: Object = { x: "50%", y: "50%" };
    public startAngle: number = 0;
    public endAngle: number = 360;
    public explode: boolean = true;
    public enableAnimation: boolean = false;
    public tooltip: Object = {
        enable: true,
        format: "${point.x} : <b>${point.y}%</b>"
    };

    refresh() {
        this.maps.refresh();
    }

    onViolationButtonClick(event){
        this.coppaActive = !this.coppaActive;
        if (!this.coppaActive) {
            this.layers[0].shapeSettings.colorValuePath = 'vppaViolations';
            this.layers[0].tooltipSettings.template = '<div id="template"> <div style="border-radius: 4px; border: 1px #abb9c6; opacity: 90%; background: rgba(53, 63, 76, 0.90); box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40); padding-bottom: 5px; padding-top: 10px; padding-left: 10px; padding-right: 10px;"> <div style="font-size: 13px; color: #ffffff; font-weight: 500;"> <center> ${continent} </center> </div> <hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD"> <div> <span style="font-size: 13px; color: #cccccc">VPPA Violations:</span><span style="font-size: 13px; color: #ffffff; font-weight: 500;">${vppaViolations}%</span> </div> </div> </div>';
        } else {
            this.layers[0].shapeSettings.colorValuePath = 'coppaViolations';
            this.layers[0].tooltipSettings.template = '<div id="template"> <div style="border-radius: 4px; border: 1px #abb9c6; opacity: 90%; background: rgba(53, 63, 76, 0.90); box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40); padding-bottom: 5px; padding-top: 10px; padding-left: 10px; padding-right: 10px;"> <div style="font-size: 13px; color: #ffffff; font-weight: 500;"> <center> ${continent} </center> </div> <hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD"> <div> <span style="font-size: 13px; color: #cccccc">COPPA Violations:</span><span style="font-size: 13px; color: #ffffff; font-weight: 500;">${coppaViolations}%</span> </div> </div> </div>';
        }
        this.maps.layers = this.layers;
        this.refresh();

    }

    refreshGraphs(event){
        setTimeout(() => {        
        $('.sparklinebasic').sparkline('html', {
            highlightSpotColor: 'black',
            highlightLineColor: 'black',
            fillColor: false,
            spotColor: false,
            minSpotColor: false,
            maxSpotColor: false,
            lineWidth: 1,
            enableTagOptions: true
        });      
        $('.sparkpie').sparkline('html', { type: 'pie', height: '2.0em' });

        }, 0);        
    }

    // public title: string = "Mobile Browser Statistics";
}
