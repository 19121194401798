export const FBDetailsTable = {
    "fbp": {
        "category": "General Information",
        "param": "fbp",
        "apiName": "fbp",
        "name": "Facebook Browser ID ",
        "explanation": "A unique identifier that Facebook assigns to each browser that visits a website with the Facebook Pixel installed."
    },
    "fbc": {
        "category": "General Information",
        "param": "fbc",
        "apiName": "fbc",
        "name": "Facebook Click ID",
        "explanation": "Captures the click ID from a Facebook-served ad. This parameter is used to track which advertisement the user clicked on to visit the website."
    },
    "ev": {
        "category": "General Information",
        "param": "ev",
        "apiName": "event_name",
        "name": "Event Name",
        "explanation": `Name of the event being tracked, such as "PageView" or "Purchase".`
    },
    "dl": {
        "category": "General Information",
        "param": "dl",
        "apiName": "event_source_url",
        "name": "Event Source URL",
        "explanation": "The URL of the page where the event is happening."
    },
    "id": {
        "category": "General Information",
        "param": "id",
        "apiName": "id",
        "name": "Pixel ID",
        "explanation": "Unique identifier for the Facebook Pixel."
    },
    "ts": {
        "category": "General Information",
        "param": "event_time",
        "apiName": "Event Time",
        "name": "Timestamp when the event occurred.",
    },
    "eid": {
        "category": "General Information",
        "param": "event_id",
        "apiName": "Event ID",
        "name": "Event ID",
        "explanation": "A unique identifier for the event, typically used to differentiate events in a session."
    },
    "r": {
        "category": "General Information",
        "param": "r",
        "apiName": "request_type",
        "name": "Request Type",
        "explanation": "Describes the type of request or the stability of the version being used (e.g., 'stable', 'beta'). "
    },
    "v": {
        "category": "General Information",
        "param": "v",
        "apiName": "pixel_version",
        "name": "Pixel Version",
        "explanation": "Indicates the version of the Facebook pixel code that is being used, ensuring the use of current features and fixes."
    },
    "dpo": {
        "category": "General Information",
        "param": "dpo",
        "apiName": "data_processing_options",
        "name": "Data Processing Options",
        "explanation": "Specifies the data processing options for the event, usually related to how data should be handled according to regional regulations."
    },
    "dpoco": {
        "category": "General Information",
        "param": "dpoco",
        "apiName": "data_processing_options_country",
        "name": "Data Processing Options Country",
        "explanation": "The country code for which specific data processing options should be applied."
    },
    "dpost": {
        "category": "General Information",
        "param": "dpost",
        "apiName": "data_processing_options_state",
        "name": "Data Processing Options State",
        "explanation": "The state code for which specific data processing options should be applied."
    },
    "exp": {
        "category": "General Information",
        "param": "exp",
        "apiName": "experiment",
        "name": "Experiment",
        "explanation": "Identifies an experiment variant or code, used to test different versions of pages or features in A/B testing scenarios."
    },
    "ct": {
        "category": "User Information",
        "param": "ct",
        "apiName": "city",
        "name": "City",
        "explanation": "City from user intent or location data."
    },
    "country": {
        "category": "User Information",
        "param": "country",
        "apiName": "country",
        "name": "Country",
        "explanation": "Country from user intent or location data."
    },
    "postal_code": {
        "category": "User Information",
        "param": "postal_code",
        "apiName": "postal_code",
        "name": "Postal Code",
        "explanation": "Postal code associated with the user or event location."
    },
    "region": {
        "category": "User Information",
        "param": "region",
        "apiName": "region",
        "name": "Region",
        "explanation": "State, district, or region of interest, often used in localized advertising and services."
    },
    "coo": {
        "category": "User Information",
        "param": "cookies_enabled",
        "apiName": "cookies_enabled",
        "name": "Cookies Enabled",
        "explanation": "Indicates whether cookies are enabled in the user's browser."
    },
    "cs_cc": {
        "category": "User Information",
        "param": "cookie_consent",
        "apiName": "cookie_consent",
        "name": "Cookie Consent",
        "explanation": "Tracks if the user has consented to cookie usage, often a binary value (0 for no, 1 for yes)."
    },
    "udff[em]": {
        "category": "User Information",
        "param": "udff[em]",
        "apiName": "email",
        "name": "Email",
        "explanation": "User's email address."
    },
    "udff[fn]": {
        "category": "User Information",
        "param": "udff[fn]",
        "apiName": "first_name",
        "name": "User First Name",
        "explanation": "User's first name."
    },
    "udff[ln]": {
        "category": "User Information",
        "param": "udff[ln]",
        "apiName": "last_name",
        "name": "User Last Name",
        "explanation": "User's last name."
    },
    "udff[zp]": {
        "category": "User Information",
        "param": "udff[zp]",
        "apiName": "zip_code",
        "name": "User Zip Code",
        "explanation": "User's zip code."
    },
    "udff[ct]": {
        "category": "User Information",
        "param": "udff[ct]",
        "apiName": "country",
        "name": "User Country",
        "explanation": "User's Country."
    },
    "udff[ph]": {
        "category": "User Information",
        "param": "udff[ph]",
        "apiName": "phone",
        "name": "User Phone",
        "explanation": "User's Phone."
    },
    "sw": {
        "category": "Device Information",
        "param": "sw",
        "apiName": "screen_width",
        "name": "Screen Width",
        "explanation": "Width of the user's screen in pixels."
    },
    "sh": {
        "category": "Device Information",
        "param": "sh",
        "apiName": "screen_height",
        "name": "Screen Height",
        "explanation": "Height of the user's screen in pixels."
    },
    "if": {
        "category": "Device Information",
        "param": "if",
        "apiName": "in_iframe",
        "name": "In Iframe",
        "explanation": "Indicates whether the tracking script is running inside an iframe (true/false)."
    },
    "currency": {
        "category": "E-Commerce & Transactions",
        "param": "currency",
        "apiName": "currency",
        "name": "Currency",
        "explanation": "Currency used in a transaction, adhering to ISO 4217 standards."
    },
    "value": {
        "category": "E-Commerce & Transactions",
        "param": "value",
        "apiName": "value",
        "name": "Value",
        "explanation": "The value associated with the event (e.g., monetary value in a purchase)."
    },
    "order_id": {
        "category": "E-Commerce & Transactions",
        "param": "order_id",
        "apiName": "order_id",
        "name": "Order ID",
        "explanation": "Unique identifier for a transaction or order."
    },
    "cd[content_type]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[content_type]",
        "apiName": "content_type",
        "name": "Content Type",
        "explanation": "Specifies if the content is a single product or a group of products (product or product_group)."
    },
    "cd[content_ids]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[content_ids]",
        "apiName": "content_ids",
        "name": "Content IDs",
        "explanation": "IDs associated with the content, such as product SKUs for items in an AddToCart event."
    },
    "cd[contents]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[contents]",
        "apiName": "contents",
        "name": "Contents",
        "explanation": "List of JSON objects containing product IDs and additional information such as quantity and price, associated with the event."
    },
    "cd[content_name]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[content_name]",
        "apiName": "content_name",
        "name": "Content Name",
        "explanation": "Name of the page or product associated with the event."
    },
    "cd[price]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[price]",
        "apiName": "price",
        "name": "Price",
        "explanation": "Cost and currency of the item, formatted with the cost followed by the ISO currency code."
    },
    "cd[predicted_ltv]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[predicted_ltv]",
        "apiName": "predicted_ltv",
        "name": "Predicted Lifetime Value",
        "explanation": "Predicted lifetime value of a customer based on the conversion event, important for customer value analysis."
    },
    "cd[fb_content]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[fb_content]",
        "apiName": "fb_content",
        "name": "FB Content",
        "explanation": "A list of JSON objects that contains the International Article Number (EAN) when applicable, or other product or content identifiers as well as quantities and prices of the products."
    },
    "cd[fb_content_id]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[fb_content_id]",
        "apiName": "fb_content_id",
        "name": "FB Content ID",
        "explanation": `International Article Number (EAN) when applicable, or other product or content identifiers. For multiple product ids: e.g. "["1234","5678"]".`
    },
    "cd[fb_description]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[fb_description]",
        "apiName": "fb_description",
        "name": "FB Description",
        "explanation": "A string description, often used in advertising to describe the content or product."
    },
    "cd[fb_payment_info_available]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[fb_payment_info_available]",
        "apiName": "fb_payment_info_available",
        "name": "FB Payment Info Available",
        "explanation": "1 for yes, 0 for no, indicates if payment information is available within the context of the event."
    },
    "cd[fb_registration_method]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[fb_registration_method]",
        "apiName": "fb_registration_method",
        "name": "FB Registration Method",
        "explanation": "Method of registration used, such as Facebook, Email, Twitter, etc."
    },
    "cd[fb_success]": {
        "category": "E-Commerce & Transactions",
        "param": "cd[fb_success]",
        "apiName": "fb_success",
        "name": "FB Success",
        "explanation": "1 for yes, 0 for no, indicates if the event was successful."
    },
    "cd[checkin_date]": {
        "category": "Travel and Hospitality",
        "param": "cd[checkin_date]",
        "apiName": "checkin_date",
        "name": "Check-in Date",
        "explanation": "Date when the user plans to check into a hotel, in various acceptable date formats."
    },
    "cd[returning_arrival_date]": {
        "category": "Travel and Hospitality",
        "param": "cd[returning_arrival_date]",
        "apiName": "returning_arrival_date",
        "name": "Returning Arrival Date",
        "explanation": "Date and time when the return journey concludes."
    },
    "cd[returning_departure_date]": {
        "category": "Travel and Hospitality",
        "param": "cd[returning_departure_date]",
        "apiName": "returning_departure_date",
        "name": "Returning Departure Date",
        "explanation": "Date and time for the start of the return journey."
    },
    "cd[travel_class]": {
        "category": "Travel and Hospitality",
        "param": "cd[travel_class]",
        "apiName": "travel_class",
        "name": "Travel Class",
        "explanation": "Class of travel for a journey, such as economy, premium, business, or first."
    },
    "cd[travel_end]": {
        "category": "Travel and Hospitality",
        "param": "cd[travel_end]",
        "apiName": "travel_end",
        "name": "Travel End Date",
        "explanation": "End date for a travel period."
    },
    "cd[travel_start]": {
        "category": "Travel and Hospitality",
        "param": "cd[travel_start]",
        "apiName": "travel_start",
        "name": "Travel Start Date",
        "explanation": "Start date for a travel period."
    },
    "cd[destination_airport]": {
        "category": "Travel and Hospitality",
        "param": "cd[destination_airport]",
        "apiName": "destination_airport",
        "name": "Destination Airport",
        "explanation": "Official IATA code of the destination airport."
    },
    "cd[origin_airport]": {
        "category": "Travel and Hospitality",
        "param": "cd[origin_airport]",
        "apiName": "origin_airport",
        "name": "Origin Airport",
        "explanation": "Official IATA code of the origin airport."
    },
    "cd[num_adults]": {
        "category": "Travel and Hospitality",
        "param": "cd[num_adults]",
        "apiName": "num_adults",
        "name": "Number of Adults",
        "explanation": "Number of adults that will be staying at a location, used in hotel bookings."
    },
    "cd[num_children]": {
        "category": "Travel and Hospitality",
        "param": "cd[num_children]",
        "apiName": "num_children",
        "name": "Number of Children",
        "explanation": "Number of children that will be staying at a location, used in hotel bookings."
    },
    "cd[num_infants]": {
        "category": "Travel and Hospitality",
        "param": "cd[num_infants]",
        "apiName": "num_infants",
        "name": "Number of Infants ",
        "explanation": "Number of infants that will be staying at a location, used in hotel bookings."
    },
    "cd[preferred_num_stops]": {
        "category": "Travel and Hospitality",
        "param": "cd[preferred_num_stops]",
        "apiName": "preferred_num_stops",
        "name": "Preferred Number of Stops",
        "explanation": "Preferred number of stops for travel itineraries."
    },
    "cd[preferred_star_ratings]": {
        "category": "Travel and Hospitality",
        "param": "cd[preferred_star_ratings]",
        "apiName": "preferred_star_ratings",
        "name": "Preferred Star Ratings",
        "explanation": "Range of hotel star ratings a user is filtering for, usually a tuple indicating minimum and maximum ratings."
    },
    "cd[suggested_destinations]": {
        "category": "Travel and Hospitality",
        "param": "cd[suggested_destinations]",
        "apiName": "suggested_destinations",
        "name": "Suggested Destinations",
        "explanation": "List of suggested destinations, used in travel and tourism-related marketing."
    },
    "cd[suggested_hotels]": {
        "category": "Travel and Hospitality",
        "param": "cd[suggested_hotels]",
        "apiName": "suggested_hotels",
        "name": "Suggested Hotels",
        "explanation": "List of suggested hotels, used in travel and tourism-related marketing."
    },
    "cd[lease_end_date]": {
        "category": "Real Estate",
        "param": "cd[lease_end_date]",
        "apiName": "lease_end_date",
        "name": "Lease End Date",
        "explanation": "End date of a lease, specified in ISO 8601 format."
    },
    "cd[lease_start_date]": {
        "category": "Real Estate",
        "param": "cd[lease_start_date]",
        "apiName": "lease_start_date",
        "name": "Lease Start Date",
        "explanation": "Start date of a lease, enhances property recommendations and improves user experience on landing pages."
    },
    "cd[listing_type]": {
        "category": "Real Estate",
        "param": "cd[listing_type]",
        "apiName": "listing_type",
        "name": "Listing Type",
        "explanation": "Type of property listing, including for rent by agent, for rent by owner, for sale by agent, for sale by owner, foreclosed, new construction, or new listing."
    },
    "cd[preferred_baths_range]": {
        "category": "Real Estate",
        "param": "cd[preferred_baths_range]",
        "apiName": "preferred_baths_range",
        "name": "Preferred Baths Range",
        "explanation": "Range of preferred number of bathrooms, used in property searches."
    },
    "cd[preferred_beds_range]": {
        "category": "Real Estate",
        "param": "cd[preferred_beds_range]",
        "apiName": "preferred_beds_range",
        "name": "Preferred Beds Range",
        "explanation": "Range of preferred number of bedrooms, used in property searches."
    },
    "cd[preferred_neighborhoods]": {
        "category": "Real Estate",
        "param": "cd[preferred_neighborhoods]",
        "apiName": "preferred_neighborhoods",
        "name": "Preferred Neighborhoods",
        "explanation": "List of preferred neighborhoods, used in property and local services searches."
    },
    "cd[preferred_price_range]": {
        "category": "Real Estate",
        "param": "cd[preferred_price_range]",
        "apiName": "preferred_price_range",
        "name": "Preferred Price Range",
        "explanation": "Preferred price range for purchasing a vehicle or property, can include min/max values."
    },
    "cd[region]": {
        "category": "Real Estate",
        "param": "cd[region]",
        "apiName": "region",
        "name": "Region",
        "explanation": "State, district, or region of interest, often used in localized advertising and services."
    },
    "cd[suggested_home_listings]": {
        "category": "Real Estate",
        "param": "cd[suggested_home_listings]",
        "apiName": "suggested_home_listings",
        "name": "Suggested Home Listings",
        "explanation": "List of suggested home listings, used in real estate marketing."
    },
    "cd[suggested_location_based_items]": {
        "category": "Real Estate",
        "param": "cd[suggested_location_based_items]",
        "apiName": "suggested_location_based_items",
        "name": "Suggested Location Based Items",
        "explanation": "List of suggested items based on user's location, used in location-targeted advertising."
    },
    "cd[body_style]": {
        "category": "Automotive",
        "param": "cd[body_style]",
        "apiName": "body_style",
        "name": "Body Style",
        "explanation": "Describes the body style of a vehicle, including options like CONVERTIBLE, COUPE, HATCHBACK, MINIVAN, TRUCK, SUV, SEDAN, VAN, WAGON, CROSSOVER, OTHER."
    },
    "cd[condition_of_vehicle]": {
        "category": "Automotive",
        "param": "cd[condition_of_vehicle]",
        "apiName": "condition_of_vehicle",
        "name": "Condition of Vehicle",
        "explanation": "Specifies the condition of the vehicle."
    },
    "cd[exterior_color]": {
        "category": "Automotive",
        "param": "cd[exterior_color]",
        "apiName": "exterior_color",
        "name": "Exterior Color",
        "explanation": "Color of the vehicle's exterior."
    },
    "cd[fuel_type]": {
        "category": "Automotive",
        "param": "cd[fuel_type]",
        "apiName": "fuel_type",
        "name": "Fuel Type",
        "explanation": "Type of fuel used by the vehicle, options include DIESEL, ELECTRIC, FLEX, GASOLINE, HYBRID, PETROL, PLUGIN_HYBRID, OTHER, NONE."
    },
    "cd[interior_color]": {
        "category": "Automotive",
        "param": "cd[interior_color]",
        "apiName": "interior_color",
        "name": "Interior Color",
        "explanation": "Color of the vehicle's interior."
    },
    "cd[make]": {
        "category": "Automotive",
        "param": "cd[make]",
        "apiName": "make",
        "name": "Make",
        "explanation": "Brand of the vehicle."
    },
    "cd[mileage.unit]": {
        "category": "Automotive",
        "param": "cd[mileage.unit]",
        "apiName": "mileage.unit",
        "name": "Mileage Unit",
        "explanation": "Unit of measure for the vehicle's mileage."
    },
    "cd[mileage.value]": {
        "category": "Automotive",
        "param": "cd[mileage.value]",
        "apiName": "mileage.value",
        "name": "Mileage",
        "explanation": "Value of the vehicle's mileage."
    },
    "cd[model]": {
        "category": "Automotive",
        "param": "cd[model]",
        "apiName": "model",
        "name": "Model",
        "explanation": "Model of the vehicle."
    },
    "cd[state_of_vehicle]": {
        "category": "Automotive",
        "param": "cd[state_of_vehicle]",
        "apiName": "state_of_vehicle",
        "name": "State of Vehicle",
        "explanation": "Current state or condition of the vehicle."
    },
    "cd[status]": {
        "category": "Automotive",
        "param": "cd[status]",
        "apiName": "status",
        "name": "Status",
        "explanation": "Current status, used in various contexts like order status, property availability, etc."
    },
    "cd[suggested_vehicles]": {
        "category": "Automotive",
        "param": "cd[suggested_vehicles]",
        "apiName": "suggested_vehicles",
        "name": "Suggested Vehicles",
        "explanation": "List of suggested vehicles, used in automotive sales and marketing."
    },
    "cd[transmission]": {
        "category": "Automotive",
        "param": "cd[transmission]",
        "apiName": "transmission",
        "name": "Transmission",
        "explanation": "Type of transmission in the vehicle: AUTOMATIC, MANUAL, OTHER, NONE."
    },
    "cd[trim]": {
        "category": "Automotive",
        "param": "cd[trim]",
        "apiName": "trim",
        "name": "Trim",
        "explanation": "Trim level or specification of a vehicle, often indicating variations within a model."
    },
    "cd[vin]": {
        "category": "Automotive",
        "param": "cd[vin]",
        "apiName": "vin",
        "name": "VIN",
        "explanation": "Vehicle Identification Number, unique identifier for vehicles."
    },
    "cd[year]": {
        "category": "Automotive",
        "param": "cd[year]",
        "apiName": "year",
        "name": "Year",
        "explanation": "Year the vehicle was manufactured or launched, in yyyy format."
    },
    "cd[suggested_jobs]": {
        "category": "Jobs and Local",
        "param": "cd[suggested_jobs]",
        "apiName": "suggested_jobs",
        "name": "Suggested Jobs",
        "explanation": "List of suggested jobs, used in employment-related platforms and services."
    },
    "cd[suggested_local_service_businesses]": {
        "category": "Jobs and Local",
        "param": "cd[suggested_local_service_businesses]",
        "apiName": "suggested_local_service_businesses",
        "name": "Suggested Local Service Businesses",
        "explanation": "List of suggested local service businesses, used in employment-related platforms and services."
    },
    "cd[user_bucket]": {
        "category": "Miscellaneous",
        "param": "cd[user_bucket]",
        "apiName": "user_bucket",
        "name": "User Bucket",
        "explanation": "Categorization of a user within a specific bucket for segmentation or testing purposes."
    },
    "cd[item_number]": {
        "category": "Miscellaneous",
        "param": "cd[item_number]",
        "apiName": "item_number",
        "name": "Item Number",
        "explanation": "Unique identifier to distinguish events within the same order or transaction."
    },
    "cd[ad_type]": {
        "category": "Miscellaneous",
        "param": "cd[ad_type]",
        "apiName": "ad_type",
        "name": "Ad Type",
        "explanation": "Type of advertisement."
    },
    // "cd[fb_description]": {
    //     "category": "Miscellaneous",
    //     "param": "cd[fb_description]",
    //     "apiName": "fb_description",
    //     "name": "Facebook Description",
    //     "explanation": "A string description, often used in advertising to describe the content or product."
    // },
    "cd[fb_level]": {
        "category": "Miscellaneous",
        "param": "cd[fb_level]",
        "apiName": "fb_level",
        "name": "Facebook Level",
        "explanation": "Level of a game or similar application, used in gaming-related events."
    },
    "cd[fb_max_rating_value]": {
        "category": "Miscellaneous",
        "param": "cd[fb_max_rating_value]",
        "apiName": "fb_max_rating_value",
        "name": "Facebook Max Rating Value",
        "explanation": "Upper bounds of a rating scale, for example, 5 on a 5-star scale."
    },
    // "cd[fb_registration_method]": {
    //     "category": "Miscellaneous",
    //     "param": "cd[fb_registration_method]",
    //     "apiName": "fb_registration_method",
    //     "name": "Facebook Registration Method",
    //     "explanation": "Method of registration used, such as Facebook, Email, Twitter, etc."
    // },
    // "cd[fb_success]": {
    //     "category": "Miscellaneous",
    //     "param": "cd[fb_success]",
    //     "apiName": "fb_success",
    //     "name": "Facebook Success",
    //     "explanation": "1 for yes, 0 for no, indicates if the event was successful."
    // },
    "cas": {
        "category": "Miscellaneous",
        "param": "cas",
        "apiName": "code_and_search ",
        "name": "Code and Search",
        "explanation": "A parameter often used for passing codes or search IDs in the URL for tracking or identification purposes."
    },
    "hrl": {
        "category": "Miscellaneous",
        "param": "hrl",
        "apiName": "hashed_request_length",
        "name": "Hashed Request Length",
        "explanation": "Hashed value of the request length, possibly for security or verification purposes."
    },
    "ler": {
        "category": "Miscellaneous",
        "param": "ler",
        "apiName": "last_event_response",
        "name": "Last Event Response",
        "explanation": "Indicates the response or result of the last event processed before the current one, often used for debugging or tracking chains of events."
    },
    "pm": {
        "category": "Miscellaneous",
        "param": "pm",
        "apiName": "page_multiple",
        "name": "Page Multiple",
        "explanation": "Identifies if the event corresponds to a single or multiple page views, or interactions within a single page load."
    },
    "redirect": {
        "category": "Miscellaneous",
        "param": "redirect",
        "apiName": "redirect_flag",
        "name": "Redirect Flag",
        "explanation": "Indicates whether the event should trigger a redirect after processing (0 for no, 1 for yes)."
    },
    "rl": {
        "category": "Miscellaneous",
        "param": "rl",
        "apiName": "referrer_location",
        "name": "Referrer Location",
        "explanation": "URL of the referrer or the previous page from which the current page was accessed."
    },
    // "exp": {
    //     "category": "Miscellaneous",
    //     "param": "exp",
    //     "apiName": "experiment",
    //     "name": "Experiment",
    //     "explanation": "Identifies an experiment variant or code, used to test different versions of pages or features in A/B testing scenarios."
    // },
    "ec": {
        "category": "Miscellaneous",
        "param": "ec",
        "apiName": "event_count",
        "name": "Event Count",
        "explanation": "Tracks the number of events that have been fired in the session, reflecting user engagement."
    }
};
