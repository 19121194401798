import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { environment as env } from "../../../../../environments/environment";
import { VendorSummaryData } from '../../dashboardDS';

@Component({
  selector: 'vendor-summary-panel',
  templateUrl: './vendor-summary-panel.component.html',
  styleUrls: ['./vendor-summary-panel.component.css'],
})
export class VendorSummaryPanelComponent implements OnInit,OnChanges {
  public cdnUrl: string;
  @Input ('data') inputData: VendorSummaryData[] = [];

  public otherMetricsList: any = [];
  public percentRegex = /\.?0+$/;
  public vendorList = [];
  public isLoaded:boolean;

  public data: any = [];
  constructor(
    private cd: ChangeDetectorRef
  ) {
    // this.data = this.inputData || [];
    this.isLoaded = false;
    
  }

  colorDefine(value:number){
    if(value < 10){
      return 'green'
    }else if(value < 50){
      return 'orange'
    }else{
      return 'red'
    }
  }

  ngOnInit(): void {
    this.cdnUrl = env.cdn.url;
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.data = this.inputData;
    this.isLoaded = true;
  }
}
