<div class="row" style="height: 500px;">
    <div class="col-sm-4">
      <div class="card cardx card-table card-border be-loading" [class.be-loading-active]="!history" style="height: 500px;">
        <div class="card-header">Change History
          <span class="card-subtitle" container="body" [ngbPopover]="fileName" triggers="mouseenter:mouseleave">{{fileName | truncate:70 : "..."}}&nbsp;<span ngxClipboard (cbOnSuccess)="onCopied($event)" [cbContent]="fileName" class="icon zmdi zmdi-copy" style="cursor: pointer;"></span></span>
        </div>
        <div class="card-body">
          <div class="table-responsive noSwipe">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th style="width:5%;">Code</th>
                  <th style="width:5%;">Diff</th>
                  <th>Hash</th>
                  <th>Date</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody *ngIf="historyDataError == null; else missingDataMessage">
                <ng-container *ngFor="let item of history; let i = index">
                <tr>
                  <td>                                      
                      <input type="radio" name="radio" [(ngModel)]="radioHash" [value]="i" (change)="radioLog(item, '');">
                  </td>
                  <td>
                    <label class="custom-control custom-control-sm custom-checkbox">
                      <span class="diffLabel">{{ item.diffLabel }}</span>
                      <input type="checkbox" disabled="{{!item.enabled}}" class="custom-control-input" [(ngModel)]="item.selected" (change)="onHistorySelected(item);" ><span class="custom-control-label"></span>
                    </label>
                  </td>                
                  <td class="cell-detail">
                    <span *ngIf="authService.userHasScopes(['read:developer'])" ngxClipboard (cbOnSuccess)="onCopiedHash($event)" [cbContent]="item.currenthash" style="cursor: pointer;">
                      <span>{{item.currenthash | truncate: 10}}</span>
                    </span>
                    <span class="cell-detail-description"></span></td>
                  <td class="cell-detail"><span>{{item.lastcheckedFormatted}}</span><span class="cell-detail-description"></span></td>
                  <td>
                      <a style="cursor: pointer;" class="nav-link" (click)="downloadFile(item.currenthash);"><span class="icon zmdi zmdi-download"></span></a>
                  </td>
                </tr>
                </ng-container>                        
              </tbody>
              <ng-template #missingDataMessage>
                <tbody>
                  <tr><td colspan="5" class="noDataMessage">{{historyDataError}}</td></tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
        <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
            </svg>
        </div>
      </div>  
    </div>    
    <div class="col-sm-8">
      <div class="card cardx card-table card-border be-loading" [class.be-loading-active]="analysisLoading || codeLoading" style="height: 500px;">
          <div class="card-header">Info & Analysis</div>
          <div class="card-body">
            <!-- <textarea id="ast">{{astText}}</textarea> -->
            <div class="col-lg-12">
              <div class="tab-container">
                <ul id="analysisTabs" class="nav nav-tabs">
                  <li class="nav-item"><a id="overview" href="#overview" data-toggle="tab" role="tab" class="nav-link" [class.active]="true" (click)="onAnalysisTabClick($event);"><span class="icon zmdi zmdi-code-setting"></span>Overview<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Basic information about the code file" triggers="mouseenter:mouseleave"></span></a></li>
                  <li class="nav-item"><a id="pages" href="#pages" data-toggle="tab" role="tab" class="nav-link" (click)="onAnalysisTabClick($event);"><span class="icon zmdi zmdi-collection-text"></span>Pages<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Pages on which this code file was identified." triggers="mouseenter:mouseleave"></span></a></li>
                  <li class="nav-item"><a id="functions" href="#functions" data-toggle="tab" role="tab" class="nav-link" (click)="onAnalysisTabClick($event);"><span class="icon zmdi zmdi-functions"></span>Functions<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="All of the unique functions names contained in the code file (not preserving scope.)" triggers="mouseenter:mouseleave"></span></a></li>                
                  <li class="nav-item"><a id="vars" href="#vars" data-toggle="tab" role="tab" class="nav-link"(click)="onAnalysisTabClick($event);"><span class="icon zmdi zmdi-view-list-alt"></span>Variables<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="All of the unique variable names contained in the code file" triggers="mouseenter:mouseleave"></span></a></li>
                  <li class="nav-item"><a id="literals" href="#literals" data-toggle="tab" role="tab" class="nav-link"(click)="onAnalysisTabClick($event);"><span class="icon zmdi zmdi-view-list-alt"></span>Literals<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="All of the literals contained in the code file" triggers="mouseenter:mouseleave"></span></a></li>
                </ul>      
              </div>
              <div class="tab-content">
                <div id="overview" role="tabpanel" class="tab-pane" [class.active]="activeAnalysisTab == 'overview'">
                  <h4>File size: {{codeFileContent ? codeFileContent.length : "Loading..."}}</h4>
                  <h4>LOC: {{numLines ? numLines : "Loading..."}} </h4>
                  <h4>Number of Functions: {{codeFileFunctions ? codeFileFunctions.length : "Loading..."}}</h4>
                  <h4>Analysis Features: {{analysisFeatures ? analysisFeatures : ""}}</h4>                  
                </div>
                <div id="pages" role="tabpanel" class="tab-pane" [class.active]="activeAnalysisTab == 'pages'" >
                  <ejs-grid #pagesGrid 
                    [dataSource]="pages"
                    locale='en-US' 
                    [allowPaging]="true" 
                    [pageSettings]="pageSettings" 
                    [allowSorting]="true" 
                    [sortSettings]="pagesSortSettings"
                    [allowSelection]="false"
                    [enableHover]="false"
                    [toolbar]="toolbar"
                    [allowResizing]="true"
                    >
                    <e-columns>
                      <e-column field='link' headerText='Page' width='70%'>
                        <ng-template #template let-data>
                          <a [routerLink] = "['/pages', data.linkId]">{{data.link}}</a>
                        </ng-template>
                      </e-column>
                      <e-column field='firstDetected' headerText='First Detected' type='datetime'>
                        <ng-template #template let-data>{{ timezoneService.format(data.firstDetected) }}</ng-template>
                      </e-column>
                      <e-column field='lastDetected' headerText='Last Detected' type='datetime'>
                        <ng-template #template let-data>{{ timezoneService.format(data.lastDetected) }}</ng-template>
                      </e-column>
                    </e-columns>
                  </ejs-grid>                
                </div>            
                <div id="functions" role="tabpanel" class="tab-pane" [class.active]="activeAnalysisTab == 'functions'">
                  <ejs-grid #functionsGrid 
                    [dataSource]="codeFileFunctions"
                    locale='en-US' 
                    [allowPaging]="true" 
                    [pageSettings]="pageSettings" 
                    [allowSorting]="true" 
                    [sortSettings]="functionsSortSettings"
                    [allowSelection]="false"
                    [enableHover]="false"
                    [toolbar]="toolbar"
                    [allowResizing]="true">
                    <e-columns>
                      <e-column field='name' headerText='Name'></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
                <div id="vars" role="tabpanel" class="tab-pane" [class.active]="activeAnalysisTab == 'vars'">
                  <ejs-grid #varsGrid 
                    [dataSource]="codeFileVars"
                    locale='en-US' 
                    [allowPaging]="true" 
                    [pageSettings]="pageSettings" 
                    [allowSorting]="true" 
                    [sortSettings]="functionsSortSettings"
                    [allowSelection]="false"
                    [enableHover]="false"
                    [toolbar]="toolbar"
                    [allowResizing]="true">
                    <e-columns>
                      <e-column field='name' headerText='Name'></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
                <div id="literals" role="tabpanel" class="tab-pane" [class.active]="activeAnalysisTab == 'literals'">
                  <ejs-grid #varsGrid 
                  [dataSource]="codeFileLiterals"
                  locale='en-US' 
                  [allowPaging]="true" 
                  [pageSettings]="pageSettings" 
                  [allowSorting]="true" 
                  [sortSettings]="functionsSortSettings"
                  [allowSelection]="false"
                  [enableHover]="false"
                  [toolbar]="toolbar"
                  [allowResizing]="true">
                    <e-columns>
                      <e-column field='name' headerText='Name'></e-column>
                    </e-columns>
                  </ejs-grid>                
                </div>
              </div>
            </div>              
          </div>
          <div class="be-spinner" style="height: 100%">
            <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
            </svg>
          </div>
      </div>    
    </div>
  </div>
  <div class="page-head-title">
      <h2>Code</h2>
  </div>
  
  <div class="row code-section">
    <div class="col-lg-12 be-loading" [class.be-loading-active]="codeLoading">
      <div class="tab-container">
        <ul id="codeTabs" class="nav nav-tabs">
          <li class="nav-item"><a id="beaut" href="#beaut" data-toggle="tab" role="tab" class="nav-link" [class.disabled]="!singleFileView" [class.active]="isBeautificationTabActive" (click)="onTabClick($event);"><span class="icon zmdi zmdi-format-align-left"></span>Beautified<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Code that has been formatted to make it easier to read" triggers="mouseenter:mouseleave"></span></a></li>
          <li class="nav-item"><a id="raw" href="#raw" data-toggle="tab" role="tab" class="nav-link" [class.active]="isRawTabActive" [class.disabled]="!singleFileView" (click)="onTabClick($event);"><span class="icon zmdi zmdi-language-javascript"></span>Raw<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Raw code file without any modifications or formatting changes" triggers="mouseenter:mouseleave"></span></a></li>          
          <li class="nav-item"><a id="analysis" href="#analysis" data-toggle="tab" role="tab" class="nav-link" [class.active]="isAnalysisTabActive" [class.disabled]="!singleFileView" (click)="onTabClick($event);"><span class="icon zmdi zmdi-comment-alert"></span>Analysis<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Show the code analysis performed by Vault JS on a beautified version of the code. Highlighted sections are color coded to match the area of concern that has been detected." triggers="mouseenter:mouseleave"></span></a></li>
          <li class="nav-item"><a id="diff" href="#diff" data-toggle="tab" role="tab" [class.active]="isDiffTabActive" class="nav-link" [class.disabled]="!singleFileView" (click)="onDiffTabClick($event);"><span class="icon zmdi zmdi-swap"></span>Diff<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="View diff on the selected versions" triggers="mouseenter:mouseleave"></span></a></li>
          <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link" (click)="next('start');"><span class="icon zmdi zmdi-fast-rewind"></span></a></li>
          <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link" (click)="next(-1);"><span class="icon zmdi zmdi-arrow-left"></span></a></li>
          <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item" style="margin-top: 10px;"><span>{{analysisPosition >= 0 && analysisPosition < this.analysisInfo.length ? (analysisPosition + 1) + "/" + this.analysisInfo.length : "" }}</span></li>
          <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link" (click)="next(1);"><span class="icon zmdi zmdi-arrow-right"></span></a></li>
          <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link" (click)="next('end');"><span class="icon zmdi zmdi-fast-forward"></span></a></li>
          <li *ngIf="isAnalysisTabActive && selectedItems && selectedItems.length == 2" class="nav-item"><a class="nav-link" target="_" [routerLink]="['/codeDiff']" [queryParams]="{h1:hash1, h2:hash2}"><span class="icon zmdi zmdi-fullscreen"></span></a></li>
        </ul>      
      </div>
      <div class="tab-content">
        <div id="beaut" role="tabpanel" class="tab-pane" [class.active]="isBeautificationTabActive || isRawTabActive || isAnalysisTabActive">
          <div *ngIf="singleFileView" id="mergeViewContainer">
            <div></div>
            <ngx-codemirror #codeViewer [(ngModel)]="codeFileContent" [options]="codeMirrorOptions"></ngx-codemirror>
          </div>
        </div>      
        <div id="diff" role="tabpanel" class="tab-pane" [class.active]="isDiffTabActive">
            <div [innerHtml]="outputHtml | noSanitizePipe"></div>  
        </div>
        <div class="be-spinner" style="width: 100%; height: 100%">
          <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
          </svg>
        </div>
      </div>
    </div>
  </div>