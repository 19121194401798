import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import {
  DataStateChangeEventArgs,
  GridComponent,
  PageSettingsModel,
  SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { NotificationsService } from 'angular2-notifications';
import { Chart } from 'chart.js';
import { Observable, of } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { PageData } from '../../../models/PageData';
import { AuthService } from '../../../services/auth/auth.service';
import { ConfigService } from '../../../services/config/config.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { PageRunnerService } from '../../../services/data-export/page-runner.service';
import { NodesService } from '../../../services/nodes/nodes.service';
import { PageService } from '../../../services/page/page.service';
import { RegionService } from '../../../services/region/region.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { TimezoneService } from '../../../services/timezone.service';
import { delay } from 'rxjs/operators';
import { MobileService } from '../../../services/mobile/mobile.service';
import { CustomerService } from '../../../services/customer/customer.service';
declare var $: any;

@Component({
  selector: 'app-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.css']
})
export class AppDetailComponent implements OnInit, AfterViewInit {
  pageHistory: any[];
  dataLoading: boolean;
  vizLoading: boolean;
  screenshotButtonDisabled: boolean;

  notificationOptions: any;
  selectedVendor: string;
  public vendorCodeFiles: any[];

  s3PerfSubs: any[];

  selectedRows: any;
  firstLoadId: number;
  d3Viz: any;

  pageData: PageData;

  runNowRegionPickerForm: FormGroup;

  public pageSettings: PageSettingsModel;
  public sortSettings: any;
  public selectionSettings: SelectionSettingsModel;
  public toolbar: string[];
  public dateFormat: any;
  @ViewChild('historyGrid') public historyGrid: GridComponent;
  public ej2HistoryData: Observable<any>;

  @ViewChild('dataChart') public dataChart: ChartComponent;

  pageHistoryDataManager: DataManager;

  screenShotToolTipContent: string = 'Screenshot';

  chartWidth = '99%';

  public chart: Chart;

  domContentLoadedDataNew: any[];
  scriptDurationDataNew: any[];

  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  //Initializing Primary X Axis
  private fontStyle: any = {
    fontFamily: 'Roboto, sans-serif',
  };
  public primaryXAxis: Object = {
    valueType: 'DateTimeCategory',
    titleStyle: this.fontStyle,
    labelStyle: this.fontStyle,
    labelFormat: "dd MMM `yy",
    labelIntersectAction: 'Rotate45',
    edgeLabelPlacement: 'Shift',
    intervalType: 'Days',
  };
  //Initializing Primary Y Axis
  public primaryYAxis: any = {
    lineStyle: { width: 1 },
    titleStyle: this.fontStyle,
    labelStyle: this.fontStyle,
    majorGridLines: { width: 1 },
    majorTickLines: { width: 1 },
    minimum: 0,
    title: "DOM Content Loaded Duration (in ms)"

  };
  public marker: Object = {
    visible: true,
    width: 5,
    height: 5,
  };
  public tooltip: Object = {
    enable: true,
    textStyle: this.fontStyle,
  };
  public legend: Object = {
    visible: true,
    enableHighlight: true,
    textStyle: this.fontStyle,
  };
  public palette: string[] = ['red', 'green'];

  public axis = [
    {
      majorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      opposedPosition: true,
      lineStyle: { width: 1 },
      minimum: 0,
      titleStyle: this.fontStyle,
      labelStyle: this.fontStyle,
      plotOffsetBottom: 20,
      name: "scriptDurationAxis",
      title: "Script Duration (in ms)"
    },

  ];

  regions: any[];
  pageRunnerTypes = [
    { label: 'Android 15 (Device - Rooted)', value: 'default' },
    { label: 'Android 14 (Device)', value: 'android14' },
    { label: 'Android 14 (Simulator)', value: 'android14' },
    { label: 'Android 13 (Device)', value: 'android13' },
    { label: 'Android 13 (Simulator)', value: 'android13' },
  ];

  public fields: Object = { text: 'city', value: 'name', groupBy: 'continent' };
  public prTypeFields: Object = { text: 'label', value: 'value' };
  configs: Object;
  configTypeFields: Object = { text: 'name', value: 'id' };

  public data: any[];
  public placeholder: string = 'Select a version';
  public versionFields: Object = { text: 'version', value: 'version' };
  public selectedId: string = '0';
  public appName = '';
  public currentVersion = '';
  public appVersions: any[] = [];

  constructor(
    public pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private notificationService: NotificationsService,
    private zone: NgZone,
    private dataExportService: DataExportService,
    public authService: AuthService,
    private pageRunnerService: PageRunnerService,
    public timezoneService: TimezoneService,
    protected nodesService: NodesService,
    protected regionService: RegionService,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private mobileService: MobileService,
    private customerService: CustomerService
  ) {
    this.sitemapService.update(this.route);
    this.dataLoading = false;
    this.vizLoading = true;
    this.screenshotButtonDisabled = true;
    this.selectedRows = [];
    this.vendorCodeFiles = [];
    this.selectedVendor = '';
    this.notificationOptions = {
      timeOut: 2000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
    };

    this.firstLoadId = null;
    this.appName = this.route.snapshot.params.appName;
    this.currentVersion = this.route.snapshot.params.appVersion;
    this.appVersions = [this.currentVersion];

    this.mobileService.getAndroidApps(this.customerService.customer.id, this.appName).subscribe((apps) => {
      console.log('xxxx', apps);
      let app = apps[0];
      this.appVersions = this.appVersions.concat(app.versions);
    })

    this.dateFormat = { type: 'dateTime', skeleton: 'short' };
    this.pageSettings = {
      currentPage: 1,
      pageSize: 20,
      pageCount: 4,
      pageSizes: [25, 50, 100],
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [{ field: 'timestamp', direction: 'desc' }],
    };
    this.selectionSettings = {
      type: 'Single',
      mode: 'Row',
      checkboxMode: 'ResetOnRowClick',
      checkboxOnly: true,
    };
    this.runNowRegionPickerForm = this.formBuilder.group({
      region: ['us-east-1'],
      pageRunnerType: ['default'],
      configId: [-1],
    });
    this.regions = this.regionService.regionsArray.sort((x, y) => {
      if (x.city > y.city) {
        return 1;
      }
      if (x.city < y.city) {
        return -1;
      }
      return 0;
    });
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);
  }
  public graphData: any[] = [];
  private extractMetrics(data: any, metricName: string) {
    if (data && data.metrics) {
      return data.metrics.find((m: any) => m.name == metricName)?.value || 0;
    }
    return 0;
  }
  public dataBound(event) {
    console.log('Databound');
    console.log(event);
    console.log(this.pageHistoryDataManager);
    console.log(this.historyGrid);

    let dclMax = 0;
    let sdMax = 0;

    this.graphData = this.historyGrid.currentViewData.map(
      (item: any, index) => {
        let dcl = this.extractMetrics(item, 'DomContentLoaded');
        let sd = this.extractMetrics(item, 'ScriptDuration');
        dclMax = Math.max(dclMax, dcl);
        sdMax = Math.max(sdMax, sd);
        return {
          x: item.lastChecked,
          y0: dcl,
          y1: sd
        };
      }
    ).reverse();
    this.dataChart.primaryYAxis.maximum = this.roundUpToNearest(dclMax, 1000);
    (this.dataChart.axes[0] as any).maximum = this.roundUpToNearest(sdMax, 2);

    console.log(this.dataChart.primaryYAxis.maximum, this.dataChart.axes[0].maximum);

    console.log('Graph Data', this.graphData);

    this.vizLoading = false;
  }

  roundUpToNearest(value, nearest) {
    return Math.ceil(value / nearest) * nearest;
  }

  ngOnInit() {

    console.log('ngOnInit');
    // let { appName, appVersion } = this.route.snapshot.params;
    // this.mobileService.getAppRunsS3('com.fidelity.android', appVersion).subscribe((response)=>{
    //   console.log(response);
    // })
    // this.configService.getAllConfigs().subscribe((configs) => {
    //   console.log('configs', configs);
    //   this.configs = configs;
    //   (this.configs as any).unshift({ id: -1, name: 'Default' });
    // });
  }

  loadPageData() {

    let { appName, appVersion } = this.route.snapshot.params;
    this.mobileService.getAppRuns(appName, appVersion).subscribe((history) => {
      console.log(history);
      console.log(this.historyGrid);
      this.historyGrid.dataSource = history;
    })


    // this.pageService.getPageData(this.route.snapshot.params.pageId).subscribe(
    //   (pageData) => {
    //     this.pageData = pageData;
    //   },
    //   (error) => {
    //     //console.log("Error loading page data...");
    //     //console.log(error);
    //   }
    // );
  }

  harDiff() {
    //console.log(this.selectedRows);
    const harA = this.pageHistory[this.selectedRows[0]];
    const harB = this.pageHistory[this.selectedRows[1]];

    //console.log(harA, harB);

    const urlA = `${harA.dataLocation}HAR.json`;
    const urlB = `${harB.dataLocation}HAR.json`;

    const harDiffUrl = `http://diff.har.ui${env.production ? '.' : '.' + env.name + '.'
      }vaultjs.com/?har1=${urlA}&har2=${urlB}`;

    window.open(harDiffUrl, '_harDiff');
  }

  screenshotDiff() {
    const historyItemA = this.historyGrid.getSelectedRecords()[0];
    const historyItemB = this.historyGrid.getSelectedRecords()[1];

    const urlA = `${historyItemB['dataLocation']}screenshot.png`;
    const urlB = `${historyItemA['dataLocation']}screenshot.png`;

    const screenShotDiffUrl = `/screenshot?img1=${urlA}&img2=${urlB}`;

    window.open(screenShotDiffUrl, '_screenshotDiff');
  }

  ngAfterViewInit() {
    this.loadPageData();
  }

  onCopied(event) {
    this.notificationService.success(
      'Copied',
      'Full URL was copied to clipboard.',
      this.notificationOptions
    );
  }

  onCheckboxClick(id) {
    var items = $('input:checkbox:checked').map(function () {
      return parseInt(String($(this).val()));
    });
    //console.log(items);
    if (items && items.length == 2) {
      $('.rowCBs:not(:checked)').attr('disabled', 'true');
    } else {
      $('.rowCBs:not(:checked)').removeAttr('disabled');
    }

    if (items.length == 1) {
      //console.log("Loading viz for: " + items[0]);
    }

    this.selectedRows = items;

    this.zone.run(() => {
      //console.log("");
    });
  }

  runNow() {
    $('#modal-run-now').modal('hide');
    this.dataLoading = true;
    of("").pipe(delay(1000)).subscribe(() => {
      this.dataLoading = false;
      this.notificationService.success(
        'Success',
        'The App was successfully queued. Reload to view results.',
        { ...this.notificationOptions, timeOut: 5000 }
      );
    })
    // console.log(this.runNowRegionPickerForm);
    // console.log(this.runNowRegionPickerForm.value);
    // let selectedRegion = this.runNowRegionPickerForm.value.region;
    // let selectedPageRunnerType = this.runNowRegionPickerForm.value.pageRunnerType;
    // let selectedConfigId = this.runNowRegionPickerForm.value.configId;
    // console.log(selectedRegion, selectedPageRunnerType, selectedConfigId);

    // let pageId = this.route.snapshot.params.pageId;
    // console.log('run now...', pageId);
    // this.dataLoading = true;
    // this.pageRunnerService
    //   .runPage(pageId, selectedRegion, selectedPageRunnerType, selectedConfigId)
    //   .subscribe(
    //     (response) => {
    //       console.log(response);
    //       this.dataLoading = false;
    //       this.notificationService.success(
    //         'Success',
    //         'The page was successfully queued. Reload the page to view results.',
    //         { ...this.notificationOptions, timeOut: 5000 }
    //       );
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.dataLoading = false;
    //       this.notificationService.success(
    //         'Uh oh!',
    //         'An unexpected error has occured: ' + err,
    //         this.notificationOptions
    //       );
    //     }
    //   );
  }

  async saveRawFile(fileName, item) {
    // console.log(data);

    // let item = this.historyGrid.getSelectedRecords()[0] as any;
    if (item) {
      console.log(item);
      let url =
        (item.scriptedPageRunnerHistoryId
          ? env.aws.dataCollectorBucket + '/'
          : '') +
        item.dataLocation +
        (item.scriptedPageRunnerHistoryId ? item.uuid + '/' : '') +
        fileName +
        '.json.gz';
      let signedUrl = await this.dataExportService
        .getSignedUrl(url)
        .toPromise();
      window.open(signedUrl, '_rawfile');
    }
  }

  onHistorySelecting(args) {
    console.log('onHistorySelecting');
    console.log(args);
    if (
      this.historyGrid.getSelectedRecords().length == 2 ||
      args.isHeaderCheckboxClicked == true
    ) {
      args.cancel = true;
    }
  }

  onHistorySelected(args) {
    if (this.historyGrid.getSelectedRecords().length == 2) {
      this.screenshotButtonDisabled = false;
    } else {
      this.screenshotButtonDisabled = true;
    }
  }

  onHistoryDeselected(args) {
    if (this.historyGrid.getSelectedRecords().length == 2) {
      this.screenshotButtonDisabled = false;
    } else {
      this.screenshotButtonDisabled = true;
    }
  }

  async onViewHarClick(data) {
    console.log('onViewHarClick');
    console.log(data);

    let val = `${(data.scriptedPageRunnerHistoryId
      ? env.aws.dataCollectorBucket + '/'
      : '') + data.dataLocation
      }${data.scriptedPageRunnerHistoryId ? data.uuid + '/' : ''}HAR.json${data.scriptedPageRunnerHistoryId ? '.gz' : ''
      }`;

    // let val = `${env.aws.dataCollectorBucket}/${data.dataLocation}HAR.json`;
    const url = await this.dataExportService.getSignedUrl(val).toPromise();
    document.cookie = `harUrl=${url};domain=vaultjs.com;path=/`;
    const harUrl = `https://har.ui${env.production ? '.' : '.' + env.name + '.'
      }vaultjs.com/index.html`;

    window.open(harUrl, '_harViewer');
  }

  onScreenshotClick(data) {
    // const url = `/screenshot?img1=${(data.scriptedPageRunnerHistoryId
    //   ? env.aws.dataCollectorBucket + '/'
    //   : '') + data.dataLocation
    //   }${data.scriptedPageRunnerHistoryId ? data.uuid + '/' : ''}screenshot.png`;
    window.open("http://localhost/vid.html", '_screenshot');
  }

  onHistoryDeselecting(args) {
    console.log('onHistoryDeselecting');
    console.log(args);
    if (args.target.className == 'harLink') {
      args.cancel = true;
    }
  }

  onHistoryItemClick(event: MouseEvent, data) {

    // // this.pageService.selectedPageHistory.next(data);

    // if (event.shiftKey == true) {
    //   window.open(location.href + '/history/' + data.id);
    // } else {
    //   this.router.navigate(['history', data.id], { relativeTo: this.route });
    // }
  }

  onViewNodeGraphClick(data) {
    console.log('node graph click');
    console.log(data);
    this.nodesService.dataLocation = data.dataLocation;
    // localStorage.setItem('nodesDataLocation', data.dataLocation);
    // const nodesUrl = `https://ui${env.production ? "." : "." + env.name + "."}vaultjs.com/nodes`;
    // window.open(nodesUrl, "_nodesViewer");

    this.router.navigate(['history', data.id, 'nodes'], {
      relativeTo: this.route,
    });
  }

  onViewAnalysisClick(data) {
    this.router.navigate(['history', data.id, 'analysis'], {
      relativeTo: this.route,
    });
  }

  actionComplete(args) {
    console.log('actionComplete');
    console.log(args);
  }

  actionBegin(args) {
    console.log('actionBegin');
    console.log(args);
  }

  consentString = (configFile) => {
    let cString = 'No Preference';
    if (!configFile) return cString;
    const gpcoptout = configFile['GPCOptOut']
    const gpcoptin = configFile['GPCOptIn']
    const otoptin = configFile['oneTrustOptIn']
    const otoptout = configFile['oneTrustOptOut']
    if (gpcoptin == null && gpcoptout == null && otoptin == null && otoptout == null) {
      return cString;
    }
    if (gpcoptin == true) {
      cString += "GPC Opt In, "
    }
    if (gpcoptout == true) {
      cString += "GPC Opt Out, "
    }
    if (otoptin == true) {
      cString += "OneTrust Opt In, "
    }
    if (otoptout == true) {
      cString += "OneTrust Opt Out, "
    }
    return cString.slice(0, -2)
  }

  eventChange(selectedId) {
    console.log('ner eventChange');
    console.log('eventChange', selectedId);
  }

  onBeforeOpen(args) {
    console.log('onBeforeOpen');
    console.log(args);
  }

  onBeforeClose(args) {
    console.log('onBeforeClose');
    console.log(args);
  }

  onBeforeConfigTooltipOpen(data) {
    console.log('onConfigTooltipOpen');
    if (!data.configTooltip) {
      data.configTooltip = `Page Run Config: ${(this.configs as any).find(c => (c.id == data.config.configId || c.id == data.config.config?.configurationId))?.name || "Default"}`;
    }
  }

}
