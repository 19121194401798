import { Injectable } from '@angular/core';
import { DataSourceChangedEventArgs, DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerService } from '../customer/customer.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class EJ2VendorsProxyServiceService extends Subject<any> {

  constructor(private customerService:CustomerService, private spinnerService:SpinnerService) {
    super();
  }

  public execute(state: any): void {    
    this.spinnerService.toggle(true);
    this.getData(state).pipe(tap(()=>{
      this.spinnerService.toggle(false);
    })).subscribe(x => super.next(x));
  }

  protected getData(state: DataStateChangeEventArgs): Observable<any> {
    console.log('proxy state');
    console.log(state);
    var search = '';
    if ( state.search && state.search[0] ){
      console.log('search');
      console.log(state.search[0]['key']);
      search = state.search[0]['key'];
    }

    console.log("state.where");
    console.log(state.where);
    var sorted = state.sorted ? state.sorted : [ {
      "name": "vendor",
      "direction": "ascending"
    }];
    let params = {start: (state.skip ? state.skip : 0), length: (state.take ? state.take : 20), sort: sorted, where: state.where};
    return this.customerService.getAllCodeFilesV2(params);      
  }

  updateRecord(state: DataSourceChangedEventArgs): Observable<any> {
    // return this.http.put(this.customersUrl, state.data, httpOptions);
    console.log('Update Code File Record');
    //codeFileId
    return this.customerService.updateCodeFile({codeFileId: state.data['id'], data:{maxTimeBetweenChecks: state.data['maxTimeBetweenChecks']}});
  }
}
