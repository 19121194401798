<div class="row">
  <div class="col-md-12">
    <div class="card be-loading">
      <div class="card-header card-header-divider">
        <div class="tools">
          <!-- <button *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" type="button" (click)="onAdvEditClick();" class="btn btn-rounded btn-space btn-primary"><i style="color: white;" class="icon icon-left zmdi zmdi-code-setting"></i>  Edit as JSON</button> -->
        </div>
        <span class="title">Page Runner Configuration</span>
        <span class="card-subtitle">Configfure how and when we scan your website(s)</span>        
      </div>
      <div class="card-body">
        <ng-template #codeEditor>         
          <div style="height: 700px;">
            <ngx-codemirror #advConfigEditor [(ngModel)]="config" [options]="codeMirrorOptions" style="height: auto;"></ngx-codemirror>
            <div style="text-align: center; margin-top: 10px;">
              <button (click)="saveAdvConfig();" class="btn btn-rounded btn-space btn-primary">Save</button>
            </div>
          </div>
        </ng-template>
        <form *ngIf="!advEdit; else codeEditor" [formGroup]="configForm" (ngSubmit)="save()">
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Enabled:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="If disabled, Vault JS will NOT visit your pages."></span></label>
            <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
              <label class="custom-control custom-checkbox custom-control-inline">
                <input class="custom-control-input" type="checkbox" [formControl]="configForm.controls['enabled']"><span class="custom-control-label"></span>
              </label>
            </div>
          </div>
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Timeout:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="The maximum time the browser will wait for a page to load."></span></label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" type="number" formControlName="timeout">
              <div *ngIf="timeout.invalid && (timeout.dirty || timeout.touched)" class="alert alert-danger">
                <div *ngIf="timeout.errors.required">
                  Timeout is required.
                </div>
                <div *ngIf="timeout.errors.min">
                  Timeout must be >= 0.
                </div>
                <div *ngIf="timeout.errors.max">
                  Timeout must be <= 830000 ms.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Custom User Agent:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Override which browser we appear to be using"></span></label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" type="text" formControlName="userAgent" placeholder="Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.106 Safari/537.36 OPR/38.0.2220.41">
            </div>
          </div>
          <div class="accordion" id="accordian4">
              <div id="headersHeading" class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <h4>
                      <button type="button" class="btn" data-toggle="collapse" data-target="#headersCollapse" aria-expanded="true" aria-controls="headersCollapse"><i class="icon zmdi zmdi-chevron-right"></i></button>
                      Custom Headers&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Add or override headers to each request"></span>
                    </h4>
                  </div>
              </div>
              <div class="collapse show" id="headersCollapse" aria-labelledby="headersHeading" data-parent="#accordian4">
                <div formArrayName="headers" *ngFor="let header of headers.controls; let i=index">                
                  <div class="form-group row pt-1" [formGroupName]="i">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right">Header:</label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input class="form-control" type="text" formControlName="header" placeholder="HeaderName: SomeValue">
                    </div>
                    <div *ngIf="headers.controls.length > 1" class="text-sm-right">
                        <span style="cursor: pointer;" class="icon zmdi zmdi-minus-circle-outline" (click)="removeHeader(i)"></span>                      
                    </div>
                  </div>                
                </div>
                <div class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <a style="cursor: pointer;" (click)="addHeader()">+ Add another Header</a>
                    </div>
                </div>
              </div>
            </div>
            <div style="text-align: center">
              <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!configForm.valid">Save</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>