import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AccountSwitchComponent } from './account-switch/account-switch.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RootComponent } from './root/root.component';
import { UsermenuComponent } from './usermenu/usermenu.component';
import { MenuComponent } from './menu/menu.component';
import { ProfileComponent } from './profile/profile.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ProgressDisplayComponent } from './progress-display/progress-display.component';
import { DashboardFileSelectorComponent } from './dashboard-file-selector/dashboard-file-selector.component';
import { RegionPipe } from '../../pipes/RegionPipe/region.pipe';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DropDownListModule,
    // RegionPipe
  ],
  declarations: [
    AccountSwitchComponent,
    BreadcrumbsComponent,
    RootComponent,
    UsermenuComponent,
    MenuComponent,
    ProfileComponent,
    ProgressDisplayComponent,
    DashboardFileSelectorComponent,
    RegionPipe
  ],
  exports: [
    AccountSwitchComponent,
    BreadcrumbsComponent,
    RootComponent,
    UsermenuComponent,
    MenuComponent,
    ProfileComponent,
    RegionPipe
  ]
})
export class SharedUiComponentsModule { }
