<div class="row">
    <div class="col-lg-12">
        <div class="card" style="display: flex; flex-direction: row; padding: 10px; align-items: center;">
            <div class="card-header"
                style="font-size: xx-large; font-weight: 500; flex-grow: 1; padding:10px;margin:0px;">Report Library
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end; gap: 10px; align-items: center;">
                <button ejs-button [routerLink]="['/export-center']" iconCss="zmdi zmdi-file-text">Export
                    Center</button>
                <button ejs-button (click)="onLibraryConfigClick()" *ngIf="authService.isVaultUser()"
                    iconCss="zmdi zmdi-settings"></button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngFor="let cat of ( reportPerCategory$ | async) || currentReportPerCategory">
    <div class="row" *ngIf="cat.hidden">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header" style="font-size: large;">{{cat.category}}</div>
                <div class="cards-grid">
                    <ng-container *ngFor="let report of  cat.reports" #reportTiles>
                        <app-report-selection-tile *ngIf="!report.hidden" [title]="report.title"
                            [description]="report.description" [icon]="report.icon" [reportCode]="report.reportCode"
                            [beautifiedPath]="report.beautifiedPath" [hasRaw]='report.hasRaw' [isEmpty]="report.isEmpty"
                            [isCustom]="report.isCustom" [isView]="report.isView"
                            style="position: relative;"></app-report-selection-tile>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<ejs-dialog #libraryConfigDialog [closeOnEscape]="true" showCloseIcon="true" [visible]="false" width="500px"
    height="700px" [allowDragging]="false" [isModal]="true" [animationSettings]="dialogAnimationSettings">
    <ng-template #header>
        <div>
            <span>Report Library Configuration</span>

        </div>
    </ng-template>
    <ng-template #content>
        <div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end; gap: 10px;">
                <button ejs-button (click)="onCustomReportsClick()" iconCss="zmdi zmdi-file-text"
                    content="Custom Reports"></button>
                <button ejs-button (click)="onViewConfigClick()" iconCss="zmdi zmdi-eye" content="Views"></button>
            </div>
            <div>
                <div *ngFor="let catObj of currentReportPerCategory"
                    style="display: flex; flex-direction: column;  gap:10px">
                    <ng-container *ngIf="catObj.category !== 'Extras' && catObj.category !== 'Custom Views'">
                        <span>{{catObj.category}}</span>
                        <div style="display: flex; flex-direction: row; gap:5px; flex-wrap: wrap;">
                            <ng-container *ngFor="let report of catObj.reports">
                                <div *ngIf="!report.isCustom && !report.isView" class="report-selector-btns e-btn"
                                    [class.e-active]="!report.hidden" (click)="onToggleChange(report)"
                                    [class.disableBtns]="!reportButtonsFlag">
                                    {{report.title}}
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
            <ng-template #reportsLoader>
                <div>Updating....</div>
            </ng-template>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <button ejs-button (click)="onLibraryConfigSave()" content="Confirm Changes"></button>
    </ng-template>
</ejs-dialog>

<ejs-dialog #viewConfigDialog [closeOnEscape]="true" showCloseIcon="true" [visible]="false" width="600px" height="500px"
    [allowDragging]="false" [isModal]="true" [animationSettings]="dialogAnimationSettings">
    <ng-template #header>
        View Cards Configuration
    </ng-template>
    <ng-template #content>
        <ejs-grid [dataSource]="currentViewCardReportList || []" height="100%" [toolbar]="['Delete']"
            [editSettings]="{allowDeleting:true}" (actionComplete)="onViewDelete($event)">
            <e-columns>
                <e-column field="title" headerText="View Name" minWidth="100" textAlign="Center">
                    <ng-template #template let-data>
                        {{data.title}}
                    </ng-template>
                </e-column>
                <e-column field="reportCode" headerText="Report Code" width="100" textAlign="Center" [autoFit]="true">
                    <ng-template #template let-data>
                        {{data.reportCode}}
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </ng-template>
</ejs-dialog>

<ejs-dialog #customReportsDialog [closeOnEscape]="true" showCloseIcon="true" [visible]="false" width="600px"
    height="700px" [allowDragging]="false" [isModal]="true" [animationSettings]="dialogAnimationSettings">
    <ng-template #header>
        Custom Reports Configuration
    </ng-template>
    <ng-template #content>
        <ejs-grid [dataSource]="currentCustomReportList || []" [toolbar]="['Add','Edit','Delete']"
            [editSettings]="{allowEditing: true,allowAdding: true,allowDeleting: true,mode: 'Dialog'}"
            (actionBegin)="onCustomReportGridActionBegin($event)"
            (actionComplete)="onCustomReportGridActionComplete($event)">
            <e-columns>
                <e-column field="title" headerText="Title" width="100" textAlign="Left"
                    [validationRules]="{required:true}">
                    <ng-template #template let-data>
                        <div style="display: flex;flex-direction: row; gap:10px">
                            <div class='icon-container'>
                                <div class='svg-container'>
                                    <img [src]="'/assets/svg/'+ (data?.icon) +'.svg'" width="100%">
                                </div>
                            </div>
                            <div style="display: flex; flex-direction: column; gap:5px">
                                <div style="font-size: large;"><b> {{data.title}}</b></div>
                                <div style="font-style: italic;">Filename: {{data.reportCode}}</div>
                                <div style="color:grey">{{data.description}}</div>
                            </div>
                        </div>

                    </ng-template>
                </e-column>
                <e-column field="icon" [validationRules]="{required:false}" width="0px">
                </e-column>
                <e-column field="reportCode" headerText="Report Code" textAlign="Left"
                    [validationRules]="{required:true}"  width="0px">
                </e-column>
                <e-column field="description" headerText="Description" textAlign="Left" width="0px">
                </e-column>
            </e-columns>
        </ejs-grid>
    </ng-template>
</ejs-dialog>