import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { AdobeGroupData, AdobeVarGridData, BarChartData, BarChartSeries, RuleGridData, TrendLineSeries, ValueMonitoring, VarPagesGridData } from '../../dashboardDS';
import { DataExportService } from '../../../../services/data-export/data-export.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../../services/notifications/vault-notification.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-adobe-report',
  templateUrl: './adobe-report.component.html',
  styleUrls: ['./adobe-report.component.css']
})
export class AdobeReportComponent implements OnInit {
  public totalPages: ValueMonitoring = {
    max: 0,
    monitored: 0
  }
  public adobeLaunch: ValueMonitoring = {
    max: 0,
    monitored: 0
  }
  public varInst: ValueMonitoring = {
    max: 0,
    monitored: 0
  }
  public keyTrendErrs: TrendLineSeries = {
    series: []
  }
  public totalTrendErrs: TrendLineSeries = {
    series: []
  }
  public topVars: BarChartSeries = {
    series: []
  };
  public pageGridData: VarPagesGridData;
  public ruleGridData: RuleGridData;
  public topErrs: any[] = [];
  public keyTrendLen = 0;
  public totalTrendLen = 0;
  public groupData: AdobeGroupData[];
  public variableGridData: AdobeVarGridData;

  public isLoaded: BehaviorSubject<boolean>;

  constructor(private spinnerService: SpinnerService,
    private dataExportService: DataExportService,
    private authService: AuthService,
    private notificationService: VaultNotificationService,
  ) {
    this.isLoaded = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    this.loadData();
  }

  loadData() {
    const cid = parseInt(this.authService.customerId());
    this.dataExportService.getDashboardFile('adobeDataReport.json', cid).subscribe({
      next: (data) => {
        const adobeDataReport = JSON.parse(data.toString());
        console.log(adobeDataReport);

        this.keyTrendErrs.series = adobeDataReport.keyTrendErrs.series;
        this.totalTrendErrs.series = adobeDataReport.totalTrendErrs.series;
        this.keyTrendLen = this.keyTrendErrs.series.length;
        this.totalTrendLen = this.totalTrendErrs.series.length;
        this.topErrs = adobeDataReport.topErrs;
        this.topErrs.sort((a, b) => b.totalErrors - a.totalErrors);
        this.topErrs = this.topErrs.slice(0, 5);
        this.totalPages = adobeDataReport.totalPages;
        this.adobeLaunch = adobeDataReport.adobeLaunch;
        this.varInst = adobeDataReport.varInst;
        this.topVars = adobeDataReport.barChartData;
        this.groupData = adobeDataReport.siteGroupData;
        this.variableGridData = adobeDataReport.variableGridData;
        this.pageGridData = adobeDataReport.pageGridData;
        this.ruleGridData = adobeDataReport.ruleGridPanelData;
        this.spinnerService.toggle(false);
        this.isLoaded.next(true);
      },
      error: (err) => {
        console.log(err);
        this.spinnerService.toggle(false);
        this.notificationService.error('Unable to load data.', 'Please try again or contact support');
      },
      complete: () => {
        
      }
    });
  }

  updateData() {

  }
}
