import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardFileSelectorService {
  public show: boolean = false;
  public reportCode: string = '';
  public selectionHandler:any;
  public defaultFile: string = '';
  constructor() {}
  toggle(show: boolean = true) {
    this.show = show;
  }
  setReportCode(code: string) {
    this.reportCode = code;
  }
  setSelectionHandler(handler: any) {
    this.selectionHandler = handler.bind(this);
  }
}
