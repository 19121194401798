import { Injectable } from '@angular/core';
import { CrudOptions, DataOptions, DataResult, Query } from '@syncfusion/ej2-data';
import { VaultBaseUrlAdaptor } from '../../../services/VaultBaseUrlAdaptor';
import { TimezoneService } from '../../../services/timezone.service';
@Injectable({
    providedIn: 'root'
})
export class PageAdaptor extends VaultBaseUrlAdaptor {

    public isFilterSearch: boolean = false;

    constructor(private timezoneService: TimezoneService) {
        super();
    }

    override processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
        console.log('processResponse');
        console.log(data, ds, query, xhr, request, changes);
        console.log("isFilterSearch", this.isFilterSearch);

        data.result = (data.result as any[]).map((item) => {
            return {
                ...item,
                lastCheckedPageRunnerFormatted: this.timezoneService.format(Number(item.lastCheckedPageRunner * 1000), false, true),
                lastCheckedPageRunner: item.lastCheckedPageRunner ? new Date(Number(item.lastCheckedPageRunner * 1000)) : null
            }
        });

        console.log(data);

        if (this.isFilterSearch == true) {

            data = data.result;
            this.isFilterSearch = false;
        }

        return data;
    }
}