import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomainDefinition } from '../../components/vendorLibrary/models/DomainDefinition';
import { Extractor } from '../../components/vendorLibrary/models/Extractor';
import { ResourceMatcher } from '../../components/vendorLibrary/models/ResourceMatcher';

@Injectable({
  providedIn: 'root'
})
export class VendorLibraryStateService {


  selectedDomainDefinition:BehaviorSubject<DomainDefinition>;
  selectedResourceMatcher:BehaviorSubject<ResourceMatcher>;
  selectedExtractor:BehaviorSubject<Extractor>;

  // private _domainDef:DomainDefinition;

  // get domainDef():DomainDefinition {
  //   return this._domainDef;
  // }

  // public domainDefName:BehaviorSubject<string>;
  // public resourceMatcherName:BehaviorSubject<string>;
  // public extractorName:BehaviorSubject<string>;

  constructor() { 
    // this.domainDefName = new BehaviorSubject("");
    // this.resourceMatcherName = new BehaviorSubject("");
    // this.extractorName = new BehaviorSubject("");

    this.selectedDomainDefinition = new BehaviorSubject(null);
    this.selectedResourceMatcher = new BehaviorSubject(null);
    this.selectedExtractor = new BehaviorSubject(null);

  }

  // public update(domainDef:DomainDefinition, resourceMatcherId:number=null, extractorId:number=null){
    
  //   this._domainDef = domainDef;
  //   this.domainDefName.next(domainDef.name);
    
  //   if ( resourceMatcherId ){
  //     let resourceMatcher = this.domainDef.resourceMatchers.filter( item => item.id == resourceMatcherId)[0];
  //     this.resourceMatcherName.next(resourceMatcher.name);

  //     if ( extractorId ) {
  //       let extractor = resourceMatcher.extractors.filter( item => item.id == extractorId )[0];
  //       this.extractorName.next(extractor.name);
  //     }

  //   }
  // }

}
