import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { GridModel, GridComponent } from '@syncfusion/ej2-angular-grids';
import { RuleGridData } from '../../dashboardDS';

@Component({
  selector: 'app-rule-grid-panel',
  templateUrl: './rule-grid-panel.component.html',
  styleUrls: ['./rule-grid-panel.component.css']
})
export class RuleGridPanelComponent implements OnInit, OnChanges {
  @Input('data') gridData: RuleGridData;

  // public testSuiteData?: object[];
  // public varData?: object[];
  // public varDetailData?: object[];
  public isLoading: boolean = true;
  public nestedGrid: GridModel = {
    queryString: 'varName',
    columns: [
      { field: 'varRule', headerText: 'Variable Rule', width: 100 },
      { field: 'condition', headerText: 'Condition', width: 100 },
      { field: 'varTest', headerText: 'Test', width: 120 },
      { field: 'pagesFound', headerText: 'Page Found', width: 120 },
      { field: 'pageErrors', headerText: 'Pages With Errors', width: 120 }
    ],
    allowResizing: true
  };
  public childGrid: GridModel = {
    queryString: 'ruleName',
    childGrid: this.nestedGrid,
    columns: [
      { field: 'varName', headerText: 'Variable', textAlign: 'Left', width: 90 },
    ],
  };
  @ViewChild('grid')
  public grid?: GridComponent;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.updateData();
  }

  expand(): void {
    (this.grid as GridComponent).detailRowModule.expandAll();
  }

  collapse(): void {
    (this.grid as GridComponent).detailRowModule.collapseAll();
  }

  updateData(): void {
    if (!this.gridData) {
      return;
    }
    this.childGrid.dataSource = this.gridData.varData;
    this.nestedGrid.dataSource = this.gridData.varDetailData;
    if (this.grid)
      (this.grid as GridComponent).refresh();
  }

}
