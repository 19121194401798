import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;

  ssoId:string = null;

  constructor(
    protected authService: AuthService,
    protected activatedRoute: ActivatedRoute
  ) { 
    console.log(this.activatedRoute.snapshot);
    this.ssoId = this.activatedRoute.snapshot.queryParams["sso"];
    if ( this.ssoId ){
      localStorage.setItem('ssoId', this.ssoId);
    }
  }

  login(): void {
    this.errors = this.messages = [];
    if ( this.ssoId ){
      this.submitted = true;
      this.authService.ssoLogin(this.ssoId);
    } else if ( this.user.email && this.user.email != "" && this.user.password && this.user.password != "" ){
      this.submitted = true;
      this.authService.loginWithUsernameAndPassword(this.user.email, this.user.password, this.authResultHandler.bind(this));
    } else {
      this.errors = ["Please enter both a username and a password."]
    }
    
  }

  authResultHandler(result:any):void{
    console.log("authResultHandler", result);
    this.submitted = false;
    if ( result ){
      console.log(result);
      this.errors = [result.errorDescription || result.error_description];
    } else {
      console.log('Result was empty?');
    }
  }

}
