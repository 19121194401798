<div class="row be-loading" [class.be-loading-active]="vizLoading">
  <div class="col-lg-10 graphDiv">
    <div class="card graphCard" style="height: 500px;">
      <div class="card-header card-header-divider">
        <div class="tools">
          <span class="icon zmdi zmdi-fullscreen" (click)="fullScreen();"></span>
        </div>
        Request Graph<span class="card-subtitle">View how a particular vendor wound up on this page. Click a node to view more details.</span>
      </div>
      <div class="card-body">          
        <div id="d3Viz" style="width: 100%; height: 375px" ></div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 vendorsDiv">
    <div class="card" style="height: 500px;">
      <div class="card-header card-header-divider">
        Vendors<span class="card-subtitle">Toggle request name per vendor</span>
      </div>
      <div class="card-body">          
        <ejs-grid #vendorsGrid
        height='350'
        [dataSource]="vendors"
        locale='en-US' 
        [allowPaging]="false" 
        [pageSettings]="pageSettings" 
        [allowSorting]="true" 
        [sortSettings]="sortSettingsVendors"
        (created)="onGridCreate()"
        (rowSelected)="onVendorSelected($event)"
        (rowDeselected)="onVendorDeselected($event)">
        <e-columns>                    
          <e-column type='checkbox' width='30'></e-column>
          <e-column field='vendorName' headerText='Vendor'>
            <ng-template #template let-data>
              <img style="width: 32px; height:32px;margin-right: 5px;" src='https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : (data.hostname ? data.hostname : "unknown")}}.ico'/>{{data.vendorName}}
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
      </div>      
    </div>
  </div>
  <div class="be-spinner">
    <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
    </svg>
  </div>
</div>
<br/>
<div class="row">
  <div class="col-lg-12 requestsDiv">
    <div class="card card-table card-border be-loading" [class.be-loading-active]="dataLoading">
      <div class="card-body">        
        <ejs-grid #fileGrid
        locale='en-US' 
        [allowPaging]="true" 
        [pageSettings]="pageSettings" 
        [allowSorting]="true" 
        [sortSettings]="sortSettings"
        [allowSelection]="false"
        [enableHover]="false"
        [toolbar]="toolbar"
        [allowResizing]="true"
        (created)="onGridCreate()"
        height="500px"
        >
        <e-columns>          
          <e-column field='file' headerText='Request URL' width="80%"></e-column>
          <e-column field='vendorName' headerText='Vendor'>
            <ng-template #template let-data>
              <img style="width: 32px; height:32px;margin-right: 5px;" src='https://{{cdnUrl}}/vendors/icon/{{ data.vendorWebsite ? data.vendorWebsite.toLowerCase() : "unknown" }}.ico'/>{{data.vendorName}}
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div>
    </div>
  </div>
</div>