<form [formGroup]="outputFormGroup">
  <div class="form-group row pt-1">
    <div class="col-12 col-lg-8">
      <ejs-textbox #txtEmail placeholder="Email address" floatLabelType="Auto" (change)="ontxtChange($event)" ></ejs-textbox>
    </div>
  </div>
  <div class="form-group row pt-1">
    <div class="col-12 col-lg-8">
      <ejs-listview #recipientsList 
        id='recipientsList' 
        [dataSource]='recipients' 
        [fields]='recipientsLVFields' 
        (actionComplete)='onLVComplete()' 
        height="100px">
          <ng-template  #template let-data>
            <div class='text-content'> {{data.text}} <span class='delete-icon e-icons'></span> </div>
          </ng-template>
      </ejs-listview>
    </div>
  </div>                
  <div class="form-group row pt-1">
    <div class="col-12 col-lg-8">
      <input class="form-control form-control-sm" id="txtEmailSubject" type="text" placeholder="Subject" formControlName="subject">
    </div>
  </div>
  <div class="form-group row pt-1">
    <div class="col-12 col-lg-8" style="height: 300px">
      <ejs-richtexteditor #tributeRTE
            id='tributeRTE'       
            height="275px"
            [enableResize]="true" 
            (created)='onCreate()' 
            (actionBegin)='actionBeginEvent($event)'
            formControlName="body"
            placeholder='Press # to insert and filter properties of the currently selected event.'>
        </ejs-richtexteditor>
    </div>
  </div>
</form>