import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalResourcesService } from '../../services/global-resources/global-resources.service';
import { last } from 'rxjs/operators';
@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.css'],
})
export class UserManualComponent implements OnInit {
  constructor(
    private globalResourcesService: GlobalResourcesService,
    private sanitizer: DomSanitizer
  ) {}
  public pdfLink: any = '';
  public userManualVersionList: any = [];
  public userManualListFields: any = {
    text: 'semVer',
    value: 'key',
  };
  public lastModified: any = '';
  public selectedManualVersion: any = null;
  ngOnInit(): void {
    this.globalResourcesService.getListOfResources().subscribe((data: any) => {
      if (data && data.Contents) {
        this.userManualVersionList = data.Contents.filter((item: any) => {
          // regex to match the file name with the pattern VJS Manual v1.1.2.pdf and extract the version number
          const regex = /VJS Manual v(\d+\.\d+\.\d+)\.pdf/;
          return regex.test(item.Key);
        }).map((item: any) => {
          return {
            key: item.Key,
            semVer: item.Key.match(/VJS Manual v(\d+\.\d+\.\d+)\.pdf/)[1],
            lastModified: item.LastModified,
          };
        });
        if (this.userManualVersionList.length > 0) {
          this.userManualVersionList.sort((a: any, b: any) => {
            return (
              new Date(b.lastModified).getTime() -
              new Date(a.lastModified).getTime()
            );
          });
          this.selectedManualVersion = this.userManualVersionList[0].key;
          this.onManualVersionChange({ value: this.selectedManualVersion });
        }
      }
    });
  }

  onManualVersionChange(event: any) {
    this.userManualVersionList.find((item: any) => {
      if (item.key === event.value) {
        this.lastModified = new Date(item.lastModified).toDateString();
      }
    });
    this.globalResourcesService.getPDFLinkUrl(event.value).subscribe((data) => {
      this.pdfLink = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      console.log('Link:', this.pdfLink.changingThisBreaksApplicationSecurity);
    });
  }

  onNewTabClick() {
    if (this.selectedManualVersion) {
      window.open(this.pdfLink.changingThisBreaksApplicationSecurity, '_blank');
    }
  }
}
