import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ReportingV2Service } from '../../services/reporting-v2/reporting-v2.service';
import { VaultNotificationService } from '../../services/notifications/vault-notification.service';
import { ReportLibraryConfig } from '../../models/ReportLibraryConfig';
import { AuthService } from '../../services/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-report-library',
  templateUrl: './report-library.component.html',
  styleUrls: ['./report-library.component.css'],
})
export class ReportLibraryComponent implements OnInit, AfterViewInit {
  configForm: FormGroup;
  libConfig: ReportLibraryConfig[] = [];
  defaultConfig: ReportLibraryConfig[] = [];
  configFromS3: any = [];
  reportList: any = [];
  listCategories: any = [
    'Dashboard',
    'Cookie Reports',
    'Online Tracking Technology',
    'Other Privacy Risks',
    'Vendor Analytics',
    'Extras',
    'Custom'

  ];
  tempCustomConfigState: any = [];
  tempPredefinedConfigState: any = [];

  constructor(
    private router: Router,
    private reportingV2Service: ReportingV2Service,
    private notificationService: VaultNotificationService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadConfig();
  }

  ngAfterViewInit(): void {}

  private loadConfig(): void {
    this.reportingV2Service
      .getReportLibraryConfig()
      .toPromise()
      .then((config: any) => {
        this.configFromS3 = JSON.parse(config);
        this.libConfig = this.reportingV2Service.overrideDefaultConfig(
          this.configFromS3
        );
      })
      .catch((error: any) => {
        console.error(error);
        this.notificationService.error(
          'Error fetching config',
          'Loading default config'
        );
        const temp = [
          {
            title: 'Some Random Report',
            description: 'Some Random Report',
            hidden: false,
          } as ReportLibraryConfig,
        ];
        this.libConfig = this.reportingV2Service.overrideDefaultConfig([]);
      })
      .finally(() => {
        this.defaultConfig = this.reportingV2Service.getDefaultConfig();
        this.reportingV2Service
          .getAvailableReports()
          .subscribe((reports: any) => {
            console.log("Reports A: ", reports)
            const listFromS3 = this.groupReportByCode(reports);
            const reportNames = reports.map((report) => report.name);
            this.reportList = this.libConfig.map((config) => {
              return {
                title: config.title,
                description: config.description,
                icon: config.icon,
                reportCode: config.reportCode,
                beautifiedPath: config.beautifiedPath,
                hasRaw: config.hasRaw,
                isEmpty: config.isCustom
                  ? !reportNames.some(
                      (report) => report === config.reportCode + '.csv'
                    )
                  : !listFromS3.some((report) => report === config.reportCode),
                hidden: config.hidden,
                cat: this.listCategories.includes(config.category)
                  ? config.category
                  : 'Custom',
                isCustom: config.isCustom,
                defaultValues:
                  this.defaultConfig.find(
                    (defaultConfig) =>
                      defaultConfig.reportCode === config.reportCode
                  ) || {},
              };
            });
            console.log(this.reportList);
          });
      });
  }

  groupReportByCode(reports: any[] = []) {
    const categories = new Set();
    reports.forEach((report) => {
      if (report && report.reportCode) categories.add(report.reportCode);
    });
    return Array.from(categories);
  }

  navigateToReport(reportCode: string) {
    this.router.navigate(['report', reportCode]);
  }

  splitByCategory(category: string) {
    return this.reportList.filter((report) => report.cat === category)

  }
  splitCheckHidden(cat) {
    return this.splitByCategory(cat).some((report) => report.hidden === false);
  }

  openModal(id: string) {
    $(id).modal('show');
  }

  closeModal(id: string) {
    $(id).modal('hide');
  }

  configModalAction = () => {
    this.tempCustomConfigState = this.libConfig.filter(
      (config) => config.isCustom
    );
    this.tempPredefinedConfigState = this.libConfig.filter(
      (config) => !config.isCustom
    );
    this.openModal('#configModal');
  };

  addNewCustomConfig = () => {
    this.tempCustomConfigState.push({
      title: '',
      description: '',
      hidden: false,
      icon: '',
      isCustom: true,
      reportCode: 'CUSTOM_REPORT',
    });
  };

  removeCustomConfig = (index: number) => {
    this.tempCustomConfigState.splice(index, 1);
  };

  onConfigConfirm = () => {
    const output = [];
    const customReportCodes = new Set();
    let hasDuplicate = false;

    this.tempCustomConfigState.forEach((config) => {
      output.push({
        reportCode: config.reportCode,
        hidden: config.hidden,
        title: config.title,
        description: config.description,
        icon: config.icon,
      });
      if (customReportCodes.has(config.reportCode)) {
        this.notificationService.error(
          'Duplicate Report Code',
          `Report code ${config.reportCode} is duplicated`
        );
        hasDuplicate = true;
        return;
      }
      if (
        this.tempPredefinedConfigState.some(
          (predefinedConfig) =>
            predefinedConfig.reportCode === config.reportCode
        )
      ) {
        this.notificationService.error(
          'Duplicate Report Code',
          `Report code ${config.reportCode} conflicts with predefined report code`
        );
        hasDuplicate = true;
        return;
      }
      customReportCodes.add(config.reportCode);
    });

    if (hasDuplicate) {
      return;
    }

    this.tempPredefinedConfigState.forEach((config) => {
      if (config.hidden) {
        output.push({
          reportCode: config.reportCode,
          hidden: true,
        });
      }
    });

    console.log(output);
    this.reportingV2Service.saveReportLibraryConfigToS3(output);
    this.notificationService.success('Config Saved', 'Reload to see changes');
    this.closeModal('#configModal');
  };

  public testDownload() {
    // this.reportingV2Service.queryFromS3();
    console.log(this.reportingV2Service.queryBuilder([], [], 500));
  }
}
