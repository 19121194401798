import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ToolbarService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { List } from './List';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { VaultNotificationService } from '../../services/notifications/vault-notification.service';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  QuickToolbarService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { CustomerService } from '../../services/customer/customer.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'vendor-id-validation-list',
  templateUrl: './vendor-id-validation-list.component.html',
  styleUrls: ['./vendor-id-validation-list.component.css'],
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    QuickToolbarService,
    ResizeService,
  ],
})
export class VendorIDValidationList implements OnInit {
  @ViewChild('dropdown') public cbComponent: ComboBoxComponent;
  @ViewChildren('txtNewVal') public txtNewVals: QueryList<TextBoxComponent>;
  @ViewChildren('listView') public listViews: QueryList<ListViewComponent>;
  @ViewChildren('txtName') public txtNames: QueryList<TextBoxComponent>;
  public selectedList: List;
  public lists: List[];
  public form: FormGroup;
  public newListForm: FormGroup;
  public isInitialized: boolean = true;
  public isPristine: boolean = true;
  // public keyValuePair: any;
  public cbFields: any;

  ngOnInit(): void {
    this.loadAllLists();
  }
  constructor(
    private spinnerService: SpinnerService,
    private customerService: CustomerService,
    public notificationService: VaultNotificationService,
    private formBuilder: FormBuilder
  ) {
    this.selectedList = null;
    this.lists = [];
    this.cbFields = { text: 'Name', value: 'ARN' };
    this.newListForm = this.formBuilder.group({
      id: '',
      name: '',
      description: '',
    });
    this.InitForm();
  }

  public async loadAllLists(showSpinner = true) {
    if (showSpinner) {
      this.spinnerService.toggle(true);
    }
    this.updateList(await this.LoadLists());
    if (showSpinner) {
      this.spinnerService.toggle(false);
    }
  }

  public updateList(data) {
    const specialList = 'client-validation-ids';
    try {
      this.cbComponent.dataSource = data;
      // Check for the specific list here, if returns false, create list
      if (this.CheckForList(specialList)) {
        // if list exists, set the selected list to the list
        this.isInitialized = true;
        this.cbComponent.value = data.find((x) => x.Name === specialList).ARN; // Special Case
        this.onListChange({
          itemData: data.find((x) => x.Name === specialList),
        });
      } else {
        // if list does not exist, create list
        this.isInitialized = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  public onListChange(args) {
    this.selectedList = args.itemData;
    console.log(this.selectedList);
    if (this.selectedList && this.selectedList.Name) {
      this.LoadSecrets(this.selectedList.Name);
    } else {
      this.InitForm();
    }
  }

  public onValueSubmit(event: any, index: number) {
    const valueInput = this.txtNewVals.get(index)?.value;
    console.log(valueInput);
    if (valueInput === '' || valueInput === null) return;
    if (valueInput.indexOf(',') > -1) {
      let splitVals = valueInput.split(',');
      splitVals.forEach((val) => {
        if (val === '' || val === null) return;
        this.ValueInsert(index, val);
      });
    } else {
      this.ValueInsert(index, valueInput);
    }
    this.txtNewVals.get(index).value = '';
    this.isPristine = false;
  }

  public onValueDelete(value: any, index: any) {
    let valueArray = this.keyValuePairs.get([index, 'value']) as FormArray;
    for (var i = 0; i < valueArray.length; i++) {
      let val = valueArray.get([i]).value;

      if (val.text == value.text) {
        valueArray.removeAt(i);
        break;
      }
    }
    this.isPristine = false;
  }

  public onValueClick(value: any) {
    // copy value to clipboard
    navigator.clipboard.writeText(value.text);
    // show notification
    this.notificationService.success(
      'Success!',
      `Copied ${value.text} to clipboard`
    );
  }

  public async reloadList() {
    this.spinnerService.toggle(true);
    if (this.selectedList && this.selectedList.Name) {
      this.LoadSecrets(this.selectedList.Name);
    } else {
      console.log(this.form.dirty);
      this.form = this.formBuilder.group({ kvp: this.formBuilder.array([]) });
    }
    this.isPristine = true;
  }
  public saveAllChanges() {
    let updatedValues = {};
    let duplicateKeys = false;
    let emptyKeys = false;
    this.keyValuePairs.value.forEach((item) => {
      /// if same key is found, notify with warning and stop
      if (item.name === '') {
        emptyKeys = true;
        return;
      }
      if (updatedValues[item.name]) {
        duplicateKeys = true;
        return;
      }
      updatedValues[item.name] = item.value
        .map((val) => {
          return val.text;
        })
        .join(',');
    });
    if (duplicateKeys) {
      this.notificationService.error(
        'Oops!',
        'The list was not saved successfully. Duplicate keys are not allowed.'
      );
      return;
    }
    if (emptyKeys) {
      this.notificationService.error(
        'Oops!',
        'The list was not saved successfully. Empty keys are not allowed.'
      );
      return;
    }
    this.SaveChanges(updatedValues);
  }

  public onSearchValueChange(event: any, index: number) {
    console.log('Search Value Changed: ', event);
    const searchValue = event.value?.toLowerCase();
    let valueArray = this.keyValuePairs.get([index, 'value']) as FormArray;
    for (var i = 0; i < valueArray.length; i++) {
      let val = valueArray.get([i]).value;

      if (val.text.toLowerCase().match(searchValue)) {
        valueArray.get([i]).patchValue({ matched: true });
      }else{
        valueArray.get([i]).patchValue({ matched: false });
      }
      if(val.text === ''){
        valueArray.get([i]).patchValue({ matched: true });
      }
    }
  }
  // getters and setters
  public get keyValuePairs(): FormArray {
    return this.form.get('kvp') as FormArray;
  }
  public get newListName(): AbstractControl {
    return this.newListForm.get('name');
  }
  // private functions
  private async LoadLists() {
    this.spinnerService.toggle(true);
    let response = await this.customerService.getLists().toPromise();
    this.lists = response.lists;
    this.spinnerService.toggle(false);
    return new Promise((resolve, reject) => {
      try {
        resolve(this.lists);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
  private async LoadSecrets(secretName) {
    this.spinnerService.toggle(true);
    const txSecretName = `list-${secretName}`;
    this.customerService.getLists(txSecretName).subscribe((response) => {
      if (response.error) {
        console.log('No keys found');
        this.InitForm();
      } else {
        let secrets = JSON.parse(response);
        this.InitForm();

        // Get keys from secret
        let keys = Object.keys(secrets);
        if (keys.length) {
          console.log('Keys found: ', keys);
          this.selectedList.values = keys.map((key) => {
            return this.ParseSecretObject(secrets, key);
          });
        } else {
          console.log('No keys found');
          this.InitPairForm();
        }
      }
      this.spinnerService.toggle();
    });
  }
  private InitForm() {
    // sets the form
    this.form = this.formBuilder.group({
      kvp: this.formBuilder.array([]),
    });
  }
  private ParseSecretObject(obj: string[], key: string) {
    const parsedArray = obj[key].split(',');
    const txObj = {
      key: key,
      value: parsedArray,
    };
    const valuesFromArray = this.formBuilder.array([]);
    txObj.value.forEach((value: string) => {
      valuesFromArray.push(this.formBuilder.group({ text: value, matched: true }));
    });
    this.PushKeyPair(txObj.key, valuesFromArray);
    console.log('TXObj', txObj);
    return txObj;
  }
  private PushKeyPair(key: string, value: FormArray) {
    // sets the form array
    (this.form.get('kvp') as FormArray).push(
      this.formBuilder.group({
        name: key,
        value: value,
      })
    );
  }
  private InitPairForm() {
    this.InitForm();
    this.PushKeyPair('', this.formBuilder.array([]));
  }
  private CheckForList(listName) {
    return this.lists.find((x) => x.Name === listName);
  }
  public tempIds ="GA-13848493,GA-13848494,GA-13848495,GA-13848496"
  private ValueInsert(index: number, value: any) {
    (this.keyValuePairs.get([index, 'value']) as FormArray).insert(
      0,
      this.formBuilder.group({ text: value, matched: true })
    );
  }
  private async refreshListAfterAction(
    successMessage = 'The list was created successfully.',
    errMessage = "New list wasn't available yet"
  ) {
    let curListCount = this.lists.length;
    let watchdogCounter = 0;
    while (this.lists.length == curListCount) {
      console.log('load lists');
      await this.loadAllLists();
      await of(null).pipe(delay(1000)).toPromise();
      if (watchdogCounter++ > 4) break;
    }
    if (curListCount == this.lists.length) {
      console.log(errMessage);
    } else {
      this.notificationService.success('Success!', successMessage);
    }
  }
  private async SaveChanges(UpdatedValues) {
    try {
      this.spinnerService.toggle(true);
      let updateResponse = await this.customerService
        .updateList(`list-${this.selectedList.ARN}`, UpdatedValues)
        .toPromise();
      console.log(updateResponse);

      if (updateResponse.error) {
        this.spinnerService.toggle();
        this.notificationService.error(
          'Oops!',
          'The list was not saved successfully. Please try again or contact support.'
        );
      } else {
        this.spinnerService.toggle();
        this.LoadSecrets(this.selectedList.Name);

        this.notificationService.success(
          'Success!',
          'The list was saved successfully'
        );
      }
    } catch (err) {
      console.log(err);
      this.notificationService.error(
        'Oops!',
        'The list was not saved successfully. Please try again or contact support.'
      );
    }
  }
}

// Process: Load Secrets -> Upon selection -> Load List from Secrets -> Check for Action
