<div class="row" *ngIf="userId == null">
  <div class="col-md-12">
    <div class="card be-loading">
      <div class="card-header card-header-divider">Alerting Configuration<span class="card-subtitle">Configfure your alerting properties</span></div>
      <div class="card-body">        
        <form [formGroup]="alertConfigForm" novalidate (ngSubmit)="save(alertConfigForm)">                                  
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Add a Subscription(s):</label>
            <div class="row col-8">
              <div class="col-6">
                <select multiple class="form-control topics" formControlName="topics">
                  <option *ngFor="let topic of topics;" [ngValue]="topic.id">{{topic.name}}</option>
                </select>
              </div>            
              <div class="col-2">
                <button type="button" (click)="addSubscription();" class="btn btn-rounded btn-space btn-primary" style="text-align: center;">Subscribe</button>
              </div>
            </div>              
        </div> 
        <div class="form-group row pt-1">
          <label class="col-12 col-sm-3 col-form-label text-sm-right">Existing Subscriptions</label>
          <div class="col-12 col-sm-8 col-lg-6">
            <select class="form-control subs" formControlName="subscriptions" name="subscriptions">
              <option *ngFor="let sub of subscriptions;" [ngValue]="sub">{{sub.topicName}}</option>
            </select>
            <button type="button" class="btn btn-space btn-secondary btn-sm" (click)="deleteSubs();">Delete selected</button>
          </div>
        </div>
        <div class="form-group row pt-1">
          <label class="col-12 col-sm-3 col-form-label text-sm-right">Vendor Filter</label>
          <div class="row col-8">
            <div class="col-6">
              <select class="form-control vendorFilters" multiple="" formControlName="vendorFilter" name="vendorFilter"> 
                <option *ngFor="let vendor of vendors;" [ngValue]="vendor.name">{{vendor.name}}</option>
              </select>      
            </div>
            <div class="col-2">
              <button type="button" [disabled]="!buttonsEnabled" class="btn btn-rounded btn-space btn-primary" (click)="updateFilter();">Save</button>
            </div>
          </div>                           
        </div>
        <!--
        <div class="form-group row pt-1">
          <label class="col-12 col-sm-3 col-form-label text-sm-right">Delivery Method</label>
          <div class="col-12 col-sm-8 col-lg-6 mt-2">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" checked="true" formControlName="deliveryEnabled">
              <span class="custom-control-label">Email</span>
              <input id="inputEmail" type="email" placeholder="" formControlName="emails[0]" class="form-control" formControlName="emails" />
            </label>          
          </div>
        </div>
        <div *ngIf="false" style="text-align: center">
          <button type="submit" [disabled]="!buttonsEnabled" class="btn btn-rounded btn-space btn-primary">Save Settings</button>
        </div>                    
        -->
        </form>        
      </div>
    </div>              
  </div>
</div>

<div *ngIf="userId != null">
  <form [formGroup]="alertConfigForm" novalidate (ngSubmit)="save(alertConfigForm)">                                  
    <div class="form-group row pt-1">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Add a Subscription(s):</label>
      <div class="row col-8">
        <div class="col-6">
          <select multiple class="form-control topics" formControlName="topics">
            <option *ngFor="let topic of topics;" [ngValue]="topic.id">{{topic.name}}</option>
          </select>
        </div>            
        <div class="col-2">
          <button type="button" (click)="addSubscription();" class="btn btn-rounded btn-space btn-primary" style="text-align: center;">Subscribe</button>
        </div>
      </div>              
  </div> 
  <div class="form-group row pt-1">
    <label class="col-12 col-sm-3 col-form-label text-sm-right">Existing Subscriptions</label>
    <div class="col-12 col-sm-8 col-lg-6">
      <select class="form-control subs" formControlName="subscriptions" name="subscriptions">
        <option *ngFor="let sub of subscriptions;" [ngValue]="sub">{{sub.topicName}}</option>
      </select>
      <button type="button" class="btn btn-space btn-secondary btn-sm" (click)="deleteSubs();">Delete selected</button>
    </div>
  </div>
  <div class="form-group row pt-1">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Vendor Filter</label>      
        <div class="row col-8">
          <div class="col-6">  
            <select class="form-control vendorFilters" multiple="" formControlName="vendorFilter" name="vendorFilter"> 
              <option *ngFor="let vendor of vendors;" [ngValue]="vendor.name">{{vendor.name}}</option>
            </select>      
          </div>
          <div class="col-2">
            <button type="button" [disabled]="!buttonsEnabled" class="btn btn-rounded btn-space btn-primary" (click)="updateFilter();">Save</button>
          </div>
        </div>                           
    </div>
  <!-- <div class="form-group row pt-1">
    <label class="col-12 col-sm-3 col-form-label text-sm-right">Delivery Method</label>
    <div class="col-12 col-sm-8 col-lg-6 mt-2">
      <label class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" checked="true" formControlName="deliveryEnabled">
        <span class="custom-control-label">Email</span>
        <input id="inputEmail" type="email" placeholder="" formControlName="emails[0]" class="form-control" formControlName="emails" />
      </label>          
    </div>
  </div> -->
  <!-- <div *ngIf="false" style="text-align: center">
    <button type="submit" [disabled]="!buttonsEnabled" class="btn btn-rounded btn-space btn-primary">Save Settings</button>
  </div>                     -->
  </form>
</div>

      
    