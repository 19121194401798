export class PageRunnerScriptHistory {

    constructor(
        public id:number, 
        public date:string,
        public duration:string,
        public errors:string
    ) {

    }
}
