import { Component, OnInit } from '@angular/core';
import * as jsdiff from 'diff';
import { Observable, forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from "../../../../environments/environment";
import { ActivatedRoute, Router } from '@angular/router';
import { DataExportService } from '../../../services/data-export/data-export.service';
import * as Diff2Html from 'diff2html';

@Component({
  selector: 'app-code-diff',
  templateUrl: './code-diff.component.html',
  styleUrls: ['./code-diff.component.css']
})
export class CodeDiffComponent implements OnInit {

  outputDiffHtml:string;

  hash1:string;
  hash2:string;

  constructor( public authHttp: HttpClient, private router: Router, public route:ActivatedRoute, public dataExportService:DataExportService) {     
  }

  ngOnInit() {
    console.log(this.route);
    console.log(this.route.snapshot.params);
    this.route.queryParams.subscribe((params)=>{
      let hash1 = params.h1,
          hash2 = params.h2;

      console.log(hash1, hash2);
    
      if ( hash1 != null && hash2 != null ){
        this.loadCodeFiles(hash1, hash2).subscribe((files)=>{
          this.updatePrettyDiff(files);
        });
      }
    });
  }

  doDiff(){
    console.log(this.hash1, this.hash2);
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: {h1: this.hash1, h2: this.hash2}, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  loadCodeFiles(file1, file2):Observable<any> {
    return forkJoin(
      this.loadCodeFile(file1),
      this.loadCodeFile(file2)
    );
  };

  loadCodeFile(hash, flag:string=".beautified"):Observable<any>{
    return this.dataExportService.getAnalysisFile(hash + flag);
  }

  updatePrettyDiff(files){
    var diff = jsdiff.createPatch(" ", files[1].toString(), files[0].toString(), this.route.snapshot.queryParams["h1"], this.route.snapshot.queryParams["h2"]);    
    let outputHtml = Diff2Html.html(diff, {matching: 'none', outputFormat:'side-by-side'});
    this.outputDiffHtml = outputHtml;
  }

}
