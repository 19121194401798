import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorDomain } from '../../../../models/VendorDomain';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { VendorsService } from '../../../../services/vendors/vendors.service';

@Component({
  selector: 'app-vendor-domains-list',
  templateUrl: './vendor-domains-list.component.html',
  styleUrls: ['./vendor-domains-list.component.css']
})
export class VendorDomainsListComponent implements OnInit {

  
  
  public vendorId:number;
  public vendorName?: string;
  listings:VendorDomain[];
  searchval:string; 

  constructor(
    private vendorService:VendorsService,
    private route:ActivatedRoute,
    private spinnerService:SpinnerService
  ) { 

  }

  ngOnInit() {    
    this.vendorId = this.route.snapshot.params["vendorId"];
    if ( this.vendorService.selectedVendor){
      this.vendorName =  this.vendorService.selectedVendor.name;
    } else {
      this.vendorName = this.vendorId + "";
    }
    
    this.loadData();
  }

  loadData(){
    this.spinnerService.toggle(true);
    this.vendorService.getDomainsForVendorById(this.vendorId).subscribe( (domains:VendorDomain[]) => {
      this.listings = domains;
      this.vendorService.loadDomain(domains);
      this.spinnerService.toggle(false);
    },
    (err)=>{
      this.spinnerService.toggle(false);
    });
  }

  
  

  onDeleteClicked(id):void{
    this.spinnerService.toggle(true);
    this.vendorService.deleteDomain(id).subscribe((response)=>{
      this.vendorService.getDomainsForVendorById(this.vendorId).subscribe((result)=>{
        this.spinnerService.toggle(false);
        this.listings = result
      })
    },
    (err)=>{
      this.spinnerService.toggle(false);
    });
  }

}
