export class PageHistory {

    constructor(
        public id:string, 
        public dataLocation:string,
        public lastChecked:string,
        public region?:string
    ) {

    }
}
