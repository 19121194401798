<div class="row">
    <div class="col-sm-12">
      <div class="card card-table card-border">
        <div class="card-header">
            <div class="tools">
              <!-- <button *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" type="button" class="btn btn-rounded btn-space btn-success" data-toggle="modal" data-target="#modal-bulk-update" ><i style="color: white;" class="icon icon-left zmdi zmdi-collection-plus"></i>Bulk Update</button>
              <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exportData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button> -->
              <button *ngIf="authService.userHasScopes(['write:admin'])" type="button" class="btn btn-rounded btn-space btn-success" data-toggle="modal" data-target="#modal-new" ><i style="color: white;" class="icon icon-left zmdi zmdi-collection-plus"></i>New</button>
            </div>
          <span class="title">Pages</span>&nbsp;<span class="icon zmdi zmdi-info" title="Pages" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
          <span class="card-subtitle">{{tooltipContent}}</span>
        </div>
        <div class="card-body">
          <ejs-grid #grid [dataSource]='pagesDataManager'
                        id="pages"
                        (dataSourceChanged)='dataSourceChanged($event)'
                        [allowPaging]="true" 
                        [allowSorting]="true"
                        [sortSettings]="sortSettings"
                        allowFiltering="true" 
                        [pageSettings]="pageOptions" 
                        [selectionSettings]="selectionSettings" 
                        [allowSelection]="false" 
                        [enableHover]="false" 
                        (dataStateChange)= 'dataStateChange($event)'
                        (actionBegin)='actionBegin($event)'
                        (actionComplete)="actionComplete($event)"
                        [filterSettings]="filterSettings"                        
                        locale='en-US'
                        allowResizing='true'
                        [editSettings]='editSettings'
                        [allowTextWrap]='true'
                        [enablePersistence]="true"
                        >
          <e-columns>
              <e-column field='link' headerText='Link' type="string" [allowEditing]="false" [minWidth]="400" [filter]="{params: {showSpinButton: false}}">
                <ng-template #template let-data>
                  <a [routerLink] = "['/pages', data.id]">{{data.link}}</a>
                </ng-template>
              </e-column>
              <e-column field='lastCheckedPageRunner' [format]="formatOptions" headerText='Last Checked' [allowEditing]="false" [allowSorting]="true" [allowFiltering]="true">
                  <ng-template #template let-data>{{ data.lastCheckedPageRunner ? data.lastCheckedPageRunnerFormatted : '(Not Checked)' }}</ng-template>
              </e-column>
              <e-column field="maxAgePageRunnerInMinutes" headerText="Frequency (Mins)" [allowEditing]="false" [allowSorting]="true" [allowFiltering]="true" format="N"></e-column>
              <e-column *ngIf="authService.isVaultUser()" field="lowMemoryFailCount" headerText="Low Memory Failures" [allowEditing]="false" [allowSorting]="true" [allowFiltering]="true"></e-column>
              <e-column *ngIf="authService.isVaultUser()" field="highMemoryFailCount" headerText="High Memory Failures" [allowEditing]="false" [allowSorting]="true" [allowFiltering]="true"></e-column>
          </e-columns>
        </ejs-grid>
        </div>        
      </div>
    </div>
  </div>

  <div class="custom-width modal fade" id="modal-bulk-update" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <h2>Bulk Update</h2>
            <p>This will update all {{ grid?.pageSettings.totalRecordsCount | number }} items you are currently viewing (inclusive of filters.) Leave blank if changes aren't desired.</p>
            <form [formGroup]="bulkUpdateForm">
              <div class="form-group row pt-1">
                <label class="col-12 col-sm-4 col-form-label text-sm-right">Page Runner Max Age (mins)</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input class="form-control" type="text" formControlName="maxAgePageRunnerInMinutes">
                </div>
              </div>
              <div class="form-group row pt-1">
                <label class="col-12 col-sm-4 col-form-label text-sm-right">Link Discovery Max Age (mins)</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input class="form-control" type="text" formControlName="maxAgeLinkDiscoveryInMinutes">
                </div>
              </div>
            </form>
            <div class="mt-8">
              <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
              <button class="btn btn-rounded btn-space btn-success" type="button" (click)="bulkUpdate();">Save</button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>

  <div class="custom-width modal fade" id="modal-new" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <h2>New Page Links</h2>
            <p>Add URL(s) to monitor. One URL per line</p>
            <form [formGroup]="newUrlForm">
              <div class="form-group row pt-1">
                <div style="width: 100%;" class="col-12">
                  <textarea style="height: 250px" class="form-control" type="text" formControlName="link"></textarea>
                  <button style="margin-top: 10px;" class="btn btn-rounded btn-space btn-success" type="button" (click)="parsePageLinksFromText();">Parse URLs</button>
                  <ejs-grid
                    [dataSource]="parsedPageLinks"
                    [editSettings]='newPageEditSettings'
                    (commandClick)="newPageCommandClick($event)"
                    (actionComplete)="newPageActionComplete($event)"
                    height="250px">
                    <e-columns>
                      <e-column headerText='' width='50' [commands]='newPageLinkCommands'></e-column>
                      <e-column field='link' headerText='Page URL'></e-column>
                      <e-column field='isParseURL' headerText='Valid URL' [autoFit]="true" [allowEditing]="false">
                        <ng-template #template let-data>
                          <span style="font-weight: bold; width: 100%; text-align: center">{{data.isParseURL ? 'Yes' : 'No'}}</span>
                        </ng-template>
                      </e-column>
                      <e-column field='alreadyInDB' headerText='Already In Database' [autoFit]="true" [allowEditing]="false">
                        <ng-template #template let-data>
                          <span style="font-weight: bold; width: 100%; text-align: center">{{data.alreadyInDB ? 'Yes' : 'No'}}</span>
                        </ng-template>
                      </e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>              
            </form>
            <div class="mt-8">
              <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
              <button class="btn btn-rounded btn-space btn-success" type="button" (click)="addPageLinks();">Submit</button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>