<form [formGroup]="outputFormGroup">
    <div class="form-group row pt-1">
      <div class="col-12 col-lg-8">
        <ejs-textbox #txtPhoneNumber placeholder="Phone number ( ie '+12345678901' )" floatLabelType="Auto" (change)="ontxtChange($event)"></ejs-textbox>
      </div>
    </div>
    <div class="form-group row pt-1">
      <div class="col-12 col-lg-8">
        <ejs-listview #recipientsList
          id='recipientsList' 
          [dataSource]='recipients' 
          [fields]='recipientsLVFields' 
          (actionComplete)='onLVComplete()' 
          height="100px">
            <ng-template #template let-data>
              <div class='text-content'> {{data.text}} <span class='delete-icon e-icons'></span> </div>
            </ng-template>
        </ejs-listview>
      </div>
    </div>                
    <div class="form-group row pt-1">
      <div class="col-12 col-lg-8" style="height: 300px">
        <ejs-richtexteditor #tributeRTE
              id='tributeRTE'       
              height="275px"
              [enableResize]="true"
              editorMode="Markdown"
              (created)='onCreate()' 
              (actionBegin)='actionBeginEvent($event)'
              [toolbarSettings]="toolbarSettings"
              formControlName="body"
              placeholder='Press # to insert and filter properties of the currently selected event.'>
          </ejs-richtexteditor>
      </div>
    </div>
  </form>