import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CustomerService } from '../../../services/customer/customer.service';
import { NotificationsService } from 'angular2-notifications';
import { GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../services/spinner/spinner.service';

@Component({
  selector: 'app-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.css']
})
export class TotalsComponent implements OnInit {
  notificationOptions:any;
  data:any;
  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public cdnUrl:string;
  public dateFormat:any;
  @ViewChild('grid') public grid: GridComponent;

  constructor(
    private customerService:CustomerService, 
    public notificationService:NotificationsService,
    private spinnerService:SpinnerService
    ) {

    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 100, 
      pageCount: 4, 
      pageSizes: [100, 200]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'client', direction: 'Ascending'}  
      ]
    }
  }

  ngOnInit() {
    this.spinnerService.toggle(true);
    this.customerService.getEventsTotals().subscribe((totals)=>{
      this.spinnerService.toggle(false);
      this.data = totals;
    },
    (err)=>{
      this.spinnerService.toggle(false);
      this.notificationService.success('Error!', 'Unable to get data. Try again or contact support.', this.notificationOptions);
    });
    
  }

}
