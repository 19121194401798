import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ClipboardService } from 'ngx-clipboard';
import { concat, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, mergeMap, retryWhen, scan, take } from 'rxjs/operators';
import { ValidationRule } from '../../../../models/ValidationRule';
import { ValidationRuleHistory } from '../../../../models/ValidationRuleHistory';
import { AuthService } from '../../../../services/auth/auth.service';
import { CustomerService } from '../../../../services/customer/customer.service';
import { DataExportService } from '../../../../services/data-export/data-export.service';
import { DataValidationService } from '../../../../services/data-validation/data-validation.service';
import { PageService } from '../../../../services/page/page.service';
import { SitemapService } from '../../../../services/sitemap/sitemap.service';
declare var $:any;
import * as moment from 'moment';
import { DataStateChangeEventArgs, GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Ej2RuleHistoryService } from '../../../../services/ej2Proxies/ej2-rule-history.service';
import { TimezoneService } from '../../../../services/timezone.service';

@Component({
  selector: 'app-rule-details',
  templateUrl: './rule-details.component.html',
  styleUrls: ['./rule-details.component.css']
})
export class RuleDetailsComponent implements OnInit {

  ruleHistory:ValidationRuleHistory[];
  ruleHistoryLoading:boolean;
  tooltipContent:string;

  rule:ValidationRule;

  selectedRuleHistory:ValidationRuleHistory;

  exportId:string;
  pollingTimer:any;
  notificationOptions:any;
  exportButtonDisabled:boolean;
  pollingTimerCounter:number;
  pollingTime:number;
  
  public ej2HistoryData: Observable<any>;
  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('ruleHistoryGrid') public grid: GridComponent;

  
  constructor(private el: ElementRef, 
    private pageService:PageService,
    private router: Router, 
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private customerService:CustomerService,
    private dataExportService:DataExportService,
    private notificationService: NotificationsService,
    private zone:NgZone,
    private authService:AuthService,
    private _clipboardService: ClipboardService,
    private dataValidationService:DataValidationService,
    private ej2ProxyService:Ej2RuleHistoryService,
    private timezoneService:TimezoneService
  ) { 
    this.sitemapService.update(route);
    this.pollingTime = 1000;
    this.sitemapService.update(this.route);
    this.ruleHistoryLoading = false;
    this.tooltipContent = "Review execution history for a validation rule";
    this.notificationOptions = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.exportButtonDisabled = false;
    this.rule = this.dataValidationService.selectedValidationRule.value;

    this.ej2HistoryData = ej2ProxyService;
    let state = { skip: 0, take: 20 };
    this.ej2ProxyService.execute(state, this.dataValidationService.selectedValidationRule.value.uuid);  

    this.dateFormat = {type: 'dateTime', skeleton:'short'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'epoch', direction: 'Ascending'}          
      ]
    };
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);
    this.grid.hideSpinner();
    this.ej2ProxyService.execute(state, this.dataValidationService.selectedValidationRule.value.uuid);
  }


  ngOnInit() {
  }

}
