<ul class="nav navbar-nav float-right be-user-nav row">
  
  <div class="page-title">
    <div *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser(); else CustomerNameTemplate">
      <ejs-dropdownlist 
        id='customer' 
        [dataSource]='customers' 
        [value]='currentCustomerId'
        [fields]='customerFields' 
        (filtering)='onFiltering($event)' 
        [filterBarPlaceholder]="filterPlaceholder"
        [allowFiltering]='true' 
        [placeholder]='watermark'
        (change)='onCustomerChange($event)'
        [width]="250"
        >
        <ng-template #itemTemplate let-data>
            <div>
                <img style="width: 32px; height:32px;margin: 5px 5px 0px 10px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.icon ? data.icon.toLowerCase() : 'unknown' }}.ico"/> {{data.name}}                 
            </div>
        </ng-template>
        <ng-template #valueTemplate let-data>
            <div>
                <img style="width: 32px; height:32px;margin: 0px 5px 0px 10px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.icon ? data.icon.toLowerCase() : 'unknown' }}.ico"/>{{data.name}}                 
            </div>
        </ng-template>      
      </ejs-dropdownlist>
    </div>
    <ng-template #CustomerNameTemplate>
      <span><img style="width: 32px; height:32px;margin: 0px 5px 0px 10px;" src="https://{{cdnUrl}}/vendors/icon/{{ currentCustomer.icon ? currentCustomer.icon.toLowerCase() : 'unknown' }}.ico"/>{{customerName}}</span>
    </ng-template>
  </div>
  <li class="nav-item dropdown">
    <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
      <img src="{{userProfile.picture}}" alt="Avatar">
    </a>
    <div role="menu" class="dropdown-menu">     
      <div class="user-info">
        <div class="user-name">{{userProfile.nickname}}</div>
      </div>
      <a [routerLink]="['/profile']" class="dropdown-item"><span class="icon zmdi zmdi-face"></span>Account</a>      
      <a [routerLink]="['/logout']" class="dropdown-item"><span class="icon zmdi zmdi-power"></span> Logout</a>
    </div>    
  </li>
</ul>
<div class="row justify-content-end">{{sessionExpiration}}</div>