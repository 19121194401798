import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../../../../services/spinner/spinner.service';
import { VendorsService } from '../../../../../services/vendors/vendors.service';

declare var $: any;

@Component({
  selector: 'app-domainedit',
  templateUrl: './domainedit.component.html',
  styleUrls: ['./domainedit.component.css']
})
export class DomaineditComponent implements OnInit {
  private vendorId: number;
  domainsForm:FormGroup;

  get domains(){
    return this.domainsForm.get("domains") as FormArray;
  }

  constructor(private vendorservice: VendorsService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private _fb: FormBuilder,
    private spinnerService:SpinnerService) {

    this.domainsForm = this._fb.group({
      domains: this._fb.array([
        this._fb.group(
        {
          id: "",
          name: "",
          hostname: ""
        })
      ])
    })

  }

  ngOnInit() {
    this.vendorId = this.activatedRoute.snapshot.params["vendorId"];
    this.spinnerService.toggle(true);
    this.vendorservice.getDomainsForVendorById(this.vendorId).subscribe((response) => {
      this.spinnerService.toggle(false);
      this.domainsForm = this._fb.group({
        domains: this._fb.array(response.map((r)=>{return this._fb.group(r)}))
      });
    },
    (err)=>{
      this.spinnerService.toggle(false);
    });
  }

  updateDomain() {
    this.spinnerService.toggle(true);
    this.vendorservice.updateDomain(this.domainsForm.value.domains).subscribe((result)=>{
      this.spinnerService.toggle(false);
      console.log(result, "data updated successfully");
      this.router.navigate(['/vendorDomains', this.vendorId]);
    },
    (err)=>{
      this.spinnerService.toggle(false);
    });
  }
}
