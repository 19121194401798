import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import { VaultBaseService } from '../VaultBaseService';
import { tap } from 'rxjs/operators';
import { CreateObjectResponse } from '../../components/vendorLibrary/models/CreateObjectResponse';
import { ConfigManagement } from '../../components/configManagement/config-management/models/ConfigManagement';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends VaultBaseService{

  private readonly END_POINT:string = `${env.api.url}pageRunner/`
  private configName: string;

  public configById:Map<number, ConfigManagement>;
  public configs:ConfigManagement[];

  constructor(authHttp: HttpClient) {
    super(authHttp);
    this.configById = new Map<number, ConfigManagement>();
  }

  createConfig(config:ConfigManagement):Observable<CreateObjectResponse>{
    const url = `${this.END_POINT}linkConfig`;
    return this.authHttp.post<CreateObjectResponse>(
      url,
      JSON.stringify(config)
    );
  }

  createConfigLink(configId:number, linkIds:number[]):Observable<any>{
    const url = `${this.END_POINT}linkConfig/${configId}`;
    return this.authHttp.post(url, JSON.stringify(linkIds));
  }

  getConfig(linkConfigId:number, includeLinks:boolean=false):Observable<any>{
    const url = `${this.END_POINT}linkConfig/${linkConfigId}`;
    const params = new HttpParams().set("full", includeLinks);
    return this.authHttp.get(url, {params});
  }

  getAllConfigs(includeLinks:boolean=false, consentOnly:boolean=false):Observable<Object>{
    const url = `${this.END_POINT}linkConfig`;
    const params = new HttpParams().set("totals", includeLinks).set("consent", consentOnly);
    return this.makeHttpRequest(url, params).pipe(tap((results)=>{
      this.configs = results;
      this.configs.forEach((key)=>{
        this.configById[key.id] = key;
      });
    }));
  }

  getChangedProperties(currentObject, changedObject){
    let returnObj = { };
    let props = Object.keys(currentObject);
    for(var prop of props){
      if ( currentObject[prop] != changedObject[prop] && Array.isArray(currentObject[prop]) == false) {
        returnObj[prop] = changedObject[prop];
      }
    }
    if ( Object.keys(returnObj).length > 0) {
      returnObj["id"] = currentObject.id;
      return returnObj;
    }
    return null;
  }

  updateConfig(configId, config):Observable<any>{
    const url = `${this.END_POINT}linkConfig/${configId}`;
    return this.authHttp.put(url, JSON.stringify(config));
  }

  deleteConfig(linkConfigId:number, recursive=false):Observable<any>{
    const url = `${this.END_POINT}linkConfig/${linkConfigId}`;
    const params = new HttpParams().set("recursive", recursive.toString());
    return this.authHttp.delete(url, {params});
  }

  deleteConfigLink(linkConfigId:number, linkId:number):Observable<any>{
    const url = `${this.END_POINT}linkConfig/${linkConfigId}/${linkId}`;
    return this.authHttp.delete(url);
  }

  setConfigName(configName:string): void {
    this.configName = configName;
  }

  getConfigName(): string {
    return this.configName;
  }
}
