import { Component, OnInit } from '@angular/core';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { ActivatedRoute } from '@angular/router';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';
import { PageService } from '../../../services/page/page.service';
import { TimezoneService } from '../../../services/timezone.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';

@Component({
  selector: 'app-analysis-details',
  templateUrl: './analysis-details.component.html',
  styleUrls: ['./analysis-details.component.css']
})
export class AnalysisDetailsComponent implements OnInit {

  STEP_DICT = {
    "schedule-collection-start": {
      title: "Page Run Scheduled"
    }
  };


  // [
  //   'schedule-collection-start',
  //   'pageRunner-collection-start',
  //   'pageRunner-collection-output',
  //   'extractScreenshots-analysis-start',
  //   'processCustomData-analysis-start',
  //   'extractScreenshots-analysis-complete',
  //   'processCustomData-analysis-output',
  //   'processCustomData-analysis-complete',
  //   'mergedRequestsWithResponses-indexing-output',
  //   'undefined-indexing-complete',
  //   'saveNewScriptResponseHashes-analysis-start',
  //   'classifyWithVendorLibrary-analysis-start',
  //   'classifyWithVendorLibrary-analysis-output',
  //   'classifyWithVendorLibrary-analysis-complete',
  //   'saveNewScriptResponseHashes-analysis-output',
  //   'saveNewScriptResponseHashes-analysis-complete',
  //   'beautifyNewCodeHash-analysis-start',
  //   'checkWithRetireJs-analysis-start',
  //   'generateRequestTree-analysis-start',
  //   'generateDataSharingGraph-analysis-start',
  //   'checkWithRetireJs-analysis-complete',
  //   'beautifyNewCodeHash-analysis-complete',
  //   'generateRequestTree-analysis-complete',
  //   'generateAstAndSimpleAnalysis-analysis-start',
  //   'generateDataSharingGraph-analysis-complete',
  //   'generateAstAndSimpleAnalysis-analysis-complete',
  //   'generateControlFlowGraph-analysis-start',
  //   'generateControlFlowGraph-analysis-complete',
  //   'finishAnalysis-analysis-start',
  //   'finishAnalysis-analysis-complete'
  // ]



  steps: any[];

  constructor(
    private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private vendorlibService: VendorLibraryService,
    private pageService: PageService,
    private tzService: TimezoneService,
    private spinnerService:SpinnerService
  ) {
    this.sitemapService.update(this.route);
    this.steps = [];
  }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    let ph = this.pageService.selectedPageHistory.value;
    let jobUuid = ph.id
    console.log(jobUuid);
    this.vendorlibService.getPageRunAnalysisDetails("", jobUuid).subscribe((response) => {
      this.spinnerService.toggle();
      console.log(response);
      this.steps = response.map((step) => {
        let txed = { 
          ...step, 
          stepKey: `${step.data?.jobStepType}-${step.data?.jobStep}-${step.data?.event}`,
          formattedTimestamp: this.tzService.format(step.timestamp,false, true, false, 'L/d/yy h:mm:ss:SSS a ZZZZ')
        };
        // console.log(txed);formattedTimestamp
        return txed;
      })
    });
  }

  findStep(steps){    
    if ( Array.isArray(steps) ){
      return null;
    } 
    let ret = this.steps.find((s)=>{return s.stepKey == steps});
    return ret ? {...ret, found: true} : {found: false, formattedTimestamp: "(Not found)"}
  }

  getStatusStyleForStep(steps, foundClass, notFoundClass, lookForStartAndComplete=false){
    
    let found = this.findStep(steps);
  
    if ( lookForStartAndComplete ){
      let completeFound = this.findStep(steps.replace("-start", "-complete"));
      found = found && completeFound;
    }

    if ( found.found == true ){
      return foundClass;
    }
    return notFoundClass
  }

}
