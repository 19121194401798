import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AlertingComponent } from './alerting.component';
import { AlertDetailsComponent } from './alert-details/alert-details.component';
import { ConfigComponent } from './config/config.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [    
    AlertingComponent,
    AlertDetailsComponent,
    ConfigComponent
  ],
  exports: [
    AlertingComponent,
    AlertDetailsComponent,
    ConfigComponent
  ]
})
export class AlertingComponentsModule { }
