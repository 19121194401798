import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { PageRunnerScript } from "../models/PageRunnerScript";
import { PageRunnerService } from "../services/data-export/page-runner.service";

@Injectable()
export class PageRunnerScriptResolver implements Resolve<PageRunnerScript> {
    
    constructor(private pageRunnerService:PageRunnerService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<PageRunnerScript> {
        
        console.log("Automated Users Resolver", route);
        const scriptId = route.params["scriptId"];

        if ( scriptId == "new"){
            return of(null);
        } else {
            this.spinnerService.toggleRoot(true);
            return this.pageRunnerService.getScripts(scriptId, 0, 1, "id", "desc", "", 1).pipe(tap(data=>{
                console.log(data);
                this.spinnerService.toggleRoot(false);
                this.pageRunnerService.selectedScript.next(data.data[0]);
            }));      
        }
    }
}