import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { Observable, forkJoin, Subscription, timer, of, throwError, concat } from 'rxjs';
import { VendorsService } from '../../../services/vendors/vendors.service';
declare var BeagleApp: any;
declare var $: any;
declare var window: any;
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { TextMarkerOptions } from 'codemirror';
import { DiffService } from '../../../services/workers/diff-service.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { CustomerService } from '../../../services/customer/customer.service';
import * as Diff2Html from 'diff2html';
import { environment as env } from "../../../../environments/environment";
import { catchError, concatMap, delay, mergeMap, retryWhen, scan, take } from 'rxjs/operators';
import { AuthService } from '../../../services/auth/auth.service';
import { AnalysisService } from '../../../services/analysis/analysis.service';
import { GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TimezoneService } from '../../../services/timezone.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { error } from 'console';

@Component({
  selector: 'app-code-file-detail',
  templateUrl: './code-file-detail.component.html',
  styleUrls: ['./code-file-detail.component.css']
})
export class CodeFileDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  colors = (<any>BeagleApp).color;
  codeFileContent;
  codeMirrorOptions: any;
  history: any[];
  singleFileView: boolean;
  mergeView: any;
  fileHistorySub: Subscription;
  changesSub: Subscription;
  vendor: string;
  @ViewChild('codeViewer') codeViewer: CodemirrorComponent;
  categoryColors: any;
  selectedItems: any[];
  codeLoading: boolean;
  graphLoading: boolean;
  fileName: string;
  changes: any[];
  alerts: any[];
  fileToDLSuffix: string;
  analysisInfo: any;
  analysisPosition: number;
  xLabelText: string;
  curItem: any;

  isBeautificationTabActive: boolean;
  isRawTabActive: boolean;
  isAnalysisTabActive: boolean;
  isDiffTabActive: boolean;

  rulesTabActive: boolean;
  diffTabActive: boolean;

  outputHtml: string;
  hash1: string;
  hash2: string;
  radioHash: number;
  diffSub: Subscription;
  astText: string;
  analysisLoading: boolean;
  activeAnalysisTab: string;
  codeFileFunctions: string[];
  codeFileVars: string[];
  codeFileLiterals: string[];
  invocations: any;
  numLines: number;
  functionsDtOptions: any;
  varsDtOptions: any;
  literalsDtOptions: any;
  pagesDtOptions: any;
  pages: any[];
  analysisFeatures: string;
  historyDataError: string;

  public pageSettings: PageSettingsModel;
  public pagesSortSettings: SortSettingsModel;
  public functionsSortSettings: SortSettingsModel;
  public toolbar: string[];
  public dateFormat: any;
  @ViewChild('pagesGrid') public grid: GridComponent;

  constructor(private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private vendorService: VendorsService,
    private diffService: DiffService,
    private dataExportService: DataExportService,
    private customerService: CustomerService,
    private notificationService: VaultNotificationService,
    private authService: AuthService,
    private analysisService: AnalysisService,
    private timezoneService: TimezoneService) {

    this.sitemapService.update(route);
    this.historyDataError = null;

    this.singleFileView = true;

    this.rulesTabActive = false;
    this.diffTabActive = false;

    this.isBeautificationTabActive = false;
    this.isRawTabActive = true;
    this.isAnalysisTabActive = false;
    this.isDiffTabActive = false;

    this.fileToDLSuffix = "";
    this.codeLoading = true;
    this.fileName = "";
    this.activeAnalysisTab = "overview";
    this.numLines = null;

    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      tabSize: 2,
      readOnly: true,
      theme: 'material',
      gutters: ["CodeMirror-linenumbers", "analysis"]
    };
    this.radioHash = 0;

    this.outputHtml = "Select two files to compare...";

    this.dateFormat = { type: 'dateTime', skeleton: 'short' }
    this.pageSettings = {
      currentPage: 1,
      pageSize: 20,
      pageCount: 4,
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.pagesSortSettings = {
      columns: [
        { field: 'link', direction: 'Descending' }
      ]
    };
    this.functionsSortSettings = {
      columns: [
        { field: 'name', direction: 'Ascending' }
      ]
    };
  }

  onCopied(event) {
    this.notificationService.success('Copied', 'Full URL was copied to clipboard.');
  }

  onCopiedHash(event) {
    this.notificationService.success('Copied', 'File hash was copied to clipboard.');
  }

  ngOnInit() {

    const params = this.route.snapshot.params;

    console.log("aaaaa", this.route.snapshot.data);

    if (params.vendor && params.fileId) {

      this.vendor = params.vendor;
      const isLib = this.route.snapshot.url.join('').indexOf("library") > -1;

      // const isHistoryId = this.route.snapshot.queryParams.hid ? true : false;
      this.customerService.getPagesForCodefile(params.fileId, false).subscribe((pages) => {
        console.log(pages);
        this.pages = pages;
      });

      this.fileHistorySub = this.vendorService.getCodeFileHistory(params.vendor, params.fileId, isLib, false).subscribe((fileHistory) => {

        console.log("FileHistory!");
        console.log(fileHistory);
        if (fileHistory && fileHistory.length == 0) {
          this.history = [];
          this.analysisLoading = false;
          this.codeLoading = false;
          return;
        }
        this.history = fileHistory.map((item) => {
          return { ...item, lastcheckedFormatted: this.timezoneService.format(item.epoch), enabled: true, selected: false };
        });//.slice(0, 25);
        //console.log(this.history.length);
        var historyItemToSelect = null;
        if (this.route.snapshot.queryParams.uuid) {
          var revIndex = -1;
          const rev = this.history.filter((item, i) => {
            if (item.uuid == this.route.snapshot.queryParams.uuid) {
              revIndex = i;
              return true;
            }
            return false;
          })
          console.log("REV", rev);
          if (rev && revIndex >= 0) {
            //console.log("RevIndex", revIndex);
            // this.history[revIndex].selected = true;
            this.radioHash = revIndex;
            historyItemToSelect = this.history[revIndex];
            window["historyScrollPercent"] = revIndex / this.history.length;
          } else {
            this.radioHash = 0;
            historyItemToSelect = this.history[0];
          }
        } else {
          this.radioHash = 0;
          historyItemToSelect = this.history[0];
        }

        this.radioLog(historyItemToSelect, "");

        this.fileName = historyItemToSelect.name;

        timer(500).subscribe(()=>{
          $('[data-toggle="popover"]').popover();
        });
      },
      (error)=>{
        console.log("Get history error handler: ")
        console.log(error);
        if (error.code && error.code == 666 ){
          this.historyDataError = error.message;
        }
        this.history = [];
        this.analysisLoading = false;
        this.codeLoading = false;
      });
    } else {
      this.history = [];
      this.analysisLoading = false;
      this.codeLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.fileHistorySub.unsubscribe();
    if (this.diffSub) {
      this.diffSub.unsubscribe();
    }
  }

  onHistorySelected(item) {

    //console.log("thee Item");
    //console.log(item);
    if (item == null) return;
    this.selectedItems = this.history.filter(h => h.selected);

    this.history.forEach((item, index) => {
      if (!item.selected && !item.enabled) {
        item.enabled = true;
      }
      item.diffLabel = "";
    });

    //console.log(this.selectedItems);

    if (!this.selectedItems) {
      return;
    }

    if (this.selectedItems.length < 2) {
      this.outputHtml = "Select two files to compare...";
    } else if (this.selectedItems.length == 2) {

      this.outputHtml = "Select two files to compare...";
      this.isDiffTabActive = true;
      this.isBeautificationTabActive = this.isRawTabActive = this.isAnalysisTabActive = false;

      this.history.forEach((item, index) => {
        if (!item.selected) {
          item.enabled = false;
        } else {
          item.enabled = true;
        }
      });

      this.selectedItems[1].diffLabel = "L";
      this.selectedItems[0].diffLabel = "R";

      this.codeLoading = true;
      this.hash1 = this.selectedItems[0].currenthash;
      this.hash2 = this.selectedItems[1].currenthash;

      this.loadCodeFiles(this.hash1, this.hash2).subscribe(files => {
        this.diffSub = this.diffService.startDiff(files[1].toString(), files[0].toString()).subscribe((result) => {
          if (!result.data || result.data == "") {
            console.log("EMPTY/NULL from worker");
          } else {
            let outputHtml = Diff2Html.html(result.data, { matching: 'none', outputFormat: 'side-by-side' });
            this.outputHtml = outputHtml;
            this.codeLoading = false;
          }
        });
      },
        (err) => {
          console.error(err);
          this.codeLoading = false;
        }
      );

    }
  };

  loadCodeFiles(file1, file2): Observable<any> {
    return forkJoin(
      this.loadCodeFile(file1),
      this.loadCodeFile(file2)
    );
  };

  loadCodeFile(hash, flag: string = ".beautified"): Observable<any> {
    const cachedFile = localStorage.getItem(`${hash}${flag}`);
    // //console.log(cachedFile);
    if (cachedFile) {
      return of(cachedFile);
    }

    return this.dataExportService.getAnalysisFile(hash + flag).pipe(catchError((error) => {
      return this.dataExportService.getAnalysisFile(hash + flag.replace(".", "-"), env.aws.codeFileBucketLegacy).pipe(catchError(error => of(null)));
    }));

  }

  onAnalysisTabClick(event) {
    this.activeAnalysisTab = event.target.id;

    if (event.target.nodeName == "SPAN") {
      this.activeAnalysisTab = event.target.parentNode.id;
    }

  }

  onDiffTabClick(event) {
    console.log(event);

    console.log(this.isBeautificationTabActive, this.isRawTabActive, this.isAnalysisTabActive);

    if (this.selectedItems && this.selectedItems.length == 2) {
      this.onTabClick(event);
    } else {
      this.notificationService.info("Info", "Please select two versions to compare.");
      this.isDiffTabActive = false;
      setTimeout(() => {
        $("#diff").removeClass('active');
        if (this.isBeautificationTabActive == true) {
          $("#beaut").addClass('active');
        } else if (this.isRawTabActive == true) {
          $("#raw").addClass('active');
        } else if (this.isAnalysisTabActive == true) {
          $("#analysis").addClass('active');
        }
      }, 50);
      event.preventDefault();
      return false;
    }
  }

  onTabClick(event) {

    // console.log("Tab raw event", event);

    var tabId = event.target.id;
    console.log("Tab ID", tabId);

    if (event.target.nodeName == "SPAN") {
      tabId = event.target.parentNode.id;
    }

    this.isBeautificationTabActive = (tabId == "beaut");
    this.isRawTabActive = (tabId == "raw");
    this.isAnalysisTabActive = (tabId == "analysis");
    this.isDiffTabActive = (tabId == "diff");

    if (this.selectedItems && this.selectedItems.length > 1) {
      //console.log("Preventing clicks since tabs are disabled or no item is selected");
      event.preventDefault();
      return false;
    }

    this.fileToDLSuffix = ".beautified";
    if (tabId == "raw") {
      this.fileToDLSuffix = "";
    } else if (tabId == "deobf") {
      this.fileToDLSuffix = ".deobfuscated";
    } else if (tabId == "analysis") {
      this.fileToDLSuffix = ".beautified";
    }

    //console.log("fileToDLSuffix", this.fileToDLSuffix);

    this.codeLoading = true;
    const fileHash = this.history[this.radioHash].currenthash;

    // this.loadCodeFile(fileHash, this.fileToDLSuffix).pipe(catchError(error=>of(error))).subscribe( response => {

    this.loadCodeFile(fileHash, this.fileToDLSuffix).subscribe(response => {
      console.log('LOAD CODE FILE RESPONSE:');
      console.log(response);
      if (!response) {
        this.codeLoading = false;
        this.codeFileContent = "// Unable to load file contents...";

        this.notificationService.warn("Hmm...", "That file/content was not found. Please try again or contact support.")
        return;
      }
      console.log('*************************************');
      const fileContents = response.toString();
      //console.log("File DLed")
      this.codeFileContent = fileContents;
      this.codeViewer.codeMirror.setSize("100%", "475px");
      if (tabId == "analysis") {
        this.analysisPosition = -1;
        this.dataExportService.getAnalysisFile(fileHash + ".simpleAnalysis").subscribe((response) => {
          var analysis = response ? JSON.parse(response.toString()).basicAnalysisResults : [];
          console.log(analysis);
          this.analysisInfo = analysis;
          for (let index = 0; index < analysis.length; index++) {
            var item = analysis[index];
            const start: CodeMirror.Position = { line: (item.location.start.line - 1), ch: item.location.start.column };
            const end: CodeMirror.Position = { line: (item.location.end.line - 1), ch: item.location.end.column };
            var options: TextMarkerOptions = { css: "color : white; background-color: #ff0000" };
            console.log(start, end, options);
            this.codeViewer.codeMirror.getDoc().markText(start, end, options);
          };
          this.codeLoading = false;
        },
          (analysisErr) => {
            console.log('analysis ERROR!');
            console.log(analysisErr);
            this.analysisInfo = [];
            this.codeLoading = false;
            this.codeFileContent = false;
          });
      } else {
        this.analysisInfo = null;
        this.codeLoading = false;
        this.codeViewer.codeMirror.getDoc().getAllMarks().forEach((markedText) => {
          markedText.clear();
        });
      }

    },
      (err) => {

        console.log('LOAD CODE FILE ERROR:');
        console.log(err);
        console.log('*************************************');


        this.codeLoading = false;
        this.codeFileContent = "// Unable to load file contents...";
      }
    );
  }

  ngAfterViewInit(): void {
    this.codeViewer.codeMirror.setSize("100%", "475px");

  }

  next(direction) {

    if (!this.analysisInfo || (this.analysisPosition <= 0 && direction == -1) || (this.analysisPosition >= this.analysisInfo.length - 1 && direction == 1)) {
      return;
    }

    //console.log(direction);

    var scrollViewPosition: CodeMirror.Position;
    if (direction == 'start') {
      scrollViewPosition = { line: (0), ch: 0 };
      this.analysisPosition = -1;
    } else if (direction == 'end') {

      const numLines = this.codeFileContent.split(/\r\n|\r|\n/).length;
      scrollViewPosition = { line: (numLines - 1), ch: 0 };
      this.analysisPosition = this.analysisInfo.length;
    } else {
      this.analysisPosition += direction
      const item = this.analysisInfo[this.analysisPosition];
      console.log("ITEM");
      console.log(item);
      if (!item) {
        return;
      }
      scrollViewPosition = { line: (item.location.start.line - 1), ch: item.location.start.column };

      if (this.curItem) {
        window.gutterId = this.codeViewer.codeMirror.clearGutter("analysis");
      }

      window.gutterId = this.codeViewer.codeMirror.setGutterMarker(item.location.start.line - 1, "analysis", this.makeMarker());

      this.curItem = item;
    }
    this.codeViewer.codeMirror.scrollIntoView(scrollViewPosition);
  }

  makeMarker() {
    var marker = document.createElement("div");
    marker.style.color = "#f00";
    marker.innerHTML = "●";
    return marker;
  }

  downloadFile(hash = null) {

    const fileToDL = hash ? hash : this.selectedItems[0].hash;

    this.dataExportService.getAnalysisFile(fileToDL).subscribe((response) => {
      this.dataExportService.saveFile(fileToDL, response.toString(), DataExportService.JS_EXTENSION);
    });
  }

  radioLog(item, flag = ".beautified") {

    this.isRawTabActive = true;
    this.isBeautificationTabActive = this.isAnalysisTabActive = this.isDiffTabActive = false;

    this.codeLoading = true;
    //pipe(catchError(error => of(null))
    this.loadCodeFile(item.currenthash, flag).pipe(catchError(error=>of(null))).subscribe(response => {
      console.log("resonse xxxx");
      console.log(response);
      var fileData = null;
      if (response == null || response == "null") {
        this.codeFileContent = "// Unable to load file. Please try again or contact support."
        this.codeViewer.codeMirror.setSize("100%", "475px");
        this.analysisLoading = false;
        this.codeLoading = false;
        return;
      } else {
        fileData = response.toString();
      }

      const fileContents = fileData;
      this.analysisLoading = true;

      this.dataExportService.getAnalysisFile(item.currenthash + ".simpleAnalysis").subscribe((response) => {
        console.log("AST RESPONSE");
        const parseResult = JSON.parse(response.toString());
        console.log("basicAnalysisResults");
        // console.log(JSON.stringify(parseResult.basicAnalysisResults));
        this.codeFileFunctions = parseResult.functions.map((f) => { return { name: f } });
        this.codeFileFunctions = this.codeFileFunctions.length == 0 ? null : this.codeFileFunctions;
        this.codeFileVars = parseResult.variableNames.map((f) => { return { name: f } });
        this.codeFileLiterals = parseResult.literals.map((f) => { return { name: f } });
        // this.invocations = parseResult.invocations.map((f)=>{return {name: f}});
        this.analysisFeatures = this.analysisService.processAnalysis(parseResult.basicAnalysisResults);
        this.analysisLoading = false;
      },
        (err) => {
          console.log("AST Error");
          console.log(err);
          this.analysisLoading = false;
        });

      this.codeFileContent = fileContents;
      this.codeViewer.codeMirror.setSize("100%", "475px");
      this.codeViewer.codeMirror.refresh();
      this.codeLoading = false;
      this.numLines = fileContents.split(/\r\n|\r|\n/).length;

      setTimeout(() => {
        if (window["historyScrollPercent"]) {
          $(".card")[0].scrollTop = $(".card")[0].scrollHeight * window["historyScrollPercent"];
        }
      }, 10);

    },
      (err) => {
        console.log("ajsldkfkjaslkfjasdlkfjsdklf");
        console.log(err);
        // console.log(err);
        this.codeLoading = false;
        this.codeFileContent = "// Unable to load file. Please try again or contact support."
        this.codeViewer.codeMirror.setSize("100%", "475px");
      });
  }
}
