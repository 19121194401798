import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'multi-pie-chart',
  templateUrl: './multi-pie-chart.component.html',
  styleUrls: ['./multi-pie-chart.component.css'],
})
export class MultiPieChartComponent implements AfterViewInit, OnChanges {
  @Input('datasource') datasource: any;
  @Input('id') id: any;
  @Input('limit') limit: boolean = false;
  public data: any;
  public flattenedData: any;
  constructor() {
    // this.id = Math.random().toString(36).substring(7);
    console.log(this.id);
    if (!this.id) {
      this.id = Math.random().toString(36).substring(7);
    }
  }

  ngAfterViewInit(): void {
    if (this.limit) {
      this.data = this.groupAfter10(this.datasource);
    } else {
      this.data = this.datasource;
    }
    this.flattenedData = this.createArcs(this.data);
    console.log(this.flattenedData);
    this.createRingChart(this.flattenedData, this.id + '_container', 180, 180);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasource) {
      if (this.limit) {
        this.data = this.groupAfter10(changes.datasource.currentValue);
      } else {
        this.data = changes.datasource.currentValue;
      }
      this.flattenedData = this.createArcs(this.data);
      console.log(this.flattenedData);
      this.createRingChart(
        this.flattenedData,
        this.id + '_container',
        180,
        180,
        true
      );
    }
  }
  groupAfter10(data) {
    // only works when only 1 level
    if (data && data.length > 10) {
      const first10 = data.slice(0, 9);
      const rest = data.slice(9);
      const others = {
        label: 'Others',
        value: rest.reduce((sum, item) => sum + item.value, 0),
        color: rest[0].color,
        children: [],
      };
      return first10.concat([others]);
    }
    return data;
  }

  public arcTooltip = '';

  public getTooltipText(item) {
    return item.label + ' (' + item.value + ')';
  }
  public getTooltipItem(id) {
    const item = this.flattenedData.find((item) => item.id == id);
    return item;
  }

  public createRingChart(data, containerId, width, height, replace = false) {
    if (replace) {
      const container = document.getElementById(containerId);
      container.innerHTML = '';
    }
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', width.toString());
    svg.setAttribute('height', height.toString());

    const centerX = width / 2;
    const centerY = height / 2;

    data.forEach((item) => {
      if (item.value == 0) return;
      // Adjust angles for spacing
      let spacingAngle = 1 * (1 / item.radius); // 2px spacing converted to angle

      let startAngle =
        (item.start / 100) * 2 * Math.PI - Math.PI / 2 + spacingAngle;
      let endAngle =
        (item.end / 100) * 2 * Math.PI - Math.PI / 2 - spacingAngle;
      // if (data.length == 1) {
      //   startAngle = 0;
      //   endAngle = 2 * Math.PI - 0.0001;
      // }
      const outerStartX = centerX + item.radius * Math.cos(startAngle);
      const outerStartY = centerY + item.radius * Math.sin(startAngle);
      const outerEndX = centerX + item.radius * Math.cos(endAngle);
      const outerEndY = centerY + item.radius * Math.sin(endAngle);

      const innerRadius = item.radius - item.thickness;
      const innerStartX = centerX + innerRadius * Math.cos(startAngle);
      const innerStartY = centerY + innerRadius * Math.sin(startAngle);
      const innerEndX = centerX + innerRadius * Math.cos(endAngle);
      const innerEndY = centerY + innerRadius * Math.sin(endAngle);

      const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

      const path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      const d = [
        `M ${outerStartX} ${outerStartY}`,
        `A ${item.radius} ${item.radius} 0 ${largeArcFlag} 1 ${outerEndX} ${outerEndY}`,
        `L ${innerEndX} ${innerEndY}`,
        `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${innerStartX} ${innerStartY}`,
        'Z',
      ].join(' ');

      path.setAttribute('d', d);
      path.setAttribute('fill', item.color);
      path.classList.add(this.id + '_arc');
      path.setAttribute('id', this.id + '_arc' + item.id);
      svg.appendChild(path);
    });
    console.log(containerId);
    const container = document.getElementById(containerId);

    container.appendChild(svg);
    container.style.width = 'fit-content';
    container.style.height = 'fit-content';
    // container.style.transform = 'scale(-1,1)';

    const allArcs = document.querySelectorAll('.' + this.id + '_arc');
    Array.from(allArcs).forEach((arc) => {
      arc.addEventListener('mouseenter', function (e) {
        const target = e.target as HTMLElement;
        allArcs.forEach((arc) => {
          const temp = arc as HTMLElement;
          temp.style.transition = 'all 0.1s ease-in-out';
          temp.style.cursor = 'pointer';
          if (temp !== target) {
            // temp.style.opacity = '0.5';
            temp.style.filter = 'opacity(0.4)';
          } else {
            temp.style.filter = 'drop-shadow(0px 0px 2px #616161)';
          }
        });
        const text =
          target.id.split('_arc')[0] +
          '-' +
          target.id.split('_arc')[1] +
          '-legend';
        const legend = document.getElementById(text);
        console.log(text);
        if (legend) {
          legend.classList.add('legend-hover');
          console.log('added');
        }
      });
      arc.addEventListener('mouseleave', function (e) {
        const target = e.target as HTMLElement;
        allArcs.forEach((arc) => {
          const temp = arc as HTMLElement;
          temp.style.transition = 'all 0.1s ease-in-out';

          temp.style.filter = 'none';
        });
        const text =
          target.id.split('_arc')[0] +
          '-' +
          target.id.split('_arc')[1] +
          '-legend';
        const legend = document.getElementById(text);
        console.log(text);
        if (legend) {
          legend.classList.remove('legend-hover');
          console.log('removed');
        }
      });
    });
  }

  public createArcs(data) {
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    const temp = this.flattenData(data, 0, 0, 40, totalValue);
    temp.sort((a, b) => a.level - b.level);

    return temp.map((item, id) => {
      item.id = id;
      return item;
    });
  }

  public flattenData(
    data,
    level,
    startPercent,
    thickness,
    totalValue,
    baseDiameter = 80
  ) {
    let flatList = [];
    let currentPercent = startPercent;

    data.forEach((item) => {
      const segmentValue =
        item.children.length > 0
          ? item.children.reduce((sum, child) => sum + child.value, 0)
          : item.value;
      const endPercent = currentPercent + (segmentValue / totalValue) * 100;
      const childrenThickness =
        item.children.length > 0 ? thickness / 2 - 2 : thickness;
      let outerDiameter = baseDiameter;
      if (item.children.length > 0) {
        flatList = flatList.concat(
          this.flattenData(
            item.children,
            level + 1,
            currentPercent,
            childrenThickness + 2,
            totalValue,
            outerDiameter - childrenThickness - 2
          )
        );
      }
      flatList.push({
        label: item.label,
        start: currentPercent,
        end: endPercent,
        color: item.color || '#000',
        thickness: childrenThickness,
        radius: outerDiameter,
        value: item.value,
        percent: (item.value / totalValue) * 100,
        level: level,
      });

      currentPercent = endPercent;
    });

    return flatList;
  }

  public highlightArc(id) {
    const allArcs = document.querySelectorAll('.' + this.id + '_arc');
    const target = document.getElementById(this.id + '_arc' + id);
    Array.from(allArcs).forEach((arc) => {
      const temp = arc as HTMLElement;
      temp.style.transition = 'all 0.1s ease-in-out';
      temp.style.cursor = 'pointer';
      if (temp !== target) {
        temp.style.filter = 'opacity(0.4)';
      }
    });
  }
  public unhighlightArc(id) {
    const allArcs = document.querySelectorAll('.' + this.id + '_arc');
    Array.from(allArcs).forEach((arc) => {
      const temp = arc as HTMLElement;
      temp.style.transition = 'all 0.1s ease-in-out';
      temp.style.cursor = 'pointer';
      temp.style.filter = 'none';
    });
  }
}
