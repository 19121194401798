import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageRunnerService } from '../../../services/data-export/page-runner.service';
import { AuthService } from '../../../services/auth/auth.service';
import { PageRunnerScript } from '../../../models/PageRunnerScript';
import { DataStateChangeEventArgs, GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { Ej2ScriptedPageRunnnerService } from '../../../services/ej2Proxies/ej2-scripted-page-runnner.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
declare var $:any;

@Component({
  selector: 'app-scripted-page-runner',
  templateUrl: './scripted-page-runner.component.html',
  styleUrls: ['./scripted-page-runner.component.css']
})
export class ScriptedPageRunnerComponent implements OnInit, AfterViewInit {
  
  pageSettings:PageSettingsModel;
  sortSettings:SortSettingsModel;
  toolbar: string[];
  tooltipContent:string;
  scripts:PageRunnerScript[];  
  exportId:string;
  pollingTimer:any;
  notificationOptions:any;
  exportButtonDisabled:boolean;
  pollingTimerCounter:number;
  
  @ViewChild('grid') 
  grid: GridComponent;

  public ej2ScriptedPageRunData: Observable<any>;

  constructor(private el: ElementRef, 
    private pageRunnerService:PageRunnerService,
    private router: Router,     
    public authService:AuthService,
    private spinnerService:SpinnerService,
    private ej2ProxyService:Ej2ScriptedPageRunnnerService) { 
    this.tooltipContent = "Automated users allow for complex page flows such as logging in and checking out.";
    this.spinnerService.toggle(true);
    this.ej2ScriptedPageRunData = ej2ProxyService.pipe(tap(()=>{
      this.spinnerService.toggle(false);
    }));
  }

  ngOnInit() {
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'scriptFilePath', direction: 'Ascending'}
      ]
    }; 
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
    let state = { skip: 0, take: 20 };
    this.ej2ProxyService.execute(state, null);
  }

  createScript(){
    const newPath = "admin/automatedUsers/";      
    this.router.navigate([newPath, "new"]);
  }

  onEditClick(script){
    this.grid.hideSpinner();
    const newPath = "admin/automatedUsers/";      
    this.router.navigate([newPath, script.id]);
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);    
    this.ej2ProxyService.execute(state, null);
  }
}
