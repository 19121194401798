<div class="row">
    <div class="col-lg-12">
        <ejs-tab style="background-color: white;">
            <e-tabitems>
                <e-tabitem [header]="{ 'text': 'Dictionaries' }">
                    <ng-template #content>
                        <app-title-card height="700px">
                            <app-report-dictionary-tools></app-report-dictionary-tools>
                        </app-title-card>
                    </ng-template>
                </e-tabitem>
                <e-tabitem [header]="{ 'text': 'All Reports' }">
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>
</div>