<div class="row" *ngIf="!isExisting">
  <div class="col-md-12">
    <div class="card be-loading">
      <div class="card-header card-header-divider">User Details<span class="card-subtitle">Edit properties of the user</span></div>
      <div class="card-body">
        <form [formGroup]="userDetailsForm" (ngSubmit)="onSubmit()">  
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">First Name:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                  <input class="form-control" type="text" formControlName="firstName" pattern="[a-zA-Z]*" >
                  <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="alert alert-danger">
                    <div *ngIf="firstName.errors.pattern">
                      You may only enter letters
                    </div>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Last Name:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="lastName" pattern="[a-zA-Z]*">
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="alert alert-danger">
                  <div *ngIf="lastName.errors.pattern">
                    You may only enter letters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">E-Mail:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="email" email>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                  <div *ngIf="email.errors.email">
                    Please ensure you have entered a valid email address
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1" *ngIf="user.id != 'new'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Password:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="password" formControlName="password">
              </div>
            </div>
            <div class="form-group row pt-1" *ngIf="user.id != 'new'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Re-type Password:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="password" formControlName="retypedPassword">
              </div>
            </div>            
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Roles:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select multiple class="form-control" formControlName="roles">
                    <option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</option>
                </select>
              </div>
            </div>
            
            <subscriptionConfig *ngIf="user.id != 'new'" [userEmail]="user.email" [userId]="user.user_id"></subscriptionConfig>
            
            <div style="text-align: center">
                <button *ngIf="btnText == 'Update'" class="btn btn-rounded btn-space btn-danger" data-toggle="modal" data-target="#mod-confirm-delete" type="button">Delete</button>
              <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!userDetailsForm.valid">{{btnText}}</button>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isExisting">
  <div class="col-md-12">
      <div class="card be-loading">
        <div class="card-header card-header-divider">Add Users<span class="card-subtitle">Add existing super users to this customer account</span></div>
          <div class="card-body">
            <form [formGroup]="existingUserForm" (ngSubmit)="addUsers()">
              <div class="form-group row pt-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Existing Auth0 IDs (comma separated):</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input class="form-control" type="text" formControlName="userIds">
                </div>
              </div>
              <div style="text-align: center">
                <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!existingUserForm.valid">Add User(s)</button>
              </div>
            </form>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="mod-confirm-delete" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
          <h3>Warning!</h3>
          <p>Are you sure you want to delete this user? You cannot undo this action!</p>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="onDeleteClick();">Proceed</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
