<div class="report-container">
    <div class="row" style="margin-bottom: 25px;">
        <div class="col-lg-2" style="display: flex; flex-direction: row; justify-content: center;">
            <img src="assets/svg/violation.svg" alt="Cookie Violation Risk Summary Logo" height="125px">
        </div>
        <div class="col-lg-7">
            <div class="report-title">Cookie Violation Risk Summary</div>
            <!-- <div class="report-meta">Report Generated on 27th May 2024</div> -->
            <ejs-dropdownlist width="50%" [dataSource]="processorList" placeholder="Select a page"
                [fields]="processorListFields" [value]="selectedProcessor"
                (change)="onProcessorChange($event)"></ejs-dropdownlist>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4">
            <app-minified-value-panel label="Total Pages Scanned" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="totalPages || 0" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-4">
            <app-minified-value-panel label="Unique Cookies" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="uniqueCookies || 0" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-4">
            <app-minified-value-panel label="Cookie Count" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="totalCookies || 0" [isVertical]="true"></app-minified-value-panel>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <app-title-card height="800px" [isLoading]="isLoading">
                <ng-container header>
                    <span class="title-icon"><img src="assets/svg/privacy.svg" height="20px"></span>
                    Top {{topNChains}} {{this.processorTitle}} w/ Request Chains
                </ng-container>
                <ejs-grid #grid [dataSource]="violationData" height="100%" width="100%" [allowResizing]="true">
                    <e-columns>
                        <e-column field="cookie" headerText="Cookie Name" [autoFit]="true">
                            <ng-template #template let-data>
                                <span (click)="goToReportWithCookie(data.cookie)"
                                    class="cookie-link">{{data.cookie}}</span>
                            </ng-template>
                        </e-column>
                        <e-column field="vendor" headerText="Vendor" width="100px"></e-column>
                        <e-column field="count" headerText="Count" width="100px"></e-column>
                        <e-column field="data" headerText="Top chains" [autoFit]="true">
                            <ng-template #template let-data>
                                <div
                                    style="display: flex; flex-direction:column; gap: 10px; width: 100%; flex-wrap: nowrap">
                                    <div *ngFor="let item of data.data; index as j">
                                        <div [style.border-bottom]="j < data.data.length - 1 ? '.5px solid #e6e8eb':'none'"
                                            style="display: flex; flex-direction:row; gap: 5px; width: 100%; flex-wrap: nowrap; align-items: center; padding: 5px;">
                                            <div *ngFor="let link of item.chain; index as i">
                                                <span [style.font-style]="link.isIFrame ? 'italic':'normal'"
                                                    [style.color]="link.isIFrame ? 'grey':'black'">{{link.node}}</span>
                                                <span *ngIf="i < item.chain.length - 1" class="zmdi zmdi-arrow-right"
                                                    style="margin-left:5px"></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </ng-template>
                        </e-column>


                    </e-columns>
                </ejs-grid>

            </app-title-card>

        </div>

    </div>
</div>