export class Condition {
    
    constructor(
        public id:number, 
        public name:string,
        public description:string="",
        public author:string="",
        public dateCreated:Date=null,
        public filterJavaScript:string=''
        ){

    }
}
