export class User {
    public blocked:boolean;
    public vaultId:number;
    public email:string;
    public timezone:string;
    public logins_count?:number;
        
    constructor(public id:string, public firstName:string, public lastName:string, public userId:string, public dateCreated:Date, public lastLogin:Date, public roles:string[]){

    }

    public fullName(){
        return this.firstName + " " + this.lastName;
    }
}
