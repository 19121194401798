import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataValidationService } from '../data-validation/data-validation.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class Ej2ValidationRuleProxyService extends Subject<any> {

  constructor(private dataValidationService:DataValidationService, private spinnerService:SpinnerService) { 
    super();
  }
  public execute(state: any, testSuiteId:number): void {    
    this.spinnerService.toggle(true);
    this.getData(state, testSuiteId).pipe(tap(()=>{
      this.spinnerService.toggle(false);
    })).subscribe(x => super.next(x));
  }

  protected getData(state: DataStateChangeEventArgs, testSuiteId:number): Observable<any> {
    
    var sortOrderDict = {"ascending" : "asc", "descending": "desc"};
    
    return this.dataValidationService.getRulesForTestSuite(
      testSuiteId,
      (state.skip ? state.skip : 0),
      (state.take ? state.take : 20),
      (state.sorted ? state.sorted[0].name : 'name'),
      (state.sorted ? sortOrderDict[state.sorted[0].direction.toLowerCase()] : "asc"),
      "",
      1
    ).pipe(map((response)=>{return { count: response.recordsTotal, result: response.data } }));   
  }
}
