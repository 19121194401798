<div *ngIf="steps.length > 0">
    <div class="row justify-content-md-center">
        <div class="col-sm-3">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('schedule-collection-start', 'success', 'danger')}}">
                <div class="card-header card-header-divider">Page Run Scheduled
                    
                    <span class="card-subtitle">The page was scheduled to run</span>
                </div>
                <div class="card-body">
                    <p>The page was scheduled to run at {{findStep('schedule-collection-start').formattedTimestamp}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-sm-3">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('pageRunner-collection-output', 'success', 'danger')}}">
                <div class="card-header card-header-divider">Page Run Complete
                    
                    <span class="card-subtitle">The page run has completed</span>
                </div>
                <div class="card-body">
                    <p>The page run was completed at {{findStep('pageRunner-collection-output').formattedTimestamp}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-sm-3">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('mergedRequestsWithResponses-indexing-output', 'success', 'danger')}}">
                <div class="card-header card-header-divider">Indexing
                    
                    <span class="card-subtitle">Page run data is indexed and archived.</span>
                </div>
                <div class="card-body">
                    <p>Indexing was completed at
                        {{findStep('mergedRequestsWithResponses-indexing-output').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('extractScreenshots-analysis-start', 'success', 'danger', true)}}">
                <div class="card-header card-header-divider">Extract Screenshots
                    
                    <span class="card-subtitle">Screenshots from the page run were processed and archived.</span>
                </div>
                <div class="card-body">
                    <p>Screenshots were exactracted at
                        {{findStep('extractScreenshots-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>

        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('classifyWithVendorLibrary-analysis-start', 'success', 'danger', true)}}">
                <div class="card-header card-header-divider">Vendor Classification
                    
                    <span class="card-subtitle">Attempts to classify all requests from page run using the Vendor
                        Library</span>
                </div>
                <div class="card-body">
                    <p>Vendor classification started at
                        {{findStep('classifyWithVendorLibrary-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>

        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('processCustomData-analysis-start', 'success', 'default', true)}}">
                <div class="card-header card-header-divider">Custom Data
                    
                    <span class="card-subtitle">Processes custom data from page run</span>
                </div>
                <div class="card-body">
                    <p>Custom data started at {{findStep('processCustomData-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>

        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('saveNewScriptResponseHashes-analysis-start', 'success', 'default', true)}}">
                <div class="card-header card-header-divider">New Code Detection
                    
                    <span class="card-subtitle">Detects new code files</span>
                </div>
                <div class="card-body">
                    <p>New Code Detection started at
                        {{findStep('saveNewScriptResponseHashes-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
    <!-- </div> -->
    <!-- <div class="row justify-content-md-center"> -->
        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('beautifyNewCodeHash-analysis-start', 'success', 'default', true)}}">
                <div class="card-header card-header-divider">Beautification
                    
                    <span class="card-subtitle">Creates a beautified version of new code files</span>
                </div>
                <div class="card-body">
                    <p>Beautification started at
                        {{findStep('beautifyNewCodeHash-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
        </div>
<div class="row justify-content-md-center">
        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('checkWithRetireJs-analysis-start', 'success', 'default', true)}}">
                <div class="card-header card-header-divider">RetireJS Analysis
                    
                    <span class="card-subtitle">RetireJS analysis to detect out of date code</span>
                </div>
                <div class="card-body">
                    <p>RetireJS Analysis
                        {{findStep('checkWithRetireJs-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
    <!-- </div> -->

    <!-- <div class="row justify-content-md-center"> -->
        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('generateAstAndSimpleAnalysis-analysis-start', 'success', 'default', true)}}">
                <div class="card-header card-header-divider">AST & Simple Analysis
                    
                    <span class="card-subtitle">Creates the AST of new code files and preforms simple analysis</span>
                </div>
                <div class="card-body">
                    <p>AST & Simple Analysis started at
                        {{findStep('generateAstAndSimpleAnalysis-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>

        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('generateRequestTree-analysis-start', 'success', 'danger', true)}}">
                <div class="card-header card-header-divider">Request Tree
                    
                    <span class="card-subtitle">Creates the request tree for the page run</span>
                </div>
                <div class="card-body">
                    <p>Request Tree started at
                        {{findStep('generateRequestTree-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('generateDataSharingGraph-analysis-start', 'success', 'danger', true)}}">
                <div class="card-header card-header-divider">Data Sharing Graph
                    
                    <span class="card-subtitle">Creates the data sharing graph for the page run</span>
                </div>
                <div class="card-body">
                    <p>Data Sharing Graph started at
                        {{findStep('generateDataSharingGraph-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
    <!-- </div> -->

    <!-- <div class="row justify-content-md-center"> -->
        <div class="col-sm-2">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('generateControlFlowGraph-analysis-start', 'success', 'default', true)}}">
                <div class="card-header card-header-divider">Control Flow Graph
                    
                    <span class="card-subtitle">Creates the control flow graph</span>
                </div>
                <div class="card-body">
                    <p>Control Flow Graph started at
                        {{findStep('generateControlFlowGraph-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
    </div>
    

    <div class="row justify-content-md-center">
        <div class="col-sm-3">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('finishAnalysis-analysis-start', 'success', 'danger')}}">
                <div class="card-header card-header-divider">Complete
                    
                    <span class="card-subtitle">Validates that analysis pipeline has completed sucessfully</span>
                </div>
                <div class="card-body">
                    <p>Analysis Complete started at
                        {{findStep('finishAnalysis-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>

        <div class="col-sm-3">
            <div
                class="card card-border-color card-border-color-{{getStatusStyleForStep('timeoutAnalysis-analysis-start', 'success', 'default')}}">
                <div class="card-header card-header-divider">Timeout
                    
                    <span class="card-subtitle">Detects if analysis pipeline has timed out.</span>
                </div>
                <div class="card-body">
                    <p>Timeout started at
                        {{findStep('timeoutAnalysis-analysis-start').formattedTimestamp}} </p>
                </div>
            </div>
        </div>
    </div>

</div>