<div class="row">
    <div class="col-sm-12">
      <div class="card card-table card-border be-loading" [class.be-loading-active]="alertsLoading">
        <div class="card-header">
          <div class="tools">          
          </div>
          <span class="title">Alerts</span>&nbsp;<span class="icon zmdi zmdi-info" title="Alerts" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
          <span class="card-subtitle">These are your alerts.</span>
        </div>
        <div class="card-body">
          <table id="alertsTable" class="table"></table>
        </div>    
      </div>
    </div>
  </div>