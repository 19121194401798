import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { SearchfilterdomainsPipe } from '../pipes/searchfilterdomains.pipe';
import { NoSanitizePipePipe } from '../pipes/no-sanitize-pipe.pipe';
import { SearchfilterPipe  } from '../pipes/searchfilter.pipe';

import { RouterModule } from '@angular/router';
import { SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({  
  imports: [
    SimpleNotificationsModule.forRoot({
      position: ["top", "right"]
    }),
    CommonModule,
    NgbModule,
    ClipboardModule,
    TruncateModule,
    ReactiveFormsModule,
    FormsModule,
    CodemirrorModule,
    RouterModule
  ],
  declarations: [
    NoSanitizePipePipe,
    SearchfilterdomainsPipe,
    SearchfilterPipe
  ],
  exports:[
    SimpleNotificationsModule,
    NgbModule,
    ClipboardModule,
    TruncateModule,
    ReactiveFormsModule,
    FormsModule,
    CodemirrorModule,
    RouterModule,
    NoSanitizePipePipe,
    SearchfilterdomainsPipe,
    SearchfilterPipe
  ]
})
export class SharedModule { }
