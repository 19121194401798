import { Extractor } from "./Extractor";

export class ResourceMatcher {

    extractors?:Extractor[];
    
    isMonitoringEnabled?:boolean;
    isMonitoringStatic?:boolean;
    value?:string;

    constructor(
        public id:number,
        public name:string,
        public description:string
    ){
        this.extractors = [];
    }
    
}