<div style="display: flex; flex-direction: column; gap:10px; height: 100%;width:100%">
    <div style="display: flex; flex-direction: row;gap:10px">
        <ejs-dropdownlist width="200px" [dataSource]="allDictionaries | async"
            (select)="onSelectDictionaryWithCode($event)"></ejs-dropdownlist>
        <button ejs-button content='Refresh'></button>
        <button ejs-button content='Save Changes' [disabled]="hasChanged" (click)="onSaveChanges()"></button>
    </div>
    <ejs-grid #grid height="500px" [dataSource]="selectedDictionary | async" [editSettings]="editSettings"
        [toolbar]="['Search','Add','Update','Delete']" (actionComplete)="onActionComplete($event)">
        <e-columns>
            <e-column field="field" headerText="Field">
                <ng-template #template let-data>
                    <div style="display: flex; flex-direction: column;">
                        <div style="display: flex; flex-direction: row; gap:10px;padding:5px;">
                            <span style="font-size: 1.1rem"><b>{{data.field}}</b>:</span>
                            <span style="color:grey"><i>{{data.type || 'string (Default)'}}</i></span>
                        </div>
                        <div style="padding:5px;">
                            <span>{{data.description}}</span>
                        </div>
                    </div>
                </ng-template>
            </e-column>
            <e-column field="type" headerText="Field Type" width="0"></e-column>
            <e-column field="description" headerText="Description" width="0"></e-column>
        </e-columns>
        <ng-template #editSettingsTemplate let-data>
            <div style="display: flex; flex-direction: column; gap:20px;">
                <ejs-textbox id='field'[value]="data.field" (input)="onFieldChange($event,data)"></ejs-textbox>
                <ejs-dropdownlist id="type" width="200px" [dataSource]="fieldTypes" [value]="data.type"
                    (change)="onFieldTypeChange($event,data)"></ejs-dropdownlist>
                <textarea id="description" style="resize: none; width: 300px; height: 300px; padding: 5px; border: .5px grey solid"
                    [value]="data.description" (input)="onDescriptionChange($event,data)" height="300px"></textarea>
            </div>
        </ng-template>
    </ejs-grid>

</div>