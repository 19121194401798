import { Injectable } from '@angular/core';
import { DataSourceChangedEventArgs, DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageRunnerService } from '../data-export/page-runner.service';

@Injectable({
  providedIn: 'root'
})
export class Ej2ScriptedPageRunnnerService extends Subject<any> {

  constructor(private pageRunnerService:PageRunnerService) {
    super();
  }

  public execute(state:any, scriptId:number):void {
    this.getData(state, scriptId).subscribe(x => super.next(x));
  }

  protected getData(state: DataStateChangeEventArgs, scriptId:number): Observable<any> {
    console.log('proxy state');
    console.log(state);

    var sortOrderDict = {"ascending" : "asc", "descending": "desc"};
    
    return this.pageRunnerService.getScripts(
      scriptId, 
      (state.skip ? state.skip : 0),
      (state.take ? state.take : 20),
      (state.sorted ? state.sorted[0].name : "scriptFilePath"),
      (state.sorted ? sortOrderDict[state.sorted[0].direction.toLowerCase()] : "asc"),
      "",
      1
    ).pipe(map((response)=>{return { count: response.recordsTotal, result: response.data } }));
  }
}
