<div class="report-container">
    <div class="row" style="height:100px">
        <div class="col-lg-12">
            <div style="font-size: xx-large; margin-left: 10px;">Tracker Summary</div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <app-minified-value-panel label="Unique Vendors" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="uniqueVendors"></app-minified-value-panel>
        </div>
        <div class="col-lg-6">
            <app-minified-value-panel label="Unique Domains" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="uniqueDomains"></app-minified-value-panel>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <app-minified-value-panel label="Unique Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-3">
            <app-minified-value-panel label="Unique Cookie Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueCookieTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-3">
            <app-minified-value-panel label=" Unique Data Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueDataTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-3">
            <app-minified-value-panel label="Unique Code Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueCodeTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <app-title-card height="500px" [isLoading]="isLoading"
                tooltipContent="List of domains with pages scanned per domain, unique tracker vendors per domain and total tracker per domain.">
                <ng-container header>
                    <span class="title-icon
                        zmdi zmdi-apps"></span>
                    Tracker Details by Hostname
                </ng-container>
                <ejs-grid height="100%" width="100%" [dataSource]="domainGroupedData" [allowResizing]="true"
                    (rowSelected)="onHostnameSelected($event)" [allowFiltering]="true" [allowSorting]="true"
                    [filterSettings]="{type:'Excel'}">
                    <e-columns>
                        <e-column field="pagehostname" headerText="Hostname" [allowFiltering]="true" width="40%"
                            clipMode='EllipsisWithTooltip'></e-column>
                        <e-column field="totalpages" [headerText]="'Total Pages'">
                            <ng-template #template let-data>
                                <div [style.color]="data.totalpages ? 'black' : 'lightgrey'">{{data.totalpages || 'N/A'}}</div>
                                </ng-template>
                        </e-column>
                        <e-column field="uniquevendors" headerText="Unique Vendors"></e-column>
                        <e-column field="totaltrackers" [allowFiltering]="true" [allowSorting]="true"
                            headerText="Total Trackers">
                        </e-column>
                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <e-column field="totalpages" type="Sum">
                                    <ng-template #footerTemplate let-data>
                                        <div>Total Pages: {{data.Sum || 'N/A'}}</div>
                                    </ng-template>
                                </e-column>
                                <e-column field="totaltrackers" type="Sum">
                                    <ng-template #footerTemplate let-data>
                                        <div>Total Trackers: {{data.Sum}}</div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </app-title-card>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-8">
            <app-title-card height="500px" [isLoading]="isLoading"
                tooltipContent="Detailed vendor list based on selected domain from the domain list. Displays trackers per vendor for that domain and categories of scanned trackers. Tracker categories include: Code Tracker, Data Tracker, Cookie Tracker">
                <ng-container header>
                    <span class="title-icon
                        zmdi zmdi-view-headline"></span>
                    Vendor Details
                    <span style="color: lightgrey;margin-left: 10px">{{selectedHostnameDetails?.pagehostname || 'No hostname selected'}}</span>
                </ng-container>
                <ejs-grid [dataSource]="selectedHostnameDetails?.vendorDetails || []" height="100%" width="100%"
                    [toolbar]="['Search']" [allowSorting]="true">

                    <e-columns>
                        <e-column width="60px">
                            <ng-template #template let-data>
                                <img width="30px"
                                    src="https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : 'unknown' }}.ico">
                            </ng-template>
                        </e-column>
                        <e-column field="calculatedvendorname" headerText="Vendor" textAlign="left">
                            <ng-template #template let-data>
                                <div class="vendor-buttons" (click)="goToVendorDetails(data.calculatedvendorname)">
                                    <div class="label">{{data.calculatedvendorname}}</div>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field="totaltrackers" headerText="Tracker Requests" textAlign="left"></e-column>
                        <e-column field="trackercategories" headerText="Categories" textAlign="left">
                            <ng-template #template let-data>
                                <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                    <div *ngFor="let cat of data.trackercategories">
                                        <ejs-tooltip [content]="cat">
                                            <ng-container [ngSwitch]="cat">
                                                <div (click)="goToCategoryDetails(cat,selectedHostnameDetails?.pagehostname, data.calculatedvendorname)"
                                                    [style.opacity]="cat === 'none' ? '0' : '1'" class='cat-icons'>
                                                    <img width="85%" *ngSwitchCase="'Cookie Tracker'"
                                                        src="assets/svg/cookie-tracker.svg">
                                                    <img width="85%" *ngSwitchCase="'Data Tracker'"
                                                        src="assets/svg/data-tracker.svg">
                                                    <img width="100%" *ngSwitchCase="'Code Tracker'"
                                                        src="assets/svg/code-tracker.svg">
                                                    <img width="85%" *ngSwitchCase="'none'"
                                                        src="assets/svg/privacy.svg">

                                                </div>
                                            </ng-container>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>

                </ejs-grid>
            </app-title-card>
        </div>
        <div class="col-lg-4">
            <app-title-card height="500px" [isLoading]="false" tooltipContent="Graphical Representation of Tracker Vendor Distribution for the select hostname/domain. Only shows top 9 vendors with most trackers">
                <ng-container header>
                    <span class="title-icon
                        zmdi zmdi-chart"></span>
                    Vendor Distribution
                </ng-container>
                <div
                    style="position: relative; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
                    <multi-pie-chart [datasource]="selectedHostnameDetails?.pieData || []" [id]="'summaryPieChartId'"
                        [limit]="true"></multi-pie-chart>
                </div>
            </app-title-card>
        </div>
    </div>
</div>