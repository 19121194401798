import { ReportLibraryConfig } from "../../models/ReportLibraryConfig"
export const AllDefinedReports: ReportLibraryConfig[] = [
    {
        title: 'Global Dashboard',
        description: 'Analytical Insight on Regional Privacy Violations',
        reportCode: 'GDS',
        beautifiedPath: 'reports/dashboard/global',
        hasRaw: false,
        hidden: true,
        icon: 'global',
        category: 'Dashboard',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Tracker Summary',
        description: 'Overview of Scanned Tracking Technologies',
        reportCode: 'TS',
        beautifiedPath: 'reports/tracker-summary-dashboard',
        hasRaw: true,
        hidden: true,
        icon
        : 'tracker',
        category: 'Online Tracking Technology',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Trackers By Vendor',
        description: 'Vendor Breakdown of Detected Tracking Technologies',
        reportCode: 'TS',
        beautifiedPath: 'reports/tracker-vendor-dashboard',
        hasRaw: true,
        hidden: true,
        icon: 'tracker',
        category: 'Online Tracking Technology',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Fingerprinting Report',
        description: 'Analysis of Browser Fingerprinting Technologies',
        reportCode: 'FP',
        beautifiedPath: 'reports/fingerprint-dashboard',
        hasRaw: true,
        hidden: true,
        icon: 'fingerprint',
        category: 'Other Privacy Risks',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'VPPA Report',
        description: 'Video Player and Tracker Analysis',
        reportCode: 'VRA',
        beautifiedPath: 'reports/vppa-report',
        hasRaw: false,
        hidden: true,
        icon: 'vppa',
        category: 'Other Privacy Risks',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'CMP & 3rd Party Analysis',
        description: 'Quick Sitewide Insight on CMP Tool Detection and Third Party Technology ',
        reportCode: 'CS',
        beautifiedPath: 'reports/consentSummary',
        hasRaw: false,
        hidden: true,
        icon: 'privacy',
        category: 'Cookie Reports',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Cookie w/ Sources',
        description: 'In-depth Cookie Report with Source Chain',
        reportCode: 'CRS',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'cookie-tracker',
        category: 'Cookie Reports',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Cookie Violation Risk',
        description: 'Risk Analysis of Cookie Violations',
        reportCode: 'CVRS',
        beautifiedPath: 'reports/cookie-violation-risk-summary',
        hasRaw: false,
        hidden: true,
        icon: 'cookie-tracker',
        category: 'Cookie Reports',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Tags w/ Sources',
        description: 'In-depth Tag Report with Source Chain',
        reportCode: 'TRS',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'code-tracker',
        category: 'Cookie Reports',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Input Spy',
        description: 'Input Field Tracking Report',
        reportCode: 'IP',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'input-spy',
        category: 'Other Privacy Risks',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Cookie Tracker Details',
        description: 'In-depth Online Cookie Tracking Technology',
        reportCode: 'CTD',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'cookie-tracker',
        category: 'Online Tracking Technology',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Data Tracker Details',
        description: 'In-depth Online Data Tracking Technology',
        reportCode: 'DTD',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'data-tracker',
        category: 'Online Tracking Technology',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'CDN Report',
        description: '4th Party Content Delivery Network Report',
        reportCode: 'CDN',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'cdn',
        category: 'Other Privacy Risks',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Retire JS Report',
        description: 'Open Source JavaScript Warnings',
        reportCode: 'RJS',
        beautifiedPath: null,
        hasRaw: true,
        hidden: true,
        icon: 'retirejs',
        category: 'Custom',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Adobe Analytics',
        description: 'Adobe Analytics Report',
        reportCode: 'ADT',
        beautifiedPath: 'reports/adobe-analytics',
        hasRaw: false,
        hidden: true,
        icon: 'adobe-logo',
        category: 'Vendor Analytics',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Meta Vendor Page Analysis',
        description: 'In-depth Analysis for Facebook/Meta Technology',
        reportCode: 'VAR_META',
        beautifiedPath: 'reports/meta-vendor-report',
        hasRaw: false,
        hidden: true,
        icon: 'meta-logo',
        category: 'Vendor Analytics',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'Meta Vendor Site Analysis',
        description: 'Site Level Facebook/Meta Technology Overview',
        reportCode: 'VAR_META_SITE',
        beautifiedPath: 'reports/meta-vendor-site-report',
        hasRaw: false,
        hidden: true,
        icon: 'meta-logo',
        category: 'Vendor Analytics',
        isCustom: false
    } as ReportLibraryConfig,
    {
        title: 'TikTok Vendor Analysis',
        description: 'In-depth Analysis for Tiktok Technology',
        reportCode: 'VAR_TIKTOK',
        beautifiedPath: 'reports/tiktok-vendor-report',
        hasRaw: false,
        hidden: true,
        icon: 'tiktok-logo',
        category: 'Vendor Analytics',
        isCustom: false
    } as ReportLibraryConfig
];
