import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, concat } from 'rxjs';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { GridModel } from '@syncfusion/ej2-angular-grids';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { AuthService } from '../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { FBDetailsService } from '../../../services/fb-details/fb-details.service';
import { concatMap } from 'rxjs/operators';
import { QueriedReportingService } from '../../../services/queried-reporting/queried-reporting.service';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';
import { FBDetailsTable } from './FB-details-table';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-fb-details',
  templateUrl: './fb-details.component.html',
  styleUrls: ['./fb-details.component.css'],
})
export class FbDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('FBDataGrid') FBDataGrid: GridComponent;
  @ViewChild('pixelDropDown') pixelDropDown: any;

  isLoaded: BehaviorSubject<boolean>;
  dataSource: any;
  pageValue = null;
  pageData: any[];
  domainData: any[];
  PFColumns: any[];
  PFData: any;
  FBTechColumns: any[];
  FBTechData: any;
  VOPColumns: any[];
  VOPData: any[];
  CAPIColumns: any[];
  CAPIData: any;
  PIDColumns: any[];
  PIDData: any[];
  CFSColumns: any[];
  CFSData: any[];
  FBDColumns: any[];
  FBDChildGrid: GridModel;
  FBDData: any;
  baseData: any;

  FBDataColumns: any[];
  private simpleColumns: any[] = [
    { field: 'configOpt', headerText: 'Config Option', width: 100 },
    { field: 'value', headerText: 'Value', width: 100 },
  ];
  private advancedColumns: any[] = [
    { field: 'category', headerText: 'Category', width: 100 },
    { field: 'param', headerText: 'Param', width: 100 },
    { field: 'value', headerText: 'Value', width: 100 },
    { field: 'apiName', headerText: 'API Name', width: 100 },
    { field: 'name', headerText: 'Name', width: 100 },
    { field: 'explanation', headerText: 'Explanation', width: 100 },
  ];
  constructor(
    private spinnerService: SpinnerService,
    private dataExportService: DataExportService,
    private authService: AuthService,
    private notificationService: VaultNotificationService,
    public dashboardFileSelectorService: DashboardFileSelectorService,
    protected FBDetailsService: FBDetailsService,
    private queriedReportingService: QueriedReportingService
  ) {
    this.isLoaded = new BehaviorSubject<boolean>(false);

    this.dashboardFileSelectorService.toggle(true);
    this.dashboardFileSelectorService.setReportCode('VAR_META');
    this.PFData = {};
    this.FBTechData = {};
    this.VOPData = [];
    this.CAPIData = {};
    this.PIDData = [];
    this.CFSData = [];
    this.FBDColumns = this.advancedColumns;
  }
  // load report data from the server then do a bunch of filters for each panel
  private rawData: any;
  public pageList: any[] = [];
  public pageListFields: any = { name: 'pageUrl', value: 'pageUrl' };
  ngOnInit(): void {
    this.dashboardFileSelectorService.setSelectionHandler(
      this.onReportNameSelected.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.dashboardFileSelectorService.toggle(false);
  }

  ngAfterViewInit(): void {
    this.FBDataGrid.filterSettings = { type: 'Menu' };
    console.log('FBDataGrid:', this.FBDataGrid.columns);
    ((this.FBDataGrid as GridComponent).columns[4] as any).filterTemplate = '<span></span>';
  }
  onAdvancedToggle(): void {
    this.FBDataGrid.refresh();
  }

  onDropdownValueChanged($event: any): void {
    console.log('Selected:', $event);
    this.selectedPageUrl = $event.itemData.value;
    this.loadData();
  }
  public showAdvanced: boolean = false;

  public selectedPageUrl: string;
  private selectedReportName: string;
  public loadData(): void {
    console.log('Loading data for:', this.selectedPageUrl);
    this.queriedReportingService
      .getDataByPageUrl(this.selectedReportName, this.selectedPageUrl)
      .toPromise()
      .then((data) => {
        this.rawData = data;
        this.parseData();
      });
  }

  parseData() {
    const pixels = {};
    this.spinnerService.toggle(true);
    this.initializeValues();
    this.rawData.forEach((row, index) => {
      if (row.pixelRequest !== null) {
        if (pixels[row.pixelRequest] === undefined) {
          pixels[row.pixelRequest] = 1;
        }
        pixels[row.pixelRequest]++;
      }
      if (row.type === 'PrivacyFeaturesData') {
        this.PFData[row.dataKey] = row.dataValue;
      }

      if (row.type === 'FBConfigData') {
        this.CFSData.push({
          configOpt: row.dataKey,
          value: row.dataValue,
        });
      }
      if (row.type === 'FBTechData') {
        this.FBTechData[row.dataKey] = row.dataValue;
      }
      if (row.type === 'FBConversionAPIData') {
        this.CAPIData[row.dataKey] = row.dataValue;
      }
      if (row.type === 'PixelID') {
        this.PIDData.push({
          id: row.dataValue,
          count: this.rawData[index + 1].dataValue,
        });
      }
      if (row.type === 'FBData') {
          console.log('ROW:', row.dataKey, row.dataValue);
        const findIndex = this.FBDData.findIndex(
          (item) => {
            return (item.param === row.dataKey) && (item.value === row.dataValue)
          }
        );

        if (findIndex !== -1) {
            this.FBDData[findIndex].associatedPixel.push(row.pixelRequest);
            this.FBDData[findIndex].associatedPixel = this.FBDData[findIndex].associatedPixel.filter(
              (item, index, self) => self.indexOf(item) ===
              index
            ).filter((item) => item !== null);
        } else {
          if (FBDetailsTable[row.dataKey] !== undefined) {
            const rowTable = FBDetailsTable[row.dataKey];

            this.FBDData.push({
              category: rowTable.category,
              param: rowTable.param,
              value: row.dataValue,
              apiName: rowTable.apiName,
              name: rowTable.name,
              explanation: rowTable.explanation,
              associatedPixel: row.pixelRequest ? [row.pixelRequest] : [],
            });
          } else {
            this.FBDData.push({
              category: 'Uncategorized',
              param: row.dataKey,
              value: row.dataValue,
              apiName: 'N/A',
              name: 'N/A',
              explanation: 'N/A',
              associatedPixel: row.pixelRequest ? [row.pixelRequest] : [],
            });
          }
        }
      }
      if (row.type === 'VideoPlayerData') {
        this.VOPData.push({
          vendor: row.dataKey,
          request: row.dataValue,
        });
      }
    });
    console.log('FBDATA:', this.FBDData);
    this.PIDData = Object.keys(pixels).map((key) => {
      return { id: key, count: pixels[key] };
    });
    this.pixelDropDownList = this.PIDData.length > 0 ? [{id:'Clear Highlight'}, ...this.PIDData] : [];
    this.spinnerService.toggle(false);
  }
  public pixelDropDownList: any[] = [];
  initializeValues() {
    this.PFData = {};
    this.FBTechData = {};
    this.VOPData = [];
    this.CAPIData = {};
    this.PIDData = [];
    this.CFSData = [];
    this.FBDData = [];
  }

  public onReportNameSelected(name: any): void {
    this.queriedReportingService
      .getUniqueValues(name, 'pageUrl')
      .toPromise()
      .then((data) => {
        this.selectedReportName = name;
        this.pageList = data;
        this.selectedPageUrl = this.pageList[0];
        this.loadData();
      });
  }

  onPixelSelect($event: any): void {
    const pixel:any = $event.itemData.id;
    if(pixel === 'Clear Highlight') {
      this.FBDataGrid.clearFiltering();
      return;
    }
    console.log('Pixel:', pixel);
    this.FBDataGrid.filterByColumn('associatedPixel', 'contains', pixel);

  }

  // ===========================================================================
  // Descriptions
  // ===========================================================================
}
