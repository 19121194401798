<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border">
      <div class="card-header">
        <div class="tools">          
        </div>
        <span class="title">Event Totals</span>
        <span class="card-subtitle">View unclassified event totals for all clients for past 24 hours and across ALL data we have. If data fails to load reload page a few times...</span>
      </div>
      <div class="card-body">
        <ejs-grid #grid 
          [dataSource]="data"
          locale='en-US' 
          [allowPaging]="true" 
          [pageSettings]="pageSettings" 
          [allowSorting]="true" 
          [sortSettings]="sortSettings"
          [allowSelection]="false"
          [enableHover]="false"
          [toolbar]="toolbar">
          <e-columns>
            <e-column field='client' headerText='Client'></e-column>
            <e-column field='Event Count 24 hours' headerText='Past 24 hours'></e-column>
            <e-column field='Event Count TOTAL' headerText='ALL Unclassified Events'></e-column>
          </e-columns>
        </ejs-grid>
      </div>      
    </div>
  </div>
</div>