export class Page {

    constructor(
        public id:number, 
        public link:string,
        public lastCheckedPageRunner:string
    ) {

    }
}
