<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border">
      <div class="card-header">
          <div class="tools">
            <button *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" type="button" class="btn btn-rounded btn-space btn-success" data-toggle="modal" data-target="#modal-bulk-update" ><i style="color: white;" class="icon icon-left zmdi zmdi-collection-plus"></i>Bulk Update</button>
            <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exportData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button>
          </div>
        <span class="title">Code Files</span>&nbsp;<span class="icon zmdi zmdi-info" title="Code Files" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
        <span class="card-subtitle">{{tooltipContent}}</span>
      </div>  
      <div class="card-body">
        <ejs-grid #grid [dataSource]='data | async'
                        locale='en-US'
                        (dataSourceChanged)='dataSourceChanged($event)'
                        [allowPaging]="true" 
                        [allowSorting]="true" 
                        allowFiltering="true" 
                        [pageSettings]="pageOptions"
                        [selectionSettings]="selectionSettings" 
                        [allowSelection]="false" 
                        [enableHover]="false" 
                        (dataStateChange)= 'dataStateChange($event)'
                        (actionBegin)='actionBegin($event)'                        
                        [filterSettings]="filterSettings"
                        locale='en-US'
                        allowResizing='true'
                        [editSettings]='editSettings'
                        [allowTextWrap]="true"
                        (dataBound)="onDataBound()"
                        >
          <e-columns>
              <e-column field='name' headerText='File Name' type="string" [allowEditing]="false" [minWidth]="400">
                <ng-template #template let-data>
                  <a [routerLink] = "['/vendors', data.vendor, 'code', data.id]">{{data.name}}</a>
                </ng-template>
              </e-column>
              <e-column field='vendor' headerText='Vendor' type="string" [allowEditing]="false" [minWidth]="100">
                <ng-template #template let-data>
                  <img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : 'unknown' }}.ico"/><a [routerLink] = "['/vendors',data.vendor]">{{data.vendor}}</a>
                </ng-template>
              </e-column>
              <e-column field='maxTimeBetweenChecks' headerText='Check Interval (secs)' type='number' editType='numericedit' [validationRules]='frequencyEditRules' ></e-column>
              <e-column field='lastChecked' headerText='Last Checked' [allowEditing]="false" type='datetime'>
                <ng-template #template let-data>{{ data.lastCheckedFormatted }}</ng-template>
              </e-column>
              <e-column field='lastChanged' headerText='Last Changed' [allowEditing]="false" type='datetime'>
                <ng-template #template let-data>{{ data.lastChangedFormatted }}</ng-template>
              </e-column>
          </e-columns>
        </ejs-grid>
      </div>      
    </div>
  </div>
</div>


<div class="modal fade" id="modal-bulk-update" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <h2>Bulk Update</h2>
          <p>This will update all {{ grid?.pageSettings.totalRecordsCount | number }} items you are currently viewing (inclusive of filters)</p>
          <form [formGroup]="bulkUpdateForm">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Check Frequency</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="maxTimeBetweenChecks">
              </div>
            </div>
          </form>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="bulkUpdate();">Save</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>