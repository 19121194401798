import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { PageComponent } from './page/page.component';
import { PageDetailsComponent } from './page-details/page-details.component';
import { PageHistoryDetailsComponent } from './page-history-details/page-history-details.component';
import { PageRunnerComponent } from './page-runner/page-runner.component';

import { ScriptedPageRunnerComponent } from './scripted-page-runner/scripted-page-runner.component';
import { ScriptDetailsComponent } from './scripted-page-runner/script-details/script-details.component';
import { ScriptHistoryComponent  } from './scripted-page-runner/script-history/script-history.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { AnalysisDetailsComponent } from './analysis-details/analysis-details.component';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    ComboBoxModule,
    TooltipAllModule,
    ChartAllModule
  ],
  declarations: [
    PageComponent,
    PageDetailsComponent,
    PageHistoryDetailsComponent,
    PageRunnerComponent,
    ScriptedPageRunnerComponent,
    ScriptDetailsComponent,
    ScriptHistoryComponent,
    AnalysisDetailsComponent
  ],
  exports: [
    PageComponent,
    PageDetailsComponent,
    PageHistoryDetailsComponent,
    PageRunnerComponent,
    ScriptedPageRunnerComponent,
    ScriptDetailsComponent,
    ScriptHistoryComponent
  ]
})
export class PageComponentsModule { }
