import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import { LegendService, SparklineTooltipService } from '@syncfusion/ej2-angular-charts';
import { AccumulationChart, AccumulationChartComponent, IAccTextRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import { DetailRowService, ExcelExportService, Grid, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../services/auth/auth.service';
import { DataExportService } from '../../services/data-export/data-export.service';
import { DataValidationService } from '../../services/data-validation/data-validation.service';
import { SitemapService } from '../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { TimezoneService } from '../../services/timezone.service';
import { DateTime, Interval } from "luxon";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigService } from '../../services/config/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from 'process';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

declare var $:any;

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.css'],
  providers:[ 
    SparklineTooltipService, 
    DetailRowService,
    LegendService,
    ExcelExportService
  ]
})
export class ValidationSummaryComponent implements OnInit {

  public cdnUrl:string;
  public data: any;
  public pageOptions: Object;  
  public filterSettings: any;
  public pageSettings: PageSettingsModel;
  public sortSettings: any;
  public reportSelectionForm:FormGroup;
  public availableConfigs:any;
  public currentConfigId:number = -1;
  
  public totalDetected = 0;
  public totalViolations = 0;
  public totalScanned = 0;

  public totalDetectedPercent = "";
  public totalViolationsPercent = "";

  @ViewChild('pie')
  public pie: AccumulationChartComponent | AccumulationChart;

  @ViewChild('grid') 
  public grid: GridComponent;

  public pieTooltip: Object = {
      enable: true
  };

  public pieCenter:Object = {
    x: '60%',
    y: '50%'
  }
  
  public title: string = 'Pages Last Scanned';

  public pieData: Object[] = [
    { 'x': 'Last 24 Hours', daysMin: 0, daysMax:1, counter:0, y: 0, text: '' },
    { 'x': 'Last 7 Days', daysMin:1, daysMax: 7, counter:0, y: 0, text: '' },
    { 'x': 'Last 30 Days', daysMin: 7, daysMax: 30, counter:0, y: 0, text: '' },
    { 'x': 'Last 90 Days', daysMin: 30, daysMax: 90, counter:0, y: 0, text: '' }
  ];

  public getFontSize(width: number): string {
    if (width > 300) {
        return '13px';
    } else if (width > 250) {
        return '8px';
    } else {
        return '8px';
    }
  };

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    console.log(args);
    args.text = `${args.point.x}: ${Number(args.point.y).toFixed(2)}%`;
  }

  public onTextRender(args: IAccTextRenderEventArgs): void {
    // args.series.dataLabel.font.size = this.getFontSize(this.pie.initialClipRect.width);
    // console.log("args.text", args.text);
    if ( args.text == '0') {
      args.text = '';
    } else { 
      args.text = Number(args.text).toFixed(2) + '%';
    }
  }

  //Initializing Legend
  public legendSettings: Object = {
    visible: true,
    toggleVisibility: false,
    position: 'Right',
    height: '75%',
    width: '60%',
    textWrap:'Wrap',
    maximumLabelWidth:100,
  };
  //Initializing Datalabel
  public dataLabel: Object = {
    visible: true, 
    position: 'Inside',
    font: {
        // color: 'white',
        fontWeight: '600',
        // size: '14px'
    }
  };

  public tooltipSettings: object = {
      visible: true,
      format: '${x} : ${yval}',
      trackLineSettings: {
          visible: true
      }
  };
  
  constructor(private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private notificationService: NotificationsService,
    public authService:AuthService,
    public validationService:DataValidationService,
    private spinnerService:SpinnerService,
    private dataService:DataExportService,
    private fb:FormBuilder,
    private configService:ConfigService,
    private sanitizer: DomSanitizer
  ) { 
    this.sitemapService.update(this.route);
    this.filterSettings = { 
      type: 'Excel'
    };

    this.pageOptions = { pageSize: 25, pageCount: 5, pageSizes: [25, 50, 75, 100, 200] };

    this.sortSettings = {
      columns: [
        { field: 'requestViolationCount', direction: 'Descending'}
      ]
    };

    this.reportSelectionForm = this.fb.group({
      selectedFormId: null
    });
  }

  detailDataBound(arg){
    console.log(arg);

    let page = arg.data;

    let rvDomains = Object.keys(page.requestViolationsByDomain);
    let cDomains = Object.keys(page.cookieViolationsByDomain);
    
    let detailData = [];
    rvDomains.forEach((domain)=>{
      if (domain != ""){
        let domainCount = page.requestViolationsByDomain[domain] ? page.requestViolationsByDomain[domain].length : 0;
        detailData.push({domain: domain, type: 'Request', details: `${domainCount} requests from this domain were detected.`});
      }      
    });
    
    cDomains.forEach((domain)=>{
      if (domain != ""){
        let cookieCount = page.cookieViolationsByDomain[domain] ? page.cookieViolationsByDomain[domain].length : 0;
        detailData.push({domain: domain, type: 'Cookie', details: `${cookieCount} cookies from this domain were detected.`});
      }
    });

    

    let detailGrid = new Grid({
      dataSource: detailData,
      columns:[
        { field: 'domain', headerText: 'Domain', width: 110 },
        { field: 'type', headerText: 'Type', width: 110 },
        { field: 'details', headerText: 'Details', width: 300 }
      ]
    });
    detailGrid.appendTo(arg.detailElement.querySelector('.detailGrid'));
  }

  async ngOnInit() {
    this.spinnerService.toggle(true);

    let configs = await this.configService.getAllConfigs(false, true).pipe(catchError(error=>of(error))).toPromise();
    if ( configs instanceof HttpErrorResponse || (configs?.length == 0) ){
      this.loadData();
    } else {
      $('#modal-select-report').modal('toggle');
      this.availableConfigs = configs.map( (config) => { return {...config, label: `${config.name} (${config.id})`} });
    }
  }

  loadData(){
    let url = this.authService.customerId() + (this.currentConfigId == -1 ? '' : "/" + this.currentConfigId) + '/summary.json.gz';
    console.log(url);
    this.dataService.getConsentFile(url).subscribe((response)=>{
      // console.log(consentReport);
      let consentReport = JSON.parse(response.toString());
      console.log(consentReport);

      let now = DateTime.now();
      consentReport.pages.forEach((page)=>{
        let timestamp = DateTime.fromMillis(page.timestamp, TimezoneService.UTC_ZONE);
        const diff = Interval.fromDateTimes(timestamp, now);

        console.log(diff.length('hours'), diff.length('days'));
        

        for (var i = 0; i < this.pieData.length; i++){
          let timespan:any = this.pieData[i];
          if ( diff.length('days') >= timespan.daysMin && diff.length('days') < timespan.daysMax ){
            timespan.counter += 1;
          }
        }

        this.pieData.forEach((datum:any)=>{
          datum.y = (datum.counter / consentReport.pages.length) * 100;
          datum.text = `${ datum.y }%`;
        })

        console.log(this.pieData);
        this.pie.refresh();

        page.requestViolationCount = this.getTotalForPageProperty(page, "requestViolationsByDomain");
        page.cookieViolationCount = this.getTotalForPageProperty(page, "cookieViolationsByDomain");
      });

      this.totalScanned = consentReport.summary.totalPages;
      this.totalDetected = consentReport.summary.consentDetectedPages;
      this.totalViolations = consentReport.summary.pagesWithViolations;
      this.totalDetectedPercent = ((consentReport.summary.consentDetectedPages / this.totalScanned) * 100) + "";
      this.totalViolationsPercent = ((consentReport.summary.pagesWithViolations / this.totalScanned) * 100) + "";
      this.grid.dataSource = consentReport.pages;
      this.spinnerService.toggle();
      
      let cr = document.querySelector("#currentReport");
      if (cr) {
        cr.removeEventListener("click", this.changeReport);
        cr.addEventListener("click", this.changeReport.bind(this));
      }
    },
    (error)=>{
      console.log(error);
      this.notificationService.error("Uh oh!", "Data is not available. Please try again later or contact support.");  
      this.spinnerService.toggle();

      let cr = document.querySelector("#currentReport");
      if (cr) {
        cr.removeEventListener("click", this.changeReport);
        cr.addEventListener("click", this.changeReport.bind(this));
      }
    });
  }

  exportData(){
    // this.notificationService.info("Data Export", "//TODO");    
  }

  getTotalForPageProperty(page, property){
    let total = 0;
    for (var domain in page[property]){
      if ( domain != "" ){
        total += page[property][domain].length;
      }
    }
    return total;
  }

  onSelectReport(){
    console.log("onSelectReport");
    console.log(this.reportSelectionForm.value);
    this.currentConfigId = this.reportSelectionForm.value.selectedFormId;
    let selectedConfig = this.availableConfigs.filter( config => config.id == this.currentConfigId );
    if ( selectedConfig && selectedConfig.length == 1){
      let config = selectedConfig[0];
      this.sitemapService.isPageTitleHtml = true;
      this.sitemapService.safePageTitle = this.sanitizer.bypassSecurityTrustHtml(`Consent Summary - <span id="currentReport" style="cursor: pointer; color: #4285f4; important!" id="currentReport">${config.name}</span>`); // crumbs.push({text: 'One Trust', active:'false'})
      $('#modal-select-report').modal('toggle');
      this.spinnerService.toggle(true);
      this.loadData();  
    } 
  }

  changeReport(){
    for (var i = 0; i < this.pieData.length; i++){
      let timespan:any = this.pieData[i];
      timespan.counter = 0;
      
    }

    this.pieData.forEach((datum:any)=>{
      datum.y = 0;
      datum.text = `0%`;
    })
    $('#modal-select-report').modal('toggle');
  }

}
