import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'number') {
      if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2) + ' GB';
      } else if (value >= 1000000) {
        return (value / 1000000).toFixed(2) + ' MB';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(2) + ' KB';
      } else {
        return value + ' B';
      }
    }
    return value;
  }

}
