<div class="report-container">

    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="vendor-selector-panel">
                    <div class="vendor-info">
                        <div class="logo"><img width="50px"
                                src="https://{{cdnUrl}}/vendors/icon/{{ selectedVendorInfo?.website ? selectedVendorInfo?.website.toLowerCase() : 'unknown' }}.ico" />
                        </div>
                        <div class="name">{{selectedVendorName || "Undefined"}}</div>
                    </div>
                    <ejs-dropdownlist style="align-self: flex-end;" width="200px" [dataSource]="availableVendors"
                        (select)="onVendorSelected($event)" [value]="selectedVendorName"></ejs-dropdownlist>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4" *ngIf="CHDPages > 0">
            <app-minified-value-panel label="CHD Pages" [bgColor]="'#4d545c'" [textColor]="'white'" [value]="CHDPages"
                [isVertical]="false"></app-minified-value-panel>
        </div>
        <div [class]="CHDPages > 0 ? 'col-lg-4' : 'col-lg-6'">
            <app-minified-value-panel label=" Unique Domains" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="uniqueDomains" [isVertical]="false"></app-minified-value-panel>
        </div>
        <div [class]="CHDPages > 0 ? 'col-lg-4' : 'col-lg-6'">
            <app-minified-value-panel label="Unique Pages" [bgColor]="'#4d545c'" [textColor]="'white'"
                [value]="uniquePages" [isVertical]="false"></app-minified-value-panel>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <app-minified-value-panel label="Unique Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-3">
            <app-minified-value-panel label="Unique Cookie Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueCookieTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-3">
            <app-minified-value-panel label=" Unique Data Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueDataTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
        <div class="col-lg-3">
            <app-minified-value-panel label="Unique Code Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
                [value]="uniqueCodeTrackers" [isVertical]="true"></app-minified-value-panel>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <app-title-card height="500px">
                <ng-container header>
                    <span class="title-icon
                        zmdi zmdi-apps"></span>
                    Domain Vendor Distribution
                </ng-container>
                <ejs-grid [dataSource]="vendorGroupedData" height="100%" width="100%" [allowPaging]="true">
                    <e-columns>
                        <e-column field="pagehostname" headerText="Domain" textAlign="left"></e-column>
                        <e-column field="totalpages" headerText="Total Pages" textAlign="left"></e-column>
                        <e-column field="totaltrackers" headerText="Tracker Requests" textAlign="left"></e-column>
                        <e-column field="trackercategories" headerText="Categories" textAlign="left">
                            <ng-template #template let-data>
                                <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                    <div *ngFor="let cat of data.trackercategories">
                                        <ejs-tooltip [content]="cat">
                                            <ng-container [ngSwitch]="cat">
                                                <div (click)="goToCategoryDetails(cat,data.pagehostname, selectedVendorName)"
                                                    [style.opacity]="cat === 'none' ? '0' : '1'" class="cat-icons">
                                                    <img width="85%" *ngSwitchCase="'Cookie Tracker'"
                                                        src="assets/svg/cookie-tracker.svg">
                                                    <img width="85%" *ngSwitchCase="'Data Tracker'"
                                                        src="assets/svg/data-tracker.svg">
                                                    <img width="100%" *ngSwitchCase="'Code Tracker'"
                                                        src="assets/svg/code-tracker.svg">
                                                    <img width="85%" *ngSwitchCase="'none'"
                                                        src="assets/svg/privacy.svg">
                                                </div>
                                            </ng-container>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </app-title-card>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <app-title-card height="350px">
                <ng-container header>
                    <span class="title-icon
                        zmdi zmdi-chart"></span>
                    Page Distribution By Domain
                </ng-container>
                <div
                    style="position: relative; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
                    <multi-pie-chart [datasource]="pagePieData || emptyPieData" [id]="'trackerPie'" [limit]="true"></multi-pie-chart>
                </div>
            </app-title-card>

        </div>
        <div class="col-lg-6">
            <app-title-card height="350px">
                <ng-container header>
                    <span class="title-icon
                        zmdi zmdi-chart"></span>
                    Tracker Distribution By Domain
                </ng-container>
                <div
                    style="position: relative; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
                    <multi-pie-chart [datasource]="vendorPieData || emptyPieData" [id]="'vendorPie'" [limit]="true"></multi-pie-chart>

                </div>
            </app-title-card>
        </div>
    </div>
</div>