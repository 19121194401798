import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import { Customer } from '../../models/Customer';
import { Event } from '../../models/Event';
import { Analyst } from '../../models/Analyst';
import { VaultBaseService } from '../VaultBaseService';
import { TimezoneService } from '../timezone.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends VaultBaseService {

  private static CUSTOMER_INFO:string = "customerInfo";
  private static GET_ANALYSTS:string = "getAnalysts";
  private static GET_EVENTS:string = "getEvents";
  private static GET_PAGES:string = "getPages";
  private static GET_ALL_CODE_FILES:string = "GET_ALL_CODE_FILES";

  customer:Customer
  customers:Customer[];
  hashReq$:any;
  hashes:any;

  cache:{[type:string]:Observable<any>;} = {};
  public customerName:BehaviorSubject<string>;

  public setCustomerName(name:string){
    console.log("Setting customer name in service to " + name);
    this.customerName.next(name);
  }

  public setCustomer(customer:any){
    this.customer = customer;
    this.setCustomerName(this.customer.name);
    this.timezoneService.updateSettings((customer.isUtcDefaultForUI == "1" ? true : false), customer.uiDateTimeFormatString);    
    localStorage.setItem('customer', JSON.stringify(customer));
  }

  constructor(authHttp: HttpClient, private timezoneService:TimezoneService) { 
    super(authHttp);
    this.customerName = new BehaviorSubject("");

    var existingCustomer = localStorage.getItem('customer');
    if ( existingCustomer ){
      this.customer = JSON.parse(existingCustomer);
      this.customerName.next(this.customer.name);
    }    
  }

  public getCustomer():Observable<any>{
    const customerId = localStorage.getItem('cid');
    const url = `${env.api.url}customer/${customerId}`;
    return this.authHttp.get(url);
  }

  // public getHashes():Observable<any>{
  //   // .subscribe((hashHistory)=>{
  //   //   console.log("Hash History");              
  //   //   let hashes = {};
  //   //   hashHistory.forEach((item)=>{hashes[item.hash] = item.hash;});
  //   //   console.log(hashes);
  //   //   this.customerService.hashes = hashes;
  //   // },
  //   // (error)=>{
  //   //   console.log("Hash history DATA error handler: ")
  //   //   console.log(error);            
  //   // });
  //   return this.hashReq$;
  // }

  public createCustomer(name:string, domain:string):Observable<any>{
    const url = `${env.api.url}customer`;
    return this.authHttp.post(url, JSON.stringify({name:name, domain:domain}));
  }

  public getCustomerInfo():Observable<Customer[]> {
    const cacheKey = `${CustomerService.CUSTOMER_INFO}`;
    
    if (!this.cache[cacheKey]){
      const url = `${env.api.url}customerInfoForUser`;
      this.cache[cacheKey] = this.makeHttpRequest(url, null, false);
    }
    return this.cache[cacheKey];
  }

  public getAllCustomers():Observable<Customer[]> {
    const cacheKey = `${CustomerService.CUSTOMER_INFO}All`;
    
    if (!this.cache[cacheKey]){
      const url = `${env.api.url}customers`;
      this.cache[cacheKey] = this.makeHttpRequest(url, null, true);
    }
    return this.cache[cacheKey];
  }

  public getAnalysts():Observable<Analyst[]>{
    const cacheKey = `${CustomerService.GET_ANALYSTS}`;
    
    if (!this.cache[cacheKey]){
      const url = `${env.api.url}customers/analysts`;
      this.cache[cacheKey] = this.makeHttpRequest(url, null, false);
    }
    return this.cache[cacheKey];
  }

  public getEventsForCustomers(data):Observable<any>{
    var url = `${env.api.url}customer/eventsv2`;
    return this.authHttp.post(url, JSON.stringify(data));

    // var url = `${env.api.url}customer/events?id=${customerIds.join(",")}&limit=${limit}&startDate=${startDate}`;
    // if (offset){
    //   url += `&offset=${offset}`;
    // }
    // return this.makeHttpRequest(url, null, false); 
  }

  public getEventsTotals():Observable<Event[]>{    
    var url = `${env.api.url}customer/events/totals`;    
    return this.makeHttpRequest(url, null, false); 
  }

  public idpUserSync():Observable<any>{
    var url = `${env.api.url}customer/idpUserSync`;    
    return this.makeHttpRequest(url, null, false); 
  }

  public updateCustomer(customer:any):Observable<any>{
    const url = `${env.api.url}customer`;
    return this.authHttp.put(url, JSON.stringify(customer));    
  }

  public getEventMessage():Observable<Event[]>{    
    var url = `${env.api.url}customer/eventMessage`;    
    return this.makeHttpRequest(url, null, false); 
  }

  public createEventMessage(messages:any):Observable<any>{
    const url = `${env.api.url}customer/eventMessage`;
    return this.authHttp.post(url, JSON.stringify(messages));  
  }

  public deleteEventMessage(ids:number[]):Observable<any>{
    const url = `${env.api.url}customer/eventMessage`;
    return this.authHttp.put(url, JSON.stringify(ids));  
  }

  public getPagesForCodefile(codeFileId:number, isHistoryId:boolean):Observable<any>{
    var url = `${env.api.url}customer/getPagesForCodefile/${codeFileId}?hid=${isHistoryId}`;    
    return this.makeHttpRequest(url, null, false);
  }

  public getAllCodeFiles(start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{
    const cacheKey = `${CustomerService.GET_ALL_CODE_FILES}`;
    const orderFixes = {"ascending": "asc", "descending": "desc"};
    const url = `${env.api.url}customer/codeFiles`;
    const params = new HttpParams()
                  .set("start", start ? start.toString() : '0')
                  .set("length", length ? length.toString() : '20')
                  .set("order", order)
                  .set("orderDir", orderFixes[orderDirection])
                  .set("search", search)
                  .set("draw", draw.toString())
                  ;
    this.cache[cacheKey] = this.makeHttpRequest(url, params, false);
    
    return this.cache[cacheKey];
  }

  public getAllCodeFilesV2(data:any):Observable<any>{
    const url = `${env.api.url}customer/codeFilesv2`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public updateCodeFile(codeFileInfo:any):Observable<any>{
    const url = `${env.api.url}customer/codeFiles/${codeFileInfo.codeFileId}`;
    return this.authHttp.put(url, JSON.stringify(codeFileInfo));
  }

  public getVendorDomains():Observable<any>{
    const url = `${env.api.url}customer/vendors/domains`;
    return this.authHttp.get(url);
  }

  public getScheduledReports():Observable<any>{
    const url = `${env.api.url}customer/reports`;
    return this.authHttp.get(url);
  }

  public getNewCodeFileHashes(reviewedIds):Observable<any>{
    const url = `${env.api.url}newCodeFileHashes`;
    return this.authHttp.post(url, JSON.stringify(reviewedIds));
  }

  public getNewDomainCodeFileHashes(reviewedIds):Observable<any>{
    const url = `${env.api.url}newCodeFileHashes?type=domain`;
    return this.authHttp.post(url, JSON.stringify(reviewedIds));
  }

  public getNewDomainCodeFileHashesV2():Observable<any>{
    const url = `${env.api.url}newCodeFileHashesV2?type=domain`;
    return this.authHttp.post(url, JSON.stringify({}));
  }

  public getNewVendorCodeFileHashes(reviewedIds):Observable<any>{
    const url = `${env.api.url}newCodeFileHashes?type=vendor`;
    return this.authHttp.post(url, JSON.stringify(reviewedIds));
  }

  public getNewVendorCodeFileHashesV2(reviewedIds):Observable<any>{
    const url = `${env.api.url}newCodeFileHashesV2?type=vendor`;
    return this.authHttp.post(url, JSON.stringify(reviewedIds));
  }

  public getNewCodeFileHashesV2(reviewedIds):Observable<any>{
    const url = `${env.api.url}newCodeFileHashes?type=hash`;
    return this.authHttp.post(url, JSON.stringify(reviewedIds));
  }

  public getNewCodeFileHashesV2V2(activeCustomers):Observable<any>{
    const url = `${env.api.url}newCodeFileHashesV2?type=hash`;
    return this.authHttp.post(url, JSON.stringify(activeCustomers));
  }

  public getNewCodeFileManualReviewEvents():Observable<any>{
    const url = `${env.api.url}newCodeFileManualReviewEvents`;
    return this.authHttp.get(url);
  }

  public getNewDomainCodeFileManualReviewEvents():Observable<any>{
    const url = `${env.api.url}newCodeFileManualReviewEvents?type=domain`;
    return this.authHttp.get(url);
  }

  public getNewVendorCodeFileManualReviewEvents():Observable<any>{
    const url = `${env.api.url}newCodeFileManualReviewEvents?type=vendor`;
    return this.authHttp.get(url);
  }

  public updateScheduledReports(updates:any):Observable<any>{
    const url = `${env.api.url}customer/reports`;
    return this.authHttp.post(url, JSON.stringify(updates));
  }

  public getLists(list:string=null):Observable<any>{
    const url = `${env.api.url}customer/lists${ list ? '/' + list : '' }`;
    return this.authHttp.get(url);
  }

  public createList(name:string, description:string):Observable<any>{
    const url = `${env.api.url}customer/lists`;
    return this.authHttp.post(url, JSON.stringify({name: name, description: description}));
  }
  
  public updateList(listId:string, value:any):Observable<any>{
    const url = `${env.api.url}customer/lists/${listId}`;
    return this.authHttp.put(url, JSON.stringify(value));
  }

  public deleteList(name:string):Observable<any>{
    const url = `${env.api.url}customer/lists/${name}`;
    return this.authHttp.delete(url);
  }
  
  public clearCache(){
    this.cache = {};
  }

}
