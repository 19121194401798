import { Injectable } from '@angular/core';
import { VaultBaseService } from '../VaultBaseService';
import { HttpClient } from '@angular/common/http';
import { ConfigGroup } from '../../models/ConfigGroup';
import { ReportingS3Service } from '../reporting-s3/reporting-s3.service';
import { VaultNotificationService } from '../notifications/vault-notification.service';
import { SpinnerService } from '../spinner/spinner.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';




@Injectable({
  providedIn: 'root',
})
export class ConfigGroupsService extends VaultBaseService {
  constructor(
    authHttp: HttpClient,
    private reportingS3Service: ReportingS3Service,
    private notificationService: VaultNotificationService,
    private spinnerService: SpinnerService
  ) {
    super(authHttp);
  }
  public getConfigGroups(): Observable<any> {
    return this.reportingS3Service.getFile('configGroups.json').pipe(
      // if the response is empty, return an empty array
      map((data:string) => {
        // convert from string to JSON
        try {
          return JSON.parse(data) as ConfigGroup[];
        } catch (err) {
          return [] as  ConfigGroup[];
        }
      })
    );
  }

  public getCustomerConfigTemplates(): Observable<any> {
    return this.reportingS3Service.getFile('configTemplates.json').pipe(
      map((data:string) => {
        // convert from string to JSON
        try {
          return JSON.parse(data) as ConfigGroup[];
        } catch (err) {
          return [] as  ConfigGroup[];
        }
      })
    );
  }

  public saveConfigGroups(data: ConfigGroup[]):Observable<any> {
    if (data.length === 0) {
      return this.reportingS3Service.uploadJSON([] as any, 'configGroups.json');
    }
    return this.reportingS3Service.uploadJSON(data as any, 'configGroups.json');
  }
  public updateConfigGroups(newData: ConfigGroup): Observable<any> {
    this.spinnerService.toggle(true, 'Updating Config Group');
      return this.getConfigGroups().pipe(
        map((data: ConfigGroup[]) => {
          const configGroups = data;
          const index = configGroups.findIndex(
            (configGroup) => configGroup.id === newData.id
          );
          if (index !== -1) {
            configGroups[index] = newData;
            this.notificationService.success(
              'Config Group Updated',
              'Reloading Config Groups'
            );
            return configGroups
          }
          this.notificationService.error(
            'Error Updating Config Group',
            'Config Group Not Found'
          );
          this.spinnerService.toggle(false);
          return null;
        }),
        switchMap((configGroups:ConfigGroup[]) => {
          if (configGroups) {
            return this.saveConfigGroups(configGroups);
          }
          return of(null);
        })
      );
  }

  public deleteConfigGroups(id):Observable<any> {
    this.spinnerService.toggle(true, 'Deleting Config Group');
    return this.getConfigGroups().pipe(
      map((data: ConfigGroup[]) => {
        const configGroups = data;
        const index = configGroups.findIndex(
          (configGroup) => configGroup.id === id
        );
        if (index !== -1) {
          configGroups.splice(index, 1);
          this.notificationService.success(
            'Config Group Deleted',
            'Reloading Config Groups'
          );
          return configGroups;
        }
        this.notificationService.error(
          'Error Deleting Config Group',
          'Config Group Not Found'
        );
        this.spinnerService.toggle(false);
        return null;
      }),
      switchMap((configGroups:ConfigGroup[]) => {
        if (configGroups) {
          return this.saveConfigGroups(configGroups);
        }
        return of(null);
      })
    );
  }
  public addConfigGroup(name, configIds, color = null): Observable<any> {
    this.spinnerService.toggle(true, 'Adding Config Group');
    return this.getConfigGroups().pipe(
      switchMap((data: ConfigGroup[]) => {
        const configGroups = data;
        // look for the smallest available id greater than 0 that is not in use
        const ids = configGroups.map((configGroup) => configGroup.id);
        let id = 1;
        while (ids.includes(id)) {
          id++;
        }
        const newConfigGroup = {
          id: id,
          name,
          configIds,
          color,
        } as ConfigGroup;
        configGroups.push(newConfigGroup);
        this.notificationService.success(
          'Config Group Added',
          'Reloading Config Groups'
        );
        this.spinnerService.toggle(false);
        return this.saveConfigGroups(configGroups);
      })
    );
  }
}
