<div style="text-align: center; padding-top: 10px;">
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Code File Change');">Code File Change</button>    
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Code Analysis Complete');">Code Analysis Complete</button>    
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Instrumentation');">Instrumentation</button>
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('New Code File');">New Code File</button>
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Page Run Complete');">Page Run Complete</button>    
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Report Complete');">Report Complete</button>
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Timer Complete');">Timer Complete</button>        
    <button class="btn btn-rounded btn-space btn-primary" (click)="loadTemplate('Validation Result');">Validation Result</button>    
</div>

<div>
    <b>Code Editor:</b> Click a button to populate event template JSON, or enter in whatever you want.
    <ngx-codemirror #codeEditor [(ngModel)]="eventTemplate" [options]="codeMirrorOptions"></ngx-codemirror>
</div>

<div style="text-align: center; padding-top: 10px;">
    <button class="btn btn-rounded btn-space btn-success" (click)="sendEvent();">Send</button>
</div>
