<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border be-loading">
      <div class="card-header">
          <!-- <div class="tools">
            <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exporData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button>
          </div> -->
        <span class="title">{{vendorTitle}}</span>&nbsp;<span class="icon zmdi zmdi-info" title="{{vendorTitle}}" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
        <span class="card-subtitle">Browse or search for vendors</span>
      </div>
      <div class="card-body">
        <ejs-grid #grid 
          locale='en-US' 
          [allowPaging]="true" 
          [pageSettings]="pageSettings" 
          [allowSorting]="true" 
          [sortSettings]="sortSettings"
          [allowSelection]="false"
          [enableHover]="false"
          [toolbar]="toolbar">
          <e-columns>
            <e-column field='name' headerText='Vendor'>
              <ng-template #template let-data>
                  <img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : 'unknown' }}.ico"/><a [routerLink] = "['/vendors',data.id]">{{data.name}}</a><span class="icon zmdi zmdi-sign-in" style="margin-left: 5px; font-size: 15px !important"></span>
              </ng-template>
            </e-column>
            <e-column field='fileCount' headerText='Total Requests' format="N"></e-column>
            <e-column field='uniqueResourceIds' headerText='Unique Resources' format="N"></e-column>
            <e-column field='uniqueDomainIds' headerText='Unique Domains' format="N"></e-column>
            <e-column field='mostRecentUpdate' headerText='Latest Event' type='datetime'>
              <ng-template #template let-data>{{ tzService.format(data.mostRecentUpdate, false, false, false, 'D') }}</ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>      
    </div>
  </div>
</div>