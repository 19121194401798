import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReportingLibraryService } from '../../services/reporting-library/reporting-library.service';
import { FormGroup } from '@angular/forms';
import { VaultNotificationService } from '../../services/notifications/vault-notification.service';
import { ReportLibraryConfig } from '../../models/ReportLibraryConfig';
import { AuthService } from '../../services/auth/auth.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { ReportingViewsService } from '../../services/reporting-views/reporting-views.service';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface ToggleBtns {
  hidden: boolean;
  code: string;
}

declare var $: any;

interface ReportCardDetails {
  title: string;
  description: string;
  icon: string;
  reportCode: string;
  beautifiedPath: string;
  hasRaw: boolean;
  isEmpty: boolean;
  hidden: boolean;
  cat: string;
  isCustom: boolean;
  isView: boolean;
  defaultValues: any;
}

@Component({
  selector: 'app-report-library',
  templateUrl: './report-library.component.html',
  styleUrls: ['./report-library.component.css'],
})
export class ReportLibraryComponent implements OnInit, AfterViewInit {
  configForm: FormGroup;
  libConfig: ReportLibraryConfig[] = [];
  defaultConfig: ReportLibraryConfig[] = [];
  configFromS3: any = [];
  reportList: ReportCardDetails[] = [];
  listCategories: any = [
    'Dashboard',
    'Cookie Reports',
    'Online Tracking Technology',
    'Other Privacy Risks',
    'Vendor Analytics',
    'Extras',
    'Custom Reports',
    'Custom Views',
  ];
  tempCustomConfigState: any = [];
  tempPredefinedConfigState: any = [];

  constructor(
    private router: Router,
    private reportingLibraryService: ReportingLibraryService,
    private notificationService: VaultNotificationService,
    public authService: AuthService,
    private spinnerService: SpinnerService,
    private reportingViewsService: ReportingViewsService
  ) {}

  public viewCardsToShowInLibrary: ReportCardDetails[] = [];
  public customReportsInLibrary: ReportCardDetails[] = [];
  ngOnInit(): void {
    this.loadConfig();
  }

  ngAfterViewInit(): void {}
  public allReportList: ReportCardDetails[] = [];
  public reportLibraryConfig: ReportLibraryConfig[] = [];
  public reportList$: Observable<ReportCardDetails[]>;
  public reportPerCategory$: Observable<any>;
  private _currentReportPerCategory: any = [];
  private _currentReportList: ReportCardDetails[] = [];
  private _currentCustomReportList: ReportCardDetails[] = [];
  private _currentViewCardReportList: ReportCardDetails[] = [];
  private loadConfig(): void {
    this.reportButtonsFlag = false;
    this.reportPerCategory$ = this.reportingLibraryService
      .loadReportLibrary()
      .pipe(
        tap((data) => {
          this._currentReportList = data;
          this._currentCustomReportList = data.filter(
            (report) => report.isCustom
          );
          console.log(
            'Current Custom Reports: ',
            this._currentCustomReportList
          );
          this._currentViewCardReportList = data.filter(
            (report) => report.isView
          );
        }),
        map((reports) => {
          const out = this.reportingLibraryService.listCategories.map(
            (category) => {
              const filtered = reports.filter(
                (report) => report.cat === category
              );
              return {
                category: category,
                hidden: filtered.some((report) => report.hidden === false),
                reports: filtered,
              };
            }
          );
          this.reportButtonsFlag = true;
          console.log('Report Per Category: ', out);
          this._currentReportPerCategory = out;
          return out;
        })
      );
  }

  navigateToReport(reportCode: string) {
    this.router.navigate(['report', reportCode]);
  }
  public tempViewConfigState: any = [];
  configModalAction = () => {
    // get from s3
    this.tempPredefinedConfigState = this.libConfig.filter(
      (config) => !config.isCustom
    );
  };

  onCustomReportsModal = () => {
    this.tempViewConfigState = this.libConfig.filter(
      (config) => config.isView === true
    );
    this.tempCustomConfigState = this.libConfig.filter(
      (config) => config.isCustom && !config.isView
    );
    console.log('Custom Reports: ', this.tempCustomConfigState);
  };
  addNewCustomConfig = () => {
    this.tempCustomConfigState.push({
      title: '',
      description: '',
      hidden: false,
      icon: '',
      isCustom: true,
      reportCode: 'CUSTOM_REPORT',
    });
  };

  removeCustomConfig = (index: number) => {
    this.tempCustomConfigState.splice(index, 1);
  };
  removeViewConfig = (data: any) => {
    console.log('Removing Config: ', data);
    // this.closeModal('#customReportsModal');
    this.spinnerService.toggle(true);
    this.reportingLibraryService
      .getReportLibraryConfig()
      .subscribe((config: any) => {
        const temp = JSON.parse(config);
        const newConfig = temp.filter(
          (c) =>
            !(
              c.reportCode === data.reportCode &&
              c.title === data.title &&
              c.isView === true
            )
        );
        this.reportingLibraryService
          .saveReportLibraryConfigToS3(newConfig)
          .subscribe(
            () => {
              this.spinnerService.toggle(false);
              this.loadConfig();
              // this.openModal('#customReportsModal');
            },
            (error) => {
              this.spinnerService.toggle(false);
              this.notificationService.error(
                'Error',
                'Error removing view config'
              );
            },
            () => {
              this.spinnerService.toggle(false);
              this.loadConfig();

              this.onCustomReportsModal();
            }
          );
      });
  };

  onConfigConfirm = () => {
    const output = [];
    const customReportCodes = new Set();
    let hasDuplicate = false;
    const viewConfigList = this.libConfig.filter(
      (config) => config.isView === true
    );

    this.tempCustomConfigState.forEach((config) => {
      output.push({
        reportCode: config.reportCode,
        hidden: config.hidden,
        title: config.title,
        isView: config.isView,
        description: config.description,
        icon: config.icon,
      });
      if (customReportCodes.has(config.reportCode)) {
        this.notificationService.error(
          'Duplicate Report Code',
          `Report code ${config.reportCode} is duplicated`
        );
        hasDuplicate = true;
        return;
      }
      if (
        this.tempPredefinedConfigState.some(
          (predefinedConfig) =>
            predefinedConfig.reportCode === config.reportCode
        )
      ) {
        this.notificationService.error(
          'Duplicate Report Code',
          `Report code ${config.reportCode} conflicts with predefined report code`
        );
        hasDuplicate = true;
        return;
      }
      customReportCodes.add(config.reportCode);
    });

    if (hasDuplicate) {
      return;
    }

    this.tempPredefinedConfigState.forEach((config) => {
      if (config.hidden) {
        ({
          reportCode: config.reportCode,
          hidden: true,
        });
      } else {
        output.push({
          reportCode: config.reportCode,
          hidden: false,
        });
      }
    });
    output.push(...viewConfigList);
    console.log(output);
    this.reportingLibraryService.saveReportLibraryConfigToS3(output);
    this.notificationService.success('Config Saved', 'Reload to see changes');
    // this.closeModal('#configModal');
  };

  public dialogAnimationSettings: Object = {
    effect: 'Fade',
    duration: 150,
  };

  // Load temporary states here
  public updatedReportList: any[] = [];
  public updatedReportsToShowInLibrary: any[] = [];
  public updatedCustomReportsInLibrary: ReportCardDetails[] = [];
  public updatedViewCardsToShowInLibrary: ReportCardDetails[] = [];
  private _loadTempStates(): void {
    console.log('Reloading Initial States');
    this.updatedReportList = this._currentReportList.map((report) => {
      if (!report.isView && !report.isCustom) {
        return {
          reportCode: report.reportCode,
          hidden: report.hidden,
        };
      }
      return report;
    });
    this.updatedReportsToShowInLibrary = Object.assign(
      [],
      this._currentReportPerCategory
    );
    console.log('Updated Reports: ', this.updatedReportsToShowInLibrary);
    this.updatedCustomReportsInLibrary = this.currentReportList.filter(
      (report) => report.isCustom && !report.isView
    );
    this.updatedViewCardsToShowInLibrary = this.currentReportList.filter(
      (report) => report.isView
    );
  }
  // =============================================================================
  // Dialogs and Refactor to Load more gracefully
  // =============================================================================
  @ViewChild('libraryConfigDialog') libraryConfigDialog: DialogComponent;
  @ViewChild('viewConfigDialog') viewConfigDialog: DialogComponent;
  @ViewChild('customReportsDialog') customConfigDialog: DialogComponent;
  public reportButtons: ToggleBtns[] = [];
  public reportButtonsFlag = true;
  public onLibraryConfigClick(): void {
    this._loadTempStates();
    this.libraryConfigDialog.show();
  }
  private _libraryToggleChanges: [] = [];
  public onLibraryConfigSave(): void {
    // this.spinnerService.toggle(true);
    this.libraryConfigDialog.hide();
    console.log('Library Config: ', this.updatedReportList);
  }
  public onViewConfigClick(): void {
    this.libraryConfigDialog.hide();
    this.viewConfigDialog.show();
  }
  public onCustomReportsClick(): void {
    this.libraryConfigDialog.hide();
    this.customConfigDialog.show();
  }

  onToggleChange(report: ReportCardDetails): void {
    this.reportButtonsFlag = false;
    report.hidden = !report.hidden;
    this.reportingLibraryService.updateReportLibraryEntry(report).subscribe(
      (data) => {
        console.log('Update Report: ', data);
        this.loadConfig();
      },
      (error) => {
        console.error('Error Updating Report: ', error);
      },
      () => {
        console.log('Update Report Completed');
        this.spinnerService.toggle(false);
      }
    );
  }
  public allCSVReportNames: string[] = [];
  onCustomReportGridActionBegin(data: any): void {
    console.log('Custom Report Grid Action Begin: ', data);
  }

  onCustomReportGridActionComplete(data: any): void {
    console.log('Custom Report Grid Action Complete: ', data);
    if (data.requestType === 'save') {
      // this.reportingLibraryService.addCustomReportToLibrary(data.data).subscribe();
    }
    switch (data.requestType) {
      case 'save':
        if (data.action === 'add') {
          this.reportingLibraryService
            .addCustomReportToLibrary(data.data)
            .subscribe(
              (data) => {
                console.log('Add Custom Report: ', data);
                this.loadConfig();
              },
              (error) => {
                console.error('Error Adding Custom Report: ', error);
              },
              () => {
                console.log('Add Custom Report Completed');
              }
            );
        } else if (data.action === 'edit') {
          console.log('Edit Custom Report: ', data);
          this.reportingLibraryService
            .updateCustomReportInLibrary(data.previousData, data.data)
            .subscribe(
              (data) => {
                console.log('Update Custom Report: ', data);
                this.loadConfig();
              },
              (error) => {
                console.error('Error Updating Custom Report: ', error);
              },
              () => {
                console.log('Update Custom Report Completed');
              }
            );
        }
        break;
      case 'delete':
        this.reportingLibraryService
          .deleteCustomReportFromLibrary(data?.data[0])
          .subscribe(
            (data) => {
              console.log('Delete Custom Report: ', data);
              this.loadConfig();
            },
            (error) => {
              console.error('Error Deleting Custom Report: ', error);
            },
            () => {
              console.log('Delete Custom Report Completed');
            }
          );
        break;
    }
    // this.loadConfig();
  }

  onViewDelete(data: any): void {
    console.log('View Delete: ', data);
    if (data.requestType === 'delete') {
      this.reportingLibraryService
        .deleteViewCardFromLibrary(data?.data[0])
        .subscribe(
          (data) => {
            console.log('Delete View: ', data);
            this.loadConfig();
          },
          (error) => {
            console.error('Error Deleting View: ', error);
          },
          () => {
            console.log('Delete View Completed');
          }
        );
    }
  }

  public get currentReportList(): ReportCardDetails[] {
    return this._currentReportList;
  }
  public get currentReportPerCategory(): any {
    return this._currentReportPerCategory;
  }
  public get currentCustomReportList(): ReportCardDetails[] {
    return this._currentCustomReportList;
  }
  public get currentViewCardReportList(): ReportCardDetails[] {
    return this._currentViewCardReportList;
  }
}
