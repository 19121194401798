import { CrudOptions, DataManager, DataOptions, DataResult, Query } from '@syncfusion/ej2-data';
import { VaultBaseUrlAdaptor } from '../../../services/VaultBaseUrlAdaptor';
import { TimezoneService } from '../../../services/timezone.service';
import { UnclassifiedRequestsComponent } from './unclassified-requests.component';

export class UnclassifiedRequestsAdaptor extends VaultBaseUrlAdaptor {

    constructor(
        private tzService: TimezoneService,
        private unclassifiedRequestsComponent: UnclassifiedRequestsComponent,
        private domain:string,
        private rmMatchResponse: any
    ) {
        super();
    }

    override processQuery(dm: DataManager, query: Query, hierarchyFilters?: Object[]): Object {
        console.log("processQuery", query, hierarchyFilters);

        let q = super.processQuery(dm, query, hierarchyFilters) as any;
        let queryObj = JSON.parse(q.data);

        queryObj.distinct = query.distincts[0];
        if (this.domain){
            queryObj.domain = this.domain;
        }

        queryObj.days = this.unclassifiedRequestsComponent.days;

        q.data = JSON.stringify(queryObj);

        return q;
    }

    override processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
        console.log(data, ds, query, xhr, request, changes);
        data.result = (data.result as any).map((item) => {
            let formattedTimestamp = this.tzService.format(new Date(item.ts).getTime(), false, true);
            return { ...item, formattedTimestamp: formattedTimestamp }
        });

        if (this.rmMatchResponse != undefined) {
            data.result = (data.result as any).map((item) => {
                let matchData = this.rmMatchResponse.find((rmMatch) => { return rmMatch.uuid == item.requestrecorduuid });
                let ret = { ...item };
                if (matchData) {
                    ret.matchData = matchData;
                }
                return ret;
            })
        }

        return data;
    }
}