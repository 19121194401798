export class Query { 
    public id?:number;
    public customerId?:number;
    public dateCreated?:Date;
    public createdBy?:string;
    public metadata?:any;
	constructor(		
		public query:string,
		public name:string,
		public description:string) {

    }
}