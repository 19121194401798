import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vendor-info',
  templateUrl: './vendor-info.component.html',
  styleUrls: ['./vendor-info.component.css']
})
export class VendorInfoComponent implements OnInit {

  @Input() public vendor:any;
  infoTabActive:boolean;
  dnsTabActive:boolean;
  codeTabActive:boolean;
  cookieTabActive:boolean;
  dataTabActive:boolean;

  constructor(private route: ActivatedRoute) { 
    this.infoTabActive = this.dnsTabActive = this.codeTabActive = this.cookieTabActive = this.dataTabActive = false;
    
  }

  ngOnInit() {
    const fragment = this.route.snapshot.fragment;
    switch(fragment){
      case "info":
        this.infoTabActive = true;
        break;
      case "dns":
        this.dnsTabActive = true;
        break;
      case "cookie":
        this.cookieTabActive = true;
        break;
      case "data":
        this.dataTabActive = true;
        break;
      default:
        this.codeTabActive = true;
    }
    console.log("Fragment", fragment);
  }

}
