import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ToolsComponent } from './tools/tools.component';
import { VendorDomainsComponent } from './tools/vendor-domains/vendor-domains.component';
import { VendorDomainsListComponent } from './tools/vendor-domains-list/vendor-domains-list.component';
import { NewVendorListComponent } from './tools/vendor-domains/new-vendor-list/new-vendor-list.component';
import { VendorEditComponent } from './tools/vendor-domains/vendor-edit/vendor-edit.component';
import { DomaineditComponent } from './tools/vendor-domains-list/domainedit/domainedit.component';
import { NewDomainComponent } from './tools/vendor-domains-list/new-domain/new-domain.component';
import { EventTriggerComponent } from './tools/event-trigger/event-trigger.component';
import { ReportsComponent } from './tools/reports/reports.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { RealtimeEventViewComponent } from './tools/realtime-event-view/realtime-event-view.component';
// import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import {LogMonitorModule} from 'ngx-log-monitor';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { CustomReportMenuItemEditorComponent } from './tools/custom-report-menu-item-editor/custom-report-menu-item-editor.component';
import { PersistentCustomReportMenuItemEditorComponent } from './tools/persistent-report-menu-item-editor/persistent-report-menu-item-editor.component';
import { RealtimeRegionalMapComponent } from './tools/realtime-regional-map/realtime-regional-map.component';
import { MapsModule } from '@syncfusion/ej2-angular-maps';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { EventViewerV2Component } from './tools/event-viewer-v2/event-viewer-v2.component';
import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    LogMonitorModule,
    NgxBootstrapSliderModule,
    MapsModule,
    MultiSelectModule,
    TabAllModule,
    ButtonAllModule,
    TextBoxAllModule
  ],
  declarations: [
    ToolsComponent,
    VendorDomainsComponent,
    VendorDomainsListComponent,
    NewVendorListComponent,
    VendorEditComponent,
    DomaineditComponent,
    NewDomainComponent,
    EventTriggerComponent,
    ReportsComponent,
    RealtimeEventViewComponent,
    CustomReportMenuItemEditorComponent,
    PersistentCustomReportMenuItemEditorComponent,
    RealtimeRegionalMapComponent,
    EventViewerV2Component
  ],
  exports: [
    ToolsComponent,
    VendorDomainsComponent,
    VendorDomainsListComponent,
    NewVendorListComponent,
    VendorEditComponent,
    DomaineditComponent,
    NewDomainComponent
  ]
})
export class DeveloperComponentsModule { }
