import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from '../../../models/Event';
import { Incident } from '../../../models/Incident';
import { TopicMessage } from '../../../models/TopicMessage';
import { AuthService } from '../../../services/auth/auth.service';
import { IncidentService } from '../../../services/incident/incident.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { environment as env } from '../../../../environments/environment';


declare var $: any;

const logTypes = require('../data/logTypes.json');
@Component({
  selector: 'app-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.css'],
})
export class IncidentDetailsComponent implements OnInit, AfterViewInit {
  detailsLoading: boolean;
  incident: Incident;
  incidentEvents: Event[];
  incidentId: number;
  codeFiles: any[];
  vendors: any[];
  messages: TopicMessage[];
  newMessageText: string;
  notificationOptions: any;
  messagesLoading: boolean;
  incidentUpdateForm: FormGroup;

  // Palette
  palette = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
  ];

  public cdnUrl = env.cdn.url;

  public getPalleteColor(_index) {
    const index = _index % this.palette.length;
    return this.palette[index];
  }
  // Lists
  listOfPossibleMerges: Array<any> = [];
  codeFilesList: Array<any> = [];
  mergedIncidentsList: Array<any> = [];
  mergedCodeFilesList: Array<any> = [];

  // Forms
  commentForm: FormGroup;
  mergeForm: FormGroup;
  editForm: FormGroup;

  private initializeForms() {
    this.commentForm = new FormGroup({
      comment: new FormControl(),
    });
    this.mergeForm = new FormGroup({
      mergeId: new FormControl(),
    });
    this.editForm = new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
    });
  }

  // UI Flags
  public showMergeAddView = false;

  // Type Definitions

  public severityTypes = {
    1: 'Yellow',
    2: 'Red',
  };

  public getLogTypes(type) {
    if ([type]) return logTypes[type];
    else return logTypes['default'];
  }

  public incidentDetails: any = {};

  public getSeverityColor(severity) {
    return severity === 1 ? 'orange' : 'red';
  }

  public selectedFile: any = {};
  public selectedMergeIncident: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sitemapService: SitemapService,
    private incidentService: IncidentService,
    private spinnerService: SpinnerService,
    public _fb: FormBuilder,
    public authService: AuthService,
    private timezoneService: TimezoneService,
    private vendorService: VendorsService
  ) {
    this.initializeForms();
    this.sitemapService.update(route);
  }
  public tempLogData: any = [];
  public logs: any = [];

  private validateIncident(incident) {
    return {
      id: incident.id,
      name: incident.name,
      status: incident.status,
      severity: incident.severity,
      dateCreated: incident.createdTimestamp,
      dateString: new Date(incident.createdTimestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      allAnalysts: [
        incident.createdBy,
        ...incident.allAnalysts.filter((el) => {
          return el !== incident.createdBy;
        }),
      ],
      isReviewRequested: incident.reviewRequested,
      description: incident.description,
      mergedIncidents: incident.mergedIncidents,
      isMerged: incident.isMerged,
    };
  }
  private vendorListing: any = [];

  private validateCodeFile(codeFile: any, fileIndex = 0, incidentIndex = 0) {
    codeFile.incidentIndex = incidentIndex;
    codeFile.nameString =
      'Code File ' + (incidentIndex + 1) + String.fromCharCode(fileIndex + 65);
    // //console.log('CODE FILE TS: ', codeFile.timestamp);
    codeFile.dateString = new Date(
      parseInt(codeFile.timestamp)
    ).toLocaleString();
    try {
      this.vendorListing.then((vendors) => {
        //console.log('VENDORS: ', vendors);
        const vendor = vendors.find((x) => x.id === codeFile.hostVendorId);
        codeFile.vendorDomain = vendor ? vendor.website : null;
        codeFile.vendorName = vendor ? vendor.name : null;
      });
      //console.log('Found vendor domain: ', codeFile.vendorDomain);
    } catch (err) {
      //console.log('Error getting domains for vendor', err);
    }
    codeFile.isUnclassified = codeFile.hostVendorId === undefined || codeFile.hostVendorId === null ? true : false;
    console.log('CODE FILE: ', codeFile);
    return codeFile;
  }

  private validateMergeIncident(incident, incidentIndex = 0) {
    incident.incidentIndex = incidentIndex;
    incident.dateString = new Date(incident.createdTimestamp).toLocaleString();
    return incident;
  }

  public sortCodeFiles(codeFiles) {
    return codeFiles.sort((a, b) => {
      return a.nameString.localeCompare(b.nameString);
    });
  }

  public sortMergeIncidents(incidents) {
    return incidents.sort((a, b) => {
      return a.incidentIndex - b.incidentIndex;
    });
  }

  private loadIncidentDetails() {
    this.spinnerService.toggle(true);
    this.incidentService
      .getIncidentV2(this.route.snapshot.params.incidentId)
      .subscribe(async (incident) => {
        //console.log('Incident from API: ', incident);
        incident = incident[0];
        //console.log('Retrieved Incident: ', incident);

        this.incidentDetails = this.validateIncident(incident);
        this.logs = incident.logs;

        this.mergedIncidentsList = [];
        this.codeFilesList = [];
        this.mergedCodeFilesList = [];

        incident.mergedIncidents.forEach(async (id, incidentIndex) => {
          let fileIndex = 0;
          let mergedIncident = await this.incidentService
            .getIncidentV2(id)
            .toPromise();
          this.mergedIncidentsList.push(
            this.validateMergeIncident(mergedIncident[0], incidentIndex + 1)
          );
          mergedIncident[0].requests.forEach((codeFile, fileIndex) => {
            this.mergedCodeFilesList.push(
              this.validateCodeFile(codeFile, fileIndex, incidentIndex + 1)
            );
          });
          //console.log('MERGED INCIDENTS: ', this.mergedIncidentsList);
          fileIndex++;
          this.selectedMergeIncident = this.mergedIncidentsList[0];
          if (this.codeFilesList.length == 0) {
            this.selectedFile = this.mergedCodeFilesList[0];
          }
        });
        this.codeFilesList = incident.requests.map((codeFile, index) => {
          return this.validateCodeFile(codeFile, index, 0);
        });
        //console.log('CODE FILES: ', this.codeFilesList);
        //console.log('SELECTED FILED: ', this.selectedFile);
        this.selectedFile = this.codeFilesList[0];

        this.tempLogData = await this.formatLogs(this.logs);
        setTimeout(() => {
          this.timeline = this.getLineData();
          this.spinnerService.toggle();
        }, 500);
      });
  }
  ngOnInit() {
    this.vendorListing = this.vendorService.getAllVendors().toPromise();
    this.loadIncidentDetails();
  }
  public timeline: any = {};
  ngAfterViewInit() {}

  public shortenString(str: string) {
    if (str.length > 12) {
      // create new string with only first and last 3 characters
      str = str.substring(0, 3) + '...' + str.substring(str.length - 3);
    }
    return str;
  }

  private generateColor(from, to) {
    const fromColor = this.getLogTypes(from.value).color;
    return fromColor;
  }

  public getLineData() {
    const markers = document.getElementsByClassName('classic');
    const mergedMarkers = document.getElementsByClassName('merged');
    const container = document.getElementById('log-timeline');
    const containerCoords = container.getBoundingClientRect();

    const xOffset = 20;
    const yOffset = 20;
    const numMarkers = markers.length;

    function setPathAttributes(path, d, color) {
      path.setAttribute('d', d);
      path.setAttribute('stroke', color);
      path.setAttribute('stroke-width', '5');
      path.setAttribute('fill', 'none');
      return path;
    }

    function findLineCoords(from, to) {
      const fromCoords = from.getBoundingClientRect();
      const toCoords = to.getBoundingClientRect();
      const x1 = Math.abs(containerCoords.x - fromCoords.x);
      const y1 = Math.abs(containerCoords.y - fromCoords.y);
      const x2 = Math.abs(containerCoords.x - toCoords.x);
      const y2 = Math.abs(containerCoords.y - toCoords.y);
      return { x1, y1, x2, y2 };
    }

    const svg = document.getElementById('incident-svg');
    let offsetLine = false;
    let prevColor = 'black';

    // //console.log('FOUND MARKERS ', markers.length);
    // //console.log('FOUND MERGED MARKERS ', mergedMarkers.length);

    for (let i = 0; i < numMarkers - 1; i++) {
      if (markers[i].getAttribute('show') == 'false') {
        offsetLine = true;
      } else {
        offsetLine = false;
      }
      // //console.log('CLASSIC PATH CREATED ', i);
      const line = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      const from = markers[i];
      const to = markers[i + 1];

      let color: any = 'black';
      if (offsetLine) {
        color = prevColor;
      } else {
        color = this.generateColor(from, to);
      }
      prevColor = color;
      if (to) {
        const coords = findLineCoords(from, to);
        const updatedLine = setPathAttributes(
          line,
          `M${coords.x1 + xOffset} ${coords.y1 + yOffset} L${
            coords.x2 + xOffset
          } ${coords.y2 + yOffset}`,
          color
        );
        svg.appendChild(updatedLine);
        // //console.log('CLASSIC PATH ADDED ', i);
      }
    }
    for (let i = 0; i < mergedMarkers.length; i++) {
      if (mergedMarkers[i].getAttribute('show') == 'false') {
        continue;
      }
      // //console.log(mergedMarkers[i].getAttribute('show'));
      // //console.log('MERGE PATH CREATED ', i);

      const path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      const from = mergedMarkers[i];
      const to = markers[i];
      const nextMarker = markers[i + 1];
      const extension = nextMarker.getAttribute('show') === 'false' ? 40 : 0;
      let color: any = 'black';
      color = this.generateColor(from, to);
      if (to) {
        const coords = findLineCoords(from, to);
        const nextCoords = findLineCoords(to, nextMarker);
        const updatedLine = setPathAttributes(
          path,
          `
          M${coords.x1 + xOffset} ${coords.y1 + yOffset} 
          L${coords.x2 + xOffset} ${coords.y2 + yOffset + 30} 
          Q${coords.x2 + xOffset} ${coords.y2 + yOffset + 10} ${
            nextCoords.x1 + xOffset + 20
          } ${nextCoords.y1 + yOffset + 10} 
          L${nextCoords.x2 + xOffset + extension} ${
            nextCoords.y2 + yOffset + 10
          }
          `,
          color
        );
        // //console.log(updatedLine);
        svg.appendChild(updatedLine);
        // //console.log('MERGE PATH ADDED ', i);
      }
    }
    // svg.setAttribute('width', '1000px');
    svg.setAttribute(
      'width',
      markers[markers.length - 1].getBoundingClientRect().x +
        markers[markers.length - 1].getBoundingClientRect().width +
        100 +
        'px'
    );
    svg.setAttribute('height', '300px');
  }

  public isCardVisible = false;
  public cardDetails = {
    type: 0,
    title: '',
    date: '',
    user: '',
    comment: '',
    isHidden: false,
  };

  public showDetailsCard(log) {
    // //console.log('SHOWING DETAILS CARD: ', log);
    const cardEl = document.getElementById('log-details-card');
    cardEl.style.opacity = '0';

    setTimeout(() => {
      this.isCardVisible = true;
      this.cardDetails.type = log.type;
      this.cardDetails.title = this.getLogTypes(log.type).label;
      this.cardDetails.date = new Date(log.epoch).toLocaleString();
      const user = log.user ? log.user.split('.').join(' ') : 'System';
      this.cardDetails.user = user;
      this.cardDetails.comment = log.data;
      this.cardDetails.isHidden = log.isHidden;
      cardEl.style.opacity = '1';
      cardEl.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }

  public hideDetailsCard() {
    this.isCardVisible = false;
  }

  private validateLogs(logs) {
    // //console.log(Object.keys(logs));
    //console.log(typeof logs, ' ', logs);
    const keys = Object.keys(logs);
    if (keys.length === 0) {
      return [];
    }
    return logs.map((log) => {
      // //console.log(log);
      return {
        epoch: log.epoch,
        timeString: new Date(log.epoch).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }), // get the time
        type: log.type || 'default',
        user: log.user || 'Missing User Data', // get the user name
        data: log.data || 'No Data',
        isChild: log.type === 'merge', // change on merge list implementation
        isVaultUser: log.isVaultUser, // check the user id
        isFiller: false,
        orderId: null,
        isHidden: log.isHidden || false,
      };
    });
  }
  private createFillerLog(log) {
    const emptyLog: any = {};
    emptyLog.epoch = log.epoch;
    emptyLog.type = '';
    emptyLog.message = '';
    emptyLog.isFiller = true;
    return emptyLog;
  }

  public async formatLogs(_logs) {
    // group by day

    let logs = this.validateLogs(_logs).filter((x) => x.epoch !== null);
    if (!this.authService.isVaultUser()) {
      logs = logs.filter((x) => x.isHidden !== true);
    }
    logs.sort((b, a) => {
      return b.epoch - a.epoch;
    });
    logs.unshift({
      epoch: this.incidentDetails.dateCreated,
      isChild: false,
      isFiller: false,
      isVaultUser: true,
      type: 'create',
      timeString: new Date(this.incidentDetails.dateCreated).toLocaleTimeString(
        [],
        {
          hour: '2-digit',
          minute: '2-digit',
        }
      ),
      user: this.incidentDetails.allAnalysts[0],
      data: 'Incident Created',
    });
    const lastLog = logs.length - 1;

    logs.forEach((log, index) => {
      log.orderId = index;
      if (lastLog == index && log.isChild) {
        const tempLog = this.createFillerLog(log);
        tempLog.orderId = index + 1;
        tempLog.epoch = log.epoch;
        tempLog.timeString = new Date(log.epoch).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        tempLog.isFiller = false;
        tempLog.isChild = false;
        tempLog.isVaultUser = log.isVaultUser;
        tempLog.data = 'Incoming Incident';
        tempLog.type = 'incoming_merge';
        logs.push(tempLog);
      } else {
        return logs;
      }
    });
    //console.log('CURATED LOGS: ', logs);
    const groupedByDay = [];
    logs.forEach((log) => {
      const day = new Date(log.epoch).toDateString();
      const existingDay = groupedByDay.find((x) => x.day === day);
      if (existingDay) {
        if (log.isChild) {
          existingDay.mergedLogs.push(log);
          existingDay.logs.push(this.createFillerLog(log));
        } else {
          existingDay.logs.push(log);
          existingDay.mergedLogs.push(this.createFillerLog(log));
        }
      } else {
        if (log.isChild) {
          groupedByDay.push({
            day: day,
            logs: [this.createFillerLog(log)],
            mergedLogs: [log],
          });
        } else {
          groupedByDay.push({
            day: day,
            logs: [log],
            mergedLogs: [this.createFillerLog(log)],
          });
        }
      }
    });

    //console.log(groupedByDay);
    groupedByDay.forEach((day) => {
      day.logs.sort((b, a) => {
        return b.epoch - a.epoch;
      });
      day.mergedLogs.sort((b, a) => {
        return b.epoch - a.epoch;
      });
    });
    // //console.log(groupedByDay);
    return Promise.resolve(groupedByDay);
  }

  public onFileSelect(file) {
    this.selectedFile = file;
    this.addingFile = false;
  }
  public addingFile = false;
  public onAddSelect() {
    this.addingFile = true;
  }
  public onMergeSelect(incident) {
    this.selectedMergeIncident = incident;
  }

  private createLogEvent(
    type = 'default',
    message = 'Undefined Message',
    isHidden?: boolean
  ) {
    const logEvent = {};
    logEvent['epoch'] = new Date();
    logEvent['type'] = type;
    logEvent['data'] = message;
    logEvent['user'] = this.authService.getUserProfile().nickname;
    logEvent['isVaultUser'] = this.authService.isVaultUser();
    logEvent['isHidden'] = isHidden || false;
    //console.log('LOG EVENT: ', logEvent);
    return logEvent;
  }

  public isCommentHidden = true;

  public toggleComment() {
    this.isCommentHidden = !this.isCommentHidden;
  }

  public onCommentSubmit(): void {
    this.spinnerService.toggle(true);
    const comment = this.commentForm.value.comment;
    const isHidden = this.isCommentHidden;
    //console.log('COMMENT: ', comment);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [this.createLogEvent('comment', comment, isHidden)],
        mergedIncidents: [],
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public onMergeSubmit(): void {
    this.spinnerService.toggle(true);
    this.onMergeAddViewClose();
    const mergeId = this.mergeForm.value.mergeId;
    // //console.log('MERGE ID: ', mergeId);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [
          this.createLogEvent(
            'merge',
            `Merging Incident ${mergeId} with this Incident`
          ),
        ],
        mergedIncidents: [mergeId],
      })
      .subscribe((response) => {
        //console.log(response);
        this.incidentService
          .updateIncidentV2({
            id: mergeId,
            logs: [
              this.createLogEvent(
                'merged',
                `Merged with Incident ${this.incidentDetails.id}`
              ),
            ],
          })
          .subscribe((response) => {
            //console.log(response);
            this.spinnerService.toggle();
            this.refreshAll();
          });
      });
  }

  public onResolveSubmit(): void {
    this.onDialogClose('#status-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [this.createLogEvent('resolve', 'Incident Resolved')],
        status: 2,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }
  public onReopenSubmit(): void {
    this.onDialogClose('#status-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [this.createLogEvent('reopen', 'Incident Reopened')],
        status: 1,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }
  public onReviewCompleteSubmit(): void {
    this.onDialogClose('#review-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [this.createLogEvent('submit_review', 'Review Submitted')],
        reviewRequested: 0,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }
  public onReviewRequestSubmit(): void {
    this.onDialogClose('#review-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [this.createLogEvent('request_review', 'Review Requested')],
        reviewRequested: 1,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public onAckReopenSubmit(): void {
    this.onDialogClose('#acknowledge-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [
          this.createLogEvent(
            'flag_reopen',
            'Incident acknowledge to be Reopened'
          ),
        ],
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public onAckResolveSubmit(): void {
    this.onDialogClose('#acknowledge-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [
          this.createLogEvent(
            'flag_resolve',
            'Incident acknowledge to be Resolved'
          ),
        ],
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public onRedSeveritySubmit(): void {
    this.onDialogClose('#severity-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [
          this.createLogEvent(
            'red_severity',
            'Severity of Incident changed to Red'
          ),
        ],
        severity: 2,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }
  public onOrangeSeveritySubmit(): void {
    this.onDialogClose('#severity-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [
          this.createLogEvent(
            'orange_severity',
            'Severity of Incident changed to Orange'
          ),
        ],
        severity: 1,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public compareEdits(oldData, newData) {
    newData.name = newData.name || oldData.name;
    newData.description = newData.description || oldData.description;
    return {
      previousName: oldData.name,
      newName: newData.name,
      previousDescription: oldData.description,
      newDescription: newData.description,
    };
  }

  public onEditSubmit(): void {
    const newData = this.editForm.value;
    const oldData = this.incidentDetails;
    const compareData = this.compareEdits(oldData, newData);
    this.onDialogClose('#edit-dialog');
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        logs: [
          this.createLogEvent(
            'update',
            `"${compareData.previousName}" to "${compareData.newName}" |` +
              ` "${compareData.previousDescription}" to "${compareData.newDescription}"`
          ),
        ],
        name: newData.name,
        descriptiON: newData.description,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public resetAll() {
    this.spinnerService.toggle(true);
    this.incidentService
      .updateIncidentV2({
        id: this.incidentDetails.id,
        action: 'reset',
        mergedIncidents: [],
        requestData: [],
        logs: [],
        isMerged: 0,
      })
      .subscribe((response) => {
        //console.log(response);
        this.spinnerService.toggle();
        this.refreshAll();
      });
  }

  public checkForUpdates() {}

  // UI Actions

  public onDialogOpen(identifier): void {
    const el = $(identifier);
    //console.log('OPENING DIALOG: ', identifier);
    this.isCommentHidden = false;
    el.modal('show');
  }

  public onDialogClose(identifier): void {
    const el = $(identifier);
    //console.log('CLOSING DIALOG: ', identifier);
    el.modal('hide');
  }

  public onMergeAddViewOpen(): void {
    this.showMergeAddView = true;
    this.loadListOfPossibleMerges();
  }
  public onMergeAddViewClose(): void {
    this.showMergeAddView = false;
  }

  public onViewLatest(): void {
    const el = document.getElementById('incident-svg');
    el.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  }

  public refreshTimeline() {
    const svg = document.getElementById('incident-svg');
    svg.innerHTML = '';
    this.getLineData();
  }

  private refreshAll() {
    this.spinnerService.toggle(true);
    this.loadIncidentDetails();
    this.refreshTimeline();
    this.spinnerService.toggle();
  }

  // Change Local Data

  private loadListOfPossibleMerges() {
    this.getIncidentList().then((data) => {
      this.listOfPossibleMerges = data.filter((x) => {
        return (
          x.id !== this.incidentDetails.id &&
          x.isMerged === 0 &&
          this.incidentDetails.mergedIncidents.includes(x.id) === false
        );
      });
    });
  }
  private async getIncidentList(): Promise<any> {
    let incidentList = await this.incidentService.getIncidentsV2().toPromise();
    incidentList = incidentList.map((i) => {
      return {
        ...i,
        formattedTimestamp: this.timezoneService.format(
          i.createdTimestamp,
          false,
          true
        ),
      };
    });
    return Promise.resolve(incidentList);
  }

  linkToPageHistory(event, historyId) {
    event.preventDefault();
    let url = `pages/0/history/${historyId}`;
    window.open(url);
  }

  linkToVendor(event, obj) {
    const url = `vendors/${obj.hostVendorId}`;
    window.open(url);
  }

  linkToRequestDetails(event, obj) {
    //console.log(event);
    //console.log(obj);
    event.preventDefault();
    //console.log('linkToResource');
    //console.log(obj);

    let url = `vendors/${obj.hostVendorId}/request/${obj.resourcematchid}`;
    let qps: any[] = [`vjsHash=${obj.responseHash}`];
    if (obj.extracted) {
      try {
        let extractedObj = JSON.parse(obj.extracted);
        let eKeys = Object.keys(extractedObj);
        if (eKeys.length > 0) {
          qps = qps.concat(
            eKeys.map((k) => {
              return `${k}=${extractedObj[k]}`;
            })
          );
        }
      } catch (e) {
        //console.log(e);
      }
    }

    url += `?${qps.join('&')}`;

    //console.log(url);

    window.open(url);
  }

  // async addCodeFileToIncident(event){
  //   console.log(event);
  //   this.spinnerService.toggle(true);
  //   let response = await this.incidentService.addRequestsToIncident(this.incidentDetails, ['74eeb280-8dc8-11ee-be1a-31573afd248e']).toPromise()
  //   this.spinnerService.toggle();

  //   console.log(response);
  // }
}
