<div class="row">
    <div class="col-md-12">
      <div class="card be-loading">
        <div class="card-header card-header-divider">
            <div class="tools">
                <button type="button" (click)="onTestClick();" class="btn btn-rounded btn-space btn-primary">Run Test Query</button>
            </div>
            Domain Definition Details<span class="card-subtitle">Edit the properties of the domain definition.</span>
        </div>
        <div class="card-body">
            <h4>Domain Matchers</h4>
            <ejs-grid 
                #domainMatchersGrid 
                id='domainMatchersGrid' 
                [dataSource]='model.domainMatchers'
                [allowPaging]='true' 
                [pageSettings]='pageSettings' 
                [editSettings]='editSettings'                 
                [allowSelection]='true' 
                allowResizing="true"
                [toolbar]='toolbar'
                (actionBegin)="domainMatcherActionBegin($event)"
            >
                <e-columns>                    
                    <e-column field='name' headerText='Name'></e-column>
                    <e-column field='value' headerText='Value'></e-column>
                </e-columns>
            </ejs-grid>
            <h4>Default Matcher Attributes</h4>
            <ejs-grid 
                #defaultAttributesGrid 
                id='defaultAttributesGrid'
                [dataSource]='model.defaultMatcherAttributes'
                [allowPaging]='true' 
                [pageSettings]='pageSettings' 
                [editSettings]='editSettings'                 
                [allowSelection]='true' 
                [toolbar]='toolbar'
                allowResizing="true"
                (actionBegin)="defaultAttributeActionBegin($event)"
            >
                <e-columns>                    
                    <e-column field='matcherAttributeKeyId' headerText='Name' editType='dropdownedit' [edit]='matcherAttributeKeyEditParams'>
                        <ng-template #template let-data>
                            {{ matcherAttributeKeysById[data.matcherAttributeKeyId].name }}
                        </ng-template>
                    </e-column>
                    <e-column field='value' headerText='Value'></e-column>
                </e-columns>
            </ejs-grid>

            <h4>Resource Matchers</h4>
            <ejs-grid 
                #resourceMatchersGrid
                id='resourceMatchersGrid' 
                [dataSource]='model.resourceMatchers'
                [allowPaging]='true'
                [pageSettings]='pageSettings' 
                [editSettings]='resourceMatcherEditSettings'                 
                [allowSelection]='true'
                [toolbar]='resourceMatcherToolbar'
                [selectionSettings]='selectOptions'
                allowResizing="true"
                (actionBegin)="resourceMatcherActionBegin($event)"
                (commandClick)="commandClick($event)"
            >
                <e-columns>                    
                    <e-column headerText='' width='150' [commands]='commands'></e-column>
                    <e-column field='name' headerText='Name'></e-column>
                    <e-column field='description' headerText='Description'></e-column>
                    <e-column field='value' headerText='Value'></e-column>
                    <e-column field='isMonitoringEnabled' headerText='Monitoring Enabled' editType='booleanedit' type='boolean'></e-column>
                    <e-column field='isMonitoringStatic' headerText='Monitoring Static' editType='booleanedit' type='boolean'></e-column>
                    <e-column field='monitoringMask' headerText='Monitoring Mask'></e-column>                    
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>

<div class="custom-width query-results-modal modal fade" id="modal-query-results" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <ejs-grid #resultsGrid 
                locale='en-US' 
                [allowPaging]="true" 
                [pageSettings]="resultsGridPageSettings" 
                [allowSorting]="true" 
                [allowSelection]="false"
                [enableHover]="false"
                allowFiltering="true"
                [filterSettings]="resultsGridFilterSettings"     
                [allowResizing]="true"
                height="100%"
                width="100%"
                >         
                </ejs-grid>  
            <div class="mt-8">
              <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>