import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { Query } from '../../models/Query';
import { DataExportService } from '../../services/data-export/data-export.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { Papa } from 'ngx-papaparse';
import { concatMap, delay, mergeMap, retryWhen, scan, take } from 'rxjs/operators';
import { concat, of, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { VaultNotificationService } from '../../services/notifications/vault-notification.service';
import { Router } from '@angular/router';
import { environment as env } from "../../../environments/environment";
import { GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { QueryService } from '../../services/query/query.service';
declare var $:any;

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.css']
})
export class QueriesComponent implements OnInit, AfterViewInit {

  queries:Query[];
  showSqlEditor:boolean;
  querySql:string;
  codeMirrorOptions:any;  
  @ViewChild('newCodeFileViewer') 
  sqlEditor:CodemirrorComponent;
  
  tableOptions:any;
  queryTable:any;
  queryTableWidget:any;
  selectedQuery:Query;
  runResult:any;

  data:any;
  dataProperties:any;
  manifestFile:any;
  cursorLabels:any;
  fileCounter:number;
  exportUrl:string;

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public filterSettings: Object;
  public cdnUrl:string;
  public dateFormat:any;
  @ViewChild('grid') public grid: GridComponent;

  isEditing:boolean;
  savedQuery:Query;

  constructor( 
    public spinnerService:SpinnerService,
    public el: ElementRef, 
    public dataExportService:DataExportService,
    public papa:Papa,
    public authService:AuthService,
    public notificationService:VaultNotificationService,
    public router:Router,
    public queryService:QueryService
  ) { 
    this.showSqlEditor = false;
    this.querySql = "";
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'text/x-mysql',
      readOnly: true
    };

    this.fileCounter = 0;
    this.exportUrl = null;

    this.dateFormat = {type: 'dateTime', skeleton:'short'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.filterSettings = { type: 'Excel' };

    // this.selectedQuery = new Query("", "", "");
    this.isEditing = false;
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.spinnerService.toggle(true);    
    this.loadQueries();   
  }

  loadQueries(){
    this.queryService.getQuery(-1).subscribe((queries)=>{
      console.log(queries);
      this.queries = queries;
      if (this.queries && this.queries.length > 0){
        this.selectedQuery = queries[0];
        this.querySql = this.selectedQuery.query;
      }      
      this.spinnerService.toggle(false);
    })
  }

  createQuery(){
    this.router.navigate(["admin/queries", "new"]);
  }

  deleteQuery(){
    console.log('Delete query...');
    $("#mod-confirm-delete").modal('toggle');
    if (this.selectedQuery){
      this.spinnerService.toggle(true);
      this.queryService.deleteQuery(this.selectedQuery.id).subscribe((deleteResponse)=>{
        
        console.log(deleteResponse);
        
        this.loadQueries();

        this.selectedQuery = null;
        this.querySql = "";
        this.showSqlEditor = false;

        this.notificationService.success('Success!', 'The query was deleted successfully.');
      })
    } else {
      this.notificationService.warn('Oops!', 'Please select a query.');
    }
  }

  runQuery(){
    this.fileCounter = 0;
    this.runResult = null;
    this.manifestFile = null;
    this.data = null;
    this.dataProperties = null;

    // this.grid.dataSource = [];
    // this.grid.columns = [];

    setTimeout(() => {
      this.grid.refresh();      
    }, 100);

    // if (typeof this.selectedQuery.metadata === 'string' || this.selectedQuery.metadata instanceof String ) {
    //   try{
    //     let metadata = JSON.parse(String(this.selectedQuery.metadata));
    //     if ( metadata && metadata.isAthenaQuery == true){
    //       this.notificationService.warn('Info', 'Athena queries are not currently able to be run on demand.');
    //       return;
    //     }
    //   }catch(err){
    //     console.log('Parse error for query metadata');
    //   }
    // }

    console.log('Run query...' + this.selectedQuery.id);
    this.spinnerService.toggle(true);
    this.queryService.runQuery(this.selectedQuery.id).subscribe((runResult)=>{
      console.log(runResult);
      this.runResult = runResult;
      this.checkStatus();
    });
  }

  checkStatus(){
    this.spinnerService.toggle(true);
    this.queryService.runQuery(this.selectedQuery.id, this.runResult.dataId, this.runResult.uuid)
    .pipe(          
      retryWhen(result =>        
        result.pipe(
          scan((acc, error) => ({ count: acc.count + 1, error }), {
            count: 0,
            error: undefined as any,
          }),
          concatMap(result => {
            // here we can check the error.
            // We can specify the retry only if we are getting 5xx errors for instance.
            if (result.error.status === 503) {
              return of(result);
            }
            // in other cases we throw an error down the pipe
            return throwError(result);
          }),
          mergeMap( payload =>{
            return of(payload).pipe(delay(1000 * payload.count))
          }),
          // we can keep calling forever but usually we want to avoid this.
          // So, we set the number of attempts including the initial one.
          take(15),
          o => concat(o, throwError({error:`Unable to load data. Please try again or contact support`}))
        )
      ),
    ).subscribe((runResult)=>{
      console.log(runResult);
      if (runResult.message && runResult.message == 'Complete' ) {
                  
        if ( runResult.type == 'redshift') {
          let uuid = this.runResult.uuid;
          let manifestFilePath = `${localStorage.cid}/query/${uuid}/${uuid}_manifest`;
          this.dataExportService.getSignedUrl(`${env.aws.exportBucket}/${localStorage.cid}/query/${uuid}_000.gz`).subscribe((url)=>{this.exportUrl = url;});
          this.dataExportService.getQueryResultFile(manifestFilePath).subscribe((manifestFileResponse )=>{
            this.manifestFile = JSON.parse(manifestFileResponse.toString());
            this.cursorLabels = [];
            var counter = 0;
            this.manifestFile.entries.forEach((entry, index, arr) => {
              console.log(entry);
              if ( index == 0 ){
                this.cursorLabels.push({value: index, text: `Records 1 - ${entry.meta.record_count}`});
              } else {
                this.cursorLabels.push({ value: index, text: `Records ${ counter + 1 } - ${counter + entry.meta.record_count}` });
              }
              counter += entry.meta.record_count;
            });
  
            console.log("this.cursorLabels");
            console.log(this.cursorLabels);
          
            this.loadManifestFileEntry(this.fileCounter);
          });
        } else if (runResult.type == 'athena' ) {
          this.dataExportService.getSignedUrl(`${env.aws.exportBucket}/${localStorage.cid}/query/${this.runResult.dataId}.csv`).subscribe((url)=>{this.exportUrl = url;});
          this.loadCSV(`${localStorage.cid}/query/${this.runResult.dataId}.csv`);
        }        
      } else if ( runResult.error ){
        this.notificationService.error("Uh oh...", runResult.erro);
        this.spinnerService.toggle(false);
      }
    });
  }

  loadManifestFileEntry(index){
    let fileEntry = this.manifestFile.entries[index];  
    this.loadCSV(fileEntry.url);    
  }

  loadCSV(url) {  
    url = url.replace("s3://", "").replace(env.aws.exportBucket + "/", "");
    console.log("url");
    console.log(url);
    this.dataExportService.getQueryResultFile(url).subscribe((queryResults)=>{
      let csv  = queryResults.toString();
      this.papa.parse(csv, {
        header: true,
        skipEmptyLines: "greedy",
        complete: (results, file)=>{
          
          console.log("Papa results:");
          console.log(results);

          this.data = results.data;

          this.spinnerService.toggle(false);

          // this.columnDefs = this.manifestFile.schema.elements.map((field)=>{
          //   return {field: field.name, sortable: true, filter: true, resizable: true}
          // });
          
          // this.rowData = this.data;
          
          this.grid.dataSource = [];
          this.grid.columns = [];

          this.grid.dataSource = this.data;

          setTimeout(() => {
            // this.grid.refresh();
            this.grid.autoFitColumns();
          }, 100);

        },
        error: (error, file)=>{
          console.log("Papa error:")
          console.log(error);

          this.spinnerService.toggle(false);
        }
      });
    });
  }

  onFileChange(event){
    console.log("onFileChange");
    console.log(this.fileCounter);
    
    this.spinnerService.toggle(true);
    this.loadManifestFileEntry(this.fileCounter);    
  }

  computeCursorLabel(index){
    console.log(index);
    //File {{i + 1}}
  }

  onQueryChange(event){
    let selectedIndex = event.target.selectedIndex;
    console.log('Selected Index', selectedIndex);
    var selectedQuery = this.queries[selectedIndex];
    if ( selectedQuery != null ) {
      this.selectedQuery = selectedQuery;
      this.querySql = selectedQuery.query;
    }
  }

  onEditClick(){
    console.log('edit click before: ')
    console.log(this.selectedQuery);
    if ( (!this.selectedQuery ||  (this.selectedQuery && this.selectedQuery.id == -1)) && this.savedQuery){
      this.selectedQuery = this.queries[0];
    } 
    console.log('edit click after: ')    
    console.log(this.selectedQuery);
    this.isEditing = true;
  }

  onNewClick(){
    this.savedQuery = this.selectedQuery;
    this.selectedQuery = new Query("", "", "");
    this.selectedQuery.id = -1;     
    this.isEditing = true;
  }

  onEditCancel(event){
    this.isEditing = false;
  }

  onEditSaveUpdate(query){
    console.log(query);
    this.isEditing = false;
    this.spinnerService.toggle(true);    
    this.loadQueries();
  }

}
