<div class="card card-flat" style="padding: 10px;">
    <div class="card-body">
        <div class="be-booking-promo-price">
            <span class="label">{{label}}</span>
            <div class="be-booking-promo-amount">
                <span class="singleNumber">
                    <ng9-odometer [number]="value" format="(,ddd).d"></ng9-odometer>
                </span>
            </div>
        </div>
    </div>
</div> 
