import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  Sparkline,
  SparklineTheme,
  ISparklineLoadEventArgs,
} from '@syncfusion/ej2-charts';
import { ViolationSummaryByRisk } from '../../dashboardDS';
declare var $: any;

@Component({
  selector: 'basic-violation-risk-summary-panel',
  templateUrl: './basic-violation-risk-summary-panel.component.html',
  styleUrls: ['./basic-violation-risk-summary-panel.component.css'],
})
export class BasicViolationRiskSummaryPanelComponent
  implements OnInit, OnChanges
{
  public sortSettings: { columns: { field: string; direction: string }[] };
  @Input('data') data: ViolationSummaryByRisk[];
  public gridData: Object[];

  constructor() {
   this.updateData();
  }
  refreshGraphs(event) {
    setTimeout(() => {
      $('.sparklinebasic').sparkline('html', {
        highlightSpotColor: 'black',
        highlightLineColor: 'black',
        fillColor: false,
        spotColor: false,
        minSpotColor: false,
        maxSpotColor: false,
        lineWidth: 1,
        enableTagOptions: true,
      });
      $('.sparkpie').sparkline('html', { type: 'pie', height: '2.0em' });
    }, 0);
  }

  public colorCutoff = (value: any, cutoffA: any, cutoffB: any) => {
    if (value < cutoffA) {
      return 'green';
    } else if (value < cutoffB) {
      return 'orange';
    } else {
      return 'red';
    }
  };
  updateData(){

    this.gridData  = this.data?.map((item) => {
      item.colors[0] = this.colorCutoff(item.percentPages, 5, 50);
      item.colors[1] = this.colorCutoff(item.domains, 1, 3);
      item.colors[2] = this.colorCutoff(item.instances, 100, 500);
      return item;
    }) || [];
    
  }
  ngOnChanges(): void {
    this.updateData();
  }
  ngOnInit(): void {
    this.sortSettings = {
      columns: [],
    };
    this.updateData();
  }
}
