import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const TRACKER_SUMMARY = 'data/report/v2/trackersummary';
const TRACKER_SUMMARY_DOMAIN = 'data/report/v2/trackersummarydomain';
const TRACKER_SUMMARY_DOMAIN_VENDOR =
  'data/report/v2/trackersummarydomainvendor';
const TRACKER_SUMMARY_VENDOR = 'data/report/v2/trackersummaryvendor';

@Injectable({
  providedIn: 'root',
})
export class TrackerDashboardService {
  private _api_url = env.api.url;

  constructor(private authHttp: HttpClient) {}

  public getTrackerSummary(): Observable<any> {
    return this.authHttp.post(this._api_url + TRACKER_SUMMARY, {
      skip: 0,
      take: 10,
    }).pipe(
      map((response: any) => {
        return response.result[0]
      })
    );
  }
  public getTrackerSummaryDomain(): Observable<any> {
    return this.authHttp.post(this._api_url + TRACKER_SUMMARY_DOMAIN, {
      take: 10000
    }).pipe(
      map((response: any) => {
        return response.result;
      })
    );
  }
  public getTrackerSummaryDomainVendor(): Observable<any> {
    return this.authHttp.post(this._api_url + TRACKER_SUMMARY_DOMAIN_VENDOR, {
      take: 10000,
    }).pipe(
      map((response: any) => {
        return response.result;
      })
    );
  }
  public getTrackerSummaryVendor(): Observable<any> {
    return this.authHttp.post(this._api_url + TRACKER_SUMMARY_VENDOR, {
      take: 10000,
    }).pipe(
      map((response: any) => {
        return response.result;
      })
    );
  }



}
