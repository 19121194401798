import { Injectable } from '@angular/core';
import { CrudOptions, DataOptions, DataResult, Query } from '@syncfusion/ej2-data';
import { environment as env } from "../../../../environments/environment";
import { VaultBaseUrlAdaptor } from '../../../services/VaultBaseUrlAdaptor';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { RegionService } from '../../../services/region/region.service';
import { TimezoneService } from '../../../services/timezone.service';
@Injectable({
    providedIn: 'root'
})
export class PageDetailsAdaptor extends VaultBaseUrlAdaptor {

    public isFilterSearch: boolean = false;

    constructor(
        private timezoneService: TimezoneService,
        private dataExportService: DataExportService,
        private regionService: RegionService,
    ) {
        super();
    }

    override processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
        console.log('processResponse');
        console.log(data, ds, query, xhr, request, changes);
        // console.log("isFilterSearch", this.isFilterSearch);

        data.result = (data.result as any[]).map((item) => {
            let screenShotUrlPromise = this.dataExportService.getSignedUrl(`${item.dataLocation}screenshot.png`);
            var retObj = {
                ...item,
                regionLabel: this.regionService.getDescription(item.region),
                lastCheckedFormatted: this.timezoneService.format(Number(item.lastChecked), false, true),
                domContentLoaded: (item && item.metrics && item.metrics.find((m) => { return m.name == "DomContentLoaded" })) ? Math.trunc(item.metrics.find((m) => { return m.name == "DomContentLoaded" }).value * 100) / 100 : "N/A",
                ScriptDuration: (item && item.metrics && item.metrics.find((m) => { return m.name == "ScriptDuration" })) ? item.metrics.find((m) => { return m.name == "ScriptDuration" }).value : "N/A",
                tooltip: "Screenshot"
            };
            screenShotUrlPromise.subscribe((screenShotUrl) => {
                // console.log(screenShotUrl);
                retObj.tooltip = `<img style='object-fit:contain; max-height:600px; margin:0px;' width="100%" onerror="this.onerror=null; this.src='assets/svg/no-image-svgrepo-com.svg'" src='${screenShotUrl}'/>`
            })
            return retObj;
        });

        if (this.isFilterSearch == true) {

            data = data.result;
            this.isFilterSearch = false;
        }

        // console.log(data);
        return data;
    }
}