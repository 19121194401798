<div class="row">
  <div class="col-lg-6">
    <div class="card card-table card-border be-loading" [class.be-loading-active]="dataLoading" style="height: 750px;">
      <div class="card-header">
        <div class="tools">
          <button *ngIf="authService.isVaultUser()" type="button" class="btn btn-rounded btn-space btn-success" (click)="runNow();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i> Run Now</button>
        </div>
        Script Runs<span class="card-subtitle"></span></div>
      <div class="card-body">
        <div class="col-lg-12">
          <ejs-grid #historyGrid
                [dataSource]='ej2HistoryData | async'
                height='475'
                locale='en-US' 
                [selectedRowIndex]=0
                [allowPaging]="true" 
                [pageSettings]="pageSettings" 
                [allowSorting]="true" 
                [sortSettings]="sortSettingsHistory"
                [allowSelection]="false"
                [enableHover]="false"
                (dataStateChange)= 'dataStateChange($event)'
                [allowResizing]="true" 
                [allowSelection]="true"
                (rowSelecting)="onHistorySelecting($event)"
                (rowSelected)="onHistorySelected($event)"
                (rowDeselecting)="onHistoryDeselecting($event)">
                  <e-columns>                    
                    <e-column field='timestamp' headerText='Date' width='27%'>
                      <ng-template #template let-data>{{ timezoneService.format(data.timestamp)}}</ng-template>
                    </e-column>
                    <e-column field='success' headerText='Success' width="13%"></e-column>
                    <e-column field='uiMessage' headerText='Output Message' width='50%'></e-column>
                    <e-column headerText='HAR' width='40px' width='10%'>
                      <ng-template #template let-data>                        
                        <span class="harLink" style="cursor: pointer; color:#4285f4;" (click)="onViewHarClick(data)">View</span>
                      </ng-template>
                    </e-column>
                  </e-columns>
              </ejs-grid>
              <div class="be-spinner">
                <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
                </svg>
              </div>
        </div>        
      </div>      
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card card-table card-border be-loading" style="height: 750px;" [class.be-loading-active]="runInfoLoading">
      <div class="card-header">Script Run Details<span class="card-subtitle"></span></div>
      <div class="card-body">
        <div class="col-lg-12">
          <div class="tab-container">
            <ul id="scriptRunOutputTabs" class="nav nav-tabs">
              <li class="nav-item"><a id="requests" href="#requests" data-toggle="tab" role="tab" class="nav-link" [class.active]="true" (click)="onTabClick($event);"><span class="icon zmdi zmdi-code-setting"></span>Requests<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Requests made during this script." triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="vendors" href="#vendors" data-toggle="tab" role="tab" class="nav-link" (click)="onTabClick($event);"><span class="icon zmdi zmdi-functions"></span>Vendors<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Vendors identified during this script" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="console" href="#console" data-toggle="tab" role="tab" class="nav-link"(click)="onTabClick($event);"><span class="icon zmdi zmdi-view-list-alt"></span>Console<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Console output from the execution of the script" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="screenRecording" href="#screenRecording" data-toggle="tab" role="tab" class="nav-link"(click)="onTabClick($event);"><span class="icon zmdi zmdi-movie"></span>Screen Recording<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Screen recording of script execution" triggers="mouseenter:mouseleave"></span></a></li>
            </ul>
          </div>
          <div class="tab-content">
            <div id="requests" role="tabpanel" class="tab-pane" [class.active]="activeTab == 'requests'">
              <ejs-grid #requestsGrid
                height='400'
                locale='en-US' 
                [allowPaging]="true" 
                [pageSettings]="pageSettings" 
                [allowSorting]="true" 
                [sortSettings]="sortSettingsRequests"
                [allowSelection]="false"
                [enableHover]="false"
                [toolbar]="toolbar"
                [allowResizing]="true">
                  <e-columns>                    
                    <e-column field='request.url' headerText='Request URL'></e-column>
                    <e-column field='type' headerText='Type'></e-column>          
                  </e-columns>
              </ejs-grid>
            </div>      
            <div id="vendors" role="tabpanel" class="tab-pane" [class.active]="activeTab == 'vendors'">
              <ejs-grid #vendorsGrid
                height='400'
                [dataSource]="vendors"
                locale='en-US' 
                [allowPaging]="false" 
                [pageSettings]="pageSettings" 
                [allowSorting]="true" 
                [sortSettings]="sortSettingsVendors"
                (rowSelected)="onVendorSelected($event)"
                (rowDeselected)="onVendorDeselected($event)">
                <e-columns>                    
                  <e-column field='vendorName' headerText='Vendor'>
                    <ng-template #template let-data>
                      <img style="width: 32px; height:32px;margin-right: 5px;" src='https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : (data.hostname ? data.hostname : "unknown")}}.ico'/><a [routerLink] = "['/vendors',data.vendorName]">{{data.vendorName}}</a>
                    </ng-template>
                  </e-column>
                </e-columns>
              </ejs-grid>
            </div>
            <div id="console" role="tabpanel" class="tab-pane" [class.active]="activeTab == 'console'">
              <ngx-codemirror #consoleView [(ngModel)]="consoleContent" [options]="codeMirrorOptions"></ngx-codemirror>
            </div>
            <div id="screenRecording" role="tabpanel" class="tab-pane" [class.active]="activeTab == 'screenRecording'">
              <div id="screenRecordingContainer" style="text-align: center; padding-top: 5%;">
                <div style="display: inline-block">
                  <div *ngIf="loadingString != '' " class="image-placeholder">
                    <h4>{{loadingString}}</h4>
                  </div>
                </div>
              </div>
              <div style="text-align: center; padding-top: 5%;">
                <button type="button" class="btn btn-rounded btn-space btn-info" (click)="replayScreenVideo();">Replay</button>
              </div>
            </div>
          </div>       
        </div>
      </div>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div>
    </div>
  </div>  
</div>