// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  auth0: {
    clientID: 'NCX6RytqHgEzQKP5PcnXoOcB7zlwtEA0',
    domain: 'dev-vaultjs.auth0.com',
    callbackURL: 'https://ui.dev.vaultjs.com/authCallback',
    callbackURLIdP: 'https://ui.dev.vaultjs.com/authCallbackIdP',
    apiUrl: 'https://api.dev.vaultjs.com/core'
  },
  api: {
    url: 'https://api.dev.vaultjs.com/ui/',
    wsurl: 'wss://ws.api.dev.vaultjs.com'
  },
  cdn:{
    url: 'cdn.dev.vaultjs.com'
  },
  aws: {
    region: 'us-east-1',    
    pageRunnerBucket: 'vjs-dev-page-runner-output',
    pageRunnerBucketLegacy: 'dev-chrome-pagerunner-output',
    codeFileBucket: 'dev-vjs-full-code-index',
    codeFileBucketLegacy: 'dev-javascript-analysis-output',
    exportBucket: 'dev-vaultjs-ui-data-export',
    reportBucket: 'dev-vaultjs-ui-report-content',
    apiCacheBucket: 'dev-vaultjs-ui-api-cache',
    dataCollectorBucket: 'dev-data-collector-page-runner-bucket',
    scriptedDataCollectorBucket: 'dev-chrome-scripted-pagerunner-output-by-client',
    scheduledReportsBucket: 'vjs-dev-scheduled-reports',
    eventPipeline: 'arn:aws:sns:us-east-1:188144624490:mainEventPipe',
    consentReportBucket: 'vjs-dev-consent-reports',
    customReportBucket: 'vjs-dev-custom-reports', // This is a prefix actually...need to add "-39" to the end (where 39 is a customer ID in our DB)
    newHashFileBucket: 'dev-pdf-pagerunner-data-flow-pdf-redshift-query-output'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
