import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Query } from '../../models/Query';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QueryService extends VaultBaseService {

  constructor(authHttp: HttpClient) { 
    super(authHttp);
  }

  public createQuery(query:Query):Observable<any>{
    const url = `${env.api.url}query`;
    return this.authHttp.post(url, JSON.stringify(query));  
  }  

  public getQuery(queryId:number=null):Observable<any>{
    const url = `${env.api.url}query/${queryId}`;
    return this.authHttp.get(url);
  }

  public updateQuery(query:Query):Observable<any>{
    const url = `${env.api.url}query/${query.id}`;
    return this.authHttp.put(url, JSON.stringify(query));
  }

  public deleteQuery(queryId:number):Observable<any>{
    const url = `${env.api.url}query/${queryId}`;
    return this.authHttp.delete(url);
  }

  public runQuery(queryId:number, dataId:string=null, uuid:string=null, type:string="redshift"):Observable<any>{
    var url = `${env.api.url}query/${queryId}/run`;
    if ( dataId ){
      url += "?dataId=" + dataId;
      if ( uuid ){
        url += '&uuid=' + uuid;
      }
      if ( type == "redshift" ){
        url += '&qt=0'
      } else if ( type == "athena" ){
        url += '&qt=1'
      }
    }
    
    return this.authHttp.get(url);
  }
}
