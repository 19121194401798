import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { TextMarkerOptions } from 'codemirror';
import * as Diff2Html from 'diff2html';
import { ClipboardService } from 'ngx-clipboard';
import { Papa } from 'ngx-papaparse';
import { forkJoin } from 'rxjs';
import { environment as env } from "../../../../environments/environment";
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { IncidentService } from '../../../services/incident/incident.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { DiffService } from '../../../services/workers/diff-service.service';
import { DomainDefinition } from '../../vendorLibrary/models/DomainDefinition';
import { Extractor } from '../../vendorLibrary/models/Extractor';
import { ResourceMatcher } from '../../vendorLibrary/models/ResourceMatcher';
import { Router } from '@angular/router';
declare var $: any;



export enum ANALYSIS_TYPE {
  BEAUTIFIED = "beaut",
  RAW = "raw",
  DEOBFUSCATED = "deobf",
  ANALYSIS = "analysis",
  AST = "ast",
  RETIREJS = "retire"
}

@Component({
  selector: 'code-review-v2',
  templateUrl: './code-review-v2.component.html',
  styleUrls: ['./code-review-v2.component.css']
})
export class CodeReviewComponentV2 implements OnInit {
  sideBySideFileDiff = `<div id="{{fileHtmlId}}" class="d2h-file-wrapper" data-lang="{{file.language}}">
    <div class="d2h-file-header">
      {{{filePath}}}
    </div>
    <div class="d2h-files-diff">
        <div class="d2h-file-side-diff">
            <div class="d2h-code-wrapper">
                <table class="d2h-diff-table">
                    <thead>@LEFT@</thead>
                    <tbody class="d2h-diff-tbody">
                    {{{diffs.left}}}
                    </tbody>
                </table>
            </div>
        </div>
        <div class="d2h-file-side-diff">
            <div class="d2h-code-wrapper">
                <table class="d2h-diff-table">
                    <thead>@RIGHT@</thead>
                    <tbody class="d2h-diff-tbody">
                    {{{diffs.right}}}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>`;
  public data: Object[];
  public dataSource: object[] = [];
  days: number = 2;

  domainSortingSettings: any = {
    columns: [
      { field: 'domain', direction: 'Ascending' }
    ]
  };

  domainFilteringSettings: any = {
    type: 'Excel'
  }

  requestsSortingSettings: any = {
    columns: [
      { field: 'formattedTimestamp', direction: 'Descending' }
    ]
  }

  requestsFilteringSettings: any = {
    type: 'Excel',
  }

  requestsSelectionSettings: any = {
    type: 'Multiple'
  }

  requestsGridContextMenuItems = [
    { text: "Copy Url(s)", id: "url" },
    { text: "Copy Hash(es)", id: "hash" },
    { text: "Copy Request ID(s)", id: "requestId" },
    { text: "Copy Job ID(s)", id: "jobId" },
    { separator: true },
    { text: "Go to Request Details", id: "rmDetailsLink" },
    { separator: true },
    { text: "Mark as reviewed", id: "eventReviewed" },
    { separator: true },
    {
      text: "Incidents", items: [
        { text: "New...", id: "newIncident" }
      ]
    },
  ]

  @ViewChild('requestGrid')
  public requestGrid: GridComponent;

  @ViewChild('codeViewer')
  codeViewer: CodemirrorComponent;

  isRawTabActive;
  isAnalysisTabActive;
  isDiffTabActive;
  isBeautificationTabActive
  isAstTabActive;
  isDeobftabActive;
  isRetireActive;
  isDataActive;

  codeMirrorOptions: any;
  curSelectedRowIndices: any[];
  codeFileContent;
  outputHtml;

  analysisInfo: any;
  analysisPosition: number;
  curItem: any;

  selectedHash;
  customers: any;
  vendors: any;

  vendorLibForm: FormGroup;

  allDomainDefs: any;
  selectedRequest: string;
  modalTitle: string;

  selectedRequestRows: any;

  newIncidentForm: FormGroup

  public domainDefFields: Object = {
    text: 'name', value: 'id'
  };

  selDDRMs: any;
  rmFields: Object = {
    text: 'name', value: 'id'
  }

  pageOptions = { pageSize: 100, pageCount: 5, pageSizes: [100, 200, 500, 1000] };

  incidents: any[];

  cdnUrl;

  loadedOnce = false;

  activeOnly = true;

  constructor(
    private spinnerService: SpinnerService,
    private vendorService: VendorsService,
    private vendorLibService: VendorLibraryService,
    private tzService: TimezoneService,
    private clipboardApi: ClipboardService,
    private notificationService: VaultNotificationService,
    private dataExportService: DataExportService,
    private diffService: DiffService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    public papa: Papa,
    private incidentService: IncidentService,
    private authService: AuthService,
    private router:Router
  ) {
    this.curSelectedRowIndices = [];
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      tabSize: 2,
      readOnly: true,
      theme: 'material',
      gutters: ["CodeMirror-linenumbers", "analysis"]
    };

    this.vendorLibForm = this.formBuilder.group({
      domainDefId: "",
      rmId: ""
    })

    this.newIncidentForm = this.formBuilder.group({
      name: '',
      description: ''
    });

    this.cdnUrl = env.cdn.url;
  }

  public async ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.spinnerService.toggle(true);


    let manualReviewInfo = await this.customerService.getNewCodeFileManualReviewEvents().toPromise();
    console.log(manualReviewInfo);

    let customerInfo = await this.customerService.getCustomerInfo().toPromise();
    let activeCustomers = this.activeOnly == false ? [] : customerInfo.map((c: any) => {
      if (c.products.length > 0) {
        return c.id;
      }
    }).filter(c => c);

    console.log(activeCustomers);

    forkJoin([
      this.customerService.getAllCustomers(),
      this.customerService.getNewCodeFileHashesV2({ activeCustomer: activeCustomers, manualEventIds: manualReviewInfo }),
      this.incidentService.getIncidentsV2(),
      this.vendorService.getAllVendors()
    ]).subscribe((results) => {
      this.customers = results[0];
      this.customers.push({ id: 1, name: "Test" })
      console.log("this.customers");
      console.log(this.customers);
      let hashData = results[1];
      this.incidents = results[2].filter(incident => !incident.isMerged).sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      console.log("Incidents");
      console.log(this.incidents);
      this.vendors = results[3];
      let incidentsMenuItems = this.incidents.map((i) => {
        return {
          text: `${i.name} (${IncidentService.STATUS[i.status]})`,
          id: i.id + "",
          iconCss: `${IncidentService.SEVERITY[i.severity].toLowerCase()} zmdi ${i.severity == 1 ? "zmdi-alert-triangle" : "zmdi-alert-octagon"}`
        }
      });
      console.log(incidentsMenuItems);
      if (!this.loadedOnce) {
        this.requestGrid.contextMenuModule.contextMenu.insertAfter([{ text: "Add to...", items: incidentsMenuItems }], "New...");
        this.loadedOnce = true;
      }

      this.requestGrid.dataSource = hashData.map((item) => {
        let formattedTimestamp = this.tzService.format(Number(item.timestamp), false, true);
        item.libraryName = item.libraryName == "" ? "(No match)" : item.libraryName;
        item.domainName = item.domainName == "" ? "(No match)" : item.domainName;
        item.resourceName = item.resourceName == "" ? "(No match)" : item.resourceName;
        let customerNames = "";
        if (item.customerids) {
          customerNames = JSON.parse(item.customerids).map((cid) => { console.log(cid); return this.customers.find((c) => { return c.id == cid })?.name })
        }
        item.vendorName = this.vendors.find(v => v.id == item.vendorId)?.name;
        item.vendorWebsite = this.vendors.find(v => v.id == item.vendorId)?.website || 'unknown';
        return { ...item, formattedTimestamp: formattedTimestamp, customerNames: customerNames }
      });
      this.requestGrid.refresh();
      this.spinnerService.toggle();

    })

    $('#modal-stub-vendor-lib').on('shown.bs.modal', (e) => {

    });

    this.vendorLibService.getAllDomainDefinitions(false).subscribe((domainDefs) => {
      this.allDomainDefs = domainDefs.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    });
  }

  onContextMenuOpen(event) {
    console.log("CMOpen");
    console.log(event);

    let items = document.getElementsByClassName("yellow");
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      item["style"] = 'color: #fbbc05';
    }

    items = document.getElementsByClassName("red");
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      item["style"] = 'color: #ea4335';
    }
  }

  ngAfterViewInit() {
    $('[data-toggle="popover"]').popover();
  }

  public loadDomains(days: number = this.days) {
    this.spinnerService.toggle(true);
    this.vendorLibService.getUnclassifiedRequestDomains(days).subscribe((domains) => {
      this.data = domains.results;
      this.spinnerService.toggle();
    });
  }

  public onRowSelected(args: RowSelectEventArgs): void {
    console.log(args);
    const domain: any = (args.data as any).domain;

    this.spinnerService.toggle(true);

    this.vendorLibService.getUnclassifiedRequests(domain, this.days).subscribe((requests) => {
      this.requestGrid.dataSource = requests.map((request) => {
        let formattedTimestamp = this.tzService.format(request.timestamp, false, true);
        let customerNames = JSON.parse(request.customerids).map((cid) => { return this.customers.find((c) => { return c.id == cid })?.name })
        return { ...request, formattedTimestamp: formattedTimestamp, customers: customerNames.join(",") }
      });
      this.spinnerService.toggle();

      setTimeout(() => {
        // this.grid.refresh();
        this.requestGrid.autoFitColumns();
      }, 100);
    })

  }

  requestSelected(event) {
    console.log(event);

    if (event.rowIndexes) {
      this.curSelectedRowIndices = event.rowIndexes;

      let selectedRows = this.requestGrid.getSelectedRecords();

      console.log("actual selected rows");
      console.log(selectedRows);

      let leftFile: any = this.requestGrid.dataSource[this.curSelectedRowIndices[0]];
      let rightFile: any = this.requestGrid.dataSource[this.curSelectedRowIndices[1]];

      console.log("left", leftFile);
      console.log("right", rightFile);

      forkJoin([
        this.dataExportService.getCodeFileBeautified(leftFile.responsehash),
        this.dataExportService.getCodeFileBeautified(rightFile.responsehash)
      ]).subscribe((files) => {
        this.diffService.startDiff(files[0].toString(), files[1].toString()).subscribe((result) => {
          if (!result.data || result.data == "") {
            console.log("EMPTY/NULL from worker");
          } else {
            let outputHtml = Diff2Html.html(result.data, {
              matching: 'none',
              outputFormat: 'side-by-side',
              rawTemplates: {
                "file-summary-wrapper": ``,
                "side-by-side-file-diff": this.sideBySideFileDiff.replace('@LEFT@', leftFile.responsehash).replace('@RIGHT@', rightFile.responsehash)
              }
            });
            this.outputHtml = outputHtml;
          }
          this.spinnerService.toggle();
        },
          (error) => {
            this.spinnerService.toggle();
          })
      },
        (error) => {
          this.spinnerService.toggle();
        })
    } else {

      this.curSelectedRowIndices = this.requestGrid.getSelectedRecords();
      console.log(this.curSelectedRowIndices);
      this.selectedHash = this.curSelectedRowIndices[0].responsehash;
      console.log("Hash", this.selectedHash);

      this.dataExportService.getCodeFileBeautified(this.selectedHash).subscribe((response) => {
        console.log(response.toString());
        this.resetAllTabs();
        this.isBeautificationTabActive = true;

        const fileContents = response.toString();
        this.codeFileContent = fileContents;
        if (this.codeViewer && this.codeViewer.codeMirror) {
          this.codeViewer?.codeMirror?.setSize("100%", "475px");
        } else {
          setTimeout(() => { this.codeViewer?.codeMirror?.setSize("100%", "475px"); }, 50)
        }
        this.spinnerService.toggle(false);
      },
        (error) => {
          console.log(error);
          // this.notificationService.error("Uh oh...", "Unable to load the requested file. Please contact support.")
          this.codeFileContent = `// Unable to load file...`;
          this.codeViewer.codeMirror.setSize("100%", "475px");
          this.spinnerService.toggle(false);
        },
        () => {
          console.log("File DL complete...");
        })
    }
  }

  onDayWindowChange(event) {
    console.log('Day Window Change');
    console.log(event);

    if (event.value == "") return;

    this.days = Number(event.value)

    this.requestGrid.dataSource = [];
    this.data = [];

    this.loadDomains(this.days);
  }

  requestsGridContextMenuClick(event) {
    console.log(event);
    let menuItemProps = event.item.properties;
    let menuItemId = menuItemProps.id;

    console.log(menuItemId);

    let selectedRecords: any[] = this.requestGrid.getSelectedRecords();

    console.log(selectedRecords);

    let clipboardSourceContent = "";
    let infoMessageText = "Data was copied to the clipboard";
    switch (menuItemId) {
      case "url":
        console.log("URL");
        clipboardSourceContent = selectedRecords.map((request) => { return request.requesturl }).join(",")
        break;
      case "hash":
        console.log("Hash");
        clipboardSourceContent = selectedRecords.map((request) => { return request.responsehash }).join(",")
        break;
      case "requestId":
        console.log("RequestId");
        clipboardSourceContent = selectedRecords.map((request) => { return request.requestrecorduuid }).join(",")
        break;
      case "jobId":
        console.log("Job ID");
        clipboardSourceContent = selectedRecords.map((request) => { return request.jobuuid }).join(",")
        break;
      case "uniqueHashes":
        let uniqueHashes = new Set(selectedRecords.map((request) => { return request.responsehash }))
        infoMessageText = `${uniqueHashes.size} unique hashes were found.`;
        clipboardSourceContent = Array.from(uniqueHashes).join(",");
        break;
      case "stubExtractors":
        this.selectedRequest = selectedRecords[0].url;
        let urlObj = new URL(this.selectedRequest);
        console.log(urlObj);
        urlObj.searchParams.forEach((value, key) => {
          console.log(`${key}, ${value}`);
        });
        this.modalTitle = "Stub out Extractors";
        $('#modal-stub-vendor-lib').modal('show');
        return;
      case "testRM":
        this.selectedRequestRows = selectedRecords.map((request) => {
          return {
            url: request.url,
            uuid: request.requestrecorduuid,
            customerIds: request.customerids
          }
        })
        this.selectedRequest = `${selectedRecords.length} request(s) selected`
        this.modalTitle = "Test Resource Matcher";
        $('#modal-stub-vendor-lib').modal('show');
        return;
      case "resetRM":
        this.requestGrid.dataSource = (this.requestGrid.dataSource as any).map((item) => {
          delete item.matchData;
          return item;
        })
        this.requestGrid.refresh();
        return;
      case "newIncident":
        $('#new-incident-modal').modal('show');
        return;
      case "eventReviewed":
        this.spinnerService.toggle(true);
        this.vendorLibService.createManualCodeFileReviewEvent(selectedRecords).subscribe((response) => {
          if (response.error) {
            this.notificationService.error('Uh oh...', response.error);
            this.spinnerService.toggle();
          }
          else {
            this.notificationService.success('Success!', response.message);
            this.loadData();
          }

        })
        return;
      case "rmDetailsLink":
        window.open(`vendors/${selectedRecords[0].vendorId}/request/${selectedRecords[0].resourcematchid}`);  
        return;
      default:
        console.log("XXXX");
        console.log(menuItemId);
        console.log(event.item.parentObj?.properties?.text);
        if (event.item.parentObj?.properties?.text == 'Add to...') {
          this.updateIncident(menuItemId);
        }
        return;
    }

    console.log(clipboardSourceContent);

    if (clipboardSourceContent) {
      this.clipboardApi.copyFromContent(clipboardSourceContent);
    }
    this.notificationService.info('', infoMessageText);

  }

  onReqGridQueryCellInfo(args) {
    console.log("queryCell");
    console.log(args);
    if (
      args.column.field == 'url' &&
      args.data.matchData &&
      args.data.matchData.hasMatch == true
    ) {
      let tooltip: Tooltip = new Tooltip({
        content: `Domain: ${args.data.matchData.domainName}\n Resource: ${args.data.matchData.resourceMatchName}`
      }, args.cell as HTMLElement);
    }

  }

  onTabClick(event) {
    console.log("qqq onTabClick", event);
    console.log(event);

    var tabId = event.target.id;
    console.log("Tab ID", tabId);

    if (event.target.nodeName == "SPAN") {
      tabId = event.target.parentNode.id;
    }

    this.resetAllTabs();

    let funcToUse = this.dataExportService.getCodeFileBeautified;
    let isJSON = false;
    if (tabId == ANALYSIS_TYPE.BEAUTIFIED) {
      this.isBeautificationTabActive = true;
      funcToUse = this.dataExportService.getCodeFileBeautified;
    } else if (tabId == ANALYSIS_TYPE.RAW) {
      this.isRawTabActive = true;
      funcToUse = this.dataExportService.getCodeFileRaw;
    } else if (tabId == ANALYSIS_TYPE.DEOBFUSCATED) {
      this.isDeobftabActive = true;
      funcToUse = this.dataExportService.getCodeFileDeobfuscated;
    } else if (tabId == ANALYSIS_TYPE.ANALYSIS) {
      this.isAnalysisTabActive = true;
      funcToUse = this.dataExportService.getCodeFileSimpleAnalysis;
      isJSON = true;
    } else if (tabId == ANALYSIS_TYPE.AST) {
      this.isAstTabActive = true;
      funcToUse = this.dataExportService.getCodeFileAst;
      isJSON = true;
    } else if (tabId == ANALYSIS_TYPE.RETIREJS) {
      this.isRetireActive = true;
      funcToUse = this.dataExportService.getCodeFileRetireJs;
      isJSON = true;
    }

    this.curSelectedRowIndices[0].responsehash;
    this.spinnerService.toggle();
    funcToUse.apply(this.dataExportService, [this.selectedHash]).subscribe((response) => {

      if (this.isAnalysisTabActive == true) {

        this.dataExportService.getCodeFileBeautified(this.selectedHash).subscribe((gcfbResponse) => {

          let fileContents = gcfbResponse.toString();
          this.codeFileContent = fileContents;
          this.codeViewer.codeMirror.getDoc().setValue(this.codeFileContent);
          this.codeViewer.codeMirror.setSize("100%", "475px");

          this.analysisPosition = -1;
          var analysis = response ? JSON.parse(response.toString()).basicAnalysisResults : [];
          this.analysisInfo = analysis;

          for (let index = 0; index < analysis.length; index++) {
            var item = analysis[index];
            const start: CodeMirror.Position = { line: (item.location.start.line - 1), ch: item.location.start.column };
            const end: CodeMirror.Position = { line: (item.location.end.line - 1), ch: item.location.end.column };
            var options: TextMarkerOptions = { css: "color : white; background-color: #ff0000" };
            console.log(start, end, options);
            this.codeViewer.codeMirror.getDoc().markText(start, end, options);
          };
        })
      } else {
        let fileContents = response.toString();
        this.codeFileContent = (isJSON ? JSON.stringify(JSON.parse(fileContents), null, 2) : fileContents);
        this.codeViewer.codeMirror.setSize("100%", "475px");
        this.spinnerService.toggle(false);
      }

    },
      (err) => {
        console.log("Caught Error");
        const fileContents = '// Unable to load data. Please contact support.'
        this.codeFileContent = fileContents;
        this.spinnerService.toggle(false);
      })

  }

  resetAllTabs() {
    this.isBeautificationTabActive =
      this.isRawTabActive =
      this.isAnalysisTabActive =
      this.isDiffTabActive =
      this.isDeobftabActive =
      this.isAstTabActive =
      this.isRetireActive =
      this.isDataActive =
      false;
  }

  makeMarker() {
    var marker = document.createElement("div");
    marker.style.color = "#f00";
    marker.innerHTML = "●";
    return marker;
  }

  next(direction) {

    if (!this.analysisInfo || (this.analysisPosition <= 0 && direction == -1) || (this.analysisPosition >= this.analysisInfo.length - 1 && direction == 1)) {
      return;
    }

    //console.log(direction);

    var scrollViewPosition: CodeMirror.Position;
    if (direction == 'start') {
      scrollViewPosition = { line: (0), ch: 0 };
      this.analysisPosition = -1;
    } else if (direction == 'end') {

      const numLines = this.codeFileContent.split(/\r\n|\r|\n/).length;
      scrollViewPosition = { line: (numLines - 1), ch: 0 };
      this.analysisPosition = this.analysisInfo.length;
    } else {
      this.analysisPosition += direction
      const item = this.analysisInfo[this.analysisPosition];
      console.log("ITEM");
      console.log(item);
      if (!item) {
        return;
      }
      scrollViewPosition = { line: (item.location.start.line - 1), ch: item.location.start.column };

      if (this.curItem) {
        window["gutterId"] = this.codeViewer.codeMirror.clearGutter("analysis");
      }

      window["gutterId"] = this.codeViewer.codeMirror.setGutterMarker(item.location.start.line - 1, "analysis", this.makeMarker());

      this.curItem = item;
    }
    this.codeViewer.codeMirror.scrollIntoView(scrollViewPosition);
  }

  onReqGridRowDataBound(event) {
    if (event.data.matchData != null && event.data.matchData?.hasMatch == true) {
      event.row.style.background = 'rgb(0, 256, 0, 0.5)';
    } else if (event.data.matchData != null && event.data.matchData?.hasMatch == false) {
      event.row.style.background = 'rgb(256, 0, 0, 0.5)';
    }
  }

  async updateVendorLib() {

    console.log(this.modalTitle);

    console.log("updateVendorLib");
    console.log(this.vendorLibForm.value);
    $('#modal-stub-vendor-lib').modal('hide');

    let formVals = this.vendorLibForm.value;
    let ddId = formVals.domainDefId;
    let rmVal = formVals.rmId
    let isNewDD = isNaN(ddId);
    let isNewRM = isNaN(rmVal);

    if (this.modalTitle == "Test Resource Matcher") {

      console.log("Testing...");

      this.spinnerService.toggle(true);

      this.vendorLibService.testRequests(ddId, rmVal, this.selectedRequestRows).subscribe((rmMatchResponse) => {

        this.requestGrid.dataSource = (this.requestGrid.dataSource as any[]).map((r) => {
          let matchData = rmMatchResponse.find((item) => { return item.uuid == r.requestrecorduuid });
          let ret = { ...r };
          if (matchData) {
            ret.matchData = matchData;
          }
          return ret;
        });

        console.log(this.requestGrid.dataSource);


        // console.log(rmMatchResponse);
        this.requestGrid.refresh()
        this.spinnerService.toggle();

        // this.requestGrid.getSelectedRows().forEach((item)=>{
        //   console.log(item);
        //   // let match = (rmMatchResponse as any[]).find((match)=>{return match.uuid == item.uuid});
        //   // if (match){
        //   //    (item as any).style.background = match.hasMatch == true ? 'rgb(0, 128, 0)' : 'rgb(128, 0, 0)';
        //   // } else{
        //   //   console.log(item);
        //   // }
        // })


      })

      return;
    }

    this.spinnerService.toggle(true);

    if (isNewDD) {
      let newDD = new DomainDefinition(0, ddId, 1, false);
      let newDDResponse = await this.vendorLibService.createDomainDefinition(newDD).toPromise();
      console.log(newDDResponse);
      ddId = newDDResponse.newObjectId;
    }

    if (isNewRM) {
      let rm = new ResourceMatcher(0, rmVal, "To Do...");
      rm.value = "To Do...";
      let newRmResponse = await this.vendorLibService.createResourceMatcher(
        ddId,
        rm
      ).toPromise();

      console.log(newRmResponse);
      rmVal = newRmResponse.newObjectId;
    }

    let urlObj = new URL(this.selectedRequest);
    let extractors = [];
    urlObj.searchParams.forEach((value, key) => {
      let ex = new Extractor(0, key);
      ex.key = key;
      ex.value = `[&?]${key}=(?<${key}>.*?)(?:$|&)`;
      ex.groupName = key;
      ex.description = "To Do...";
      extractors.push(this.vendorLibService.createResourceMatcherExtractor(ddId, rmVal, ex).toPromise())
    })

    let resp = await Promise.all(extractors);

    console.log(resp);

    this.spinnerService.toggle();
  }

  loadRMForDD(event) {
    console.log(event);
    let ddId = event.itemData.id;
    if (isNaN(ddId)) return;
    this.vendorLibService.getDomainDefinition(ddId).subscribe((ddObj) => {
      this.selDDRMs = ddObj[0].resourceMatchers.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      console.log(ddObj);
    })
  }

  doStubbing(event) {
    console.log(event);
    console.log(this.vendorLibForm);
  }

  createIncident() {
    $('#new-incident-modal').modal('hide');
    console.log(this.newIncidentForm.value);

    let selectedRecords: any[] = this.requestGrid.getSelectedRecords();

    let newIncident = {
      name: this.newIncidentForm.value.name,
      customerId: selectedRecords[0].customerids.replace(/\[|\]/g,''),
      description: this.newIncidentForm.value.description,
      nickname: this.authService.getUserProfile().nickname,
      status: 1,
      severity: 1,
      requestData: selectedRecords.map((r) => { return { ...r, type: "newCodeFile" } })
    };

    console.log(newIncident);

    this.spinnerService.toggle(true);

    this.incidentService.createIncidentV2(newIncident).subscribe((response) => {
      console.log(response);
      this.notificationService.success('Success', 'Incident created successfully');
      this.spinnerService.toggle();
    })
  }

  private createLogEvent(type = 'default', message = 'Undefined Message') {
    const logEvent = {};
    logEvent['epoch'] = new Date();
    logEvent['type'] = type;
    logEvent['data'] = message;
    logEvent['user'] = this.authService.getUserProfile().nickname;
    logEvent['isVaultUser'] = this.authService.isVaultUser();
    return logEvent;
  }

  updateIncident(incidentId) {
    let selectedRecords: any[] = this.requestGrid.getSelectedRecords();
    console.log(selectedRecords);
    let selRecIds = selectedRecords.map((r) => { return r.id });
    console.log(selRecIds);
    console.log("incidentId", incidentId);

    let updatedIncident = {
      id: incidentId,
      requestData: selectedRecords.map((r) => { return { ...r, type: "newCodeFile" } }),
      logs: [this.createLogEvent('new_file', 'New Code File Added')]
    };

    console.log(updatedIncident);

    this.spinnerService.toggle(true);
    this.incidentService.updateIncidentV2(updatedIncident).subscribe((resp) => {
      console.log(resp);
      this.notificationService.success('Success', 'Incident updated successfully');
      this.spinnerService.toggle();
    });


  }
  onActiveOnlyChange(event) {
    console.log(event);
    console.log(this.activeOnly);
    this.loadData();
  }
}
