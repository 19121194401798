import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { change } from '@syncfusion/ej2-angular-grids';
import {
  MapsTheme,
  Maps,
  IShapeSelectedEventArgs,
  ILoadEventArgs,
  ILoadedEventArgs,
} from '@syncfusion/ej2-angular-maps';

import { RegionalPanelData } from '../../dashboardDS';


const worldMap = require('./data/world-map.json');
const tempdatasource = require('./data/default-datasource.json');
const africa = require('./data/africa.json');
const europe = require('./data/europe.json');
const asia = require('./data/asia.json');
const north_america = require('./data/north-america.json');
const south_america = require('./data/south-america.json');
const oceania = require('./data/oceania.json');
const usa = require('./data/usa.json');
declare var $: any;
export interface ShapeData {
  continent?: string;
}
@Component({
  selector: 'geolocation-map-panel',
  templateUrl: './geolocation-map-panel.component.html',
  styleUrls: ['./geolocation-map-panel.component.css'],
})
export class GeolocationMapPanelComponent implements OnChanges {
  @Input('datasource') datasource: RegionalPanelData;
  @ViewChild('fixedpopup') fixedpopup: any;
  @ViewChild('maps') maps: Maps;
  public createTooltip = (data: any) => {
    const container =
      '<div id="tooltip" style="width: 150px; backdrop-filter: blur(5px); height: fit-content; background-color: #00000099; color: white; border-radius: 0.25rem; box-shadow: 0px 0px 2px grey; padding: 10px;">#INNERHTML</div>';
    const staticContent =
      '<div style="font-weight: bold; font-size: 1.2rem; margin-bottom: 5px;">${continent}</div>' +
      '<div style="font-size: 1rem; margin-bottom: 5px;">${totalViolations} Violations</div>' +
      '<div style="font-size: 1rem; margin-bottom: 5px;">${totalViolationsPages} of ${totalPages}</div>';
    return container.replace('#INNERHTML', staticContent);
  };
  public isLoaded: boolean;
  public tooltipSettings: object;
  public legendSettings: object;
  // public mapData: ViolationSummaryByRegion[] = [];
  public buttonData: any;
  public mapData: any;
  public showDialog = false;
  public panelData = Object;

  tooltipMapRender(args: any) {
    const isVisible = args.options.data.totalViolations !== -1;
    args.cancel = !isVisible;
    // console.log(args.options);
  }
  public colorCutoff = (value: any, cutoffA: any, cutoffB: any) => {
    if (value < cutoffA) {
      return 'green';
    } else if (value < cutoffB) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  createMapData() {
    const regionalList: string[] = [
      'Africa',
      'Asia',
      'Europe',
      'North America',
      'South America', // all the USA states
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware', // 10
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa', // 17
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland', // 22
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri', // 27
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey', // 32
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio', // 37
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina', // 42
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont', // 47
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming', // 52
      'Oceania',
      'Australia',
      'Brazil',
      'Canada',
      'United Kingdom'
    ];
    const generatedMapData = regionalList.map((region) => {
      return {
        continent: region,
        totalViolations: -1,
        totalPages: -1,
        totalViolationsPages: -1,
        percent: -1,
      };
    });
    if(this.datasource === null){
      return null;
    }
    this.datasource.byRegion.filter((item)=> item.continent!== null).forEach((data: any) => {
      const region = data.continent;

      const regionIndex = regionalList.indexOf(region);
      if (regionIndex < 0) {
        generatedMapData.push(data);
      } else {
        generatedMapData[regionIndex] = data;
      }
      
    });

    return generatedMapData;
  }
  public layers: any;

  constructor(private cd: ChangeDetectorRef) {
    this.legendSettings = {
      visible: true,
      mode: 'Interactive',
      invertedPointer: true,
      height: '10',
      width: '300',
    };
    this.tooltipSettings = {
      visible: true,
      valuePath: 'continent',
      template: this.createTooltip(this),
    };
    this.isLoaded = false;
  
  }
  setLayer(){
    this.layers = [
      {
        shapeData: worldMap,
        shapeDataPath: 'continent',
        dataSource: this.mapData,
        shapePropertyPath: 'continent',
        shapeSettings: {
          colorValuePath: 'percent',
          colorMapping: [
            {
              from: 0,
              to: 5,
              color: 'green',
              label: 'No Violations', // green
            },
            {
              from: 5,
              to: 50,
              color: 'orange',
              label: ' < 100 Violations', // yellow
            },
            {
              from: 50,
              to: 100,
              color: 'red',
              label: '100+ Violations', // red
            },
            {
              color: '#00000022',
              showLegend: false,
            }
          ],
        },
        tooltipSettings: this.tooltipSettings,
      }];
  }
  ngOnChanges(changes: SimpleChanges): void {

    console.log('GEOCHANGES', changes)
    if(changes.datasource?.firstChange){
      this.mapData = this.createMapData();
      this.buttonData = this.datasource.byRisk;
      this.setLayer();
      this.isLoaded = true;
    }
  }
}
