import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { AgendaService, DayService, MonthAgendaService, MonthService, RecurrenceEditorComponent, TimelineMonthService, TimelineViewsService, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { RecurrenceEditorChangeEventArgs } from '@syncfusion/ej2-schedule';
import { NotificationsService } from 'angular2-notifications';
import { Query } from '../../../../models/Query';
import { CustomerService } from '../../../../services/customer/customer.service';
import { QueryService } from '../../../../services/query/query.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';

@Component({
  selector: 'query-details',
  templateUrl: './query-details.component.html',
  styleUrls: ['./query-details.component.css'],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService]
})
export class QueryDetailsComponent implements OnInit {

  // query:Query;
  isExisting:boolean;
  notificationOptions:any;
  queryForm:FormGroup;
  codeMirrorOptions:any;
  @ViewChild('queryEditor') 
  codeEditor:CodemirrorComponent;

  // @ViewChild('recurrenceObj') 
  // recurrenceObj:RecurrenceEditorComponent;

  headerText:string;
  headerSubText:string;
  queryId:string;
  btnText:string;
  sqlQueryModel:string;
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Input() public query:any;
  @Input() public isNewQuery:boolean;

  public selectedType = 1;


  constructor(
    private route: ActivatedRoute, 
    private fb:FormBuilder,  
    public spinnerService:SpinnerService,
    private notificationService:NotificationsService,
    private customerService:CustomerService,
    private queryService:QueryService) { 
    
      this.isExisting = false;
    
      this.notificationOptions = {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };

      this.codeMirrorOptions = {
        lineNumbers: true,
        mode: 'text/x-mysql',
      };
  }

  ngOnInit() {
    
    this.spinnerService.toggle(false);
    
    if ( this.query.id == -1 ) {
      console.log('new query!');
      
      this.isExisting = false;
      this.headerText = "New Query";
      this.headerSubText = "Specify the properties of the new query.";
      this.btnText = "Create";      
    } else {
      console.log('update query!');
      this.isExisting = true;
      this.headerText = "Update Query";
      this.headerSubText = "Update the properties of " + this.query.name + " below.";
      this.btnText = "Update";
      this.sqlQueryModel = this.query.query;     
    }
    
    if (!this.query.metadata){
      this.query.metadata = {}
    } else if (typeof this.query.metadata === 'string' || this.query.metadata instanceof String ) {
      try{
        this.query.metadata = JSON.parse(this.query.metadata);
      }catch(err){
        console.log('Parse error for query metadata');
        console.log(err);
        console.log(this.query.metadata);
        this.query.metadata = {}
      }
      
    }

    this.queryForm = this.fb.group({
      id: this.query.id,
      name: this.query.name,
      query: this.query.query,
      description: this.query.description,
      cron: this.query.cron,
      cronEnabled: this.query.cronEnabled,
      isAthenaQuery: this.query.metadata && this.query.metadata["isAthenaQuery"] != null ? this.query.metadata["isAthenaQuery"] : false
    });
  }

  onSubmit(){    
    
    this.query.name = this.queryForm.value.name;
    this.query.description = this.queryForm.value.description ? this.queryForm.value.description: "";
    this.query.query = this.sqlQueryModel ? this.sqlQueryModel : "";
    this.query.metadata["isAthenaQuery"] = this.queryForm.value.isAthenaQuery;
    this.query.cron = this.queryForm.value.cron;
    this.query.cronEnabled = this.queryForm.value.cronEnabled;

    console.log(this.query);

    this.spinnerService.toggle(true);
    if (this.isExisting){    
      this.queryService.updateQuery(this.query).subscribe((updateResponse)=>{
        this.notificationService.success('Success!', 'The query was updated sucessfully.', this.notificationOptions);
        this.resetForm();
        this.spinnerService.toggle(false);
        this.saveEvent.emit();
      });
    } else {
      this.queryService.createQuery(this.query).subscribe((createQueryResponse)=>{
        this.notificationService.success('Success!', 'The query was created sucessfully.', this.notificationOptions);
        this.resetForm();
        this.spinnerService.toggle(false);
        this.saveEvent.emit();
      });
    }
  }

  resetForm(){
    this.queryForm.reset();
    this.sqlQueryModel = "";
  }

  cancel(){
    this.cancelEvent.emit('cancel');
  }


  public onChange(args: RecurrenceEditorChangeEventArgs): void {
    console.log(args);
  }

}