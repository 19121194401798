import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Incident } from '../../models/Incident';
import { environment as env } from "../../../environments/environment";
import { Event } from '../../models/Event';
import { VaultBaseService } from '../VaultBaseService';
import { HttpParams } from '@angular/common/http';
import { off } from 'codemirror';

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends VaultBaseService {

  private static GET_INCIDENTS:string = "GET_INCIDENTS";

  public static RED:string = "red";
  public static YELLOW:string = "yellow";
  public static GREEN:string = "green";
  public static RESOLVED:string = "resolved";
  public static IN_PROGRESS:string = "inProgress";
  public static EXCLUDE_AUTO_GREEN:string = "excludeAutoGreen";

  public static severityToStyles = ["", "warning", "danger"];
  public static STATUS = ["", "In Progress", "Resolved", "Acknowledged"];
  public static SEVERITY = ["", "Yellow", "Red"];
  public static TYPE = ["Code File", "DNS Record", "WHOIS Record"];

  public incident:BehaviorSubject<Incident>;

  constructor(authHttp: HttpClient) { 
    super(authHttp);
    this.incident = new BehaviorSubject(null);
  }

  public setIncident(incident:Incident){
    this.incident.next(incident);
  }

  public getIncidentsForCustomers(severity:string=null, status:string=null, exclude:string=null, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{
    
    var url = `${env.api.url}incident`;

    const params = new HttpParams()
      .set("severity", severity.toString())
      .set("status", status.toString())
      .set("exclude", exclude.toString())
      .set("start", start.toString())
      .set("length", length.toString())
      .set("order", order)
      .set("orderDir", orderDirection)
      .set("search", search)
      .set("draw", draw.toString())
    ;

    return this.makeHttpRequest(url, params, false); 
  }

  public getIncident(id:Number, severity:Number=null, limit:Number=1000, offset:Number=null):Observable<Incident>{
    
    var url = `${env.api.url}incident`;
    const params = new HttpParams()
      .set("id", id.toString())
      .set("severity", severity ? severity.toString() : "")
      .set("status", "")
      .set("start", "")
      .set("length", "")
      .set("order", "")
      .set("orderDir", "")
      .set("search", "")
      .set("draw", "1")
    ;
    return this.makeHttpRequest(url, params, false); 
  }

  public getIncidentEvents(id:Number):Observable<Event[]>{
    var url = `${env.api.url}incident/${id}/events`;    
    return this.makeHttpRequest(url, null, false);     
  }

  public createIncident(incident:Incident[]):Observable<any>{
    const url = `${env.api.url}incident`;
    return this.authHttp.post(url, JSON.stringify(incident));
  }

  public createIncidentV2(incident):Observable<any>{
    const url = `${env.api.url}incidentV2`;
    return this.authHttp.post(url, JSON.stringify(incident));
  }

  public getIncidentsV2():Observable<any>{
    const url = `${env.api.url}incidentV2`;
    return this.makeHttpRequest(url);
  }

  public getIncidentV2(incidentId):Observable<any>{
    const url = `${env.api.url}incidentV2/${incidentId}`;
    return this.makeHttpRequest(url, new HttpParams().set("full", 1));
  }

  public updateIncidentV2(incident:any):Observable<any>{
    const url = `${env.api.url}incidentV2/${incident.id}`;
    return this.authHttp.put(url, JSON.stringify(incident));
  }

  public updateIncident(incident:Incident):Observable<any>{
    const url = `${env.api.url}incident/${incident.id}`;
    return this.authHttp.put(url, JSON.stringify(incident));
  }
}
