import { Injectable, Injector } from '@angular/core';
import { AUTH_CONFIG } from './auth0-variables';
import { Router } from '@angular/router';

import * as auth0 from 'auth0-js';
import { environment as env } from '../../../environments/environment';
import { CustomerService } from '../customer/customer.service';
import { Subscription, Observable, from, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, shareReplay } from 'rxjs/operators';
import { IncidentService } from '../incident/incident.service';
import { TimezoneService } from '../timezone.service';
import { InstrumentationService } from '../instrumentation/instrumentation.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable()
export class AuthService {

  userProfile: any;
  requestedScopes: string = 'openid profile email';
  customerSub: Subscription;

  auth0: any;

  public awsCreds: any;

  constructor(
    public router: Router,
    private customerService: CustomerService,
    protected authHttp: HttpClient,
    private timezoneService: TimezoneService,
    private injector: Injector,
    private spinnerService: SpinnerService
  ) {

    this.auth0 = new auth0.WebAuth({
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
      responseType: 'token id_token',
      audience: AUTH_CONFIG.apiUrl,
      redirectUri: AUTH_CONFIG.callbackURL,
      scope: this.requestedScopes
    });

  }

  public loginWithUsernameAndPassword(username: string, password: string, callbackFunction): void {
    const credentials = { email: username, password: password };
    this.auth0.login(credentials, callbackFunction);
  }

  public ssoLogin(ssoId: string): void {
    this.auth0.authorize({
      connection: ssoId,
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
      responseType: 'token id_token',
      audience: AUTH_CONFIG.apiUrl,
      redirectUri: AUTH_CONFIG.callbackURLIdP
    })
  }

  public resetPassword(email: string, callback): void {
    this.auth0.changePassword({
      connection: 'Username-Password-Authentication',
      email: email
    }, callback);
  }

  public login(): void {
    this.auth0.authorize();
  }

  public getUserProfile() {
    if (localStorage.profile) {
      return JSON.parse(localStorage.profile);
    }
    return {};
  }

  public handleIdPAuthPassthru(): void {
    //debugger;
    console.log("Auth Passthru...");
    if (location.href.indexOf("state") == -1) {
      return this.auth0.authorize({
        prompt: "none", clientID: AUTH_CONFIG.clientID,
        domain: AUTH_CONFIG.domain,
        responseType: 'token id_token',
        audience: AUTH_CONFIG.apiUrl,
        redirectUri: AUTH_CONFIG.callbackURLIdP,
        scope: this.requestedScopes
      });
    } else {
      console.log("Can Make API calls now...");
      this.auth0.parseHash((err, authResult) => {
        if (!err) {
          this.setSession(authResult);
          this.customerService.idpUserSync().subscribe((result) => {
            console.log("Response from idpUserSync...");
            console.log(result);
            return this.auth0.authorize({
              prompt: "none", clientID: AUTH_CONFIG.clientID,
              domain: AUTH_CONFIG.domain,
              responseType: 'token id_token',
              audience: AUTH_CONFIG.apiUrl,
              redirectUri: AUTH_CONFIG.callbackURL,
              scope: this.requestedScopes
            });
          });
        }
      });
    }
  }

  public customerId(): string {
    return localStorage.getItem("cid");
  }

  public handleAuthentication(): void {

    this.auth0.parseHash((err, authResult) => {
      //debugger;
      console.log('Auth Error', err);
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log("Access Token", authResult.accessToken);
        console.log("ID Token", authResult.idToken);
        this.resetUI();
        window.location.hash = '';
        this.setSession(authResult);
        console.log("AAA 2");
        this.customerSub = this.customerService.getCustomerInfo().subscribe((infos) => {
          console.log('Customer Info', infos);
          if (infos && infos.length == 1) {
            console.log('Logging in...');
            console.log("AAA 2 Response", infos[0]);
            this.customerService.setCustomer(infos[0]);
            if (this.customerService.customerName.value.includes("Fidelity")) {
              localStorage.setItem('expires_at', JSON.stringify((30 * 60 * 1000) + new Date().getTime()));
            }
            let customer = infos[0];
            localStorage.setItem('customer', JSON.stringify(customer));
            this.timezoneService.updateSettings(customer.isUtcDefaultForUI, customer.uiDateTimeFormatString)
            localStorage.setItem("cid", "" + infos[0].id);
            this.logUIEvents('Login');
            if (localStorage.getItem("redirectUrl") != null) {
              this.router.navigate([localStorage.getItem("redirectUrl")]);
              localStorage.setItem('redirectUrl', null);
            } else {
              this.router.navigate(['/vendors']);
            }

          } else if (infos && infos.length > 1) {
            console.log('Routing to switcher...');
            this.customerService.customers = infos;
            this.router.navigate(['accountSelect'],);
          }
          this.customerSub.unsubscribe();
        },
          (error) => {
            console.log(error);
            if (error.status == 403) {
              alert('Please login via your SSO portal.');
              this.router.navigate(['/logout']);
              return;
            }
          });

      } else if (err) {
        if (err.errorDescription) {
          alert(err.errorDescription);
          if (err.errorDescription == "Your password has expired, please reset it.") {
            this.router.navigate(['/request-password']);
            return;
          }
        } else {
          console.log(err);
          alert('An unexpected error has occurred. Check the console for further details or contact support.');
        }
        this.router.navigate(['/login']);
      }
    });
  }

  private setSession(authResult): void {
    // console.log(authResult);
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

    // If there is a value on the scope param from the authResult,
    // use it to set scopes in the session for the user. Otherwise
    // use the scopes as requested. If no scopes were requested,
    // set it to nothing
    const scopes = authResult.scope || this.requestedScopes || '';

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('profile', JSON.stringify(authResult.idTokenPayload));
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('scopes', JSON.stringify(scopes));
  }

  public logout(): void {
    this.logUIEvents('Logout');

    this.spinnerService.isRootVisible = true;

    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');

    this.clearGridFilters();

    this.resetUI();

    this.auth0.logout({
      clientID: AUTH_CONFIG.clientID,
      returnTo: `https://ui${env.name == 'prod' ? '' : '.' + env.name}.vaultjs.com`,
      federated: true
    });

  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    console.log("expiresAt")
    console.log(expiresAt);
    let now = new Date().getTime();
    console.log("now");
    console.log(now);
    return now < expiresAt;
  }

  public userHasScopes(scopes: Array<string>): boolean {
    if (!scopes || scopes.length == 0) {
      return true;
    }
    const grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }

  public getCdnCookie(): Observable<any> {
    const url = `${env.api.url}cookie`;
    return this.authHttp.get(url, { responseType: 'text', withCredentials: true });
  }

  public getAwsCreds(): Observable<any> {
    // const creds = localStorage.getItem('awsCreds');
    // if ( creds ){
    //   this.awsCreds = of(JSON.parse(creds));
    // }

    if (!this.awsCreds) {
      const url = `${env.api.url}creds`;
      this.awsCreds = this.authHttp.get(url).pipe(shareReplay());
    }
    return this.awsCreds;
  }

  public clearCache() {
    this.awsCreds = null;
  }

  public isVaultUser(): boolean {
    try {
      const currentUserEmail: string = JSON.parse(localStorage.profile).email;
      return currentUserEmail ? currentUserEmail.endsWith("@vaultjs.com") : false;
    } catch (err) {
      console.log(err);
      return false;
    }

  }

  public resetUI(): void {
    localStorage.removeItem(IncidentService.RED);
    localStorage.removeItem(IncidentService.YELLOW);
    localStorage.removeItem(IncidentService.GREEN);
    localStorage.removeItem(IncidentService.RESOLVED);
    localStorage.removeItem(IncidentService.IN_PROGRESS);

    this.clearGridFilters();
  }

  logUIEvents(event) {
    let instrumentationService = this.injector.get<InstrumentationService>(InstrumentationService);
    if (instrumentationService) {
      instrumentationService.sendEvent({ type: `${event}`, page: '' });
    } else {
      console.log("Can't get ref to instrumentationService..");
    }
  }

  clearGridFilters(){
    let keys = Object.keys(localStorage);
    for(var key of keys){
      if (key.startsWith('grid')){
        localStorage.removeItem(key);
      }
    }
  }

}