import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthService } from '../../../../services/auth/auth.service';
import { DataExportService } from '../../../../services/data-export/data-export.service';
import { VaultNotificationService } from '../../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { AdvancedViolationRiskSummaryPanelComponent } from '../../custom-report-components/advanced-violation-risk-summary-panel/advanced-violation-risk-summary-panel.component';
import {
  ConfigData,
  GlobalReportData,
  GroupMapping,
  GroupReportData,
  KeyPageViolationData,
  PageListData,
  RegionalPanelData,
  SiteReportData,
  ValueMonitoring,
  VendorSummaryData,
  ViolationDetailedSummaryByRisk,
  ViolationSummaryByRisk,
  ViolationSummaryBySite,
} from '../../dashboardDS';
// const globalData = require('../data/globalData.json');
// let configData = {} as any;
let globalData = {} as GlobalReportData;
let groupData = [] as GroupReportData[];
let siteData = [] as SiteReportData[];
// const groupData = require('../data/groupData.json');
// const siteData = require('../data/siteData.json');
const geoData = require('../data/geoData.json');

@Component({
  selector: 'dashboard-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardGlobalComponent implements OnInit, OnChanges {
  public vendorList: any[] = [];
  public configData = {} as ConfigData;
  public isLoaded: boolean = false;
  public reportTitle: string = 'Global Report';
  public reportTitleDetails: string = 'All Sites and Groups';
  public reportType: string = 'Global';
  public labels: any = {};

  public isFetched: boolean = true;
  public showMeta: boolean = false;

  public totalSites: ValueMonitoring = {
    max: 1,
    monitored: 0,
  };
  public totalPages: ValueMonitoring = {
    max: 1,
    monitored: 0,
  };
  public keyPageViolations: KeyPageViolationData;
  public scanRecency: number[];

  @ViewChild('AdvancedSummaryGrid')
  public advancedSummaryGrid: AdvancedViolationRiskSummaryPanelComponent;
  public violationSummaryByRisk: ViolationSummaryByRisk[];
  public violationSummaryByRegion: RegionalPanelData[];
  public violationDetailedSummaryByRisk: ViolationDetailedSummaryByRisk[];
  public groupList = [];
  public violationSummaryBySite: ViolationSummaryBySite[];
  public violationSummaryByVendor: VendorSummaryData[];
  public pagesData: PageListData[];
  public data: GlobalReportData;

  // dropdown related
  public groupMapping: GroupMapping[] = [];
  public siteMapping: GroupMapping[] = [];
  public selectedGroupId: number = 0;
  public selectedSiteId: number = 0;
  public backtrackGroupId: number = 0;
  public backtrackSiteId: number = 0;
  public loading: boolean;
  public logo: string = '';

  updateData() {
    this.backtrackGroupId = this.selectedGroupId;
    this.backtrackSiteId = this.selectedSiteId;
    if (this.selectedGroupId === 0 && this.selectedSiteId === 0) {
      // GLOBAL REPORT
      this.reportType = 'Global';
      this.reportTitle = 'Global Report';
      this.reportTitleDetails = 'All Sites and Groups';
      this.updateReportGlobalData();
    } else if (this.selectedGroupId !== 0 && this.selectedSiteId === 0) {
      // GROUP REPORT
      this.reportTitle = 'Group Report';
      this.reportTitleDetails = this.groupMapping.find(
        (group) => group.id === this.selectedGroupId.toString()
      ).name;
      this.reportType = 'Global';
      this.siteMapping = [
        { id: '0', name: 'All Sites' },
        ...siteData
          .filter((site) => site.groupId === this.selectedGroupId.toString())
          .map((site) => {
            return {
              id: site.id,
              name: this.labels[site.name] || site.name,
            };
          }),
      ];
      this.reportType = 'Group';
      this.updateReportGroupData(this.selectedGroupId.toString());
    } else if (this.selectedGroupId !== 0 && this.selectedSiteId !== 0) {
      // SITE REPORT
      this.reportType = 'Site';
      this.reportTitle = 'Site Report: ';
      this.reportTitleDetails = this.siteMapping.find(
        (site) => site.id === this.selectedSiteId.toString()
      ).name;

      this.updateReportSiteData(
        this.selectedSiteId.toString(),
        this.selectedGroupId.toString()
      );
    }
    this.isLoaded = true;
  }
  updateReportGroupId(id: string) {
    this.selectedGroupId = parseInt(id);
    this.updateReportSiteId('0');
  }
  updateReportSiteId(id: string) {
    this.selectedSiteId = parseInt(id);
    this.updateData();
  }
  updateReportGlobalData() {
    this.totalSites.monitored = globalData.totalSites;
    this.totalSites.max =
      this.configData.estimatedMaxSites || globalData.totalSites * 10;
    this.totalPages.monitored = globalData.totalPages;
    this.totalPages.max =
      this.configData.estimatedMaxPages || globalData.totalPages * 10;
    this.keyPageViolations = globalData.keyPageViolations;
    this.scanRecency = globalData.scanRecency;
    this.violationSummaryByRisk = globalData.violationSummaryByRisk;
    this.violationSummaryByRegion = globalData.violationSummaryByRegion;
    this.violationSummaryByVendor = this.vendorMapping(
      globalData.violationSummaryByVendor
    );
    this.violationDetailedSummaryByRisk =
      globalData.violationDetailedSummaryByRisk;
    this.pagesData = globalData.pagesData || [];
    this.loading = false;
    this.spinnerService.toggle(false);
  }
  updateReportSiteData(id: string, groupId: string) {
    const data: SiteReportData = siteData
      .filter((site: SiteReportData) => site.groupId === groupId)
      .find((site: any) => site.id === id);

    this.totalPages.monitored = data.totalPages;
    this.totalPages.max = globalData.totalPages;
    this.keyPageViolations = data.keyPageViolations;
    this.violationSummaryByRisk = data.violationSummaryByRisk;
    this.scanRecency = data.scanRecency;
    // this.violationSummaryByRegion = data.violationSummaryByRegion;
    this.violationSummaryByVendor = this.vendorMapping(
      data.violationSummaryByVendor
    );
    this.pagesData = data.pagesData || [];
    this.loading = false;
  }
  updateReportGroupData(id: string) {
    const data: GroupReportData = groupData.find((group) => group.id === id);
    this.totalSites.monitored = data.totalSites;
    this.totalSites.max = globalData.totalSites;
    this.totalPages.monitored = data.totalPages;
    this.totalPages.max = globalData.totalPages;
    this.keyPageViolations = data.keyPageViolations;
    this.scanRecency = data.scanRecency;
    this.violationSummaryByRisk = data.violationSummaryByRisk;
    // this.violationSummaryByRegion = data.violationSummaryByRegion;
    this.violationSummaryBySite = data.violationSummaryBySite;
    this.violationSummaryByVendor = this.vendorMapping(
      data.violationSummaryByVendor
    );
    this.pagesData = data.pagesData || [];
    this.loading = false;
  }
  vendorMapping(inputList: any[]) {
    const ret = inputList.map((item) => {
      const vendorLookup = this.vendorList.find(
        (vendor) => vendor.id === item.vendorId
      );
      item.vendorDomain = vendorLookup?.website;
      console.log('DOMAIN', item.vendorDomain);
      return item;
    });
    return ret;
  }
  public tempGeoData = [
    {
      continent: 'California',
      violations: 100,
    },
    {
      continent: 'South America',
      violations: 100,
    },
    {
      continent: 'Europe',
      violations: 100,
    },
  ];
  public updateGeoData = (newData, template) => {
    newData.forEach((item) => {
      const index = template.findIndex(
        (element) => element.continent === item.continent
      );
      if (index > -1) {
        template[index].violations = item.violations;
      }
    });
    console.log(template);
    return template;
  };

  constructor(
    private dataExportService: DataExportService,
    private authService: AuthService,
    private notificationService: VaultNotificationService,
    private spinnerService: SpinnerService,
    private cd: ChangeDetectorRef,
    private VendorService: VendorsService
  ) {
    console.log('DashboardGlobalComponent constructor');
    this.loading = true;
    this.isLoaded = false;
    this.spinnerService.toggle(true);
    this.LoadData();
    this.data = globalData;
    this.totalSites.monitored = globalData.totalSites;
    this.totalSites.max = 999999;
    this.totalPages.monitored = globalData.totalPages;
    this.totalPages.max = 99999999;
    this.keyPageViolations = globalData.keyPageViolations;
    this.scanRecency = [];
    this.violationSummaryByRisk = globalData.violationSummaryByRisk;
    this.violationSummaryByRegion = globalData.violationSummaryByRegion;
    this.violationDetailedSummaryByRisk =
      globalData.violationDetailedSummaryByRisk;
    this.violationSummaryBySite = globalData.violationSummaryBySite;
    this.violationSummaryByVendor = globalData.violationSummaryByVendor;
    this.pagesData = globalData.pagesData || [];
    this.groupMapping = [{ id: '0', name: 'All Groups' }];
    this.siteMapping = [{ id: '0', name: 'All Sites' }];
    this.selectedGroupId = 0;
    this.selectedSiteId = 0;
    this.backtrackGroupId = 0;
    this.backtrackSiteId = 0;
    // this.updateReportGlobalData();
  }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    this.updateReportGlobalData();
  }

  ngOnChanges(): void {
    this.updateData();
  }

  DisabledInConfig(string) {
    try {
      if (this.configData.disabled.includes(string)) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }

  GetLogoLocation(string) {
    const pre = '../../../../../../assets/img/';
    let url = '';
    switch (string) {
      case 'Adobe':
        url = pre + 'Adobe-logo.png';
        break;
      case 'Disney':
        url = pre + 'Disney-logo.png';
        break;
      case 'HP':
        url = pre + 'HP-logo.png';
        break;
      case 'CK':
        url = pre + 'CK-logo.png';
        break;
      case 'CourseHero':
        url = pre + 'CourseHero-logo.png';
        break;
      case 'Costco':
        url = pre + 'Costco-logo.png';
        break;
      case 'RBC':
        url = pre + 'RBC-logo.png';
        break;
      case 'Mailchimp':
        url = pre + 'Mailchimp-logo.png';
        break;
      default:
        if (string.includes('http')) {
          url = string;
        } else {
          url = pre + 'logo-xx.png';
        }
    }
    return url;
  }

  LoadData(): void {
    const cid = parseInt(this.authService.customerId());

    forkJoin([
      this.VendorService.getAllVendors(),
      this.dataExportService.getDashboardFile('dashboard.json', cid),
      this.dataExportService.getDashboardFile('config.json', cid),
    ]).subscribe(
      (results) => {
        this.vendorList = results[0];
        const dashboardData = JSON.parse(results[1]) as any;
        this.configData = JSON.parse(results[2]) as ConfigData;
        this.logo = this.GetLogoLocation(this.configData.logo);
        console.log('LOGO: ', this.logo);
        this.labels = this.configData.labels || {};
        globalData = dashboardData.globalSummary;
        groupData = dashboardData.groupSummary;
        siteData = dashboardData.siteSummary;
        this.updateReportGlobalData();
        this.groupMapping = [
          { id: '0', name: 'All Groups' },
          ...groupData.map((group) => {
            return {
              id: group.id,
              name: this.labels[group.name] || group.name,
            };
          }),
        ];
        this.totalSites.max = this.configData.estimatedMaxSites || 1;
        this.totalPages.max = this.configData.estimatedMaxPages || 1;
        this.spinnerService.toggle(false);
        this.showMeta = true;
        this.isLoaded = true;
        this.cd.detectChanges();
      },
      (error) => {
        console.log(error);
        this.notificationService.error(
          'Uh oh...',
          'Unable to load data. Please contact support.'
        );
        this.isFetched = false;
        this.spinnerService.toggle(false);
      }
    );
  }
}
