import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { EventType } from '../../../../models/EventType';
import { AuthService } from '../../../../services/auth/auth.service';
import { EventRuleService } from '../../../../services/eventRule/event-rule.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { ToolbarService, LinkService, ImageService, QuickToolbarService, ResizeService } from '@syncfusion/ej2-angular-richtexteditor';
import { HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { EmailOutputComponent } from '../../event-output/email-output/email-output.component';
import { WebhookOutputComponent } from '../../event-output/webhook-output/webhook-output.component';
import { IEventOutput } from '../../../../models/IEventOutput';

@Component({
  selector: 'new-advanced-event-rule',
  templateUrl: './new-advanced-event-rule.component.html',
  styleUrls: ['./new-advanced-event-rule.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, ResizeService]
})
export class NewAdvancedEventRuleComponent implements OnInit {

  @ViewChild('eventTypesUI')
  public eventTypeUI: ComboBoxComponent;

  @ViewChild('webhook')
  webhook:WebhookOutputComponent;

  @ViewChild("email")
  email:EmailOutputComponent;
  
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  
  @Input() public rule:any;
  @Input() public isNewRule:boolean;
  @Input() public eventTypes:EventType[];
  @Input() public eventDestinationTypes:any[];

  outputData:any;

  ruleForm:FormGroup;
  eventFields: Object = { value: 'id', text: 'name' };

  selectedEvent:any;
  selectedDestination:any;
  
  fields: Object = { value: 'id', text: 'name' };

  typeTemplate:any;
  vendorTemplate:any;
  typeOperators: any[] = [ 
    { value: 'equal', key: 'Is' },
    { value: 'notequal', key: 'Is Not' },
  ];

  outputFormGroup:FormGroup;
  todoMessage:string;

  eventTypeEnabled:boolean;
  selectedEventTypeName:string;

  codeMirrorOptions:any;

  constructor(
    public spinnerService:SpinnerService,
    public eventRuleService:EventRuleService,
    public authService:AuthService,
    public formBuilder:FormBuilder
  ) { 
    this.todoMessage = "Not yet supported...";

    this.ruleForm = this.formBuilder.group({
      id: -1,
      name: '',        
      enabled: false,
      eventTypeId: null,        
      filterConfig: '',
      conditionCode: '',
      actionCode: '',
      actionConfig: ''
    });    
    this.eventTypeEnabled = true;
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
    };
  }

  ngOnInit(): void {
    console.log("Add/Edit Form onInit");
    console.log(this.rule);

    if ( !this.rule) {
      this.rule = {
        id: null, name: '',
        enabled: false,
        typeId: null,
        conditionCode: '// Required, code to run against desired events',
        actionCode: '// Optional, advanced configuration code',
        filterConfig: '// Optional, code to determine events of interest',
        actionConfig: '// Optional, advanced properties configuration'
      };
    }

    this.ruleForm.patchValue({
      id: this.rule.id,
      name: this.rule.name, 
      enabled: this.rule.enabled,
      eventTypeId: this.rule.typeId,
      conditionCode: this.rule.conditionCode,
      actionCode: this.rule.actionCode,
      filterConfig: this.rule.filterConfig,
      actionConfig: this.rule.actionConfig
    });

    if ( this.rule.id != null || this.rule.isDuplicate == true ){
      this.eventTypeEnabled = false;
      this.outputData = {};

      this.selectedDestination = this.rule.outputs[0].destinationId;      
      this.ruleForm.patchValue({destinationId: this.rule.outputs[0].destinationId});

      if ( this.rule.outputs[0].outputConfig && this.rule.outputs[0].outputConfig != "") {
        try{ 
          this.outputData = JSON.parse(this.rule.outputs[0].outputConfig);
        } catch(parseErr) {        
          console.log(parseErr);          
        }
      }
    }

    this.loadRuleData();
  }

  loadRuleData(){
    this.spinnerService.toggle(true);
    this.eventRuleService.getEventRulesForUI(null).subscribe((results)=>{      
      console.log(results);
      this.eventDestinationTypes = results.allDestinations;
      this.eventTypes = this.authService.isVaultUser() ? results.allEvents : results.allEvents.filter((event)=>{ return event.isInternal == false });
      this.spinnerService.toggle();
    },
    (error)=>{
      this.spinnerService.toggle();
    });
  }

  cancel(){
    this.cancelEvent.emit('cancel!');
  }

  save() {

    this.spinnerService.toggle(true);
    
    let ruleData = {
      eventTypeId: this.ruleForm.value.eventTypeId,
      enabled: this.ruleForm.value.enabled == true ? true : false,
      name: this.ruleForm.value.name, 
      filterConfig: this.ruleForm.value.filterConfig, 
      conditionCode: this.ruleForm.value.conditionCode,
      uiJSON: '',
      associationJSON: '',
      customRule: true,
      actionCode: this.ruleForm.value.actionCode,
      actionConfig: this.ruleForm.value.actionConfig,
      destinationTypeId: this.selectedDestination,
      destinationConfig: JSON.stringify(this.getOutputControl().getValue())
    };

    console.log(ruleData);

    if ( this.rule.id == null ) {

      this.eventRuleService.createEventRule([ruleData]).subscribe((result)=>{
        console.log(result);
        this.spinnerService.toggle();
        this.saveEvent.emit({type: 'new', state: 'success'});
      },
      (err)=>{
        console.log(err);
        this.spinnerService.toggle();
      });
    } else {
      let updatedRuleData = {
        id: this.rule.id,
        name: this.ruleForm.value.name,
        enabled: this.ruleForm.value.enabled == true ? true : false,
        filterConfig: this.ruleForm.value.filterConfig, 
        conditionCode: this.ruleForm.value.conditionCode,
        actionCode: this.ruleForm.value.actionCode,
        actionConfig: this.ruleForm.value.actionConfig,
        customRule: true,
        outputId: this.rule.outputs[0].outputId,
        destinationConfig: JSON.stringify(this.getOutputControl().getValue())
      }

      this.eventRuleService.updateEventRule([updatedRuleData]).subscribe((updateResponse)=>{
        console.log(updateResponse);
        this.spinnerService.toggle();
        this.saveEvent.emit({type: 'update', state: 'success'});
      },
      (err)=>{
        console.log(err);
        this.spinnerService.toggle();
      });
    }

  }

  eventChange(selectedId) {
    if ( !selectedId || !this.eventTypes || this.eventTypes.length == 0 ) return;  
    this.selectedEventTypeName = this.eventTypes.filter((item)=>{ return item.id == selectedId;})[0].name;
  }


  getOutputControl():IEventOutput {
    if ( this.selectedDestination == 3 ){      
      return this.webhook as IEventOutput;
    } else if ( this.selectedDestination == 1 ) {
      return this.email as IEventOutput;
    }
    
    return null;
  }
}