<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border">
      <div class="card-header">
          <div class="tools">
            <!-- <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exporData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button> -->            
            <button *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" type="button" class="btn btn-rounded btn-space btn-primary" data-toggle="modal" data-target="#modal-create-edit-test-suite"><i style="color: white;" class="icon icon-left zmdi zmdi-plus"></i> New</button>
          </div>
        <span class="title">Test Suites</span>&nbsp;<span class="icon zmdi zmdi-info" title="Test Suites" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
        <span class="card-subtitle">{{tooltipContent}}</span>
      </div>
      <div class="card-body">
        <ejs-grid #testSuiteGrid
        [dataSource]="testSuites$ | async"
        locale='en-US' 
        [allowPaging]="true" 
        [pageSettings]="pageSettings" 
        [allowSorting]="true" 
        [sortSettings]="sortSettings"
        [allowSelection]="false"
        [enableHover]="false"
        [toolbar]="toolbar"
        [allowResizing]="true"
        (created)="onGridCreate()"
        (dataStateChange)= 'dataStateChange($event)'
        >
        <e-columns>
          <e-column [visible]="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" width="65">
            <ng-template #template let-data>
              <button class="btn btn-xs btn-rounded btn-space btn-danger" (click)="showDeletePrompt(data)">Delete</button>
            </ng-template>
          </e-column>
          <e-column [visible]="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" width="50">
            <ng-template #template let-data>
              <button class="btn btn-xs btn-rounded btn-space btn-primary" (click)="onEditConditionClick(data)">Edit</button>
            </ng-template>
          </e-column>
          <e-column field='name' headerText='Name'>
            <ng-template #template let-data>
              <a [routerLink]="" (click)="onTestSuiteClick(data)">{{data.name}}</a>
            </ng-template>
          </e-column>
          <e-column field='description' headerText='Description'></e-column>
          <e-column field='author' headerText='Author'></e-column>
          <e-column field='dateCreated' headerText='Date Created'>
            <ng-template #template let-data>{{ timezoneService.format(data.dateCreated) }}</ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modal-create-edit-test-suite" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <p>Test Suite Details</p>
          <form [formGroup]="newTestSuiteForm">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="name">
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Description</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <textarea cols="100" rows="4" class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>
          </form>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="createTestSuite();">Save</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<div class="modal fade" id="mod-confirm-delete" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
          <h3>Warning!</h3>
          <p>Are you sure you want to delete "{{selectedTestSuite?.name}}"? You cannot undo this action!</p>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="onDeleteClick();">Proceed</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
