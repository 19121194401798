import { Component, AfterViewInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { environment as env } from "../environments/environment";
import { SpinnerService } from './services/spinner/spinner.service';
declare var BeagleApp: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  constructor(public auth: AuthService, public router: Router, public activatedRoute: ActivatedRoute, public spinnerService: SpinnerService) {
    console.log("AppComponent constructor");
    this.initAppJs();

    console.log('Checking auth...');
    let ssoId = localStorage.getItem('ssoId');
    if (location.href.indexOf("authCallbackIdP") != -1) {
      auth.handleIdPAuthPassthru();
    } else if (location.href.endsWith("/login") && ssoId != null) {
      this.router.navigate(['login'], { queryParams: { sso: ssoId } });
    } else {
      auth.handleAuthentication();
    }

    window["vaultSwapEnv"] = function () {
      console.log("Changing env vars...");
      console.log("Before:", JSON.stringify(env.aws));
      for (var prop in env.aws) {
        env.aws[prop] = env.aws[prop].replace("dev-", "prod-");
      }
      console.log("After:", JSON.stringify(env.aws));
    }
  }

  ngAfterViewInit() {
    this.initAppJs();
  }

  initAppJs() {
    if ((<any>BeagleApp)) {
      (<any>BeagleApp).init();
    } else {
      console.log('App object is null in AppComponent constructor');
    }
  }

}
