import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../alerts/alerts.service';
import { AuthService } from '../auth/auth.service';
import { CustomerService } from '../customer/customer.service';
import { InstrumentationService } from '../instrumentation/instrumentation.service';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../users/user.service';
import { VendorsService } from '../vendors/vendors.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSwitchService {

  constructor(public customerService:CustomerService, 
    private alertService:AlertsService, 
    private vendorService:VendorsService,
    private userService:UserService,
    private router:Router, 
    private route:ActivatedRoute,
    private authService:AuthService,
    private timezoneService:TimezoneService,
    private instrumentationService:InstrumentationService) { 

    }
    
    switchAccount(accountId:number, shouldReload=false){
      console.log("switch account",accountId);
      
      localStorage.setItem("cid", "" + accountId);
      let customerObj = this.customerService.customers.filter( (c) => {return c.id == accountId})[0];
      console.log("xxx", customerObj);
      this.customerService.setCustomer(customerObj);
      
      localStorage.setItem('customer', JSON.stringify(this.customerService.customer));
      this.timezoneService.updateSettings(this.customerService.customer.isUtcDefaultForUI, this.customerService.customer.uiDateTimeFormatString)
      
      console.log("Clearing all caches...");
      this.vendorService.clearCache();
      this.alertService.clearCache();
      this.customerService.clearCache();
      this.userService.clearCache();
      this.authService.clearCache();
      this.authService.resetUI();     
      this.instrumentationService.reset();

      this.instrumentationService.sendEvent({type: 'Account Switch', page: "" });
      
      // Need to do this?
      // localStorage.setItem('redirectUrl', null);

      if ( shouldReload ){
        location.reload();
      } else {
        this.router.navigate(['/vendors']);
      }
    }

  
}
