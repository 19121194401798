const userAgent = `Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36`;
const timeout = 30000;
export const config_templates = [
  {
    id: "1D",
    name: "Default",
    template: `{
    "enabled": true,
    "timeout": ${timeout},
    "userAgent": "${userAgent}"
}`,
  },
  {
    id: "2D",
    name: "California w/ GPC Header",
    template: `{
    "enabled": true,
    "timeout": ${timeout},
    "userAgent": "${userAgent}",
    "region": "us-west-1",
    "GPCOptOut": true
}`,
  },
  {
    id: "3D",
    name: "Frankfurt No Preference",
    template: `{
    "enabled": true,
    "timeout": ${timeout},
    "userAgent": "${userAgent}",
    "region": "eu-central-1"
}`,
  },
  {
    id: "4D",
    name: "Virginia Baseline",
    template: `{
    "enabled": true,
    "timeout": ${timeout},
    "userAgent": "${userAgent}",
    "region": "us-east-1"
}`,
  },
];
