<div class="jumbotron">
  <h1 class="text-center">Add New Domain</h1>
</div>
<form [formGroup]="createdomain"  (ngSubmit)="addDomain()">
  <div class="form-group">
    <label>Name</label>
    <input type="text" class="form-control" formControlName="domains" placeholder="Enter Domain Name">
  </div>
  <div class="form-group">
    <button type="submit" class="btn-md btn-outline-primary">Add</button>
  </div>
</form>