import { Injectable } from '@angular/core';
import { ReportingS3Service } from '../reporting-s3/reporting-s3.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RuleModel } from '@syncfusion/ej2-angular-querybuilder';
export interface ViewObject {
  name: string;
  filterRules: RuleModel;
  sorting: {
    field: string;
    direction: string;
  };
  columns: {
    field: string;
    visible: boolean;
  }[];
  updated: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ReportingViewsService {
  constructor(private reportingS3Service: ReportingS3Service) {}

  /**
   * Retrieves a list of views from S3 for a given report code.
   *
   * @param reportCode - The code of the report.
   * @returns An observable that emits an array of ViewObject.
   */
  public getViewListFromS3(reportCode: string): Observable<ViewObject[]> {
    return this.reportingS3Service.getFile(`view${reportCode}.json`).pipe(
      map((data: string) => {
        return JSON.parse(data) as ViewObject[];
      }),
      catchError(() => of([]))
    );
  }
  public saveViewListToS3(
    code: string,
    viewName: string,
    state: any,
    filterRules: RuleModel
  ): Observable<any> {
    const newView: ViewObject = this.createViewObjectFromState(
      viewName,
      state,
      filterRules
    );
    return this.getViewListFromS3(code).pipe(
      map((data: any) => {
        console.log('Data from S3: ', data);
        const parsedData = data ? data : [];
        const existingViewIndex = parsedData.findIndex(
          (view) => view.name === newView.name
        );
        if (existingViewIndex > -1) {
          // Update the existing view
          parsedData[existingViewIndex] = {
            ...parsedData[existingViewIndex],
            ...newView,
          };
        } else {
          // Add the new view
          parsedData.push(newView);
        }
        console.log('Storing view to S3: ', parsedData);
        return parsedData;
      }),
      switchMap((parsedData) =>
        this.reportingS3Service
          .uploadJSON(parsedData, `view${code}.json`)
          .pipe(map(() => newView))
      ),
      catchError((error) => {
        console.error('Error storing view to S3:', error);
        return throwError(error);
      })
    );
  }
  public deleteViewFromS3(code: string, name: string): Observable<any> {
    return this.getViewListFromS3(code).pipe(
      map((data: any) => {
        const parsedData = data ? data : [];
        const updatedData = parsedData.filter(
          (view: ViewObject) => view.name !== name
        );
        return updatedData;
      }),
      switchMap((updatedData) =>
        this.reportingS3Service
          .uploadJSON(updatedData, `view${code}.json`)
          .pipe(map(() => name))
      ),
      catchError((error) => {
        console.error('Error deleting view to S3:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Retrieves the view list from local storage based on the provided code.
   * If the view list is found in the local storage, it is returned as an Observable of ViewObject[].
   * If the view list is not found, an empty array is returned as an Observable.
   *
   * @param code - The code used to identify the view list in the local storage.
   * @returns An Observable of ViewObject[] representing the view list from the local storage.
   */
  public getViewListFromLocalStorage(code: string): Observable<ViewObject[]> {
    if (window.localStorage.getItem(`vjsreportgrid-view-${code}`)) {
      console.log(
        'Getting view from local storage: ',
        JSON.parse(window.localStorage.getItem(`vjsreportgrid-view-${code}`))
      );
      return of(
        JSON.parse(window.localStorage.getItem(`vjsreportgrid-view-${code}`))
      );
    }
    return of([]);
  }
  public saveViewListToLocalStorage(
    code: string,
    viewName: string,
    state: any,
    filterRules: RuleModel
  ): Observable<null> {
    const newView: ViewObject = this.createViewObjectFromState(
      viewName,
      state,
      filterRules
    );
    return this.getViewListFromLocalStorage(code).pipe(
      map((list) => {
        if (list.filter((view) => view.name === newView.name).length > 0) {
          //update instead of adding
          if (!!newView.updated) {
            list.forEach((view) => {
              if (view.name === newView.name) {
                view = Object.assign(view, newView);
              }
            });
          }
        } else {
          list.push(newView);
        }
        console.log('Storing view to local storage: ', list);
        window.localStorage.setItem(
          `vjsreportgrid-view-${code}`,
          JSON.stringify(list)
        );
        return null;
      })
    );
  }

  public deleteViewListFromLocalStorage(
    code: string,
    name: string
  ): Observable<null> {
    return this.getViewListFromLocalStorage(code).pipe(
      map((existingList) => {
        if (existingList) {
          const list = existingList;
          const newList = list.filter((view) => view.name !== name);
          window.localStorage.setItem(
            `vjsreportgrid-view-${code}`,
            JSON.stringify(newList)
          );
        }
        return null;
      })
    );
  }

  public createViewObjectFromState(
    name: string,
    _state: any,
    filterRules: RuleModel
  ): ViewObject {
    const state = JSON.parse(_state);
    const sortingRules = state.sortSettings.columns[0];
    const visibleColumns = state.columns.map((col) => {
      return {
        field: col.field,
        visible: col.visible,
      };
    });
    return {
      name: name,
      filterRules: filterRules,
      sorting: sortingRules,
      columns: visibleColumns,
      updated: true,
    };
  }
}
