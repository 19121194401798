<div class="card card-flat">
  <div class="card-header">Scan Recency</div>
  <div class="card-body">
    <!-- <span style="font-size: medium;">{{label}}</span> -->
    <ejs-accumulationchart
      id="donut-container"
      #pie
      class="acc"
      [legendSettings]="legendSettings"
      [tooltip]="pieTooltip"
      (tooltipRender)="tooltipRender($event)"
      selectionMode="Point"
      (textRender)="onTextRender($event)"
      [center]="pieCenter"
      background="transparent"
    >
      <e-accumulation-series-collection >
        <e-accumulation-series
          [name]="toolTipLabel"
          [dataSource]="data"
          xName="x"
          yName="y"
          radius="100%"
          [dataLabel]="dataLabel"
          enableAnimation="true"
          class="series"
        >
        </e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</div>
