import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { Page } from "../models/Page";
import { PageService } from "../services/page/page.service";
import { tap } from "rxjs/operators";


@Injectable()
export class PageResolver implements Resolve<Page> {
    
    constructor(private pageService:PageService, private spinnerService:SpinnerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<Page> {
        console.log("Page Resolver", route);
        const pageId = route.params["pageId"];

        if ( this.pageService.selectedPage.value && this.pageService.selectedPage.value.id == pageId) {            
            return;
        } else {
            this.spinnerService.toggleRoot(true);
            return this.pageService.getPage(pageId).pipe(tap((data)=>{
                this.pageService.selectedPage.next(data);
                this.spinnerService.toggleRoot(false);
            }));
        }        
    }
}