import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent, ResizeService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { forkJoin, Observable, of } from 'rxjs';
import { Customer } from '../../../models/Customer';
import { DomainDefinition } from '../models/DomainDefinition';
import { Vendor } from '../../../models/Vendor';
import { CustomerService } from '../../../services/customer/customer.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { VendorLibraryStateService } from '../../../services/vendorLibrary/vendor-library-state.service';

@Component({
  selector: 'app-domain-definition',
  templateUrl: './domain-definition.component.html',
  styleUrls: ['./domain-definition.component.css'],
  providers: [ResizeService, ToolbarService]
})
export class DomainDefinitionComponent implements OnInit {

  @ViewChild('domainDefGrid') domainDefGrid: GridComponent;

  domainDefs: DomainDefinition[];
  vendors: Vendor[];
  customers: Customer[];

  vendorsById: Map<number, Vendor>;
  customersById: Map<number, Customer>;

  pageSettings;
  editSettings;
  sortSettings;
  toolbar;
  vendorEditParams;
  customerEditParams;
  commands;
  selectOptions;
  boolParams;

  public filterSettings: Object;

  constructor(
    public vendorLibService: VendorLibraryService,
    public vendorLibState: VendorLibraryStateService,
    public vendorService: VendorsService,
    public customerService: CustomerService,
    public spinnerService: SpinnerService,
    public notificationService: VaultNotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.filterSettings = { type: 'Excel' };

    this.sortSettings = {
      columns: [
        { field: 'name', direction: 'Ascending'}
      ]
    }

    this.editSettings = {
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Normal',
      allowEditOnDblClick: true
    };
    this.toolbar = ['Add', 'Cancel'];
    this.pageSettings = {
      currentPage: 1,
      pageSize: 50,
      pageCount: 4,
      pageSizes: [50, 75, 100]
    };
    this.selectOptions = { type: 'Single' };

    this.commands = [
      { type: 'Custom', buttonOption: { iconCss: ' icon zmdi zmdi-info', cssClass: 'e-flat', disabled: false } },
      { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
      { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
      { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }
    ];

    this.vendorsById = new Map<number, Vendor>();
    this.customersById = new Map<number, Customer>();

    this.vendorEditParams = {
      params: {
        allowFiltering: true,
        fields: { text: 'name', value: 'id' },
        query: new Query(),
        actionComplete: () => false
      }
    };

    this.customerEditParams = {
      params: {
        allowFiltering: true,
        fields: { text: 'name', value: 'id' },
        query: new Query(),
        actionComplete: () => false
      }
    };

    this.boolParams = { params: { checked: false } };
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.toggle(true);

    forkJoin([
      this.vendorService.getAllVendors(),
      this.customerService.getAllCustomers(),
      this.vendorLibService.getAllDomainDefinitions(false),
      this.vendorLibService.getAllFlags(),
      this.vendorLibService.getAllMatcherAttributeKeys(),
    ]).subscribe((results) => {
      this.vendors = results[0];
      this.customers = results[1];
      this.domainDefs = results[2];
      this.spinnerService.toggle();

      this.vendors.forEach((vendor) => {
        if (vendor) {
          this.vendorsById[vendor.id] = vendor;
        }
      });

      this.customers.forEach((customer) => {
        if (customer) {
          this.customersById[customer.id] = customer;
        }
      });

      this.domainDefs = this.domainDefs.map((domainDef) => {
        let vendor = this.vendorsById[domainDef.hostVendorId];
        return { ...domainDef, hostVendorName: vendor ? vendor.name : "(Missing Vendor)" }
      });

      this.vendorEditParams.params.dataSource = this.vendors;
      this.customerEditParams.params.dataSource = this.customers;
    });
  }

  actionBegin(event) {
    console.log("actionBegin", event);
    console.log("actionBegin DataSource", this.domainDefs);
    if (event.action == 'add') {
      this.spinnerService.toggle(true);
      this.vendorLibService.createDomainDefinition(event.data).subscribe((response) => {
        console.log(response);
        console.log("actionBegin complete DataSource", this.domainDefs);
        if (response.error) {
          this.notificationService.error("Error", response.error);
        } else {
          this.domainDefs[0].id = response.newObjectId;
        }
        this.loadData();
      },
        (error) => {
          console.log("err", error);
        })
    } else if (event.action == 'edit') {
      console.log('EDIT');
      let changes = this.vendorLibService.getChangedProperties(event.rowData, event.data);
      if (changes) {
        this.spinnerService.toggle(true);
        this.vendorLibService.updateDomainDefinition(changes).subscribe((response) => {
          console.log(response);
          this.loadData();
        });
      } else {
        this.notificationService.info("Info", "No changes were detected to save.")
      }
    } else if (event.requestType == 'delete') {
      console.log('DELETE');
      this.spinnerService.toggle(true);
      this.vendorLibService.deleteDomainDefinition(event.data[0].id, true).subscribe((result) => {
        console.log(result);
        this.loadData();
      });
    } else if (event.type == 'actionComplete' && event.requestType == 'add') {
      this.commands[0].buttonOption.disabled = true;
    } else if (event.type == 'actionBegin' && event.requestType == 'cancel') {
      this.commands[0].buttonOption.disabled = false;
    }
  }

  rowSelected(event) {
    console.log('Row Selected');
    console.log(event);
  }

  commandClick(event) {
    if (event.commandColumn.type == 'Custom' && event.commandColumn.buttonOption.disabled == false && event.rowData && !isNaN(event.rowData.id)) {
      this.vendorLibState.selectedDomainDefinition.next(null);
      this.router.navigate(["domainDefinition", event.rowData.id], { relativeTo: this.route });
    } else if (event.commandColumn.type == 'Edit') {
      this.commands[0].buttonOption.disabled = true;
    } else if (event.commandColumn.type == 'Cancel') {
      this.commands[0].buttonOption.disabled = false;
    }
  }

  onActionComplete(event) {
    console.log("onActionComplete", event);
  }

  public hostVendorSortComparer = (reference: string, comparer: string) => {
    
    let refName = this.vendorsById[reference].name
    let compName = this.vendorsById[comparer].name;

    if (refName < compName) {
      return -1;
    }
    if (refName > compName) {
      return 1;
    }
    return 0;
  }
}