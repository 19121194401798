<div class="row" *ngIf="!isEditing; else newQuery">
  <div class="col-sm-12">
    <div class="card card-table card-border be-loading">
      <div class="card-header">                  
      </div>
      <div class="card-body">        
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Query:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select class="form-control queries" (change)='onQueryChange($event);'>
                  <option *ngFor="let query of queries;" [ngValue]="query.id">{{query.name}}</option>
                </select>            
              </div>
              <div class="buttonGroup">
                <button type="button" class="btn btn-rounded btn-space btn-warning runButtonMargin" (click)="runQuery();" >Run</button>
                <button *ngIf="authService.isVaultUser()" type="button" class="btn btn-rounded btn-space btn-danger" data-toggle="modal" data-target="#mod-confirm-delete">Delete</button>
                <button *ngIf="authService.isVaultUser()" type="button" (click)="onEditClick();" class="btn btn-rounded btn-space btn-primary">Edit</button>
                <button *ngIf="authService.isVaultUser()" type="button" (click)="onNewClick();" class="btn btn-rounded btn-space btn-success">New</button>
              </div>      
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Show Query:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <label class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" [(ngModel)]="showSqlEditor"><span class="custom-control-label"></span>
                </label>
              </div>
            </div>
            <div *ngIf="showSqlEditor" style="width: 100%; height: 200px;" >
              <ngx-codemirror #sqlEditor [(ngModel)]="querySql" [options]="codeMirrorOptions"></ngx-codemirror>
            </div>
            <div *ngIf="manifestFile" class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Records Cursor:</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select class="form-control queries" [(ngModel)]="fileCounter" (ngModelChange)="onFileChange();">
                  <option *ngFor="let label of cursorLabels" [ngValue]="label.value">{{ label.text }}</option>
                </select>            
              </div>
            </div>
            <div *ngIf="manifestFile || exportUrl" class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">All Records:</label>
              <div class="col-12 col-sm-8 col-lg-6" style="margin-top: 10px;">
                <a href="{{exportUrl}}">Export</a>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>

<simple-notifications ></simple-notifications>

<div class="modal fade" id="mod-confirm-delete" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
          <h3>Warning!</h3>
          <p>Are you sure you want to delete "{{ selectedQuery?.name }}"? You cannot undo this action!</p>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="deleteQuery();">Proceed</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>


  <ejs-grid #grid  *ngIf="!isEditing"
  locale='en-US' 
  [allowPaging]="true" 
  [pageSettings]="pageSettings" 
  [allowSorting]="true" 
  [allowSelection]="false"
  [enableHover]="false"
  allowFiltering="true"
  [filterSettings]="filterSettings"     
  [allowResizing]="true"  
  >         
  </ejs-grid>  

  <ng-template #newQuery>
    <query-details (cancelEvent)="onEditCancel($event)" (saveEvent)="onEditSaveUpdate($event)" [query]="selectedQuery"></query-details>
  </ng-template>