import { Injectable } from '@angular/core';
import { FBDetailsTable } from './FB-details-table';
import { FbDescriptions } from './fb-descriptions';
import { Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root'
})
export class FBDetailsService {
  private header = true;
  private currentRequest = undefined;
  private headerNums = {
    pageURL: 0,
    pageHostname: 1,
    requestUuid: 2,
    requestHostname: 3,
    requestPath: 4,
    isPost: 5,
    dataKey: 6,
    dataValue: 7,
    configKey: 8,
    configValue: 9
  };
  private reportJSON = { "pages": {} };

  constructor(private papa: Papa) { }

  public parseCSVFile(CSV: any): Observable<any> {
    return new Observable((observer) => {
      this.papa.parse(CSV, {
        // header: true,
        dynamicTyping: true,
        step: (results) => {
          try {
            if (this.header) {
              this.header = false;
              console.log('Skipping Header...');
              return;
            }
            this.accumPFData(results.data);
            this.accumPIDData(results.data);
            this.accumCFSData(results.data);
            this.accumFBDData(results.data);
            const requestUuid = results.data[this.headerNums.requestUuid];
            if (this.currentRequest === requestUuid) {
              return;
            }
            this.currentRequest = requestUuid;
            this.accumFBTData(results.data);
            this.accumCAPIData(results.data);
            this.accumVOPData(results.data);
          } catch (error) {
            console.log(error); // FIXME: uncomment when testing new CSV
          }
        },
        complete: (results) => {
          try {
            this.setPFData();
            this.setFBTData();
            this.setCAPIData();
            this.setVOPData();
            this.setPIDData();
            this.setCFSData();
            this.setFBDData();
            // console.log(JSON.stringify(this.reportJSON));
            observer.next(this.reportJSON);
            observer.complete();
          } catch (error) {
            console.log(error);
          }
        }
      });
    });
  }

  private uniqBy(a, key) {
    let seen = new Set();
    return a.filter(item => {
      let k = item[key];
      return seen.has(k) ? false : seen.add(k);
    });
  }

  private accumPFData = (row) => {
    const page = row[this.headerNums.pageURL];
    const dataKey = row[this.headerNums.dataKey];
    const dataValue = row[this.headerNums.dataValue];
    const features = ["dpo", "fbp", "fbc"];
    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = { "page": page, "PFData": { "dpo": undefined, "fbp": undefined, "fbc": undefined } };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("PFData")) {
      this.reportJSON.pages[page]["PFData"] = { "dpo": undefined, "fbp": undefined, "fbc": undefined };
    }
    if (!features.includes(dataKey)) {
      return;
    };
    this.reportJSON.pages[page]["PFData"][dataKey] = dataValue;
  }

  private setPFData = () => {
    const pages = this.reportJSON.pages;
    Object.keys(pages).forEach((url) => {
      let PFData = pages[url]["PFData"];
      let arrData = [];
      Object.keys(PFData).forEach((key) => {
        // FIXME: add logic for undefined here (See UI)
        arrData.push({ "dataKey": key, "dataValue": PFData[key] });
      });
      pages[url]["PFData"] = arrData;
    })
  }

  private accumFBTData = (row) => {
    const FBDomain = /^facebook\.(com|net)$/;
    const FBPaths = [/^\/.+\/fbevents\.js$/, /^\/.+\/sdk\.js$/, /^\/privacy_sandbox\/.+\/trigger/];
    const page = row[this.headerNums.pageURL];
    let domain = row[this.headerNums.requestHostname];
    const pathName = row[this.headerNums.requestPath];
    try {
      domain = domain.split('.').splice(-2).join('.');
    } catch (error) {
      console.log(error);
    }
    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = { "page": page, "FBTData": { "SDK": undefined, "FBTrack": undefined, "Privacy Sandbox": undefined } };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("FBTData")) {
      this.reportJSON.pages[page]["FBTData"] = { "SDK": undefined, "FBTrack": undefined, "Privacy Sandbox": undefined };
    }
    if (!FBDomain.test(domain) || !!!FBPaths.find(pathRegex => pathRegex.test(pathName))) {
      return;
    } else if (/^\/.+\/fbevents\.js$/.test(pathName)) {
      this.reportJSON.pages[page]["FBTData"]["FBTrack"] = true;
    } else if (/^\/.+\/sdk\.js$/.test(pathName)) {
      this.reportJSON.pages[page]["FBTData"]["SDK"] = true;
    } else {
      this.reportJSON.pages[page]["FBTData"]["Privacy Sandbox"] = true;
    }
  }

  private setFBTData = () => {
    const pages = this.reportJSON.pages;
    Object.keys(pages).forEach((url) => {
      let FBTData = pages[url]["FBTData"];
      let arrData = [];
      console.log('FBT',FBTData);
      Object.keys(FBTData).forEach((key) => {
        arrData.push({ "tech": key, "detected": FBTData[key]});
      });
      pages[url]["FBTData"] = arrData;
    });
  }

  private accumCAPIData = (row) => {
    const page = row[this.headerNums.pageURL];
    const hostName = row[this.headerNums.requestHostname];
    const pathName = row[this.headerNums.requestPath];
    const isPost = row[this.headerNums.isPost];

    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = { "page": page, "CAPIData": { "POST": 0, "GET": 0 } };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("CAPIData")) {
      this.reportJSON.pages[page]["CAPIData"] = { "POST": 0, "GET": 0 };
    }
    if (hostName != 'www.facebook.com' && pathName != '/tr/') {
      return;
    }
    if (isPost === true) {
      this.reportJSON.pages[page]["CAPIData"]["POST"]++;
    } else {
      this.reportJSON.pages[page]["CAPIData"]["GET"]++;
    }
  }

  private setCAPIData = () => {
    const pages = this.reportJSON.pages;
    Object.keys(pages).forEach((url) => {
      let CAPIData = pages[url]["CAPIData"];
      let arrData = [];
      Object.keys(CAPIData).forEach((key) => {
        arrData.push({ "method": key, "total": CAPIData[key] });
      });
      pages[url]["CAPIData"] = arrData;
    });
  }

  private accumVOPData = (row) => {
    // FIXME: Need to get data from report with header data.
    // Maybe: this dashboard neeeds to accumulated from VpPA report
    const findVideoPlayers = (data) => {
      return data.requests
        .filter((request) => {
          if (
            request.response &&
            request.response.response &&
            request.response.response.headers
          ) {
            const contentType =
              request.response.response.headers["content-type"];

            if (contentType) {
              if (contentType.indexOf("video/") !== -1) {
                return true;
              }
            }
          }
        })
        .map((req) => {
          return req.request.url;
        });
    };

    const findYoutubePlayers = (host, path) => {
      try {
        return host.includes("youtube.com") && path.includes("/embed");
      } catch (error) {
        console.log(error);
        return false;
      }
    };


    const findKalturaPlayers = (host, path) => {
      try {
        return /^.+?cdnapisec\.kaltura\.com/.test(host) && /.+?embedPlaykitJs.+$/.test(path);
      } catch (error) {
        console.log(error);
        return false;
      }
    };


    const findVimeoPlayers = (host, path) => {
      try {
        return host.includes("player.vimeo.com") && path.includes("/video");
      } catch (error) {
        console.log(error);
        return false;
      }
    };


    const findBrightcovePlayers = (host, path) => {
      try {
        return host.includes("players.brightcove.net");
      } catch (error) {
        console.log(error);
        return false;
      }
    };
    const page = row[this.headerNums.pageURL];
    const hostName = row[this.headerNums.requestHostname];
    const pathName = row[this.headerNums.requestPath];

    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = { "page": page, "VOPData": [] };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("VOPData")) {
      this.reportJSON.pages[page]["VOPData"] = [];
    }
    const YTPlayer = findYoutubePlayers(hostName, pathName);
    const KalturaPlayer = findKalturaPlayers(hostName, pathName);
    const VimeoPlayer = findVimeoPlayers(hostName, pathName);
    const BrightcovePlayer = findBrightcovePlayers(hostName, pathName);
    if (YTPlayer || KalturaPlayer || VimeoPlayer || BrightcovePlayer) {
      this.reportJSON.pages[page]["VOPData"].push({ host: hostName, path: pathName });
    }
  }

  private setVOPData = () => {
    // TODO: any formatting goes here
  }

  private accumPIDData = (row) => {
    // FIXME: if the request is a POST, it is in the data JSON with the key 'id' 
    const page = row[this.headerNums.pageURL];
    const hostName = row[this.headerNums.requestHostname];
    const requestPath = row[this.headerNums.requestPath];
    const dataKey = row[this.headerNums.dataKey];
    const dataValue = row[this.headerNums.dataValue];
    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = { "page": page, "PIDData": [] };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("PIDData")) {
      this.reportJSON.pages[page]["PIDData"] = [];
    }
    if (hostName != 'www.facebook.com' ||
      requestPath != '/tr/' ||
      !dataKey ||
      dataKey.toLowerCase() != 'id') {
      return;
    }
    this.reportJSON.pages[page]["PIDData"].push({ "id": dataValue });
  }

  private setPIDData = () => {
    Object.keys(this.reportJSON.pages).forEach((key) => {
      let PIDData = this.reportJSON.pages[key]["PIDData"];
      this.reportJSON.pages[key]["PIDData"] = this.uniqBy(PIDData, "id");
    });
  }

  private accumCFSData = (row) => {
    const hasJsonStructure = (str) => {
      if (typeof str !== 'string') return false;
      try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
          || type === '[object Array]';
      } catch (err) {
        return false;
      }
    }
    const fromStringReturnJson = (str) => {
      // return false if str isn't a string
      if (typeof str !== 'string') return false;

      if (hasJsonStructure(str)) {
        return JSON.parse(str);
      } else {
        return false;
      }
    }

    const page = row[this.headerNums.pageURL];
    const configKey = row[this.headerNums.configKey];
    const configValue = row[this.headerNums.configValue];
    const jsonValue = fromStringReturnJson(configValue);

    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = { "page": page, "CFSData": [] };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("CFSData")) {
      this.reportJSON.pages[page]["CFSData"] = [];
    }

    if (configKey === -1) {
      return;
    }

    this.reportJSON.pages[page]["CFSData"].push({ "configOpt": configKey, "value": jsonValue ? jsonValue : configValue });
  }

  private setCFSData = () => {
    Object.keys(this.reportJSON.pages).forEach((key) => {
      let CFSData = this.reportJSON.pages[key]["CFSData"];
      CFSData.forEach((data) => {
        const configOpt = data["configOpt"];
        const value = data["value"];
        if (configOpt === "fbcParamsConfig") {
          data["value"] = value.params.map(x => JSON.stringify(x)).join("<br/>");
        } else if (configOpt === "prohibitedSources") {
          data["value"] = value.map(x => JSON.stringify(x)).join("<br/>");
        } else if (configOpt === "blacklisted_keys" || configOpt === "sensitive_keys") {
          data["value"] = JSON.stringify(value).replace(/"/g, '');
        }
      });
    });
  }

  private accumFBDData = (row) => {
    const page = row[this.headerNums.pageURL];
    const hostName = row[this.headerNums.requestHostname];
    const dataKey = row[this.headerNums.dataKey];
    const dataValue = row[this.headerNums.dataValue];

    if (dataKey === -1) {
      return;
    }

    if (!this.reportJSON.pages.hasOwnProperty(page)) {
      this.reportJSON.pages[page] = {
        "page": page, "FBDData": {
          "root": [
            {
              category: 'General Information'
            },
            {
              category: 'User Information'
            },
            {
              category: 'Device Information'
            },
            {
              category: 'E-Commerce & Transactions'
            },
            {
              category: 'Travel & Hospitality'
            },
            {
              category: 'Real Estate'
            },
            {
              category: 'Automotive'
            },
            {
              category: 'Jobs & Local'
            },
            {
              category: 'Miscellaneous'
            },
            {
              category: 'Unknown'
            }
          ], "level1": []
        }
      };
    } else if (!this.reportJSON.pages[page].hasOwnProperty("FBDData")) {
      this.reportJSON.pages[page]["FBDData"] = {
        "root": [
          {
            category: 'General Information'
          },
          {
            category: 'User Information'
          },
          {
            category: 'Device Information'
          },
          {
            category: 'E-Commerce & Transactions'
          },
          {
            category: 'Travel & Hospitality'
          },
          {
            category: 'Real Estate'
          },
          {
            category: 'Automotive'
          },
          {
            category: 'Jobs & Local'
          },
          {
            category: 'Miscellaneous'
          },
          {
            category: 'Unknown'
          }
        ], "level1": []
      };
    }
    if (!hostName.includes("facebook")) {
      return;
    }
    if (FBDetailsTable.hasOwnProperty(dataKey)) {
      const entry = FBDetailsTable[dataKey];
      entry["value"] = dataValue;
      this.reportJSON.pages[page]["FBDData"]["level1"].push(entry);
    } else {
      const entry = {
        "category": "Unknown",
        "param": dataKey,
        "value": dataValue,
        "apiName": dataKey,
        "name": dataKey,
        "explanation": "Unknown"
      }
      this.reportJSON.pages[page]["FBDData"]["level1"].push(entry);
    }
  }

  private setFBDData = () => {
    Object.keys(this.reportJSON.pages).forEach((page) => {
      let FBDData = this.reportJSON.pages[page]["FBDData"];
      FBDData.level1 = this.uniqBy(FBDData.level1, "param");
      FBDData.level1.sort((a, b) => a.param.localeCompare(b.param));
      this.reportJSON.pages[page]["FBDData"].level1 = FBDData.level1;
    });
  }

  public getDescription = (key: string) => {
    return FbDescriptions[key] ? FbDescriptions[key] : 'No description available';
  }
}