<div class="jumbotron">
    <h1 class="text-center">Domains for {{vendorName}}</h1>
  </div>
<div class="container">
    <div class="form-group row">
        <label for="search" class="col-sm-2 col-form-label" style="text-align: right;">Search</label>
        <div class="col-sm-6">
            <input type="text" [(ngModel)]="searchval" class="form-control" id="searchval"/>    
        </div>
        <div class="border">
            <a [routerLink] ="['/newdomain',vendorId]">
            <button class="btn btn-md btn-outline-primary">New Domain</button></a>
        </div>
    </div>
</div>
<table id="domains">
    <thead>
        <tr>
            <th>Domain Name</th>
            <th>Hostname</th>
            <th>Actions</th>
        </tr>  
    </thead>
    <tbody>
    <tr *ngFor= 'let listing of listings | searchfilterdomains: searchval'>
        <td>{{listing.name}}</td>
        <td>{{listing.hostname}}</td>
        <td>
            <button (click)="onDeleteClicked(listing.id)" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
        </td>
        </tr>
    </tbody>
</table>
<div class="border">
    <a [routerLink] = "['/domainEdit',vendorId]">
    <button class="btn btn-primary" type="submit"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Domains</button></a>
</div>
