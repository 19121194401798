import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { EventsComponent } from './events/events.component';
import { TotalsComponent } from './totals/totals.component';
import { CodeDiffComponent } from './code-diff/code-diff.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { EventRuleComponent } from './event-rule/event-rule.component';
import { EventOutputComponent } from './event-output/event-output.component';
import { NewEventRuleComponent } from './event-rule/new-event-rule/new-event-rule.component';
import { ComboBoxModule, ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs'
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { AvancedEventRuleComponent } from './avanced-event-rule/avanced-event-rule.component';
import { NewAdvancedEventRuleComponent } from './avanced-event-rule/new-advanced-event-rule/new-advanced-event-rule.component';
import { WebhookOutputComponent } from './event-output/webhook-output/webhook-output.component';
import { EmailOutputComponent } from './event-output/email-output/email-output.component';
import { SmsOutputComponent } from './event-output/sms-output/sms-output.component';
import { CodeReviewComponent } from './code-review/code-review.component';
import { DomainReviewComponent } from './domain-review/domain-review.component';
import { VendorReviewComponent } from './vendor-review/vendor-review.component';
import { CodeReviewComponentV2 } from './code-review-v2/code-review-v2.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    ComboBoxModule,
    QueryBuilderModule,
    RichTextEditorModule,
    TextBoxModule,
    ListBoxModule,
    ListViewModule,
    ButtonModule,
    AccordionModule
  ],
  declarations: [
    EventsComponent,
    TotalsComponent,
    CodeDiffComponent,
    EventRuleComponent,
    EventOutputComponent,
    NewEventRuleComponent,
    AvancedEventRuleComponent,
    NewAdvancedEventRuleComponent,
    WebhookOutputComponent,
    EmailOutputComponent,
    SmsOutputComponent,
    CodeReviewComponent,
    CodeReviewComponentV2,
    DomainReviewComponent,
    VendorReviewComponent
  ],
  exports: [
    EventsComponent,
    TotalsComponent
  ]
})
export class EventComponentsModule { }
