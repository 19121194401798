<div class="row">
    <div class="col-lg-4">
        <app-title-card>
            <ng-container header>
                <span class="title-icon
                    zmdi zmdi-apps"></span>
                Queried Report Services
            </ng-container>
            <div class="flex-column">
                <button (click)="run_getDataByPageUrl()">Run getDataByPageUrl()</button>
                <button (click)="run_uniqueValues()">Run UniqueValues()</button>
            </div>
        </app-title-card>
    </div>
    <div class="col-lg-4">
        <app-title-card>
            <ng-container header>
                <span class="title-icon
                    zmdi zmdi-apps"></span>
                Global Resources Service
            </ng-container>
            <div class="flex-column">
                <button (click)="run_globalResourcesAllFiles()"> Get Global Resources List</button>
            </div>
        </app-title-card>
    </div>
    <div class="col-lg-4">
        <app-title-card>
            <ng-container header>
                <span class="title-icon
                    zmdi zmdi-apps"></span>
                Config Groups Service
            </ng-container>
            <div class="flex-column">
                <button (click)="run_configGroupsAllFiles()"> Get config groups</button>
            </div>
        </app-title-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-4">
        <app-title-card>
            <ng-container header>
                <span class="title-icon
                    zmdi zmdi-apps"></span>
                    Tracker Dashboard Service
            </ng-container>
            <div class="flex-column">
                <button (click)="run_trackerDashboard()">Get Tracker Data</button>
                <div>{{trackerData}}</div>
            </div>
        </app-title-card>
    </div>
</div>