<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="vendor-selector-panel">
                <div class="vendor-info">
                    <div class="logo"><img width="50px"
                            src="https://{{cdnUrl}}/vendors/icon/{{ selectedVendorInfo.website ? selectedVendorInfo.website.toLowerCase() : 'unknown' }}.ico" />
                    </div>
                    <div class="name">{{selectedVendorName || "Undefined"}}</div>
                </div>
                <ejs-dropdownlist style="align-self: flex-end;" width="200px" [dataSource]="availableVendors"
                    (select)="onVendorSelected($event)" [value]="selectedVendorName"></ejs-dropdownlist>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-4">
        <app-minified-value-panel label="CHD Pages" [bgColor]="'#4d545c'" [textColor]="'white'" [value]="CHDPages"
            [isVertical]="false"></app-minified-value-panel>
    </div>
    <div class="col-lg-4">
        <app-minified-value-panel label=" Unique Domains" [bgColor]="'#4d545c'" [textColor]="'white'"
            [value]="uniqueDomains" [isVertical]="false"></app-minified-value-panel>
    </div>
    <div class="col-lg-4">
        <app-minified-value-panel label="Unique Pages" [bgColor]="'#4d545c'" [textColor]="'white'" [value]="uniquePages"
            [isVertical]="false"></app-minified-value-panel>
    </div>
</div>
<div class="row">
    <div class="col-lg-3">
        <app-minified-value-panel label="Unique Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
    <div class="col-lg-3">
        <app-minified-value-panel label="Unique Cookie Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueCookieTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
    <div class="col-lg-3">
        <app-minified-value-panel label=" Unique Data Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueDataTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
    <div class="col-lg-3">
        <app-minified-value-panel label="Unique Code Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueCodeTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <ejs-grid [dataSource]="vendorGroupedData">
                <e-columns>
                    <e-column field="name" headerText="Domain" textAlign="left"></e-column>
                    <e-column field="pageCount" headerText="Page Count" textAlign="left"></e-column>
                    <e-column field="domainTrackerCount" headerText="Tracker Requests" textAlign="left"></e-column>
                    <e-column field="categories" headerText="Categories" textAlign="left">
                        <ng-template #template let-data>
                            <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                <div *ngFor="let cat of data.categories">
                                    <ejs-tooltip [content]="cat">
                                        <ng-container [ngSwitch]="cat">
                                            <div (click)="goToCategoryDetails(cat,data.name, selectedVendorName)"
                                                class="cat-icons">
                                                <img width="85%" *ngSwitchCase="'Cookie Tracker'"
                                                    src="assets/svg/cookie-tracker.svg">
                                                <img width="85%" *ngSwitchCase="'Data Tracker'"
                                                    src="assets/svg/data-tracker.svg">
                                                <img width="100%" *ngSwitchCase="'Code Tracker'"
                                                    src="assets/svg/code-tracker.svg">
                                            </div>
                                        </ng-container>
                                    </ejs-tooltip>
                                </div>
                            </div>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>

<div class="row" *ngIf="isLoaded">
    <div class="col-lg-6" style="height: 100%">
        <div class="card">
            <div class="card-header">
                Pages By Domain
            </div>
            <multi-pie-chart [datasource]="pagePieData" [id]="'trackerPie'"></multi-pie-chart>
        </div>

    </div>
    <div class="col-lg-6" style="height: 100%">
        <div class="card">
            <div class="card-header">
                Trackers By Domain
            </div>
            <multi-pie-chart [datasource]="vendorPieData" [id]="'vendorPie'"></multi-pie-chart>
        </div>

    </div>
</div>