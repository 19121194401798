import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DataExportService } from '../../../services/data-export/data-export.service';

@Component({
  selector: 'app-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.css']
})
export class ScreenshotComponent implements OnInit {
  
  @ViewChild('img1') img1Canvas:ElementRef;
  @ViewChild('img2') img2Canvas:ElementRef;
  @ViewChild('diff') diffCanvas:ElementRef;

  img1str:string;
  img2str:string;
  image1;
  image2;
  diffCount;
  imagesLoading:boolean;

  dateA = "Image A";
  dateB = "Image B";
  
  constructor(private route: ActivatedRoute, private dataExportService:DataExportService) { 
    this.image1 = new Image();
    this.image1.crossOrigin = "Anonymous";
    this.image1.addEventListener('load', () => {
      this.img1Canvas.nativeElement.width = this.image1.width;
      this.img1Canvas.nativeElement.height = this.image1.height;
      this.img1Canvas.nativeElement.getContext('2d').drawImage(this.image1, 0, 0);
      if (this.img2str) {
        this.image2.src = this.img2str;
      }
    });
    this.image2 = new Image();
    this.image2.crossOrigin = "Anonymous";
    this.image2.addEventListener('load', () => {
      const ctx1 = this.img1Canvas.nativeElement.getContext('2d');
      const ctx2 = this.img2Canvas.nativeElement.getContext('2d');
      const diffCtx = this.diffCanvas.nativeElement.getContext('2d');
      this.img2Canvas.nativeElement.width = this.image2.width;
      this.img2Canvas.nativeElement.height = this.image2.height;
      ctx2.drawImage(this.image2, 0, 0);

      const pixelmatch = require('pixelmatch');
      const {width, height} = this.img1Canvas.nativeElement;
      const img1Data = ctx1.getImageData(0, 0, width, height);
      const img2Data = ctx2.getImageData(0, 0, width, height);
      const diffData = diffCtx.createImageData(width, height);
      this.diffCount = pixelmatch(img1Data.data, img2Data.data, diffData.data, width, height, {threshold: 0.1});
      console.log("Diff Count:", this.diffCount);
      this.diffCanvas.nativeElement.width = width;
      this.diffCanvas.nativeElement.height = height;
      diffCtx.putImageData(diffData, 0, 0);
      this.imagesLoading = false;

    });
  }

  ngOnInit() {
    this.imagesLoading = true;
    var img1Path = this.route.snapshot.queryParams["img1"];
    var img2Path = this.route.snapshot.queryParams["img2"];

    this.dateA = img1Path.split("/").slice(1,4).join("/");
    
    var obvs = [this.dataExportService.getSignedUrl(img1Path)];
    if ( img2Path ){
      this.dateB = img2Path.split("/").slice(1,4).join("/");
      obvs.push(this.dataExportService.getSignedUrl(img2Path));
    }
    
    forkJoin(obvs).subscribe((signedUrls)=>{
      this.img1str = signedUrls[0];
      this.image1.src = this.img1str;
      if ( signedUrls.length > 1){
        this.img2str = signedUrls[1];
      } else {
        this.imagesLoading = false;
      }  
    });
  }

}
