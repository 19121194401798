export const eventTypes = [
  {
    type: 'Code File Change',
    color: 'red',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Code Analysis Complete',
    color: 'green',
    iconCss: 'zmdi zmdi-check',
  },
  {
    type: 'Instrumentation',
    color: '#ababab',
    iconCss: 'zmdi zmdi-settings',
  },
  {
    type: 'New Code File',
    color: 'orange',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'New Incident',
    color: 'yellow',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Page Run Complete',
    color: '#3acfff',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Page Run Start',
    color: 'lime',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Report Complete',
    color: 'orange',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Validation Result',
    color: 'salmon',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Job Scheduled (priority)',
    color: '#ff7200',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Job Scheduled (highMemory)',
    color: '#ff7200',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Job Scheduled (lowMemory)',
    color: '#ff7200',
    iconCss: 'zmdi zmdi-file-text',
  },
  {
    type: 'Mobile App Run Start',
    color: 'lime',
    iconCss: 'zmdi zmdi-smartphone-iphone',
  },
  {
    type: 'Mobile Request',
    color: 'orange',
    iconCss: 'zmdi zmdi-smartphone-iphone',
  },
  {
    type: 'Mobile Navigation',
    color: 'lightblue',
    iconCss: 'zmdi zmdi-smartphone-iphone',
  },
];
