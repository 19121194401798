<div class="row">
    <div class="col-lg-12 dataDiv">
        <div class="card card-table card-border">
            <div class="card-body">        
                <ejs-grid #grid 
                height="700px"
                    [dataSource]='data' 
                    allowPaging='true' 
                    allowSorting="true" 
                    [groupSettings]="groupOptions" 
                    [pageSettings]='pageSettings' 
                    [sortSettings]='sortSettings'
                    [filterSettings]='filterSettings'
                    [allowFiltering]='true'
                >
                    <e-columns>            
                        <e-column field='codefile' headerText="Code File URL"></e-column>
                        <e-column field='present' headerText='# Pages On'></e-column>
                        <e-column field='percentage' headerText='% Pages On'></e-column>            
                    </e-columns>
                </ejs-grid>
            </div>    
        </div>
    </div>
</div>