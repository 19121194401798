<div [class.tile-background-overlay]="!(isEmpty && !beautifiedPath)"></div>

<div [class]='isEmpty && !beautifiedPath? "empty":"report-tile-container"'(click)="navigateToReport()" [style.opacity]="isEmpty && !beautifiedPath? 0.3  : 1">
    <div class='icon-container'>
        <div class='svg-container'>
            <img [src]="'/assets/svg/'+ icon +'.svg'" width="100%">
        </div>
    </div>
    <div class='text-container'  >
        <div class="title">{{title}}</div>
        <div class="description">{{description}}</div>
        <!-- <ejs-dropdownlist [dataSource]="availableReports"></ejs-dropdownlist> -->
    </div>
    <div class='button-container'>
        <div class='buttons' *ngIf="!(isEmpty && !beautifiedPath)">
                <button class='button-raw' *ngIf="hasRaw" (click)="openRawReportViewer()">RAW</button>
        </div>

    </div>
</div>