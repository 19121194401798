import { Component, Input, OnInit } from '@angular/core';
import {
  ITooltipRenderEventArgs,
  IAccTextRenderEventArgs,
} from '@syncfusion/ej2-charts';
@Component({
  selector: 'simple-piechart-panel',
  templateUrl: './simple-piechart-panel.component.html',
  styleUrls: ['./simple-piechart-panel.component.css'],
})
export class SimplePiechartPanelComponent implements OnInit {
  @Input('label') label: string;
  @Input('data') data: Array<number>;
  @Input('toolTipLabel') toolTipLabel: string;
  // public data: Object[] = [
  //   { x: '1-7 Days', y: 0 },
  //   { x: '8-30 Days', y: 0 },
  //   { x: '30-90 Days', y: 0 },
  //   { x: '> 90 Days', y: 0 },
  // ];

  public legendSettings: Object = {
    visible: true,
    toggleVisibility: false,
    position: 'Right',
    textWrap: 'Wrap',
    alignment: 'Center',
    width: "100",
  };
  public pieTooltip: Object = {
    enable: true,
  };
  public tooltipRender(args: ITooltipRenderEventArgs): void {
    console.log(args);
    args.text = `${args.point.x}: ${Number(args.point.y).toFixed(2)}%`;
  }

  public onTextRender(args: IAccTextRenderEventArgs): void {
    // args.series.dataLabel.font.size = this.getFontSize(this.pie.initialClipRect.width);
    // console.log("args.text", args.text);
    if (args.text == '0') {
      args.text = '';
    } else {
      args.text = Number(args.text).toFixed(2) + '%';
    }
  }

  public pieCenter: Object = {
    x: '50%',
    y: '50%',
  };

  public dataLabel: Object = {
    visible: false,
    position: 'Inside',
    font: { size: '12px', color: 'white' },
    template: '<div>${point.x}: ${point.y}</div>',
  };
  public recencyLabels: any = ['1-7 Days', '8-30 Days', '30-90 Days', '> 90 Days'];
  constructor() {
  }

  ngOnInit(): void {
    // this.data = this.inputData.map((item,index) => {
    //   return{
    //     x: this.recencyLabels[index],
    //     y: item
    //   }
    // });
  }
}
