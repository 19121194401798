import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

let worldMap: object[] = require('./worldMap.json');
import { MapsTooltipService, MarkerService, MapsComponent, ILoadedEventArgs } from '@syncfusion/ej2-angular-maps';


@Component({
  selector: 'app-realtime-regional-map',
  templateUrl: './realtime-regional-map.component.html',
  styleUrls: ['./realtime-regional-map.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MapsTooltipService, MarkerService]
})
export class RealtimeRegionalMapComponent implements OnInit {

  @ViewChild('map') public map: MapsComponent;

  public titleSettings: object = {
    text: 'Datacenter Regions'
  };

  public layers: object[] = [{
    shapeData: worldMap,
    shapeSettings: {
      border: { color: '#D2691E', width: 0.5 },
      fill: '#FFFFE0'
    },
    markerSettings: [
      {
        visible: true, height: 15, width: 15,
        template: '<div id="template"><div class="pulse-css"><br /><div class="name">{{:name}}</div></div></div>',
        animationDelay: 100,
        animationDuration: 1000,
        dataSource: [
          { latitude: 15.758401, longitude: 101.155642, name: 'Thailand' },
          { latitude: 57.725612, longitude: -101.802160, name: 'Canada' },
          { latitude: 39.634177, longitude: 42.410740, name: 'Turkey' },
          { latitude: 22.860388, longitude: 79.739066, name: 'India' },
          { latitude: -24.763753, longitude: 134.852191, name: 'Australia' },
          { latitude: 34.611098, longitude: 104.189872, name: 'China' },
          { latitude: -12.354844, longitude: -49.017709, name: 'Brazil' },
          { latitude: 3.450682, longitude: -72.943141, name: 'Colombia' },
          { latitude: 62.448268, longitude: 97.251835, name: 'Russia' },
          { latitude: 65.931163, longitude: -45.812820, name: 'Greenland' },
          { latitude: -21.206222, longitude: 17.122018, name: 'Namibia' },
          { latitude: 35.839969, longitude: 137.904641, name: 'Japan' },
          { latitude: 46.582184, longitude: 2.232903, name: 'France' },
          { latitude: 0.390617, longitude: 37.893734, name: 'Kenya' }
        ]
      },
      {
        visible: true, height: 15, width: 15,
        template: '<div id="parent_template" ><div class="parent_css"><br/><div class="name" style="margin-left: -10px">United States</div></div></div>',
        animationDelay: 100,
        animationDuration: 1000,
        dataSource: [{
          latitude: 39.864171,
          longitude: -100.854833
        }]
      },
    ]
  }];

  constructor() {

  }

  ngOnInit(): void {
  }

  public loaded = (args: ILoadedEventArgs) => {
    let lines:Object[] = args.maps.layers[0].markerSettings[0].dataSource as Object[];
    for (let i: number = 0; i < lines.length; i++) {
      // let line: HTMLElement = document.getElementById('container_LayerIndex_0_NavigationIndex_' + i + '_Line0');
      let marker: HTMLElement = document.getElementById('container_LayerIndex_0_MarkerIndex_0_dataIndex_' + i)
      console.log("marker", marker);
      // line.style.strokeDasharray = '1000';
      // line.style.strokeDashoffset = '1000';
      if (i < 4) {
        // line.style.animation = marker.style.animation = 'dash 5s linear 0s infinite';
        marker.style.visibility = 'hidden';
        setTimeout(() => {
          marker.style.visibility = 'visible';
        }, 0);
      } else if (i < 8) {
        // line.style.animation = marker.style.animation = 'dash 6s linear 2s infinite';
        marker.style.visibility = 'hidden';
        setTimeout(() => {
          marker.style.visibility = 'visible';
        }, 2000);
      } else if (i < 12) {
        // line.style.animation = marker.style.animation = 'dash 6s linear 4.5s infinite';
        marker.style.visibility = 'hidden';
        setTimeout(() => {
          marker.style.visibility = 'visible';
        }, 4500);
      } else {
        // line.style.animation = marker.style.animation = 'dash 5s linear 7s infinite';
        marker.style.visibility = 'hidden';
        setTimeout(() => {
          marker.style.visibility = 'visible';
        }, 7000);
      }
    }
  }
}
