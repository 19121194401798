<div class="card card-flat">
  <div class="card-body">
    <div class="be-booking-promo-price">
      <span style="font-size: medium">{{ max }}<br />{{ label }}</span>
      <div
        class="row user-progress"
        style="margin-top: 5px; margin-bottom: 0px !important"
      >
        <div class="col">
          <table>
            <tr>
              <td style="width: 200px">
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    [style.width.%]="getPercent()"
                  ></div>
                </div>
              </td>
              <td>
                <span
                  style="
                    padding-left: 10px;
                    color: black;
                    font-size: smaller;
                    font-style: italic;
                  "
                  >{{ getPercent() }}%</span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
