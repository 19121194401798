import { Component, OnInit, OnDestroy, Input, SimpleChanges, ViewChild } from '@angular/core';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../../services/timezone.service';
declare var $:any;

@Component({
  selector: 'dns-info',
  templateUrl: './dns-info.component.html',
  styleUrls: ['./dns-info.component.css']
})
export class DnsInfoComponent implements OnInit, OnDestroy {

  @Input() public vendor:any;
  
  dnsRecords:any;  
  dnsSub:Subscription;

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];  
  public dateFormat:any;
  @ViewChild('dnsGrid') public grid: GridComponent;

  constructor(
    private vendorService:VendorsService, 
    private router:Router, 
    private route:ActivatedRoute,
    private spinnerService:SpinnerService,
    private timezoneService:TimezoneService
  ) { 
    this.dateFormat = {type: 'dateTime', skeleton:'short'}
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 20, 
      pageCount: 4, 
      pageSizes: [20, 25, 50]
    };
    this.toolbar = ['Search'];
    this.sortSettings = {
      columns: [
        { field: 'type', direction: 'Ascending'},
        { field: 'Date', direction: 'Descending'}  
      ]
    }
  }

  ngOnInit() { 
    $('a[data-toggle="tab"]a:contains("DNS")').on('shown.bs.tab', (e) => {
      this.router.navigate([], {fragment:"dns"});
      this.loadData(this.vendor.id);      
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes && changes["vendor"] && changes["vendor"].currentValue){
      if ( this.route.snapshot.fragment && this.route.snapshot.fragment == "dns"){      
        this.loadData(this.vendor.id);
      }      
    }
  }

  loadData(vendorId){
    this.spinnerService.toggle(true);;
    this.dnsSub = this.vendorService.getVendorDnsInfo(vendorId).subscribe((dnsInfo) => {
        
      this.dnsRecords = dnsInfo;
      this.grid.dataSource = this.dnsRecords;
      this.spinnerService.toggle(false);
    },
    (error)=>{
      this.spinnerService.toggle(false);
    });
  }

  ngOnDestroy(){
    if ( this.dnsSub){
      this.dnsSub.unsubscribe();
    }    
  }

}