import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { VendorsComponent } from './vendors.component';
import { CodeFileDetailComponent } from './code-file-detail/code-file-detail.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { VendorInfoComponent } from './vendor-details/vendor-info/vendor-info.component';
import { CodeInfoComponent }  from './vendor-details/code-info/code-info.component';
import { DnsInfoComponent } from './vendor-details/dns-info/dns-info.component';

import { GridModule } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    QueryBuilderModule
  ],
  declarations: [
    VendorsComponent,
    CodeFileDetailComponent,
    VendorDetailsComponent,
    VendorInfoComponent,
    CodeInfoComponent,
    DnsInfoComponent,
    RequestDetailComponent
  ],
  exports: [
    VendorsComponent,
    CodeFileDetailComponent,
    VendorDetailsComponent,
    VendorInfoComponent,
    CodeInfoComponent,
    DnsInfoComponent,
    RequestDetailComponent
  ],
  providers:[
    PageService,
    SortService,
    FilterService,
    GroupService
  ]
})
export class VendorComponentsModule { }
