import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TestSuite } from '../../../models/TestSuite';
import { AuthService } from '../../../services/auth/auth.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { DataValidationService } from '../../../services/data-validation/data-validation.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataStateChangeEventArgs, GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { Ej2TestSuiteProxyService } from '../../../services/ej2Proxies/ej2-test-suite-proxy.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
declare var $:any;

@Component({
  selector: 'app-test-suite',
  templateUrl: './test-suite.component.html',
  styleUrls: ['./test-suite.component.css']
})
export class TestSuiteComponent implements OnInit {

  
  tooltipContent:string;
  selectedTestSuite:TestSuite;
  exportId:string;
  pollingTimer:any;
  notificationOptions:any;
  exportButtonDisabled:boolean;
  pollingTimerCounter:number;
  pollingTime:number;
  newTestSuiteForm:FormGroup;

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('testSuiteGrid') public grid: GridComponent;

  testSuites$:Observable<any>;


  constructor(private el: ElementRef, 
    private router: Router, 
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private notificationService: NotificationsService,
    private zone:NgZone,
    public authService:AuthService,
    private dataValidationService:DataValidationService,
    public _fb:FormBuilder,
    public spinnerService:SpinnerService,
    public timezoneService:TimezoneService,
    public ej2ProxyService:Ej2TestSuiteProxyService
    ) { 
      this.testSuites$ = this.ej2ProxyService.pipe(tap(()=>{this.spinnerService.toggle(false);}));
      let state = { skip: 0, take: 20 };
      this.ej2ProxyService.execute(state);

      this.pollingTime = 1000;
      this.sitemapService.update(this.route);
      
      this.tooltipContent = "Browse or search for your test suites";
      this.notificationOptions = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };
      this.exportButtonDisabled = false;

      this.newTestSuiteForm = this._fb.group({
        id: -1,
        name: '',
        description: ''
      });
      
      this.dateFormat = {type: 'dateTime', skeleton:'short'}
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 20, 
        pageCount: 4, 
        pageSizes: [20, 25, 50]
      };
      this.toolbar = ['Search'];
      this.sortSettings = {
        columns: [
          { field: 'name', direction: 'Ascending'}          
        ]
      };
  }

  createTestSuite(){
    $('#modal-create-edit-test-suite').modal('hide');
    console.log("createTestSuite");
    console.log(this.newTestSuiteForm.value);
    this.spinnerService.toggle(true);
    if ( this.newTestSuiteForm.value.id == -1 ){
      const newTS = new TestSuite(-1, "", this.newTestSuiteForm.value.name, 39, this.newTestSuiteForm.value.description, 0, new Date(), false);
      this.dataValidationService.createTestSuite(newTS).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The test suite has been saved.', this.notificationOptions);
        this.grid.refresh();
      });
    } else {
      this.selectedTestSuite.name = this.newTestSuiteForm.value.name;
      this.selectedTestSuite.description = this.newTestSuiteForm.value.description;
      this.dataValidationService.updateTestSuite(this.selectedTestSuite).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The test suite has been updated.', this.notificationOptions);        
        this.grid.refresh();
      });
    }
  };

  ngAfterViewInit(){
    console.log('ngAfterViewInit')
    $('[data-toggle="popover"]').popover();    
  }

  ngOnInit() {

    $('#modal-create-edit-test-suite').on('show.bs.modal', (e) => {      
      if (e.relatedTarget){
        console.log('New !');
        this.newTestSuiteForm = this._fb.group({
          id: -1,
          name: '',
          description: ''
        });
        this.zone.run(()=>{
          console.log("Refreshing test suite zone...");          
        });
      }
    });    
  }

  onTestSuiteClick(testSuite){
    console.log(testSuite);    
    this.dataValidationService.selectedTestSuite.next(testSuite);
    this.router.navigate(['validation/testSuites', testSuite.id]);
  }

  onEditConditionClick(testSuite) {
    this.selectedTestSuite = testSuite;
    console.log("Edit", this.selectedTestSuite);
    this.newTestSuiteForm = this._fb.group({
      id: this.selectedTestSuite.id,
      name: this.selectedTestSuite.name,
      description: this.selectedTestSuite.description
    });
    $("#modal-create-edit-test-suite").modal('toggle');
  }

  showDeletePrompt(testSuite) {
    this.selectedTestSuite = testSuite;
    $("#mod-confirm-delete").modal('toggle');
  }
  
  onGridCreate(){
    this.grid.showSpinner = () => true;
  }

  onDeleteClick(){
    $("#mod-confirm-delete").modal('toggle');
    if ( this.selectedTestSuite){
      this.spinnerService.toggle(true);
      this.dataValidationService.deleteTestSuite(this.selectedTestSuite).subscribe((response)=>{
        console.log(response);
        this.notificationService.success('Success!', 'The test suite has been deleted.', this.notificationOptions);        
        this.grid.refresh();
      })
    }
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);
    this.spinnerService.toggle(true);
    this.ej2ProxyService.execute(state);
  }

}
