import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { EditSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-scrollable-progress-bars',
  templateUrl: './scrollable-progress-bars.component.html',
  styleUrls: ['./scrollable-progress-bars.component.css']
})
export class ScrollableProgressBarsComponent implements OnInit, OnChanges {
  @Input() data?: any;
  @Input() title?: string;
  @Input() height?: any;

  public options?: PageSettingsModel;
  public editSettings?: EditSettingsModel;

  constructor() { }

  ngOnInit(): void {
    this.editSettings = { mode: 'Normal' };
    this.options = { pageSize: 5 };
  }

  ngOnChanges() {
  }

  loadData() {
  }

}
