<div class="col-sm-12">
  <div class="card card-table card-border">
      <div class="card-header">
          <span class="title">Page Configurations</span>
          <span class="card-subtitle">Create and manage your page and link configurations below</span>
      </div>
      <div class="card-body">
          <ejs-grid
          #configManagement
          id="configManagement"
          [dataSource]="configs"
          [allowPaging]='true'
          [pageSettings]='pageSettings'
          [editSettings]='editSettings'
          [allowSelection]='true'
          [toolbar]='toolbar'
          [selectionSettings]='selectOptions'
          allowResizing="true"
          (actionBegin)='actionBegin($event)'
          (commandClick)='commandClick($event)'
          (actionComplete)="actionComplete($event)"
          >

            <e-columns
            >   

                <e-column headerText='Actions' width='60px' [commands]='commands' textAlign="center"></e-column>
                <e-column field='name' headerText='Name' width="150">
                    <ng-template #template let-data>
                        <div class="row" style="margin: 0px 4px;">
                            <div class="col"
                                style="font-size: medium; font-weight: bold; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                                {{data.name || "No Name"}}
                            </div>
                        </div>
                        <div class="row" style="padding:0px; margin: 0px 4px; border-top: 1px solid grey; color:grey; ">
                            <div class="col"
                                style="font-size: small; padding:2px; margin:0;">
                                Config ID: {{data.id || "No ID"}}
                            </div>
                            <div class="col"
                                style="font-size: small; text-align: right; padding:2px; margin:0">
                                {{data.links.length}} Pages
                            </div>
                        </div>
                        <div *ngIf="data.modules.length > 0" class="row" style="padding:0px; margin: 0px 4px;  color:grey; ">
                            <div class="col"
                                style="font-size: small; padding:2px; margin:0; display: flex; flex-direction: row; flex-wrap: wrap;">
                                <span style="padding:2px 5px; margin: 2px 2px;">
                                Modules:
                                </span>
                                <span *ngFor="let module of data.modules" style="border-radius: 1rem; background-color:grey; padding:2px 5px; margin: 2px 2px; color: white">
                                        {{module.name}}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </e-column>
                <e-column field='lastChecked' headerText='Last Run' width="80px"></e-column>
                <e-column field='maxAgeInMinutes' headerText='Max Age' width="30px"></e-column>
                <e-column
                field='config'
                headerText='Config'
                type="string"
                width="100px"
                textAlign="left"
                [disableHtmlEncode]= 'disableHtmlEncode'
                defaultValue="{{json}}">
                <ng-template #editTemplate let-data>
                    <div>
                        <div style="font-size: medium; padding-left: 2px; padding-bottom: 4px; padding-top: 0px;">
                            Config
                        </div>
                        <ngx-codemirror [(ngModel)]="rowData.config" [options]="codeMirrorOptions"></ngx-codemirror>
                    </div>
                </ng-template>
                <ng-template #template let-data>
                    <div class="row" style="margin: 0px 4px;">
                        <div class="col"
                        style="font-size: small ; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                            {{data.config}}
                        </div>
                    </div>
                    <div class="row" style="padding:0px; margin: 0px 4px; border-top: 1px solid grey; color:grey; ">
                        <div class="col">
                        <div class="row"
                            style="font-size: small; text-align: left;">
                            ID in Config: {{data.idInConfig || "No ID"}}
                        </div>
                        <div class="row"
                            style="font-size: small; text-align: left;">
                            {{data.consentOptions}}
                        </div>
                        <div class="row"
                            style="font-size: small; text-align: left;">
                            {{data.region}}
                        </div>
                    </div>
                    </div>
                </ng-template>
                </e-column>
            </e-columns>

          </ejs-grid>
      </div>
  </div>
