import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../services/config/config.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { json_data } from './config-template';
import { InstrumentationService } from '../../../services/instrumentation/instrumentation.service';

@Component({
  selector: 'app-config-management',
  templateUrl: './config-management.component.html',
  styleUrls: ['./config-management.component.css'],
})
export class ConfigManagementComponent implements OnInit {
  public elemContent: HTMLElement;
  public textEditor;
  public disableHtmlEncode;

  configs: any;

  editSettings;
  toolbar;
  pageSettings;
  selectOptions;
  commands;
  codeMirrorOptions: any;
  rowData;
  json = json_data;

  constructor(
    public configService: ConfigService,
    public spinnerService: SpinnerService,
    public notificationService: VaultNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private instrumentationService: InstrumentationService
    ) {
    this.editSettings = {
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: true,
    };

    this.toolbar = ['Add', 'Cancel'];

    this.pageSettings = {
      currentPage: 1,
      pageSize: 50,
      pageCount: 4,
      pageSizes: [50, 75, 100],
    };

    this.selectOptions = { type: 'Single' };

    this.commands = [
      {
        type: 'ConfigPageLinks',
        buttonOption: {
          iconCss: ' icon zmdi zmdi-info',
          cssClass: 'e-flat',
          disabled: false,
        },
      },
      {
        type: 'Edit',
        buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' },
      },
      {
        type: 'Delete',
        buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' },
      },
      {
        type: 'Save',
        buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' },
      },
      {
        type: 'Cancel',
        buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' },
      },
    ];

    this.disableHtmlEncode = false;

    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
    };

    this.rowData = null;
  }

  ngOnInit(): void {
    /* subscribe service here */
    this.loadData();
  }

  loadData() {
    const consentString = (configFile) => {
      let cString = ''
      const gpcoptout = configFile['GPCOptOut']
      const gpcoptin = configFile['GPCOptIn']
      const otoptin = configFile['oneTrustOptIn']
      const otoptout = configFile['oneTrustOptOut']
      if(gpcoptin == null && gpcoptout == null && otoptin == null && otoptout == null){
        return "No Preference"
      }
      if(gpcoptin == true){
        cString += "GPC Opt In, "
      }
      if(gpcoptout == true){
        cString += "GPC Opt Out, "
      }
      if(otoptin == true){
        cString += "OneTrust Opt In, "
      }
      if(otoptout == true){
        cString += "OneTrust Opt Out, "
      }
      return cString.slice(0, -2)
    }

    this.spinnerService.toggle(true);
    this.configService.getAllConfigs(true)
      .subscribe((results:any[]) =>
      {
        console.log('results', results);
        this.configs = results.map((config) =>
        {
          const parsedConfig = JSON.parse(config.config);
          const configurationId  = parsedConfig.configurationId || null;
          const consentOptions  = consentString(parsedConfig) || "Not Set";
          const region = parsedConfig.region || "No Region Set";
          const modules = parsedConfig.modules || [];
          return {...config, config: JSON.stringify(JSON.parse(config.config), null, 2), idInConfig:configurationId, consentOptions:consentOptions, region:region, modules:modules};
        });
      this.spinnerService.toggle();
      });
  }

  actionBegin(args) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.rowData =  Object.assign({}, args.rowData); // needed for code editor 2 way binding
    }
    if (args.action == 'add') {
      if (!this.isJSON(args.data.config)) {
        args.cancel=true;
        return;
      }
      this.spinnerService.toggle(true);
      this.instrumentationService.sendEvent({type: 'Page Runner Config', page: "Create New Config" });
      this.configService.createConfig(args.data).subscribe(
        (response) => {
          if (response.error) {
            this.notificationService.error('Error', response.error);
          } else {
            this.configs[0].id = response.newObjectId;
          }
          this.loadData();
        },
        (error) => {
          console.log('err', error);
        }
      );
    } else if ( args.action == 'edit' ){      
      args.data['config'] = this.rowData['config']; // needed for code editor 2 way binding
      if (!this.isJSON(args.data.config)) {
        args.cancel=true;
        return;
      }
      let changes = this.configService.getChangedProperties(args.rowData, args.data);
      if ( changes ){
        this.spinnerService.toggle(true);
        this.instrumentationService.sendEvent({type: 'Page Runner Config', page: `Updating Config ${args.data.id}` });
        this.configService.updateConfig(args.data.id, changes).subscribe((response)=>{
          this.loadData();
        });
      } else {
        this.notificationService.info("Info", "No changes were detected to save.")
      }
    } else if ( args.requestType == 'delete' ){
      this.spinnerService.toggle(true);
      this.instrumentationService.sendEvent({type: 'Page Runner Config', page: `Deleting Config ${args.data[0].id}` });
      this.configService.deleteConfig(args.data[0].id).subscribe((result)=>{
        this.loadData();
      });
    }
  }

  actionComplete(args) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = args.dialog;
      dialog.width = 700;
      dialog.height = 700;
      if (args.requestType === 'beginEdit') {
        dialog.header = `Edit Record`;
      }
      dialog.refresh(); // needed for code editor
    }
  }

  commandClick(args): void {
    if ( args.commandColumn.type == 'ConfigPageLinks'){
      this.configService.setConfigName(args.rowData.name);
      this.router.navigate(["configPageLinks", args.rowData.id], {relativeTo: this.route});
    }
  }

  private isJSON(json: string): boolean {
    try {
      (JSON.parse(json));
    } catch (e) {
      this.notificationService.error("Improper JSON Format", e.message);
      return false;
    }
    return true;
  }
}
