<div class="card card-default">
    <div class="card-header">Violation Summary (Detailed)</div>
    <div class="card-body">
        <ejs-grid #ledGrid id="ledGrid" [dataSource]="ledGridData" [allowPaging]="false" [allowSorting]="true"
            [sortSettings]="sortSettings" [allowSelection]="false" [enableHover]="false" locale="en-US"
            [allowResizing]="true" [allowTextWrap]="true" (actionComplete)="refreshGraphs($event)"
            (dataBound)="refreshGraphs($event)">
            <e-columns>
                <e-column field="type" textAlign="right" headerText="" width="20%" headerTextAlign="right">
                    <ng-template #template let-data>
                        <div class="row">
                            <div class="col" style="font-size: large; font-weight: bold; padding-bottom: 5px;">
                                {{ data.label }}
                            </div>
                        </div>
                    </ng-template>
                </e-column>
                <e-column field="percent" headerText="Total" headerTextAlign="Center" width="20%" textAlign="center">
                    <ng-template #template let-data>
                        <div class="row">
                            <div class="col" style="font-size: x-large; font-weight: bold; padding-bottom: 5px;">
                                {{ data.percent }}%
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[0]"
                                    [attr.values]="data.history"></span>
                            </div>
                        </div>
                    </ng-template>
                </e-column>
                <e-column *ngFor="let group of groupList; let i = index; trackBy: trackByFn" [field]="group.label"
                    [headerText]="labelmapping[group.label] || group.label" headerTextAlign="Center" width="20%"
                    textAlign="center">
                    <ng-template #template let-data>
                        <div class="row">
                            <div class="col">
                                <div class="row" *ngIf="!data[group.key].isNA">
                                    <div class="col">
                                        <div class="grid-container-element">
                                            <div *ngFor="let led of data.leds[i]; let j = index; trackBy: trackByFn"
                                                class="grid-child-element"
                                                [class.red]="led.percent >= data.ledCutoff[1]"
                                                [class.orange]="led.percent < data.ledCutoff[1] && led.percent >= data.ledCutoff[0]"
                                                [class.green]="led.percent < data.ledCutoff[0]" data-container="body"
                                                data-toggle="popover" data-trigger="hover" data-placement="top"
                                                data-html="true"
                                                [attr.data-content]="'Site: ' + led.siteName + '<br/>Pages w/ Violation: ' + led.percent.toFixed(2) + '%'">
                                                &nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col" style="font-size: x-large; font-weight: bold; padding-bottom: 5px;"
                                        [style.color]="data[group.key].isNA ? 'lightgrey': 'black'">
                                        <ng-container *ngIf="!data[group.key].isNA else isNA">{{ data[group.key].percent
                                            }}%</ng-container>
                                        <ng-template #isNA>N/A</ng-template>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!data[group.key].isNA">
                                    <div class="col">
                                        <span class="sparklinebasic" [attr.sparkLineColor]="data.colors[i+1]"
                                            [attr.values]="data[group.key].history"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>

    </div>
    <ng-template #stillLoading>
        <div class="card-body">Loading...</div>
    </ng-template>
</div>