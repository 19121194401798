import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { ViolationDetailedSummaryByRisk } from '../../dashboardDS';
import { setTime } from '@syncfusion/ej2-angular-schedule';
declare var $: any;
// TODO - Change the popup html for better styling
@Component({
  selector: 'advanced-violation-risk-summary-panel',
  templateUrl: './advanced-violation-risk-summary-panel.component.html',
  styleUrls: ['./advanced-violation-risk-summary-panel.component.css'],
})
export class AdvancedViolationRiskSummaryPanelComponent
  implements OnChanges
{
  @Input('datasource') datasource: ViolationDetailedSummaryByRisk[];
  @Input('labelmapping') labelmapping: any = {};
  public sortSettings: any;
  public groupList: Object[] = [];
  public isLoaded: boolean;
  public notificationOptions: any = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
  };
  constructor(
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private notificationService: NotificationsService,
    private cd: ChangeDetectorRef
  ) {
    console.log('AdvancedViolationRiskSummaryPanelComponent constructor');
    try {
      this.isLoaded = false;

      this.sortSettings = {
        columns: [],
      };
    } catch (e) {
      this.notificationService.success('Error Building Detailed Summary', this.notificationOptions);
    }
  }

  ngOnChanges(): void {
    this.ledGridData = this.formatData(this.datasource); 
  }
  trackByFn(index: number, item: any): any {
    return item.someUniqueIdentifier;
  }

  public ledGridData: any;
  public createSparkLineData = (value: any) => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      const newVal = value * (0.1 * i) + Math.random() * 0.05;
      if (newVal > value) {
        data.push(value.toFixed(2));
      } else {
        data.push(newVal.toFixed(2));
      }
    }
    data.push(value);
    return data;
  };

  refreshGraphs(event) {
    setTimeout(() => {
      $('.sparklinebasic').sparkline('html', {
        highlightSpotColor: 'black',
        highlightLineColor: 'black',
        fillColor: false,
        spotColor: false,
        minSpotColor: false,
        maxSpotColor: false,
        lineWidth: 1,
        enableTagOptions: true,
      });

      $('.grid-child-element').popover('enable');
    }, 0);
  }

  // public initData: ViolationDetailedSummaryByRisk[] = [
  //   {
  //     type: 'NULLA',
  //     label: 'NULL',
  //     percent: 0,
  //     percent_history: [],
  //     groupData: [
  //       {
  //         groupName: 'NULLB',
  //         percent: 0,
  //         percent_history: [],
  //         siteData: [
  //           {
  //             siteName: 'NULLD',
  //             percent: 0,
  //           },
  //         ],
  //       },
  //       {
  //         groupName: 'NULLC',
  //         percent: 0,
  //         percent_history: [],
  //         siteData: [
  //           {
  //             siteName: 'NULLE',
  //             percent: 0,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  // public init2: ViolationDetailedSummaryByRisk[] = [
  //   {
  //     type: 'Total',
  //     label: 'Total',
  //     percent: 26.060138781804167,
  //     percent_history: [
  //       0, 0, 0, 0, 0, 0, 26.060138781804167, 26.060138781804167,
  //       26.060138781804167, 26.060138781804167, 26.060138781804167,
  //     ],
  //     groupData: [
  //       {
  //         groupName: 'Sports',
  //         percent: 11.068702290076336,
  //         percent_history: [
  //           0, 0, 0, 0, 0, 0, 11.068702290076336, 11.068702290076336,
  //           11.068702290076336, 11.068702290076336, 11.068702290076336,
  //         ],
  //         siteData: [
  //           {
  //             siteName: 'ESPN',
  //             percent: 11.068702290076336,
  //           },
  //         ],
  //       },
  //       {
  //         groupName: 'News',
  //         percent: 26.764705882352942,
  //         percent_history: [
  //           0, 0, 0, 0, 0, 0, 26.764705882352942, 26.764705882352942,
  //           26.764705882352942, 26.764705882352942, 26.764705882352942,
  //         ],
  //         siteData: [
  //           {
  //             siteName: 'ABC-News',
  //             percent: 26.764705882352942,
  //           },
  //         ],
  //       },
  //       {
  //         groupName: 'Branded-Entertainment',
  //         percent: 31.366906474820144,
  //         percent_history: [
  //           0, 0, 0, 0, 0, 0, 31.366906474820144, 31.366906474820144,
  //           31.366906474820144, 31.366906474820144, 31.366906474820144,
  //         ],
  //         siteData: [
  //           {
  //             siteName: 'NationalGeographic',
  //             percent: 29.19254658385093,
  //           },
  //           {
  //             siteName: 'Disney',
  //             percent: 19.101123595505616,
  //           },
  //         ],
  //       },
  //       {
  //         groupName: 'Branded-22222',
  //         percent: 31.366906474820144,
  //         percent_history: [
  //           0, 0, 0, 0, 0, 0, 31.366906474820144, 31.366906474820144,
  //           31.366906474820144, 31.366906474820144, 31.366906474820144,
  //         ],
  //         siteData: [
  //           {
  //             siteName: 'NationalGeographic',
  //             percent: 29.19254658385093,
  //           },
  //           {
  //             siteName: 'Disney',
  //             percent: 19.101123595505616,
  //           },
  //         ],
  //       }
  //     ],
  //   },
  // ];

  public colorCutoff = (value: any, cutoffA: any, cutoffB: any) => {
    if (value < cutoffA) {
      return 'green';
    } else if (value < cutoffB) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  public formatData = (data: ViolationDetailedSummaryByRisk[]) => {
    if (!data) {
      return [];
    }
    return data.map((row: any) => {
      const output = {};
      output['type'] = row.type;
      output['label'] = row.label;
      output['percent'] = row.percent.toFixed(2);
      output['history'] = row.percent_history;
      this.groupList = [];
      row.groupData.forEach((group) => {
        output[group.groupName.toLowerCase()] = {};
        output[group.groupName.toLowerCase()].percent = group.percent
          .toFixed(2)
          .replace(/\.?0+$/, '');
        output[group.groupName.toLowerCase()].history = group.percent_history;
        // output[group.groupName.toLowerCase()].checked =
        //   group.percent != 0 &&
        //   group.siteData.filter((site) => site.percent == 0).length == 0;
        output[group.groupName.toLowerCase()].isNA = group.isNA;
        this.groupList.push({
          key: group.groupName.toLowerCase(),
          label: group.groupName.replace(/(?<!^)(?=[A-Z][a-z])/g, ' '),
        });
      });
      output['leds'] = row.groupData.map((group) => {
        return group.siteData;
      });
      output['ledCutoff'] = [5, 50, 100];
      output['colors'] = [
        this.colorCutoff(row.percent, 5, 50),
        ...row.groupData.map((item) => {
          return this.colorCutoff(item.percent, 5, 50);
        }),
      ];
      return output;
    });
    this.isLoaded = true;
  };
}
