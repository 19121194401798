import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommandModel, DataSourceChangedEventArgs, DataStateChangeEventArgs, GridComponent, PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { NotificationsService } from 'angular2-notifications';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { PageService } from '../../../services/page/page.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../services/timezone.service';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { PageAdaptor } from './PageAdaptor';
import { environment as env } from "../../../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy {

  pages: any[];
  pagesLoading: boolean;
  tooltipContent: string;
  exportId: string;
  pollingTimer: any;
  notificationOptions: any;
  exportButtonDisabled: boolean;
  pollingTimerCounter: number;
  editor: any;
  pollingTime: number;
  bulkUpdateForm: FormGroup;
  newUrlForm: FormGroup;
  parsedPageLinks: any[];

  public pageSettings: PageSettingsModel;
  public sortSettings: any;
  public selectionSettings: SelectionSettingsModel;
  public cdnUrl: string;
  @ViewChild('grid') public grid: GridComponent;
  public data: Observable<DataStateChangeEventArgs>;
  public state: DataStateChangeEventArgs;
  public pageOptions: Object;
  public toolbar: string[];
  public filterSettings: any;
  public locale: any;
  public editSettings: Object;
  public frequencyEditRules: Object;
  public editparams: Object;
  public dateFormat: any;
  public commands: CommandModel[];
  public newPageLinkCommands: CommandModel[];
  public newPageEditSettings: Object;

  public pagesDataManager: DataManager;

  public formatOptions: object;

  private filterSearch = false;


  constructor(private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private dataExportService: DataExportService,
    private notificationService: NotificationsService,
    private spinnerService: SpinnerService,
    public authService: AuthService,
    private _fb: FormBuilder,
    private vendorService: VendorsService,
    private timezoneService: TimezoneService,
    private pageService: PageService
  ) {

    this.formatOptions = { type: 'dateTime', format: 'dd/MM/yyyy hh:mm a' };

    this.pagesDataManager = new DataManager({
      url: `${env.api.url}/pagesv2`,
      adaptor: new PageAdaptor(this.timezoneService)
    });

    this.pollingTime = 1000;
    this.sitemapService.update(this.route);
    // this.spinnerService.toggle(true);
    this.tooltipContent = "Browse or search for pages Vault JS has discovered on your domain(s)";
    this.notificationOptions = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    };

    this.bulkUpdateForm = this._fb.group({
      maxAgePageRunnerInMinutes: [''],
      maxAgeLinkDiscoveryInMinutes: ['']
    });

    this.newUrlForm = this._fb.group({
      link: ['']
    });

    this.filterSettings = {
      type: 'Excel',
      columns: [
        { field: 'maxAgePageRunnerInMinutes', operator: 'lessthan', value: 4294967295 }
      ]
    };

    this.sortSettings = {
      columns: [
        { field: 'lastCheckedPageRunner', direction: 'descending' }
      ]
    };

    this.frequencyEditRules = { number: true };
    this.editSettings = { allowEditing: false, mode: 'Normal' };
    this.editparams = { params: { popupHeight: '300px' } };
    this.toolbar = ['Update', 'Cancel'];
    this.dateFormat = { type: 'dateTime', skeleton: 'short' };

    this.pageOptions = { pageSize: 25, pageCount: 5, pageSizes: [25, 50, 75, 100, 200] };

    this.commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
    { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];

    this.exportButtonDisabled = false;
    this.parsedPageLinks = [];
    this.newPageLinkCommands = [
      { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
    ];
    this.newPageEditSettings = { allowEditing: true, mode: 'Normal' };

  }

  ngAfterViewInit() {
    $('[data-toggle="popover"]').popover();
  }

  ngOnInit() {

  }

  exportData() {
    this.exportButtonDisabled = true;
    this.dataExportService.startDataExport(DataExportService.PAGES).subscribe((result) => {
      this.notificationService.success('Success!', 'Your export was requested successfully. The file will download automatically once it has been generated. Please remain on this page until then.', this.notificationOptions);
      console.log(result);
      this.exportId = result.exportId;
      this.pollingTimerCounter = 0;
      this.pollingTime = 1000;
      this.startPolling();
    },
      (error) => {
        this.exportButtonDisabled = false;
        console.log(error);
        this.notificationService.success('Oops!', 'Your export request was not successful. Please contact support.', this.notificationOptions);
      });
  }

  handlePollingError(error) {
    console.log(error);
    if (this.pollingTimerCounter < 10) {
      this.startPolling();
      this.pollingTimerCounter++;
      this.pollingTime += 1000; // add 1s each time so it gives more time for file to show up...
    } else {
      clearTimeout(this.pollingTimer);
      this.notificationService.success('Hmmm...', 'Your export seems to be taking longer than expected. Please contact support.', this.notificationOptions);
    }
  }

  startPolling() {
    this.pollingTimer = setTimeout(() => {
      console.log(new Date() + " Checking for file...");

      const filePath = `${localStorage.cid}/${this.exportId}/${DataExportService.PAGES}.${DataExportService.CSV_EXTENSION}`;
      this.dataExportService.getDataExportFile(filePath).subscribe((data) => {

        console.log(data);

        if (data && data.message && data.message == 'Access Denied') {
          this.handlePollingError(data);
        } else {
          this.exportButtonDisabled = false;

          let fileName = "Vault JS Pages " + new Date().toLocaleString() + ".csv";
          this.dataExportService.saveFile(fileName, data.toString());

          clearTimeout(this.pollingTimer);
        }


      },
        (error) => {
          this.handlePollingError(error);
        });
    }, this.pollingTime);
  }

  ngOnDestroy() {
    if (this.pollingTimer) {
      clearTimeout(this.pollingTimer);
    }
  }

  bulkUpdate() {

    $('#modal-bulk-update').modal('hide');

    var filters = this.grid.filterSettings["properties"]["columns"].map((predicate) => {
      var obj = {};
      if (predicate && predicate.properties) {
        obj[predicate.properties.field] = predicate.properties.value;
      } else {
        obj = null;
      }
      return obj;
    }).filter((i) => { return i != null });

    var props = [];
    if (this.bulkUpdateForm.value.maxAgePageRunnerInMinutes && this.bulkUpdateForm.value.maxAgePageRunnerInMinutes != '') {
      props.push({ name: 'maxAgePageRunnerInMinutes', value: this.bulkUpdateForm.value.maxAgePageRunnerInMinutes });
    }
    if (this.bulkUpdateForm.value.maxAgeLinkDiscoveryInMinutes && this.bulkUpdateForm.value.maxAgeLinkDiscoveryInMinutes != '') {
      props.push({ name: 'maxAgeLinkDiscoveryInMinutes', value: this.bulkUpdateForm.value.maxAgeLinkDiscoveryInMinutes });
    }

    var params = { type: 'page', properties: props, filters: filters };
    console.log(params);

    this.spinnerService.toggle(true);
    this.vendorService.bulkUpdate(params).subscribe((response) => {
      console.log(response);
      this.spinnerService.toggle(false);
      this.notificationService.success('Success!', `${response.numberOfRecordsUpdated} entries were updated successfully.`, this.notificationOptions);
      this.grid.refresh();
    },
      (error) => {
        console.log(error);
        this.spinnerService.toggle(false);
        this.notificationService.success('Oops!', 'Bulk update was not successful. Please contact support.', this.notificationOptions);
      });
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);
  }

  public dataSourceChanged(state: DataSourceChangedEventArgs): void {
    console.log('dataSourceChanged');
    console.log(state);
  }

  public actionBegin(args) {
    console.log('actionBegin');
    console.log(args);
    if (args.requestType === "filtersearchbegin") {
      this.filterSearch = true;
      (this.pagesDataManager.adaptor as PageAdaptor).isFilterSearch = true;
    }
    // if (args.requestType === "filterchoicerequest") {
    //   args.filterChoiceCount = 20;
    //   args.filterModel.dialogObj.contentEle.classList.add('e-hide');
    //   args.filterModel.dialogObj.element.querySelector('.e-footer-content').classList.add('e-hide');
    // } else if (args.requestType === 'save') {
    //   console.log('XXXXXXXX');
    // }
  }

  actionComplete(args) {
    console.log('actionComplete');
    console.log(args);

    if (args.name == "actionComplete" && args.requestType == "filterchoicerequest") {
      (this.pagesDataManager.adaptor as PageAdaptor).isFilterSearch = false;
    }
  }

  public batchSave(event) {
    console.log('batchSave');
    console.log(event);
  }

  newPageCommandClick(event) {
    console.log("newPageCommandClick", event);
    if (event.commandColumn.type == 'Delete') {
      console.log('DELETE');
      this.parsedPageLinks = this.parsedPageLinks
        .filter((link) => link.link != event.rowData.link);
    } else if (event.commandColumn.type == 'Edit') {
      console.log('EDIT');
      this.parsedPageLinks = this.parsedPageLinks
        .map((link) => {
          if (link.link == event.rowData.link) {
            link.link = true;
          }
          return link;
        });
    }
  }

  newPageActionComplete(event) {
    console.log("NEW PAGE ACTION COMPLETE", event);
    if (event.requestType == 'save' && event.type == "actionComplete") {
      console.log("SAVE")
      const oldData = event.rowData;
      const newData = event.data;
      if (oldData.link == newData.link) {
        return;
      }
      const updateLink = this.parsedPageLinks.find((link) => link.link == oldData.link);
      updateLink.link = newData.link;
      console.log("updateLink", updateLink);
      this.refreshEditedLinks();
    }
  }

  addPageLinks() {
    $('#modal-new').modal('hide');
    this.spinnerService.toggle(true);
    if (this.parsedPageLinks.length == 0) {
      this.notificationService.error('Oops!', 'Please enter links.', this.notificationOptions);
      return;
    }
    forkJoin(this.parsedPageLinks.map((link) => {
      return this.pageService.createLink(link.link);
    })).subscribe((response) => {
      console.log(response);
      this.notificationService.success('Success!', 'Page(s) added successfully.', this.notificationOptions);
      this.spinnerService.toggle(false);
      this.grid.refresh();
    },
      (error) => {
        this.notificationService.error('Oops!', 'Something went wrong.', this.notificationOptions);
        this.spinnerService.toggle(false);
      });
  }

  async findLinkInDB(url: string) {
    const temp = await this.pageService
      .getPages(0, 5, 'link', 'asc', 'link:' + url, 1)
      .toPromise();
    console.log("temp", temp);
    if (temp.data.length > 0) {
      const findUrl = temp.data.find((link) => link.link === url);
      if (findUrl !== undefined) {
        return true;
      }
    }
    return false;
  }

  refreshEditedLinks() {
    const parsedLinks = this.parsedPageLinks.map(async (link) => {
      return this.parsedPageMap(link.link);
    });
    this.spinnerService.toggle(true);
    this.promiseAllPageLinks(parsedLinks);
  }

  async parsedPageMap(link) {
    const isLinkInDB = await this.findLinkInDB(link);
    let isParseURL = false;
    try {
      isParseURL = !!(new URL(link));
    } catch (e) {
      isParseURL = false;
      console.error("Malformed URL:", link);
    }
    return {
      link: link,
      isParseURL: isParseURL,
      alreadyInDB: isLinkInDB,
    };
  }

  promiseAllPageLinks(parsedLinks) {
    Promise.all(parsedLinks)
      .then((results) => {
        this.parsedPageLinks = results;
        console.log("here is the parsed links", this.parsedPageLinks);
        this.spinnerService.toggle(false);
      })
      .catch((err) => {
        this.spinnerService.toggle(false);
        this.notificationService.error('Error', 'Something went wrong');
      });
  }

  parsePageLinksFromText(): void {
    const text = this.newUrlForm.value.link;

    let pageLinks = text.split('\n');
    if (pageLinks.length === 1 && pageLinks[0] === '') {
      this.parsedPageLinks = [];
      return;
    }
    // dedup links
    pageLinks = pageLinks.filter((link, index) => {
      return pageLinks.indexOf(link) === index;
    });
    const parsedLinks = pageLinks.filter((link) => link !== "").map(async (link) => {
      return this.parsedPageMap(link);
    });
    this.spinnerService.toggle(true);
    this.promiseAllPageLinks(parsedLinks);
  }

}
