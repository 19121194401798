<div class="card" style="display: flex; flex-direction: row; flex-wrap: nowrap; height: 425px;">
    <div class="card-lhs" style="padding: 0; margin: 0;">
        <div class="card-header" style=" font-size: large; width: fit-content; ">{{title}}</div>
        <div style="padding: 0; margin:auto; border: 0px solid red; width: fit-content; padding-bottom: 20%; padding-top:50%;">
            <div style="text-align: center; margin:0px; padding:0px; font-size: x-large;">{{value}}<span style="font-size: medium; margin-left: 5px">of</span></div>
            <div
                style=" text-align: center;  margin:auto; padding: auto; font-size: x-large; width: fit-content; color: grey">
                {{total}}<span style="font-size: medium; margin-left: 5px">total {{extraLabel}}</span></div>
        </div>

    </div>
    <div class="card-rhs" style="border: 0px solid red; position: relative; width: 300px; margin: auto; flex-shrink: 1;">
        <div style=" position:absolute; padding: 0px; margin:auto; top:0; bottom:0; height: fit-content; width: fit-content; left: 0; right: 0; font-size: x-large; font-weight: bold;">
            {{percent}}%</div>
        <ejs-accumulationchart #pie class="acc" [legendSettings]="legendSettings" (load)="updateData"
            [tooltip]="pieTooltip"  selectionMode="Point" [center]="pieCenter" background="transparent">
            <e-accumulation-series-collection>
                <e-accumulation-series [name]="'toolTipLabel'" [dataSource]="data" xName="x" yName="y" radius="100%" enableAnimation="true" class="series" innerRadius="80%"
                    [palettes]="palette">
                </e-accumulation-series>
            </e-accumulation-series-collection>
        </ejs-accumulationchart>
        
    </div>
</div>