import { Injectable } from '@angular/core';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';

@Injectable({
  providedIn: 'root'
})
export class VaultBaseUrlAdaptor extends UrlAdaptor {

  constructor() {
    super();
  }

  override beforeSend(dm: DataManager, request: XMLHttpRequest): void {
    console.log('beforeSend');
    // console.log(this.addParams({
    //   dm,
    //   query: new Query("blah"),
    //   params: null,
    //   reqParams: {
    //       "test": "test",
    //   }
    // }));
    console.log(dm, request);

    request.setRequestHeader("authorization", `Bearer ${localStorage.getItem('access_token')}`);
    request.setRequestHeader("cid", localStorage.getItem("cid"));

    super.beforeSend(dm, request);
  }

}
