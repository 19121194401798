import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import * as AWS from 'aws-sdk';
import { AuthService } from '../auth/auth.service';
import { scheduled, asapScheduler, Observable } from 'rxjs';
import { VaultBaseService } from '../VaultBaseService';
import { HttpClient } from '@angular/common/http';
import { Papa } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root',
})
export class QueriedReportingService extends VaultBaseService {
  private customerId: string;

  constructor(
    authHttp: HttpClient,
    private authService: AuthService,
    private papa: Papa
  ) {
    super(authHttp);
    this.customerId = this.authService.customerId();
  }

  getDataByPageUrl(
    reportkey: string,
    url: string,
    overrideColumnKey: string = null
  ): Observable<any> {
    const query = `SELECT * FROM S3Object s WHERE ${
      overrideColumnKey || 'pageUrl'
    } = '${url}'`;
    return this.getReportData(reportkey, query);
  }

  getReportData(reportkey: string, query: string): Observable<any> {
    console.log('Querying report data for:', reportkey);
    console.log('Query:', query);

    const params = this.createSelectObjectContentRequest(
      reportkey,
      query,
      'CSV'
    );
    const headerParams = this.createSelectObjectContentRequest(
      reportkey,
      `SELECT * FROM S3Object s LIMIT 1`,
      'CSV',
      false
    );

    const retPromise = new Promise((resolve, reject) => {
      this.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const s3 = new AWS.S3();

        this.getHeader(s3, headerParams)
          .then((header) => {
            console.log('Header:', header);
            return this.getRecords(s3, params, header);
          })
          .then((output) => {
            // console.log('Output:', output);
            this.papa.parse(output, {
              header: true,
              skipEmptyLines: true,
              dynamicTyping: true,
              quoteChar: '"',
              newline: '\n',
              transform: (value) => value.trim(),
              transformHeader: (header) => header.trim(),
              complete: function (results) {
                // console.log('Parsed:', results);
                resolve(
                  results.data.map((row) => {
                    if(typeof row.dataValue === 'string') row.dataValue = row.dataValue.trim();
                    if(row.dataValue === "TRUE") row.dataValue = true;
                    if(row.dataValue === "FALSE") row.dataValue = false;
                    if(row.dataValue === "null") row.dataValue = null;
                    return row;
                  })
                );
              },
            });
          })
          .catch((error) => {
            // console.error(error);
            reject(error);
          });
      });
    });

    return scheduled(retPromise, asapScheduler);
  }

  private createSelectObjectContentRequest(
    reportkey: string,
    query: string,
    format: string,
    useHeader: boolean = true
  ): AWS.S3.Types.SelectObjectContentRequest {
    return {
      Bucket: `${env.aws.customReportBucket}-${this.customerId}`,
      Key: reportkey,
      ExpressionType: 'SQL',
      Expression: query,
      InputSerialization: {
        CSV: {
          AllowQuotedRecordDelimiter: true,
          FileHeaderInfo: useHeader ? 'USE' : 'NONE',
          RecordDelimiter: '\n',
          FieldDelimiter: ',',
        },
        CompressionType: reportkey && reportkey?.endsWith('.gz') ? 'GZIP' : 'NONE',
      },
      OutputSerialization: {
        [format]: {},
      },
      RequestProgress: {
        Enabled: true,
      },
    };
  }

  private getHeader(
    s3: AWS.S3,
    params: AWS.S3.Types.SelectObjectContentRequest
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      s3.selectObjectContent(params, (err, data) => {
        if (err) {
          return reject(err);
        }
        const events: any =
          data.Payload as AWS.S3.SelectObjectContentEventStream;
        let header = '';

        for (const event of events) {
          if (event.Records) {
            header += event.Records.Payload.toString();
          } else if (event.End) {
            resolve(header);
          }
        }
      });
    });
  }

  private getRecords(
    s3: AWS.S3,
    params: AWS.S3.Types.SelectObjectContentRequest,
    header: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      s3.selectObjectContent(params, (err, data) => {
        if (err) {
          return reject(err);
        }
        const events: any =
          data.Payload as AWS.S3.SelectObjectContentEventStream;
        let output = header; // Include header in the output

        for (const event of events) {
          if (event.Records) {
            output += event.Records.Payload.toString();
          } else if (event.Stats) {
            // console.log('Stats:', event.Stats.Details);
          } else if (event.End) {
            resolve(output);
          }
        }
      });
    });
  }

  getUniqueValues(reportkey: string, column: string): Observable<any> {
    // Modified query to select distinct values of the specified column
    const query = `SELECT ${column} FROM S3Object s`;
    const params = this.createSelectObjectContentRequest(
      reportkey,
      query,
      'CSV'
    );

    const retPromise = new Promise((resolve, reject) => {
      this.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const s3 = new AWS.S3();

        this.getRecords(s3, params, '')
          .then((output) => {
            // console.log('Output:', output);
            const out = Array.from(new Set(output.split('\n'))).filter(
              (val) => val !== ''
            );
            resolve(out);
          })
          .catch((error) => {
            // console.error(error);
            reject(error);
          });
      });
    });

    return scheduled(retPromise, asapScheduler);
  }
}
