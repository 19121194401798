<div class="row">
  <div class="col-md-12">
    <div class="card be-loading">
      <div class="card-header card-header-divider">{{headerText}}<span class="card-subtitle">{{headerSubText}}</span></div>
      <div class="card-body">
        <form [formGroup]="pageRunnerScriptForm" (ngSubmit)="onSubmit()">  
          <!-- <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="The name of the script."></span></label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" type="text" formControlName="name">
            </div>
          </div> -->
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Enabled:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="If script is disabled, it will not run."></span></label>
            <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
              <label class="custom-control custom-checkbox custom-control-inline">
                <input class="custom-control-input" type="checkbox" [formControl]="pageRunnerScriptForm.controls['enabled']"><span class="custom-control-label"></span>
              </label>
            </div>
          </div>
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Schedule:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Cron schedule for when script will run."></span></label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" type="text" formControlName="cronSchedule">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right" for="scriptFile">Script File:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Javscript file to run."></span></label>
            <div class="col-12 col-sm-6">
              <input #scriptFile class="inputfile" id="scriptFile" type="file" name="scriptFile" data-multiple-caption="{count} files selected" multiple (change)="onFileChange($event)">
              <label class="btn-secondary" for="scriptFile"> <i class="mdi mdi-upload"></i><span>{{scriptButtonText}}</span></label>
            </div>
          </div>
          <div class="form-group row" *ngIf="isExisting == true">
            <label class="col-12 col-sm-3 col-form-label text-sm-right" for="scriptFile">Current Script: <span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Javscript file to run."></span></label>
            <div class="col-12 col-sm-6">
              <ngx-codemirror #consoleView formControlName="fileContent" [options]="codeMirrorOptions"></ngx-codemirror>
            </div>
          </div>          
          <div style="text-align: center">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" (click)="cancelEdit();">Cancel</button>
            <button *ngIf="btnText == 'Update'" class="btn btn-rounded btn-space btn-danger" data-toggle="modal" data-target="#mod-confirm-delete" type="button">Delete</button>
            <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!pageRunnerScriptForm.valid">{{btnText}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mod-confirm-delete" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
          <h3>Warning!</h3>
          <p>Are you sure you want to delete this script? You cannot undo this action!</p>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="onDeleteClick();">Proceed</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
