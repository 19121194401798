<div class="row">
    <div class="col-md-12">
        <div class="card be-loading">
            <div class="card-header card-header-divider">My Details<span class="card-subtitle">Edit your account details</span></div>
            <div class="card-body">
                <form [formGroup]="userDetailsForm" (ngSubmit)="onSubmit()">
                    <div class="form-group row pt-1">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">Reports Time Zone:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                            <select class="form-control" formControlName="timezone" name="timezone">
                                <option selected disabled>Select a time zone</option>
                                <option value="EST/EDT">EST/EDT</option>
                                <option value="CST/CDT">CST/CDT</option>
                                <option value="MST/MDT">MST/MDT</option>
                                <option value="MST">MST</option>
                                <option value="PST/PDT">PST/PDT</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row pt-1">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">Default to UTC:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                            <label class="custom-control custom-checkbox custom-control-inline">
                            <input class="custom-control-input" type="checkbox" name="isUtcDefault" formControlName="isUtcDefault"><span class="custom-control-label"></span>
                            </label>
                        </div>
                    </div>                    
                    <div class="form-group row pt-1">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">Date Format:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                            <input class="form-control" type="text" formControlName="uiDateFormat">
                        </div>
                    </div>
                    <div class="form-group row pt-1">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">Password:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                            <input class="form-control" type="password" formControlName="password">
                        </div>
                    </div>
                    <div class="form-group row pt-1">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">Re-type Password:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                            <input class="form-control" type="password" formControlName="retypedPassword">
                        </div>
                    </div>                              
                    <div style="text-align: center">
                        <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!userDetailsForm.valid">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>