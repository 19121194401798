import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'region'
})
export class RegionPipe implements PipeTransform {
  private regions = [
    {
      fileCode: "USW1",
      regionCode: "us-west-1",
      regionName: "US West (N. California)"
    },
    {
      fileCode: "USE1",
      regionCode: "us-east-1",
      regionName: "US East (N. Virginia)"
    },
    {
      fileCode: "USW2",
      regionCode: "us-west-2",
      regionName: "US West (Oregon)"
    },
    {
      fileCode: "USE2",
      regionCode: "us-east-2",
      regionName: "US East (Ohio)"
    },
    {
      fileCode: "CAC1",
      regionCode: "ca-central-1",
      regionName: "Canada (Montreal)"
    },
    {
      fileCode: "CAW1",
      regionCode: "ca-west-1",
      regionName: "Canada (Calgary)"
    },
    {
      fileCode: "EUW1",
      regionCode: "eu-west-1",
      regionName: "EU (Ireland)"
    },
    {
      fileCode: "EUW2",
      regionCode: "eu-west-2",
      regionName: "EU (London)"
    },
    {
      fileCode: "EUW3",
      regionCode: "eu-west-3",
      regionName: "EU (Paris)"
    },
    {
      fileCode: "EUC1",
      regionCode: "eu-central-1",
      regionName: "EU (Frankfurt)"
    },
    {
      fileCode: "EUN1",
      regionCode: "eu-north-1",
      regionName: "EU (Stockholm)"
    },
    {
      fileCode: "EUS1",
      regionCode: "eu-south-1",
      regionName: "EU (Milan)"
    },
    {
      fileCode: "EUCC1",
      regionCode: "eu-central-2",
      regionName: "EU (Zurich)"
    },
    {
      fileCode: "EUSC1",
      regionCode: "eu-south-central-1",
      regionName: "EU (Madrid)"
    }
  ];
  transform(value: string, ...args: unknown[]): unknown {
    const region = this.regions.find(region => region.fileCode === value || region.regionCode === value);
    return region ? region.regionName : value;
  }

}
