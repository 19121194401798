import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../../services/auth/auth.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { Vendor } from '../../../models/Vendor';
import { DataStateChangeEventArgs, Grid, GridComponent, PageSettingsModel, Resize, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TimezoneService } from '../../../services/timezone.service';
import { config, Observable } from 'rxjs';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { EventRuleService } from '../../../services/eventRule/event-rule.service';
declare var $:any;

@Component({
  selector: 'app-event-output',
  templateUrl: './event-output.component.html',
  styleUrls: ['./event-output.component.css']
})
export class EventOutputComponent implements OnInit {

  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('grid') public grid: GridComponent;

  exportId:string;
  notificationOptions:any;
  exportButtonDisabled:boolean;

  selectedItem:any;
  newItemForm:FormGroup;

  codeMirrorOptions:any;
  // @ViewChild('configEditor') codeEditor:CodemirrorComponent;
  
  vendors:Vendor[];
  destinations:any[];

  items$:Observable<any>;

  items:any[];

  showToDoMessage:boolean;
  todoMessage;

  selectedType;

  constructor(
    private route:ActivatedRoute,
    private sitemapService:SitemapService,
    private notificationService: NotificationsService,
    private zone:NgZone,
    public authService:AuthService,
    private eventRuleService:EventRuleService,
    public _fb:FormBuilder,
    public vendorService:VendorsService,
    public timezoneService:TimezoneService,
    public spinnerService:SpinnerService
    ) {       
      this.sitemapService.update(this.route);

      this.showToDoMessage = false;

      this.notificationOptions = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };

      this.dateFormat = {type: 'dateTime', skeleton:'short'}
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 20, 
        pageCount: 4, 
        pageSizes: [20, 25, 50]
      };
      this.toolbar = ['Search'];
      this.sortSettings = {
        columns: [
          { field: 'name', direction: 'Ascending'}
        ]
      };

      Grid.Inject(Resize);
      
      this.exportButtonDisabled = false;

      this.newItemForm = this._fb.group({
        id: -1,
        name: '',
        destinationTypeId: -1,
        destinationConfig: '',
        recipients: this._fb.array([]),
        webhook: this._fb.group({
          url: '',
          headers: this._fb.array([
            this._fb.group({
              key: '', value: ''
            })
          ]),
          data: '',
          method: ''
        })
      });

      this.codeMirrorOptions = {
        lineNumbers: true,
        mode: 'javascript',
      };

      this.loadData();
  }

  loadData(){
    this.spinnerService.toggle(true);
    this.eventRuleService.getEventOutputs().subscribe((results)=>{

      this.items = results.result;
      this.destinations = results.destinations;
      this.spinnerService.toggle();
    },
    (error)=>{
      this.spinnerService.toggle();
    });
  }

  destinationIdToName(id){
    let found = this.destinations.filter((d)=>{return d.id == id});
    if ( found && found.length > 0 ){
      return found[0].name;
    }
    return "???";
  }

  create(){
    $('#modal-create-edit').modal('hide');
    
    console.log("create");
   
    
    this.spinnerService.toggle(true);
    
    let config = "";
    let destId = this.selectedItem ? this.selectedItem.destinationTypeId : this.selectedType;
    if ( destId == 1 ){
      let updatedRecipients =  this.newItemForm.value.recipients.filter((r)=>{ if (r.email == "" && r.name == "" && r.timeToSend == "") return false; return true;});
      updatedRecipients = {recipients: updatedRecipients}
      config = JSON.stringify(updatedRecipients);
    } else if ( destId == 3) {
      let updatedWebhook = this.newItemForm.value.webhook;
      config = JSON.stringify(updatedWebhook);
    }
    
    console.log(config);
    
    if ( this.newItemForm.value.id == -1 ) {
      // let newOutput = {...this.newItemForm.value, destinationConfig: this.codeEditor.codeMirror.getValue()};        
      let newOutput  = {...this.newItemForm.value, destinationConfig: config };
      this.eventRuleService.createEventOutput([newOutput]).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The output has been saved.', this.notificationOptions);
        this.loadData();
      });
    } else {
      this.selectedItem.name = this.newItemForm.value.name;
      this.selectedItem.destinationTypeId = this.newItemForm.value.destinationTypeId;
      this.selectedItem.destinationConfig = config; //this.codeEditor.codeMirror.getValue();
      this.eventRuleService.updateEventOutput([this.selectedItem]).subscribe((response)=>{
        console.log("Response: ");
        console.log(response);
        this.notificationService.success('Success!', 'The output has been updated.', this.notificationOptions);
        this.loadData();
      });
    }
  };

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();            
  }

  ngOnInit() {

    this.vendorService.getVendors().subscribe((vendors)=>{
      this.vendors = vendors;
    });
    
    $('#modal-create-edit').on('shown.bs.modal', (e) => {
      // this.codeEditor.codeMirror.setSize("99%", "99%");
      // this.codeEditor.codeMirror.refresh();
      console.log('Set editor size...');
    });

    $('#modal-create-edit').on('show.bs.modal', (e) => {     
      if (e.relatedTarget){
        console.log('New !');
        this.newItemForm = this._fb.group({
          id: -1,
          name: '',
          destinationTypeId: -1,
          destinationConfig: '',
          recipients: this._fb.array([])
        });
        // this.codeEditor.codeMirror.setValue('');
        this.zone.run(()=>{
          console.log("Refreshing edit modal zone...");          
        });
      }
    });
  }

  onEditClick(item){
    this.selectedItem = item;
    this.selectedType = this.selectedItem.destinationTypeId;
    console.log("Edit", this.selectedItem);
    this.newItemForm = this._fb.group({
      id: this.selectedItem.id,
      name: this.selectedItem.name,
      destinationTypeId: this.selectedItem.destinationTypeId,
      destinationConfig: this.selectedItem.destinationConfig,
      recipients: this._fb.array([])
    });
    
    this.showFormForType(this.selectedItem);

    //this.codeEditor.codeMirror.setValue(this.selectedItem.destinationConfig);
    this.zone.run(()=>{
      console.log("Refreshing edit modal zone...");          
    });
    $("#modal-create-edit").modal('toggle');
  }

  showFormForType(output) {
    console.log("showFormForType", output.destinationTypeId)
    if ( output.destinationTypeId == 1) {
      // Email
      try{
        this.loadEmailForm(output);
      }catch(err){
        console.log(err);
      }
    } else if ( output.destinationTypeId == 3) {
      // Webhook
      this.loadWebhookForm(output);
    }
  }

  showDeletePrompt(item){
    this.selectedItem = item;
    console.log("Delete", this.selectedItem);
    $("#mod-confirm-delete").modal('toggle');    
  }

  onDeleteClick(){
    $("#mod-confirm-delete").modal('toggle');
    if ( this.selectedItem){
      this.spinnerService.toggle(true);
      this.eventRuleService.deleteEventOutput([this.selectedItem.id]).subscribe((response)=>{
        console.log(response);
        this.notificationService.success('Success!', 'The output has been deleted.', this.notificationOptions);
        this.loadData();
      })
    }
  }

  onDataBound(){    
    // this.grid.autoFitColumns();
  }

  onGridCreate(){
    this.grid.showSpinner = () => true;
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);    
    // this.spinnerService.toggle(true);
    // this.loadData();
  }

  get emailRecipients(){
    return this.newItemForm.get('recipients') as FormArray;
  }

  addEmailFormControl() {
    const control = <FormArray>this.newItemForm.controls["recipients"];
    var obj = {name: "", email: "", timeToSend: ""};    
    control.push(this._fb.group(obj));
  }

  onTypeChange(event){
    console.log(event);
    console.log()
    let typeId = this.newItemForm.value.destinationTypeId;
    this.selectedType = typeId;
    if ( typeId == 1 ){
      this.loadEmailForm();
      this.toggleTodoMessage(false);
    } else if (typeId == 3) {
      this.newItemForm.setControl("recipients", this._fb.array([]));
      this.loadWebhookForm();
      this.toggleTodoMessage(false);
    } else {
      this.toggleTodoMessage();
      this.todoMessage = "Not yet supported...";
      this.newItemForm.setControl("recipients", this._fb.array([]));
    }
  }

  toggleTodoMessage(state=true){
    this.showToDoMessage = state;
    this.todoMessage = "Not yet supported...";
    if (state){
      this.newItemForm.controls['destinationTypeId'].setErrors({'invalid': true});
    }
  }

  loadEmailForm(item=this.selectedItem){
    console.log("LoadEmailForm");
    console.log(item);
    if (item) {
      console.log('if passed');
      let configObj = JSON.parse(item.destinationConfig);
      console.log(configObj);
      let existingRecipients = configObj.recipients ? configObj.recipients.map((r)=>{ console.log(r); return this._fb.group(r); }) : [this._fb.group({name: "", email: "", timeToSend: ""})];      
      this.newItemForm.setControl("recipients", this._fb.array(existingRecipients));
    }    
  }

  loadWebhookForm(item=this.selectedItem){
    console.log(this.selectedItem);
    let webhookInfo = null;
    if (item && item.destinationTypeId == 3){
      webhookInfo = JSON.parse(item.destinationConfig);
    } else {
      webhookInfo = {url: '', data: '', method: ''}
    }

    this.newItemForm.setControl("webhook", this._fb.group({
      url: webhookInfo.url,
      data: webhookInfo.data,
      method: webhookInfo.method
    }));
  }

  onDuplicateClick(output){
    console.log(output);
  
    this.newItemForm.reset();
    this.selectedItem = null;
    this.selectedType = output.destinationTypeId;

    this.newItemForm = this._fb.group({
      id: -1,
      name: output.name + ' Copy',
      destinationTypeId: output.destinationTypeId,
      destinationConfig: output.destinationConfig
    });

    this.showFormForType(output);

    $("#modal-create-edit").modal('toggle');
  }

}