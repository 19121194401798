import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { Papa } from 'ngx-papaparse';
import {
  GridComponent,
  load,
  PageSettingsModel,
  SortSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { CustomReportService } from '../../../services/customReportService/custom-report-service.service';
import { AuthService } from '../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
declare var $: any;

@Component({
  selector: 'app-custom-report-viewer',
  templateUrl: './custom-report-viewer.component.html',
  styleUrls: ['./custom-report-viewer.component.css'],
})
export class CustomReportViewerComponent implements OnInit {
  @ViewChild('advConfigEditor') advConfigEditor: CodemirrorComponent;

  public allReports: any;
  public reports: any;
  public selectedReport: any;
  public selectedReportMetadata: any;

  public data: any;

  public pageSettings: PageSettingsModel;
  public sortSettings: SortSettingsModel;
  public filterSettings: Object;
  public groupOptions: Object;

  public hideList = true;
  public isJSON = false;

  @ViewChild('grid')
  public grid: GridComponent;

  exportUrl: string;
  manifestFile: any;

  navigationSubscription;
  codeMirrorOptions: any;
  codeMirrorJSON: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public customReportService: CustomReportService,
    public spinnerService: SpinnerService,
    public notificationService: VaultNotificationService,
    public papa: Papa
  ) {
    this.reports = [];
    this.data = [];
    this.filterSettings = {
      type: 'Excel',
    };
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      tabSize: 2,
      readOnly: true,
      theme: 'material',
      gutters: ['CodeMirror-linenumbers'],
    };

    this.pageSettings = {
      pageSize: 25,
      pageCount: 5,
      pageSizes: [25, 50, 75, 100, 200],
    };
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }
  public dropDownTemplate =
    "<div style='display: flex; flex-direction: row;'> <div style='flex-grow: 1;'> ${label} </div> <div style='width: fit-content;text-transform: uppercase; font-weight: bold;color:lightgrey'> ${format} </div> </div>";
  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    this.initReports();
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initReports();
  }

  initReports() {
    console.log('Custom Report ngOnInit');
    let reportName = this.route.snapshot.params.reportName;
    if (reportName != 'admin') {
      this.allReports = [];
      this.selectedReport = { Key: reportName };
      this.loadData(this.selectedReport.Key);
    } else {
      this.loadReports();
      this.hideList = false;
    }
  }

  loadReports() {
    this.spinnerService.toggle(true);
    this.customReportService.getAvailableReports().subscribe(
      (reportList: any) => {
        this.allReports = reportList.Contents;
        this.reports = reportList.Contents.filter((file) => {
          return file.Key.endsWith('.meta') == false;
        }).map((file, index) => {
          return {
            ...file,
            label: file.Key.split('.')[0],
            index: index,
            isJSON: file.Key.endsWith('.json'),
            format: file.Key.split('.')[1],
          };
        });
        this.selectedReport = null;
        // (document.getElementById('reportsDD') as any).selectedIndex = 0;
        this.data = [];
        this.grid.dataSource = [];
        this.spinnerService.toggle();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public exportProperties: any = {
    exportType: 'AllPages',
  }

  toolbarClick(args) {
    console.log(args);
    if (args.item.id.indexOf('Export CSV')){
      console.log('CSV Export');
      this.grid!.csvExport(this.exportProperties);
    }
  }

  onReportChange(event) {
    console.log(event);
    let selectedIndex = event.itemData.index;
    var selectedReport = this.reports[selectedIndex];
    console.log('Selected Index', selectedIndex);
    console.log(selectedReport);
    if (selectedReport != null) {
      this.grid.clearGrouping();
      this.selectedReport = selectedReport;
      this.loadData(this.selectedReport.Key);
    }
  }
  // _onReportChange(event){
  //   let selectedIndex = event.target.selectedIndex;
  //   console.log('Selected Index', selectedIndex);
  //   var selectedReport = this.reports[selectedIndex - 1];
  //   console.log(selectedReport);
  //   if ( selectedReport != null ) {
  //     this.selectedReport = selectedReport;
  //     this.loadData(this.selectedReport.Key);
  //   }
  // }

  loadData(reportName) {
    console.log('loadData', reportName);
    this.spinnerService.toggle(true);
    this.customReportService.getReportData(reportName).subscribe(
      (files: any) => {
        let data = files[0];
        let metadata = files[1];
        console.log(metadata);
        this.papa.parse(data, {
          header: true,
          skipEmptyLines: 'greedy',
          dynamicTyping: true,
          complete: (results) => {
            if (this.selectedReport.isJSON) {
              this.isJSON = true;
              this.codeMirrorJSON = data;
              this.advConfigEditor.codeMirror.setSize('100%', '100%');
            } else {
              this.isJSON = false;
              if (metadata) {
                this.grid.columns = JSON.parse(metadata).columns;
              } else {
                try {
                  this.grid.columns = [];
                } catch (e) {
                  console.log(e);
                }
              }
            }

            this.data = results.data;
            this.spinnerService.toggle(false);
            this.grid.dataSource = [];

            // setTimeout(() => {
            //   // this.grid.refresh();
            //   this.grid.autoFitColumns();
            // }, 100);
          },
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async upload(files: File[]) {
    console.log(files);
    this.spinnerService.toggle(true);
    await this.customReportService.upload(files);
    this.notificationService.success(
      'Success',
      'Reports uploaded successfully'
    );
    this.loadReports();
    // this.spinnerService.toggle();
  }

  addToMenu() {
    console.log(this.selectedReport);
    if (this.selectedReport) {
      try {
        let customReports: any = localStorage.getItem('customReports');
        let customReportObj = [];
        if (customReports) {
          customReportObj = JSON.parse(customReports);
        }

        let menuLabel = prompt(
          'Enter the name you would like to see in the menu:'
        );
        console.log(menuLabel);
        menuLabel =
          menuLabel == '' ? this.selectedReport.Key.split('.')[0] : menuLabel;

        customReportObj.push({
          menuText: menuLabel,
          headerText: 'Reports',
          pageText: menuLabel,
          link: '/reports/custom/' + this.selectedReport.Key,
        });

        localStorage.setItem('customReports', JSON.stringify(customReportObj));

        this.notificationService.success(
          'Success',
          `${menuLabel} was added successfully. Refresh for changes to be reflected.`
        );
      } catch (err) {}
    }
  }

  resetMenu() {
    localStorage.setItem('customReports', JSON.stringify([]));
    this.notificationService.success(
      'Success',
      `Menu was successfully reset. Refresh for changes to be reflected.`
    );
  }

  async deleteReport() {
    if (this.selectedReport) {
      console.log(this.selectedReport.Key);
      this.spinnerService.toggle(true);
      let result = await this.customReportService.deleteReport(
        this.selectedReport.Key
      );
      console.log('Delete result', result);
      this.notificationService.success('Success', 'Report was deleted.');
      this.loadReports();
      this.spinnerService.toggle();
    } else {
      this.notificationService.warn('Info', 'Please select a report');
    }
  }

  onDataBound(event) {
    console.log(event);
    this.grid.autoFitColumns();
  }
}
