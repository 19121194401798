import { Component, OnInit, Input, OnChanges ,SimpleChanges, ChangeDetectionStrategy} from '@angular/core';


@Component({
  selector: 'condensed-value-display',
  templateUrl: './condensed-value-display.component.html',
  styleUrls: ['./condensed-value-display.component.css'],
})
export class CondensedValueDisplayComponent implements OnChanges, OnInit {

  @Input('title') label: string;
  @Input('max') max: number;
  @Input('monitored') monitored: number;
  @Input('label') extraLabel: string;
  public total: number = 1;
  public value: number = 0;
  public percent: number = 0;
  public title: string = 'No Data Found';
  public data: Object[] = [
    { x: 'Monitored', y: this.percent },
    { x: 'Unchecked', y: 100 - this.percent },
  ];

  constructor() {
    this.updateData()
  }

  public palette = [
    '#00dbff',
    '#e5ebe833',
  ];

  public legendSettings: Object = {
    visible: false,
    toggleVisibility: false,
    position: 'Right',
    textWrap: 'Wrap',
    alignment: 'Center',
    width: '20',
  };
  public pieTooltip: Object = {
    enable: false,
  };
  

  public pieCenter: Object = {
    x: '50%',
    y: '50%',
  };

  
  updateData() {
    if (this.max != undefined && this.monitored != undefined) {
      this.total = this.max;
      this.value = this.monitored;
      this.percent = Math.round(this.value/this.total * 100);
      this.data = [
        { x: 'Monitored', y: this.percent },
        { x: 'Unchecked', y: 100 - this.percent },
      ]
      this.title = this.label || 'No Title Attached';
    }
  }

  ngOnInit(): void {
    this.updateData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
    console.log("changes", changes);
  }
}
