import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-metric-diff',
  templateUrl: './simple-metric-diff.component.html',
  styleUrls: ['./simple-metric-diff.component.css']
})
export class SimpleMetricDiffComponent implements OnInit {
  @Input('currentValue') currentValue: number;
  @Input('diffValue') diffValue: number;
  @Input('isHidden') isHidden: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  public getCompareColor(x:number,y:number): any {
    if(x === y){
      return 'lightgrey';
    }
    if(x > y){
      return 'green';
    }
    if(x < y){
      return 'red';
    }
  }
}
