import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForOf } from '@angular/common';
import { GridComponent, GridModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-nested-grid-panel',
  templateUrl: './nested-grid-panel.component.html',
  styleUrls: ['./nested-grid-panel.component.css']
})
export class NestedGridPanelComponent implements OnInit {
  @Input('data') gridData: any;
  @Input('headers') headers: string;
  @Input('childGrid') childGrid?: GridModel;
  @ViewChild('grid')
  public grid?: GridComponent;
  public toolbarOptions?: ToolbarItems[];

  constructor() {
    this.toolbarOptions = ['Search'];
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.updateData();
  }

  updateData(): void {
    if (!this.gridData)
      return;
    console.log("gridData", this.gridData);
    this.childGrid.dataSource = this.gridData.level1;
    // this.nestedGrid.dataSource = this.gridData;
    if (this.grid)
      (this.grid as GridComponent).refresh();
  }
}
