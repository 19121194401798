import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { PageService } from '../../services/page/page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../services/sitemap/sitemap.service';
import { CustomerService } from '../../services/customer/customer.service';
import { DataExportService } from '../../services/data-export/data-export.service';
import { NotificationsService } from 'angular2-notifications';
import { environment as env } from "../../../environments/environment";
import { AuthService } from '../../services/auth/auth.service';
import { ClipboardService } from 'ngx-clipboard';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VendorsService } from '../../services/vendors/vendors.service';
import { DataSourceChangedEventArgs, DataStateChangeEventArgs, PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-grids';
import { CommandModel, EditService, GridComponent, ResizeService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';
import { L10n } from '@syncfusion/ej2-base';
import { EJ2VendorsProxyServiceService } from '../../services/ej2Proxies/ej2-vendors-proxy-service.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { TimezoneService } from '../../services/timezone.service';
import { tap } from 'rxjs/operators';

declare var $:any;

L10n.load({
  'en-US': {
    'grid': {
      'EmptyRecord': 'No records were found',
    },
    'pager': {
        'currentPageInfo': '',
        'totalItemsInfo': '{1} to {2} of {0}',
    }
  }
});

@Component({
  selector: 'app-code-files',
  templateUrl: './code-files.component.html',
  styleUrls: ['./code-files.component.css'],
  providers:[ResizeService, ToolbarService, EditService]
})
export class CodeFilesComponent implements OnInit {

  codeFiles:any[];
  tooltipContent:string;  
  exportId:string;
  pollingTimer:any;
  notificationOptions:any;
  exportButtonDisabled:boolean;
  pollingTimerCounter:number;
  editor:any;
  pollingTime:number;
  bulkUpdateForm:FormGroup;

  

  public pageSettings: PageSettingsModel;
  public selectionSettings:SelectionSettingsModel;
  public cdnUrl:string;
  @ViewChild('grid') public grid: GridComponent;
  public data: Observable<DataStateChangeEventArgs>;
  public state: any;
  public pageOptions: Object;
  public toolbar: string[];
  public filterSettings: Object;
  public locale:any;
  public editSettings: Object;
  public frequencyEditRules:Object;
  public editparams: Object;
  public dateFormat:any;
  public commands: CommandModel[];

  constructor(private route:ActivatedRoute,
              private sitemapService:SitemapService,              
              private dataExportService:DataExportService,
              private notificationService: NotificationsService,
              public authService:AuthService,
              public _fb:FormBuilder,
              private vendorService:VendorsService,
              private ej2ProxyService:EJ2VendorsProxyServiceService,
              private spinnerService:SpinnerService,
              public timezoneService:TimezoneService
  ) {
    console.log("Constructor");
    this.cdnUrl = env.cdn.url;
    this.pollingTime = 1000;
    this.sitemapService.update(this.route);
    this.spinnerService.toggle(false);
    this.tooltipContent = "Browse or search for code files Vault JS has discovered on your domain(s)";
    this.notificationOptions = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
    this.exportButtonDisabled = false;

    this.bulkUpdateForm = this._fb.group({
      maxTimeBetweenChecks: 1800
    });

    this.filterSettings = { type: 'Excel' };
    
    this.data = this.ej2ProxyService.pipe(tap((data)=>{
      data.result = data.result.map((item)=>{
        return {...item, lastCheckedFormatted: timezoneService.format(item.lastChecked), lastChangedFormatted: timezoneService.format(item.lastChanged) }
      })
    }));

    this.frequencyEditRules = {number: true};
    this.editSettings = { allowEditing: true, mode: 'Normal' };
    this.editparams = { params: { popupHeight: '300px' }};
    this.toolbar = ['Update', 'Cancel'];
    this.dateFormat = {type: 'dateTime', skeleton:'short'};

    this.pageOptions = { pageSize: 25, pageCount: 5, pageSizes: [25, 50, 75, 100, 200] };
    // let state = { skip: 0, take: 25, where:[] };
    // this.ej2ProxyService.execute(state);  
    this.commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  onDataBound(){    
    this.grid.autoFitColumns(["vendor", "maxTimeBetweenChecks", "lastChecked", "lastChanged"]);
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);
    
    this.grid.hideSpinner();
    this.ej2ProxyService.execute(state);
  }

  public dataSourceChanged(state: DataSourceChangedEventArgs): void {
    console.log('dataSourceChanged');
    console.log(state);
    
    if (state.action === 'add') {      

    } else if (state.action === 'edit') {
      this.ej2ProxyService.updateRecord(state).subscribe((result)=>{
        state.endEdit();
      },
      (error)=>{
        console.log(error);
        state.cancelEdit();
      });
    } else if (state.requestType === 'delete') {
      
    }
  }

  public actionBegin(args) {
    console.log('actionBegin');
    console.log(args);
    if ( args.requestType === "filterchoicerequest" ) {
      args.filterChoiceCount = 20;
      args.filterModel.dialogObj.contentEle.classList.add('e-hide'); 
      args.filterModel.dialogObj.element.querySelector('.e-footer-content').classList.add('e-hide'); 
    } else if ( args.requestType === 'save'){
      console.log('XXXXXXXX');
    }
  }

  public batchSave(event){
    console.log('batchSave');
    console.log(event);
  }

  onGridCreated(){
    this.grid.showSpinner = () => false;
  }

  ngOnInit() {
    console.log("ngOnInit");
    let state:any = { skip: 0, take: 25};
    
    let qps = this.route.snapshot.queryParams;
    if ( qps && (qps["f"] || qps["v"] )) {

      state.where = [{
        "isComplex": true,
        "ignoreAccent": false,
        "condition": "and",
        "predicates": [{
          "isComplex": false,
          "field":  qps["f"] ? "name" : "vendor",
          "operator": "equal",
          "value": qps["f"] ? qps["f"] : qps["v"],
          "ignoreCase": false,
          "ignoreAccent": false
        }]
      }];
    }
    this.ej2ProxyService.execute(state);  
  }

  exportData() {
    this.exportButtonDisabled = true;
    this.dataExportService.startDataExport(DataExportService.ALL_CODE_FILES_REPORT).subscribe((result)=> {
      this.notificationService.success('Success!', 'Your export was requested successfully. The file will download automatically once it has been generated. Please remain on this page until then.', this.notificationOptions);
      console.log(result);
      this.exportId = result.exportId;
      this.pollingTimerCounter = 0;
      this.pollingTime = 1000;
      this.startPolling();
    },
    (error)=>{
      this.exportButtonDisabled = false;
      console.log(error);
      this.notificationService.success('Oops!', 'Your export request was not successful. Please contact support.', this.notificationOptions);
    });
  }

  handlePollingError(error){
    console.log(error);
    if ( this.pollingTimerCounter < 10 ){
      this.startPolling();
      this.pollingTimerCounter++;
      this.pollingTime += 1000; // add 1s each time so it gives more time for file to show up...
    } else {
      clearTimeout(this.pollingTimer);
      this.notificationService.success('Hmmm...', 'Your export seems to be taking longer than expected. Please contact support.', this.notificationOptions);
    }
  }

  startPolling() {    
    this.pollingTimer = setTimeout(()=>{
      console.log(new Date() + " Checking for file...");

      const filePath = `${localStorage.cid}/${this.exportId}/${DataExportService.ALL_CODE_FILES_REPORT}.${DataExportService.CSV_EXTENSION}`;
      this.dataExportService.getDataExportFile(filePath).subscribe((data)=>{

        if ( data && data.message && data.message == 'Access Denied' ){
          this.handlePollingError(data);
        } else {
          this.exportButtonDisabled = false;

          let fileName = "Vault JS All Code Files " + new Date().toLocaleString() + ".csv";  
          this.dataExportService.saveFile(fileName, data.toString());
          
          clearTimeout(this.pollingTimer);
        }
      },
      (error)=>{
       this.handlePollingError(error);
        
      });
    }, this.pollingTime);
  }

  ngOnDestroy(){
    if ( this.pollingTimer ){
      clearTimeout(this.pollingTimer);
    }
  }

  bulkUpdate(){
    $('#modal-bulk-update').modal('hide');
    
    var filters = this.grid.filterSettings["properties"]["columns"].map((predicate)=>{
      var obj = {};
      if ( predicate && predicate.properties ){
        obj[predicate.properties.field] = predicate.properties.value;
      } else {
        obj = null;
      }
      return obj;
    }).filter((i)=>{return i != null});

    console.log(filters);

    console.log(this.bulkUpdateForm.value);
    var params = {type: 'codeFile', properties:[{name: 'maxTimeBetweenChecks', value: this.bulkUpdateForm.value.maxTimeBetweenChecks}],  filters: filters};
    console.log(params);

    this.spinnerService.toggle(true);
    this.vendorService.bulkUpdate(params).subscribe((response)=>{
      console.log(response);
      this.notificationService.success('Success!', `${response.numberOfRecordsUpdated} entries were updated successfully.`, this.notificationOptions);
      this.spinnerService.toggle(false);
      this.grid.refresh();
    },
    (error)=>{
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.success('Oops!', 'Bulk update was not successful. Please contact support.', this.notificationOptions);
    });
  }

}
