<h4>Matcher Attributes</h4>
<ejs-grid 
    #resourceMatcherAttributesGrid 
    id='resourceMatcherAttributesGrid'
    [dataSource]='model.attributes'
    [allowPaging]='true'
    [pageSettings]='attributePageSettings' 
    [editSettings]='attributeEditSettings'                 
    [allowSelection]='true' 
    allowResizing="true"
    [toolbar]='attributeToolbar'
    (actionBegin)="attributeActionBegin($event)"
>
    <e-columns>                    
        <e-column field='matcherAttributeKeyId' headerText='Name' editType='dropdownedit' [edit]='matcherAttributeKeyEditParams'>
            <ng-template #template let-data>
                {{ matcherAttributeKeysById[data.matcherAttributeKeyId].name }}
            </ng-template>
        </e-column>
        <e-column field='value' headerText='Value'></e-column>
    </e-columns>
</ejs-grid>

<h4>Extractors</h4>
<ejs-grid 
    #extractorsGrid
    id='extractorsGrid' 
    [dataSource]='model.extractors'
    [allowPaging]='true'
    [pageSettings]='pageSettings' 
    [editSettings]='extractorsEditSettings'                 
    [allowSelection]='true' 
    [toolbar]='extractorsToolbar'
    [selectionSettings]='extractorsSelectOptions'
    allowResizing="true"
    (actionBegin)="extractorsActionBegin($event)"
    (commandClick)="extractorsCommandClick($event)"
>
    <e-columns>                    
        <e-column headerText='' width='150' [commands]='commands'></e-column>
        <e-column field='key' headerText='Key'></e-column>
        <e-column field='name' headerText='Name'></e-column>
        <e-column field='description' headerText='Description'></e-column>
        <e-column field='value' headerText='Value'></e-column>
        <e-column field='groupName' headerText='Group Name'></e-column>
        <e-column field='isRequired' headerText='Required' editType='booleanedit' type='boolean'></e-column>
    </e-columns>
</ejs-grid>

<!-- <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [options]="options" [model]="model"></formly-form>
    <br/>
    <h4>Extractors</h4>
    <ejs-grid 
        #extractorsGrid
        id='extractorsGrid' 
        [dataSource]='model.extractors'
        [allowPaging]='true'
        [pageSettings]='pageSettings' 
        [allowSelection]='true'
        [editSettings]='extractorsEditSettings'                 
        [toolbar]='extractorsToolbar'
        (commandClick)="commandClick($event)"
        (actionComplete)="actionComplete($event)"
        (actionBegin)="actionBegin($event)"
    >
        <e-columns>                    
            <e-column headerText='' width='100' [commands]='commands'></e-column>
            <e-column field='name' headerText='Name'></e-column>
            <e-column field='description' headerText='Description'></e-column>
        </e-columns>
    </ejs-grid>

    <br/><br/>
    <button type="submit" class="btn btn-primary submit-button" [disabled]="!form.valid">Submit</button>
</form> -->