export let vendorData:any = [
	{
        id: 1,
		vendorIcon: "google.com",
		vendorName: "Google",
		vendorId: 1234, 
		lastConfigUpdate: 1234123412,
		vendorPresence: [
			{
				timestamp: 1620675554,
				value: 75
			},
			{
				timestamp: 1620677554,
				value: 85
			},
			{
				timestamp: 1620679554,
				value: 100
			}
		],
		validationStatus: [
			{
				timestamp: 1620675554,
				testsPassing: 0,
				testsFailing: 0
			},
			{
				timestamp: 1620677554,
				testsPassing: 1,
				testsFailing: 0
			},
			{
				timestamp: 1620679554,
				testsPassing: 1,
				testsFailing: 1
			},
			{
				timestamp: 1620685554,
				testsPassing: 2,
				testsFailing: 0
			}
		],
		detail: {
			validations: [
			{
				id: 123123,
				name: "Google Analytics on page",
				description: "This tests if Google is on the page as it should be.",
				results: {
					passing: 999,
					failing: 1
				},
				passing: false,
			},{
				id: 123124,
				name: "Google account in account list",
				description: "This tests if blahblabllaa.",
				results: {
					passing: 999,
					failing: 0
				},
				passing: true				
			}],
			events: [
				{
					name: "Code File Changed",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				},
				{
					name: "New Code File",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				},
				{
					name: "DNS Record Change",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				}					
			]			
		}
	},
	{
        id: 2,
		vendorIcon: "adobe.com",
		vendorName: "Adobe",
		vendorId: 1235, 
		lastConfigUpdate: 1234123412,		
		vendorPresence: [{
			timestamp: 1620675554,
			value: 85
		},{
			timestamp: 1620677554,
			value: 90
		},{
			timestamp: 1620679554,
			value: 75
		}],
		validationStatus: [{
			timestamp: 1620675554,
			testsPassing: 0,
			testsFailing: 0
		},{
			timestamp: 1620677554,
			testsPassing: 0,
			testsFailing: 4
		},{
			timestamp: 1620679554,
			testsPassing: 2,
			testsFailing: 4
		},{
			timestamp: 1620685554,
			testsPassing: 3,
			testsFailing: 4
		}],
		detail: {
			validations: [
			{
				id: 123123,
				name: "Adobe on page",
				description: "This tests if Adobe is on the page as it should be.",
				results: {
					passing: 999,
					failing: 0
				},
				passing: true
			},{
				id: 123124,
				name: "Adobe account in account list",
				description: "This tests if blahblabllaa.",
				results: {
					passing: 999,
					failing: 24
				},
				passing: false				
			},{
				id: 123124,
				name: "Adobe sets cookies",
				description: "This tests if right cookies set.",
				results: {
					passing: 1034,
					failing: 1
				},
				passing: false								
			}],
			events: [
				{
					name: "Code File Changed",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				},
				{
					name: "New Code File",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				},
				{
					name: "DNS Record Change",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				}					
			]
		}
	},
	{
        id: 3,
		vendorIcon: "ensighten.com",
		vendorName: "Ensighten",
        lastConfigUpdate: 1234123412,		
		vendorId: 1236, 
		vendorPresence: [{
			timestamp: 1620675554,
			value: 55
		},{
			timestamp: 1620677554,
			value: 75
		},{
			timestamp: 1620679554,
			value: 100
		}],
		validationStatus: [{
			timestamp: 1620675554,
			testsPassing: 0,
			testsFailing: 0
		},{
			timestamp: 1620677554,
			testsPassing: 0,
			testsFailing: 4
		},{
			timestamp: 1620679554,
			testsPassing: 2,
			testsFailing: 4
		},{
			timestamp: 1620685554,
			testsPassing: 3,
			testsFailing: 4
		},{
			timestamp: 1620695554,
			testsPassing: 0,
			testsFailing: 0
		}],
		detail: {
			validations: [],
			events: [
				{
					name: "Code File Changed",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				},
				{
					name: "New Code File",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				},
				{
					name: "DNS Record Change",
					values: [12,43,55,0,12,55,224,51,2,45,12,43,55,0,12,55,224,51,2,45,432,2,45,21]
				}					
			]
		}
	}	
];