import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToolbarService, LinkService, ImageService, QuickToolbarService, ResizeService, MarkdownEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { HtmlEditorService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import Tribute  from 'tributejs';
import { IEventOutput } from '../../../../models/IEventOutput';
import { EventRuleService } from '../../../../services/eventRule/event-rule.service';

@Component({
  selector: 'webhook-output',
  templateUrl: './webhook-output.component.html',
  styleUrls: ['./webhook-output.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, ResizeService, MarkdownEditorService]
})
export class WebhookOutputComponent implements OnInit, IEventOutput, OnChanges {

  @ViewChild('webhookTributeRTE') 
  webhookRteObj: RichTextEditorComponent;

  public webhookHttpMethods:any[];
  public vendors:any[];
  public fields: Object = { value: 'name', text: 'name' };
  public vendorFields: Object = { value: 'name', text: 'name' };
  public showHttpMethod:boolean;
  
  @Input() data: any;
  @Input() eventType:string;
  @Input() isNewRule:boolean;

  public outputFormGroup:FormGroup;

  tributeObj:any;
  toolbarSettings: any = {
    enable: false
  };

  constructor(
    public formBuilder:FormBuilder
  ) { 
    
    this.showHttpMethod = false;

    this.webhookHttpMethods = [
      { name: "GET" },
      { name: "PUT" },
      { name: "POST" }
    ];

    this.vendors = [
      { 
        name: "Slack", 
        method:'POST', 
        format: (message)=>{
          return JSON.stringify({ text: message });
        } 
      },
      { 
        name: 'Custom', 
        format: (message)=>{
          return message;
        } 
      }
    ];

    console.log("MY INPUT Constructor!");
    console.log(this.data);

    this.outputFormGroup = this.formBuilder.group({
      vendor: '',
      url: '',
      method: '',
      data: '',
      rawData: ''
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    console.log(changes);

    if ( changes.eventType && this.tributeObj) {
      this.tributeObj.append(0, EventRuleService.getTributeJSOptions(this.eventType), true);

      if (!this.isNewRule) return;
      
      if ( this.outputFormGroup.get('url').value.indexOf("{{") != -1){
        this.outputFormGroup.patchValue({url: ''});  
      }
      if ( this.outputFormGroup.get('data').value.indexOf("{{") != -1){
        this.outputFormGroup.patchValue({data: ''});
      }      
    }
  }
  
  getValue(): Object {
    let vals = this.outputFormGroup.value;
    let method = vals.method;
    let data = vals.data;
    let vendorObj = this.vendors.filter((v)=>{return v.name == vals.vendor})[0];
    if ( vals.vendor != 'Custom'){
      method = vendorObj.method;
      data = vendorObj.format(vals.rawData);
    }
    return {vendor: vals.vendor, url: vals.url, method: method, data: data, rawData: vals.rawData };
  }

  ngOnInit(): void {
    console.log("MY INPUT!");
    console.log(this.data);
    this.outputFormGroup = this.formBuilder.group({
      vendor: this.data ? this.data.vendor : '',
      url: this.data ? this.data.url : '',
      method: this.data ? this.data.method : '',
      data: this.data ? this.data.data : '',
      rawData: this.data ? this.data.rawData : '',
    }); 
    
    this.showHttpMethod = this.outputFormGroup.value.vendor == 'Custom';    

    this.outputFormGroup.valueChanges.subscribe((valueChangeArg)=>{
        this.showHttpMethod = valueChangeArg.vendor == 'Custom';        
    });
  }

  public actionBeginEvent(args: any): void {
    if (args.requestType === 'EnterAction') {
      args.cancel = true;
    }
  }

  public onCreate(): void {
    console.log('onCreate');
    this.tributeObj = new Tribute({
      trigger: "#",
      values: EventRuleService.getTributeJSOptions(this.eventType),
      selectTemplate: function(item) {
        let value = item.original.value;
        if ( item.original.key == 'Vendor') {
          value = 'event.vendorName';
        }
        return `{{${value}}}`;
      },
      menuItemTemplate: function(item) {
        return item.string;
      }
    });     

    if ( this.webhookRteObj && this.webhookRteObj.inputElement ){
      this.tributeObj.attach(this.webhookRteObj.inputElement);
    }
    
    this.tributeObj.attach(document.getElementById("txtUrl"));
    
  }

}
