import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerService } from '../customer/customer.service';
import { SpinnerService } from '../spinner/spinner.service';
import { TimezoneService } from '../timezone.service';


@Injectable({
  providedIn: 'root'
})
export class Ej2EventsService extends Subject<any> {

  constructor(private customerService:CustomerService, private spinnerService:SpinnerService, private timezoneService:TimezoneService) { 
    super();
  }

  public execute(state:any):void {
    this.spinnerService.toggle(true);
    this.getData(state).pipe(map((item)=>{      
      let ret  = {count: item.count, result: []};
      ret.result = item.result.map((item)=>{
        return {...item, 
          SLADateTime: this.timezoneService.unformatted(item.SLADateTime), 
          detectedDateTime: this.timezoneService.unformatted(item.detectedDateTime)  }
      })
      .filter((item)=>{ return (item.vendorName != "???" && item.codeFileUrl != "???" )});
      return ret;
    })).subscribe(x => super.next(x));
    // this.getData(state).subscribe(x => super.next(x));
  }

  protected getData(state: DataStateChangeEventArgs): Observable<any> {    
    console.log('proxy state');
    console.log(state);
    var search = '';
    if ( state.search && state.search[0] ){      
      search = state.search[0]['key'];      
    }  
    var sorted = state.sorted ? state.sorted : [ {
      "name": "detectedDateTime",
      "direction": "descending"
    }];
    
    let params = {
      start: (state.skip ? state.skip : 0), 
      length: (state.take ? state.take : 20), 
      sort: sorted, 
      where: state.where
    };

    return this.customerService.getEventsForCustomers(params);
  }
}
