import { Injectable } from '@angular/core';
import { CrudOptions, DataOptions, DataResult, Query } from '@syncfusion/ej2-data';
import { TimezoneService } from '../../services/timezone.service';
import { VaultBaseUrlAdaptor } from '../../services/VaultBaseUrlAdaptor';

@Injectable({
    providedIn: 'root'
})
export class ReportDataAdaptor extends VaultBaseUrlAdaptor {

    constructor(private timezoneService: TimezoneService) {
        super();
    }

    override processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
        console.log('processResponse');
        console.log(data, ds, query, request, changes);
        return data;
    }
}