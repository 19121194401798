import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterEvent,
  NavigationEnd,
} from '@angular/router';
import { PageService } from '../page/page.service';
import { PageRunnerService } from '../data-export/page-runner.service';
import { DataValidationService } from '../data-validation/data-validation.service';
import {
  MENU_ITEMS,
  VaultMenuItem,
} from '../../components/shared-ui/menu/menu';
import { TimezoneService } from '../timezone.service';
import { InstrumentationService } from '../instrumentation/instrumentation.service';
import { VendorLibraryStateService } from '../vendorLibrary/vendor-library-state.service';
import { SafeHtml, Title } from '@angular/platform-browser';
import { ConfigService } from '../config/config.service';
import { CustomerService } from '../customer/customer.service';
import { RegionService } from '../region/region.service';

@Injectable({
  providedIn: 'root',
})
export class SitemapService {
  section: string;
  pageTitle: string;
  safePageTitle: SafeHtml;
  isPageTitleHtml: boolean;
  helpText: string;
  vendorName: string;
  params: any;
  data: any;
  showBreadcrumbs: boolean;
  crumbs: any[];
  activeMenuUrl: string;
  image: string;
  hideHeader: boolean;

  constructor(
    private router: Router,
    private pageService: PageService,
    private pageRunnerService: PageRunnerService,
    private dataValidationService: DataValidationService,
    private timezoneService: TimezoneService,
    private instrumentationService: InstrumentationService,
    private vendorLibState: VendorLibraryStateService,
    private title: Title,
    private configService: ConfigService,
    private customerService: CustomerService,
    private regionService: RegionService
  ) {
    this.section = '';
    this.pageTitle = '';
    this.isPageTitleHtml = false;
    this.helpText = '';
    this.vendorName = '';
    this.showBreadcrumbs = false;
    this.crumbs = [];
    this.activeMenuUrl = '';
    this.hideHeader = false;

    let customReports: any = localStorage.getItem('customReports');
    try {
      if (customReports) {
        customReports = JSON.parse(customReports);
      } else {
        customReports = [];
      }
    } catch (parseErr) {
      console.log(parseErr);
      customReports = [];
    }

    let menuItems = this.customerService.customer.menuItems;
    console.log('XXXX', this.customerService.customer);
    if (menuItems && menuItems.length > 0) {
      customReports = customReports.concat(
        menuItems.map((item) => {
          let menuText = item.itemName.replace('menu:', '');
          let templateName = item.templateName;
          let scopes = null;
          if (item.templateName.indexOf('|') > -1) {
            let chunks = templateName.split('|');
            templateName = chunks[0];
            scopes = chunks[1].split(',');
          }
          let newMenuItem = {
            menuText: menuText,
            headerText: 'Reports',
            pageText: menuText,
            link: `/reports/custom/${templateName}`,
          };
          if (scopes) {
            newMenuItem['scopes'] = scopes;
          }
          console.log('return newMenuItem', newMenuItem);
          return newMenuItem;
        })
      );
    }

    console.log('customReports', customReports);
    if (customReports != null) {
      console.log('xxx Parsing custom reports');
      let dataItemIndex: any = MENU_ITEMS.findIndex((item) => {
        return item.menuText == 'Data';
      });
      console.log('xxx dataItemIndex', dataItemIndex);
      let dataItem: any = MENU_ITEMS[dataItemIndex];
      if (dataItem) {
        let reportsIndex: any = dataItem.children.findIndex((item) => {
          return item.menuText == 'Reports';
        });
        let reportRoot = dataItem.children?.[reportsIndex];
        if (reportRoot) {
          console.log('xxx report root', reportRoot);
          try {
            if (typeof customReports == 'string') {
              customReports = JSON.parse(customReports);
            }
            reportRoot.children = reportRoot.children.concat(customReports);
            console.log('xxx reportRoot.children', reportRoot.children);
          } catch (err) {
            console.log(err);
            console.log("Couldn't parse custom reports string!");
          }
        }
      }
    }
    this.subToRouter();
  }

  subToRouter() {
    this.router.events.subscribe((event: RouterEvent) => {
      console.log('Sitemap Service Router Events Sub', event);
      this.isPageTitleHtml = false;
      if (event instanceof NavigationEnd) {
        console.log('Sitemap Service - URL - ', this.router.url, event.url);

        const menuItem = this.findMenuItemWithPath(event.url, MENU_ITEMS);

        console.log('menuItem');
        console.log(menuItem);
        this.hideHeader = menuItem?.hideHeader;
        if (menuItem && menuItem.image) {
          this.image = menuItem.image;
        } else {
          this.image = null;
        }
        if (event.url.indexOf('/reports/raw-report-viewer/') > -1) {
          this.hideHeader = true;
          this.pageTitle = 'Raw Report Viewer';
        }
        if (event.url.indexOf('/reports/tracker-report-viewer/') > -1) {
          this.hideHeader = true;
          this.pageTitle = 'Tracker Report Viewer';
        }

        if (event.url == '/reports/nurtec/details') {
          this.pageTitle = 'Details';
          return;
        }

        if (event.url == '/reports/nurtec/presence') {
          this.pageTitle = 'Presence';
          return;
        }

        if (menuItem != null) {
          console.log('Sitemap Service - Found URL in menu items');
          this.activeMenuUrl = menuItem.link;
          this.pageTitle = menuItem.pageText;
          this.section = menuItem.headerText;
          this.helpText = menuItem.helpText;
          this.crumbs = [];
          this.params = null;

          if (menuItem.menuText == 'Reports' && menuItem.link != '/reports') {
            this.pageTitle = 'Reports';
            this.activeMenuUrl = '/reports';

            this.crumbs = [
              {
                text: 'Reports',
                link: '/reports',
                active: false,
              },
              {
                text: menuItem.pageText,
              },
            ];
          }

          this.title.setTitle(menuItem.menuText);

          this.instrumentationService.sendEvent({
            type: 'Page View',
            page: menuItem.pageText ? menuItem.pageText : menuItem.menuText,
            section: menuItem.headerText,
          });

          return;
        } else {
          this.activeMenuUrl = '';
        }

        if (event.url == '/validation/oneTrustSummary') {
          this.pageTitle = 'One Trust Summary';
          return;
        }

        if (this.params && Object.keys(this.params).length > 0) {
          console.log('params', this.params);


          if (this.params['fileId']) {
            this.activeMenuUrl = '/codeFiles';
            this.section = 'Vendors';
            this.pageTitle = 'Code File Details'; // for " + this.params["fileId"];
            this.crumbs = [
              {
                text: 'Vendors',
                link: '/vendors',
                active: false,
              },
              {
                text: this.params['vendor'],
                link: '/vendors/' + this.params['vendor'],
                fragment: 'code',
                active: true,
              },
            ];
            this.instrumentationService.sendEvent({
              type: 'Page View',
              page: `Code File Details: ${this.params['fileId']} (${this.params['vendor']})`,
              vendor: this.params['vendor'],
              codeFileId: this.params['fileId'],
            });
          } else if (
            this.params['vendor'] &&
            !this.params['fileId'] &&
            !this.params['requestId']
          ) {
            this.section = 'Vendors';
            this.activeMenuUrl = '/vendors';
            if (this.router.url.indexOf('/score') != -1) {
              this.pageTitle = 'Vendor Score - ' + this.params['vendor'];
              this.crumbs = [
                {
                  text: 'Vendors',
                  link: '/vendors',
                  active: false,
                },
              ];
              this.instrumentationService.sendEvent({
                type: 'Page View',
                page: 'Vendor Details',
                vendor: this.params['vendor'],
              });
            } else if (this.params['domainId']) {
              this.section = 'Vendors';
              this.pageTitle = 'Data Details';
              this.crumbs = [
                {
                  text: 'Vendors',
                  link: '/vendors',
                },
                {
                  text: `${this.params['vendor']}`,
                  link: `/vendors/${this.params['vendor']}`,
                  fragment: 'data',
                },
              ];
            } else {
              this.pageTitle = 'Vendor Details';
              this.crumbs = [
                {
                  text: 'Vendors',
                  link: '/vendors',
                  active: false,
                },
              ];
              this.instrumentationService.sendEvent({
                type: 'Page View',
                page: `Vendor Details: (${this.params['vendor']})`,
                vendor: this.params['vendor'],
              });
            }
          } else if (this.params['incidentId']) {
            console.log('INCIDENT !!! ');
            this.activeMenuUrl = '/incidents';
            this.section = 'Incidents';
            this.pageTitle = 'Incident Details';
            this.crumbs = [
              {
                text: 'Incidents',
                link: '/incidents',
                active: false,
              },
            ];
            this.instrumentationService.sendEvent({
              type: 'Page View',
              page: `${this.pageTitle} (${this.params['incidentId']})`,
            });
          } else if (this.params['pageId']) {
            console.log('Params');
            console.log(this.params);
            this.activeMenuUrl = '/pages';
            this.section = 'Pages';
            this.pageTitle = 'Page Details';
            this.crumbs = [
              {
                text: 'Pages',
                link: '/pages',
                active: false,
              },
              {
                text: this.pageService.selectedPage.value.link,
                link: null,
                active: true,
              },
            ];
            if (this.params['pageHistoryId']) {
              this.pageTitle = 'Page History Details';
              console.log('pageHistoryId', this.params['pageHistoryId']);
              this.crumbs[1].link = `/pages/${this.params['pageId']}`;
              this.crumbs[1].active = false;

              if (this.router.url.endsWith('nodes') == false) {
                var crumbText = this.params['pageHistoryId'];
                if (
                  this.pageService.selectedPageHistory &&
                  this.pageService.selectedPageHistory.value &&
                  this.pageService.selectedPageHistory.value.lastChecked
                ) {
                  crumbText = `${this.timezoneService.format(
                    this.pageService.selectedPageHistory.value.lastChecked
                  )} Region: ${this.regionService.getDescription(
                    this.pageService.selectedPageHistory.value.region
                  )}`;
                }

                if (this.router.url.endsWith('analysis') == true) {
                  this.pageTitle = 'Page Run Analysis Details';
                  crumbText += ` Job Id: ${this.pageService.selectedPageHistory.value.id}`;
                }

                this.crumbs.push({
                  text: crumbText,
                  link: null,
                  active: false,
                });
              } else {
                this.crumbs[1].active = true;
                this.pageTitle = 'Page History Details - Nodes';
              }
              this.instrumentationService.sendEvent({
                type: 'Page View',
                page: `${this.pageTitle} (${this.params['pageHistoryId']})`,
              });
            } else {
              this.instrumentationService.sendEvent({
                type: 'Page View',
                page: `${this.pageTitle} (${this.params['pageId']})`,
              });
            }
          } else if (this.params['scriptId']) {
            this.activeMenuUrl = '/automatedUsers';
            this.pageTitle = 'Automated User History';
            this.section = 'Automated Users';
            this.crumbs = [
              {
                text: 'Automated Users',
                link: '/automatedUsers',
                active: false,
              },
              {
                text: this.pageRunnerService.selectedScript.value
                  .scriptFilePath,
                active: true,
              },
            ];
            this.instrumentationService.sendEvent({
              type: 'Page View',
              page: `${this.pageTitle} (${this.params['scriptId']})`,
            });
          } else if (this.params['testSuiteId']) {
            this.pageTitle = 'Test Suite';
            this.activeMenuUrl = '/validation/testSuites';
            this.crumbs = [
              {
                text: 'Test Suites',
                link: '/validation/testSuites',
                active: false,
              },
              {
                text: this.dataValidationService.selectedTestSuite.value.name,
                active: true,
              },
            ];

            if (this.params['validationRuleId']) {
              this.pageTitle = 'Rule History Details';

              this.crumbs = [
                {
                  text: 'Test Suites',
                  link: '/validation/testSuites',
                  active: false,
                },
                {
                  text: this.dataValidationService.selectedTestSuite.value.name,
                  link: `/validation/testSuites/${this.dataValidationService.selectedTestSuite.value.id}`,
                  active: false,
                },
                {
                  text: this.dataValidationService.selectedValidationRule.value
                    .name,
                  active: false,
                },
              ];
              this.instrumentationService.sendEvent({
                type: 'Page View',
                page: `${this.pageTitle} (${this.params['validationRuleId']})`,
              });
            } else {
              this.instrumentationService.sendEvent({
                type: 'Page View',
                page: `${this.pageTitle} (${this.params['testSuiteId']})`,
              });
            }
          } else if (this.params['userId']) {
            this.crumbs = [
              {
                text: 'Users',
                link: '/admin/users',
                active: false,
              },
            ];
            this.instrumentationService.sendEvent({
              type: 'Page View',
              page: ` User Details (${this.params['userId']})`,
            });
          } else if (this.params['domainDefId']) {
            let domainDefId = this.params['domainDefId'];
            let resourceMatcherId = this.params['resourceMatcherId'];
            let extractorId = this.params['extractorId'];

            this.crumbs = [];

            this.pageTitle = 'Vendor Library';
            this.crumbs.push({
              text: 'Domain Definitions',
              link: '/admin/vendorLibrary',
            });

            if (domainDefId) {
              let ddCrumb = {
                text: this.vendorLibState.selectedDomainDefinition.value.name,
                link: resourceMatcherId
                  ? `admin/vendorLibrary/domainDefinition/${domainDefId}`
                  : '',
                active: !resourceMatcherId,
              };
              this.crumbs.push(ddCrumb);
            }

            if (resourceMatcherId) {
              let rmCrumb = {
                text: this.vendorLibState.selectedResourceMatcher.value.name,
                link: extractorId
                  ? `admin/vendorLibrary/domainDefinition/${domainDefId}/resourceMatcher/${resourceMatcherId}`
                  : '',
                active: resourceMatcherId && !extractorId,
              };

              this.vendorLibState.selectedResourceMatcher.subscribe((value) => {
                rmCrumb.text = value.name;
              });

              this.crumbs.push(rmCrumb);
            }

            if (extractorId) {
              let extCrumb = {
                text: this.vendorLibState.selectedExtractor.value.name,
                active: true,
              };

              this.vendorLibState.selectedExtractor.subscribe((value) => {
                extCrumb.text = value.name;
              });

              this.crumbs.push(extCrumb);
            }
          } else if (this.params['configId']) {
            this.crumbs = [];
            this.pageTitle = 'Page / Link Configuration Management';
            this.crumbs.push(
              {
                text: 'Configuration Management',
                link: '/admin/configmanagement',
                active: false,
              },
              {
                text: this.configService.getConfigName(),
                active: true,
              }
            );
          } else if (this.params['vendor'] && this.params['requestId']) {
            console.log('bbbbb', this.data);

            let vendorName = this.data.vendorState?.vendorName;
            let requestName = this.data.vendorState?.requestName;

            this.pageTitle = 'Request Details';
            this.crumbs = [];
            this.crumbs.push(
              {
                text: 'Vendors',
                link: '/vendors',
                active: false,
              },
              {
                text: vendorName,
                link: `/vendors/${this.params['vendor']}`,
                active: false,
              },
              {
                text: requestName,
                active: true,
              }
            );
          } else if (this.params["appName"] && this.params["appVersion"]) {
            let appName = this.params["appName"];
            let appVersion = this.params["appVersion"];

            this.pageTitle = 'App Details';
            this.crumbs = [];
            this.crumbs.push(
              {
                text: 'Apps',
                link: '/mobile/apps',
                active: true,
              },
              {
                text: `${appName}`,
                active: false,
              }
            )
          } else {
            console.log('Clearing sitemap props');
            this.section = '';
            this.pageTitle = '';
            this.crumbs = [];
          }
        }
        this.title.setTitle(this.pageTitle);
        this.params = null;
      }
    });
  }

  update(route: ActivatedRoute) {
    route.params.subscribe((p) => {
      console.log('Sitemap Service - Params:', p);
      this.params = p;
    });
    route.data.subscribe((d) => {
      this.data = d;
    });
  }

  findMenuItemWithPath(url: string, items: VaultMenuItem[]): VaultMenuItem {
    url = window.decodeURI(url);
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if ('/' + item.link == url || item.link == url) return item;
      if (item.children) {
        let result = this.findMenuItemWithPath(url, item.children);
        if (result) return result;
      }
    }
    return null;
  }
}
