import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Papa } from 'ngx-papaparse';
import { inventoryData } from './data';

@Component({
  selector: 'app-data-viz2',
  templateUrl: './data-viz2.component.html',
  styleUrls: ['./data-viz2.component.css']
})
export class DataViz2Component implements OnInit {

  public data: Object[];
  public groupOptions: Object;
  public pageSettings: Object;
  public refresh: Boolean;
  @ViewChild('grid')
  public grid: GridComponent;
  @ViewChild('alertDialog')
  // public alertDialog: DialogComponent;
  public alertHeader: string = 'Grouping';
  public hidden: Boolean = false;
  public target: string = '.control-section';
  public alertWidth: string = '300px';
  public alertContent: string = 'Grouping is disabled for this column';
  public showCloseIcon: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public alertDlgBtnClick = () => {
      // this.alertDialog.hide();
  }
  public alertDlgButtons: Object[] = [{ click: this.alertDlgBtnClick.bind(this), buttonModel: { content: 'OK', isPrimary: true } }];

  constructor(public papa:Papa) { }

  ngOnInit(): void {
      // this.data = inventoryData;
      this.groupOptions = { showGroupedColumn: false, columns: ['Vendor', 'Cookie Name'], enableLazyLoading: true  };
      this.pageSettings = { pageCount: 5 };

      this.papa.parse('https://ui.dev.vaultjs.com/assets/data/vivint/thirdPartyCookiesReport.csv', {
        header: true,
        download: true,
        skipEmptyLines: "greedy",
        complete: (results, file)=>{
          console.log(results);
          this.data = results.data;
          // this.pivotData = results as unknown as IDataSet[];
          // this.pivotData = [
          //   { 'Sold': 31, 'Amount': 52824, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q1' },
          //   { 'Sold': 51, 'Amount': 86904, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q2' },
          //   { 'Sold': 90, 'Amount': 153360, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q3' },
          //   { 'Sold': 25, 'Amount': 42600, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q4' },
          //   { 'Sold': 27, 'Amount': 46008, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2016', 'Quarter': 'Q1' }
          // ];
          // this.dataSourceSettings = {
          //   dataSource: this.pivotData,
          //   expandAll: false,
          //   columns: [{ name: 'Year', caption: 'Production Year' }, { name: 'Quarter' }],
          //   values: [{ name: 'Sold', caption: 'Units Sold', type: 'Index' }, { name: 'Amount', caption: 'Sold Amount' }],
          //   rows: [{ name: 'Country' }, { name: 'Products' }]
          // };
        },
        error: (error, file)=>{
          console.log(error);
        }
      });


  }
  dataBound() {
      if(this.refresh){
          this.grid.groupColumn('Vendor');
          this.refresh =false;
      }
  }
  load() {
      this.refresh = (<any>this.grid).refreshing;
  }
  created() {
      this.grid.on("columnDragStart", this.columnDragStart, this);
  }
  public columnDragStart(args: any) {
      if(args.column.field === "Mainfieldsofinvention"){
          // this.alertDialog.show();
     }
  }

}
