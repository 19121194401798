import { Injectable } from '@angular/core';
import { VaultBaseService } from '../VaultBaseService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import * as AWS from 'aws-sdk';
import { S3 } from 'aws-sdk';
import { from } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { catchError } from 'rxjs/operators';
import { String } from 'aws-sdk/clients/cloudtrail';

@Injectable({
  providedIn: 'root'
})
export class DataExportService extends VaultBaseService {

  public static PAGES: string = "pages";
  public static VENDORS: string = "vendors";
  public static CODE_FILES_REPORT: string = "codeFiles";
  public static ALL_CODE_FILES_REPORT: string = "allCodeFiles";

  public static PDF_EXTENSION: string = 'pdf';
  public static PDF_TYPE: string = 'application/pdf';

  public static CSV_EXTENSION: string = 'csv';
  public static CSV_TYPE: string = 'text/csv';

  public static PNG_EXTENSION: string = 'png';
  public static PNG_TYPE: string = 'image/png';

  public static JSON_EXTENSION: string = 'json';
  public static JSON_TYPE: string = 'application/json';

  public static ZIP_EXTENSION: string = 'zip';
  public static ZIP_TYPE = 'application/zip';

  public static JS_EXTENSION = 'js';
  public static JS_TYPE = 'text/javascript';

  constructor(authHttp: HttpClient, private authService: AuthService) {
    super(authHttp);
  }

  public startDataExport(reportName: string, vendorId: number = null): Observable<any> {
    const url = `${env.api.url}exportData?reportName=${reportName}${vendorId ? "&vendorId=" + vendorId.toString() : ""}`;
    return this.authHttp.post(url, null);
  }

  public getPageRunnerOutputFile(dataLocation: string, newBucket: boolean = false, excludeGz: boolean = false): Observable<any> {

    var pageRunnerBucket = env.aws.pageRunnerBucket;
    if (dataLocation.indexOf("2019/") == 0) {
      pageRunnerBucket = env.aws.pageRunnerBucketLegacy;
    }

    if (newBucket == true) {
      pageRunnerBucket = env.aws.dataCollectorBucket;
    }

    return this.getDataExportFile(dataLocation, pageRunnerBucket, excludeGz);
  }

  public getScriptedPageRunnerOutputFile(dataLocation: string, excludeGz: boolean = false): Observable<any> {

    var bucket = env.aws.scriptedDataCollectorBucket;

    return this.getDataExportFile(dataLocation, bucket, excludeGz);
  }

  public getQueryResultFile(dataLocation: string): Observable<any> {

    var bucket = env.aws.exportBucket;

    return this.getDataExportFile(dataLocation, bucket, true);
  }

  public getAnalysisFile(dataLocation, bucket = null): Observable<any> {

    var analysisBucket = bucket ? bucket : env.aws.codeFileBucket;
    return this.getDataExportFile(dataLocation, analysisBucket);
  }

  public getScheduledReportFile(dataLocation: string): Observable<any> {

    var bucket = env.aws.scheduledReportsBucket;

    return this.getDataExportFile(dataLocation, bucket, true);
  }

  public getApiCache(dataLocation): Observable<any> {
    const cacheBucket = env.aws.apiCacheBucket;
    return this.getDataExportFile(dataLocation, cacheBucket, true);
  }

  public getConsentFile(dataLocation: string): Observable<any> {
    const bucket = env.aws.consentReportBucket;
    return this.getDataExportFile(dataLocation, bucket, true);
  }

  public getDashboardFile(dataLocation: string, clientId: number): Observable<any> {
    const bucket = env.aws.customReportBucket + "-" + clientId.toString();
    return this.getDataExportFile(dataLocation, bucket, true);
  }
  public getVPPAReport(clientId: number): Observable<any> {
    const bucket = env.aws.customReportBucket + "-" + clientId.toString();
    return this.getDataExportFile("vppa_report.tsv", bucket, true);
  }
  public getTrackerSummary(clientId:number,filename:string=null):Observable<any>{
    const bucket = env.aws.customReportBucket + "-" + clientId.toString();
    if ( filename || filename == ""){
      return this.getDataExportFile(filename, bucket, true);
    }
    return this.getDataExportFile("tracker_summary.csv", bucket, true);
  }
  public getTrackerVendors(clientId:number,filename:string=null):Observable<any>{
    const bucket = env.aws.customReportBucket + "-" + clientId.toString();
    if ( filename || filename == ""){
      return this.getDataExportFile(filename, bucket, true);
    }
    return this.getDataExportFile("tracker_vendors.csv", bucket, true);
  }

  public getDataExportFile(key, bucket: string = env.aws.exportBucket, excludeGz: boolean = false): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {

        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var txKey = (key.indexOf(".") == -1 ? key + ".raw" : key) + ".gz";
        if (txKey.endsWith("/performanceMetrics.json.gz")
          || txKey.endsWith("/vendors.pdf.gz")
          || txKey.endsWith("/vendors.csv.gz")
          || txKey.endsWith("/pages.csv.gz")
          || txKey.endsWith("/codeFiles.csv.gz")
          || txKey.endsWith("/allCodeFiles.csv.gz")
          || txKey.endsWith("/tagReport.zip.gz")
          || txKey.endsWith("/fileNames.json.gz")
        ) {
          if (bucket != env.aws.dataCollectorBucket) {
            txKey = txKey.substr(0, txKey.length - 3);
            console.log(txKey);
          }
        }

        if (bucket == env.aws.codeFileBucketLegacy) {
          txKey = key;
        }

        if (excludeGz == true) {
          txKey = key;
        }

        var params = {
          Bucket: bucket,
          Key: txKey
        };
        const s3 = new S3();
        from(s3.getObject(params).promise()).pipe(catchError(error => of(error))).subscribe((response) => {
          // console.log("XXXX", response);
          if (response && response.Body) {
            resolve(response.Body);
          } else {
            reject(response);
          }
        });
      });
    });

    return from(retPromise);
  }

  public getSignedScreenShotUlr(key): String {
    return this.getSignedUrlSync(key, env.aws.pageRunnerBucket);
  }

  public getSignedUrl(key): Observable<any> {
    var pageRunnerBucket = env.aws.pageRunnerBucket;
    if (key.indexOf("2019/") == 0) {
      pageRunnerBucket = env.aws.pageRunnerBucketLegacy;
    }

    if ((key as string).startsWith(env.aws.dataCollectorBucket)) {
      pageRunnerBucket = env.aws.dataCollectorBucket;
      key = (key as string).replace(pageRunnerBucket + "/", "");
    }

    if ((key as string).startsWith(env.aws.scriptedDataCollectorBucket)) {
      pageRunnerBucket = env.aws.scriptedDataCollectorBucket;
      key = (key as string).replace(pageRunnerBucket + "/", "");
    }

    if ((key as string).startsWith(env.aws.exportBucket)) {
      pageRunnerBucket = env.aws.exportBucket;
      key = (key as string).replace(pageRunnerBucket + "/", "");
    }

    return this._getSignedUrl(key, pageRunnerBucket);
  }

  private _getSignedUrl(key, bucket): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;

        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: bucket,
          Key: key
        };

        const s3 = new S3();
        const signedUrl = s3.getSignedUrl('getObject', params);
        resolve(signedUrl);
      });
    });

    return from(retPromise);
  }

  private getSignedUrlSync(key, bucket): String {
    AWS.config.region = env.aws.region;

    AWS.config.credentials = new AWS.Credentials(this.authService.awsCreds);

    var params = {
      Bucket: bucket,
      Key: key
    };

    const s3 = new S3();
    const signedUrl = s3.getSignedUrl('getObject', params);
    return signedUrl;
  }

  public saveFile(fileName, data, type: string = DataExportService.CSV_TYPE): void {

    var blob;
    if (type == DataExportService.PDF_TYPE) {
      var buf = new Uint8Array(data as ArrayBuffer);
      blob = new Blob([buf], { type: type });
    } else {
      blob = new Blob([data], { type: type });
    }

    var e = document.createEvent('MouseEvents'),
      a = document.createElement('a');


    console.log("xxx", fileName);

    a.download = fileName.indexOf(".") == -1 ? `${fileName}.${type}` : fileName;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = [type, a.download, a.href].join(':');
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
  }


  private getS3Object(bucket, key): Observable<any> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {

        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);



        var params = {
          Bucket: bucket,
          Key: key
        };
        const s3 = new S3();
        from(s3.getObject(params).promise()).pipe(catchError(error => of(error))).subscribe((response) => {
          // console.log("XXXX", response);
          if (response && response.Body) {
            resolve(response.Body);
          } else {
            reject(response);
          }
        });
      });
    });

    return from(retPromise);
  }

  public getCodeFileBeautified(hash: string): Observable<any> {
    return this.getS3Object(env.aws.codeFileBucket, `${hash}.beautified.gz`);
  }

  public getCodeFileRaw(key): Observable<any> {
    return this.getS3Object(env.aws.codeFileBucket, `${key}.raw.gz`);
  }

  public getCodeFileAst(key): Observable<any> {
    return this.getS3Object(env.aws.codeFileBucket, `${key}.ast.gz`);
  }

  public getCodeFileDeobfuscated(key): Observable<any> {
    return this.getS3Object(env.aws.codeFileBucket, `${key}.deobfuscated.gz`);
  }

  public getCodeFileSimpleAnalysis(key): Observable<any> {
    return this.getS3Object(env.aws.codeFileBucket, `${key}.simpleAnalysis.gz`);
  }

  public getCodeFileRetireJs(key): Observable<any> {
    return this.getS3Object(env.aws.codeFileBucket, `${key}.retire.gz`);
  }

  public getNewCodeFileHashes(): Observable<any> {
    return this.getS3Object(env.aws.newHashFileBucket, 'newHashes000');
  }

}