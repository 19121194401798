<div class="card">
    <div class="card-header">
        Pages With Correct Configuration
        <br>
        By Site Group
    </div>
    <div class="card-body">
        <div class="container" *ngFor="let group of dataSource;">
            <div class="metric-title">
                {{group.name}}
            </div>
            <div class="bar-container">
                <div class="progress-bar" [ngStyle]="{'width': group.width + '%', 'background-color': group.color}">
                    <div class="percent" style="padding: 0px 5px; color: white">{{group.percent}}%</div>
                </div>
            </div>
        </div>
    </div>
</div>