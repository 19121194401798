// TODO: jobs count
// TODO: fitler for analysis events
// FIXME: Crashes on too many events
// FIXME: URL leaks out  needs max width


import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  find,
  retry,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import {
  LogMessage as NgxLogMessage,
  LogMonitorComponent,
} from 'ngx-log-monitor';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment as env } from '../../../../../environments/environment';
let worldMap: object[] = require('./worldMap.json');
let population: object[] = require('./population.json');
import {
  MapsTheme,
  Maps,
  Marker,
  MapsTooltip,
  ILoadEventArgs,
  MapsTooltipService,
  MarkerService,
  DataLabelService,
  MapsComponent,
  ILoadedEventArgs,
} from '@syncfusion/ej2-angular-maps';
import { RegionService } from '../../../../services/region/region.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { eventTypes } from './event-types';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'app-event-viewer-v2',
  templateUrl: './event-viewer-v2.component.html',
  styleUrls: ['./event-viewer-v2.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    MapsTooltipService,
    MarkerService,
    MapsComponent,
    DataLabelService,
  ],
})
export class EventViewerV2Component implements OnInit {
  @ViewChild('logGrid') public logGrid: GridComponent;
  @ViewChild('map') public map: MapsComponent;
  @ViewChild('logMonitor') public logMonitor: LogMonitorComponent;
  // @ViewChild('searchTextBox') public searchTextBox;
  endpoint = '';
  messages = [];
  websocket = undefined;
  subject;
  logStream$;
  logHistory;
  subscription;
  eventLogInternal;
  searchTerm$ = new Subject<string>();
  searchText;
  filteredHistory;
  delay = 0;
  ticksLables = ['0', '10'];

  showCodeFileChangeEvents: boolean = false;
  showCodeAnalysisCompleteEvents: boolean = false;
  showInstrumentationEvents: boolean = false;
  showNewCodeFileEvents: boolean = false;
  showNewIncidentEvents: boolean = false;
  showPageRunCompletEvents: boolean = false;
  showPageRunStartEvents: boolean = false;
  showReportCompleteEvents: boolean = false;
  showTimerCompleteEvents: boolean = false;
  showValidationResultEvents: boolean = false;
  showAllEvents: boolean = true;
  public cdnUrl: string;
  public titleSettings: object = {
    text: 'Data Centers',
    textStyle: {
      size: '16px',
    },
  };

  regionTimers: any;

  public layers: object[];

  constructor(
    private regionService: RegionService,
    private vendorsService: VendorsService
  ) {
    this.cdnUrl = env.cdn.url;
    this.logHistory = [];
    this.isPaused = false;
    this.eventLogInternal = [];
    this.regionTimers = {};
    this.layers = [
      {
        shapeData: worldMap,
        type: 'Sublayer',
        dataSource: this.regionService.regionsArray,
        shapeSettings: { fill: '#6390ee' },
        markerSettings: [
          {
            dataSource: Array.from(this.regionService.regionsArray),
            visible: true,
            shape: 'Circle',
            fill: 'white',
            width: 5,
            border: { width: 2, color: '#285255' },
            tooltipSettings: {
              visible: true,
              valuePath: 'name',
            },
          },
        ],
      },
      {
        shapeData: worldMap,
        shapeSettings: { fill: '#6390ee' },
        markerSettings: [
          {
            dataSource: Array.from(this.regionService.regionsArray),
            visible: true,
            template:
              '<div id="template ${name}"><div class="pulse-css"><br /></div></div>',
            tooltipSettings: {
              template:
                '<div><div class="toolback"><div class="listing2"><center> ${description} </center></div></div>',
              visible: true,
              valuePath: 'name',
            },
          },
        ],
      },
    ];
  }
  private vendorList = [];
  ngOnInit(): void {
    // this.endpoint = 'wss://17vpxar0ih.execute-api.us-east-1.amazonaws.com/dev';
    this.vendorsService.getVendors().subscribe((vendors) => {
      this.vendorList = vendors;
    });
    this.endpoint = env.api.wsurl;
    this.logStream$ = new Subject<NgxLogMessage>();
    this.connectToWebSocket();
    // this.testData = this._testData.map((item) => {
    //   return this.eventFormatter(item);
    // })
  }

  connectToWebSocket() {
    this.subject = webSocket({
      url: `${this.endpoint}?t=${localStorage.getItem(
        'access_token'
      )}&cid=${localStorage.getItem('cid')}`,
      openObserver: {
        next: () => {
          console.log('WS Open');
        },
      },
      closingObserver: {
        next: () => {
          console.log('WS Closing...');
        },
      },
      closeObserver: {
        next: () => {
          console.log('WS Close...');
        },
      },
    });
    this.start();
  }

  start() {
    console.log('start', this.delay);
    let delayMs = this.delay * 1000;
    console.log(delayMs);
    this.subscription = this.subject.pipe(retry(5), delay(delayMs)).subscribe(
      (event) => {
        this.eventLogInternal.unshift(this.eventFormatter(event));
        console.log(event);
        this._logEvents.next(this.eventLogInternal);
        if (!this.isPaused) {
          this.logGrid.refresh();
        }
      },
      (err) => {
        console.log('Websocket error', err);
      },
      () => {
        console.log('Websocket stream complete');
        this.subject.unsubscribe();
        this.subscription.unsubscribe();
        this.connectToWebSocket();
      }
    );
  }
  @ViewChild('jobGrid') public jobGrid: GridComponent;
  private _jobList = [
    // {
    //   jobId: 'c0f845c6-fa38-42fa-8700-dd3bb9168449',
    //   url: 'http://test.vaultjs.com/google.html',
    //   region: 'us-east-1',
    //   scheduled: true,
    //   pageRunStarted: true,
    //   pageRunCompleted: true,
    //   analysisEvents: 24,
    //   validationEvents: 23,
    //   analysisStatus: 'ongoing',
    // },
  ];

  public set jobList(value: any) {
    this._jobList.push(value);
    this.jobGrid.refresh();
  }
  public get jobList() {
    return this._jobList;
  }

  public updateJobList(index, key, value) {
    this._jobList[index][key] = value;
    this.jobGrid.refresh();
  }
  public eventLogCount = 0;
  public scheduledJobsCount = 0;
  public pageRunCount = 0;
  public pageCompleteCount = 0;
  public analysisCount = 0;
  public validationCount = 0;

  eventFormatter(event) {
    let findIndx = -1;
    const jid = event.jobId || event.pageRunnerJobUuid || event.jobUuid;
    if (jid) {
      event.jobId = jid;
      console.log('FOUND JOB ID', jid);
      findIndx = this.jobList.findIndex((item) => {
        return (
          item.jobId === event.jobId ||
          item.jobId === event.pageRunnerJobUuid ||
          item.jobId === event.jobUuid
        );
      });
      if (findIndx === -1) {
        this.jobList = {
          jobId: jid,
          url: event.url || null,
          region: event.region || null,
          scheduled: false,
          pageRunStarted: false,
          pageRunCompleted: false,
          analysisEvents: 0,
          validationEvents: 0,
          analysisStatus: 'ongoing',
        };
        findIndx = this.jobList.length - 1;
      }
    }
    const findEventType = eventTypes.find((item) => {
      return item.type === event.eventTypeName;
    });
    if (!event.timestamp) {
      event.timestamp = new Date();
    }
    this.eventLogCount++;
    if (this.isPaused) {
      this.inBuffer++;
    }
    // color swap here
    if (findEventType) {
      event.color = findEventType.color;
      event.iconCss = findEventType.iconCss;
    } else {
      event.color = 'white';
      event.iconCss = 'zmdi zmdi-label-alt-outline';
    }
    /////
    if (event.event === 'scheduler-job-scheduled') {
      this.scheduledJobsCount++;
      if (findIndx !== -1) {
        this.updateJobList(findIndx, 'scheduled', true);
      }
    }
    if (event.event === 'page-run-start') {
      this.pageRunCount++;
      if (findIndx !== -1) {
        this.updateJobList(findIndx, 'pageRunStarted', true);
      }
    }
    if (event.event === 'page-run-complete') {
      this.pageCompleteCount++;
      if (findIndx !== -1) {
        this.updateJobList(findIndx, 'pageRunCompleted', true);
      }
    }

    if (event.event.indexOf('analysis-') > -1) {
      this.analysisCount++;
      event.analysisEvent = true;
      if (findIndx !== -1) {
        if (event.event === 'analysis-finishAnalysis') {
          this.updateJobList(findIndx, 'analysisStatus', 'completed');
        } else {
          this.updateJobList(
            findIndx,
            'analysisEvents',
            this.jobList[findIndx].analysisEvents + 1
          );
        }
        // this.jobList[findIndx].analysisEvents= true;
      }
    }
    if (event.event === 'validation-result') {
      this.validationCount++;
      event.validationEvent = true;
      if (findIndx !== -1) {
        this.updateJobList(
          findIndx,
          'validationEvents',
          this.jobList[findIndx].validationEvents + 1
        );
      }
    }

    /////
    if (event.eventTypeName === 'Classify With Vendor Library') {
      const hostname = new URL(event.url)?.hostname;
      event.hostname = hostname;
      const vendor = this.vendorList.find((item) => {
        return item.id === event.calculatedVendorId;
      });
      if (vendor) {
        event.vendorName = vendor.name;
        event.website = vendor.website;
      }
    }
    return event;
  }
  eventToString(event) {
    switch (event.eventTypeName) {
      case 'Instrumentation':
        return `${event.type} - ${event.page} - ${event.user}`;
      case 'Code File Change':
        return `${event.url} (Id: ${event.codeFileId}, History Id: ${event.codeFileHistoryId}, UUID: ${event.uuid}, Hash: ${event.hash}, Size: ${event.responseSize}, Valid JS: ${event.isValidJS})`;
      case 'Page Run Complete':
        return `${event.url} (Job ID: ${event.jobId} Region: ${event.region})`;
      case 'Validation Result':
        return `${event.documentUrl} Result Message: ${event.resultMessage} Result Data: ${event.resultData} (Key: ${event.key}, Test Suite UUID: ${event.testSuiteUuid})`;
      default:
        return JSON.stringify(event);
    }
  }
  onJobRowSelected($event) {
    console.log($event);
    const jobId = $event.data.jobId;
    this.filterByJobId(jobId);
  }
  filterByJobId(jobId) {
    this.logGrid.filterByColumn('jobId', 'equal', jobId);
  }

  onSlideStop(event) {
    console.log('Slider Stop');
    console.log(event);
    this.subscription.unsubscribe();
    setTimeout(() => {
      this.start();
    }, 100);
  }

  public loaded = (args: ILoadedEventArgs) => {
    let lines: Object[] = args.maps.layers[0].markerSettings[0]
      .dataSource as Object[];
    for (let i: number = 0; i < lines.length; i++) {
      let region: string = lines[i]['name'];
      let marker: HTMLElement = document.getElementById(`template ${region}`);
      if (marker) {
        marker.style.visibility = 'hidden';
      }
    }
  };

  private _logEvents: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public get logEvents() {
    return this._logEvents.asObservable();
  }
  public filters = {
    'All Events': true,
    'Page Run Start': true,
    'Page Run Complete': true,
    'Classify With Vendor Library': true,
    Instrumentation: true,
    'Validation Result': true,
  };
  public filterList = [
    'All Events',
    'Page Run Start',
    'Page Run Complete',
    'Classify With Vendor Library',
    'Instrumentation',
    'Validation Result',
  ];
  public onFilterClick($event) {
    if ($event === 'All Events') {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = true;
      });
      this.logGrid.clearFiltering();
      return;
    }
    this.filters[$event] = !this.filters[$event];
    this.doFilter();
  }

  public doFilter() {
    this.logGrid.clearFiltering();
    this.logGrid.filterByColumn(
      'eventTypeName',
      'notEqual',
      Object.keys(this.filters).filter((key) => {
        return this.filters[key] !== true;
      }),
      'and',
      true
    );
  }
  public isPaused: boolean = false;
  public inBuffer: number = 0;
  public onPauseClick() {
    this.isPaused = !this.isPaused;
    if (this.isPaused) {
      this.inBuffer = 0;
    }else{
      this.logGrid.refresh();
    }
  }
  @ViewChild('searchTextBox') searchTextBox: TextBoxComponent;

  onSearchType(){
    const searchValue = this.searchTextBox.value;
    this.logGrid.search(searchValue);
  }
}
