import { Injectable } from '@angular/core';
import { DateTime } from "luxon";

// import * as moment from 'moment';
// import "moment-timezone";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  
  private static DEFAULT_FORMAT = "D h:mm a ZZZZ";

  private isUtcDefaultForUI:boolean = false;
  private uiDateTimeFormatString:string = TimezoneService.DEFAULT_FORMAT;
  private localZone:string;
  public static UTC_ZONE = {zone: 'utc'};

  constructor() { 
    console.log('TZ Constructor!');
    let customerString = localStorage.getItem('customer');
    if ( customerString ){
      let customer = JSON.parse(customerString);
      if (customer){
        console.log("Setting tz from localstorage")
        this.isUtcDefaultForUI = customer.isUtcDefaultForUI == 1 ? true : false;
        this.uiDateTimeFormatString = customer.uiDateTimeFormatString != null && customer.uiDateTimeFormatString != "" ? customer.uiDateTimeFormatString : TimezoneService.DEFAULT_FORMAT;
      } else {
        console.log("TZ customer was null")
      }
    } else {
      console.log("TZ no customer in localstorage")
    }

    this.localZone = DateTime.local().zoneName;
    console.log('TZ Local Zone', this.localZone)
  }

  public updateSettings(isUtcDefaultForUI:boolean, uiDateTimeFormatString:string){
    console.log('TZ settings update', isUtcDefaultForUI, uiDateTimeFormatString);
    this.isUtcDefaultForUI = isUtcDefaultForUI;
    this.uiDateTimeFormatString = uiDateTimeFormatString != null ? uiDateTimeFormatString : TimezoneService.DEFAULT_FORMAT;
  }

  public updateDefaultSetting(isUtcDefaultForUI:boolean){
    this.isUtcDefaultForUI = isUtcDefaultForUI;
  }

  public updateFormatSetting(format:string){
    this.uiDateTimeFormatString = format;
  }

  public getFormatString():string{
    return this.uiDateTimeFormatString;
  }

  public unformatted(date, isISO=false, isEpoch=false, isRedshift=false):DateTime{
    var result;

    if ( date == null ){
      return DateTime.invalid("Null");
    }
    
    if ( date == null ){
      return DateTime.invalid("Null");
    }

    result = DateTime.fromSQL(date, TimezoneService.UTC_ZONE);
    if ( isISO == true ) {
      result = DateTime.fromISO(date, TimezoneService.UTC_ZONE);
    } else if ( isEpoch == true ){
      result = DateTime.fromMillis(parseInt(date), TimezoneService.UTC_ZONE);
    } else if ( isRedshift == true ){
      result = DateTime.fromFormat(date, "yyyy-LL-dd", TimezoneService.UTC_ZONE);
    }
    
    if (this.isUtcDefaultForUI == false) {
      result = result.setZone(this.localZone);  
    }    
    return result;
  }

  public format(date, isISO=false, isEpoch=false, isRedshift=false, formatString=this.uiDateTimeFormatString):string {
    let hasWackySuffix = (date + "").endsWith(".000Z");
    let hasOtherWackySuffix = (date + "").endsWith("Z");
    if ( hasWackySuffix == true || hasOtherWackySuffix == true){
      isISO = true; 
    }
    
    let result = this.unformatted(date, isISO, isEpoch, isRedshift).toFormat(formatString);
    if ( result == 'Invalid DateTime') return '(No timestamp available)';
    return result;
  }

}
