<div class="row">
  <div class="col-md-12">
    <div class="card be-loading">
      <div class="card-header card-header-divider">Dev Tools<span class="card-subtitle">Internal tools for Vault JS developers</span></div>
        <div class="card-body">
          <div class="tab-container">
            <ul role="tablist" class="nav nav-tabs">
              <li class="nav-item"><a href="#newCustomer" data-toggle="tab" role="tab" class="nav-link" [class.active]="newCustomerActive"><span class="icon zmdi zmdi-account-add"></span>Create Customer<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Create a new customer." triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#customerInfo" data-toggle="tab" role="tab" class="nav-link" [class.active]="customerInfoActive"><span class="icon zmdi zmdi-collection-text"></span>Customer Info<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Update properties for this customer." triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#incidentMessages" data-toggle="tab" role="tab" class="nav-link" [class.active]="incidentMessagesActive"><span class="icon zmdi zmdi-format-list-bulleted"></span>Incident Messages<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Manage the suggested incident names." triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#regexManager" (click)="onRegexManagerFocus()" data-toggle="tab" role="tab" class="nav-link" [class.active]="regexManagerActive"><span class="icon zmdi zmdi-collection-plus"></span>Regex Manager<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Manage regular expressions used for auto creating incidents." triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#regexTester" data-toggle="tab" role="tab" class="nav-link" [class.active]="regexTesterActive"><span class="icon zmdi zmdi-language-javascript"></span>Regex Tester<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Test classification regular expressions" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#idpConnection" data-toggle="tab" role="tab" class="nav-link" [class.active]="idpConnectionActive"><span class="icon zmdi zmdi-globe-alt"></span>IdP Connection<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Set the Auth0 connection name if a SAML provider is configured for this customer" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#favicons" data-toggle="tab" role="tab" class="nav-link" [class.active]="faviconsActive"><span class="icon zmdi zmdi-spinner"></span>Vendor/Customer Icons<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Run job to download vendor icons." triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#eventTrigger" (click)="onEventTriggerFocus()" data-toggle="tab" role="tab" class="nav-link" [class.active]="eventTriggerActive"><span class="icon zmdi zmdi-cloud-upload"></span>Trigger Event<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Immediately send event through event pipeline" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#reports" data-toggle="tab" role="tab" class="nav-link" [class.active]="reportsActive"><span class="icon zmdi zmdi-collection-text"></span>Reports<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Toggle scheduled reports" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#localCustomReports" data-toggle="tab" role="tab" class="nav-link" [class.active]="customReportsActive"><span class="icon zmdi zmdi-collection-text"></span>Custom Reports Menu Items (Local Only)<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Custom Reports Menu Items (Local Only)" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#persistentCustomReports" data-toggle="tab" role="tab" class="nav-link" [class.active]="persistentCustomReportsActive"><span class="icon zmdi zmdi-collection-text"></span>Persistent Custom Reports Menu Items<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Persistent Custom Reports Menu Items" triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a href="#refreshData" data-toggle="tab" role="tab" class="nav-link" [class.active]="refreshDataActive"><span class="icon zmdi zmdi-collection-text"></span>Refresh UI Data<span class="icon zmdi zmdi-info tab-help" data-container="body" ngbPopover="Trigger jobs to: reload data the UI displays; refresh the vendor library (for use by backend services.) Note that these jobs can take a while to complete even after the request is sent successfully." triggers="mouseenter:mouseleave"></span></a></li>
            </ul>
          </div>
          <div class="tab-content">
            <div id="newCustomer" role="tabpanel" class="tab-pane" [class.active]="newCustomerActive">
              <form [formGroup]="newCustomerForm" (ngSubmit)="createCustomer()">
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Customer Name:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Friendly name for customer. Will be displayed at top right when users log in."></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="customerName">
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Customer Domain:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Main domain for customer i.e. vaultjs.com (leave off http and www.)"></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="customerDomain">
                  </div>
              </div>
                <div style="text-align: center">
                  <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!newCustomerForm.valid">Create</button>
                </div>
              </form>
            </div>
            <div id="customerInfo" role="tabpanel" class="tab-pane" [class.active]="customerInfoActive">
              <form [formGroup]="customerInfoForm" (ngSubmit)="updateCustomerInfo()">
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Customer Name:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Friendly name for customer. Will be displayed at top right when users log in."></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="name">
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Icon File Name:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Icon file name (leave off .ico). For example, vaultjs.com"></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="icon" placeholder="customername.com">
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Products:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="The products this customer is paying for."></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <ejs-multiselect #products
                      id='products' 
                      [dataSource]='allProducts'
                      [fields]="productsFields"
                      mode="Box"
                    >
                    </ejs-multiselect>
                  </div>
                </div>
                <div style="text-align: center">
                  <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!customerInfoForm.valid">Save</button>
                </div>
              </form>
            </div>
            <div id="incidentMessages" role="tabpanel" class="tab-pane" [class.active]="incidentMessagesActive">
              <form [formGroup]="incidentMessagesForm">
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Add a message:</label>
                  <div class="row col-8">
                    <div class="col-6">
                      <input class="form-control" type="text" formControlName="newMessage">
                    </div>            
                    <div class="col-2">
                      <button type="button" (click)="addMessage();" class="btn btn-rounded btn-space btn-primary" style="text-align: center;">Add</button>
                    </div>
                  </div>              
                </div> 
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Existing Messages:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <select class="form-control messages" formControlName="messages" name="messages">
                      <option *ngFor="let msg of messages;" [ngValue]="msg">{{msg.message}}</option>
                    </select>
                    <button type="button" class="btn btn-space btn-secondary btn-sm" (click)="deleteMessages();">Delete selected</button>
                  </div>
                </div>
              </form>
            </div>
            <div id="regexManager" role="tabpanel" class="tab-pane" [class.active]="regexManagerActive">
              <form [formGroup]="regexMgrForm">                
                <div class="form-group row pt-1">
                  <label class="col-6 col-sm-3 col-form-label text-sm-right">Vendor:</label>
                  <div class="col-6 col-sm-8 col-lg-6">
                    <select class="form-control vendors" formControlName="vendor">
                      <option value=""></option>
                      <option *ngFor="let vendor of vendors;" [ngValue]="vendor.name">{{vendor.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-6 col-sm-3 col-form-label text-sm-right">Rule:</label>
                  <div class="row col-8">
                      <div class="col-6">
                        <select *ngIf="!isNewRule" class="form-control rules" formControlName="rule">
                          <option value=""></option>
                          <option *ngFor="let rule of rules;" [ngValue]="rule.id">{{rule.name}} ({{rule.enabled ? "+" : "-"}})</option>
                        </select>
                        <input *ngIf="isNewRule" class="form-control" type="text" name="ruleName" formControlName="ruleName" placeholder="Enter a name...">
                      </div>
                      <div class="col-2">
                        <button *ngIf="!isNewRule" type="button" class="btn btn-rounded btn-space btn-primary" (click)="createNewRule();">New Rule</button>
                        <button *ngIf="isNewRule" type="button" class="btn btn-rounded btn-space btn-warning" (click)="cancelNewRule();">Cancel</button>
                      </div>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Enabled:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <label class="custom-control custom-checkbox custom-control-inline">
                      <input class="custom-control-input" type="checkbox" name="enabled" formControlName="enabled"><span class="custom-control-label"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Target:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Specify if regex is applied to entire file or only the lines that changed."></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <label class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="target" formControlName="target" value="1"><span class="custom-control-label">All Code</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="target" formControlName="target" value="2"><span class="custom-control-label">Just Changes</span>
                    </label>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Severity:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Specify the threat level of this change."></span></label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <label class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="severity" formControlName="severity" value="1"><span class="custom-control-label">Green</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="severity" formControlName="severity" value="2"><span class="custom-control-label">Yellow</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="severity" formControlName="severity" value="3"><span class="custom-control-label">Red</span>
                    </label>
                  </div>
                </div>
                <div>
                  <div formArrayName="regexes" *ngFor="let regex of regexes.controls; let i=index">
                    <div class="form-group row pt-1" [formGroupName]="i">
                      <label class="col-12 col-sm-3 col-form-label text-sm-right">Regex:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="The regex to match changes against. Use a single backslash to escape characters. See regexr.com for help with regexes."></span></label>
                      <div class="col-12 col-sm-8 col-lg-6">
                        <ngx-codemirror #regexEditor style="height: 150px;" id="regex" name="regex" formControlName="regex" [options]="codeMirrorOptions"></ngx-codemirror>
                      </div>
                      <div *ngIf="regexes.controls.length > 1" class="text-sm-right">
                        <span style="cursor: pointer;" class="icon zmdi zmdi-minus-circle-outline" (click)="removeRegex(i)"></span>
                      </div>
                    </div>                
                  </div>
                  <div *ngIf="isChangesTarget" class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <a style="cursor: pointer;" (click)="addRegex()">+ Add another regex</a>
                    </div>
                  </div>
                </div>
                <div style="text-align: center">                  
                  <button *ngIf="!isNewRule" type="button" class="btn btn-rounded btn-space btn-danger" (click)="deleteRule()">Delete</button>
                  <button type="button" class="btn btn-rounded btn-space btn-success" [disabled]="!regexMgrForm.valid" (click)="saveUpdateRule()">{{ isNewRule ? 'Save' : 'Update' }}</button>
                </div>
              </form>
            </div>                         
            <div id="regexTester" role="tabpanel" class="tab-pane" [class.active]="regexTesterActive">
              <form [formGroup]="regexForm" (ngSubmit)="testRegex()">                
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Vendor:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <select class="form-control vendors" formControlName="vendor">
                      <option value=""></option>
                      <option *ngFor="let vendor of vendors;" [ngValue]="vendor.name">{{vendor.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Rule:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <select class="form-control rules" formControlName="rule">
                      <option value=""></option>
                      <option *ngFor="let rule of rules;" [ngValue]="rule.id">{{rule.name}} ({{rule.id}})</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Content Hash 1:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="contentHash1">
                  </div>
                </div>                              
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Content Hash 2:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="contentHash2" placeholder="Optional">
                  </div>
                </div>                              
                <div style="text-align: center">
                  <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!regexForm.valid">Test</button>
                </div>
              </form>     
            </div>
            <div id="idpConnection" role="tabpanel" class="tab-pane" [class.active]="idpConnectionActive">
              <form [formGroup]="idpConnectionForm" (ngSubmit)="updateIdPConnection()">
                <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Auth0 IdP Connection Name:</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <input class="form-control" type="text" formControlName="connectionName">
                  </div>
                </div>
                <div style="text-align: center">
                  <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!idpConnectionForm.valid">Save</button>
                </div>
              </form>
            </div>
            <div id="favicons" role="tabpanel" class="tab-pane" [class.active]="faviconsActive">
              <form [formGroup]="iconForm" (ngSubmit)="runFaviconsJob()">                
                <!-- <div class="form-group row pt-1">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Invalidate Cache:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="If you want ALL icons to be redownloaded, enable this flag."></span></label>
                  <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                    <label class="custom-control custom-checkbox custom-control-inline">
                      <input class="custom-control-input" type="checkbox" formControlName="refreshAll"><span class="custom-control-label"></span>
                    </label>
                  </div>
                </div> -->
                <div style="text-align: center">
                  <button type="submit" class="btn btn-rounded btn-space btn-primary">Invalidate Cache</button>
                </div>
              </form>
            </div>
            <div id="eventTrigger" role="tabpanel" class="tab-pane" [class.active]="eventTriggerActive">
             <event-trigger #eventTriggerComponent></event-trigger>             
            </div>       
            <div id="reports" role="tabpanel" class="tab-pane" [class.active]="reportsActive">
              <reports></reports>
             </div>            
             <div id="localCustomReports" role="tabpanel" class="tab-pane" [class.active]="reportsActive">
              <custom-report-menu-item-editor></custom-report-menu-item-editor>
             </div>            
             <div id="persistentCustomReports" role="tabpanel" class="tab-pane" [class.active]="persistentCustomReportsActive">
                <persistentCustomReports></persistentCustomReports>
             </div>
             <div id="refreshData" role="tabpanel" class="tab-pane" [class.active]="refreshDataActive">
                <div style="text-align: center; padding-top: 10px;">
                  <button class="btn btn-rounded btn-space btn-primary" (click)="refreshDataClick();">Refresh UI Data</button>                  
                </div>
                <div style="text-align: center; padding-top: 10px;">
                  <button class="btn btn-rounded btn-space btn-primary" (click)="refreshVendorLibClick();">Refresh Vendor Library</button>
                </div>                
             </div>
        </div>
      </div>
    </div>
  </div>
</div>