import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-minified-value-panel',
  templateUrl: './minified-value-panel.component.html',
  styleUrls: ['./minified-value-panel.component.css']
})
export class MinifiedValuePanelComponent implements OnInit {
  @Input('isVertical') isVertical: boolean;
  @Input('label') label: string;
  @Input('bgColor') bgColor: string;
  @Input('textColor') textColor: string;
  @Input('value') value: string;

  constructor() { }

  ngOnInit(): void {
  }


}
