import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public isVisible:boolean;
  public isRootVisible:boolean;
  public text:string;

  toggle(state=false, text=null) {
    this.isVisible = state;
    this.text = text;
  }

  toggleRoot(state, text=null) {
    this.isRootVisible = state;
    this.text = text;
  }

  constructor() { 
    this.isVisible = false;
    this.isRootVisible = false;
    this.text = null;
  }

  
}
