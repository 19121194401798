import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../services/config/config.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { json_data } from './config-template';
import { config_templates } from './config-default-templates';
import { InstrumentationService } from '../../../services/instrumentation/instrumentation.service';
import { ConfigGroup } from '../../../models/ConfigGroup';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { AuthService } from '../../../services/auth/auth.service';
import { ConfigGroupsService } from '../../../services/config-groups/config-groups.service';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { PaletteTileEventArgs } from '@syncfusion/ej2-angular-inputs';
import {
  EventSettingsModel,
  ScheduleComponent,
} from '@syncfusion/ej2-angular-schedule';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
declare var $: any;

@Component({
  selector: 'app-config-management',
  templateUrl: './config-management.component.html',
  styleUrls: ['./config-management.component.css'],
})
export class ConfigManagementComponent implements OnInit {
  @ViewChild('configManagement') grid: GridComponent;
  @ViewChild('manageConfigGroups') manageConfigGroupsDialog: Dialog;
  @ViewChild('updateConfigGroup') updateConfigGroupDialog: Dialog;
  @ViewChild('createConfigGroup') createConfigGroupDialog: Dialog;
  @ViewChild('scheduleViewDialog') scheduleViewDialog: Dialog;
  @ViewChild('scheduleView') scheduleView: ScheduleComponent;
  @ViewChild('configEditor') configEditor: CodemirrorComponent;
  public elemContent: HTMLElement;
  public textEditor;
  public disableHtmlEncode;

  public showGroups = true;
  public isMinified = false;
  public selectedConfigGroupId = 0;
  public configGroupList: ConfigGroup[] = [];
  public customerConfigs: any = [
    {
      id: '1C',
      name: 'Config 1',
      template: {
        enabled: true,
        timeout: 30000,
        userAgent:
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36',
        headers: { prunner: 'runner_tlcDHdqsX4iX' },
        modules: [
          { name: 'captureHelpers', before: true },
          { name: 'captureDocumentCookie', before: true },
          { name: 'captureDynamicallyAddedScripts', before: true },
          { name: 'captureFingerprintingSignals', before: true },
          { name: 'captureInputGetters', before: true },
          { name: 'interactWithInputs', after: true },
        ],
        configuredPageRun: true,
        consentReport: true,
        configurationId: 386,
        region: 'us-east-1',
      },
    },
  ];

  configs: any;

  editSettings;
  toolbar;
  pageSettings;
  selectOptions;
  commands;
  codeMirrorOptions: any;
  rowData;
  json = json_data;
  public config_templates: any;
  private cid: number;
  public vaultUser: boolean;
  constructor(
    public configService: ConfigService,
    public spinnerService: SpinnerService,
    public notificationService: VaultNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private instrumentationService: InstrumentationService,
    private configGroupsService: ConfigGroupsService,
    private authService: AuthService
  ) {
    this.vaultUser = this.authService.isVaultUser();
    this.cid = parseInt(this.authService.customerId());
    this.config_templates = config_templates;
    this.editSettings = {
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: true,
    };

    this.toolbar = ['Add', 'Cancel'];

    this.pageSettings = {
      currentPage: 1,
      pageSize: 50,
      pageCount: 4,
      pageSizes: [50, 75, 100],
      template: '',
    };

    this.selectOptions = { type: 'Single' };

    this.commands = [
      {
        type: 'ConfigPageLinks',
        buttonOption: {
          iconCss: ' icon zmdi zmdi-info',
          cssClass: 'e-flat',
          disabled: false,
        },
      },
      {
        type: 'Edit',
        buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' },
      },
      {
        type: 'Delete',
        buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' },
      },
      {
        type: 'Save',
        buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' },
      },
      {
        type: 'Cancel',
        buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' },
      },
    ];

    this.disableHtmlEncode = false;

    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      json: true,
      lineWrapping: false,
      height: 'auto',
    };

    this.rowData = null;
  }

  ngOnInit(): void {
    this.configGroupsService.getConfigGroups().subscribe((groups) => {
      this.configGroupList = groups || [];
      if (groups === null) {
        this.configGroupsService.saveConfigGroups(this.configGroupList);
      }
    });
    this.configGroupsService.getCustomerConfigTemplates().subscribe((data) => {
      const templates = data.map((template) => {
        template.template = JSON.stringify(template.template, null, 2);
        return template;
      });
      this.config_templates = [...this.config_templates, ...templates]
        .map((template) => {
          if (template.id.endsWith('C')) {
            template.cat = 'Customer Specific';
          } else if (template.id.endsWith('D')) {
            template.cat = 'Predefined';
          }
          return template;
        })
        .filter((template) => template.cat !== null);
    });
    this.loadData();
  }
  loadData() {
    const consentString = (configFile) => {
      let cString = '';
      const gpcoptout = configFile['GPCOptOut'];
      const gpcoptin = configFile['GPCOptIn'];
      const otoptin = configFile['oneTrustOptIn'];
      const otoptout = configFile['oneTrustOptOut'];
      if (
        gpcoptin == null &&
        gpcoptout == null &&
        otoptin == null &&
        otoptout == null
      ) {
        return 'No Preference';
      }
      if (gpcoptin == true) {
        cString += 'GPC Opt In, ';
      }
      if (gpcoptout == true) {
        cString += 'GPC Opt Out, ';
      }
      if (otoptin == true) {
        cString += 'OneTrust Opt In, ';
      }
      if (otoptout == true) {
        cString += 'OneTrust Opt Out, ';
      }
      return cString.slice(0, -2);
    };

    this.spinnerService.toggle(true);
    this.configService.getAllConfigs(true).subscribe((results: any[]) => {
      console.log('results', results);
      this.configs = results.map((config) => {
        const parsedConfig = JSON.parse(config.config);
        const configurationId = parsedConfig.configurationId || null;
        const consentOptions = consentString(parsedConfig) || 'Not Set';
        const region = parsedConfig.region || 'us-east-1';
        const modules = parsedConfig.modules || [];
        return {
          ...config,
          config: JSON.stringify(JSON.parse(config.config), null, 2),
          idInConfig: configurationId,
          consentOptions: consentOptions,
          region: region,
          modules: modules,
        };
      });
      this.eventSettings = {
        dataSource: this.generateAppointments(),
        fields: this.eventFields,
      };
      this.spinnerService.toggle();
    });
  }

  actionBegin(args) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.rowData = Object.assign({}, args.rowData); // needed for code editor 2 way binding
    }
    if (args.action == 'add') {
      console.log(args);
      if (this.rowData === null) {
        this.rowData = args.data;
      }
      if (!this.isJSON(this.rowData.config)) {
        args.cancel = true;
        return;
      }
      this.spinnerService.toggle(true);
      this.instrumentationService.sendEvent({
        type: 'Page Runner Config',
        page: 'Create New Config',
      });
      args.data.config = JSON.stringify(
        JSON.parse(this.rowData.config),
        null,
        2
      );
      this.configService.createConfig(args.data).subscribe(
        (response) => {
          if (response.error) {
            this.notificationService.error('Error', response.error);
          } else {
            this.configs[0].id = response.newObjectId;
          }
          this.loadData();
        },
        (error) => {
          console.log('err', error);
        }
      );
    } else if (args.action == 'edit') {
      args.data['config'] = this.rowData['config']; // needed for code editor 2 way binding
      if (!this.isJSON(args.data.config)) {
        args.cancel = true;
        return;
      }
      let changes = this.configService.getChangedProperties(
        args.rowData,
        args.data
      );
      if (changes) {
        this.spinnerService.toggle(true);
        this.instrumentationService.sendEvent({
          type: 'Page Runner Config',
          page: `Updating Config ${args.data.id}`,
        });
        this.configService
          .updateConfig(args.data.id, changes)
          .subscribe((response) => {
            this.loadData();
          });
      } else {
        this.notificationService.info(
          'Info',
          'No changes were detected to save.'
        );
      }
    } else if (args.requestType == 'delete') {
      this.spinnerService.toggle(true);
      this.instrumentationService.sendEvent({
        type: 'Page Runner Config',
        page: `Deleting Config ${args.data[0].id}`,
      });
      this.configService.deleteConfig(args.data[0].id).subscribe((result) => {
        this.loadData();
      });
    }
  }

  actionComplete(args) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = args.dialog;
      dialog.width = 700;
      dialog.height = 800;
      if (args.requestType === 'beginEdit') {
        dialog.header = `Edit Record`;
      }
      dialog.refresh(); // needed for code editor
      setTimeout(() => {
        this.configEditor.codeMirror.setSize('100%', '100%');
        this.configEditor.codeMirror.refresh();
      }, 500);
    }
  }

  commandClick(args): void {
    if (args.commandColumn.type == 'ConfigPageLinks') {
      this.configService.setConfigName(args.rowData.name);
      this.router.navigate(['configPageLinks', args.rowData.id], {
        relativeTo: this.route,
      });
    }
  }

  onTemplateSelect(event) {
    console.log(event);
    const id = event.itemData.id;
    const template = this.config_templates.find((t) => t.id == id);
    if (template) {
      // try adding configurationId to the template
      const temp = JSON.parse(template.template);
      temp.configurationId = this.rowData.id;
      this.rowData.config = JSON.stringify(temp, null, 2);
    }
    this.configEditor.codeMirror.refresh();
  }

  configurationMismatchFix() {
    const temp = JSON.parse(this.rowData.config);
    temp.configurationId = this.rowData.id;
    this.rowData.config = JSON.stringify(temp, null, 2);
    this.configEditor.codeMirror.refresh();
  }

  private isJSON(json: string): boolean {
    try {
      console.log(json);
      JSON.parse(json);
    } catch (e) {
      this.notificationService.error('Improper JSON Format', e.message);
      return false;
    }
    return true;
  }

  public filterByGroup(id: number, configIds: number[]) {
    this.selectedConfigGroupId = id;
    this.grid.clearFiltering();
    if (configIds.length === 0) {
      return;
    }
    this.grid.filterByColumn('id', 'equal', configIds);
  }

  public openModal(id: string) {
    $(id).modal('show');
  }

  public onConfigGroupEdit(configGroup: ConfigGroup) {
    this.selectedConfigGroup = configGroup;
    this.closeDialog('manageConfigGroups');
    this.openDialog('updateConfigGroup');
  }
  public onConfigGroupCreate() {
    this.selectedConfigGroup = {} as ConfigGroup;
    this.openDialog('createConfigGroup');
  }

  public onConfigGroupCreateSubmit() {
    console.log('Creating: ', this.selectedConfigGroup);
    this.configGroupsService
      .addConfigGroup(
        this.selectedConfigGroup.name,
        this.selectedConfigGroup.configIds,
        this.selectedConfigGroup.color
      )
      .subscribe(() => {
        this.closeDialog('createConfigGroup');
        this.spinnerService.toggle(true);
        this.configGroupsService.getConfigGroups().subscribe((groups:any[]) => {
          this.configGroupList = groups;
          this.spinnerService.toggle(false);
        });
      });
  }

  public onConfigGroupDelete(id: number) {
    console.log('Deleting: ', id);
    this.configGroupsService.deleteConfigGroups(id).subscribe(() => {
      this.spinnerService.toggle(true);
      this.configGroupsService.getConfigGroups().subscribe((groups:any[]) => {
        this.configGroupList = groups;
        this.spinnerService.toggle(false);
      });
    })
  }

  public onConfigGroupUpdate() {
    console.log('Updating: ', this.selectedConfigGroup);
    this.configGroupsService.updateConfigGroups(this.selectedConfigGroup).subscribe(() => {
      this.closeDialog('updateConfigGroup');
      this.spinnerService.toggle(true);
      this.configGroupsService.getConfigGroups().subscribe((groups:any[]) => {
        this.configGroupList = groups;
        this.spinnerService.toggle(false);
      });
    });
  }

  public selectedConfigGroup: ConfigGroup = {} as ConfigGroup;

  public openDialog(id: string) {
    console.log('Opening: ', id);
    switch (id) {
      case 'manageConfigGroups':
        this.manageConfigGroupsDialog.show();
        break;
      case 'updateConfigGroup':
        this.updateConfigGroupDialog.show();
        break;
      case 'createConfigGroup':
        this.createConfigGroupDialog.show();
        break;
      case 'scheduleViewDialog':
        this.scheduleViewDialog.show();
        this.scheduleView.refreshEvents();

        break;

      default:
        break;
    }
  }
  public closeDialog(id: string) {
    console.log('Closing: ', id);
    switch (id) {
      case 'manageConfigGroups':
        this.manageConfigGroupsDialog.hide();
        break;
      case 'updateConfigGroup':
        this.updateConfigGroupDialog.hide();
        break;
      case 'createConfigGroup':
        this.createConfigGroupDialog.hide();
        break;
      case 'scheduleViewDialog':
        this.scheduleViewDialog.hide();
        break;

      default:
        break;
    }
  }
  public onColorPickerChange(args: any): void {
    console.log(args.currentValue.hex);
    this.selectedConfigGroup.color = args.currentValue.hex;
  }
  public customPaletteColors: any = {
    custom1: [
      '#fc7d73',
      '#f0b53d',
      '#40ebc2',
      '#00dbff',
      '#6390ee',
      '#0e1227',
      '#4d545c',
    ],
  };
  public beforeRoundedTileRender(args: PaletteTileEventArgs): void {
    args.element.classList.add('e-rounded-palette');
  }

  public dialogAnimationSettings: Object = {
    effect: 'SlideRight',
    duration: 150,
    delay: 0,
  };
  public dialogEditConfirmBtn: Object = {
    icon: 'zmdi zmdi-check',
    click: () => {
      console.log(this.selectedConfigGroup);
    },
    text: 'Save Changes',
  };

  public configGroupToolbar: Object[] = [
    {
      text: 'Add New',
      prefixIcon: 'zmdi zmdi-plus',
      id: 'addNewConfigGroup',
    },
  ];
  public onToolbarClick(args: any): void {
    console.log(args.item.id);
    if (args.item.id === 'addNewConfigGroup') {
      this.onConfigGroupCreate();
      console.log('Add New Config Group');
    }
  }
  public appointmentData: any;
  public eventSettings: EventSettingsModel;
  public eventFields: Object = {
    id: 'id',
    subject: { name: 'subject' },
    startTime: { name: 'startTime' },
    endTime: { name: 'endTime' },
  };

  public generateAppointments() {
    const configs = this.configs;
    const appointments = [];
    // using the last run data and the maxAgeInMinutes generate the next 7 days of appointment for each config
    // round up the time to be the quarter hour + 2 mins
    // add the appointments to the array
    // and the appointments only last 15 mins each
    // lastchecked is a datestring
    // maxAgeInMinutes is a number
    // need 7 appointments for each config
    let counter = 1;
    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      const lastChecked = new Date(config.lastChecked);
      const maxAgeInMinutes = config.maxAgeInMinutes;
      const appointmentsForConfig = [];
      // skip if diff betwen next date and lastChecked is more than 1 year
      const nextDate = new Date(lastChecked);
      nextDate.setMinutes(nextDate.getMinutes() + maxAgeInMinutes);
      if (
        nextDate.getTime() - lastChecked.getTime() >
        365 * 24 * 60 * 60 * 1000
      ) {
        continue;
      }
      for (let j = 0; j < 7; j++) {
        // add maxAgeInMinutes to lastChecked for every j
        const startTime = new Date(lastChecked);
        startTime.setMinutes(startTime.getMinutes() + j * maxAgeInMinutes);
        const endTime = new Date(startTime);
        endTime.setMinutes(startTime.getMinutes() + 15);
        appointmentsForConfig.push({
          // unqiue id for the each appointment
          id: counter++,
          configId: config.id,
          subject: config.name,
          startTime: startTime,
          endTime: endTime,
        });
      }
      appointments.push(...appointmentsForConfig);
    }
    console.log(appointments);
    return appointments;
  }
}
