import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EventRuleService extends VaultBaseService {
  
  public static EVENT_TEMPLATES = { 
    "Instrumentation":
      {
        "type": "Page View",
        "page": "Tools",
        "eventTypeName": "Instrumentation",
        "event": "user-navigation",
        "source": "vault-ui",
        "user": "user@vaultjs.com",
        "customerId": 1,
        "customer": "Vault JS",
        "timestamp": 1633547649002
    },
    "Code File Change": {
      eventTypeName: 'Code File Change',
      event: 'code-file-update',
      source: 'file-monitor',
      bucket: 'dev-vaultjs-code-index',
      key: '8bc59d11307745d79af7cdb7d166feeb8efa1030ff22213e8c390100237c9c9f',
      uuid: 'ead32d5b-76bf-4cdd-bc3f-f16978d48c6c',
      customerId: 8,
      codeFileId: 623541,
      vendorId: 6832,
      codeFileHistoryId: 623541,
      eventId: 15337574,
      hash: '8bc59d11307745d79af7cdb7d166feeb8efa1030ff22213e8c390100237c9c9f',
      prevHash: '64b654b9e4d50d2c5d5fe192270068b929849a44b61b0f18d7a45458d7c4aec3',
      url: 'https://y8hxt39lg5.execute-api.us-east-1.amazonaws.com/default/fileMonitorTarget',
      responseSize: 25,
      isValidJS: 1,
      timestamp: 1633547931587
    },
    "Report Complete": {
      eventTypeName: 'Report Complete',
      event: 'complete-success',
      source: 'scheduled-report',
      bucket: 'dev-vaultjs-email-content',
      key: '38-Fidelity UK/incidentSummary-daily-1633619171364',
      reportName: 'Daily Incident Summary',
      customerId: 38,
      timezoneOffset: '1:00',
      date: '10-7-2021'
    },
    "New Code File":{
      eventTypeName: 'New Code File',
      event: 'new-code-file',
      source: 'page-runner',
      bucket: 'bucket',
      key: 'key',
      customerId: 0,
      vendorId: 0,
      pageRunnerHistoryId: 0,
      responseSize: 0,
      codeFileId: 0,
      eventId: 0,
      codeFileUrl: 'codeFileUrl',
      fullCodeFileUrl: 'fullCodeFileUrl',
      documentUrl: 'documentUrl',
      requestUuid: 'requestUuid',
      timestamp: 'timestamp'
    },
    "Validation Result": {
      eventTypeName: 'Validation Result',
      event: 'validation-result',
      source: 'validation',
      customerId: 0,
      validationResultUuid: 'validationResultUuid',
      testSuiteUuid: 'testSuiteUuid',
      validationRuleUuid: 'validationRuleUuid',
      conditionUuid: 'conditionUuid',
      linkId: 0,
      pageRunnerHistoryId: 0,
      documentUrl: 'documentUrl',
      jobUuid: 'jobUuid',
      bucket: 'bucket',
      key: 'key',
      keyIsPath: true,
      validationSuccess: 0,
      timestamp: 0,
      resultMessage: 'resultMessage',
      resultData: 'resultData'
    },
    "Page Run Complete": {
      eventTypeName: 'Page Run Complete',
      event: 'page-run-complete',
      source: 'page-runner',
      bucket: 'bucket',
      key: 'key',
      keyIsPath: "true|false",
      uuid: 'uuid',
      customerId: 0,
      pageRunnerHistoryId: 0,
      linkId: 0,
      url: 'url',
      timestamp: "timestamp"
    },
    "Timer Complete":{
      eventTypeName: 'Timer Complete',
      event: 'event',
      source: 'timer'
    },
    "Code Analysis Complete":{
      eventTypeName: 'Code Analysis Complete',
      event: 'complete-success',
      source: 'code-file-analysis'
    },
    "New Incident":{
      eventTypeName: 'New Incident',
      event: 'new-incident',
      source: 'source',
      customerId: 39,
      vendorId: 6830,
      name: 'New Incident',
      description: 'Lorem ipsum dolor...',
      severity: "Red|Yellow|Green|Unknown",
      status: "In Progress|Resolved",
      analystId: 1
    }
  };

  constructor(authHttp: HttpClient) { 
    super(authHttp);
  }

  public getEventRulesForUI(data):Observable<any>{
    const url = `${env.api.url}eventRules`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public getAdvancedEventRulesForUI(data):Observable<any>{
    const url = `${env.api.url}advancedEventRules`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public createEventRule(data):Observable<any>{
    const url = `${env.api.url}eventRule`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public updateEventRule(rule):Observable<any>{
    const url = `${env.api.url}eventRule`;
    return this.authHttp.put(url, JSON.stringify(rule));
  }

  public deleteEventRule(ruleIds:number[]):Observable<any>{
    const url = `${env.api.url}eventRule`;
    let body:any = JSON.stringify(ruleIds);
    return this.authHttp.delete(url, {body: body});
  }


  public createEventOutput(data):Observable<any>{
    const url = `${env.api.url}eventRule/output`;
    return this.authHttp.post(url, JSON.stringify(data));
  }

  public getEventOutputs():Observable<any>{
    const url = `${env.api.url}eventRule/output`;
    return this.authHttp.get(url);
  }

  public updateEventOutput(rule):Observable<any>{
    const url = `${env.api.url}eventRule/output`;
    return this.authHttp.put(url, JSON.stringify(rule));
  }

  public deleteEventOutput(ruleIds:number[]):Observable<any>{
    const url = `${env.api.url}eventRule/output`;
    let body:any = JSON.stringify(ruleIds);
    return this.authHttp.delete(url, {body: body});
  }

  public static getEventTemplatePropsForEventType(eventType){      
    let template = EventRuleService.EVENT_TEMPLATES[eventType];    
    if (!template) return [];
    return Object.keys(template).filter((p) => { 
        return p != 'customerId' && p != 'customer'
      }).map((prop) => {
        let type = isNaN(template[prop]) ? "string" : "number";
        let label = EventRuleService.camelToSentence(prop);
        if ( prop == 'vendorId') { 
          label = "Vendor";
          type = 'string';
        }
        return {
          field: prop, 
          label: label,
          type: type
        }}
      );
  }

  private static camelToSentence(word){
    // adding space between strings
    const result = word.replace(/([A-Z])/g,' $1');

    // converting first character to uppercase and join it to the final string
    const final = result.charAt(0).toUpperCase()+result.slice(1);
    
    return final;
  }

  public static getTributeJSOptions(eventType) {
    return EventRuleService
      .getEventTemplatePropsForEventType(eventType)
      .map((item)=>{
        return {key: item.label, value: `event.${item.field}` }
      });
  }
}