import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../../../services/reporting/reporting.service';
import { ReportingOverviewData } from '../../../models/ReportingOverviewData';
import { environment as env } from "../../../../environments/environment";
import { DataExportService } from '../../../services/data-export/data-export.service';
import { NotificationsService } from 'angular2-notifications';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-overview',
  templateUrl: './report-overview.component.html',
  styleUrls: ['./report-overview.component.css']
})
export class ReportOverviewComponent implements OnInit {

  isLoading:boolean;
  data:any;
  notificationOptions:any;
  customerId:number;

  constructor(
    private route:ActivatedRoute, 
    private sitemapService:SitemapService,
    private reportingService:ReportingService,
    private dataExportService:DataExportService,
    private notificationService: NotificationsService,) {
    
      this.sitemapService.update(this.route);    
      this.notificationOptions = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };
      this.customerId = -1;         
      try{
        this.customerId = Number(localStorage.getItem("cid"));
      }catch(e){

      }
      
  }

  ngOnInit() {
    this.isLoading = true;
    this.reportingService.getOverviewData().subscribe((data)=>{
      try {        
        let responseObj = JSON.parse(data.toString());
        if (responseObj && responseObj.error){
          this.data = {PagesMonitored: "Loading...", NewCodeFiles: "Loading...", CodeChanges: "Loading..."}
        } else {
          this.data = responseObj;
        }
      } catch(e){
        this.data = {PagesMonitored: "Loading...", NewCodeFiles: "Loading...", CodeChanges: "Loading..."}
      }      
    },
    (error)=>{
      console.log("Error getting reporting overiew data...");
      console.log(error);
    },
    ()=>{
      this.isLoading = false;
    });
  }

  getReport(saveAsName:string, fileName:string, fileExtension:string=DataExportService.PDF_EXTENSION, mimeType:string=DataExportService.PDF_TYPE){
    
    console.log(fileName);
    
    const filePath = `${localStorage.cid}/${fileName}.${fileExtension}`;      
    this.dataExportService.getDataExportFile(filePath, env.aws.reportBucket).subscribe((data)=>{
      let fileToSave = "Vault JS " + saveAsName + " " + new Date().toLocaleString() + "." + fileExtension;
      this.dataExportService.saveFile(fileToSave, data, mimeType);
    },
    (error)=>{
      console.log(error);
      this.notificationService.success('Oops!', 'The PDF was not available. Please contact support.', this.notificationOptions);
    }); 
  }

}