<div class="row">    
  <div class="col-12">
      <div class="card-header"></div>
      <div class="card-body">
        <ejs-grid #requestGrid id="requestGrid"    
      [allowPaging]="true"
      [pageSettings]="pageOptions"
      [allowSelection]="true"
      [selectionSettings]="requestsSelectionSettings"
      (rowSelected)="requestSelected($event)"
      [allowSorting]="true"
      [sortSettings]="requestsSortingSettings"
      [allowResizing]="true"
      [allowFiltering]="true" 
      [filterSettings]="requestsFilteringSettings"
      (contextMenuClick)="requestsGridContextMenuClick($event)"
      [contextMenuItems]="requestsGridContextMenuItems"
      (rowDataBound)="onReqGridRowDataBound($event)"
      (contextMenuOpen)="onContextMenuOpen($event)"
      
      height="500px" width="100%">
          <e-columns>
              <e-column field='formattedTimestamp' headerText='Timestamp'></e-column>
              <e-column field='customerNames' headerText='Customer(s)'></e-column>
              <!-- <e-column field='vendorName' headerText='Vendor'>
                <ng-template #template let-data>
                  <img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/vendors/icon/{{ data.vendorWebsite.toLowerCase() }}.ico"/>{{data.vendorName}}
                </ng-template>
              </e-column> -->
              <!-- <e-column field='libraryName' headerText='Domain Definition'></e-column>
              <e-column field='domainName' headerText='Domain Matcher'></e-column>
              <e-column field='resourceName' headerText='Resource Matcher'></e-column> -->
              <e-column field='requesturl' headerText="URL" width="60%"></e-column>
              <e-column field='responsehash' headerText="Hash"></e-column>
          </e-columns>
      </ejs-grid>

      </div>
  </div>
</div>
<div class="row col-12">
  <div class="col-md-12 page-head-title">
      <h2>Code</h2>
  </div>
</div>
<div class="row col-12">
  <div class="col pb-5">
      <div class="tab-container">
          <ul id="codeTabs" class="nav nav-tabs">
              <li class="nav-item"><a id="beaut" href="#beaut" data-toggle="tab" role="tab"
                      class="nav-link" [class.active]="isBeautificationTabActive"
                      (click)="onTabClick($event);"><span
                          class="icon zmdi zmdi-format-align-left"></span>Beautified<span
                          class="icon zmdi zmdi-info tab-help" data-container="body"
                          ngbPopover="Code that has been formatted to make it easier to read"
                          triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="raw" href="#raw" data-toggle="tab" role="tab"
                      class="nav-link" [class.active]="isRawTabActive"
                      (click)="onTabClick($event);"><span
                          class="icon zmdi zmdi-code"></span>Raw<span
                          class="icon zmdi zmdi-info tab-help" data-container="body"
                          ngbPopover="Raw code file without any modifications or formatting changes"
                          triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="analysis" href="#analysis" data-toggle="tab" role="tab"
                      class="nav-link" [class.active]="isAnalysisTabActive"
                      (click)="onTabClick($event);"><span
                          class="icon zmdi zmdi-functions"></span>Analysis<span
                          class="icon zmdi zmdi-info tab-help" data-container="body"
                          ngbPopover="Show the code analysis performed by Vault JS on a beautified version of the code. Highlighted sections are color coded to match the area of concern that has been detected."
                          triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="ast" href="#ast" data-toggle="tab" role="tab"
                      class="nav-link" [class.active]="isAstTabActive"
                      (click)="onTabClick($event);"><span
                          class="icon zmdi zmdi-flip-to-back"></span>AST<span
                          class="icon zmdi zmdi-info tab-help" data-container="body"
                          ngbPopover="View the AST (abstract syntax tree) for the code file."
                          triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="deobf" href="#deobf" data-toggle="tab" role="tab"
                      class="nav-link" [class.active]="isDeobftabActive"
                      (click)="onTabClick($event);"><span
                          class="icon zmdi zmdi-rotate-left"></span>Deobfuscated<span
                          class="icon zmdi zmdi-info tab-help" data-container="body"
                          ngbPopover="View deobfuscated code (if possible)"
                          triggers="mouseenter:mouseleave"></span></a></li>
              <li class="nav-item"><a id="retire" href="#retire" data-toggle="tab" role="tab"
                      class="nav-link" [class.active]="isRetireActive"
                      (click)="onTabClick($event);"><span
                          class="icon zmdi zmdi-language-javascript"></span>Retire JS<span
                          class="icon zmdi zmdi-info tab-help" data-container="body"
                          ngbPopover="View results of processing with Retire JS"
                          triggers="mouseenter:mouseleave"></span></a></li>                                
              <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                      (click)="next('start');"><span class="icon zmdi zmdi-fast-rewind"></span></a>
              </li>
              <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                      (click)="next(-1);"><span class="icon zmdi zmdi-arrow-left"></span></a></li>
              <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"
                  style="margin-top: 10px;"><span>{{analysisPosition >= 0 && analysisPosition <
                          this.analysisInfo.length ? (analysisPosition + 1) + "/" +
                          this.analysisInfo.length : "" }}</span>
              </li>
              <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                      (click)="next(1);"><span class="icon zmdi zmdi-arrow-right"></span></a></li>
              <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                      (click)="next('end');"><span class="icon zmdi zmdi-fast-forward"></span></a>
              </li>
              <li *ngIf="isAnalysisTabActive && curSelectedRowIndices && curSelectedRowIndices.length == 2"
                  class="nav-item"><a class="nav-link" target="_" [routerLink]="['/codeDiff']"
                      [queryParams]="{h1:hash1, h2:hash2}"><span
                          class="icon zmdi zmdi-fullscreen"></span></a></li>
          </ul>
      </div>
      <div class="tab-content">
          <ngx-codemirror *ngIf="!isDataActive && curSelectedRowIndices.length <= 1" #codeViewer
              [(ngModel)]="codeFileContent" [options]="codeMirrorOptions"></ngx-codemirror>
          <div *ngIf="!isDataActive && curSelectedRowIndices.length > 1"
              [innerHtml]="outputHtml | noSanitizePipe"></div>                            
      </div>
  </div>
</div>


<div class="custom-width modal fade" id="modal-stub-vendor-lib" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <h2>{{modalTitle}}</h2>
          <p>{{selectedRequest}}</p>
          <form [formGroup]="vendorLibForm">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-4 col-form-label text-sm-right">Domain Definition</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <ejs-combobox id='domainDefsCB'
                    [dataSource]="allDomainDefs"
                    [fields]="domainDefFields"
                    (change)="loadRMForDD($event)"
                    formControlName="domainDefId"
                    placeholder='Select a Domain Definition'
                    allowFiltering='true'
                    allowCustom='false'
                    filterType='contains'
                    width="300px">
                  </ejs-combobox>
              </div>
            </div>
          </form>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="updateVendorLib();">Submit</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>


<div class="new-incident-modal modal fade" id="new-incident-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <p>New Incident</p>
          <form [formGroup]="newIncidentForm">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="name">
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Description</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <textarea cols="100" rows="4" class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>
          </form>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="createIncident();">Save</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>  