import { Injectable } from '@angular/core';
import { VaultBaseService } from '../VaultBaseService';
import { Observable } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import { HttpParams, HttpClient } from '@angular/common/http';
import { ReportingOverviewData } from '../../models/ReportingOverviewData';
import { DataExportService } from '../data-export/data-export.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService extends VaultBaseService {

  cache:{[type:string]:Observable<any>;};
  
  private static GET_OVERVIEW_DATA:string = "getPages";

  constructor(authHttp: HttpClient, private dataExportService:DataExportService) { 
    super(authHttp);
  }

  getIncidentTotalsDataWeek():Observable<ReportingOverviewData> {
    const customerId = localStorage.getItem('cid');
    return this.dataExportService.getApiCache(`${customerId}/week.json`);
  }

  getIncidentTotalsDataMonth():Observable<ReportingOverviewData> {
    const customerId = localStorage.getItem('cid');
    return this.dataExportService.getApiCache(`${customerId}/month.json`);
  }

  getOverviewData():Observable<ReportingOverviewData> {
    const customerId = localStorage.getItem('cid');
    return this.dataExportService.getApiCache(`${customerId}/reportOverview.json`);
  }
  
}
