<div class="col-sm-12">
    <div class="card card-table card-border">
        <div class="card-header">          
            <span class="title">Domain Definitions</span>
            <span class="card-subtitle">Create and manage your domain definitions below</span>
        </div>
        <div class="card-body">
            <ejs-grid #domainDefGrid 
                id='domainDefGrid' 
                [dataSource]='domainDefs' 
                [allowSorting]="true"
                [sortSettings]="sortSettings"
                [allowFiltering]="true"
                [filterSettings]="filterSettings"
                [allowPaging]='true' 
                [pageSettings]='pageSettings' 
                [editSettings]='editSettings'                 
                [allowSelection]='true' 
                [toolbar]='toolbar'
                [selectionSettings]='selectOptions'
                allowResizing="true"
                (actionBegin)='actionBegin($event)'
                (rowSelected)="rowSelected($event)"
                (commandClick)="commandClick($event)"
                (actionComplete)="onActionComplete($event)"
            >
                <e-columns>
                    <e-column headerText='' width='150' [commands]='commands'></e-column>
                    <e-column field='name' headerText='Name'></e-column>
                    <e-column field='priority' headerText='Priority' editType='numericedit'></e-column>
                    <e-column field='hostVendorId' headerText='Host Vendor' editType='dropdownedit' [edit]='vendorEditParams' [sortComparer]="hostVendorSortComparer">
                        <ng-template #template let-data>
                            {{ data.hostVendorName }}
                        </ng-template>
                    </e-column>
                    <e-column field='firstPartyCustomerId' headerText='First Party Customer' editType='dropdownedit' [edit]='customerEditParams'>
                        <ng-template #template let-data>
                            {{ data.firstPartyCustomerId ? customersById[data.firstPartyCustomerId].name : '(None specified)' }}
                        </ng-template>
                    </e-column>
                    <e-column field='isCDN' headerText='Is CDN' editType='booleanedit' type='boolean' ></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>