<div class="row">
    <div class="col-sm-12">
      <div class="card card-table card-border">
        <div class="card-header">
          <span class="title">Persistent Custom Reports</span>
          <span class="card-subtitle">Create and manage custom reports below. These reports will show up in the Data->Reports menu for ALL users in this account that have the specified roles, if any. Double click to edit an existing name or value.</span>
        </div>
        <div class="card-body">
          <ejs-grid #grid
          [dataSource]='reports'
          height="500"
          locale='en-US' 
          [allowPaging]="true"
          [editSettings]="editSettings"
          [toolbar]="toolbar"
          (beforeBatchSave)="beforeBatchSave($event)"
          >
          <e-columns>                        
            <e-column field='name' type="string" headerText='Menu Item Text'></e-column>
            <e-column field='fileName' type="string" headerText='File Name'></e-column>
            <e-column field='roles' type="string" headerText='Roles'></e-column>
          </e-columns>
        </ejs-grid>
        </div>      
      </div>
    </div>
</div>
