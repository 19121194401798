import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgStyle } from '@angular/common';

import { GridComponent, GridModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-fb-details-nested-grid',
  templateUrl: './fb-details-nested-grid.component.html',
  styleUrls: ['./fb-details-nested-grid.component.css']
})
export class FbDetailsNestedGridComponent implements OnInit {
  @Input('title') title: string;
  @Input('height') height: any;
  @Input('columns') columns: any;
  @Input('childGrid') childGrid?: GridModel;
  @Input('dataSource') dataSource: any;

  @ViewChild('grid', { static: true }) grid: GridComponent;
  public toolbarOptions?: ToolbarItems[];
  public gridHeight: number;

  constructor() { 
    this.toolbarOptions = ['Search'];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.gridHeight = this.height - 54;
    this.grid.refresh();
  }

  ngOnChanges(): void {
    this.updateData();
  }

  updateData(): void {
    if (!this.dataSource)
      return;
    console.log("gridData", this.dataSource);
    this.childGrid.dataSource = this.dataSource.level1;
    // this.nestedGrid.dataSource = this.gridData;
    if (this.grid)
      (this.grid as GridComponent).refresh();
  }
}
