import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import {
  LegendService,
  SparklineTooltipService,
} from '@syncfusion/ej2-angular-charts';
import {
  AccumulationChart,
  AccumulationChartComponent,
  IAccTextRenderEventArgs,
} from '@syncfusion/ej2-angular-charts';
import {
  DetailRowService,
  ExcelExportService,
  Grid,
  GridComponent,
  PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../services/auth/auth.service';
import { DataExportService } from '../../services/data-export/data-export.service';
import { DataValidationService } from '../../services/data-validation/data-validation.service';
import { SitemapService } from '../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { TimezoneService } from '../../services/timezone.service';
import { DateTime, Interval } from 'luxon';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigService } from '../../services/config/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { VendorsService } from '../../services/vendors/vendors.service';
import { PanelDescriptions } from './panelDescriptions';

declare var $: any;

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.css'],
  providers: [
    SparklineTooltipService,
    DetailRowService,
    LegendService,
    ExcelExportService,
  ],
})
export class ValidationSummaryComponent implements OnInit {
  public isLoading = false;
  public cdnUrl: string;
  public data: any;
  public pageOptions: Object;
  public filterSettings: any;
  public pageSettings: PageSettingsModel;
  public sortSettings: any;
  public reportSelectionForm: FormGroup;
  public availableConfigs: any;
  public currentConfigId: number = -1;

  public totalDetected = 0;
  public totalViolations = 0;
  public totalScanned = 0;

  public totalDetectedPercent = '';
  public totalViolationsPercent = '';

  @ViewChild('pie')
  public pie: AccumulationChartComponent | AccumulationChart;

  @ViewChild('grid')
  public grid: GridComponent;
  public pieTooltip: Object = {
    enable: true,
  };

  public pieCenter: Object = {
    x: '60%',
    y: '50%',
  };

  public title: string = 'Pages Last Scanned';

  public pieData: Object[] = [
    { x: 'Last 24 Hours', daysMin: 0, daysMax: 1, counter: 0, y: 0, text: '' },
    { x: 'Last 7 Days', daysMin: 1, daysMax: 7, counter: 0, y: 0, text: '' },
    { x: 'Last 30 Days', daysMin: 7, daysMax: 30, counter: 0, y: 0, text: '' },
    { x: 'Last 90 Days', daysMin: 30, daysMax: 90, counter: 0, y: 0, text: '' },
  ];

  public getFontSize(width: number): string {
    if (width > 300) {
      return '13px';
    } else if (width > 250) {
      return '8px';
    } else {
      return '8px';
    }
  }

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    console.log(args);
    args.text = `${args.point.x}: ${Number(args.point.y).toFixed(2)}%`;
  }

  public onTextRender(args: IAccTextRenderEventArgs): void {
    // args.series.dataLabel.font.size = this.getFontSize(this.pie.initialClipRect.width);
    // console.log("args.text", args.text);
    if (args.text == '0') {
      args.text = '';
    } else {
      args.text = Number(args.text).toFixed(2) + '%';
    }
  }

  //Initializing Legend
  public legendSettings: Object = {
    visible: true,
    toggleVisibility: false,
    position: 'Right',
    height: '75%',
    width: '60%',
    textWrap: 'Wrap',
    maximumLabelWidth: 100,
  };
  //Initializing Datalabel
  public dataLabel: Object = {
    visible: true,
    position: 'Inside',
    font: {
      // color: 'white',
      fontWeight: '600',
      // size: '14px'
    },
  };

  public tooltipSettings: object = {
    visible: true,
    format: '${x} : ${yval}',
    trackLineSettings: {
      visible: true,
    },
  };

  public configSelectionList: any = [];
  public configSelectionListFields: Object = { text: 'name', value: 'id' };
  public selectedConfig: any = {};
  public descriptions: any = {};
  constructor(
    private route: ActivatedRoute,
    private sitemapService: SitemapService,
    private notificationService: NotificationsService,
    public authService: AuthService,
    public validationService: DataValidationService,
    private spinnerService: SpinnerService,
    private dataService: DataExportService,
    private fb: FormBuilder,
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
    private vendorService: VendorsService
  ) {
    this.descriptions = PanelDescriptions || {};
    this.isLoading = true;
    this.configService.getAllConfigs(false, true).subscribe((configs: any) => {
      console.log(configs);
      this.configSelectionList = configs;
      this.isLoading = false;
    });
    this.sitemapService.update(this.route);
    this.filterSettings = {
      type: 'Excel',
    };

    this.pageOptions = {
      pageSize: 25,
      pageCount: 5,
      pageSizes: [25, 50, 75, 100, 200],
    };

    this.sortSettings = {
      columns: [{ field: 'requestViolationCount', direction: 'Descending' }],
    };
  }

  detailDataBound(arg) {
    console.log(arg);

    let page = arg.data;

    let rvDomains = Object.keys(page.requestViolationsByDomain);
    let cDomains = Object.keys(page.cookieViolationsByDomain);

    let detailData = [];
    rvDomains.forEach((domain) => {
      if (domain != '') {
        let domainCount = page.requestViolationsByDomain[domain]
          ? page.requestViolationsByDomain[domain].length
          : 0;
        detailData.push({
          domain: domain,
          type: 'Request',
          details: `${domainCount} requests from this domain were detected.`,
        });
      }
    });

    cDomains.forEach((domain) => {
      if (domain != '') {
        let cookieCount = page.cookieViolationsByDomain[domain]
          ? page.cookieViolationsByDomain[domain].length
          : 0;
        detailData.push({
          domain: domain,
          type: 'Cookie',
          details: `${cookieCount} cookies from this domain were detected.`,
        });
      }
    });

    let detailGrid = new Grid({
      dataSource: detailData,
      columns: [
        { field: 'domain', headerText: 'Domain', width: 110 },
        { field: 'type', headerText: 'Type', width: 110 },
        { field: 'details', headerText: 'Details', width: 300 },
      ],
    });
    detailGrid.appendTo(arg.detailElement.querySelector('.detailGrid'));
  }
  onConfigSelect($event) {
    this.isLoading = true;
    console.log($event);
    const temp = $event.itemData;
    const tempConfig = this.formatConfig(JSON.parse(temp.config) || {});
    this.selectedConfig = {
      ...temp,
      ...tempConfig,
    };
    this.currentConfigId = temp.id;
    this.loadData();
  }
  private formatConfig(config) {
    let region = 'us-east-1';
    if (config.region) {
      region = config.region;
    }
    let consent = '';
    if (config.GPCOptIn) {
      consent += 'GPC Opt In ';
    }
    if (config.GPCOptOut) {
      consent += 'GPC Opt Out ';
    }
    if (config.oneTrustOptIn) {
      consent += 'One Trust Opt In ';
    }
    if (config.oneTrustOptOut) {
      consent += 'One Trust Opt Out ';
    }
    if (config.ensightenOptIn) {
      consent += 'Ensighten Opt In ';
    }
    if (config.ensightenOptOut) {
      consent += 'Ensighten Opt Out ';
    }
    if (consent == '') {
      consent = 'No Consent';
    }
    return {
      region: region,
      consent: consent,
    };
  }
  public recencydata: any[] = [];

  public cmpDetectionData: any = [];

  public thirdPartyRequestChartData: any = [
    { x: 3, y: 10 },
    { x: 4, y: 20 },
    { x: 5, y: 30 },
    { x: 6, y: 50 },
    { x: 7, y: 10 },
    { x: 8, y: 20 },
  ];

  public pageList: any = [];

  public reqGraphData: any[] = [];
  public cookieGraphData: any[] = [];
  public avgCookies: string = '';
  public avgRequests: string = '';
  async ngOnInit() {
    this.spinnerService.toggle(true);
    this.spinnerService.toggle(false);
  }
  public onPageSelect(event) {
    console.log(event);
    const pageData = this.pageList.find((page) => page.url == event.data.url);
    console.log(pageData);
    this.gridRequests = this.getRequestsFromPage(pageData);
    this.gridCookies = this.getCookiesForPage(pageData);
  }

  public getRequestsFromPage(page) {
    const requests = [];

    Object.keys(page.requestViolationsByDomain).forEach((hostname) => {
      requests.push({
        hostname: hostname,
        requestCount: page.requestViolationsByDomain[hostname].length,
        percent:
          ((page.requestViolationsByDomain[hostname].length / page.requestViolationCount) * 100).toFixed(2) + '%',
      });
    });
    console.log(requests);
    return requests;
  }
  public getCookiesForPage(page) {
    const cookies = [];
    Object.keys(page.cookieViolationsByDomain).forEach((hostname) => {
      cookies.push({
        hostname: hostname,
        cookieCount: page.cookieViolationsByDomain[hostname].length,
        percent:
          ((page.cookieViolationsByDomain[hostname].length / page.cookieViolationCount) * 100).toFixed(2) + '%',
      });
    });
    console.log(cookies);
    return cookies;
  }
  public getCookieCount(page) {
    let count = 0;
    Object.keys(page.cookieViolationsByDomain).forEach((hostname) => {
      count += page.cookieViolationsByDomain[hostname].length;
    });
    return count;
  }
  public getRequestCount(page) {
    let count = 0;
    Object.keys(page.requestViolationsByDomain).forEach((hostname) => {
      count += page.requestViolationsByDomain[hostname].length;
    });
    return count;
  }

  private getRecencyData() {
    const recencyData = [
      { label: 'Last 24 Hours', value: 0, color: '#e5ebe8', children: [] },
      { label: 'Last 7 Days', value: 0, color: '#00dbff', children: [] },
      { label: 'Last 30 Days', value: 0, color: '#6390ee', children: [] },
      { label: 'Last 90 Days', value: 0, color: '#0e1227', children: [] },
    ];

    const now = DateTime.now();
    this.pageList.forEach((page) => {
      console.log("PAGE TS",page.timestamp);
      const timestamp = new Date(page.timestamp);
      // console.log("TIMESTAMP",timestamp);
      const diff = Interval.fromDateTimes(timestamp, now);
      // console.log("DIFF",diff);
      if (diff.length('days') < 1) {
        recencyData[0].value += 1;
      }
      if (diff.length('days') >= 1 && diff.length('days') < 7) {
        recencyData[1].value += 1;
      }
      if (diff.length('days') >= 7 && diff.length('days') < 30) {
        recencyData[2].value += 1;
      }
      if (diff.length('days') >= 30 && diff.length('days') < 90) {
        recencyData[3].value += 1;
      }
    });
    console.log(recencyData);
    return recencyData;
  }

  getCmpDetectionData() {
    const detected = this.reportSummary.consentDetectedPages;
    const notDetected = this.reportSummary.totalPages - detected;
    return [
      {
        label: 'Detected',
        value: detected,
        color: '#6390ee',
        children: [],
      },
      {
        label: 'Not Detected',
        value: notDetected,
        color: '#e5ebe8',
        children: [],
      },
    ];
  }

  public gridRequests: any[] = [];
  public gridCookies: any[] = [];
  public reportSummary: any = null;
  public onRequestViolation;
  handleLargeUint8Array(response: Uint8Array): Promise<any> {
    return new Promise((resolve, reject) => {
      const blob = new Blob([response]);
      const reader = new FileReader();

      reader.onload = (event) => {
        const text = event.target?.result as string;
        try {
          const json = JSON.parse(text);
          resolve(json);
        } catch (error) {
          reject(new Error('Error parsing JSON: ' + error.message));
        }
      };

      reader.onerror = (error: any) => {
        reject(new Error('Error reading Blob: ' + error.message));
      };

      reader.readAsText(blob);
    });
  }

  loadData() {
    let url =
      this.authService.customerId() +
      (this.currentConfigId == -1 ? '' : '/' + this.currentConfigId) +
      '/summary.json.gz';
    console.log(url);
    this.isLoading = true;
    this.dataService.getConsentFile(url).subscribe(
      (response) => {
        this.spinnerService.toggle(true);
        console.log(response);
        this.spinnerService.toggle(false);

        this.handleLargeUint8Array(response).then((data) => {
          let consentReport = data;
          this.reportSummary = consentReport.summary;
          console.log(consentReport);
          let totalCookies = 0;
          let totalRequests = 0;
          this.pageList = consentReport.pages.map((page: any) => {
            const reqCount = this.getRequestCount(page);
            const cookieCount = this.getCookieCount(page);
            totalCookies += cookieCount;
            totalRequests += reqCount;

            return {
              url: page.link,
              linkid: page.id,
              requestViolationCount: reqCount,
              cookieViolationCount: cookieCount,
              cmpDetected: page.consentDetected ? true : false,
              requestViolationsByDomain: page.requestViolationsByDomain,
              cookieViolationsByDomain: page.cookieViolationsByDomain,
              timestamp: page.timestamp,
            };
          });
          this.avgCookies = (totalCookies / this.pageList.length).toFixed(0);
          this.avgRequests = (totalRequests / this.pageList.length).toFixed(0);
          this.cmpDetectionData = this.getCmpDetectionData();
          this.recencydata = this.getRecencyData();
          this.isLoading = false;
          this.spinnerService.toggle();
        });
      },
      (error) => {
        console.log(error);
        this.notificationService.error(
          'Uh oh!',
          'Data is not available. Please try again later or contact support.'
        );
        this.spinnerService.toggle();
      }
    );
  }

  exportData() {
    // this.notificationService.info("Data Export", "//TODO");
  }

  getTotalForPageProperty(page, property) {
    let total = 0;
    for (var domain in page[property]) {
      if (domain != '') {
        total += page[property][domain].length;
      }
    }
    return total;
  }

  changeReport() {
    for (var i = 0; i < this.pieData.length; i++) {
      let timespan: any = this.pieData[i];
      timespan.counter = 0;
    }

    this.pieData.forEach((datum: any) => {
      datum.y = 0;
      datum.text = `0%`;
    });
    $('#modal-select-report').modal('toggle');
  }
}
