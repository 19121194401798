<div class="card card-default">
    <div class="card-body">
        <ejs-grid #vendorViolationGrid [dataSource]='data'>
            <e-columns>
                <e-column field="id" headerText="Static Column">
                </e-column>
                <e-column field="id" headerText="Static Column">
                </e-column>
                <e-column *ngFor="let risk of riskList" [field]="risk"
				[headerText]="risk" >
                <ng-template #template let-data>
                    <div class="custom-css">
                        {{data[risk].toFixed(1)}}
                    </div>
                </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>