<div class="card card-default">
    <div class="card-body">
        <ejs-grid [dataSource]='data' [dataSource]='data' [allowPaging]="true" [allowSorting]="true"
            [sortSettings]="sortSettings" [allowFiltering]="true" [pageSettings]="pageOptions" [allowSelection]="false"
            [enableHover]="false" [filterSettings]="filterSettings" locale='en-US' [allowResizing]='true'
            [allowTextWrap]='true' (actionComplete)="refreshGraphs($event)">
            <e-columns>

                <e-column field='site' headerText='Site Group' width="10%" textAlign="left" headerTextAlign="Center">
                    <ng-container>
                        <ng-template #template let-data>
                            <div class="cell-style" style="font-size: large; font-weight: 500;">{{data.site}}</div>
                        </ng-template>
                    </ng-container>

                </e-column>

                <e-column field='keyPageViolations' headerText='KPV' width="7%" headerTextAlign="Center"
                    textAlign="center">
                    <ng-container>
                        <ng-template #template let-data>
                            <div class="cell-style"
                                style="font-weight: bold; font-size: xx-large; padding: 10px 5px; color: red;">
                                {{data.keyPageViolations}}</div>
                        </ng-template></ng-container>

                </e-column>

                <e-column *ngFor="let risk of riskList" [id]="risk" [field]="risk" [headerText]="risk" width="10%"
                    headerTextAlign="Center">
                    <ng-template #template let-data>
                        <div class="cell-style">
                            <div class="row" style="padding:5px">
                                <div class="col" style="font-weight: bold; font-size: x-large;" *ngIf="data[risk].percent; else notChecked">
                                    {{data[risk].percent || 0}}%
                                </div>
                                <ng-template #notChecked>
                                    <div class="col" style="font-weight: bold; font-size: x-large; color:lightgrey">
                                        N/A
                                    </div>
                                </ng-template>
                                
                            </div>
                            <div class="row" style="padding: 5px;" *ngIf="data[risk].percent">
                                <div class="col">
                                    <span class="sparklinebasic" [attr.sparkLineColor]="data[risk].color"
                                        [attr.values]="data[risk].percentPages_history"></span>
                                </div>
                            </div>
                        </div>


                    </ng-template>
                </e-column>

            </e-columns>
        </ejs-grid>
    </div>
</div>