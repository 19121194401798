import { Injectable } from '@angular/core';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinkDiscoveryService extends VaultBaseService {

  constructor(authHttp: HttpClient) { 
    super(authHttp);
  }

  public updateConfig(config:any):Observable<any> {
    const url = `${env.api.url}linkDiscovery/config`;
    return this.authHttp.put(url, JSON.stringify(config));
  }

  public getConfig():Observable<any> {    
    const url = `${env.api.url}linkDiscovery/config`;
    return this.makeHttpRequest(url, null, false);
  }

}
