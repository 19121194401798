<div class="card card-default">
  <div class="card-header">Violation Risk Summary</div>
  <div class="card-body">
    <ejs-grid #violationSummaryDG id="violationSummaryDG" [dataSource]="gridData" [allowPaging]="false"
      [allowSorting]="true" [sortSettings]="sortSettings" [allowSelection]="false" [enableHover]="false" locale="en-US"
      allowResizing="true" [allowTextWrap]="true" (actionComplete)="refreshGraphs($event)"
      (dataBound)="refreshGraphs($event)">
      <e-columns>
        <e-column field="type" textAlign="right" headerText="" width="10%" headerTextAlign="right">
          <ng-template #template let-data>
            <div class="row">
              <div class="col" style="
                    font-size: large;
                    font-weight: bold;
                    padding-bottom: 5px;
                  ">
                {{ data.label }}
              </div>
            </div>
          </ng-template>
        </e-column>
        <e-column field="percentPages" headerText="% Pages" headerTextAlign="Center" width="12%" textAlign="center">
          <ng-template #template let-data>
            <div class="row">
              <div class="col" style="
                    font-size: x-large;
                    font-weight: bold;
                    padding-bottom: 5px;
                  "   [style.color]="data.isNA ? 'lightgrey': 'black'">
                <ng-container *ngIf="!data?.isNA else isNA">
                  {{ data.percentPages.toFixed(2) }}%
                </ng-container>

              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="sparklinebasic" *ngIf="!data?.isNA" [attr.sparkLineColor]="data.colors[0]"
                  [attr.values]="data.percentPages_history"></span>
              </div>
            </div>
          </ng-template>
        </e-column>
        <e-column field="domains" headerText="# Domains" headerTextAlign="Center" width="10%" textAlign="center">
          <ng-template #template let-data>
            <div class="row">
              <div class="col" style="
                    font-size: x-large;
                    font-weight: bold;
                    padding-bottom: 5px;
                  "   [style.color]="data.isNA ? 'lightgrey': 'black'">
                <ng-container *ngIf="!data.isNA else isNA">
                  {{ data.domains }}
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="sparklinebasic"  *ngIf="!data?.isNA" [attr.sparkLineColor]="data.colors[1]"
                  [attr.values]="data.domains_history"></span>
              </div>
            </div>
          </ng-template>
        </e-column>
        <e-column field="instances" headerText="Total Instances" headerTextAlign="Center" width="10%"
          textAlign="center">
          <ng-template #template let-data>
            <div class="row">
              <div class="col" style="
                    font-size: x-large;
                    font-weight: bold;
                    padding-bottom: 5px;
                  "   [style.color]="data.isNA ? 'lightgrey': 'black'">
                <ng-container *ngIf="!data.isNA else isNA">
                  {{ data.instances.toLocaleString("en-US") }}
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="sparklinebasic"  *ngIf="!data?.isNA" [attr.sparkLineColor]="data.colors[2]"
                  [attr.values]="data.instances_history"></span>
              </div>
            </div>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
<ng-template #isNA>N/A</ng-template>
