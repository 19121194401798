import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { PageRunnerService } from '../../../services/data-export/page-runner.service';
import { AuthService } from '../../../services/auth/auth.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { InstrumentationService } from '../../../services/instrumentation/instrumentation.service';
declare var $:any;

@Component({
  selector: 'app-page-runner',
  templateUrl: './page-runner.component.html',
  styleUrls: ['./page-runner.component.css']
})
export class PageRunnerComponent implements OnInit, AfterViewInit {

  configForm:FormGroup;
  advEdit:boolean;
  codeMirrorOptions:any;
  @ViewChild('advConfigEditor') advConfigEditor:CodemirrorComponent;
  config;
  // timeout is in miliseconds
  maxTimeout = 830000; // 900 total seconds in AWS lambda. This will leave use 60 seconds to extract & save data.

  get headers(){
    return this.configForm.get('headers') as FormArray;
  }

  get timeout(){
    return this.configForm.get('timeout');
  }

  constructor(private _fb: FormBuilder,
              private spinnerService:SpinnerService, 
              private notificationService:VaultNotificationService,
              private pageRunnerService:PageRunnerService,
              private instrumentationService:InstrumentationService,
              public authService:AuthService,
  ) {
    
    var headersArray = this._fb.array([this._fb.group({header:""})]);

    this.configForm = this._fb.group({
      enabled: true,
      timeout: ['0'],
      userAgent: [''],
      headers: headersArray
    });              

    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
      tabSize: 2,
      readOnly: false,
      theme: 'material',
      gutters: ["CodeMirror-linenumbers"]
    };

    this.advEdit = true;
  }

  ngOnInit() {
    this.spinnerService.toggle(true);
    this.pageRunnerService.getConfig().subscribe((response)=>{
      var config = JSON.parse(response.config);
      console.log(config);
      
      // if ( !config ) {
      //   config = {};
      // }

      // var headers = config.headers ? config.headers.map((h)=>{return this._fb.group(h)}) : [this._fb.group({header:''})];

      // // TODO: add more (custom) Validators
      // this.configForm = this._fb.group({
      //   enabled: config.enabled,
      //   timeout: [config.timeout,[Validators.required, Validators.min(0), Validators.max(this.maxTimeout)]],
      //   userAgent: config.userAgent,
      //   headers: this._fb.array(headers)
      // });

      this.config = JSON.stringify(config, null, 2);
      this.spinnerService.toggle(false);

      setTimeout(() => {
        this.advConfigEditor.codeMirror.setSize("100%", "600px");
        this.advConfigEditor.codeMirror.refresh();  
      }, 100);    
    },
    (error)=>{
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.error('Uh oh!', error);
    });
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  addHeader() {
    const control = <FormArray>this.configForm.controls['headers'];
    control.push(this._fb.group({header:''}));
  }

  removeHeader(i:number) {
    const control = <FormArray>this.configForm.controls['headers'];
    control.removeAt(i);
  }

  save(updatedConfig=null){
    this.instrumentationService.sendEvent({type: 'Default Page Runner Config', page: "Update" });
    console.log(updatedConfig);
    if ( !updatedConfig ){
      let updatedValues = this.configForm.value;
      let tempCurConfig = JSON.parse(this.config);
      updatedConfig = Object.assign(tempCurConfig, updatedValues);
    }
    this.spinnerService.toggle(true);
    this.pageRunnerService.updateConfig(updatedConfig).subscribe((response)=>{
      this.spinnerService.toggle(false);
      this.notificationService.success("Success!", "Page runner config was updated successfully.");
    },
    (error)=>{
      this.spinnerService.toggle(false);
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.error('Uh oh!', error);
    })
  }

  onAdvEditClick(){
    console.log('onAdvEditClick');
    this.advEdit = !this.advEdit;
    if (this.advEdit) {
      this.instrumentationService.sendEvent({type: 'Default Page Runner Config', page: "Advanced Edit Click" });
      const updatedValues = this.configForm.value;
      let tempCurConfig = JSON.parse(this.config);
      this.config = JSON.stringify(Object.assign(tempCurConfig, updatedValues), null, 2);
      if (!this.config) {
        this.notificationService.error('Uh oh!', "Error parsing config.");
      }
    } else {
      this.instrumentationService.sendEvent({type: 'Default Page Runner Config', page: "Default Edit Click" });
      const parsedConfig = JSON.parse(this.config);
      if (!parsedConfig) {
        this.notificationService.error('Uh oh!', "Error parsing config.");
        return;
      }
      var headers = parsedConfig.headers ? parsedConfig.headers.map((h)=>{return this._fb.group(h)}) : [this._fb.group({header:''})];
      this.configForm = this._fb.group({
        enabled: parsedConfig.enabled,
        timeout: [parsedConfig.timeout,[Validators.required, Validators.min(0), Validators.max(this.maxTimeout)]],
        userAgent: parsedConfig.userAgent,
        headers: this._fb.array(headers)
      });
    }
    
    setTimeout(() => {
      this.advConfigEditor.codeMirror.setSize("100%", "600px");
      this.advConfigEditor.codeMirror.refresh();  
    }, 100);    
  }

  saveAdvConfig(){    
    console.log(this.config);
    const parsedConfig = JSON.parse(this.config);
    if (!parsedConfig) {
      this.notificationService.error('Uh oh!', "Error parsing config.");
      return;
    }
    // code editor JSON config validations go here
    const validConfig = this._fb.group({
      timeout: [parsedConfig.timeout,[Validators.required, Validators.min(0), Validators.max(this.maxTimeout)]],
    });
    if (!validConfig.valid) {
      this.notificationService.error('Uh oh!', "Config property not valid.");
      return;
    }
    this.save(parsedConfig);
  }
}
