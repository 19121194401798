import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment as env} from '../../../environments/environment';
import { VaultBaseService } from '../VaultBaseService';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InstrumentationService extends VaultBaseService {
  
  private url = `${env.api.url}analytics`;
  private customer;
  private user;

  constructor(authHttp: HttpClient, private authService:AuthService) { 
    super(authHttp);
    this.loadInfo();
  }

  private loadInfo(){
    this.user = this.authService.getUserProfile().email;
    this.customer = JSON.parse(localStorage.getItem("customer"));
    console.log('Load Info', this.user, this.customer);
  }

  reset(){
    this.loadInfo();
  }

  sendEvent(event) {
    try{
      Object.assign(event, { eventTypeName: 'Instrumentation', event: 'user-navigation', source: 'vault-ui', user:this.user, customerId: this.customer.id, customer: this.customer.name, timestamp: (new Date()).getTime()});
      let messageString = JSON.stringify(event);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      this.authHttp.post(this.url, messageString, {headers}).subscribe((result)=>{      
        console.log(result);
      });
    }catch(err){
      console.log(err);
    }    
  }

  sendRawEvent(event){
    try {      
      let messageString = JSON.stringify(event);      
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      this.authHttp.post(this.url, messageString, {headers}).subscribe((result)=>{      
        console.log(result);
      });
    }catch(err){
      console.log(err);
    }    
  }

}


