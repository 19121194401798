import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { DomainDefinitionComponent } from './domain-definition/domain-definition.component';
import { DomainDefinitionDetailComponent } from './domain-definition-detail/domain-definition-detail.component';
import { ResourceMatcherDetailComponent } from './resource-matcher-detail/resource-matcher-detail.component';
import { ExtractorDetailComponent } from './extractor-detail/extractor-detail.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { MonitoringWrapperComponent } from './resource-matcher-detail/monitoring-wrapper/monitoring-wrapper.component';
import { RepeatTypeComponent } from './resource-matcher-detail/repeat-type/repeat-type.component';
import { UnclassifiedRequestsComponent } from './unclassified-requests/unclassified-requests.component';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    FormlyModule.forRoot({ 
      extras: { lazyRender: true },
      validationMessages: [
        { name: 'required', message: 'This field is required' }
      ],
      wrappers: [
        { name: 'monitoring', component: MonitoringWrapperComponent },
      ],
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
      ],
    }),
    FormlyBootstrapModule,
    TextBoxModule,
    ComboBoxModule
  ],
  declarations: [
    DomainDefinitionComponent,
    DomainDefinitionDetailComponent,
    ResourceMatcherDetailComponent,
    ExtractorDetailComponent,
    MonitoringWrapperComponent,
    RepeatTypeComponent,
    UnclassifiedRequestsComponent
  ],
  exports: [
    DomainDefinitionComponent,
    DomainDefinitionDetailComponent
  ]
})
export class VendorLibraryComponentsModule { }
