<ejs-chart
    [primaryXAxis]='primaryXAxis'
    [primaryYAxis]='primaryYAxis'
    [titleStyle]='titleStyle'
    [legendSettings]='legendSettings'
    [title]='title'
    [chartArea]="chartArea"
    [margin]="margin"
    [tooltip]='tooltip'
    (tooltipRender)='tooltipRender($event)'
    (pointRender)='pointRender($event)'
    size.width="90%"
    size.height="90%">
    <e-series-collection>
        <e-series [dataSource]='series' type='Column' xName='x' yName='y' [name]='name' fill="#6390ee" [marker]="marker"></e-series>
    </e-series-collection>
</ejs-chart>