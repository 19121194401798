<div class="row">
    <div class="col-lg-12">
        <app-title-card height="700px">
            <ng-container header>
                <span class="zmdi zmdi-lock-download"></span>
                Export Center
            </ng-container>
            <ng-container headerToolbar>
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; gap:10px">
                        <!-- <div>Auto Polling</div>
                        <ejs-switch></ejs-switch> -->
                    <button ejs-button content="Refresh" (click)="onRefreshClick()"></button>

                </div>

            </ng-container>
            <ejs-grid [dataSource]="(allReport | async) || dataBeforeRefresh" height="100%" width="100%"
                [allowSorting]="true" [sortSettings]="sortSettings" [allowResizing]="true" [allowFiltering]="true" [filterSettings]="{type:'Excel'}">
                <e-columns>
                    <e-column field="timestamp" headerText="Timestamp" autoFit="true">
                        <ng-template #template let-data>
                            {{data.timestamp | date: 'medium'}}
                        </ng-template>
                    </e-column>
                    <e-column field="reportTitle" headerText="Report Name">
                        <ng-template #template let-data>
                            <div style="display:flex;flex-direction: column; gap: 3px;">
                                <div>{{data.customName || data.reportTitle}}</div>
                                <div *ngIf="data.customName" style="font-size: small; color:grey">{{data.reportTitle}}
                                </div>
                            </div>
                        </ng-template>
                    </e-column>
                    <e-column field="initiatorName" headerText="Generated By" autoFit="true">
                        <ng-template #template let-data>
                            <span [style.color]="data.initiatorName ? 'black':'lightgrey'" style="text-transform: capitalize;" >{{data.initiatorName || 'Unknown'}}</span>
                        </ng-template>
                    </e-column>
                    <e-column headerText="Status" width="130px" field="status" [allowFiltering]="false" >
                        <ng-template #template let-data>
                            <div [ngSwitch]="data.status">
                                <ng-container *ngFor="let x of statusTypes;index as i">
                                    <span *ngSwitchCase="i" [style.color]="x.color">
                                        <span *ngIf="x.icon" class="zmdi" [class]="x.icon" style="width: 20px;"></span>
                                        {{x.text}}</span>
                                </ng-container>
                                <span *ngSwitchDefault [style.color]="'grey'">Error</span>
                            </div>
                        </ng-template>
                    </e-column>
                    <e-column field="outputSize" [autoFit]="true">
                        <ng-template #template let-data>
                            <span>{{data.outputSize | bytes}}</span>
                        </ng-template>
                    </e-column>
                    <e-column [allowFiltering]="false" width="120px">
                        <ng-template #template let-data>
                            <button class="button" content="Download" (click)="onDownloadClick(data)" *ngIf="data.output">
                                <span class="zmdi zmdi-download"></span>
                                <div> Download</div>
                            </button>
                        </ng-template>
                    </e-column>
                    <e-column field="sqlWhere" headerText="Query" [clipMode]="'EllipsisWithTooltip'">
                    </e-column>
                    <e-column field="columns" headerText="Columns" [autoFit]="true">
                    </e-column>




                </e-columns>
            </ejs-grid>
        </app-title-card>
    </div>
</div>