<div class="row">
    <div class="col-md-12">
      <div class="card be-loading">
        <div class="card-header card-header-divider">Link Discovery Configuration<span class="card-subtitle">Configfure how we discover links on your domain(s)</span></div>
        <div class="card-body">
          <form [formGroup]="configForm" (ngSubmit)="save()">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Enabled:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="If disabled, Vault JS will NOT visit your pages."></span></label>
              <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input class="custom-control-input" type="checkbox" [formControl]="configForm.controls['enabled']"><span class="custom-control-label"></span>
                </label>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Max Links:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Once we’ve found this many links, stop looking for more"></span></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="maxLinks">                
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Default Page Runner Max Age:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="When a new page is discovered, configure it to be checked after this many minutes"></span></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="defaultPageRunnerMaxAge">              
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Default Link Discovery Max Age:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="When a new page is discovered, configure it to be checked for new links after this many minutes"></span></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input class="form-control" type="text" formControlName="defaultLinkDiscoveryAge">              
              </div>
            </div>

            <div class="accordion" id="advSettings">
              <div id="advSettingsHeading" class="form-group row pt-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <h4>
                    <button type="button" class="btn" data-toggle="collapse" data-target="#advSettingsCollapse" aria-expanded="true" aria-controls="seedsCollapse"><i class="icon zmdi zmdi-chevron-right"></i></button>
                    Advanced Settings&nbsp;
                  </h4>
                </div>
              </div>
              <div class="collapse show" id="advSettingsCollapse" aria-labelledby="advSettingsHeading" data-parent="#advSettings">
                <div class="accordion" id="accordian1">
                  <div id="seedsHeading" class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <h4>
                        <button type="button" class="btn" data-toggle="collapse" data-target="#seedsCollapse" aria-expanded="true" aria-controls="seedsCollapse"><i class="icon zmdi zmdi-chevron-right"></i></button>
                        <span class="headerText">Seed URLs&nbsp;</span><span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Which URL to look at initially for links to follow"></span>
                      </h4>
                    </div>
                  </div>
                  <div class="collapse show" id="seedsCollapse" aria-labelledby="seedsHeading" data-parent="#accordian1">
                    <div formArrayName="seeds" *ngFor="let seed of seeds.controls; let i=index">                
                      <div class="form-group row pt-1" [formGroupName]="i">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">URL:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                          <input class="form-control" type="text" formControlName="seed">
                        </div>
                        <div *ngIf="seeds.controls.length > 1" class="text-sm-right">
                            <span style="cursor: pointer;" class="icon zmdi zmdi-minus-circle-outline" (click)="removeFormControl(i, 'seeds')"></span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row pt-1">
                      <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                      <div class="col-12 col-sm-8 col-lg-6">
                        <a style="cursor: pointer;" (click)="addFormControl('seeds','seed')">+ Add another URL</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="accordian2">
                  <div id="domainsHeading" class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <h4>
                        <button type="button" class="btn" data-toggle="collapse" data-target="#domainsCollapse" aria-expanded="true" aria-controls="domainsCollapse"><i class="icon zmdi zmdi-chevron-right"></i></button>
                        <span class="headerText">Allowed Domains&nbsp;</span><span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Only follow links to these domains"></span>
                      </h4>
                    </div>
                  </div>
                  <div class="collapse show" id="domainsCollapse" aria-labelledby="domainsHeading" data-parent="#accordian2">
                    <div formArrayName="allowedDomains" *ngFor="let domain of domains.controls; let i=index">                
                      <div class="form-group row pt-1" [formGroupName]="i">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">Domain:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                          <input class="form-control" type="text" formControlName="domain">
                        </div>
                        <div *ngIf="domains.controls.length > 1" class="text-sm-right">
                            <span style="cursor: pointer;" class="icon zmdi zmdi-minus-circle-outline" (click)="removeFormControl(i,'allowedDomains')"></span>                      
                        </div>
                      </div>                
                    </div>
                    <div class="form-group row pt-1">
                      <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                      <div class="col-12 col-sm-8 col-lg-6">
                        <a style="cursor: pointer;" (click)="addFormControl('allowedDomains', 'domain')">+ Add another Domain</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="accordian3">
                  <div id="filtersHeading" class="form-group row pt-1">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <h4>
                        <button type="button" class="btn" data-toggle="collapse" data-target="#filtersCollapse" aria-expanded="true" aria-controls="filtersCollapse"><i class="icon zmdi zmdi-chevron-right"></i></button>
                        URL Filters&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Ignore any URL that matches one of these regular expressions"></span>
                      </h4>
                    </div>
                  </div>
                  <div class="collapse show" id="filtersCollapse" aria-labelledby="filtersHeading" data-parent="#accordian3">
                    <div formArrayName="filterRegexps" *ngFor="let filter of filters.controls; let i=index">                
                      <div class="form-group row pt-1" [formGroupName]="i">
                        <label class="col-12 col-sm-3 col-form-label text-sm-right">URL:</label>
                        <div class="col-12 col-sm-8 col-lg-6">
                          <input class="form-control" type="text" formControlName="filter">
                        </div>
                        <div *ngIf="filters.controls.length > 1" class="text-sm-right">
                            <span style="cursor: pointer;" class="icon zmdi zmdi-minus-circle-outline" (click)="removeFormControl(i, 'filterRegexps')"></span>                      
                        </div>
                      </div>                
                    </div>
                    <div class="form-group row pt-1">
                      <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                      <div class="col-12 col-sm-8 col-lg-6">
                        <a style="cursor: pointer;" (click)="addFormControl('filterRegexps', 'filter')">+ Add another Filter</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: center">
              <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!configForm.valid">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>