import { Component, Input, OnInit } from '@angular/core';
import { SparklinePanelComponent } from '../sparkline-panel/sparkline-panel.component';

@Component({
  selector: 'app-multi-sparkline-panel',
  templateUrl: './multi-sparkline-panel.component.html',
  styleUrls: ['./multi-sparkline-panel.component.css']
})
export class MultiSparklinePanelComponent extends SparklinePanelComponent implements OnInit {
  @Input() multiDataSeries: any[];
  @Input() multiChartHeight: number;
  @Input() multiTitle: number;
  @Input() lhs: string;
  @Input() multiLineWidth: string;
  @Input() yLabel: string;

  public XAxis: any;
  public YAxis: any;
  public tooltip: any;

  constructor() { super(); }

  ngOnInit(): void {
    this.XAxis = {
      visible: true,
      valueType: 'Category',
    };
    this.YAxis = {
      visible: true,
      title: this.yLabel,
    };
    // FIXME: this tooltip is not working atm
    this.tooltip = {
      enable: true,
    };
  }

}
