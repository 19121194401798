import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { find, switchMap } from 'rxjs/operators';
import { ConfigService } from '../../../services/config/config.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { PageService } from '../../../services/page/page.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { InstrumentationService } from '../../../services/instrumentation/instrumentation.service';

declare var $: any;

@Component({
  selector: 'app-config-page-list',
  templateUrl: './config-page-list.component.html',
  styleUrls: ['./config-page-list.component.css'],
})
export class ConfigPageListComponent implements OnInit {
  editSettings;
  toolbar;
  pageSettings;
  selectOptions;

  configId;
  configObject;
  configPageLinks;
  pageLinks;
  selectedPageLink;
  public pageLinkData: Observable<any>;

  public parsedPageLinks = null;

  constructor(
    public pageService: PageService,
    public configService: ConfigService,
    public spinnerService: SpinnerService,
    public notificationService: VaultNotificationService,
    private route: ActivatedRoute,
    private siteMapService: SitemapService,
    private instrumentationService: InstrumentationService
  ) {
    this.siteMapService.update(this.route);
    this.editSettings = {
      showDeleteConfirmDialog: true,
      allowEditing: false,
      allowAdding: true,
      allowDeleting: true,
      allowEditOnDblClick: true,
    };

    this.toolbar = ['Add', 'Delete', 'Cancel',{
      text: 'Copy All Links',id: 'copyAllLinks',prefixIcon: 'e-copy'
    },'Search'];

    this.pageSettings = {
      currentPage: 1,
      pageSize: 50,
      pageCount: 4,
      pageSizes: [50, 75, 100],
    };

    this.selectOptions = { type: 'Single' };
  }

  ngOnInit(): void {
    this.loadData();
  }

  onKeyPress(args) {
    if (args.which === 13) {
      args.preventDefault();
    }
  }

  onFilter(args) {
    args.preventDefaultAction = true;
    if (args.text.length < 4) {
      return;
    }
    let data = this.pageService
      .getPages(0, 50, 'link', 'asc', 'link:' + args.text, 1)
      .subscribe((result) => {
        this.pageLinks = result.data.map((i) => {
          return i;
        });
      });
    args.updateData(this.pageLinks, null);
  }

  actionBegin(args) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.selectedPageLink = Object.assign({}, args.rowData);
    }
    if (args.action === 'add' && args.requestType === 'save') {
      if (!this.selectedPageLink.id) {
        args.cancel = true;
        return;
      }
      this.spinnerService.toggle(true);
      this.configService
        .createConfigLink(this.configId, [this.selectedPageLink.id])
        .subscribe((result) => {
          this.loadData();
        });
    } else if (args.requestType === 'delete') {
      this.spinnerService.toggle(true);
      this.configService
        .deleteConfigLink(this.configId, args.data[0].linkId)
        .subscribe((result) => {
          this.loadData();
        });
    }
  }
  public filterSettings = { type: 'Excel' };
  toolbarClick(args) {
    if (args.item.id === 'copyAllLinks') {
      console.log('Copying all links');
      navigator.clipboard.writeText(
        this.configPageLinks.map((link) => link.link).join('\n')
      );
    }
  }

  onSelectPageLink(args: any): void {
    this.selectedPageLink.id = args.itemData.id;
  }

  loadData() {
    this.spinnerService.toggle(true);
    this.route.params
      .pipe(
        switchMap((params) => {
          this.configId = +params['configId'];
          this.instrumentationService.sendEvent({ type: `Page Runner Config`, page: `View Details for Config ${this.configId}` });
          return this.configService.getConfig(this.configId, true);
        })
      )
      .subscribe((results: any[]) => {
        this.spinnerService.toggle();
        this.configObject = results[0];
        this.configPageLinks = this.configObject.links;
      });
  }
  async checkAvailability(url: string, urls: string[] = null) {
    const temp = await this.pageService
      .getPages(0, 5, 'link', 'asc', "link:" + (url || urls), 1)
      .toPromise();
    if (temp && temp.data && temp.data.length > 0) {
      const findUrl = temp.data.find((link) => link.link === url);
      if (findUrl !== undefined) {
        return findUrl.id;
      }
      return null;
    }
    if (temp == null) {
      console.log(`Error with : ${url}
      `);
    }
    return null;
  }

  checkInConfig(url: string): boolean {
    console.log("url", url);
    const temp = this.configPageLinks.find((link) => {
      if (link.link == url) {
        return true;
      }
    });
    return temp;
  }

  parsePageLinksFromText() {
    const el = document.getElementById('bulk-url-list');
    // console.log(el);
    const text = el['value'];
    // console.log(text);

    let pageLinks = text.split('\n');
    if (pageLinks.length === 1 && pageLinks[0] === '') {
      this.parsedPageLinks = [];
      return;
    }
    //dedup links
    pageLinks = pageLinks.filter((link, index) => {
      return pageLinks.indexOf(link) === index;
    });

    const parsedLinks = pageLinks.filter((link) => link !== "").map(link => "link:" + link);

    this.spinnerService.toggle(true);

    this.pageService.getLinksForUrls(this.configId, parsedLinks).subscribe((result: any) => {
      console.log("this.configPageLinks");
      console.log(this.configPageLinks);
      this.parsedPageLinks = pageLinks.filter((link) => link !== "").map((link) => {
        let isAvailable = result.find(data => data.link === link);
        return {
          link: link,
          isAvailable: !!isAvailable,
          isInConfig: this.checkInConfig(link),
          id: isAvailable ? isAvailable.id : null
        }
      });
      this.spinnerService.toggle(false);
    });


  }

  public selectedPageLinks = [];
  showConfirmDialog() {
    $('#confirm-dialog').modal('show');
    this.selectedPageLinks = this.parsedPageLinks.filter((link) => {
      return link.isAvailable && !link.isInConfig;
    });
    console.log(this.selectedPageLinks);
  }
  showDeleteDialog() {
    $('#delete-dialog').modal('show');
    this.selectedPageLinks = this.parsedPageLinks.filter((link) => {
      return link.isAvailable && link.isInConfig;
    });
    console.log(this.selectedPageLinks);
  }

  addBulkLinks() {
    this.spinnerService.toggle(true);
    const linkIds = this.selectedPageLinks.map((link) => {
      return link.id;
    });
    this.instrumentationService.sendEvent({ type: `Page Runner Config`, page: `Add Link to Config ${this.configId}` });
    // Promise.all(bulkLinks)
    this.configService.createConfigLink(this.configId, linkIds).toPromise()
      .then((results) => {
        this.spinnerService.toggle(false);
        this.loadData();

        this.notificationService.success('Success', 'Links added successfully');
        $('#confirm-dialog').modal('hide');
      })
      .catch((err) => {
        this.spinnerService.toggle(false);
        this.notificationService.error('Error', 'Something went wrong');
        $('#confirm-dialog').modal('hide');
      });
  }

  deleteBulkLinks() {
    this.spinnerService.toggle(true);
    const bulkLinks = this.selectedPageLinks.map((link) => {
      this.configService.deleteConfigLink(this.configId, link.id).toPromise();
    });
    this.instrumentationService.sendEvent({ type: `Page Runner Config`, page: `Remove Links from Config ${this.configId}` });
    Promise.all(bulkLinks)
      .then((results) => {
        this.spinnerService.toggle(false);
        this.loadData();

        this.notificationService.success(
          'Success',
          'Links deleted successfully'
        );
        $('#delete-dialog').modal('hide');
      })
      .catch((err) => {
        this.spinnerService.toggle(false);
        this.notificationService.error('Error', 'Something went wrong');
        $('#delete-dialog').modal('hide');
      });
  }
}
