import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ReportOverviewComponent } from './report-overview/report-overview.component';
import { ReportsIncidentsComponent } from './report-incidents/report-incidents.component';
import { SkeletonModule } from 'primeng/skeleton';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DataCollectionSummaryReportComponent } from './data-collection-summary-report/data-collection-summary-report.component';
import { CustomReportViewerComponent } from './custom-report-viewer/custom-report-viewer.component';
import { GlobalComponent } from './disney/global/global.component';
import { GroupComponent } from './disney/group/group.component';
import { SiteComponent } from './disney/site/site.component';
import { ComboBoxModule, ListBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { SparklineModule, AccumulationChartAllModule } from '@syncfusion/ej2-angular-charts';
import { MapsAllModule } from '@syncfusion/ej2-angular-maps';
import { Ng9OdometerModule } from 'ng9-odometer';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { BarSeriesService, StackingBarSeriesService, CategoryService} from '@syncfusion/ej2-angular-charts';
import { PageService, SortService, FilterService, GroupService, DetailRowService } from '@syncfusion/ej2-angular-grids';
import { VirtualScrollService } from '@syncfusion/ej2-angular-grids';

import { LinearGaugeAllModule } from "@syncfusion/ej2-angular-lineargauge"
import { SimpleValueDisplayComponent } from './custom-report-components/simple-value-display/simple-value-display.component';
import { SimpleProgressBarComponent } from './custom-report-components/simple-progress-bar/simple-progress-bar.component';
import { HighlightedValueDisplayComponent } from './custom-report-components/highlighted-value-display/highlighted-value-display.component';
import { SimplePiechartPanelComponent } from './custom-report-components/simple-piechart-panel/simple-piechart-panel.component';
import { BasicViolationRiskSummaryPanelComponent } from './custom-report-components/basic-violation-risk-summary-panel/basic-violation-risk-summary-panel.component';
import { AdvancedViolationRiskSummaryPanelComponent } from './custom-report-components/advanced-violation-risk-summary-panel/advanced-violation-risk-summary-panel.component';
import { GroupSummaryPanelComponent } from './custom-report-components/group-summary-panel/group-summary-panel.component';
import { SiteSummaryPanelComponent } from './custom-report-components/site-summary-panel/site-summary-panel.component';
import { GeolocationMapPanelComponent } from './custom-report-components/geolocation-map-panel/geolocation-map-panel.component';
import { DashboardGlobalComponent } from './dashboard/global/global.component';
import { SimpleDropdownComponent } from './custom-report-components/simple-dropdown/simple-dropdown.component';
import { DynamicGroupSummaryPanelComponent } from './custom-report-components/dynamic-group-summary-panel/dynamic-group-summary-panel.component';
import { VendorSummaryPanelComponent } from './custom-report-components/vendor-summary-panel/vendor-summary-panel.component';
import { ReportHeaderComponent } from './custom-report-components/report-header/report-header.component';
import { CondensedValueDisplayComponent } from './custom-report-components/condensed-value-display/condensed-value-display.component';
import { VendorMapPanelComponent } from './custom-report-components/vendor-map-panel/vendor-map-panel.component';
import { HighlightValueHistoryComponent } from './custom-report-components/highlight-value-history/highlight-value-history.component';
import { ScanRecencyBucketsComponent } from './custom-report-components/scan-recency-buckets/scan-recency-buckets.component';
import { VppaReportComponent } from './vppa-report/vppa-report.component';
import { MultiPieChartComponent } from './custom-report-components/multi-pie-chart/multi-pie-chart.component';
import { ExtendedAccumChartComponent } from './custom-report-components/extended-accum-chart/extended-accum-chart.component';
import { AdobeReportComponent } from './cibc/adobe-report/adobe-report.component';
import { TrendLineDisplayComponent } from './custom-report-components/trend-line-display/trend-line-display.component';
import { BarChartPanelComponent } from './custom-report-components/bar-chart-panel/bar-chart-panel.component';
import { SiteGroupPanelComponent } from './custom-report-components/site-group-panel/site-group-panel.component';
import { RuleGridPanelComponent } from './custom-report-components/rule-grid-panel/rule-grid-panel.component';
import { AdobeVarPagesPanelComponent } from './custom-report-components/adobe-var-pages-panel/adobe-var-pages-panel.component';
import { FingerprintDashboardComponent } from './fingerprint-dashboard/fingerprint-dashboard.component';
import { ColBarChartPanelComponent } from './custom-report-components/fingerprint-components/col-bar-chart-panel/col-bar-chart-panel.component';
import { SparklinePanelComponent } from './custom-report-components/fingerprint-components/sparkline-panel/sparkline-panel.component';
import { ScrollableProgressBarsComponent } from './custom-report-components/fingerprint-components/scrollable-progress-bars/scrollable-progress-bars.component';
import { MultiSparklinePanelComponent } from './custom-report-components/fingerprint-components/multi-sparkline-panel/multi-sparkline-panel.component';
import { NestedGridPanelComponent } from './custom-report-components/fingerprint-components/nested-grid-panel/nested-grid-panel.component';
import { AdobeVarGridPanelComponent } from './custom-report-components/adobe-var-grid-panel/adobe-var-grid-panel.component';
import { TrackerSummaryDashboardComponent } from './tracker-summary-dashboard/tracker-summary-dashboard.component';
import { MinifiedValuePanelComponent } from './custom-report-components/minified-value-panel/minified-value-panel.component';
import { TrackerVendorDashboardComponent } from './tracker-vendor-dashboard/tracker-vendor-dashboard.component';
import { HistPanelComponent } from './custom-report-components/fingerprint-components/hist-panel/hist-panel.component';
import { FbDetailsComponent } from './fb-details/fb-details.component';
import { FbDetailsDropdownComponent } from './fb-details/fb-details-dropdown/fb-details-dropdown.component';
import { FbDetailsCardlistComponent } from './fb-details/fb-details-cardlist/fb-details-cardlist.component';
import { FbDetailsNestedGridComponent } from './fb-details/fb-details-nested-grid/fb-details-nested-grid.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SkeletonModule,
    GridModule,
    ComboBoxModule,
    ListBoxModule,
    SparklineModule,
    MapsAllModule,
    AccumulationChartAllModule,
    Ng9OdometerModule,
    LinearGaugeAllModule,
    DropDownListModule,
    ChartModule,
    SwitchModule,
    TooltipAllModule
  ],
  declarations: [
    ReportOverviewComponent,
    ReportsIncidentsComponent,
    DataCollectionSummaryReportComponent,
    CustomReportViewerComponent,
    GlobalComponent,
    GroupComponent,
    SiteComponent,
    SimpleValueDisplayComponent,
    SimpleProgressBarComponent,
    HighlightedValueDisplayComponent,
    SimplePiechartPanelComponent,
    BasicViolationRiskSummaryPanelComponent,
    AdvancedViolationRiskSummaryPanelComponent,
    GroupSummaryPanelComponent,
    SiteSummaryPanelComponent,
    GeolocationMapPanelComponent,
    DashboardGlobalComponent,
    SimpleDropdownComponent,
    DynamicGroupSummaryPanelComponent,
    VendorSummaryPanelComponent,
    ReportHeaderComponent,
    CondensedValueDisplayComponent,
    VendorMapPanelComponent,
    HighlightValueHistoryComponent,
    ScanRecencyBucketsComponent,
    VppaReportComponent,
    MultiPieChartComponent,
    ExtendedAccumChartComponent,
    AdobeReportComponent,
    TrendLineDisplayComponent,
    BarChartPanelComponent,
    SiteGroupPanelComponent,
    RuleGridPanelComponent,
    AdobeVarPagesPanelComponent,
    FingerprintDashboardComponent,
    ColBarChartPanelComponent,
    SparklinePanelComponent,
    ScrollableProgressBarsComponent,
    MultiSparklinePanelComponent,
    NestedGridPanelComponent,
    TrackerSummaryDashboardComponent,
    MinifiedValuePanelComponent,
    TrackerVendorDashboardComponent,
    AdobeVarGridPanelComponent,
    HistPanelComponent,
    FbDetailsComponent,
    FbDetailsDropdownComponent,
    FbDetailsCardlistComponent,
    FbDetailsNestedGridComponent
  ],
  exports: [
    ReportOverviewComponent,
    MultiPieChartComponent

  ],
  providers: [
    BarSeriesService,
    StackingBarSeriesService,
    CategoryService,
    PageService,
    SortService,
    FilterService,
    GroupService,
    DetailRowService,
    VirtualScrollService
  ]

})
export class ReportComponentsModule { }
