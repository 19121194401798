import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { SpinnerService } from '../../../../../services/spinner/spinner.service';
import { VendorsService } from '../../../../../services/vendors/vendors.service';

@Component({
  selector: 'app-vendor-edit',
  templateUrl: './vendor-edit.component.html',
  styleUrls: ['./vendor-edit.component.css']
})
export class VendorEditComponent implements OnInit {
  editVendor = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    website: new FormControl('')

  })
  
  currentVendor:any;

  constructor(private vendorservice: VendorsService, private activatedRoute:ActivatedRoute, private router:Router, private spinnerService:SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    this.vendorservice.getCurrentData(this.activatedRoute.snapshot.params.name).subscribe((result)=>{
      this.spinnerService.toggle(false);
      this.editVendor = new FormGroup({
        id: new FormControl(result[0]['id']),
        name: new FormControl(result[0]['name']),
        website: new FormControl(result[0]['website'])
    
      })
    },
    (err)=>{
      this.spinnerService.toggle(false);
    })
  }

  updateVendor(){
    this.spinnerService.toggle(true);
    this.vendorservice.updateVendor(this.editVendor.value).subscribe((result)=>{
      this.spinnerService.toggle(false);
      this.router.navigate(['/vendorInfo']);      
    },
    (err)=>{
      this.spinnerService.toggle(false);
    })
  }

}
