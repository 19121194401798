import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable, forkJoin, of } from "rxjs";
import { VendorsService } from "../services/vendors/vendors.service";
import { CustomerService } from "../services/customer/customer.service";
import { tap, catchError } from "rxjs/operators";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class RootResolver implements Resolve<any> {
    
    constructor(private authService:AuthService,
                private vendorService:VendorsService, 
                private spinnerService:SpinnerService, 
                private customerService:CustomerService) {}

    resolve(route: ActivatedRouteSnapshot) : Observable<any> {
        console.log("AAA Root Resolver", route);
        
        this.spinnerService.toggleRoot(true);

        var globalData = forkJoin([
                                this.authService.getAwsCreds().pipe(catchError(error=>of(error))),
                                this.authService.getCdnCookie().pipe(catchError(error=>of(error))),
                                this.vendorService.getVendors(),
                                this.customerService.getCustomerInfo()
        ]);

        return globalData.pipe(tap((data)=>{
            let customers = data[3];
            this.customerService.customers = customers;
            if ( customers && customers.length == 1 ){
                this.customerService.setCustomer(customers[0]);
            } else {
                const customerId = localStorage.getItem('cid');
                let curCustomer = customers.filter((c)=>{return c.id == customerId});
                if ( curCustomer.length == 1 ){
                    this.customerService.setCustomer(curCustomer[0]);
                }
            }
            this.spinnerService.toggleRoot(false);
        }));
    }
}