<div class="be-splash-screen">
  <div class="be-wrapper be-login">
    <div class="be-content">
      <div class="main-content container-fluid">
        <div class="splash-container forgot-password">
          <div class="card be-loading" [class.be-loading-active]="showSpinner">
            <div class="card-header"><img src="assets/img/logo-xx.png" alt="logo" width="52" height="52" class="logo-img"><br/>Vault JS<span class="splash-description">Forgot your password?</span></div>
            <div class="card-body">
              <form action="index.html" method="get">
                <p>Don't worry, we'll send you an email to reset your password.</p>
                <div class="form-group pt-4">
                  <input type="email" name="email" required="" placeholder="Your Email" autocomplete="off" class="form-control" [(ngModel)]="emailAddress">
                </div>
                <p class="pt-1 pb-4">Don't remember your email? <a href="mailto:support@vaultjs.com">Contact Support</a>.</p>
                <div class="form-group pt-1">
                  <button type="submit" class="btn btn-block btn-primary btn-xl" (click)="resetPassword();">Reset Password</button>
                </div>
              </form>
            </div>
            <div class="be-spinner">
              <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
              </svg>
            </div>
          </div>
          <div class="splash-footer">&copy; 2021 Vault JS</div>
        </div>
      </div>
    </div>
  </div>
</div>
<simple-notifications (onDestroy)="onNotificationDestroyed($event)"></simple-notifications>