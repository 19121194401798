<div class="be-wrapper be-collapsible-sidebar be-collapsible-sidebar-hide-logo">
  <nav class="navbar navbar-expand fixed-top be-top-header">
    <div class="container-fluid">
      <div class="be-navbar-header"><a href="/vendors" class="navbar-brand"></a><a class="be-toggle-left-sidebar"
          style="cursor: pointer;"><span class="icon zmdi zmdi-menu"></span></a>
      </div>
      <div class="be-right-navbar">
        <div class="page-title"><span></span></div>
        <app-usermenu></app-usermenu>
      </div>
    </div>
  </nav>
  <div class="be-left-sidebar">
    <app-menu></app-menu>
  </div>
  <div class="be-content">
    <div class="page-head" *ngIf="!sitemapService.hideHeader">
      <h2 class="page-head-title" *ngIf="!sitemapService.isPageTitleHtml && !sitemapService.image">
        {{sitemapService.pageTitle}}&nbsp;<span *ngIf="sitemapService.helpText" class="icon zmdi zmdi-info"
          title="Site Score" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="right"
          [attr.data-content]="sitemapService.helpText"></span></h2>
      <h2 class="page-head-title" *ngIf="sitemapService.isPageTitleHtml && !sitemapService.image"
        [innerHtml]="sitemapService.safePageTitle"><span *ngIf="sitemapService.helpText" class="icon zmdi zmdi-info"
          title="Site Score" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="right"
          [attr.data-content]="sitemapService.helpText"></span></h2>
      <img *ngIf="sitemapService.image" src="{{sitemapService.image}}" style="height: 50px; object-fit: contain;" />
      <app-dashboard-file-selector *ngIf="dashboardFileSelectorService.show"
        style="position:absolute; top: 20px; right: 25px; z-index: 99999"
        [reportCode]="dashboardFileSelectorService.reportCode"
        (selectEvent)="dashboardSelector($event)"
        ></app-dashboard-file-selector>
      <app-breadcrumbs></app-breadcrumbs>
    </div>
    <div class="main-content container-fluid be-loading" [class.be-loading-active]="spinnerService.isVisible">
      <router-outlet></router-outlet>
      <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
        <div style="font-size: medium; backdrop-filter: blur(5px)" *ngIf="spinnerService?.text">{{spinnerService.text}}</div>
      </div>
    </div>
  </div>
</div>
<simple-notifications></simple-notifications>
<app-progress-display style="position: fixed; bottom: 10px; left: 10px; z-index: 99" *ngIf="progressBarService.show"
  [value]="progressBarService.value" [maxValue]="progressBarService.maxValue" [unit]="progressBarService?.unit"
  (stopEvent)="progressBarService.stopHandler()"></app-progress-display>