import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Vendor } from '../../../models/Vendor';
import { CustomerService } from '../../../services/customer/customer.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { VendorLibraryService } from '../../../services/vendorLibrary/vendor-library.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { EventTriggerComponent } from './event-trigger/event-trigger.component';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
declare var $:any;

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit, AfterViewInit {

  regexForm:FormGroup;
  newCustomerForm:FormGroup;
  customerInfoForm:FormGroup;
  idpConnectionForm:FormGroup;
  iconForm:FormGroup;
  incidentMessagesForm:FormGroup;
  regexMgrForm:FormGroup;
  vendors:Vendor[];
  rules:any[];
  vendorName:string;
  
  newCustomerActive:boolean;
  customerInfoActive:boolean;
  regexTesterActive:boolean;
  idpConnectionActive:boolean;
  incidentMessagesActive:boolean;
  regexManagerActive:boolean;
  faviconsActive:boolean;
  eventTriggerActive:boolean;
  reportsActive:boolean;
  vendorsActive:boolean;
  customReportsActive:boolean;
  persistentCustomReportsActive:boolean;
  refreshDataActive: boolean;

  customer:any;
  messages:any[];
  regexTargets:any[];
  isNewRule:boolean;
  isChangesTarget:boolean;
  deleted:any[];
  selectedRule:any;
  codeMirrorOptions:any;

  @ViewChild('eventTriggerComponent') eventTrigger: EventTriggerComponent;
  @ViewChild('regexEditor') regexEditor: CodemirrorComponent;
  @ViewChild('products')
  productsLV: MultiSelectComponent
  
  public allProducts: any[] = [];
  public selectedProducts:any[] = [1];
  public productsFields : Object = {text: "name", iconCss: "e-icons", value: "id" };
  
  get regexes(){
    return this.regexMgrForm.get('regexes') as FormArray;
  }

  constructor(
    private vendorService:VendorsService, 
    private fb:FormBuilder, 
    private customerService:CustomerService, 
    private spinnerService:SpinnerService, 
    private notificationService:VaultNotificationService,
    private vendorLibService:VendorLibraryService
  ) { 
    this.regexForm = this.fb.group({      
      contentHash1: [''],
      contentHash2: [''],
      vendor: -1,
      rule: -1
    });
    this.newCustomerForm = this.fb.group({
      customerName: [''],
      customerDomain: ['']
    });
    this.newCustomerActive = true;
    this.customerInfoActive = false;
    this.regexTesterActive = false;
    this.idpConnectionActive = false;
    this.faviconsActive = false;  
    this.vendorsActive = false;
    this.incidentMessagesActive = false;
    this.regexManagerActive = false;
    this.eventTriggerActive = false;
    this.reportsActive = false;
    this.customReportsActive = false;
    this.persistentCustomReportsActive = false;
    this.refreshDataActive = false;
    
    this.idpConnectionForm = this.fb.group({
      connectionName: ''
    });
    this.iconForm = this.fb.group({
      refreshAll: ['']
    });
    this.incidentMessagesForm = this.fb.group({
      newMessage: [''],
      messages: []
    });

    this.customerInfoForm = this.fb.group({
      name: '',
      icon: ''
    })


    var regexArray = this.fb.array([this.fb.group({regex:""})]);

    this.regexMgrForm = this.fb.group({
      vendor: -1,
      rule: -1,
      target: [{value: '', disabled: true}],
      severity: [''],
      regexes: regexArray,
      ruleName: [''],
      enabled: false
    });

    this.regexTargets = [
      {id:1, name: "All Code"},
      {id: 2, name: "Just Changes"}
    ];
    this.isNewRule = false;
    this.isChangesTarget = false;
    this.deleted = [];
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: 'javascript',
    };
  }

  ngOnInit() {
    this.vendorService.getVendors("").subscribe((vendors)=>{
      this.vendors = vendors;
    });

    this.regexForm.get('vendor').valueChanges.subscribe(val => {
      console.log("Subbed val change", val);
      if ( val != "") {
        this.vendorName = val;
        this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
          this.rules = rules;
          this.regexForm.patchValue({rule:-1});
        });
      }
    });

    this.regexMgrForm.get('vendor').valueChanges.subscribe(val => {
      console.log("Subbed val change", val);
      this.vendorName = val;
      if ( val != "") {
        this.deleted = [];        
        this.spinnerService.toggle(true);
        this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
          this.spinnerService.toggle(false);
          this.rules = rules;
          this.regexMgrForm.patchValue({rule:-1});
          var regexArray = this.fb.array([this.fb.group({regex:""})]);
          this.regexMgrForm.patchValue({
            rule: -1,
            target: [''],
            severity: [''],
            ruleName: [''],
            enabled: false
          });
          this.regexMgrForm.setControl('regexes', regexArray);
        },
        (error)=>{
          this.spinnerService.toggle(false);
        });        
      }
    });

    this.regexMgrForm.get('rule').valueChanges.subscribe(val => {
      this.deleted = [];
      var rule = this.rules.find((r)=>{return r.id == val;});
      this.selectedRule = rule;
      console.log("Selected Rule:", val, rule);
      if ( rule ){
        this.regexMgrForm.patchValue({ruleName: rule.name, target: rule.regexTarget + "", severity: rule.severity + "", enabled: rule.enabled});
        if ( rule.regexTarget == "1"){
          console.log("Patching regex....");
          var item = this.fb.array([this.fb.group({regex: rule.regex[0].regex})]);
          this.regexMgrForm.setControl('regexes', item);
          this.isChangesTarget = false;
        } else {
          this.isChangesTarget = true;
          const control = <FormArray>this.regexMgrForm.controls['regexes'];          
          control.removeAt(0);
          for(var i = 0; i < rule.regex.length; i++){            
            control.push(this.fb.group({regex: rule.regex[i].regex}));
          }
        }        
      }
    });

    this.regexMgrForm.get('target').valueChanges.subscribe((targetValue)=>{
      console.log(targetValue);
      this.isChangesTarget = targetValue == "2";
      var item = this.fb.array([this.fb.group({regex: ''})]);
      this.regexMgrForm.setControl('regexes', item);      
    });

    this.customerService.getCustomer().subscribe((customer)=>{
      console.log("Customer");
      console.log(customer);
      this.customer = customer[0];
      this.allProducts = customer[0].allProducts;//.records.map( (p) => { return { text: p.name, id: p.id }});
      this.selectedProducts = customer[0].products;//.map( (p) => { return { text: p.name, id: p.id }});      
      this.productsLV.value = this.selectedProducts.map( i => i.id);
      this.idpConnectionForm.patchValue({connectionName: this.customer.idpConnectionName});
      this.customerInfoForm.patchValue({name: this.customer.name, icon: this.customer.icon});      
    });
  }

  onProductActionComplete(event){
    console.log("WTF");
    console.log(event);
    // let iconEle = document.getElementsByClassName("delete-icon");
    // //Event handler to bind the click event for delete icon
    // Array.from(iconEle).forEach((element) => {
    //   element.addEventListener("click", this.deleteItem.bind(this));
    // });
  }

  ngAfterViewInit(){
    this.loadMessages();
    $('[data-toggle="popover"]').popover();
  }

  loadMessages(){
    this.customerService.getEventMessage().subscribe((messages)=>{
      console.log(messages);
      this.messages = messages;
      this.messages.unshift("");
      this.incidentMessagesForm.patchValue({messages:messages});
    });
  }

  vendorChange(value){
    console.log(value);
    var vendorIndex = -1;
    if (value.indexOf(':') != -1 ){
      // subtract one since first is an empty row
      vendorIndex = Number(value.split(':')[0].trim()) - 1 ;
    }
    if ( vendorIndex != -1 ){
      this.vendorName = this.vendors[vendorIndex].name;
      this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
        this.rules = rules;
        this.regexForm.patchValue({rule:-1});
      });
    }
  }

  testRegex(){
    console.log(this.regexForm.value);

    const ruleId = this.regexForm.value.rule;
    const hash1 = this.regexForm.value.contentHash1;
    const hash2 = this.regexForm.value.contentHash2;
    
    if ( ruleId != -1 ){
      this.vendorService.testRuleForFile(this.vendorName, ruleId, hash1, hash2).subscribe((results)=>{
        if (results.error){
          alert("Error: " + results.error);
        } else {
          var msg = 'Rule matched!'
          if ( results.result == false ){
            if ( results.change ){
              msg = "Unexpected change: " + results.change;
            } else {
              msg = "Rule does not match";
            }            
          } 
          alert(msg);
        }
      });
    } else if ( hash1 == '' ) {
      alert('Specify a file hash!');
    } else {
      alert('Select a rule!');
    }
  }

  createCustomer(){
    console.log(this.newCustomerForm.value);
    const name = this.newCustomerForm.value.customerName;
    const domain = this.newCustomerForm.value.customerDomain;
    if ( name && domain ){
      this.spinnerService.toggle(true);
      this.customerService.createCustomer(name, domain).subscribe((result)=>{
        console.log(result);
        if (result.error){
          this.notificationService.error('Uh oh...', result.error);
        } else{          
          this.notificationService.success('Huzzah!', `Success! New customer ID: ${result.id}`, 0);
          this.newCustomerForm.reset( { 
            customerName: '',
            customerDomain: ''
          });
        }
        this.spinnerService.toggle(false);
      },
      (error)=>{
        console.log(error);
        this.notificationService.error('Uh oh...', 'An unexpected error has occurred.');
        this.spinnerService.toggle(false);
      });
    } else {
      this.notificationService.error('Oops!', "You must specify a name and domain");
    }
  }

  updateIdPConnection(){
    console.log(this.idpConnectionForm.value);
    this.spinnerService.toggle(true);
    this.customerService.updateCustomer(this.idpConnectionForm.value).subscribe((result)=>{
      this.notificationService.success('Huzzah!', `Connection name has been updated successfully`);
    },
    (error)=>{
      console.log(error);
      this.notificationService.error('Uh oh...', 'An unexpected error has occurred.');
      this.spinnerService.toggle(false);
    },
    ()=>{
      this.spinnerService.toggle(false);
    });
  }

  runFaviconsJob(){    
    console.log("Starting job...");
    this.spinnerService.toggle(true);
    this.vendorService.runFaviconJob(this.iconForm.value.refreshAll).subscribe((result)=>{
      this.notificationService.success('Huzzah!', `Icon cache invalidated job was started successfully (Job ID: ${result.id});`, 0);
    },
    (error)=>{
      console.log(error);
      this.notificationService.error('Uh oh...', 'An unexpected error has occurred.');
      this.spinnerService.toggle(false);
    },
    ()=>{
      this.spinnerService.toggle(false);
    });
  }

  addMessage(){
    console.log("Add message");
    this.spinnerService.toggle(true);
    this.customerService.createEventMessage([{message:this.incidentMessagesForm.value.newMessage}]).subscribe((result)=>{      
      this.messages = [];
      this.loadMessages();
      this.incidentMessagesForm.patchValue({newMessage: ''});
    },
    (error)=>{
      console.log(error);
      this.notificationService.error('Uh oh...', 'An unexpected error has occurred.');
      this.spinnerService.toggle(false);
    },
    ()=>{
      this.spinnerService.toggle(false);
    });
  }

  deleteMessages(){
    console.log(this.incidentMessagesForm.value);
    var selectedMsg = this.incidentMessagesForm.value.messages;
    if (selectedMsg && selectedMsg != "" ){
      this.spinnerService.toggle(true);
      this.customerService.deleteEventMessage([selectedMsg.id]).subscribe((result)=>{      
        this.messages = [];
        this.loadMessages();
        this.incidentMessagesForm.patchValue({newMessage: ''});
      },
      (error)=>{
        console.log(error);
        this.notificationService.error('Uh oh...', 'An unexpected error has occurred.');
        this.spinnerService.toggle(false);
      },
      ()=>{
        this.spinnerService.toggle(false);
      });
    }    
  }

  createNewRule() {
    console.log("New Rule for " + this.vendorName);
    if ( this.vendorName != null && this.vendorName != "" ){
      console.log("Create regex...");
      this.isNewRule = true;
      this.resetRegexMgrForm(true);
    } else {
      this.notificationService.error('Ooops', 'Please select a vendor.');
    }
    
  }

  resetRegexMgrForm(newRule:boolean=false){
    var regexArray = this.fb.array([this.fb.group({regex:""})]);
    this.regexMgrForm.patchValue({
      rule: -1,
      target: [''],
      severity: [''],
      ruleName: [''],
      enabled: false
    });
    this.regexMgrForm.setControl('regexes', regexArray);
    if ( newRule ) {
      this.regexMgrForm.get('target').enable();
    } else {
      this.regexMgrForm.get('target').disable();
    }
  }

  cancelNewRule(){
    console.log("Cancel new rule...");
    this.isNewRule = false;
    this.regexMgrForm.get('target').disable();
  }

  addRegex(regex:string="", id:string="") {
    const control = <FormArray>this.regexMgrForm.controls['regexes'];
    control.push(this.fb.group({regex: regex}));
  }

  removeRegex(i:number) {
    const control = <FormArray>this.regexMgrForm.controls['regexes'];
    const id = control.value[i].id;    
    if ( id ){
      this.deleted.push(id);
    }    
    control.removeAt(i);
  }

  saveUpdateRule(){
    
    if (this.isNewRule) {
      console.log(this.regexMgrForm);
      let ruleName = this.regexMgrForm.get('ruleName').value;
      console.log('ruleName', ruleName);
      console.log('is array ruleName', Array.isArray(ruleName));
      if ( ruleName == null || ruleName == "" || Array.isArray(ruleName) == true ) {
        this.notificationService.error('Ooops', 'Please make sure the rule has a name.');
        return;
      }
      var dto = JSON.parse(JSON.stringify(this.regexMgrForm.value));
      const vendorInfo = this.vendors.filter((v)=> { return v.name == dto.vendor;} );      
      dto.vendorId = vendorInfo[0].id;
      console.log(dto);
      dto.enabled = dto.enabled == true ? 1 : 0;
      this.spinnerService.toggle(true);
      this.vendorService.createCodeFileRule(this.vendorName, dto).subscribe((result)=>{
        console.log(result);              
        this.vendorService.clearRuleCache();
        this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
          this.notificationService.success('Success!', 'Rule was created successfully!');        
          this.resetRegexMgrForm();
          this.spinnerService.toggle(false);
          this.rules = rules;
          this.regexForm.patchValue({rule:-1});
          this.isNewRule = false;
        });
      },
      (err)=>{
        console.log(err);
        this.spinnerService.toggle(false);
        this.notificationService.error('Oops!', 'An error occurred creating the rule.');
      });
    } else {

      this.spinnerService.toggle(true);
      var dto = JSON.parse(JSON.stringify(this.regexMgrForm.value));      
      const vendorInfo = this.vendors.filter((v)=> { return v.name == dto.vendor;} );      
      var rule = {
        id: this.selectedRule.id,
        ids: this.selectedRule.regex.map((r)=>{return r.id}),
        target: this.regexMgrForm.get('target').value, 
        enabled: dto.enabled == true ? 1 : 0, 
        severity: dto.severity, 
        regexes: dto.regexes, 
        vendorId: vendorInfo[0].id,
        ruleName: dto.ruleName,
        groupId: this.selectedRule.groupId
      };
      
      console.log("rule");
      console.log(rule);
      
      this.spinnerService.toggle(true);
      // console.log(this.deleted);
      // return;
      this.vendorService.updateCodeFileRule(this.vendorName, rule).subscribe((result)=>{
        
        this.vendorService.clearRuleCache();
        this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
          this.notificationService.success('Success!', 'Rule was updated successfully!');        
          this.resetRegexMgrForm();
          this.spinnerService.toggle(false);
          this.rules = rules;
          this.regexForm.patchValue({rule:-1});
          this.isNewRule = false;
        });
      },
      (err)=>{
        console.log(err);
        this.spinnerService.toggle(false);
        this.notificationService.error('Oops!', 'An error occurred updating the rule.');      
      });
    }
  }

  deleteRule() {    
    
    var ruleIds = [];
    if ( this.selectedRule.regexTarget == "1") {
      ruleIds.push(this.selectedRule.id);
    } else {
      ruleIds = this.selectedRule.regex.map((r)=>{return r.id})
    }

    this.spinnerService.toggle(true);
    this.vendorService.deleteCodeFileRule(this.vendorName, ruleIds).subscribe((response)=>{
      console.log(response);              
      this.vendorService.clearRuleCache();
      this.vendorService.getClassificationRules(this.vendorName).subscribe((rules)=>{
        this.notificationService.success('Success!', 'Rule was deleted successfully!');        
        this.resetRegexMgrForm();
        this.spinnerService.toggle(false);
        this.rules = rules;
        this.regexForm.patchValue({rule:-1});
        this.isNewRule = false;
      });
    },
    (err)=>{
      console.log(err);
      this.spinnerService.toggle(false);
      this.notificationService.error('Oops!', 'An error occurred deleting the rule.');      
    })
   
  }

  updateCustomerInfo(){
    console.log(this.customerInfoForm.value);
    console.log(this.productsLV.value);
    this.spinnerService.toggle(true);
    this.customerService.updateCustomer({...this.customerInfoForm.value, selectedProducts: this.productsLV.value}).subscribe((result)=>{
      this.notificationService.success('Huzzah!', `Customer info has been updated successfully`);
    },
    (error)=>{
      console.log(error);
      this.notificationService.error('Uh oh...', 'An unexpected error has occurred.');
      this.spinnerService.toggle(false);
    },
    ()=>{
      this.spinnerService.toggle(false);
    });
  }
  
  refreshDataClick() {
    this.spinnerService.toggle(true);
    this.vendorLibService.refreshData().subscribe((response)=>{
      console.log(response);
      this.notificationService.success('Success', `Data refresh request was sent successfully, but data may take a while to show up.`);
      this.spinnerService.toggle();
    });
  }


  refreshVendorLibClick(){
    this.spinnerService.toggle(true);
    this.vendorLibService.refreshVendorLib().pipe(catchError(error=>of(error))).subscribe((response)=>{
      console.log(response);
      this.notificationService.success('Success', `Vendor library refresh request was sent successfully, but data may take a while to propagate throughout the system.`, 7000);
      this.spinnerService.toggle();
    });
  }

  onSelect(event){
    console.log("WTF!!!");
    console.log(event);
  }

  onEventTriggerFocus() {
    this.eventTrigger.onFocus();
  }

  onRegexManagerFocus() {
    this.regexEditor.codeMirror.refresh();
  }
}
