export class TopicSubscription {
    
    constructor(
        public id:number,
        public topicId:number,
        public topicName:string,
        public frequency:string,
        public lastRun:Date,
        public vendorFilters:string[]
    ) {

    }
}
