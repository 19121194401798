<div class="jumbotron">
  <h1 class="text-center">Edit and Update Domain List</h1>
</div>

<form [formGroup]="domainsForm" (ngSubmit)="updateDomain()">
  <div formArrayName="domains" *ngFor='let domain of domains.controls; let i=index'>
      <div class="form-group row pt-1" [formGroupName]="i">
        <label>Domain Name</label>
        <input type="text" class="form-control" formControlName="name">
      </div> 
  </div>
  <button class="btn-md btn-outline-primary" type="submit">Update</button>  
</form>