<div class="row">
    <div class="col-sm-12">
        <app-title-card height="1000px">
            <ng-container header>
                <span class="title-icon zmdi zmdi-wrench">
                </span>
                Page Configurations
            </ng-container>
            <ng-container headerToolbar *ngIf="vaultUser">
                <!-- <span>Show Config Groups </span>
                <ejs-switch [(checked)]="showGroups"> </ejs-switch> -->
                <span style="font-size: small; ">Compact View</span>
                <ejs-switch [(checked)]="isMinified"></ejs-switch>

            </ng-container>
            <div style=" width: 100%; display: flex; flex-direction: row;gap:10px" *ngIf="vaultUser">
                <div
                    style="display: flex;flex-direction: row; flex-grow:1; gap: 10px; padding-bottom: 10px; flex-wrap: wrap">
                    <button (click)="filterByGroup(0,[])" class="cfg-btn" [class.selected]="selectedConfigGroupId === 0"><span>All Configs</span></button>
                    <button *ngFor="let groupBtn of configGroupList" class="cfg-btn"
                        [class.selected]="selectedConfigGroupId === groupBtn.id"
                        (click)="filterByGroup(groupBtn.id,groupBtn.configIds)">
                        <div [style.background-color]="groupBtn.color || 'blue'"></div><span>{{groupBtn.name}}</span>
                    </button>
                </div>
                <div style="display: flex; flex-direction: row; gap: 10px">
                    <button (click)="openDialog('scheduleViewDialog')" class="cfg-btn"
                        style="height: 25px;align-items: center;"><span class="zmdi zmdi-calendar"></span></button>
                    <button class="cfg-btn" style="height: 25px;align-items: center;"
                        (click)="openDialog('manageConfigGroups')"><span class="zmdi zmdi-settings"></span></button>
                </div>

            </div>

            <ejs-grid #configManagement id="configManagement" [dataSource]="configs" [allowPaging]='true'
                [pageSettings]='pageSettings' [editSettings]='editSettings' [allowSelection]='true' [toolbar]='toolbar'
                [selectionSettings]='selectOptions' allowResizing="true" (actionBegin)='actionBegin($event)'
                (commandClick)='commandClick($event)' (actionComplete)="actionComplete($event)" height="100%"
                [allowFiltering]="true" [filterSettings]="{type:'Excel'}" width="100%">

                <e-columns>

                    <e-column headerText='Actions' width='60px' [commands]='commands' textAlign="center"
                        [allowFiltering]="false"></e-column>
                    <e-column field='id' [visible]="false" [allowFiltering]="true"></e-column>
                    <e-column field='name' headerText='Name' width="150" [allowFiltering]="true">
                        <ng-template #template let-data>
                            <ng-container *ngIf="!isMinified else minifiedname">
                                <div class="row" style="margin: 0px 4px;">
                                    <div class="col"
                                        style="font-size: medium; font-weight: bold; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                                        {{data.name || "No Name"}}
                                    </div>
                                </div>
                                <div class="row"
                                    style="padding:0px; margin: 0px 4px; border-top: 1px solid grey; color:grey ">
                                    <div class="col" style="font-size: small; padding:2px; gap: 5px ;display: flex; flex-direction: row;margin:0; align-items: center;" >
                                        ID: <ng-container *ngIf="data.idInConfig else noIdInConfig">
                                            <span >{{data.id}}</span>
                                            <span class="zmdi" [class]="data.idInConfig === data.id ? 'zmdi-check-circle' : 'zmdi-close-circle'" [style.color]="data.idInConfig === data.id ? 'green' : 'red'"></span>
                                            <span  *ngIf="data.idInConfig !== data.id">ID Mismatch [{{data.idInConfig}}]</span>

                                        </ng-container>
                                        <ng-template #noIdInConfig>
                                            <span >{{data.id}}</span>
                                            <span class="zmdi zmdi-help-outline" style="color:orange"></span>
                                            <span>ID not specified</span>
                                        </ng-template>

                                    </div>
                                    <div class="col" style="font-size: small; text-align: right; padding:2px; margin:0">
                                        {{data.pageCount == null ? data?.links?.length : data.pageCount }} Pages
                                    </div>
                                </div>
                                <!-- <div style="background-color: grey; color:white; width: fit-content; padding: 2px 5px; display:flex; gap: 5px">
                                    <span>{{data?.modules?.length || 'No'}} Modules</span>
                                    <ejs-tooltip *ngIf="data?.modules?.length > 0">
                                        <ng-template #content>
                                            <ul>
                                                <li *ngFor="let mod of data?.modules">
                                                    {{mod.name}}
                                                </li>
                                            </ul>
                                        </ng-template>
                                        <span class="zmdi zmdi-caret-right-circle"></span></ejs-tooltip></div> -->
                                <div *ngIf="data?.modules?.length > 0" class="row"
                                    style="padding:0px; margin: 0;  color:#4d545c; ">
                                    <div class="col"
                                        style="font-size: small; padding:2px; margin:0; display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px">
                                        <span >
                                            Modules:
                                        </span>
                                        <div *ngFor="let module of data.modules"
                                            style="font-size: x-small;border-radius: .2rem; background-color:#4d545c; padding:1px 5px; color: white;">
                                            {{module.name}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #minifiedname>
                                <div style="display:flex; flex-direction: row;">
                                    <div style="min-width: 40px;">{{data.id}}</div>
                                    <div style="flex-grow: 1;">{{data.name || "No Name"}}</div>
                                    <div>{{data.pageCount || data?.links?.length}} pages</div>

                                </div>
                            </ng-template>
                        </ng-template>
                    </e-column>
                    <e-column field='lastChecked' headerText='Last Run' width="80px" [allowFiltering]="true"></e-column>
                    <e-column field='maxAgeInMinutes' headerText='Max Age (in mins)' width="80px"
                        [allowFiltering]="true">
                    </e-column>
                    <e-column field='config' headerText='Configuration' type="string" width="100px" textAlign="left"
                        [allowFiltering]="false" [disableHtmlEncode]='disableHtmlEncode' defaultValue="{{json}}">
                        <ng-template #editTemplate let-data>
                            <div style="width: 650px; display: flex; flex-direction: column; gap:10px; height:100%">
                                <div style="display: flex;flex-direction:row;align-items: center; gap:10px;">
                                    <span style="flex-grow: 1;">Config JSON</span>
                                    <div
                                    style="font-size: small; text-decoration: underline; color: salmon; cursor: pointer;"
                                    *ngIf="data.id !== data.idInConfig" (click)="configurationMismatchFix()">Fix Config ID</div>
                                    <ejs-dropdownlist width="250px" [dataSource]="config_templates" placeholder="Select a template (Optional)" (select)="onTemplateSelect($event)"
                                        [fields]="{text:'name',value:'id',groupBy:'cat'}"></ejs-dropdownlist>
                                </div>
                                <div style="flex-grow:1; height:390px; overflow:hidden; border-radius:.2rem;
                                box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2); position:relative
                                ">

                                    <ngx-codemirror #configEditor [(ngModel)]="rowData.config" style="height: auto;"
                                    [options]="codeMirrorOptions"></ngx-codemirror>
                                </div>



                            </div>
                        </ng-template>
                        <ng-template #template let-data>
                            <ng-container *ngIf="!isMinified else minifiedConfig">
                                <div class="row" style="margin: 0px 4px;">
                                    <div class="col"
                                        style="font-size: small ; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                                        {{data.config}}
                                    </div>
                                </div>
                                <div class="row"
                                    style="padding:0px; margin: 0px 4px; border-top: 1px solid grey; color:grey; ">
                                    <div class="col">
                                        <!-- <div class="row" style="font-size: small; text-align: left;">
                                            ID in Config: {{data.idInConfig || "No ID"}}
                                        </div> -->
                                        <div class="row" style="font-size: small; text-align: left;">
                                            {{data.consentOptions}}
                                        </div>
                                        <div class="row" style="font-size: small; text-align: left;">
                                            {{data.region}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #minifiedConfig>
                                {{data.consentOptions}} - {{data.region}}
                            </ng-template>
                        </ng-template>
                    </e-column>
                </e-columns>

            </ejs-grid>


        </app-title-card>
    </div>
</div>


<ejs-dialog #manageConfigGroups [animationSettings]='dialogAnimationSettings' [isModal]='true' width="600px"
    height="100%" [position]="{X: 'right', Y: 'center'}" [showCloseIcon]="false" header="Manage Configurations Groups"
    [visible]="false" [closeOnEscape]="true" (overlayClick)="closeDialog('manageConfigGroups')" [allowDragging]="true">
    <ng-template #content>
        <ejs-grid height="600px" width="100%" [dataSource]="configGroupList" [toolbar]="configGroupToolbar"
            (toolbarClick)="onToolbarClick($event)" [allowResizing]="true">
            <e-columns>
                <e-column field="name" headerText="Name" width="100" textAlign="left" minWidth="200px"></e-column>
                <e-column headerText="# configs" width="100" textAlign="left" [autoFit]="true">
                    <ng-template let-data #template>
                        <div style="display: flex; flex-direction: row; gap: 5px; justify-content: flex-start;">
                            <span>{{data?.configIds?.length || 0}}</span>
                        </div>
                    </ng-template>
                </e-column>
                <e-column textAlign="left" [autofit]="true" minWidth="200px">
                    <ng-template #template let-data>
                        <div class="toolbar">
                            <button (click)="onConfigGroupEdit(data)"> <span class="zmdi zmdi-edit"></span>Edit</button>
                            <button (click)="onConfigGroupDelete(data.id)"> <span
                                    class="zmdi zmdi-delete"></span>Remove</button>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>

    </ng-template>
</ejs-dialog>
<ejs-dialog #updateConfigGroup [isModal]='true' width="600px" height="300px" header="Edit Configuration Group"
    [visible]="false" [closeOnEscape]="true" [showCloseIcon]="true" (overlayClick)="closeDialog('updateConfigGroup')">
    <ng-template #content>
        <div style="display: grid; grid-template-columns: 100px auto ; gap: 10px; align-items: center;">
            <label>Group name:</label>
            <ejs-textbox [(ngModel)]="selectedConfigGroup.name" placeholder="Name"></ejs-textbox>
            <label> Color:</label>
            <ejs-input ejs-colorpicker [inline]="false" mode="Palette" type='color' [value]="selectedConfigGroup?.color"
                [presetColors]="customPaletteColors" [showButtons]="false" [ctrlSwitch]="false" [modeSwitcher]="false"
                (beforeRender)="beforeRoundedTileRender($event)" (change)="onColorPickerChange($event)"></ejs-input>
            <label>Config Ids:</label>
            <ejs-multiselect [(ngModel)]="selectedConfigGroup.configIds" [dataSource]="configs"
                [fields]="{text: 'id', value: 'id'}">
                <ng-template #itemTemplate let-data>
                    <div style="display: flex; flex-direction: row; gap: 5px; justify-content: flex-start;">
                        <span>{{data.id}} - {{data.name}}</span>
                    </div>
                </ng-template>
            </ejs-multiselect>

        </div>

    </ng-template>
    <ng-template #footerTemplate>
        <div class='toolbar'>
            <button>Cancel</button>
            <button (click)="onConfigGroupUpdate()">Save Changes</button>
        </div>
    </ng-template>
</ejs-dialog>
<ejs-dialog #createConfigGroup [isModal]='true' width="600px" height="300px" header="Create New Configuration Group"
    [visible]="false" [closeOnEscape]="true" [showCloseIcon]="true" (overlayClick)="closeDialog('createConfigGroup')">
    <ng-template #content>
        <div style="display: grid; grid-template-columns: 100px auto ; gap: 10px; align-items: center;">
            <label>Group name:</label>
            <ejs-textbox [(ngModel)]="selectedConfigGroup.name" placeholder="Name"></ejs-textbox>
            <label> Color:</label>
            <ejs-input ejs-colorpicker [inline]="false" mode="Palette" type='color'
                [value]="selectedConfigGroup?.color || '#000000'" [presetColors]="customPaletteColors"
                [showButtons]="false" [ctrlSwitch]="false" [modeSwitcher]="false"
                (beforeRender)="beforeRoundedTileRender($event)" (change)="onColorPickerChange($event)"></ejs-input>
            <label>Config Ids:</label>
            <ejs-multiselect [(ngModel)]="selectedConfigGroup.configIds" [dataSource]="configs" placeholder="Select Config Ids" mode="CheckBox"
                [showSelectAll]="true" selectAllText="Select All" [fields]="{text: 'id', value: 'id'}">
                <ng-template #itemTemplate let-data>
                    <div style="display: flex; flex-direction: row; gap: 5px; justify-content: flex-start;">
                        <span>{{data.id}} - {{data.name}}</span>
                    </div>
                </ng-template>
            </ejs-multiselect>

        </div>

    </ng-template>
    <ng-template #footerTemplate>
        <div class="toolbar">
            <!-- <button>Cancel</button> -->
            <button (click)="onConfigGroupCreateSubmit()">Save Changes</button>
        </div>
    </ng-template>
</ejs-dialog>

<ejs-dialog #scheduleViewDialog [isModal]='true' width="600px" header="Schedule View (Next 7 Runs Per Config)"
    [visible]="false" [closeOnEscape]="true" height="100%" [position]="{X: 'right', Y: 'center'}"
    [showCloseIcon]="false" (overlayClick)="closeDialog('scheduleViewDialog')">
    <ng-template #content>

        <ejs-schedule #scheduleView width='100%' height='100%' [eventSettings]="eventSettings">
            <e-views> <e-view option="Week" startHour="07:00" endHour="15:00"></e-view>
                <e-view option="Month" [showWeekend]="true"></e-view>
            </e-views>
            <ng-template #eventSettingsTemplate let-data>

                {{data.configId}}
            </ng-template>
        </ejs-schedule>

    </ng-template>
</ejs-dialog>