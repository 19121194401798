import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ReportingService } from '../../../services/reporting/reporting.service';
import * as tinycolor from "tinycolor2";
import * as Chart from "chart.js"
declare var $:any;

@Component({
  selector: 'reportIncidents',
  templateUrl: './report-incidents.component.html',
  styleUrls: ['./report-incidents.component.css']
})
export class ReportsIncidentsComponent implements OnInit, AfterViewInit {

  totalsData:any;
  createdTotal:number;
  resolvedTotal:number;
  selectedDateRange:string = "week";
  dateRangeLUT:any;
  start:number;
  end:number;
  loadingData:boolean;
  showCustom:boolean;
  customDateRange:any;
  
  @ViewChild('dtPickers') 
  set dtPickers (v) {
    if ( v ) {
      $(".datetimepicker").datetimepicker({
        autoclose: true,
        componentIcon: '.zmdi.zmdi-calendar',
        format: 'mm/dd/yyyy',
        navIcons:{
          rightIcon: 'zmdi zmdi-chevron-right',
          leftIcon: 'zmdi zmdi-chevron-left'
        }
      }).on('changeDate', (ev)=>{
          console.log(ev.target.id, ev.date);
          if ( ev.target.id == 'customStart' ){            
            this.start = new Date(ev.date).getTime();
            console.log("start", this.start);
          } else if ( ev.target.id == 'customEnd' ){
            this.end = new Date(ev.date).getTime();
            console.log("end", this.end);
          }
      });
    }
  }

  createNowHour():number{
    const now = new Date();
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    return now.getTime();
  }
  
  constructor(public reportingService:ReportingService) { 
    this.dateRangeLUT = {"day": 1, "week": 7, "month": 30, "year":365};
    
    const now = this.createNowHour();    
    this.start = now - this.getMsForDays(this.dateRangeLUT[this.selectedDateRange]);
    this.end = now;    
    this.loadingData = false;
    this.showCustom = false;
  }

  ngOnInit() {
    
  }
  ngAfterViewInit(){
    this.loadData(this.selectedDateRange);
  }

  loadData(range){
    this.loadingData = true;
    var dataFunc = null;

    var listener = (data) => {
      try {        
        
        let responseObj = JSON.parse(data.toString());

        this.loadingData = false;
        this.totalsData = responseObj.incidents;
        this.createdTotal = (this.totalsData.Green.created + this.totalsData.Yellow.created + this.totalsData.Red.created);
        this.createdTotal = this.createdTotal == 0 ? 1 : this.createdTotal;
        this.resolvedTotal = (this.totalsData.Green.resolved + this.totalsData.Yellow.resolved + this.totalsData.Red.resolved);
        this.resolvedTotal = this.resolvedTotal == 0 ? 1 : this.resolvedTotal; 
        this.buildEventGraph(responseObj.events);

      }catch(e){
        
      }
    };

    if (range == 'week') {
      dataFunc = this.reportingService.getIncidentTotalsDataWeek().subscribe(listener);
    } else if ( range == 'month') {
      dataFunc = this.reportingService.getIncidentTotalsDataMonth().subscribe(listener);
    }
  }

  buildEventGraph(data){

    const green = tinycolor("#34a853");
    const yellow = tinycolor( "#fbbc05");
    const red = tinycolor("ea4335");

    const greenCode = data.map((e, i)=>{
      return e['Green Code File Total'];
    });

    const redAndYellowCode = data.map((e, i)=>{
      return e['R&Y Code File Total'];
    });

    const labels = data.map((e)=>{
      if ( this.selectedDateRange == 'day'){
        return new Date(e.date).toLocaleTimeString();
      } else {
        return new Date(e.date).toLocaleDateString();
      }      
    });


    var ctx = document.getElementById("line-chart");
  
    var lineChartData = {
      labels: labels,
      tooltips: {
        enabled: true,
        position: 'average'
      },
      datasets: [                
        {
          label: "Red & Yellow Code Incidents",
          borderColor: red.toString(),
          backgroundColor: red.setAlpha(.8).toString(),
          data: redAndYellowCode
        },
        {
          label: "Green Code Incidents",
          borderColor: green.toString(),
          backgroundColor: green.setAlpha(.8).toString(),
          data: greenCode
        }
      ]
    };
  
    var line = new Chart(ctx, {
      type: 'line',
      data: lineChartData,
      options:{
        scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
              display: true,
              labelString: 'Date/Time'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: '# of Incidents'
            },
            ticks:{
              min:0
            }
					}]
				}
      }
    });

  }

  changeDateRange(range:string) {

    this.selectedDateRange = range;

    if ( range == 'custom' ){
      this.showCustom = true;
      this.start = this.end = -1;
      return;
    }

    this.showCustom = false;

    const now = this.createNowHour();
    this.start = now - this.getMsForDays(this.dateRangeLUT[this.selectedDateRange]);
    this.end = now;
    this.loadData(range);
  }

  getMsForDays(numDays:number):number{
    return (1000 * 60 * 60 * 24) * numDays;
  }

  loadCustomRange() {

    if ( this.start == -1 || this.end == -1 ){
      alert('Select both start and end dates');
      return;
    }

    // this.loadData();
  }
}