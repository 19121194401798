<div class="row" *ngIf="!isEditingRule; else EditRuleUI">
  <div class="col-sm-12">
    <div class="card card-table card-border">
      <div class="card-header">
        <div class="tools">
          <button *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" type="button" (click)="onNewClick();" class="btn btn-rounded btn-space btn-primary"><i style="color: white;" class="icon icon-left zmdi zmdi-plus"></i> New</button>
        </div>
        <span class="title">Advanced Event Rules</span>
        <span class="card-subtitle">Create and manage your advanced event rules below</span>
      </div>
      <div class="card-body">
        <ejs-grid #rulesGrid
        [dataSource]='rules$ | async'
        locale='en-US' 
        [allowPaging]="true" 
        [pageSettings]="pageSettings" 
        [allowSorting]="true" 
        [sortSettings]="sortSettings"
        [allowSelection]="false"
        [enableHover]="false"
        [toolbar]="toolbar"
        [allowResizing]="true"
        (dataBound)="onDataBound()"
        (created)="onGridCreate()"
        (dataStateChange)= 'dataStateChange($event)'
        >
        <e-columns>
          <e-column [visible]="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" width="10%" headerText="Actions">
            <ng-template #template let-data>
              <button class="" (click)="showDeletePrompt(data)"><i class="icon icon-left zmdi zmdi-delete"></i></button>&nbsp;
              <button class="" (click)="onEditRuleClick(data)"><i class="icon icon-left zmdi zmdi-edit"></i></button>&nbsp;
              <button class="" (click)="onDuplicateClick(data)"><i class="icon icon-left zmdi zmdi-copy"></i></button>
            </ng-template>
          </e-column>            
          <e-column field='name' headerText='Name' width="25%"></e-column>
          <e-column field='enabled' headerText='Enabled'></e-column>
          <e-column headerText='Output Type' width="65%">
            <ng-template #template let-data>
              {{getOutputNames(data)}}
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="mod-confirm-rule-delete" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span class="mdi mdi-close"></span></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
          <h3>Warning!</h3>
          <p>Are you sure you want to delete "{{selectedRule?.name}}"? You cannot undo this action!</p>
          <div class="mt-8">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="onDeleteClick();">Proceed</button>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<ng-template #EditRuleUI>
  <new-advanced-event-rule 
    (cancelEvent)="onNewCancel($event)" 
    (saveEvent)="onNewSave($event)" 
    [rule]="selectedRule" 
    [isNewRule]="isNewRule"
    [eventTypes]="eventTypes"
    [eventDestinationTypes]="eventDestinationTypes">
  </new-advanced-event-rule>
</ng-template>