import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { Observable, forkJoin } from 'rxjs';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Topic } from '../../../models/Topic';
import { TopicService } from '../../../services/topic/topic.service';
import { TopicSubscription } from '../../../models/TopicSubscription';
import { SubscriptionSettings } from '../../../models/SubscriptionSettings';
import { Vendor } from '../../../models/Vendor';

declare var $:any;

@Component({
  selector: 'subscriptionConfig',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit, AfterViewInit, OnDestroy {
    
  @Input() public userEmail:string;
  @Input() public userId:string;

  vendors$:Observable<Vendor[]>;
  topics$:Observable<Topic[]>;
  subscriptions$:Observable<TopicSubscription[]>;
  subSettings$:Observable<SubscriptionSettings>;
  notificationOptions:any;
  
  closeResult: string;

  alertConfigForm:FormGroup;
  selectedConfig:any;
  alertConfigs:any[];
  
  buttonsEnabled:boolean;
  
  vendors:Vendor[];
  topics:Topic[];
  subscriptions:TopicSubscription[];
  subsToDelete:number[];
  settings:SubscriptionSettings;

  constructor(private vendorService:VendorsService, 
              private alertService:AlertsService, 
              private _fb: FormBuilder, 
              private spinnerService:SpinnerService,
              private notificationService: NotificationsService,
              private modalService: NgbModal,
              private topicService:TopicService
  ) { 
    
    this.userEmail = this.userEmail ? this.userEmail : JSON.parse(localStorage.profile).email;
    this.spinnerService.toggle(true);
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };

    this.buttonsEnabled = true;
    this.subsToDelete = [];
  }

  ngOnInit() {

    this.vendors$ = this.vendorService.getVendors();
    this.topics$ = this.topicService.getTopics();
    this.subscriptions$ = this.topicService.getTopicSubscriptions(this.userId);
    this.subSettings$ = this.topicService.getSubscriptionSettings();

    this.alertConfigForm = this._fb.group({      
      topics: [],
      subscriptions: [],
      vendorFilter: []
    });

    this.alertConfigForm.get('subscriptions').valueChanges.subscribe(selectedSub => {
      console.log("subscriptions val change", selectedSub);
      this.loadVendorFilterUI(selectedSub);      
    });
  }

  loadVendorFilterUI(selectedSub){
    
    if (selectedSub){
      console.log("selectedSub");
      console.log(selectedSub);    
      console.log("selectedSub.vendorFilters");
      console.log(selectedSub.vendorFilters);
    
  
      const valueToPatch = selectedSub.vendorFilters ? selectedSub.vendorFilters.split(',') : [];
      var select2FormattedValue = [];
      if ( Array.isArray(valueToPatch)){
        valueToPatch.forEach((s)=>{
          const foundVendor = this.vendors.filter((v)=>{return v.name == s});
          const index = foundVendor ? this.vendors.indexOf(foundVendor[0]) : -1;
          if ( index >= 0 ){
            select2FormattedValue.push(`${index}: '${s}'`);
          }
        });
      }
      console.log(select2FormattedValue);
    } else {
      
    }
      
    setTimeout(() => {
      $(".vendorFilters").val(select2FormattedValue);
      $(".vendorFilters").trigger('change');
    }, 10);      
  }

  ngAfterViewInit(): void {
    $(".topics").select2({width: '100%', placeholder:'Select a topic(s)...'});
    $(".vendorFilters").select2({width: '100%', placeholder:'Select a vendor(s)...'});
    
    forkJoin(this.topics$, this.subscriptions$, this.subSettings$, this.vendors$).subscribe( (val)=>{
      this.topics = val[0];
      this.subscriptions = val[1];
      this.settings = val[2];
      this.vendors = val[3];      
      this.spinnerService.toggle(false);

      if ( this.subscriptions && this.subscriptions[0] ){
        if (this.subscriptions[0].vendorFilters){
          this.loadVendorFilterUI(this.subscriptions[0]);
        }
      }

    });
  }

  ngOnDestroy(): void {
  }

  addSubscription(){
    console.log(this.alertConfigForm.value);
    var topicIds = $(".topics").select2('data').map((item)=>{
      return {id: Number(item.id.split(':')[1]), name: item.text};
    });    
    var tempSubs = [];
    for (let index = 0; index < topicIds.length; index++) {
      const topic = topicIds[index];

      const temp = this.topics.filter((t)=>{ return t.id == topic.id});
      var frequency = '@realtime';
      if ( temp && temp.length == 1 && temp[0].customerId == 1 && temp[0].name.indexOf("New Incident: ") == -1) {
        frequency = 'na';
      }
      const newSub = new TopicSubscription(null, topic.id, topic.name, frequency, null, null);
      tempSubs.push(newSub);
    }

    var postData = {subscriptions:tempSubs}
    if ( this.userId ){
      postData["userId"] = this.userId;
    }

    this.spinnerService.toggle(true);
    this.topicService.createTopicSubscription(postData).subscribe((result)=>{

      this.alertConfigForm.patchValue({
        subscriptions: []
      });

      this.topicService.getTopicSubscriptions(this.userId).subscribe( (val)=>{                
        this.subscriptions = val;
        $(".topics").val(null).change();
        
        this.spinnerService.toggle(false);

        this.notificationService.success('Success!', 'Subscriptions were saved successfully!', this.notificationOptions);

        if ( this.subscriptions && this.subscriptions[0] ){
          if (this.subscriptions[0].vendorFilters){
            this.loadVendorFilterUI(this.subscriptions[0]);
          }
        }  
      });
    },
    (error)=>{
      console.log(error);
      const errMsg = error.error ? error.error : 'Subscriptions were not saved.';
      this.spinnerService.toggle(false);
      this.notificationService.error('Oops!', errMsg, this.notificationOptions);
    });
  }
  
  deleteSubs(){    
    
    const subToDelete = this.alertConfigForm.value.subscriptions ? this.alertConfigForm.value.subscriptions : this.subscriptions[0];
    console.log("subToDelete");
    console.log(subToDelete);

    // const justIds = this.alertConfigForm.value.subscriptions.map((s)=>{return s.topicId;});

    var postData = {subscriptions:[subToDelete]}
    if ( this.userId ){
      postData["userId"] = this.userId;
    }

    this.spinnerService.toggle(true);
    this.topicService.deleteTopicSubscription(postData).subscribe((result)=>{
      console.log("Before");
      console.log(this.subscriptions);
      this.subscriptions = this.subscriptions.filter((s)=>{ return s.topicId != subToDelete.topicId});
      console.log("After");
      console.log(this.subscriptions);
      this.loadVendorFilterUI(this.subscriptions[0]);
      
      this.spinnerService.toggle(false);
      this.notificationService.success('Success!', 'Subscriptions were deleted successfully!', this.notificationOptions);
    },
    (error)=>{
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.error('Oops!', 'Subscriptions were not deleted.', this.notificationOptions);
    });
  }

  updateFilter(){
    const selectedSub = this.alertConfigForm.value.subscriptions ? this.alertConfigForm.value.subscriptions : this.subscriptions[0];
    var filters = $(".vendorFilters").select2('data');
    var vendorIds = [];
    var vendorNames = [];
    filters.forEach((s)=>{
      console.log(s);
      const foundVendor = this.vendors.filter((v)=>{return v.name == s.text});
      const vendorId = foundVendor && foundVendor.length == 1 ? foundVendor[0].id : null;
      if ( vendorId ){
        vendorIds.push(vendorId);
        vendorNames.push(foundVendor[0].name);
      }
    });
    console.log("VIDS", vendorIds);
    console.log(selectedSub);

    this.spinnerService.toggle(true);
    this.topicService.updateTopicSubscriptionVendorFilter(selectedSub.id, vendorIds).subscribe((result)=>{      
      selectedSub.vendorFilters = vendorNames.join(",");
      console.log(this.subscriptions);
      this.spinnerService.toggle(false);
      this.notificationService.success('Success!', 'Filters were saved successfully!', this.notificationOptions);
    },
    (error)=>{
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.error('Oops!', 'Filters were not saved.', this.notificationOptions);
    });

  }
}
