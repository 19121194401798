import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiffService {
  
  worker:Worker;
  subject:Subject<any>;
  
  constructor() { 
    this.worker = new Worker('assets/js/diffWorker.js');
  }

  public startDiff(file1, file2):Observable<any> {
    console.log("Diff Service...");
    // if ( this.subject){
    //   this.subject.complete();
    // }
    this.subject = new Subject<any>();
    this.worker.addEventListener('message', (e) =>{
      this.subject.next(e);
      this.subject.complete();
    }, false);
    
    this.worker.postMessage({'cmd': 'diff', 'file1': file1, 'file2': file2});
    
    return this.subject;
  }

}