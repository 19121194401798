import { Pipe, PipeTransform } from '@angular/core';
import { Vendor } from '../models/Vendor';


@Pipe({
  name: 'searchfilter'
})
export class SearchfilterPipe implements PipeTransform {

  transform(listings: Vendor[], searchValue: string): Vendor[] {
    if (!listings || !searchValue){
      return listings;
    }
    return listings.filter(vendor => vendor.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
  }

}
