import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import { VaultBaseService } from '../VaultBaseService';

@Injectable({
  providedIn: 'root'
})
export class AlertsService extends VaultBaseService {

  private static ALERTS:string = "alerts";
  private static ALERT_DETAILS:string = "ALERT_DETAILS";
  private static TRIGGERS:string = "triggers";
  private static CONFIGS:string = "configs"

  cache:{[type:string]:Observable<any>;} = {};  

  public getAlerts(limit:string=""):Observable<any>{
    const cacheKey = `${AlertsService.ALERTS}:${limit}`; 
    if (!this.cache[cacheKey]) {
      const url = env.api.url + AlertsService.ALERTS
      const params = new HttpParams().set('limit', limit);
      this.cache[cacheKey] = this.makeHttpRequest(url, params);
    }
    return this.cache[cacheKey];
  }

  public getAlertDetails(alertId):Observable<any>{
    const cacheKey = `${AlertsService.ALERT_DETAILS}:${alertId}`; 
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}${AlertsService.ALERTS}/${alertId}`;       
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getTriggers():Observable<any>{
    const cacheKey  = `${AlertsService.TRIGGERS}`;
    if (!this.cache[cacheKey]) {
      const url = `${env.api.url}${AlertsService.TRIGGERS}`;
      this.cache[AlertsService.TRIGGERS] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getConfigs(name=""):Observable<any>{
    // const cacheKey  = `${AlertsService.CONFIGS}`;
    // if (!this.cache[cacheKey]) {
    //   const url = `${env.api.url}alerts/${AlertsService.CONFIGS}`;
    //   this.cache[cacheKey] = this.makeHttpRequest(url);
    // }
    // return this.cache[cacheKey];
      const url = `${env.api.url}alerts/${AlertsService.CONFIGS}`;
      return this.makeHttpRequest(url);
  }

  public updateConfig(config:any):Observable<any>{
    const url = `${env.api.url}alerts/config`;
    return this.authHttp.put(url, JSON.stringify(config));
  }

  public createConfig(config:any):Observable<any>{
    const url = `${env.api.url}alerts/config`;
    return this.authHttp.post(url, JSON.stringify(config));
  }

  public deleteConfig(id:number):Observable<any>{
    const url = `${env.api.url}alerts/config/${id}`;
    return this.authHttp.delete(url);
  }

  clearConfigCache(){
    delete this.cache[AlertsService.CONFIGS];
  }

  public clearCache(){
    this.cache = {};
  }  
}
