<div class="main-content container-fluid">
    <div class="user-profile">
        <div class="row">
            <div class="col-lg-12">
                <div class="user-display be-loading">
                    <div class="user-display-bg"><img *ngIf="vendor" src="https://{{cdnUrl}}/vendors/screenshot/{{vendor.website ? vendor.website : 'vaultjs.com'}}.jpg"></div>
                    <div class="user-display-bottom">
                        <div class="user-display-info">
                            <div class="name">{{vendor ? vendor.name : ""}}</div>                            
                        </div>
                        <div class="row user-display-details">
                            <div class="user-info-list">
                                <div>
                                    <vendor-info [vendor]="vendor"></vendor-info>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>            
        </div>
    </div>
</div>