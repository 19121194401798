import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'simple-value-display',
  templateUrl: './simple-value-display.component.html',
  styleUrls: ['./simple-value-display.component.css']
})
export class SimpleValueDisplayComponent implements OnInit {

  @Input('value') value: string;
  @Input('label') label: string;
  constructor() {

   }

  ngOnInit(): void {

  }

}
