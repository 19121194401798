<div class="row">
    <div class="col-lg-12">
        <div class="card" style="padding: 10px">
            <div class="card-header" style="
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          gap: 10px;
          padding: 10px 0px;
          margin: 0px 0px 0px 10px;
        ">
                <div style="object-fit: contain; width: 60px; margin-right: 10px">
                    <img width="100%" src="/assets/svg/{{ reportIcon }}.svg" />
                </div>
                <div style="
            display: flex;
            gap: 10px;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
          ">
                    <div style="font-size: x-large" *ngIf="!viewName; else ifView">
                        {{ reportTitle }}
                    </div>
                    <ng-template #ifView>
                        <div style="font-size: x-large">
                            {{ viewName }}
                            <span style="font-size: large; color: #fc7d73">{{
                                reportTitle
                                }}</span>
                        </div>
                    </ng-template>
                    <ng-template #hasCustomDescription>
                        <div style="font-size: medium">
                            {{ customReportDescription || "Custom Report" }}
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12" style="overflow: hidden; margin-bottom: 10px">
        <div style="
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        margin: 0px;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        padding: 3px;
      ">
            <div style="
          min-width: 150px;
          border-radius: 0.25rem;
          background-color: #4d545c;
          color: white;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          padding: 5px 7px;
          font-weight: 400;
          font-size: medium;
          align-items: center;
        " *ngFor="let filter of filterParamsList">
                <div>{{ filter.field }}</div>
                <div style="color: #e5ebe8; text-transform: uppercase; font-size: small">
                    {{ filter.operator }}
                </div>
                <div>{{ filter.value }}</div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card" style="padding: 10px">
            <ejs-tooltip id="maingrid" #tooltip (beforeRender)="beforeRender($event)" target=".e-headertext">
                <ejs-grid #grid [allowFiltering]="true" [allowSorting]="true" [allowPaging]="true"
                    [allowGrouping]="true" [allowReordering]="true" [allowResizing]="true" [showColumnChooser]="true"
                    [showColumnMenu]="true" [enableHeaderFocus]="true" height="700px" [columnMenuItems]="columnMenuItems"
                    (columnMenuClick)="onColumnMenuClick($event)"
                    (dataStateChange)="dataStateChange($event)" (actionBegin)="onActionBegin($event)"
                    (rowSelected)="onRowSelected($event)" (actionComplete)="onActionComplete($event)"
                    [allowExcelExport]="true" [dataSource]="reportDataManager" [columns]="columns">

                    <ng-template #toolbarTemplate>
                        <div style="display: flex; flex-direction: row; gap: 10px;margin-bottom: 10px;">
                            <div #section1 class="toolbar-buttons">
                                <button ejs-button cssClass="high-btn" iconCss="zmdi zmdi-download"
                                    (click)="onExportReport()">
                                    Export
                                </button>
                                <button ejs-button cssClass="low-btn" iconCss="e-icons e-auto-fit-content  low-icons"
                                    (click)="doAutoFit()">
                                    AutoFit
                                </button>
                                <!-- <button ejs-button iconCss="zmdi zmdi-refresh">Reset</button>  -->
                            </div>
                            <div #section2 class="toolbar-buttons">
                                <button ejs-button cssClass="high-btn" iconCss="zmdi zmdi-bookmark-outline"
                                    (click)="openLocalViewSelectDialog()">
                                    Local Views
                                </button>
                                <button ejs-button cssClass="low-btn" iconCss="zmdi zmdi-bookmark"
                                    (click)="openPredefinedViewSelectDialog()">
                                    Predefined Views
                                </button>
                            </div>
                            <div #section2 class="toolbar-buttons">
                                <button ejs-button cssClass="high-btn" iconCss="e-icons e-filter icon-white "
                                    (click)="openComplexQueryDialog()">
                                    C. Query
                                </button>
                                <button ejs-button cssClass="low-btn" iconCss="e-icons e-freeze-column low-icons"
                                    (click)="openColumnSelectorDialog()">
                                    Columns
                                </button>
                                <button ejs-button cssClass="low-btn" iconCss="e-icons e-filter-clear  low-icons"
                                (click)="onClearFiltering()">
                                    Clear Filtering
                                </button>
                            </div>
                            <div #section2 class="toolbar-buttons" *ngIf="authService?.isVaultUser()">
                                <button ejs-button cssClass="high-btn" iconCss="zmdi zmdi-settings" (click)="openReportViewConfigDialog()">
                                    Settings
                                </button>
                            </div>
                            <div #section4></div>
                        </div>
                    </ng-template>
                </ejs-grid>
            </ejs-tooltip>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <app-title-card height="500px" [hideInfoIcon]="true">
            <ejs-grid [dataSource]="selectedData" [allowTextWrap]="true" height="100%" [allowFiltering]="false" [toolbar]="selectGridToolbar" (toolbarClick)="onSelectGridToolbarClick($event)">
                <e-columns>
                    <e-column field="field" headerText="Field" [autoFit]="true">
                        <ng-template #template let-data>
                            <span style="font-weight: bold">{{ data.field }}</span>
                        </ng-template>
                    </e-column>
                    <e-column field="value" headerText="Value">
                        <ng-template #template let-data>
                            <span style="white-space: pre-wrap" *ngIf="!data.isJSON">{{
                                data.value
                                }}</span>
                            <span style="white-space: pre-wrap" *ngIf="data.isJSON">{{
                                data.value | json
                                }}</span>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </app-title-card>
    </div>
</div>
<ejs-dialog #LocalViewSelectDialog [closeOnEscape]="true" showCloseIcon="true" [visible]="false" width="500px"
    height="700px" [allowDragging]="false" [isModal]="true" [animationSettings]="dialogAnimationSettings">
    <ng-template #header> Local View Selection </ng-template>
    <ng-template #content>
        <div style="display: flex; flex-direction: column; gap: 10px">
            <button ejs-button iconCss="zmdi zmdi-playlist-plus" (click)="onCreateLocalView()">
                Create New View
            </button>
            <ejs-grid height="400px" [dataSource]="localViewList" [allowSelection]="false">
                <e-columns>
                    <e-column field="name" headerText="Name" minWidth="100px"></e-column>
                    <e-column field="columns" headerText="Columns" [autoFit]="true">
                        <ng-template #template let-data>
                            {{ visibleColumnsToString(data.columns) }}
                        </ng-template>
                    </e-column>
                    <e-column headerText="Actions" width="100px">
                        <ng-template #template let-data>
                            <div style="
                  margin: 0;
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  width: 80px;
                ">
                                <button class="icon-button" (click)="onSelectLocalView(data)">
                                    <span class="zmdi zmdi-play"></span>
                                </button>
                                <button class="icon-button" (click)="onDeleteLocalView(data.name)">
                                    <span class="zmdi zmdi-delete"></span>
                                </button>
                            </div>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </ng-template>
</ejs-dialog>
<ejs-dialog #PredefinedViewSelectDialog [closeOnEscape]="true" showCloseIcon="true" [visible]="false" width="500px"
    height="700px" [allowDragging]="false" [isModal]="true" [animationSettings]="dialogAnimationSettings">
    <ng-template #header> Predefined Views </ng-template>
    <ng-template #content>
        <div style="display: flex; flex-direction: column; gap: 10px">
            <p class="section-header" *ngIf="authService?.isVaultUser()">Create New View</p>
            <div style="color: red; font-size: medium; font-weight: bold" *ngIf="cspnIsEmpty">
                Invalid Name!
            </div>
            <div style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-content: center;
        " *ngIf="authService?.isVaultUser()">
                <ejs-textbox #createPredefinedStateName type="text" placeholder="Predefined View Name"
                    style="flex-grow: 1" [class.e-error]="cspnIsEmpty"></ejs-textbox>
                <button class="icon-button" (click)="onAddS3State()">
                    <span class="zmdi zmdi-playlist-plus"></span>
                </button>
            </div>
            <p class="section-header">Available Views (Predefined Only)</p>
            <ejs-grid height="375px" [dataSource]="s3ViewList">
                <e-columns>
                    <e-column field="name" headerText="Name" minWidth="100px"></e-column>
                    <e-column field="columns" headerText="Columns" [autoFit]="true">
                        <ng-template #template let-data>
                            {{ visibleColumnsToString(data.columns) }}
                        </ng-template>
                    </e-column>
                    <e-column headerText="Actions" width="120px">
                        <ng-template #template let-data>
                            <div style="
                  margin: 0;
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  min-width: fit-content;
                ">
                                <button class="icon-button" (click)="onSelectView(data)">
                                    <span class="zmdi zmdi-play"></span>
                                </button>
                                <button class="icon-button" (click)="addViewToLibrary(data)">
                                    <span class="zmdi zmdi-collection-plus" *ngIf="authService?.isVaultUser()"></span>
                                </button>
                                <button class="icon-button" (click)="onDeleteS3View(data)"
                                    *ngIf="authService?.isVaultUser()">
                                    <span class="zmdi zmdi-delete"></span>
                                </button>
                            </div>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </ng-template>
</ejs-dialog>
<ejs-dialog [animationSettings]="dialogAnimationSettings" #ComplexQueryDialog [closeOnEscape]="true"
    showCloseIcon="true" [visible]="false" width="75%" height="500px" [allowDragging]="false" [isModal]="true">
    <ng-template #header> Complex Query </ng-template>
    <ng-template #content>
        <div style="display: flex; flex-direction: row; gap: 5px; height: 100%">
            <div *ngIf="showLogger" style="width: 700px; height: 100%">
                <ejs-grid [dataSource]="queryLog" width="100%" #QueryLogGrid height="100%" [contextMenuItems]="queryLogContextMenuItems" (contextMenuClick)="queryLogContextMenuClick($event)">
                    <e-columns>
                        <e-column field="timestamp" [autoFit]="true" headerText="Timestamp">
                            <ng-template #template let-data>
                                {{ data.timestamp | date : "shortTime" }}
                            </ng-template>
                        </e-column>
                        <e-column field="status" [autoFit]="true" headerText=" ">
                            <ng-template #template let-data>
                                <ng-container [ngSwitch]="data.status">
                                    <span *ngSwitchCase="'success'" style="color: green" class="zmdi zmdi-check"></span>
                                    <span *ngSwitchCase="'failed'" style="color: red"
                                        class="zmdi zmdi-alert-circle-o"></span>
                                    <span *ngSwitchCase="'running'" style="color: blue"
                                        class="zmdi zmdi-refresh spin"></span>
                                    <span *ngSwitchDefault>{{ data.status }}</span>
                                </ng-container>
                            </ng-template>
                        </e-column>
                        <e-column field="query" [clipMode]="'EllipsisWithTooltip'" minWidth="10px"
                            headerText="Query">
                        </e-column>
                        <e-column field="count" [autoFit]="true" headerText="Rows">
                            <ng-template #template let-data>
                                {{ data.count !== -1 ? data.count : "" }}
                                <span class="zmdi zmdi-block" style="color:red" *ngIf="data.count === -1"></span>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div style="
          display: flex;
          flex-direction: column;
          min-width: 700px;
          height: 100%;
          flex-grow: 1;
        ">
                <div style="display: flex; flex-direction: row">
                    <textarea id="sqlQuery" style="
              flex-grow: 1;
              height: 100px;
              margin-left: 20px;
              margin-right: 20px;
              border-radius: 0.25rem;
              border: lightgrey 0.5px solid;
              resize: none;
              padding: 10px;
              font-size: large;
              font-family: monospace;
              color: grey;
            "></textarea>
                    <div style="display: flex; flex-direction: column; gap: 10px">
                        <ejs-tooltip content="Text to Query Builder">
                            <button (click)="ParseFromSQL()" class="icon-button">
                                <span class="zmdi zmdi-download"></span>
                            </button>
                        </ejs-tooltip>
                        <ejs-tooltip content="Query Builder to Text">
                            <button (click)="GetSQLFromBuilder()" class="icon-button">
                                <span class="zmdi zmdi-upload"></span>
                            </button>
                        </ejs-tooltip>
                    </div>
                </div>
                <div style="overflow-y: auto">
                    <ejs-querybuilder #filterQueryBuilder (change)="onQueryChange($event)" width="60%"
                        (dataBound)="queryDataBound($event)" [columns]="qbColumns || _emptyRule"></ejs-querybuilder>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div class="toolbar">
            <button ejs-button [isToggle]="true" (click)="onLoggerToggle()" [(ngModel)]="showLogger">
                Show Query Logs
            </button>
            <button class="button-spinner" (click)="RunComplexQuery()" [disabled]="runQuerySpinner">
                Run Query
                <span class="zmdi" [class.zmdi-refresh]="runQuerySpinner" [class.zmdi-play]="!runQuerySpinner"></span>
            </button>
        </div>
    </ng-template>
</ejs-dialog>
<ejs-dialog #ReportViewConfigDialog [closeOnEscape]="true" [showCloseIcon]="true" [visible]="false" width="700px"
    height="100%" [animationSettings]="rightPaneAnimationSettings" [position]="{ X: 'right', Y: 'center' }"
    isModal="true" [allowDragging]="false">
    <ng-template #header> Report Settings </ng-template>
    <ng-template #content *ngIf="authService?.isVaultUser()">
        <ejs-grid [dataSource]="rdsMappingList" width="100%" [editSettings]="{
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Normal',
        newRowPosition: 'Bottom'
      }" [toolbar]="['Add', 'Edit', 'Delete', 'Update', 'Cancel']" (toolbarClick)="rdsToolbarClick($event)"
            (actionBegin)="onRDSActionBegin($event)" (actionComplete)="onRDSActionComplete($event)" #rdsMappingGrid>
            <e-columns>
                <e-column isPrimaryKey="true" field="reportName" [validationRules]="{ required: true }">
                </e-column>
                <e-column field="tableName" [validationRules]="{ required: true }"></e-column>
            </e-columns>
        </ejs-grid>
    </ng-template>
</ejs-dialog>
<ejs-dialog #ExportDialog [closeOnEscape]="true" showCloseIcon="true" [visible]="false" width="500px" height="700px"
    [allowDragging]="false" [isModal]="true" [animationSettings]="dialogAnimationSettings">
    <ng-template #header> Export Report </ng-template>
    <ng-template #content>
        <!-- need custom name with placeholder, list of columns and count, query string -->
        <div style="display: flex; flex-direction: column; height: 100%; gap: 10px">
            <ejs-textbox #exportCustomName placeholder="Enter Custom Report Name (Optional)"></ejs-textbox>
            <div style="display: flex; flex-direction: column; flex-grow: 1; gap: 10px">
                <textarea [value]="exportQuery" readonly style="
            flex-grow: 1;
            height: 100px;
            border-radius: 0.25rem;
            border: lightgrey 0.5px solid;
            resize: none;
            padding: 10px;
            font-size: large;
            font-family: monospace;
            color: grey;
          "></textarea>
                <ejs-grid [dataSource]="exportColumns" height="400px"> </ejs-grid>
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <button ejs-button content="Start Export" (click)="exportReport()"></button>
    </ng-template>
</ejs-dialog>
