<div style="display: flex; flex-direction: row; gap:5px; justify-content: space-around;">
    <div style="position: relative;">
        <div *ngIf="showCenterPercent"
            style="position: absolute; width: 100%; height: 100%; display: flex; justify-content: center;align-items: center; color:black; pointer-events: none;">
            <div style="font-size: x-large; font-weight: bold;" [style.color]="centerTextColor">{{centerText}}</div>
        </div>
        <div id="{{id}}_container"></div>
    </div>

    <div id="legend-container" *ngIf="!hideLabels"
        style="display: flex; flex-direction: column;  gap: 5px; justify-content: center; width: fit-content;">
        <div *ngFor="let legend of flattenedData">
            <ejs-tooltip content="Count: {{legend.value}}" position="TopCenter" [hidden]="legend.value===0">
                <div (mouseenter)="highlightArc(legend.id)" (mouseleave)="unhighlightArc()" class="legend"
                    id="{{id}}-{{legend.id}}-legend">
                    <div style="padding-right: 5px; width: 50px; text-align: right;">{{legend.percent.toFixed(1)}}%
                    </div>
                    <div style="width: 14px; height: 14px; border-radius: .5rem;"
                        [style.background-color]="legend.color">
                    </div>
                    <div style="border: 0px;  padding-left: 5px;" [style.border-color]="legend.color">{{legend.label}}
                    </div>
                </div>
            </ejs-tooltip>
        </div>
    </div>
</div>