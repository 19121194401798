<div style="display: flex; flex-direction:  column; gap: 10px; padding: 10px 0px;">
    <div style="display: flex; flex-direction: row; gap: 10px; justify-content: center; width: 100%;">
        <div class="stat-card">
            <div class="metric">{{eventLogCount}}</div>
            <div>All Log Events</div>
        </div>
        <div class="stat-card">
            <div class="metric">{{scheduledJobsCount}}</div>
            <div>Schedule Events</div>
        </div>
        <div class="stat-card">
            <div class="metric">{{pageRunCount}}</div>
            <div>Page Run Events</div>
        </div>
        <div class="stat-card">
            <div class="metric">{{pageCompleteCount}}</div>
            <div>Page Complete Events</div>
        </div>
        <div class="stat-card">
            <div class="metric">{{analysisCount}}</div>
            <div>Analysis Events</div>
        </div>
        <div class="stat-card">
            <div class="metric">{{validationCount}}</div>
            <div>Validation Events</div>
        </div>
    </div>

    <div style="display: flex; flex-direction: row; gap: 10px;">
        <button ejs-button [content]=" isPaused ?'In Buffer: ' + inBuffer :'Pause'"
            [iconCss]="isPaused ? 'zmdi zmdi-play' : 'zmdi zmdi-pause'" (click)="onPauseClick()"
            [style.background-color]="isPaused ? 'green':'salmon'" class="tgl-button"></button>
        <div style="flex-grow:1">
            <ejs-textbox width="100%" (change)="onSearchType()" #searchTextBox></ejs-textbox>
        </div>
        <button *ngFor="let f of filterList" ejs-button [content]="f" (click)="onFilterClick(f)"
            [style.background-color]="filters[f] ? '':'lightgrey'" class="tgl-button"></button>


        <button ejs-button style="background-color: rgb(255, 73, 53);border: none; color: white"
            iconCss="zmdi zmdi-delete"></button>

    </div>
    <div style="display: flex; flex-direction: row; gap: 10px">
        <ejs-grid [dataSource]="eventLogInternal" height="600px" #logGrid width="75%" [allowFiltering]="true"
            [filterSettings]="{type: 'CheckBox'}">
            <e-columns>
                <e-column field="jobId" [visible]="false"></e-column>
                <e-column field="user" [visible]="false"></e-column>
                <e-column field="page" [visible]="false"></e-column>
                <e-column field="timestamp" headerText="Timestamp" [autoFit]="true">
                    <ng-template #template let-data>
                        <span [style.color]="data.color" style="font-size:small;">{{data.timestamp | date:
                            'short'}}</span>
                    </ng-template>
                </e-column>
                <e-column field="eventTypeName" headerText="Type" [autoFit]="true">
                    <ng-template #template let-data>
                        <span [style.color]="data.color" style="font-size:small;"><span *ngIf="data.iconCss"
                                [class]="data.iconCss" style="margin-right:5px"></span>{{data.eventTypeName}}</span>
                    </ng-template>
                </e-column>
                <e-column headerText="Log Event" minWidth="200px">
                    <ng-template #template let-data>
                        <div [ngSwitch]="data.eventTypeName" [style.color]="data.color" [style.font-size]="'small'">
                            <div *ngSwitchCase="'Instrumentation'"
                                style=" display:flex; flex-direction: row; gap:5px; ">
                                <!-- <pre>{{data | json}}</pre> -->
                                <div><b>{{data.type}}</b> : </div>
                                <div style="flex-grow:1">{{data.page}}</div>
                                <div>{{data.user}}</div>
                            </div>
                            <div *ngSwitchCase="'Validation Result'"
                                style=" display:flex; flex-direction: row; gap:5px; ">
                                <div>{{data.documentUrl}}</div>
                                <div style="flex-grow:1">{{data.page}}</div>
                                <div>{{data.user}}</div>
                            </div>
                            <div *ngSwitchCase="'Page Run Start'" style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div>{{data.url}}</div>
                                    <div style="font-size: x-small;">[{{data.jobId}}]</div>
                                </div>
                                <div>{{data.region}}</div>
                            </div>
                            <div *ngSwitchCase="'Page Run Complete'"
                                style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div
                                    style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div>{{data.url}}</div>
                                    <div style="font-size: x-small;">[{{data.jobId}}]</div>
                                </div>
                                <div>{{data.region}}</div>
                            </div>
                            <div *ngSwitchCase="'Job Scheduled (priority)'"
                                style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div
                                    style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div>{{data.url}}</div>
                                    <div style="font-size: x-small;">[{{data.jobId}}]</div>
                                </div>
                                <div>{{data.region}}</div>
                            </div>
                            <div *ngSwitchCase="'Job Scheduled (highMemory)'"
                                style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div
                                    style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div>{{data.url}}</div>
                                    <div style="font-size: x-small;">[{{data.jobId}}]</div>
                                </div>
                                <div>{{data.region}}</div>
                            </div>
                            <div *ngSwitchCase="'Job Scheduled (lowMemory)'"
                                style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">

                                <div
                                    style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div>{{data.url}}</div>
                                    <div style="font-size: x-small;">[{{data.jobId}}]</div>
                                </div>
                                <div>{{data.region}}</div>
                            </div>
                            <div *ngSwitchCase="'Classify With Vendor Library'"
                                style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div>{{data.hostname}}</div>
                                <div style="flex-grow:1;font-size: x-small;font-style: italic;">
                                    [{{data.calculatedVendorId}}]
                                </div>
                                <div>{{data.vendorName || 'Unknown'}}</div>
                                <div><img height="20px"
                                        src="https://{{cdnUrl}}/vendors/icon/{{data.website || 'unknown'}}.ico">
                                </div>
                            </div>
                            <div *ngSwitchCase="'Mobile App Run Start'" style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div>{{ data.appName }} ({{data.appVersion}}) - {{data.device}}</div>
                                    <div style="font-size: x-small;">[{{data.jobId}}]</div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'Mobile Request'" style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div> {{ data.method }} {{data.url}}</div>
                                    <div style="font-size: x-small;">[{{ data.appName }} ({{data.appVersion}}) - {{data.device}}]</div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'Mobile Navigation'" style=" display:flex; flex-direction: row; gap:5px; align-items: baseline;">
                                <div style="flex-grow:1;font-size:small;font-style: italic; display: flex; flex-direction: column; gap: 2px">
                                    <div> {{ data.method }} {{data.url}}</div>
                                    <div style="font-size: x-small;">[{{ data.appName }} ({{data.appVersion}}) - {{data.device}}]</div>
                                </div>
                            </div>
                            <div *ngSwitchDefault>
                                <!-- {{data | json}} -->
                                <div>{{data.type}}</div>
                            </div>
                        </div>
                    </ng-template>

                </e-column>
            </e-columns>
            <ng-template #detailTemplate let-data>
                <ng-container [ngSwitch]="data.eventTypeName">
                    <div *ngSwitchCase="'Validation Result'">
                        <div style="display: flex; flex-direction: row;width:100%; gap: 5px; flex-wrap:nowrap">
                            <div
                                style="display:flex;flex-direction: column; gap: 5px;max-width: 50%; width: fit-content;">
                                <div>
                                    Result: {{data.validationSuccess}}
                                </div>
                                <div style=" border: .5px grey solid; border-radius: .25rem; padding: 5px; flex-grow:1">
                                    Message: {{data.resultMessage}}
                                </div>
                            </div>
                            <div
                                style="white-space: pre-wrap; flex-grow: 1; border: .5px grey solid; border-radius: .25rem; padding: 5px;">
                                Result Data: <br />
                                {{data.resultData | json}}
                            </div>
                        </div>
                    </div>
                    <div *ngSwitchCase="'Classify With Vendor Library'">
                        <div>{{data.url}}</div>
                    </div>
                    <div *ngSwitchDefault style="overflow: hidden; white-space: pre-wrap;">
                        {{ data | json}}
                    </div>

                </ng-container>
            </ng-template>
        </ejs-grid>
        <ejs-grid [dataSource]="jobList" height="600px" width="25%" #jobGrid (rowSelected)="onJobRowSelected($event)">
            <e-columns>
                <e-column field="jobId" headerText="Detected Jobs List">
                    <ng-template #template let-data>
                        <div style="display: flex; flex-direction: column; gap: 5px;">
                            <div style="display:flex; flex-direction: row; gap:5px; font-size: small; color:white;">
                                <span style="flex-grow: 1">{{data.jobId}}</span>
                            </div>
                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                <div class="bubbles" [style.background-color]="data.scheduled ? 'green' : 'lightgrey'">
                                </div>
                                <div class="bubbles"
                                    [style.background-color]="data.pageRunStarted ? 'green' : 'lightgrey'">
                                </div>
                                <div class="bubbles"
                                    [style.background-color]="data.pageRunCompleted ? 'green' : 'lightgrey'">
                                </div>

                                <div class="bubbles"
                                    [style.background-color]="data.analysisEvents ? 'green' : 'lightgrey'">
                                    <span>{{data.analysisEvents}}</span>
                                </div>
                                <div class="bubbles"
                                    [style.background-color]="data.validationEvents ? 'green' : 'lightgrey'">
                                    <span>{{data.validationEvents}}</span>
                                </div>
                                <div class="bubbles"
                                    [style.background-color]="data.analysisStatus === 'completed' ? 'green' : 'lightgrey'">
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>

    <div style="display: flex; flex-direction: row; gap:10px">
        <div style="width: 50%"></div>
        <div style="flex-grow:1">
            <ejs-maps id='container' #map style="display:block; background-color:#232323" [border]="{width: 0}"
                [margin]="{top: 0, bottom: 0, right: 0, left: 0}" [layers]="layers" [mapsArea]="{background:'#232323'}"
                (loaded)="loaded($event)"></ejs-maps>
        </div>
    </div>
</div>