import { Component, OnInit } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { IDataOptions, IDataSet, FieldListService } from '@syncfusion/ej2-angular-pivotview';
import { ViewChild } from '@angular/core';
import { SpreadsheetComponent } from '@syncfusion/ej2-angular-spreadsheet';

@Component({
  selector: 'app-data-viz',
  templateUrl: './data-viz.component.html',
  styleUrls: ['./data-viz.component.css'],
  providers: [FieldListService]
})
export class DataVizComponent implements OnInit {
  
  public pivotData: IDataSet[];
  public dataSourceSettings: IDataOptions;
  @ViewChild('default')
  public spreadsheetObj: SpreadsheetComponent;
  cookiesData:any;

  constructor(public papa:Papa) { }

  
  // public data: Object[] = getDefaultData();
  public openUrl = 'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open';
  public saveUrl = 'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save';
  created() {
      this.spreadsheetObj.cellFormat({ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }, 'A1:F1');
      this.spreadsheetObj.cellFormat({ fontWeight: 'bold' }, 'E31:F31');
      this.spreadsheetObj.cellFormat({ textAlign: 'right' }, 'E31');
      this.spreadsheetObj.numberFormat('$#,##0.00', 'F2:F31');
  }

  ngOnInit(): void {
    this.papa.parse('https://ui.dev.vaultjs.com/assets/data/vivint/thirdPartyCookiesReport.csv', {
      header: true,
      download: true,
      skipEmptyLines: "greedy",
      complete: (results, file)=>{
        console.log(results);
        this.cookiesData = results.data;
        // this.pivotData = results as unknown as IDataSet[];
        // this.pivotData = [
        //   { 'Sold': 31, 'Amount': 52824, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q1' },
        //   { 'Sold': 51, 'Amount': 86904, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q2' },
        //   { 'Sold': 90, 'Amount': 153360, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q3' },
        //   { 'Sold': 25, 'Amount': 42600, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2015', 'Quarter': 'Q4' },
        //   { 'Sold': 27, 'Amount': 46008, 'Country': 'France', 'Products': 'Mountain Bikes', 'Year': 'FY 2016', 'Quarter': 'Q1' }
        // ];
        // this.dataSourceSettings = {
        //   dataSource: this.pivotData,
        //   expandAll: false,
        //   columns: [{ name: 'Year', caption: 'Production Year' }, { name: 'Quarter' }],
        //   values: [{ name: 'Sold', caption: 'Units Sold', type: 'Index' }, { name: 'Amount', caption: 'Sold Amount' }],
        //   rows: [{ name: 'Country' }, { name: 'Products' }]
        // };
      },
      error: (error, file)=>{
        console.log(error);
      }
    });
  }

}
