import { Injectable } from '@angular/core';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { PageRunnerScript } from '../../models/PageRunnerScript';
import { PageRunnerScriptHistory } from '../../models/PageRunnerScriptHistory';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageRunnerService extends VaultBaseService {

  cache:{[type:string]:Observable<any>;};
  public selectedScript:BehaviorSubject<PageRunnerScript>;
  public selectedScriptHistory:BehaviorSubject<PageRunnerScriptHistory>;


  constructor(authHttp: HttpClient) { 
    super(authHttp);
    this.cache = {};
    this.selectedScript = new BehaviorSubject(new PageRunnerScript(-1, "", "", "", false));
    this.selectedScriptHistory = new BehaviorSubject(new PageRunnerScriptHistory(-1, "", "", ""));
  }

  public updateConfig(config:any):Observable<any> {
    const url = `${env.api.url}pageRunner/config`;
    return this.authHttp.put(url, JSON.stringify(config));
  }

  public getConfig():Observable<any> {    
    const url = `${env.api.url}pageRunner/config`;
    return this.makeHttpRequest(url, null, false);
  }

  public getScripts(scriptId:number=null, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{
    const scriptIdQP = scriptId ? `scriptId=${scriptId}` : '';
    const url = `${env.api.url}pageRunner/scriptedConfig?${scriptIdQP}&start=${start}&length=${length}&order=${order}&orderDir=${orderDirection}&search=${search}&draw=${draw}`;
    return this.makeHttpRequest(url, null, false);
    
  }
  public createScript(script:PageRunnerScript, file:File=null):Observable<any>{
    const url = `${env.api.url}pageRunner/script`;
    const formData: FormData = new FormData();
    formData.append("data", JSON.stringify(script));
    if ( file ){      
      formData.append("file", file, file.name);            
    }
    return this.authHttp.post(url, formData);
  }
  
  public updateScript(script:PageRunnerScript, file:File=null):Observable<any> {
    const url = `${env.api.url}pageRunner/scriptedConfig`;

    const formData: FormData = new FormData();
    formData.append("data", JSON.stringify(script));
    if ( file ){      
      formData.append("file", file, file.name);            
    }

    return this.authHttp.put(url, formData);
  }

  public getScriptHistory(scriptId:number, start:number, length:number, order:string, orderDirection:string, search:string, draw:number):Observable<any>{

    const url = `${env.api.url}pageRunner/${scriptId}/history?start=${start}&length=${length}&order=${order}&orderDir=${orderDirection}&search=${search}&draw=${draw}`;
    return this.makeHttpRequest(url, null, false);
  }

  public deleteScript(scriptId:string):Observable<any> {
    const url = `${env.api.url}pageRunner/script/${scriptId}`;
    return this.authHttp.delete(url);
  }

  public runPage(pageId:number, region='us-east-1', pageRunnerType='priority', configId=null):Observable<any>{    
    const url = `${env.api.url}pages/${pageId}/run?region=${region}&prType=${pageRunnerType}${ configId && configId >= 0 ? '&configId=' + configId : '' }`;
    return this.authHttp.get(url);
  }

  public runScript(scriptId:number):Observable<any>{    
    const url = `${env.api.url}pageRunner/script/${scriptId}/run`;
    return this.authHttp.get(url);
  }

}
