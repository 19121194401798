<ejs-grid #grid
    [dataSource]='gridData ? gridData.root : null'
    [childGrid]='childGrid'
    [allowResizing]='true'
    [toolbar]='toolbarOptions'>
    <e-columns>
        <e-column *ngFor="let header of headers;"
        [field]="header.field"
        [headerText]="header.headerText"
        [textAlign]="header.textAlign"
        [width]="header.width"
        [template]="header.template"></e-column>
    </e-columns>
</ejs-grid>