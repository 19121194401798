<nav aria-label="breadcrumb" role="navigation" *ngIf="sitemapService.crumbs.length > 0" >
  <ol class="breadcrumb page-head-nav">
    <ng-container *ngFor="let crumb of sitemapService.crumbs; let i=index">      
      <li class="breadcrumb-item" [class.active]="crumb.active" *ngIf="crumb.link">
        <a [routerLink]="crumb.link" [fragment]="crumb.fragment">{{crumb.text}}</a>
      </li>
      <li class="breadcrumb-item" [class.active]="crumb.active" *ngIf="!crumb.link">
        <span>{{crumb.text}}</span>
      </li>      
    </ng-container>
  </ol>
</nav>