import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { GridModel } from '@syncfusion/ej2-angular-grids';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { AuthService } from '../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';

@Component({
  selector: 'app-fb-details',
  templateUrl: './fb-details.component.html',
  styleUrls: ['./fb-details.component.css']
})
export class FbDetailsComponent implements OnInit {
  isLoaded: BehaviorSubject<boolean>;
  pageValue = null;
  pageData: any[];
  domainData: any[];
  PFColumns: any[];
  PFData: any[];
  FBTechColumns: any[];
  FBTechData: any[];
  VOPColumns: any[];
  VOPData: any[];
  CAPIColumns: any[];
  CAPIData: any[];
  PIDColumns: any[];
  PIDData: any[];
  CFSColumns: any[];
  CFSData: any[];
  FBDColumns: any[];
  FBDChildGrid: GridModel;
  FBDData: any;
  baseData: any;

  constructor(private spinnerService: SpinnerService,
    private dataExportService: DataExportService,
    private authService: AuthService,
    private notificationService: VaultNotificationService
  ) {
    this.isLoaded = new BehaviorSubject<boolean>(false);
    this.PFColumns = [
      { field: 'dataKey', headerText: 'Key' },
      { field: 'dataValue', headerText: 'Value', template: '<div>${dataValue}</div>' }
    ];
    this.PFData = [];
    this.FBTechColumns = [
      { field: 'tech', headerText: 'Tech' },
      { field: 'detected', headerText: 'Detected', template: '<div>${detected}</div>' }
    ];
    this.FBTechData = [];
    this.VOPColumns = [
      { field: 'host', headerText: 'File' },
      { field: 'path', headerText: 'Name' }
    ];
    this.VOPData = [];
    this.CAPIColumns = [
      { field: 'method', headerText: 'Method' },
      { field: 'total', headerText: 'Total' }
    ];
    this.CAPIData = [];
    this.PIDColumns = [
      { field: 'id', headerText: 'IDs' },
    ];
    this.PIDData = [];
    this.CFSColumns = [
      { field: 'config', headerText: 'Configuration' },
      { field: 'status', headerText: 'Status' }
    ];
    this.CFSData = [];
    this.FBDColumns = [
      { field: 'category', headerText: 'Category' },
    ];
    this.FBDChildGrid = {
      queryString: 'category',
      columns: [
        { field: 'param', headerText: 'Parameter' },
        { field: 'apiName', headerText: 'API Name' },
        { field: 'name', headerText: 'Friendly Name' },
        { field: 'explanation', headerText: 'Explanation', template: '<div style="text-align: left; white-space: pre-wrap;">${explanation}</div>' },
      ],
      allowResizing: true,
    };
  }

  ngOnInit(): void {
    this.spinnerService.toggle(true);
    // FIXME: this.loadData(); needs to be in a subscription
    this.spinnerService.toggle(true);
    const CID = parseInt(this.authService.customerId());
    this.dataExportService.getDashboardFile('FBDDashboard.json', CID).subscribe({
      next: (data) => {
        try {
          this.loadData(JSON.parse(data));
        } catch (error) {
          console.log(error);
          this.notificationService.error('Unable to load data.', 'Please try again or contact support');
        }
      },
      error: (err) => {
        console.log(err);
        this.notificationService.error('Unable to load data.', 'Please try again or contact support');
      },

    });
  }

  onDropdownValueChanged(value: string): void {
    this.pageValue = value;
    console.log(value);
    this.spinnerService.toggle(true);
    const CID = parseInt(this.authService.customerId());
    this.dataExportService.getDashboardFile('FBDDashboard.json', CID).subscribe({
      next: (data) => {
        try {
          this.pageDataChange(JSON.parse(data));
        } catch (error) {
          console.log(error);
          this.notificationService.error('Unable to load data.', 'Please try again or contact support');
        }
      },
      error: (err) => {
        console.log(err);
        this.notificationService.error('Unable to load data.', 'Please try again or contact support');
      },
    });
  }

  pageDataChange(data: any) {
    this.PFData = data.pages[this.pageValue].PFData.map((data: any) => {
      if (data.dataKey == "dpo") {
        return {
          dataKey: "Limited Data Use",
          dataValue: data.value == "LDU" ? "<span style='color: green'>Yes</span>" : "<span style='color: red'>No</span>"
        }
      } else {
        return {
          dataKey: data.dataKey,
          dataValue: data.dataValue == undefined ? "Not found" : data.dataValue
        }
      }
    });
    this.FBTechData = data.pages[this.pageValue].FBTData;
    this.VOPData = data.pages[this.pageValue].VOPData;
    this.CAPIData = data.pages[this.pageValue].CAPIData;
    this.PIDData = data.pages[this.pageValue].PIDData;
    this.CFSData = data.pages[this.pageValue].CFSData;
    this.FBDData = data.pages[this.pageValue].FBDData;
    this.spinnerService.toggle(false);
  }

  loadData(data: any) {
    // FIXME: temp data
    console.log(data);
    this.pageData = Object.values(data.pages).map((page: any, idx) => {
      return {
        id: idx,
        text: page.page
      }
    })
    this.spinnerService.toggle(false);
    this.isLoaded.next(true);
  }

}
