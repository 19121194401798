<div class="col-sm-12">
  <div class="card card-table card-border">
    <div class="card-header">
      <span class="title">Configuration: Page links</span>
      <span class="card-subtitle">Add pages to configuration</span>
    </div>
    <div class="card-body">
      <ejs-tab [animation]="false">
        <e-tabitems>
          <e-tabitem>
            <ng-template #headerText>
              <div> Simple Page List </div>
            </ng-template>
            <ng-template #content>
              <ejs-grid #configPageLinkGrid id='configPageLinkGrid' [dataSource]='configPageLinks' [allowPaging]='true'
                [pageSettings]='pageSettings' [editSettings]='editSettings' [allowSelection]='true' [toolbar]='toolbar'
                allowResizing="true" (actionBegin)="actionBegin($event)">
                <e-columns>
                  <e-column field='link' headerText='Page URL'></e-column>
                  <ng-template #editSettingsTemplate let-data>
                    <div ngForm #pageLinkForm="ngForm" (keypress)="onKeyPress($event)">
                      <ejs-autocomplete name="link" #pageLinkEditor [(ngModel)]="selectedPageLink.link"
                        [dataSource]="pageLinkData | async" (filtering)="onFilter($event)" [fields]="{ value: 'link' }"
                        [placeholder]="'Select a page'" (select)="onSelectPageLink($event)"></ejs-autocomplete>
                    </div>
                  </ng-template>
                </e-columns>
              </ejs-grid>
            </ng-template>
          </e-tabitem>
          <e-tabitem>
            <ng-template #headerText>
              <div> Bulk Action </div>
            </ng-template>
            <ng-template #content>
              <div style="margin: 10px; display: flex; flex-direction: column;">
                <textarea id="bulk-url-list" style="resize:none; padding: 10px; font-size: medium; height: 200px;"></textarea>
                <div class="toolbar">

                  <button (click)="parsePageLinksFromText()">Parse Links From Text</button>
                  <button (click)="showConfirmDialog()">Add Config Links</button>
                  <button (click)="showDeleteDialog()">Delete Config Links</button>

                </div>

                <ejs-grid [dataSource]="parsedPageLinks" height="500px">
                  <e-columns>
                    <e-column field='link' headerText='Page URL'></e-column>
                    <e-column field='isAvailable' headerText='Availability' [autoFit]="true" >
                      <ng-template #template let-data>
                        <span style="font-size: large; width: 100%; text-align: center;" class="zmdi zmdi-{{data.isAvailable ? 'check':'close'}}"
                          [style.color]="data.isAvailable? 'green': 'red'"></span>
                      </ng-template>
                    </e-column>
                    <e-column field='isInConfig' headerText='Already In Config' [autoFit]="true">
                      <ng-template #template let-data>
                        <span  style="font-size: large; width: 100%; text-align: center;" class="zmdi zmdi-{{data.isInConfig? 'check':'close'}}"
                          [style.color]="data.isInConfig ? 'green': 'red'"></span>
                      </ng-template>
                    </e-column>

                  </e-columns>
                </ejs-grid>
              </div>
            </ng-template>
          </e-tabitem>
        </e-tabitems>
      </ejs-tab>

    </div>
  </div>
</div>

<div class="modal fade" id="confirm-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="font-size: large; font-weight: 300; ">

        Add Pages to Configuration
      </div>
      <div class="modal-body" >
        <div style="font-size: medium; font-weight: 300; margin-bottom: 20px;">
          Are you sure you want to add these {{selectedPageLinks?.length}} pages to configuration?
        </div>
        <ejs-grid [dataSource]="selectedPageLinks" height="500px" [allowPaging]="true">
          <e-columns>
            <e-column field='link' headerText='Page URL' width="70%"></e-column>
            <e-column field="id" headerText="Link Id" width="30%"></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="modal-footer">
        <div class="toolbar">
          <button (click)="addBulkLinks()"  [disabled]="selectedPageLinks?.length === 0">Add Files</button>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="delete-dialog" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="font-size: large; font-weight: 300; ">

        Delete Pages from Configuration
      </div>
      <div class="modal-body" >
        <div style="font-size: medium; font-weight: 300; margin-bottom: 20px;">
          Are you sure you want to remove these {{selectedPageLinks?.length}} pages from configuration?
        </div>
        <ejs-grid [dataSource]="selectedPageLinks" height="500px" [allowPaging]="true">
          <e-columns>
            <e-column field='link' headerText='Page URL' width="70%"></e-column>
            <e-column field="id" headerText="Link Id" width="30%"></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="modal-footer">
        <div class="toolbar">
          <button (click)="deleteBulkLinks()" [disabled]="selectedPageLinks?.length === 0">Delete Files</button>
        </div>
      </div>
    </div>
  </div>
</div>