<div class="control-section">
    <ejs-grid #grid [dataSource]='data' allowPaging='true' allowSorting="true" [allowGrouping]="true" [groupSettings]="groupOptions" [pageSettings]='pageSettings' (dataBound)='dataBound()' (created)='created()' (load)='load()'>
        <e-columns>            
            <e-column field='Vendor' headerText="Vendor"></e-column>
            <e-column field='Tag Domain' headerText='Tag Domain'></e-column>
            <e-column field='Tag URL' headerText='Tag URL'></e-column>
            <e-column field='Data Element Name' headerText='Data Element Name'></e-column>
            <e-column field='Data Element Value' headerText='Data Element Value'></e-column>            
        </e-columns>
    </ejs-grid>
</div>