<div class="report-container">
    <div class="row" style="margin-bottom: 25px;">
        <div class="col-lg-2" style="display: flex; flex-direction: row; justify-content: center;">
            <img src="assets/svg/fingerprint.svg" alt="Fingerprint Logo" height="100px">
        </div>
        <div class="col-lg-7">
            <div class="report-title">Fingerprint Dashboard</div>
        </div>
    </div>
</div>

<div *ngIf="isLoaded | async" class="report-container">
    <div class="row">
        <div class="col-lg-12" style="border: 0px solid red;">
            <div class="card" style="border: 0px solid red;">
                <app-col-bar-chart-panel title="Vendors Collecting Data" yLabel="Unique Attributes" name="Vendors"
                    [data]="barChartData">
                </app-col-bar-chart-panel>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7" style="border: 0px solid red;">
            <div class="card" style="border: 0px solid red;">
                <app-hist-panel title="Pages by Attributes Collected" yLabel="Pages" name='Attributes Collected'
                    [data]="histData">
                </app-hist-panel>
            </div>
        </div>
        <div class="col-lg-5" style="border: 0px solid red;">
            <app-scrollable-progress-bars title="Attributes Collected" [data]="atributeData"
                height="413"></app-scrollable-progress-bars>
        </div>
    </div>
    <div class="row" style="padding-bottom: 11px;">
        <div class="col-lg-12" style="border: 0px solid red;">
            <app-nested-grid-panel [data]="vendorGridData ? vendorGridData : null" [headers]="vendorGridHeaders"
                [childGrid]="vendorChildGrid"></app-nested-grid-panel>
        </div>
    </div>
    <div class="row" style="padding-top: 11px;">
        <div class="col-lg-12" style="border: 0px solid red;">
            <app-nested-grid-panel [data]="pageGridData ? pageGridData : null" [headers]="pageGridHeaders"
                [childGrid]="pageChildGrid">
            </app-nested-grid-panel>
        </div>
    </div>
</div>