import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
@Component({
  selector: 'highlight-value-history',
  templateUrl: './highlight-value-history.component.html',
  styleUrls: ['./highlight-value-history.component.css']
})
export class HighlightValueHistoryComponent implements OnInit, OnChanges {
  @Input('title') title:string;
  @Input ('value') value:number;
  @Input ('change') change:number;
  @Input ('loading') loading:boolean = true;
  public changeText = ""
  constructor(private spinnerService:SpinnerService) {
    this.updateText();
  }

  updateText() {
    this.title = this.title ? this.title :"Key Page With Violation Risks";
    this.value = this.value ? this.value : 0;
    this.change = this.change ? this.change : 0;
    if(this.change === 0){
      this.changeText = "No change";
    }
    else if(this.change > 0){
      this.changeText = `Increased by ${this.change}`;
    }
    else{
      this.changeText = `Decreased by ${this.change*-1}`;
    }
  }

  ngOnInit(): void {
    
    this.updateText();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateText();
  }

}
