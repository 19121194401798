import { Component, OnInit, ElementRef, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { PageService } from '../../../../services/page/page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../../services/sitemap/sitemap.service';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { NotificationsService } from 'angular2-notifications';
import { DataExportService } from '../../../../services/data-export/data-export.service';
import { catchError, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { PageRunnerService } from '../../../../services/data-export/page-runner.service';
import { environment as env } from "../../../../../environments/environment";
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { AuthService } from '../../../../services/auth/auth.service';
import { ClipboardService } from 'ngx-clipboard';
declare var $:any;
import * as gifshot from 'gifshot';
import { DataStateChangeEventArgs, GridComponent, PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Ej2ScriptHistoryService } from '../../../../services/ej2Proxies/pageRunner/ej2-script-history.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { TimezoneService } from '../../../../services/timezone.service';

export interface IMedia {
  title: string;
  src: string;
  type: string;
}


@Component({
  selector: 'app-script-history',
  templateUrl: './script-history.component.html',
  styleUrls: ['./script-history.component.css']
})
export class ScriptHistoryComponent implements OnInit, AfterViewInit {

  scriptHistory:any[];
  dataLoading:boolean;  
  runInfoLoading:boolean;
  loadingString:string;
  notificationOptions:any;
  historyData:any;
  screenRecordingImageElement:any;
  screenRecordingImage:any;

  selectedScript:any;
  firstLoadId:number;

  consoleContent:string;

  activeTab:string;

  @ViewChild('consoleView') consoleView:CodemirrorComponent;
  codeMirrorOptions:any;

  vendors:any[];

  cdnUrl:string;
  scriptedDataCollectorBucket:string;
  customerId:number;
  currentVideoItem: IMedia;  

  public pageSettings:PageSettingsModel;
  public sortSettingsHistory:SortSettingsModel;
  public sortSettingsRequests:SortSettingsModel;
  public sortSettingsVendors:SortSettingsModel;
  public toolbar: string[];
  public dateFormat:any;
  @ViewChild('historyGrid') public historyGrid: GridComponent;
  @ViewChild('requestsGrid') public requestsGrid: GridComponent;
  @ViewChild('vendorsGrid') public vendorsGrid: GridComponent;

  private imageUrls;
  private signedUrlPromises;

  public ej2HistoryData: Observable<any>;

  constructor(private el: ElementRef, 
    public pageService:PageService,
    private router: Router, 
    private route:ActivatedRoute, 
    private sitemapService:SitemapService,
    private vendorService:VendorsService,
    private notificationService:NotificationsService,
    private zone:NgZone,
    private dataExportService:DataExportService,
    private pageRunnerService:PageRunnerService,
    public authService:AuthService,
    private clipboardService: ClipboardService,
    private ej2ProxyService:Ej2ScriptHistoryService,
    private spinnerService:SpinnerService,
    private timezoneService:TimezoneService
  ) { 
    
      this.sitemapService.update(this.route);    
      // this.dataLoading = false;      
      this.runInfoLoading = false;
      this.loadingString = ""; //"Loading...";

      this.cdnUrl = env.cdn.url;
      this.scriptedDataCollectorBucket = env.aws.scriptedDataCollectorBucket;
      this.dataLoading = true;
      this.ej2HistoryData = ej2ProxyService.pipe(tap(()=>{
        // this.spinnerService.toggle(false);
        this.dataLoading = false;
      }));
      let state = { skip: 0, take: 20 };
      // this.spinnerService.toggle(true);
      this.ej2ProxyService.execute(state, this.route.snapshot.params.scriptId);  
      this.dateFormat = {type: 'dateTime', skeleton:'short'}
      this.pageSettings = {
        currentPage: 1, 
        pageSize: 20, 
        pageCount: 4, 
        pageSizes: [20, 25, 50]
      };
      this.toolbar = ['Search'];
      this.sortSettingsHistory = {
        columns: [
          { field: 'timestamp', direction: 'Descending'}
        ]
      };
      this.sortSettingsRequests = {
        columns: [
          { field: 'type', direction: 'Ascending'},
          { field: 'request.url', direction: 'Ascending'}
        ]
      };
      this.sortSettingsVendors = {
        columns: [
          { field: 'vendorName', direction: 'Ascending'}
        ]
      };

      this.notificationOptions = {
        timeOut: 2000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true      
      };
      this.firstLoadId = null;

      // this.consoleContent = "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n";

      this.activeTab = 'requests';

      this.codeMirrorOptions = {
        lineNumbers: true,
        theme: 'midnight',
        readOnly: true
      };      
      this.historyData = {vendors:[], requests:[]};
      this.customerId = Number(localStorage.getItem("cid"));
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    console.log('dataStateChange');
    console.log(state);
    // this.spinnerService.toggle(true);
    this.dataLoading = true;
    this.ej2ProxyService.execute(state, this.route.snapshot.params.scriptId);
  }

  ngOnInit() {
    // this.loadData();


    this.pageRunnerService.getScriptHistory(
      this.route.snapshot.params.scriptId, 
      0, 
      18, 
      'timestamp', 
      'asc',
      "", 
      1).subscribe( async (historyData)=>{

      this.dataLoading = false;            
      
      this.scriptHistory = historyData.data;
      this.selectedScript = this.scriptHistory[0];
      this.loadRunInfo();            
      this.dataLoading = false;

      // setTimeout(() => {
      //   this.historyTableWidget.row(0).select();
      // }, 100);
    },
    (error)=>{
      this.dataLoading = false;
    });          

  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  loadData(){

  }

  onTabClick(event){
    let prevActiveTab = this.activeTab;
    this.activeTab = event.target.id;
    console.log('ACtive Tab:', this.activeTab);
    if ( event.target.nodeName == "SPAN" ) {
      this.activeTab = event.target.parentNode.id;
    }
    if ( this.activeTab == 'codeFiles' ){
      
    } else if ( this.activeTab == 'console' ){
      setTimeout(() => {
        this.consoleView.codeMirror.setSize("99%", "99%");
        this.consoleView.codeMirror.refresh();
        
      }, 100);
    } else if ( this.activeTab == 'screenRecording' ){
      this.prepScreenRecContent();
    }    
  }
  
  prepScreenRecContent() {
    if ( this.screenRecordingImageElement != null ) {
      var container = document.getElementById("screenRecordingContainer");
      if ( container ){
        try {
          container.removeChild(this.screenRecordingImageElement);  
        } catch (error) {
          
        }        
      }      
    }

    if (this.signedUrlPromises != null && this.signedUrlPromises.length > 0){
      var frameDuration = window['vaultjs_frameDuration'] ? window['vaultjs_frameDuration'] : 5;
      this.runInfoLoading = true;
      Promise.all(this.signedUrlPromises).then((signedUrls)=>{
        gifshot.createGIF({
        'images': signedUrls,
        'gifWidth': 498,
        'gifHeight': 290,
        'frameDuration': frameDuration,
        'progressCallback': (prog)=>{ this.runInfoLoading = false; this.loadingString = "Loading..." + (Math.floor(prog * 100)) + "%"; },
        'completeCallback': (gif)=>{console.log('Complete Callback'); this.loadingString = "Loading...100%";}
        },
        (obj) => {
          if(!obj.error) {
            var image = obj.image;
            this.screenRecordingImage = image;
            this.screenRecordingImageElement = document.createElement('img');
            this.screenRecordingImageElement.src = image;              
            document.getElementById("screenRecordingContainer").appendChild(this.screenRecordingImageElement);
            this.loadingString = "";
          }
        });
      });  
    }
  }
  
  onPlayerReady(event){
    console.log(event);
  }

  loadRunInfo() {
    
    this.runInfoLoading = true;
    let runInfoPath = this.selectedScript.dataLocation;
    this.loadingString = "Loading...";
    if (this.activeTab == 'screenRecording'){
      if ( this.screenRecordingImageElement != null ) {
        var container = document.getElementById("screenRecordingContainer");
        if ( container ){
          try {
            container.removeChild(this.screenRecordingImageElement);  
          } catch (error) {
            
          }        
        }      
      }
    }
    

    forkJoin([
      this.dataExportService.getScriptedPageRunnerOutputFile(runInfoPath + 'consoleMessages.json').pipe(catchError(error=>of(error))),
      this.dataExportService.getScriptedPageRunnerOutputFile(runInfoPath + 'requests.json').pipe(catchError(error=>of(error))),
      this.dataExportService.getScriptedPageRunnerOutputFile(runInfoPath + 'vendors.json').pipe(catchError(error=>of(error))),
      this.dataExportService.getScriptedPageRunnerOutputFile(runInfoPath + 'screenshots/fileNames.json', true).pipe(catchError(error=>of(error)))
      // this.dataExportService.getSignedUrl( env.aws.scriptedDataCollectorBucket + '/' + runInfoPath + 'screenshots/screenshot-0001.png').pipe(catchError(error=>of(error)))
    ]).subscribe((responses)=>{
      console.log(responses);
      this.runInfoLoading = false;

      if ( responses[3] instanceof Error == false){
        var recordingImages = responses[3].toString();
        var images = null;
        
        try {
          this.imageUrls = JSON.parse(recordingImages);  
        } catch (error) {
          
        }

        this.signedUrlPromises = this.imageUrls.map((url)=>{            
          return this.dataExportService.getSignedUrl(env.aws.scriptedDataCollectorBucket + "/" + url).toPromise()
        })

        if ( this.activeTab == 'screenRecording' ){
          this.prepScreenRecContent();
        }
      }
      try{
        this.consoleContent = JSON.parse(responses[0].toString()).flatMap((row)=>{return row.text}).join('\n');        
        console.log("this.consoleContent");
        console.log(this.consoleContent);
      }catch(e){console.log(e)}
      try{
        this.historyData.requests = JSON.parse(responses[1].toString()).map((item)=>{
          var displayName:string = item.request.url;
          if ( displayName.length > 50 ){            
            displayName = item.request.url.substr(0, 50) + "..";
          }
          item.displayName = displayName;
          return {...item }
        });
        console.log("this.historyData.requests");
        console.log(this.historyData.requests);
                
        this.requestsGrid.dataSource = this.historyData.requests;
        
        this.zone.run(()=>{
          console.log("Requests zone refreshing...");          
          $('.full-file-name-popper').popover();
        });

        $('#requestsTable tbody').on( 'click', '.full-file-name-copy', (e) => {
          e.stopImmediatePropagation(); // stop the row selection when clicking on an icon
          
          
          this.clipboardService.copyFromContent($(e.target).attr('data-content'));
          this.notificationService.success('Success!', 'Full file name has been copied to the clipboard.', this.notificationOptions);
        });
      }catch(e){console.log(e)}
      try{
        this.historyData.vendors = JSON.parse(responses[2].toString());
        console.log("this.historyData.vendors");
        console.log(this.historyData.vendors[0]);                
        this.vendorsGrid.dataSource = this.historyData.vendors;        
      }catch(e){console.log(e)}      
    });
  }

  runNow(){
    let scriptId = this.route.snapshot.params.scriptId;
    console.log('run now...', scriptId);
    this.dataLoading = true;
    this.pageRunnerService.runScript(scriptId).subscribe((response)=>{
      console.log(response);
      this.dataLoading = false;
      this.notificationService.success('Success', 'The script was successfully queued. Reload the page to view results.', {...this.notificationOptions, timeOut: 5000});
    },
    (err)=>{
      console.log(err);  
      this.dataLoading = false;
      this.notificationService.success('Uh oh!', 'An unexpected error has occured: ' + err, this.notificationOptions);
    });
  }
  
  replayScreenVideo(){
    this.screenRecordingImageElement.src = '';
    // var temp = this.screenRecordingUrl;
    // this.screenRecordingUrl = ""
    setTimeout(() => {      
      this.screenRecordingImageElement.src = this.screenRecordingImage;
    }, 100);    

  }

  onVendorSelected(vendor){
    console.log(vendor)        
  }

  onVendorDeselected(event){
    console.log(event);
  }

  onHistorySelecting(args){
    console.log("onHistorySelecting")
    console.log(args);    
    if ( args && args.target && args.target.className == 'harLink' ){
      args.cancel = true;
    }
  }

  onHistorySelected(event){
    console.log("History Selected");
    console.log(event);    
    
    this.imageUrls = null;
    this.signedUrlPromises = null;

    this.selectedScript = event.data;
    this.loadRunInfo();        
  }

  async onViewHarClick(data){
    console.log("onViewHarClick");
    console.log(data);

    let val = `${this.scriptedDataCollectorBucket}/${data.dataLocation}HAR.json`;
    const url = await this.dataExportService.getSignedUrl(val).toPromise();
    document.cookie = `harUrl=${url};domain=vaultjs.com;path=/`;
    const harUrl = `https://har.ui${env.production ? "." : "." + env.name + "."}vaultjs.com/index.html`;

    window.open(harUrl, "_harViewer");
  }

  onHistoryDeselecting(args){
    console.log("onHistoryDeselecting");
    console.log(args);
    if ( args && args.target && args.target.className == 'harLink' ){
      args.cancel = true;
    }
  }
}
