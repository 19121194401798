import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { NotificationsService } from 'angular2-notifications';
import { LinkDiscoveryService } from '../../../services/linkDiscovery/link-discovery.service';
declare var $:any;

@Component({
  selector: 'app-link-discovery-config',
  templateUrl: './link-discovery-config.component.html',
  styleUrls: ['./link-discovery-config.component.css']
})
export class LinkDiscoveryConfigComponent implements OnInit, AfterViewInit {

  configForm:FormGroup;
  notificationOptions:any;

  get seeds(){
    return this.configForm.get('seeds') as FormArray;
  }

  get domains(){
    return this.configForm.get('allowedDomains') as FormArray;
  }

  get filters(){
    return this.configForm.get('filterRegexps') as FormArray;
  }

  constructor(private _fb: FormBuilder,
    private spinnerService:SpinnerService, 
    private notificationService:NotificationsService,
    private linkDiscoveryService:LinkDiscoveryService) {
    
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };

    this.configForm = this._fb.group({
      enabled: true,
      seeds: this._fb.array([this._fb.group({seed:""})]),
      allowedDomains: this._fb.array([this._fb.group({domain:""})]),
      filterRegexps: this._fb.array([this._fb.group({filter:""})]),
      maxLinks: 1440,
      defaultPageRunnerMaxAge: 1440,
      defaultLinkDiscoveryAge: 10080
    });
  }

  ngOnInit() {
    this.spinnerService.toggle(true);
    this.linkDiscoveryService.getConfig().subscribe((response)=>{
      var config = JSON.parse(response.config);
      console.log(config);

      if ( !config ){
        config = {};
      }

      var seeds = config.seeds ? config.seeds.map((s)=>{return this._fb.group({seed: s})}) : [this._fb.group({seed:''})];
      var domains = config.allowedDomains ? config.allowedDomains.map((d)=>{return this._fb.group({domain:d})}) : [this._fb.group({domain:''})];
      var filters = config.filterRegexps ? config.filterRegexps.map((f)=>{return this._fb.group({filter:f})}) : [this._fb.group({filter:''})];

      console.log(domains);

      this.configForm = this._fb.group({
        enabled: config.enabled,
        seeds: this._fb.array(seeds),
        allowedDomains: this._fb.array(domains),
        filterRegexps: this._fb.array(filters),
        maxLinks: config.maxLinks,
        defaultPageRunnerMaxAge: config.defaultPageRunnerMaxAge,
        defaultLinkDiscoveryAge: config.defaultLinkDiscoveryAge
      });

      this.spinnerService.toggle(false);
    },
    (error)=>{
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.error('Uh oh!', error, this.notificationOptions);
    });
  }

  ngAfterViewInit(){
    $('[data-toggle="popover"]').popover();
  }

  save(){
    console.log(this.configForm.value);

    // Lazy deep copy
    var configToSend = JSON.parse(JSON.stringify(this.configForm.value));
    configToSend.enabled = (configToSend.enabled == null || configToSend.enabled == undefined) ? false : configToSend.enabled;
    
    configToSend.seeds = configToSend.seeds.map((s)=>{return s.seed});
    configToSend.seeds = configToSend.seeds.length == 1 && configToSend.seeds[0] == "" ? [] : configToSend.seeds;
    
    configToSend.allowedDomains = configToSend.allowedDomains.map((d)=>{return d.domain});
    configToSend.allowedDomains = configToSend.allowedDomains.length == 1 && configToSend.allowedDomains[0] == "" ? [] : configToSend.allowedDomains;

    configToSend.filterRegexps = configToSend.filterRegexps.map((f)=>{return f.filter });
    configToSend.filterRegexps = configToSend.filterRegexps.length == 1 && configToSend.filterRegexps[0] == "" ? [] : configToSend.filterRegexps;


    this.spinnerService.toggle(true);
    this.linkDiscoveryService.updateConfig(configToSend).subscribe((response)=>{
      this.spinnerService.toggle(false);
      this.notificationService.success("Success!", "Link discovery config was updated successfully.", this.notificationOptions);
    },
    (error)=>{
      this.spinnerService.toggle(false);
      console.log(error);
      this.spinnerService.toggle(false);
      this.notificationService.error('Uh oh!', error, this.notificationOptions);      
    })
  }

  addFormControl(formProp, objProp) {
    const control = <FormArray>this.configForm.controls[formProp];
    var obj = {};
    obj[objProp] = '';
    control.push(this._fb.group(obj));
  }

  removeFormControl(i:number, formProp) {
    const control = <FormArray>this.configForm.controls[formProp];
    control.removeAt(i);
  }


}
