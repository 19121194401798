<div class="card card-flat">
  <ejs-dropdownlist
    class="dropdown"
    id="ddlelement"
    [dataSource]="data"
    [fields]="fields"
    [placeholder]="placeholder"
    (change)="onChange($event)"
    (created)="onCreated($event)"
    [value]="backtrackValue"
  >
  </ejs-dropdownlist>
</div>
