<div class="control-section">
    <ejs-spreadsheet #default (created)="created()">
        <e-sheets>
            <e-sheet name="Third Party Cookies Report">
                <e-ranges>
                    <e-range [dataSource]="cookiesData"></e-range>
                </e-ranges>
                <e-columns>
                    <e-column [width]=180></e-column>
                    <e-column [width]=130></e-column>
                    <e-column [width]=130></e-column>
                    <e-column [width]=180></e-column>
                    <e-column [width]=130></e-column>
                    <e-column [width]=120></e-column>
                    <e-column [width]=180></e-column>
                    <e-column [width]=130></e-column>
                    <e-column [width]=130></e-column>
                    <e-column [width]=180></e-column>
                    <e-column [width]=130></e-column>
                </e-columns>
            </e-sheet>
        </e-sheets>
    </ejs-spreadsheet>
</div>