import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { MENU_ITEMS, VaultMenuItem } from "./menu";
import { CustomerService } from '../../../services/customer/customer.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {

  }
  menu = MENU_ITEMS;

  constructor(private auth: AuthService, private sitemapService: SitemapService, private customerService: CustomerService) {
    this.menu = this.menu.filter(this.processNodes, this);
  }

  processNodes(item) {
    if ( item.disabledCustomers?.includes(this.customerService.customerName.value) ){
      item["isCustomerDisabled"] = true;
    }
    //console.log("Process Nodes ", item.menuText);
    if (item) {
      if (item.children && item.children.length > 0) {
        item.children = item.children.filter(this.processNodes, this);
      }
    }
    return this.processNode(item);
  }

  processNode(item: VaultMenuItem) {

    if ( item.disabledCustomers?.includes(this.customerService.customerName.value) ){
      item["isCustomerDisabled"] = true;
    }
    
    //console.log('Process Node: ', item.menuText);
    if (item.vaultUsersOnly == true && this.auth.isVaultUser() == true) {
      //console.log('\t Vault User only and vault user');
      return true;
    } else if (item.vaultUsersOnly == true && this.auth.isVaultUser() == false) {
      //console.log('\t Vault User only and NOT vault user');
      return false;
    }

    if (item.scopes && (!item.customers || item.customers.length == 0)) {
      //console.log('\t Item Scope violation');
      return this.auth.userHasScopes(item.scopes);
    }
    
    return this.isCustomerFiltered(item)
  }
 
  isCustomerFiltered(item: VaultMenuItem) {
    let ret = true;
    //console.log("isCustomerFiltered", item.menuText);
    if (item.customers && item.customers.length > 0) {
      //console.log("Customer Filter");
      let approved = item.customers.includes(this.customerService.customerName.value);
      if (approved == true && item.scopes) {
        //console.log('\t Item Scope violation');
        approved =  this.auth.userHasScopes(item.scopes);
      }
      //console.log("approved", approved);
      ret = approved
    }
    return ret
  }

}
