import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { VaultBaseService } from '../VaultBaseService';
import { environment as env } from '../../../environments/environment';
import * as AWS from 'aws-sdk';
import { S3 } from 'aws-sdk';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Papa } from 'ngx-papaparse';
import { ReportLibraryConfig } from '../../models/ReportLibraryConfig';
import { Observable } from 'rxjs';
import { VaultNotificationService } from '../notifications/vault-notification.service';
import { SpinnerService } from '../spinner/spinner.service';
import { ProgressBarService } from '../progressBarService/progress-bar.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingV2Service extends VaultBaseService {
  customerId: any;
  bucket: string;
  constructor(
    authHttp: HttpClient,
    private authService: AuthService,
    private papa: Papa,
    private notificationService: VaultNotificationService,
    private spinnerService: SpinnerService,
    private progressBarService: ProgressBarService
  ) {
    super(authHttp);
    this.customerId = this.authService.customerId();
    this.bucket = `${env.aws.customReportBucket}-${this.customerId}`;
    this.defaultConfig = [
      new ReportLibraryConfig(
        'Global Dashboard',
        'Analytical Insight on Regional Privacy Violations',
        'GDS',
        'reports/dashboard/global',
        false,
        false,
        'global',
        'Dashboard'
      ),
      new ReportLibraryConfig(
        'Tracker Summary',
        'Overview of Scanned Tracking Technologies',
        'TS',
        'reports/tracker-summary-dashboard',
        true,
        false,
        'tracker',
        'Online Tracking Technology'
      ),
      new ReportLibraryConfig(
        'Trackers By Vendor',
        'Vendor Breakdown of Detected Tracking Technologies',
        'TS',
        'reports/tracker-vendor-dashboard',
        true,
        false,
        'tracker',
        'Online Tracking Technology'
      ),
      new ReportLibraryConfig(
        'Fingerprinting Report',
        'Analysis of Browser Fingerprinting Technologies',
        'FP',
        'reports/fingerprint-dashboard',
        true,
        false,
        'fingerprint',
        'Other Privacy Risks'
      ),
      new ReportLibraryConfig(
        'VPPA Report',
        'Video Player and Tracker Analysis',
        'VRA',
        'reports/vppa-report',
        true,
        false,
        'vppa',
        'Other Privacy Risks'
      ),
      new ReportLibraryConfig(
        'Consent Summary',
        'Quick Insight on CMP Tool Detection and Privacy Violations',
        'CS',
        'reports/consentSummary',
        false,
        false,
        'privacy',
        'Cookie Reports'
      ),
      new ReportLibraryConfig(
        'Cookie w/ Sources',
        'In-depth Cookie Report with Source Chain',
        'CRS',
        null,
        true,
        false,
        'cookie-tracker',
        'Cookie Reports'
      ),

      new ReportLibraryConfig(
        'Tags w/ Sources',
        'In-depth Tag Report with Source Chain',
        'TRS',
        null,
        true,
        false,
        'code-tracker',
        'Cookie Reports'
      ),
      new ReportLibraryConfig(
        'Input Spy',
        'Input Field Tracking Report',
        'IP',
        null,
        true,
        false,
        'input-spy',
        'Other Privacy Risks'
      ),
      new ReportLibraryConfig(
        'Cookie Tracker Details',
        'In-depth Online Cookie Tracking Technology',
        'CTD',
        null,
        true,
        false,
        'cookie-tracker',
        'Online Tracking Technology'
      ),
      new ReportLibraryConfig(
        'Data Tracker Details',
        'In-depth Online Data Tracking Technology',
        'DTD',
        null,
        true,
        false,
        'data-tracker',
        'Online Tracking Technology'
      ),
      new ReportLibraryConfig(
        'CDN Report',
        '4th Party Content Delivery Network Report',
        'CDN',
        null,
        true,
        false,
        'cdn',
        'Other Privacy Risks'
      ),
      new ReportLibraryConfig(
        'Retire JS Report',
        'Open Source JavaScript Warnings',
        'RJS',
        null,
        true,
        false,
        'retirejs',
        'Custom'
      ),
      new ReportLibraryConfig(
        'Adobe Analytics',
        'Adobe Analytics Report',
        'ADT',
        'reports/adobe-analytics',
        true,
        false,
        'report',
        'Vendor Analytics'
      ),
      new ReportLibraryConfig(
        'Facebook Details',
        'Facebook Details Report',
        'FB',
        'reports/fb-details-dashboard',
        false,
        false,
        'report',
        'Vendor Analytics'
      ),
    ];
  }

  public extractReportDetails(reportName: string = '',size?:number): any {
    // console.log(`Extracting report details from ${reportName}`);
    if (!reportName) {
      return null;
    }
    const r = reportName.split('-');
    const out = {
      name: reportName,
      clientCode: r[0],
      reportCode: r[1],
      config: this.extractConfig(r[2]),
      timestamp: this.extractDate(r[3]),
      semVer: this.extractSemVer(r[4]),
      other: r.slice(5).join('-').split('.')[0],
      size: size || 0
    };
    //validate the report details
    if (
      out.clientCode &&
      out.reportCode &&
      out.config &&
      out.timestamp &&
      out.semVer
    ) {
      console.log(`Valid report name: ${reportName}`);
      return out;
    } else {
      // console.log(`Invalid report name: ${reportName}`);
      return {
        name: reportName,
      };
    }
  }
  private extractConfig(config: string) {
    try {
      const configParts = config.split('_');
      return {
        region: configParts[0] || 'No region specified',
        id: configParts[1] || 'No associated config ID',
      };
    } catch (e) {
      return {};
    }
  }
  private extractDate(date: string) {
    try {
      var month = parseInt(date.substring(0, 2))-1;
      var day = parseInt(date.substring(2, 4));
      var year = parseInt('20' + date.substring(4, 6));
      return new Date(year, month, day).toDateString();
    } catch (e) {
      return null;
    }
  }
  private extractSemVer(semVer: string) {
    try {
      var major = semVer.substring(1, 2);
      var minor = semVer.substring(3, 5);
      var patch = semVer.substring(5, 7);
      return `${major}.${minor}.${patch}`;
    } catch (e) {
      return null;
    }
  }
  public getLatestReportName(reports: any): any {
    var that = this;
    return reports.reduce((acc, curr) => {
      if (acc.timestamp < curr.timestamp) {
        return curr;
      } else {
        return acc;
      }
    });
  }
  public getAvailableReportsByCode(code: string = '') {
    var that = this;
    var retPromise = new Promise(function (resolve) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${
            env.name
          }-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            console.log(`Filtering reports for ${code}`);
            resolve(
              response.Contents.map((report) =>
                that.extractReportDetails(report.Key,report.Size)
              ).filter((report) => {
                return report?.reportCode === code;
              }).sort((a, b) => {
                return a.timestamp < b.timestamp ? 1 : -1;
              })
            );
          });
      });
    });
    return from(retPromise);
  }
  public getAvailableReports() {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `vjs-${
            env.name
          }-custom-reports-${that.authService.customerId()}`,
        };
        const s3 = new S3();
        from(s3.listObjectsV2(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            resolve(
              response.Contents.map((report) =>
                that.extractReportDetails(report.Key,report.Size)
              )
            );
          });
      });
    });
    return from(retPromise);
  }
  public getReportData(
    fileName: string,
    query: string = `SELECT * FROM S3Object s`,
    reportSize?:number,
    loader: boolean = false
  ) {
    return this.getCSVFromS3v2(fileName,query,reportSize,loader);
  }
  private getFile(key) {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
        };
        const s3 = new S3();
        from(s3.getObject(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            console.log('listObjectsV2', response);
            if (response && response.Body) {
              console.log(response);
              resolve(response.Body.toString());
            } else {
              resolve(null);
            }
          });
      });
    });
    return from(retPromise);
  }
  public getFileCSV(key: string): Observable<string | null> {
    return new Observable<string | null>((observer) => {
      this.authService.getAwsCreds().subscribe({
        next: (creds) => {
          AWS.config.region = env.aws.region;
          AWS.config.credentials = new AWS.Credentials(creds);

          const params = {
            Bucket: `${env.aws.customReportBucket}-${this.customerId}`,
            Key: key,
          };
          const s3 = new AWS.S3();

          s3.getObject(params)
            .promise()
            .then((response: any) => {
              if (response.Body) {
                if (response.Body instanceof Blob) {
                  // Handle Blob data
                  const reader = new FileReader();
                  reader.onload = () => {
                    observer.next(reader.result as string);
                    observer.complete();
                  };
                  reader.onerror = (error) => {
                    console.error('Error reading blob:', error);
                    observer.error(error);
                  };
                  reader.readAsText(response.Body);
                } else if (typeof response.Body === 'string') {
                  // If it's a string
                  observer.next(response.Body);
                  observer.complete();
                } else {
                  // Handle other cases like Buffer
                  const text = new TextDecoder('utf-8').decode(response.Body);
                  observer.next(text);
                  observer.complete();
                }
              } else {
                observer.next(null);
                observer.complete();
              }
            })
            .catch((error) => {
              console.error('Error fetching object from S3:', error);
              observer.error(error);
            });
        },
        error: (err) => {
          console.error('Error getting AWS creds:', err);
          observer.error(err);
        },
      });
    });
  }
  async uploadJSON(data: File[], filename: string) {
    let fileContents = JSON.stringify(data, null, 2);
    console.log('Processing file: ' + filename);
    const s3PutParams = {
      Body: fileContents,
      Bucket: this.bucket,
      Key: filename,
      ContentType: 'text/json',
    };

    let creds = await this.authService.getAwsCreds().toPromise();

    AWS.config.region = env.aws.region;
    AWS.config.credentials = new AWS.Credentials(creds);
    const s3 = new S3();

    let s3Response = await s3.putObject(s3PutParams).promise();
    console.log('putResponse', s3Response);
  }
  public getReportLibraryConfig() {
    return this.getFile('report-library-config.json');
  }
  public getDefaultConfig() {
    return this.defaultConfig;
  }
  public overrideDefaultConfig(config: ReportLibraryConfig[]) {
    // append new items to the default config
    // override only certain setting of the existing items if they exist in the new config
    console.log('Overriding default config with: ', config);
    const outConfig = [...this.defaultConfig];
    config.forEach((newConfig) => {
      const existingConfigs = outConfig.filter(
      (c) => c.reportCode === newConfig.reportCode
      );
      if (existingConfigs.length > 0) {
      existingConfigs.forEach((existingConfig) => {
        const index = outConfig.indexOf(existingConfig);
        outConfig[index] = new ReportLibraryConfig(
        newConfig.title || existingConfig.title,
        newConfig.description || existingConfig.description,
        existingConfig.reportCode,
        existingConfig.beautifiedPath,
        existingConfig.hasRaw,
        newConfig.hidden || existingConfig.hidden,
        newConfig.icon || existingConfig.icon,
        existingConfig.category,
        existingConfig.isCustom
        );
      });
      } else {
      outConfig.push({ ...newConfig, isCustom: true, hasRaw: true });
      }
    });
    return outConfig;
  }
  public getReportNameFromCode(code: string) {
    const report = this.defaultConfig.find((c) => c.reportCode === code);
    return report ? report.title : null;
  }
  public getStateListFromS3(code: string) {
    return this.getFile(`state${code}.json`);
  }
  public async saveStateListToS3(code: string, state: any) {
    const data: any = await this.getStateListFromS3(code).toPromise();
    const parsedData = data ? JSON.parse(data) : [];
    console.log('Parsed data: ', parsedData);
    const newState = {
      name: state.name,
      state: state.state,
      isComplexFilter: state.isComplexFilter,
      complexFilterRules: state.complexFilterRules,
    };
    if (parsedData.filter((state) => state.name === newState.name).length > 0) {
      //update instead of adding
      parsedData.forEach((state) => {
        if (state.name === newState.name) {
          state.state = newState.state;
        }
      });
    } else {
      parsedData.push(newState);
    }
    await this.uploadJSON(parsedData, `state${code}.json`);
  }
  public getStateListFromLocalStorage(code: string): any {
    if (window.localStorage.getItem(`grid-state-${code}`)) {
      console.log(
        'Getting state from local storage: ',
        JSON.parse(window.localStorage.getItem(`grid-state-${code}`))
      );
      return JSON.parse(window.localStorage.getItem(`grid-state-${code}`));
    }
    return [];
  }
  public saveStateListToLocalStorage(code: string, state: any) {
    const newState = {
      name: state.name,
      state: state.state,
      isComplexFilter: state.isComplexFilter,
      complexFilterRules: state.complexFilterRules,
    };
    const existingList = this.getStateListFromLocalStorage(code);
    if (existingList) {
      const list = existingList;
      if (list.filter((state) => state.name === newState.name).length > 0) {
        //update instead of adding
        list.forEach((state) => {
          if (state.name === newState.name) {
            state.state = newState.state;
          }
        });
      } else {
        list.push(newState);
      }
      window.localStorage.setItem(`grid-state-${code}`, JSON.stringify(list));
    } else {
      window.localStorage.setItem(
        `grid-state-${code}`,
        JSON.stringify([newState])
      );
    }
  }
  public deleteStateListFromLocalStorage(code: string, name: string) {
    const existingList = this.getStateListFromLocalStorage(code);
    if (existingList) {
      const list = existingList;
      const newList = list.filter((state) => state.name !== name);
      window.localStorage.setItem(
        `grid-state-${code}`,
        JSON.stringify(newList)
      );
    }
  }
  public saveReportLibraryConfigToS3(config: any[]) {
    this.uploadJSON(config, 'report-library-config.json');
  }
  public getIcon(code: string) {
    const report = this.defaultConfig.find((c) => c.reportCode === code);
    console.log('Icon for ', code, ' is ', report?.icon);
    return report ? report.icon : 'report';
  }
  private defaultConfig: ReportLibraryConfig[] = [];
  public queryBuilder(
    columnNames: string[],
    conditions: any[],
    limit: number = 10000
  ) {
    let query = `SELECT [COLUMNS] FROM S3Object s`;
    if (conditions.length > 0) {
      query += ' WHERE ';
    }
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      if (condition.operator === 'LIKE') {
        query += `(s.${condition.field} ${condition.operator} '${condition.value}%'`;
        query += ' OR ';
        query += `s.${condition.field} ${condition.operator} '%${condition.value}'`;
        query += ' OR ';
        query += `s.${condition.field} ${condition.operator} '${condition.value}'`;
        query += ' OR ';
        query += `s.${condition.field} ${condition.operator} '%${condition.value}%')`;
      } else {
        query += `s.${condition.field} ${condition.operator} ${condition.value}`;
      }
      if (i < conditions.length - 1) {
        query += ' AND ';
      }
    }
    query += ` LIMIT ${limit}`;
    if (columnNames.length > 0) {
      query = query.replace('[COLUMNS]', columnNames.join(','));
    } else {
      query = query.replace('[COLUMNS]', '*');
    }

    return query;
  }

  public bigDataRequest: AWS.Request<AWS.S3.SelectObjectContentOutput, AWS.AWSError>;
  public getCSVFromS3v2(key: string, query: string,reportSize?:number,loader?:boolean): Observable<any> {
    const isCompressed = key.endsWith('.gz');
    const that = this;
    const estimatedFileSize = reportSize || 1;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
          ExpressionType: 'SQL',
          Expression: query,
          InputSerialization: {
            CSV: {
              AllowQuotedRecordDelimiter: true,
              FileHeaderInfo: 'USE',
              RecordDelimiter: '\n',
              FieldDelimiter: ',',
              // QuoteCharacter: '"',
              QuoteEscapeCharacter: '"',

            },
            CompressionType: key.endsWith('.gz') ? 'GZIP' : 'NONE',
          },
          OutputSerialization: {
            CSV: {
            },
          },
          RequestProgress: {
            Enabled: true,
          },
        };
        var headerParams = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
          ExpressionType: 'SQL',
          Expression: `SELECT * FROM S3Object s LIMIT 1`,
          InputSerialization: {
            CSV: {
              FileHeaderInfo: 'NONE',
              RecordDelimiter: '\n',
              FieldDelimiter: ',',
              QuoteEscapeCharacter: '"',
            },
            CompressionType: key.endsWith('.gz') ? 'GZIP' : 'NONE',

          },
          OutputSerialization: {
            CSV: {
              QuoteCharacter: '"',
            },
          },
        };
        const s3 = new AWS.S3();
        s3.selectObjectContent(headerParams, (err, data) => {
          if (err) {
            console.log('Error while retrieving data: ', err);
            reject(err);
          }
          const _events: any = data.Payload;
          let output = '';
          // that.spinnerService.toggle(true,'Retrieving Metadata');
          for (const _event of _events) {
            if (_event.Records) {
              const records = _event.Records.Payload.toString();
              output = records;
            }else if (_event.Stats){
              // console.log('Stats: ',event.Stats);
              // console.log('File Size:', that.bytesToMB(reportSize));
            } else if (_event.End) {
              if(loader){
                that.progressBarService.resetComponent();
                if(isCompressed){
                  that.progressBarService.setIndeterminate(true);
                  console.log('Compressed file. Setting indeterminate');
                }
                that.progressBarService.toggle(true);

              }
              // console.log('Final output received');
              // that.spinnerService.toggle(true,'Retrieving Report Data');
              const tempReq: AWS.Request<AWS.S3.SelectObjectContentOutput, AWS.AWSError> = s3.selectObjectContent(params, (err, data) => {
                if (err) {
                  // console.log('Error while retrieving data: ', err);
                  that.notificationService.error('Error while retrieving report data', err);
                  reject(err);
                }

                const events: any = data.Payload;
                for (const event of events) {
                  if (event.Records) {
                    const records = event.Records.Payload.toString();
                    output += records;
                  }
                  else if (event.End) {
                    console.log('Final output received');

                    if(loader) that.progressBarService.complete();
                    that.papa.parse(output, {
                      header: true,
                      delimiter: ',',
                      skipEmptyLines: true,
                      quoteChar: '"',
                      escapeChar: '"',
                      newline: '\n',
                      complete: (result) => {
                        resolve(result.data);
                        if(result.meta.fields.includes('_parsed_extra')){
                          this.notificationService.error('Parse Error','Please check the file for errors');
                        }
                      },

                    });
                  }
                }
              });
              that.bigDataRequest = tempReq;
              if(loader){
                that.bigDataRequest.on('httpDownloadProgress', (progress) => {
                  // console.log(`Progress: ${progress.loaded} of ${estimatedFileSize}`);
                    //
                  if(isCompressed){
                    that.progressBarService.setValue(that.bytesToMB(progress.loaded),that.bytesToMB(progress.loaded)*4,'MB');
                  }else{
                    that.progressBarService.setValue(that.bytesToMB(progress.loaded),that.bytesToMB(estimatedFileSize),'MB');
                  }


                });
              }

            }
          }
        });
      });
    });
    return from(retPromise);
  }
public bytesToMB(bytes: number) {
    return bytes / 1024 / 1024;
  }

  public getDictionaryFromS3(code: string) {
    return this.getFile(`dictionary${code}.json`);
  }

  public getRDSMappingFromS3() {
    return this.getFile(`rdsReportMapping.json`);
  }
  public stopBigDataRequest(){
    if(this.bigDataRequest){
      this.bigDataRequest.abort();
      console.log('Aborted request');
      this.progressBarService.toggle(false);
      this.notificationService.info('Request Aborted','The request has been aborted');
    }else{
      console.log('No request to abort');
    }
  }
}
