import { Injectable, Injector } from '@angular/core';
import { concat, forkJoin, from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { S3 } from 'aws-sdk';
import * as AWS from 'aws-sdk';
import { environment as env } from '../../../environments/environment';
import { VaultNotificationService } from '../notifications/vault-notification.service';
import { ProgressBarService } from '../progressBarService/progress-bar.service';
import { Papa } from 'ngx-papaparse';
import { AuthService } from '../auth/auth.service';
import { ReportingIdbService } from '../reporting-idb/reporting-idb.service';
import { ReportingLibraryService } from '../reporting-library/reporting-library.service';
import { SpinnerService } from '../spinner/spinner.service';
import { ReportDetails } from '../../models/ReportDetails';
import { ReturnOption } from '@syncfusion/ej2-data';

export interface RDSMapping {
  // Define the structure of your RDSMapping here // can have multiple keys
  [key: string]: string;
  // Example: key1: string;
  // Example: key2: string;
  // Add more keys as needed
}

@Injectable({
  providedIn: 'root',
})
export class ReportingS3Service {
  private bigDataRequest: AWS.Request<
    AWS.S3.SelectObjectContentOutput,
    AWS.AWSError
  >;
  private customerId: string;
  private bucket: string;
  constructor(
    private progressBarService: ProgressBarService,
    private notificationService: VaultNotificationService,
    private papa: Papa,
    private reportingIdbService: ReportingIdbService,
    private spinnerService: SpinnerService,
    private reportingLibraryService: ReportingLibraryService,
    private authService: AuthService
  ) {
    this.bucket = `${env.aws.customReportBucket}-${this.customerId}`;
    this.customerId = this.authService.customerId();
  }
  /**
   * Retrieves a file from the AWS S3 bucket.
   *
   * @param key - The key of the file to retrieve.
   * @returns A promise that resolves to the content of the file as a string, or null if the file does not exist.
   */
  public getFile(key): Observable<string | null | unknown> {
    var that = this;
    var retPromise = new Promise(function (resolve, reject) {
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
        };
        const s3 = new S3();
        from(s3.getObject(params).promise())
          .pipe(catchError((error) => of(error)))
          .subscribe((response) => {
            console.log('listObjectsV2', response);
            if (response && response.Body) {
              console.log(response);
              resolve(response.Body.toString());
            } else {
              resolve(null);
            }
          });
      });
    });
    return from(retPromise);
  }

  /**
   * Retrieves a CSV file from AWS S3 based on the provided key.
   *
   * @param key - The key of the file to retrieve.
   * @returns An Observable that emits the content of the CSV file as a string, or null if the file does not exist.
   */
  public getFileCSV(key: string): Observable<string | null> {
    return new Observable<string | null>((observer) => {
      this.authService.getAwsCreds().subscribe({
        next: (creds) => {
          AWS.config.region = env.aws.region;
          AWS.config.credentials = new AWS.Credentials(creds);

          const params = {
            Bucket: `${env.aws.customReportBucket}-${this.customerId}`,
            Key: key,
          };
          const s3 = new AWS.S3();

          s3.getObject(params)
            .promise()
            .then((response: any) => {
              if (response.Body) {
                if (response.Body instanceof Blob) {
                  // Handle Blob data
                  const reader = new FileReader();
                  reader.onload = () => {
                    observer.next(reader.result as string);
                    observer.complete();
                  };
                  reader.onerror = (error) => {
                    console.error('Error reading blob:', error);
                    observer.error(error);
                  };
                  reader.readAsText(response.Body);
                } else if (typeof response.Body === 'string') {
                  // If it's a string
                  observer.next(response.Body);
                  observer.complete();
                } else {
                  // Handle other cases like Buffer
                  const text = new TextDecoder('utf-8').decode(response.Body);
                  observer.next(text);
                  observer.complete();
                }
              } else {
                observer.next(null);
                observer.complete();
              }
            })
            .catch((error) => {
              console.error('Error fetching object from S3:', error);
              observer.error(error);
            });
        },
        error: (err) => {
          console.error('Error getting AWS creds:', err);
          observer.error(err);
        },
      });
    });
  }
  /**
   * Uploads JSON data to an S3 bucket.
   *
   * @param data - The JSON data to upload.
   * @param filename - The name of the file to be uploaded.
   * @returns A promise that resolves when the upload is complete.
   */
  public uploadJSON(data: File[], filename: string):Observable<any> {
    let fileContents = JSON.stringify(data, null, 2);
    console.log('Processing file: ' + filename);
    const s3PutParams = {
      Body: fileContents,
      Bucket: `${env.aws.customReportBucket}-${this.customerId}`,
      Key: filename,
      ContentType: 'application/json',
    };
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        const s3 = new S3();
        return from(s3.putObject(s3PutParams).promise());
      }),
      map((response) => {
        // console.log('putResponse', response);
        return response;
      }),
      catchError((error) => {
        console.error('Error uploading file', error);
        return of({ error: error });
      }));
  }
  /*
  private uploadInitiatorFile(data: any, filename: string): Observable<any> {
    return this.authService.getAwsCreds().pipe(
      switchMap((creds) => {
        AWS.config.region = this._env.exportAWS.region;
        AWS.config.credentials = new AWS.Credentials(creds);
        console.log('Got creds', creds);
        const fileContents = JSON.stringify(data, null, 2);
        const s3PutParams = {
          Body: fileContents,
          Bucket: this._bucket,
          Key: `${this._customerId}/${filename}`,
          ContentType: 'text/plain',
        };
        return from(new AWS.S3().putObject(s3PutParams).promise());
      }),
      map((response) => {
        console.log('putResponse', response);
        return response;
      }),
      catchError((error) => {
        console.error('Error uploading file', error);
        return of({ error: error });
      })
    );
  }
  */
  /**
   * Retrieves report data from S3.
   *
   * @param fileName - The name of the file to retrieve.
   * @param query - The SQL query to execute. Defaults to `SELECT * FROM S3Object s`.
   * @param reportSize - The size of the report (optional)
   * @param loader - Indicates whether to show a loader while retrieving the data. Defaults to `false`.
   * @param useIDB - Indicates whether to use IndexedDB for caching. Defaults to `false`.
   * @returns An observable that emits the report data.
   */
  public getReportData(
    fileName: string,
    query: string = `SELECT * FROM S3Object s`,
    reportSize?: number,
    loader: boolean = false,
    useIDB: boolean = false
  ): Observable<ReturnOption> {
    return this.getCSVFromS3(fileName, query, reportSize, loader, useIDB);
  }
  /**
   * Retrieves a CSV file from S3 based on the provided key and query.
   *
   * @param key - The key of the CSV file in S3.
   * @param query - The SQL query to filter the data in the CSV file.
   * @param reportSize - (Optional) The estimated size of the report in bytes.
   * @param loader - (Optional) Indicates whether a loader should be displayed while loading the data.
   * @param useIDB - (Optional) Indicates whether to check the IndexedDB for cached data.
   * @returns An Observable that emits the CSV data as an array of objects.
   */
  public getCSVFromS3(
    key: string,
    query: string,
    reportSize?: number,
    loader?: boolean,
    useIDB?: boolean
  ): Observable<ReturnOption> {
    const isCompressed = key?.endsWith('.gz') ? true : false;
    const that = this;
    const estimatedFileSize = reportSize || 1;
    var retPromise = new Promise(async (resolve, reject) => {
      if (useIDB) {
        console.log('Checking IDB for cached data');
        const cachedData = await that.reportingIdbService.getCachedData(
          key,
          that.customerId
        );
        console.log('Checking IDB: ', cachedData);
        if (cachedData) {
          console.log('Data found in IDB');
          resolve(
            {
              result: cachedData.data,
              count: cachedData.data.length,
            } as ReturnOption
          );
          return;
        }
      }
      if (loader) {
        this.notificationService.info(
          'Large Data Set',
          'Loading more data. Please wait...'
        );
      }
      that.authService.getAwsCreds().subscribe((creds) => {
        AWS.config.region = env.aws.region;
        AWS.config.credentials = new AWS.Credentials(creds);

        var params = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
          ExpressionType: 'SQL',
          Expression: query,
          InputSerialization: {
            CSV: {
              AllowQuotedRecordDelimiter: true,
              FileHeaderInfo: 'USE',
              RecordDelimiter: '\n',
              FieldDelimiter: ',',
              // QuoteCharacter: '"',
              QuoteEscapeCharacter: '"',
            },
            CompressionType: key?.endsWith('.gz') ? 'GZIP' : 'NONE',
          },
          OutputSerialization: {
            CSV: {},
          },
          RequestProgress: {
            Enabled: true,
          },
        };
        var headerParams = {
          Bucket: `${env.aws.customReportBucket}-${that.customerId}`,
          Key: key,
          ExpressionType: 'SQL',
          Expression: `SELECT * FROM S3Object s LIMIT 1`,
          InputSerialization: {
            CSV: {
              AllowQuotedRecordDelimiter: true,
              FileHeaderInfo: 'NONE',
              RecordDelimiter: '\n',
              FieldDelimiter: ',',
              QuoteEscapeCharacter: '"',
            },
            CompressionType: key?.endsWith('.gz') ? 'GZIP' : 'NONE',
          },
          OutputSerialization: {
            CSV: {
              QuoteCharacter: '"',
            },
          },
        };
        const s3 = new AWS.S3();
        s3.selectObjectContent(headerParams, (err, data) => {
          if (err) {
            console.log('Error while retrieving data: ', err);
            reject(err);
          }
          const _events: any = data.Payload;
          let output = '';
          for (const _event of _events) {
            if (_event.Records) {
              const records = _event.Records.Payload.toString();
              output = records;
            } else if (_event.End) {
              if (loader) {
                that.progressBarService.resetComponent();
                if (isCompressed) {
                  that.progressBarService.setIndeterminate(true);
                  console.log('Compressed file. Setting indeterminate');
                }
                that.progressBarService.toggle(true);
              }
              const tempReq: AWS.Request<
                AWS.S3.SelectObjectContentOutput,
                AWS.AWSError
              > = s3.selectObjectContent(params, (err, data) => {
                if (err) {
                  that.notificationService.error(
                    'Error while retrieving report data',
                    err
                  );
                  reject(err);
                }
                const events: any = data.Payload;
                for (const event of events) {
                  if (event.Records) {
                    const records = event.Records.Payload.toString();
                    output += records;
                  } else if (event.End) {
                    console.log('Final output received');
                    let stringTooBig = false;
                    if (loader) that.progressBarService.complete();
                    try {
                      console.log('Output Length');
                      if (output.length > 9007199254740900) {
                        stringTooBig = true;
                      }
                    } catch {
                      stringTooBig = true;
                    }
                    this.spinnerService.toggle(
                      true,
                      'Processing Incoming Data'
                    );
                    that.papa.parse(output, {
                      header: true,
                      delimiter: ',',
                      skipEmptyLines: true,
                      quoteChar: '"',
                      escapeChar: '"',
                      newline: '\n',
                      dynamicTyping: true,
                      transform(value, columnOrHeader) {
                        value = value.trim();
                        if (value === null) value = '';
                        return value;
                      },
                      transformHeader(header) {
                        return header.trim();
                      },

                      complete: async (result) => {
                        if (useIDB) {
                          that.reportingIdbService.cacheData(
                            key,
                            that.customerId,
                            result.data
                          );
                        }
                        if (result.meta.fields.includes('_parsed_extra')) {
                          this.notificationService.error(
                            'Parse Error',
                            'Please check the file for errors'
                          );
                        }
                        if (stringTooBig) {
                          this.notificationService.error(
                            'Data too large',
                            'Data too large to process. Please try again with a smaller dataset'
                          );
                          this.spinnerService.toggle(false, '');

                          resolve(
                            {
                              result: [],
                              count: 0,
                            } as ReturnOption
                          );
                        }
                        this.spinnerService.toggle(false, '');
                        resolve(
                          {
                            result: result.data,
                            count: result.data.length,
                          } as ReturnOption
                        );
                      },
                    });
                  }
                }
              });
              that.bigDataRequest = tempReq;
              if (loader) {
                that.bigDataRequest.on('httpDownloadProgress', (progress) => {
                  // console.log(`Progress: ${progress.loaded} of ${estimatedFileSize}`);
                  //
                  if (isCompressed) {
                    that.progressBarService.setValue(
                      that.bytesToMB(progress.loaded),
                      that.bytesToMB(progress.loaded) * 4,
                      'MB'
                    );
                  } else {
                    that.progressBarService.setValue(
                      that.bytesToMB(progress.loaded),
                      that.bytesToMB(estimatedFileSize),
                      'MB'
                    );
                  }
                });
              }
            }
          }
        });
      });
    });
    return from(retPromise);
  }
  /**
   * Retrieves a dictionary from S3 based on the provided code.
   * @param code - The code used to identify the dictionary.
   * @returns A promise that resolves to the dictionary file.
   */
  public getDictionaryFromS3(code: string) {
    return this.getFile(`dictionary${code}.json`);
  }

  /**
   * Converts bytes to megabytes.
   *
   * @param bytes - The number of bytes to convert.
   * @returns The equivalent value in megabytes.
   */
  public bytesToMB(bytes: number) {
    return bytes / 1024 / 1024;
  }
  /**
   * Stops the big data request if it is currently running.
   * If the request is aborted, it logs a message, toggles off the progress bar, and displays an info notification.
   * If there is no request to abort, it logs a message indicating that there is no request to abort.
   */
  public stopBigDataRequest() {
    if (this.bigDataRequest) {
      this.bigDataRequest.abort();
      console.log('Aborted request');
      this.progressBarService.toggle(false);
      this.notificationService.info(
        'Request Aborted',
        'The request has been aborted'
      );
    } else {
      console.log('No request to abort');
    }
  }

  /**
   * Retrieves the RDS mapping from S3.
   * @returns {Observable<RDSMapping>} A promise that resolves with the RDS mapping.
   */
  public getRDSMappingFromS3(): Observable<RDSMapping> {
    return this.getFile(`rdsReportMapping.json`).pipe(
      map((data: string) => JSON.parse(data || '{}')),
      catchError((error) => {
        console.error('Error fetching RDS mapping:', error);
        return of({});
      })
    );
  }

  /**
   * Sets the RDS mapping to S3.
   *
   * @param data - The data to be uploaded.
   * @returns A promise that resolves when the upload is complete.
   */
  public setRDSMappingToS3(data: any) {
    return this.uploadJSON(data, `rdsReportMapping.json`);
  }
  private _initialChunkLimit = 1000; // Set your initial chunk limit
  public getDataManagerS3(selectedReport: ReportDetails): Observable<ReturnOption> {
    const _lastModifiedDate: Observable<any> = this.reportingLibraryService.getLastModifiedDate(
      selectedReport.name
    );
    const _lastModifiedDateFromLS: Observable<any> = this.reportingLibraryService.getLastModifiedDateFromLocalStorage(
      this.authService.customerId(),
      selectedReport.name
    );

    return forkJoin([_lastModifiedDate, _lastModifiedDateFromLS]).pipe(
      map(([lastModifiedDate, lastModifiedDateFromLS]) => {
        if (lastModifiedDate !== lastModifiedDateFromLS) {
          this.reportingIdbService.removeCachedData(
            selectedReport.name,
            this.authService.customerId()
          );
          this.reportingLibraryService.setLastModifiedDateInLocalStorage(
            this.authService.customerId(),
            selectedReport.name,
            lastModifiedDate.toString()
          );
        }
      }),
      concatMap(() => {
        const initialChunk$ = this.getReportData(
          selectedReport.name,
          `SELECT * FROM s3Object s LIMIT ${this._initialChunkLimit + 5}`,
          selectedReport.size,
          false
        );
        return initialChunk$;
      })
    );
}
  public getDataManagerS3Big(selectedReport: ReportDetails): Observable<any> {
      const fullData$ = this.getReportData(
          selectedReport.name,
          `SELECT * FROM s3Object s`,
          selectedReport.size,
          true,
          true
        );
        return fullData$;
  }

  public getCustomReport(reportName: string): Observable<ReturnOption> {
    return this.getReportData(reportName);
  }

  public get initialChunkLimit(): number {
    return this._initialChunkLimit;
  }

}

