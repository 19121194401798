import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class VaultNotificationService {

  constructor(protected notificationService:NotificationsService) {   
  }
  
  success(title, message, timeOut=5000, showProgressBar=true, pauseOnHover=true, clickToClose=true){
    let options = {
      timeOut: timeOut,
      showProgressBar: showProgressBar,
      pauseOnHover: pauseOnHover,
      clickToClose: clickToClose
    };
    return this.notificationService.success(title, message, options);
  }

  error(title, message, timeOut=5000, showProgressBar=true, pauseOnHover=true, clickToClose=true){
    let options = {
      timeOut: timeOut,
      showProgressBar: showProgressBar,
      pauseOnHover: pauseOnHover,
      clickToClose: clickToClose
    };
    return this.notificationService.error(title, message, options);
  }

  warn(title, message, timeOut=5000, showProgressBar=true, pauseOnHover=true, clickToClose=true){
    let options = {
      timeOut: timeOut,
      showProgressBar: showProgressBar,
      pauseOnHover: pauseOnHover,
      clickToClose: clickToClose
    };
    return this.notificationService.warn(title, message, options);
  }

  info(title, message, timeOut=5000, showProgressBar=true, pauseOnHover=true, clickToClose=true){
    let options = {
      timeOut: timeOut,
      showProgressBar: showProgressBar,
      pauseOnHover: pauseOnHover,
      clickToClose: clickToClose
    };
    return this.notificationService.info(title, message, options);
  }
  
}
