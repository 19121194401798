import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.css']
})
export class ReportHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
