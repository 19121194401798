import { Component, OnInit, Input, OnChanges, SimpleChanges, Output } from '@angular/core';
import { ReportingLibraryService } from '../../../services/reporting-library/reporting-library.service';
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'app-dashboard-file-selector',
  templateUrl: './dashboard-file-selector.component.html',
  styleUrls: ['./dashboard-file-selector.component.css'],
})
export class DashboardFileSelectorComponent implements OnInit, OnChanges {
  constructor(private reportingService: ReportingLibraryService) {}
  @Output() selectEvent: EventEmitter<any> = new EventEmitter();
  @Input() reportCode: string = '';
  public reportListFields: any = {
    text: 'name',
    value: 'name',
  }
  public selectedReport: any = '';
  public reportList: any[] = [];

  ngOnInit(): void {
      if (this.reportCode === '') {
        console.error('Report Code is not defined');
        return;
      }
      this.reportingService
        .getAvailableReportsByCode(this.reportCode)
        .subscribe((data: any) => {
          this.reportList = data;
          this.selectedReport = this.reportList[0]
          this.onReportSelect({ itemData: this.selectedReport });
        });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reportCode.currentValue !== '') {
      console.log('ReportCode changed');
      this.reportingService
        .getAvailableReportsByCode(this.reportCode)
        .subscribe((data: any) => {
          this.reportList = data;
          this.selectedReport = this.reportList[0]
          this.onReportSelect({ itemData: this.selectedReport });
        });
    }
  }

  onReportSelect(event: any) {
    console.log(`Select in Selector: `, event.itemData.name)
    this.selectEvent.emit(event.itemData.name);
  }

}
