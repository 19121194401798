import { Component, OnInit, Input } from '@angular/core';
import { SitemapService } from '../../../services/sitemap/sitemap.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  constructor(public sitemapService:SitemapService) { }

  ngOnInit() {
    
  }

}
