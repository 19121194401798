<div class="card" [style.height]="height">
    <div class="card-header">
        <div style="flex-grow: 1;">
            <ng-content select="[header]"></ng-content>
        </div>
        <div class="toolbar" [style.margin-right]="hideInfoIcon ?'0px':'10px'">
            <ng-content select="[headerToolbar]"></ng-content>
        </div>
        <div style="width: fit-content;" *ngIf="!hideInfoIcon">
            <ejs-tooltip [content]="tooltipContent || 'No Description Available'">
                <span class="zmdi zmdi-info-outline info-tooltip-icon"></span>
            </ejs-tooltip>
        </div>
    </div>
    <div class="card-body" style="position: relative;">
        <ng-content [hidden]="isLoading"></ng-content>
        <div class="skeleton-loader" [hidden]="!isLoading">
            <ng-container [ngSwitch]="loaderTemplate">
                <ng-container *ngSwitchCase="'chart'">
                    <div class="skeleton-chart"></div>
                </ng-container>
                <ng-container *ngSwitchCase="'table'">
                    <div class="skeleton-table"></div>
                </ng-container>
                <ng-container *ngSwitchCase="'list'">
                    <div class="skeleton-list"></div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="skeleton-line-large"></div>
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line-small"></div>
                </ng-container>
            </ng-container>
        </div>
    </div>

</div>


