export let data:any = 
[
  {
    id: 7092,
    name: 'Adblade',
    website: 'adblade.com',
    fileCount: 87,
    mostRecentUpdate: '2021-03-09T19:17:18+00:00',
    x: 0
  },
  {
    id: 6837,
    name: 'Adobe',
    website: 'adobe.com',
    fileCount: 5,
    mostRecentUpdate: '2021-03-10T22:16:43+00:00',
    x: 1
  },
  {
    id: 6997,
    name: 'Akamai',
    website: 'akamai.com',
    fileCount: 1177,
    mostRecentUpdate: '2021-03-23T23:16:47+00:00',
    x: 2
  },
  {
    id: 6852,
    name: 'Amazon',
    website: 'amazon.com',
    fileCount: 3,
    mostRecentUpdate: '2021-02-25T01:52:26+00:00',
    x: 3
  },
  {
    id: 6836,
    name: 'AppNexus',
    website: 'AppNexus.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-25T01:52:26+00:00',
    x: 4
  },
  {
    id: 7035,
    name: 'Branch',
    website: 'branch.io',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:57:17+00:00',
    x: 5
  },
  {
    id: 6892,
    name: 'Chartbeat',
    website: 'chartbeat.com',
    fileCount: 3,
    mostRecentUpdate: '2021-02-25T02:35:32+00:00',
    x: 6
  },
  {
    id: 6989,
    name: 'Cisco',
    website: 'cisco.com',
    fileCount: 1,
    mostRecentUpdate: '2021-01-11T12:19:01+00:00',
    x: 7
  },
  {
    id: 7087,
    name: 'Cloudflare',
    website: 'cloudflare.com',
    fileCount: 1,
    mostRecentUpdate: '2021-02-10T19:46:37+00:00',
    x: 8
  },
  {
    id: 6992,
    name: 'comScore',
    website: 'comscore.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-25T02:35:32+00:00',
    x: 9
  },
  {
    id: 7274,
    name: 'Credit Karma',
    website: 'imgix.net',
    fileCount: 6,
    mostRecentUpdate: '2021-01-11T12:18:35+00:00',
    x: 10
  },
  {
    id: 6840,
    name: 'Criteo',
    website: 'Criteo.com',
    fileCount: 1,
    mostRecentUpdate: '2021-02-25T01:52:26+00:00',
    x: 11
  },
  {
    id: 7071,
    name: 'Decibel Insight',
    website: 'decibelinsight.com',
    fileCount: 1,
    mostRecentUpdate: '2021-01-11T12:19:01+00:00',
    x: 12
  },
  {
    id: 6900,
    name: 'Demandbase',
    website: 'Demandbase.com',
    fileCount: 1,
    mostRecentUpdate: '2021-02-10T19:46:37+00:00',
    x: 13
  },
  {
    id: 7273,
    name: 'Digicert',
    website: 'digicert.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:33:37+00:00',
    x: 14
  },
  {
    id: 6860,
    name: 'DoubleVerify',
    website: 'DoubleVerify.com',
    fileCount: 4,
    mostRecentUpdate: '2021-03-09T14:16:16+00:00',
    x: 15
  },
  {
    id: 6832,
    name: 'Ensighten',
    website: 'ensighten.com',
    fileCount: 6,
    mostRecentUpdate: '2021-01-11T12:19:01+00:00',
    x: 16
  },
  {
    id: 6844,
    name: 'Facebook',
    website: 'facebook.com',
    fileCount: 24,
    mostRecentUpdate: '2021-03-09T01:16:17+00:00',
    x: 17
  },
  {
    id: 6830,
    name: 'Google',
    website: 'gsuite.google.com',
    fileCount: 163,
    mostRecentUpdate: '2021-03-16T07:16:45+00:00',
    x: 18
  },
  {
    id: 7271,
    name: 'Gravatar',
    website: 'gravatar.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:33:46+00:00',
    x: 19
  },
  {
    id: 7275,
    name: 'Impact',
    website: 'impactradius-event.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:36:22+00:00',
    x: 20
  },
  {
    id: 7270,
    name: 'Infogram',
    website: 'jifo.co',
    fileCount: 231,
    mostRecentUpdate: '2020-10-20T04:57:54+00:00',
    x: 21
  },
  {
    id: 6928,
    name: 'Innovid',
    website: 'innovid.com',
    fileCount: 2,
    mostRecentUpdate: '2021-03-05T11:16:16+00:00',
    x: 22
  },
  {
    id: 6876,
    name: 'Integral Ad Science',
    website: 'integralads.com',
    fileCount: 8,
    mostRecentUpdate: '2021-03-06T00:16:16+00:00',
    x: 23
  },
  {
    id: 7276,
    name: 'jsDelivr',
    website: 'jsdelivr.net',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:34:49+00:00',
    x: 24
  },
  {
    id: 6896,
    name: 'LinkedIn',
    website: 'linkedin.com',
    fileCount: 2,
    mostRecentUpdate: '2021-01-11T12:19:01+00:00',
    x: 25
  },
  {
    id: 7029,
    name: 'Marketo',
    website: 'marketo.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-10T19:46:37+00:00',
    x: 26
  },
  {
    id: 7155,
    name: 'Matomo',
    website: 'tvsquared.com',
    fileCount: 0,
    mostRecentUpdate: '2020-01-03T19:48:51+00:00',
    x: 27
  },
  {
    id: 6841,
    name: 'Microsoft',
    website: 'microsoft.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-10T19:46:37+00:00',
    x: 28
  },
  {
    id: 6883,
    name: 'Moat',
    website: 'moat.com',
    fileCount: 1,
    mostRecentUpdate: '2021-02-25T02:35:32+00:00',
    x: 29
  },
  {
    id: 6847,
    name: 'New Relic',
    website: 'newrelic.com',
    fileCount: 10,
    mostRecentUpdate: '2021-03-19T12:17:17+00:00',
    x: 30
  },
  {
    id: 6903,
    name: 'Nielsen',
    website: 'Nielsen.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-25T02:35:32+00:00',
    x: 31
  },
  {
    id: 6968,
    name: 'Optimizely',
    website: 'optimizely.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-10T19:46:37+00:00',
    x: 32
  },
  {
    id: 6833,
    name: 'Oracle',
    website: 'oracle.com',
    fileCount: 4,
    mostRecentUpdate: '2021-03-05T11:16:16+00:00',
    x: 33
  },
  {
    id: 6904,
    name: 'Outbrain',
    website: 'outbrain.com',
    fileCount: 2,
    mostRecentUpdate: '2020-11-20T05:11:13+00:00',
    x: 34
  },
  {
    id: 6996,
    name: 'Quora',
    website: 'quora.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:46:47+00:00',
    x: 35
  },
  {
    id: 7031,
    name: 'RevJet',
    website: 'revjet.com',
    fileCount: 12,
    mostRecentUpdate: '2021-03-06T22:16:13+00:00',
    x: 36
  },
  {
    id: 6855,
    name: 'Rubicon Project',
    website: 'RubiconProject.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-25T01:52:26+00:00',
    x: 37
  },
  {
    id: 6990,
    name: 'Snapchat',
    website: 'snapchat.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:52:16+00:00',
    x: 38
  },
  {
    id: 6865,
    name: 'Taboola',
    website: 'taboola.com',
    fileCount: 29,
    mostRecentUpdate: '2021-03-09T16:16:07+00:00',
    x: 39
  },
  {
    id: 6967,
    name: 'Tealium',
    website: 'tealium.com',
    fileCount: 60,
    mostRecentUpdate: '2021-03-16T07:16:45+00:00',
    x: 40
  },
  {
    id: 6875,
    name: 'The Trade Desk',
    website: 'thetradedesk.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:34:02+00:00',
    x: 41
  },
  {
    id: 7058,
    name: 'TVSquared',
    website: 'tvsquared.com',
    fileCount: 1,
    mostRecentUpdate: '2020-10-20T04:55:46+00:00',
    x: 42
  },
  {
    id: 6884,
    name: 'Twitter',
    website: 'marketing.twitter.com',
    fileCount: 21,
    mostRecentUpdate: '2021-03-04T18:51:08+00:00',
    x: 43
  },
  {
    id: 7210,
    name: 'Unknown',
    website: '',
    fileCount: 71,
    mostRecentUpdate: '2020-10-20T04:34:14+00:00',
    x: 44
  },
  {
    id: 7221,
    name: 'Vimeo',
    website: 'vimeocdn.com',
    fileCount: 43,
    mostRecentUpdate: '2021-01-20T18:21:41+00:00',
    x: 45
  },
  {
    id: 7272,
    name: 'Wordpress',
    website: 'wp.com',
    fileCount: 38,
    mostRecentUpdate: '2021-05-06T13:55:52+00:00',
    x: 46
  },
  {
    id: 6853,
    name: 'Yahoo!',
    website: 'yahoo.com',
    fileCount: 2,
    mostRecentUpdate: '2021-02-25T01:52:26+00:00',
    x: 47
  },
  {
    id: 7048,
    name: 'Zendesk',
    website: 'zendesk.com',
    fileCount: 103,
    mostRecentUpdate: '2021-01-20T18:21:26+00:00',
    x: 48
  }
]
