import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { VendorsService } from '../../../services/vendors/vendors.service';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { environment as env } from "../../../../environments/environment";

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.css']
})
export class VendorDetailsComponent implements OnInit {
  vendor:any;
  cdnUrl:string;

  constructor(private route:ActivatedRoute, private sitemapService:SitemapService, private vendorService:VendorsService) { 
    console.log("Resolver Data");
    console.log(this.route.snapshot.data);
    sitemapService.update(route);
    this.cdnUrl = env.cdn.url;
  }

  ngOnInit() {
    console.log("Vendor Init");
    this.route.params.subscribe( p => {
      console.log("p", p);
      if ( p.vendor && this.vendorService.selectedVendor && p.vendor !== this.vendorService.selectedVendor ){ 
        this.loadVendor();
      }
      else if ( this.vendorService.selectedVendor ) {
        console.log("Current Vendor");
        this.vendor = this.vendorService.selectedVendor;
      } else {
        this.loadVendor();
      }
    });
  }

  loadVendor(){
    console.log("URL:", this.route.snapshot.url.join("/"));
    const vendor = unescape(decodeURI(this.route.snapshot.url.join("/").replace("vendors/","")).replace("library/", ""));
    console.log(vendor);
    this.vendorService.getVendorInfo(vendor).subscribe( data => {
      console.log("Vendors loaded");
      this.vendorService.selectedVendor = data.filter((element, index, array) => {
        if ( element.id == vendor || element.name == vendor ){
          return vendor;
        }
      })
      console.log("Loaded vendor:", this.vendorService.selectedVendor);
      this.vendor = this.vendorService.selectedVendor[0];
    });
  }

}
