<div class="report-container">
    <div class="row" style="margin-bottom: 25px;">
        <div class="col-lg-2">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/logo-Meta.png" alt="Facebook Logo"
                height="100px">
        </div>
        <div class="col-lg-7">
            <div class="report-title">Meta Vendor Site Analysis</div>
            <div class="report-meta">Report Generated on {{reportDate}}</div>
        </div>
    </div>
    <div class="row" style="margin-bottom:25px;">
        <div class="col-lg-8">
            <ejs-dropdownlist width="100%" [dataSource]="domainList" placeholder="Select a domain"
                [fields]="domainListFields" (select)="onDropdownValueChanged($event)"
                [value]="selectedDomain"></ejs-dropdownlist>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6" style="border: 0px solid #fc7d73;">
            <app-title-card [isLoading]="isLoading" [tooltipContent]="panelDescriptions['fbTechDetected']" height="250px">
                <ng-container header>
                    <span class="zmdi zmdi-chart title-icon"></span>
                    FB Technology Detected
                </ng-container>
                <div class="flex-row">
                    <multi-pie-chart [datasource]="scannedResults" [id]="'scanResults'" [useThin]="true"
                        [hideLabels]="true" [showCenterPercent]="true"></multi-pie-chart>
                    <div>
                        <div class="metric-label">Pages Scanned</div>
                        <div class="metric-value">{{FBPagesScannedData.totalPages}}</div>
                        <div class="metric-label">Pages w/ Facebook Technology</div>
                        <div class="metric-value">{{FBPagesScannedData.totalFBPages}}</div>
                    </div>
                </div>
            </app-title-card>
        </div>

        <div class="col-lg-6">
            <app-title-card [isLoading]="isLoading" height="250px" [tooltipContent]="panelDescriptions['compareReports']">
                <ng-container header>
                    <span class="zmdi zmdi-image-o title-icon"></span>
                    Compare Reports
                </ng-container>

                <!-- <ejs-grid [dataSource]="[]" height="100%">
                    <e-columns>
                        <e-column field="timestamp" headerText="Timestamp">

                        </e-column>
                        <e-column>
                            <ng-template #template let-data>
                                <button (click)="onCompareClick(data)">Compare</button>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid> -->
                <div class="flex-row">
                    No Comparable Reports Found
                </div>
            </app-title-card>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12" style="border: 0px solid #fc7d73;">
            <app-title-card [isLoading]="isLoading" height="300px" [tooltipContent]="panelDescriptions['fbPixelDetected']">
                <ng-container header>
                    <span class="zmdi zmdi-image-o title-icon"></span>
                    Tracking Pixels IDs
                </ng-container>

                <ejs-grid height="100%" [dataSource]="PIDData || []" width="100%" #pixelGrid>
                    <e-columns>
                        <e-column field="id" headerText="Pixel ID">
                            <ng-template #template let-data>
                                <span>{{data.id}}</span>
                                <span *ngIf="data.new"
                                    style="background-color: #6390ee; color:white; padding:2px 5px; margin-left:3px; border-radius: .25rem">NEW</span>
                            </ng-template>
                        </e-column>
                        <e-column field="totalPages" headerText="Total Pages">
                            <ng-template #template let-data>
                                <app-simple-metric-diff [currentValue]="data?.totalPages"
                                    [diffValue]="PIDDataCompare[data.id]?.totalPages" [isHidden]="!
                                    PIDDataCompare[data.id] || data.new"></app-simple-metric-diff>
                            </ng-template>
                        </e-column>
                        <e-column field="LDU" headerText="Pages w/ LDU">
                            <ng-template #template let-data>
                                <app-simple-metric-diff [currentValue]="data?.LDU"
                                    [diffValue]="PIDDataCompare[data.id]?.LDU"
                                    [isHidden]="!PIDDataCompare[data.id] || data.new"></app-simple-metric-diff>
                            </ng-template>
                        </e-column>
                        <e-column field="SDK" headerText="Pages w/ SDK">
                            <ng-template #template let-data>
                                <app-simple-metric-diff [currentValue]="data?.SDK"
                                    [diffValue]="PIDDataCompare[data.id]?.SDK"
                                    [isHidden]="!PIDDataCompare[data.id] || data.new"></app-simple-metric-diff>
                            </ng-template>
                        </e-column>
                        <e-column field="FBtracking" headerText="Pages w/ FB Track">
                            <ng-template #template let-data>
                                <app-simple-metric-diff [currentValue]="data?.FBtracking"
                                    [diffValue]="PIDDataCompare[data.id]?.FBtracking"
                                    [isHidden]="!PIDDataCompare[data.id] || data.new"></app-simple-metric-diff>
                            </ng-template>
                        </e-column>
                        <e-column field="PrivacySandbox" headerText="Pages w/ Privacy Sandbox">
                            <ng-template #template let-data>
                                <app-simple-metric-diff [currentValue]="data?.PrivacySandbox"
                                    [diffValue]="PIDDataCompare[data.id]?.PrivacySandbox"
                                    [isHidden]="!PIDDataCompare[data.id] || data.new"></app-simple-metric-diff>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>

            </app-title-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <app-title-card [isLoading]="isLoading" height="300px" [tooltipContent]="panelDescriptions['configParamDistribution']">
                <ng-container header>
                    <span class="zmdi zmdi-chart title-icon"></span>
                    Config Param Distribution
                </ng-container>
                <div style="width: 100%;">
                    <ejs-chart height="250px" width="100%" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
                        [palettes]="chartPallete">
                        <e-series-collection>
                            <e-series [dataSource]="configParamDistribution" xName="numParams" yName="pages" type="Bar"
                                name="Total Pages">
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>

            </app-title-card>
        </div>

        <div class="col-lg-6">
            <app-title-card [isLoading]="isLoading" height="300px" [tooltipContent]="panelDescriptions['pageParamDistribution']">
                <ng-container header>
                    <span class="zmdi zmdi-chart title-icon"></span>
                    Page Parameter Distribution
                </ng-container>
                <div style="width: 100%;">
                    <ejs-chart height="250px" width="100%" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
                        [palettes]="chartPallete">
                        <e-series-collection>
                            <e-series [dataSource]="pageParamDistribution" xName="numParams" yName="pages" type="Bar"
                                name="Total Pages">
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
            </app-title-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <app-title-card [isLoading]="isLoading" height="700px" [tooltipContent]="panelDescriptions['fbAllParams']">
                <ng-container header>
                    <span class="zmdi zmdi-info-outline title-icon"></span>
                    All Parameters
                    <span>: {{FBDData?.length || 0}} Params</span>
                </ng-container>
                <ng-container headerToolbar>
                    <ejs-switch [(ngModel)]="showAdvanced" (change)="onAdvancedToggle()"></ejs-switch>
                </ng-container>
                <div class="flex-row" style="height:100%">
                    <ejs-grid height="100%" [dataSource]="FBDData" #FBDataGrid width="100%"
                        [groupSettings]="{ columns: ['category'],showDropArea: false }" [allowGrouping]="true"
                        [allowFiltering]="true" [allowResizing]="true">
                        <e-columns>
                            <e-column field="category" [allowFiltering]="false"></e-column>
                            <e-column field="param" headerText="Parameter" textAlign="left" [allowGrouping]='false'
                                isPrimaryKey='true' [allowFiltering]="false" [autoFit]="true">
                                <ng-template #template let-data>
                                    <ejs-tooltip
                                        [content]="data.name+ ': ' + (data.explanation || 'No Description Available')">
                                        <div style='font-family:monospace; font-size: medium;'>{{data.param}}</div>
                                    </ejs-tooltip>

                                </ng-template>
                            </e-column>
                            <e-column field="totalPages" headerText="Total Pages" textAlign="center" [autoFit]="true"
                                [allowGrouping]='false' [allowFiltering]="false">
                                <ng-template #template let-data>
                                    <div>
                                        {{data.totalPages}}
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field="totalRequests" headerText="Total Requests" textAlign="center"
                                [autoFit]="true" [allowGrouping]='false' [allowFiltering]="false">
                                <ng-template #template let-data>
                                    <div>
                                        {{data.totalRequests}}
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field="uniqueValues" headerText="Unique Values" textAlign="center"
                                [autoFit]="true" [allowGrouping]='false' [allowFiltering]="false">
                                <ng-template #template let-data>
                                    <div>
                                        {{data.uniqueValues}}
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field="value" headerText="Sample Value" textAlign="left" [allowGrouping]='false'
                                [allowFiltering]="false" minWidth="200px">
                                <ng-template #template let-data>
                                    <div>
                                        {{ data.value }}
                                    </div>
                                </ng-template>
                            </e-column>
                            <!-- <e-column field="apiName"></e-column> -->
                            <e-column field="name" [visible]="showAdvanced" [allowFiltering]="false"></e-column>
                            <e-column field="explanation" [visible]="showAdvanced" [allowFiltering]="false"
                                clipMode='EllipsisWithTooltip'></e-column>
                            <!-- <e-column field="associatedPixel" [allowFiltering]="true"></e-column> -->

                        </e-columns>
                        <ng-template #groupSettingsCaptionTemplate let-data>
                            <span style="font-weight: bold;">{{ data.key }} - {{data.count}}</span>
                        </ng-template>
                    </ejs-grid>
                </div>
            </app-title-card>
        </div>
    </div>

</div>