import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VaultNotificationService } from '../../../../services/notifications/vault-notification.service';

@Component({
  selector: 'custom-report-menu-item-editor',
  templateUrl: './custom-report-menu-item-editor.component.html',
  styleUrls: ['./custom-report-menu-item-editor.component.css']
})
export class CustomReportMenuItemEditorComponent implements OnInit {

  form:FormGroup;

  constructor(
    private fb:FormBuilder,
    private notificationService:VaultNotificationService
  ) { 
    this.form = this.fb.group({
      menuItems: ''
    })
  }

  ngOnInit() {
    let data = JSON.stringify(JSON.parse(localStorage.getItem("customReports")), null, 2);
    this.form.patchValue({"menuItems": data})
  }

  save(){
    console.log(this.form.value);
    localStorage.setItem("customReports", this.form.value.menuItems);
    try{
      let validJson = JSON.parse(this.form.value.menuItems);
      this.notificationService.success("Success", "Changes have been saved.")
    }catch(err){
      this.notificationService.error("Uh oh", err);
    }
    
  }

}
