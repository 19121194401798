<div class="jumbotron">
  <h1 class="text-center">Edit and Update Vendor List</h1>
</div>
<form [formGroup]="editVendor" (ngSubmit)="updateVendor()">
  <div class="form-group">
    <label>Vendor Name</label>
    <input type="text" class="form-control" placeholder="Enter Vendor Name" formControlName="name">
  </div>
  <div class="form-group">
    <label>Website</label>
    <input type="text" class="form-control" placeholder="Enter Vendor Website" formControlName="website">
  </div>
  <button class="btn-md btn-outline-primary" type="submit">Update</button>
</form>
