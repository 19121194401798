import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-title-card',
  templateUrl: './title-card.component.html',
  styleUrls: ['./title-card.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate('1500ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate('1500ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TitleCardComponent implements OnInit {
  @Input() loaderTemplate: string = 'default';
  @Input() isLoading: boolean = false;
  @Input() tooltipContent: string;
  @Input() height: string = '180px';
  @Input() hideInfoIcon: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
