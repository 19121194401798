import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReportingDictService,
  DictionaryObject,
} from '../../../services/reporting-dict/reporting-dict.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-report-dictionary-tools',
  templateUrl: './report-dictionary-tools.component.html',
  styleUrls: ['./report-dictionary-tools.component.css'],
})
export class ReportDictionaryToolsComponent implements OnInit {
  @ViewChild('grid') public grid: GridComponent;

  public allDictionaries: Observable<string[]>;
  public selectedDictionary: Observable<DictionaryObject[]>;
  public hasChanged: boolean = false;
  public editSettings: Object = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Dialog'
  };

  constructor(private reportingDictService: ReportingDictService) {}

  ngOnInit(): void {
    this.allDictionaries = this.reportingDictService.getDictList();
  }

  onSelectDictionaryWithCode($event: any): void {
    console.log($event);
    this.selectedDictionary = this.reportingDictService.getDictByCode(
      $event.itemData.value
    );
  }

  onSaveChanges(): void {
    console.log('Save Changes, ', this.selectedDictionary);
  }

  onActionComplete($event: any): void {
    console.log('Action Complete: ', $event);
    if ($event.requestType === 'save') {
      const data = $event.data as DictionaryObject;
      const previousField = $event.previousData.field;
      this.reportingDictService
        .updateEntryByCode('CTD', data, previousField)
        .subscribe((res) => {
          console.log('Updated Entry: ', res);
          this.selectedDictionary = this.reportingDictService.getDictByCode(
            'CTD'
          );
        });
    }
  }
}
