<div class="card card-default">
    <div class="card-header">Regional Summary</div>
    <div class="card-body" *ngIf="isLoaded; else stillLoading"
        style="border: 0px solid black; display: flex; flex-direction: row; justify-content: space-around;">

        <!-- <div class="col-10">
                <ejs-maps id='maps' #maps
                style="display:block;"
                [zoomSettings]='zoomSettings'
                [layers]='layers'
                [legendSettings]="mapLegendSettings"
                (shapeSelected)="shapeSelected($event)"
                (load)="mapsLoad($event)"
                (loaded)="loaded($event)"
                (tooltipRender)="tooltipMapRender($event)"
                format='n'
                useGroupingSeparator="true">
            </ejs-maps>
            </div> -->
        <div class="col-lg-8">
            <ejs-maps id='maps' #maps style="display:block;" [layers]="layers" [legendSettings]="legendSettings"
                style="padding: 0; margin: 0;" (tooltipRender)="tooltipMapRender($event)"
                >
            </ejs-maps>
        </div>
        <div class="col-lg">
            <div id="buttons"
                style="display: flex; flex-direction: column; gap:5px; padding: 10px;justify-content: center;">
                <!-- <div *ngFor="let button of buttonData;" style="height: 80px; width: 80px; border-radius: 0.25rem;
                    color: black;
                    box-shadow: 0px 0px 2px grey; padding: 10px; text-align: center; font-size: large; font-weight: bold; cursor: pointer; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 5px
                    "
                    [style.background]="'linear-gradient(to top,' + colorCutoff(button?.percent,5,50) + ' ' + button?.percent.toFixed(0) +'%, transparent '+ button?.percent.toFixed(0) +'%) no-repeat'"
                    (click)="showDialog = false; panelData=button; showDialog = true">{{button.type.replace("_"," ")}}
                </div> -->
                <ng-container *ngFor="let button of buttonData">

                    <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap;">
                        <div style="font-size: large; text-align: left;  width: 100%; font-weight: bold; margin: 0; padding: 0;">{{button.type}}</div>
                        <div style="position: relative; height: 15px; width: 60%; margin:0px ">
                            <div
                                style="position: absolute; background-color: lightgrey; height: 100%; padding: 0; width: 100%; border-radius: 1rem;">
                            </div>
                            <div style="position: absolute; height: 100%; padding: 0; border-radius: 1rem;"
                                [style.width]="button.percent <= 3 ? '3%' : button.percent +'%'"
                                [style.background-color]="colorCutoff(button.percent,5,50)"></div>
                        </div>
                        <div style="width: 40%; padding: 0px; font-size: medium; text-align: right; font-weight: bold;">
                            {{button.percent.toFixed(1)}}%</div>
                    </div>
                </ng-container>
            </div>
            <!-- <ng-template #noselect>

                <div style="padding: 0px; height: 50%; position: relative;"
                    (click)="showDialog = false">

                    <div
                        style="color: lightgrey; border-radius: 0.25rem; width: 100%; height: fit-content; padding:10px; position: absolute; text-align: center; bottom:0">

                        Select a violation to view details.
                    </div>

                </div>
            </ng-template> -->
            <!-- <div *ngIf="showDialog; else noselect" id="fixed-popup" style="padding: 2px; height: 50%;" (click)="showDialog = false">

                <div
                    style="color: black; border-radius: 0.25rem; box-shadow: 0px 0px 2px grey; width: 100%; height: 100%; padding:10px; position: relative;">

                    <div style="display: flex; flex-direction: row; font-size: x-large; font-weight: bold;">
                        <div style="flex-grow: 1; padding: auto;">{{panelData.type}}</div>
                        <div style="width: fit-content;" [style.color]="colorCutoff(panelData.percent,5,50)">
                            {{panelData.percent.toFixed(1)}}%
                        </div>
                    </div>
                    <br />
                    <div style="overflow-y: scroll;height: 75%; border-bottom: 0.25rem solid lightgrey;">
                        <ng-container *ngFor="let regionData of panelData.byRegion">

                            <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap;">
                                <div style="font-size: small; text-align: left;  width: 100%; font-weight: bold; margin: 0; padding: 0;">{{regionData.region}}</div>
                                <div style="position: relative; height: 5px; width: 60%; margin:0px ">
                                    <div
                                        style="position: absolute; background-color: lightgrey; height: 100%; padding: 0; width: 100%; border-radius: 1rem;">
                                    </div>
                                    <div style="position: absolute; height: 100%; padding: 0; border-radius: 1rem;"
                                        [style.width]="regionData.percent <= 3 ? '3%' : regionData.percent +'%'"
                                        [style.background-color]="colorCutoff(regionData.percent,5,50)"></div>
                                </div>
                                <div style="width: 40%; padding: 0px; font-size: small; text-align: right;">
                                    {{regionData.percent.toFixed(1)}}% ({{regionData.count}})</div>
                            </div>
                        </ng-container>
                    </div>
                    
                </div>

            </div> -->
        </div>

    </div>


    <!-- <div class="col" style="margin-left: -65px; padding-top: 175px;"> -->
    <!-- <button class="btn btn-space btn-secondary btn-big" style=" background: linear-gradient(transparent 50%, #FBB917 20%) no-repeat; pointer-events: none;" ><i class="icon"><img src="/assets/img/coppa.png" width="29" height="25"></i> COPPA </button> -->
    <!-- <button class="btn btn-space btn-secondary btn-big" style=" background: linear-gradient(transparent 27%, #C60D25 20%) no-repeat; pointer-events: none;" ><i class="icon zmdi zmdi-youtube-play" style="padding: 3px 6px 3px 6px !important"></i> VPPA </button> -->
    <!-- </div> -->
</div>

<ng-template #tooltipTemplate>
    <div
        style="width: 200px; height: 100px; background-color: white; border-radius: 5px; border: 1px solid black; padding: 5px;">
        <div style="font-weight: bold; font-size: 1.2em; margin-bottom: 5px;">continent</div>
        <div style="font-size: 1.2em; margin-bottom: 5px;">totalViolations Violations</div>
    </div>
</ng-template>

<ng-template #stillLoading>
    <div class="card-body">Loading...</div>
</ng-template>

