<div *ngIf="showExportButton" style="text-align: right">
    <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exportData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button>
</div>
<ejs-grid #codeFilesGrid 
  locale='en-US' 
  [allowPaging]="true" 
  [pageSettings]="pageSettings" 
  [allowSorting]="true" 
  [sortSettings]="sortSettings"
  [allowSelection]="false"
  [enableHover]="false"
  [toolbar]="toolbar"
  [allowResizing]="true"
  >
  <e-columns>
      <e-column field='name' headerText='Request Type'> <!--resource match name-->      
        <ng-template #template let-data>          
          <a [routerLink] = "['/vendors', vendor.id, 'request', data.id]">{{data.name}}</a>
        </ng-template>
      </e-column>
      <e-column field='domainMatcherName' headerText='Domain Group'></e-column>        
      <e-column field='description' headerText='Description'></e-column>        
      <e-column field='sum' headerText='Total' format="N"></e-column>
      <e-column field='firstFound' headerText='First Seen' type='datetime'>
        <ng-template #template let-data>{{ timezoneService.format(data.firstFound, false, false, true) }}</ng-template>
      </e-column>
      <e-column field='lastSeen' headerText='Last Seen' type='datetime'>
        <ng-template #template let-data>{{ timezoneService.format(data.lastSeen, false, false, true) }}</ng-template>
      </e-column>
  </e-columns>
</ejs-grid>