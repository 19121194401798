import { Injectable } from '@angular/core';
import {
  CrudOptions,
  DataOptions,
  DataResult,
  Query,
} from '@syncfusion/ej2-data';
import { VaultBaseUrlAdaptor } from '../../../services/VaultBaseUrlAdaptor';
import { TimezoneService } from '../../../services/timezone.service';
import { DataManager } from '@syncfusion/ej2-data';
@Injectable({
  providedIn: 'root',
})
export class ReportDataAdaptor extends VaultBaseUrlAdaptor {
  public isFilterSearch: boolean = false;
  public isDistinct: boolean = false;
  public distinctColumns: string = ""
  constructor(private timezoneService: TimezoneService) {
    super();
  }

  override processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Object,
    changes?: CrudOptions
  ): DataResult {
    console.log('processResponse');
    console.log(data, ds, query, xhr, request, changes);
    return data;
  }
  override processQuery(
    dm: DataManager,
    query: Query,
    hierarchyFilters?: Object[]
  ): Object {
    console.log(dm, query, hierarchyFilters);
    let q = super.processQuery(dm, query, hierarchyFilters) as any;
    console.log('q', q);
    if(this.isDistinct){
        let queryObj = JSON.parse(q.data);
        console.log('Column Name', this.distinctColumns);
        queryObj["distinct"] = true;
        queryObj["distinctColumns"] = this.distinctColumns;
        q.data = JSON.stringify(queryObj);
        console.log('q.data', q.data);
        this.resetDistinct();
    }

    // let queryObj = JSON.parse(q.data);
    // console.log("queryObj", queryObj);
    // queryObj["requestedDataType"] = "hashes"
    // console.log("after queryObj", queryObj);
    // q.data = JSON.stringify(queryObj);

    return q;
  }

  override beforeSend(dm: DataManager, request: XMLHttpRequest): void {
    console.log('beforeSend in report data adaptor');
    // if (this.isDistinct) {
    // console.log('beforeSend in report data adaptor isDistinct');
    //   const requestBody = request.getResponseHeader('body');
    //   if (requestBody) {
    //     const body = JSON.parse(requestBody);
    //     body.distinct = true;
    //     request.setRequestHeader('body', JSON.stringify(body));
    //   }
    // }
    // console.log('Request BeforeSend in Report Data Adaptor', request);
    // this.resetDistinct();
    return super.beforeSend(dm, request);
  }

  private resetDistinct() {
    this.isDistinct = false;
  }
}
