<div id="dashboardContainerForCanvas">
<div class="row">
    <div class="col-lg-6">
        <app-minified-value-panel label="Unique Vendors" [bgColor]="'#4d545c'" [textColor]="'white'"
            [value]="uniqueVendors"></app-minified-value-panel>
    </div>
    <div class="col-lg-6">
        <app-minified-value-panel label="Unique Domains" [bgColor]="'#4d545c'" [textColor]="'white'"
            [value]="uniqueDomains"></app-minified-value-panel>
    </div>
</div>
<div class="row">
    <div class="col-lg-3">
        <app-minified-value-panel label="Unique Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
    <div class="col-lg-3">
        <app-minified-value-panel label="Unique Cookie Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueCookieTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
    <div class="col-lg-3">
        <app-minified-value-panel label=" Unique Data Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueDataTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
    <div class="col-lg-3">
        <app-minified-value-panel label="Unique Code Trackers" [bgColor]="'#e6e8eb'" [textColor]="'#000000'"
            [value]="uniqueCodeTrackers" [isVertical]="true"></app-minified-value-panel>
    </div>
</div>
<div class="row" *ngFor="let d of domainGroupedData; index as i">
    <div class="col-lg-12">
        <div class="card" style="gap:5px; display: flex; flex-direction: column;">
            <div style="display: flex; gap: 5px;">
                <div class="metric-two-tone">
                    <div class="label">DOMAIN:</div>
                    <div class="value"> {{d.name || 'Unknown'}}</div>
                </div>
                <div class="metric-two-tone" style="margin-right:10px">
                    <div class="label">VENDORS:</div>
                    <div class="value"> {{d.vendorCount}}</div>
                </div>
            </div>

            <div style="display: flex; gap: 5px">

            </div>
            <div class="row">


                <div class="col-lg-7">
                    <ejs-grid [dataSource]="d.vendorDetails" height="400px"  [toolbar]="['Search']" [allowSorting]="true">

                        <e-columns>
                            <e-column width="60px">
                                <ng-template #template let-data>
                                        <img width="30px"
                                            src="https://{{cdnUrl}}/vendors/icon/{{ data.website ? data.website.toLowerCase() : 'unknown' }}.ico">
                                </ng-template>
                            </e-column>
                            <e-column field="name" headerText="Vendor" textAlign="left" >
                                <ng-template #template let-data>
                                    <div class="vendor-buttons" (click)="goToVendorDetails(data.name)">
                                        <div class="label">{{data.name}}</div>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field="totalTrackers" headerText="Tracker Requests" textAlign="left"></e-column>
                            <e-column field="categories" headerText="Categories" textAlign="left">
                                <ng-template #template let-data>
                                    <div style="display: flex; gap: 5px; justify-content: flex-start; width: 100%;">
                                        <div *ngFor="let cat of data.categories">
                                            <ejs-tooltip [content]="cat">
                                                <ng-container [ngSwitch]="cat">
                                                    <div (click)="goToCategoryDetails(cat,d.name, data.name)"
                                                        class='cat-icons'>
                                                        <img width="85%" *ngSwitchCase="'Cookie Tracker'"
                                                            src="assets/svg/cookie-tracker.svg">
                                                        <img width="85%" *ngSwitchCase="'Data Tracker'"
                                                            src="assets/svg/data-tracker.svg">
                                                        <img width="100%" *ngSwitchCase="'Code Tracker'"
                                                            src="assets/svg/code-tracker.svg">
                                                    </div>
                                                </ng-container>
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>

                    </ejs-grid>
                </div>
                <div class="col-lg-5" style="height: 100%; display: flex; flex-direction: column; justify-content: flex-end;" *ngIf="isLoaded">
                    <div class="card-header" style="margin:0px 0px 20px 0px;padding:10px; text-align: center;width: 100%;">Tracker Vendor Contribution</div>
                    <div style="position: relative">
                    <multi-pie-chart [datasource]="d.pieData" [id]="'trackerPie'+(i+1)" [limit]="true"></multi-pie-chart>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>