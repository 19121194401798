<div class="row">
    <div class="col-md-12">
      <div class="card card-table card-border">
        <div class="card-header">
          <span class="title">Advanced Event Rule Details</span>
          <span class="card-subtitle">Edit/Create an advanced event rule below.</span>                  
        </div>
        <div class="card-body">
          <form [formGroup]="ruleForm">
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input id="ruleNameTxt" class="form-control form-control-sm" type="text" formControlName="name">
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Type</label>
              <div class="row col-8">
                  <div class="col-12">
                    <ejs-combobox id='eventTypesUI' #eventTypesUI 
                      [dataSource]='eventTypes' 
                      [fields]='eventFields' 
                      formControlName="eventTypeId"
                      placeholder='Select an event'
                      allowFiltering='true'
                      allowCustom='false'
                      filterType='contains'
                      width="300px"
                      [enabled]="eventTypeEnabled"
                      (valueChange)="eventChange($event)"
                      >
                    </ejs-combobox>
                  </div>     
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Enabled:&nbsp;<span class="icon zmdi zmdi-info" title="Help" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="If disabled, this rule will not be processed."></span></label>
              <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                <label class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" id="enabled" formControlName="enabled"><span class="custom-control-label"></span>
                </label>
              </div>                
            </div>              
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Filter</label>
              <div class="row col-8">
                <div class="col-12">
                  <ngx-codemirror cols="100" rows="4" id="filterConfig" formControlName="filterConfig" [options]="codeMirrorOptions"></ngx-codemirror>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Condition</label>
              <div class="row col-8">
                <div class="col-12">
                  <ngx-codemirror cols="100" rows="4" id="conditionCode" formControlName="conditionCode" [options]="codeMirrorOptions"></ngx-codemirror>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Action Code</label>
              <div class="row col-8">
                <div class="col-12">
                  <ngx-codemirror cols="100" rows="4" id="actionCode" formControlName="actionCode" [options]="codeMirrorOptions"></ngx-codemirror>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Action Config</label>
              <div class="row col-8">
                <div class="col-12">
                  <ngx-codemirror cols="100" rows="4" id="actionConfig" formControlName="actionConfig" [options]="codeMirrorOptions"></ngx-codemirror>
                </div>
              </div>
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Destination</label>
              <div class="row col-8">
                  <div class="col-12">
                    <ejs-combobox id='destination' #destination
                      [dataSource]='eventDestinationTypes' 
                      [fields]='fields' 
                      [(value)]='selectedDestination' 
                      placeholder='Select a destination'
                      allowFiltering='true'
                      allowCustom='false'
                      filterType='contains'
                      width="300px"
                      [enabled]="eventTypeEnabled">
                    </ejs-combobox>
                  </div>     
              </div>        
            </div>
            <div class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="row col-8">
                <div class="col-12">
                  <div *ngIf="selectedDestination != 1 && selectedDestination != 3 && selectedDestination != null">
                    {{todoMessage}}
                  </div>
                  <div *ngIf="selectedDestination == 1">
                    <email-output #email [data]="outputData" [eventType]="selectedEventTypeName" [isNewRule]="isNewRule"></email-output>
                  </div>
                  <div *ngIf="selectedDestination == 3">
                    <webhook-output #webhook [data]="outputData" [eventType]="selectedEventTypeName" [isNewRule]="isNewRule"></webhook-output>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div style="text-align: center">
            <button class="btn btn-rounded btn-space btn-secondary" type="button" (click)='cancel();' >Cancel</button>
            <button class="btn btn-rounded btn-space btn-success" type="button" (click)="save();">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>