import { Component, OnInit } from '@angular/core';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-vppa-report',
  templateUrl: './vppa-report.component.html',
  styleUrls: ['./vppa-report.component.css'],
})
export class VppaReportComponent implements OnInit {
  constructor(
    private dataExportService: DataExportService,
    private authService: AuthService,
    private papa: Papa
  ) {}

  ngOnInit(): void {
    this.LoadReportData();
  }
  public data = [];

  public scanRecency = [];
  public selectedPage = null;

  public dataByPage = [];
  public bucketDataByVendor = [];
  public bucketWidth = 200;

  private getScanRecency(_data) {
    const last7Days = [];
    const last30Days = [];
    const last90Days = [];
    _data.forEach((item) => {
      const date = new Date(item.lastChecked);
      date.setHours(0, 0, 0, 0);

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const diffTime = Math.abs(today.getDate() - date.getDate());
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 7) {
        last7Days.push(item);
      } else if (diffDays <= 30) {
        last30Days.push(item);
      } else {
        last90Days.push(item);
      }
    });
    const data = [
      {
        label: 'Last 7 Days',
        value: last7Days.length || 0,
        color: '#6390ee',
        children: [],
      },
      {
        label: 'Last 30 Days',
        value: last30Days.length || 0,
        color: '#00dbff',
        children: [],
      },
      {
        label: 'Over 30 Days',
        value: last90Days.length || 0,
        color: '#4d545c',
        children: [],
      },
    ];
    return data;
  }

  private getBucketsData(_data) {
    const getPagesWithTracker = (technology) => {
      return _data.filter((item) => {
        const findVendor = item.riskyVendors.find((vendor) => {
          return vendor.name === technology;
        });
        if (findVendor) {
          return true;
        }
        return false;
      });
    };
    const getPagesWithVideoPlayer = (pages) => {
      return pages.filter((item) => {
        return item.videoPlayers.length > 0;
      });
    };
    const techArray = ['Google Tag Manager', 'Google Analytics', 'Meta Pixel'];
    const data = techArray.map((item) => {
      const temp = getPagesWithTracker(item);
      const temp2 = getPagesWithVideoPlayer(temp);
      return {
        label: item,
        total: _data.length,
        trackerCount: temp.length,
        videoCount: temp2.length,
        color: '#6390ee',
      };
    });
    console.log('BUCKET DATA: ', data);
    return data;
  }

  public totalVideoPlayerPages = 0;
  private getChartData(tempData) {
    const _data = tempData.filter((item) => {
      return item.videoPlayers.length > 0;
    });
    const videoPlayers = _data.filter((item) => {
      return item.videoPlayers.length > 0;
    });
    this.totalVideoPlayerPages = videoPlayers.length;
    const vimeoPlayers = videoPlayers.filter((item) => {
      return item.videoPlayers.find((player) => {
        return player.technology === 'Vimeo';
      });
    });
    const youtubePlayers = videoPlayers.filter((item) => {
      return item.videoPlayers.find((player) => {
        return player.technology === 'YouTube';
      });
    });
    const otherPlayers = videoPlayers.filter((item) => {
      return item.videoPlayers.find((player) => {
        return player.technology !== 'YouTube' && player.technology !== 'Vimeo';
      });
    });
    const vimeoTrackers = vimeoPlayers.filter((item) => {
      return item.riskyVendors.length > 0;
    });
    const youtubeTrackers = youtubePlayers.filter((item) => {
      return item.riskyVendors.length > 0;
    });
    const otherTrackers = otherPlayers.filter((item) => {
      return item.riskyVendors.length > 0;
    });
    const noVimeoTrackers = vimeoPlayers.length - vimeoTrackers.length;
    const noYoutubeTrackers = youtubePlayers.length - youtubeTrackers.length;
    const noOtherTrackers = otherPlayers.length - otherTrackers.length;
    const data = [
      {
        label: 'Vimeo Player',
        value: vimeoPlayers.length,
        color: '#00dbff',
        children: [
          {
            label: 'Vimeo w/ Trackers',
            value: vimeoTrackers.length,
            color: '#4d545c',
            children: [],
          },
          {
            label: 'No Tracker (Vimeo)',
            value: noVimeoTrackers,
            color: '#40ebc2',
            children: [],
          },
        ],
      },
      {
        label: 'Youtube Player',
        value: youtubePlayers.length,
        color: '#6390ee',
        children: [
          {
            label: 'Youtube w/ Trackers',
            value: youtubeTrackers.length,
            color: '#4d545c',
            children: [],
          },
          {
            label: 'No Tracker (Youtube)',
            value: noYoutubeTrackers,
            color: '#40ebc2',
            children: [],
          },
        ],
      },
      {
        label: 'Misc. Player',
        value: otherPlayers.length,
        color: '#e5ebe8',
        children: [
          {
            label: 'Misc. Player w/ Trackers',
            value: otherPlayers.length,
            color: '#4d545c',
            children: [],
          },
          {
            label: 'No Tracker (Misc.)',
            value: noOtherTrackers,
            color: '#40ebc2',
            children: [],
          },
        ],
      },
    ];
    return data;
  }

  private groupByTech(_data) {
    const dataByVendor = [];
    _data.forEach((element) => {
      const findVendor = dataByVendor.find((item) => {
        return item.name === element.technology;
      });
      if (findVendor) {
        findVendor.urls.push(element.url);
      } else {
        const newVendor = {
          name: element.technology,
          urls: [element.url],
          vendor: element.vendor,
        };
        dataByVendor.push(newVendor);
      }
      // console.log('DATA BY VENDOR', dataByVendor);
    });
    return dataByVendor;
  }
  private techCheck(url, vendor) {
    if (url.indexOf('youtube.com/embed') > -1) {
      return 'YouTube';
    }
    if (url.indexOf('player.vimeo.com') > -1) {
      return 'Vimeo';
    }
    if (url.indexOf('google-analytics') > -1) {
      return 'Google Analytics';
    }
    if (url.indexOf('facebook.com/tr') > -1) {
      return 'Meta Pixel';
    }
    if (url.indexOf('gtm.') > -1) {
      return 'Google Tag Manager';
    }
    return vendor;
  }
  public onSelectedPageChange(event) {
    console.log('SELECTED PAGE: ', event);
    this.selectedPage = event.data;
    this.selectedPage.trackerData = this.loadDataCollection(this.selectedPage);
  }
  private loadDataCollection(_data) {
    // for the selected data
    const trackerData = [];
    const videoData = [];
    let counter = 0;
    _data.riskyVendors.forEach((item) => {
      item.urls.forEach((url) => {
          trackerData.push({
            url: url,
            urlTrimmed: url.split('?')[0],
            params: [],
          });
          let findTrackerURLIndex = counter;
        let parsedURL = null;
        try {
          parsedURL = new URL(url);
          parsedURL.searchParams.forEach((value, key) => {
            trackerData[findTrackerURLIndex].params.push({
              key: key,
              value: value,
            });
          });
        } catch (e) {
          console.log('URL PARSE ERROR: ', url);
        }
        counter++;

      });
    });
    //srot inside tracker data based on length of keyvalue string
    trackerData.forEach((item) => {
      item.params.sort((a, b) => {
        return (b.key + b.value).length - (a.key + a.value).length;
      });
    });
    console.log('TRACKER DATA: ', trackerData);
    return trackerData;
  }
  private groupByPage(_data) {
    const dataByPage = [];
    _data.forEach((element) => {
      const findPage = dataByPage.find((item) => {
        return item['page'] === element['Page URL'];
      });
      console.log(findPage);
      if (findPage) {
        switch (element['Technology']) {
          case 'VideoPlayer':
            findPage.videoPlayers.push({
              url: element['Request URL'],
              vendor: element['Vendor'],
              technology: this.techCheck(
                element['Request URL'],
                element['Vendor']
              ),
            });
            break;
          case 'Tracker':
            findPage.riskyVendors.push({
              url: element['Request URL'],
              vendor: element['Vendor'],
              technology: this.techCheck(
                element['Request URL'],
                element['Vendor']
              ),
            });
            break;
          default:
            break;
        }
      } else {
        const newPage = {
          page: element['Page URL'],
          linkId: element['LinkID'],
          lastChecked: element['Timestamp'],
          videoPlayers: [],
          riskyVendors: [],
        };
        switch (element['Technology']) {
          case 'VideoPlayer':
            newPage.videoPlayers.push({
              url: element['Request URL'],
              vendor: element['Vendor'],
              technology: this.techCheck(
                element['Request URL'],
                element['Vendor']
              ),
            });
            break;
          case 'Tracker':
            newPage.riskyVendors.push({
              url: element['Request URL'],
              vendor: element['Vendor'],
              technology: this.techCheck(
                element['Request URL'],
                element['Vendor']
              ),
            });
            break;
          default:
            break;
        }
        dataByPage.push(newPage);
      }
    });
    return dataByPage;
  }
  private LoadReportData() {
    const cid = parseInt(this.authService.customerId());
    this.dataExportService.getVPPAReport(cid).subscribe((data) => {
      this.papa.parse(data.toString(), {
        header: true,
        skipEmptyLines: true,
        complete: (results, file) => {
          console.log('Parsed: ', results, file);
          const temp = this.groupByPage(results.data);
          this.dataByPage = temp.map((item) => {
            return {
              page: item.page,
              linkId: item.linkId,
              lastChecked: item.lastChecked,
              videoPlayers: this.groupByTech(item.videoPlayers).sort((a, b) => {
                return b.urls.length - a.urls.length;
              }),
              playerCount: item.videoPlayers.length,
              riskyVendors: this.groupByTech(item.riskyVendors).sort((a, b) => {
                return b.urls.length - a.urls.length;
              }),
              trackerCount: item.riskyVendors.length,
            };
          });
          this.data = this.getChartData(temp);
          this.scanRecency = this.getScanRecency(temp);
          this.bucketDataByVendor = this.getBucketsData(this.dataByPage);
          // this.selectedPage = this.dataByPage[0];
          // this.selectedPage.trackerData = this.loadDataCollection(this.selectedPage);
        },
      });

      // this.dataByPage = data;
    });
  }
}
