import { Component, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SitemapService } from '../../../services/sitemap/sitemap.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { ProgressBarService } from '../../../services/progressBarService/progress-bar.service';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';
declare var BeagleApp:any;
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements AfterViewInit {
  
  pageTitle:string;
  sectionTitle:string;
  showCrumbs:boolean;
  hideHeader:boolean;
  
  constructor(
    private route:ActivatedRoute, 
    public sitemapService:SitemapService, 
    public spinnerService:SpinnerService,
    public progressBarService:ProgressBarService,
    public dashboardFileSelectorService:DashboardFileSelectorService)
  {    
    sitemapService.update(route);
    this.showCrumbs = false;
  }

  ngAfterViewInit() {    
    try {
      (<any> BeagleApp).init();
    } catch(beagleError) {
      
    }
  }
  dashboardSelector(event) {
    console.log('Root Log: ', event);
    this.dashboardFileSelectorService.defaultFile = event;
    this.dashboardFileSelectorService.selectionHandler(event);
  }
}