<ejs-grid #dnsGrid 
  locale='en-US' 
  [allowPaging]="true" 
  [pageSettings]="pageSettings" 
  [allowSorting]="true" 
  [sortSettings]="sortSettings"
  [allowSelection]="false"
  [enableHover]="false"
  [toolbar]="toolbar"
  [allowResizing]="true"
  >
  <e-columns>
    <e-column field='Date' headerText='Date' type='datetime'>
      <ng-template #template let-data>{{ timezoneService.format(data.Date) }}</ng-template>
    </e-column>
    <e-column field='type' headerText='Type'></e-column>
    <e-column field='data' headerText='Value'></e-column>
  </e-columns>
</ejs-grid>