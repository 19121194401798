<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-divider">{{headerText}}<span class="card-subtitle">{{headerSubText}}</span></div>
      <div class="card-body">
        <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">  
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" type="text" formControlName="name">
            </div>
          </div>
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Description</label>
            <div class="col-12 col-sm-8 col-lg-6">
              <textarea cols="100" rows="4" class="form-control" id="description" formControlName="description"></textarea>
            </div>
          </div>
          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Is Athena Query?:</label>
            <div class="col-12 col-sm-8 col-lg-6">
              <label class="custom-control custom-checkbox custom-control-inline">
                <input class="custom-control-input" type="checkbox" name="isAthenaQuery" formControlName="isAthenaQuery"><span class="custom-control-label"></span>
              </label>
            </div>
          </div>

          <!-- <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Schedule:</label>
            <div class="col-12 col-sm-8 col-lg-6">
              <label>
                <ejs-recurrenceeditor #recurrenceObj id='RecurrenceEditor' [selectedType]='selectedType'(change)="onChange($event)"></ejs-recurrenceeditor>
              </label>
            </div>
          </div> -->

          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Schedule</label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" type="text" formControlName="cron" placeholder="Enter cron string for schedule">
            </div>
          </div>

          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Schedule Enabled:</label>
            <div class="col-12 col-sm-8 col-lg-6">
              <label class="custom-control custom-checkbox custom-control-inline">
                <input class="custom-control-input" type="checkbox" name="cronEnabled" formControlName="cronEnabled"><span class="custom-control-label"></span>
              </label>
            </div>
          </div>

          <div class="form-group row pt-1">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Query</label>
            <div class="col-12 col-sm-8 codemirrorFix">
              <ngx-codemirror #queryEditor id="query" [(ngModel)]="sqlQueryModel" [ngModelOptions]="{standalone: true}"  [options]="codeMirrorOptions"></ngx-codemirror>
            </div>
          </div>  
          <div style="text-align: center">
            <button class="btn btn-rounded btn-space btn-warning" type="button" (click)="cancel();">Cancel</button>
            <button type="submit" class="btn btn-rounded btn-space btn-primary" [disabled]="!queryForm.valid">{{btnText}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>