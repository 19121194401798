<div class="row">
  <div class="col-12 col-lg-12">
      <div class="card card-table card-border be-loading" [class.be-loading-active]="isLoading">
        <div class="card-header">{{alert?.title}}</div>
        <div class="card-body">
          <div class="tab-container">
            <ul role="tablist" class="nav nav-tabs">
              <li class="nav-item"><a href="#details" data-toggle="tab" role="tab" class="nav-link active"><span class="icon zmdi zmdi-info"></span>Alert Details</a></li>                        
            </ul>
            <div class="tab-content">
              <div id="details" role="tabpanel" class="tab-pane active" *ngIf="!isLoading">
                <div>
                  <p>Changes were detected for the following {{alert.details.data.length}} files: </p>
                  <div class="table-responsive noSwipe" style="height: 600px;">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Vendor</th>
                          <th>Event</th>
                          <th>Event Count</th>
                          <th>Date</th>
                          <th>File Name</th>
                        </tr>
                      </thead>
                      <tbody>
                    <ng-container *ngFor="let item of alert.details.data">
                      <tr>
                        <td>{{item.vendor}}</td><td>{{item.event}}</td><td>{{item.eventCount}}</td><td>{{item.date | date: 'M/d/yyyy, h:mm:ss a'}}</td><td><a [routerLink]="['/vendors', item.vendor, 'code', item.fileId]"><span [ngbTooltip]="item.fileName" container="body">{{truncateFileName(item.fileName)}}</span></a></td>
                      </tr>
                    </ng-container>
                  </tbody>
                  </table>
                  </div>
                </div>
              </div>
              <div id="analysis" role="tabpanel" class="tab-pane">
                <p>Found on 10 pages:</p>
                <p>XX% of pages</p>
                <p>Page 1</p>
                <p>Page 2</p>
                <p>Page 3</p>
                <p>Page 4</p>
              </div>
              <div id="actions" role="tabpanel" class="tab-pane">
                <p>Escalation</p>
                <p>Integrations</p>
              </div>
            </div>         
          </div>
      </div>
      <!-- <div class="be-spinner">
        <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
        </svg>
      </div> -->
    </div>
  </div>
</div>