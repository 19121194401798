<div class="row">
    <div class="col-md-12">
        <div class="card card-table card-border">
            <div class="card-header">
                <div class="tools"></div>
                <span class="title">Request Details</span>
                <span class="card-subtitle">Select a hash to view code and analyses. Selecting two rows will display the code diff. Right click on a row to copy values to the clipboard.</span>
            </div>
            <div class="card-body">
                                           
                <div class="row col pt-5">
                    <div class="col-4"></div>
                    <div class="col-8 d-flex justify-content-center pt-2">
                        <div class="btn-group btn-space">
                            <button class="btn btn-primary" [class.active]="isPagesButtonActive" type="button" (click)="onPagesButtonGroupClick('Pages');">Pages</button>
                            <button class="btn btn-primary" [class.active]="!isPagesButtonActive"  type="button" (click)="onPagesButtonGroupClick('Data');">Data{{" (" + (extractedDS.length || 0) + ")"}}</button>
                        </div>
                    </div>
                    <div class="col-4">
                        <ejs-grid #hashGrid 
                            height="450px" 
                            [allowSorting]="false" 
                            [allowReordering]="false" 
                            [allowPaging]="true"
                            [allowResizing]='true' 
                            [allowFiltering]="false"
                            [filterSettings]="pageFilterSettings" 
                            [pageSettings]="pagingSettingsHashGrid"
                            (dataBound)="dataBound($event)" 
                            [allowSelection]="true"
                            [selectionSettings]="pagesSelectionSettings" 
                            (rowSelected)="pagesRowSelected($event)"
                            (rowDeselected)="pagesRowDeselected($event)"
                            (contextMenuClick)="hashContextMenuClick($event)"
                            (contextMenuOpen)="hashContextMenuOpen($event)"
                            [contextMenuItems]="hashContextMenuItem"
                            [dataSource]="hashDataManager" 
                            >
                            <e-columns>
                                <e-column field='formattedTimestamp' type="datetime" headerText='Date' allowSorting="true">
                                    <ng-template #template let-data>
                                        {{data.formattedTimestamp}}
                                    </ng-template>
                                </e-column>
                                <e-column field='responsehash' headerText='Hash' width="120px"></e-column>
                                <e-column headerText="Download" width="108px">
                                    <ng-template #template let-data>
                                        <div class="row justify-content-center">
                                        <a style="cursor: pointer;" class="nav-link" (click)="downloadFile($event, data.responsehash, ANALYSIS_TYPES.BEAUTIFIED)"><span class="icon zmdi zmdi-format-align-left"></span></a>
                                        <a style="cursor: pointer;" class="nav-link" (click)="downloadFile($event, data.responsehash, ANALYSIS_TYPES.RAW)"><span class="icon zmdi zmdi-code"></span></a>
                                    </div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>                        
                    </div>
                    <div class="col-8">
                        <ejs-grid #pagesGrid 
                            [dataSource]="requestsDataManager"
                            [hidden]="!isPagesButtonActive"  
                            [allowResizing]='true' 
                            [allowSorting]="false" 
                            [filterSettings]="pageFilterSettings" 
                            [allowPaging]="true" 
                            [pageSettings]="pagingSettingsPagesGrid"
                            [allowReordering]="true" height="450px" 
                            [allowFiltering]="false" 
                            width="auto"
                            [contextMenuItems]="pagesContextMenuItems"
                            (contextMenuClick)="pagesContextMenuClick($event)"
                            >
                            <e-columns>
                                <e-column field='starturl' headerText='Found on Page' width="100%"></e-column>
                                <e-column field='requesturl' headerText='Full Request' width="100%"></e-column>                                
                                <e-column field='responsehash' headerText='Hash' width="100%" [visible]="false"></e-column>
                            </e-columns>
                        </ejs-grid>
                        <ejs-grid 
                            #extractedGrid 
                            [hidden]="isPagesButtonActive"
                            [dataSource]="extractedDS"
                            height="450px"                             
                            [allowSorting]="true"
                            [allowReordering]="true" 
                            [allowGrouping]="true" 
                            [allowResizing]='true' 
                            [contextMenuItems]="extractedDsContextMenuItems"
                            (contextMenuClick)="extractedDsContextMenuClick($event)"
                            >
                            <e-columns>
                                <e-column field='key' headerText='Key'></e-column>
                                <e-column field='value' headerText='Value'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
                <div class="row col-12">
                    <div class="col-md-12 page-head-title">
                        <h2>Code</h2>
                    </div>
                </div>
                <div class="row col-12">
                    <div class="col pb-5">
                        <div class="tab-container">
                            <ul id="codeTabs" class="nav nav-tabs">
                                <li class="nav-item"><a id="beaut" href="#beaut" data-toggle="tab" role="tab"
                                        class="nav-link" [class.active]="isBeautificationTabActive"
                                        (click)="onTabClick($event);"><span
                                            class="icon zmdi zmdi-format-align-left"></span>Beautified<span
                                            class="icon zmdi zmdi-info tab-help" data-container="body"
                                            ngbPopover="Code that has been formatted to make it easier to read"
                                            triggers="mouseenter:mouseleave"></span></a></li>
                                <li class="nav-item"><a id="raw" href="#raw" data-toggle="tab" role="tab"
                                        class="nav-link" [class.active]="isRawTabActive"
                                        (click)="onTabClick($event);"><span
                                            class="icon zmdi zmdi-code"></span>Raw<span
                                            class="icon zmdi zmdi-info tab-help" data-container="body"
                                            ngbPopover="Raw code file without any modifications or formatting changes"
                                            triggers="mouseenter:mouseleave"></span></a></li>
                                <li class="nav-item"><a id="analysis" href="#analysis" data-toggle="tab" role="tab"
                                        class="nav-link" [class.active]="isAnalysisTabActive"
                                        (click)="onTabClick($event);"><span
                                            class="icon zmdi zmdi-functions"></span>Analysis<span
                                            class="icon zmdi zmdi-info tab-help" data-container="body"
                                            ngbPopover="Show the code analysis performed by Vault JS on a beautified version of the code. Highlighted sections are color coded to match the area of concern that has been detected."
                                            triggers="mouseenter:mouseleave"></span></a></li>
                                <li class="nav-item"><a id="ast" href="#ast" data-toggle="tab" role="tab"
                                        class="nav-link" [class.active]="isAstTabActive"
                                        (click)="onTabClick($event);"><span
                                            class="icon zmdi zmdi-flip-to-back"></span>AST<span
                                            class="icon zmdi zmdi-info tab-help" data-container="body"
                                            ngbPopover="View the AST (abstract syntax tree) for the code file."
                                            triggers="mouseenter:mouseleave"></span></a></li>                                
                                <li class="nav-item"><a id="retire" href="#retire" data-toggle="tab" role="tab"
                                        class="nav-link" [class.active]="isRetireActive"
                                        (click)="onTabClick($event);"><span
                                            class="icon zmdi zmdi-language-javascript"></span>Retire JS<span
                                            class="icon zmdi zmdi-info tab-help" data-container="body"
                                            ngbPopover="View results of processing with Retire JS"
                                            triggers="mouseenter:mouseleave"></span></a></li>                                
                                <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                                        (click)="next('start');"><span class="icon zmdi zmdi-fast-rewind"></span></a>
                                </li>
                                <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                                        (click)="next(-1);"><span class="icon zmdi zmdi-arrow-left"></span></a></li>
                                <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"
                                    style="margin-top: 10px;"><span>{{analysisPosition >= 0 && analysisPosition <
                                            this.analysisInfo.length ? (analysisPosition + 1) + "/" +
                                            this.analysisInfo.length : "" }}</span>
                                </li>
                                <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                                        (click)="next(1);"><span class="icon zmdi zmdi-arrow-right"></span></a></li>
                                <li *ngIf="isAnalysisTabActive && analysisInfo" class="nav-item"><a class="nav-link"
                                        (click)="next('end');"><span class="icon zmdi zmdi-fast-forward"></span></a>
                                </li>
                                <li *ngIf="isAnalysisTabActive && curSelectedRowIndices && curSelectedRowIndices.length == 2"
                                    class="nav-item"><a class="nav-link" target="_" [routerLink]="['/codeDiff']"
                                        [queryParams]="{h1:hash1, h2:hash2}"><span
                                            class="icon zmdi zmdi-fullscreen"></span></a></li>
                            </ul>
                        </div>
                        <div class="tab-content">
                            <ngx-codemirror *ngIf="!isDataActive && curSelectedRowIndices.length <= 1" #codeViewer
                                [(ngModel)]="codeFileContent" [options]="codeMirrorOptions"></ngx-codemirror>
                            <div *ngIf="!isDataActive && curSelectedRowIndices.length > 1"
                                [innerHtml]="outputHtml | noSanitizePipe"></div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>