import { CrudOptions, DataManager, DataOptions, DataResult, Query } from '@syncfusion/ej2-data';
import { VaultBaseUrlAdaptor } from '../../../services/VaultBaseUrlAdaptor';
import { TimezoneService } from '../../../services/timezone.service';
import { VendorReviewComponent } from './vendor-review.component';

export class VendorReviewAdaptor extends VaultBaseUrlAdaptor {

    constructor(
        private tzService: TimezoneService,
        private domainDetailComponent: VendorReviewComponent
    ) {
        super();
    }

    override processQuery(dm: DataManager, query: Query, hierarchyFilters?: Object[]): Object {
        console.log("processQuery", query, hierarchyFilters);

        let q = super.processQuery(dm, query, hierarchyFilters) as any;
        let queryObj = JSON.parse(q.data);

        queryObj.distinct = query.distincts[0];

        q.data = JSON.stringify(queryObj);

        return q;
    }

    override processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
        data.result = (data.result as any).map((item) => {
            let formattedFirstFound = this.tzService.format(Number(item.firstfound), false, true);
            let formattedLastSeen = this.tzService.format(Number(item.lastseen), false, true);
            item.libraryName = item.libraryName == "" ? "(No match)" : item.libraryName;
            item.domainName = item.domainName == "" ? "(No match)" : item.domainName;
            item.resourceName = item.resourceName == "" ? "(No match)" : item.resourceName;
            let customerNames = "";
            if (item.customerid) {
                customerNames = this.domainDetailComponent.customers.find((c) => { return c.id == item.customerid })?.name || "";
            }
            item.vendorName = this.domainDetailComponent.vendors.find(v => v.id == item.calculatedvendorid)?.name;
            item.vendorWebsite = this.domainDetailComponent.vendors.find(v => v.id == item.calculatedvendorid)?.website || 'unknown';
            item.customerIcon = this.domainDetailComponent.customers.find((c) => { return c.id == item.customerid })?.icon || 'unknown';
            return { ...item, formattedFirstFound: formattedFirstFound,formattedLastSeen: formattedLastSeen, customerNames: customerNames }
        });

        return data;
    }
}