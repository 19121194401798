<div>
    <div class="card" style="border: 0px solid red;">
        <div class="row">
            <div class="col-lg-6" style="text-align: center;">
                <h3>
                    <b>
                        {{occurences}}
                    </b>
                </h3>
            </div>
            <div class="col-lg-6" style="text-align: left; padding-top: 18px;">
                {{title}}
            </div>
        </div>
        <div clas="row" style="border: 0px solid red;">
            <div class="col-lg-12">
                <div>
                    <ejs-chart #chart [height]="chartHeight" [primaryXAxis]='_primaryXAxis'
                        [primaryYAxis]='_primaryYAxis' [chartArea]="_chartArea">
                        <e-series-collection>
                            <e-series *ngFor="let series of dataSeries;"
                            [dataSource]='series.series'
                            type='Line'
                            xName='x'
                            yName='y'
                            [name]='series.name'
                            [fill]="series.color"
                            [tooltip]="_tooltip"
                            [width]="_lineWidth"></e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
            </div>
        </div>
    </div>
</div>