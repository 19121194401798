import { Injectable } from '@angular/core';
import { Observable, of } from '../../../../node_modules/rxjs';
import { User } from '../../models/User';
import { environment as env } from "../../../environments/environment";
import { Role } from '../../models/Role';
import { VaultBaseService } from '../VaultBaseService';

@Injectable({
  providedIn: 'root'
})
export class UserService extends VaultBaseService {
  
  private static USERS:string = "USERS";
  private static ROLES:string = "ROLES";

  cache:{[type:string]:Observable<any>;} = {};

  public users:User[];

  public createUser(user:any) {
    const url = `${env.api.url}customer/user`;
    return this.authHttp.post(url, JSON.stringify(user));
  }

  public updateUser(user:any){
    const url = `${env.api.url}customer/user`;
    return this.authHttp.put(url, JSON.stringify(user));
  }

  public getUser(userId:string):Observable<User[]> {
    const cacheKey = `${UserService.USERS}:${userId}`;
    if (!this.cache[cacheKey]){
      var cleanedUserId = encodeURI(userId.replace("auth0|", ""));
      const url = `${env.api.url}customer/user/${cleanedUserId}`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getUsers(admins:boolean=false):Observable<User[]>{
    const cacheKey = `${UserService.USERS}:admins:${admins}`;
    if (!this.cache[cacheKey]){
      var url = `${env.api.url}customer/users`;
      if ( admins ){
        url += "?admins=true"
      }
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public getRoles():Observable<Role[]>{
    const cacheKey = `${UserService.ROLES}`;
    if (!this.cache[cacheKey]){
      const url = `${env.api.url}customer/roles`;
      this.cache[cacheKey] = this.makeHttpRequest(url);
    }
    return this.cache[cacheKey];
  }

  public deleteUser(userId:string):Observable<any>{
    const url = `${env.api.url}customer/user/${userId}`;
    return this.authHttp.delete(url);
  }

  public clearCache(){
    this.cache = {};
  }

  public clearUser(userId:string){
    const cacheKey = `${UserService.USERS}:${userId}`;
    console.log(Object.keys(this.cache));
    delete this.cache[cacheKey];
    console.log(Object.keys(this.cache));
  }
}
