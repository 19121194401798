<div class="row">
    <div class="col-lg-12">
        <div class="card" style="padding: 10px;">
            <div class="card-header"
                style="flex-grow: 1; display: flex; flex-direction: row; gap:10px; padding: 10px 0px; margin: 0px 0px 0px 10px ">
                <div style="object-fit: contain; width: 60px;margin-right: 10px"><img width="100%"
                        src='/assets/svg/{{reportIcon}}.svg'>
                </div>
                <div style="display: flex; gap: 10px; flex-direction: column; flex-grow: 1; justify-content: center;">
                    <div style="font-size: x-large;">
                        {{reportTitle}}</div>
                    <div *ngIf="!isCustomReport"
                        style="display: flex; flex-direction: row; gap: 10px; font-size: medium; margin: left">
                        <div>Region: {{selectedReport.config?.region | region}}</div>
                        <div>Date: {{selectedReport.timestamp}}</div>
                        <div>Other: {{selectedReport.other}}</div>
                    </div>
                </div>
                <div *ngIf="!isCustomReport">
                    <ejs-dropdownlist [dataSource]="allReportsList" [fields]="allReportsDropdownFields" width="300px"
                        (select)="onReportDropdownSelect($event)" [value]="selectedReport.name">
                        <ng-template #itemTemplate="" let-data="">
                            <!--set the value to itemTemplate property-->
                            <div style="display: flex; flex-direction: column; gap:2px;padding:10px; width: 100%;">
                                <span>{{data.other}}</span>
                                <span style="font-size: small">{{data.timestamp}} | {{data.config?.region |
                                    region}}</span>

                            </div>
                        </ng-template>
                        <ng-template #valueTemplate="" let-data="">
                            <!--set the value to valueTemplate property-->
                            <div style="display: flex; flex-direction: column; gap:5px;padding:10px; width: 100%">
                                <div>{{data.other}}</div>
                                <div style="font-size: small;color:grey">{{data.timestamp}} | {{data.config?.region |
                                    region}}</div>

                            </div>

                        </ng-template>
                    </ejs-dropdownlist>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!isCustomReport">
    <div class="col-lg-3">
        <div class="card" style="padding: 16px; display:flex; flex-direction: row;">
            <div class="toolbar" style="justify-content: space-around; flex-grow: 1">
                <div style="flex-grow:1">{{isComplexFilter ? 'Disable Complex Filtering': 'Enable Complex Filtering'}}
                </div>
                <ejs-switch #switch id="switch" [(ngModel)]="isComplexFilter" cssClass="bar-color"></ejs-switch>
            </div>
        </div>
    </div>
    <div class="col-lg-9">
        <div class="card" style="padding: 10px;display:flex; flex-direction: row;">
            <!-- <div style="flex-grow:1; display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px;">
                <div *ngFor="let filter of filterColumnList" class="filter-button">
                    {{filter}} <span class="zmdi zmdi-close" (click)="removeFilterByField(filter)"></span></div>
            </div> -->
            <div class="toolbar" style="flex-grow: 1;">
                <div style="flex-grow: 1">Selected View: {{this.selectedViewName || 'No View Selected'}}</div>
                <!-- <ejs-dropdownlist placeholder="Default View" [dataSource]="stateList" [value]="selectedState"
                    [fields]="stateListDropdownFields" (select)="onSelectView($event)" width="100%"></ejs-dropdownlist> -->
                <ejs-menu #viewMenu [items]="viewMenuItems" [fields]="localViewMenuFields"
                    (select)="onViewMenuClick($event)" (beforeOpen)="onBeforeOpen($event)"></ejs-menu>
                <!-- <button *ngIf="authService.isVaultUser()" (click)="openModal('#predefinedLibraryModal')">Persistent View
                    Settings</button> -->
            </div>
        </div>
    </div>
</div>
<div class="row" [hidden]="!(isComplexFilter || filterColumnList?.length > 0)">
    <div class="col-lg-12">
        <div class="card" style="padding: 10px;">
            <div class="card-header" style="padding:10px 0px; margin:5px;">
                Filter Settings
            </div>
            <div class="card-body" style=" margin:0px">
                <div [hidden]="!isComplexFilter" class="toolbar" style="margin: 5px 10px">
                    <button (click)="queryReload()">Hard Reload</button>
                </div>
                <div [hidden]="isComplexFilter"
                    style="flex-grow:1; display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px;">
                    <div *ngFor="let filter of filterColumnList" class="filter-button">
                        {{filter}} <span class="zmdi zmdi-close" (click)="removeFilterByField(filter)"></span></div>
                </div>
                <ejs-querybuilder [hidden]="!isComplexFilter" #filterQueryBuilder class="row" (change)="onQueryChange($event)"
                    width="100%" (actionBegin)="onActionBegin($event)"></ejs-querybuilder>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card" style="padding: 10px;">
            <ejs-tooltip #tooltip (beforeRender)="beforeRender($event)" target=".e-headertext">
                <ejs-grid #grid (actionComplete)="onActionComplete($event)" (actionBegin)="onActionBegin($event)"
                    (actionFailure)="onActionFailure($event)" (toolbarClick)="onToolbarClick($event)"
                    (rowSelected)="onRowSelected($event)">
                </ejs-grid>
            </ejs-tooltip>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <ejs-grid [dataSource]="selectedData" [allowTextWrap]="true">
                <e-columns>
                    <e-column field="field" [autoFit]="true">
                        <ng-template #template let-data>
                            <span style="font-weight: bold;">{{data.field}}</span>
                        </ng-template>
                    </e-column>
                    <e-column field="value">
                        <ng-template #template let-data>
                            <span style="white-space: pre-wrap;" *ngIf="!data.isJSON">{{data.value}}</span>
                            <span style="white-space: pre-wrap;" *ngIf="data.isJSON">{{data.value | json}}</span>

                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>

<div class="modal fade" id="predefinedLibraryModal" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 800px;">
            <div class="modal-header">
                Predefined Library
            </div>
            <div class="modal-body">
                <div style="display: flex;flex-direction: row; gap: 10px">
                    <input #newStateName type="text" placeholder="Create New State Model">
                    <button (click)="onAddS3State()"> Add to Library</button>
                </div>
                <ejs-listview [dataSource]="s3States" [fields]="s3StatesDropdownFields"></ejs-listview>
            </div>
            <div class="modal-footer">
                <div class="toolbar" style="margin: 0">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addLocalState" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 800px;">
            <div class="modal-header">
                Are you sure you want to add a new state to the local storage?
            </div>
            <div class="modal-body">
                <input #newLocalStateName type="text" placeholder="Add State Name">
            </div>
            <div class="modal-footer">
                <div class="toolbar" style="margin: 0">
                    <button (click)="onNewView()"> Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteLocalState" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 800px;">
            <div class="modal-header">
                Are you sure you want to delete the state from local storage?
            </div>
            <div class="modal-footer">
                <div class="toolbar" style="margin: 0">
                    <button (click)="onDeleteView()"> Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="clearLocalState" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 800px;">
            <div class="modal-header">
                Are you sure you want to clear the state?
            </div>
            <div class="modal-footer">
                <div class="toolbar" style="margin: 0">
                    <button (click)="onClearView()"> Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>