import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Vendor } from '../../../../models/Vendor';
import { VaultNotificationService } from '../../../../services/notifications/vault-notification.service';
import { SpinnerService } from '../../../../services/spinner/spinner.service';
import { VendorsService } from '../../../../services/vendors/vendors.service';
import { environment as env } from "../../../../../environments/environment";

@Component({
  selector: 'vendor-domains',
  templateUrl: './vendor-domains.component.html',
  styleUrls: ['./vendor-domains.component.css']
})
export class VendorDomainsComponent implements OnInit, AfterViewInit{
  listings: Vendor[];
  searchValue: string;
  isEditing:boolean;
  cdnUrl
  
  constructor( 
    private vendorService:VendorsService, 
    private spinnerService:SpinnerService,
    private notificationService:VaultNotificationService) { 
    this.isEditing = false;
    this.cdnUrl = env.cdn.url;
  }

  ngOnInit() {
    this.spinnerService.toggle(true);
    this.vendorService.getAllVendors().subscribe( (vendors:Vendor[]) => {
      this.listings = vendors;
      this.spinnerService.toggle(false);
    },
    (err)=>{
      this.spinnerService.toggle(false);
    });  
  }

  ngAfterViewInit(){
    this.vendorService.getAllVendors(true).subscribe( (vendors:Vendor[]) => {
      this.listings = vendors;
    }); 
  }
  
  onDeleteClicked(id):void{
    this.spinnerService.toggle(true);
    this.vendorService.deleteVendor(id).subscribe((res:Response)=>{
      this.vendorService.getAllVendors(true).subscribe((result)=>{
        this.spinnerService.toggle(false); 
        this.listings=result
      });
      console.log("successfully deleted");
    },
    (err)=>{
      this.spinnerService.toggle(false); 
    })    
  }

  onVendorClick(vendor):void{
    console.log(vendor);
    this.vendorService.selectedVendor = vendor;
  }

  reclassifyClick():void{
    this.spinnerService.toggle(true);
    this.vendorService.reclassifyCodeFiles().subscribe((response)=>{
      console.log(response);
      this.notificationService.success('Success', 'Reclassification job was started successfully.')
      this.spinnerService.toggle(false);
    },
    (error)=>{
      console.log(error);
      this.notificationService.error('Uh oh...', 'An unexpected error occurred...')
      this.spinnerService.toggle(false);
    });
  }
}