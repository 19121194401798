import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { GridModel } from '@syncfusion/ej2-angular-grids';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { AuthService } from '../../../services/auth/auth.service';
import { VaultNotificationService } from '../../../services/notifications/vault-notification.service';
import { FBDetailsService } from '../../../services/fb-details/fb-details.service';
import { QueriedReportingService } from '../../../services/queried-reporting/queried-reporting.service';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';
import { FBDetailsTable } from '../fb-details/FB-details-table';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ChangeDetectorRef } from '@angular/core';
import { ReportingLibraryService } from '../../../services/reporting-library/reporting-library.service';
import { PanelDescriptions } from './panelDescriptions';
@Component({
  selector: 'app-fb-site-details',
  templateUrl: './fb-site-details.component.html',
  styleUrls: ['./fb-site-details.component.css'],
})
export class FbSiteDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('FBDataGrid') FBDataGrid: GridComponent;
  @ViewChild('pixelDropDown') pixelDropDown: any;
  @ViewChild('pixelGrid') pixelGrid: GridComponent;
  reportDate: string;
  isLoaded: BehaviorSubject<boolean>;
  dataSource: any;
  pageValue = null;
  pageData: any[];
  domainData: any[];
  FBPagesScannedData: any;
  FBAvgLoadTimesData: any;
  ConsoleErrorData: any;
  PIDData: any[];
  pageParamDistribution: any;
  FBDColumns: any[];
  FBDChildGrid: GridModel;
  FBDData: any;
  baseData: any;
  FBAutoEvents: any;
  FBConsentValidation: any;
  configParamDistribution: any;

  FBDataColumns: any[];
  private simpleColumns: any[] = [
    { field: 'configOpt', headerText: 'Config Option', width: 100 },
    { field: 'value', headerText: 'Value', width: 100 },
  ];
  private advancedColumns: any[] = [
    { field: 'category', headerText: 'Category', width: 100 },
    { field: 'param', headerText: 'Param', width: 100 },
    { field: 'value', headerText: 'Value', width: 100 },
    { field: 'apiName', headerText: 'API Name', width: 100 },
    { field: 'name', headerText: 'Name', width: 100 },
    { field: 'explanation', headerText: 'Explanation', width: 100 },
  ];
  public panelDescriptions: any = PanelDescriptions;
  public isLoading: boolean = true;
  constructor(
    private spinnerService: SpinnerService,
    private dataExportService: DataExportService,
    private authService: AuthService,
    private notificationService: VaultNotificationService,
    public dashboardFileSelectorService: DashboardFileSelectorService,
    protected FBDetailsService: FBDetailsService,
    private queriedReportingService: QueriedReportingService,
    private cdr: ChangeDetectorRef,
    private reportingLibraryService: ReportingLibraryService
  ) {
    this.isLoaded = new BehaviorSubject<boolean>(false);

    this.dashboardFileSelectorService.toggle(true);
    this.dashboardFileSelectorService.setReportCode('VAR_META_SITE');
    this.FBPagesScannedData = {};
    this.FBAvgLoadTimesData = {};
    this.ConsoleErrorData = [];
    this.FBAutoEvents = {};
    this.FBDColumns = this.advancedColumns;
    this.chartPallete = this.generateMultiGradientColors(5, this.baseColors);
    this.comparableDataset = {
      FBPagesScannedData: {},
      PIDData: [],
      FBDData: [],
      pageParamDistribution: [],
      configParamDistribution: [],
    };
    this.scannedResults = [
      {
        label: 'Pages w/ FB Technology',
        value: 0,
        color: '#6390ee',
        children: [],
      },
      {
        label: 'Pages w/o FB Technology',
        value: 0,
        color: '#e5ebe8 ',
        children: [],
      },
    ];
  }
  // load report data from the server then do a bunch of filters for each panel
  private rawData: any;
  public scannedResults: any = [];
  public domainList: any[] = [];
  public domainListFields: any = { name: 'domain', value: 'domain' };

  public primaryXAxis: any = {
    valueType: 'Category',
    title: '# Parameters',
    titleStyle: { fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'light' },
    labelStyle: { fontFamily: 'Roboto', fontSize: '12px',  fontWeight: 'light'  },

  };

  public primaryYAxis: any = {
    labelStyle: {  fontFamily: 'Roboto', fontSize: '12px',  fontWeight: 'light'  },
  };
  public chartStyle: any = {
    width: '100%',
    height: '100%',
  };
  public chartPallete: string[] = [];
  ngOnInit(): void {
    this.dashboardFileSelectorService.setSelectionHandler(
      this.onReportNameSelected.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.dashboardFileSelectorService.toggle(false);
  }

  ngAfterViewInit(): void {
    this.FBDataGrid.filterSettings = { type: 'Menu' };
    console.log('FBDataGrid:', this.FBDataGrid.columns);
    ((this.FBDataGrid as GridComponent).columns[4] as any).filterTemplate =
      '<span></span>';
  }
  onAdvancedToggle(): void {
    this.FBDataGrid.refresh();
  }

  onDropdownValueChanged($event: any): void {
    console.log('Selected:', $event);
    this.selectedDomain = $event.itemData.value;
    this.loadData();
  }
  public showAdvanced: boolean = false;

  public selectedDomain: string;
  private selectedReportName: string;
  public loadData(): void {
    console.log('Loading data for:', this.selectedDomain);
    this.isLoading = true;

    this.queriedReportingService
      .getDataByPageUrl(
        this.selectedReportName,
        this.selectedDomain,
        'siteDomain'
      )
      .toPromise()
      .then((data) => {
        console.log('Raw data from domain: ', data);
        // this.rawData = data;
        this.initializeValues();
        this.parseData(data);
        // this.getReportsWithRelevantData();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  parseData(data: any[], isMain: boolean = true) {
    data.forEach((row) => {
      switch (row.type) {
        case 'PageScannedFBPercent': {
          if (isMain) {
            this.FBPagesScannedData[row.dataKey] = row.dataValue;
          } else {
            this.comparableDataset.FBPagesScannedData[row.dataKey] =
              row.dataValue;
          }
          break;
        }
        case 'FBPixelData': {
          const temp = this.splitDataKey(row.dataKey);
          const paramName = temp.name;
          const field = temp.field;
          if (field === null) break;
          const findIndex = isMain
            ? this.PIDData.findIndex((item) => item.id === paramName)
            : this.comparableDataset.PIDData.findIndex(
                (item) => item.id === paramName
              );
          if (findIndex === -1) {
            const newRow = {
              id: paramName,
              totalPages: 0,
              LDU: 0,
              SDK: 0,
              FBtracking: 0,
              PrivacySandbox: 0,
            };
            newRow[field] = row.dataValue;
            isMain
              ? this.PIDData.push(newRow)
              : this.comparableDataset.PIDData.push(newRow);
          } else {
            isMain
              ? (this.PIDData[findIndex][field] = row.dataValue)
              : (this.comparableDataset.PIDData[findIndex][field] =
                  row.dataValue);
          }
          break;
        }
        case 'ParamDistribution': {
          const temp = this.splitDataKey(row.dataKey);
          const paramName = temp.name;
          const field = temp.field;
          if(field === null) break;

          isMain
            ? this.pageParamDistribution.push({
                numParams: field,
                pages: row.dataValue,
              })
            : this.comparableDataset.pageParamDistribution.push({
                numParams: field,
                pages: row.dataValue,
              });
          break;
        }
        case 'FBConfigParamDistribution': {
          const temp = this.splitDataKey(row.dataKey);
          const paramName = temp.name;
          const field = temp.field;
          if(field === null) break;
          isMain
            ? this.configParamDistribution.push({
                numParams: field,
                pages: row.dataValue,
              })
            : this.comparableDataset.configParamDistribution.push({
                numParams: field,
                pages: row.dataValue,
              });
          break;
        }
        case 'FBData': {
          const temp = this.splitDataKey(row.dataKey);
          const paramName = temp.name;
          const field = temp.field;
          if (field === null) break;
          if (isMain) {
            const findIndex = this.FBDData.findIndex((item) => {
              return item.param === paramName;
            });
            if (findIndex !== -1) {
              this.FBDData[findIndex][field] = row.dataValue;
            } else {
              if (FBDetailsTable[paramName]) {
                const rowTable = FBDetailsTable[paramName];
                const newRow = {
                  category: rowTable.category,
                  param: rowTable.param,
                  apiName: rowTable.apiName,
                  name: rowTable.name,
                  explanation: rowTable.explanation,
                };
                newRow[field] = row.dataValue;
                this.FBDData.push(newRow);
              } else {
                const newRow = {
                  category: 'Uncategorized',
                  param: paramName,
                  apiName: 'N/A',
                  name: 'N/A',
                  explanation: 'N/A',
                };
                newRow[field] = row.dataValue;
                this.FBDData.push(newRow);
              }
            }
          }
          break;
        }
        default: {
          console.log('Unknown type:', row.type);
        }
      }
    });
    console.log('Post Processing Raw Data')
    if (isMain) {
      this.scannedResults = [
        {
          label: 'Pages w/ FB Technology',
          value: this.FBPagesScannedData.totalFBPages || 0,
          color: '#6390ee',
          children: [],
        },
        {
          label: 'Pages w/o FB Technology',
          value:
            this.FBPagesScannedData.totalPages -
              this.FBPagesScannedData.totalFBPages || 0,
          color: '#e5ebe8 ',
          children: [],
        },
      ];
      this.pageParamDistribution.sort((a, b) => a.numParams - b.numParams);
      this.PIDData = this.PIDData.map((row) => {
        // all values divide by total pages
        row.percent_LDU = row.LDU / row.totalPages;
        row.percent_SDK = row.SDK / row.totalPages;
        row.percent_FBtracking = row.FBtracking / row.totalPages;
        row.percent_PrivacySandbox = row.PrivacySandbox / row.totalPages;
        return row;
      });
    } else {
      this.comparableDataset.scannedResults = [
        {
          label: 'Pages w/ FB Technology',
          value: this.comparableDataset.FBPagesScannedData.totalFBPages,
          color: '#6390ee',
          children: [],
        },
        {
          label: 'Pages w/o FB Technology',
          value:
            this.comparableDataset.FBPagesScannedData.totalPages -
            this.comparableDataset.FBPagesScannedData.totalFBPages,
          color: '#e5ebe8 ',
          children: [],
        },
      ];
      this.comparableDataset.pageParamDistribution.sort(
        (a, b) => a.numParams - b.numParams
      );
      this.comparableDataset.PIDData = this.comparableDataset.PIDData.map(
        (row) => {
          // all values divide by total pages
          row.percent_LDU = row.LDU / row.totalPages;
          row.percent_SDK = row.SDK / row.totalPages;
          row.percent_FBtracking = row.FBtracking / row.totalPages;
          row.percent_PrivacySandbox = row.PrivacySandbox / row.totalPages;
          return row;
        }
      );
    }
    console.log('FBDATA:', this.FBDData);
    console.log('PIDData:', this.PIDData);
    console.log('pageParamDistribution:', this.pageParamDistribution);
    console.log('FBPagesScannedData:', this.FBPagesScannedData);
    this.spinnerService.toggle(false);
    this.pixelGrid.refresh();
  }

  public pixelDropDownList: any[] = [];
  initializeValues() {
    this.FBPagesScannedData = {};
    this.PIDData = [];
    this.FBDData = [];
    this.pageParamDistribution = [];
    this.configParamDistribution = [];
  }

  public onReportNameSelected(name: any): void {
    console.log('onReportNameSelected:', name);
    const temp = this.reportingLibraryService.extractReportDetails(name);
    if (temp && temp.timestamp) {
      this.reportDate = temp.timestamp;
    }
    this.queriedReportingService
      .getUniqueValues(name, 'siteDomain')
      .toPromise()
      .then((data) => {
        console.log('Domain list:', data);
        this.selectedReportName = name;
        this.domainList = data;
        this.selectedDomain = this.domainList[0];
        this.loadData();
      });
  }

  onPixelSelect($event: any): void {
    const pixel: any = $event.itemData.id;
    if (pixel === 'Clear Highlight') {
      this.FBDataGrid.clearFiltering();
      return;
    }
    console.log('Pixel:', pixel);
    this.FBDataGrid.filterByColumn('associatedPixel', 'contains', pixel);
  }
  public comparableReports: any = [];
  public getReportsWithRelevantData(): any {
    this.reportingLibraryService
      .getAvailableReportsByCode('VAR_META_SITE')
      .toPromise()
      .then((data: any) => {
        console.log('Available Reports for Relevent Data:', data);
        // skip current report
        const filtered = data.filter(
          (item) => item.name !== this.selectedReportName
        );
        // do the queried page logic for each report to see if it has data
        const promises = filtered.map((report) => {
          console.log('Checking:', report);
          return this.queriedReportingService
            .getDataByPageUrl(report.name, this.selectedDomain, 'siteDomain')
            .toPromise();
        });
        Promise.all(promises).then((values) => {
          console.log('Values:', values);
          const relevantReports = values.reduce(
            (accumulator: any, currentValue: any, index: any) => {
              if (currentValue.length > 0) {
                accumulator.push(filtered[index]);
              }
              return accumulator;
            },
            []
          );
          this.comparableReports = relevantReports;
          console.log('Relevant Reports:', relevantReports);
        });
        return data;
      });
  }

  public comparableDataset: any = [];
  public PIDDataCompare: any = {};
  public isComparing: boolean = false;
  public onCompareClick(data): void {
    this.comparableDataset = {
      FBPagesScannedData: {},
      PIDData: [],
      FBDData: [],
      pageParamDistribution: [],
    };
    this.queriedReportingService
      .getDataByPageUrl(data.name, this.selectedDomain, 'siteDomain')
      .toPromise()
      .then((data) => {
        this.parseData(data, false);
        this.comparableDataset.PIDData.forEach((row) => {
          this.PIDDataCompare[row.id] = row;
        });
        // if pixel does not exist in the current dataset, add it and mark it as new
        const temp = this.PIDData;
        Object.keys(this.PIDDataCompare).forEach((key) => {
          if (!temp.find((item) => item.id === key)) {
            this.PIDData.push({ ...this.PIDDataCompare[key], new: true });
          }
        });
        console.log('PIDData:', this.PIDData);
        this.pixelGrid.refresh();
        this.isComparing = true;
      });
  }
  private interpolateColor(color1, color2, factor) {
    let result = '#';
    for (let i = 0; i < 3; i++) {
      let value1 = parseInt(color1.substring(1 + i * 2, 3 + i * 2), 16);
      let value2 = parseInt(color2.substring(1 + i * 2, 3 + i * 2), 16);
      let value = Math.round(value1 + (value2 - value1) * factor).toString(16);
      result += ('0' + value).slice(-2); // Pad with zero if necessary
    }
    return result;
  }
  public baseColors = ['#6390ee', '#4d545c'];

  private generateMultiGradientColors(numItems, baseColors) {
    let colors = [];
    const numSections = baseColors.length - 1;
    if (numItems > 1) {
      for (let i = 0; i < numItems; i++) {
        const sectionLength = 1 / numSections;
        const position = (i / (numItems - 1)) * numSections; // Position in the gradient
        const index = Math.floor(position); // Determine which color to start from
        const factor = position - index; // Factor of interpolation within the section

        let color = this.interpolateColor(
          baseColors[index],
          baseColors[Math.min(index + 1, baseColors.length - 1)],
          factor
        );
        colors.push(color);
      }
    } else if (numItems === 1) {
      // Only one item, use the first color
      colors.push(baseColors[0]);
    }
    return colors;
  }

  private splitDataKey(dataKey: string): any {
    // split by . and the get the last element
    const split = dataKey.split('.');
    // join the other elements
    return {
      name: split.slice(0, split.length - 1).join('.'),
      field: split[split.length - 1],
    };
  }
}
