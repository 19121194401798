import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SpinnerService } from "../services/spinner/spinner.service";
import { Observable } from "rxjs";
import { Query } from "../models/Query";
import { tap } from "rxjs/operators";
import { QueryService } from "../services/query/query.service";


@Injectable()
export class QueryResolver implements Resolve<Query[]> {
    
    constructor( 
        private queryService:QueryService, 
        private spinnerService:SpinnerService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<Query[]> | Query[] {
        console.log("Query Resolver", route);
        const queryId = route.params["queryId"];
        if (queryId == "new" ) {
            var dummyQ = new Query("", "", "");
            dummyQ.id = -1;
            return [dummyQ];
        } else {
            this.spinnerService.toggleRoot(true);
            return this.queryService.getQuery(Number(queryId)).pipe(tap(()=>{
                this.spinnerService.toggleRoot(false);
            }));
        }
    }
}