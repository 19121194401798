import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { QueriesComponent } from './queries.component';
import { QueryDetailsComponent } from './admin/query-details/query-details.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GridModule
  ],
  declarations: [
    QueriesComponent,
    QueryDetailsComponent
  ],
  exports: [
    QueriesComponent,
    QueryDetailsComponent
  ]
})
export class QueryComponentsModule { }
