<div class="jumbotron">
  <h1 class="text-center">Add New Vendor</h1>
</div>
<form  #add="ngForm" (ngSubmit)="addVendor(add)"> 
  <div class = "form-group">
    <label>Vendor Name</label>
    <input type = "text" class="form-control" ngModel name="name">
  </div>
  <div class="form-group">
    <label>Website</label>
    <input type="text" class="form-control" ngModel name="website">
  </div>
  <div class="form-group">
    <button type="submit" class="btn-md btn-outline-primary">Add</button>
  </div>

</form>



  
