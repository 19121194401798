import { Component, Input, OnInit } from '@angular/core';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-hist-panel',
  templateUrl: './hist-panel.component.html',
  styleUrls: ['./hist-panel.component.css']
})
export class HistPanelComponent implements OnInit {
  @Input('title') title: string;
  @Input('yLabel') yLabel: string;
  @Input('data') dataSource: any;
  @Input('name') name: string;

  public primaryXAxis?: any;
  public primaryYAxis: any;
  public titleStyle: any;
  public legendSettings: any;
  public chartArea: any;
  public tooltip: any;
  public margin: any;

  constructor() {
    this.title = 'No Data Found';
    this.primaryXAxis = {
      // visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      },
      valueType: 'Category',
      labelIntersectAction: 'Rotate90',
      labelStyle: {
        fontFamily: 'Sans-Serif',
      },
      titleStyle: {
        fontFamily: 'Sans-Serif',
      }
    };
    this.primaryYAxis = {
      // visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      },
      labelStyle: {
        fontFamily: 'Sans-Serif',
      },
      titleStyle: {
        fontFamily: 'Sans-Serif',
      }
    };
    this.titleStyle = {
      fontFamily: 'Sans-Serif',
    };
    this.legendSettings = {
      textStyle: { fontFamily: 'Sans-Serif' }
    };
    this.chartArea = {
      border: { width: 0 },
      title: this.title
    };
    this.margin = {
      top: 25,
      right: 25,
      bottom: 25,
      left: 25
    };
  }

  ngOnInit(): void {
    this.chartArea.title = this.title;
    this.primaryYAxis.title = this.yLabel;

    this.tooltip = {
      enable: true,
      shared: true,
    };
  }

  ngOnChanges(): void {
    this.updateData();
  }

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    const point = args.series.dataSource[args.data.pointIndex];
    args.headerText = '';
    args.text = "<b>" + point.y + " pages collected " + point.x + " unique attributes" + "</b>";
    args.textStyle.fontFamily = 'Sans-Serif';
  }

  updateData(): void {
  }

}
