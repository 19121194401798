<div class="row">
    <div class="col-sm-12">
        <div class="card card-table card-border">
            <div class="card-header" style="display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                    <span class="title">Lists</span>
                    <span class="card-subtitle">Create and manage custom lists of key/value pairs that can be used
                        throughout the
                        system.</span>
                </div>
                <div>
                    <button class="control-buttons" [disabled]="!selectedList"
                        style="background-color: seagreen; font-size: medium;" type="button"
                        (click)="saveAllChanges()">Save
                        Changes</button>
                </div>

            </div>
            <div class="card-body" style="padding:25px">
                <div style="margin-bottom: 25px; display: flex; flex-direction: row; gap: 10px">
                    <div style="flex-shrink:1; width:400px;">
                        <ejs-combobox #dropdown [dataSource]='lists' (change)='onListChange($event)' [fields]='cbFields'
                            placeholder='Select a List or Create a New List' width="100%">
                        </ejs-combobox>
                    </div>
                    <button type="button" class="control-buttons" style="background-color: darkgray; width: 32px"
                        [disabled]="selectedList?.Name == null" (click)="reloadList()">
                        <span class="zmdi zmdi-refresh-sync"></span>
                    </button>
                    <button type="button" data-toggle="modal" class="control-buttons" style="background-color: #6390ee;"
                        data-target="#modal-create-edit-list">New List</button>
                    <button type="button" [disabled]="selectedList?.Name == null" class="control-buttons"
                        style="background-color: salmon;" data-toggle="modal"
                        data-target="#mod-confirm-list-delete">Delete</button>
                </div>
                <form [formGroup]="form">
                    <div formArrayName="kvp" *ngFor="let item of keyValuePairs.controls;let i=index"
                        style="margin: 20px 0px; padding-bottom:5px; border-bottom: .5px solid lightgrey;">
                        <div formGroupName="{{i}}">
                            <div
                                style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
                                <div
                                    style="background-color: #ececec; padding:10px 10px 5px 10px; width: 300px; border-radius: 0.25rem; border-bottom-right-radius: 0; border-bottom-left-radius: 0;">
                                    <ejs-textbox placeholder="Enter Key Name" formControlName="name"></ejs-textbox>
                                </div>
                                <button type="button" class="control-buttons" (click)="removeKey($event, i)"
                                    style="background-color: salmon; width: 30px; height: 30px;"><span
                                        class="zmdi zmdi-delete"></span></button>
                            </div>
                            <div
                                style="display: flex; flex-direction: column; background-color: #ececec; gap: 5px; border-radius: 0.25rem; border-top-left-radius: 0;">
                                <div
                                    style="display: flex; flex-direction: row; gap:10px; justify-items: center; padding: 10px; align-items: center; height: fit-content; flex-shrink: 0;">
                                    <ejs-textbox style="flex-grow: 1;"
                                        placeholder="Enter a value or a list of comma-separated values" id="name"
                                        width="100%" class="action-buttons" (keyup.enter)="onValueSubmit($event, i)"
                                        #txtNewVal></ejs-textbox>
                                    <button class="control-buttons"
                                        style="font-size: small; color: white; text-align: center; border: none; background-color: #6390ee; border-radius: .5rem; height: 30px; width: 30px; margin:5px;"
                                        (click)="onValueSubmit($event, i)"><span
                                            class="zmdi zmdi-long-arrow-down"></span></button>
                                    <ejs-textbox placeholder="Filter.." width="200px"
                                        (input)="onSearchValueChange($event,i)" class="action-buttons"></ejs-textbox>

                                </div>
                                <div
                                    style="display: flex; flex-direction: row; flex-wrap: wrap; flex-grow: 1; align-items: center; height: fit-content; padding: 10px 10px 10px 10px; background-color: white; margin:0px 10px 10px 10px; border-radius: 0.25rem; border: 1px lightgrey solid; gap: 5px">
                                    <ng-container *ngIf="item.value.value.length > 0; else noVals">
                                        <div *ngFor="let valueItem of item.value.value; let valueIndex=index"
                                            formArrayName="value"
                                            [style.filter]="valueItem.matched ? 'none' : 'opacity(0.2)'"
                                            style="margin: 2px;border-radius: 1rem; padding: 5px 10px 5px 15px ; height:fit-content; color: white; word-break: break-all; text-overflow: ellipsis; background-color: #6390ee; cursor: pointer; box-shadow: 0px 0px 2px 1px lightgrey;"
                                            (click)="onValueClick(valueItem)">
                                            {{valueItem.text}} <span class="zmdi zmdi-close-circle-o delete-x"
                                                (click)="onValueDelete(valueItem,i)"></span>
                                        </div>
                                    </ng-container>
                                    <ng-template #noVals>
                                        <div
                                            style="margin: 2px; padding: 5px 10px 5px 15px ; height:fit-content; color: lightgrey; flex-grow: 1; text-align: center;">
                                            List is Empty.
                                        </div>
                                    </ng-template>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="display: flex; flex-direction: row; gap:10px; margin-bottom: 50px;" *ngIf="selectedList">
                    <button class="control-buttons" style="background-color: #6390ee;" (click)="addKey()">Insert
                        New
                        Key</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="lists-modal modal fade" id="modal-create-edit-list" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span
                        class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <p>List Details</p>
                    <form [formGroup]="newListForm">
                        <div class="form-group row pt-1">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
                            <div class="col-12 col-sm-8 col-lg-6">
                                <input class="form-control" type="text" formControlName="name"
                                    pattern="[a-zA-Z0-9/_+=.@-]*">
                                <div *ngIf="newListName.invalid && (newListName.dirty || newListName.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="newListName.errors.pattern">
                                        Only alphanumeric characters and the characters /_+=.@- are allowed.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-1">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Description</label>
                            <div class="col-12 col-sm-8 col-lg-6">
                                <textarea cols="100" rows="4" class="form-control" id="description"
                                    formControlName="description"></textarea>
                            </div>
                        </div>
                    </form>
                    <div class="mt-8">
                        <button class="btn btn-rounded btn-space btn-secondary" type="button"
                            data-dismiss="modal">Cancel</button>
                        <button class="btn btn-rounded btn-space btn-success" type="button"
                            [disabled]="!newListForm.valid" (click)="createNewList();">Save</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>

<div class="modal fade" id="mod-confirm-list-delete" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span
                        class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Warning!</h3>
                    <p>Are you sure you want to delete "{{selectedList?.Name}}"? You cannot undo this action!</p>
                    <div class="mt-8">
                        <button class="btn btn-rounded btn-space btn-secondary" type="button"
                            data-dismiss="modal">Cancel</button>
                        <button class="btn btn-rounded btn-space btn-warning" type="button"
                            (click)="deleteSelectedList();">Proceed</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>