import { DomainMatcher } from "./DomainMatcher";
import { MatcherAttribute } from "./MatcherAttribute";
import { ResourceMatcher } from "./ResourceMatcher";

export class DomainDefinition {

    defaultMatcherAttributes?:MatcherAttribute[]
    domainMatchers?:DomainMatcher[]
    resourceMatchers?:ResourceMatcher[]

    constructor(
        public id:number,
        public name:string,
        public hostVendorId:number,
        public isCDN:boolean,
        public priority?:number,        
        public firstPartyCustomerId?:number,        
    ) {
        this.defaultMatcherAttributes = [];
        this.domainMatchers = [];
        this.resourceMatchers = [];
    }
    
}
