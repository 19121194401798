export declare abstract class VaultMenuItem {
  headerText?: string;
  menuText: string;
  pageText?: string;
  home?: boolean;
  group?: boolean;
  icon?: string;
  helpText?: string;
  link?: string;
  scopes?: string[];
  children?: VaultMenuItem[];
  hide?: boolean;
  crumbs?: boolean;
  vaultUsersOnly?: boolean;
  image?: string;
  customers?: string[];
  disabledCustomers?: string[];
  hideHeader?: boolean;
}

export const MENU_ITEMS: VaultMenuItem[] = [
  {
    menuText: 'Inventories',
    group: true,
    icon: 'zmdi-view-web',
    children: [
      {
        menuText: 'Vendors',
        headerText: 'Vendors',
        pageText: 'Vendors',
        icon: 'zmdi-developer-board',
        link: '/vendors',
        home: true,
        children: [],
      },
      {
        menuText: 'Pages',
        headerText: 'Pages',
        pageText: 'Pages',
        icon: 'zmdi-link',
        link: '/pages',
        children: [],
      },
      {
        menuText: 'Automated Users',
        headerText: 'Admin',
        pageText: 'Automated Users',
        icon: 'zmdi-network-setting',
        link: '/automatedUsers',
        children: [],
        disabledCustomers: ['Intuit - Mailchimp'],
      },
    ],
  },

  {
    menuText: 'Data',
    group: true,
    icon: 'zmdi-dns',
    children: [
      {
        menuText: 'Incidents',
        headerText: 'Incidents',
        pageText: 'Incidents',
        icon: 'zmdi-alert-triangle',
        link: '/incidents',
        children: [],
      },
      {
        menuText: 'Incidents',
        headerText: 'Incident Details',
        pageText: 'Incident Details',
        icon: 'zmdi-alert-triangle',
        link: '/incidents/details',
        children: [],
        hide: true,
      },

      {
        menuText: 'Report Library',
        hideHeader: true,
        icon: 'zmdi-equalizer',
        link: '/report-library',
        children: [],
      },
      {
        menuText: 'Raw Report Viewer',
        headerText: 'Report Viewer',
        hideHeader: true,
        icon: 'zmdi-equalizer',
        link: '/report/raw-report-viewere/x',
        children: [],
        hide: true,
      },
      // {
      //   menuText: 'Reports',
      //   headerText: 'Reports',
      //   pageText: 'Reports',
      //   icon: 'zmdi-equalizer',
      //   hide: true,
      //   children: [
      //     {
      //       menuText: 'Data Collection Summary',
      //       headerText: 'Reports',
      //       pageText: 'Data Collection Summary',
      //       link: '/reports/dataCollectionSummary',
      //       hide: false,
      //       customers:["Adobe"]
      //     },
      //     {
      //       menuText: 'Consent Summary',
      //       headerText: 'Reports',
      //       pageText: 'Consent Summary',
      //       link: '/reports/consentSummary',
      //       hide: false,
      //     },
      //     {
      //       menuText: 'Global Summary',
      //       headerText: 'Reports',
      //       pageText: 'Global Report',
      //       link: '/reports/disney/global',
      //       hide: false,
      //       image: '/assets/img/Disney.png',
      //       customers: ['Disney Plus', 'Disney - Marvel', 'ESPN'],
      //     },
      //     {
      //       menuText: 'Group Summary',
      //       headerText: 'Reports',
      //       pageText: 'Consent Summary',
      //       link: '/reports/disney/group',
      //       hide: false,
      //       image: '/assets/img/DisneySport.png',
      //       customers: ['Disney Plus', 'Disney - Marvel', 'ESPN'],
      //     },
      //     {
      //       menuText: 'Site Summary',
      //       headerText: 'Reports',
      //       pageText: 'Site Summary',
      //       link: '/reports/disney/site',
      //       hide: false,
      //       image: '/assets/img/DisneyEspn.png',
      //       customers: ['Disney Plus', 'Disney - Marvel', 'ESPN'],
      //     },
      //     {
      //       menuText: 'Global Summary',
      //       headerText: 'Reports',
      //       pageText: 'Global Report',
      //       link: '/reports/dashboard/global',
      //       hide: false,
      //       image: '/assets/img/Disney.png',
      //       customers: ['Disney'],
      //     },
      //     {
      //       menuText: 'Group Summary',
      //       headerText: 'Reports',
      //       pageText: 'Consent Summary',
      //       link: '/reports/dashboard/group',
      //       hide: false,
      //       image: '/assets/img/Disney.png',
      //       customers: ['Disney'],
      //     },
      //     {
      //       menuText: 'Site Summary',
      //       headerText: 'Reports',
      //       pageText: 'Site Summary',
      //       link: '/reports/dashboard/site',
      //       hide: false,
      //       image: '/assets/img/Disney.png',
      //       customers: ['Disney'],
      //     },
      //     {
      //       menuText: 'Adobe Analytics',
      //       headerText: 'Reports',
      //       pageText: 'Adobe Analytics',
      //       link: '/reports/adobe-analytics',
      //       hide: false,
      //       customers:["Adobe", "CIBC"]
      //     },
      //     {
      //       menuText: 'Group Summary',
      //       headerText: 'Reports',
      //       pageText: 'Group Summary',
      //       link: '/reports/conagrabrands/group',
      //       hide: false,
      //       image: '/assets/img/conagrabrandsLogo.png',
      //       customers: ['Conagra Brands'],
      //     },
      //     {
      //       menuText: 'Site Summary',
      //       headerText: 'Reports',
      //       pageText: 'Site Summary',
      //       link: '/reports/conagrabrands/site',
      //       hide: false,
      //       image: '/assets/img/conagrabrandsLogo.png',
      //       customers: ['Conagra Brands'],
      //     },
      //     {
      //       menuText: 'Dashboard',
      //       headerText: 'Reports',
      //       pageText: 'Dashboard',
      //       link: '/reports/dashboard/global',
      //       hide: false,
      //     },
      //     {
      //       menuText: 'VPPA',
      //       headerText: 'VPPA Report',
      //       pageText: 'VPPA Report',
      //       link: '/reports/vppa-report',
      //       customers: [
      //         'Course Hero',
      //         'Adobe',
      //         'Costco',
      //         'RBC',
      //         'Intuit - Mailchimp',
      //       ],
      //       hide: false,
      //     },
      //     {
      //       menuText: 'All Tags',
      //       headerText: 'Reports',
      //       pageText: 'All Tags',
      //       link: '/reports/custom/tags',
      //       hide: false,
      //       customers: ['PwC - Walmart'],
      //       scopes: ['read:admin'],
      //     },
      //     {
      //       menuText: 'All Cookies',
      //       headerText: 'Reports',
      //       pageText: 'All Cookies',
      //       link: '/reports/custom/cookies',
      //       hide: false,
      //       customers: ['PwC - Walmart'],
      //       scopes: ['read:admin'],
      //     },
      //     {
      //       menuText: 'Fingerprint Dashboard',
      //       headerText: "Reports",
      //       pageText: "Privacy: Fingerprint Dashboard",
      //       link: '/reports/fingerprint-dashboard',
      //       hide: false,
      //       customers:["Adobe"]
      //     },
      //     {
      //       menuText: 'Tracker Summary',
      //       headerText: "Tracker Summary Dashboard",
      //       pageText: "Tracker Summary Dashboard",
      //       link: '/reports/tracker-summary-dashboard',
      //       hide: false,
      //       customers:["Costco","Adobe"]
      //     },
      //     {
      //       menuText: 'Trackers by Vendor',
      //       headerText: "Trackers by Vendor",
      //       pageText: "Trackers by Vendor",
      //       link: '/reports/tracker-vendor-dashboard',
      //       hide: false,
      //       customers:["Costco","Adobe"]
      //     }
      //   ]
      // }
    ]
  },
  {
    menuText: 'Validation',
    group: true,
    icon: 'zmdi-shield-check',
    children: [
      {
        menuText: 'Overview',
        headerText: 'Overview',
        pageText: 'Overview',
        icon: 'zmdi-format-list-bulleted',
        link: '/validation/overview',
        scopes: ["read:ui"],
        children: []
      },
      {
        menuText: 'Test Suites',
        headerText: 'Test Suites',
        pageText: 'Test Suites',
        icon: 'zmdi-format-list-bulleted',
        link: '/validation/testSuites',
        scopes: ['read:admin', 'write:admin'],
        children: [],
      },
      {
        menuText: 'Conditions',
        headerText: 'Conditions',
        pageText: 'Conditions',
        icon: 'zmdi-code-setting',
        link: '/validation/conditions',
        scopes: ['read:admin', 'write:admin'],
        children: [],
      },
    ],
  },
  {
    menuText: 'Real Time Alerts',
    group: true,
    scopes: ['read:admin', 'write:admin'],
    icon: 'zmdi-collection-video',
    children: [
      {
        menuText: 'Rules',
        headerText: 'Rules',
        pageText: 'Real Time Alerts',
        link: '/eventPipeline/rules',
        scopes: ['read:admin', 'write:admin'],
        children: [],
      },
      {
        menuText: 'Advanced Rules',
        headerText: 'Advanced Rules',
        pageText: 'Real Time Alerts',
        link: '/eventPipeline/advancedRules',
        scopes: ['read:analyst', 'write:analyst'],
        children: [],
      },
    ],
  },
  {
    menuText: 'Events',
    group: true,
    scopes: ['write:analyst'],
    icon: 'zmdi-flash',
    vaultUsersOnly: true,
    children: [
      {
        menuText: 'Unclassified Requests',
        headerText: 'Unclassified Requests',
        pageText: 'Unclassified Requests',
        icon: 'zmdi-flash',
        link: '/events/list',
        scopes: ['read:analyst', 'write:analyst'],
        vaultUsersOnly: true,
        children: [],
      },
    ],
  },
  {
    menuText: 'Admin',
    icon: 'zmdi-settings',
    group: true,
    scopes: ['read:admin', 'write:admin'],
    children: [
      {
        menuText: 'Code Review',
        headerText: 'Admin',
        pageText: 'Code Review',
        icon: 'zmdi-settings',
        link: '/admin/codeReview',
        children: [],
        vaultUsersOnly: true,
      },
      {
        menuText: 'Code Review V2',
        headerText: 'Admin',
        pageText: 'Code Review V2',
        icon: 'zmdi-settings',
        link: '/admin/codeReviewV2',
        children: [],
        vaultUsersOnly: true,
      },
      {
        menuText: 'Domain Review',
        headerText: 'Admin',
        pageText: 'Domain Review',
        icon: 'zmdi-settings',
        link: '/admin/domainReview',
        children: [],
        vaultUsersOnly: true,
      },
      {
        menuText: 'Vendor Review',
        headerText: 'Admin',
        pageText: 'Vendor Review',
        icon: 'zmdi-settings',
        link: '/admin/vendorReview',
        children: [],
        vaultUsersOnly: true,
      },
      {
        menuText: 'Subscriptions',
        headerText: 'Admin',
        pageText: 'Subscriptions',
        icon: 'zmdi-settings',
        link: '/admin/alerts/config',
        hide: true,
        children: [],
      },
      {
        menuText: 'Page Runner',
        headerText: 'Admin',
        pageText: 'Page Runner Configuration',
        icon: 'zmdi-network-setting',
        link: '/admin/pagerunner/config',
        scopes: ['read:analyst', 'write:analyst'],
        children: [],
      },
      {
        menuText: 'Automated Users',
        headerText: 'Admin',
        pageText: 'Automated Users',
        icon: 'zmdi-network-setting',
        link: '/admin/automatedUsers/new',
        scopes: ['read:analyst', 'write:analyst'],
        hide: true,
        children: [],
      },
      {
        menuText: 'Link Discovery',
        headerText: 'Admin',
        pageText: 'Link Discovery Configuration',
        icon: 'zmdi-network-setting',
        link: '/admin/linkDiscovery/config',
        scopes: ['read:analyst', 'write:analyst'],
        children: [],
      },
      {
        menuText: 'Lists',
        headerText: 'Admin',
        pageText: 'Lists',
        icon: 'zmdi-network-setting',
        link: '/admin/lists',
        scopes: ['read:analyst', 'write:analyst'],
        children: [],
      },
      {
        menuText: 'Vendor ID Validation',
        headerText: 'Admin',
        pageText: 'Vendor ID Validation',
        icon: 'zmdi-network-setting',
        link: '/admin/vendor-id-validation-list',
        scopes: ["read:admin", "write:admin"],
        children: []
      },
      {
        menuText: 'Secrets',
        headerText: 'Secrets',
        pageText: 'Secrets',
        link: '/admin/secrets',
        scopes: ["read:admin", "write:admin"],
        children: []
      },
      {
        menuText: 'Users',
        headerText: 'Admin',
        pageText: 'Users',
        link: '/admin/users',
        icon: 'zmdi-account',
        scopes: ['read:admin', 'write:admin'],
        children: [],
      },
      {
        menuText: 'Roles',
        headerText: 'Admin',
        pageText: 'Roles',
        link: '/admin/permissions',
        icon: 'zmdi-shield-security',
        hide: true,
        children: [],
      },
      {
        menuText: 'User Profile',
        link: '/profile',
        hide: true,
        children: [],
      },
      {
        menuText: 'Vendor Library',
        headerText: 'Vendor Library',
        pageText: 'Vendor Library',
        icon: 'zmdi-network-setting',
        link: '/admin/vendorLibrary',
        scopes: ['read:developer', 'write:developer'],
        children: [],
      },
      {
        menuText: 'Config Management',
        headerText: 'Config Management',
        pageText: 'Page / Link Configuration Management',
        icon: 'zmdi-network-setting',
        link: '/admin/configmanagement',
        scopes: ['read:developer', 'write:developer'],
        children: [],
      },
      {
        menuText: 'Config Management',
        headerText: "Config Management",
        pageText: "Page / Link Configuration Management",
        icon: 'zmdi-network-setting',
        link: '/admin/configmanagement',
        scopes: ["read:admin", "write:admin"],
        children: [],
        customers: ["Costco"]
      },
    ]
  },
  {
    menuText: 'Notifications',
    group: true,
    hide: true,
    children: [
      {
        menuText: 'Notification Details',
        link: '/notification-details',
        hide: true,
        children: [],
      },
      {
        menuText: 'Alert Details',
        link: '/alerts/details',
        hide: true,
        children: [],
      },
    ],
  },
  {
    menuText: 'Developer',
    group: true,
    scopes: ['read:developer', 'write:developer'],
    icon: 'zmdi-code',
    vaultUsersOnly: true,
    children: [
      {
        menuText: 'Tools',
        link: '/developer/tools',
        icon: 'zmdi-wrench',
        scopes: ['read:developer', 'write:developer'],
        vaultUsersOnly: true,
        children: [],
      },
      {
        menuText: 'Vendor Info',
        link: '/vendorInfo',
        icon: 'zmdi-wrench',
        scopes: ['read:developer', 'write:developer'],
        vaultUsersOnly: true,
        children: [],
      },
      {
        menuText: 'Event Viewer',
        pageText: 'Event Viewer',
        link: 'developer/eventViewer',
        icon: 'zmdi-wrench',
        scopes: ['read:analyst', 'write:analyst'],
        vaultUsersOnly: true,
        hide: false,
        children: [],
      },{
        menuText: 'Queries',
        headerText: 'Queries',
        pageText: 'Queries',
        icon: 'zmdi-file-text',
        link: '/queries',
        children: [],
        scopes: ["read:admin"],
      },
      {
        menuText: 'Custom Reports',
        pageText: 'Custom Reports',
        headerText: 'Custom Reports',
        link: '/reports/custom/admin',
        hide: false,
        scopes: ['read:analyst'],
        vaultUsersOnly: true,
        children: [],
      },
    ],
  },
];
