export const FbDescriptions = {
    'configOptions.buttonSelector': 'Used to select buttons for inferred events tracking.',
    'configOptions.disableRestrictedData': 'Disables tracking of restricted data if set to true.',
    'configOptions.batchWaitTimeMs': 'Sets the wait time before sending batched events.',
    'configOptions.maxBatchSize': 'Sets the maximum number of events in a batch.',
    'configOptions.waitTimeMs': 'Sets the wait time for microdata collection.',
    'configOptions.prohibitedSources': 'List of sources from which data collection is prohibited.',
    'configOptions.blacklisted_keys': 'Keys that should not be tracked.',
    'configOptions.sensitive_keys': 'Keys that are considered sensitive and should not be tracked.',
    'configOptions.enableAutoEventId': 'Enables or disables automatic event ID generation.',
    'configOptions.enableEventSuppression': 'Suppresses certain events from being tracked.',
    'configOptions.enableBackupTimeout': 'Enables a backup timeout for event tracking.',
    'configOptions.fbcParamsConfig.params.prefix': 'Prefix for Facebook Click ID parameters.',
    'configOptions.fbcParamsConfig.params.query': 'Query parameter name for Facebook Click ID.',
    'configOptions.fbcParamsConfig.params.ebp_path': 'Path to the click ID in event data.',
    'configOptions.ProhibitedSources': 'Opt-in for prohibited sources plugin.',
    'configOptions.InferredEvents': 'Opt-in for inferred events plugin.',
    'configOptions.FirstPartyCookies': 'Opt-in for first-party cookies plugin.',
    'configOptions.AutomaticMatchingForPartnerIntegrations': 'Opt-in for automatic matching for partner integrations plugin.',
    'configOptions.IABPCMAEBridge': 'Opt-in for IAB PCMAE Bridge plugin.',
    'configOptions.BrowserProperties': 'Opt-in for browser properties plugin.',
    'configOptions.ClientHint': 'Opt-in for client hint plugin.',
    'configOptions.PrivacySandbox': 'Opt-in for privacy sandbox plugin.',
    'configOptions.LastExternalReferrer': 'Opt-in for last external referrer plugin.',
    'configOptions.CookieDeprecationLabel': 'Opt-in for cookie deprecation label plugin.'
};