import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { NotificationsService } from 'angular2-notifications';
import { Papa } from 'ngx-papaparse';
import { environment as env } from "../../../../environments/environment";
import { CustomerService } from '../../../services/customer/customer.service';
import { DataExportService } from '../../../services/data-export/data-export.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';

@Component({
  selector: 'data-collection-summary-report',
  templateUrl: './data-collection-summary-report.component.html',
  styleUrls: ['./data-collection-summary-report.component.css']
})
export class DataCollectionSummaryReportComponent implements OnInit {

  data:any;
  domains:any;
  public groupOptions: Object;
  public pageSettings: Object;
  public sortSettings: Object;
  public filterSettings: Object;
  @ViewChild('grid')public grid: GridComponent;
  isFirst:boolean;
  public cdnUrl:string;
  notificationOptions:any;

  constructor(public papa:Papa, public customerService:CustomerService, public dataExportService:DataExportService, public spinnerService:SpinnerService, public notificationService:NotificationsService) { 
    // this.groupOptions = { showGroupedColumn: false, columns: ['vendorName', 'domain', 'allKeys'], };
    this.pageSettings = { currentPage: 1, pageSize: 500 };
    this.sortSettings = { columns: [{ field: 'vendorName', direction: 'Ascending' }, { field: 'domain', direction: 'Ascending' }, {field: 'allKeys', direction:'Ascending'}] };
    this.filterSettings = { type: 'Excel' };
    this.isFirst = true;
    this.cdnUrl = env.cdn.url;
    this.notificationOptions = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true      
    };
  }

  ngOnInit(): void {
    let papaParams = {
      header: true,
      skipEmptyLines: "greedy"
    };
    this.spinnerService.toggle(true);
    const customerId = localStorage.getItem('cid');
    let reportUrl = `queryParamDataSummary/${customerId}/current.csv`;
    this.dataExportService.getScheduledReportFile(reportUrl).subscribe((reportData)=>{      
      Promise.all([
        this.parseFile(reportData.toString(), papaParams),
        this.customerService.getVendorDomains().toPromise()
      ]).then((results)=>{
        this.domains = results[1];
        // console.log(this.domains);
        this.data = (results[0] as any[]).map((row)=>{
          if ( row.domain == '') {
            row.domain = 'missing'
          }
          //id,vendor,website,domain,hostname
          let foundVendor = this.domains.filter((item)=>{return item.domain == row.domain || item.hostname == row.domain});
          if ( foundVendor && foundVendor.length > 0 ){
            row.vendorId = foundVendor[0].id;
            row.vendorName = foundVendor[0].vendor;
            row.vendorWebsite = foundVendor[0].website;
          } else {
            row.vendorId = -1;
            row.vendorName = 'Unknown';
            row.vendorWebsite = null;
          }
          return {...row};
        });
        
        this.spinnerService.toggle(false);
      })
    },
    (error)=>{
      this.spinnerService.toggle(false);
      this.notificationService.error('Uh Oh...', 'Unable to load data. Try again or contact support.', this.notificationOptions);
    });
  }

  onDataBound(event){
    Object.assign((this.grid.filterModule as any).filterOperators, { startsWith: 'contains' });
    if ( this.isFirst == true ){      
      this.grid.groupModule.collapseAll();
      this.isFirst = false;
    }
  }

  parseFile(file, params={}) {
    return new Promise(resolve => {
      params["complete"] = results => {
        resolve(results.data);
      };
      params["error"] = (error, file) => {
        console.log(error);
      };
      this.papa.parse(file, params);
    });
  };

}