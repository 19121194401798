import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorDomain } from '../../../../../models/VendorDomain';
import { VendorsService } from '../../../../../services/vendors/vendors.service';

@Component({
  selector: 'app-new-domain',
  templateUrl: './new-domain.component.html',
  styleUrls: ['./new-domain.component.css']
})
export class NewDomainComponent implements OnInit {
  vendorId: number;
  listings: VendorDomain[];
  createdomain: FormGroup;
  
  



  constructor(private vendorservice: VendorsService, private activatedRoute: ActivatedRoute, private _fb: FormBuilder,
    private router: Router) {
  }

     

  ngOnInit() {
    this.vendorId = this.activatedRoute.snapshot.params["vendorId"];
    this.vendorservice.getDomainsForVendorById(this.vendorId).subscribe((domains: VendorDomain[]) => {
      this.listings = domains;
    });

    
    this.createdomain = this._fb.group({
      vendorId: this.vendorId,
      domains: ['']
    });

  }

  addDomain() {
    const newDomainObj = {vendorId: this.vendorId, domains: [this.createdomain.value.domains]};
    this.vendorservice.createDomain(newDomainObj).subscribe((result)=>{  
     this.router.navigate(['/vendorDomains', this.vendorId]);
    })
  }

}
