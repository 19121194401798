<div class="row">
    <div class="col-sm-12">
        <div class="card card-table card-border be-loading">
            <div class="card-header">
                <div class="tools">
                    <!-- <button *ngIf="authService.userHasScopes(['write:admin']) && authService.isVaultUser()" type="button" class="btn btn-rounded btn-space btn-success" data-toggle="modal" data-target="#modal-bulk-update" ><i style="color: white;" class="icon icon-left zmdi zmdi-collection-plus"></i>Bulk Update</button>
                    <button [disabled]="exportButtonDisabled" type="button" class="btn btn-rounded btn-space btn-primary" (click)="exportData();"><i style="color: white;" class="icon icon-left zmdi zmdi-download"></i>Export</button> -->
                    <button type="button" class="btn btn-rounded btn-space btn-success" data-toggle="modal"
                        data-target="#modal-new"><i style="color: white;"
                            class="icon icon-left zmdi zmdi-collection-plus"></i>New App/Version</button>
                </div>
                <span class="title">Apps</span>&nbsp;<span class="icon zmdi zmdi-info" title="Apps"
                    data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top"
                    data-content="Adipiscing risus egestas id vestibulum conubia vulputate"></span>
                <span class="card-subtitle">Browse or search for your Apps</span>
            </div>
            <div class="card-body">
                <ejs-grid #grid locale='en-US' [allowPaging]="true" [pageSettings]="pageSettings" [allowSorting]="true"
                    [sortSettings]="sortSettings" [allowSelection]="false" [enableHover]="false" [toolbar]="toolbar">
                    <e-columns>
                        <e-column field="name" headerText="App" textAlign="Left" minWidth="25%">
                            <ng-template #template let-data>
                                <div style="position: relative; width: 100%;">
                                    <div class="row" style="margin: 0px 4px; position: relative;">
                                        <div class="col-3">
                                            
                                        </div>
                                        <div class="col-7" style="text-align: center;">
                                            <span
                                                style="font-size: medium; font-weight: bold; font-style: italic;">Most
                                                Recent Data</span>
                                            <br />
                                            <span
                                                style="font-size: small; color:grey; font-style: italic; margin-left: 4px;">(
                                                10/7/24 12:30 PM CT)</span>
                                            <br />
                                        </div>
                                        <div class="col-3"
                                            style="font-size: medium; font-weight: bold; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis; width: 200px ;">

                                            <img style="width: 96px; height:96px; margin-right: 5px; box-shadow: 0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15); border-radius: 20%;"
                                                src="https://{{cdnUrl}}/mobile/apps/{{ data.package }}.png" /><a
                                                [routerLink]="['/mobile','apps', data.name, data.versions[0]]">{{data.name}}
                                                ({{data.versions[0]}})</a>

                                            <div class="row"
                                                style="padding:0px; margin: 0px 4px; color:grey; font-style: italic; overflow:clip; text-overflow: ellipsis;">
                                                <div class="col" style="font-size: small; padding:2px; margin:0;">
                                                    Released: 10/2/2024
                                                    ({{data.versions.length-1}} previous versions)
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <span style="font-size: small; font-weight: bold; text-decoration: underline; font-style: italic;">Summary</span>
                                            <br />
                                            <span
                                                style='font-size: small; font-weight: bold; color: red; margin-right: 4px;'
                                                class="zmdi zmdi-circle-o"></span><span>PII: {{data.pii.length}}</span><br />
                                            <span
                                                style='font-size: small; font-weight: bold; color: orange; margin-right: 4px;'
                                                class="zmdi zmdi-circle-o"></span><span>Domains: {{data.domains.length}}</span><br />
                                            <span
                                                style='font-size: small; font-weight: bold; color: greenyellow; margin-right: 4px;'
                                                class="zmdi zmdi-circle-o"></span><span>SDKs: {{data.sdks.length}}</span><br />
                                        </div>

                                        <div class="col-2">
                                            <span style="font-size: small; font-weight: bold; text-decoration: underline; font-style: italic;">PII</span>
                                            <br />
                                            <div *ngIf="data.pii.length == 0">
                                                <span style="font-size: small">(N/A)</span>
                                            </div>
                                            <div *ngFor="let pii of data.pii; let i = index">
                                                <span style="font-size: small">{{pii}}</span><br/>
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <span
                                                style="font-size: small; font-weight: bold; text-decoration: underline; font-style: italic;">Domains</span>
                                            <br />
                                            <div *ngIf="data.domains.length == 0">
                                                <span style="font-size: small">(N/A)</span>
                                            </div>
                                            <div *ngFor="let domain of data.domains; let i = index">
                                                <span style="font-size: small">{{domain}}</span><br/>
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <span
                                                style="font-size: small; font-weight: bold; text-decoration: underline; font-style: italic;">SDKs</span>
                                            <br />
                                            <div *ngIf="data.sdks.length == 0">
                                                <span style="font-size: small">(N/A)</span>
                                            </div>
                                            <div *ngFor="let sdk of data.sdks; let i = index">
                                                <img style="width: 16px; height:16px;margin-right: 5px;" src="https://{{cdnUrl}}/mobile/sdks/{{ sdk.icon }}.ico"/><span style="size: small;"><a href="{{sdk.url}}">{{sdk.name}} ({{sdk.version}})</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #detailTemplate let-data>
                                <div class="card" *ngFor="let version of data.versions.slice(1); let i = index">
                                    <span style="size: small; font-weight: bold;"><a
                                            [routerLink]="['/mobile','apps', data.name, version]">{{version}}</a></span>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</div>

<div class="custom-width modal fade" id="modal-new" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" type="button" data-dismiss="modal" aria-hidden="true"><span
                        class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <h2>New App</h2>
                    <p>Add a new app, or version of an existing one. You can manually add it here or it can be uploaded
                        to AWS S3 via HTTPS.</p>
                    <div style="height: 225px; border-style: dashed;">
                        <span style="vertical-align: middle; font-weight: bold; font-size: small;">Drop Files
                            Here...</span>
                    </div>
                    <div class="mt-4">
                        <button class="btn btn-rounded btn-space btn-primary" type="button">Click to copy secure S3
                            upload URL</button>
                    </div>
                    <div class="mt-8">
                        <button class="btn btn-rounded btn-space btn-secondary" type="button"
                            data-dismiss="modal">Cancel</button>
                        <button class="btn btn-rounded btn-space btn-success" type="button">Submit</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>


<!-- <div class="row">
    <div class="col-sm-12">
        <div class="card card-table card-border be-loading">
            <div class="card-header">
                <span class="title">Apps</span>&nbsp;<span class="icon zmdi zmdi-info" title="Apps"
                    data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top"
                    data-content="Adipiscing risus egestas id vestibulum conubia vulputate"></span>
                <span class="card-subtitle">Browse or search for your Apps</span>
            </div>
            <div class="card-body">

                <div *ngFor="let app of androidApps; let i = index">
                    <img width="128" height="128" src="assets/mobile/{{app.package}}-192x192.png" />
                </div>
            </div>
        </div>
    </div>
</div> -->