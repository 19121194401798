import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-input-spy',
  templateUrl: './input-spy.component.html',
  styleUrls: ['./input-spy.component.css']
})
export class InputSpyComponent implements OnInit {

  public data: Object[];
  public groupOptions: Object;
  public pageSettings: Object;
  public refresh: Boolean;
  @ViewChild('grid')
  public grid: GridComponent;
  @ViewChild('alertDialog')
  // public alertDialog: DialogComponent;
  public alertHeader: string = 'Grouping';
  public hidden: Boolean = false;
  public target: string = '.control-section';
  public alertWidth: string = '300px';
  public alertContent: string = 'Grouping is disabled for this column';
  public showCloseIcon: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public alertDlgBtnClick = () => {
      // this.alertDialog.hide();
  }
  public alertDlgButtons: Object[] = [{ click: this.alertDlgBtnClick.bind(this), buttonModel: { content: 'OK', isPrimary: true } }];

  constructor(public papa:Papa) { }

  ngOnInit(): void {
      // this.data = inventoryData;
      this.groupOptions = { showGroupedColumn: false, columns: ['Spy Domain'], enableLazyLoading: true  };
      this.pageSettings = { pageCount: 5 };

      this.papa.parse('https://ui.dev.vaultjs.com/assets/data/filus/inputSpyReport.csv', {
        header: true,
        download: true,
        skipEmptyLines: "greedy",
        complete: (results, file)=>{
          console.log(results);
          this.data = results.data;         
        },
        error: (error, file)=>{
          console.log(error);
        }
      });


  }
  dataBound() {
      if(this.refresh){
          this.grid.groupColumn('Spy Domain');
          this.refresh =false;
      }
  }
  load() {
      this.refresh = (<any>this.grid).refreshing;
  }
  created() {
      this.grid.on("columnDragStart", this.columnDragStart, this);
  }
  public columnDragStart(args: any) {
      if(args.column.field === "Mainfieldsofinvention"){
          // this.alertDialog.show();
     }
  }

}
