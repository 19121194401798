import { Component, OnInit, ViewChild } from '@angular/core';
import { vendorData } from './data';
import { environment as env } from "../../../environments/environment";
import { DetailRowService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { Chart, ISparklineLoadEventArgs, Sparkline } from '@syncfusion/ej2-charts';
import { ChartTheme, ILoadedEventArgs, IPointRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-vendors2',
  templateUrl: './vendors2.component.html',
  styleUrls: ['./vendors2.component.css'],
  providers: [DetailRowService]
})
export class Vendors2Component implements OnInit {
  public data: any;
  public cdnUrl: string;
  public initial = true;
  
  @ViewChild('grid') 
  public grid: GridComponent;

  public pausTooltipSettings: object = {
    visible: true,
    format: '${xval} : ${yval}',
    trackLineSettings: {
      visible: true,
      color: 'red',
      width: 2
    }
  };

  constructor() {
   
  }

  dataBound(){
    // if (this.initial === true) {
    //   this.grid.detailExpandAll();
    //   this.initial = false;
    // }
  }

  public detailDataBound(arg) {
    console.log('detailDataBound: ');
    console.log(arg);

    setTimeout(() => {
      let eventChart = new Chart({
        chartArea: this.chartArea,
        width: this.width,
        primaryXAxis: this.primaryXAxis,
        primaryYAxis: this.primaryYAxis,
        title: this.title,
        tooltip: this.tooltip,
        legendSettings: this.legend,
        load: this.load,
        pointRender: this.pointRender,
        series: [{
          dataSource: this.data2,
          type: 'Bubble',
          xName: 'x',
          yName: 'y',
          size: 'size',
          name: 'Events',
          marker: this.marker,
          minRadius: this.minRadius,
          maxRadius: this.maxRadius
        }]
      });
      eventChart.appendTo('#eventChart' + arg.data.id);
    }, 100);
  }

  ngOnInit(): void {
    this.data = vendorData;    
    this.cdnUrl = env.cdn.url;    
  }

  computeVendorPresence(data) {
    return '100%';
  }

  computeValidationStatus(data) {
    return '0/0'
  }

  public getSparkData(type: string, count: number): any {
    let vendorInfo = this.data[count-1];
    if ( vendorInfo ){
      return vendorInfo[type];
    }
    return [];
  }

  sparklineLoad(arg:ISparklineLoadEventArgs){
    console.log(arg);
    let chartId = arg.sparkline.element.id;    
    if ( chartId.startsWith('statusSPL') ){
      let index = Number(chartId.replace('statusSPL', ''));      
      arg.sparkline.dataSource = this.data[index]['validationStatus'];
    } else if ( chartId.startsWith('presenceSPL') ) {
      let index = Number(chartId.replace('presenceSPL', ''));
      arg.sparkline.dataSource = this.data[index]['vendorPresence'];
    }
  }
  
  public data2: Object[] = [
    { x: -24, y: 7.8, size: 1.347, text: 'Code File Changed' },
    { x: -16, y: 6.5, size: 1.241, text: 'New Code File' },
    { x: -8, y: 6.0, size: 0.238, text: 'DNS Record Change' },
    { x: -9, y: 3.0, size: 0.238, text: 'New DNS Record' }
  ];

  public data3: Object[] = [
    { x: -24, y: 1.8, size: 0.75, text: 'Code File Changed 2' },
    { x: -16, y: 3.5, size: 1.00, text: 'New Code File 2' },
    { x: -8, y: 4.0, size: 0.638, text: 'DNS Record Change 2'},
    { x: -9, y: 1.0, size: 0.038, text: 'New DNS Record 2' }
  ];

  public data4: Object[] = [
    { x: -2, y: 9.8, size: 2.0, text: 'Code File Changed 3' },
    { x: -16, y: 4.5, size: 1.01, text: 'New Code File 3' },
    { x: -30, y: 1.0, size: 0.008, text: 'DNS Record Change 3' },
    { x: -9, y: 6.0, size: 0.03, text: 'New DNS Record 3' }
  ];


  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    title: 'Hour',
    minimum: -24,
    maximum: 0,
    interval: 4
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: 'Event Total',
    minimum: 0,
    maximum: 10,
    interval: 2.5
  };

  public marker: Object = {
    dataLabel: { name: 'text' }
  };
  //Initializing Tooltip
  public tooltip: Object = {
    enable: true,
    format: "${point.text}<br/>Literacy Rate : <b>${point.x}%</b>" +
      "<br/>GDP Annual Growth Rate : <b>${point.y}</b><br/>Population : <b>${point.size} Billion</b>"
  };
  public minRadius: number = 3;
  public maxRadius: number = Browser.isDevice ? 6 : 8;
  public pointRender(args: IPointRenderEventArgs): void {
    console.log("pointRender");
    let bootstrapColors: string[] = ['#a16ee5', '#f7ce69', '#55a5c2', '#7ddf1e', '#ff6ea6', '#7953ac', '#b99b4f', '#407c92', '#5ea716', '#b91c52'];
    args.fill = bootstrapColors[args.point.index % 10];
  };

  public legend: Object = {
    visible: false
  };
  public chartArea: Object = {
    border: {
      width: 0
    }
  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    console.log('Chart load!');
    
    var id = args.chart.element.id.replace("chart", "");
    if ( id == "1" ){
      args.chart.dataSource = this.data2;
    } else if ( id == "2" ){
      args.chart.dataSource = this.data3;
    } else if ( id == "3" ){
      args.chart.dataSource = this.data4;
    } else {
      args.chart.dataSource = [];
    }

    
    let selectedTheme: string = 'bootstrap4';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };
  // custom code end
  public width: string = Browser.isDevice ? '100%' : '60%';

  public title: string = 'Events';

  // public data3: object[] = [{ x: 'Mon', y: 3 }, { x: 'Tue', y: 5 }, { x: 'Wed', y: 2 }, { x: 'Thu', y: 4 }, { x: 'Fri', y: 6 }];
  // public axisSettings: object ={
  //     minX: -1, maxX: 7, maxY: 8, minY: -1
  // };
  public tooltipSettings: object = {
    visible: true,
    format: '${timestamp} : ${value}',
    fill: '#033e96',
    textStyle: {
      color: 'white'
    },
  }


  public tooltipSettings2: object = {
    visible: true,
    format: '${timestamp} : ${testsPassing}',
    fill: '#033e96',
    textStyle: {
      color: 'white'
    },
    trackLineSettings: {
      visible: true,
      color: 'red',
      width: 2
    }    
  }

  getEvents(args){
    console.log("getEvents");
    console.log(args);
    return [];
  }

  public pausData: object[] = [
    { x: 0, xval: '2005', yval: 20090440 },
    { x: 1, xval: '2006', yval: 20264080 },
    { x: 2, xval: '2007', yval: 20434180 },
    { x: 3, xval: '2008', yval: 21007310 },
    { x: 4, xval: '2009', yval: 21262640 },
    { x: 5, xval: '2010', yval: 21515750 },
    { x: 6, xval: '2011', yval: 21766710 },
    { x: 7, xval: '2012', yval: 22015580 },
    { x: 8, xval: '2013', yval: 22262500 },
    { x: 9, xval: '2014', yval: 22507620 },
];

public minUs: object = { minY: 13 };

public areatooltipSettings: object = {
  visible: true,
  format: '${xval} : ${yval}',
};

}
