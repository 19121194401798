import { Component, OnInit } from '@angular/core';
import { DashboardFileSelectorService } from '../../../services/dashboardFileSelector/dashboard-file-selector.service';
@Component({
  selector: 'app-tiktok-vendor-report',
  templateUrl: './tiktok-vendor-report.component.html',
  styleUrls: ['./tiktok-vendor-report.component.css']
})
export class TiktokVendorReportComponent implements OnInit {

  constructor(
    private dashboardFileSelectorService: DashboardFileSelectorService
  ) {
    this.dashboardFileSelectorService.toggle(true);
    this.dashboardFileSelectorService.setReportCode('TiktokVAR');
  }

  ngOnInit(): void {
    this.dashboardFileSelectorService.setSelectionHandler(
      this.onReportSelect.bind(this)
    );
  }

  onReportSelect(reportName: string) {

  }

}
