export class Incident {
    
    public vendor:string;
    public eventType:string;
    public firstName:string;
    public lastName:string;

    constructor(
        public id:number, 
        public name:string, 
        public description:string,
        public severity:number, 
        public status:number, 
        public analystId:number,
        public customerId:number,
        public eventId:number,
        public topicId:number,
        public linkedEvents:number[]
    ) {

    }
}
