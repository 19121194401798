<div *ngIf="isLoaded | async">
    <div class="container h-100 d-flex flex-column">
        <div class="row">
            <div class="col-lg-12" style="border: 0px solid red;">
                <div class="card" style="border: 0px solid red;">
                    <app-col-bar-chart-panel
                        title="Vendors Collecting Data"
                        yLabel="Unique Attributes"
                        name="Vendors"
                        [data]="barChartData">
                    </app-col-bar-chart-panel>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7" style="border: 0px solid red;">
                <div class="card" style="border: 0px solid red;">
                    <app-hist-panel
                        title="Pages by Attributes Collected"
                        yLabel="Pages"
                        name='Attributes Collected'
                        [data]="histData">
                    </app-hist-panel>
                </div>
            </div>
            <div class="col-lg-5" style="border: 0px solid red;">
                <app-scrollable-progress-bars title="Attributes Collected" [data]="atributeData" height="413"></app-scrollable-progress-bars>
            </div>
        </div>
        <div class="row" style="padding-bottom: 11px;">
            <div class="col-lg-12" style="border: 0px solid red;">
                <app-nested-grid-panel
                    [data]="vendorGridData ? vendorGridData : null"
                    [headers]="vendorGridHeaders"
                    [childGrid]="vendorChildGrid"
                ></app-nested-grid-panel>
            </div>
        </div>
        <div class="row" style="padding-top: 11px;">
            <div class="col-lg-12" style="border: 0px solid red;">
                <app-nested-grid-panel
                    [data]="pageGridData ? pageGridData : null"
                    [headers]="pageGridHeaders"
                    [childGrid]="pageChildGrid">
                </app-nested-grid-panel>
            </div>
        </div>
    </div>
</div>