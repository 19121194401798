import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TopicMessage } from '../../models/TopicMessage';
import { environment as env } from "../../../environments/environment";
import { VaultBaseService } from '../VaultBaseService';
import { Topic } from '../../models/Topic';
import { HttpParams } from '@angular/common/http';
import { SubscriptionSettings } from '../../models/SubscriptionSettings';
import { TopicSubscription } from '../../models/TopicSubscription';

@Injectable({
  providedIn: 'root'
})
export class TopicService extends VaultBaseService {

  public getMessagesForTopic(topicId:number):Observable<TopicMessage[]>{
    var url = `${env.api.url}topic/${topicId}/messages`;
    return super.makeHttpRequest(url, null, false);
  }

  public addMessageForTopic(topicId:number, message:string):Observable<any>{
    const url = `${env.api.url}topic/${topicId}/messages`;
    return this.authHttp.post(url, JSON.stringify([{text:message}]));  
  }

  public getTopics(status:number[]=[1]):Observable<Topic[]>{
    var url = `${env.api.url}topics`;
    var params = new HttpParams().set('status', status.join(","));
    return super.makeHttpRequest(url, params, false);    
  }

  public getTopicSubscriptions(userId:string=null):Observable<TopicSubscription[]>{
    var url = `${env.api.url}topic/subscriptions`;
    if ( userId ){
      url += `?uid=${encodeURIComponent(userId)}`;
    }
    return super.makeHttpRequest(url, null, false);    
  }

  public getSubscriptionSettings():Observable<SubscriptionSettings>{
    var url = `${env.api.url}topic/subscriptions/settings`;
    return super.makeHttpRequest(url, null, false);    
  }

  public createTopicSubscription(subs:any):Observable<any>{
    const url = `${env.api.url}topic/subscription`;
    return this.authHttp.post(url, JSON.stringify(subs));  
  }

  public deleteTopicSubscription(subs:any):Observable<any>{
    const url = `${env.api.url}topic/subscription`;
    return this.authHttp.put(url, JSON.stringify(subs));  
  }

  public updateTopicSubscriptionVendorFilter(subscriptionId:number,vendors:number[]):Observable<any>{
    const url = `${env.api.url}topic/subscription/${subscriptionId}/filter`;
    return this.authHttp.put(url, JSON.stringify({vendors:vendors}));
  }

}
