<div class="row">
  <div class="col-sm-12">
    <div class="card card-table card-border">
      <div class="card-header">
          <div class="tools">            
          </div>
        <span class="title">Validation Rule History</span>&nbsp;<span class="icon zmdi zmdi-info" title="Validation Rule History" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
      </div>
      <div class="card-body">
        <ejs-grid #ruleHistoryGrid
        [dataSource]='ej2HistoryData | async'
        allowTextWrap="true"
        locale='en-US' 
        [allowPaging]="true" 
        [pageSettings]="pageSettings" 
        [allowSorting]="true" 
        [sortSettings]="sortSettings"
        [allowSelection]="false"
        [enableHover]="false"
        [allowResizing]="true"
        (dataStateChange)= 'dataStateChange($event)'>
        <e-columns>         
          <e-column field='chrome_linkurl' headerText='Name'></e-column>
          <e-column field='epoch' headerText='Execution Date' width="10%">
            <ng-template #template let-data>{{ timezoneService.format(data.epoch, false, true) }}</ng-template>
          </e-column>
          <e-column field='validation_success' headerText='Status' width="4%"></e-column>
          <e-column field='validation_message' headerText='Message' width="50%"></e-column>          
        </e-columns>
      </ejs-grid>
      </div>     
    </div>
  </div>
</div>