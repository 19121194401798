<div class="row">
    <div class="col-sm-12">
      <div class="card card-table card-border be-loading">
        <div class="card-header">
          <span class="title">{{vendorTitle}}</span>&nbsp;<span class="icon zmdi zmdi-info" title="{{vendorTitle}}" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="top" [attr.data-content]="tooltipContent"></span>
          <span class="card-subtitle">Browse or search for vendors</span>
        </div>
        <div class="card-body">
          <ejs-grid #grid 
            locale='en-US' 
            [allowPaging]="true" 
            [pageSettings]="pageSettings" 
            [allowSorting]="true" 
            [sortSettings]="sortSettings"
            [allowSelection]="false"
            [enableHover]="false"
            [toolbar]="toolbar">
            <e-columns>         
                <e-column field="name" headerText="Vendor/SDK" textAlign="Left" minWidth="25%">
                    <ng-template #template let-data>
                      <div style="position: relative; width: 100%;">
                        <div class="row" style="margin: 0px 4px; position: relative;">
                          <div class="col" style="font-size: medium; font-weight: bold; padding: 0px; margin: 0px 0px; overflow-wrap: normal; overflow:clip; text-overflow: ellipsis;">
                            <img style="width: 32px; height:32px;margin-right: 5px;" src="https://{{cdnUrl}}/mobile/sdks/{{ data.iconName ? data.iconName : 'unknown' }}.ico"/><a href={{data.website}} target="_blank">{{data.name}}</a><span class="icon zmdi zmdi-sign-in" style="margin-left: 5px; font-size: 15px !important"></span>                                                        
                          </div>
                        </div>
                        <div class="row" style="padding:0px; margin: 0px 4px; color:grey; font-style: italic; overflow:clip; text-overflow: ellipsis;">
                          <div class="col" style="font-size: small; padding:2px; margin:0;">
                            {{data.description}}
                          </div>
                        </div>                    
                        <div *ngIf="data?.categories?.length > 0" class="row"
                            style="padding:0px; margin: 0;  color:#4d545c; ">
                            <div class="col"
                                style="font-size: small; padding:2px; margin:0; display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px">
                                <span >
                                    Categories:
                                </span>
                                <div *ngFor="let category of data.categories"
                                    style="font-size: x-small;border-radius: .2rem; background-color:#4d545c; padding:1px 5px; color: white;">
                                    {{category}}
                                </div>
                            </div>
                        </div>
                      </div>
                    </ng-template>
              </e-column>
              <e-column field='detected' headerText='Detected'></e-column>
              <!-- <e-column field='sslpin' headerText='SSL Pinning' value="No"></e-column> -->
              <e-column field='creation_date' headerText='Creation Date'></e-column>
            </e-columns>
          </ejs-grid>
        </div>      
      </div>
    </div>
  </div>