export class Extractor {

    public key?:string;
    public value?:string;
    public description?:string;
    public groupName?:string;

    constructor(
        public id:number,
        public name:string
    ){

    }
}