import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { IncidentsComponent } from './incidents/incidents.component';
import { IncidentDetailsComponent } from './incident-details/incident-details.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { AccumulationChartAllModule, } from '@syncfusion/ej2-angular-charts';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ReportComponentsModule } from '../reports/report-components.module';

@NgModule({  
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    TooltipModule,
    ChartModule,
    AccumulationChartAllModule,
    DropDownListAllModule,
    ReportComponentsModule,
  ],
  declarations: [
    IncidentsComponent,
    IncidentDetailsComponent,
  ],
  exports: [
    IncidentsComponent,
    IncidentDetailsComponent
  ]
})
export class IncidentComponentsModule { }
