import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TrendLineData } from '../../dashboardDS';

@Component({
  selector: 'app-trend-line-display',
  templateUrl: './trend-line-display.component.html',
  styleUrls: ['./trend-line-display.component.css']
})
export class TrendLineDisplayComponent implements OnInit, OnChanges {
  @Input('title') label: string;
  @Input('max') max: number;
  @Input('series') series: TrendLineData[];
  @Input('label') extraLabel: string;
  public title: string = 'No Data Found';

  public _max: number;
  public data: TrendLineData[];
  public errors: number;
  public primaryXAxis: Object;
  public primaryYAxis: Object;
  public chartArea: Object;
  public margin: Object;

  constructor() {
    this.primaryXAxis = {
      visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      }
    };
    this.primaryYAxis = {
      visible: false,
      majorGridLines: {
        width: 0
      },
      minorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      minorTickLines: {
        width: 0
      }
    };
    this.chartArea = {
      border: { width: 0 },
    };
    this.margin = {
      top: 25,
      right: 25,
      bottom: 25,
      left: 25
    };
    this._max = 0;
    this.data = [];
    this.errors = 0;
  }

  updateData() {
    if (this.max && this.series.length) {
      this._max = this.max;
      this.data = this.series.splice(0, this._max);
      this.errors = this.data.reduce((acc, curr) => acc + curr.y, 0);
      this.title = this.label || 'No Title Attached';
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.updateData();
  }
}
