import { Component, OnInit, ViewChild } from '@angular/core';
import { CommandModel, DataSourceChangedEventArgs, DataStateChangeEventArgs, GridComponent, PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { environment as env } from "../../../environments/environment";
import { ReportDataAdaptor } from './reportDataAdaptor';
import { TimezoneService } from '../../services/timezone.service';

@Component({
  selector: 'app-report-data-grid',
  templateUrl: './report-data-grid.component.html',
  styleUrls: ['./report-data-grid.component.css']
})
export class ReportDataGridComponent implements OnInit {


  @ViewChild('reportDataGrid') public reportDataGrid: GridComponent;

  public reportDataManager: DataManager;

  filterSettings: any = {
    type: 'Excel',
  }

  constructor(private timezoneService: TimezoneService) {
    this.reportDataManager = new DataManager({
      url: `${env.api.url}/data/report/cookieTrackerDetails_small`,
      adaptor: new ReportDataAdaptor(this.timezoneService)
    });

  }

  ngOnInit(): void {

  }

  onDataBound() {
    this.reportDataGrid.autoFitColumns();
  }

}
